import React from 'react';

import { usePresentationType } from '../../../../../../../../providers/providers/PresentationTypeProvider';
import { CoverContentDividedBT } from './CoverContentDividedBT';
import { CoverContentDividedRegular } from './CoverContentDividedRegular';

export interface ICoverContentDividedProps {
  divider: React.ReactNode;
  isReversed?: boolean;
}

export const CoverContentDivided: React.FC<ICoverContentDividedProps> = props => {
  const { presentationType } = usePresentationType();
  if (presentationType === 'buyerTour') return <CoverContentDividedBT {...props} />;
  return <CoverContentDividedRegular {...props} />;
};
