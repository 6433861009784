import React from 'react';
import { Handle } from 'rc-slider';
import { Range } from '../../../../../../../../components/Range/Range';
import { NumberFormatter } from '../../../../../../../../../services/numberFormatter';

import classes from './TooltipRange.module.scss';

const calculateOffset = characters => {
  if (characters === 4) {
    return -9;
  }

  if (characters === 3) {
    return -5;
  }

  if (characters === 2) {
    return -1;
  }

  if (characters === 1) {
    return 0;
  }

  return -12;
};

const myHandle =
  () =>
  ({ value, dragging, index, ...restProps }) => {
    const bottomLeftOffset = calculateOffset(`${value}`.length);

    return (
      <Handle {...restProps} key={index}>
        <div className={classes.bottomValue} style={{ left: `${bottomLeftOffset}px` }}>
          {NumberFormatter.format(value)}
        </div>
      </Handle>
    );
  };

export const TooltipRange = ({ changed = f => f, options = [], value = [], className = '' }) => {
  const handle = myHandle();

  return (
    <div className={`${classes.container} ${className}`}>
      <Range
        allowCross={false}
        changed={changed}
        marks={options}
        initValue={value}
        customHandle={handle}
        step={1}
      />

      <div className={classes.between}>
        <span className={classes.bottomLeft}>0</span>
        <span className={classes.bottomCenter}>Months</span>
        <span className={classes.bottomRight} style={{ right: '-7px' }}>
          24
        </span>
      </div>
    </div>
  );
};
