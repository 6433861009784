import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../../../components/Card/Card.component';
import { useUser } from '../../../../../providers/UserProvider';
import { getCards } from './data';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '300px 300px',
  rowGap: 20,
  columnGap: 20,
  padding: '4%',
});

export const WhereBuyersGetTheirInfoModern: React.FC = () => {
  const { isCanadaUser } = useUser();
  const cards = getCards(isCanadaUser);
  return (
    <Grid>
      {cards.map((card, i) => (
        <Card key={i} {...card} />
      ))}
    </Grid>
  );
};
