import React from 'react';

import { ProgressTrackerComponentProps } from '../ProgressTracker';
import { Container, Title, Section } from './LuxuryProgressTracker.styles';

const LuxuryProgressTracker: React.FC<ProgressTrackerComponentProps> = ({
  label,
  sectionIds,
  activeSectionPosition,
  containerStyles,
  titleStyles = {},
}) => (
  <Container style={containerStyles} id='progress-tracker'>
    <Title style={{ textTransform: 'uppercase', ...titleStyles }}>{label}</Title>
    <Section variant='body1'>
      Section {activeSectionPosition + 1} of {sectionIds.length}
    </Section>
  </Container>
);

export default LuxuryProgressTracker;
