import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { TCategory } from '../../types';
import { CATEGORIES } from '../../constants/categories';

type CategoryContextType = {
  category: TCategory | undefined;
  setCategory: Dispatch<SetStateAction<TCategory | undefined>>;
  resetCategory: () => void;
};
const CategoryContext = React.createContext<CategoryContextType | undefined>(undefined);

export const useCategory = () => {
  const context = useContext(CategoryContext);

  if (!context) {
    throw new Error('useCategory cannot be used without CategoryContext');
  }

  return context;
};

interface CategoryProviderProps {
  initiallyUndefined?: boolean;
  initValue?: TCategory;
}

export const CategoryProvider: React.FC<CategoryProviderProps> = ({
  initiallyUndefined,
  initValue,
  children,
}) => {
  const [category, setCategory] = useState<TCategory | undefined>(
    initiallyUndefined ? undefined : initValue ?? CATEGORIES.TRADITIONAL,
  );

  const resetCategory = React.useCallback(() => setCategory(undefined), []);

  const value = React.useMemo(
    () => ({
      category,
      setCategory,
      resetCategory,
    }),
    [category, resetCategory],
  );

  return <CategoryContext.Provider value={value}>{children}</CategoryContext.Provider>;
};
