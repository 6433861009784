import styled from 'styled-components';

import { Carousel } from '../../../../components/Common/ResponsiveCarousel/js';
import { Icon } from '../../../../components/Common';

export { Carousel };

type WithLastSlide = {
  isLastSlide: boolean;
};

export const Container = styled.div`
  padding: 40px 0 40px 0;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
`;

export const CarouselContainer = styled('div')({
  position: 'relative',
  width: '90%',
  margin: '0 auto',
});

export const StyledCarousel = styled(Carousel)<WithLastSlide>(({ isLastSlide }) => ({
  '& .carousel.carousel-slider': {
    height: '100%',
    display: 'flex',
    overflow: 'visible',
  },
  '& .carousel .slider-wrapper': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '50px 0',
  },
  '& .carousel .slider-wrapper.axis-horizontal .slider': {
    alignItems: 'center',
  },
  '& .slide': {
    minWidth: '50% !important',
    left: isLastSlide ? '-25%' : '25%',
    background: 'transparent',
  },
  '& .slide.selected': {
    minWidth: '100% !important',
    left: isLastSlide ? '-75%' : '-25%',
    zIndex: 1,
    '&:after': {
      display: 'none',
    },
    '& > div': {
      width: '70%',
      margin: '0 auto',
    },
    '& + li': {
      left: isLastSlide ? '-125%' : '-75%',
    },
    '& + li > div > div': {
      flexDirection: 'row-reverse',
    },
  },
}));

export const Button = styled(Icon)({
  position: 'absolute',
  top: '50%',
  zIndex: 1,
  cursor: 'pointer',
});

export const PrevButton = styled(Button)({
  left: '7%',
});

export const NextButton = styled(Button)({
  right: '7%',
  transform: 'rotate(180deg) ',
});

export const SingleSlideItemWrapper = styled('div')({
  paddingTop: 50,
});

export const NoSlidesMessageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 410px)',
});
