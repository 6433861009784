import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')(() => ({
  fontFamily: useUiConfigFontsBody(),
  flex: 1,
  padding: '60px 5% 35px 5%',
  backgroundColor: '#fff',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const Wrapper = styled('div')({
  display: 'flex',
  flex: 1,
  height: '100%',
});

export const Text = styled('p')(({ theme }) => ({
  fontSize: 16,
  color: theme.colors.gray[400],
  marginBottom: 10,
  textAlign: 'left',
  letterSpacing: useUiConfigBodyLetterSpacing(),
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const TitlePdf = styled('h2')(({ theme }) => ({
  fontWeight: 900,
  textTransform: 'uppercase',
  fontSize: 26,
  marginTop: 52,
  color: useUiConfigColorsPrimary(),
  textAlign: 'left',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const ParagraphPdf = styled(Text)({
  color: '#000',
  textAlign: 'justify',
  marginBottom: 40,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const ContentWrapper = styled('div')({
  overflow: 'hidden',
  height: 805,
  margin: '0 auto',
});
