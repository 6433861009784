import { useState, useRef, useMemo } from 'react';

import styled from 'styled-components';

import { TSlide, TPresentationSessionSlide } from '../../../../../../../../../../types';

import { SlidePopup } from './SlidePopup/SlidePopup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SPACING_AND_PAGE_TEXT_LENGTH = 62;

type Props = {
  hash: string;
  page: number;
  slide: TSlide;
  value: number;
  maxValue: number;
  analytics: TPresentationSessionSlide;
};

export function Bar({ value, maxValue, page, slide, analytics, hash }: Props) {
  const [invertedColors, setInvertedColors] = useState(false);
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const valueBarRef = useRef<HTMLDivElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);

  const barElement = ref?.current?.getBoundingClientRect();
  const parentElement = document.getElementById('analytics-container')?.getBoundingClientRect();

  function shouldInvertTextColor() {
    const valueBarWidth = valueBarRef?.current?.getBoundingClientRect().width;
    const textContainerWidth = textContainerRef?.current?.getBoundingClientRect().width;
    if (!textContainerWidth || !valueBarWidth) return;

    if (valueBarWidth > textContainerWidth + SPACING_AND_PAGE_TEXT_LENGTH)
      return setInvertedColors(true);

    return setInvertedColors(false);
  }

  const isPopupHeaderDown = useMemo(() => {
    if (!parentElement || !barElement) return;
    const popupHeight = 270;
    return parentElement.height < barElement.bottom + popupHeight - parentElement.top;
  }, [parentElement, barElement]);

  const widthPercent = (value / maxValue) * 100;

  function onClick() {
    setOpen(true);
    shouldInvertTextColor();
  }

  function onClose() {
    setOpen(false);
    setInvertedColors(false);
  }

  return (
    <OuterContainer onClick={onClick} ref={ref}>
      <Background
        ref={valueBarRef}
        open={open}
        style={{
          width: `${widthPercent}%`,
        }}
      >
        <PageNumberText invertTextColor={invertedColors}>
          PAGE {page < 10 ? `0${page}` : page}
        </PageNumberText>
        <SlideNameText ref={textContainerRef} invertTextColor={invertedColors}>
          {slide.label}
          {slide.isInteractive ? (
            <IconWrapper invertTextColor={invertedColors}>
              <Icon invertTextColor={invertedColors} icon='hand-pointer' />
            </IconWrapper>
          ) : null}
        </SlideNameText>
      </Background>
      {open && (
        <SlidePopup
          hash={hash}
          slide={slide}
          pageVisits={analytics?.views ?? 0}
          timeOnPage={analytics?.duration ?? 0}
          pageInteractions={analytics?.interactions ?? 0}
          onClose={onClose}
          cursorTrackingData={analytics?.data}
          isHeaderDown={isPopupHeaderDown}
        />
      )}
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  cursor: pointer;
`;
const Background = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  word-break: keep-all;
  overflow: visible;
  background-color: ${({ theme, open }) =>
    open ? theme.colors.v2.primary : theme.colors.v2.gray[200]};

  height: 100%;
  padding: 6px 0px 6px 10px;
  align-items: center;
  gap: 10px;
`;

const PageNumberText = styled.div.withConfig({
  shouldForwardProp: prop => !['invertTextColor'].includes(prop),
})<{ invertTextColor: boolean }>`
  display: flex;
  width: 42px;
  flex-shrink: 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme, invertTextColor }) =>
    invertTextColor ? theme.colors.v2.text.contrastText : theme.colors.v2.text.regular};
`;

const SlideNameText = styled.div.withConfig({
  shouldForwardProp: prop => !['invertTextColor'].includes(prop),
})<{ invertTextColor: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
  gap: 10px;
  color: ${({ theme, invertTextColor }) =>
    invertTextColor ? theme.colors.v2.text.contrastText : theme.colors.v2.text.regular};
`;

const IconWrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['invertTextColor'].includes(prop),
})<{ invertTextColor: boolean }>`
  display: flex;
  width: 16px;
  height: 16px;
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  border-radius: 100px;
  background-color: ${({ theme, invertTextColor }) =>
    invertTextColor ? theme.colors.v2.background : theme.colors.v2.primary};
`;

const Icon = styled(FontAwesomeIcon).withConfig({
  shouldForwardProp: prop => !['invertTextColor'].includes(prop),
})<{ invertTextColor: boolean }>`
  color: ${({ theme, invertTextColor }) =>
    invertTextColor ? theme.colors.v2.primary : theme.colors.v2.text.contrastText};
`;
