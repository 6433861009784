import styled from 'styled-components';
import { Button } from '../../../../../components/Common';

export { Button };

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  flex: 1 0 0;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  min-height: 200px;
`;

export const Wrapper = styled(Column)<{ hasMargin?: boolean }>`
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-bottom: ${({ theme, hasMargin }) =>
    hasMargin ? `1px solid  ${theme.colors.v2.gray[200]}` : 'none'};
`;

export const ModalBody = styled(Column)(({ theme }) => ({
  height: '100%',
  maxHeight: 'calc(100vh - 166px)', // 166px header + header info
  width: '100%',
  overflowY: 'auto',
  padding: '20px',
  backgroundColor: '#fff',
  background: `${theme.colors.v2.gradient.background}`,
}));

export const ModalFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  flexShrink: 0,
  padding: '12px 10px',
  backgroundColor: '#fff',
  borderTop: `1px solid ${theme.colors.v2.gray[200]}`,
}));

export const ModalContent = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  overflowY: 'auto',
  borderRadius: '10px',
  border: `1px solid ${theme.colors.v2.gray[200]}`,
  padding: '20px',
  justifyContent: 'space-between',
  boxShadow: '2px 4px 6px 0px rgba(0, 0, 0, 0.07)',
  backgroundColor: `${theme.colors.v2.background}`,
}));

export const ModalContentLeft = styled(Column)({
  width: 'calc(100% - 434px)',
});

export const SlideWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.05);
`;

export const ModalContentRight = styled(Column)({
  width: 394,
  flexShrink: 0,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

export const ModalContentLeftWrapper = styled('div')({
  display: 'flex',
  boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.25)',
});
