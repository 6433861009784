export default `<svg width="490" height="563" viewBox="0 0 490 563" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_346_5404)">
<path d="M169.46 207.745L100 175H240L170.54 207.745C170.198 207.907 169.802 207.907 169.46 207.745Z" fill="white"/>
<path d="M100 54H240V175H100V54Z" fill="white"/>
<path d="M169.46 21.2546L100 54H240L170.54 21.2546C170.198 21.0934 169.802 21.0934 169.46 21.2546Z" fill="white"/>
<mask id="path-6-outside-1_346_5404" maskUnits="userSpaceOnUse" x="99" y="20.1337" width="142" height="189" fill="black">
<rect fill="white" x="99" y="20.1337" width="142" height="189"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M169.46 21.2546L100 54V175L169.46 207.745C169.802 207.907 170.198 207.907 170.54 207.745L240 175V54L170.54 21.2546C170.198 21.0934 169.802 21.0934 169.46 21.2546Z"/>
</mask>
<path d="M100 54L99.5736 53.0954L99 53.3658V54H100ZM169.46 21.2546L169.033 20.3501L169.033 20.3501L169.46 21.2546ZM100 175H99V175.634L99.5736 175.904L100 175ZM169.46 207.745L169.886 206.841H169.886L169.46 207.745ZM170.54 207.745L170.114 206.841H170.114L170.54 207.745ZM240 175L240.426 175.904L241 175.634V175H240ZM240 54H241V53.3658L240.426 53.0954L240 54ZM170.54 21.2546L170.967 20.3501L170.967 20.3501L170.54 21.2546ZM100.426 54.9045L169.886 22.1591L169.033 20.3501L99.5736 53.0954L100.426 54.9045ZM101 175V54H99V175H101ZM99.5736 175.904L169.033 208.65L169.886 206.841L100.426 174.095L99.5736 175.904ZM169.033 208.65C169.646 208.938 170.354 208.938 170.967 208.65L170.114 206.841C170.042 206.875 169.958 206.875 169.886 206.841L169.033 208.65ZM170.967 208.65L240.426 175.904L239.574 174.095L170.114 206.841L170.967 208.65ZM239 54V175H241V54H239ZM170.114 22.1591L239.574 54.9045L240.426 53.0954L170.967 20.3501L170.114 22.1591ZM169.886 22.1591C169.958 22.1252 170.042 22.1252 170.114 22.1591L170.967 20.3501C170.354 20.0615 169.646 20.0615 169.033 20.3501L169.886 22.1591Z" fill="#027B76" mask="url(#path-6-outside-1_346_5404)"/>
<path d="M319.46 207.745L250 175H390L320.54 207.745C320.198 207.907 319.802 207.907 319.46 207.745Z" fill="white"/>
<path d="M250 54H390V175H250V54Z" fill="white"/>
<path d="M319.46 21.2546L250 54H390L320.54 21.2546C320.198 21.0934 319.802 21.0934 319.46 21.2546Z" fill="white"/>
<mask id="path-13-outside-2_346_5404" maskUnits="userSpaceOnUse" x="249" y="20.1337" width="142" height="189" fill="black">
<rect fill="white" x="249" y="20.1337" width="142" height="189"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M319.46 21.2546L250 54V175L319.46 207.745C319.802 207.907 320.198 207.907 320.54 207.745L390 175V54L320.54 21.2546C320.198 21.0934 319.802 21.0934 319.46 21.2546Z"/>
</mask>
<path d="M250 54L249.574 53.0954L249 53.3658V54H250ZM319.46 21.2546L319.033 20.3501L319.033 20.3501L319.46 21.2546ZM250 175H249V175.634L249.574 175.904L250 175ZM319.46 207.745L319.886 206.841H319.886L319.46 207.745ZM320.54 207.745L320.114 206.841H320.114L320.54 207.745ZM390 175L390.426 175.904L391 175.634V175H390ZM390 54H391V53.3658L390.426 53.0954L390 54ZM320.54 21.2546L320.967 20.3501L320.967 20.3501L320.54 21.2546ZM250.426 54.9045L319.886 22.1591L319.033 20.3501L249.574 53.0954L250.426 54.9045ZM251 175V54H249V175H251ZM249.574 175.904L319.033 208.65L319.886 206.841L250.426 174.095L249.574 175.904ZM319.033 208.65C319.646 208.938 320.354 208.938 320.967 208.65L320.114 206.841C320.042 206.875 319.958 206.875 319.886 206.841L319.033 208.65ZM320.967 208.65L390.426 175.904L389.574 174.095L320.114 206.841L320.967 208.65ZM389 54V175H391V54H389ZM320.114 22.1591L389.574 54.9045L390.426 53.0954L320.967 20.3501L320.114 22.1591ZM319.886 22.1591C319.958 22.1252 320.042 22.1252 320.114 22.1591L320.967 20.3501C320.354 20.0615 319.646 20.0615 319.033 20.3501L319.886 22.1591Z" fill="#027B76" mask="url(#path-13-outside-2_346_5404)"/>
<path d="M94.4598 370.745L25 338H165L95.5402 370.745C95.1981 370.907 94.8019 370.907 94.4598 370.745Z" fill="white"/>
<path d="M25 217H165V338H25V217Z" fill="white"/>
<path d="M94.4598 184.255L25 217H165L95.5402 184.255C95.1981 184.093 94.8019 184.093 94.4598 184.255Z" fill="white"/>
<mask id="path-20-outside-3_346_5404" maskUnits="userSpaceOnUse" x="24" y="183.134" width="142" height="189" fill="black">
<rect fill="white" x="24" y="183.134" width="142" height="189"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M94.4598 184.255L25 217V338L94.4598 370.745C94.8019 370.907 95.1981 370.907 95.5402 370.745L165 338V217L95.5402 184.255C95.1981 184.093 94.8019 184.093 94.4598 184.255Z"/>
</mask>
<path d="M25 217L24.5736 216.095L24 216.366V217H25ZM94.4598 184.255L94.0334 183.35L94.0334 183.35L94.4598 184.255ZM25 338H24V338.634L24.5736 338.904L25 338ZM94.4598 370.745L94.8863 369.841H94.8863L94.4598 370.745ZM95.5402 370.745L95.1137 369.841H95.1137L95.5402 370.745ZM165 338L165.426 338.904L166 338.634V338H165ZM165 217H166V216.366L165.426 216.095L165 217ZM95.5402 184.255L95.9666 183.35L95.9666 183.35L95.5402 184.255ZM25.4264 217.904L94.8863 185.159L94.0334 183.35L24.5736 216.095L25.4264 217.904ZM26 338V217H24V338H26ZM24.5736 338.904L94.0334 371.65L94.8863 369.841L25.4264 337.095L24.5736 338.904ZM94.0334 371.65C94.6455 371.938 95.3545 371.938 95.9666 371.65L95.1137 369.841C95.0417 369.875 94.9583 369.875 94.8863 369.841L94.0334 371.65ZM95.9666 371.65L165.426 338.904L164.574 337.095L95.1137 369.841L95.9666 371.65ZM164 217V338H166V217H164ZM95.1137 185.159L164.574 217.904L165.426 216.095L95.9666 183.35L95.1137 185.159ZM94.8863 185.159C94.9583 185.125 95.0417 185.125 95.1137 185.159L95.9666 183.35C95.3545 183.062 94.6455 183.062 94.0334 183.35L94.8863 185.159Z" fill="#027B76" mask="url(#path-20-outside-3_346_5404)"/>
<path d="M244.46 370.745L175 338H315L245.54 370.745C245.198 370.907 244.802 370.907 244.46 370.745Z" fill="white"/>
<path d="M175 217H315V338H175V217Z" fill="white"/>
<path d="M244.46 184.255L175 217H315L245.54 184.255C245.198 184.093 244.802 184.093 244.46 184.255Z" fill="white"/>
<mask id="path-27-outside-4_346_5404" maskUnits="userSpaceOnUse" x="174" y="183.134" width="142" height="189" fill="black">
<rect fill="white" x="174" y="183.134" width="142" height="189"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M244.46 184.255L175 217V338L244.46 370.745C244.802 370.907 245.198 370.907 245.54 370.745L315 338V217L245.54 184.255C245.198 184.093 244.802 184.093 244.46 184.255Z"/>
</mask>
<path d="M175 217L174.574 216.095L174 216.366V217H175ZM244.46 184.255L244.033 183.35L244.033 183.35L244.46 184.255ZM175 338H174V338.634L174.574 338.904L175 338ZM244.46 370.745L244.886 369.841H244.886L244.46 370.745ZM245.54 370.745L245.114 369.841H245.114L245.54 370.745ZM315 338L315.426 338.904L316 338.634V338H315ZM315 217H316V216.366L315.426 216.095L315 217ZM245.54 184.255L245.967 183.35L245.967 183.35L245.54 184.255ZM175.426 217.904L244.886 185.159L244.033 183.35L174.574 216.095L175.426 217.904ZM176 338V217H174V338H176ZM174.574 338.904L244.033 371.65L244.886 369.841L175.426 337.095L174.574 338.904ZM244.033 371.65C244.646 371.938 245.354 371.938 245.967 371.65L245.114 369.841C245.042 369.875 244.958 369.875 244.886 369.841L244.033 371.65ZM245.967 371.65L315.426 338.904L314.574 337.095L245.114 369.841L245.967 371.65ZM314 217V338H316V217H314ZM245.114 185.159L314.574 217.904L315.426 216.095L245.967 183.35L245.114 185.159ZM244.886 185.159C244.958 185.125 245.042 185.125 245.114 185.159L245.967 183.35C245.354 183.062 244.646 183.062 244.033 183.35L244.886 185.159Z" fill="#027B76" mask="url(#path-27-outside-4_346_5404)"/>
<path d="M394.46 370.745L325 338H465L395.54 370.745C395.198 370.907 394.802 370.907 394.46 370.745Z" fill="white"/>
<path d="M325 217H465V338H325V217Z" fill="white"/>
<path d="M394.46 184.255L325 217H465L395.54 184.255C395.198 184.093 394.802 184.093 394.46 184.255Z" fill="white"/>
<mask id="path-34-outside-5_346_5404" maskUnits="userSpaceOnUse" x="324" y="183.134" width="142" height="189" fill="black">
<rect fill="white" x="324" y="183.134" width="142" height="189"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M394.46 184.255L325 217V338L394.46 370.745C394.802 370.907 395.198 370.907 395.54 370.745L465 338V217L395.54 184.255C395.198 184.093 394.802 184.093 394.46 184.255Z"/>
</mask>
<path d="M325 217L324.574 216.095L324 216.366V217H325ZM394.46 184.255L394.033 183.35L394.033 183.35L394.46 184.255ZM325 338H324V338.634L324.574 338.904L325 338ZM394.46 370.745L394.886 369.841H394.886L394.46 370.745ZM395.54 370.745L395.114 369.841H395.114L395.54 370.745ZM465 338L465.426 338.904L466 338.634V338H465ZM465 217H466V216.366L465.426 216.095L465 217ZM395.54 184.255L395.967 183.35L395.967 183.35L395.54 184.255ZM325.426 217.904L394.886 185.159L394.033 183.35L324.574 216.095L325.426 217.904ZM326 338V217H324V338H326ZM324.574 338.904L394.033 371.65L394.886 369.841L325.426 337.095L324.574 338.904ZM394.033 371.65C394.646 371.938 395.354 371.938 395.967 371.65L395.114 369.841C395.042 369.875 394.958 369.875 394.886 369.841L394.033 371.65ZM395.967 371.65L465.426 338.904L464.574 337.095L395.114 369.841L395.967 371.65ZM464 217V338H466V217H464ZM395.114 185.159L464.574 217.904L465.426 216.095L395.967 183.35L395.114 185.159ZM394.886 185.159C394.958 185.125 395.042 185.125 395.114 185.159L395.967 183.35C395.354 183.062 394.646 183.062 394.033 183.35L394.886 185.159Z" fill="#027B76" mask="url(#path-34-outside-5_346_5404)"/>
<path d="M169.46 533.745L100 501H240L170.54 533.745C170.198 533.907 169.802 533.907 169.46 533.745Z" fill="white"/>
<path d="M100 380H240V501H100V380Z" fill="white"/>
<path d="M169.46 347.255L100 380H240L170.54 347.255C170.198 347.093 169.802 347.093 169.46 347.255Z" fill="white"/>
<mask id="path-41-outside-6_346_5404" maskUnits="userSpaceOnUse" x="99" y="346.134" width="142" height="189" fill="black">
<rect fill="white" x="99" y="346.134" width="142" height="189"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M169.46 347.255L100 380V501L169.46 533.745C169.802 533.907 170.198 533.907 170.54 533.745L240 501V380L170.54 347.255C170.198 347.093 169.802 347.093 169.46 347.255Z"/>
</mask>
<path d="M100 380L99.5736 379.095L99 379.366V380H100ZM169.46 347.255L169.033 346.35L169.033 346.35L169.46 347.255ZM100 501H99V501.634L99.5736 501.905L100 501ZM169.46 533.745L169.886 532.841H169.886L169.46 533.745ZM170.54 533.745L170.114 532.841H170.114L170.54 533.745ZM240 501L240.426 501.905L241 501.634V501H240ZM240 380H241V379.366L240.426 379.095L240 380ZM170.54 347.255L170.967 346.35L170.967 346.35L170.54 347.255ZM100.426 380.905L169.886 348.159L169.033 346.35L99.5736 379.095L100.426 380.905ZM101 501V380H99V501H101ZM99.5736 501.905L169.033 534.65L169.886 532.841L100.426 500.096L99.5736 501.905ZM169.033 534.65C169.646 534.938 170.354 534.938 170.967 534.65L170.114 532.841C170.042 532.875 169.958 532.875 169.886 532.841L169.033 534.65ZM170.967 534.65L240.426 501.905L239.574 500.096L170.114 532.841L170.967 534.65ZM239 380V501H241V380H239ZM170.114 348.159L239.574 380.905L240.426 379.095L170.967 346.35L170.114 348.159ZM169.886 348.159C169.958 348.125 170.042 348.125 170.114 348.159L170.967 346.35C170.354 346.062 169.646 346.062 169.033 346.35L169.886 348.159Z" fill="#027B76" mask="url(#path-41-outside-6_346_5404)"/>
<path d="M319.46 533.745L250 501H390L320.54 533.745C320.198 533.907 319.802 533.907 319.46 533.745Z" fill="white"/>
<path d="M250 380H390V501H250V380Z" fill="white"/>
<path d="M319.46 347.255L250 380H390L320.54 347.255C320.198 347.093 319.802 347.093 319.46 347.255Z" fill="white"/>
<mask id="path-48-outside-7_346_5404" maskUnits="userSpaceOnUse" x="249" y="346.134" width="142" height="189" fill="black">
<rect fill="white" x="249" y="346.134" width="142" height="189"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M319.46 347.255L250 380V501L319.46 533.745C319.802 533.907 320.198 533.907 320.54 533.745L390 501V380L320.54 347.255C320.198 347.093 319.802 347.093 319.46 347.255Z"/>
</mask>
<path d="M250 380L249.574 379.095L249 379.366V380H250ZM319.46 347.255L319.033 346.35L319.033 346.35L319.46 347.255ZM250 501H249V501.634L249.574 501.905L250 501ZM319.46 533.745L319.886 532.841H319.886L319.46 533.745ZM320.54 533.745L320.114 532.841H320.114L320.54 533.745ZM390 501L390.426 501.905L391 501.634V501H390ZM390 380H391V379.366L390.426 379.095L390 380ZM320.54 347.255L320.967 346.35L320.967 346.35L320.54 347.255ZM250.426 380.905L319.886 348.159L319.033 346.35L249.574 379.095L250.426 380.905ZM251 501V380H249V501H251ZM249.574 501.905L319.033 534.65L319.886 532.841L250.426 500.096L249.574 501.905ZM319.033 534.65C319.646 534.938 320.354 534.938 320.967 534.65L320.114 532.841C320.042 532.875 319.958 532.875 319.886 532.841L319.033 534.65ZM320.967 534.65L390.426 501.905L389.574 500.096L320.114 532.841L320.967 534.65ZM389 380V501H391V380H389ZM320.114 348.159L389.574 380.905L390.426 379.095L320.967 346.35L320.114 348.159ZM319.886 348.159C319.958 348.125 320.042 348.125 320.114 348.159L320.967 346.35C320.354 346.062 319.646 346.062 319.033 346.35L319.886 348.159Z" fill="#027B76" mask="url(#path-48-outside-7_346_5404)"/>
</g>
<defs>
<filter id="filter0_d_346_5404" x="0" y="0.133667" width="490" height="562.733" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_346_5404"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_346_5404" result="shape"/>
</filter>
</defs>
</svg>`;
