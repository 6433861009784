import profit_img from './web/modern/icons/profit.png';
import efficient_img from './web/modern/icons/efficient.png';
import effective_img from './web/modern/icons/effective.png';
import trusted_img from './web/modern/icons/trusted.png';
import experience_img from './web/modern/icons/experience.png';

export const slideContent = [
  {
    title: 'VALUE OF AN AGENT',
    text: 'According to a Zillow 2019 study, more than a third of Americans cry while selling their home.',
    icon: undefined,
  },
  {
    title: 'PROFIT',
    text: 'The typical FSBO home sold for, on average, 40% less than agent-assisted home sales.',
    icon: profit_img,
  },
  {
    title: 'EFFICIENT',
    text: 'On average, FSBO homes take 19 days longer to sell than homes listed with an agent.',
    icon: efficient_img,
  },
  {
    title: 'EFFECTIVE',
    text: '36% of sellers attempted a FSBO sale before turning to an agent.',
    icon: effective_img,
  },
  {
    title: 'TRUSTED',
    text: '92% of homes in the US are sold using an agent or a broker.',
    icon: trusted_img,
  },
  {
    title: 'EXPERIENCE',
    text: '90% of buyers would use their agent again or recommend their agent to others.',
    icon: experience_img,
  },
];

export const alternativeSlideContent = {
  title: 'Value of an agent',
  paragraphs: [
    `Buying a home was likely one of your largest investments. As such, the management and decisions made regarding your largest asset shouldn't be made lightly.`,
    `Just as the heart is your body's largest asset, you wouldn't trust open heart surgery to just anyone. While the pillars of the home selling process are fairly consistent, it is hardly ever a straight line. An experienced and dedicated Agent can help guide you through the twists and turns, that will inevitably arise, allowing you to make confident decisions every step of the way.`,
  ],
};

export const getDescription = (isCanadaUser: boolean) => {
  return isCanadaUser
    ? alternativeSlideContent.paragraphs
    : ['Five top reasons to work with a real estate agent for the sale of your home.'];
};
