import { toast } from 'react-toastify';
import { http } from '../services/httpService';
import { StorageService } from '../services/storageService';
import { AuthApi } from './AuthApi';

class CorePresentApi {
  addAcceptJsonHeaderAndToken(options: any) {
    const token = StorageService.read('token');

    const opt = {
      ...options,
      headers: {
        ...options.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (!token) {
      return opt;
    }
    return {
      ...options,
      headers: {
        ...options.headers,
        Authorization: 'Bearer ' + token,
      },
    };
  }

  buildRejectedPromise(error: any) {
    const response = error.response ? error.response.data.data : {};

    const message = (response && response.message) || 'Communication Error';
    const errors = response && response.errors ? { errors: response.errors } : {};

    if (error.response?.status === 401) {
      if (!AuthApi.isLoggedIn()) return;

      const timeout = setTimeout(() => {
        window.location.reload();
        clearTimeout(timeout);
      }, 1500);
      // toast('Your token has expired, please login again', {
      //   type: 'error',
      //   position: 'bottom-center',
      // });
      localStorage.clear();
      return;
    }

    return Promise.reject({
      status: error.response?.status,
      message,
      ...errors,
    });
  }

  get(url: string, options = {}, params = {}): Promise<any> {
    const combinedOptions = this.addAcceptJsonHeaderAndToken(options);

    return http
      .get(url, combinedOptions, params)
      .then(res => res.data)
      .catch(error => this.buildRejectedPromise(error));
  }

  fileDownload(url: string, options = {}, params = {}): Promise<any> {
    const combinedOptions = this.addAcceptJsonHeaderAndToken(options);

    return http
      .get(url, combinedOptions, params)
      .then(res => res)
      .catch(error => this.buildRejectedPromise(error));
  }

  post(url: string, data = {}, options = {}, params = {}): Promise<any> {
    const combinedOptions = this.addAcceptJsonHeaderAndToken({
      ...options,
      data,
    });

    return http
      .post(url, combinedOptions, params)
      .then(res => res.data)
      .catch(error => this.buildRejectedPromise(error));
  }

  put(url: string, data = {}, options = {}, params = {}): Promise<any> {
    const combinedOptions = this.addAcceptJsonHeaderAndToken({
      ...options,
      data,
    });

    return http
      .put(url, combinedOptions, params)
      .then(res => res.data)
      .catch(error => this.buildRejectedPromise(error));
  }

  delete(url: string, data = {}, options = {}, params = {}): Promise<any> {
    const combinedOptions = this.addAcceptJsonHeaderAndToken({
      ...options,
      data,
    });

    return http
      .delete(url, combinedOptions, params)
      .then(res => res.data)
      .catch(error => this.buildRejectedPromise(error));
  }
}

export const corePresentApi = new CorePresentApi();
