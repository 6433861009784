import { TPresentation, TSuperPresentation } from '../../../types';
import { useOrder } from '../../../providers/providers/OrderProvider';
import { useSlides } from '../../../providers/providers/SlidesProvider';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { useCategory } from '../../../providers/providers/CategoryProvider';
import { useSections } from '../../../providers/providers/SectionsProvider';
import { useUiConfigs } from '../../../providers/providers/UiConfigProvider';
import { PresentationEditActions } from '../../../components/presentation/PresentationEditActions';

type Props = {
  presentation: TPresentation;
};

export const WTREditActions: React.FC<Props> = ({ presentation }) => {
  const user = useUser();
  const ui = useUiConfigs();
  const { slides } = useSlides();
  const { sections } = useSections();
  const { category } = useCategory();
  const { sections: sectionsOrder, sectionsSlide } = useOrder();

  const updatedPresentation = {
    ...presentation,
    ui,
    category,
    slidesMap: slides,
    regular: {
      sections,
      order: { sections: sectionsOrder, sectionsSlide },
    },
  };

  return (
    <PresentationEditActions
      isFetching={!presentation || !user}
      presentation={updatedPresentation as TSuperPresentation}
    />
  );
};
