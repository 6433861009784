import React from 'react';
import { CSSObject } from 'styled-components';
import { ButtonWrapper, Input, InputWrapper } from './EditInput.styles';

import { Button } from '../../../../../../../Common';

interface EditInputProps {
  inputStyles?: CSSObject;
  inputValue: string;
  onChange: (val: string) => void;
  onApplyClick: () => void;
  width?: string;
}

export const EditInput: React.FC<EditInputProps> = ({
  inputStyles,
  inputValue,
  onChange,
  onApplyClick,
  width,
}) => {
  return (
    <InputWrapper style={{ width: width ?? '50%' }}>
      <Input style={inputStyles} value={inputValue} onChange={e => onChange(e.target.value)} />
      <ButtonWrapper>
        <Button btnstyle='primary' size='large' style={{ fontSize: 20 }} onClick={onApplyClick}>
          Apply
        </Button>
      </ButtonWrapper>
    </InputWrapper>
  );
};
