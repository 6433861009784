import { toast } from 'react-toastify';
import classes from './styles.module.scss';
import { mapColorFromStatus } from '../../../../../../../config/constants';
import { MoneyFormatter } from '../../../../../../../services/moneyFormatter';
import { NumberFormatter } from '../../../../../../../services/numberFormatter';
import { getPropertyStatus } from '../../../../../../services/getPropertyStatus';
import { CurrentUserService } from '../../../../../../../../../../services/CurrentUserService';
import { getPropertyId } from '../../../../../../../../../../features/report/services/getPropertyId';
import { getMapPinBasedOnStatus } from '../../../../../../../../../../services/getMapPinBasedOnStatus';
import pinSubject from '../../../../../../../../../../assets/icons/map/pin-subject.svg?url';

export class MapHelpers {
  theme = null;
  map = null;
  google = null;
  infoWindow = null;
  drawingManager = null;
  subjectMarker = null;
  compMarkers = [];
  oms = null;

  polygon = null;
  circle = null;
  bounds = null;

  constructor(theme, map, google, oms, hasBathCountFF) {
    this.theme = theme;
    this.map = map;
    this.google = google;
    this.oms = oms;
    this.infoWindow = new google.maps.InfoWindow();
    this.drawingManager = this.createDrawingManager();
    this.hasBathCountFF = hasBathCountFF;
  }

  // ================== GETTERS ======================= //
  getDrawingManager() {
    return this.drawingManager;
  }

  getCircle() {
    return this.circle;
  }

  getMap() {
    return this.circle;
  }

  getSubjectMarker() {
    return this.subjectMarker;
  }

  getInfoWindow() {
    return this.infoWindow;
  }

  createSubjectMarker = position => {
    this.subjectMarker = new window.google.maps.Marker({
      position,
      map: this.map,
      icon: {
        url: pinSubject,
      },
    });

    return this.subjectMarker;
  };

  createCompMarker = (property, subjectSize, isReadonly = false, isBoldTrailTheme = false) => {
    const status = getPropertyStatus(property.status);

    const icon = getMapPinBasedOnStatus({
      status,
      isGray: property.partialMatch || property.excluded,
      hasBtTheme: isBoldTrailTheme,
    });

    const marker = new window.google.maps.Marker({
      position: {
        lat: Number(property.coordinates.latitude),
        lng: Number(property.coordinates.longitude),
      },
      map: this.map,
      icon: {
        url: icon,
      },
    });
    this.compMarkers.push(marker);
    this.addLabelToCompMarker(marker, property, subjectSize, isReadonly);
    this.oms?.addMarker?.(marker);

    return marker;
  };

  addLabelToCompMarker = (marker, property, subjectSize, isReadonly = false) => {
    const status = property.status;
    const s = getPropertyStatus(status);
    const adjustedPrice = property?.adjustedPrice
      ? MoneyFormatter.format(property.adjustedPrice)
      : null;

    const color = mapColorFromStatus(status);
    const clickEvent =
      property.partialMatch || property.excluded
        ? `document.dispatchEvent(new CustomEvent('add-comp', {detail: '${getPropertyId(
            property,
          )}'}))`
        : `document.dispatchEvent(new CustomEvent('remove-comp', {detail: '${getPropertyId(
            property,
          )}'}))`;
    let percentDiff = (property.size / subjectSize) * 100;
    if (percentDiff > 100) {
      percentDiff = Math.round(percentDiff - 100) + '% larger';
    } else {
      percentDiff = Math.round(100 - percentDiff) + '% smaller';
    }

    marker.addListener('click', () => {
      this.infoWindow.setContent(
        `
        <div>
          <div style="color: ${color};font-weight: bold">
                <img style="max-height: 15px" src="../../../../../../../../../../assets/images/map-pointer-${s}.png" alt="" />
                ${property.address.deliveryLine}
           </div>
          <hr>
          <div class="${classes.address}">${
            status === 'sold'
              ? 'Sale price: ' + MoneyFormatter.format(property.salePrice)
              : 'List price: ' + MoneyFormatter.format(property.price)
          } <br>
          <div style="${adjustedPrice ? 'display: block;' : 'display: none;'}">
          ${
            status === 'sold'
              ? 'ADJ Sale Price: ' + adjustedPrice
              : 'ADJ List Price: ' + adjustedPrice
          }
          </div>
${
  status === 'sold'
    ? 'Sale date: ' + property.saleDateHuman
    : 'List date: ' + property.listDateHuman
} <br>
${property.beds} Beds ${
          this.hasBathCountFF ? `| ${property.totalBaths} Total Baths <br />` : '<br />'
        }
        ${
          !CurrentUserService.isCanadaUser()
            ? NumberFormatter.format(property.size) + ' sqft (' + percentDiff + ')'
            : ''
        }
        </div>
          ${
            isReadonly
              ? ''
              : `<button
            id="infoWindow${getPropertyId(property)}"
            class="${classes.infoWindowButton}"
            onclick="${clickEvent}"
          >${
            property.partialMatch || property.excluded ? 'Add to comps' : 'Remove from comps'
          }</button>
          <button
            id="infoWindow${getPropertyId(property)}"
            class="${classes.infoWindowButton}"
            onclick="document.dispatchEvent(new CustomEvent('more-info', {detail: {id: '${getPropertyId(
              property,
            )}', status: '${property.status}'}}))"
          >More Info</button>
          `
          }`,
      );
      this.infoWindow.open(this.map, marker);
    });
  };
  clearMarkers = () => {
    if (!this.compMarkers) {
      return;
    }
    this.compMarkers.forEach(marker => {
      marker.setMap(null);
    });
    this.compMarkers = [];
  };

  setMarkersFromComps = (comps, subjectSize, isReadonly = false, isBoldTrailTheme = false) => {
    this.clearMarkers();

    return [...(comps?.comingSoon ?? []), ...comps.active, ...comps.pending, ...comps.sold].map(
      property => {
        return this.createCompMarker(property, subjectSize, isReadonly, isBoldTrailTheme);
      },
    );
  };

  clearPolygon = () => {
    if (!this.polygon) {
      return;
    }

    this.polygon.setMap(null);
    this.polygon = null;
  };

  clearCircle = () => {
    if (!this.circle) {
      return;
    }

    this.circle.setMap(null);
    this.circle = null;
  };

  setCircle = circle => {
    if (this.circle) {
      this.circle.setMap(null);
    }

    this.circle = circle;
  };

  setPolygon = shape => {
    this.clearPolygon();
    this.polygon = shape;
  };

  createPolygon = (paths, color) =>
    new window.google.maps.Polygon({
      paths,
      map: this.map,
      strokeColor: color || this.theme.colors.v2.primary,
      strokeOpacity: 1.0,
      strokeWeight: 2,
      fillColor: color || this.theme.colors.v2.primary,
      fillOpacity: 0.2,
    });

  createCircle = (position, radius, color) =>
    new window.google.maps.Circle({
      strokeColor: color || this.theme.colors.v2.primary,
      strokeOpacity: 1.0,
      strokeWeight: 2,
      fillColor: color || this.theme.colors.v2.primary,
      fillOpacity: 0.2,
      map: this.map,
      center: position,
      radius: radius * 1000 * 1.609,
    });

  createDrawingManager = () => {
    if (!this.google.maps.drawing?.DrawingManager) {
      const errorMessage = 'Error while loading Google Map';

      console.info(errorMessage);
      toast(errorMessage);

      return null;
    }

    const drawingManager = new this.google.maps.drawing.DrawingManager({
      drawingMode: this.google.maps.drawing.OverlayType.MARKER,
      drawingControl: true,
      drawingControlOptions: {
        position: this.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          this.google.maps.drawing.OverlayType.MARKER,
          this.google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      polygonOptions: {
        strokeColor: this.theme.colors.v2.primary,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        fillColor: this.theme.colors.v2.primary,
        fillOpacity: 0.2,
      },
    });

    return drawingManager;
  };

  setDrawingMode = mode => {
    this.drawingManager?.setDrawingMode(mode);
  };

  setDrawingManagerMap = () => {
    this.drawingManager?.setMap(this.map);
  };

  setMapOptionsDuringLoading = isLoading => {
    if (this.map) {
      this.map.setOptions({ disableDefaultUI: isLoading });
      this.drawingManager?.setMap(isLoading ? null : this.map);
    }
  };

  drawCircle = (center, radius, color) => {
    const circle = this.createCircle(center, radius, color);
    this.setCircle(circle);
  };

  extendBoundsToFitComparables = defaultCenter => {
    const bounds = new google.maps.LatLngBounds();
    const subjectMarker = this.createSubjectMarker(defaultCenter);

    const isAllInShape = this.compMarkers.every(marker => this.isInShape(marker));

    if (isAllInShape) return;

    [subjectMarker, ...this.compMarkers].forEach(marker => {
      bounds.extend(marker.getPosition());
    });
    this.map.fitBounds(bounds);
  };

  extendBoundsToCircle = () => {
    const bounds = this.circle.getBounds();

    this.map.fitBounds(bounds);
  };

  extendBoundsToPolygon = () => {
    if (!this.polygon) return;
    const paths = this.polygon.getPath();
    let points = [];
    let bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < paths.length; i++) {
      points = new google.maps.LatLng(paths.getAt(i).lat(), paths.getAt(i).lng());
      bounds.extend(points);
    }
    this.map.fitBounds(bounds);
  };

  drawPolygon = (paths, color) => {
    if (typeof paths !== 'string') return;
    const polygon = this.createPolygon(
      JSON.parse(paths).coordinates[0].map(latLng => ({
        lat: latLng[1],
        lng: latLng[0],
      })),
      color,
    );
    this.setPolygon(polygon);
  };

  isInCircle = marker => {
    const markerPosition = marker.getPosition();
    if (!markerPosition) return false;

    return this.circle
      ?.getBounds()
      .contains({ lat: markerPosition.lat(), lng: markerPosition.lng() });
  };

  isInPolygon = marker => {
    const markerPosition = marker.getPosition();
    if (!markerPosition) return false;

    return (
      this.polygon &&
      google.maps?.geometry?.poly?.containsLocation(
        { lat: markerPosition.lat(), lng: markerPosition.lng() },
        this.polygon,
      )
    );
  };

  isInShape = marker => {
    return this.circle ? this.isInCircle(marker) : this.isInPolygon(marker);
  };

  zoomOutToFitAllMarkersWithShape = (markers, defaultCenter) => {
    const subjectMarker = this.createSubjectMarker(defaultCenter);

    let bounds = new google.maps.LatLngBounds();

    const isAllIn = markers.every(marker => this.isInShape(marker));

    if (!isAllIn) {
      [...markers, subjectMarker].forEach(marker => {
        bounds.extend(marker.getPosition());
      });

      this.map.fitBounds(bounds);
    } else {
      this.circle ? this.extendBoundsToCircle() : this.extendBoundsToPolygon();
    }
  };

  zoomOutToFitAllMarkers = (markers, defaultCenter) => {
    let bounds = new google.maps.LatLngBounds();
    const subjectMarker = this.createSubjectMarker(defaultCenter);

    [...markers, subjectMarker].forEach(marker => {
      bounds.extend(marker.getPosition());
    });
    this.map.fitBounds(bounds);
  };
}
