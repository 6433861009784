import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  display: block;
  background: white;
  border: 1px solid #ddd;
  padding: 0 20px 20px;

  p {
    margin-top: 20px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 20px;

  & p {
    margin: 0;
  }
`;

export const Adjusted = styled.p`
  color: #b1b1b1;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
`;
