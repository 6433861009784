import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MediaApi } from '../../../../../../api/MediaApi';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { toBase64 } from '../../../../../../services/toBase64';
import { QuickActionsModal, Typography } from '../../../../../Common';
import WarningConfirmPopup from '../../../../../Common/WarningConfirmPopup/WarningConfirmPopup';
import UploadFileComponent, { TFileType } from './UploadFileComponent';

const ModalContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const FilesConfigContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  height: 466,
  marginTop: 20,
  justifyContent: 'space-between',
  gap: '20px',
}));

const FileConfigContainer = styled('div')({
  flex: 1,
  height: '100%',
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #ddd',
  borderRadius: '10px',
  padding: '28px 34px 0 34px',
});

const FileConfigTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  margin-bottom: 3px;
`;

const FileConfigSubtitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

interface UploadFileModalProps {
  open: boolean;
  onClose: () => void;
  webUploadInfoText?: string;
  pdfUploadInfoText?: string;
  webDataProperty: string;
  pdfDataProperty: string;
  fileType: TFileType;
  heading: string;
  initialSrcWeb?: string;
  initialSrcPdf?: string;
}

export const UploadFileModal: React.FC<UploadFileModalProps> = ({
  open,
  onClose,
  webUploadInfoText,
  pdfUploadInfoText,
  webDataProperty,
  pdfDataProperty,
  fileType,
  heading,
  initialSrcPdf = '',
  initialSrcWeb = '',
}) => {
  const [webFile, setWebFile] = useState<File | null>(null);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [srcWeb, setSrcWeb] = useState(initialSrcWeb);
  const [srcPdf, setSrcPdf] = useState(initialSrcPdf);
  const [loading, setLoading] = useState(false);

  const [warningOpen, setWarningOpen] = useState(false);
  const versionMissing = !srcWeb || !srcPdf;

  const { setSlideDataProperty } = useSelectedSlide();

  const cleanUp = () => {
    setLoading(false);
  };

  const setConfig = async () => {
    const webFileSrc = webFile ? await MediaApi.upload(webFile) : srcWeb;
    const pdfFileSrc = pdfFile ? await MediaApi.upload(pdfFile) : srcPdf;
    setSlideDataProperty(webDataProperty, webFileSrc);
    setSlideDataProperty(pdfDataProperty, pdfFileSrc);
    cleanUp();
    onClose();
  };

  const handleClose = () => {
    cleanUp();
    onClose();
  };

  useEffect(() => {
    if (webFile) {
      toBase64(webFile).then(base64img => setSrcWeb(base64img));
    }

    if (pdfFile) {
      toBase64(pdfFile).then(base64img => setSrcPdf(base64img));
    }
  }, [webFile, pdfFile]);

  const saveFiles = async () => {
    setLoading(true);
    setWarningOpen(false);
    try {
      await setConfig();
    } catch (err) {
      console.error(err);
      setLoading(false);
    } finally {
      setWarningOpen(false);
    }
  };

  const onSave = () => {
    if (versionMissing) return setWarningOpen(true);
    saveFiles();
  };

  const onRemoveWeb = () => {
    setWebFile(null);
    setSrcWeb('');
  };

  const onRemovePdf = () => {
    setPdfFile(null);
    setSrcPdf('');
  };

  return (
    <QuickActionsModal
      open={open}
      actionButtonText='Save & Add to Library'
      actionButtonDisabled={loading || (!srcWeb && !srcPdf)}
      cancelButtonDisabled={loading}
      footerActionsPosition='right'
      heading={heading}
      zIndex={101}
      onClose={handleClose}
      onCancel={handleClose}
      onAction={onSave}
    >
      <ModalContentWrapper>
        <Label>Upload both versions of your image/slide for optimal viewing experience.</Label>
        <FilesConfigContainer>
          <FileConfigContainer>
            <FileConfigTitle>Web Version</FileConfigTitle>
            <FileConfigSubtitle>Landscape</FileConfigSubtitle>
            <UploadFileComponent
              orientation='landscape'
              fileSrc={srcWeb}
              setFile={setWebFile}
              infoText={webUploadInfoText}
              fileType={fileType}
              onRemove={onRemoveWeb}
            />
          </FileConfigContainer>
          <FileConfigContainer>
            <FileConfigTitle>PDF Version</FileConfigTitle>
            <FileConfigSubtitle>Portrait</FileConfigSubtitle>
            <UploadFileComponent
              orientation='portrait'
              fileSrc={srcPdf}
              setFile={setPdfFile}
              infoText={pdfUploadInfoText}
              fileType={fileType}
              onRemove={onRemovePdf}
            />
          </FileConfigContainer>
        </FilesConfigContainer>
        {versionMissing && warningOpen && (
          <WarningConfirmPopup
            open={versionMissing && warningOpen}
            heading={`${!pdfFile ? 'PDF' : 'Web'} version missing!`}
            description='If you don’t have or add the the web version the PDF version will be used for both orientations.'
            cancelButtonText='No, upload another'
            onCancel={() => setWarningOpen(false)}
            onConfirm={() => saveFiles()}
            onClose={() => setWarningOpen(false)}
          />
        )}
      </ModalContentWrapper>
    </QuickActionsModal>
  );
};
