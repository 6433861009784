import React, { useEffect, useState } from 'react';
import { Marker } from '@react-google-maps/api';
import { useOMS } from '../GoogleMap/MapOMSProvider';
import { PropertyMarker } from '../../../types';

interface CustomMarkerProps {
  property: any;
  icon: string;
  isClickable?: boolean;
  openInfo?: string | null;
  setOpenInfo?: (id: string | null) => void;
  infoWindowContent?: string;
}

export const CustomMarker: React.FC<CustomMarkerProps> = ({
  property,
  icon,
  isClickable,
  infoWindowContent,
}) => {
  const oms = useOMS();
  const [markerInstance, setMarkerInstance] = useState<google.maps.Marker | null>(null);

  useEffect(() => {
    if (!oms || !markerInstance) return;

    markerInstance.set('infoWindowContent', infoWindowContent);
  }, [infoWindowContent, markerInstance, oms]);

  if (!property?.coordinates?.latitude || !property?.coordinates?.longitude) return null;

  return (
    <Marker
      position={{ lat: property.coordinates.latitude, lng: property.coordinates.longitude }}
      icon={icon}
      clickable={isClickable}
      onLoad={marker => {
        if (!oms) return;

        marker.set('infoWindowContent', infoWindowContent);
        oms.addMarker(marker as PropertyMarker);
        setMarkerInstance(marker);
      }}
    />
  );
};
