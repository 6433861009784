import styled from 'styled-components';
import { TProperty } from '../../../../types';
import { SectionDetailItem } from './components/SectionDetailItem';
import { useDaysOnMarket } from '../../../../hooks/daysOnMarket/useDaysOnMarket';
import { getPropertyTypeString } from '../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';
import { SectionTitle } from './components/SectionTitle';
import { getPropertyId } from '../../services/getPropertyId';

interface Props {
  property: TProperty;
}

export const ListedInfo = ({ property }: Props) => {
  const DOM = useDaysOnMarket(property);

  return (
    <Container>
      <SectionTitle title='listed info' />
      {property.status !== 'sold' && (
        <SectionDetailItem label='Listing Date' value={property.listDateHuman} />
      )}
      {property.status === 'sold' && (
        <SectionDetailItem label='Sold Date' value={property.saleDateHuman} />
      )}
      {property.status !== 'pending' && (
        <SectionDetailItem label={DOM.label} value={String(DOM.value)} />
      )}
      <SectionDetailItem label='MLS ID' value={getPropertyId(property).toUpperCase()} />
      <SectionDetailItem
        label='Property Type'
        value={getPropertyTypeString(property.propertyType)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
