import {
  PropertyGroups,
  ReportCompAverages,
  ReportFlags,
  ReportFlagValues,
  TCompConfigItem,
} from '../../../types';
import {
  LARGER_FLAGS_FOOTAGE,
  SMALLER_FLAGS_FOOTAGE,
} from '../results-tuning/outliers/flagsConfig';
import { ACTIVE, CLOSED, PENDING, TRENDS_KEYS } from '../results-tuning/trends/trendsConfig';

const buildConfSelection = (compValues: any, config: TCompConfigItem) => ({
  icon: config.icon,
  id: config.id,
  name: config.labelHtml,
  value: compValues[config.id],
  valuePerSqft: compValues[config.idPerSqft],
  customizedData: config.customizedData,
});

export const getDefaultSelectedCategories = ({
  flags,
  compAverages,
  properties,
}: {
  flags: ReportFlags;
  compAverages: ReportCompAverages;
  properties?: PropertyGroups;
}) => {
  let selections = [];

  if (properties?.sold?.filter(p => !p.excluded)?.length) {
    selections.push(buildConfSelection(compAverages, CLOSED[TRENDS_KEYS.AVERAGE]));
  }

  if (properties?.active?.filter(p => !p.excluded)?.length) {
    selections.push(buildConfSelection(compAverages, ACTIVE[TRENDS_KEYS.AVERAGE]));
  }

  if (properties?.pending?.filter(p => !p.excluded)?.length) {
    selections.push(buildConfSelection(compAverages, PENDING[TRENDS_KEYS.AVERAGE]));
  }

  if (flags?.[LARGER_FLAGS_FOOTAGE.FROM_25_TO_50.id as keyof ReportFlagValues] || 0 > 0) {
    selections.push(buildConfSelection(flags, LARGER_FLAGS_FOOTAGE['FROM_25_TO_50']));
  }

  if (flags?.[SMALLER_FLAGS_FOOTAGE.FROM_25_TO_50.id as keyof ReportFlagValues] || 0 > 0) {
    selections.push(buildConfSelection(flags, SMALLER_FLAGS_FOOTAGE['FROM_25_TO_50']));
  }

  selections.sort((a, b) => a.value - b.value);

  return selections;
};
