import React from 'react';

import styled from 'styled-components';
import { Typography } from '../../../Slide.styles';
import { SlideSectionHeaderComponent } from '../../components/common/SlideSectionHeader.component';

interface WithPdf {
  isPdf?: boolean;
}

interface SlideContentProps {
  graphPadding?: string | number;
}

const Wrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  borderRadius: 5,
  boxShadow: isPdf ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.15)',
  border: isPdf ? ' 0.5px solid #DDDDDD' : 'none',
  height: '100%',
}));

const SlideContent = styled('div')<SlideContentProps>(({ graphPadding }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: graphPadding ? graphPadding : '1.5%',
  '& > *': { width: '100%' },
}));

const SummaryWrapper = styled('div')({
  padding: '3.5% 4%',
});

interface GraphWrapperProps {
  heading: string;
  summary?: string[];
  children: React.ReactNode;
  isPdf?: boolean;
  headerDescription?: string;
  graphPadding?: string | number;
}

export const GraphWrapper: React.FC<GraphWrapperProps> = ({
  heading,
  summary,
  children,
  isPdf,
  headerDescription,
  graphPadding,
}) => {
  return (
    <Wrapper isPdf={isPdf}>
      <SlideSectionHeaderComponent
        heading={heading}
        isPdf={isPdf}
        description={headerDescription}
      />

      {summary && (
        <SummaryWrapper>
          {summary.map((text, i) => (
            <Typography key={i} color='400' style={{ fontWeight: 500 }}>
              {text}
            </Typography>
          ))}
        </SummaryWrapper>
      )}
      <SlideContent
        graphPadding={graphPadding}
        style={{ height: !summary ? 'calc(100% - 105px)' : 'auto' }}
      >
        {children}
      </SlideContent>
    </Wrapper>
  );
};
