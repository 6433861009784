import { useAtomValue } from 'jotai';
import styled, { useTheme } from 'styled-components';

import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { ChartContainer } from '../components/ChartContainer';
import { DoubleBarsChart } from '../components/DoubleBarsChart';
import { Legend } from '../components/Legend';
import { PricingRateOfChangeTooltip } from './PricingRateOfChangeTooltip';
import { usePricingRateOfChangeData } from './usePricingRateOfChangeData';

const legendOptions = (firstBar: string, secondBar: string) => [
  { style: { background: firstBar }, text: 'List Price' },
  { style: { background: secondBar }, text: 'Closed Price' },
];

interface PricingRateOfChangeChartProps {
  headline?: string;
  hasDescription?: boolean;
}

export const PricingRateOfChangeChart = ({
  headline,
  hasDescription,
}: PricingRateOfChangeChartProps) => {
  const { colors } = useTheme();
  const { props } = usePricingRateOfChangeData();
  const properties = useAtomValue(reportPropertiesAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primaryColor = useUiConfigColorsPrimary();

  // check this logic, because staging and prod have this behavior
  const firstBar = isPresentationView ? '#bdc3c7' : colors.v2.primary;
  const secondBar = isPresentationView ? primaryColor : '#bdc3c7';

  return (
    <ChartContainer
      headline={headline}
      description={hasDescription && <Description />}
      legend={<Legend options={legendOptions(firstBar, secondBar)} />}
    >
      <DoubleBarsChart
        data={props}
        firstBarDataKey='list_price'
        secondBarDataKey='sale_price'
        xLabelKey='sale_date'
        primaryColor={firstBar}
        secondaryColor={secondBar}
        customTooltip={props => <PricingRateOfChangeTooltip {...props} />}
      />
    </ChartContainer>
  );
};

const Description = () => (
  <DescriptionContainer>
    <div>
      The graph highlights the change between the most recent list price and recorded sale price of
      the last 10 sold comparable properties. Hover over each for more info.
    </div>
    <div>You can get more details about each address by clicking on the X axis.</div>
  </DescriptionContainer>
);

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  margin-bottom: 10px;
`;
