import React from 'react';
import { DashboardInputEl, DashboardLabel, ErrorMessage } from './DashboardInput.styles';
import { Input } from '../V2/Input';

interface DashboardInputProps {
  label: string;
  type?: 'text' | 'number' | 'email';
  inputId?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
}

const style = {
  fontFamily: 'Figtree',
  fontWeight: 400,
};

export const DashboardInput: React.FC<DashboardInputProps> = ({
  label,
  type,
  inputId,
  onChange,
  value = '',
  placeholder,
  errorMessage,
}) => {
  return (
    <DashboardInputEl>
      <DashboardLabel htmlFor={inputId}>{label}</DashboardLabel>
      <Input
        type={type}
        inputStyle={style}
        name={inputId}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        inputWrapperStyle={{ width: '100%' }}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </DashboardInputEl>
  );
};
