import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  ACTIVE_COLOR,
  CLOSED_COLOR,
} from '../../../../../pages/PresentationCreate/dash/config/constants';

interface Props {
  title: string;
  isClosed?: boolean;
  children: ReactNode;
}

export const Section = ({ title = '', children, isClosed }: Props) => (
  <Wrapper>
    <Title isClosed={isClosed}>{title}</Title>
    <Content>{children}</Content>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.h5<{ isClosed?: boolean }>`
  color: ${({ isClosed }) => (isClosed ? CLOSED_COLOR : ACTIVE_COLOR)};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
