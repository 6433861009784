import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { pricingFavorabilityAtom } from '../../state/pricingFavorabilityAtom';

export function usePricingFavorabilityData() {
  const data = useAtomValue(pricingFavorabilityAtom);

  const activeMonth = data?.graph?.length ? data.graph.length - 1 : 0;
  const activeLabel = data?.graph?.[activeMonth]?.label;
  const text = data?.text;

  return useMemo(() => {
    const options = data?.graph || [];

    return { options, activeMonth, activeLabel, text };
  }, [data, activeMonth, activeLabel, text]);
}
