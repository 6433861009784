import { EntityMode } from '../types';

type Params = {
  entity: number | null;
  entityMode: EntityMode;
  useSpecialBranding: boolean;
};

export function getSupperaccountId({ entity, entityMode, useSpecialBranding }: Params): number {
  const isReadMode = entityMode === EntityMode.READ;
  const isReadModeWithSpecial = isReadMode && useSpecialBranding;
  const isAgentLevelWithSpecial = entity === null && useSpecialBranding;
  const isSpecialLevel = entity === -2;

  return isReadModeWithSpecial || isAgentLevelWithSpecial || isSpecialLevel ? -2 : -1;
}
