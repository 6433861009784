export function attachScalableZoomControl(map, scaleFactor = 1) {
  if (!map) return;

  const validFactor = scaleFactor === 0 ? 1 : scaleFactor;

  const controlDiv = document.createElement('div');
  controlDiv.index = 1;
  controlDiv.style.padding = '5px';

  const controlWrapper = document.createElement('div');
  controlWrapper.style.backgroundColor = 'white';
  controlWrapper.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
  controlWrapper.style.cursor = 'pointer';
  controlWrapper.style.textAlign = 'center';
  controlWrapper.style.width = '40px';
  controlWrapper.style.height = '81px';
  controlWrapper.style.transformOrigin = 'bottom left';
  controlWrapper.style.transform = `scale(${validFactor})`;
  controlDiv.appendChild(controlWrapper);

  const zoomInButton = document.createElement('div');
  zoomInButton.style.width = '40px';
  zoomInButton.style.height = '40px';
  zoomInButton.style.backgroundRepeat = 'no-repeat';
  zoomInButton.style.backgroundPosition = 'center';
  zoomInButton.style.backgroundImage =
    'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2218%2C7%2011%2C7%2011%2C0%207%2C0%207%2C7%200%2C7%200%2C11%207%2C11%207%2C18%2011%2C18%2011%2C11%2018%2C11%22%2F%3E%0A%3C%2Fsvg%3E%0A")';
  controlWrapper.appendChild(zoomInButton);

  const divider = document.createElement('div');
  divider.style.width = '30px';
  divider.style.height = '1px';
  divider.style.margin = '0 5px';
  divider.style.backgroundColor = 'rgb(230, 230, 230)';
  divider.style.overflow = 'hidden';
  controlWrapper.appendChild(divider);

  const zoomOutButton = document.createElement('div');
  zoomOutButton.style.width = '40px';
  zoomOutButton.style.height = '40px';
  zoomOutButton.style.backgroundRepeat = 'no-repeat';
  zoomOutButton.style.backgroundPosition = 'center';
  zoomOutButton.style.backgroundImage =
    'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%2C7h18v4H0V7z%22%2F%3E%0A%3C%2Fsvg%3E%0A")';
  controlWrapper.appendChild(zoomOutButton);

  zoomInButton.addEventListener('click', function () {
    map.setZoom(map.getZoom() + 1);
  });

  zoomOutButton.addEventListener('click', function () {
    map.setZoom(map.getZoom() - 1);
  });

  map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM] = [controlDiv];
}
