import { cloneDeep } from 'lodash';
import {
  TPresentationSlides,
  TDynamicSectionId,
  TSlideId,
  TDynamicSection,
  TSlide,
} from '../types';

interface ReturnValue {
  order: {
    sections: TDynamicSectionId[];
    sectionsSlide: Record<TDynamicSectionId, TSlideId[]>;
  };
  sections: Record<TDynamicSectionId, TDynamicSection>;
  slides: Partial<Record<TSlideId, TSlide>>;
}

export class SlidesConfigToNewProvidersAdapter {
  static adapt(slidesConfig: TPresentationSlides): ReturnValue {
    const obj: ReturnValue = {
      order: {
        sections: [],
        sectionsSlide: {},
      },
      sections: {},
      slides: {},
    };

    if (!slidesConfig?.orderById || !slidesConfig?.sections) return obj;

    slidesConfig?.orderById?.forEach(sectionId => {
      const section = slidesConfig.sections[sectionId];
      const { id, label } = section;
      obj.order.sections.push(sectionId);
      obj.order.sectionsSlide[sectionId] = section.slides
        .filter(slide => slide.includeSlide)
        .map(slide => {
          return slide.id as TSlideId;
        });
      section.slides.map(slide => {
        obj.slides[slide.id as TSlideId] = slide;
      });
      obj.sections[sectionId] = { id, label };
    });

    return obj;
  }
}
