import { cloneDeep } from 'lodash';

import type { IPresentationConfigs, TSlide, TSlideId } from '../../../types';
import { config as cover } from '../../../components/Slide/slides/startingSlides/Cover';
import type { TDynamicOrder, TDynamicSection, TDynamicSectionId } from '../../../types';
import standaloneSlidesConfig from '../../../components/Slide/services/StandaloneSlidesGroup';
import { config as listingDetails } from '../../../components/Slide/slides/buyerTour/listingDetails';
import { config as listingFeedback } from '../../../components/Slide/slides/buyerTour/listingFeedback';
import { config as greetingVideo } from '../../../components/Slide/slides/startingSlides/GreetingVideo';
import { config as cma } from '../../../components/Slide/slides/whatsMyHomeWorth/comparativeMarketAnalysis';
import { config as homeBuying } from '../../../components/Slide/slides/whyIAmTheRightFitSection/homeBuyingProcess';
import { config as homeSelling } from '../../../components/Slide/slides/whyIAmTheRightFitSection/homeSellingProcess';
import { config as whereBuyersGetTheirInfo } from '../../../components/Slide/slides/whatsMyHomeWorth/whereBuyersGetTheirInfo';
import { config as whatBuyersWantFromTheirAgents } from '../../../components/Slide/slides/whyIAmTheRightFitSection/whatBuyersWantFromTheirAgents';

const initSection: TDynamicSection = {
  id: 'initSection',
  label: 'Introduction',
};
const theProperties: TDynamicSection = {
  id: 'theProperties',
  label: 'The Properties',
};

const rateAndReview: TDynamicSection = {
  id: 'rateAndReview',
  label: 'Rate & Review',
};

const sections: Record<TDynamicSectionId, TDynamicSection> = {
  initSection,
  theProperties,
  rateAndReview,
} as const;

class BuyerTourConfigsImp implements IPresentationConfigs {
  getSlides(): Partial<Record<TSlideId, TSlide>> {
    return cloneDeep({
      ...standaloneSlidesConfig,
      [greetingVideo.id]: greetingVideo,
      [cover.id]: cover,
      [listingDetails.id]: listingDetails,
      [cma.id]: cma,
      [listingFeedback.id]: listingFeedback,
      [homeBuying.id]: homeBuying,
      [homeSelling.id]: homeSelling,
      [whereBuyersGetTheirInfo.id]: whereBuyersGetTheirInfo,
      [whatBuyersWantFromTheirAgents.id]: whatBuyersWantFromTheirAgents,
    });
  }

  getSections(): Record<TDynamicSectionId, TDynamicSection> {
    return cloneDeep(sections);
  }

  getFullOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [sections.initSection.id, sections.theProperties.id, sections.rateAndReview.id],
      sectionsSlide: {
        [sections.initSection.id]: [cover.id, cma.id] as TSlideId[],
        [sections.theProperties.id]: [listingDetails.id] as TSlideId[],
        [sections.rateAndReview.id]: [listingFeedback.id] as TSlideId[],
      },
    });
  }

  getDefaultOrder(): TDynamicOrder {
    return this.getDefaultOrder();
  }

  getPredefinedSectionSlides(): Record<TDynamicSectionId, TSlideId[]> {
    return cloneDeep({
      [sections.initSection.id]: [greetingVideo.id, cover.id, cma.id] as TSlideId[],
      [sections.theProperties.id]: [listingDetails.id] as TSlideId[],
      [sections.rateAndReview.id]: [listingFeedback.id] as TSlideId[],
    });
  }
}

export const BuyerTourConfigs = new BuyerTourConfigsImp();
