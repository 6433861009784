import styled from 'styled-components';
import { Typography, Icon } from '../../../../components/Common';

export { Typography, Icon };

type WithActive = {
  isActive: boolean;
};

export const NavigationContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderBottom: `1px solid ${theme.colors.gray[200]}`,
}));

export const NavigationWrapper = styled('div')({
  height: 67,
  display: 'flex',
  alignItems: 'flex-end',
});

export const NavItemWrapper = styled('div')<WithActive>(({ isActive, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '3%',
  paddingBottom: 10,
  borderBottom: '5px solid',
  borderColor: isActive ? theme.colors.primary.main : '#fff',
  cursor: 'pointer',
  '@media all and (max-width: 1170px)': {
    marginRight: '2%',
  },
  '&:last-of-type': {
    marginRight: 0,
  },
}));

export const NavItemText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.link};
`;

export const AllSlidesWrapper = styled('div')({
  paddingLeft: '1%',
  marginRight: '6%',
  '@media all and (max-width: 1170px)': {
    marginRight: '0.5%',
  },
});
