import styled from 'styled-components';

export const Separator = styled.div`
  width: 326px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  margin: 20px 0;

  @media (max-width: 991px) {
    width: 100%;
  }
`;
