import { useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { usePastPresentationsColumns } from './usePastPresentationsColumns';
import { PastPresentationsUtils } from '../services/PastPresentationsUtils';
import { useLoadPresentationsWithPdfPooling } from './useLoadPresentations';

import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  ExpandedState,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFilter,
} from '@tanstack/react-table';
import typeFilterAtom from '../jotai/typeFilterAtom';
import { filterByType } from '../../../../../services/filterByType';

export const usePastPresentationsTable = () => {
  const columns = usePastPresentationsColumns();
  const { data } = useLoadPresentationsWithPdfPooling();

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filtering, setFiltering] = useState<ColumnFilter[]>([]);
  const typeFilter = useAtomValue(typeFilterAtom);

  const groupedPastPresentations = useMemo(() => {
    const filteredData = filterByType(data, typeFilter);
    const allGroups = PastPresentationsUtils.getTableRows(filteredData, 'lastUpdated_dsc');
    return allGroups.map(([firstItem, ...rest]) => {
      return {
        ...firstItem,
        subRows: rest,
      };
    });
  }, [data, typeFilter]);

  const table = useReactTable({
    data: groupedPastPresentations,
    columns,
    state: {
      expanded,
      sorting,
      columnFilters: filtering,
    },
    onExpandedChange: setExpanded,
    getSubRows: row => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setFiltering,
  });

  const numberOfShownRows = table.getExpandedRowModel()?.rows?.length;

  return {
    table,
    numberOfShownRows,
  };
};
