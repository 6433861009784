import styled from 'styled-components';

export const Container = styled('div')({
  width: 182,
});

export const HandleEl = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 9,
  color: theme.colors.gray[400],
  position: 'absolute',
  top: 0,
  left: 0,
}));

export const RatingText = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 700,
  color: theme.colors.gray[400],
  textTransform: 'uppercase',
  marginTop: 7,
}));
