import React from 'react';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  Container,
  AsideBackground,
  ContentContainer,
  TextualContent,
  Graphic,
  Heading,
  Text,
  UL,
  LI,
  SourceText,
} from './WhyPricingCorrectlyIsImportantTraditional.styles';
import svgString from '../../assets/slideGraphics';
import aside_bg_png from './aside_bg.png';
import * as data from '../../data';
import { useUser } from '../../../../../providers/UserProvider';
import slideImage from '../../assets/imageTraditional.png';

export const WhyPricingCorrectlyIsImportantTraditional: React.FC = () => {
  const { isCanadaUser } = useUser();

  const primary = useUiConfigColorsPrimary();
  const image = React.useMemo(() => svgStringToDataUrl(svgString, primary), [primary]);
  return (
    <Container>
      <AsideBackground src={aside_bg_png} />
      <ContentContainer>
        <TextualContent>
          <Heading>{data.heading}</Heading>
          <Text>{data.paragraphs[0]}</Text>
          <Text style={{ fontWeight: 700, textTransform: 'uppercase', marginBottom: 10 }}>
            {data.paragraphs[1]}
          </Text>

          <UL>
            {data.listItems.map(li => (
              <LI key={li}>{li}</LI>
            ))}
          </UL>
        </TextualContent>
        <Graphic src={isCanadaUser ? slideImage : image} isCanadaUser={isCanadaUser} />
      </ContentContainer>
      {!isCanadaUser && <SourceText>Source: Zillow Listing Data</SourceText>}
    </Container>
  );
};
