import React from 'react';

import { TSlide } from '../../../../../../../types';
import { SelectableSlide } from './SelectableSlide';
import { Container, Grid, NoSlidesText } from './styles';

interface Props {
  slides: TSlide[];
  hasTags?: boolean;
  selected: Record<string, boolean>;
  onClick: (slide: TSlide) => void;
}

export const SelectableSlidesGrid: React.FC<Props> = ({ slides, selected, hasTags, onClick }) => {
  const height = hasTags ? 'calc(100vh - 321px)' : 'calc(100vh - 247px)';

  if (!slides.length) {
    return (
      <Container>
        <NoSlidesText>No slides to show</NoSlidesText>
      </Container>
    );
  }

  return (
    <Container style={{ maxHeight: height, minHeight: height }}>
      <Grid>
        {slides.map(slide => (
          <SelectableSlide
            key={slide.id}
            isSelected={!!selected?.[slide?.id]}
            slide={slide}
            onClick={() => onClick(slide)}
          />
        ))}
      </Grid>
    </Container>
  );
};
