import { TSlide } from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { HomeBuyingProcessTraditional } from './web/traditional/HomeBuyingProcessTraditional';
import { HomeBuyingProcessModern } from './web/modern/HomeBuyingProcessModern';
import { HomeBuyingProcessLuxury } from './web/luxury/HomeBuyingProcessLuxury';
import HomeBuyingProcessTraditionalPDF from './pdf/traditional/HomeBuyingProcessTraditionalPDF';
import { HomeBuyingProcessModernPDF } from './pdf/modern/HomeBuyingProcessModernPDF';
import { HomeBuyingProcessLuxuryPDF } from './pdf/luxury/HomeBuyingProcessLuxuryPDF';

const id = 'homeBuyingProcess';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Home Buying Process',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: HomeBuyingProcessTraditional,
    [CATEGORIES.MODERN]: HomeBuyingProcessModern,
    [CATEGORIES.LUXURY]: HomeBuyingProcessLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: HomeBuyingProcessTraditionalPDF,
    [CATEGORIES.MODERN]: HomeBuyingProcessModernPDF,
    [CATEGORIES.LUXURY]: HomeBuyingProcessLuxuryPDF,
  },
};

export type THomeBuyingProcess = typeof id;
export { config, mappings };
