import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';

import { ContactItem } from '../../../../../Common';

export { ContactItem };

export const AsideContainer = styled('div')(({ theme }) => ({
  backgroundColor: useUiConfigColorsPrimary(),
  width: '33%',
  paddingTop: 30,
  position: 'relative',
}));

export const ImageWrapper = styled('div')({
  width: '250px',
  height: '250px',
  borderRadius: '50%',
  overflow: 'hidden',
  margin: '0 auto',
});

export const Image = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover',
  objectPosition: 'top',
});

export const BrokerInfo = styled('div')({
  margin: '25px 0 50px 0',
});

export const BrokerName = styled('h3')(({ theme }) => ({
  fontSize: 28,
  color: '#fff',
  textTransform: 'uppercase',
  fontWeight: 400,
  fontFamily: useUiConfigFontsHeader(),
  textAlign: 'center',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const Title = styled('h5')(({ theme }) => ({
  fontSize: 20,
  color: '#fff',
  textTransform: 'uppercase',
  fontWeight: 400,
  marginTop: 10,
  fontFamily: useUiConfigFontsHeader(),
  textAlign: 'center',
  opacity: 0.85,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const License = styled('h6')({
  fontSize: 20,
  color: '#fff',
  fontWeight: 300,
  minHeight: 20,
  marginTop: 10,
  textAlign: 'center',
  opacity: 0.6,
});

export const ContactWrapper = styled('div')({
  paddingLeft: 50,
});

export const CompanyLogoContainer = styled('div')({
  backgroundColor: '#fff',
  padding: '20px 0',
  margin: '58px 0 28px 0',
  boxShadow: '4px 0px 9px rgba(0,0,0,0.1)',
  position: 'relative',
  zIndex: 1,
  height: 142,
  display: 'flex',
  justifyContent: 'center',
});

export const CompanyLogoWrapper = styled('div')({
  height: '100%',
});

export const LogoImage = styled('img')({
  height: '100%',
  maxWidth: '100%',
  padding: '10px',
});

export const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background:
    'linear-gradient(163.23deg, rgba(0, 0, 0, 0.304) -3.3%, rgba(236, 236, 236, 0.204) 49.23%, rgba(0, 0, 0, 0.276) 103.99%)',
});
