import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBrandName } from '../../hooks/useBrandName';

export function BackToHome() {
  const { present } = useBrandName();

  return (
    <HomeLink to='/'>
      <FontAwesomeIcon icon={['fas', 'angle-left']} />
      <HomeLinkText>{present} Home</HomeLinkText>
    </HomeLink>
  );
}

const HomeLink = styled(Link)(({ theme }) => ({
  color: theme.colors.v2.gray[400],
  marginBottom: 26,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textDecoration: 'none',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
}));

const HomeLinkText = styled('span')(({ theme }) => ({
  marginLeft: 5,
  color: theme.colors.v2.text.link,
}));
