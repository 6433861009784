import React from 'react';

import { TDynamicSlideConfigs } from '../../../../../../../../components/Slide/dynamic/types';
import { useSelectedSlide } from '../../../../../../../../providers/providers/SelectedSlideProvider';
import { DynamicSlidesDefaultConfigProvider } from '../../../../../../../../components/Slide/dynamic/DynamicSlidesDefaultConfigProvider';
import { useCreateSlideProps } from '../../../../../../providers/CreateSlidePropsProvider';
import { Col, Container, ThumbnailImage, ThumbnailWrapper } from '../styles';

interface ThumbnailsProps {
  templates: TDynamicSlideConfigs[];
}

export const Thumbnails: React.FC<ThumbnailsProps> = ({ templates }) => {
  const { selectedSlide, setSelectedSlide } = useSelectedSlide();
  const { editing } = useCreateSlideProps();

  const theSlide = editing ? editing : selectedSlide ? selectedSlide : null;
  return (
    <Col>
      <Container>
        {templates.map((t, i) => (
          <ThumbnailWrapper
            key={t.config.template}
            active={t.config.template === selectedSlide?.template}
          >
            <ThumbnailImage
              style={{
                backgroundImage: `url('${t.thumbnail}')`,
              }}
              active={t.config.template === selectedSlide?.template}
              onClick={() =>
                setSelectedSlide(
                  theSlide
                    ? DynamicSlidesDefaultConfigProvider.mergeWithEditingSlideData(
                        theSlide,
                        t.config.template,
                      )
                    : DynamicSlidesDefaultConfigProvider.getTemplateConfig(t.config.template),
                )
              }
            />
          </ThumbnailWrapper>
        ))}
      </Container>
    </Col>
  );
};
