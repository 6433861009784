import { useMemo } from 'react';
import { InheritanceLevel } from '../../../../../../../types';
import { useUser } from '../../../../../../../components/Slide/providers/UserProvider';
import { useRawCustomizations } from '../../../../../../../data/customizations/useRawCustomizations';

export const useAvailableCustomizationLevels = () => {
  const user = useUser();

  const { data: rawCustomizations } = useRawCustomizations(user?.hash);

  return useMemo(
    () =>
      [
        {
          label: 'Agent',
          value: InheritanceLevel.AGENT,
          entity: null,
          isAvailable: Boolean(rawCustomizations?.['e-null']),
        },
        {
          label: 'Team',
          value: InheritanceLevel.TEAM,
          entity: user?.teamsPrimaryEntity,
          isAvailable: Boolean(rawCustomizations?.['e-' + user?.teamsPrimaryEntity]),
        },
        {
          label: 'Office',
          value: InheritanceLevel.OFFICE,
          entity: user?.officePrimaryEntity,
          isAvailable: Boolean(rawCustomizations?.['e-' + user?.officePrimaryEntity]),
        },
        {
          label: 'Company',
          value: InheritanceLevel.ACCOUNT,
          entity: 0,
          isAvailable: Boolean(rawCustomizations?.['e-0']),
        },
        {
          label: 'Brand',
          value: InheritanceLevel.SUPERACCOUNT,
          entity: user?.useSpecialBranding ? -2 : -1,
          isAvailable: Boolean(rawCustomizations?.[user?.useSpecialBranding ? 'e--2' : 'e--1']),
        },
      ].filter(c => c.isAvailable),
    [user, rawCustomizations],
  );
};
