import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { TPersonalizeSlide, TUserConfigs } from '../../../../../../types';
import { PersonalizedSlidesApi } from '../../../../api/PersonalizedSlidesApi';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { DefaultConfigProvider } from '../../../../../../services/DefaultConfigProvider';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { useRawCustomizations } from '../../../../../../data/customizations/useRawCustomizations';
import { Button } from '../../../../../../components/Common/V2/Button/Button';

interface Props {
  onClose: () => void;
}

export const SaveAndContinue: React.FC<Props> = ({ onClose }) => {
  const { hash } = useUser();
  const { getSlides } = useSlides();

  const queryClient = useQueryClient();

  const defaultConfigs = DefaultConfigProvider.getPersonalizedSlides();

  const { data: customizationDataRaw } = useRawCustomizations(hash);

  const { mutate, isLoading } = useMutation(PersonalizedSlidesApi.update, {
    onSuccess: data => {
      queryClient.setQueryData(['personalized', hash], data);
      onClose();
    },
  });

  const onNext = () => {
    const updatedPersonalSlides = {} as any;

    getSlides(['aboutMe', 'testimonials'])
      .filter(
        slide =>
          JSON.stringify(slide) !== JSON.stringify(defaultConfigs[slide.id as TPersonalizeSlide]),
      )
      .forEach(slide => {
        updatedPersonalSlides[slide.id] = slide;
      });

    const customizationData = (customizationDataRaw as any)?.['e-null']?.data ?? {};

    mutate({
      config: updatedPersonalSlides,
      customizationData,
    });
  };

  return <Button onClick={onNext}>{!isLoading ? 'Save & Continue' : 'Loading ...'}</Button>;
};
