import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../Checkbox/Checkbox';

import classes from './CheckboxWithLabel.module.scss';

const CheckboxWithLabel = ({ label, value, onChange, boldChecked, disabled, style = {} }) => (
  <div className={`${classes.checkboxItem} ${disabled && classes.disabled}`}>
    <Checkbox value={value} onChange={onChange} disabled={disabled} />
    <h5 style={style} className={boldChecked && value ? classes.checked : ''}>
      {label}
    </h5>
  </div>
);

CheckboxWithLabel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  markChecked: PropTypes.bool,
  changed: PropTypes.func,
  disabled: PropTypes.bool,
};

CheckboxWithLabel.defaultProps = {
  value: false,
  markChecked: false,
  changed: f => f,
  disabled: false,
};

export { CheckboxWithLabel };
