import styled from 'styled-components';

export const Container = styled('div')({
  padding: '44px 30px 30px 30px',
});

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
