import styled from 'styled-components';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  padding: isPdf ? 0 : '2%',
}));

export const LeftCol = styled('div')<WithPdf>(({ isPdf }) => ({
  width: isPdf ? '48%' : '53%',
}));

export const RightCol = styled('div')<WithPdf>(({ isPdf }) => ({
  width: isPdf ? '52%' : '44%',
}));

export const ImageWrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  width: '100%',
  height: isPdf ? '50%' : '100%',
}));

export const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'center',
});
