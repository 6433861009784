import React from 'react';
import styled from 'styled-components';

import { IHomesCount } from './InfoCard';

interface Props extends IHomesCount {
  maxTotalCount: number;
}

export const ColoredBar: React.FC<Props> = ({
  maxTotalCount,
  active,
  pending,
  closed,
  comingSoon,
}) => {
  const totalCount = active + pending + closed + comingSoon;
  const totalCountRelativeToMaxCount =
    totalCount >= maxTotalCount ? 100 : (totalCount / maxTotalCount) * 100;
  return (
    <Container>
      <ColoredDiv
        style={{
          height: (comingSoon / totalCount) * totalCountRelativeToMaxCount * 2.35,
          backgroundColor: '#6ab4e4',
        }}
      />
      <ColoredDiv
        style={{
          height: (active / totalCount) * totalCountRelativeToMaxCount * 2.35,
          backgroundColor: '#3BCA46',
        }}
      />
      <ColoredDiv
        style={{
          height: (pending / totalCount) * totalCountRelativeToMaxCount * 2.35,
          backgroundColor: '#F26A21',
        }}
      />
      <ColoredDiv
        style={{
          height: (closed / totalCount) * totalCountRelativeToMaxCount * 2.35,
          backgroundColor: '#CA3B4C',
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 30%;
  margin-bottom: 4px;
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`;

const ColoredDiv = styled.div`
  display: flex;
`;
