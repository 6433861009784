import React from 'react';
import styled from 'styled-components';
import * as data from '../../data';
import slideGraphics from './slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import calendar from '../../assets/calendarModern';
import { useUser } from '../../../../../providers/UserProvider';

type WithCanadaUser = {
  isCanadaUser?: boolean;
};

const Col = styled('div')({ display: 'flex', flexDirection: 'column' });

const Container = styled(Col)<WithCanadaUser>(({ isCanadaUser }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: isCanadaUser ? 'column-reverse' : 'column',
}));

const TopContent = styled(Col)<WithCanadaUser>(({ isCanadaUser }) => ({
  flex: 1,
  borderRadius: 5,
  border: '1px solid #DDDDDD',
  marginBottom: isCanadaUser ? 0 : 15,
}));

const Wrapper = styled(Col)({
  flex: 1,
  position: 'relative',
  padding: 30,
});

const Header = styled('header')(({ theme }) => ({
  width: '100%',
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
}));

const HeaderText = styled('p')(({ theme }) => ({
  fontWeight: 800,
  textAlign: 'center',
  letterSpacing: useUiConfigHeaderLetterSpacing('0.11em'),
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  color: useUiConfigColorsPrimary(),
  fontSize: '26px',
}));

const Graphics = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  width: isCanadaUser ? '100%' : '90%',
  alignSelf: 'center',
  margin: isCanadaUser ? '57px 0 0 0' : '30px 0 10px 0',
}));

const ChartText = styled('p')(({ theme }) => ({
  fontWeight: 900,
  textAlign: 'center',
  fontSize: 16,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const BottomContent = styled(Col)<WithCanadaUser>(({ theme, isCanadaUser }) => ({
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
  borderRadius: 5,
  border: '1px solid #DDDDDD',
  padding: '20px 20px 40px 20px',
  flexShrink: 0,
  letterSpacing: useUiConfigBodyLetterSpacing(),
  marginBottom: isCanadaUser ? 15 : 0,
}));

const Heading = styled('h3')(({ theme }) => ({
  fontWeight: 900,
  marginBottom: 10,
  color: useUiConfigColorsPrimary(),
}));

const Text = styled('p')({
  textAlign: 'justify',
  color: '#010101',
  fontSize: 16,
  lineHeight: 1.5,
  marginBottom: 10,
});

const SourceText = styled('p')({
  textAlign: 'right',
  fontSize: 13,
  color: '#101010',
  fontWeight: 300,
  marginTop: 15,
});
export const ImportanceOfOpeningWeekendModernPDF: React.FC = () => {
  const { isCanadaUser } = useUser();
  const primary = useUiConfigColorsPrimary();
  const fontFamily = useUiConfigFontsHeader();

  const graph = isCanadaUser ? calendar : slideGraphics;

  const image = React.useMemo(() => svgStringToDataUrl(graph, primary), [graph, primary]);

  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      <Container isCanadaUser={isCanadaUser}>
        <TopContent isCanadaUser={isCanadaUser}>
          <Header>
            <HeaderText>{data.heading}</HeaderText>
          </Header>
          <Wrapper>
            <ChartText style={{ fontFamily }}>
              The longer homes last on the market, the lower the odds of selling for list price.
            </ChartText>
            {!isCanadaUser && (
              <ChartText style={{ fontWeight: 400, marginTop: 5 }}>
                Share of homes selling at or above list price
              </ChartText>
            )}
            <Graphics src={image} isCanadaUser={isCanadaUser} />
            {!isCanadaUser && (
              <>
                <ChartText
                  style={{
                    textTransform: 'uppercase',
                    fontSize: 13,
                    fontFamily,
                  }}
                >
                  Weeks On Market
                </ChartText>
                <ChartText
                  style={{
                    transform: 'rotate(-90deg)',
                    position: 'absolute',
                    left: -128,
                    top: 272,
                    textTransform: 'uppercase',
                    fontSize: 13,
                    fontFamily,
                  }}
                >
                  Odds of home selling at or above list price
                </ChartText>
              </>
            )}
          </Wrapper>
        </TopContent>
        <BottomContent isCanadaUser={isCanadaUser}>
          <Heading style={{ fontFamily }}>Why is Opening weekend important?</Heading>
          {data.paragraphs.map((p, i) => (
            <Text key={i}>{p}</Text>
          ))}
        </BottomContent>
        {!isCanadaUser && <SourceText>Source: Zillow Listing Data</SourceText>}
      </Container>
    </PdfFrame>
  );
};
