import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import slideGraphics from './assets/slideGraphics.png';
import hexagonCells from './assets/hexagonCells';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useMemo } from 'react';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import { data } from '../../data';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  position: relative;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  width: 420px;
  font-size: 28px;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
  z-index: 1;
  padding-left: 45px;
  margin-top: 25px;
`;

const SlideGraphicWrapper = styled.div`
  position: absolute;
  bottom: -9px;
`;

const Image = styled('img')`
  display: block;
  position: absolute;
  top: 0;
  left: 245px;
`;

const SlideGraphicsImg = styled('img')`
  display: block;
  width: 100%;
`;

export const PurposeOfBuyersAgentTraditional = () => {
  const primary = useUiConfigColorsPrimary();

  const img = useMemo(() => svgStringToDataUrl(hexagonCells, primary), [primary]);

  const headingStyles = {
    fontSize: 21,
    color: '#444',
    fontWeight: 800,
    lineHeight: '25.2px',
    marginBottom: '15px',
    width: 140,
  };

  const textStyles = {
    fontSize: 15,
    fontWeight: 500,
    color: '#101010',
    lineHeight: '24.06px',
    width: 227,
  };

  const containerStyles = {
    justifyContent: 'flex-start',
  };

  return (
    <Wrapper>
      <Heading>
        Purpose & <br /> importance of a <br />
        buyer&apos;s agent
      </Heading>
      <SlideGraphicWrapper>
        <SlideGraphicsImg src={slideGraphics} />
      </SlideGraphicWrapper>
      <Image src={img} />
      <TraditionalSlideCircularCard
        heading={data[0].title}
        headingStyles={{ ...headingStyles, width: 160 }}
        textStyles={textStyles}
        text={data[0].text}
        position={{ left: 379, top: 29 }}
        size={340}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data[1].title}
        headingStyles={{ ...headingStyles, width: 127 }}
        textStyles={{ ...textStyles, width: 218 }}
        text={data[1].text}
        position={{ left: 670, top: 29 }}
        size={340}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data[2].title}
        headingStyles={{ ...headingStyles, width: 135 }}
        textStyles={{ ...textStyles, width: 224 }}
        text={data[2].text}
        position={{ left: 958, top: 29 }}
        size={340}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data[3].title}
        headingStyles={headingStyles}
        textStyles={textStyles}
        text={data[3].text}
        position={{ left: 236, top: 352 }}
        size={340}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data[4].title}
        headingStyles={{ ...headingStyles, width: 136 }}
        textStyles={{ ...textStyles, width: 225 }}
        text={data[4].text}
        position={{ left: 525, top: 352 }}
        size={340}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data[5].title}
        headingStyles={headingStyles}
        textStyles={textStyles}
        text={data[5].text}
        position={{ left: 814, top: 352 }}
        size={340}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data[6].title}
        headingStyles={headingStyles}
        textStyles={textStyles}
        text={data[6].text}
        position={{ left: 1103, top: 352 }}
        size={340}
        containerStyles={containerStyles}
      />
    </Wrapper>
  );
};
