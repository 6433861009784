import React from 'react';

import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper, LeftCol, RightCol } from './OneImageAlpha.styles';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';

interface Props {
  reverse?: boolean;
}

export const OneImageAlphaForm: React.FC<Props> = ({ reverse }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  const onChangeText = (value: string) => {
    setSlideDataProperty('text', value);
  };

  if (!selectedSlide) return null;

  return (
    <Container style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
      <LeftCol>
        <ImageWrapper>
          <UploadImage
            image={selectedSlide.data?.image}
            slideDataConfigPropertyName={'image'}
            imageSizeSuggestion='797x683'
          />
        </ImageWrapper>
      </LeftCol>
      <RightCol>
        <SlideTextBlock
          isEditable
          text={selectedSlide.data?.text}
          headline={selectedSlide.data?.headline}
          onChangeText={onChangeText}
          onChangeHeadline={onChangeHeadline}
        />
      </RightCol>
    </Container>
  );
};
