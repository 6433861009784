import React, { useMemo } from 'react';
import { TProperty } from '../../../../types';
import { getPropertyFeatures } from '../../../../pages/PresentationCreate/dash/services/getPropertyFeatures';
import styled from 'styled-components';
import { SectionTitle } from './components/SectionTitle';

interface Props {
  property: TProperty;
}

export const Features = ({ property }: Props) => {
  const features = useMemo(() => getPropertyFeatures(property), [property]);

  if (!features?.length) return <div />;

  return (
    <Container>
      <SectionTitle title='Features' />
      <FeaturesContainer>
        {features.map(feature => (
          <FeatureItem key={feature.label}>{feature.label}</FeatureItem>
        ))}
      </FeaturesContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeaturesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 10px;
`;

const FeatureItem = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: #010101;
`;
