import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateAtomOnChange } from '../../../hooks/useUpdateAtomOnChange';
import { extractSubject } from '../services/extractSubject';
import { subjectAtom } from '../state/subjectAtom';
import { searchCriteriaAtom } from '../state/searchCriteriaAtom';
import { isPresentationViewAtom } from '../state/isPresentationViewAtom';
import { compAveragesAtom } from '../state/compAveragesAtom';
import { searchAPIAtom } from '../state/searchAPIAtom';
import { useSetProperties } from './useSetProperties';
import { useSetNotes } from './useSetNotes';
import { isDtcAtom } from '../state/isDtcAtom';
import { useSetGraphs } from './useSetGraphs';

export const useSyncStateWithRedux = () => {
  const search = useSelector((state: any) => state.search);
  const report = useSelector((state: any) => state.report);

  const subject = useMemo(() => extractSubject(report.reportDetailed), [report.reportDetailed]);
  useUpdateAtomOnChange(subjectAtom, subject);

  useSetProperties();
  useSetNotes();
  useSetGraphs();

  useUpdateAtomOnChange(searchCriteriaAtom, search.searchCriteria);
  useUpdateAtomOnChange(compAveragesAtom, report.reportDetailed.compAverages);
  useUpdateAtomOnChange(searchAPIAtom, search.api);
  useUpdateAtomOnChange(isDtcAtom, report.reportDetailed.useDtcInsteadDomForSold);
  useUpdateAtomOnChange(isPresentationViewAtom, false);
};
