import React from 'react';

import {
  PresentationModeProvider,
  usePresentationMode,
} from '../../providers/providers/PresentationModeProvider';
import { InheritanceLevel, TPresentation } from '../../types';
import { withProviders } from '../../hoc/withProviders';
import { UiConfigProvider } from '../../providers/providers/UiConfigProvider';
import { CategoryProvider } from '../../providers/providers/CategoryProvider';
import { PresentationTypeProvider } from '../../providers/providers/PresentationTypeProvider';
import { ActiveSectionProvider } from '../../components/Slide/providers/ActiveSectionProvider';
import { CoreSlidesConfigProvider } from '../../providers/providers/CoreSlidesConfigProvider';
import { PresentationEditContent } from './PresentationCreationContent/PresentationEditContent.component';
import { EmphasisProvider } from './PresentationCreationContent/PresentationSettingsHeader/PresentationSettings/Emphasis/EmphasisProvider';
import { CreatedAtProvider, useCreatedAt } from '../../providers/providers/CreatedAtProvider';
import {
  PresentationMetaProvider,
  usePresentationMeta,
} from '../../providers/providers/PresentationMetaProvider';

interface PresentationEditProps {
  presentation: TPresentation;
}

export const PresentationEdit: React.FC<PresentationEditProps> =
  withProviders<PresentationEditProps>(
    PresentationModeProvider,
    PresentationMetaProvider,
    CreatedAtProvider,
  )(({ presentation }) => {
    const { presentationMode, setPresentationMode } = usePresentationMode();
    const { setInheritanceLevel } = usePresentationMeta();
    const { setCreatedAt } = useCreatedAt();

    React.useEffect(() => {
      setPresentationMode(presentation?.mode ?? 'regular');
      setCreatedAt(presentation.createdAt as number);

      setInheritanceLevel(presentation.meta?.inheritanceLevel ?? InheritanceLevel.AGENT);
    }, [
      presentation.createdAt,
      presentation.id,
      presentation.meta?.inheritanceLevel,
      presentation?.mode,
      setCreatedAt,
      setInheritanceLevel,
      setPresentationMode,
    ]);

    if (!presentationMode) return null;

    return (
      <CategoryProvider key={presentation?.id} initValue={presentation?.category}>
        <PresentationTypeProvider initValue={presentation?.type}>
          <UiConfigProvider initValue={presentation?.ui}>
            <CoreSlidesConfigProvider presentationMode={presentationMode}>
              <ActiveSectionProvider>
                <EmphasisProvider>
                  <PresentationEditContent presentation={presentation} />
                </EmphasisProvider>
              </ActiveSectionProvider>
            </CoreSlidesConfigProvider>
          </UiConfigProvider>
        </PresentationTypeProvider>
      </CategoryProvider>
    );
  });
