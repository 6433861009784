import { TSlideId } from '../types';

export class GoToSlide {
  private static eventId = 'goToSlide';

  public static dispatch(slide: TSlideId) {
    if (!slide) return;

    const goToEvent = new CustomEvent(GoToSlide.eventId, { detail: slide });
    window.document.dispatchEvent(goToEvent);
  }

  public static subscribe(navigateToSlide: EventListener) {
    window.document.addEventListener(GoToSlide.eventId, navigateToSlide);
  }

  public static unsubscribe(navigateToSlide: EventListener) {
    window.document.removeEventListener(GoToSlide.eventId, navigateToSlide);
  }
}
