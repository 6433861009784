import React from 'react';
import { CSSObject } from 'styled-components';
import {
  GraphCard,
  GraphCardContent,
  Typography,
  GraphCardContainer,
  TitleWrapper,
  GraphCardHeader,
} from './AnalyticsGraphsCard.styles';
import { GraphCardLegend } from './GraphCardLegend/GraphCardLegend.compoenent';

interface AnalyticsGraphCardProps {
  title: string;
  subtitle?: string;
  height?: number | string;
  source?: string;
  isWithAgentData?: boolean;
  onCheckboxClick?: (showOfficeData: boolean) => void;
  cardContainerStyles?: CSSObject;
}

export const AnalyticsGraphCard: React.FC<AnalyticsGraphCardProps> = ({
  title,
  subtitle,
  children,
  height = 'auto',
  source,
  isWithAgentData,
  onCheckboxClick,
  cardContainerStyles,
}) => {
  return (
    <GraphCard>
      <GraphCardContainer style={cardContainerStyles}>
        <GraphCardHeader>
          <TitleWrapper>
            <Typography variant='h3' color='#333'>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant='h4' color='#666' style={{ fontSize: 14 }}>
                {subtitle}
              </Typography>
            )}
          </TitleWrapper>
          {isWithAgentData && <GraphCardLegend onCheckboxClick={onCheckboxClick} />}
        </GraphCardHeader>
        <GraphCardContent style={{ height }}>{children}</GraphCardContent>
        {source && (
          <Typography
            variant='small'
            color='#666'
            style={{ position: 'absolute', bottom: 0, left: 0 }}
          >
            * {source}
          </Typography>
        )}
      </GraphCardContainer>
    </GraphCard>
  );
};
