import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Address,
  ContactItemContainer,
  IconWrapper,
  Link,
  Text,
  TextWrapper,
} from './ContactItem.styles';

type ContactAddress = {
  companyName: string;
  address: string;
  city: string;
};

export type TContactIconName =
  | 'envelope'
  | 'phone-office'
  | 'mobile-android'
  | 'globe'
  | 'map-marker-alt';

export interface ContactInfo {
  phone?: string;
  email?: string;
  website?: string;
  contactAddress?: ContactAddress;
}

interface ContactItemProps extends ContactInfo {
  iconName: TContactIconName;
  color?: string;
  fontSize?: number;
  isPdf?: boolean;
  containerStyles?: React.CSSProperties;
}

export const ContactItem: React.FC<ContactItemProps> = ({
  iconName,
  phone,
  email,
  website,
  contactAddress,
  isPdf,
  containerStyles,
}) => {
  return (
    <ContactItemContainer isPdf={isPdf} style={containerStyles}>
      <IconWrapper isPdf={isPdf}>
        <FontAwesomeIcon icon={['far', iconName]} style={{ color: isPdf ? '#444' : '#fff' }} />
      </IconWrapper>
      {phone && <Text isPdf={isPdf}>{phone}</Text>}
      {email && (
        <Link href={`mailto:${email}`} isPdf={isPdf}>
          {email}
        </Link>
      )}
      {website && (
        <Link href={`https://${website}`} target='_blank' isPdf={isPdf}>
          {website}
        </Link>
      )}
      {contactAddress && (
        <TextWrapper>
          <Address isPdf={isPdf}>{contactAddress.companyName}</Address>
          <Address isPdf={isPdf}>{contactAddress.address}</Address>
          <Address isPdf={isPdf}>{contactAddress.city}</Address>
        </TextWrapper>
      )}
    </ContactItemContainer>
  );
};
