import { DefaultTheme } from 'styled-components';

export const getWhiteLabelColors = ({
  theme,
  hasWL,
  wlPrimaryColor,
  wlSecondaryColor,
  packageLevel,
}: {
  theme: DefaultTheme;
  hasWL?: boolean;
  wlPrimaryColor?: string;
  wlSecondaryColor?: string;
  packageLevel?: string;
}) => {
  const primary = wlPrimaryColor || theme.colors.v2.primary;
  const secondary = wlSecondaryColor || theme.colors.v2.gray[300];

  const primaryLogoColor =
    hasWL && packageLevel === 'premium' ? wlPrimaryColor : theme.colors.v2.gray[400]; // '#444'

  const secondaryLogoColor =
    hasWL && packageLevel === 'premium' ? wlSecondaryColor : theme.colors.v2.gray[300]; // '#B1B1B1'

  return { primary, secondary, primaryLogoColor, secondaryLogoColor };
};
