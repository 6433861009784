import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '../../Common';
import { AnalyticsGraphCard } from '../components/AnalyticsGraphsCard/AnalyticsGraphsCard.component';
import {
  PercentageGraph,
  TPercentagesBarData,
} from '../components/PercentageGraph/PercentageGraph.component';

type WithSingleCol = {
  isSingleCol?: boolean;
};

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const Col = styled('div')<WithSingleCol>(({ isSingleCol }) => ({
  width: isSingleCol ? '100%' : '49%',
}));

export type TNextStepsFeedback = {
  sellers: TPercentagesBarData[];
  buyers: TPercentagesBarData[];
};

interface NextStepsFeedbackProps {
  nextStepsFeedback: TNextStepsFeedback;
  isWithAgentData?: boolean;
  type: 'all' | 'seller' | 'buyer';
}
export const NextStepsFeedback: React.FC<NextStepsFeedbackProps> = ({
  nextStepsFeedback,
  isWithAgentData,
  type = 'all',
}) => {
  const [nextStepsFeedbackData, setNextStepsFeedbackData] = useState<TNextStepsFeedback | null>(
    null,
  );

  useEffect(() => {
    setNextStepsFeedbackData(nextStepsFeedback);
  }, [nextStepsFeedback]);

  const onCheckboxClick = (showOfficeData: boolean) => {
    if (showOfficeData) {
      setNextStepsFeedbackData(nextStepsFeedback);
    } else {
      const filteredSellersData = nextStepsFeedback.sellers.map(item => {
        return {
          title: item.title,
          agent: item.agent,
        };
      });
      const filteredBuyersData = nextStepsFeedback.buyers.map(item => {
        return {
          title: item.title,
          agent: item.agent,
        };
      });
      setNextStepsFeedbackData({ sellers: filteredSellersData, buyers: filteredBuyersData });
    }
  };

  return (
    <AnalyticsGraphCard
      title='Next Steps Feedback'
      source='Based on data sourced from web reports'
      isWithAgentData={isWithAgentData}
      onCheckboxClick={onCheckboxClick}
    >
      <Container>
        {type !== 'buyer' && (
          <Col>
            <Typography
              variant='body1'
              color='#444'
              style={{ textTransform: 'uppercase', marginBottom: 18 }}
            >
              Sellers
            </Typography>
            {nextStepsFeedbackData?.sellers.map((dataItem, i) => (
              <PercentageGraph
                key={i}
                title={dataItem.title}
                office={dataItem.office}
                agent={dataItem.agent}
              />
            ))}
          </Col>
        )}
        {type !== 'seller' && (
          <Col>
            <Typography
              variant='body1'
              color='#444'
              style={{ textTransform: 'uppercase', marginBottom: 18 }}
            >
              BUYERS
            </Typography>
            {nextStepsFeedbackData?.buyers.map((dataItem, i) => (
              <PercentageGraph
                key={i}
                title={dataItem.title}
                office={dataItem.office}
                agent={dataItem.agent}
              />
            ))}
          </Col>
        )}
      </Container>
    </AnalyticsGraphCard>
  );
};
