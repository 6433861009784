import { FC } from 'react';
import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { TCategory } from '../../../types';
import { TRACKING_EVENTS, TrackingEvent } from '../../../services/TrackingEvent';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { isPresentationViewAtom } from '../state/isPresentationViewAtom';
import { PricingFavorabilityGauge } from './components/PricingFavorabilityGauge';
import { ActiveCompetitionGauge } from './components/ActiveCompetitionGauge';
import { SellingPaceGauge } from './components/SellingPaceGauge';
import { PricePerSqftGauge } from './components/PricePerSqftGauge';
import { PricingRateOfChangeGauge } from './components/PricingRateOfChangeGauge';
import { ActiveShelfLifeGauge } from './components/ActiveShelfLifeGauge';

const boxIdToLabelMap: any = {
  favor: 'Average List Price',
  competition: 'Fresh/Stale',
  sellingPace: 'Selling Pace',
  pricePerSqft: 'Price per SQFT',
  rate: 'Pricing Rate of Change',
  shelfLife: 'Current Active Shelf Life',
};

interface MarketAssessmentGridProps {
  category?: TCategory;
}

export const MarketAssessmentGrid: FC<MarketAssessmentGridProps> = ({ category }) => {
  const { isCanadaUser } = useUser();
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const trackClick = (overview: string) => {
    if (!isPresentationView) return;

    TrackingEvent.click(
      boxIdToLabelMap[overview] ?? 'gauge',
      TRACKING_EVENTS.GAUGES_ELEMENT_CLICKED,
    );
  };

  const Grid = isPresentationView ? PresentationViewGrid : ReportViewGrid;

  return (
    <Grid isCanadaUser={isCanadaUser}>
      <PricingFavorabilityGauge trackClick={() => trackClick('favor')} category={category} />
      <ActiveCompetitionGauge trackClick={() => trackClick('competition')} category={category} />
      <SellingPaceGauge trackClick={() => trackClick('sellingPace')} category={category} />
      {!isCanadaUser && (
        <PricePerSqftGauge trackClick={() => trackClick('pricePerSqft')} category={category} />
      )}
      <PricingRateOfChangeGauge trackClick={() => trackClick('rate')} category={category} />
      <ActiveShelfLifeGauge trackClick={() => trackClick('shelfLife')} category={category} />
    </Grid>
  );
};

const PresentationViewGrid = styled.div<{ isCanadaUser?: boolean }>`
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;

  & > div {
    grid-column: span 2;
  }

  & div:nth-child(4),
  & div:nth-child(5) {
    grid-column: span ${props => (props.isCanadaUser ? 3 : 2)};
  }
`;

const ReportViewGrid = styled.div<{ isCanadaUser?: boolean }>`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 28px;

  & > div {
    grid-column: span 2;
  }

  & div:nth-child(4),
  & div:nth-child(5) {
    grid-column: span ${props => (props.isCanadaUser ? 3 : 2)};
  }

  @media (max-width: 1340px) {
    & div {
      grid-column: span 3;
    }
    & div:nth-child(4) {
      grid-column: span ${props => (props.isCanadaUser ? 3 : 3)};
    }
    & div:nth-child(5) {
      grid-column: span ${props => (props.isCanadaUser ? 6 : 3)};
    }
  }

  @media (max-width: 790px) {
    & div {
      grid-column: span 6 !important;
    }
  }
`;
