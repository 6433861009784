import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import TwoImagesAlpha from './TwoImagesAlpha';
import TwoImagesAlphaForm from './TwoImagesAlphaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import TwoImagesLayoutAlphaPdf from './TwoImagesAlphaPdf';

const template = 'twoImagesLayoutAlpha';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Two Image Layout',
  frame: true,
  data: {
    image: '',
    image2: '',
    text: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TwoImagesAlphaForm,
  web: TwoImagesAlpha,
  pdf: TwoImagesLayoutAlphaPdf,
};

export type TTwoImageLayoutAlpha = typeof template;
export { config, mappings, thumbnail };
