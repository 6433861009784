import { ReactElement } from 'react';
import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import Portal from '../../../../components/Common/Portal';
import { Icon } from '../../../../components/Common';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { useUiConfigFontsBody } from '../../../../providers/providers/UiConfigFontProvider';

interface Props {
  children: ReactElement;
  isOpen?: boolean;
  onClose: () => void;
  id?: string;
}

export const Sidebar = ({
  children,
  isOpen = false,
  onClose = () => '',
  id = 'scale-frame',
}: Props) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const body = useUiConfigFontsBody();

  if (!isOpen) {
    return null;
  }

  const fontFamily = isPresentationView ? body : 'Figtree';

  return (
    <Portal id={id}>
      <Aside onClick={onClose} style={{ fontFamily }}>
        <Panel onClick={e => e.stopPropagation()}>
          <CloseWrapper onClick={onClose}>
            <Icon iconName='close_sidebar' style={{ width: 14, height: 18 }} />
          </CloseWrapper>

          <Content>{children}</Content>
        </Panel>
      </Aside>
    </Portal>
  );
};

const Aside = styled.aside`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;

const Panel = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 600px;
  background: #fff;

  @media (max-width: 650px) {
    width: auto;
    left: 0;
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  cursor: pointer;
`;

const Content = styled.main`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;
  position: relative;
`;
