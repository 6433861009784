import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SectionHeaderComponentProps = {
  title: string;
  subtitle: string;
  icon: 'sidebar' | 'user-circle';
};

export function SectionHeader({ title, subtitle, icon }: SectionHeaderComponentProps) {
  return (
    <Container>
      <Icon icon={icon} />
      <Wrapper>
        <Title>{title}</Title>
        <Text>{subtitle}</Text>
      </Wrapper>
    </Container>
  );
}

export const Container = styled.header`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 7px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 40px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
