import React, { ReactElement } from 'react';

import { ScalePdf } from './ScalePdf';
import { usePropertiesDetailsPdf } from './usePropertiesDetailsPdf';
import { useAtomValue } from 'jotai';
import { highlightedPropertiesAtom } from '../../../../../../features/report/state/highlighted-properties/highlightedPropertiesAtom';
import { reportPropertiesAtom } from '../../../../../../features/report/state/reportPropertiesAtom';
import { extractPropertyGroups } from '../../../../../../features/report/services/extractPropertiesGroups';
import { propertiesNotesAtom } from '../../../../../../features/report/state/propertiesNotesAtom';
import { extractHighlightedProperties } from '../../../../../../features/report/services/extractHighlightedProperties';
import { useUser } from '../../../../providers/UserProvider';
import { useSlide } from '../../../../providers/SlideProvider';
import { usePropertiesListPdfPages } from './usePropertiesListPdfPages';
import { useHasComparableAdjustments } from '../../../../../../hooks/useHasComparableAdjustments';

export const CmaReportPdf = () => {
  const user = useUser();
  const notes = useAtomValue(propertiesNotesAtom);
  const highlightedProperties = useAtomValue(highlightedPropertiesAtom);

  const reportProperties = useAtomValue(reportPropertiesAtom);

  const hasAdjustedPrice = useHasComparableAdjustments();

  const slide = useSlide();

  const allProperties = extractPropertyGroups(reportProperties);
  const highlighted = extractHighlightedProperties(allProperties, highlightedProperties);

  const allPropertiesList = [
    ...(allProperties?.active || []),
    ...(allProperties?.sold || []),
    ...(allProperties?.pending || []),
    ...(allProperties.comingSoon || []),
  ];
  const hasPropertiesWithoutSQFT = allPropertiesList?.find((property: any) => !property.size);

  const properties = slide?.data?.cmaReportShowAllProperties ? allPropertiesList : highlighted;

  const tablePages = usePropertiesListPdfPages() as ReactElement[];
  const propertyDetailPages = usePropertiesDetailsPdf(notes, properties) as ReactElement[];

  const isCanadaUser = user?.isCanadaUser;

  const scalePages = hasAdjustedPrice
    ? [
        <ScalePdf key={'scale'} />,
        <ScalePdf key={'scaleSQFT'} isPricePerSqftMode />,
        <ScalePdf key={'scaleAdj'} isAdjustedPricePlotBy />,
        <ScalePdf key={'scaleAdjSQFT'} isPricePerSqftMode isAdjustedPricePlotBy />,
      ]
    : [<ScalePdf key={'scale'} />, <ScalePdf key={'scaleSQFT'} isPricePerSqftMode />];

  const pages = [...scalePages, ...tablePages, ...propertyDetailPages];

  if (isCanadaUser || hasPropertiesWithoutSQFT) {
    return pages.filter(item => item.key !== 'scaleSQFT');
  }

  return pages;
};
