import { CustomMarker } from '../../../../../components/Common/CustomMarker/CustomMarker.component';
import { TProperty } from '../../../../../types';
import subjectIcon from '../../../../../assets/icons/map/pin-subject.svg?url';

interface Props {
  subject: TProperty;
}

export const SubjectMarker = ({ subject }: Props) => {
  return <CustomMarker icon={subjectIcon} property={subject} />;
};
