import React from 'react';
import styled from 'styled-components';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { Typography } from '../../../../../../Common';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { Card } from '../../../../../components/Card/Card.component';
import { cards } from '../../data';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { CATEGORIES } from '../../../../../../../constants/categories';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'repeat(7, 1fr)',
  rowGap: 20,
  columnGap: 20,
  height: '100%',
});

const CardItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 5,
  padding: '0 7%',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1'),
}));

const WhatFactorsInfluencePricingModernPDF: React.FC = () => {
  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      <Grid>
        {cards.map((card, i) =>
          i === 0 ? (
            <Card
              key={i}
              {...card}
              cardStyles={{ gridRow: '1 / 3', gridColumn: '1 / -1', padding: '2% 4%' }}
              titleStyles={{ height: 'auto' }}
              textStyles={{ minWidth: '100%' }}
              type='pdf'
            />
          ) : (
            <CardItem key={i}>
              <Typography
                color='400'
                style={{ fontSize: 20, fontWeight: 700, textTransform: 'uppercase' }}
              >
                {card.title}
              </Typography>
              <img src={card.icon} style={{ flexShrink: 0, width: 25, height: 'auto' }} />
            </CardItem>
          ),
        )}
      </Grid>
    </PdfFrame>
  );
};

export default WhatFactorsInfluencePricingModernPDF;
