import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../constants/categories';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { svgStringToDataUrl } from '../../../../services/svgStringToDataUrl';
import image from './image.png';
import slideGraphics from './slideGraphics';

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr auto repeat(9, 1fr)',
  height: '100%',
  width: '100%',
  rowGap: 15,
});

const RowItem = styled('div')({
  padding: 20,
  border: '.7px solid #dddddd',
});

const Graphics = styled('img')({
  position: 'absolute',
  height: 630,
  top: 0,
  left: 70,
});

const Heading = styled('p')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontFamily: useUiConfigFontsHeader(),
  fontWeight: 800,
  textTransform: 'uppercase',
  fontSize: 26,
  lineHeight: 1.3,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const Subtitle = styled('p')(({ theme }) => ({
  color: theme.colors.gray[400],
  fontWeight: 500,
  fontSize: 18,
  letterSpacing: useUiConfigHeaderLetterSpacing('0.05em'),
}));

const Text = styled('p')(({ theme }) => ({
  color: theme.colors.gray[400],
  fontSize: 16,
  lineHeight: 1.56,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const SourceText = styled('small')({
  fontWeight: 300,
  color: '#101010',
  position: 'absolute',
  bottom: 10,
  right: 10,
  fontSize: 9,
});

const ImageTitle = styled('p')({
  fontSize: 19,
  color: '#101010',
  position: 'absolute',
  top: 20,
  right: 20,
  textTransform: 'uppercase',
  fontWeight: 800,
  letterSpacing: '0.11em',
});

export const PricingMisconceptionsPDF: React.FC = () => {
  const { category } = useCategory();
  const svgImg = React.useMemo(() => svgStringToDataUrl(slideGraphics), []);

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <RowItem
          style={{ gridRow: '1 / 2', display: 'flex', flexDirection: 'column', border: 'none' }}
        >
          <Heading>Pricing misconceptions</Heading>
          <Subtitle>What is an AVM - Automated Value Model?</Subtitle>
        </RowItem>
        <RowItem
          style={{
            gridRow: '2 / 3',
            backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
            columnCount: 2,
            borderRadius: 5,
            columnGap: 30,
          }}
        >
          <Text>
            An AVM is simply an estimate. It’s based on a modeled formula that accounts for multiple
            variables that range from market conditions and home facts to buyer interest. AVMs
            should be considered in the big picture and are a great starting point for agents.
            <br />
            There are over 1.7 billion websites globally, and over 200 million in the U.S. alone.
            Buyers are likely to check the Zillow “Zestimate” when they start their online home
            search. In fact, Zillow ranks in the top 24 most visited U.S. sites with each visitor
            spending an average of 7 minutes on average.
          </Text>
        </RowItem>
        <RowItem
          style={{
            gridRow: '3 / -1',
            background: `linear-gradient(87.3deg, rgba(0, 0, 0, 0.2) -16.31%, rgba(0, 0, 0, 0) 28.28%), linear-gradient(261.23deg, rgba(0, 0, 0, 0.2) 15.81%, rgba(0, 0, 0, 0) 74.27%), url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            overflow: 'hidden',
            backdropFilter: 'blur(1.87639px)',
          }}
        >
          <Graphics src={svgImg} />
          <ImageTitle>Most VIsited US WEBSITES</ImageTitle>
          <SourceText>Source: www.alexa.com/topsites/countries/US (Nov 2020)</SourceText>
        </RowItem>
      </Container>
    </PdfFrame>
  );
};
