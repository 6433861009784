import styled from 'styled-components';

export const Container = styled('div')<{ isPdf?: boolean }>(({ isPdf }) => ({
  display: 'grid',
  height: '100%',
  gridTemplateColumns: isPdf ? '50% 50%' : 'repeat(2, 1fr)',
  gridTemplateRows: isPdf ? '53% 43%' : 'repeat(2, 1fr)',
  gap: 32,
  padding: isPdf ? 0 : '2%',
}));

export const ImageWrapper = styled('div')({
  overflow: 'hidden',
});

export const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'center',
});
