import { useEffect } from 'react';

import { Presentation } from './Presentation';
import Loader from '../../components/Loader/Loader';
import { ErrorMessage, Spacer } from './Presentation.styles';
import { usePresentation } from '../../hooks/usePresentation';
import { BuyerTourPresentation } from '../../features/BuyerTour';
import { PRESENTATION_TYPES } from '../../constants/PresentationTypes';
import { usePresentationHashFromUrl } from './hooks/usePresentationHashFromUrl';
import { useSetApiUser, useUser } from '../../components/Slide/providers/UserProvider';
import { useSetPresentationHash } from '../../state/presentationHash/useSetPresentationHash';
import { useSetPresentationToState } from '../../features/report/hooks/useSetPresentationToState';

const presentationComponentByTypeMap = {
  [PRESENTATION_TYPES.PRESENT_INFO]: Presentation,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: Presentation,
  [PRESENTATION_TYPES.WIN_THE_LISTING]: Presentation,
  [PRESENTATION_TYPES.WIN_THE_OFFER]: Presentation,
  [PRESENTATION_TYPES.BUYER_TOUR]: BuyerTourPresentation,
};

export function PresentationPage() {
  const user = useUser();
  const setApiUser = useSetApiUser();
  const hash = usePresentationHashFromUrl();

  useSetPresentationHash(hash);
  const { data, isLoading, isError, error } = usePresentation(hash);
  useSetPresentationToState(data);

  useEffect(
    function onPresentationLoaded() {
      if (!data?.user) return;

      setApiUser?.(data.user);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const isNotFound = error?.message.includes('404');

  if (isError) {
    return (
      <Spacer>
        <ErrorMessage>
          {isNotFound
            ? 'Unfortunately, we could not find a presentation using the information provided.'
            : error?.message}
        </ErrorMessage>
      </Spacer>
    );
  }

  if (isLoading || !data?.type || !user?.userId) {
    return (
      <Spacer>
        <Loader size='4x' centered />
      </Spacer>
    );
  }

  const Component = presentationComponentByTypeMap[data.type];
  if (!Component || !user) return null;

  return <Component presentation={data as any} />;
}
