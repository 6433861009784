import React from 'react';
import { ModernCoverContainer, InfoContainer, TopContainer } from './CoverContentDivided.styles';
import { CoverLogos } from '../CoverLogos/CoverLogos.component';
import { useClient } from '../../../../../../../../providers/providers/ClientProvider';
import { ICoverContentDividedProps } from './CoverContentDivided.component';
import { CoverHeading, BTPreHeading } from '../Heading';

export const CoverContentDividedBT: React.FC<ICoverContentDividedProps> = ({
  divider,
  isReversed,
}) => {
  const { client } = useClient();

  return (
    <ModernCoverContainer>
      <TopContainer>
        <BTPreHeading style={{ transform: 'translate(-50%, 210px)' }}>
          Tour prepared for
        </BTPreHeading>
        <CoverHeading style={{ transform: 'translateY(230px)', textAlign: 'center' }}>
          {client?.name}
        </CoverHeading>
      </TopContainer>
      {divider}
      <InfoContainer style={{ justifyContent: 'center' }}>
        <CoverLogos />
      </InfoContainer>
    </ModernCoverContainer>
  );
};
