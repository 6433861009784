import styled from 'styled-components';

export const InputWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  position: 'relative',
});

export const Input = styled('input')(({ theme }) => ({
  border: 'none',
  borderBottom: `1px solid ${theme.colors.gray[400]}`,
  marginBottom: 10,
  width: '100%',
  background: 'transparent',
  padding: '3px',
}));

export const ButtonWrapper = styled('div')({
  position: 'absolute',
  left: '102%',
  top: '50%',
  transform: 'translateY(-50%)',
  fontFamily: "'Figtree', sans-serif",
});
