export default `
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="333px" height="201px" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns:xlink="http://www.w3.org/1999/xlink">
<g><path style="opacity:1" fill="transparent" d="M -0.5,-0.5 C 110.5,-0.5 221.5,-0.5 332.5,-0.5C 332.5,66.5 332.5,133.5 332.5,200.5C 221.5,200.5 110.5,200.5 -0.5,200.5C -0.5,133.5 -0.5,66.5 -0.5,-0.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 146.5,31.5 C 152.5,31.5 158.5,31.5 164.5,31.5C 164.5,39.5 164.5,47.5 164.5,55.5C 151.745,54.9178 139.412,56.9178 127.5,61.5C 123.765,54.5289 120.431,47.3623 117.5,40C 119.221,38.4728 121.221,37.4728 123.5,37C 131.181,34.728 138.848,32.8946 146.5,31.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 168.5,31.5 C 183.681,32.3028 198.348,35.4695 212.5,41C 213.022,41.5613 213.355,42.228 213.5,43C 211.085,50.2729 207.919,57.1062 204,63.5C 192.617,58.8566 180.784,56.1899 168.5,55.5C 168.5,47.5 168.5,39.5 168.5,31.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 110.5,41.5 C 112.144,41.2865 113.644,41.6198 115,42.5C 117.057,48.2808 119.39,53.9474 122,59.5C 122.667,60.8333 122.667,62.1667 122,63.5C 110.603,68.7803 100.103,75.447 90.5,83.5C 84.6813,78.1816 79.0146,72.6816 73.5,67C 84.5078,56.5026 96.8411,48.0026 110.5,41.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 217.5,43.5 C 232.03,49.6035 244.696,58.4368 255.5,70C 250.867,75.6349 245.867,80.9682 240.5,86C 239.5,86.6667 238.5,86.6667 237.5,86C 228.952,77.4515 219.285,70.4515 208.5,65C 211.644,57.8801 214.644,50.7134 217.5,43.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 70.5,69.5 C 76.479,74.8123 82.1457,80.479 87.5,86.5C 79.0013,96.4931 71.5013,107.16 65,118.5C 57.7159,115.52 50.5492,112.353 43.5,109C 50.3764,94.3063 59.3764,81.1396 70.5,69.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 257.5,73.5 C 259.716,74.2026 261.549,75.5359 263,77.5C 271.256,87.9347 278.09,99.2681 283.5,111.5C 276.281,114.405 269.115,117.405 262,120.5C 256.617,109.554 250.117,99.3871 242.5,90C 247.324,84.3421 252.324,78.8421 257.5,73.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 167.5,90.5 C 166.833,88.8333 166.167,87.1667 165.5,85.5C 165.369,84.6236 165.702,83.9569 166.5,83.5C 167.474,85.7427 167.808,88.076 167.5,90.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 41.5,113.5 C 49.3774,115.905 57.044,118.905 64.5,122.5C 59.2014,134.579 56.868,147.246 57.5,160.5C 49.1667,160.5 40.8333,160.5 32.5,160.5C 32.5516,144.243 35.5516,128.576 41.5,113.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 165.5,85.5 C 166.167,87.1667 166.833,88.8333 167.5,90.5C 168.736,106.857 170.236,123.191 172,139.5C 172.561,141.725 173.561,143.725 175,145.5C 175.991,156.593 171.158,160.427 160.5,157C 155.594,152.16 155.428,147.327 160,142.5C 162.22,123.531 164.053,104.531 165.5,85.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 283.5,115.5 C 285.284,116.574 286.451,118.24 287,120.5C 288.515,126.893 290.181,133.226 292,139.5C 292.5,146.506 293,153.506 293.5,160.5C 285.5,160.5 277.5,160.5 269.5,160.5C 269.821,148.436 267.821,136.769 263.5,125.5C 270.074,121.976 276.741,118.642 283.5,115.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 163.5,142.5 C 170.169,142.005 173.336,145.005 173,151.5C 168.491,156.778 164.158,156.778 160,151.5C 159.333,149.833 159.333,148.167 160,146.5C 161.376,145.295 162.542,143.962 163.5,142.5 Z"/></g>
</svg>
`;
