import styled from 'styled-components';

import { BigPictureMode } from './BigPictureMode';
import { PriceModeSelection } from '../components/PriceModeSelection';
import { PlotByPriceSelection } from '../components/PlotByPriceSelection';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { useHasComparableAdjustments } from '../../../../hooks/useHasComparableAdjustments';

interface ModeSelectionProps {
  bigPictureMode: boolean;
  setBigPictureMode: (value: boolean) => void;
}

export const ModeSelection = ({ bigPictureMode, setBigPictureMode }: ModeSelectionProps) => {
  const { isCanadaUser } = useUser();
  const showAdjusted = useHasComparableAdjustments();

  return (
    <ModeSelectionContainer>
      {showAdjusted && <PlotByPriceSelection />}
      {!isCanadaUser && (
        <>
          <BigPictureMode bigPictureMode={bigPictureMode} setBigPictureMode={setBigPictureMode} />
          <PriceModeSelection />
        </>
      )}
    </ModeSelectionContainer>
  );
};

const ModeSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: normal;
  width: 100%;
`;

const StarsWrapper = styled.div`
  display: flex;
  min-width: 380px;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto;
`;
