import React from 'react';
import { usePresentationType } from '../../../../../../../../providers/providers/PresentationTypeProvider';
import { CoverContentBT } from './CoverContentBT';
import { CoverContentRegular } from './CoverContentRegular';

export const CoverContent: React.FC = () => {
  const { presentationType } = usePresentationType();

  if (presentationType === 'buyerTour') return <CoverContentBT />;
  return <CoverContentRegular />;
};
