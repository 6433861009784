import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import styled, { useTheme } from 'styled-components';

import {
  ACTIVE_COLOR,
  CLOSED_COLOR,
  PENDING_COLOR,
} from '../../../../../pages/PresentationCreate/dash/config/constants';
import { reportPropertiesAtom } from '../../../state/reportPropertiesAtom';
import { filteredPropertiesAtom } from '../../../state/filteredPropertiesAtom';
import { useUser } from '../../../../../components/Slide/providers/UserProvider';
import { extractPropertyGroups } from '../../../services/extractPropertiesGroups';
import pinA from '../../../../../assets/icons/map/pin-a.svg?url';
import pinP from '../../../../../assets/icons/map/pin-p.svg?url';
import pinC from '../../../../../assets/icons/map/pin-c.svg?url';
import pinCS from '../../../../../assets/icons/map/pin-coming_soon.svg?url';
import pinCSBT from '../../../../../assets/icons/map/pin-coming_soon_bt.svg?url';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';

export const PropertiesMapLegend = () => {
  const theme = useTheme();

  const { isCanadaUser } = useUser();
  const properties = useAtomValue(reportPropertiesAtom);
  const filteredProperties = useAtomValue(filteredPropertiesAtom);

  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const propertiesToShow = useMemo(() => {
    const props = filteredProperties || properties;
    return extractPropertyGroups(props);
  }, [filteredProperties, properties]);

  const comingSoonIcon = hasBtTheme ? pinCSBT : pinCS;

  return (
    <Container>
      <Icons>
        <img src={comingSoonIcon} alt='' />
        <span style={{ color: theme.colors.v2.primary }}>
          Coming Soon Match:{' '}
          {propertiesToShow?.comingSoon?.filter(property => !property?.excluded)?.length ?? 0}
        </span>
        <img src={pinA} alt='' />{' '}
        <span style={{ color: ACTIVE_COLOR }}>
          Active Match:{' '}
          {propertiesToShow?.active?.filter(property => !property?.excluded)?.length ?? 0}
        </span>
        {!isCanadaUser && (
          <>
            <img src={pinP} alt='' />{' '}
            <span style={{ color: PENDING_COLOR }}>
              Pending Match:{' '}
              {propertiesToShow?.pending?.filter(property => !property?.excluded)?.length ?? 0}
            </span>
          </>
        )}
        <img src={pinC} alt='' />{' '}
        <span style={{ color: CLOSED_COLOR }}>
          Sold Match: {propertiesToShow?.sold?.filter(property => !property?.excluded)?.length ?? 0}
        </span>
      </Icons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
`;

const Icons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;

  img {
    margin-right: 5px;
  }
  span {
    margin-right: 15px;
  }
`;
