import { AreaType } from '../../../../../../../types';

export const criteriaToApiMapper = criteria => {
  const {
    id,
    propertyType,
    propertyTypeSelections,
    status,
    beds,
    bedsRange,
    size,
    sizeRange,
    totalSize,
    totalSizeSet,
    totalSizeRange,
    totalSizeUnit,
    ignoreSize,
    yearBuilt,
    yearBuiltFrom,
    yearBuiltTo,
    yearBuiltRangeSet,
    saleDateLimitRange,
    coordinates,
    mapCoordinates,
    radius,
    mapMode,
    reportType,
    manualMlsIds = [],
    additional = [],
    areaDetails,
    areaType,
    totalBaths,
    bathsRange,
  } = criteria;

  let apiData = {
    id,
    ignore_size: ignoreSize,
    subject: {
      beds: beds,
      property_type: [propertyType],
      status,
      size,
      total_size: totalSize,
      coordinates,
      year_built: yearBuilt,
      baths: totalBaths,
    },
  };

  if (reportType !== 'manual') {
    apiData = {
      ...apiData,
      property_type: propertyTypeSelections.map(selected => selected.value),
      bed_from: parseInt(beds) + Math.min(...(bedsRange ?? [0])),
      bed_to: parseInt(beds) + Math.max(...(bedsRange ?? [0])),
      total_baths_from: Math.min(...(bathsRange ?? [1])),
      total_baths_to: Math.max(...(bathsRange ?? [10])),
      size_from: Array.isArray(sizeRange) ? Math.round(Math.min(...sizeRange)) : null,
      size_to: Array.isArray(sizeRange) ? Math.round(Math.max(...sizeRange)) : null,
      total_size_set: totalSizeSet,
      total_size_from: Array.isArray(totalSizeRange) ? Math.min(...totalSizeRange) : null,
      total_size_to: Array.isArray(totalSizeRange) ? Math.max(...totalSizeRange) : null,
      total_size_unit: totalSizeUnit ? totalSizeUnit : 'sqft',
      year_built_range_set: yearBuiltRangeSet,
      year_built_from: !isNaN(yearBuiltFrom) && yearBuiltRangeSet ? parseInt(yearBuiltFrom) : null,
      year_built_to: !isNaN(yearBuiltTo) && yearBuiltRangeSet ? parseInt(yearBuiltTo) : null,
      sale_date_limit_from: Math.min(...saleDateLimitRange),
      sale_date_limit_to: Math.max(...saleDateLimitRange),
      additional_ids: [...additional, ...manualMlsIds],
    };
  } else {
    apiData = {
      ...apiData,
      report_type: 'manual',
      property_type:
        typeof propertyType === 'string' ? [propertyType] : propertyType.map(pt => pt.value),
      bed_from: parseInt(beds),
      bed_to: parseInt(beds),
      total_baths_from: Math.min(...(bathsRange ?? [1])),
      total_baths_to: Math.max(...(bathsRange ?? [10])),
      size_from: size * 0.8,
      size_to: size * 1.2,
      total_size_set: totalSizeSet,
      total_size_from: 0,
      total_size_to: totalSize * 2,
      total_size_unit: totalSizeUnit ? totalSizeUnit : 'sqft',
      year_built_range_set: yearBuiltRangeSet,
      year_built_from: yearBuilt ? yearBuilt : null,
      year_built_to: yearBuilt ? yearBuilt : null,
      sale_date_limit_from: 0,
      sale_date_limit_to: 6,
      additional_ids: manualMlsIds ?? [],
    };
  }

  if (mapMode === 'area') {
    apiData = {
      ...apiData,
      lat: coordinates.latitude,
      lon: coordinates.longitude,
      radius: radius?.value ?? 1,
      mls_area_state: areaType === AreaType.mls ? areaDetails.state : undefined,
      mls_area_type: areaType === AreaType.mls ? areaDetails.type : undefined,
      mls_area_value: areaType === AreaType.mls ? areaDetails.name : undefined,
      polygon: areaType === AreaType.geographical ? mapCoordinates : undefined,
    };
  }

  if (mapMode === 'marker' || mapMode === null) {
    apiData = {
      ...apiData,
      lat: coordinates.latitude,
      lon: coordinates.longitude,
      radius: radius?.value ?? 1,
    };
  }

  if (mapMode === 'polygon') {
    apiData = {
      ...apiData,
      polygon: mapCoordinates,
    };
  }

  return apiData;
};
