import React from 'react';
import image from './image.png';
import { listItemsLuxury, heading } from '../../data';
import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';

export const WhatFactorsInfluencePricingLuxury: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading={heading}
      subtitle='Ultimately, a home’s value is based on what potential buyers will pay, to know what they’re looking for in a home can help you get top dollar. Here are ten factors potential buyers consider when comparing homes:'
      imagePosition='right'
      image={image}
      imageStyles={{ width: '65%' }}
      listItems={listItemsLuxury}
      listType='ul'
      textContainerStyles={{ padding: '30px 0px 0px', width: '50%' }}
    />
  );
};
