import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './css';
import 'reactjs-popup/dist/index.css';
import { Router } from './pages/Router/Router';
import { Helmet } from './components/Helmet';
import { FullStory } from './features/fullstory/FullStory';
import { ErrorMonitoring } from './services/sentry/ErrorMonitoring';
import GlobalProviders from './providers/GlobalProviders';
import { GlobalErrorBoundary } from './components/error-boundary/GlobalErrorBoundary';
import { FullScreenLoader } from './components/Loader/FullScreenLoader';
import { useWhiteLabelAuthorizationGuard } from './features/whitelabel/useWhiteLabelAuthorizationGuard';

FullStory.init();
ErrorMonitoring.init();

const App = () => {
  return (
    <>
      <Helmet />
      <Router />
      <ToastContainer autoClose={4000} />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalErrorBoundary>
      <GlobalProviders>
        <App />
      </GlobalProviders>
    </GlobalErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
