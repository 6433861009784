import { TEmphasisMeta } from '../types';
import { TEmphasis } from '../../../../../../../types';

export const staticEmphasisConfigs: Record<TEmphasis, TEmphasisMeta> = {
  defaultOrder: {
    label: 'Default Order',
    value: 'defaultOrder',
    order: [],
    slidesOrder: '',
  },
  rightTimeToSell: {
    label: 'Contingent',
    value: 'rightTimeToSell',
    order: [
      'startingSlides',
      'marketingAndBuyerActivity',
      'marketAnalysis',
      'whyIAmTheRightFit',
      'whatsMyHomeWorth',
      'closingAndNextSteps',
    ],
    slidesOrder: '',
  },
  agentIsTheRightFit: {
    label: `I'm Shopping You`,
    value: 'agentIsTheRightFit',
    order: [
      'startingSlides',
      'whyIAmTheRightFit',
      'marketingAndBuyerActivity',
      'whatsMyHomeWorth',
      'marketAnalysis',
      'closingAndNextSteps',
    ],
    slidesOrder: '',
  },
  topDollarValue: {
    label: 'Make Me List',
    value: 'topDollarValue',
    order: [
      'startingSlides',
      'whatsMyHomeWorth',
      'marketAnalysis',
      'marketingAndBuyerActivity',
      'whyIAmTheRightFit',
      'closingAndNextSteps',
    ],
    slidesOrder: '',
  },
};
