import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import SsrComponent from './SsrComponent';
import { http } from '../services/httpService';
import { TPresentation, TUser } from '../types';
import { useSetApiUser } from '../components/Slide/providers/UserProvider';
import { PresentationApi } from '../pages/Presentation/api/PresentationApi';
import { FullScreenLoader } from '../components/Loader/FullScreenLoader';
import { useSetPresentationHash } from '../state/presentationHash/useSetPresentationHash';

export const fetchPresentation = async (hash: string) => {
  const response = await http.get(`/presentations/${hash}`);
  const { data, user, client_id, client_name, client_email } = response.data;

  const presentationData = {
    ...data,
    clientId: client_id,
    clientName: client_name,
    clientEmail: client_email,
  };

  return { data: presentationData, user };
};

export const SsrPresentation: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();

  useSetPresentationHash(hash);
  const setApiUser = useSetApiUser();
  const [presentation, setPresentation] = useState<TPresentation | null>(null);

  useEffect(
    function onMount() {
      (async () => {
        try {
          const pres = await PresentationApi.fetch(hash);

          setApiUser(pres?.user);
          setPresentation(pres);
        } catch (e: any) {
          console.log('ERROR: ', e?.message ?? e);
        }
      })();
    },
    [hash, setApiUser],
  );

  if (!presentation) return <FullScreenLoader />;

  return <SsrComponent data={presentation} user={presentation?.user as TUser} />;
};
