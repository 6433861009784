import styled from 'styled-components';

import { TProperty } from '../../../../types';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { IntroItem } from './IntroItem';

const getPrice = (
  property: TProperty,
  priceProperty: 'price' | 'salePrice',
  pricePerSqftProperty: 'pricePerSqft' | 'salePricePerSqft',
) =>
  !property[priceProperty]
    ? 'n/a'
    : `${MoneyFormatter.format(property[priceProperty])} (${MoneyFormatter.format(
        property[pricePerSqftProperty],
      )}/ft)`;

interface Props {
  property: TProperty;
}

export const Price = ({ property }: Props) => {
  return (
    <GridWrapper>
      <IntroItem label='Listed' value={getPrice(property, 'price', 'pricePerSqft')} />
      <IntroItem
        label='Sold'
        value={getPrice(property, 'salePrice', 'salePricePerSqft')}
        hide={property.status !== 'sold'}
        valueStyle={{ color: '#F13B3B' }}
      />
    </GridWrapper>
  );
};

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;
