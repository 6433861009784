import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconWrapper } from './EditIcon.styles';

interface EditIconProps {
  onClick?: () => void;
}

export const EditIcon: React.FC<EditIconProps> = ({ onClick }) => {
  return (
    <IconWrapper onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'pen']} />
    </IconWrapper>
  );
};
