import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { LogoPickerComponent } from './LogoPicker.component';
import { MediaApi } from '../../../../../../../../../../api/MediaApi';
import { useCanUpdateLockStatus } from '../../Lock/useCanUpdateLockStatus';
import { useEntity } from '../../../../../../../../providers/EntityProvider';
import { getConfigLockedStatus } from '../../../services/getConfigLockedStatus';
import { KvCoreLogoContainer, KvCoreLogoImg } from './ExpandablesLogos.styles';
import { TSlideLockableValue, TSlideLogos } from '../../../../../../../../../../types';
import { useUser } from '../../../../../../../../../../components/Slide/providers/UserProvider';
import { CustomizationSettingsExpandableCommon } from '../../../CustomizationModalStepOne.component';
import { useUiConfigLogos } from '../../../../../../../../../../providers/providers/UiConfigLogosProvider';
import { useUiUseKvCoreLogo } from '../../../../../../../../../../providers/providers/UiUseKvCoreLogoProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from '../../../../../../../../../../components/Common/V2/Accordion';

import { Lock } from '../../Lock/Lock';
import { InfoBanner } from '../InfoBanner';
import { SuperAccountCheckboxAndInfo } from './SuperAccountCheckboxAndInfo';
import { getShouldShowInfoBanner } from '../../../services/getShouldShowInfoBanner';

type ExpandablesLogosComponentProps = CustomizationSettingsExpandableCommon;

export const ExpandablesLogosComponent: React.FC<ExpandablesLogosComponentProps> = ({
  open,
  onToggle,
}) => {
  const { logos: initLogos, setLogos } = useUiConfigLogos();
  const { useKvCoreLogo: isBoxChecked, setUseKvCoreLogo: setIsBoxChecked } = useUiUseKvCoreLogo();

  const { entity } = useEntity();
  const { companyLogo, officePrimaryEntity, teamsPrimaryEntity, offices } = useUser();
  const canLockFirstLogo = useCanUpdateLockStatus(
    officePrimaryEntity,
    initLogos?.first?.lockedStatus,
  );

  const isOfficeEntity = offices.some(office => office.id === entity);

  const [logoFiles, setLogoFiles] = useState<(File | null)[]>([]);
  const [locked, setLocked] = useState(
    getConfigLockedStatus(
      entity,
      officePrimaryEntity,
      teamsPrimaryEntity,
      isOfficeEntity,
      initLogos?.first?.lockedStatus,
    ),
  );

  useEffect(() => {
    setLocked(
      getConfigLockedStatus(
        entity,
        officePrimaryEntity,
        teamsPrimaryEntity,
        isOfficeEntity,
        initLogos?.first?.lockedStatus,
      ),
    );
  }, [
    entity,
    initLogos?.first?.lockedStatus,
    initLogos?.first?.value,
    officePrimaryEntity,
    teamsPrimaryEntity,
    isOfficeEntity,
  ]);

  const uploadMedia = useCallback(
    async (mediaFile: File, isFirst = false) => {
      const url = await MediaApi.upload(mediaFile);

      const logoEntity: TSlideLockableValue = {
        value: url,
      };

      if (isFirst) {
        logoEntity.lockedStatus = { [`e-${entity}`]: entity !== null ? locked : undefined };
      }

      return logoEntity;
    },
    [entity, locked],
  );

  const onApply = useCallback(async () => {
    const newLogos = { ...initLogos };
    if (newLogos.first?.value) {
      newLogos.first.lockedStatus = { [`e-${entity}`]: entity !== null ? locked : undefined };
    }

    if (logoFiles[0]) {
      newLogos.first = await uploadMedia(logoFiles[0], true);
      delete logoFiles[0];
    }

    if (logoFiles[1]) {
      newLogos.second = await uploadMedia(logoFiles[1]);
      delete logoFiles[0];
    }

    if (logoFiles[2]) {
      newLogos.third = await uploadMedia(logoFiles[2]);
      delete logoFiles[0];
    }

    setLogos(newLogos);
    setLogoFiles(logoFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, locked, logoFiles, setLogos, uploadMedia]);

  useEffect(() => {
    onApply();
  }, [logoFiles, locked, onApply]);

  const onFileChange: any = (index: number) => (file: File | null) => {
    const filesToLogoMap: Record<number, keyof TSlideLogos> = {
      0: 'first',
      1: 'second',
      2: 'third',
    };

    if (file === null) {
      delete initLogos[filesToLogoMap[index]];
      setLogos(initLogos);
    }

    setLogoFiles(prev => {
      const newLogos = [...prev];
      newLogos[index] = file;
      return newLogos;
    });
  };

  const isUserLevel = entity === null;

  const isSuperAccount = useMemo(() => {
    return entity === -1 || entity === -2;
  }, [entity]);

  const descriptionText = useMemo(() => {
    if (isSuperAccount && !isBoxChecked) {
      return "Option: Remove agent's ability to manually upload logos and dynamically source logos from the Agent's kvCORE settings.";
    }

    if (isBoxChecked) return "Logos are dynamically sourced from the Agent's kvCORE settings";

    return '';
  }, [isBoxChecked, isSuperAccount]);

  const additionalDescription = useMemo(() => {
    if (isSuperAccount && !isBoxChecked) {
      return "Note: Lock settings will remove an Agent's ability to change setting and only administrators can lock/unlock customization settings.";
    }

    return '';
  }, [isSuperAccount, isBoxChecked]);

  const showAdminLockBannerInfo = getShouldShowInfoBanner({
    canUpdateLock: canLockFirstLogo,
    isLocked: locked,
  });

  const bannerText = showAdminLockBannerInfo
    ? 'Only admins can lock/unlock customization settings! Logos within a 2:1 (ex: 300x150px) and 3:1 (ex: 300x100) aspect ratio display best.'
    : 'Logos within a 2:1 (ex: 300x150px) and 3:1 (ex: 300x100) aspect ratio display best.';

  return (
    <Accordion
      title={'Logos'.toUpperCase()}
      prefixIcon={<FontAwesomeIcon icon={'image'} />}
      open={open}
      onClick={() => onToggle('logos')}
    >
      {isSuperAccount ? (
        <SuperAccountCheckboxAndInfo
          additionalText={additionalDescription}
          text={descriptionText}
          isChecked={isBoxChecked}
          setIsChecked={setIsBoxChecked}
        />
      ) : null}
      {isBoxChecked ? (
        <KvCoreLogoContainer>
          <KvCoreLogoImg alt='company logo' src={companyLogo} />
        </KvCoreLogoContainer>
      ) : (
        <>
          {!isSuperAccount ? <InfoBanner text={bannerText} /> : null}
          <Container>
            <LogoPickerContainer>
              <LogoPickerComponent
                key={initLogos?.first?.value || ''}
                initImgSrc={initLogos?.first?.value || ''}
                file={logoFiles[0]}
                btnText='Add Logo 1'
                setFile={onFileChange(0)}
                isDisabled={locked}
              />
              {canLockFirstLogo && (
                <Lock
                  isLocked={locked}
                  canLock={canLockFirstLogo}
                  onClick={() => setLocked(prev => !prev)}
                />
              )}
            </LogoPickerContainer>
            <LogoPickerContainer>
              <LogoPickerComponent
                key={logoFiles[1]?.size}
                initImgSrc={initLogos?.second?.value || ''}
                file={logoFiles[1]}
                btnText='Add Logo 2'
                setFile={onFileChange(1)}
                isDisabledAdding={!isUserLevel}
              />
            </LogoPickerContainer>
            <LogoPickerContainer>
              <LogoPickerComponent
                key={logoFiles[2]?.size}
                initImgSrc={initLogos?.third?.value || ''}
                file={logoFiles[2]}
                btnText='Add Logo 3'
                setFile={onFileChange(2)}
                isDisabledAdding={!isUserLevel}
              />
            </LogoPickerContainer>
          </Container>
        </>
      )}
    </Accordion>
  );
};

const Container = styled.div`
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
`;

const LogoPickerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
