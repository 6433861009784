import React from 'react';
import { useAtomValue } from 'jotai';

import { activeCompetitionData } from '../data';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { SlideWithGraphWrapperPdf } from '../../../../../components/common/SlideWithGraphWrapperPdf';
import { getWeeksOnMarket } from '../../../../../../../../services/getWeeksOnMarket';
import ActiveCompetitionChart from '../../../../../../../../features/report/charts/active-competition/ActiveCompetitionChart';
import { reportPropertiesAtom } from '../../../../../../../../features/report/state/reportPropertiesAtom';
import { competitionGraphAtom } from '../../../../../../../../features/report/state/competitionGraphAtom';

const ActiveCompetitionPdf: React.FC = () => {
  const { category } = useCategory();
  const properties = useAtomValue(reportPropertiesAtom);
  const data = useAtomValue(competitionGraphAtom);

  const onMarket = properties
    .filter(prop => prop.status === 'active' && !prop.excluded)
    .map(prop => prop.property);
  const countOfFresh = onMarket.filter((p: any) => p.daysOnMarket <= 84).length;
  const countOfStale = onMarket.filter((p: any) => p.daysOnMarket > 84).length;
  const weeksOnMarket = getWeeksOnMarket(onMarket);

  const subtitle = !weeksOnMarket
    ? ''
    : `${activeCompetitionData.subtitle} has been on market for ${weeksOnMarket} ${
        weeksOnMarket > 1 ? 'weeks' : 'week'
      }`;

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SlideWithGraphWrapperPdf
        heading={activeCompetitionData.heading}
        title={!data?.count ? 'N/A' : countOfFresh > countOfStale ? 'Fresh' : 'Stale'}
        subtitle={subtitle}
        description={activeCompetitionData.description}
        graphPadding='0px'
        isReversed
        noDisclaimer
      >
        <ActiveCompetitionChart height={480} isPdf />
      </SlideWithGraphWrapperPdf>
    </PdfFrame>
  );
};

export default ActiveCompetitionPdf;
