import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../constants/categories';
import { PdfFrame } from '../../../components/SlideFrame/frames/pdf/PdfFrame';
import SectionItem from './SectionItem';
import sectionsMap from '../data';
import { TSection } from '../../../../../types';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: '80px 0 80px 70px',
  position: 'relative',
});

interface Props {
  activeSection: TSection;
}
const SectionContentsTraditionalPDF: React.FC<Props> = ({ activeSection }) => {
  const { sections, sectionsSlide } = useOrder();
  const { slides } = useSlides();
  const sectionIds = sections.filter(id => id !== 'startingSlides') as TSection[];

  const getSectionSlides = (sectionId: TSection) => {
    if (!slides) return [];

    return sectionsSlide[sectionId].map(slideId => slides[slideId]);
  };

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL} hideProgressTracker>
      <Container>
        {sectionIds
          .filter(s => getSectionSlides(s)?.length)
          .map((section, i) => (
            <SectionItem
              key={section}
              {...sectionsMap[section]}
              isActive={section === activeSection}
              order={i + 1}
              isLast={i === sectionIds.length - 1}
            />
          ))}
      </Container>
    </PdfFrame>
  );
};

export default SectionContentsTraditionalPDF;
