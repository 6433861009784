import { useState, useEffect } from 'react';

import { propertyTypeOptions } from '../propertyTypeOptions';
import { EditableSelect } from '../../components/EditableSelect';

export const PropertyTypeEdit = ({ subject, defaultValue, onSubjectChange }) => {
  const [editValue, setEditValue] = useState({
    value: subject.value,
    label: subject.label,
  });
  const [enterPressed, setEnterPressed] = useState(false);
  useEffect(() => {
    onEditConfirm();
  }, [enterPressed]);

  const onEditConfirm = () => {
    if (!editValue) {
      return;
    }
    onSubjectChange({ ...editValue });
  };

  const onUndoClick = () => {
    setEnterPressed(false);
    onSubjectChange({ ...defaultValue });
    setEditValue({ ...defaultValue });
  };

  const confirmClick = value => {
    setEditValue(value);
    setEnterPressed(true);
  };

  return (
    <EditableSelect
      label={subject.label}
      value={subject.value}
      defaultValue={defaultValue.value}
      onConfirm={onEditConfirm}
      onUndo={onUndoClick}
      enterPressed={enterPressed}
      editValue={editValue}
      onChange={confirmClick}
      options={propertyTypeOptions}
    />
  );
};
