import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { graphItems, listItems } from '../../data';

import slideGraphics from './graph';

const GraphWrapper = styled('div')(theme => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  borderTop: '1px solid #e5e5e5',
  borderBottom: '1px solid #e5e5e5',
  fontFamily: useUiConfigFontsBody(),
}));

const GraphImage = styled('img')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  objectFit: 'cover',
});

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 40px;
  font-size: 26px;
  font-weight: 900;
  text-transform: uppercase;
  position: absolute;
  width: 25%;
  margin-top: 33;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

export const HomeSellingProcessTraditionalPdf: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const img = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);
  const letterSpacing = useUiConfigBodyLetterSpacing();

  const headingStyles: React.CSSProperties = {
    color: '#000',
    fontSize: 20,
    fontWeight: 900,
    textAlign: 'left',
  };

  const textStyles: React.CSSProperties = {
    textAlign: 'left',
    lineHeight: 1.3,
    fontSize: 16,
  };

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <GraphWrapper>
        <GraphImage src={img} />
        <Heading>The Home Selling Process</Heading>
        <TraditionalSlideCircularCard
          heading='Start'
          headingStyles={{ color: '#fff', fontSize: 8, fontWeight: 900 }}
          position={{ top: -3, left: 358 }}
          size={100}
        />
        <TraditionalSlideCircularCard
          heading={graphItems[0].shortText}
          position={{ top: 29, left: 250 }}
          headingStyles={{ fontSize: 12 }}
          size={125}
        />
        <TraditionalSlideCircularCard
          heading={graphItems[1].shortText}
          position={{ top: 138, left: 133 }}
          headingStyles={{ fontSize: 12 }}
          size={125}
        />
        <TraditionalSlideCircularCard
          heading={graphItems[2].shortText}
          position={{ top: 267, left: 87 }}
          headingStyles={{ fontSize: 12 }}
          size={125}
        />
        <TraditionalSlideCircularCard
          heading={graphItems[3].shortText}
          position={{ top: 384, left: 103 }}
          headingStyles={{ fontSize: 12 }}
          size={125}
        />
        <TraditionalSlideCircularCard
          heading={graphItems[4].shortText}
          position={{ top: 497, left: 184 }}
          headingStyles={{ fontSize: 12 }}
          size={142}
        />
        <TraditionalSlideCircularCard
          heading={graphItems[5].shortText}
          position={{ top: 624, left: 192 }}
          headingStyles={{ fontSize: 12 }}
          size={126}
        />
        <TraditionalSlideCircularCard
          heading={graphItems[6].shortText}
          position={{ top: 744, left: 210 }}
          headingStyles={{ fontSize: 12 }}
          size={126}
        />
        <TraditionalSlideCircularCard
          heading={graphItems[7].shortText}
          position={{ top: 863, left: 250 }}
          headingStyles={{ fontSize: 12 }}
          size={126}
        />
        <TraditionalSlideCircularCard
          heading='Home Sold'
          headingStyles={{ color: '#fff', fontSize: 8, fontWeight: 900 }}
          position={{ top: 912, left: 367 }}
          size={100}
        />
        <TraditionalSlideCircularCard
          heading={listItems[0].title}
          text={listItems[0].text}
          containerStyles={{ borderRadius: 0, alignItems: 'flex-start', letterSpacing }}
          headingStyles={headingStyles}
          textStyles={textStyles}
          position={{ top: -15, left: 567 }}
          size={304}
        />
        <TraditionalSlideCircularCard
          heading={listItems[1].title}
          text={listItems[1].text}
          containerStyles={{ borderRadius: 0, alignItems: 'flex-start', letterSpacing }}
          headingStyles={headingStyles}
          textStyles={textStyles}
          position={{ top: 244, left: 567 }}
          size={304}
        />
        <TraditionalSlideCircularCard
          heading={listItems[2].title}
          text={listItems[2].text}
          containerStyles={{ borderRadius: 0, alignItems: 'flex-start', letterSpacing }}
          headingStyles={headingStyles}
          textStyles={textStyles}
          position={{ top: 483, left: 567 }}
          size={304}
        />
        <TraditionalSlideCircularCard
          heading={listItems[3].title}
          text={listItems[3].text}
          containerStyles={{ borderRadius: 0, alignItems: 'flex-start', letterSpacing }}
          headingStyles={headingStyles}
          textStyles={textStyles}
          position={{ top: 727, left: 567 }}
          size={304}
        />
      </GraphWrapper>
    </PdfFrame>
  );
};
