import { PropsWithChildren } from 'react';
import { CSSObject, useTheme } from 'styled-components';

import { Modal } from '../../Common';
import { IconName } from '../Icon/Icon.types';
import { Content, ModalFooter } from './FitContentModal.styles';
import { ModalDescription } from './ModalDescription/ModalDescription';
import { Button } from '../../Common/V2/Button/Button';
import { useIsMobileView } from '../../../hooks/useIsMobileView';

type TModalDescription = {
  iconName?: IconName;
  text?: string;
  buttonText?: string;
  onButtonClick?: () => void;
};

type FitContentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  headerTitle: string;
  loading?: boolean;
  disabled?: boolean;
  contentDisplay?: string;
  hasCancelBtn?: boolean;
  confirmBtnText?: string;
  hasConfirmBtn?: boolean;
  contentStyles?: CSSObject;
  onConfirmClick?: () => void;
  modalContainerStyles?: CSSObject;
  prefixButtonsContent?: JSX.Element | null;
  modalDescription?: TModalDescription;
  modalDescriptionContainerStyles?: CSSObject;
};

export function FitContentModal({
  isOpen,
  onClose,
  loading,
  children,
  disabled,
  headerTitle,
  hasCancelBtn,
  contentStyles,
  onConfirmClick,
  contentDisplay,
  modalDescription,
  prefixButtonsContent,
  modalContainerStyles,
  hasConfirmBtn = true,
  confirmBtnText = 'OK',
  modalDescriptionContainerStyles,
}: PropsWithChildren<FitContentModalProps>) {
  const isMobile = useIsMobileView();

  return (
    <Modal
      open={isOpen}
      title={headerTitle}
      onClose={onClose}
      modalContainerStyles={{
        width: 'auto',
        height: 'max-content',
        minWidth: isMobile ? '98%' : '680px',
        maxHeight: 'calc(100vh - 60px)',
        top: '50%',
        transform: 'translateY(-50%)',
        overflow: 'hidden',
        borderRadius: '0px 0px 5px 5px',
        ...modalContainerStyles,
      }}
      contentStyles={{ height: 'auto', ...contentStyles }}
      zIndex={50}
      isBlueHeader
    >
      {modalDescription && (
        <ModalDescription
          iconName={modalDescription.iconName}
          descriptionText={modalDescription.text}
          buttonText={modalDescription.buttonText}
          onButtonClick={modalDescription.onButtonClick}
          modalDescriptionContainerStyles={modalDescriptionContainerStyles}
        />
      )}
      <Content contentDisplay={contentDisplay}>{children}</Content>
      {(hasConfirmBtn || hasCancelBtn) && (
        <ModalFooter>
          {prefixButtonsContent}
          {hasCancelBtn && (
            <Button variant='secondary' onClick={onClose} disabled={loading}>
              Cancel
            </Button>
          )}
          {hasConfirmBtn && (
            <Button variant='primary' onClick={onConfirmClick} disabled={disabled || loading}>
              {confirmBtnText}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
}
