import React, { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { IConfigKeeper } from '../../../../providers/providers/OrderProvider';
import { TConfig } from '../../../../providers/providers/SectionsProvider';
import {
  TDynamicSectionId,
  TPresentationMode,
  TPresentationModeConfig,
  TSlideId,
} from '../../../../types';

export class DashboardItemsUtils {
  static copyToClipboard(inputRef: React.RefObject<HTMLInputElement>, presentationUrl: string) {
    const copyText = inputRef.current;
    if (!copyText) return;
    copyText.value = presentationUrl;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    toast('Copied to clipboard', { type: 'info', position: 'bottom-center' });
  }

  static removeSlideFromModes(
    slideId: TSlideId,
    orderByMode: IConfigKeeper,
    sectionsByMode: TConfig,
    setSectionsSlideOrder: (sectionsSlide: Record<TDynamicSectionId, TSlideId[]>) => void,
  ) {
    const modesConfig: Partial<Record<TPresentationMode, TPresentationModeConfig>> = {};
    const modes: TPresentationMode[] = ['cma', 'oneSheeter', 'regular'];

    modes.forEach(mode => {
      const modeOrderConfig = orderByMode[mode];
      const modeSectionsConfig = sectionsByMode[mode];

      if (!modeOrderConfig || !modeSectionsConfig) return;

      const section =
        mode === 'regular' && slideId === 'netProceeds'
          ? 'closingAndNextSteps'
          : modeOrderConfig.sections[0];

      const modeSectionSlideOrder = modeOrderConfig.sectionsSlide[section] || [];
      const filteredSlidesOrder = modeSectionSlideOrder.filter(sId => sId !== slideId);

      const newSectionSlidesOrder = {
        ...modeOrderConfig.sectionsSlide,
        [section]: filteredSlidesOrder,
      };

      setSectionsSlideOrder(newSectionSlidesOrder);

      modesConfig[mode] = {
        order: {
          sections: modeOrderConfig.sections,
          sectionsSlide: newSectionSlidesOrder,
        },
        sections: modeSectionsConfig,
      };
    });

    return modesConfig;
  }
}
