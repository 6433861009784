import { Info } from './Info';
import { Features } from './Features';
import { Description } from './Description';
import { TProperty } from '../../../../types';
import { MlsDisclaimer } from './MlsDisclaimer';
import { SectionDropdown } from '../components/SectionDropdown';
import { DropdownKeys } from '../../../../features/report/components/Property/DetailDescription/DetailDescription';

interface Props {
  property: TProperty;
  openedDropdown: string;
  setOpenedDropdown: (value: string) => void;
}

export const ListingInfoAndDescription = ({
  property,
  openedDropdown,
  setOpenedDropdown,
}: Props) => (
  <SectionDropdown
    title={'Listing Info & Description'.toUpperCase()}
    isOpen={openedDropdown === DropdownKeys.LISTING_INFO_AND_DESCRIPTION}
    setOpen={() => {
      if (openedDropdown === DropdownKeys.LISTING_INFO_AND_DESCRIPTION)
        return setOpenedDropdown('');
      setOpenedDropdown(DropdownKeys.LISTING_INFO_AND_DESCRIPTION);
    }}
  >
    <Info property={property} />
    <Features property={property} />
    <Description property={property} />
    <MlsDisclaimer property={property} />
  </SectionDropdown>
);
