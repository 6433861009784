import styled from 'styled-components';

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => (
  <Container>
    <TitleText>{title}</TitleText>
  </Container>
);

const Container = styled.div`
  text-align: left;
`;

const TitleText = styled.h3`
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
  margin-bottom: 50px;
`;
