import { TSlide } from '../../../../../types';
import { BuyerInterestPdf } from './pdf/BuyerInterestPdf';
import { BuyerInterest } from './web/BuyerInterest.component';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';

const id = 'buyerInterest';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Buyer Interest',
  frame: true,
  buyerInterestData: null,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    shared: BuyerInterestPdf,
  },
  web: {
    shared: BuyerInterest,
  },
};

export type TBuyerInterest = typeof id;
export { config, mappings };
