import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { Container, ImageWrapper, ImagesWrapper, ImagePreview } from './FourImagesGamma.styles';
import { ImageProxy } from '../../../../services/ImageProxy';

interface Props {
  reverse?: boolean;
  horizontal?: boolean;
}

export const FourImagesGammaPdf: React.FC<Props> = ({ reverse, horizontal }) => {
  const slide = useSlide();
  const { category } = useCategory();

  let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'column';

  if (horizontal) flexDirection = 'row';
  if (horizontal && reverse) flexDirection = 'row-reverse';
  if (!horizontal && reverse) flexDirection = 'column-reverse';

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container isPdf={!horizontal} style={{ flexDirection }}>
        <SlideTextBlock
          isEditable={false}
          text={slide.data?.text}
          headline={slide.data?.headline}
          containerStyles={{
            height: horizontal ? '100%' : '49%',
            width: horizontal ? 'calc(55% - 30px)' : '100%',
          }}
        />
        <ImagesWrapper
          style={{
            display: horizontal ? 'grid' : 'flex',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 1fr',
            padding:
              horizontal && reverse ? '0 0 30% 0' : horizontal && !reverse ? '30% 0 0 0' : '0',
            flexDirection: horizontal ? 'column' : 'row',
            width: horizontal ? 'calc(45% - 30px)' : '100%',
            marginRight: horizontal && reverse ? 60 : 0,
            marginLeft: horizontal && !reverse ? 60 : 0,
            marginBottom: horizontal || !reverse ? 0 : 40,
            marginTop: horizontal || reverse ? 0 : 40,
            height: horizontal ? '100%' : '45%',
          }}
        >
          <ImageWrapper>
            <ImagePreview src={ImageProxy.optimize(slide.data?.image, { width: 750 })} />
          </ImageWrapper>
          <ImageWrapper>
            <ImagePreview src={ImageProxy.optimize(slide.data?.image2, { width: 750 })} />
          </ImageWrapper>
          <ImageWrapper>
            <ImagePreview src={ImageProxy.optimize(slide.data?.image3, { width: 750 })} />
          </ImageWrapper>
          <ImageWrapper>
            <ImagePreview src={ImageProxy.optimize(slide.data?.image4, { width: 750 })} />
          </ImageWrapper>
        </ImagesWrapper>
      </Container>
    </PdfFrame>
  );
};
