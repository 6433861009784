import React from 'react';

import { InfoModal } from '../../components/InfoModal/InfoModal';
import { withUserContext } from '../../components/hoc/withUserContext';

export const UserDataIssuesWarnings = withUserContext(({ user }) => {
  const isAffectedByDataIssue = () => [8, 10].includes(user?.mls?.id);

  if (!isAffectedByDataIssue()) {
    return null;
  }

  return (
    <InfoModal
      storageKey='dataIssue-v2'
      subject='Important information'
      text='We are aware of missing listings coming from VCRDS (Ventura County) and i-Tech feeds and are diligently working on a resolution.'
    />
  );
});
