import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../providers/providers/UiConfigColorProvider';
import { useUiConfigBodyLetterSpacing } from '../../../providers/providers/UiConfigFontProvider';

type WithChecked = {
  isChecked: boolean;
};

export const CheckboxContainer = styled('label')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const CheckboxInput = styled('input')({
  opacity: 0,
  height: 0,
  width: 0,
});

export const CheckboxControl = styled('div')<WithChecked>(({ isChecked }) => ({
  height: 24,
  width: 24,
  backgroundColor: '#fff',
  border: '2px solid #888888',
  position: 'relative',
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',
  '&:after': {
    position: 'absolute',
    content: '""',
    width: 7,
    height: 14,
    top: '42%',
    left: '50%',
    transform: 'translate(-50%, -50%)  rotate(45deg)',
    borderStyle: 'solid',
    borderColor: isChecked ? 'black' : '#fff',
    borderWidth: '0 3px 3px 0',
  },
}));

export const CheckboxText = styled('p')(() => ({
  fontSize: 22,
  color: '#000',
  marginLeft: 12,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));
