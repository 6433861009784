import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ButtonVariants } from './types';
import { getButtonVariantStyles } from './getButtonVariantStyles';
import { ColorOpacityApplier } from '../../../Slide/services/ColorOpacityApplier';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'small' | 'large';
  disabled?: boolean;
  isLoading?: boolean;
  variant?: ButtonVariants;
  prefixIcon?: JSX.Element | null;
  suffixIcon?: JSX.Element | null;
};

export function Button({
  variant = 'primary',
  size = 'small',
  isLoading = false,
  prefixIcon = null,
  suffixIcon = null,
  children,
  ...props
}: Props) {
  return (
    <StyledButton size={size} variant={variant} isLoading={isLoading} {...props}>
      {prefixIcon ? prefixIcon : null}
      {children}
      {suffixIcon ? suffixIcon : null}
    </StyledButton>
  );
}

const StyledButton = styled.button<{
  size: 'small' | 'large';
  variant: ButtonVariants;
  isLoading: boolean;
}>`
  // Layout
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  height: ${({ size }) => (size === 'small' ? '35px' : '50px')};
  min-height: ${({ size }) => (size === 'small' ? '35px' : '50px')};
  max-height: ${({ size }) => (size === 'small' ? '35px' : '50px')};
  padding: ${({ size }) => (size === 'small' ? '0px 10px' : '0px 20px')};
  ${({ theme, variant }) => ({ ...getButtonVariantStyles(theme, variant) })};

  // Font
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  text-align: center;
  font-style: normal;
  line-height: normal;
  :disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  ::after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 2px;
    animation: sliding 1500ms infinite ease-in-out;
    background-color: ${({ theme, variant }) =>
      ColorOpacityApplier.hex(
        ['secondary', 'clear', 'link'].includes(variant)
          ? theme.colors.v2.text.regular
          : theme.colors.v2.text.contrastText,
        '0.8',
      )};
    display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};

    @keyframes sliding {
      0% {
        left: 0px;
        right: 100%;
        border-radius: 0 5px 5px 5px;
      }
      50% {
        left: 0px;
        right: 0px;
      }
      100% {
        left: 100%;
        right: 0px;
        border-radius: 5px 0 0 5px;
      }
    }
  }
`;
