import React from 'react';
import styled from 'styled-components';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  color: '#888888',
  fontSize: 12,
  marginLeft: 20,
  lineHeight: 1.4,
});

const Icon = styled('img')({
  marginRight: 8,
  height: 14,
  width: 'auto',
  transform: 'translateY(-1.5px)',
});

const UserImg = styled('img')({
  height: 60,
  width: 60,
  marginRight: 10,
  borderRadius: '50%',
  objectFit: 'cover',
  objectPosition: 'top',
});

interface Props {
  img: string;
  content: string;
}

export const InfoItem: React.FC<Props> = ({ img, content }) => {
  return (
    <Container>
      <Icon src={img} />
      {content}
    </Container>
  );
};

interface UserProps {
  img: string;
  name: string;
  dre: string;
}

export const UserItem: React.FC<UserProps> = ({ img, name, dre }) => {
  return (
    <Container style={{ flex: 1 }}>
      <UserImg src={img} />
      {name}
      <br />
      {dre}
    </Container>
  );
};
