import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PROPERTY_STATUS, PropertyGroups, TProperty } from '../../../../../../types';
import { getPropertyStatusBasedConfigs } from '../../../../../../services/getPropertyStatusBasedConfigs';
import { ShowPropertiesOnMap } from '../ShowPropertiesOnMap';
import styled from 'styled-components';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { isPresentationViewAtom } from '../../../../state/isPresentationViewAtom';
import { HomePreview } from './HomePreview/HomePreview';

interface Props {
  title: string;
  type: PROPERTY_STATUS | 'coming_soon';
  properties: TProperty[] | null;
  hide?: boolean;
  propertiesRef: React.MutableRefObject<HTMLElement | null>;
}

export const HomesGroup = ({ title, type, properties, hide, propertiesRef }: Props) => {
  const { color, backgroundColor, icon } = getPropertyStatusBasedConfigs(type);
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const includedPropertiesOnly = useMemo(() => properties?.filter(p => !p.excluded), [properties]);

  const propertiesToShow = useMemo(() => {
    if (isPresentationView) return includedPropertiesOnly;

    return properties;
  }, [includedPropertiesOnly, isPresentationView, properties]);

  if (hide) return null;

  return (
    <PropertiesGroup>
      <Title style={{ color, backgroundColor }}>
        {title} ({includedPropertiesOnly?.length || 0})
        <ShowPropertiesOnMap type={type} />
      </Title>

      <List>
        {propertiesToShow?.map(property => (
          <HomePreview key={property.id} property={property} propertiesRef={propertiesRef} />
        ))}
      </List>
    </PropertiesGroup>
  );
};

const PropertiesGroup = styled.div`
  position: relative;
`;

const Title = styled.h5`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 30px;
  height: 28px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
`;

const List = styled.ul`
  overflow: hidden;
  transition: all 0.5s ease;
  margin-bottom: 10px;
  list-style-type: none;
  max-height: none;
`;
