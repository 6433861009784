import { useMemo } from 'react';

import { TSlide } from '../types';
import { useUser } from '../components/Slide/providers/UserProvider';
import { usePersonalizedWithDynamicSlides } from './usePersonalizedWithDynamicSlides';

const isPersonalSlide = (slide: TSlide) => ['testimonials', 'aboutMe'].includes(slide.id);

export const useLibraryDynamicSlides = (hash: string) => {
  function filterLevelSlide(slide: any, superaccounts: any[], useSpecialBranding: boolean) {
    if (isPersonalSlide(slide)) return true;

    const isSuperAdmin = superaccounts?.find((sa: any) => slide?.entityId === sa.id && sa.isAdmin);
    if (isSuperAdmin) return true;

    if (slide?.entityId === -2 && !useSpecialBranding) return false;

    return true;
  }

  const { useSpecialBranding, superaccounts } = useUser();
  const dynamic = usePersonalizedWithDynamicSlides(hash);

  const slidesMap = useMemo((): Record<string, TSlide> => {
    return (Object.values(dynamic?.data ?? {})
      .filter(slide => filterLevelSlide(slide, superaccounts, useSpecialBranding))
      .reduce((map: any, slide: any) => ({ ...map, [slide?.id]: { ...slide } }), {}) ??
      {}) as Record<string, TSlide>;
  }, [dynamic?.data, superaccounts, useSpecialBranding]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ ...dynamic, data: slidesMap }), [slidesMap]);
};
