import { useSetDefaultCategories } from './useSetDefaultCategories';
import { RawReport } from '../../../types';
import { useSetAtom } from 'jotai';
import { customEstimatesAtom } from '../state/customEstimatesAtom';
import { estimatesAtom } from '../state/estimatesAtom';
import { useSetDefaultHighlights } from './useSetDefaultHighlights';

export const useSetDefaultResultState = () => {
  const setDefaultCategories = useSetDefaultCategories();
  const setCustomEstimates = useSetAtom(customEstimatesAtom);
  const setEstimates = useSetAtom(estimatesAtom);
  const setDefaultHighlights = useSetDefaultHighlights();

  const setDefaultResultState = (reportDetailed: RawReport) => {
    setDefaultCategories(reportDetailed);
    setCustomEstimates([]);
    setEstimates(reportDetailed?.estimates);
    setDefaultHighlights(reportDetailed);
  };

  return setDefaultResultState;
};
