import React from 'react';
import { Switch } from '../../../../../../../../components/Common/V2/Switch';
import { SwitchWrapper, Text } from './PersonalizationSwitch.styles';

interface PersonalizationSwitchProps {
  text: string;
  checked: boolean;
  onSwitch: (val: boolean) => void;
}

export const PersonalizationSwitch: React.FC<PersonalizationSwitchProps> = ({
  text,
  checked,
  onSwitch,
}) => {
  return (
    <SwitchWrapper>
      <Switch checked={checked} onChange={() => onSwitch(checked)} />
      <Text>{text}</Text>
    </SwitchWrapper>
  );
};
