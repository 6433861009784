import styled from 'styled-components';

export const InnerContent = styled.div`
  display: flex;
  flex: 1;
  gap: 24px;
  overflow-y: auto;
  flex-direction: column;
  padding: 24px 24px 24px 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 250px);
`;

export const SelectorContainer = styled.div`
  display: flex;
`;
