import React, { useState } from 'react';

import Loader from '../../../components/Loader/Loader';
import { TSlideId, TSlideInfoPlacement, TSlideTheme, TUser } from '../../../types';
import PreviewSlide from '../../../components/PreviewSlide/PreviewSlide.component';
import { SlidePreviewContainer, SlidePreviewWrapper } from './SlidePreviewContent.styles';
import { useSlides } from '../../../providers/providers/SlidesProvider';

interface SlidePreviewContentProps {
  slideId: string;
  theme: TSlideTheme;
  infoPlacement: TSlideInfoPlacement;
  content: string;
  user: TUser | null;
  isLoading?: boolean;
}

export const SlidePreviewContent: React.FC<SlidePreviewContentProps> = ({
  slideId,
  theme,
  infoPlacement,
  user,
  isLoading,
}) => {
  const { getSlide } = useSlides();
  const previewSlide = getSlide(slideId as TSlideId);

  if (!user || !previewSlide) return null;

  return (
    <SlidePreviewContainer>
      <SlidePreviewWrapper>
        {isLoading ? (
          <Loader centered size={'3x'} />
        ) : (
          <PreviewSlide
            slide={previewSlide}
            theme={theme}
            infoPlacement={infoPlacement?.value}
            user={user}
            hideProgressTracker
          />
        )}
      </SlidePreviewWrapper>
    </SlidePreviewContainer>
  );
};
