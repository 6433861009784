import React from 'react';
import image from './image.png';
import { listItems } from './data';
import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';

export const HomeBuyingProcessLuxury: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading='Home Buying Process'
      subtitle='At a high level, the home buying process can be structured as 5 key steps:'
      imagePosition='right'
      image={image}
      textContainerStyles={{ width: '50%', padding: 25, paddingTop: 35 }}
      contentStyles={{ lineHeight: 1.5 }}
      imageStyles={{ width: '70%' }}
      listItems={listItems}
    />
  );
};
