import { useEffect } from 'react';

import { useOrder } from '../../../../../../../../providers/providers/OrderProvider';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { useInheritanceCategoryCustomizations } from './useInheritanceCategoryCustomizations';
import { usePresentationType } from '../../../../../../../../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../../../../../../../../providers/providers/PresentationModeProvider';
import { PresentationConfigsFactory } from '../../../../../PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';

export function useUpdateOrderingOnCategoryChange({
  isDefaultOrder,
}: {
  isDefaultOrder?: boolean;
}) {
  const { setSectionsOrder, setSectionsSlideOrder } = useOrder();
  const { category } = useCategory();

  const { presentationType } = usePresentationType();
  const { presentationMode } = usePresentationMode();

  const { categoryCustomizations, isLoading, isFetching } = useInheritanceCategoryCustomizations();
  /**
   * Set the order of sectionsSlide and sections from the user customization if available or
   * from default congis when default emphasis is selected.
   */
  useEffect(() => {
    if (isLoading || isFetching) return;

    let reorderedSections = null;
    let reorderedSectionSlides = null;

    if (isDefaultOrder && categoryCustomizations) {
      reorderedSections = categoryCustomizations?.sections;
      reorderedSectionSlides = categoryCustomizations?.sectionsSlide;
    }

    if (isDefaultOrder && !categoryCustomizations) {
      const defaultOrder = PresentationConfigsFactory.create(
        presentationType,
        presentationMode,
      ).getDefaultOrder();

      reorderedSections = defaultOrder.sections;
      reorderedSectionSlides = defaultOrder.sectionsSlide;
    }

    if (reorderedSections) setSectionsOrder(reorderedSections);
    if (reorderedSectionSlides) setSectionsSlideOrder(reorderedSectionSlides);
  }, [
    category,
    isDefaultOrder,
    categoryCustomizations,
    isLoading,
    isFetching,
    setSectionsOrder,
    setSectionsSlideOrder,
    presentationType,
    presentationMode,
  ]);
}
