import { PropsWithChildren } from 'react';

import styled from 'styled-components';

type Props = {
  title: string;
};

export function Card({ children, title }: PropsWithChildren<Props>) {
  return (
    <Container>
      <Title>{title}</Title>
      <Body>{children}</Body>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 250px;
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
`;

const Title = styled.p`
  display: flex;
  gap: 10px;
  height: 35px;
  padding: 0px 10px;
  align-items: center;
  align-self: stretch;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  background: ${({ theme }) => theme.colors.v2.gray[100]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

const Body = styled.div`
  display: flex;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  padding: 13px 20px;
`;
