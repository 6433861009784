import { TSlide } from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { ImportanceOfStagingTraditionalPdf } from './traditional/pdf/ImportanceOfStagingTraditionalPdf';
import { ImportanceOfStagingModernPdf } from './modern/pdf/ImportanceOfStagingModernPdf';
import { ImportanceOfStagingLuxuryPdf } from './luxury/pdf/ImportanceOfStagingLuxuryPdf';
import { ImportanceOfStagingTraditional } from './traditional/web/ImportanceOfStagingTraditional';
import { ImportanceOfStagingModern } from './modern/web/ImportanceOfStagingModern';
import { ImportanceOfStagingLuxury } from './luxury/web/ImportanceOfStagingLuxury';

const id = 'importanceOfStaging';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Importance of Staging',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    [CATEGORIES.LUXURY]: ImportanceOfStagingLuxuryPdf,
    [CATEGORIES.MODERN]: ImportanceOfStagingModernPdf,
    [CATEGORIES.TRADITIONAL]: ImportanceOfStagingTraditionalPdf,
  },
  web: {
    [CATEGORIES.TRADITIONAL]: ImportanceOfStagingTraditional,
    [CATEGORIES.MODERN]: ImportanceOfStagingModern,
    [CATEGORIES.LUXURY]: ImportanceOfStagingLuxury,
  },
};

export type TImportanceOfStaging = typeof id;
export { config, mappings };
