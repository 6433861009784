import React from 'react';
import styled from 'styled-components';

import { CATEGORIES } from '../../../../../../constants/categories';
import { Report } from '../../../../../../pages/PresentationCreate/dash';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import {
  CriteriaReviewBox,
  CriteriaReviewBoxBT,
} from '../components/CriteriaReviewBox/CriteriaReviewBox';
import { SlideDescription, SlideDescriptionBT } from '../components/SlideDescription';
import { ReportInfoSectionPdf, ReportInfoSectionBTPdf } from './ReportInfoSectionPdf';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { Map } from '../../../../../../features/BuyerTour/components/Map/Map.component';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InfoSection = styled.div`
  display: flex;
`;

const LeftCol = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  margin-right: 20px;
`;

const MapWrapper = styled.div`
  margin-top: 20px;
  height: 650px;
`;

const MapHeading = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 18px;
  background: ${() => ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '0.05')};
  border: 1px solid #f2f2f2;
  padding-left: 10px;
  & span {
    font-weight: 700;
  }
`;

const ComparativeMarketAnalysisRegularPdf: React.FC = () => {
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <InfoSection>
          <LeftCol>
            <ReportInfoSectionPdf />
            <SlideDescription isPdf />
          </LeftCol>

          <CriteriaReviewBox isPdf style={{ flexGrow: 1, flexBasis: '50%' }} />
        </InfoSection>

        <MapWrapper>
          <Report.Components.ComparativeMarketAnalysis height={650} isPdf />
        </MapWrapper>
      </Container>
    </PdfFrame>
  );
};

const ComparativeMarketAnalysisBTPdf: React.FC = () => {
  const { category } = useCategory();
  const { properties } = useProperties();
  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <InfoSection>
          <LeftCol>
            <ReportInfoSectionBTPdf />
            <SlideDescriptionBT isPdf />
          </LeftCol>
          <CriteriaReviewBoxBT isPdf style={{ flexGrow: 1, flexBasis: '50%' }} />
        </InfoSection>

        <MapWrapper>
          <MapHeading>
            <span style={{ marginRight: 6 }}>{properties.length}</span> Total Properties Found
          </MapHeading>
          <Map properties={properties} cma useNumberedMarkers />
        </MapWrapper>
      </Container>
    </PdfFrame>
  );
};

const ComparativeMarketAnalysisPdf: React.FC = () => {
  const { presentationType } = usePresentationType();
  if (presentationType === 'buyerTour') return <ComparativeMarketAnalysisBTPdf />;
  return <ComparativeMarketAnalysisRegularPdf />;
};

export default ComparativeMarketAnalysisPdf;
