import { TProperty } from '../../../../../types';

export const getValidPropertyPrice = (
  property: TProperty,
  useSqft: boolean,
  useAdjustedPrice: boolean,
) => {
  if (useSqft && useAdjustedPrice) {
    return property?.adjustedPricePerSqft || property?.pricePerSqft;
  }

  if (useAdjustedPrice) {
    return property?.adjustedPrice || property.price;
  }

  if (useSqft) {
    return property?.pricePerSqft;
  }

  return property.price;
};
