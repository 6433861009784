import {
  IPresentationConfigs,
  TPresentationMode,
  TPresentationType,
  TSlide,
  TSlideId,
} from '../../types';
import { TDynamicSectionId } from '../../types';
import { BuyerTourConfigsUtility } from '../../features/BuyerTour/services/BuyerTourConfigsUtility';
import { CustomizationConfigs } from '../../features/Customization/services/CustomizationConfigs';
import { PresentationConfigsFactory } from '../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';
import { pushSlideToTheEndOfTheSlidesOrder } from '../pushSlideToTheEndOfTheSlideOrder';

export class OrderValidator {
  private presentationType?: TPresentationType;
  private presentationConfigs: IPresentationConfigs;

  constructor(presentationType?: TPresentationType, mode?: TPresentationMode) {
    this.presentationType = presentationType;
    this.presentationConfigs = this.presentationType
      ? PresentationConfigsFactory.create(this.presentationType, mode)
      : CustomizationConfigs;
  }

  getPartialSectionsOrder(partialSectionsOrder: TDynamicSectionId[]) {
    const sections = this.presentationConfigs.getSections();

    return partialSectionsOrder.filter((sectionId: TDynamicSectionId) => sections[sectionId]);
  }

  getFullSectionsOrder(partialSectionsOrder: TDynamicSectionId[]) {
    const { sections: referenceSectionsOrder } = this.presentationConfigs.getFullOrder();

    const validPartial = this.getPartialSectionsOrder(partialSectionsOrder);
    const restOfValid = referenceSectionsOrder.filter(
      sectionId => !validPartial.includes(sectionId),
    );

    return [...validPartial, ...restOfValid];
  }

  getPartialSectionsSlidesOrder(
    partialSectionsSlidesOrder: Record<TDynamicSectionId, TSlideId[]>,
    dynamicSlidesMap?: Record<string, TSlide>,
  ) {
    const referentSlides = this.presentationConfigs.getSlides();
    const referentSections = this.presentationConfigs.getSections();
    const sectionsSpecificSlides = this.presentationConfigs.getPredefinedSectionSlides();

    const sectionsSlides = {} as Record<TDynamicSectionId, TSlideId[]>;

    Object.keys(partialSectionsSlidesOrder).forEach(sectionId => {
      if (!referentSections[sectionId]) return;

      const validSectionSlides = partialSectionsSlidesOrder[sectionId].filter(slideId => {
        if (!referentSlides?.[slideId] && !dynamicSlidesMap?.[slideId]) return false;

        const predefinedSection = BuyerTourConfigsUtility.getSlideSection(
          slideId,
          sectionsSpecificSlides,
        );
        if (!predefinedSection) return true;

        return predefinedSection === sectionId;
      });

      sectionsSlides[sectionId] = Array.from(new Set([...validSectionSlides]));
    });

    return sectionsSlides;
  }

  getFullSectionsSlidesOrder(
    partialSectionsSlidesOrder: Record<TDynamicSectionId, TSlideId[]>,
    dynamicSlidesMap: Record<string, TSlide>,
  ) {
    const slides = this.presentationConfigs.getSlides();
    const { sectionsSlide: referenceSectionsSlidesOrder } = this.presentationConfigs.getFullOrder();

    const validPartialSectionsSlides = this.getPartialSectionsSlidesOrder(
      partialSectionsSlidesOrder,
      dynamicSlidesMap,
    );

    const sectionsSlides = {} as Record<TDynamicSectionId, TSlideId[]>;

    Object.keys(referenceSectionsSlidesOrder).forEach(sectionId => {
      if (!validPartialSectionsSlides[sectionId]) {
        sectionsSlides[sectionId] = referenceSectionsSlidesOrder[sectionId];
        return;
      }

      const restSectionSlides = referenceSectionsSlidesOrder[sectionId].filter(
        slideId => slides[slideId] && !validPartialSectionsSlides[sectionId].includes(slideId),
      );

      sectionsSlides[sectionId] = [...validPartialSectionsSlides[sectionId], ...restSectionSlides];
    });

    const fullSectionsSlide = {} as Record<TDynamicSectionId, Partial<TSlideId>[]>;
    Object.keys(sectionsSlides).forEach(sectionId => {
      const sectionDynamic = Object.values(dynamicSlidesMap).filter(
        slide => slide.sectionId === sectionId,
      );

      const missingDynamic = sectionDynamic
        .filter(dynamicSlide => !sectionsSlides[sectionId].some(s => s === dynamicSlide.id))
        .map(s => s.id as TSlideId);

      fullSectionsSlide[sectionId] = [...sectionsSlides[sectionId], ...missingDynamic];

      if (sectionId === 'closingAndNextSteps') {
        fullSectionsSlide['closingAndNextSteps'] = pushSlideToTheEndOfTheSlidesOrder(
          fullSectionsSlide['closingAndNextSteps'],
          'closingAndNextSteps',
        );
      }
      if (sectionId === 'startingSlides') {
        fullSectionsSlide['startingSlides'] = pushSlideToTheEndOfTheSlidesOrder(
          fullSectionsSlide['startingSlides'],
          'coverSlide',
        );
      }
    });

    return fullSectionsSlide;
  }

  getFullExtendedSectionsSlidesOrder(
    extendedSectionsSlidesOrder: Record<TDynamicSectionId, Partial<TSlide>[]>,
    dynamicSlides: Record<string, TSlide>,
  ) {
    const regularSlidesOrder = {} as Record<TDynamicSectionId, TSlideId[]>;
    Object.keys(extendedSectionsSlidesOrder).forEach(sectionId => {
      regularSlidesOrder[sectionId] =
        extendedSectionsSlidesOrder[sectionId]?.map(slide => slide.id as TSlideId) ?? [];
    });

    const fullSectionsSlides = this.getFullSectionsSlidesOrder(regularSlidesOrder, dynamicSlides);

    const fullExtendedSectionsSlides = {} as Record<TDynamicSectionId, Partial<TSlide>[]>;
    Object.keys(fullSectionsSlides).forEach(sectionId => {
      if (!extendedSectionsSlidesOrder[sectionId]) return;

      fullExtendedSectionsSlides[sectionId] = fullSectionsSlides[sectionId].map(slideId => {
        const slideInExtended = extendedSectionsSlidesOrder[sectionId].find(
          slide => slide.id === slideId,
        );

        if (slideInExtended) return slideInExtended;

        const missingSlide = dynamicSlides[slideId as string];

        return {
          id: slideId,
          includeSlide: missingSlide ? missingSlide.includeSlide : true,
        };
      });
    });

    return fullExtendedSectionsSlides;
  }
}
