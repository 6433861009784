import React from 'react';

import { AboutMeExpandible } from './AboutMeExpandible.component';
import { ModalContent, ModalContentLeft, ModalContentRight } from '../../../sections.styles';
import { useSlides } from '../../../../../../../../providers/providers/SlidesProvider';
import PreviewSlide from '../../../../../../../../components/PreviewSlide/PreviewSlide.component';
import { useUiConfigTheme } from '../../../../../../../../providers/providers/UiConfigThemeProvider';
import { PersonalizationSwitch } from '../../components/PersonalizationSwitch/PersonalizationSwitch.component';

export const ModalContentAbout: React.FC = () => {
  const { getSlide, setSlide } = useSlides();
  const { theme } = useUiConfigTheme();

  const slide = getSlide('aboutMe');

  const onSwitch = (prop: 'includeSlide' | 'displayImage') => {
    if (!slide) return alert('Something went wrong');

    const updatedSlide = { ...slide };
    if (prop === 'includeSlide') {
      updatedSlide.includeSlide = !updatedSlide.includeSlide;
    }

    if (prop === 'displayImage' && updatedSlide.data) {
      updatedSlide.data.displayImage = !slide.data?.displayImage;
    }

    setSlide(slide?.id, updatedSlide);
  };

  if (!slide || !slide.data) return null;

  const { displayImage } = slide.data;

  return (
    <ModalContent>
      <ModalContentLeft>
        <PreviewSlide key={slide?.id} slide={slide} theme={theme} isEditable isDefaultConfig />
      </ModalContentLeft>
      <ModalContentRight>
        <AboutMeExpandible />
        <PersonalizationSwitch
          text='Display image'
          onSwitch={() => onSwitch('displayImage')}
          checked={displayImage!}
        />
      </ModalContentRight>
    </ModalContent>
  );
};
