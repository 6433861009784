import React from 'react';
import styled from 'styled-components';

import { Themes } from './Themes';
import { Emphasis } from './Emphasis/Emphasis';
import { PresentationMode } from './PresentationMode/PresentationMode';
import { HeaderLabel } from './components/HeaderLabel';

interface Props {
  useEmphasis?: boolean;
  useTheme?: boolean;
  useMode?: boolean;
}

export const PresentationSettings: React.FC<Props> = ({
  useEmphasis = true,
  useTheme = true,
  useMode = false,
}) => (
  <PresentationSettingsWrapper>
    <HeaderLabel>Presentation Settings:</HeaderLabel>
    <PresentationSettingsButtons>
      {!useEmphasis ? null : <Emphasis />}
      {!useTheme ? null : <Themes />}
      {!useMode ? null : <PresentationMode />}
    </PresentationSettingsButtons>
  </PresentationSettingsWrapper>
);

const PresentationSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
`;

const PresentationSettingsButtons = styled.div`
  display: flex;
  gap: 10px;
`;
