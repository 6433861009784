import { FeatureFlag } from '../types';
import { useAgentFeatureFlags } from './use-feature-flags/useAgentFetureFlags';
import { useAccountFeatureFlags } from './use-feature-flags/useAccountFeatureFlags';

export enum FEATURES {
  CpComparableAdjustments = 'cp-comparable-adjustments',
  CpPdfAllListingsToggle = 'cp-pdf-all-listings-toggle',
  BoldTrailTheme = 'ta-boldtrail',
  BoldTrailWhiteLabel = 'boldtrail-whitelabel',
  CpSubjectBathCount = 'cp-subject-bath-count',
}

type FeatureFlagResult = {
  isLoading: boolean;
};

export function useFeatureFlags(): FeatureFlagResult {
  const agentFFQuery = useAgentFeatureFlags();
  const accountFFQuery = useAccountFeatureFlags();

  const isLoading = agentFFQuery.isLoading || accountFFQuery.isLoading;

  return { isLoading };
}

function hasFeature(feature: FEATURES, featureFlags?: FeatureFlag[]): boolean | void {
  if (!featureFlags) return;

  return featureFlags.find(item => item.feature === feature)?.enabledInd;
}

export function useHasFeatureFlag(feature: FEATURES, defaultValue = false): boolean {
  const agentFFQuery = useAgentFeatureFlags();
  const accountFFQuery = useAccountFeatureFlags();

  const hasAgentFF = hasFeature(feature, agentFFQuery.data);
  const hasAccountFF = hasFeature(feature, accountFFQuery.data);

  return (hasAgentFF || hasAccountFF) ?? defaultValue;
}
