import React, { useEffect, useMemo } from 'react';

import { useUrlSearchParam } from '../hooks/useQueryParams';
import { SsrLayout } from '../layouts/SsrLayout/SsrLayout';
import { CategoryProvider } from '../providers/providers/CategoryProvider';
import { UiConfigProvider } from '../providers/providers/UiConfigProvider';
import PresentationSlide from '../pages/Presentation/components/PresentationSlide/PresentationSlide';
import { PresentationTypeProvider } from '../providers/providers/PresentationTypeProvider';
import { CoreSlidesConfigProvider } from '../providers/providers/CoreSlidesConfigProvider';
import { useSetApiUser, useUser } from '../components/Slide/providers/UserProvider';
import { useSlides } from '../providers/providers/SlidesProvider';
import { useUserCustomizations } from '../hooks/useUserCategoryCustomizations';
import { UserApi } from '../api/UserApi';
import { DefaultConfigProvider } from '../services/DefaultConfigProvider';
import { TCategory, TSlideId, TSlideTheme, TUser, TWhiteLabelSlidesProp } from '../types';
import { THEMES } from '../constants/themes';
import { withProviders } from '../hoc/withProviders';
import { CreatedAtProvider, useCreatedAt } from '../providers/providers/CreatedAtProvider';
import { Timestamp } from '../services/Timestamp';
import { useLevelDynamicSlides } from '../hooks/useLevelDynamicSlides';
import { LibraryConfigs } from '../features/Library/services/LibraryConfigs';

interface PdfSlidePreviewProps {
  theme: TSlideTheme;
  slideId: TSlideId;
  user: TUser;
  whiteLabelSettings?: TWhiteLabelSlidesProp | null;
}

const PdfSlidePreview: React.FC<PdfSlidePreviewProps> = ({
  theme,
  slideId,
  user,
  whiteLabelSettings,
}) => {
  const { getSlide } = useSlides();
  const previewSlide = getSlide(slideId as TSlideId);

  if (!user || !previewSlide) return null;
  return (
    <PresentationSlide
      platform='pdf'
      resizable
      user={user}
      theme={theme}
      slide={previewSlide}
      infoPlacement={{ value: 'top' }}
      hideProgressTracker
      whiteLabelSettings={whiteLabelSettings}
    />
  );
};

interface PdfSlidePreviewWrapperProps {
  hash: string;
  category: TCategory;
  theme: TSlideTheme;
  slideId: TSlideId;
  whiteLabelSettings?: TWhiteLabelSlidesProp | null;
}

const PdfSlidePreviewWrapper: React.FC<PdfSlidePreviewWrapperProps> = ({
  hash,
  category,
  theme,
  slideId,
  whiteLabelSettings,
}) => {
  const user = useUser();
  const { setSlides } = useSlides();
  const setApiUser = useSetApiUser();

  const { isLoading: isLoadingCustomizations, data: configs } = useUserCustomizations(
    hash,
    null,
    user?.useSpecialBranding,
  );

  const { isLoading: isLoadingDynamic, data: dynamic } = useLevelDynamicSlides(
    hash,
    user?.useSpecialBranding ?? false,
  );

  const isLoading = isLoadingCustomizations || isLoadingDynamic;

  useEffect(() => {
    if (isLoadingDynamic) return;

    setSlides({
      ...LibraryConfigs.getSlides(),
      ...(dynamic ?? {}),
    });
  }, [dynamic, isLoadingDynamic, setSlides]);

  useEffect(() => {
    (async () => {
      const apiUser = await UserApi.fetch(hash);
      if (!apiUser) return;

      setApiUser(apiUser);
    })();
  }, [hash, setApiUser]);

  if (isLoading) return null;

  return (
    <UiConfigProvider
      initValue={configs?.[category]?.ui || DefaultConfigProvider.getConfigs()[category].ui}
    >
      <PdfSlidePreview
        slideId={slideId}
        theme={theme || THEMES.TRADITIONAL.DEFAULT}
        user={user as TUser}
        whiteLabelSettings={whiteLabelSettings}
      />
    </UiConfigProvider>
  );
};

const SingleSsrSlide = withProviders<React.FC>(CreatedAtProvider)(() => {
  const { setCreatedAt } = useCreatedAt();
  const dataQuery = useUrlSearchParam('data');

  if (!dataQuery) return <div>loading</div>;

  const data = JSON.parse(dataQuery.toString());

  useEffect(() => setCreatedAt(Timestamp.now()), [setCreatedAt]);

  const whiteLabelSettings: TWhiteLabelSlidesProp | null = useMemo(() => {
    if (!data.packageLevel) return null;
    return {
      packageLevel: data.packageLevel,
      primaryColor: data.primaryColor,
      secondaryColor: data.secondaryColor,
    };
  }, [data.packageLevel, data.primaryColor, data.secondaryColor]);

  return (
    <div style={{ width: '100%' }}>
      <SsrLayout>
        <PresentationTypeProvider>
          <CategoryProvider initValue={data?.category}>
            <CoreSlidesConfigProvider>
              <PdfSlidePreviewWrapper
                theme={data.theme}
                slideId={data.slideId}
                hash={data.userHash}
                category={data?.category}
                whiteLabelSettings={whiteLabelSettings}
              />
            </CoreSlidesConfigProvider>
          </CategoryProvider>
        </PresentationTypeProvider>
      </SsrLayout>
    </div>
  );
});

export default SingleSsrSlide;
