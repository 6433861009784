import * as alpha from './alpha';
import * as beta from './beta';
import * as gamma from './gamma';
import * as delta from './delta';
import * as epsilon from './epsilon';
import * as zeta from './zeta';

import { TDynamicSlideConfigs } from '../../types';
import { TDynamicSlideThreeImagesTemplate } from '../../../../../types';

export const threeImagesTemplateConfigs: Record<
  TDynamicSlideThreeImagesTemplate,
  TDynamicSlideConfigs
> = {
  threeImagesLayoutAlpha: alpha,
  threeImagesLayoutBeta: beta,
  threeImagesLayoutGamma: gamma,
  threeImagesLayoutDelta: delta,
  threeImagesLayoutEpsilon: epsilon,
  threeImagesLayoutZeta: zeta,
};
