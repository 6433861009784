import React from 'react';

import Logos from '../../../../components/Logos/Logos';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';
import {
  FrameContainer,
  GradientComponentWrapper,
  HeaderContainer,
  GradientComponent,
  SlideContent,
  SlideContentRoundBorders,
  BrokerInfoWrapper,
} from './TraditionalFrameBeta.styles';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { CommonFrameProps } from '../../../../../../../../types';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

const TraditionalFrameBeta: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => {
  const flipHorizontaly = { transform: `scale(${infoPlacement === 'bottom' ? '-' : ''}1)` };
  const reverse = useReverseHeader();

  const getFlexDirection = () => {
    if (!reverse) return infoPlacement === 'top' ? 'row' : 'row-reverse';
    return infoPlacement !== 'top' ? 'row' : 'row-reverse';
  };
  return (
    <PlacementAwareContainer infoPlacement={infoPlacement}>
      <FrameContainer>
        <HeaderContainer
          style={{
            ...flipHorizontaly,
            flexDirection: getFlexDirection(),
          }}
        >
          <GradientComponentWrapper>
            <GradientComponent
              isReversed={
                (infoPlacement === 'bottom' && !reverse) || (infoPlacement === 'top' && reverse)
              }
            >
              <BrokerInfoWrapper>
                <BrokerInfo
                  fontColor='#000'
                  nameFontStyles={{ fontSize: 18 }}
                  containerStyles={{
                    transform: infoPlacement === 'top' ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              </BrokerInfoWrapper>
            </GradientComponent>
          </GradientComponentWrapper>
          {!hideProgressTracker && (
            <ProgressTracker
              fontColor='#000'
              category={CATEGORIES.TRADITIONAL}
              containerStyles={{
                paddingBottom: 32,
                ...flipHorizontaly,
                margin: '0 auto',
                position: 'static',
              }}
            />
          )}
          <GradientComponentWrapper>
            <GradientComponent
              isReversed={
                (infoPlacement === 'top' && !reverse) || (infoPlacement === 'bottom' && reverse)
              }
            >
              <Logos
                containerStyles={{
                  transform: infoPlacement === 'top' ? 'rotate(0deg)' : 'rotate(180deg)',
                  width: '75%',
                }}
              />
            </GradientComponent>
          </GradientComponentWrapper>
        </HeaderContainer>
        <TocPortalContainer offset={-28} zIndex={20} />
      </FrameContainer>

      <SlideContent>
        <SlideContentRoundBorders>{children}</SlideContentRoundBorders>
      </SlideContent>
    </PlacementAwareContainer>
  );
};

export default TraditionalFrameBeta;
