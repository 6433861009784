import styled from 'styled-components';

export const ModalHeader = styled.div`
  padding: 15px 20px;
  background-color: ${({ theme }) => theme.colors.v2.background};
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  /* gap: 50px; */

  @media all and (max-width: 878px) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
