import styled from 'styled-components';

import { TCompConfigItem } from '../../../../../types';
import { CategoryCheckbox } from '../../components/CategoryCheckbox';
import { DescriptionBlock } from './DescriptionBlock';

interface Props {
  fields: TCompConfigItem[];
  title: string;
  description: string;
}

export const CheckboxGroup = ({ fields, title, description }: Props) => {
  return (
    <Container>
      <DescriptionBlock title={title} description={description} titleStyles={{ fontSize: 14 }} />
      <Wrapper>
        {fields.map(field => (
          <CategoryCheckbox key={field.id} field={field} />
        ))}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
