import styled, { useTheme } from 'styled-components';
import React, { useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SettingsOptions } from './SettingsOptions/SettingsOptions.component';
import { useOutsideClick } from '../../../../../../../services/useOutsideClick';
import { Button } from '../../../../../../../components/Common/V2/Button/Button';

export type TOption = { label: string; value: string };

const ButtonWrapper = styled('div')({
  position: 'relative',
});

interface SettingsOptionsButtonProps {
  options: TOption[];
  onSelectOption: (val: string) => void;
  defaultSelected?: string;
  selectedOption?: TOption;
  AdditionalInfo?: React.FC | null;
  settingsTypeLabel?: string;
  label?: string;
  icon: IconProp;
  isDisabled?: boolean;
}

export const SettingsOptionsButton: React.FC<SettingsOptionsButtonProps> = ({
  options,
  onSelectOption,
  defaultSelected,
  selectedOption,
  AdditionalInfo,
  settingsTypeLabel,
  label,
  icon,
  isDisabled,
}) => {
  const theme = useTheme();
  const elRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<React.RefObject<HTMLDivElement> | null>(null);

  const onCloseDropdown = () => {
    setOpen(null);
  };

  useOutsideClick(open, onCloseDropdown);

  return (
    <ButtonWrapper ref={elRef}>
      <Button
        variant='secondary'
        prefixIcon={<FontAwesomeIcon icon={icon} color={theme.colors.v2.primary} />}
        onClick={() => setOpen(elRef)}
        style={{ boxShadow: 'none' }}
        disabled={isDisabled}
      >
        {settingsTypeLabel}: {label ?? ''}
      </Button>

      {open && open.current === elRef?.current && (
        <SettingsOptions
          onClose={onCloseDropdown}
          isOpen={!!open}
          options={options}
          onSelectOption={onSelectOption}
          defaultSelected={defaultSelected}
          selectedOption={selectedOption}
          AdditionalInfo={AdditionalInfo}
        />
      )}
    </ButtonWrapper>
  );
};
