import styled from 'styled-components';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigBodyLetterSpacing } from '../../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';

export const TitleContainer = styled('div')({
  marginTop: 20,
  marginBottom: 23,
  padding: 30,
  border: '0.5px solid #DDDDDD',
  borderRadius: 5,
});

export const Title = styled('h2')(({ theme }) => ({
  fontSize: 26,
  textTransform: 'uppercase',
  color: useUiConfigColorsPrimary(),
  fontWeight: 900,
}));

export const Subtitle = styled('h4')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  marginTop: 8,
  textTransform: 'uppercase',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const DescriptionContainer = styled('div')(({ theme }) => ({
  padding: '18px 40px',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1'),
  border: '0.5px solid #DDDDDD',
  borderRadius: 5,
  color: theme.colors.gray[400],
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const DescriptionTitle = styled('h6')({
  fontSize: 16,
  fontWeight: 700,
  textTransform: 'uppercase',
  marginBottom: 5,
});

export const DescriptionText = styled('p')({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: 15,
  lineHeight: '26px',
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const CardsContainer = styled('div')({
  borderRadius: 5,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  marginTop: 23,
  paddingBottom: 8,
});

export const Cards = styled('div')({
  padding: '40px 20px 30px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
});

export const SourceText = styled('p')({
  fontSize: 9,
  fontWeight: 300,
  paddingTop: 12,
  position: 'absolute',
  right: 20,
  bottom: 0,
});
