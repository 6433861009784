import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Button } from '../../../../../components/Common/V2/Button/Button';

interface Props {
  isActivated: boolean;
  onClick: () => void;
}

export const AddButton = ({ isActivated, onClick }: Props) => {
  const icon = isActivated ? faTimes : faPlus;
  const text = isActivated ? 'Remove/Change' : 'Add';

  return (
    <Button variant='primary' onClick={onClick} prefixIcon={<FontAwesomeIcon icon={icon} />}>
      {text}
    </Button>
  );
};
