import { useSetDummyPresentationToState } from '../../features/report/hooks/useSetDummyPresentationToState';
import { useLoadDummyPresentation } from '../../hooks/usePresentation';
import { CustomizationSettingsPage } from './views/CustomizationSettings/CustomizationSettings.page';

export function PresentationSettings() {
  useLoadDummyPresentation();
  useSetDummyPresentationToState();

  return <CustomizationSettingsPage />;
}
