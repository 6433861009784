import styled, { CSSObject } from 'styled-components';

import { Typography as Text } from '../../../../Common';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
} from '../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')(({ theme }) => ({
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: 120,
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, 0)',
  top: 0,
  fontFamily: useUiConfigFontsHeader(),
  cursor: 'pointer',
  zIndex: 2,
}));

export const Typography = styled(Text)(() => ({
  color: '#fff',
  fontWeight: 400,
  fontFamily: useUiConfigFontsHeader() ?? 'Arial',
}));

export const StepsWrapper = styled('div')({
  display: 'flex',
  marginTop: 7,
  backgroundColor: '#f7f7f7',
  borderRadius: 100,
  position: 'relative',
});

interface WithActive {
  active: boolean;
  completed: boolean;
}

export const Step = styled('div')<WithActive>(({ theme, active, completed }) => ({
  width: 40,
  height: 22,
  flexShrink: 0,
  fontSize: 14,
  color: theme.colors.gray[400],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'relative',
  fontFamily: useUiConfigFontsBody() ?? 'Arial',
  '&:last-of-type': {
    width: 35,
    borderRadius: '0 100px 100px 0',
    textAlign: 'right',
  },
  '&:first-of-type': {
    width: 35,
    borderRadius: '100px 0  0 100px',
  },
}));

export const CircularStep = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  border: `2px solid ${useUiConfigColorsPrimary()}`,
  height: 37,
  width: 37,
  borderRadius: '50%',
  fontSize: 16,
  fontWeight: 700,
}));

export const StepsBackground = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: '#d9d9d9',
  borderRadius: 100,
  marginLeft: 1,
});
