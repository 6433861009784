import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { PropertyBoost } from './PropertyBoost';
import PropertyBoostPDF from './PropertyBoostPDF';

const id = 'propertyBoost';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Property Boost',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: PropertyBoost,
  },
  pdf: {
    shared: PropertyBoostPDF,
  },
};

export type TPropertyBoost = typeof id;
export { config, mappings };
