import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { ThreeImagesZeta } from './ThreeImagesZeta';
import { ThreeImagesZetaForm } from './ThreeImagesZetaForm';
import { ThreeImagesZetaPdf } from './ThreeImagesZetaPdf';
import thumbnail from './thumbnail.png';

const template = 'threeImagesLayoutZeta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Three Image Layout',
  frame: true,
  data: {
    image: '',
    image2: '',
    image3: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: ThreeImagesZetaForm,
  web: ThreeImagesZeta,
  pdf: ThreeImagesZetaPdf,
};

export type TThreeImagesLayoutZeta = typeof template;
export { config, mappings, thumbnail };
