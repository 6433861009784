import { PropertyGroups, RawReport, ReportProperty } from '../../../types';

type TOptions = {
  filterExcluded?: boolean;
};

export function extractReportProperties(
  rawProperties?: PropertyGroups,
  options?: TOptions,
): ReportProperty[] {
  if (!rawProperties) return [];

  const properties = (Object.values(rawProperties) || [])
    .flatMap(p => p)
    .map(p => {
      return {
        lat: p?.coordinates?.latitude,
        lng: p?.coordinates?.longitude,
        address: p?.address?.deliveryLine,
        excluded: p?.excluded,
        status: p?.status,
        property: p,
      };
    }) as ReportProperty[];

  if (options?.filterExcluded) {
    return properties?.filter(property => !property?.excluded);
  }

  return properties;
}
