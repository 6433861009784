import styled from 'styled-components';

export const UploadImage = styled('div')(({ theme }) => ({
  width: 70,
  height: 70,
  backgroundColor: theme.colors.v2.primary,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    backgroundColor: theme.colors.primary.dark,
  },
}));

export const UploadImageIconWrapper = styled('div')({
  cursor: 'pointer',
});

export const UploadImageIcon = styled('img')({
  width: 28,
  display: 'block',
});
