import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../constants/categories';
import { useCategory } from '../../../../../providers/providers/CategoryProvider';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';
import { PdfFrame } from '../../../components/SlideFrame/frames/pdf/PdfFrame';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';
import bg from './assets/bg_pdf.png';
import customColors from './assets/pdf-custom-colors';
import whiteOverlay from './assets/pdf-white-overlay.png';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
});

const Background = styled('div')({
  flex: 1,
  background: 'rgba(0, 0, 0, 0.12)',
  backgroundPosition: 'bottom',
  backgroundSize: 'cover',
  position: 'absolute',
  top: 0,
  left: 2,
  right: 0,
  bottom: 0,
  zIndex: 2,
});

const TextContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  clipPath: 'polygon(0 0, 79% 0, 27% 100%, 0% 100%)',
  zIndex: 4,
  padding: 40,
});

const Img = styled('img')({ position: 'absolute', zIndex: 1, right: 0, width: '100%' });

const Heading = styled('h1')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontFamily: useUiConfigFontsHeader(),
  fontWeight: 800,
  fontSize: '26px',
  marginBottom: 25,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const CustomColorsOverlay = styled('img')({
  width: '100%',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 2,
});

const WhiteOverlay = styled('img')({
  width: '82%',
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 3,
});

const Text = styled('p')(({ theme }) => ({
  fontSize: 16,
  marginBottom: 30,
  lineHeight: 1.3,
  width: '45%',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const MyMarketingPlanPDF: React.FC = () => {
  const { category } = useCategory();
  const primary = useUiConfigColorsPrimary();
  const img = React.useMemo(() => svgStringToDataUrl(customColors, primary), [primary]);

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <TextContainer>
          <Heading>MY MARKETING PLAN</Heading>
          <Text>
            I’ll provide a straightforward course of action for marketing your home that will
            identify and attract buyers and generate buzz about your listing. Think of your
            marketing plan as a map of the road to your final destination – selling your home.
          </Text>
          <Text>
            Your customized marketing plan will include various marketing tools like open houses,
            virtual tours, websites, mailers, emails, social media ads, and others. To prove
            progress, I’ll also track metrics like where buyers are finding your listing, how much
            online traffic your listing is generating, and returns on any advertising.
          </Text>
        </TextContainer>
        <Background />
        <Img src={bg} />
        <CustomColorsOverlay src={img} />
        <WhiteOverlay src={whiteOverlay} />
      </Container>
    </PdfFrame>
  );
};

export default MyMarketingPlanPDF;
