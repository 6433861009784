import styled from 'styled-components';

export const FlexWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  '&:not(:last-of-type)': {
    marginBottom: 16,
  },
  '@media all and (max-width: 1485px)': {
    '&:not(:last-of-type)': {
      marginBottom: 5,
      marginRight: 0,
    },
  },
});

export const Label = styled('div')`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  text-transform: uppercase;
  font-weight: 700;
  width: 100px;
`;

export const InputWrapper = styled.div`
  width: 226px;
`;
