import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';

const Container = styled('div')({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 60,
  height: 140,
  zIndex: 3,
});

const Title = styled('h1')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontSize: 20,
  fontWeight: 800,
  lineHeight: 1.6,
  marginBottom: 0,
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing('0.08em'),
}));

const Text = styled('div')(({ theme }) => ({
  color: '#000',
  fontSize: 18,
  lineHeight: 1.7,
  width: '85%',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const Circle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 26,
  fontWeight: 900,
  height: 45,
  width: 45,
  borderRadius: '50%',
  border: `1px solid ${useUiConfigColorsPrimary()}`,
  position: 'absolute',
  top: 10,
  left: 0,
  zIndex: 10,
  backgroundColor: '#fff',
  fontFamily: useUiConfigFontsHeader(),
}));

const Bar = styled('div')({
  display: 'block',
  height: 140,
  width: 20,
  content: '" "',
  backgroundColor: '#fafafa',
  position: 'absolute',
  zIndex: 5,
  left: 12.5,
  top: 12.5,
});

interface Props {
  order: number;
  title: string;
  description: string;
  isActive?: boolean;
  isLast?: boolean;
}

const SectionItem: React.FC<Props> = ({ order, title, description, isActive, isLast }) => {
  return (
    <Container
      style={{ filter: `grayscale(${isActive ? '0' : '1'})`, opacity: isActive ? 1 : 0.4 }}
    >
      {!isLast && <Bar />}
      <Circle>{order}</Circle>
      <Title>{title}</Title>
      {description && (
        <Text>
          {description.split(/\r?\n/).map((p, i) => (
            <Fragment key={i}>
              {p}
              {i === 0 && <br />}
            </Fragment>
          ))}
        </Text>
      )}
    </Container>
  );
};
export default SectionItem;
