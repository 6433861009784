import { FC } from 'react';
import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { useUiConfigFontsHeader } from '../../../../providers/providers/UiConfigFontProvider';
import { isPresentationViewAtom } from '../../../../features/report/state/isPresentationViewAtom';

export const SectionTitle: FC = ({ children }) => {
  const font = useUiConfigFontsHeader();
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const fontFamily = isPresentationView ? font : 'Figtree';

  return <Title style={{ fontFamily }}>{children}</Title>;
};

const Title = styled.h3`
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  color: #444;
`;
