import { useEffect } from 'react';
import { useEntity } from '../../../../../providers/EntityProvider';
import { useUser } from '../../../../../../../components/Slide/providers/UserProvider';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { useSetUiConfigs } from '../../../../../../../providers/providers/UiConfigProvider';
import { DefaultConfigProvider } from '../../../../../../../services/DefaultConfigProvider';
import { useUserCustomizations } from '../../../../../../../hooks/useUserCategoryCustomizations';

export function useUpdateUiConfigWhenEntityChanges() {
  const { hash, useSpecialBranding } = useUser();
  const { entity, entityMode } = useEntity();
  const setUiConfigs = useSetUiConfigs();
  const { category } = useCategory();

  const { data } = useUserCustomizations(hash, entity, useSpecialBranding, entityMode);

  useEffect(() => {
    if (!category) return;

    const defaultCategoryUiConfigs = DefaultConfigProvider.getConfigs()[category].ui;
    setUiConfigs(data?.[category]?.ui ?? defaultCategoryUiConfigs);
  }, [entity, category, data, setUiConfigs]);
}
