import styled from 'styled-components';

import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { SidebarTitle } from '../components/SidebarTitle';
import { Disclaimer } from '../../components/Disclaimer';
import { ComingSoonTrends } from './ComingSoonTrends';
import { ActiveTrends } from './ActiveTrends';
import { PendingTrends } from './PendingTrends';
import { ClosedTrends } from './ClosedTrends';
import { useHasComparableAdjustments } from '../../../../hooks/useHasComparableAdjustments';
import { compAveragesAtom } from '../../state/compAveragesAtom';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

export const TrendsSidebarContent = () => {
  const { isCanadaUser } = useUser();
  const hasAdjustments = useHasComparableAdjustments();
  const compAverages = useAtomValue(compAveragesAtom);

  const disclaimerText = useMemo(() => {
    if (!compAverages?.adjusted)
      return "This section is using the actual price. Click into the property to see it's adjusted price.";

    return "This section will show actual prices or adjusted prices depending on your 'Plot by' selection.";
  }, [compAverages?.adjusted]);

  return (
    <TrendsContainer>
      <div>
        <SidebarTitle title='TRENDS' iconName='chart_line_up' />
        <Wrapper>
          <ComingSoonTrends />
          <ActiveTrends />
          {!isCanadaUser && <PendingTrends />}
          <ClosedTrends />
        </Wrapper>
      </div>
      {!hasAdjustments ? null : <Disclaimer text={disclaimerText} />}
    </TrendsContainer>
  );
};

const TrendsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
