import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { PROPERTY_STATUS, TCompConfigItem } from '../../../../types';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { ACTIVE, TRENDS_KEYS } from './trendsConfig';
import { TrendsSection } from './TrendsSection';

const activeGroups = [
  [ACTIVE[TRENDS_KEYS.HIGHEST], ACTIVE[TRENDS_KEYS.AVERAGE], ACTIVE[TRENDS_KEYS.MEDIAN]],
  [ACTIVE[TRENDS_KEYS.LOWEST], ACTIVE[TRENDS_KEYS.CLOSEST], ACTIVE[TRENDS_KEYS.RECENT]],
] as TCompConfigItem[][];

export const ActiveTrends = () => {
  const properties = useAtomValue(reportPropertiesAtom);

  const count = useMemo(() => {
    return properties?.filter(p => p.status === PROPERTY_STATUS.ACTIVE && !p.excluded)?.length;
  }, [properties]);

  return (
    <TrendsSection
      title='ACTIVE PROPERTIES'
      count={count}
      groups={activeGroups}
      status={PROPERTY_STATUS.ACTIVE}
    />
  );
};
