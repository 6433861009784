import React from 'react';
import styled from 'styled-components';

import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';

export const Container = styled('div')({
  width: '70%',
  margin: '0 auto',
});

export const TextBetaPdf: React.FC = () => {
  const slide = useSlide();
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <SlideTextBlock
          text={slide.data?.text}
          headline={slide.data?.headline}
          isHeadlineCenter
          containerStyles={{ textAlign: 'center' }}
        />
      </Container>
    </PdfFrame>
  );
};
