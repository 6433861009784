import React from 'react';

import styles from './InternetExplorerWarning.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InternetExplorerWarning = () => {
  const isIE = () => {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.includes('MSIE ') || ua.includes('Trident/');
  };

  if (!isIE()) {
    return null;
  }

  return (
    <header className={styles.header}>
      <FontAwesomeIcon
        style={{ padding: '10px' }}
        icon={['fas', 'exclamation-circle']}
        size='2x'
        color='#6ab4e4'
      />
      You are using Internet Explorer. For an optimal experience, please switch to Chrome or
      Firefox.
    </header>
  );
};
