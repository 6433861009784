import styled from 'styled-components';

import { Icon } from '../../../../components/Common';
import { IconName } from '../../../../components/Common/Icon/Icon.types';

interface Props {
  title: string;
  iconName: IconName;
}

export const SidebarTitle = ({ title, iconName }: Props) => (
  <Wrapper>
    <Icon iconName={iconName} style={{ width: 18, height: 18 }} />
    <Title>{title}</Title>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
`;

const Title = styled.h2`
  color: #444;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
