import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../constants/categories';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../providers/providers/UiConfigColorProvider';
import BrokerInfo from '../../../../components/SlideFrame/components/BrokerInfo/BrokerInfo.component';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUser } from '../../../../providers/UserProvider';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import InfoItem from './InfoItem';
import * as icons from './icons';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';
import BoxItem from './BoxItem';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';
import { PRESENTATION_TYPES } from '../../../../../../constants/PresentationTypes';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const TopContentWrapper = styled('div')(({ theme }) => ({
  height: 320,
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  width: '100%',
  marginBottom: 40,
}));

const Row = styled('div')(({ theme }) => ({
  flex: 1,
  padding: '0 24px',
  display: 'flex',
  alignItems: 'center',
  '&:last-child': {
    borderTop: '1px solid #ccc',
  },
}));

const RowItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Text = styled('p')(({ theme }) => ({
  color: '#444',
  lineHeight: 1.6,
}));

const BottomContent = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
});

export const ClosingAndNextStepsPDF: React.FC = () => {
  const { category } = useCategory();
  const primary = useUiConfigColorsPrimary();
  const secondary = useUiConfigColorsSecondary();
  const fontFamily = useUiConfigFontsHeader();
  const letterSpacing = useUiConfigHeaderLetterSpacing();
  const { presentationType } = usePresentationType();

  const { phone, email, website, company, address, zip, city, state } = useUser()!;

  const styles: React.CSSProperties = {};

  if (category === 'luxury') styles.border = '1px solid #ccc';

  const isWTRPres = presentationType === PRESENTATION_TYPES.WIN_THE_REPRESENTATION;

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <TopContentWrapper
          style={{
            borderRadius: category === 'luxury' ? 0 : 5,
            border: category === 'luxury' ? 'none' : '1px solid #ccc',
          }}
        >
          <Row>
            <BrokerInfo
              nameFontStyles={{ color: primary }}
              contactFontStyles={{ color: '#444' }}
              showLicenseNumber
            />
          </Row>
          <Row style={{ backgroundColor: ColorOpacityApplier.hex(secondary, '0.1'), ...styles }}>
            <RowItem>
              {phone && <InfoItem icon={icons.phone} text={phone} />}
              {/*{phone && <InfoItem icon={icons.fax} text={phone} />}*/}
              {email && <InfoItem icon={icons.mail} text={email} />}
              {website && <InfoItem icon={icons.web} text={website} />}
            </RowItem>
            <RowItem>
              {company && (
                <InfoItem icon={icons.pin}>
                  {company}
                  <br />
                  {address}
                  <br />
                  {`${city}, ${state} ${zip}`}
                </InfoItem>
              )}
            </RowItem>
          </Row>
        </TopContentWrapper>
        <Text
          style={{
            color: primary,
            fontFamily,
            fontSize: 75,
            fontWeight: 700,
            textAlign: 'center',
            letterSpacing,
          }}
        >
          THANK YOU
        </Text>
        <Text
          style={{ fontFamily, fontSize: 28, textAlign: 'center', margin: '0 auto', letterSpacing }}
        >
          This is a big decision, thank you for allowing me the
          <br />
          opportunity to assist. Let&apos;s discuss next steps.
        </Text>
        {!isWTRPres && (
          <Text
            style={{ fontFamily, fontSize: 20, textAlign: 'left', marginTop: 80, letterSpacing }}
          >
            CHOOSE YOUR NEXT STEP:
          </Text>
        )}
        <BottomContent style={isWTRPres ? { justifyContent: isWTRPres ? 'center' : '' } : {}}>
          <BoxItem topTitle="Let's get started" title='View Contract' />
          {!isWTRPres && <BoxItem topTitle='I Need' title=' MORE TIME / INFO' />}
        </BottomContent>
      </Container>
    </PdfFrame>
  );
};
