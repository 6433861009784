import styled from 'styled-components';

export const LeftCol = styled('div')({
  width: '27%',
});

export const RightCol = styled('div')({
  width: '71%',
});

export const SourceText = styled('p')({
  position: 'absolute',
  bottom: '3%',
  right: '3%',
  fontSize: 14,
  color: '#000',
  fontWeight: 300,
});
