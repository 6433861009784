import styled from 'styled-components';

export const NotesContainer = styled('div')({
  marginLeft: 30,
  paddingTop: 6,
});

export const Title = styled('h6')(({ theme }) => ({
  color: theme.colors.gray[400],
  textTransform: 'uppercase',
  fontSize: 15,
  fontWeight: 900,
  lineHeight: '133.2%',
}));

export const Line = styled('div')({
  height: 35,
  borderBottom: '1px solid #efefefef',
});
