import React, { useState } from 'react';

import { TNetProceedsData } from '../../../../../../types';
import { useSlide } from '../../../../providers/SlideProvider';
import { LeftCol, RightCol, Container } from './NetProceeds.styles';
import { NetProceedsInfo } from '../components/NetProceedsInfo/NetProceedsInfo.component';
import { NetProceedsTable } from '../components/NetProceedsTable/NetProceedsTable.component';

export const NetProceeds: React.FC = () => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const slide = useSlide();

  const changeSelectedItem = (index: number) => {
    setSelectedItemIndex(index);
  };

  const calculateCosts = (netProceedData: TNetProceedsData) => {
    if (!netProceedData) return 0;
    return (
      netProceedData.mortgagePayoffs.first +
      netProceedData.realEstateFees.listingOffice +
      netProceedData.realEstateFees.sellingOffice +
      netProceedData.settlementCosts.escrowFee +
      netProceedData.settlementCosts.titleInsurance +
      netProceedData.settlementCosts.proratedPropertyTax +
      netProceedData.transferExciseTax.stateTax +
      netProceedData.otherCosts.assessments +
      netProceedData.otherCosts.HOAFees +
      netProceedData.otherCosts.administrativeFee +
      netProceedData.otherCosts.homeWarrantyPolicy +
      netProceedData.otherCosts.inspections +
      netProceedData.otherCosts.closingCosts
    );
  };

  const getCardsData = () => {
    if (!slide?.netProceedsData) return [];
    const [netProceeds1, netProceeds2, netProceeds3] = slide.netProceedsData;

    const listPrice1 = netProceeds1?.listPrice?.price || 0;
    const costs1 = calculateCosts(netProceeds1);

    const listPrice2 = netProceeds2?.listPrice?.price || 0;
    const costs2 = calculateCosts(netProceeds2);

    const listPrice3 = netProceeds3?.listPrice?.price || 0;
    const costs3 = calculateCosts(netProceeds3);

    const card1 = {
      listPrice: listPrice1,
      estimatedPrice: listPrice1,
      costs: costs1,
      netProceeds: listPrice1 - costs1,
    };

    const card2 = {
      listPrice: listPrice2,
      estimatedPrice: listPrice2,
      costs: costs2,
      netProceeds: listPrice2 - costs2,
    };

    const card3 = {
      listPrice: listPrice3,
      estimatedPrice: listPrice3,
      costs: costs3,
      netProceeds: listPrice3 - costs3,
    };

    return [card1, card2, card3];
  };

  return (
    <Container>
      <LeftCol>
        <NetProceedsInfo
          selectedItemIndex={selectedItemIndex}
          cards={getCardsData()}
          changeSelectedItem={changeSelectedItem}
        />
      </LeftCol>
      <RightCol>
        <NetProceedsTable
          data={slide?.netProceedsData}
          selectedItemIndex={selectedItemIndex}
          changeSelectedItem={changeSelectedItem}
        />
      </RightCol>
    </Container>
  );
};
