import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { Card } from '../../../../../components/Card/Card.component';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUser } from '../../../../../providers/UserProvider';
import { ValueOfAnAgentModernCanada } from '../../components/ValueOfAnAgentModernCanada/ValueOfAnAgentModernCanada';
import { cards } from './data';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr 1fr',
  rowGap: 20,
  columnGap: 20,
  width: '100%',
  height: '100%',
});

const ValueOfAnAgentModernPDF: React.FC = () => {
  const { isCanadaUser } = useUser();

  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      {isCanadaUser ? (
        <ValueOfAnAgentModernCanada isPdf />
      ) : (
        <Grid>
          {cards.map((card, i) => (
            <Card key={i} {...card} type='pdf' />
          ))}
        </Grid>
      )}
    </PdfFrame>
  );
};

export default ValueOfAnAgentModernPDF;
