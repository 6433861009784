import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as Items from './InfoItems';
import ico_mail from './icons/mail.png';
import ico_phone from './icons/phone.png';
import ico_web from './icons/web.png';
import { useUser } from '../../../../../../../providers/UserProvider';
import { ImageProxy } from '../../../../../../../services/ImageProxy';

const Container = styled('footer')({
  display: 'flex',
  height: 95,
  borderTop: '1px solid #888888',
  flexWrap: 'wrap',
});

interface FooterProps {
  page: number;
  isCover?: boolean;
}

const formatUrl = (url: string) => {
  return url.startsWith('http') ? url : `http://${url}`;
};

export const Footer: React.FC<FooterProps> = ({ page, isCover }) => {
  const user = useUser();

  if (!user) return null;

  return (
    <Container>
      {!isCover && (
        <Fragment>
          <Items.UserItem
            img={ImageProxy.optimize(user.image)}
            name={user.name}
            dre={`Lic. #${user.cal_bre}`}
          />
          {user.email && <Items.InfoItem img={ico_mail} content={user.email} />}
          {user.phone && <Items.InfoItem img={ico_phone} content={user.phone} />}
          {user.website && <Items.InfoItem img={ico_web} content={formatUrl(user.website)} />}
        </Fragment>
      )}
    </Container>
  );
};
