import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
`;

export const Layout: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};
