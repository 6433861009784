import React from 'react';

import {
  PresentationTypeProvider,
  usePresentationType,
} from '../../../../providers/providers/PresentationTypeProvider';
import { withProviders } from '../../../../hoc/withProviders';
import type { TBuyerTourPresentation } from '../../../../types';
import { ClientProvider } from '../../../../providers/providers/ClientProvider';
import { SectionsProvider } from '../../../../providers/providers/SectionsProvider';
import { TSlide, TUser } from '../../../../types';
import { OrderProvider, useOrder } from '../../../../providers/providers/OrderProvider';
import { PropertiesProvider } from '../../../../providers/providers/PropertiesProvider';
import { PRESENTATION_TYPES } from '../../../../constants/PresentationTypes';
import { SlidesProvider, useSlides } from '../../../../providers/providers/SlidesProvider';
import { UiConfigProvider } from '../../../../providers/providers/UiConfigProvider';
import { CategoryProvider } from '../../../../providers/providers/CategoryProvider';
import { ActiveSectionProvider } from '../../../../components/Slide/providers/ActiveSectionProvider';
import PresentationSlide from '../../../../pages/Presentation/components/PresentationSlide/PresentationSlide';
import { useSetBuyerTourPresentationToProviders } from '../../hooks/useSetBuyerTourPresentationToProviders';
import { CreatedAtProvider } from '../../../../providers/providers/CreatedAtProvider';

interface Props {
  presentation: TBuyerTourPresentation;
  user: TUser;
}
export const BuyerTourSsr: React.FC<Props> = withProviders<Props>(
  UiConfigProvider,
  PresentationTypeProvider,
  CategoryProvider,
  PropertiesProvider,
  ClientProvider,
  SlidesProvider,
  SectionsProvider,
  OrderProvider,
  ActiveSectionProvider,
  CreatedAtProvider,
)(function BuyerTourPresentation({ presentation, user }) {
  useSetBuyerTourPresentationToProviders(presentation as TBuyerTourPresentation);
  const { presentationType } = usePresentationType();

  const { sectionsSlide, sections } = useOrder();

  const { slides: presentationSlides } = useSlides();

  const slides = sections.flatMap(sectionId => {
    return sectionsSlide[sectionId].map(slideId => presentationSlides?.[slideId] as TSlide);
  });

  if (PRESENTATION_TYPES.BUYER_TOUR !== presentationType) return null;

  return (
    <>
      {slides.map(slide => (
        <PresentationSlide
          key={slide.id}
          platform='pdf'
          resizable
          user={user}
          infoPlacement={presentation.ui.infoPlacement}
          theme={presentation.ui.theme}
          slide={slide}
          presentationHash={presentation.hash}
          hideProgressTracker
          whiteLabelSettings={presentation.whiteLabelSettings}
        />
      ))}
    </>
  );
});
