const potentialBuyers = [
  {
    id: '#142689',
    contact_mask: 'someemail@gmail.com',
    interest_description: 'Single Family, $225,000, 3bd/2bs',
  },
  {
    id: '#123456',
    contact_mask: 'someemail@gmail.com',
    interest_description: 'Single Family, $225,000, 3bd/2bs',
  },
  {
    id: '#555412',
    contact_mask: 'someemail@gmail.com',
    interest_description: 'Single Family, $225,000, 3bd/2bs',
  },
  {
    id: '#555415',
    contact_mask: 'someemail@gmail.com',
    interest_description: 'Single Family, $225,000, 3bd/2bs',
  },
];

export const mockedData = {
  leads: {
    contacts: {
      count: 12,
      details: potentialBuyers,
    },
  },
  bottom: { value: 123 },
  mid: { value: 1234 },
  top: { value: 12345 },
};
