import { TPresentationSlides, TSlideId, TDynamicOrder, TFullOrder } from '../types';

export class SlidesConfigOrderAdapter {
  static toNewConfig(slidesConfig: TPresentationSlides): TFullOrder {
    const config: TFullOrder = {
      sections: [],
      sectionsSlide: {},
    };

    if (!slidesConfig.orderById || !slidesConfig.sections) return config;

    slidesConfig?.orderById?.forEach(sectionId => {
      const section = slidesConfig.sections[sectionId];

      config.sections.push(sectionId);

      const fullSectionSlides = section.slides.map(slide => {
        return { id: slide.id as TSlideId, includeSlide: slide.includeSlide };
      });

      config.sectionsSlide[sectionId] = fullSectionSlides;
    });

    return config;
  }
}
