import styled from 'styled-components';

export const AutoSuggestWrapper = styled.div`
  margin-bottom: 0;
`;

export const AutosuggestLabel = styled.label`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.gray[400]};
`;

export const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
`;

export const PreviewLink = styled.a`
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.v2.text.link};
`;

export const Spacer = styled.div`
  height: 10px;
`;
