export const title = 'Buyer’s Agent Compensation';
export const subtitle =
  "Let's emphasize this crucial aspect and delve into the details of how buyer's agents are compensated:";

export const data = [
  {
    title: 'Compensation is Negotiable',
    text: 'Buyers and sellers negotiate compensation with their agent.',
  },
  {
    title: 'Costs Are Explained to Buyers & Sellers',
    text: 'In the listing agreement, sellers determine the fee for their agent’s services and, if applicable, the portion for the buyer’s agent. Buyers negotiate the source of compensation for their agent and include it in the agreement. At closing, both parties are reminded of each agent’s payment details',
  },
  {
    title: 'Listing agents are paid if the property sells',
    text: 'Listing agents are usually paid from the proceeds of the sale and they may offer compensation to the Buyer’s Agent according to agreed-upon negotiations.',
  },
  {
    title: 'There is No Standard Compensation',
    text: 'Compensation fluctuates from agent to agent and depends on a variety of factors, such as consumer preference, the free market, and the services provided.',
  },
];
