import React from 'react';
import styled from 'styled-components';
import * as data from '../../data';
import slideGraphics from '../../assets/slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';

import slideImage from '../../assets/imageTraditional.png';

type WithCanadaUser = {
  isCanadaUser?: boolean;
};

const Col = styled('div')({ display: 'flex', flexDirection: 'column' });

const Container = styled(Col)({
  width: '100%',
  height: '100%',
  border: '1px solid #DDDDDD',
  backgroundColor: '#F6F6F6',
  overflow: 'hidden',
});

const BottomContent = styled(Col)({
  borderRadius: 5,
  marginBottom: 15,
  paddingBottom: 15,
  paddingTop: 20,
  position: 'relative',
  flex: 1,
});

const Graphics = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  width: isCanadaUser ? 1045 : 776,
  alignSelf: 'center',
  margin: '0',
  position: 'relative',
  zIndex: 5,
  left: isCanadaUser ? '47%' : '50%',
  transform: 'translateX(-50%)',
  top: isCanadaUser ? 0 : '3%',
}));

const TopContent = styled(Col)(({ theme }) => ({
  padding: '30px 30px 118px 30px',
  flexShrink: 0,
  backgroundColor: '#fff',
}));

const Heading = styled('h2')(({ theme }) => ({
  fontWeight: 900,
  color: '#444',
  marginBottom: 10,
  fontSize: 26,
  letterSpacing: '0.08em',
  textTransform: 'uppercase',
}));

const common = {
  color: '#010101',
  fontSize: 16,
  lineHeight: 1.3,
};

const Text = styled('p')(({ theme }) => ({
  textAlign: 'justify',
  width: '55%',
  letterSpacing: useUiConfigBodyLetterSpacing(),
  ...common,
  '&:not(:last-child)': {
    marginBottom: 35,
  },
}));

const SourceText = styled('p')({
  textAlign: 'right',
  fontSize: 13,
  color: '#101010',
  fontWeight: 300,
  marginTop: 15,
  paddingRight: 25,
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const UL = styled('ul')({
  paddingLeft: 20,
  marginTop: -25,
});

const LI = styled('li')(({ theme }) => ({
  ...common,
  marginBottom: 5,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const EllipticWrapper = styled('div')({
  background: '#f6f6f6',
  display: 'block',
  width: 4000,
  height: 2800,
  position: 'absolute',
  top: -49,
  left: '50%',
  transform: 'translateX(-50%)',
  clipPath: 'ellipse(50% 50% at 50% 50%)',
  overflow: 'hidden',
});

const WhyPricingCorrectlyIsImportantTraditionalPDF: React.FC = () => {
  const { isCanadaUser } = useUser();
  const primary = useUiConfigColorsPrimary();
  const fontFamily = useUiConfigFontsHeader();
  const letterSpacing = useUiConfigHeaderLetterSpacing();
  const image = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  return (
    <PdfFrame category={CATEGORIES.LUXURY}>
      <Container>
        <TopContent>
          <Heading style={{ fontFamily, color: primary, letterSpacing }}>{data.heading}</Heading>
          {data.paragraphs.map((p, i) => (
            <Text key={i}>{p}</Text>
          ))}
          <UL>
            {data.listItems.map((li, i) => (
              <LI key={i}>{li}</LI>
            ))}
          </UL>
        </TopContent>
        <BottomContent>
          <EllipticWrapper>
            <Graphics src={isCanadaUser ? slideImage : image} isCanadaUser={isCanadaUser} />
          </EllipticWrapper>
          {!isCanadaUser && <SourceText>Source: Zillow Listing Data</SourceText>}
        </BottomContent>
      </Container>
    </PdfFrame>
  );
};

export default WhyPricingCorrectlyIsImportantTraditionalPDF;
