import { ThemeText } from '../types';

const text: ThemeText = {
  font: {
    heading: 'Georgia, serif',
    body: '"Open Sans", Helvetica, Arial, sans-serif',
  },
  weight: {
    light: 200,
    regular: 400,
    bold: 700,
  },
};

export default text;
