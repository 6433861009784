import React, { useState } from 'react';
import { useUiConfigColorsPrimary } from '../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../Slide/services/ColorOpacityApplier';
import {
  Switch,
  InputCheckbox,
  SwitchSlider,
  Switch_V2Container,
  Circle,
} from './SwitchButton.style';

interface SwitchButtonComponentProps {
  disabled?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export const SwitchButton: React.FC<SwitchButtonComponentProps> = ({
  onChange,
  checked,
  disabled,
}) => {
  return (
    <Switch
      style={{
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
        filter: disabled ? 'grayscale(.6)' : 'none',
      }}
    >
      <InputCheckbox
        type='checkbox'
        onChange={e => onChange && onChange(!!e.target.checked)}
        checked={checked}
      />
      <SwitchSlider />
    </Switch>
  );
};

export const SwitchButton_V2: React.FC<SwitchButtonComponentProps> = ({
  onChange = () => {},
  checked,
  disabled,
}) => {
  const primary = useUiConfigColorsPrimary();
  const [isChecked, setChecked] = useState(!!checked);
  const prop = isChecked ? 'right' : 'left';
  const opposite = isChecked ? 'left' : 'right';

  const onSwitch = () => {
    onChange(!isChecked);
    setChecked(prev => !prev);
  };
  return (
    <Switch_V2Container
      style={{
        backgroundColor: '#CBCBCB',
        pointerEvents: disabled ? 'none' : 'auto',
        filter: disabled ? 'grayscale(.6)' : 'none',
      }}
      onClick={onSwitch}
    >
      <Circle
        style={{
          [prop]: 2.5,
          [opposite]: 27.5,
          backgroundColor: isChecked ? primary : '#fff',
        }}
      />
    </Switch_V2Container>
  );
};
