import React, { useEffect, useRef } from 'react';

import styles from './ItemContainer.module.scss';

interface Props {
  isActive: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  inactive: boolean;
  style: React.CSSProperties;
  propertiesRef: React.RefObject<HTMLElement>;
}
export const ItemContainer: React.FC<Props> = ({
  isActive,
  children,
  onClick = f => f,
  inactive,
  style = {},
  propertiesRef,
}) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (isActive) {
      const propertiesList = propertiesRef.current as HTMLElement;
      const itemContainer = ref.current as HTMLLIElement;

      const propertiesListRect = propertiesList.getBoundingClientRect();
      const itemContainerRect = itemContainer.getBoundingClientRect();

      const itemOffset = propertiesList.scrollTop + itemContainerRect.top;
      const propertiesListBottom = propertiesList.scrollTop + propertiesListRect.height;
      const propertiesListTop = propertiesList.scrollTop;

      if (propertiesListTop < itemOffset && propertiesListBottom > itemOffset) {
        return;
      }
      propertiesList.scrollTop =
        itemContainerRect.top + propertiesList.scrollTop - propertiesListRect.top - 60;
    }
  }, [isActive, propertiesRef]);

  const inactiveStyle = !inactive
    ? {}
    : {
        filter: 'grayscale(1)',
        opacity: 0.5,
      };

  return (
    <li
      ref={ref}
      className={`${styles.itemContainer} ${isActive ? styles.active : ''}`}
      onClick={onClick}
      style={{ ...(style ?? {}), ...inactiveStyle }}
    >
      {children}
    </li>
  );
};
