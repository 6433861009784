import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { SingleImage } from './SingleImage';
import { SingleImageForm } from './SingleImageForm';
import { TDynamicSlideTypeMapping } from '../../../types';

import thumbnail from './thumbnail.png';
import { SingleImagePdf } from './SingleImagePdf';

const template = 'singleImageTemplate';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - Single image only',
  frame: false,
  data: {
    image: '',
    image2: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: SingleImageForm,
  web: SingleImage,
  pdf: SingleImagePdf,
};

export type TSingleImageTemplate = typeof template;

export { config, mappings, thumbnail };
