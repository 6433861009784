import { TSlide, TSlideId } from '../../../types';
import { config as valueOfAgentConfig } from '../slides/whyIAmTheRightFitSection/valueOfAnAgent';
import { config as coreSocialConfig } from '../slides/marketingAndBuyerActivitySection/CoreSocial';
import { config as realtorVSIBuyerConfig } from '../slides/whyIAmTheRightFitSection/realtorVSIBuyer';
import { config as propertyBoostConfig } from '../slides/marketingAndBuyerActivitySection/propertyBoost';
import { config as pricingMisconceptionsConfig } from '../slides/whatsMyHomeWorth/PricingMisconceptions';
import { config as aboutMeConfig } from '../slides/whyIAmTheRightFitSection/personalSlides/slides/AboutMe';
import { config as myMarketingPlanConfig } from '../slides/marketingAndBuyerActivitySection/MyMarketingPlan';
import { config as importanceOfOpeningWeekendConfig } from '../slides/marketAnalysis/importanceOfOpeningWeekend';
import { config as whatFactorsInfluencePricingConfig } from '../slides/marketAnalysis/whatFactorsInfluencePricing';
import { config as importanceOfStagingConfig } from '../slides/marketingAndBuyerActivitySection/ImportanceOfStaging';
import { config as myTestimonialsConfig } from '../slides/whyIAmTheRightFitSection/personalSlides/slides/MyTestimonials';
import { config as whyPricingCorrectlyIsImportantConfig } from '../slides/marketAnalysis/whyPricingCorrectlyIsImportant';

const standalone: Partial<Record<TSlideId, TSlide>> = {
  [aboutMeConfig.id]: aboutMeConfig,
  [myTestimonialsConfig.id]: myTestimonialsConfig,
  [coreSocialConfig.id]: coreSocialConfig,
  [valueOfAgentConfig.id]: valueOfAgentConfig,
  [propertyBoostConfig.id]: propertyBoostConfig,
  [myMarketingPlanConfig.id]: myMarketingPlanConfig,
  [realtorVSIBuyerConfig.id]: realtorVSIBuyerConfig,
  [importanceOfStagingConfig.id]: importanceOfStagingConfig,
  [pricingMisconceptionsConfig.id]: pricingMisconceptionsConfig,
  [importanceOfOpeningWeekendConfig.id]: importanceOfOpeningWeekendConfig,
  [whatFactorsInfluencePricingConfig.id]: whatFactorsInfluencePricingConfig,
  [whyPricingCorrectlyIsImportantConfig.id]: whyPricingCorrectlyIsImportantConfig,
};

export default standalone;
