import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TCategory } from '../../../../types';
import { BasicModal } from '../../../../components/Common/BasicModal';
import PricePerSquareFootSlide from '../../../../components/Slide/slides/marketAnalysis/marketOverview/components/pricePerSquareFoot/web/PricePerSquareFootSlide';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { MarketAssessmentCard } from './MarketAssessmentCard';
import bgImg from './assets/price-per-sqft-dark.svg?url';
import bgImgLight from './assets/price-per-sqft.svg?url';

interface PricePerSqftGaugeProps {
  trackClick?: () => void;
  category?: TCategory;
  oneSheeterSlide?: boolean;
}

export const PricePerSqftGauge = ({
  trackClick,
  category,
  oneSheeterSlide,
}: PricePerSqftGaugeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const properties = useAtomValue(reportPropertiesAtom);

  let combinedProperties = properties
    .filter(prop => !prop.property.excluded && !!prop.property.size)
    .map(prop => prop.property);

  const calculateAvgPricePerSqft = () => {
    if (!combinedProperties.length) {
      return 'N/A';
    }

    let sum = 0;
    combinedProperties.forEach(property => {
      const pricePerSqft =
        property.status === 'sold' ? property.salePricePerSqft : property.pricePerSqft;
      sum = sum + pricePerSqft;
    });
    return Math.round(sum / combinedProperties.length);
  };

  const openPopup = () => {
    setIsOpen(true);
    trackClick?.();
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const id = oneSheeterSlide ? 'oneSheeter' : 'marketOverview';
  const content = calculateAvgPricePerSqft();
  const isDisabled = !combinedProperties.length;

  return (
    <Fragment>
      <MarketAssessmentCard
        headline='Price per SQFT'
        content={content}
        description='Average price per square foot of all comparable properties'
        bgImg={bgImg}
        bgImgLight={bgImgLight}
        clicked={openPopup}
        hasCircle
        disabled={isDisabled}
        oneSheeter={oneSheeterSlide}
        sup={<FontAwesomeIcon icon={['fas', 'dollar-sign']} fontSize={20} />}
      />

      <BasicModal id={id} isOpen={isOpen} onClose={closePopup}>
        <PricePerSquareFootSlide category={category || 'traditional'} />
      </BasicModal>
    </Fragment>
  );
};
