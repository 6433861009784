import React from 'react';
import { optionCSS } from 'react-select/src/components/Option';
import { useActiveSection } from '../../../../../../components/Slide/providers/ActiveSectionProvider';
import { usePresentationMode } from '../../../../../../providers/providers/PresentationModeProvider';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { TPresentationMode } from '../../../../../../types';
import { SettingsOptionsButton } from '../components/SettingsOptionsButton/SettingsOptionsButton.component';
import { useSetAtom } from 'jotai';
import { isPropertyModalOpenAtom } from '../../../../../../features/report/state/isPropertyModalOpenAtom';

type TModeOption = {
  label: 'string';
  value: TPresentationMode;
};

const modeOptions = [
  {
    label: 'Presentation',
    value: 'regular',
  },
  {
    label: 'CMA',
    value: 'cma',
  },
  {
    label: 'Single Page Review',
    value: 'oneSheeter',
  },
];

export const PresentationMode: React.FC = () => {
  const { setSelectedSlide } = useSelectedSlide();
  const { setActiveSection } = useActiveSection();
  const { presentationMode, setPresentationMode } = usePresentationMode();
  const setIsPropertyModalOpen = useSetAtom(isPropertyModalOpenAtom);

  const onPresentationModeSelection = (val: string) => {
    setSelectedSlide(null);
    setActiveSection(null);
    setIsPropertyModalOpen(false);
    setPresentationMode(val as TPresentationMode);
  };

  const selectedMode = modeOptions.find(option => option.value === presentationMode);

  return (
    <SettingsOptionsButton
      options={modeOptions as TModeOption[]}
      onSelectOption={onPresentationModeSelection}
      defaultSelected={selectedMode?.value ?? ''}
      selectedOption={selectedMode as { label: string; value: string }}
      label={selectedMode?.label ?? ''}
      settingsTypeLabel='Mode'
      icon={['fas', 'bullseye']}
    />
  );
};
