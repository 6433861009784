import React from 'react';

import { pricingRateOfChangeData } from '../data';
import { SlideWithGraphWrapper } from '../../../../../components/common/SlideWithGraphWrapper';
import { TCategory } from '../../../../../../../../types';
import { PricingRateOfChangeChart } from '../../../../../../../../features/report/charts/pricing-rate-of-change/PricingRateOfChangeChart';

interface PricingRangeOfChangeSlideProps {
  category: TCategory;
}

export const PricingRangeOfChangeSlide: React.FC<PricingRangeOfChangeSlideProps> = ({
  category,
}) => (
  <SlideWithGraphWrapper
    description={pricingRateOfChangeData.description}
    heading={pricingRateOfChangeData.heading}
    category={category}
  >
    <PricingRateOfChangeChart />
  </SlideWithGraphWrapper>
);

export default PricingRangeOfChangeSlide;
