import { useTheme } from 'styled-components';

import { FEATURES, useHasFeatureFlag } from '../../../../../../hooks/useFeatureFlags';

import btArrow from '../../images/bt_arrow.svg?url';
import customization from '../../images/customization_settings.png';
import whiteLabelArrow from '../../images/whiteLabelArrow';

import { Text, Title, ImageCP, ImageBT, TextContainer, HeroContainer } from './HeroSection.styles';
import { svgStringToDataUrl } from '../../../../../../components/Slide/services/svgStringToDataUrl';
import { useWhiteLabelSettings } from '../../../../../../features/whitelabel/useWhiteLabelSettings';

export function HeroSection() {
  const theme = useTheme();

  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const { hasWL, primary, secondary, isPremium } = useWhiteLabelSettings();

  const logoColor = hasWL && isPremium ? secondary : primary;

  const whiteLabeled = svgStringToDataUrl(whiteLabelArrow, logoColor, logoColor);

  const textColor = shouldUseBtTheme
    ? theme.colors.v2.text.regular
    : theme.colors.v2.text.contrastText;

  return (
    <HeroContainer isBT={shouldUseBtTheme}>
      <TextContainer>
        <Title variant='h2' color={textColor}>
          Customize & Personalize Your Presentations
        </Title>
        <Text variant='body1' color={textColor}>
          Do you know the look and feel of the presentation you want? Set the default and all of
          your presentations will have that same design. No surprises from one presentation to the
          next. It’s never been so easy to personalize!
        </Text>
      </TextContainer>
      {hasWL ? (
        <img src={whiteLabeled} alt='white labeled arrow image' />
      ) : shouldUseBtTheme ? (
        <ImageBT src={btArrow} alt='arrow image' />
      ) : (
        <ImageCP src={customization} alt='customization image' />
      )}
    </HeroContainer>
  );
}
