import React from 'react';

import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper } from './OneImageGamma.styles';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';

interface Props {
  reverse?: boolean;
}

export const OneImageGammaForm: React.FC<Props> = ({ reverse }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  const onChangeText = (value: string) => {
    setSlideDataProperty('text', value);
  };

  if (!selectedSlide) return null;

  return (
    <Container style={{ flexDirection: reverse ? 'column-reverse' : 'column' }}>
      <SlideTextBlock
        isEditable
        text={selectedSlide.data?.text}
        headline={selectedSlide.data?.headline}
        onChangeText={onChangeText}
        onChangeHeadline={onChangeHeadline}
        containerStyles={{ marginBottom: reverse ? 0 : 40, height: '46%' }}
      />
      <ImageWrapper style={{ marginBottom: !reverse ? 0 : 40 }}>
        <UploadImage
          image={selectedSlide.data?.image}
          slideDataConfigPropertyName={'image'}
          imageSizeSuggestion='1504x329'
        />
      </ImageWrapper>
    </Container>
  );
};
