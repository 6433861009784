'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

var _default = function _default() {
  return window;
};

exports.default = _default;
