import React from 'react';

import { TAddSlideBtnStyle, TDynamicSectionId, TPresentationMode } from '../../../../../types';
import { PRESENTATION_TYPES } from '../../../../../constants/PresentationTypes';
import { AddSlideToOrderConfig } from './AddSlideToOrderConfig/AddSlideToOrderConfig';
import { AddSlideRegularMode } from './AddSlideRegularMode/AddSlideRegularMode';
import { usePresentationMode } from '../../../../../providers/providers/PresentationModeProvider';
import { usePresentationType } from '../../../../../providers/providers/PresentationTypeProvider';

interface Props {
  section: TDynamicSectionId;
  btnStyle?: TAddSlideBtnStyle;
}

type TModeComponentMap = Record<TPresentationMode, React.FC<Props>>;

const addSlideComponentsByMode: TModeComponentMap = {
  regular: AddSlideRegularMode,
  cma: AddSlideToOrderConfig,
  oneSheeter: AddSlideToOrderConfig,
};

export const AddSlides: React.FC<Props> = ({ section, btnStyle = 'add' }) => {
  const { presentationMode } = usePresentationMode();
  const { presentationType } = usePresentationType();

  if (presentationType === PRESENTATION_TYPES.BUYER_TOUR)
    return <AddSlideToOrderConfig section={section} />;

  const AddComponent = addSlideComponentsByMode?.[presentationMode] ?? AddSlideRegularMode;

  return <AddComponent section={section} btnStyle={btnStyle} />;
};
