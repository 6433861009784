import React from 'react';
import styled from 'styled-components';
import * as data from '../../data';
import slideGraphics from '../../assets/slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';
import canadaImage from './image.png';

type WithCanadaUser = {
  isCanadaUser?: boolean;
};

const Col = styled('div')({ display: 'flex', flexDirection: 'column' });

const Container = styled(Col)({
  width: '100%',
  height: '100%',
  border: '1px solid #DDDDDD',
  backgroundColor: '#F6F6F6',
});

const BottomContent = styled(Col)<WithCanadaUser>(({ isCanadaUser }) => ({
  borderRadius: isCanadaUser ? 0 : 5,
  marginBottom: isCanadaUser ? 0 : 15,
  paddingBottom: isCanadaUser ? 0 : 15,
  paddingTop: isCanadaUser ? 0 : 50,
  overflow: 'hidden',
}));

const Graphics = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  width: isCanadaUser ? '100%' : '85%',
  alignSelf: 'center',
  margin: '0',
  marginBottom: 10,
  filter: isCanadaUser ? 'grayscale(1)' : 'none',
}));

const TopContent = styled(Col)<WithCanadaUser>(({ isCanadaUser }) => ({
  padding: isCanadaUser ? '30px 30px 58px 30px' : '30px 30px 80px 30px',
  flexShrink: 0,
  backgroundColor: '#fff',
}));

const Heading = styled('h2')(({ theme }) => ({
  fontWeight: 900,
  color: '#444',
  marginBottom: 10,
  letterSpacing: '0.08em',
  textTransform: 'uppercase',
  fontSize: 26,
}));

const common = {
  color: '#010101',
  fontSize: 16,
  lineHeight: 1.3,
};

const Text = styled('p')(({ theme }) => ({
  textAlign: 'justify',
  width: '55%',
  letterSpacing: useUiConfigBodyLetterSpacing(),
  ...common,
  '&:not(:last-child)': {
    marginBottom: 35,
  },
}));

const SourceText = styled('p')({
  textAlign: 'right',
  fontSize: 13,
  color: '#101010',
  fontWeight: 300,
  marginTop: 15,
  paddingRight: 25,
});

const UL = styled('ul')({
  paddingLeft: 20,
  marginTop: -25,
});

const LI = styled('li')(({ theme }) => ({
  ...common,
  marginBottom: 5,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const WhyPricingCorrectlyIsImportantLuxuryPDF: React.FC = () => {
  const { isCanadaUser } = useUser();
  const primary = useUiConfigColorsPrimary();
  const fontFamily = useUiConfigFontsHeader();
  const letterSpacing = useUiConfigHeaderLetterSpacing();
  const image = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  return (
    <PdfFrame category={CATEGORIES.LUXURY}>
      <Container>
        <TopContent isCanadaUser={isCanadaUser}>
          <Heading style={{ fontFamily, color: primary, letterSpacing }}>{data.heading}</Heading>
          {data.paragraphs.map((p, i) => (
            <Text key={i}>{p}</Text>
          ))}
          <UL>
            {data.listItems.map((li, i) => (
              <LI key={i}>{li}</LI>
            ))}
          </UL>
        </TopContent>
        <BottomContent isCanadaUser={isCanadaUser}>
          <Graphics src={isCanadaUser ? canadaImage : image} isCanadaUser={isCanadaUser} />
          {!isCanadaUser && <SourceText>Source: Zillow Listing Data</SourceText>}
        </BottomContent>
      </Container>
    </PdfFrame>
  );
};

export default WhyPricingCorrectlyIsImportantLuxuryPDF;
