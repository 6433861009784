import React from 'react';
import { Container, Rectangle, RectangleText } from './BuyerInterestInfo.styles';
import { BuyerInterestGraph } from './BuyerInterestGraph/BuyerInterestGraph.component';
import { NumberFormatter } from '../../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
interface BuyerInterestInfoProps {
  data: any;
  isWeb?: boolean;
}
export const BuyerInterestInfo: React.FC<BuyerInterestInfoProps> = ({ data, isWeb }) => {
  return (
    <Container>
      <BuyerInterestGraph data={data} isWeb={isWeb} />
      <Rectangle isWeb={isWeb}>
        <RectangleText isWeb={isWeb}>{data?.leads?.contacts?.label}</RectangleText>
        <RectangleText isWeb={isWeb} style={{ fontSize: isWeb ? 28 : 26, marginTop: 10 }}>
          {!Number.isNaN(parseInt(data?.leads.contacts?.count))
            ? NumberFormatter.format(data?.leads.contacts?.count)
            : data?.leads.contacts?.count}
        </RectangleText>
      </Rectangle>
    </Container>
  );
};
