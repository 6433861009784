import { cmaApi } from '../cmaApiCommunicator';
import { ReportUpdateObservable } from './ReportUpdateObservable';
import { ReportDetailedApiMapper } from './ReportDetailedApiMapper';

export class ReportDetailedCommunicator {
  static async build(data) {
    const apiReportDetailed = await cmaApi.post(
      '/cma/build',
      ReportDetailedApiMapper.entityToApi(data),
    );

    const report = ReportDetailedApiMapper.apiToEntity(apiReportDetailed);
    ReportUpdateObservable.onUpdate(report);

    return report;
  }

  static async buildPartial(data) {
    const apiReportDetailed = await cmaApi.post('/cma/build/partial', data);

    const report = ReportDetailedApiMapper.apiToEntity(apiReportDetailed);
    ReportUpdateObservable.onUpdate(report);

    return report;
  }
}
