import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { TextGamma } from './TextGamma';
import { TextGammaForm } from './TextGammaForm';
import { TextGammaPdf } from './TextGammaPdf';

import thumbnail from './thumbnail.png';

const template = 'textLayoutGamma';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Text Only',
  frame: true,
  data: {
    text: '',
    text2: '',
    headline: '',
    headline2: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TextGammaForm,
  web: TextGamma,
  pdf: TextGammaPdf,
};

export type TTextLayoutGamma = typeof template;
export { config, mappings, thumbnail };
