import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { UploadImageButton } from '../../../../Common/UploadImageButton/UploadImageButton.component';
import PdfViewer from '../components/UploadFileModal/PdfViewer';
import { UploadPdfPresentationModal } from '../components/UploadPdfPresentationModal.component';
import { PdfViewerWrapper, Container } from './PdfPresentation.styles';

export const PdfPresentationForm: React.FC = () => {
  const { selectedSlide } = useSelectedSlide();
  const [isLoaded, setIsLoaded] = useState(false);

  const webPdf = selectedSlide?.data?.webPdf;
  const pdf = selectedSlide?.data?.pdf;

  const [open, setOpen] = useState(!webPdf && !pdf);
  if (!selectedSlide) return null;
  return (
    <Container>
      {(!!webPdf || !!pdf) && (
        <>
          <PdfViewerWrapper>
            <PdfViewer
              pdfUrl={webPdf ? webPdf : pdf}
              containerHeight={900}
              setIsLoaded={setIsLoaded}
            />
          </PdfViewerWrapper>
          {isLoaded && (
            <UploadImageButton
              onClick={() => setOpen(true)}
              containerStyles={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            />
          )}
        </>
      )}
      <UploadPdfPresentationModal
        open={open}
        onClose={() => setOpen(false)}
        initialSrcWeb={webPdf}
        initialSrcPdf={pdf}
      />
    </Container>
  );
};
