import styled from 'styled-components';

export const BackgroundContainer = styled('div')({
  width: '100%',
  position: 'relative',
  height: '100%',
  overflow: 'hidden',
});

export const Image = styled('img')({
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});
