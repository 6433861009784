import styled from 'styled-components';
import { MoneyFormatter } from '../PresentationCreate/dash/services/moneyFormatter';
import React, { useState } from 'react';
import { Button } from '../../components/Common';

const ListingPhoto = styled('img')({
  overflow: 'hidden',
  maxWidth: '100%',
  borderRadius: '10px',
  maxHeight: '150px',
  marginBottom: '5px',
});
const Splitter = styled('div')({
  height: '1px',
  width: '100%',
  marginTop: '5px',
  marginBottom: '5px',
  backgroundColor: '#666',
});

const ListingValues = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
});

const Warning = styled('div')({
  display: 'flex',
  width: '100%',
  backgroundColor: '#FF7276',
  border: '1px solid #E6676B',
  borderRadius: '5px',
  padding: '5px',
  margin: '5px 0',
  alignItems: 'center',
});

const RawDiv = styled('div')({
  overflow: 'scroll',
  maxWidth: '100%',
  backgroundColor: '#fff',
  border: '1px solid #bbb',
  padding: '5px',
  margin: '5px 0',
});

const ListingValue = ({ label = '', value = '' }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '80%',
        padding: '10px 0',
        justifyContent: 'space-between',
      }}
    >
      <span style={{ fontWeight: 'bold' }}>{label}:</span>
      <span>{value}</span>
    </div>
  );
};

export const SingleListing = ({ listing, debugMode }: any) => {
  const [rawMode, setRawMode] = useState(false);

  const SingleListingDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '24%',
    padding: '10px',
    marginTop: '10px',
    backgroundColor: theme.colors.gray[200],
    borderRadius: '10px',
  }));
  const isLocationSet = listing.location?.length === 2;
  const isOnMarketOrSoldSet = listing.is_sold_data || listing.is_on_market;
  return (
    <SingleListingDiv>
      {debugMode && (
        <>
          {!isLocationSet && <Warning>Location is not set!</Warning>}
          {!isOnMarketOrSoldSet && <Warning>is_sold_data and is_on_market are both false!</Warning>}
        </>
      )}
      <ListingPhoto src={listing?.thumbnail_url} alt='listing photo' />
      <h2>{listing.address}</h2>
      <h3>
        {listing.city} {listing.state} {listing.zip}
      </h3>
      <Splitter />
      <h3>
        {listing.beds} beds / {listing.baths} baths
      </h3>
      <ListingValues>
        <ListingValue label='Listing Status' value={listing.actual_status_name} />
        <ListingValue label='Listing Type' value={listing.actual_type_name} />
        <ListingValue
          label='Listing Date'
          value={new Date(listing.listingdate * 1000).toLocaleDateString()}
        />
        <ListingValue label='Listing Price' value={MoneyFormatter.format(listing.price)} />
        <ListingValue label='Size' value={`${listing.footage} sqft`} />
        <ListingValue label='Days On Market' value={listing.days_on_market} />
        <ListingValue label='MLS Source ID' value={listing.mls} />
        {isLocationSet && (
          <ListingValue
            label='Location'
            value={`${listing.location[0].toFixed(3)}, ${listing.location[1].toFixed(3)}`}
          />
        )}
        {listing.sold_date && (
          <ListingValue
            label='Sale Date'
            value={new Date(listing.sold_date * 1000).toLocaleDateString()}
          />
        )}
        {listing.sold_price && (
          <ListingValue label='Sale Price' value={MoneyFormatter.format(listing.sold_price)} />
        )}
      </ListingValues>
      <Button onClick={() => setRawMode(!rawMode)} btnstyle='primary' size='large'>
        {rawMode ? 'Close Raw Data' : 'View Raw Data'}
      </Button>
      {rawMode && (
        <RawDiv>
          <pre>{JSON.stringify(listing, null, 2)}</pre>
        </RawDiv>
      )}
    </SingleListingDiv>
  );
};
