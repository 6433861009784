import { useAtomValue } from 'jotai';
import { useTheme } from 'styled-components';

import { Button as Btn } from '../../../../components/Common';
import { IconName } from '../../../../components/Common/Icon/Icon.types';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import { trackElementClick } from '../../../../pages/PresentationCreate/dash/communicators/tracking';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';

interface Props {
  label: string;
  iconName: IconName;
  isOpen: boolean;
  onOpen: () => void;
}

export const Button = ({ label, iconName, isOpen, onOpen }: Props) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primaryColor = useUiConfigColorsPrimary();
  const theme = useTheme();

  const color = isPresentationView ? primaryColor : theme.colors.v2.primary;

  return (
    <Btn
      btnstyle='option'
      onClick={() => {
        onOpen();
        if (!isPresentationView) return;

        trackElementClick(`Clicked ${label}`);
      }}
      icon={{
        name: iconName,
        fill: isOpen ? '#fff' : '#444',
        style: { marginRight: 5 },
      }}
      style={{
        height: 35,
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: -0.385,
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
        ...(isOpen ? { backgroundColor: color, color: '#fff', fontWeight: 700 } : {}),
      }}
    >
      {label}
    </Btn>
  );
};
