import React, { useEffect, useMemo, useRef } from 'react';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import {
  Container,
  PropertyAddress,
  PropertyImage,
  PropertyInfoContainer,
  PropertyInfoItems,
  PropertyInfoItem,
  PropertyPreview,
  ReportInfo,
  PresentedTitle,
} from './CoverPdf.styles';

import { BrokerContactInfo } from '../components/BrokerContactInfo/BrokerContactInfo';
import { Report } from '../../../../../../../pages/PresentationCreate/dash';
import { useUser } from '../../../../../providers/UserProvider';
import { useSlide } from '../../../../../providers/SlideProvider';
import { useParams } from 'react-router-dom';
import { usePresentation } from '../../../../../../../hooks/usePresentation';
import { TAddress } from '../../../../../../../types';
import { NumberFormatter } from '../../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { FEATURES, useHasFeatureFlag } from '../../../../../../../hooks/useFeatureFlags';

export const CoverRegularPdf: React.FC = () => {
  const { category } = useCategory();
  const { address, addressShort, beds, size, totalBaths } = Report.data.subject.useSubjectInfo();
  const updatedAt = Report.data.report.useReportUpdatedAt();
  const user = useUser();
  const [headerHeight, setHeaderHeight] = React.useState(64);
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  const ref = useRef<HTMLDivElement>(null);

  const { hash } = useParams<{ hash: string }>();

  const { data: presentation } = usePresentation(hash);

  const { name, isCanadaUser } = user ?? {};

  const slide = useSlide();
  const displayImage = slide?.data?.displayImage;
  const image = displayImage && slide?.data?.image ? slide?.data?.image : '';
  const editedTitle = slide?.data?.text ? slide.data.text : '';
  const editedFeatures = slide?.data?.text2 ? slide.data.text2 : '';

  const title = useMemo(() => {
    if (editedTitle) return editedTitle;

    if (addressShort) {
      return (
        <>
          {`${addressShort}, ${address.city},`}
          <br />
          {`${address.state} ${address.zip}`}
        </>
      );
    }

    return presentation?.clientName || '';
  }, [address, addressShort, editedTitle, presentation?.clientName]);

  const renderFeatures = editedFeatures ? (
    <PropertyInfoItems>
      <PropertyInfoItem>{editedFeatures}</PropertyInfoItem>
    </PropertyInfoItems>
  ) : (
    <PropertyInfoItems>
      {!isNaN(parseInt(String(beds))) && <PropertyInfoItem>{beds} bedrooms</PropertyInfoItem>}
      {!isNaN(parseInt(String(totalBaths))) && hasBathCountFF && (
        <PropertyInfoItem>{totalBaths} Total Bathrooms</PropertyInfoItem>
      )}
      {!isCanadaUser && size && (
        <PropertyInfoItem>{NumberFormatter.format(size)} SQFT</PropertyInfoItem>
      )}
    </PropertyInfoItems>
  );

  useEffect(() => {
    setHeaderHeight(ref.current?.scrollHeight ?? 64);
  }, []);

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL} isCover>
      <Container>
        <PropertyPreview>
          <ReportInfo>
            Report valid for{' '}
            {new Date(updatedAt).toLocaleDateString('en-US', {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
            })}
          </ReportInfo>
          <PropertyInfoContainer displayImage={displayImage}>
            {image && <PropertyImage src={image} height={headerHeight} />}
            <PropertyAddress displayImage={!!image} ref={ref}>
              <p style={{ fontSize: '12px', fontWeight: 'normal' }}>Presentation prepared for</p>
              {title}
            </PropertyAddress>
          </PropertyInfoContainer>
          {renderFeatures}
        </PropertyPreview>
        <PresentedTitle>Presented by: {name}</PresentedTitle>
        <BrokerContactInfo />
      </Container>
    </PdfFrame>
  );
};
