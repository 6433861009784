import { useAtomValue } from 'jotai';
import { PROPERTY_STATUS, PropertyGroups, TProperty } from '../../../../../../../../types';
import { reportPropertiesAtom } from '../../../../../../../../features/report/state/reportPropertiesAtom';
import { extractPropertyGroups } from '../../../../../../../../features/report/services/extractPropertiesGroups';
import { getPropertyId } from '../../../../../../../../features/report/services/getPropertyId';
import { useMemo } from 'react';

const getExtendedProperty = (property: TProperty, reportProperties: TProperty[]) => {
  const reportProperty = reportProperties?.find(
    reportProperty => getPropertyId(reportProperty) === property.id,
  );
  if (!reportProperty) return property;

  return {
    ...property,
    id: reportProperty?.id || property?.id,
    adjustedPrice: reportProperty?.adjustedPrice,
    mlsListingId: reportProperty?.mlsListingId,
  };
};

export const useExtendBuildPropertiesWithAdjusted = (buildProperties: PropertyGroups) => {
  const reportProperties = useAtomValue(reportPropertiesAtom);

  const reportPropertyGroups = extractPropertyGroups(reportProperties);

  const extended: PropertyGroups = useMemo(
    () => ({
      active:
        buildProperties?.active?.map?.(property => {
          return getExtendedProperty(property, reportPropertyGroups?.active || []);
        }) || [],
      pending:
        buildProperties?.pending?.map?.(property => {
          return getExtendedProperty(property, reportPropertyGroups?.pending || []);
        }) || [],
      sold:
        buildProperties?.sold?.map?.(property => {
          return getExtendedProperty(property, reportPropertyGroups?.sold || []);
        }) || [],
      comingSoon:
        buildProperties?.comingSoon?.map?.(property => {
          return getExtendedProperty(property, reportPropertyGroups?.comingSoon || []);
        }) || [],
    }),
    [buildProperties, reportProperties],
  );

  return extended;
};
