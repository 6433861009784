import {
  LARGER_FLAGS_BEDROOMS,
  LARGER_FLAGS_FOOTAGE,
  SMALLER_FLAGS_BEDROOMS,
  SMALLER_FLAGS_FOOTAGE,
} from '../results-tuning/outliers/flagsConfig';
import { ACTIVE, CLOSED, COMING_SOON, PENDING } from '../results-tuning/trends/trendsConfig';
import { FEATURES } from '../results-tuning/features/featuresConfig';
import { ESTIMATES } from '../results-tuning/estimates/estimatesConfig';

export const getCompsConfigAsCollection = () => [
  ...Object.values(ESTIMATES),
  ...Object.values(FEATURES),
  ...Object.values(ACTIVE),
  ...Object.values(PENDING),
  ...Object.values(CLOSED),
  ...Object.values(COMING_SOON),
  ...Object.values(LARGER_FLAGS_FOOTAGE),
  ...Object.values(LARGER_FLAGS_BEDROOMS),
  ...Object.values(SMALLER_FLAGS_BEDROOMS),
  ...Object.values(SMALLER_FLAGS_FOOTAGE),
];
