import styled from 'styled-components';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
  alignItems: 'center',
  position: 'relative',
  overflow: 'visible',
  marginBottom: 10,
  zIndex: 100,
  paddingRight: useReverseHeader() ? 40 : 20,
  paddingLeft: useReverseHeader() ? 20 : 0,
}));

export const BrokerInfoWrapper = styled('div')({
  position: 'relative',
  zIndex: 6,
  width: '33.33%',
  paddingLeft: '3%',
});

export const Graphics = styled('img')({
  position: 'absolute',
  left: 0,
  width: 1600,
  zIndex: 6,
});
