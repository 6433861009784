import React from 'react';

import { useEmphasis } from './EmphasisProvider';
import { TEmphasis } from '../../../../../../types';
import { useApplyOrdering } from './hooks/ordering/useApplyOrdering';
import { EmphasisInfo } from './EmphasisInfo/EmphasisInfo.component';
import { isWinTheListing } from '../../../../../../constants/PresentationTypes';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../../../../../../providers/providers/PresentationModeProvider';
import { SettingsOptionsButton } from '../components/SettingsOptionsButton/SettingsOptionsButton.component';

export const Emphasis: React.FC = () => {
  const { presentationType } = usePresentationType();
  const { presentationMode } = usePresentationMode();

  const { emphasis, setEmphasis, emphasisConfigs } = useEmphasis();

  useApplyOrdering();

  const options: { label: string; value: TEmphasis }[] =
    emphasisConfigs?.orderById.map(em => emphasisConfigs.configs[em]) ?? [];

  const onEmphasisSelection = (val: string) => {
    setEmphasis(emphasisConfigs?.configs?.[val as TEmphasis] ?? null);
  };

  if (!isWinTheListing(presentationType)) return null;

  return (
    <SettingsOptionsButton
      options={options}
      onSelectOption={onEmphasisSelection}
      defaultSelected={emphasis?.value ?? ''}
      selectedOption={emphasis as { label: string; value: string }}
      AdditionalInfo={EmphasisInfo}
      label={emphasis?.levelLabel ?? emphasis?.label ?? 'Custom Order'}
      settingsTypeLabel='Emphasis'
      icon={['fas', 'bullseye']}
      isDisabled={presentationMode !== 'regular'}
    />
  );
};
