import { TOrderByType, TPresentationType, TSlide } from '../../types';
import { EntityType } from './EntityType';

type CombinableParams = {
  entity: number | null;
  combinedOBT?: TOrderByType;
  superaccountOBT?: TOrderByType;
  accountOBT?: TOrderByType;
  officeOBT?: TOrderByType;
  teamOBT?: TOrderByType;
  usersOBT?: TOrderByType;
};

export class OrderByTypeCombiner {
  static mutate({
    entity,
    combinedOBT,
    superaccountOBT,
    accountOBT,
    officeOBT,
    teamOBT,
    usersOBT,
  }: CombinableParams) {
    if (!superaccountOBT && !accountOBT && !officeOBT && !teamOBT && !usersOBT) return undefined;

    if (entity === -1 || entity === -2) return;

    if (entity === 0) {
      this.collectionToSingleConfig(combinedOBT as TOrderByType, [accountOBT, superaccountOBT]);
      return;
    }

    if (entity !== null && entity > 0 && EntityType.isOfficeEntity(entity)) {
      this.collectionToSingleConfig(combinedOBT as TOrderByType, [
        officeOBT,
        accountOBT,
        superaccountOBT,
      ]);
      return;
    }

    if (entity !== null && entity > 0 && EntityType.isTeamEntity(entity)) {
      this.collectionToSingleConfig(combinedOBT as TOrderByType, [
        teamOBT,
        officeOBT,
        accountOBT,
        superaccountOBT,
      ]);
      return;
    }

    this.collectionToSingleConfig(combinedOBT as TOrderByType, [
      usersOBT,
      teamOBT,
      officeOBT,
      accountOBT,
      superaccountOBT,
    ]);
  }

  private static combineSlides(
    sectionsSlideToCombine: (Record<string, Partial<TSlide>[]> | undefined)[],
  ): Record<string, Partial<TSlide>[]> {
    const combinedSlides = {} as Record<string, Partial<TSlide>[]>;

    sectionsSlideToCombine.forEach(sectionsSlide => {
      if (!sectionsSlide) return;

      Object.keys(sectionsSlide).forEach(section => {
        if (!combinedSlides[section]) {
          combinedSlides[section] = sectionsSlide[section];
          return;
        }

        const missingSlides =
          sectionsSlide[section]?.reduce((map, slide) => {
            if (combinedSlides[section].find(item => item.id === slide.id)) return map;

            return { ...map, [slide.id as string]: slide };
          }, {}) || {};

        combinedSlides[section] = [...combinedSlides[section], ...Object.values(missingSlides)];
      });
    });

    return combinedSlides;
  }

  private static collectionToSingleConfig(
    combinedOBT: TOrderByType,
    orders: (TOrderByType | undefined)[],
  ): TOrderByType | undefined {
    const nonEmptyOrders = orders.filter(Boolean) as TOrderByType[];
    if (nonEmptyOrders.length === 0) return;

    const types = this.getPresentationTypes(orders);

    types.forEach(type => {
      const sectionsSlides = nonEmptyOrders
        .filter(obt => obt?.[type]?.sectionsSlide)
        .map(obt => obt?.[type]?.sectionsSlide);

      if (!sectionsSlides.length) return;

      const sections =
        nonEmptyOrders.find(obt => obt?.[type]?.sections?.length)?.[type]?.sections || [];
      const sectionsSlide = this.combineSlides(sectionsSlides);

      combinedOBT[type] = {
        sections,
        sectionsSlide,
      };
    });
  }

  private static getPresentationTypes(obts: (TOrderByType | undefined)[]): TPresentationType[] {
    const typesWithDuplicates = obts.flatMap(obt => Object.keys(obt || {})).filter(Boolean);
    return Array.from(new Set(typesWithDuplicates)) as TPresentationType[];
  }
}
