import React from 'react';
import { iBuyerData, realtorData, staticText } from '../../data';
import { RealtorVSIBuyerCard } from '../../components//RealtorVSIBuyerCard/RealtorVSIBuyerCard.component';

import {
  CardsContainer,
  Container,
  DescriptionSection,
  DescriptionTitle,
  LeftCol,
  RightCol,
  RightColTitle,
  Subtitle,
  Text,
  Title,
  Uppercase,
} from './TraditionalRealtorVSIBuyer.styles';

export const TraditionalRealtorVSIBuyer: React.FC = () => {
  const { title, subtitle, descriptionTitle, descriptionText, comparisonTitle } = staticText;
  return (
    <Container>
      <LeftCol>
        <Title>{title} </Title>
        <Subtitle>{subtitle}</Subtitle>
        <DescriptionSection>
          <DescriptionTitle>
            <Uppercase>{descriptionTitle.part1} </Uppercase>
            {descriptionTitle.part2}
          </DescriptionTitle>
          <Text>{descriptionText.p1}</Text>
          <Text>{descriptionText.p2}</Text>
          <Text>{descriptionText.p3}</Text>
        </DescriptionSection>
      </LeftCol>
      <RightCol>
        {/*<RightColTitle>{comparisonTitle}</RightColTitle>*/}
        <CardsContainer>
          <RealtorVSIBuyerCard cardData={realtorData} category='traditional' />
          <RealtorVSIBuyerCard cardData={iBuyerData} isIBuyer category='traditional' />
        </CardsContainer>
      </RightCol>
    </Container>
  );
};
