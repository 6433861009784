import { PropertyBoostCardProps } from './Card/Card';
import ico1 from './assets/ico1.png';
import ico2 from './assets/ico2.png';
import ico3 from './assets/ico3.png';

export const paragraphs = [
  `$brand is a one-of-a-kind social media marketing program for listings. It uses intelligent automation to maximize listing exposure, build brand awareness, and generate qualified leads for every new listing.`,
  `Boosts can be specific for announcing a new listing, an open house or a price reduction . PropertyBoost will expose your property to all buyers within a 15 mile radius, that are looking for homes similar to yours.`,
];

export const cards: PropertyBoostCardProps[] = [
  {
    title: 'GENERATE LEADS FROM FACEBOOK ADS',
    text: '1000’s of local potential buyers will be seeing the property.',
    image: ico1,
  },
  {
    title: 'CONSTANT FEEDBACK FOR CLIENTS',
    text: 'Your sellers will be getting constant feedback with daily reports.',
    image: ico2,
  },
  {
    title: 'INCREASE YOUR SPHERE OF INFLUENCE',
    text: 'Because your listing is being boosted socially it can easily go “viral”.',
    image: ico3,
  },
];
