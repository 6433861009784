import React from 'react';

import {
  Container,
  GreetingText,
  GreetingTextWrapper,
  SlidesNavigationList,
  NavigationItem,
  NavigationTextBold,
  NavigationTextLight,
  Circle,
  ImagePreview,
  Overlay,
  NavigationTextWrapper,
} from './PersonalizationAside.styles';
import { useUser } from '../../../../../providers/UserProvider';
import { GoToSlide } from '../../../../../../../services/GoToSlide';
import { useSlideProps } from '../../../../../providers/SlideProvider';
import { TPersonalizeSlide, TSlide, TSlideId } from '../../../../../../../types';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { useSlides } from '../../../../../../../providers/providers/SlidesProvider';
interface SlideMeta {
  category: TSlideId;
  name: string;
}
const slidesNavigationList: SlideMeta[] = [
  {
    category: 'testimonials',
    name: 'My Testimonials',
  },
  {
    category: 'aboutMe',
    name: 'About Me',
  },
];

interface PersonalizationAsideProps {
  activeSlide: TPersonalizeSlide;
}

export const PersonalizationAside: React.FC<PersonalizationAsideProps> = ({ activeSlide }) => {
  const { isEditable, isDefaultConfig } = useSlideProps();
  const { image } = useUser();
  const { category } = useCategory();
  const { getSlide, slides } = useSlides();

  const aboutMeConfig = getSlide('aboutMe');
  const testimonialsConfig = getSlide('testimonials');

  const personalizedSlides = React.useMemo(
    function personalizedSlides(): TSlide[] {
      return ([aboutMeConfig, testimonialsConfig] as TSlide[]).filter(s => !!s);
    },
    [aboutMeConfig, testimonialsConfig],
  );

  const slidesNavItems = React.useMemo(() => {
    if (isEditable) return slidesNavigationList;

    return slidesNavigationList.filter(item =>
      personalizedSlides.some(s => s.id === item.category && s.includeSlide),
    );
  }, [isEditable, personalizedSlides]);

  return (
    <Container isDefaultConfig={isDefaultConfig}>
      {category === 'luxury' && <Overlay />}
      <ImagePreview src={image} />

      <GreetingTextWrapper>
        <GreetingText>Let&apos;s get to know one another.</GreetingText>
        <GreetingText>Here&apos;s some information about me.</GreetingText>
      </GreetingTextWrapper>

      <SlidesNavigationList>
        {slidesNavItems.map((navigationItem, i) => {
          const slideConfig =
            personalizedSlides.filter(item => item.id === navigationItem.category) ?? [];

          const shouldShow = slideConfig.length > 0 ? slideConfig[0]?.includeSlide : true;

          return (
            shouldShow && (
              <NavigationItem
                key={i}
                isActive={navigationItem.category === activeSlide}
                isDefaultConfig={isDefaultConfig}
                onClick={() => GoToSlide.dispatch(navigationItem.category)}
              >
                <Circle
                  isActive={navigationItem.category === activeSlide}
                  isDefaultConfig={isDefaultConfig}
                />
                <NavigationTextWrapper>
                  <NavigationTextLight>{navigationItem.name.split(' ')[0]}</NavigationTextLight>
                  <NavigationTextBold>{navigationItem.name.split(' ')[1]}</NavigationTextBold>
                </NavigationTextWrapper>
              </NavigationItem>
            )
          );
        })}
      </SlidesNavigationList>
    </Container>
  );
};
