import { useCallback, useEffect, useMemo } from 'react';

import { TUserConfigsUi } from '../../../../types';
import { useOrder } from '../../../../providers/providers/OrderProvider';
import { useClient } from '../../../../providers/providers/ClientProvider';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../providers/providers/SectionsProvider';
import { useVideoLink } from '../../providers/VideoLinkProvider';
import { useProperties } from '../../../../providers/providers/PropertiesProvider';
import { useUrlSearchParam } from '../../../../hooks/useQueryParams';
import { PRESENTATION_TYPES } from '../../../../constants/PresentationTypes';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { BuyerTourConfigs } from '../../services/BuyerTourConfigs';
import { useSetUiConfigs } from '../../../../providers/providers/UiConfigProvider';
import { PresentationApi } from '../../../../pages/Presentation/api/PresentationApi';
import { usePresentationType } from '../../../../providers/providers/PresentationTypeProvider';
import { DefaultConfigProvider } from '../../../../services/DefaultConfigProvider';
import { useUserCategoryCustomizations } from '../../../../hooks/useUserCategoryCustomizations';
import { useCreatedAt } from '../../../../providers/providers/CreatedAtProvider';

export function useSetBuyerTourDefaultPresentationConfigs() {
  const targetAction = useUrlSearchParam('action');
  const presentationHash = useUrlSearchParam('presentation');

  const { setSlides } = useSlides();
  const setUiConfigs = useSetUiConfigs();
  const { setSections } = useSections();
  const { setPresentationType } = usePresentationType();
  const { setSectionsOrder, setSectionsSlideOrder } = useOrder();
  const { setVideoLink } = useVideoLink();
  const { setClient } = useClient();
  const { setProperties } = useProperties();
  const { setCreatedAt } = useCreatedAt();

  const { hash, useSpecialBranding } = useUser();
  const ui = useUserCategoryCustomizations(hash, null, useSpecialBranding).data?.ui;

  const userUi = useMemo(() => ui ?? DefaultConfigProvider.getUiConfig(), [ui]);

  const loadPresentationAndSetConfig = useCallback(
    async (ui: TUserConfigsUi) => {
      const presentation = (await PresentationApi.fetch(presentationHash ?? '')) as any;
      if (!presentation) return;

      const {
        clientId,
        clientEmail,
        clientName,
        properties,
        order,
        sections,
        slides,
        videoLink,
        createdAt,
      } = presentation;

      const presentationClient = clientId
        ? {
            id: clientId,
            email: clientEmail ?? '',
            name: clientName ?? '',
          }
        : null;

      setProperties(properties ?? []);
      setClient(presentationClient);
      setSectionsSlideOrder(order?.sectionsSlide ?? {});
      setSectionsOrder(order?.sections ?? []);
      setSections(sections ?? {});

      setSlides(slides);
      setVideoLink(videoLink);
      setUiConfigs(ui);
      setCreatedAt(createdAt);
    },
    [
      presentationHash,
      setClient,
      setCreatedAt,
      setProperties,
      setSections,
      setSectionsOrder,
      setSectionsSlideOrder,
      setSlides,
      setUiConfigs,
      setVideoLink,
    ],
  );

  const setDefaultConfig = useCallback(
    (ui: TUserConfigsUi) => {
      setPresentationType(PRESENTATION_TYPES.BUYER_TOUR);

      setSlides(BuyerTourConfigs.getSlides());
      setSections(BuyerTourConfigs.getSections());

      const order = BuyerTourConfigs.getFullOrder();
      setSectionsOrder(order.sections);
      setSectionsSlideOrder(order.sectionsSlide);

      setUiConfigs(ui);
    },
    [
      setPresentationType,
      setSections,
      setSectionsOrder,
      setSectionsSlideOrder,
      setSlides,
      setUiConfigs,
    ],
  );

  useEffect(
    function setBuyerTourDefaultPresentationConfig() {
      if (!userUi) return;

      if (targetAction === 'create') {
        setDefaultConfig(userUi);
        return;
      }

      loadPresentationAndSetConfig(userUi);
    },
    [loadPresentationAndSetConfig, setDefaultConfig, targetAction, ui, userUi],
  );
}
