import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';

import {
  FloatWrapper,
  ImageWrapper,
  ImagePreview,
  UploadIconWrapper,
  Image,
  Text,
} from './AboutMe.styles';
import { MediaApi } from '../../../../../../../../api/MediaApi';
import { CommonFrameProps } from '../../../../../../../../types';
import { PersonalSlidesLayout } from '../../PersonalSlidesLayout';
import { useUser } from '../../../../../../providers/UserProvider';
import { useSlide, useSlideProps } from '../../../../../../providers/SlideProvider';
import { useSlides } from '../../../../../../../../providers/providers/SlidesProvider';
import { UploadImageButton } from '../../../../../../../Common/UploadImageButton/UploadImageButton.component';

export const AboutMe: React.FC<CommonFrameProps> = () => {
  const slide = useSlide();
  const { bio } = useUser();
  const { isEditable } = useSlideProps();
  const { setSlideData } = useSlides();

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    const imageUpload = async (file: File) => {
      try {
        const imageUrl = await MediaApi.upload(file);
        setSlideData('aboutMe', { image: imageUrl });
      } catch (error) {
        console.error(error);
      }
    };

    if (file) {
      imageUpload(file);
    }
  }, [file, setSlideData]);

  if (!slide) return null;

  const { text, displayImage, image } = slide?.data || {};

  let slideText = text ? text : bio;
  // Bit hacky, remove img
  slideText = slideText.replace(/<img[^>]*>/g, '');

  return (
    <PersonalSlidesLayout slideId='aboutMe' isEditable={isEditable}>
      {displayImage && (
        <FloatWrapper>
          <ImageWrapper>
            <ImagePreview isEditable={isEditable}>
              <Image style={{ backgroundImage: `url('${image}')` }} />
              {isEditable && (
                <UploadIconWrapper>
                  <UploadImageButton setFile={setFile} />
                </UploadIconWrapper>
              )}
            </ImagePreview>
          </ImageWrapper>
        </FloatWrapper>
      )}
      <Text id='aboutText'>{parse(slideText)}</Text>
    </PersonalSlidesLayout>
  );
};
