import { getAnalyticsFeedbackText } from '../../services/getAnalyticsFeedbackText';
import { FEEDBACK_RESPONSE, MORE_TIME_DESCRIPTIONS } from '../../types';

interface MonthData {
  name: string;
  count: number;
  count_per_agent: number;
  open_rate: number;
}
interface SellerBreakdown {
  competitive_market: number;
  hoping_to_list_for_more: number;
  meeting_with_other_agents: number;
  other: number;
  time_it_will_take_to_sell: number;
}
interface BuyerBreakdown {
  competitive_market: number;
  hoping_to_buy_for_less: number;
  meeting_with_other_agents: number;
  other: number;
  time_it_will_take_to_buy: number;
}
interface User {
  hash: string;
  office: string;
  email: string;
  last_presentation: number | null;
  name: string;
  open_rate: number;
  presentation_count: number;
  top_seller_barrier: string;
  top_buyer_barrier: string;
  top_feedback_buyer: string;
  top_feedback_seller: string;
  unique_addresses: number;
  unique_clients: number;
  unique_presentations: number;
}
export interface ApiResponse {
  reports_per_month: {
    months: MonthData[];
    total: number;
    total_open_rate: number;
    total_per_agent: number;
  };
  tracking: {
    buyer: {
      lets_get_started: number;
      more_info: number;
      more_info_breakdown: BuyerBreakdown;
    };
    seller: {
      lets_get_started: number;
      more_info: number;
      more_info_breakdown: SellerBreakdown;
    };
  };
  users: User[];
}
export const mapData = (apiData: ApiResponse) => {
  return {
    reportsSent: {
      totalReportsSent: apiData?.reports_per_month?.total,
      reportsSentData: apiData?.reports_per_month?.months.map((month: MonthData) => {
        return {
          month: month.name,
          office: month.count,
        };
      }),
    },
    reportsSentPerAgent: {
      averageSentReports: apiData?.reports_per_month?.total_per_agent,
      reportsSentPerAgentData: apiData?.reports_per_month?.months.map((month: MonthData) => {
        return {
          month: month.name,
          office: month.count_per_agent,
        };
      }),
    },
    reportsOpenRate: {
      averageOpenRate: Math.round(apiData?.reports_per_month?.total_open_rate * 100),
      reportOpenRateData: apiData?.reports_per_month?.months.map((month: MonthData) => {
        return {
          month: month.name,
          office: Math.round(month.open_rate * 100),
        };
      }),
    },
  };
};

export const mapTrackingData = (apiData: ApiResponse) => {
  return {
    nextStepsFeedback: {
      sellers: [
        {
          title: 'Lets get started',
          office: Math.round(apiData.tracking.seller.lets_get_started * 100) + '%',
        },
        {
          title: 'Need more time',
          office: Math.round(apiData.tracking.seller.more_info * 100) + '%',
        },
      ],
      buyers: [
        {
          title: 'Lets get started',
          office: Math.round(apiData.tracking.buyer.lets_get_started * 100) + '%',
        },
        {
          title: 'Need more time',
          office: Math.round(apiData.tracking.buyer.more_info * 100) + '%',
        },
      ],
    },
    sellerBarrierBreakdown: [
      {
        title: 'Time it will take to sell',
        office:
          Math.round(apiData.tracking.seller.more_info_breakdown.time_it_will_take_to_sell * 100) +
          '%',
      },
      {
        title: 'Competitive Market',
        office:
          Math.round(apiData.tracking.seller.more_info_breakdown.competitive_market * 100) + '%',
      },
      {
        title: 'Hoping to list for more',
        office:
          Math.round(apiData.tracking.seller.more_info_breakdown.hoping_to_list_for_more * 100) +
          '%',
      },
      {
        title: 'Meeting with other agents',
        office:
          Math.round(apiData.tracking.seller.more_info_breakdown.meeting_with_other_agents * 100) +
          '%',
      },
      {
        title: 'Other',
        office: Math.round(apiData.tracking.seller.more_info_breakdown.other * 100) + '%',
      },
    ],
    buyerBarrierBreakdown: [
      {
        title: 'Time it will take to buy',
        office:
          Math.round(apiData.tracking.buyer.more_info_breakdown.time_it_will_take_to_buy * 100) +
          '%',
      },
      {
        title: 'Competitive Market',
        office:
          Math.round(apiData.tracking.buyer.more_info_breakdown.competitive_market * 100) + '%',
      },
      {
        title: 'Hoping to buy for less',
        office:
          Math.round(apiData.tracking.buyer.more_info_breakdown.hoping_to_buy_for_less * 100) + '%',
      },
      {
        title: 'Meeting with other agents',
        office:
          Math.round(apiData.tracking.buyer.more_info_breakdown.meeting_with_other_agents * 100) +
          '%',
      },
      {
        title: 'Other',
        office: Math.round(apiData.tracking.buyer.more_info_breakdown.other * 100) + '%',
      },
    ],
  };
};

export const mapUserData = (apiData: ApiResponse) => {
  return apiData.users.map(user => {
    return {
      name: user.name,
      hash: user.hash,
      email: user.email,
      officeName: user.office,
      dateLastUsed: user.last_presentation
        ? new Date(user.last_presentation).toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
      reportsSent: user.presentation_count,
      openRate: Math.round(user.open_rate * 100) + '%',
      topBuyerFeedback: getAnalyticsFeedbackText(
        user.top_feedback_buyer as FEEDBACK_RESPONSE,
        user.top_buyer_barrier as keyof typeof MORE_TIME_DESCRIPTIONS,
      ),
      topSellerFeedback: getAnalyticsFeedbackText(
        user.top_feedback_seller as FEEDBACK_RESPONSE,
        user.top_seller_barrier as keyof typeof MORE_TIME_DESCRIPTIONS,
      ),
      uniqueAddresses: user.unique_addresses,
      uniqueClients: user.unique_clients,
      uniquePresentations: user.unique_presentations,
    };
  });
};
