export const heading = 'Why pricing correctly is important';

export const paragraphs = [
  `How a home is priced directly impacts the volume of potential buyers that a home attracts.`,
  `Pricing is psychological.
`,
];

export const listItems = [
  `Pricing at value: Triggers a sense of urgency in the buyer to act immediately and not let "a deal" pass by.`,
  `Pricing well below value: Opens up buyer concerns about what might be wrong with the home.`,
  `Pricing well above value: Closes off a pool of buyers that have set limits on their search.`,
];
