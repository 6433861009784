import { CSSProperties } from 'react';
import styled from 'styled-components';
import { useAtomValue } from 'jotai';

import { ACTIVE_COLOR } from '../../../../pages/PresentationCreate/dash/config/constants';
import { ScatterChart } from '../components/ScatterChart';
import { ChartContainer } from '../components/ChartContainer';
import { ActiveShelfLifeTooltip } from './ActiveShelfLifeTooltip';
import { usePropertiesForActiveShelfLifeChart } from './usePropertiesForActiveShelfLifeChart';
import { useShowPropertyDetails } from '../../hooks/useShowPropertyDetails';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';

interface ActiveShelfLifeChartProps {
  headline?: string;
  description?: string;
  style?: CSSProperties;
}

export const ActiveShelfLifeChart = ({
  headline,
  description,
  style,
}: ActiveShelfLifeChartProps) => {
  const data = usePropertiesForActiveShelfLifeChart();

  const { showPropertyDetails } = useShowPropertyDetails();
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const onMoreInfo = (id: string) => {
    if (isPresentationView) return;
    const property = data.find(p => p.id === id);
    showPropertyDetails(property || null);
  };

  return (
    <ChartContainer
      headline={headline}
      description={description && <Description description={description} />}
      childrenWrapperStyle={style}
    >
      <ScatterChart
        data={data}
        dataKey='daysOnMarket'
        moreInfo={onMoreInfo}
        customTooltip={props => <ActiveShelfLifeTooltip {...props} />}
        color={ACTIVE_COLOR}
        xDataKey='daysOnMarket'
        xLabel={{ value: 'DAYS ON MARKET', offset: -6, position: 'bottom', fontWeight: 'normal' }}
        yDataKey='price'
        yLabel={{ value: 'LIST PRICE', angle: -90, position: 'left' }}
        id='colorPvNewShelf'
        margin={{ top: 10, right: 10, bottom: 10, left: 11 }}
        scatterDataSets={[{ dataKey: 'daysOnMarket', fill: 'url(#colorPvNewShelf)' }]}
      />
    </ChartContainer>
  );
};

const Description = ({ description }: { description: string }) => (
  <DescriptionContainer>
    <div>{description}</div>
  </DescriptionContainer>
);

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
