import { ITestimonial } from '../types';
import { limitLongString } from './limitLongString';

export const getTestimonials = (
  slideTestimonials: ITestimonial[],
  userApiTestimonials: ITestimonial[],
) => {
  const hasSlideTestimonials = slideTestimonials?.some(t => t.text || t.client);
  const hasUserTestimonials = userApiTestimonials?.some(t => t.text || t.client);

  if (!hasSlideTestimonials && hasUserTestimonials) {
    const finalTestimonials = [...userApiTestimonials, ...slideTestimonials].slice(0, 3);
    const maxTestimonialsChars = 1100;
    let currCharCount = 0;

    return finalTestimonials.map((t, i) => {
      let text = t.text;
      currCharCount += text.length;
      if (i === 2 && currCharCount > maxTestimonialsChars) {
        const remaining = text.length - Math.abs(maxTestimonialsChars - currCharCount);
        text = limitLongString(text, remaining);
      }
      return {
        id: `testimonial_${i + 1}`,
        text: text,
        client: t.client,
        isHidden: t.isHidden,
      };
    });
  }
  return slideTestimonials;
};
