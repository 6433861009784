import { TNote } from '../../../../../../types';
import { TextDescription } from '../../../../../../components/Property/DetailDescription/components/UI';
import { SectionDropdown } from '../../../../../../components/Property/DetailDescription/components/SectionDropdown';
import { DropdownKeys } from '../DetailDescription';

interface Props {
  note: string;
  openedDropdown: string;
  setOpenedDropdown: (value: string) => void;
}

export const NotesPreview = ({ note, openedDropdown, setOpenedDropdown }: Props) => {
  if (!note) return null;

  return (
    <SectionDropdown
      title='Notes'
      isOpen={openedDropdown === DropdownKeys.NOTES}
      setOpen={() => {
        if (openedDropdown === DropdownKeys.NOTES) return setOpenedDropdown('');
        setOpenedDropdown(DropdownKeys.NOTES);
      }}
      containerStyle={{ gap: 15 }}
    >
      <TextDescription key={note}>{note}</TextDescription>
    </SectionDropdown>
  );
};
