import React from 'react';
import { CATEGORIES } from '../../../../../../constants/categories';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { Container, ImageWrapper, ImagePreview } from './TwoImagesEpsilon.styles';
import { useSlide } from '../../../../providers/SlideProvider';
import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { ImageProxy } from '../../../../services/ImageProxy';

interface Props {
  reverse?: boolean;
}
const TwoImagesEpsilonPdf: React.FC<Props> = ({ reverse }) => {
  const { category } = useCategory();
  const slide = useSlide();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container isPdf>
        <ImageWrapper style={{ gridColumn: reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image, { width: 750 })} />
        </ImageWrapper>
        <SlideTextBlock
          isEditable={false}
          text={slide.data?.text}
          headline={slide.data?.headline}
          containerStyles={{ gridColumn: !reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}
        />
        <SlideTextBlock
          isEditable={false}
          text={slide.data?.text2}
          headline={slide.data?.headline2}
          containerStyles={{ gridColumn: reverse ? '2 / 2' : '1 / 1', gridRow: '2 / 2' }}
        />
        <ImageWrapper style={{ gridColumn: !reverse ? '2 / 2' : '1 / 1', gridRow: '2 / 2' }}>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image2, { width: 750 })} />
        </ImageWrapper>
      </Container>
    </PdfFrame>
  );
};

export default TwoImagesEpsilonPdf;
