import { TCompConfigItem } from '../../../../../types';
import { FeatureGroup } from '../components/FeatureGroup';
import { Section } from '../components/Section';
import { FEATURES } from '../featuresConfig';
import { FEATURES_KEYS } from '../types';

export const OtherFeaturesOffMarket = () => {
  return (
    <Section isClosed title='OTHER:'>
      <FeatureGroup
        title='ASSOCIATION FEES'
        fields={
          [
            FEATURES[FEATURES_KEYS.HOA_1_OFF_MARKET],
            FEATURES[FEATURES_KEYS.HOA_0_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='FIXER UPPER'
        fields={
          [
            FEATURES[FEATURES_KEYS.FIXER_UPPER_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.FIXER_UPPER_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='NEW CONSTRUCTION'
        fields={
          [
            FEATURES[FEATURES_KEYS.NEW_CONSTRUCTION_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.NEW_CONSTRUCTION_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='ADULT 55+'
        fields={
          [
            FEATURES[FEATURES_KEYS.ADULT55_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.ADULT55_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='GREEN/ENERGY STAR'
        fields={
          [
            FEATURES[FEATURES_KEYS.GREEN_ENERGY_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.GREEN_ENERGY_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='HORSE PROPERTY'
        fields={
          [
            FEATURES[FEATURES_KEYS.HORSE_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.HORSE_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='GOLF COURSE'
        fields={
          [
            FEATURES[FEATURES_KEYS.GOLF_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.GOLF_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
    </Section>
  );
};
