import styled from 'styled-components';

export const Spacer = styled.div`
  width: 100%;
  height: 100vh;
`;

export const PresentationWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

export const ErrorMessage = styled('h3')({
  fontSize: 22,
  color: '#6AB4E4',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
