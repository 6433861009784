import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import FourImagesAlpha from './FourImagesAlpha';
import FourImagesAlphaForm from './FourImagesAlphaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import FourImagesLayoutAlphaPdf from './FourImagesAlphaPdf';

const template = 'fourImagesLayoutAlpha';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Two Image Layout',
  frame: true,
  data: {
    image: '',
    image2: '',
    text: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: FourImagesAlphaForm,
  web: FourImagesAlpha,
  pdf: FourImagesLayoutAlphaPdf,
};

export type TFourImageLayoutAlpha = typeof template;
export { config, mappings, thumbnail };
