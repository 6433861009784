type THiddenParameters = 'hidden' | 'msHidden' | 'webkitHidden';

class PageVisibilityImp {
  private doc = document as Document & { msHidden?: boolean; webkitHidden?: boolean };

  isVisible() {
    return !this.doc[this.getBrowserDocumentHiddenProp()];
  }

  getEvent() {
    if (typeof this.doc.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      return 'visibilitychange';
    } else if (typeof this.doc?.msHidden !== 'undefined') {
      return 'msvisibilitychange';
    } else if (typeof this.doc?.webkitHidden !== 'undefined') {
      return 'webkitvisibilitychange';
    }
  }

  private getBrowserDocumentHiddenProp(): THiddenParameters {
    if (typeof this.doc.hidden !== 'undefined') {
      return 'hidden';
    } else if (typeof this.doc.msHidden !== 'undefined') {
      return 'msHidden';
    } else if (typeof this.doc.webkitHidden !== 'undefined') {
      return 'webkitHidden';
    }

    return 'hidden';
  }
}

export const PageVisibility = new PageVisibilityImp();
