import { useUrlSearchParam } from '../useQueryParams';

const decodeColorFromSearchParam = (colorFromSearchParam: string | null): string | undefined => {
  return colorFromSearchParam && colorFromSearchParam.startsWith('%')
    ? decodeURIComponent(colorFromSearchParam)
    : colorFromSearchParam || undefined;
};

export function usePrimaryColorFromSearchParams() {
  const colorFromSearchParam = useUrlSearchParam('primaryColor');
  return decodeColorFromSearchParam(colorFromSearchParam);
}
