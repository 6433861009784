import { TProperty } from '../../types';
import { useIsDTC } from './useIsDTC';

type TDomLabel = 'DOM' | 'Days To Close';

type TDomConfig = {
  label: TDomLabel;
  value: number;
};

export function useDaysOnMarket(property: TProperty): TDomConfig {
  const isDTC = useIsDTC();

  if (isDTC && property.status === 'sold') {
    return {
      label: 'Days To Close',
      value: property.daysToClose,
    };
  }

  return {
    label: 'DOM',
    value: property.daysOnMarket,
  };
}
