import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';

export const ContentContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  padding: 40,
  background: useUiConfigColorsPrimary(),
}));

export const ContentWrapper = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 20,
  overflow: 'hidden',
});
