import styled from 'styled-components';

import { Typography } from '../../../../../components/Common';

export { Typography };

export const Container = styled('div')({
  padding: '50px 30px',
  minHeight: 'calc(100vh - 70px)',
});

export const ContentContainer = styled('section')({
  backgroundColor: '#fff',
  boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
});

export const ContentHeader = styled('div')(({ theme }) => ({
  padding: '20px 30px',
  borderBottom: `1px solid ${theme.colors.gray[200]}`,
}));

export const ContentBody = styled('div')({
  padding: 30,
  display: 'flex',
  justifyContent: 'space-between',
});

export const LeftCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '20%',
});

export const ManualListContainer = styled('div')({
  maxHeight: 530,
  overflow: 'auto',
  paddingRight: 10,
});

export const RightCol = styled('div')({
  width: '80%',
  marginLeft: 20,
});

export const AddPropertyText = styled('div')(({ theme }) => ({
  fontSize: 13,
  color: theme.colors.gray[400],
  marginTop: 22,
}));

export const AddPropertyLink = styled('span')(({ theme }) => ({
  color: theme.colors.primary.main,
  cursor: 'pointer',
}));

export const InactivePropertiesMessage = styled('div')(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.error,
  marginTop: 20,
}));
