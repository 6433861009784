import { TCompConfigItem } from '../../../../../types';
import { FeatureGroup } from '../components/FeatureGroup';
import { Section } from '../components/Section';
import { FEATURES } from '../featuresConfig';
import { FEATURES_KEYS } from '../types';

export const InteriorFeaturesOffMarket = () => {
  return (
    <Section isClosed title='INTERIOR:'>
      <FeatureGroup
        title='BATHROOMS'
        fields={
          [
            FEATURES[FEATURES_KEYS.BATHROOM_MINUS_TWO_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BATHROOM_MINUS_ONE_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BATHROOM_SAME_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BATHROOM_ONE_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BATHROOM_TWO_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BATHROOM_THREE_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='LEVELS'
        fields={
          [
            FEATURES[FEATURES_KEYS.LEVELS_ONE_OFF_MARKET],
            FEATURES[FEATURES_KEYS.LEVELS_TWO_OFF_MARKET],
            FEATURES[FEATURES_KEYS.LEVELS_THREE_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='MASTER ON MAIN'
        fields={
          [
            FEATURES[FEATURES_KEYS.MASTER_MAIN_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.MASTER_MAIN_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='AIR CONDITIONING'
        fields={
          [
            FEATURES[FEATURES_KEYS.AIR_CONDITIONING_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.AIR_CONDITIONING_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
    </Section>
  );
};
