import { getDefaultStore } from 'jotai';
import { useStore } from 'react-redux';
import { customMarkersAtom } from '../../features/report/state/customMarkersAtom';
import { highlightedPropertiesAtom } from '../../features/report/state/highlighted-properties/highlightedPropertiesAtom';
import { selectedCategoriesAtom } from '../../features/report/state/selectedCategoriesAtom';
import { customEstimatesAtom } from '../../features/report/state/customEstimatesAtom';
import { excludedPropertyIdsAtom } from '../../features/report/state/excludedPropertyIdsAtom';

export const useReportData = () => {
  const store = useStore();

  const getReportData = () => {
    const reportData = store.getState();
    const defaultStore = getDefaultStore();

    const customMarkers = defaultStore.get(customMarkersAtom);
    const highlighted = defaultStore.get(highlightedPropertiesAtom);
    const excluded = defaultStore.get(excludedPropertyIdsAtom);
    const selectedCategories = defaultStore.get(selectedCategoriesAtom);
    const customEstimates = defaultStore.get(customEstimatesAtom);

    return {
      report: (reportData as any)?.report,
      result: {
        ...(reportData as any)?.result,
        customMarkers,
        highlighted,
        excluded,
        selectedCategories,
        customEstimates,
      },
      search: (reportData as any)?.search,
    };
  };

  return getReportData;
};
