import styled from 'styled-components';
import { Typography, Select, Icon as I } from '../../../../../../../../../../components/Common';

export { Typography, Select };

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 13,
  '&:last-child': {
    marginBottom: 35,
  },
});

export const Icon = styled(I)({
  height: 19,
  width: 'auto',
  marginLeft: 12.25,
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    transform: 'cmaReport(1.05)',
  },
});
