import React from 'react';
import { useAtomValue } from 'jotai';

import { pricePerSquareFootData } from '../data';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { SlideWithGraphWrapperPdf } from '../../../../../components/common/SlideWithGraphWrapperPdf';
import { PricePerSqftChart } from '../../../../../../../../features/report/charts/price-per-sqft/PricePerSqftChart';
import { reportPropertiesAtom } from '../../../../../../../../features/report/state/reportPropertiesAtom';

const PricePerSquareFootPdf: React.FC = () => {
  const { category } = useCategory();
  const { heading, subtitle, description } = pricePerSquareFootData;

  const propertiesRaw = useAtomValue(reportPropertiesAtom);

  const properties = propertiesRaw
    .filter(prop => !prop.excluded && !!prop.property.size)
    .map(prop => prop.property);

  const calculateAvgPricePerSqft = () => {
    if (!properties.length) {
      return 'N/A';
    }

    let sum = 0;
    properties.forEach(property => {
      const pricePerSqft =
        property.status === 'sold' ? property.salePricePerSqft : property.pricePerSqft;
      sum = sum + pricePerSqft;
    });
    return Math.round(sum / properties.length);
  };
  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SlideWithGraphWrapperPdf
        heading={heading}
        title={`$${calculateAvgPricePerSqft()}`}
        subtitle={subtitle}
        description={description}
        graphPadding='2%'
      >
        <PricePerSqftChart style={{ height: 350 }} />
      </SlideWithGraphWrapperPdf>
    </PdfFrame>
  );
};

export default PricePerSquareFootPdf;
