import { PRESENTATION_TYPES } from '../constants/PresentationTypes';
import { TPresentationTypeApi } from '../pages/Home/components/PastPresentation/types';

const typesMap = {
  seller: PRESENTATION_TYPES.WIN_THE_LISTING,
  buyer: PRESENTATION_TYPES.WIN_THE_OFFER,
  info: PRESENTATION_TYPES.PRESENT_INFO,
  [PRESENTATION_TYPES.BUYER_TOUR]: PRESENTATION_TYPES.BUYER_TOUR,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: PRESENTATION_TYPES.WIN_THE_REPRESENTATION,
};

export function getPresentationTypeFromApi(type: TPresentationTypeApi) {
  return typesMap[type] ?? type;
}
