import { PROPERTY_STATUS, PropertyGroups, ReportProperty, TProperty } from '../../../types';

export const extractPropertyGroups = (reportProperties: ReportProperty[]): PropertyGroups => {
  const properties = reportProperties?.map(p => p.property);

  const activeProperties = properties?.filter(p => p.status === PROPERTY_STATUS.ACTIVE);
  const pendingProperties = properties?.filter(p => p.status === PROPERTY_STATUS.PENDING);
  const soldProperties = properties?.filter(p => p.status === PROPERTY_STATUS.SOLD);
  const comingSoonProperties = properties?.filter(
    p => p.status === PROPERTY_STATUS.COMING_SOON || p.status === 'coming_soon',
  );

  return {
    [PROPERTY_STATUS.ACTIVE]: activeProperties,
    [PROPERTY_STATUS.PENDING]: pendingProperties,
    [PROPERTY_STATUS.SOLD]: soldProperties,
    [PROPERTY_STATUS.COMING_SOON]: comingSoonProperties,
  };
};
