import { TCompConfigItem } from '../../../../types';
import { FEATURES_KEYS } from './types';
import featuresActiveIcon from './assets/features-of-active-comps.png';

export const FEATURES: Record<FEATURES_KEYS, TCompConfigItem> = Object.freeze({
  [FEATURES_KEYS.POOL_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> comps <span>WITH POOL</span>',
    id: 'average_with_pool',
    idPerSqft: 'average_with_pool_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.pool),
        pending: properties?.pending?.filter(p => !p.excluded && p.pool),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.pool).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.POOL_NO]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> comps <span>WITHOUT POOL</span>',
    id: 'average_without_pool',
    idPerSqft: 'average_without_pool_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.pool),
        pending: properties?.pending?.filter(p => !p.excluded && !p.pool),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.pool).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.VIEW_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> comps <span>WITH VIEW</span>',
    id: 'average_with_view',
    idPerSqft: 'average_with_view_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.view),
        pending: properties?.pending?.filter(p => !p.excluded && p.view),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.view).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.VIEW_NO]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> comps <span>WITHOUT VIEW</span>',
    id: 'average_without_view',
    idPerSqft: 'average_without_view_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.view),
        pending: properties?.pending?.filter(p => !p.excluded && !p.view),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.view).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_MINUS_TWO]: {
    label: '-2',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH -2 BATHROOMS</span>',
    id: 'bath_minus_2',
    idPerSqft: 'bath_minus_2_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: properties?.active?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -2,
        ),
        pending: properties?.pending?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -2,
        ),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }, subject) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -2,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_MINUS_ONE]: {
    label: '-1',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH -1 BATHROOMS</span>',
    id: 'bath_minus_1',
    idPerSqft: 'bath_minus_1_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: properties?.active?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -1,
        ),
        pending: properties?.pending?.filter(p => !p.excluded && p.baths - p.totalBaths === -1),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }, subject) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -1,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_SAME]: {
    label: 'Same',
    labelHtml:
      '<span style="color: #7EBD39">On Market</span> Comps <span>WITH SAME BATHROOMS</span>',
    id: 'bath_same',
    idPerSqft: 'bath_same_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: properties?.active?.filter(
          p => !p.excluded && subject?.totalBaths === p.totalBaths,
        ),
        pending: properties?.pending?.filter(
          p => !p.excluded && subject?.totalBaths === p.totalBaths,
        ),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }, subject) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && subject?.totalBaths === p.totalBaths,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_ONE]: {
    label: '+1',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH +1 BATHROOMS</span>',
    id: 'bath_1',
    idPerSqft: 'bath_1_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: properties?.active?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 1,
        ),
        pending: properties?.pending?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 1,
        ),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }, subject) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 1,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_TWO]: {
    label: '+2',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH +2 BATHROOMS</span>',
    id: 'bath_2',
    idPerSqft: 'bath_2_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: properties?.active?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 2,
        ),
        pending: properties?.pending?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 2,
        ),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }, subject) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 2,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_THREE]: {
    label: '3+',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH 3+ BATHROOMS</span>',
    id: 'bath_3',
    idPerSqft: 'bath_3_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: properties?.active?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) >= 3,
        ),
        pending: properties?.pending?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) >= 3,
        ),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }, subject) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) >= 3,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.LEVELS_ONE]: {
    label: '1',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH 1 LEVEL</span>',
    id: 'level_1',
    idPerSqft: 'level_1_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.levels === 1),
        pending: properties?.pending?.filter(p => !p.excluded && p.levels === 1),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.levels === 1)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.LEVELS_TWO]: {
    label: '2',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH 2 LEVELS</span>',
    id: 'level_2',
    idPerSqft: 'level_2_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.levels === 2),
        pending: properties?.pending?.filter(p => !p.excluded && p.levels === 2),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.levels === 2)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.LEVELS_THREE]: {
    label: '3+',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH 3+ LEVELS</span>',
    id: 'level_3',
    idPerSqft: 'level_3_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.levels >= 3),
        pending: properties?.pending?.filter(p => !p.excluded && p.levels >= 3),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.levels >= 3)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.HOA_0]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NO ASSOCIATION FEE</span>',
    id: 'hoa_0',
    idPerSqft: 'hoa_0_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.hoaFee),
        pending: properties?.pending?.filter(p => !p.excluded && !p.hoaFee),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.hoaFee).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.HOA_1]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>HAS ASSOCIATION FEE</span>',
    id: 'hoa_1',
    idPerSqft: 'hoa_1_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.hoaFee),
        pending: properties?.pending?.filter(p => !p.excluded && p.hoaFee),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.hoaFee).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.FIXER_UPPER_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>FIXER UPPER</span> comps',
    id: 'average_fixer_upper',
    idPerSqft: 'average_fixer_upper_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isFixerUpper),
        pending: properties?.pending?.filter(p => !p.excluded && p.isFixerUpper),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.isFixerUpper)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.FIXER_UPPER_NO]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NOT FIXER UPPER</span> comps',
    id: 'average_not_fixer_upper',
    idPerSqft: 'average_not_fixer_upper_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isFixerUpper),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isFixerUpper),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.isFixerUpper)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.NEW_CONSTRUCTION_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NEW CONSTRUCTION</span> comps',
    id: 'average_new_construction',
    idPerSqft: 'average_new_construction_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isNewConstruction),
        pending: properties?.pending?.filter(p => !p.excluded && p.isNewConstruction),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.isNewConstruction,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.NEW_CONSTRUCTION_NO]: {
    label: 'No',
    labelHtml:
      '<span style="color: #7EBD39">On Market</span> <span>NOT NEW CONSTRUCTION</span> comps',
    id: 'average_not_new_construction',
    idPerSqft: 'average_not_new_construction_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isNewConstruction),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isNewConstruction),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && !p.isNewConstruction,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.ADULT55_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>ADULT 55+</span> comps',
    id: 'average_adult',
    idPerSqft: 'average_adult_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isAdult55),
        pending: properties?.pending?.filter(p => !p.excluded && p.isAdult55),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.isAdult55)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.ADULT55_NO]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NOT ADULT 55+</span> comps',
    id: 'average_not_adult',
    idPerSqft: 'average_not_adult_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isAdult55),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isAdult55),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.isAdult55)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GREEN_ENERGY_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>GREEN/ENERGY STAR</span> comps',
    id: 'average_green',
    idPerSqft: 'average_green_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isGreenEnergyStar),
        pending: properties?.pending?.filter(p => !p.excluded && p.isGreenEnergyStar),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.isGreenEnergyStar,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GREEN_ENERGY_NO]: {
    label: 'No',
    labelHtml:
      '<span style="color: #7EBD39">On Market</span> <span>NOT GREEN/ENERGY STAR</span> comps',
    id: 'average_not_green',
    idPerSqft: 'average_not_green_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isGreenEnergyStar),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isGreenEnergyStar),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && !p.isGreenEnergyStar,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.HORSE_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>HORSE PROPERTY</span> comps',
    id: 'average_horse',
    idPerSqft: 'average_horse_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isHorseProperty),
        pending: properties?.pending?.filter(p => !p.excluded && p.isHorseProperty),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.isHorseProperty)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.HORSE_NO]: {
    label: 'No',
    labelHtml:
      '<span style="color: #7EBD39">On Market</span> <span>NOT HORSE PROPERTY</span> comps',
    id: 'average_not_horse',
    idPerSqft: 'average_not_horse_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isHorseProperty),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isHorseProperty),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && !p.isHorseProperty,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GOLF_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>GOLF COURSE</span> comps',
    id: 'average_golf',
    idPerSqft: 'average_golf_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isGolfCourse),
        pending: properties?.pending?.filter(p => !p.excluded && p.isGolfCourse),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.isGolfCourse)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GOLF_NO]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NOT GOLF COURSE</span> comps',
    id: 'average_not_golf',
    idPerSqft: 'average_not_golf_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isGolfCourse),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isGolfCourse),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.isGolfCourse)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.MASTER_MAIN_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>MASTER ON MAIN</span> comps',
    id: 'average_master_on_main',
    idPerSqft: 'average_master_on_main_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isMasterOnMain),
        pending: properties?.pending?.filter(p => !p.excluded && p.isMasterOnMain),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.isMasterOnMain)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.MASTER_MAIN_NO]: {
    label: 'No',
    labelHtml:
      '<span style="color: #7EBD39">On Market</span> <span>NOT MASTER ON MAIN</span> comps',
    id: 'average_not_master_on_main',
    idPerSqft: 'average_not_master_on_main_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isMasterOnMain),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isMasterOnMain),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.isGolfCourse)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.AIR_CONDITIONING_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>AIR CONDITIONING</span> comps',
    id: 'average_air_conditioning',
    idPerSqft: 'average_air_conditioning_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isAirConditioning),
        pending: properties?.pending?.filter(p => !p.excluded && p.isAirConditioning),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.isAirConditioning,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.AIR_CONDITIONING_NO]: {
    label: 'No',
    labelHtml:
      '<span style="color: #7EBD39">On Market</span> <span>NOT AIR CONDITIONING</span> comps',
    id: 'average_not_air_conditioning',
    idPerSqft: 'average_not_air_conditioning_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isAirConditioning),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isAirConditioning),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && !p.isAirConditioning,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.WATER_FRONT_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>WATER FRONT</span> comps',
    id: 'average_water_front',
    idPerSqft: 'average_water_front_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isWaterFront),
        pending: properties?.pending?.filter(p => !p.excluded && p.isWaterFront),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.isWaterFront)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.WATER_FRONT_NO]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NOT WATER FRONT</span> comps',
    id: 'average_not_water_front',
    idPerSqft: 'average_not_water_front_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isWaterFront),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isWaterFront),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.isWaterFront)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.WATER_VIEW_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>WATER VIEW</span> comps',
    id: 'average_water_view',
    idPerSqft: 'average_water_view_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isWaterView),
        pending: properties?.pending?.filter(p => !p.excluded && p.isWaterView),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.isWaterView)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.WATER_VIEW_NO]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NOT WATER VIEW</span> comps',
    id: 'average_not_water_view',
    idPerSqft: 'average_not_water_view_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isWaterView),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isWaterView),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.isWaterView)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.DECK_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>DECK</span> comps',
    id: 'average_deck',
    idPerSqft: 'average_deck_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.isDeck),
        pending: properties?.pending?.filter(p => !p.excluded && p.isDeck),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.isDeck).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.DECK_NO]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NO DECK</span> comps',
    id: 'average_not_deck',
    idPerSqft: 'average_not_deck_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && !p.isDeck),
        pending: properties?.pending?.filter(p => !p.excluded && !p.isDeck),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && !p.isDeck).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_NONE]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>NO BASEMENT</span> comps',
    id: 'average_basement_none',
    idPerSqft: 'average_basement_none_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.basement === 'none'),
        pending: properties?.pending?.filter(p => !p.excluded && p.basement === 'none'),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.basement === 'none',
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_YES]: {
    label: 'Yes',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>WITH BASEMENT</span> comps',
    id: 'average_basement_yes',
    idPerSqft: 'average_basement_yes_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.basement === 'yes'),
        pending: properties?.pending?.filter(p => !p.excluded && p.basement === 'yes'),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.basement === 'yes',
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_FINISHED]: {
    label: 'Finished',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>FINISHED BASEMENT</span> comps',
    id: 'average_basement_finished',
    idPerSqft: 'average_basement_finished_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.basement === 'finished'),
        pending: properties?.pending?.filter(p => !p.excluded && p.basement === 'finished'),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.basement === 'finished',
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_PARTIAL]: {
    label: 'Partial',
    labelHtml: '<span style="color: #7EBD39">On Market</span> <span>PARTIAL BASEMENT</span> comps',
    id: 'average_basement_partial',
    idPerSqft: 'average_basement_partial_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.basement === 'partial'),
        pending: properties?.pending?.filter(p => !p.excluded && p.basement === 'partial'),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.basement === 'partial',
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_UNFINISHED]: {
    label: 'Unfinished',
    labelHtml:
      '<span style="color: #7EBD39">On Market</span> <span>UNFINISHED BASEMENT</span> comps',
    id: 'average_basement_unfinished',
    idPerSqft: 'average_basement_unfinished_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.basement === 'unfinished'),
        pending: properties?.pending?.filter(p => !p.excluded && p.basement === 'unfinished'),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(
          p => !p.excluded && p.basement === 'unfinished',
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GARAGE_0]: {
    label: 'No',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH NO GARAGE</span>',
    id: 'average_garage_0',
    idPerSqft: 'average_garage_0_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.garage === 0),
        pending: properties?.pending?.filter(p => !p.excluded && p.garage === 0),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.garage === 0)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GARAGE_1]: {
    label: '1',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH 1 GARAGE</span>',
    id: 'average_garage_1',
    idPerSqft: 'average_garage_1_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.garage === 1),
        pending: properties?.pending?.filter(p => !p.excluded && p.garage === 1),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.garage === 1)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GARAGE_2]: {
    label: '2',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH 2 GARAGES</span>',
    id: 'average_garage_2',
    idPerSqft: 'average_garage_2_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.garage === 2),
        pending: properties?.pending?.filter(p => !p.excluded && p.garage === 2),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.garage === 2)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GARAGE_3]: {
    label: '3+',
    labelHtml: '<span style="color: #7EBD39">On Market</span> Comps <span>WITH 3+ GARAGES</span>',
    id: 'average_garage_3',
    idPerSqft: 'average_garage_3_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: properties?.active?.filter(p => !p.excluded && p.garage >= 3),
        pending: properties?.pending?.filter(p => !p.excluded && p.garage >= 3),
        sold: null,
        comingSoon: null,
      }),
      getCount: ({ active, pending }) => {
        return [...(active || []), ...(pending || [])].filter(p => !p.excluded && p.garage >= 3)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.POOL_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> comps <span>WITH POOL</span>',
    id: 'average_with_pool_off_market',
    idPerSqft: 'average_with_pool_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.pool),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.pool).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.POOL_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> comps <span>WITHOUT POOL</span>',
    id: 'average_without_pool_off_market',
    idPerSqft: 'average_without_pool_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.pool),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.pool).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.VIEW_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> comps <span>WITH VIEW</span>',
    id: 'average_with_view_off_market',
    idPerSqft: 'average_with_view_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.view),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.view).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.VIEW_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> comps <span>WITHOUT VIEW</span>',
    id: 'average_without_view_off_market',
    idPerSqft: 'average_without_view_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.view),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.view).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_MINUS_TWO_OFF_MARKET]: {
    label: '-2',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> Comps <span>WITH -2 BATHROOMS</span>',
    id: 'bath_minus_2_off_market',
    idPerSqft: 'bath_minus_2_off_market_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -2,
        ),
        comingSoon: null,
      }),
      getCount: ({ sold }, subject) => {
        return [...(sold || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -2,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_MINUS_ONE_OFF_MARKET]: {
    label: '-1',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> Comps <span>WITH -1 BATHROOMS</span>',
    id: 'bath_minus_1_off_market',
    idPerSqft: 'bath_minus_1_off_market_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -1,
        ),
        comingSoon: null,
      }),
      getCount: ({ sold }, subject) => {
        return [...(sold || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === -1,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_SAME_OFF_MARKET]: {
    label: 'Same',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> Comps <span>WITH SAME BATHROOMS</span>',
    id: 'bath_same_off_market',
    idPerSqft: 'bath_same_off_market_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.totalBaths === subject?.totalBaths),
        comingSoon: null,
      }),
      getCount: ({ sold }, subject) => {
        return [...(sold || [])].filter(p => !p.excluded && p.totalBaths === subject?.totalBaths)
          .length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_ONE_OFF_MARKET]: {
    label: '+1',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> comps <span>WITH +1 BATHROOMS</span>',
    id: 'bath_1_off_market',
    idPerSqft: 'bath_1_off_market_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 1,
        ),
        comingSoon: null,
      }),
      getCount: ({ sold }, subject) => {
        return (
          sold?.filter(p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 1)
            ?.length ?? 0
        );
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_TWO_OFF_MARKET]: {
    label: '+2',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> comps <span>WITH +2 BATHROOMS</span>',
    id: 'bath_2_off_market',
    idPerSqft: 'bath_2_off_market_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 2,
        ),
        comingSoon: null,
      }),
      getCount: ({ sold }, subject) => {
        return [...(sold || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) === 2,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BATHROOM_THREE_OFF_MARKET]: {
    label: '3+',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> comps <span>WITH 3+ BATHROOMS</span>',
    id: 'bath_3_off_market',
    idPerSqft: 'bath_3_off_market_per_sqft',
    customizedData: {
      getProperties: (properties, sqftModeOn = false, subject) => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) >= 3,
        ),
        comingSoon: null,
      }),
      getCount: ({ sold }, subject) => {
        return [...(sold || [])].filter(
          p => !p.excluded && p.totalBaths - (subject?.totalBaths || 0) >= 3,
        ).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.LEVELS_ONE_OFF_MARKET]: {
    label: '1',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> comps <span>WITH 1 LEVEL</span>',
    id: 'level_1_off_market',
    idPerSqft: 'level_1_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.levels === 1),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.levels === 1).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.LEVELS_TWO_OFF_MARKET]: {
    label: '2',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> comps <span>WITH 2 LEVELS</span>',
    id: 'level_2_off_market',
    idPerSqft: 'level_2_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.levels === 2),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.levels === 2).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.LEVELS_THREE_OFF_MARKET]: {
    label: '3+',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> Comps <span>WITH 3+ LEVELS</span>',
    id: 'level_3_off_market',
    idPerSqft: 'level_3_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.levels >= 3),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.levels >= 3).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.HOA_0_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NO ASSOCIATION FEE</span>',
    id: 'hoa_0_off_market',
    idPerSqft: 'hoa_0_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.hoaFee),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.hoaFee).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.HOA_1_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>HAS ASSOCIATION FEE</span>',
    id: 'hoa_1_off_market',
    idPerSqft: 'hoa_1_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.hoaFee),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.hoaFee).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.FIXER_UPPER_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>FIXER UPPER</span> comps',
    id: 'average_fixer_upper_off_market',
    idPerSqft: 'average_fixer_upper_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isFixerUpper),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isFixerUpper).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.FIXER_UPPER_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NOT FIXER UPPER</span> comps',
    id: 'average_not_fixer_upper_off_market',
    idPerSqft: 'average_not_fixer_upper_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isFixerUpper),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isFixerUpper).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.NEW_CONSTRUCTION_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NEW CONSTRUCTION</span> comps',
    id: 'average_new_construction_off_market',
    idPerSqft: 'average_new_construction_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isNewConstruction),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isNewConstruction).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.NEW_CONSTRUCTION_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> <span>NOT NEW CONSTRUCTION</span> comps',
    id: 'average_not_new_construction_off_market',
    idPerSqft: 'average_not_new_construction_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isNewConstruction),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isNewConstruction).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.ADULT55_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>ADULT 55+</span> comps',
    id: 'average_adult_off_market',
    idPerSqft: 'average_adult_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isAdult55),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isAdult55).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.ADULT55_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NOT ADULT 55+</span> comps',
    id: 'average_not_adult_off_market',
    idPerSqft: 'average_not_adult_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isAdult55),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isAdult55).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GREEN_ENERGY_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> <span>GREEN/ENERGY STAR</span> comps',
    id: 'average_green_off_market',
    idPerSqft: 'average_green_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isGreenEnergyStar),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isGreenEnergyStar).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GREEN_ENERGY_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> <span>NOT GREEN/ENERGY STAR</span> comps',
    id: 'average_not_green_off_market',
    idPerSqft: 'average_not_green_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isGreenEnergyStar),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isGreenEnergyStar).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.HORSE_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>HORSE PROPERTY</span> comps',
    id: 'average_horse_off_market',
    idPerSqft: 'average_horse_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isHorseProperty),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isHorseProperty).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.HORSE_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> <span>NOT HORSE PROPERTY</span> comps',
    id: 'average_not_horse_off_market',
    idPerSqft: 'average_not_horse_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isHorseProperty),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isHorseProperty).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GOLF_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>GOLF COURSE</span> comps',
    id: 'average_golf_off_market',
    idPerSqft: 'average_golf_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isGolfCourse),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isGolfCourse).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GOLF_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NOT GOLF COURSE</span> comps',
    id: 'average_not_golf_off_market',
    idPerSqft: 'average_not_golf_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isGolfCourse),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isGolfCourse).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.MASTER_MAIN_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>MASTER ON MAIN</span> comps',
    id: 'average_master_on_main_off_market',
    idPerSqft: 'average_master_on_main_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isMasterOnMain),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isMasterOnMain).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.MASTER_MAIN_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> <span>NOT MASTER ON MAIN</span> comps',
    id: 'average_not_master_on_main_off_market',
    idPerSqft: 'average_not_master_on_main_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isMasterOnMain),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isMasterOnMain).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.AIR_CONDITIONING_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>AIR CONDITIONING</span> comps',
    id: 'average_air_conditioning_off_market',
    idPerSqft: 'average_air_conditioning_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isAirConditioning),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isAirConditioning).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.AIR_CONDITIONING_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> <span>NOT AIR CONDITIONING</span> comps',
    id: 'average_not_air_conditioning_off_market',
    idPerSqft: 'average_not_air_conditioning_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isAirConditioning),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isAirConditioning).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.WATER_FRONT_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>WATER FRONT</span> comps',
    id: 'average_water_front_off_market',
    idPerSqft: 'average_water_front_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isWaterFront),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isWaterFront).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.WATER_FRONT_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NOT WATER FRONT</span> comps',
    id: 'average_not_water_front_off_market',
    idPerSqft: 'average_not_water_front_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isWaterFront),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isWaterFront).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.WATER_VIEW_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>WATER VIEW</span> comps',
    id: 'average_water_view_off_market',
    idPerSqft: 'average_water_view_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isWaterView),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isWaterView).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.WATER_VIEW_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NOT WATER VIEW</span> comps',
    id: 'average_not_water_view_off_market',
    idPerSqft: 'average_not_water_view_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isWaterView),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isWaterView).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.DECK_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>DECK</span> comps',
    id: 'average_deck_off_market',
    idPerSqft: 'average_deck_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.isDeck),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.isDeck).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.DECK_NO_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NO DECK</span> comps',
    id: 'average_not_deck_off_market',
    idPerSqft: 'average_not_deck_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && !p.isDeck),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && !p.isDeck).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_NONE_OFF_MARKET]: {
    label: 'No',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>NO BASEMENT</span> comps',
    id: 'average_basement_none_off_market',
    idPerSqft: 'average_basement_none_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.basement === 'none'),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.basement === 'none').length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_YES_OFF_MARKET]: {
    label: 'Yes',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>WITH BASEMENT</span> comps',
    id: 'average_basement_yes_off_market',
    idPerSqft: 'average_basement_yes_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.basement === 'yes'),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.basement === 'yes').length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_FINISHED_OFF_MARKET]: {
    label: 'Finished',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> <span>FINISHED BASEMENT</span> comps',
    id: 'average_basement_finished_off_market',
    idPerSqft: 'average_basement_finished_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.basement === 'finished'),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.basement === 'finished').length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_PARTIAL_OFF_MARKET]: {
    label: 'Partial',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> <span>PARTIAL BASEMENT</span> comps',
    id: 'average_basement_partial_off_market',
    idPerSqft: 'average_basement_partial_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.basement === 'partial'),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.basement === 'partial').length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.BASEMENT_UNFINISHED_OFF_MARKET]: {
    label: 'Unfinished',
    labelHtml:
      '<span style="color: #F13B3B">Off Market</span> <span>UNFINISHED BASEMENT</span> comps',
    id: 'average_basement_unfinished_off_market',
    idPerSqft: 'average_basement_unfinished_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.basement === 'unfinished'),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.basement === 'unfinished').length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GARAGE_0_OFF_MARKET]: {
    label: '0',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> Comps <span>WITH NO GARAGE</span>',
    id: 'average_garage_0_off_market',
    idPerSqft: 'average_garage_0_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.garage === 0),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.garage === 0).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GARAGE_1_OFF_MARKET]: {
    label: '1',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> Comps <span>WITH 1 GARAGE</span>',
    id: 'average_garage_1_off_market',
    idPerSqft: 'average_garage_1_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.garage === 1),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.garage === 1).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GARAGE_2_OFF_MARKET]: {
    label: '2',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> Comps <span>WITH 2 GARAGES</span>',
    id: 'average_garage_2_off_market',
    idPerSqft: 'average_garage_2_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.garage === 2),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.garage === 2).length;
      },
    },
    icon: featuresActiveIcon,
  },
  [FEATURES_KEYS.GARAGE_3_OFF_MARKET]: {
    label: '3+',
    labelHtml: '<span style="color: #F13B3B">Off Market</span> Comps <span>WITH 3+ GARAGES</span>',
    id: 'average_garage_3_off_market',
    idPerSqft: 'average_garage_3_off_market_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties?.sold?.filter(p => !p.excluded && p.garage >= 3),
        comingSoon: null,
      }),
      getCount: ({ sold }) => {
        return [...(sold || [])].filter(p => !p.excluded && p.garage >= 3).length;
      },
    },
    icon: featuresActiveIcon,
  },
});
