import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from './Routes';
import { LoginPage } from '../Sso/Login.page';
import { CustomUserPaths, CustomUserRouter } from './CustomUserRouter';

export const UnauthenticatedRouter: React.FC = function UnauthenticatedRouter() {
  return (
    <>
      <Switch>
        <Route path={CustomUserPaths} component={CustomUserRouter} />
        <Route path={Routes.root} component={LoginPage} />
      </Switch>
    </>
  );
};
