import React from 'react';

export const Error = ({ message = '' }) => {
  if (!message) {
    return null;
  }

  return (
    <div
      style={{
        padding: '10px',
        marginTop: '10px',
        background: 'rgba(255,0,0, 0.4)',
        border: '1px solid red',
      }}
    >
      {message}
    </div>
  );
};
