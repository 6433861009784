import { TPresentationType } from '../types';
import { PRESENTATION_TYPES } from '../constants/PresentationTypes';

const typesMap = {
  [PRESENTATION_TYPES.WIN_THE_LISTING]: 'seller',
  [PRESENTATION_TYPES.WIN_THE_OFFER]: 'buyer',
  [PRESENTATION_TYPES.PRESENT_INFO]: 'info',
  [PRESENTATION_TYPES.BUYER_TOUR]: PRESENTATION_TYPES.BUYER_TOUR,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: PRESENTATION_TYPES.WIN_THE_REPRESENTATION,
};

export function getPresentationType(type: TPresentationType) {
  return typesMap[type] ?? type;
}
