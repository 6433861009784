import styled from 'styled-components';

interface Props {
  title: string;
}

export const SectionTitle = ({ title }: Props) => {
  return <Title>{title}</Title>;
};

const Title = styled.h3`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
  margin-bottom: 15px;
`;
