import React from 'react';
import {
  CardContainer,
  CardInfoContainer,
  CardInfoName,
  CardInfoValue,
  CardInfoWrapper,
  EmailPixelatedImage,
  PixelatedContainer,
} from './PotentialBuyerCard.styles';

export type TBuyerCard = {
  id: string;
  contact_mask: string;
  interest_description: string;
};

interface PotentialBuyerCardProps {
  buyerCardInfo: TBuyerCard;
}

export const PotentialBuyerCard: React.FC<PotentialBuyerCardProps> = ({ buyerCardInfo }) => {
  const { id, contact_mask, interest_description } = buyerCardInfo;

  return (
    <CardContainer>
      <CardInfoContainer>
        <CardInfoWrapper>
          <CardInfoName>Buyer ID:</CardInfoName>
          <CardInfoValue>{id}</CardInfoValue>
        </CardInfoWrapper>
        <CardInfoWrapper>
          <CardInfoName>Email:</CardInfoName>
          <PixelatedContainer>
            <EmailPixelatedImage />
            <CardInfoValue>{`@${contact_mask.split('@')[1]}`}</CardInfoValue>
          </PixelatedContainer>
        </CardInfoWrapper>
        <CardInfoWrapper>
          <CardInfoName>Interests:</CardInfoName>
          <CardInfoValue>{interest_description}</CardInfoValue>
        </CardInfoWrapper>
      </CardInfoContainer>
    </CardContainer>
  );
};
