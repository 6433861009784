import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { Typography } from '../../../realtorVSIBuyer/web/ModernRealtorVSIBuyer/ModernRealtorVSIBuyer.styles';
import { alternativeSlideContent } from '../../data';
import slideGraphics from './slideGraphics';

type WithPdf = {
  isPdf?: boolean;
};

const Container = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  padding: isPdf ? 0 : '4%',
  justifyContent: 'space-between',
  flexDirection: isPdf ? 'column' : 'row',
}));

const CardContainer = styled('div')<WithPdf>(({ isPdf }) => ({
  borderRadius: 5,
  padding: '35px 60px',
  border: '1.5px solid #F2F8F8',
  backgroundColor: '#fff',
  borderColor: useUiConfigColorsPrimary(),
  width: isPdf ? '100%' : '49%',
}));

const Image = styled('img')<WithPdf>(({ isPdf }) => ({
  width: isPdf ? '72%' : '100%',
  display: 'block',
  margin: '0 auto',
}));

interface Props {
  isPdf?: boolean;
}

export const ValueOfAnAgentModernCanada: React.FC<Props> = ({ isPdf }) => {
  const primary = useUiConfigColorsPrimary();

  const img = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);
  const letterSpacing = useUiConfigBodyLetterSpacing('0.35');

  return (
    <Container isPdf={isPdf}>
      <CardContainer isPdf={isPdf} style={{ marginBottom: isPdf ? 32 : 0 }}>
        <Typography
          variant='h1'
          style={{
            letterSpacing: 3,
            wordSpacing: 2,
            fontFamily: useUiConfigFontsHeader(),
            color: useUiConfigColorsPrimary(),
            textTransform: 'uppercase',
            fontSize: isPdf ? 28 : 32,
            fontWeight: 800,
            margin: 0,
            marginBottom: 30,
          }}
        >
          {alternativeSlideContent.title}
        </Typography>
        {alternativeSlideContent.paragraphs.map((paragraph, i) => (
          <Typography
            key={i}
            variant='body1'
            color='400'
            style={{
              lineHeight: 1.4,
              fontWeight: 500,
              fontSize: isPdf ? 16 : 18,
              letterSpacing,
              marginBottom: 30,
            }}
          >
            {paragraph}
          </Typography>
        ))}
      </CardContainer>
      <CardContainer isPdf={isPdf}>
        <Image src={img} isPdf={isPdf} />
      </CardContainer>
    </Container>
  );
};
