import styled from 'styled-components';

import { Typography } from '../../../../components/Common';
export { Typography };

export const ModalContent = styled.div`
  padding: 20px;
  max-height: calc(100vh - 218px);
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
  border-top: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
  gap: 30px;
`;

export const ExpandibleContainer = styled('div')(({ theme }) => ({
  minWidth: 268,
  maxWidth: 268,
  maxHeight: 'calc(100vh - 246px)',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    display: 'flex',
    background: theme.colors.gray[200],
    width: 6,
    paddingRight: 2,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.colors.gray[400],
    borderRadius: 100,
    width: 4,
  },
  '@-moz-document url-prefix()': {
    paddingRight: 20,
  },
}));

export const SwitchButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: 20,
});
