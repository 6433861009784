import React from 'react';
import {
  PresentationTypeProvider,
  usePresentationType,
} from '../../../../providers/providers/PresentationTypeProvider';
import { withProviders } from '../../../../hoc/withProviders';
import { withLoadedUser } from '../../../../hoc/withLoadedUser';
import type { TBuyerTourPresentation } from '../../../../types';
import { PRESENTATION_TYPES } from '../../../../constants/PresentationTypes';
import { OrderProvider } from '../../../../providers/providers/OrderProvider';
import { SlidesProvider } from '../../../../providers/providers/SlidesProvider';
import { ClientProvider } from '../../../../providers/providers/ClientProvider';
import { BTDashboardItems } from './BTDashboardItems/BTDashboardItems.component';
import { SectionsProvider } from '../../../../providers/providers/SectionsProvider';
import { UiConfigProvider } from '../../../../providers/providers/UiConfigProvider';
import { CategoryProvider } from '../../../../providers/providers/CategoryProvider';
import { CreatedAtProvider } from '../../../../providers/providers/CreatedAtProvider';
import { PropertiesProvider } from '../../../../providers/providers/PropertiesProvider';
import { ActiveSectionProvider } from '../../../../components/Slide/providers/ActiveSectionProvider';
import { useSetBuyerTourPresentationToProviders } from '../../hooks/useSetBuyerTourPresentationToProviders';
import { config as cma } from '../../../../components/Slide/slides/whatsMyHomeWorth/comparativeMarketAnalysis';
import { DashboardContent } from '../../../../layouts/Dashboard/DashboardContent';
interface Props {
  presentation: TBuyerTourPresentation;
}

export const BuyerTourDashboard: React.FC<Props> = withLoadedUser(
  withProviders<Props>(
    UiConfigProvider,
    PresentationTypeProvider,
    CategoryProvider,
    PropertiesProvider,
    ClientProvider,
    SlidesProvider,
    SectionsProvider,
    OrderProvider,
    ActiveSectionProvider,
    CreatedAtProvider,
  )(function BuyerTourDashboard({ presentation }) {
    useSetBuyerTourPresentationToProviders(presentation);
    const { presentationType } = usePresentationType();

    if (PRESENTATION_TYPES.BUYER_TOUR !== presentationType || !presentation?.hash) return null;

    return (
      <DashboardContent presentationHash={presentation.hash} previewSlide={cma}>
        <BTDashboardItems presentationData={presentation} />
      </DashboardContent>
    );
  }),
);
