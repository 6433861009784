import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigFontsHeader } from '../../../../../../providers/providers/UiConfigFontProvider';
import { useScaleFactor } from '../../../../../PreviewSlide/ScaleFactorProvider';
import { CustomDrawingManagerControl } from './Control';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '5px 3.42585px 3.42585px 3.42585px',
  marginBottom: '24px',
  padding: '10.2px 16px 20px 16px',
});

const Title = styled('p')(({ theme }) => ({
  textAlign: 'center',
  fontSize: 14,
  fontFamily: useUiConfigFontsHeader(),
  fontWeight: 700,
}));

const BGItemsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 18,
  width: 420,
  padding: '0 12px',
}));

const BgItem = styled('div')(() => ({
  flex: 1,
  height: '100%',
  backgroundColor: useUiConfigColorsPrimary(),
  content: '" "',
}));

const BottomRowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  marginTop: 8,
  fontFamily: useUiConfigFontsHeader(),
}));

const BottomControls: React.FC = (props, context) => {
  const position = (window as any).google.maps.ControlPosition.BOTTOM_CENTER;
  const scaleFactor = useScaleFactor();

  const style: React.CSSProperties = {};

  if (scaleFactor) {
    style.transform = `scale(${scaleFactor})`;
    style.transformOrigin = 'auto auto';
    if (scaleFactor < 0.5) {
      style.marginBottom = `-${24 * scaleFactor}px`;
    }
    if (scaleFactor < 0.16) {
      style.marginBottom = `-${180 * scaleFactor}px`;
    }
  }

  return (
    <CustomDrawingManagerControl position={position}>
      <Container style={style}>
        <Title>Number of Buyers Looking for Homes</Title>
        <BottomRowWrapper>
          Low
          <BGItemsWrapper>
            <BgItem style={{ opacity: 0.1 }} />
            <BgItem style={{ opacity: 0.25 }} />
            <BgItem style={{ opacity: 0.5 }} />
            <BgItem style={{ opacity: 0.75 }} />
            <BgItem style={{ opacity: 1 }} />
          </BGItemsWrapper>
          High
        </BottomRowWrapper>
      </Container>
    </CustomDrawingManagerControl>
  );
};

export default BottomControls;
