import { RawReport } from '../../../types';
import { getPropertyId } from './getPropertyId';

export const getHighlightsByIds = (highlights: string[], reportDetailed: RawReport) => {
  const properties = [
    ...(reportDetailed.properties?.active || []),
    ...(reportDetailed.properties?.pending || []),
    ...(reportDetailed.properties?.comingSoon || []),
    ...(reportDetailed.properties?.sold || []),
  ];

  if (properties.length === 0) {
    return;
  }

  const validHighlights = highlights.filter(
    hId => !!properties.find(p => p.id === hId && !p.excluded),
  );

  const highlighted = validHighlights.map(propertyId => {
    const getStatusIndicator = (status: string) => (status === 'sold' ? 'C' : status.charAt(0));

    const property = properties.find(property => getPropertyId(property) === propertyId);

    if (!property) {
      return;
    }

    const {
      id,
      address,
      price,
      pricePerSqft,
      salePrice,
      salePricePerSqft,
      status,
      beds,
      size,
      distance,
      adjustedPrice,
    } = property;

    const priceToReturn = status === 'sold' ? salePrice : price;
    const pricePerSqftToReturn = status === 'sold' ? salePricePerSqft : pricePerSqft;

    return {
      id,
      price: priceToReturn,
      pricePerSqft: pricePerSqftToReturn,
      name: address.deliveryLine,
      status: getStatusIndicator(status),
      beds,
      size,
      distance,
      adjustedPrice,
    };
  });

  return highlighted;
};
