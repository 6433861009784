import React from 'react';
import styled from 'styled-components';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { CommonPdfFrameProps } from '../../PdfFrame';
import { useUiConfigFontsBody } from '../../../../../../../../providers/providers/UiConfigFontProvider';

interface PdfPageLayoutProps extends CommonPdfFrameProps {
  page?: number;
}

const LetterContainer = styled.div`
  width: 1000px;
  height: 1294px;
  background-color: #fff;
  page-break-after: auto;
  page-break-inside: avoid;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 30px 50px 20px 50px;
`;

const Main = styled.div`
  flex-grow: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 1039px;
  min-height: 1039px;
`;

export const PdfPageLayout: React.FC<PdfPageLayoutProps> = ({
  children = null,
  page = 0,
  isCover = false,
  hideProgressTracker,
}) => (
  <LetterContainer>
    <Container>
      <Header page={page} isCover={isCover} hideProgressTracker={hideProgressTracker} />
      <Main style={{ fontFamily: useUiConfigFontsBody() }}>{children}</Main>
      <Footer page={page} isCover={isCover} />
    </Container>
  </LetterContainer>
);
