import styled from 'styled-components';

const Text = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray[400]};
  font-weight: 500;
`;

interface Props {
  text: string;
}

export const TextCell = ({ text }: Props) => {
  return <Text>{text}</Text>;
};
