import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { OneImageZeta } from './OneImageZeta';
import { OneImageZetaForm } from './OneImageZetaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { OneImageZetaPdf } from './OneImageZetaPdf';

const template = 'oneImageLayoutZeta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - One Image',
  frame: true,
  data: {
    image: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: OneImageZetaForm,
  web: OneImageZeta,
  pdf: OneImageZetaPdf,
};

export type TOneImageLayoutZeta = typeof template;
export { config, mappings, thumbnail };
