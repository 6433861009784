import styled from 'styled-components';
import { Typography } from '../../../Common';

export { Typography };

export const GraphCard = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: 10,
  padding: '25px 20px 14px 20px',
}));

export const GraphCardContent = styled('div')({
  marginTop: 38,
  width: '100%',
  overflow: 'scroll',
  'overflow-y': 'hidden',
  position: 'relative',
  paddingBottom: 25,
  '::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
    width: '1px',
    marginTop: '10px',
    height: '8px',
  },
  '::-webkit-scrollbar-thumb': {
    'border-radius': '4px',
    'background-color': 'rgba(0, 0, 0, .3)',
  },
});

export const GraphCardContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

export const GraphCardHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '65%',
});
