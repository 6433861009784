import React, { useMemo } from 'react';
import { useUiConfigLogos } from '../../../../../../providers/providers/UiConfigLogosProvider';
import {
  AsideContainer,
  BrokerInfo,
  BrokerName,
  CompanyLogoContainer,
  CompanyLogoWrapper,
  ContactItem,
  ContactWrapper,
  Image,
  ImageWrapper,
  License,
  Title,
  LogoImage,
  Overlay,
} from './ClosingAndNextStepsAside.styles';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { useUser } from '../../../../providers/UserProvider';
import { useUiUseKvCoreLogo } from '../../../../../../providers/providers/UiUseKvCoreLogoProvider';

export const ClosingAndNextStepsAside: React.FC = () => {
  const { logos } = useUiConfigLogos();
  const { useKvCoreLogo } = useUiUseKvCoreLogo();

  const { category } = useCategory();
  const user = useUser();

  const logoImg = useMemo(() => {
    if (useKvCoreLogo) return user?.companyLogo;

    return logos?.first?.value;
  }, [useKvCoreLogo, logos?.first?.value, user?.companyLogo]);

  if (!user?.userId) return null;

  const {
    name,
    position,
    cal_bre,
    phone,
    email,
    website,
    company,
    address,
    city,
    zip,
    slideProfileImage,
    state,
  } = user;

  const showProfileImage =
    slideProfileImage && !slideProfileImage?.includes('/agentimages/nophoto.jpg');

  return (
    <AsideContainer>
      {showProfileImage && (
        <ImageWrapper>
          <Image src={slideProfileImage} alt='Profile photo' />
        </ImageWrapper>
      )}
      {category === 'luxury' && <Overlay />}
      <BrokerInfo>
        <BrokerName>{name}</BrokerName>
        <Title>{position}</Title>
        <License>{cal_bre ? `License #${cal_bre}` : ''}</License>
      </BrokerInfo>
      <ContactWrapper>
        {phone && <ContactItem iconName='mobile-android' phone={phone}></ContactItem>}
        {email && <ContactItem iconName='envelope' email={email}></ContactItem>}
        {website && <ContactItem iconName='globe' website={website}></ContactItem>}
      </ContactWrapper>
      <CompanyLogoContainer>
        <CompanyLogoWrapper>
          {!logoImg ? null : <LogoImage src={logoImg} alt='Company logo' />}
        </CompanyLogoWrapper>
      </CompanyLogoContainer>
      <ContactWrapper>
        {company && (
          <ContactItem
            iconName='map-marker-alt'
            contactAddress={{
              companyName: company,
              address: address,
              city: `${city}, ${state} ${zip}`,
            }}
          />
        )}
      </ContactWrapper>
    </AsideContainer>
  );
};
