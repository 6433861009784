import {
  TAgentPerformance,
  TAgentPerformanceSortOption,
} from './AgentPerformanceTable/AgentPerformanceTable.component';

export class AgentPerformanceUtils {
  static filter = (
    agentPerformanceData: TAgentPerformance[],
    searchTerm: string,
  ): TAgentPerformance[] => {
    if (!searchTerm.trim()) return agentPerformanceData;
    return agentPerformanceData.filter(agentPerformanceItem => {
      return agentPerformanceItem.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  static sort = (
    agentPerformanceData: TAgentPerformance[],
    sort: TAgentPerformanceSortOption,
  ): TAgentPerformance[] => {
    const mappedData = agentPerformanceData.map(data => {
      if (isNaN(new Date(data.dateLastUsed).getTime())) {
        return {
          ...data,
          dateLastUsedTime: 0,
        };
      }
      return {
        ...data,
        dateLastUsedTime: new Date(data.dateLastUsed).getTime(),
      };
    });
    switch (sort) {
      case 'name_asc':
        return mappedData.sort((a, b) => {
          return a.name < b.name ? -1 : b.name < a.name ? 1 : 0;
        });
      case 'name_dsc':
        return mappedData.sort((a, b) => {
          return a.name > b.name ? -1 : b.name > a.name ? 1 : 0;
        });
      case 'email_asc':
        return mappedData.sort((a, b) => {
          return a.email < b.email ? -1 : b.email < a.email ? 1 : 0;
        });
      case 'email_dsc':
        return mappedData.sort((a, b) => {
          return a.email > b.email ? -1 : b.email > a.email ? 1 : 0;
        });
      case 'office_asc':
        return mappedData.sort((a, b) => {
          return a.officeName < b.officeName ? -1 : b.officeName < a.officeName ? 1 : 0;
        });
      case 'office_dsc':
        return mappedData.sort((a, b) => {
          return a.officeName > b.officeName ? -1 : b.officeName > a.officeName ? 1 : 0;
        });
      case 'lastDate_asc':
        return mappedData.sort((a, b) => a.dateLastUsedTime - b.dateLastUsedTime);
      case 'lastDate_dsc':
        return mappedData.sort((a, b) => b.dateLastUsedTime - a.dateLastUsedTime);
      case 'reportsSent_asc':
        return mappedData.sort((a, b) => a.reportsSent - b.reportsSent);
      case 'reportsSent_dsc':
        return mappedData.sort((a, b) => b.reportsSent - a.reportsSent);
      case 'uniqueAddresses_dsc':
        return mappedData.sort((a, b) => a.uniqueAddresses - b.uniqueAddresses);
      case 'uniqueAddresses_asc':
        return mappedData.sort((a, b) => b.uniqueAddresses - a.uniqueAddresses);
      case 'uniqueClients_dsc':
        return mappedData.sort((a, b) => a.uniqueClients - b.uniqueClients);
      case 'uniqueClients_asc':
        return mappedData.sort((a, b) => b.uniqueClients - a.uniqueClients);
      case 'uniqueReports_dsc':
        return mappedData.sort((a, b) => a.uniquePresentations - b.uniquePresentations);
      case 'uniqueReports_asc':
        return mappedData.sort((a, b) => b.uniquePresentations - a.uniquePresentations);
      case 'openRate_asc':
        return mappedData.sort((a, b) => parseInt(a.openRate) - parseInt(b.openRate));
      case 'openRate_dsc':
        return mappedData.sort((a, b) => parseInt(b.openRate) - parseInt(a.openRate));
      case 'topSellerFeedback_asc':
        return mappedData.sort((a, b) => {
          return a.topSellerFeedback < b.topSellerFeedback
            ? -1
            : b.topSellerFeedback < a.topSellerFeedback
              ? 1
              : 0;
        });
      case 'topSellerFeedback_dsc':
        return mappedData.sort((a, b) => {
          return a.topSellerFeedback > b.topSellerFeedback
            ? -1
            : b.topSellerFeedback > a.topSellerFeedback
              ? 1
              : 0;
        });
      case 'topBuyerFeedback_asc':
        return mappedData.sort((a, b) => {
          return a.topBuyerFeedback < b.topBuyerFeedback
            ? -1
            : b.topBuyerFeedback < a.topBuyerFeedback
              ? 1
              : 0;
        });
      case 'topBuyerFeedback_dsc':
        return mappedData.sort((a, b) => {
          return a.topBuyerFeedback > b.topBuyerFeedback
            ? -1
            : b.topBuyerFeedback > a.topBuyerFeedback
              ? 1
              : 0;
        });
      default:
        return mappedData.sort((a, b) => a.dateLastUsedTime - b.dateLastUsedTime);
    }
  };
}
