import React from 'react';
import { MoneyFormatter } from '../../../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';

import { TNetProceedCard } from '../NetProceedsInfo.component';
import {
  CardContainer,
  CardItemsContainer,
  CardItemTitle,
  CardItemValue,
  CardItemWrapper,
  ListPrice,
  NetProceedsItemTitle,
  NetProceedsValue,
} from './NetProceedsCard.styles';

interface NetProceedsCardProps {
  card: TNetProceedCard;
  isActive?: boolean;
  onClick: () => void;
  isWeb?: boolean;
}

export const NetProceedsCard: React.FC<NetProceedsCardProps> = ({
  card,
  isActive,
  onClick,
  isWeb = true,
}) => {
  return (
    <CardContainer isActive={isActive} isWeb={isWeb} onClick={onClick}>
      <ListPrice>{MoneyFormatter.format(card.listPrice)} LIST PRICE</ListPrice>
      <CardItemsContainer isActive={isActive}>
        <CardItemWrapper>
          <CardItemTitle>Estimated Sales Price</CardItemTitle>
          <CardItemValue>{MoneyFormatter.format(card.estimatedPrice)}</CardItemValue>
        </CardItemWrapper>
        <CardItemWrapper>
          <CardItemTitle>Costs</CardItemTitle>
          <CardItemValue>({MoneyFormatter.format(card.costs)})</CardItemValue>
        </CardItemWrapper>
      </CardItemsContainer>
      <CardItemWrapper style={{ paddingBottom: 0, marginBottom: 0 }}>
        <NetProceedsItemTitle>Net proceeds</NetProceedsItemTitle>
        <NetProceedsValue>{MoneyFormatter.format(card.netProceeds)}</NetProceedsValue>
      </CardItemWrapper>
    </CardContainer>
  );
};
