import React from 'react';
import {
  Container,
  Description,
  DescriptionWrapper,
  List,
  ListItem,
  ListTitle,
  Title,
} from './HomeStagingDescription.styles';

type TText = {
  title: string;
  description: string;
};

type TListItem = {
  percentage: string;
  description: string;
};

type TList = {
  listTitle: string;
  listItems: TListItem[];
};

interface HomeStagingDescriptionProps {
  isPdf?: boolean;
  text: TText[];
  list: TList;
}

export const HomeStagingDescription: React.FC<HomeStagingDescriptionProps> = ({
  isPdf,
  text,
  list,
}) => (
  <Container>
    {text.map((textItem, i) => {
      const { title, description } = textItem;
      return (
        <DescriptionWrapper isPdf={isPdf} key={i}>
          <Title isPdf={isPdf}>{title}</Title>
          <Description isPdf={isPdf}>{description}</Description>
        </DescriptionWrapper>
      );
    })}
    <DescriptionWrapper>
      <ListTitle isPdf={isPdf}>{list.listTitle}</ListTitle>
      <List isPdf={isPdf}>
        {list.listItems.map((listItem, i) => {
          const { percentage, description } = listItem;
          return (
            <ListItem isPdf={isPdf} key={i}>
              {percentage} {description}
            </ListItem>
          );
        })}
      </List>
    </DescriptionWrapper>
  </Container>
);
