import React from 'react';
import styled from 'styled-components';

import { Typography } from '../../../../Common';
import { ColorOpacityApplier } from '../../../services/ColorOpacityApplier';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')<WithPdf>(({ isPdf }) => ({
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1'),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: isPdf ? 14 : 24,
  fontWeight: 700,
  height: isPdf ? 85 : 105,
}));

export const Heading = styled(Typography)<WithPdf>(({ theme, isPdf }) => ({
  color: useUiConfigColorsPrimary(),
  textTransform: 'uppercase',
  fontWeight: 700,
  wordSpacing: 2,
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(isPdf ? 'normal' : '0.11em'),
}));

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.colors.gray[400],
  fontWeight: 500,
}));

interface SlideSectionHeaderComponentProps {
  heading: string;
  isPdf?: boolean;
  description?: string;
}

export const SlideSectionHeaderComponent: React.FC<SlideSectionHeaderComponentProps> = ({
  heading,
  isPdf,
  description = '',
}) => {
  const headingStyle = isPdf ? { fontSize: '26px' } : {};
  return (
    <Container isPdf={isPdf}>
      <Heading variant='h3' align='center' style={headingStyle} isPdf={isPdf}>
        {heading}
      </Heading>
      {description && <Description>{description}</Description>}
    </Container>
  );
};
