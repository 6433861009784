import styled from 'styled-components';

export const MapWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 640,
  padding: 10,
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: 5,
}));

export const CMAWrapper = styled('div')({
  width: '100%',
  height: '100%',
});
