import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { useReportUpdatedAt } from '../../../../../../pages/PresentationCreate/dash/ReportFacade/data/useReportUpdatedAt';
import { useSubjectInfo } from '../../../../../../pages/PresentationCreate/dash/ReportFacade/data/useSubjectInfo';
import { useUser } from '../../../../providers/UserProvider';
import { NumberFormatter } from '../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { FEATURES, useHasFeatureFlag } from '../../../../../../hooks/useFeatureFlags';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #dddddd;
  margin-bottom: 20px;
`;

const ColoredBox = styled.div`
  background: ${() => ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '0.05')};
  padding: 17px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ColoredBoxTitle = styled.h3`
  text-align: center;
  line-height: 23px;
  font-weight: 800;
  font-size: 18px;
  text-transform: uppercase;
`;

const ColoredBoxSubtitle = styled.h3`
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
`;

export const ReportInfo: React.FC = () => {
  const updatedAt = useReportUpdatedAt();
  const { addressShort, address, beds, size, totalBaths } = useSubjectInfo();
  const { isCanadaUser } = useUser();
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  return (
    <Container>
      <ColoredBox style={{ padding: 40 }}>
        <ColoredBoxTitle>
          {addressShort}
          <br />
          {`${address.city}, ${address?.state} ${address?.zip}`}
        </ColoredBoxTitle>
        <ColoredBoxSubtitle style={{ marginTop: 10 }}>
          {!isNaN(parseInt(String(beds))) && `${beds} Beds `}
          {hasBathCountFF &&
            !isNaN(parseInt(String(totalBaths))) &&
            ` | ${totalBaths} Total Baths `}
          {!isCanadaUser && !!size && `| Living Area Size ${NumberFormatter.format(size)} sqft`}
        </ColoredBoxSubtitle>
      </ColoredBox>
      <ColoredBox style={{ background: '#fff', padding: 20 }}>
        <ColoredBoxSubtitle>Report valid for:</ColoredBoxSubtitle>
        <ColoredBoxTitle style={{ marginTop: 10 }}>
          {new Date(updatedAt).toLocaleDateString()}
        </ColoredBoxTitle>
      </ColoredBox>
    </Container>
  );
};
