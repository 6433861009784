import React, { Component, createRef } from 'react';
import { useScaleFactor } from '../../../../../../components/PreviewSlide/ScaleFactorProvider';
import { MapScaleInverter } from '../../../../../../components/MapScaleInverter/MapScaleInverter';
import { attachScalableZoomControl } from '../../../../../../components/MapScaleInverter/ScalableZoomControl';

const withScaleFactor = Component => props => {
  const scaleFactor = useScaleFactor();

  return <Component scaleFactor={scaleFactor} {...props} />;
};

class MapImp extends Component {
  map = null;
  ref = createRef();

  constructor(props) {
    super(props);

    this.initializeMap = this.initializeMap.bind(this);
  }

  initializeMap() {
    const { options, onMapLoad, scaleFactor } = this.props;

    const { zoomControl, zoomControlOptions, ...restrictedOptions } = options;

    this.map = new window.google.maps.Map(this.ref.current, restrictedOptions);
    if (!this.props.isPdf) {
      attachScalableZoomControl(this.map, scaleFactor);
    }

    import('overlapping-marker-spiderfier')
      .then(OverlappingMarkerSpiderfierModule => {
        const newOms = new OverlappingMarkerSpiderfierModule.default(this.map, {
          markersWontMove: true,
          markersWontHide: true,
          basicFormatEvents: true,
        });

        onMapLoad(this.map, window.google, newOms);
      })
      .catch(err => {
        console.error('module loading:', err);
        onMapLoad(this.map, window.google);
      });
  }

  componentDidMount() {
    if (window.google) {
      return this.initializeMap();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.scaleFactor !== this.props.scaleFactor && !this.props.isPdf) {
      attachScalableZoomControl(this.map, this.props.scaleFactor);
    }

    if (window.google && !this.map) {
      this.initializeMap();
    }
  }

  render() {
    const { width = '100%', height = '100%', ...restOfProps } = this.props.style;

    return (
      <MapScaleInverter style={{ width, height, ...restOfProps }}>
        <div ref={this.ref} style={{ width: '100%', height: '100%' }} className='google-map' />
      </MapScaleInverter>
    );
  }
}

export const Map = withScaleFactor(MapImp);
