import { CustomMarker } from '../../../../../components/Common/CustomMarker/CustomMarker.component';
import { getPropertyStatus } from '../../../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';
import { TProperty } from '../../../../../types';
import { getInfoWindowContent } from '../getInfoWindowContent';
import { useMemo } from 'react';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import { getMapPinBasedOnStatus } from '../../../../../services/getMapPinBasedOnStatus';

interface Props {
  property: TProperty;
  subject: TProperty;
}

export const PropertyMarker = ({ property, subject }: Props) => {
  const pinName = getPropertyStatus(property.status); //move to services
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  const icon = useMemo(() => {
    const isGray = property.partialMatch || property.excluded;

    return getMapPinBasedOnStatus({ status: pinName, isGray, hasBtTheme });
  }, [hasBtTheme, pinName, property.excluded, property.partialMatch]);

  return (
    <CustomMarker
      key={property?.id}
      icon={icon}
      property={property}
      isClickable
      infoWindowContent={getInfoWindowContent({
        property,
        subjectSize: subject?.size,
        hasBathCountFF,
      })}
    />
  );
};
