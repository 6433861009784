import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { highlightedPropertiesAtom } from '../../../state/highlighted-properties/highlightedPropertiesAtom';
import { selectedCategoriesAtom } from '../../../state/selectedCategoriesAtom';
import { customEstimatesAtom } from '../../../state/customEstimatesAtom';
import { RangeHighlights } from '../components/RangeHighlights';
import { ScaleMiddleLine } from '../components/ScaleMiddleLine';
import { BottomRangeSelected } from '../components/BottomRangeSelected';

export const ScalePDF = ({ pricePerSqftMode = false, isAdjustedPricePlotBy = false }) => {
  const highlighted = useAtomValue(highlightedPropertiesAtom);
  const selectedCategories = useAtomValue(selectedCategoriesAtom);
  const customEstimates = useAtomValue(customEstimatesAtom);

  const numberOfHighlighted = highlighted.length;
  const numberOfBottomSelected = selectedCategories.length + customEstimates.length;
  const totalSelected = numberOfHighlighted + numberOfBottomSelected;

  const justifyContent = useMemo(() => {
    if (totalSelected <= 16) return 'center';

    return 'flex-start';
  }, [totalSelected]);

  return (
    <Container style={{ justifyContent }}>
      <Content>
        <RangeHighlights
          isPdf
          overrideMode={pricePerSqftMode}
          overridePlotBy={isAdjustedPricePlotBy}
        />
      </Content>
      <ScaleMiddleLine
        isPdf
        overrideMode={pricePerSqftMode}
        overridePlotBy={isAdjustedPricePlotBy}
      />
      <Content>
        <BottomRangeSelected
          isPdf
          overrideMode={pricePerSqftMode}
          overridePlotBy={isAdjustedPricePlotBy}
        />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  width: 90%;
`;
