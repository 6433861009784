import React from 'react';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import {
  Container,
  ImagesContainer,
  ImageWrapper,
  Image,
  DescriptionContainer,
  TextSection,
  Title,
  DescriptionText,
  ListTitle,
  List,
  ListItem,
  SourceText,
} from './ImportanceOfStagingLuxuryPdf.styles';

import image1 from '../assets/image1.jpg';
import image2 from '../assets/image2.jpg';

import { text, source, list } from '../../data';

export const ImportanceOfStagingLuxuryPdf: React.FC = () => {
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <ImagesContainer>
          <ImageWrapper>
            <Image src={image1} alt='Importance of staging image' />
          </ImageWrapper>
          <ImageWrapper>
            <Image src={image2} alt='Importance of staging image' />
          </ImageWrapper>
        </ImagesContainer>
        <DescriptionContainer>
          {text.map((textSection, i) => {
            const { title, description } = textSection;
            return (
              <TextSection key={i}>
                <Title>{title}</Title>
                <DescriptionText>{description}</DescriptionText>
              </TextSection>
            );
          })}
          <TextSection>
            <ListTitle>{list.listTitle}</ListTitle>
            <List>
              {list.listItems.map((listItem, i) => {
                const { percentage, description } = listItem;
                return (
                  <ListItem key={i}>
                    {percentage} {description}
                  </ListItem>
                );
              })}
            </List>
          </TextSection>
        </DescriptionContainer>
        <SourceText>Source: {source}</SourceText>
      </Container>
    </PdfFrame>
  );
};
