import modernConfig from './Modern';
import luxuryConfig from './Luxury';
import traditionConfig from './Traditional';
import { TFrameConfig, TSlideTheme } from '../../../../../../types';

export class FrameConfigsProvider {
  private static themes: Record<TSlideTheme, TFrameConfig> = {
    ...traditionConfig,
    ...modernConfig,
    ...luxuryConfig,
  };

  static get(theme: TSlideTheme): TFrameConfig {
    return FrameConfigsProvider.themes[theme];
  }
}
