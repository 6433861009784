import React from 'react';
import styled from 'styled-components';
import * as data from '../../data';
import slideGraphics from '../../assets/slideGraphics';
import slideGraphicsCanada from './slideGraphCanada';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';

type WithCanadaUser = {
  isCanadaUser: boolean;
};

const Col = styled('div')({ display: 'flex', flexDirection: 'column' });

const Container = styled(Col)({
  width: '100%',
  height: '100%',
});

const BottomContent = styled(Col)({
  borderRadius: 5,
  border: '1px solid #DDDDDD',
  marginBottom: 15,
  paddingBottom: 15,
});

const Wrapper = styled(Col)({
  flex: 1,
  position: 'relative',
  padding: 30,
});

const Header = styled('header')(({ theme }) => ({
  width: '100%',
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
}));

const HeaderText = styled('p')(({ theme }) => ({
  fontWeight: 800,
  textAlign: 'center',
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing('0.11em'),
  color: useUiConfigColorsPrimary(),
}));

const Graphics = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  width: isCanadaUser ? '87%' : '82%',
  alignSelf: 'center',
  margin: '0',
}));

const TopContent = styled(Col)(({ theme }) => ({
  borderRadius: 5,
  border: '1px solid #DDDDDD',
  padding: '20px 30px 30px 30px',
  flexShrink: 0,
  marginBottom: 15,
}));

const Heading = styled('h2')(({ theme }) => ({
  fontWeight: 900,
  color: '#444',
  marginBottom: 10,
  letterSpacing: '0.08em',
  textTransform: 'uppercase',
  fontSize: 26,
}));

const common = {
  color: '#010101',
  fontSize: 16,
  lineHeight: 1.3,
};

const Text = styled('p')(({ theme }) => ({
  textAlign: 'justify',
  width: '55%',
  letterSpacing: useUiConfigBodyLetterSpacing(),
  ...common,
  '&:not(:last-child)': {
    marginBottom: 35,
  },
}));

const SourceText = styled('p')({
  textAlign: 'right',
  fontSize: 13,
  color: '#101010',
  fontWeight: 300,
  marginTop: 5,
});

const UL = styled('ul')({
  paddingLeft: 20,
  marginTop: -25,
});

const LI = styled('li')(({ theme }) => ({
  ...common,
  marginBottom: 5,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const WhyPricingCorrectlyIsImportantModernPDF: React.FC = () => {
  const { isCanadaUser } = useUser();
  const primary = useUiConfigColorsPrimary();
  const fontFamily = useUiConfigFontsHeader();
  const letterSpacing = useUiConfigHeaderLetterSpacing();

  const graph = isCanadaUser ? slideGraphicsCanada : slideGraphics;

  const image = React.useMemo(() => svgStringToDataUrl(graph, primary), [graph, primary]);

  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      <Container>
        <TopContent>
          <Heading style={{ fontFamily, color: primary, letterSpacing }}>{data.heading}</Heading>
          {data.paragraphs.map((p, i) => (
            <Text key={i}>{p}</Text>
          ))}
          <UL>
            {data.listItems.map((li, i) => (
              <LI key={i}>{li}</LI>
            ))}
          </UL>
        </TopContent>
        <BottomContent>
          <Header>
            <HeaderText>
              {isCanadaUser
                ? 'ATTRACT MORE BUYERS WHEN PRICED CORRECTLY'
                : 'Percentage of Potential Buyers when priced correctly'}
            </HeaderText>
          </Header>
          <Wrapper>
            <Graphics src={image} isCanadaUser={isCanadaUser} />
          </Wrapper>
        </BottomContent>
        {!isCanadaUser && <SourceText>Source: Zillow Listing Data</SourceText>}
      </Container>
    </PdfFrame>
  );
};

export default WhyPricingCorrectlyIsImportantModernPDF;
