import {
  IPresentationConfigs,
  TPresentationMode,
  TPresentationType,
} from '../../../../../../types';
import { CmaConfigs } from '../../../../../../services/configs/CmaConfigs';
import { PRESENTATION_TYPES } from '../../../../../../constants/PresentationTypes';
import { OneSheeterConfigs } from '../../../../../../services/configs/OneSheeterConfigs';
import { BuyerTourConfigs } from '../../../../../../features/BuyerTour/services/BuyerTourConfigs';
import { WinTheOfferConfigs } from '../../../../../../features/WinTheOffer/services/WinTheOfferConfigs';
import { PresentInfoConfigs } from '../../../../../../features/PresentInfo/services/PresentInfoConfigs';
import { WinTheListingConfigs } from '../../../../../../features/WinTheListing/services/WinTheListingConfigs';
import { WTRConfigs } from '../../../../../../features/WinTheRepresentation/services/WTRConfigs';

type TConfigMap = {
  [key in TPresentationType]: {
    regular: IPresentationConfigs;
    cma?: IPresentationConfigs;
    oneSheeter?: IPresentationConfigs;
  };
};
export class PresentationConfigsFactory {
  private static factories: TConfigMap = {
    [PRESENTATION_TYPES.WIN_THE_LISTING]: {
      regular: WinTheListingConfigs,
      cma: CmaConfigs,
      oneSheeter: OneSheeterConfigs,
    },
    [PRESENTATION_TYPES.WIN_THE_OFFER]: {
      regular: WinTheOfferConfigs,
      cma: CmaConfigs,
      oneSheeter: OneSheeterConfigs,
    },
    [PRESENTATION_TYPES.PRESENT_INFO]: { regular: PresentInfoConfigs },
    [PRESENTATION_TYPES.BUYER_TOUR]: { regular: BuyerTourConfigs },
    [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: { regular: WTRConfigs },
  };

  static create(
    type: TPresentationType,
    mode: TPresentationMode = 'regular',
  ): IPresentationConfigs {
    if (!PresentationConfigsFactory.factories?.[type][mode]) {
      throw new Error(`There is no presentation config for provided type (${type})!`);
    }

    return PresentationConfigsFactory.factories[type][mode] as IPresentationConfigs;
  }
}
