import { useState } from 'react';

import { Sidebar } from '../components/Sidebar';
import { EstimatesSidebarContent } from './EstimatesSidebarContent';
import { Button } from '../components/Button';

export const Estimates = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        label='Estimates'
        iconName='clipboard_list'
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
      />

      <Sidebar isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <EstimatesSidebarContent />
      </Sidebar>
    </>
  );
};
