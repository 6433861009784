import { TSlide } from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { WhatBuyersWantFromTheirAgentsLuxury } from './web/luxury/WhatBuyersWantFromTheirAgentsLuxury';
import { WhatBuyersWantFromTheirAgentsModern } from './web/modern/WhatBuyersWantFromTheirAgentsModern';
import { WhatBuyersWantFromTheirAgentsTraditional } from './web/traditional/WhatBuyersWantFromTheirAgentsTraditional';
import WhatBuyersWantFromTheirAgentstModernPDF from './pdf/modern/WhatBuyersWantFromTheirAgentstModernPDF';
import WhatBuyersWantFromTheirAgentsLuxuryPDF from './pdf/luxury/WhatBuyersWantFromTheirAgentsLuxuryPDF';
import WhatBuyersWantFromTheirAgentstTraditionalPDF from './pdf/traditional/WhatBuyersWantFromTheirAgentstTraditionalPDF';

const id = 'whatBuyersWantFromTheirAgents';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'What Buyers want from Their Agent',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: WhatBuyersWantFromTheirAgentsTraditional,
    [CATEGORIES.MODERN]: WhatBuyersWantFromTheirAgentsModern,
    [CATEGORIES.LUXURY]: WhatBuyersWantFromTheirAgentsLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: WhatBuyersWantFromTheirAgentstTraditionalPDF,
    [CATEGORIES.MODERN]: WhatBuyersWantFromTheirAgentstModernPDF,
    [CATEGORIES.LUXURY]: WhatBuyersWantFromTheirAgentsLuxuryPDF,
  },
};

export type TWhatBuyersWantFromTheirAgents = typeof id;
export { config, mappings };
