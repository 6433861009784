import React from 'react';
import styled from 'styled-components';

import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { useUiConfigColorsSecondary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { useSubjectInfo } from '../../../../../../pages/PresentationCreate/dash/ReportFacade/data/useSubjectInfo';
import { useReportUpdatedAt } from '../../../../../../pages/PresentationCreate/dash/ReportFacade/data/useReportUpdatedAt';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { TProperty } from '../../../../../../types';
import { MoneyFormatter } from '../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { abbreviateNumber } from '../../../../../../services/abbreviateNumber';
import { usePresentation } from '../../../../../../hooks/usePresentation';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../../providers/UserProvider';
import { NumberFormatter } from '../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { FEATURES, useHasFeatureFlag } from '../../../../../../hooks/useFeatureFlags';

const Container = styled.div`
  width: 100%;
  display: flex;
`;
const ColoredBox = styled.div`
  background: ${() => ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1')};
  border-radius: 5px;
  padding: 17px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  &:first-child {
    margin-right: 20px;
  }
`;

const ColoredBoxTitle = styled.h3`
  text-align: center;
  line-height: 23px;
  font-weight: 800;
  font-size: 18px;
  text-transform: uppercase;
`;
const ColoredBoxSubtitle = styled.h3`
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
`;

interface Props {
  coloredBoxStyles?: React.CSSProperties;
}

export const ReportInfoSection: React.FC<Props> = ({ coloredBoxStyles }) => {
  const updatedAt = useReportUpdatedAt();
  const { addressShort, address, beds, size, totalBaths } = useSubjectInfo();
  const { isCanadaUser } = useUser();
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  return (
    <Container>
      <ColoredBox style={coloredBoxStyles}>
        <ColoredBoxTitle>
          {addressShort}
          <br />
          {`${address.city}, ${address?.state} ${address?.zip}`}
        </ColoredBoxTitle>
        <ColoredBoxSubtitle style={{ marginTop: 10 }}>
          {!isNaN(parseInt(String(beds))) && `${beds} Beds `}
          {hasBathCountFF &&
            !isNaN(parseInt(String(totalBaths))) &&
            ` | ${totalBaths} Total Baths `}
          {!isCanadaUser && !!size && `| Living Area Size ${NumberFormatter.format(size)} sqft`}
        </ColoredBoxSubtitle>
      </ColoredBox>
      <ColoredBox style={coloredBoxStyles}>
        <ColoredBoxSubtitle>Report valid for:</ColoredBoxSubtitle>
        <ColoredBoxTitle style={{ marginTop: 10 }}>
          {new Date(updatedAt).toLocaleDateString()}
        </ColoredBoxTitle>
      </ColoredBox>
    </Container>
  );
};

export const getPriceData = (properties: TProperty[]) => {
  const prices = properties.map(p => p.price).sort();
  const min = prices[0];
  const max = prices.slice(-1)[0];
  const avg = Math.round(prices.reduce((prev, curr) => prev + curr) / prices.length);

  return { min, max, avg };
};

export const ReportInfoSectionBT: React.FC = () => {
  const { properties } = useProperties();
  const { hash } = useParams<{ hash: string }>();

  const priceData = getPriceData(properties);
  const { data: presentation } = usePresentation(hash);

  return (
    <Container>
      <ColoredBox>
        <ColoredBoxTitle>AVERAGE PRICE: {MoneyFormatter.format(priceData.avg)}</ColoredBoxTitle>
        <ColoredBoxSubtitle style={{ marginTop: 10 }}>
          Price range of tour:{' '}
          {`$${abbreviateNumber(priceData.min)} - $${abbreviateNumber(priceData.max)}`}
        </ColoredBoxSubtitle>
      </ColoredBox>
      <ColoredBox>
        <ColoredBoxSubtitle>Tour prepared on:</ColoredBoxSubtitle>
        <ColoredBoxTitle style={{ marginTop: 10 }}>
          {new Date(presentation?.createdAt ? presentation.createdAt : '').toLocaleDateString()}
        </ColoredBoxTitle>
      </ColoredBox>
    </Container>
  );
};
