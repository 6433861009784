import styled from 'styled-components';

export const TableItem = styled('div')(({ theme }) => ({
  padding: '10px 19px',
  borderTop: `1px solid ${theme.colors.gray[200]}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#fff',
  '&:nth-of-type(even)': {
    backgroundColor: '#FAFAFA',
  },
}));

export const PropertyInfoWrapper = styled('div')({
  display: 'flex',
});

export const ImageWrapper = styled('div')({
  width: 116,
  height: 71,
  marginRight: 30,
});

export const Image = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover',
  objectPosition: 'center',
});

export const PropertyDetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const Price = styled('div')(({ theme }) => ({
  color: theme.colors.gray[400],
  fontSize: 20,
  fontWeight: 800,
  lineHeight: '26.64px',
}));

export const PropertyDetails = styled('div')({
  display: 'flex',
  marginTop: 3,
});

export const PropertyDetail = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 700,
  color: theme.colors.gray[400],
  textTransform: 'uppercase',
  '&:not(:last-of-type)': {
    marginRight: 4,
    borderRight: `1px solid ${theme.colors.gray[400]}`,
    paddingRight: 4,
  },
}));

export const AddressContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 310,
});

export const Address = styled('div')(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.gray[400],
  lineHeight: '20.3px',
}));

export const City = styled('div')(({ theme }) => ({
  fontSize: 13,
  color: theme.colors.gray[400],
  textTransform: 'uppercase',
  lineHeight: '17.32px',
  fontWeight: 700,
}));
