import { useState } from 'react';
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { TPresentationSession } from '../../../../../../../api/TrackingApi';
import { usePresentation } from '../../../../../../../hooks/usePresentation';
import { formatBTAnalyticsTableData } from '../services/formatBTAnalyticsTableData';
import { useBTAnalyticsColumns } from './useBTAnalyticsColumns';

export const useBTAnalyticsTable = (hash: string, session?: TPresentationSession) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: 'propertyPrice', desc: true }]);

  const columns = useBTAnalyticsColumns();

  const { data } = usePresentation(hash);

  const formattedData = formatBTAnalyticsTableData(data?.properties, session?.ratings);

  const table = useReactTable({
    data: formattedData,
    columns: columns,
    enableSortingRemoval: false,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return table;
};
