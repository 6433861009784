import React, { useEffect, useState } from 'react';

import { TKvCoreEntity, TUser } from '../../../types';
import { Select } from '../Select/Select.component';
import { useUser } from '../../Slide/providers/UserProvider';
import { Container, DropdownWrapper } from './AccountOfficeDropdown.styles';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { afterToday, combine, before } from 'rsuite/cjs/DateRangePicker/disabledDateUtils';
import { subDays, subMonths, startOfMonth, endOfToday } from 'date-fns';
import { UtcTime } from '../../../services/utcTime';
import { useTheme } from 'styled-components';

type TScope = 'myself' | 'teams' | 'offices' | 'account' | 'superaccount';
type TOption = {
  value: string | number;
  label: string;
  isDisabled?: boolean;
};

const scopeOptions: Record<TScope, TOption> = {
  myself: {
    value: 'myself',
    label: 'Myself',
  },
  teams: {
    value: 'teams',
    label: 'My Team',
  },
  offices: {
    value: 'offices',
    label: 'My Office',
  },
  account: {
    value: 'account',
    label: 'Whole Company',
  },
  superaccount: {
    value: 'superaccount',
    label: 'Super Account',
  },
};

const getScopeOptions = (user: TUser) => {
  return Object.values(scopeOptions).map(o => {
    if (o.value === 'myself') {
      return { ...o, isDisabled: false };
    }

    if (o.value === 'account') {
      return { ...o, isDisabled: !user.account?.isAdmin };
    }

    if (o.value === 'superaccount') {
      return { ...o, isDisabled: !user.superaccounts?.find(sa => sa.isAdmin) };
    }

    const isLevelAdmin = (user as any)[o.value]?.some((entity: TKvCoreEntity) => entity.isAdmin);

    return { ...o, isDisabled: !isLevelAdmin };
  });
};

const valueToScopeOption = (user: TUser, value: number | null, removeOptions: any): TOption => {
  if (value === -1 || value === -2) return scopeOptions.superaccount;

  if (value === 0) return scopeOptions.account;

  const team = user.teams.find(team => team.id === value);
  if (team) return scopeOptions.teams;

  const office = user.offices.find(team => team.id === value);
  if (office) return scopeOptions.offices;

  if (Object.keys(removeOptions).length && removeOptions) {
    return scopeOptions.offices;
  }

  return scopeOptions.myself;
};

const valueToSubOption = (user: TUser, value: number | null): TOption | undefined => {
  if (value === -1 || value === -2) {
    const sa = user.superaccounts?.find(entity => entity.id === value);

    return !sa ? undefined : { label: sa.name, value: sa.id };
  }

  const entity = [...user.teams, ...user.offices].find(entity => entity.id === value);
  if (!entity) return undefined;

  return { label: entity.name, value: entity.id };
};

const getSubOptions = (user: TUser, scope: TScope, hideSupperAccountSubOptions: boolean) => {
  if (scope === 'superaccount' && !hideSupperAccountSubOptions) {
    return user.superaccounts.map(sa => ({ label: sa.name, value: sa.id })).sort();
  }

  if (scope === 'offices') {
    return user.offices.map(office => ({ label: office.name, value: office.id }));
  }

  if (scope === 'teams') {
    return user.teams.map(team => ({ label: team.name, value: team.id }));
  }

  return [];
};

interface AccountOfficeDropdownProps {
  value: number | null;
  onChange?: (val: number | null, name?: string | null) => void;
  onSubChange?: (val: string | null) => void;
  onTypeChange?: (val: string) => void;
  onDateChange?: (start: string | null, end: string | null) => void;
  menuPlacementTop?: boolean;
  disabled?: boolean;
  removeOptions?: any;
  orientation?: 'horizontal' | 'vertical';
  hidePresentationType?: boolean;
  setSelectedOptionLabel?: (selectedLabel: string) => void;
  hideSupperAccountSubOptions?: boolean;
  fullWidth?: boolean;
}

export const AccountOfficeDropdown: React.FC<AccountOfficeDropdownProps> = ({
  value,
  onChange = f => f,
  onSubChange = f => f,
  onTypeChange = f => f,
  onDateChange = f => f,
  menuPlacementTop,
  disabled = false,
  removeOptions = {},
  orientation = 'horizontal',
  hidePresentationType = false,
  setSelectedOptionLabel = () => {},
  hideSupperAccountSubOptions = false,
  fullWidth,
}) => {
  const styles = {
    fontSize: 14,
    selectedBgColor: '#ddd',
    selectedFontColor: '#444',
    menuMarginTop: '0px',
    menuMarginBottom: '0px',
  };

  const typeOptions = [
    {
      value: 'all',
      label: 'All Presentations',
    },
    {
      value: 'seller',
      label: 'Seller Presentations',
    },
    {
      value: 'buyer',
      label: 'Buyer Presentations',
    },
  ];

  const user = useUser();

  const { colors } = useTheme();

  const [innerScope, setInnerScope] = useState<TOption>(
    valueToScopeOption(user, value, removeOptions),
  );
  const [subOptions, setSubOptions] = useState<TOption[]>(
    getSubOptions(user, innerScope.value as TScope, hideSupperAccountSubOptions),
  );
  const [selectedSubOption, setSelectedSubOption] = useState<TOption | undefined>(
    valueToSubOption(user, value),
  );

  const [selectedType, setSelectedType] = useState<any>(typeOptions[0]);
  const [selectedDate, setSelectedDate] = useState<any>([]);
  useEffect(() => {
    setSelectedOptionLabel(innerScope.label);
  }, [innerScope, setSelectedOptionLabel]);

  useEffect(() => {
    if (!onSubChange) return;
    if (!selectedSubOption?.label) return;
    onSubChange(selectedSubOption.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = React.useMemo(() => getScopeOptions(user), [user]);

  const onScopeChange = React.useCallback(
    function onScopeChange(scope: TOption) {
      setInnerScope(scope);
      setSubOptions([]);
      setSelectedSubOption(undefined);

      if (scope.value === 'myself') {
        return onChange(null);
      }

      if (scope.value === 'account') {
        return onChange(0);
      }

      if (scope.value === 'superaccount' && hideSupperAccountSubOptions) {
        return onChange(-1);
      }

      const options = getSubOptions(user, scope.value as TScope, hideSupperAccountSubOptions);
      setSubOptions(() => options);

      const firstOption = options?.[0];
      setSelectedSubOption(() => firstOption);

      onChange(firstOption?.value, firstOption?.label);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedType, selectedDate],
  );

  const onSubOptionChange = (option: TOption) => {
    onChange(option.value as number);
    setSelectedSubOption(option);
    onSubChange(option.label);
  };

  const finalOptions = options.filter(option => {
    return removeOptions[option.value] === undefined;
  });

  const isVertical = orientation === 'vertical';
  const thirteenMonthsAgo = subMonths(startOfMonth(new Date()), 13);
  const sixMonthsAgo = subMonths(startOfMonth(new Date()), 6);
  const sevenDaysAgo = subDays(new Date(), 7);
  const startOfCurrentMonth = startOfMonth(new Date());

  return (
    <Container
      style={{ flexDirection: isVertical ? 'column' : 'row', justifyContent: 'space-between' }}
    >
      <div
        style={{
          width: fullWidth ? '100%' : '70%',
          display: 'flex',
          flexDirection: isVertical ? 'column' : 'row',
        }}
      >
        <DropdownWrapper
          style={{
            width: isVertical ? 226 : 155,
            flex: 'initial',
            marginBottom: isVertical && Boolean(subOptions?.length) ? 16 : 0,
          }}
        >
          <Select
            disabled={disabled}
            options={finalOptions}
            selectedOption={innerScope}
            onChange={option => onScopeChange(option as TOption)}
            styles={styles}
            menuPlacementTop={menuPlacementTop}
          />
        </DropdownWrapper>

        {!!subOptions.length && (
          <DropdownWrapper
            style={{ width: isVertical ? 226 : 'auto', marginLeft: !isVertical ? 10 : 0 }}
          >
            <Select
              disabled={disabled}
              options={subOptions}
              selectedOption={selectedSubOption}
              onChange={option => onSubOptionChange(option as TOption)}
              styles={styles}
              menuPlacementTop={menuPlacementTop}
            />
          </DropdownWrapper>
        )}
      </div>
      {!hidePresentationType && (
        <DropdownWrapper
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
          }}
        >
          <DateRangePicker
            placeholder={'Select Date Range'}
            onChange={dates => {
              if (!dates) {
                setSelectedDate([]);
                return;
              }
              setSelectedDate([dates[0], dates[1]]);
              onDateChange(UtcTime.getInUtc(dates[0]), UtcTime.getInUtc(dates[1]));
            }}
            shouldDisableDate={combine(afterToday(), before(thirteenMonthsAgo))}
            defaultValue={[thirteenMonthsAgo, endOfToday()]}
            cleanable={false}
            placement='autoVertical'
            appearance='subtle'
            ranges={[
              {
                label: 'Last 7 Days',
                value: [sevenDaysAgo, endOfToday()],
              },
              {
                label: 'Month To Date',
                value: [startOfCurrentMonth, endOfToday()],
              },
              {
                label: 'Last 6 Months',
                value: [sixMonthsAgo, endOfToday()], // Adding one more day due to some weird datepicker stuff
              },
              {
                label: 'Last 13 Months',
                value: [thirteenMonthsAgo, endOfToday()], // Adding one more day due to some weird datepicker stuff
              },
            ]}
            style={{
              display: 'flex',
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset',
              background: colors.v2.background,
              border: `1px solid ${colors.v2.gray[300]}`,
              cursor: 'pointer',
              borderRadius: '5px',
              height: '35px',
              outline: 'none',
            }}
          />
          <Select
            options={typeOptions}
            selectedOption={selectedType}
            onChange={(option: any) => {
              setSelectedType(option);
              onTypeChange(option?.value);
            }}
            styles={{ ...styles, flex: 'initial', width: '180px' }}
            menuPlacementTop={menuPlacementTop}
          />
        </DropdownWrapper>
      )}
    </Container>
  );
};
