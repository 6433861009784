import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';

const Title = styled('h2')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontFamily: useUiConfigFontsHeader(),
  fontSize: 78,
  textTransform: 'uppercase',
  marginBottom: 25,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  textAlign: 'center',
}));

export const ClosingAndNextStepsTitle: React.FC = () => {
  return <Title>Thank you</Title>;
};
