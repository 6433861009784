import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

import {
  ModalActiveSectionProvider,
  useModalActiveSection,
} from '../providers/ModalActiveSectionProvider';
import { useSelectableSectionSlides } from './useSelectableSectionSlides';
import { useTagsInSection } from '../../../../../../hooks/dynamicSlides/useTags';
import { SectionsNavigation } from '../AddSlideRegularMode/SectionsNavigation';
import {
  TTag,
  TSlide,
  TDynamicSectionId,
  TSlideId,
  TAddSlideBtnStyle,
} from '../../../../../../types';
import { withProviders } from '../../../../../../hoc/withProviders';
import { useOrder } from '../../../../../../providers/providers/OrderProvider';
import { AddButtonWithModal } from '../components/AddButtonWithModal/AddButtonWithModal';
import { useSections } from '../../../../../../providers/providers/SectionsProvider';
import { SelectableSlidesGrid } from '../components/SelectableSlidesGrid/SelectableSlidesGrid';
import { SlidesTags } from '../../../../../../components/Common/SlidesTags/SlidesTags.component';
import { ModalVisibilityProvider, useModalVisibility } from '../providers/ModalVisibilityProvider';
import { Button } from '../../../../../../components/Common/V2/Button/Button';

const Container = styled('div')(({ theme }) => ({
  padding: '26px 28px 24px 30px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.gray[100],
  flexShrink: 0,
}));

interface Props {
  section: TDynamicSectionId;
  withSectionsNavigation?: boolean;
  btnStyle?: TAddSlideBtnStyle;
}

export const AddSlideToOrderConfig: React.FC<Props> = withProviders<Props>(
  ModalActiveSectionProvider,
  ModalVisibilityProvider,
)(({ section, withSectionsNavigation = false, btnStyle = 'add' }) => {
  const {
    sections: sectionsOrder,
    sectionsSlide,
    setSectionSlidesOrder,
    getOrderedSlides,
  } = useOrder();

  const { sections } = useSections();
  const { closeModal } = useModalVisibility();

  const { modalActiveSection, setModalActiveSection, resetModalActiveSection } =
    useModalActiveSection();

  const [selectedTags, setSelectedTags] = useState<TTag[]>([]);
  const tags = useTagsInSection('allSlides');
  const tagsList = React.useMemo(() => Object.values(tags), [tags]);

  const activeSectionSlides = sectionsSlide[section as TDynamicSectionId];
  const [updatedOrder, setUpdatedOrder] = useState(activeSectionSlides ?? getOrderedSlides());

  const slides = useSelectableSectionSlides(selectedTags, modalActiveSection);

  useEffect(() => {
    setSelectedTags([]);
  }, [modalActiveSection]);

  useEffect(() => {
    setModalActiveSection(section);
  }, [section, setModalActiveSection]);

  useEffect(() => {
    const activeSectionSlides = sectionsSlide[modalActiveSection as TDynamicSectionId];
    setUpdatedOrder(activeSectionSlides ?? getOrderedSlides());
  }, [sectionsSlide, modalActiveSection, getOrderedSlides]);

  const onAddSelectedSlide = () => {
    setSectionSlidesOrder(modalActiveSection as TDynamicSectionId, updatedOrder);
    closeModal();
  };

  const onSlideClick = React.useCallback(function onSlideClick(slide: TSlide) {
    setUpdatedOrder(prev => {
      const orderWithoutClicked = prev.filter(sId => sId !== slide.id);

      if (orderWithoutClicked.length < prev.length) {
        return orderWithoutClicked;
      }

      return [...orderWithoutClicked, slide.id] as TSlideId[];
    });
  }, []);

  const selected = React.useMemo(
    function selected() {
      return slides.reduce(
        (map, slide) => ({ ...map, [slide.id]: !!updatedOrder.find(sId => sId === slide.id) }),
        {},
      );
    },
    [slides, updatedOrder],
  );

  return (
    <AddButtonWithModal onClose={resetModalActiveSection} onAddClick={onAddSelectedSlide}>
      {withSectionsNavigation && <SectionsNavigation order={sectionsOrder} sections={sections} />}

      <SlidesTags
        tagsList={tagsList}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />

      <Container>
        <SelectableSlidesGrid
          selected={selected}
          slides={slides}
          hasTags={!!tagsList.length}
          onClick={onSlideClick}
        />
      </Container>
    </AddButtonWithModal>
  );
});
