import styled from 'styled-components';

import { Typography, Button } from '../../components/Common';

export { Typography, Button };

export const SectionContainer = styled('section')({
  backgroundColor: '#fff',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
  borderRadius: 10,
  marginTop: '10px',
});

export const SectionHeader = styled('div')(({ theme }) => ({
  height: 96,
  padding: '0 21px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.gray[200]}`,
  justifyContent: 'space-between',
}));

export const HeaderButtons = styled('div')({
  display: 'flex',
});

export const ButtonText = styled('p')({
  fontSize: 13,
  fontWeight: 400,
  color: '#333',
  marginRight: 38,
});

export const SectionContent = styled('div')({
  padding: '43px 25px 29px 25px',
});
