import styled from 'styled-components';
import { useUiConfigColorsSecondary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';

export const Table = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 10,
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
  overflow: 'hidden',
});

export const TableHead = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '40px 20px 11px 164px',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.05'),
  justifyContent: 'space-between',
}));

export const TableButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const TableButtonSubtext = styled('p')(({ theme }) => ({
  fontSize: 10,
  color: theme.colors.gray[400],
  fontStyle: 'italic',
  fontWeight: 300,
}));

export const TableBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 406,
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    background: theme.colors.gray[200],
    width: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.colors.gray[300],
    borderRadius: 50,
    width: 6,
  },
}));
