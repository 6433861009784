import React from 'react';
import { Loading } from '../../../../../../components/Loading/Loading';

import styles from './LoadingOverlay.module.scss';

export const LoadingOverlay = ({ loading = false, children = null }) => (
  <div className={styles.loadingOverlay}>
    <div className={`${styles.loader} ${loading ? styles.active : ''}`}>
      <Loading />
    </div>
    {children}
  </div>
);
