import { TSlide } from '../../../../types';
import { CATEGORIES } from '../../../../constants/categories';
import { TSlideTypeMapping } from '../../services/PredefinedSlideFactory';
import SectionContentsTraditionalPDF from './traditional/SectionContentsTraditionalPDF';
import SectionContentsModernPDF from './modern/SectionContentsModernPDF';
import SectionContentsLuxuryPDF from './luxury/SectionContentsLuxuryPDF';

const id = 'sectionContents';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Section Contents',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    [CATEGORIES.TRADITIONAL]: SectionContentsTraditionalPDF,
    [CATEGORIES.MODERN]: SectionContentsModernPDF,
    [CATEGORIES.LUXURY]: SectionContentsLuxuryPDF,
  },
};

export type TSectionContents = typeof id;
export { config, mappings };
