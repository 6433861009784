import React from 'react';
import styled from 'styled-components';

import overlay from './overlay.png';
import slideGraphics from './slideGraphics';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import { cards } from '../../data';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 40px;
  margin-bottom: 100px;
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const Image = styled.img`
  height: auto;
  width: 100%;
  margin-left: auto;
`;

const HomeBuyingProcessTraditionalPDF: React.FC = () => {
  const primary = useUiConfigColorsPrimary();

  const img = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container>
        <Heading>Home Buying Process</Heading>
        <Image src={img} style={{ position: 'absolute', top: 100 }} />
        <Image src={overlay} style={{ position: 'absolute', bottom: -15, right: 4 }} />
        <TraditionalSlideCircularCard
          heading={cards[3].title}
          text={cards[3].text}
          position={{ top: 109, left: 142 }}
          headingStyles={{ fontSize: 18 }}
          textStyles={{ fontSize: 16, lineHeight: 1.4 }}
          size={240}
        />
        <TraditionalSlideCircularCard
          heading={cards[1].title}
          text={cards[1].text}
          position={{ top: 366, left: 19 }}
          headingStyles={{ fontSize: 18 }}
          textStyles={{ fontSize: 16, lineHeight: 1.4 }}
          size={212}
        />
        <TraditionalSlideCircularCard
          heading={cards[4].title}
          text={cards[4].text}
          position={{ top: 591, right: 554.5 }}
          headingStyles={{ fontSize: 18 }}
          textStyles={{ fontSize: 16, lineHeight: 1.4 }}
          size={279}
        />
        <TraditionalSlideCircularCard
          heading={cards[2].title}
          text={cards[2].text}
          position={{ top: 712, right: 309.5 }}
          headingStyles={{ fontSize: 18, width: '70%' }}
          textStyles={{ fontSize: 16, lineHeight: 1.4 }}
          size={255}
        />
        <TraditionalSlideCircularCard
          heading={cards[5].title}
          text={cards[5].text}
          position={{ top: 672, right: -12 }}
          headingStyles={{ fontSize: 18, width: '70%' }}
          textStyles={{ fontSize: 16, lineHeight: 1.4 }}
          size={278}
        />
      </Container>
    </PdfFrame>
  );
};

export default HomeBuyingProcessTraditionalPDF;
