import React from 'react';
import image from './image.png';
import * as data from '../../data';

import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';
import { useUser } from '../../../../../providers/UserProvider';

export const WhereBuyersGetTheirInfoLuxury: React.FC = () => {
  const { isCanadaUser } = useUser();
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading={data.heading}
      paragraphs={data.paragraphs}
      imagePosition='right'
      image={image}
      listItems={data.getListItems(isCanadaUser)}
      contentStyles={{ fontSize: 18, lineHeight: 1.3 }}
      imageDominant
      listType='ul'
    />
  );
};
