import { CATEGORIES } from '../../../../../../constants/categories';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { ListingDetailsPdf } from '../../../../../../features/report/listing-details-pdf/ListingDetailsPdf';

export const usePropertiesDetailsPdf = (notes: any[], properties: any[]) => {
  const { category } = useCategory();

  const activeProperties = properties.filter(p => p.status === 'active') || [];
  const pendingProperties = properties.filter(p => p.status === 'pending') || [];
  const soldProperties = properties.filter(p => p.status === 'sold') || [];
  const comingSoonProperties = properties.filter(p => p.status === 'coming_soon') || [];

  const sortedProperties = [
    ...activeProperties,
    ...pendingProperties,
    ...soldProperties,
    ...comingSoonProperties,
  ];

  return sortedProperties.map(property => (
    <PdfFrame key={property.id} category={category ?? CATEGORIES.TRADITIONAL}>
      <ListingDetailsPdf notes={notes} property={property} />
    </PdfFrame>
  ));
};
