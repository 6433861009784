import { useEffect } from 'react';
import { StorageService } from '../../../services/storageService';
import { usePresentationHashFromUrl } from './usePresentationHashFromUrl';

export function useInteractiveIconTooltipVisibility() {
  const hash = usePresentationHashFromUrl();

  useEffect(() => {
    const seen = StorageService.read('interactiveIconSeen');
    if (seen) StorageService.remove('interactiveIconSeen');

    return () => {
      StorageService.remove('interactiveIconSeen');
    };
  }, [hash]);
}
