import React, { useState } from 'react';

import * as S from './style';
import alert_icon from './alert_icon.jpg';
import { DuplicatesList } from './DuplicatesList';
import { RootModal } from '../../../../../../../../../../../components/Common';
import { Button } from '../../../../../../../../../../../components/Common/V2/Button/Button';

interface Props {
  open: boolean;
  properties: any;
  isLoading?: boolean;
  onConfirm: (property: any) => void;
  onCancel?: () => void;
  onClose: () => void;
}

export const MultiplePropertySourcesPopup: React.FC<Props> = ({
  open,
  isLoading,
  onConfirm,
  onCancel,
  onClose,
  properties,
}) => {
  const [selected, setSelected] = useState({});

  const onPropertySelect = (id: string, value: any) => {
    setSelected(prev => ({ ...prev, [id]: value }));
  };

  const onContinue = () => {
    onConfirm(Object.values(selected));
    setSelected({});
  };

  const hasSelections = React.useMemo(() => {
    const propertiesLength = Object.values(properties).length;
    const selectedLength = Object.values(selected).filter(s => !!s).length;

    return propertiesLength === selectedLength && selectedLength > 0;
  }, [selected]);

  return (
    <RootModal open={open} onClose={onClose} styles={{ backdrop: { zIndex: 999999 } }}>
      <S.Container>
        <S.Content>
          <S.Header>
            <S.Icon src={alert_icon} alt='alert icon' />
            <S.Headline color='400'>Multiple Matches</S.Headline>
            <S.Description variant='body1' align='center' color='400'>
              There are multiple properties that match this MLS ID.
              <br />
              Please select the one that you'd like to add.
            </S.Description>
          </S.Header>

          <S.Main>
            {Object.keys(properties).map(propertyId => (
              <DuplicatesList
                id={propertyId}
                selected={selected}
                onChange={onPropertySelect}
                properties={properties[propertyId]}
              />
            ))}
          </S.Main>
        </S.Content>

        <S.Footer>
          {onCancel && (
            <Button
              variant='danger'
              disabled={isLoading}
              onClick={() => {
                onCancel();
                setSelected({});
              }}
            >
              Cancel & Remove
            </Button>
          )}

          <Button variant='primary' disabled={isLoading || !hasSelections} onClick={onContinue}>
            Continue with Selected
          </Button>
        </S.Footer>
      </S.Container>
    </RootModal>
  );
};
