import { useEffect } from 'react';
import { connect } from 'react-redux';

import { Pills } from './Pills';
import classes from './AdrSection.module.scss';
import { SearchCombiner } from '../../SearchCombiner';
import { LoadingOverlay } from './ManualReportMap/LoadingOverlay';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';
import { useExtendBuildPropertiesWithAdjusted } from '../useExtendBuildPropertiesWithAdjusted';
import { InteractiveMap } from '../../../../../../../../../features/report/components/InteractiveMap/InteractiveMap';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { radiusAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/radiusAtom';
import { initRadiusAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/initRadiusAtom';
import { mapCoordinatesAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/mapCoordinatesAtom';
import { initMapCoordinatesAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/initMapCoordinatesAtom';
import { areaDetailsAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/areaDetailsAtom';
import { mapModeAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/mapModeAtom';
import { initMapModeAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/initMapModeAtom';
import { areaTypeAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/areaTypeAtom';
import { initAreaDetailsAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/initAreaDetailsAtom';
import { initAreaTypeAtom } from '../../../../../../../../../features/report/components/InteractiveMap/state/initAreaTypeAtom';

const deepCopy = value => JSON.parse(JSON.stringify(value));

const AdrSectionComponent = ({
  coordinates,
  searchCriteria,
  searchData,
  partialLoading,
  partialData,
  onChange,
  exclusions,
  setExclusions,
  additional,
  setAdditional,
  getAdditional,
  getExclusions,
  excludeComp,
  addComp,
  setIsModalOpen,
}) => {
  const { isCanadaUser } = useUser();
  const unit = isCanadaUser ? ' km' : 'Mile';

  const [mapMode, setMapMode] = useAtom(mapModeAtom);
  const setInitMapMode = useSetAtom(initMapModeAtom);
  const [radius, setRadius] = useAtom(radiusAtom);
  const setInitRadius = useSetAtom(initRadiusAtom);
  const [mapCoordinates, setMapCoordinates] = useAtom(mapCoordinatesAtom);
  const setInitMapCoordinates = useSetAtom(initMapCoordinatesAtom);
  const setInitAreaDetails = useSetAtom(initAreaDetailsAtom);
  const [areaDetails, setAreaDetails] = useAtom(areaDetailsAtom);
  const setInitAreaType = useSetAtom(initAreaTypeAtom);
  const [areaType, setAreaType] = useAtom(areaTypeAtom);

  const properties = useExtendBuildPropertiesWithAdjusted(partialData.properties);

  const getMapCoordinates = () => {
    if (searchCriteria.mapCoordinates) {
      return deepCopy(searchCriteria.mapCoordinates);
    }

    return [
      {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      },
    ];
  };

  const getRadius = () => {
    if (searchCriteria.radius) {
      const radius = deepCopy(searchCriteria.radius);

      const newRadius = {
        label: `Within ${radius.value} ${unit} Radius`,
        value: radius.value,
      };

      return newRadius;
    }
    // this is where it takes the init value for radius from.
    return { label: `Within 1 ${unit} Radius`, value: 1 };
  };

  const getMapMode = () => {
    if (!searchCriteria.mapMode || searchCriteria.mapMode === 'marker') return null;

    return searchCriteria.mapMode;
  };

  useEffect(() => {
    setMapCoordinates(getMapCoordinates());
    setInitMapCoordinates(getMapCoordinates());
    setRadius(getRadius());
    setInitRadius(getRadius());
    setMapMode(getMapMode());
    setInitMapMode(getMapMode());
    setExclusions(getExclusions('exclusions'));
    setAdditional(getAdditional('additional'));
    setAreaDetails(searchCriteria.areaDetails);
    setInitAreaDetails(searchCriteria.areaDetails);
    setAreaType(searchCriteria.areaType);
    setInitAreaType(searchCriteria.areaType);
  }, []);

  const onCombine = () => ({
    coordinates,
    mapCoordinates,
    radius,
    mapMode,
    additional,
    exclusions,
    areaDetails,
    areaType,
  });

  useEffect(() => {
    SearchCombiner.add('map', onCombine);
    return () => SearchCombiner.remove('map', onCombine);
  });

  useEffect(() => {
    onChange(SearchCombiner.combine());
  }, [mapCoordinates, radius, exclusions, additional, areaDetails, areaType]);

  const defaultCenter = {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  };

  return (
    <div className={classes.adrSection}>
      {coordinates.latitude && (
        <>
          {searchData && <Pills searchData={searchData} />}
          <LoadingOverlay loading={partialLoading}>
            <div>
              <InteractiveMap
                defaultCenter={defaultCenter}
                subject={partialData?.subject}
                properties={properties}
                currentRadius={
                  (mapMode === 'marker' || mapMode == null) && radius ? radius.value : 1
                }
                onAdd={addComp}
                onExclude={excludeComp}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </LoadingOverlay>
        </>
      )}
    </div>
  );
};

const stateToProps = ({ search }) => {
  const { coordinates } = search.searchCriteria;

  return {
    searchCriteria: search.searchCriteria,
    coordinates,
  };
};
export const AdrSection = connect(stateToProps)(AdrSectionComponent);
