import React, { useMemo } from 'react';
import { useSelectedSlide } from '../../../../../../../providers/providers/SelectedSlideProvider';
import { TSection } from '../../../../../../../types';
import { FlexWrapper, InputWrapper, Label } from './styles';
import { useSections } from '../../../../../../../providers/providers/SectionsProvider';
import { useOrder } from '../../../../../../../providers/providers/OrderProvider';
import { Select } from '../../../../../../../components/Common';

interface Props {
  value: TSection;
  onChange: (val: TSection) => void;
}

export const SlideSectionSelector: React.FC<Props> = ({ value, onChange }) => {
  const { sections } = useSections();
  const { sections: sectionsOrder } = useOrder();

  const { selectedSlide } = useSelectedSlide();

  const isPdfPresentationSlide = selectedSlide?.template === 'pdfPresentationTemplate';

  const onChangeInner = React.useCallback(
    function onChangeInner(val: string) {
      onChange(val as TSection);
    },
    [onChange],
  );

  const sectionsList = React.useMemo(() => {
    if (isPdfPresentationSlide) {
      const startingSlideSection = sections.startingSlides;
      return [startingSlideSection];
    }

    return sectionsOrder
      .filter(sectionId => sectionId !== 'startingSlides')
      .map(sectionId => sections[sectionId]);
  }, [isPdfPresentationSlide, sections, sectionsOrder]);

  const categoryOptions = React.useMemo(
    () =>
      sectionsList.map(section => {
        return {
          label: section.label,
          value: section.id,
        };
      }),
    [sectionsList],
  );

  const selectedOption = useMemo(() => {
    return categoryOptions?.find(section => section.value === value);
  }, [categoryOptions, value]);

  return (
    <FlexWrapper>
      <Label>Add to category</Label>
      <InputWrapper>
        <Select
          selectedOption={selectedOption}
          options={categoryOptions}
          onChange={(option: any) => onChangeInner(option?.value)}
        />
      </InputWrapper>
    </FlexWrapper>
  );
};
