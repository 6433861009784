import { RuntimeEnvironment } from '../../types';

export class Environment {
  static getEnv(): RuntimeEnvironment {
    return import.meta.env.VITE_APP_ENV as RuntimeEnvironment;
  }

  static isDevelopment() {
    return import.meta.env.VITE_APP_ENV === 'development';
  }

  static isStaging() {
    return import.meta.env.VITE_APP_ENV === 'staging';
  }

  static isProduction() {
    return import.meta.env.VITE_APP_ENV === 'production';
  }
}
