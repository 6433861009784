import React, { useContext, useState } from 'react';
import { PRESENTATION_TYPES } from '../../constants/PresentationTypes';
import { InheritanceLevel, TPresentationMeta, TPresentationType } from '../../types';

type TPresentationMetaContext = {
  meta: TPresentationMeta;
  inheritanceLevel: InheritanceLevel;
  setInheritanceLevel: (value: InheritanceLevel) => void;
};
const PresentationMetaContext = React.createContext<TPresentationMetaContext | undefined>(
  undefined,
);

export const usePresentationMeta = () => {
  const context = useContext(PresentationMetaContext);

  if (!context) {
    throw new Error('usePresentationMeta cannot be used without PresentationMetaProvider');
  }

  return context;
};

export const PresentationMetaProvider: React.FC = ({ children }) => {
  const [inheritanceLevel, setInheritanceLevel] = useState<InheritanceLevel>(
    InheritanceLevel.AGENT,
  );

  const value = React.useMemo(
    () => ({
      inheritanceLevel,
      setInheritanceLevel,
      meta: {
        inheritanceLevel,
      },
    }),
    [inheritanceLevel],
  );

  return (
    <PresentationMetaContext.Provider value={value}>{children}</PresentationMetaContext.Provider>
  );
};
