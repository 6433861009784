import { TDynamicSlideDto } from '../api/DynamicSlidesApi';
import { TSlide } from '../types';

export class DynamicSlidesFilter {
  static byTags(dynamicSlides: TSlide[], selectedTagsIds: string[]) {
    return dynamicSlides?.filter(slide => {
      const hasTags = selectedTagsIds.every(tagId => slide?.tags?.find(tag => tag.id === tagId));

      return hasTags;
    });
  }
}
