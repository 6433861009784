class SearchCombinerImp {
  #searchCriteriaBuilders = {};

  list() {
    return this.#searchCriteriaBuilders;
  }

  clear() {
    this.#searchCriteriaBuilders = {};
  }

  add(key, criteriaBuilder) {
    if (typeof criteriaBuilder !== 'function' || typeof key !== 'string') {
      return null;
    }

    this.#searchCriteriaBuilders[key] = criteriaBuilder;
  }

  remove(key) {
    if (!this.#searchCriteriaBuilders?.[key]) {
      return null;
    }

    delete this.#searchCriteriaBuilders[key];
  }

  combine() {
    return Object.values(this.#searchCriteriaBuilders)
      .map(builder => builder())
      .reduce(
        (searchCriteriaModel, criteria) => ({
          ...searchCriteriaModel,
          ...criteria,
        }),
        {},
      );
  }
}

export const SearchCombiner = new SearchCombinerImp();
