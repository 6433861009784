import styled from 'styled-components';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';

type WithWeb = {
  isWeb?: boolean;
};

export const LeftCol = styled('div')<WithWeb>(({ isWeb }) => ({
  height: isWeb ? '100%' : 580,
  marginRight: isWeb ? 10 : 0,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: isWeb ? 0 : 10,
  borderRadius: isWeb ? 5 : 10,
  marginTop: isWeb ? 0 : 10,
  justifyContent: isWeb ? 'flex-start' : 'center',
  border: isWeb ? 'none' : '1px solid #dedede',
}));

export const BuyerGraphWrapper = styled('div')<WithWeb>(({ isWeb }) => ({
  borderRadius: 5,
  border: isWeb ? '1px solid #dedede' : 'none',
  marginTop: isWeb ? 10 : 0,
  flex: isWeb ? 1 : 0,
}));

export const RightCol = styled('div')({
  width: '22%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #DEDEDE',
  borderRadius: 5,
});

export const TitleSection = styled('div')(({ theme }) => ({
  background: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
  borderRadius: 5,
  padding: 17,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: 90,
}));

export const Title = styled('h3')(({ theme }) => ({
  lineHeight: '23px',
  fontWeight: 900,
  fontSize: 20,
  textTransform: 'uppercase',
  color: useUiConfigColorsPrimary(),
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const SubTitle = styled('p')(() => ({
  fontWeight: 300,
  fontSize: 18,
  lineHeight: '32px',
  color: '#000000',
  marginTop: 5,
}));
