export const text = `With $brand, I will automate the social media presence with custom curated content posted directly to my social media channels including Facebook, Twitter, and LinkedIn Accounts. I'll choose the frequency of posting and content categories to get your listing seen and $brand does the rest!`;

export const listItems = [
  'Articles: Curated for maximum engagement',
  'Marketplace Listings: Shared from within my brokerage',
  'Holiday Greetings: Religious and non-religious, U.S. and Canada',
  'Calendar & Scheduler: Preview, edit and swap out content',
  'Custom Posts: Schedule and share my own content',
  'Lead Generation: Banners on every article and Marketplace Listing',
];

export const heading = 'What’s included:';
