import { LastOpenedProps } from './types';

import { Card } from './Card';
import { LargeText, Row, SmallText } from './Cards.styles';

import { getDateDiffDays } from '../../../../../../../PresentationCreate/dash/services/getDateDiffDays';

export function LastOpenedCard({ lastOpenedDate }: LastOpenedProps) {
  const days = getDateDiffDays(new Date(), new Date(lastOpenedDate));
  return (
    <Card title='Last opened'>
      <SmallText>
        {new Date(lastOpenedDate).toLocaleDateString('en-US', {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        })}
      </SmallText>
      <Row>
        <LargeText>
          {days}
          <SmallText style={{ fontWeight: 500, textTransform: 'lowercase', marginLeft: 3 }}>
            days ago
          </SmallText>
        </LargeText>
      </Row>
    </Card>
  );
}
