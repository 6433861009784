import { useSetAtom } from 'jotai';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { BuildCMAActions } from './BuildCMAActions';
import { useRebuildUtils } from '../../hooks/useRebuildUtils';
import { FullScreenLoader } from '../../components/Loader/FullScreenLoader';
import { withLoadedUser } from '../../hoc/withLoadedUser';
import { Authenticated } from './dash/view/pages/Authenticated/Authenticated';
import { UiConfigProvider } from '../../providers/providers/UiConfigProvider';
import { usePresentationCreationQuery } from '../../hooks/usePresentationCreationQuery';
import { PresentationBuildingLayout } from '../../layouts/PresentationBuildingLayout/PresentationBuildingLayout';
import { PresentationBuildFlowProvider } from './dash/ReportFacade/providers/PresentationBuildFlowProvider';
import { isPresentationViewAtom } from '../../features/report/state/isPresentationViewAtom';

export const PresentationCreate: React.FC = withLoadedUser(() => {
  const { fetchPresentationAndSetToStore } = useRebuildUtils();
  const { type, isRebuild, presentation } = usePresentationCreationQuery();
  const setIsPresentationView = useSetAtom(isPresentationViewAtom);

  const [isFetchingRebuild, setIsFetchingRebuild] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsPresentationView(true);
  }, [setIsPresentationView]);

  useEffect(() => {
    (async () => {
      if (isRebuild && presentation) {
        await fetchPresentationAndSetToStore(presentation, type);
      }

      setIsFetchingRebuild(false);
    })();
  }, [fetchPresentationAndSetToStore, isRebuild, presentation, type]);

  if (isFetchingRebuild) {
    return <FullScreenLoader />;
  }

  return (
    <PresentationBuildFlowProvider>
      <UiConfigProvider>
        <PresentationBuildingLayout HeaderActions={<BuildCMAActions />} isScrollable>
          <Wrapper>
            <Authenticated />
          </Wrapper>
        </PresentationBuildingLayout>
      </UiConfigProvider>
    </PresentationBuildFlowProvider>
  );
});

const Wrapper = styled.div`
  padding: 30px;
  min-height: calc(100vh - 70px);
  background: ${({ theme }) => theme.colors.v2.gradient.background};
`;
