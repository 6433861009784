import { useMutation } from '@tanstack/react-query';

import { corePresentApi } from '../../../../../../api/CorePresentApi';
import { useInvalidatePresentation } from '../../../../../../hooks/usePresentation';
import { usePresentationCreationQuery } from '../../../../../../hooks/usePresentationCreationQuery';

type NotesPayload = {
  comparablePropertyId: string;
  note: string;
};

async function updateNotes({ comparablePropertyId, note }: NotesPayload) {
  return corePresentApi.put(`/comparable-property/${comparablePropertyId}/notes`, {
    note,
  });
}

export function useNotesUpdate() {
  const { presentation: presentationHash } = usePresentationCreationQuery();
  const invalidatePresentation = useInvalidatePresentation(presentationHash);

  return useMutation(updateNotes, {
    onSuccess: invalidatePresentation,
  });
}
