import styled from 'styled-components';
import { Typography } from '../../../Common';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../services/ColorOpacityApplier';
export { Typography };

export const Container = styled('div')(() => ({
  borderRadius: 5,
  padding: '5% 10%',
  border: '1.5px solid #F2F8F8',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1'),
  '&:first-of-type': {
    backgroundColor: '#fff',
    borderColor: useUiConfigColorsPrimary(),
  },
}));
