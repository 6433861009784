import React, { Dispatch, SetStateAction } from 'react';
import { TextEditor } from '../../../../../../../../../../components/Common/TextEditor/TextEditor.component';

import {
  TestimonialEditWrapper,
  TestimonialEditInfo,
  TestimonialEditTitle,
  TestimonialEditNote,
  Input,
  Wrapper,
} from './TestimonialEdit.styles';
import { Button } from '../../../../../../../../../../components/Common/V2/Button/Button';

interface TestimonialEditProps {
  id: string;
  title: string;
  note: string;
  values?: {
    text: string;
    client: string;
    isHidden: boolean;
  };
  onChange: (id: string, key: 'text' | 'client', value: string) => void;
  onToggle: (id: string, value: boolean) => void;
  isResynced?: boolean;
  setIsResynced?: Dispatch<SetStateAction<boolean>>;
}

export const TestimonialEdit: React.FC<TestimonialEditProps> = ({
  id,
  title,
  note,
  values,
  onChange,
  onToggle,
  isResynced,
  setIsResynced,
}) => {
  return (
    <TestimonialEditWrapper>
      <TestimonialEditInfo style={values?.isHidden ? { marginBottom: 0 } : {}}>
        <TestimonialEditTitle>{title}</TestimonialEditTitle>
        <Wrapper>
          {!values?.isHidden && <TestimonialEditNote>{note}</TestimonialEditNote>}
          <Button onClick={() => onToggle(id, !values?.isHidden)}>
            {values?.isHidden ? 'Show' : 'Hide'}
          </Button>
        </Wrapper>
      </TestimonialEditInfo>
      {!values?.isHidden && (
        <>
          <TextEditor
            onChangeText={value => onChange(id, 'text', value)}
            rawHtml={values?.text ?? ''}
            editorWrapperStyles={{ height: 69, marginBottom: 10 }}
            toolbarOptions={['inline', 'list', 'fontSize']}
            isRefresh={isResynced}
            setIsRefresh={setIsResynced}
          />
          <Input
            type='text'
            placeholder='Client Name'
            value={values?.client ?? ''}
            onChange={({ target }) => onChange(id, 'client', target.value)}
            inputWrapperStyle={{ width: '100%' }}
          />
        </>
      )}
    </TestimonialEditWrapper>
  );
};
