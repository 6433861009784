import React, { useState } from 'react';

import { Icon } from '../Icon/Icon.component';
import { IconComponentProps } from '../Icon/Icon.types';
import { ButtonsContainer, Button } from './RadioButtons.style';

export interface RadioButtonsOption {
  value: string | number;
  label: string;
  icon?: IconComponentProps;
}

interface RadioButtonsProps {
  options: RadioButtonsOption[];
  onChange: (value: string) => void;
  defaultSelected: RadioButtonsOption['value'];
  buttonStyle?: React.CSSProperties;
}

export const RadioButtons: React.FC<RadioButtonsProps> = ({
  options,
  defaultSelected,
  buttonStyle,
  onChange,
}) => {
  const [activeButton, setActiveButton] = useState<RadioButtonsOption['value']>(defaultSelected);

  const onToggle = (option: RadioButtonsOption) => {
    const { value } = option;

    setActiveButton(value);
    onChange(value.toString());
  };

  return (
    <ButtonsContainer>
      {options.map((option, i) => {
        const { value, label } = option;
        return (
          <Button
            isActive={activeButton === value}
            onClick={() => onToggle(option)}
            key={i}
            numberOfItems={options.length}
            style={buttonStyle}
          >
            {option.icon && <Icon {...option.icon} style={{ marginRight: 6 }} />} {label}
          </Button>
        );
      })}
    </ButtonsContainer>
  );
};
