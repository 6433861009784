import { CompAverages, ReportCompAverages } from '../../../../../types';

type Params = {
  id: keyof ReportCompAverages;
  compAverages: CompAverages | null;
  useSqftPrice: boolean;
};

export const getAveragesAdjustedPrice = ({ id, compAverages, useSqftPrice }: Params) => {
  return useSqftPrice
    ? compAverages?.adjusted?.[(id + '_per_sqft') as keyof ReportCompAverages]
    : compAverages?.adjusted?.[id];
};
