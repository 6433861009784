import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, Fragment, SetStateAction, Dispatch } from 'react';
import { useTheme } from 'styled-components';
import { Button } from '../../../../../../../../../../components/Common/V2/Button/Button';
import { toBase64 } from '../../../../../../../../../../services/toBase64';
import { ImageWrapper, Image, CloseIcon, FilePicker } from './ExpandablesLogos.styles';

interface LogoPickerComponentProps {
  initImgSrc?: string;
  setFile: Dispatch<SetStateAction<File | null>>;
  file: null | File;
  btnText: string;
  isDisabled?: boolean;
  isDisabledAdding?: boolean;
}

export const LogoPickerComponent: React.FC<LogoPickerComponentProps> = ({
  initImgSrc,
  file,
  btnText,
  setFile,
  isDisabled,
  isDisabledAdding,
}) => {
  const [imgSrc, setImgSrc] = useState(initImgSrc ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const onClose = () => {
    if (isDisabled) return;
    setImgSrc('');
    setFile(null);
  };

  useEffect(() => {
    const handleFile = async () => {
      if (!file) return;
      try {
        setIsLoading(true);
        const base64 = await toBase64(file);
        if (base64) setImgSrc(base64);
      } catch (err) {
        setImgSrc('');
        setIsLoading(false);
        setFile(null);
        console.error('Something went wrong');
      }
    };

    handleFile();
  }, [file, setFile]);

  return (
    <>
      {!imgSrc ? (
        <FilePicker setFile={setFile} accept='image/*' isDisabled={isDisabled || isDisabledAdding}>
          <Button
            style={{
              width: '100%',
            }}
            variant='secondary'
            disabled={isDisabled || isDisabledAdding}
            prefixIcon={
              <FontAwesomeIcon
                icon={'circle-plus'}
                fontSize={'12px'}
                color={theme.colors.v2.text.regular}
              />
            }
          >
            {btnText}
          </Button>
        </FilePicker>
      ) : (
        <ImageWrapper>
          {isLoading && !imgSrc ? 'isLoading' : <Image src={imgSrc} />}
          {!isDisabled && <CloseIcon iconName='close_filled' onClick={onClose} fill={'#666'} />}
        </ImageWrapper>
      )}
    </>
  );
};
