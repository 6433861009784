import styled, { CSSProperties } from 'styled-components';

interface Props {
  label: string;
  value: string;
  valueStyles?: CSSProperties;
}

export const DetailsItem = ({ label, value, valueStyles }: Props) => {
  return (
    <Container>
      <Label>{label}:</Label>
      <Value style={valueStyles}>{value}</Value>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 2px;
`;

const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-transform: uppercase;
`;

const Value = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: #010101;
`;
