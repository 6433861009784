import React from 'react';
import { useUser } from '../components/Slide/providers/UserProvider';
import { useFeatureFlags } from '../hooks/useFeatureFlags';

export const withLoadedUser = <T extends object>(WrappedComponent: React.ComponentType<T>) => {
  return function WithLoadedUserWrapper(props: T) {
    const user = useUser();

    const { isLoading: isLoadingFF } = useFeatureFlags();

    if (!user || isLoadingFF) return null;

    return <WrappedComponent {...props} />;
  };
};
