import styled from 'styled-components';
import { HeaderCard } from '../components/HeaderCard';
import { Button } from '../../../../components/Common/V2/Button/Button';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { URLS } from '../../../../pages/PresentationCreate/dash/view/constants/URLS';
import { CriteriaReviewItem } from './CriteriaReviewItem';
import AreaSizeIcon from './assets/AreaSizeIcon.svg';
import BedroomIcon from './assets/BedroomIcon.svg';

import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { searchAPIAtom } from '../../state/searchAPIAtom';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';

export const ComparableCriteriaReview = () => {
  const history = useHistory();
  const { isCanadaUser } = useUser();
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  const searchApi = useAtomValue(searchAPIAtom);

  const onEdit = () => {
    const params = new URLSearchParams(history.location.search);

    const p = params.toString();

    const URL = `${URLS.SEARCH.DETAILS}${!p.length ? '' : '?' + p}`;

    history.push(URL, {
      from: history.location.pathname + history.location.search,
    });
  };

  const size = useMemo(() => {
    if (!searchApi) return '';
    return `${NumberFormatter.format(searchApi?.size_from)} - ${NumberFormatter.format(
      searchApi?.size_to,
    )}`;
  }, [searchApi]);

  const beds = useMemo(() => {
    if (!searchApi) return '';
    return searchApi?.bed_from === searchApi?.bed_to
      ? `${searchApi?.bed_to}`
      : `${searchApi?.bed_from} - ${searchApi?.bed_to}`;
  }, [searchApi]);

  const baths = useMemo(() => {
    if (!searchApi) return '';

    const totalBathsToWithPlus =
      searchApi?.total_baths_to === 10 ? '10+' : searchApi?.total_baths_to;

    return searchApi?.total_baths_from === searchApi?.total_baths_to
      ? `${searchApi?.total_baths_to}`
      : `${searchApi?.total_baths_from} - ${totalBathsToWithPlus}`;
  }, [searchApi]);

  return (
    <HeaderCard title='Comparable Criteria Review'>
      <Container>
        <DescriptionContainer>
          <Description>
            To be considered a “comparable result” for the analysis. Properties must, at a minimum,
            match the following criteria:
          </Description>
          <Button variant='primary' onClick={onEdit}>
            Edit Search Criteria
          </Button>
        </DescriptionContainer>
        <CompCriteriaCardsWrapper hasBathCountFF={hasBathCountFF} isCanadaUser={isCanadaUser}>
          {!isCanadaUser && (
            <CriteriaReviewItem
              title='Living Area Size'
              description={size}
              icon={<AreaSizeIcon />}
            />
          )}
          <CriteriaReviewItem
            title={hasBathCountFF ? 'Beds' : 'Bedroom Count'}
            description={beds}
            icon={<BedroomIcon />}
          />
          {hasBathCountFF && <CriteriaReviewItem title='Total Baths' description={baths} />}
        </CompCriteriaCardsWrapper>
      </Container>
    </HeaderCard>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;

  @media all and (max-width: 1199px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 53%;

  @media all and (max-width: 1199px) {
    width: 100%;
  }
`;

const Description = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};
  margin-bottom: 10px;
  text-align: center;
  padding: 0 5px;
`;

const CompCriteriaCardsWrapper = styled.div<{ hasBathCountFF: boolean; isCanadaUser: boolean }>`
  width: 166px;

  ${({ hasBathCountFF, isCanadaUser, theme }) =>
    hasBathCountFF
      ? `
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 1px;

    & > :nth-child(1) {
      grid-column: span 2;
    }

    & > :nth-child(2) {
      grid-column: ${isCanadaUser ? 'span 2' : '1'};
    }

    & > :nth-child(3) {
      grid-column: 2;
    }
  `
      : `
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.v2.gray[100]};
    border-radius: 5px;
  `}
`;
