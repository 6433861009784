import React from 'react';
import styled from 'styled-components';

import { Typography } from '..';
import { RootModal } from '../RootModal/RootModal';
import alert_icon from './alert_icon.jpg';
import { Button } from '../V2/Button/Button';

const Container = styled('div')(({ theme }) => ({
  width: 500,
  height: 350,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.325294)',
  borderRadius: 8,
  backgroundColor: '#fff',
}));

const Icon = styled('img')({
  marginBottom: 36,
});

const ActionButtonsWrapper = styled('div')({ display: 'flex', justifyContent: 'center' });

interface Props {
  open: boolean;
  heading: string;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose: () => void;
}

const WarningConfirmPopup: React.FC<Props> = ({
  open,
  heading,
  description,
  confirmButtonText = 'Yes, continue',
  cancelButtonText = 'No, go back',
  isLoading,
  onConfirm,
  onCancel,
  onClose,
}) => {
  return (
    <RootModal open={open} onClose={onClose} styles={{ backdrop: { zIndex: 2000 } }}>
      <Container>
        <Icon src={alert_icon} alt='alert icon' />
        <Typography
          style={{ fontSize: 36, fontWeight: 700, lineHeight: 1.4 }}
          align='center'
          color='400'
        >
          {heading}
        </Typography>
        {description && (
          <Typography
            variant='body1'
            style={{ width: 418, margin: '10px 0 25px 0', lineHeight: 1.28 }}
            align='center'
            color='400'
          >
            {description}
          </Typography>
        )}
        <ActionButtonsWrapper>
          <Button
            variant='primary'
            size='large'
            style={{ width: 190, marginRight: onCancel ? 36 : 0, justifyContent: 'center' }}
            disabled={isLoading}
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
          {onCancel && (
            <Button
              variant='danger'
              size='large'
              style={{ width: 190, justifyContent: 'center' }}
              disabled={isLoading}
              onClick={onCancel}
            >
              {cancelButtonText}
            </Button>
          )}
        </ActionButtonsWrapper>
      </Container>
    </RootModal>
  );
};

export default WarningConfirmPopup;
