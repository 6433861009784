import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

export const TextAlpha: React.FC = () => {
  const slide = useSlide();

  return (
    <SlideTextBlock
      isEditable={false}
      text={slide.data?.text}
      headline={slide.data?.headline}
      containerStyles={{ padding: '2%' }}
    />
  );
};
