import { CSSProperties } from 'react';

import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { TooltipPayloadProps } from '../../../../types';
import { Adjusted, PriceWrapper, TooltipWrapper } from '../components/UI';

interface PricingRateOfChangeTooltipProps {
  active: boolean;
  payload: TooltipPayloadProps[];
  label: string;
}

export const PricingRateOfChangeTooltip = ({
  active,
  payload,
  label,
}: PricingRateOfChangeTooltipProps) => {
  if (!active) {
    return null;
  }

  const listed = payload?.[0]?.value ?? 0;
  const closed = payload?.[1]?.value ?? 0;
  const adjustedSalePrice = payload?.[0]?.payload?.adjusted_sale_price
    ? payload[0].payload.adjusted_sale_price
    : null;

  const diff = listed ? (closed / listed) * 100 - 100 : 0;
  const adjustedDiff = adjustedSalePrice ? (adjustedSalePrice / listed) * 100 - 100 : null;

  const style: CSSProperties = {};

  if (payload?.[0]?.color) {
    style.color = payload[0].color;
  }

  return (
    <TooltipWrapper>
      <p>{label}</p>
      <p>Sale Date: {payload?.[0]?.payload.sale_date}</p>

      <PriceWrapper>
        <p style={style}>{`List Price: ${MoneyFormatter.format(listed)}`}</p>
      </PriceWrapper>

      <PriceWrapper>
        <p
          style={{
            color: payload?.[1]?.color,
          }}
        >{`Closed Price: ${MoneyFormatter.format(closed)}`}</p>
        {Boolean(adjustedSalePrice) && (
          <Adjusted>Closed ADJ Price: {MoneyFormatter.format(adjustedSalePrice)}</Adjusted>
        )}
      </PriceWrapper>

      <PriceWrapper>
        <p>Difference: {Math.round(diff * 100) / 100}%</p>
        {adjustedDiff && (
          <Adjusted>ADJ Difference: {Math.round(adjustedDiff * 100) / 100}%</Adjusted>
        )}
      </PriceWrapper>
    </TooltipWrapper>
  );
};
