import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import states from '../../../../../../data/states';
import styles from './PropertyInfoForm.module.scss';
import { Footer } from '../../components/Footer/Footer';
import * as searchOperations from '../../../../../../store/search/operations';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';
import { AddressCommunicator } from '../../../../../../communicators/Address/AddressCommunicator';
import { PropertyCommunicator } from '../../../../../../communicators/Property/PropertyCommunicator';
import { Input } from '../../../../../../../../../components/Common/V2/Input';
import { Select } from '../../../../../../../../../components/Common';
import { Autocomplete } from '../../../../../../../../../components/Common/Autocomplete/Autocomplete';

const statesList = states.map(({ name, abbreviation }) => {
  return { value: abbreviation, label: name };
});

export const PropertyInfoFormComponent = ({
  storeAddress,
  onNoAddress,
  onContinue,
  disableFooter,
  setClients,
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [address, setAddress] = useState('');
  const [external, setExternal] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);

  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const location = useLocation();

  useEffect(() => {
    setAddress(storeAddress.address || '');
    setNumber(storeAddress.number || '');
    setCity(storeAddress.city || '');
    setState(storeAddress.state || '');
  }, [storeAddress]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const addressToSet = query.get('address') === 'null' ? '' : query.get('address');
    if (addressToSet && addressToSet.includes('#')) {
      const split = addressToSet.split('#');
      setAddress(split[0]?.trim());
      setNumber(split[1]?.trim());
    } else {
      setAddress(addressToSet);
      setNumber(query.get('number') === 'null' ? '' : query.get('number'));
    }
    setCity(query.get('city') === 'null' ? '' : query.get('city'));
    setState(statesList.find(({ value }) => value === query.get('state')) || '');
  }, []);

  const { isCanadaUser } = useUser();

  const getAddressOptions = async address => {
    if (isCanadaUser) {
      return [];
    }
    const addresses = await AddressCommunicator.search(address);

    const autocompleteAddresses = addresses.map(address => ({
      ...address,
      label: address.fullAddress,
      value: address.fullAddress,
    }));

    setAddressOptions(autocompleteAddresses);
    setErrors({});
  };

  const onAddressChange = addressQuery => {
    setAddress(addressQuery);

    const addressOption = addressOptions.find(({ value }) => value === addressQuery);
    if (!addressOption) {
      return;
    }

    setAddress(addressOption.deliveryLine);
    setCity(addressOption.city);
    setExternal(addressOption.external);
    setState(statesList.find(({ value }) => value === addressOption.state) || '');
  };

  const onSubmit = async () => {
    setLoading(true);
    let searchCriteria = null;
    try {
      searchCriteria = isCanadaUser
        ? null
        : await PropertyCommunicator.search({
            deliveryLine: address,
            number,
            city,
            state: state.value,
            external: external,
          });
      setLoading(false);
      if (searchCriteria.coordinates.latitude == 0) {
        onNoAddress({
          address,
          number,
          city,
          state,
          size: searchCriteria?.size,
          beds: searchCriteria?.beds,
        });
        return;
      }
      onContinue({ address, number, city, state }, searchCriteria);
    } catch (e) {
      if (e.errors && Object.entries(e.errors).length > 0) {
        setErrors(e.errors);
        setLoading(false);
        return;
      }

      onNoAddress({ address, number, city, state });
    }
  };

  const onChange = (errorField, setFieldValue) => value => {
    delete errors[errorField];
    setErrors({ ...errors });
    setFieldValue(value);
  };

  return (
    <div>
      <h3 className={styles.title}>WHAT IS THE ADDRESS?</h3>

      <SectionWrapper>
        <GridWrapper>
          <Autocomplete
            label={isCanadaUser ? 'Address (Include City Name):' : 'Address:'}
            value={address}
            error={errors.address}
            onChange={onChange('address', onAddressChange)}
            asyncOptionsProvider={getAddressOptions}
            required={isCanadaUser}
            suggestions={addressOptions}
          />
          <Input
            label='Apt or Suite #:'
            value={number}
            inputWrapperStyle={{ width: '100%' }}
            inputStyle={{ width: '100%' }}
            onChange={e => onChange('apartment_number', setNumber)(e.target.value)}
          />
        </GridWrapper>

        <GridWrapper style={isCanadaUser ? { gridTemplateColumns: '1fr' } : {}}>
          {!isCanadaUser && (
            <Input
              label='City'
              value={city}
              error={errors.city}
              onChange={e => onChange('city', setCity)(e.target.value)}
            />
          )}
          {!isCanadaUser && (
            <div style={{ position: 'relative' }}>
              <Select
                label='State'
                defaultValue={state && [state]}
                options={statesList}
                onChange={onChange('state', setState)}
                placeholder='State'
                dataAttributes={{ 'data-test-state-select': true }}
              />
              {errors.state && <ErrorText>{errors.state}</ErrorText>}
            </div>
          )}
          {isCanadaUser && (
            <Input
              label='Province'
              value={city}
              error={errors.city}
              onChange={e => onChange('city', setCity)(e.target.value)}
            />
          )}
        </GridWrapper>
      </SectionWrapper>

      {!disableFooter && (
        <Footer
          hidePrev
          onNext={onSubmit}
          loadingNext={loading}
          disabledNext={loading}
          className={styles.footer}
        />
      )}
    </div>
  );
};

const stateToProps = ({ search }) => ({
  storeAddress: search.address,
});
const dispatchToProps = dispatch => ({
  setClients: clients => dispatch(searchOperations.setClients(clients)),
});

export const PropertyInfoForm = connect(stateToProps, dispatchToProps)(PropertyInfoFormComponent);

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -14px;
  left: 0px;
  font-size: 11px;
  color: ${props => props.theme.colors.v2.status.error};
`;
