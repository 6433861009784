import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChromePicker } from 'react-color';

import { Lock } from '../../Lock/Lock';
import { TSlideColors } from '../../../../../../../../../../types';
import { useCanUpdateLockStatus } from '../../Lock/useCanUpdateLockStatus';
import { useEntity } from '../../../../../../../../providers/EntityProvider';
import { getConfigLockedStatus } from '../../../services/getConfigLockedStatus';
import { RootModal } from '../../../../../../../../../../components/Common';
import { useUser } from '../../../../../../../../../../components/Slide/providers/UserProvider';
import { CustomizationSettingsExpandableCommon } from '../../../CustomizationModalStepOne.component';
import { useUiConfigColors } from '../../../../../../../../../../providers/providers/UiConfigColorProvider';
import { Accordion } from '../../../../../../../../../../components/Common/V2/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Input } from '../../../../../../../../../../components/Common/V2/Input';
import { InfoBanner } from '../InfoBanner';
import { getShouldShowInfoBanner } from '../../../services/getShouldShowInfoBanner';

type ExpandablesColorsComponentProps = CustomizationSettingsExpandableCommon;

export const ExpandablesColorsComponent: React.FC<ExpandablesColorsComponentProps> = ({
  open,
  onToggle,
}) => {
  const { entity } = useEntity();
  const { teamsPrimaryEntity, officePrimaryEntity, offices } = useUser();
  const { colors: initColors, setColors: setColorsFunc } = useUiConfigColors();

  const [colors, setColors] = useState<Record<string, any>>({
    primary: initColors.primary.value,
    secondary: initColors.secondary?.value ?? initColors.primary.value,
  });

  const isOfficeEntity = offices.some(office => office.id === entity);

  const initialSecondaryLockStatus = initColors.secondary
    ? getConfigLockedStatus(
        entity,
        officePrimaryEntity,
        teamsPrimaryEntity,
        isOfficeEntity,
        initColors.secondary.lockedStatus,
      )
    : false;

  const [locked, setLocked] = useState({
    primary: getConfigLockedStatus(
      entity,
      officePrimaryEntity,
      teamsPrimaryEntity,
      isOfficeEntity,
      initColors.primary.lockedStatus,
    ),
    secondary: initialSecondaryLockStatus,
  });

  const [isSecondaryLockChanged, setIsSecondaryLockChanged] = useState(!!initColors.secondary);

  const [pickColorFor, setPickColorFor] = useState<null | keyof TSlideColors>(null);
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const canUpdateLockPrimary = useCanUpdateLockStatus(
    officePrimaryEntity,
    initColors.primary.lockedStatus,
  );
  const canUpdateLockSecondary = useCanUpdateLockStatus(
    officePrimaryEntity,
    initColors.secondary?.lockedStatus,
  );

  const showBannerInfo = getShouldShowInfoBanner({
    canUpdateLock: canUpdateLockPrimary || canUpdateLockSecondary,
    isLocked: locked.primary || locked.secondary,
  });

  const getColorsConfig = useCallback(
    (prevConfig: any) => {
      if (colors.primary === colors.secondary && !initColors.secondary) {
        return {
          primary: {
            ...prevConfig.primary,
            value: colors.primary,
          },
        };
      }

      return {
        primary: {
          ...prevConfig.primary,
          value: colors.primary,
        },
        secondary: {
          ...prevConfig.secondary,
          value: colors.secondary,
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colors.primary, colors.secondary],
  );

  const getConfigWithLockStatus = useCallback(
    (config: any) => {
      const primaryLockStatus = {
        ...config.primary.lockedStatus,
        [`e-${entity}`]: entity === null ? undefined : locked.primary,
      };

      const secondaryLockStatus = {
        ...config.secondary?.lockedStatus,
        [`e-${entity}`]: entity === null ? undefined : locked.secondary,
      };

      if (isSecondaryLockChanged) {
        return {
          primary: {
            ...config.primary,
            lockedStatus: primaryLockStatus,
          },
          secondary: {
            ...config.secondary,
            lockedStatus: secondaryLockStatus,
          },
        };
      }

      return {
        primary: {
          ...config.primary,
          lockedStatus: primaryLockStatus,
        },
      };
    },
    [entity, isSecondaryLockChanged, locked.primary, locked.secondary],
  );

  useEffect(() => {
    setColorsFunc(prev => {
      const colorsConfig = getColorsConfig(prev);

      if (entity !== null) {
        return getConfigWithLockStatus(colorsConfig);
      }

      return colorsConfig;
    });
  }, [locked, colors, setColorsFunc, entity, getColorsConfig, getConfigWithLockStatus]);

  const addHashToValue = (value: string) => {
    if (value.includes('#')) {
      return value;
    }
    return `#${value}`;
  };

  const onColorChange = (field: keyof TSlideColors, value: string) => {
    if (field === 'primary' && !initColors.secondary) {
      setColors({
        primary: value,
        secondary: value,
      });
    } else {
      setColors(prev => ({ ...prev, [field]: addHashToValue(value) }));
    }
  };

  const onColorLockChange = (field: keyof TSlideColors) => {
    if (field === 'secondary') {
      setIsSecondaryLockChanged(true);
    }
    setLocked(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const onColorPickBoxClick = (
    e: React.MouseEvent<SVGSVGElement>,
    field: keyof TSlideColors,
  ): void => {
    setPickColorFor(field);
    setAnchorEl(e.currentTarget);
  };

  const onColorPickerClose = () => {
    setPickColorFor(null);
    setAnchorEl(null);
  };

  return (
    <Accordion
      title={'Colors'.toUpperCase()}
      prefixIcon={<FontAwesomeIcon icon={'palette'} />}
      open={open}
      onClick={() => onToggle('colors')}
    >
      {showBannerInfo && <InfoBanner />}
      <Container>
        <Row>
          <Text>Primary Color</Text>
          <PickerContainer>
            <Input
              type='text'
              name='primary'
              value={colors.primary}
              disabled={locked.primary}
              placeholder='#ffffff'
              leftIconName={'square'}
              onLeftIconClick={
                !locked.primary
                  ? (e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
                      onColorPickBoxClick(e, 'primary')
                  : undefined
              }
              leftIconStyles={{
                color: colors.primary,
              }}
              onChange={e => onColorChange('primary', e.target.value)}
            />
            <Lock
              onClick={() => onColorLockChange('primary')}
              isLocked={Boolean(locked.primary)}
              canLock={canUpdateLockPrimary}
            />
          </PickerContainer>
        </Row>
        <Row>
          <Text>Secondary Color</Text>
          <PickerContainer>
            <Input
              type='text'
              name='secondary'
              value={colors.secondary}
              disabled={locked.secondary}
              placeholder='#ffffff'
              leftIconName={'square'}
              onLeftIconClick={
                !locked.secondary
                  ? (e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
                      onColorPickBoxClick(e, 'secondary')
                  : undefined
              }
              leftIconStyles={{
                color: colors.secondary,
              }}
              onChange={e => onColorChange('secondary', e.target.value)}
            />
            <Lock
              onClick={() => onColorLockChange('secondary')}
              isLocked={Boolean(locked.secondary)}
              canLock={canUpdateLockSecondary}
            />
          </PickerContainer>
        </Row>
        {pickColorFor && anchorEl && (
          <RootModal
            open={Boolean(pickColorFor) && Boolean(anchorEl)}
            onClose={onColorPickerClose}
            anchorEl={anchorEl}
            styles={{ backdrop: { background: 'transparent' } }}
          >
            <ChromePicker
              color={colors[pickColorFor]}
              onChange={color => onColorChange(pickColorFor, color.hex)}
            />
          </RootModal>
        )}
      </Container>
    </Accordion>
  );
};

const Container = styled.div`
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  background: ${({ theme }) => theme.colors.v2.background};
  border-radius: 0 0 5px 5px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const Text = styled.p`
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const PickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
