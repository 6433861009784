import { StorageService } from '../services/storageService';

export const loadState = (): any => {
  // eslint-disable-line
  try {
    const serializedState = StorageService.read('state');
    return !serializedState ? undefined : serializedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  // eslint-disable-line
  try {
    StorageService.save('state', state);
  } catch {
    // ignore write errors
  }
};

export function persister({ getState }: any) {
  return (next: any) => (action: any) => {
    const returnValue = next(action);

    StorageService.save('state', getState());

    return returnValue;
  };
}
