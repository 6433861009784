import React from 'react';
import { UploadFileModal } from './UploadFileModal/UploadFileModal.component';

interface Props {
  open: boolean;
  onClose: () => void;
  webUploadInfoText?: string;
  pdfUploadInfoText?: string;
  initialSrcWeb?: string;
  initialSrcPdf?: string;
}

export const UploadSingleImageModal: React.FC<Props> = ({
  open,
  onClose,
  webUploadInfoText,
  pdfUploadInfoText,
  initialSrcWeb,
  initialSrcPdf,
}) => {
  return (
    <UploadFileModal
      open={open}
      onClose={onClose}
      webUploadInfoText={webUploadInfoText}
      pdfUploadInfoText={pdfUploadInfoText}
      webDataProperty='image'
      pdfDataProperty='image2'
      fileType='image'
      heading='Upload Image'
      initialSrcPdf={initialSrcPdf}
      initialSrcWeb={initialSrcWeb}
    />
  );
};
