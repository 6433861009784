import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Error } from './Error/Error';
import { SearchCombiner } from '../SearchCombiner';
import { SearchValidator } from '../SearchValidator';

import { PropertyMatchLegends } from './PropertyMatchLegends/PropertyMatchLegends';
import styles from './PropertyDetails.module.scss';
import { Footer } from '../components/Footer/Footer';
import { PropertyArea } from '../PropertyArea/PropertyArea';
import { ManualMLSList } from './ManualMLSList/ManualMLSList';
import { ManualSelector } from './ManualSelector/ManualSelector';
import { CriteriaIsOpenProvider } from './CriteriaIsOpenProvider';
import { AddressOverview } from './AddressOverview/AddressOverview';
import { SubjectPropertyInfo } from './SubjectPropertyInfo/SubjectPropertyInfo';
import { BedCount } from './PropertySearchCriteria/Criterias/BedCount/BedCount';
import { YearBuilt } from './PropertySearchCriteria/Criterias/YearBuilt/YearBuilt';
import { ClosedDate } from './PropertySearchCriteria/Criterias/ClosedDate/ClosedDate';
import { PropertyType } from './PropertySearchCriteria/Criterias/PropertyType/PropertyType';
import { TotalLotSize } from './PropertySearchCriteria/Criterias/TotalLotSize/TotalLotSize';
import { LivingAreaSize } from './PropertySearchCriteria/Criterias/LivingAreaSize/LivingAreaSize';
import { useUrlSearchParam } from '../../../../../../../../hooks/useQueryParams';
import { CurrentUserService } from '../../../../../../../../services/CurrentUserService';
import { useSetProperties } from '../../../../../../../../features/report/hooks/useSetProperties';
import { TotalBathCount } from './PropertySearchCriteria/Criterias/TotalBathCount/TotalBathCount';
import { FEATURES, useHasFeatureFlag } from '../../../../../../../../hooks/useFeatureFlags';

export const PropertyDetails = ({
  onContinue = f => f,
  onPartial = f => f,
  partialReset = f => f,
  partialLoading = false,
  partialData,
  onBack = f => f,
  className = '',
  loading,
  setLoading,
}) => {
  const initIsManual = useSelector(({ search }) => search?.searchCriteria?.reportType === 'manual');

  const [error] = useState('');
  const [combinedData, setCombinedData] = useState(null);
  const [isManual, setIsManual] = useState(initIsManual);

  const initialIds = useSelector(({ search }) => search?.searchCriteria?.manualMlsIds || []);
  const [mlsIds, setMlsIds] = useState(initialIds);
  const [errors, setErrors] = useState({});

  const subjectId = useSelector(({ search }) => search?.searchCriteria?.id);
  const isRebuild = useUrlSearchParam('action') === 'rebuild';

  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  const onCombine = () => ({ manualMlsIds: mlsIds });

  useSetProperties();

  useEffect(function onInit() {
    setLoading(false);
    SearchValidator.clear();
    SearchCombiner.clear();
  }, []);

  useEffect(
    function onCriteriaChange() {
      const isEmpty = !Object.keys(combinedData ? combinedData : {}).length;

      if (!combinedData || isEmpty) {
        return; //not loaded
      }

      onPartial(SearchCombiner.combine());
    },
    [combinedData],
  );

  useEffect(
    function onSwitchToManual() {
      if (isManual) partialReset();
      if (isManual && mlsIds.length) {
        onManualMlsChanged(mlsIds);
      }
    },
    [isManual],
  );

  useEffect(
    function onMlsChange() {
      const isEmpty = !Object.keys(combinedData ? combinedData : {}).length;

      if (!combinedData || isEmpty) {
        return; //not loaded
      }
      SearchCombiner.add('manualMlsIds', onCombine);
      onManualMlsChanged(mlsIds);

      return () => SearchCombiner.remove('manualMlsIds');
    },
    [mlsIds],
  );

  function onNext(shouldBeSaved = true) {
    setLoading(true);
    // if (!SearchValidator.validate()) {
    //   setError(
    //     'Please fill any missing data or select the pencil icon to edit any fields showing "Please enter"',
    //   );
    // }
    onContinue(SearchCombiner.combine(), shouldBeSaved);
  }

  function onManualMlsChanged() {
    onPartial(SearchCombiner.combine());
  }

  return (
    <div className={`${styles.propertyDetails} ${className}`}>
      <Error message={error} />
      <div className={styles.wrapper}>
        <CriteriaIsOpenProvider>
          <div className={`${styles.propertySearchCriteria} ${className}`}>
            <ManualSelector
              isManual={isManual}
              onChange={setIsManual}
              disabled={partialLoading}
              className={styles.selector}
            />

            {!isManual && (
              <div className={styles.criteriaWrapper}>
                <PropertyType onChange={data => setCombinedData(data)} />
                <BedCount onChange={data => setCombinedData(data)} className={styles.filterCard} />
                {hasBathCountFF && <TotalBathCount onChange={data => setCombinedData(data)} />}
                {!CurrentUserService.isCanadaUser() && (
                  <LivingAreaSize
                    onChange={data => setCombinedData(data)}
                    className={styles.filterCard}
                  />
                )}
                <YearBuilt onChange={data => setCombinedData(data)} className={styles.filterCard} />
                {/* <YearBuiltV2 onChange={(data) => setCombinedData(data)} className={styles.filterCard}/> */}
                <ClosedDate
                  onChange={data => setCombinedData(data)}
                  className={styles.filterCard}
                />
                {!CurrentUserService.isCanadaUser() && (
                  <TotalLotSize
                    onChange={data => setCombinedData(data)}
                    className={styles.filterCard}
                  />
                )}
              </div>
            )}
            <ManualMLSList
              className={styles.manualList}
              mlsIds={mlsIds}
              errors={errors}
              setErrors={setErrors}
              setMlsIds={setMlsIds}
              subjectId={subjectId}
            />
          </div>
          <div className={`${styles.map}`}>
            <div className={`${styles.rightSide}`}>
              <AddressOverview />
            </div>

            <SubjectPropertyInfo />
            <PropertyArea
              isManual={isManual}
              onChange={data => setCombinedData(data)}
              searchData={combinedData}
              partialData={partialData}
              partialLoading={partialLoading}
            />

            <div className={styles.footer}>
              <PropertyMatchLegends partialData={partialData} />

              <Footer
                onPrev={onBack}
                hidePrev={!!isRebuild}
                disabledPrev={loading || partialLoading}
                nextText='Next: Build CMA'
                onNext={onNext}
                loadingNext={loading}
                disabledNext={loading || partialLoading}
              />
            </div>
          </div>
        </CriteriaIsOpenProvider>
      </div>
    </div>
  );
};
