import * as alpha from './alpha';
import * as beta from './beta';
import * as gamma from './gamma';
import * as delta from './delta';
import * as epsilon from './epsilon';
import * as zeta from './zeta';

import { TDynamicSlideConfigs } from '../../types';
import { TDynamicSlideTextTemplate } from '../../../../../types';

export const textTemplateConfigs: Record<TDynamicSlideTextTemplate, TDynamicSlideConfigs> = {
  textLayoutAlpha: alpha,
  textLayoutBeta: beta,
  textLayoutGamma: gamma,
  textLayoutDelta: delta,
  textLayoutEpsilon: epsilon,
  textLayoutZeta: zeta,
};
