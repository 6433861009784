import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { PROPERTY_STATUS } from '../../../../types';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';

export function usePropertiesForActiveShelfLifeChart() {
  const reportProperties = useAtomValue(reportPropertiesAtom);

  return useMemo(
    () =>
      reportProperties
        .map(property => property.property)
        .filter(property => property.status === PROPERTY_STATUS.ACTIVE && !property.excluded),
    [reportProperties],
  );
}
