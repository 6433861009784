import { Document, Page, pdfjs } from 'react-pdf';
import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import Loader from '../../../../../Loader/Loader';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const LoaderContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const PageWrapper = styled('div')({
  width: '100%',
  objectFit: 'contain',
  '&:not(:last-of-type)': {
    marginBottom: 40,
  },
});

interface PdfViewerProps {
  pdfUrl?: string;
  containerHeight?: number;
  setIsLoaded?: Dispatch<SetStateAction<boolean>>;
}

const LoadingComponent: React.FC = () => {
  return (
    <LoaderContainer>
      <Loader centered size='4x' />
    </LoaderContainer>
  );
};

const PdfViewer: React.FC<PdfViewerProps> = ({
  pdfUrl = '',
  containerHeight,
  setIsLoaded = () => {},
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setNumPages(numPages);
      setIsLoaded(true);
    },
    [setIsLoaded],
  );

  return (
    <div>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} loading={<LoadingComponent />}>
        {Array.from(new Array(numPages), (el, index) => (
          <PageWrapper
            key={index}
            style={{
              height: containerHeight || '100%',
            }}
          >
            <Page key={`page_${index + 1}`} pageNumber={index + 1} height={containerHeight || 0} />
          </PageWrapper>
        ))}
      </Document>
    </div>
  );
};

export default PdfViewer;
