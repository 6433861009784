import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';

export const BackgroundContainer = styled('div')({
  height: '100%',
  width: '100%',
  padding: 45,
  backgroundColor: '#fff',
  position: 'relative',
});

export const ClippedBackground = styled('div')(({ theme }) => ({
  clipPath: 'polygon(0 90%, 100% 20%, 100% 100%, 0% 100%)',
  backgroundColor: useUiConfigColorsPrimary(),
  position: 'absolute',
  width: '100%',
  height: '100%',
  bottom: 0,
  left: 0,
}));

export const ContentContainer = styled('div')({
  height: '100%',
  width: '100%',
  position: 'relative',
  zIndex: 1,
});
