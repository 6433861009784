import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { Button } from '../../../../../../../components/Common/V2/Button/Button';
import { TCategory, TUserConfigs } from '../../../../../../../types';
import { useEntity } from '../../../../../providers/EntityProvider';
import { useStep } from '../../../../../../../providers/providers/StepProvider';
import { useUser } from '../../../../../../../components/Slide/providers/UserProvider';
import { CustomizationSettingsApi } from '../../../../../api/CustomizationSettingsApi';
import { useUiConfigFonts } from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUiConfigTheme } from '../../../../../../../providers/providers/UiConfigThemeProvider';
import { useUiConfigColors } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigLogos } from '../../../../../../../providers/providers/UiConfigLogosProvider';
import { useUiConfigInfoPlacement } from '../../../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { useCustomizationOrder } from '../../../../../../../providers/providers/CustomizationOrderProvider';
import { useCustomizationSettings } from '../../../../../../../hooks/useCustomizationSettings';
import { useUiUseKvCoreLogo } from '../../../../../../../providers/providers/UiUseKvCoreLogoProvider';
import {
  useInvalidateCustomizations,
  useRawCustomizations,
} from '../../../../../../../data/customizations/useRawCustomizations';

interface Props {
  category: TCategory;
  onFinish: () => void;
}

export const SaveAndContinue: React.FC<Props> = ({ category, onFinish }) => {
  const { step, toNextStep } = useStep();

  const user = useUser();
  const hash = user?.hash ?? '';
  const { entity, entityMode } = useEntity();
  const { theme } = useUiConfigTheme();
  const { logos } = useUiConfigLogos();
  const { useKvCoreLogo } = useUiUseKvCoreLogo();
  const { fonts } = useUiConfigFonts();
  const { colors } = useUiConfigColors();
  const { infoPlacementData } = useUiConfigInfoPlacement();
  const { customizationSectionsByType, customizationSectionsSlideByType } = useCustomizationOrder();
  const { order } = useCustomizationSettings(hash, entityMode, entity);

  const { data: customizationConfigsRaw } = useRawCustomizations(hash);

  const invalidateCustomizations = useInvalidateCustomizations();

  const onNextClick = (data: TUserConfigs) => {
    invalidateCustomizations(hash);

    if (step === 2) {
      onFinish();
      return;
    }

    toNextStep();
  };

  const { mutate, isLoading } = useMutation(CustomizationSettingsApi.update, {
    onSuccess: onNextClick,
  });

  const onNext = () => {
    mutate({
      entityId: entity,
      config: {
        ...((customizationConfigsRaw as any)?.['e-' + entity]?.data ?? {}),
        [category]: {
          ui: {
            logos,
            useKvCoreLogo,
            theme,
            infoPlacement: infoPlacementData,
            fonts,
            colors,
          },
          order: {
            sections: order ? order.sections : customizationSectionsByType.winTheListing,
            sectionsSlide: order
              ? order.sectionsSlide
              : customizationSectionsSlideByType.winTheListing,
          },
          orderByType: {
            winTheListing: {
              sections: customizationSectionsByType.winTheListing,
              sectionsSlide: customizationSectionsSlideByType.winTheListing,
            },
            winTheOffer: {
              sections: customizationSectionsByType.winTheOffer,
              sectionsSlide: customizationSectionsSlideByType.winTheOffer,
            },
            presentInfo: {
              sections: customizationSectionsByType.presentInfo,
              sectionsSlide: customizationSectionsSlideByType.presentInfo,
            },
            winTheRepresentation: {
              sections: customizationSectionsByType.winTheRepresentation,
              sectionsSlide: customizationSectionsSlideByType.winTheRepresentation,
            },
          },
        },
      },
    });
  };

  return (
    <Button isLoading={isLoading} variant='primary' onClick={onNext}>
      Save & Continue
    </Button>
  );
};
