import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Input } from './Input';

export const MoneyInput = ({
  placeholder,
  label,
  onChange,
  value,
  className,
  error,
  icon,
  disabled,
  dataAttributes,
  prefix = '$',
  noError = false,
}) => (
  <NumberFormat
    customInput={Input}
    value={value}
    onValueChange={({ floatValue }) => onChange(floatValue)}
    thousandSeparator={true}
    prefix={prefix}
    useEvent
    label={label}
    placeholder={placeholder}
    min='0'
    className={className}
    error={error}
    icon={icon}
    disabled={disabled}
    dataAttributes={dataAttributes}
    noError={noError}
  />
);

MoneyInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  dataAttributes: PropTypes.shape(PropTypes.any),
};

MoneyInput.defaultProps = {
  value: '',
  onChange: f => f,
  label: '',
  placeholder: '',
  className: '',
  error: '',
  icon: '',
  disabled: false,
  dataAttributes: null,
};
