import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TBuyerTourPresentation } from '../../../../../../types';
import { DashboardInput, FitContentModal } from '../../../../../../components/Common';
import { useQueryClient } from '@tanstack/react-query';
import { corePresentApi } from '../../../../../../api/CorePresentApi';

export const Container = styled('div')({
  padding: '20px',
});

interface BTAddCoreVideoProps {
  isOpen: boolean;
  onClose: () => void;
  initialVideoLink?: string;
  presentationData?: TBuyerTourPresentation;
}

export const BTAddCoreVideo: React.FC<BTAddCoreVideoProps> = ({
  isOpen,
  onClose,
  initialVideoLink,
  presentationData,
}) => {
  const [videoLink, setVideoLink] = useState(initialVideoLink || '');
  const [errMessage, setErrMessage] = useState('');

  const queryClient = useQueryClient();
  const theme = useTheme();

  const onConfirmVideoLink = async () => {
    try {
      const isVideoValid =
        /(youtube|youtu|loom)\.(com|be)\/((watch\?v=([-\w]+))|(share\/([-\w]+))|([-\w]+))/.test(
          videoLink,
        );
      if (!presentationData) return;

      const data = {
        ...presentationData,
        slides: {
          ...presentationData.slides,
          greetingVideo: {
            ...presentationData.slides['greetingVideo'],
          },
        },
        order: {
          ...presentationData.order,
          sectionsSlide: {
            ...presentationData.order.sectionsSlide,
            initSection: ['greetingVideo', ...presentationData.order.sectionsSlide.initSection],
          },
        },
        videoLink,
      };

      if (isVideoValid) {
        await corePresentApi.put(`/presentations/${presentationData.id}`, {
          data: JSON.stringify(data),
        });
        queryClient.invalidateQueries(['presentation', presentationData?.hash]);
        onClose();
      } else {
        setErrMessage('Url should be valid YouTube or Loom video url');
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <FitContentModal
      isOpen={isOpen}
      headerTitle='Add Video Link'
      onClose={onClose}
      confirmBtnText='Add'
      hasCancelBtn
      onConfirmClick={onConfirmVideoLink}
      modalDescription={{ text: 'You can add a YouTube or Loom video link.' }}
      modalDescriptionContainerStyles={{ display: 'block', color: theme.colors.v2.gray[400] }}
    >
      <Container>
        <DashboardInput
          type='text'
          label='Video URL'
          inputId='video-url'
          value={videoLink}
          onChange={e => {
            setVideoLink(e.currentTarget.value);
          }}
          errorMessage={errMessage}
        />
      </Container>
    </FitContentModal>
  );
};
