import React from 'react';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { RealtorVSIBuyerCard } from '../../components/RealtorVSIBuyerCard/RealtorVSIBuyerCard.component';

import { staticText, iBuyerData, realtorData } from '../../data';

import {
  Container,
  GrayBg,
  Title,
  Subtitle,
  DescriptionWrapper,
  DescriptionTitle,
  DescriptionText,
  CardsContainer,
  Cards,
} from './TraditionalRealtorVSIBuyerPdf.styles';

export const TraditionalRealtorVSIBuyerPdf: React.FC = () => {
  const { title, subtitle, descriptionTitle, descriptionText, comparisonTitle } = staticText;

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container>
        <GrayBg />
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <DescriptionWrapper>
          <DescriptionTitle>
            {descriptionTitle.part1} {descriptionTitle.part2}
          </DescriptionTitle>
          <DescriptionText>{descriptionText.p1}</DescriptionText>
          <DescriptionText>{descriptionText.p2}</DescriptionText>
          <DescriptionText>{descriptionText.p3}</DescriptionText>
        </DescriptionWrapper>
        <CardsContainer>
          {/*<DescriptionTitle>{comparisonTitle}</DescriptionTitle>*/}
          <Cards>
            <RealtorVSIBuyerCard cardData={realtorData} category='traditional' />
            <RealtorVSIBuyerCard cardData={iBuyerData} isIBuyer category='traditional' />
          </Cards>
        </CardsContainer>
      </Container>
    </PdfFrame>
  );
};
