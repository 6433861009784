import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './Input.module.scss';

export const InputTypes = Object.freeze({
  PASSWORD: 'password',
  TEXT: 'text',
  EMAIL: 'email',
});

class Input extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    icon: PropTypes.string,
    handleKeyUp: PropTypes.func,
    disabled: PropTypes.bool,
    useEvent: PropTypes.bool,
    required: PropTypes.bool,
    noError: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    onChange: f => f,
    label: '',
    name: '',
    placeholder: '',
    className: '',
    type: InputTypes.TEXT,
    error: '',
    icon: '',
    disabled: false,
    useEvent: false,
    required: false,
    noError: false,
  };

  render() {
    const {
      value,
      onChange,
      label,
      name,
      placeholder,
      type,
      className,
      icon,
      error,
      handleKeyUp,
      disabled,
      min = null,
      max = null,
      useEvent,
      required,
      noError,
    } = this.props;

    const changeHandler = useEvent ? e => onChange(e) : ({ target }) => onChange(target.value);

    return (
      <div className={`${styles.input} ${className}`}>
        <label
          className={`${styles.container}  ${disabled && styles.disabled}${error && styles.error}`}
        >
          {required && <span className={styles.required} />}
          {label && <span className={styles.label}>{label}</span>}

          <input
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={changeHandler}
            onKeyUp={handleKeyUp}
            className={`${styles.field} ${icon && styles.icon}`}
            autoComplete='off'
            disabled={disabled}
            min={min}
            max={max}
          />
        </label>

        {noError ? null : <span className={styles.errorMsg}>{error}</span>}
      </div>
    );
  }
}

export { Input };
