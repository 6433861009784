import React from 'react';
import { Line, NotesContainer, Title } from './Notes.styles';

export const Notes: React.FC = () => {
  return (
    <NotesContainer>
      <Title>Notes</Title>
      {Array.from(new Array(15).keys()).map(num => (
        <Line key={num} />
      ))}
    </NotesContainer>
  );
};
