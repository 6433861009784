import React, { createContext, useContext, useState } from 'react';

type TViewMode = 'all' | 'property';

type TViewModeContext = {
  viewMode: TViewMode;
  setViewMode: (viewMode: TViewMode) => void;
};

const ViewModeContext = createContext<TViewModeContext | undefined>(undefined);

export function useViewMode() {
  const context = useContext(ViewModeContext);

  if (!context) {
    throw new Error('useViewMode must be used within a ViewModeContext');
  }

  return context;
}

export const ViewModeProvider: React.FC = ({ children }) => {
  const [viewMode, setViewMode] = useState<TViewMode>('property');

  const value = React.useMemo(() => ({ viewMode, setViewMode }), [viewMode]);

  return <ViewModeContext.Provider value={value}>{children}</ViewModeContext.Provider>;
};
