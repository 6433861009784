import React from 'react';
import styled from 'styled-components';

import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { StarIcon } from '../components/StarIcon';

export interface IHomesCount {
  active: number;
  pending: number;
  closed: number;
  comingSoon: number;
}

interface Props {
  priceRange: [number, number];
  homesCount: IHomesCount;
  onClick: () => void;
}

export const InfoCard: React.FC<Props> = ({ priceRange, homesCount, onClick }) => {
  const { active, pending, closed, comingSoon } = homesCount;
  const totalCount = active + pending + closed + comingSoon;

  return (
    <Container>
      <PriceRange>
        {MoneyFormatter.format(priceRange[0])} - {MoneyFormatter.format(priceRange[1])}
      </PriceRange>
      <HomeCount>
        {totalCount} home{totalCount > 1 ? 's' : ''} in this price range
      </HomeCount>
      <IconsWrapper>
        <StarIcon status='coming_soon' text={comingSoon} size={30} />
        <StarIcon status='a' text={active} size={30} />
        <StarIcon status='p' text={pending} size={30} />
        <StarIcon status='c' text={closed} size={30} />
      </IconsWrapper>
      <Button onClick={onClick}>View Homes</Button>
    </Container>
  );
};

const Container = styled.div`
  width: 70%;
  background-color: #fff;
  border: 1px solid #eaeaea;
  padding: 25px 12.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 2px;
`;

const PriceRange = styled.p`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
`;

const HomeCount = styled.p`
  font-size: 16px;
  text-align: center;
`;

const IconsWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const Button = styled.button`
  outline: none;
  background-color: #444;
  color: #fff;
  padding: 8px 0;
  border-radius: 4px;
  transition: all 0.2s;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;
