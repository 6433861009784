import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { TPastPresentationRaw } from '../../../../../types';
import { PresentationApi } from '../../../../Presentation/api/PresentationApi';
import { useInvalidatePresentations } from './useLoadPresentations';

function hasFailedBuild(presentation: TPastPresentationRaw): boolean {
  if (!presentation?.updated_at_timestamp) return false;

  const tenMinutesAgo = new Date(new Date().setMinutes(new Date().getMinutes() - 10));
  const isRebuildCandidate = new Date(presentation.updated_at_timestamp * 1000) < tenMinutesAgo;

  if (!isRebuildCandidate) return false;

  return presentation.build_in_progress || !presentation.pdf;
}

export function usePdfGenerationRetry() {
  const isRegenerationTriggered = useRef(false);
  const invalidatePresentations = useInvalidatePresentations();

  return useMutation(async (presentations: TPastPresentationRaw[]) => {
    if (isRegenerationTriggered.current) return;
    const ids = presentations.filter(hasFailedBuild).map(p => String(p.id));

    if (!ids?.length) return;

    isRegenerationTriggered.current = true;
    const promises = ids.map(uuid => PresentationApi.triggerPdfGeneration(uuid));

    await Promise.allSettled(promises);
    setTimeout(invalidatePresentations, 3000);
  });
}
