import React from 'react';
import img from './image.png';
import { alternativeSlideContent } from '../../data';

import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';

export const ValueOfAnAgentLuxury: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading={alternativeSlideContent.title}
      imagePosition='left'
      imageStyles={{ width: '85%' }}
      image={img}
      paragraphs={alternativeSlideContent.paragraphs}
      imageDominant
    />
  );
};
