import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUrlSearchParam } from '../../hooks/useQueryParams';
import { StorageService } from '../../services/storageService';
import { FullScreenLoader } from '../../components/Loader/FullScreenLoader';
import { REDIRECT_TO_PARAM } from '../../constants/redirectToParam';

export const WhiteLabelLogin = () => {
  const history = useHistory();
  const token = useUrlSearchParam('token');
  const agentId = useUrlSearchParam('agentId');
  const query: any = new URLSearchParams(location.search);

  const redirectTo = query.get(REDIRECT_TO_PARAM);

  useEffect(() => {
    StorageService.save('token', token);
    StorageService.save('agentId', agentId);

    if (redirectTo) {
      history.push(redirectTo);
      return;
    }

    history.push('/home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, agentId]);

  return <FullScreenLoader />;
};
