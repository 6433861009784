import styled from 'styled-components';

import { Typography as Text } from '../../../../../Common';
import { useUiConfigFontsHeader } from '../../../../../../providers/providers/UiConfigFontProvider';
import { useReverseHeader } from '../../../../hooks/useReverseHeader';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
  alignItems: 'center',
}));

export const ImageWrapper = styled('div')({
  height: 85,
  width: 85,
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Image = styled('img')({
  width: '100%',
  height: `100%`,
  objectFit: 'cover',
  objectPosition: 'top',
});

export const InfoWrapper = styled('div')(({ theme }) => ({
  paddingLeft: useReverseHeader() ? 0 : 20,
  paddingRight: !useReverseHeader() ? 0 : 20,
}));

export const Typography = styled(Text)(({ theme }) => ({
  lineHeight: 1.4,
  textAlign: useReverseHeader() ? 'right' : 'left',
}));

export const Name = styled(Typography)(({ theme }) => ({
  fontSize: 22,
  fontWeight: 500,
  fontFamily: useUiConfigFontsHeader(),
}));
