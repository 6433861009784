import styled from 'styled-components';

import { TitlePdf } from '../../../common.styles';
import { Icon } from '../../../../../../../Common';

export { TitlePdf, Icon };

export const TestimonialWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const QuotesWrapper = styled('div')({
  width: 60,
  marginTop: 15,
});

export const TestimonialsContainer = styled('div')({
  marginTop: 65,
});
