import { useRef } from 'react';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { useLevelDynamicSlides } from '../../../hooks/useLevelDynamicSlides';
import { DefaultConfigProvider } from '../../../services/DefaultConfigProvider';
import { useUserCustomizations } from '../../../hooks/useUserCategoryCustomizations';
import { TSlide, TSlideId, TDynamicOrder, IPresentationConfigs } from '../../../types';
import { usePresentationCreationQuery } from '../../../hooks/usePresentationCreationQuery';
import { useCreatePresentation } from '../../../pages/PresentationCreate/hooks/sharedHooks';
import { usePredefinedProperties } from '../../../pages/PresentationCreate/hooks/usePredefinedProperties';
import { PersonalizedSlidesInjector } from '../../../pages/PresentationCreate/services/PersonalizedSlidesInjector';
import { PresentationConfigsFactory } from '../../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';

export function usePresentInfoPresentationCreator() {
  const user = useUser();
  const hash = user?.hash || '';

  const isCreating = useRef(false);

  const dynamicSlides = useLevelDynamicSlides(hash, user?.useSpecialBranding);
  const { isRebuild, presentation: rebuildPresentationHash } = usePresentationCreationQuery();

  const createPresentation = useCreatePresentation();

  const {
    data: userConfigs,
    isFetching: isFetchingConfigs,
    isLoading: isLoadingConfigs,
  } = useUserCustomizations(hash, null, user?.useSpecialBranding);

  const { buildPredefinedProperties, getCategory } = usePredefinedProperties(
    PRESENTATION_TYPES.PRESENT_INFO,
  );

  const getUI = (userConfigs: any, theme: string, category: string) => ({
    ...DefaultConfigProvider.getUiConfig(),
    ...(userConfigs?.[category]?.ui ?? {}),
    ...(!isRebuild
      ? {}
      : {
          ...(userConfigs?.[category]?.ui ?? {}),
          theme,
        }),
  });

  const getOrder = (
    piConfigs: IPresentationConfigs,
    slides: Partial<Record<TSlideId, TSlide>>,
    rebuildOrder?: TDynamicOrder,
  ) => {
    if (rebuildOrder) return rebuildOrder;

    const category = getCategory();

    const allUserSections = userConfigs?.[category]?.orderByType?.presentInfo?.sections;
    const allUserSectionsSlide = userConfigs?.[category]?.orderByType?.presentInfo?.sectionsSlide;

    if (!allUserSectionsSlide || !allUserSections) {
      const order = piConfigs.getDefaultOrder();

      const sectionsSlide = PersonalizedSlidesInjector.inject(
        PRESENTATION_TYPES.PRESENT_INFO,
        'regular',
        order.sectionsSlide,
        dynamicSlides.data,
      );

      return { ...order, sectionsSlide };
    }

    const predefinedSections = piConfigs.getDefaultOrder().sections;

    // todo user config can be outdated
    const sections = allUserSections.filter(section => predefinedSections?.includes(section));

    function mapSectionSlides(sectionSlidesConfigs: Partial<TSlide>[]) {
      return sectionSlidesConfigs
        .filter(s => s.id && s.includeSlide && slides[s.id as TSlideId])
        .map(s => s.id);
    }

    const sectionsSlide = sections.reduce(
      (map, section) => ({
        ...map,
        [section]: mapSectionSlides(allUserSectionsSlide[section]),
      }),
      {},
    );

    return {
      sections,
      sectionsSlide,
    };
  };

  const getSlides = (piConfigs: IPresentationConfigs): Partial<Record<TSlideId, TSlide>> => {
    return {
      ...piConfigs.getSlides(),
      ...(dynamicSlides.data ?? {}),
    };
  };

  const create = async () => {
    if (isLoadingConfigs || isFetchingConfigs || dynamicSlides.isLoading || isCreating.current)
      return;

    isCreating.current = true;

    const { category, theme, videoLink, agreementLink, regular, client } =
      await buildPredefinedProperties(isRebuild, rebuildPresentationHash);

    const PIConfigs = PresentationConfigsFactory.create(PRESENTATION_TYPES.PRESENT_INFO);
    const slidesMap = getSlides(PIConfigs);
    const sections = PIConfigs.getSections();
    const order = getOrder(PIConfigs, slidesMap, regular?.order);

    const ui = getUI(userConfigs, theme, category);

    const presentation = {
      regular: {
        sections,
        order,
      },
      type: PRESENTATION_TYPES.PRESENT_INFO,
      category,
      ui,
      slidesMap,
      videoLink,
      agreementLink,
      reportData: {
        search: {
          api: {
            id: 1,
          },
        },
      },
    };

    try {
      isCreating.current = true;
      const newPresentation = await createPresentation(presentation, client);

      return newPresentation;
    } catch (error) {
      isCreating.current = false;
      console.error(error);
    }
  };

  const isLoading =
    isLoadingConfigs || isFetchingConfigs || dynamicSlides.isLoading || dynamicSlides.isFetching;

  return {
    create,
    isLoading,
    isCreating: isCreating.current,
  };
}
