import { SlideViewedProps } from './types';

import { Card } from './Card';

import { Col, LargeText, Row, SmallText } from './Cards.styles';

import { CircularProgress } from '../../../../../../../../components/Common/CircularProgress/CircularProgress';

export function SlidesViewedCard({ slidesViewed, totalSlides }: SlideViewedProps) {
  const progress = !totalSlides ? 0 : Math.floor((slidesViewed.length / totalSlides) * 100);

  return (
    <Card title='Slides Viewed'>
      <Row style={{ width: '100%', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress value={progress} />

        <Col>
          <SmallText>{totalSlides} Total slides</SmallText>
          <LargeText>
            {slidesViewed.length}
            <SmallText style={{ fontWeight: 500, textTransform: 'lowercase', marginLeft: 3 }}>
              viewed
            </SmallText>
          </LargeText>
        </Col>
      </Row>
    </Card>
  );
}
