import React, { useEffect, useState } from 'react';
import {
  SectionContainer,
  Content,
  Typography,
  ContentHeader,
  Input,
  Wrapper,
} from './AgentPerformance.styles';
import { AgentPerformanceTable } from './AgentPerformanceTable/AgentPerformanceTable.component';
import { TAgentPerformance } from './AgentPerformanceTable/AgentPerformanceTable.component';
import { AgentPerformanceUtils } from './AgentPerformanceUtils';

import { Pagination } from './Pagination/Pagination.component';
import { Button } from '../../../components/Common/V2/Button/Button';

interface AgentPerformanceProps {
  data: TAgentPerformance[];
  buttonLoading: boolean;
  onExportClick: () => void;
  office: number;
  date: string[];
  type: string;
}

export const ITEMS_PER_PAGE = 10;

export const AgentPerformance: React.FC<AgentPerformanceProps> = ({
  data,
  office,
  buttonLoading,
  onExportClick,
  date,
  type,
}) => {
  const [agentPerformanceData, setAgentPerformanceData] = useState<TAgentPerformance[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dataPerPage, setDataPerPage] = useState<any>([]);
  const [activePage, setActivePage] = useState(1);

  const onSearch = (term: string) => {
    setSearchTerm(term);
    setAgentPerformanceData(AgentPerformanceUtils.filter(data, term));
  };

  const sliceData = (data: TAgentPerformance[], activePage: number) => {
    let slicedData = [];

    if (activePage === 1) {
      slicedData = data.slice(0, ITEMS_PER_PAGE);
    } else {
      const startingIndex = (activePage - 1) * ITEMS_PER_PAGE;
      slicedData = data.slice(startingIndex, startingIndex + ITEMS_PER_PAGE);
    }

    setDataPerPage(slicedData);
  };

  useEffect(() => {
    if (data) {
      const sortedData = AgentPerformanceUtils.sort(data, 'name_asc');
      setAgentPerformanceData(sortedData);
      sliceData(sortedData, 1);
    }
  }, [data]);

  useEffect(() => {
    sliceData(agentPerformanceData, 1);
    setActivePage(1);
  }, [agentPerformanceData]);

  const onChangePage = (activePage: number) => {
    sliceData(agentPerformanceData, activePage);
  };

  return (
    <SectionContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h3' color='#333'>
          Agent Performance
        </Typography>
        <Button variant='primary' isLoading={buttonLoading} onClick={onExportClick}>
          Export users to CSV
        </Button>
      </div>

      <Content>
        <ContentHeader>
          <Input
            type='text'
            placeholder='Search'
            icon={{ iconName: 'search' }}
            search
            width={254}
            value={searchTerm}
            onChange={e => onSearch(e.target.value)}
          />
        </ContentHeader>
        <Wrapper>
          <AgentPerformanceTable
            office={office}
            date={date}
            type={type}
            agentPerformanceData={dataPerPage}
            setAgentPerformanceData={setAgentPerformanceData}
          />
        </Wrapper>
      </Content>
      <Pagination
        activePage={activePage}
        setActivePage={setActivePage}
        numberOfItems={data.length}
        onChangeActivePage={onChangePage}
      />
    </SectionContainer>
  );
};
