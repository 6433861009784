import styled from 'styled-components';
import { Button } from '../../../../../../components/Common';

export { Button };

export const PresentButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 30,
});

export const PresentButtonsTitle = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 15px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
