import React from 'react';
import { Header } from './Header/Header';
import { Container, Wrapper, ContentContainer } from './MainLayout.styles';

type Props = { isWhiteBg?: boolean; className?: string };

export const MainLayout: React.FC<Props> = ({ children, isWhiteBg, className = '' }) => {
  return (
    <Container>
      <Wrapper style={{ paddingTop: 58 }}>
        <Header />
        <ContentContainer className={className} isWhiteBg={isWhiteBg}>
          {children}
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};
