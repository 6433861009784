import { useState } from 'react';
import { useAtomValue } from 'jotai';

import { PropertyModal } from '../../components/PropertyModal/PropertyModal';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { Button } from '../components/Button';

export const Comparables = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  return (
    <>
      <Button
        label='Comparables'
        iconName='square_poll'
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
      />

      <PropertyModal
        isModalOpen={isOpen}
        onCloseModal={() => setIsOpen(false)}
        modalStyle={isPresentationView ? {} : { minHeight: '98vh' }}
      />
    </>
  );
};
