import { useAtomValue } from 'jotai';
import { reportPropertiesAtom } from '../../../../../features/report/state/reportPropertiesAtom';
import { extractPropertyGroups } from '../../../../../features/report/services/extractPropertiesGroups';

export const useAveragePricePerSquareFeet = () => {
  const properties = useAtomValue(reportPropertiesAtom);

  const data = extractPropertyGroups(properties);

  const calculateAvgPricePerSqft = () => {
    const all = [...data.active, ...data.pending, ...data.sold];
    let sum = 0;
    all.forEach(property => {
      sum = sum + property.pricePerSqft;
    });
    return Math.round(sum / all.length);
  };

  return calculateAvgPricePerSqft();
};
