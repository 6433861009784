import React from 'react';
import styled from 'styled-components';

import { ReportInfoSection, ReportInfoSectionBT } from './ReportInfoSection';
import { SlideDescription, SlideDescriptionBT } from '../components/SlideDescription';
import { Report } from '../../../../../../pages/PresentationCreate/dash';
import {
  CriteriaReviewBox,
  CriteriaReviewBoxBT,
} from '../components/CriteriaReviewBox/CriteriaReviewBox';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { Map } from '../../../../../../features/BuyerTour/components/Map/Map.component';

const Container = styled.div`
  display: flex;
  padding: 20px;
  height: 100%;
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 450px;
  padding-right: 40px;
`;

const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
`;

const MapWrapper = styled.div`
  margin-top: 20px;
  height: calc(100% - 152px);
`;

const MapHeading = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 18;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  padding-left: 10px;
  & span {
    font-weight: 700;
    margin-right: 6px;
  }
`;

const ComparativeMarketAnalysisRegular: React.FC = () => {
  return (
    <Container>
      <ColumnLeft>
        <SlideDescription />
        <CriteriaReviewBox style={{ marginTop: 15 }} />
      </ColumnLeft>

      <ColumnRight>
        <ReportInfoSection />

        <MapWrapper>
          <Report.Components.ComparativeMarketAnalysis height={580} />
        </MapWrapper>
      </ColumnRight>
    </Container>
  );
};

const ComparativeMarketAnalysisBT: React.FC = () => {
  const { properties } = useProperties();
  return (
    <Container>
      <ColumnLeft>
        <SlideDescriptionBT />
        <CriteriaReviewBoxBT style={{ marginTop: 15 }} />
      </ColumnLeft>

      <ColumnRight>
        <ReportInfoSectionBT />
        <MapWrapper>
          <MapHeading>
            <span>{properties.length}</span>Total Properties Found
          </MapHeading>
          <Map properties={properties} cma useNumberedMarkers />
        </MapWrapper>
      </ColumnRight>
    </Container>
  );
};

const ComparativeMarketAnalysis: React.FC = () => {
  const { presentationType } = usePresentationType();

  if (presentationType === 'buyerTour') return <ComparativeMarketAnalysisBT />;
  return <ComparativeMarketAnalysisRegular />;
};

export default ComparativeMarketAnalysis;
