import Frame from './LuxuryFrameAlpha';
import thumbnail from './luxuryAlpha.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'luxuryAlpha';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TLuxuryFrameAlpha = typeof id;
export default config;
