import styled from 'styled-components';

export const CardsSectionEl = styled('div')({
  width: '100%',
  '&:not(:last-of-type)': {
    marginBottom: 50,
  },
});

export const CardsSectionTitle = styled('h4')(({ theme }) => ({
  fontSize: 18,
  color: theme.colors.gray[300],
  textTransform: 'uppercase',
  fontWeight: 500,
  marginBottom: 20,
}));

export const CardsSectionContent = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, 33%)',
  gridGap: 5,
  '@media all and (max-width: 1295px)': {
    gridTemplateColumns: 'repeat(auto-fit, minmax(375px, 1fr))',
  },
});
