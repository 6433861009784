import { useSetAtom } from 'jotai';
import { selectedPropertyAtom } from '../../../state/selectedPropertyAtom';
import { compViewModeAtom } from '../../../state/compViewModeAtom';
import { activePropertyTypeAtom } from '../../../state/activePropertyTypeAtom';
import styled from 'styled-components';
import { PROPERTY_STATUS } from '../../../../../types';

interface Props {
  type?: PROPERTY_STATUS | 'coming_soon';
}

export const ShowPropertiesOnMap = ({ type }: Props) => {
  const setSelectedProperty = useSetAtom(selectedPropertyAtom);
  const setViewMode = useSetAtom(compViewModeAtom);
  const setActivePropertyType = useSetAtom(activePropertyTypeAtom);

  const showPropertiesOnMap = () => {
    setSelectedProperty(null);

    if (!type) {
      setViewMode('all');
      setActivePropertyType(null);
      return;
    }

    setViewMode('map');
    setActivePropertyType(type);
  };

  return (
    <Text hasType={Boolean(type)} onClick={showPropertiesOnMap}>
      Show All On Map
    </Text>
  );
};

const Text = styled.span<{ hasType?: boolean }>`
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  color: ${({ theme, hasType }) => (hasType ? '#fff' : theme.colors.primary.textLink)};
`;
