import { cloneDeep } from 'lodash';

import { TDynamicSection, TDynamicSectionId, TPresentationType } from '../../types';
import { PresentationConfigsFactory } from '../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';

export class SectionsValidator {
  private presentationType: TPresentationType;

  constructor(type: TPresentationType) {
    this.presentationType = type;
  }

  getPartialConfig(partialSectionsConfig?: Record<TDynamicSectionId, TDynamicSection>) {
    const sectionsConfig = {} as Record<TDynamicSectionId, TDynamicSection>;

    if (!partialSectionsConfig) return sectionsConfig;

    const referenceSectionsConfig = this.getSections();

    Object.keys(partialSectionsConfig).forEach(sectionId => {
      if (!referenceSectionsConfig[sectionId]) return;

      sectionsConfig[sectionId] = cloneDeep(partialSectionsConfig[sectionId]);
    });

    return sectionsConfig;
  }

  getFullConfig(sectionsConfig: Record<TDynamicSectionId, TDynamicSection>) {
    const referenceSectionsMap = this.getSections();

    Object.keys(referenceSectionsMap).forEach(sectionId => {
      if (!sectionsConfig?.[sectionId]) return;

      referenceSectionsMap[sectionId] = cloneDeep(sectionsConfig[sectionId]);
    });

    return referenceSectionsMap;
  }

  private getSections() {
    return PresentationConfigsFactory.create(this.presentationType).getSections();
  }
}
