import React from 'react';

import { Select } from '../../../../../../../components/Common';
import { useTags } from '../../../../../../../hooks/dynamicSlides/useTags';
import { TTag } from '../../../../../../../types';

import { FlexWrapper, InputWrapper, Label } from './styles';

interface Props {
  value: TTag[];
  onChange: (val: any) => void;
}

const TagsSelector: React.FC<Props> = ({ value, onChange }) => {
  const tags = useTags();

  const options = Object.keys(tags)
    .map(id => ({ label: tags[id]?.label ?? '', value: tags[id]?.id ?? '' }))
    .filter(t => t.value.length);

  const defaultValue = value.map(v => ({ label: v.label, value: v.id }));

  return (
    <FlexWrapper>
      <Label>Add tags to slide</Label>
      <InputWrapper>
        <Select
          onChange={onChange}
          isMulti
          options={options}
          defaultValue={defaultValue}
          createable
        />
      </InputWrapper>
    </FlexWrapper>
  );
};

export default TagsSelector;
