import React from 'react';
import { useSlide } from '../../../../providers/SlideProvider';
import { Container } from './SingleFramelessVideo.styles';
import { getEmbedUrl } from '../../../../../../services/videoServices';

interface Props {
  reverse?: boolean;
}

export const SingleFramelessVideo: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();

  const videoUrl = slide.data?.videoUrl;
  const iframeSrc = getEmbedUrl(videoUrl);

  return (
    <Container>
      {iframeSrc ? (
        <iframe
          src={iframeSrc}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          style={{ width: '100%', height: '100%' }}
        />
      ) : null}
    </Container>
  );
};
