import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthApi } from '../api/AuthApi';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      if (!AuthApi.isLoggedIn()) return;

      // toast('Your token has expired, please login again', {
      //   type: 'error',
      //   position: 'bottom-center',
      // });
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
      return;
    }
    throw error;
  },
);
