import { Route, Switch, useLocation } from 'react-router-dom';

import { SsoPage } from '../Sso/Sso.page';
import { PublicRoutes } from './Routes';
import { SsrPresentation } from '../../ssr/Hash';
import SingleSsrSlide from '../../ssr/SingleSsrSlide';
import { FeedCheckPage } from '../FeedCheck/FeedCheck.page';
import { SlidePreview } from '../SlidePreview/SlidePreview.page';
import { useIsLoggedIn } from '../../features/auth/useIsLoggedIn';
import { PresentationPage } from '../Presentation/Presentation.page';
import { WhiteLabelLogin } from '../WhiteLabelLogin/WhiteLabelLogin';
import { UserProvider } from '../../components/Slide/providers/UserProvider';
import { DelightedTracking } from '../../components/DelightedTracking/DelightedTracking.component';

export const CustomUserPaths = Object.values(PublicRoutes);

export function CustomUserRouter() {
  const isAuth = useIsLoggedIn();

  return (
    <>
      <UserProvider fetch={isAuth}>
        <DelightedTracking />
      </UserProvider>

      <UserProvider fetch={false}>
        <Route exact path={PublicRoutes.slidePreview} component={SlidePreview} />
        <Route exact path={PublicRoutes.presentationPreview} component={PresentationPage} />
        <Route exact path={PublicRoutes.slidePdfPreview} component={SingleSsrSlide} />
        <Route exact path={PublicRoutes.presentationPdfPreview} component={SsrPresentation} />
        <Route exact path={PublicRoutes.feedCheck} component={FeedCheckPage} />
        <Route path={PublicRoutes.sso} component={SsoPage} />
        <Route path={PublicRoutes.whiteLabelLogin} component={WhiteLabelLogin} />
      </UserProvider>
    </>
  );
}
