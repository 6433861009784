import React, { useState } from 'react';

import styled from 'styled-components';
import { UploadImageModal } from './UploadImageModal.component';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { usePresentationType } from '../../../../../providers/providers/PresentationTypeProvider';
import { PRESENTATION_TYPES } from '../../../../../constants/PresentationTypes';
import { ButtonsGroup } from '../../../../../components/Common/V2/ButtonsGroup';

export const ImageButtons: React.FC = () => {
  const { presentationType } = usePresentationType();
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide()!;

  const { setSlide } = useSlides();
  const [showModal, setShowModal] = useState(false);

  const displayImage = selectedSlide?.data?.displayImage;

  const setConfig = (imgUrl?: string) => {
    if (!selectedSlide) return;

    const updatedData = {
      ...selectedSlide.data,
      displayImage: !!imgUrl,
      image: imgUrl ? imgUrl : '',
    };

    setSlide(selectedSlide.id, {
      ...selectedSlide,
      data: updatedData,
    });

    setSlideDataProperty('image', imgUrl ?? '');
    setSlideDataProperty('displayImage', !!imgUrl);
  };

  const onClick = (displayImage: boolean) => {
    if (displayImage) {
      setShowModal(true);
    } else {
      setConfig();
    }
  };

  const buttonConfigs = [
    {
      label: 'Image',
      onClick: () => onClick(true),
      isActive: displayImage,
      style: { width: '82px' },
    },
    {
      label: 'No Image',
      onClick: () => onClick(false),
      isActive: !displayImage,
      style: { width: '82px' },
    },
  ];

  if (selectedSlide?.id !== 'coverSlide' || presentationType === PRESENTATION_TYPES.BUYER_TOUR)
    return null;

  return (
    <Container>
      <Label>Cover Option:</Label>
      <ButtonsGroup buttonsConfigs={buttonConfigs} />
      {showModal && (
        <UploadImageModal
          open={showModal}
          onClose={() => setShowModal(false)}
          setConfig={setConfig}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;
