import { CATEGORIES } from '../../../../../constants/categories';
import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { BuyersAgentCompensationLuxuryPDF } from './pdf/luxury/BuyersAgentCompensationLuxuryPDF';
import { BuyersAgentCompensationModernPDF } from './pdf/modern/BuyersAgentCompensationModernPDF';
import { BuyersAgentCompensationLuxury } from './web/luxury/BuyersAgentCompensationLuxury';
import { BuyersAgentCompensationTraditionalPDF } from './pdf/traditional/BuyersAgentCompensationTraditionalPDF';
import { BuyersAgentCompensationTraditional } from './web/traditional/BuyersAgentCompensationTraditional';
import { BuyersAgentCompensationModern } from './web/modern/BuyersAgentCompensationModern';

const id = 'buyersAgentCompensation';

const config: TSlide = {
  id,
  includeSlide: false,
  label: "Buyer's Agent Compensation",
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: BuyersAgentCompensationTraditional,
    [CATEGORIES.MODERN]: BuyersAgentCompensationModern,
    [CATEGORIES.LUXURY]: BuyersAgentCompensationLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: BuyersAgentCompensationTraditionalPDF,
    [CATEGORIES.MODERN]: BuyersAgentCompensationModernPDF,
    [CATEGORIES.LUXURY]: BuyersAgentCompensationLuxuryPDF,
  },
};

export type TBuyersAgentCompensation = typeof id;
export { config, mappings };
