import { useState, useEffect, PropsWithChildren } from 'react';

import { toast } from 'react-toastify';
import { CSSObject, useTheme } from 'styled-components';

import {
  AutosuggestLabel,
  AutoSuggestWrapper,
  Container,
  Spacer,
  Wrapper,
} from './SendEmailPopup.styles';
import { TClient, TPresentationType } from '../../../types';
import Loader from '../../Loader/Loader';
import { URLS } from '../../../constants/urls';
import { corePresentApi } from '../../../api/CorePresentApi';
import { useUser } from '../../Slide/providers/UserProvider';
import { FitContentModal } from '../FitContentModal/FitContentModal';
import { DashboardInput } from '../DashboardInput/DashboardInput.component';
import { DashboardTextarea } from '../DashboardTextarea/DashboardTextarea.component';
import { AutosuggestClients } from '../AutosuggestClients/AutosuggestClients.component';
import { Button } from '../V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type EmailPopupProps = {
  isOpen: boolean;
  client: TClient;
  onClose: () => void;
  presentationHash: string;
  presentationType?: TPresentationType;
  headerTitle?: string;
  initialMessage?: string;
  initialSubject?: string;
  modalContainerStyles?: CSSObject;
  address?: string;
};

export function SendEmailPopup({
  isOpen,
  onClose,
  modalContainerStyles,
  initialSubject = '',
  initialMessage = '',
  headerTitle = 'Share Presentation via Email',
  presentationHash,
  presentationType,
  client,
  address,
}: PropsWithChildren<EmailPopupProps>) {
  const agent = useUser();
  const theme = useTheme();

  const [preview, setPreview] = useState(false);
  const [subject, setSubject] = useState(initialSubject);
  const [message, setMessage] = useState(initialMessage);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState<TClient | null>(client);

  const presentationUrl = `${URLS.CORE_PRESENT_FRONT_APP}/presentation/${presentationHash}`;

  useEffect(() => {
    setMessage(initialMessage);
    setSubject(initialSubject);
  }, [initialMessage, initialSubject]);

  const send = async () => {
    if (!selectedClient?.id) {
      toast('Please select a client', {
        type: 'error',
        position: 'top-center',
      });
      return;
    }

    if (!subject || !message) {
      toast('Please add a subject and a message.', {
        type: 'error',
        position: 'top-center',
      });
      return;
    }
    setLoading(true);

    try {
      await corePresentApi.post('/clients/email', {
        subject,
        message,
        presentation_hash: presentationHash,
        client_id: Number(selectedClient?.id),
      });
      toast('Email was sent.', {
        type: 'success',
        position: 'top-center',
      });
      setTimeout(() => {
        setLoading(false);
        onClose();
      }, 1500);
      return;
    } catch (e: any) {
      toast('There was an error. Make sure your kVCORE client has emailing enabled.', {
        type: 'error',
        position: 'top-center',
        autoClose: 4000,
      });
      setTimeout(() => {
        setLoading(false);
      }, 1500);
      return;
    }
  };

  function viewThePresentationText() {
    if (presentationType === 'buyerTour') {
      return 'A presentation containing a variety of available properties is available for you to tour';
    }

    if (presentationType === 'winTheRepresentation') {
      return 'A Buyer’s Agent Representation presentation is available for you to view';
    }

    if (presentationType === 'winTheListing' || presentationType === 'winTheOffer') {
      return `A presentation about the property at ${address} is available for you to view`;
    }

    return 'A presentation is available for you to view';
  }

  function renderModalContent() {
    if (loading) {
      return <Loader size={'4x'} />;
    }

    const signature =
      agent?.signature && agent.showSignatureOnOutgoingEmails ? (
        <div dangerouslySetInnerHTML={{ __html: agent.signature }} />
      ) : (
        agent?.name
      );

    if (preview) {
      return (
        <>
          Hi {client.name},
          <br />
          <br />
          {viewThePresentationText()}
          {message && (
            <>
              <br />
              <br />
            </>
          )}
          {message}
          <br />
          <p>
            For the best experience, please view the presentation on a computer using this link:{' '}
            <a style={{ color: theme.colors.v2.text.link, display: 'inline' }} href='#'>
              {presentationUrl}
            </a>
          </p>
          Looking forward to your thoughts!
          <br />
          <br />
          Best,
          <br /> {signature}
        </>
      );
    }

    return (
      <>
        <AutoSuggestWrapper>
          <AutosuggestLabel>Client</AutosuggestLabel>
          <Spacer />
          <AutosuggestClients
            initialValue={selectedClient?.id ? selectedClient.name : ''}
            setClient={client => setSelectedClient(client)}
            placeholder='Enter client name'
            suggestionsContainerStyles={{ width: '100%', position: 'absolute', zIndex: 1 }}
          />
        </AutoSuggestWrapper>
        <DashboardInput
          value={subject}
          onChange={subject => setSubject(subject.currentTarget.value)}
          type='text'
          label='Subject'
          placeholder='Subject'
          inputId='subject'
        />
        <DashboardTextarea
          value={message}
          onChange={message => setMessage(message)}
          label='Message'
          textareaId='message'
        />
        <Wrapper>
          {selectedClient && selectedClient.email && (
            <p style={{ fontSize: '14px', marginBottom: '10px' }}>
              The email will be sent to {selectedClient.name}&apos;s email: {selectedClient.email}
            </p>
          )}
          <Button variant='primary' onClick={() => setPreview(!preview)}>
            Preview
          </Button>
        </Wrapper>
      </>
    );
  }

  return (
    <FitContentModal
      isOpen={isOpen}
      headerTitle={headerTitle}
      onClose={onClose}
      onConfirmClick={() => send()}
      confirmBtnText='Send'
      hasCancelBtn
      modalContainerStyles={modalContainerStyles}
      prefixButtonsContent={
        !preview ? null : (
          <Button variant='link' onClick={() => setPreview(!preview)}>
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            Back
          </Button>
        )
      }
    >
      <Container>{renderModalContent()}</Container>
    </FitContentModal>
  );
}
