import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PresentationApi } from '../pages/Presentation/api/PresentationApi';

type TPdfStatus = {
  isBuildInProgress: boolean;
  pdf: string;
};
export const usePresentationPdf = (id: string, hash: string) => {
  const [refetchInterval, setRefetchInterval] = useState(5000);

  const query = useQuery<TPdfStatus, Error>(
    ['presentation-pdf', id],
    () => PresentationApi.getPresentationPdf(hash),
    {
      refetchOnWindowFocus: false,
      refetchInterval,
      onSuccess: data => {
        if (!data?.isBuildInProgress) {
          setRefetchInterval(-1);
          return;
        }
        setRefetchInterval(5000);
      },
    },
  );

  return query;
};
