export default `<svg width="506" height="486" viewBox="0 0 506 486" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M61.5 355L254.5 433.5M254.5 433.5L99 282M254.5 433.5L195 242.5M254.5 433.5L329.5 237M254.5 433.5L412.5 292.5M254.5 433.5L441.5 360.5M254.5 433.5L87 180M254.5 433.5L244.5 133.5C241.7 130.3 227.333 113.5 220.5 105.5M254.5 433.5L279.5 133.5L304 103M254.5 433.5L412.5 180" stroke="#888888" stroke-width="2"/>
<circle cx="254.494" cy="433.494" r="176.494" stroke="#027B76" stroke-width="7.38601"/>
<circle cx="71.0079" cy="252.008" r="41.0079" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<circle cx="64.087" cy="133.087" r="52.087" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<circle cx="187.167" cy="58.167" r="56.8949" transform="rotate(-0.269828 187.167 58.167)" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<path d="M383.405 54.9335C383.405 84.7241 359.358 108.867 329.703 108.867C300.047 108.867 276 84.7241 276 54.9335C276 25.1429 300.047 1 329.703 1C359.358 1 383.405 25.1429 383.405 54.9335Z" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<circle cx="442.474" cy="126.474" r="62.4735" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<circle cx="342.008" cy="199.008" r="41.0079" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<circle cx="179.317" cy="195.317" r="49.3172" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<circle cx="441.47" cy="263.47" r="41.4696" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<circle cx="33.2371" cy="339.237" r="32.2371" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
<circle cx="467" cy="347" r="28" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="2"/>
</svg>
`;
