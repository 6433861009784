import { useSetAtom } from 'jotai';

import { RawReport } from '../../../types';
import { extractExcluded } from '../services/extractExcluded';
import { getDefaultHighlights } from '../services/getDefaultHighlights';
import { highlightedPropertiesAtom } from '../state/highlighted-properties/highlightedPropertiesAtom';
import { excludedPropertyIdsAtom } from '../state/excludedPropertyIdsAtom';

export const useSetDefaultHighlights = () => {
  const setHighlighted = useSetAtom(highlightedPropertiesAtom);
  const setExcluded = useSetAtom(excludedPropertyIdsAtom);

  const setDefaultHighlights = (reportDetailed: RawReport) => {
    const propertyGroups = reportDetailed.properties;
    const excluded = extractExcluded(propertyGroups);

    const { highlights } = getDefaultHighlights(propertyGroups, { excluded });

    setHighlighted(highlights);
    setExcluded(excluded);
  };

  return setDefaultHighlights;
};
