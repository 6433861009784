import { useState, Children } from 'react';

import styles from './ManualMLSList.module.scss';
import { ManualMLS } from './ManualMLS/ManualMLS';
import { Droppable } from 'react-beautiful-dnd';

const DroppableWrapper = ({ children }) => (
  <Droppable droppableId={'manual-mls-list'} type={'manual-mls-list'}>
    {(provided, snapshot) => (
      <div
        className={styles.list}
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{ height: Children.count(children) * 60 }}
      >
        {children}
      </div>
    )}
  </Droppable>
);

const SimpleListWrapper = ({ children }) => <div className={styles.list}>{children}</div>;

export const ManualMLSList = ({
  className,
  mlsIds,
  errors = {},
  setErrors,
  setMlsIds = () => {},
  subjectId,
  addProperty,
  removeProperty,
  onlyActive = false,
  activeProperties = [],
  withDnD = false,
  onUpdate = f => f,
}) => {
  const [innerValue, setInnerValue] = useState('');
  const notFoundMls = Object.keys(errors).filter(key => errors[key] === 404);

  const onAdd = (ids, properties) => {
    const finalIds = [...mlsIds, ...ids.filter(_id => _id.trim().length)];
    setMlsIds(finalIds);
    addProperty && addProperty(...properties);
  };

  const onRemove = mlsToRemove => {
    const ids = mlsIds.filter(mls => mls !== mlsToRemove);
    setMlsIds(ids);
    removeProperty && removeProperty(mlsToRemove);
  };

  const ListWrapper = withDnD ? DroppableWrapper : SimpleListWrapper;

  return (
    <div className={`${styles.mlsList} ${className} ${className === 'simple' && styles.simple}`}>
      <p className={styles.info}>
        Enter MLS IDs to manually add comparables. Multiple MLS IDs can be entered but <b>must</b>{' '}
        be comma separated.
      </p>

      <ListWrapper>
        {mlsIds.map((mls, i) => {
          return (
            <ManualMLS
              key={mls}
              value={mls}
              error={errors[mls] ?? ''}
              onRemove={onRemove}
              subjectId={subjectId}
              onlyActive={onlyActive}
              onUpdate={onUpdate}
              setErrors={setErrors}
              style={{ paddingLeft: withDnD ? 20 : 0 }}
              index={i}
              withDnD={withDnD}
              notActive={!activeProperties?.some(p => p?.id === mls)}
              propertyMlsId={activeProperties?.find(p => p?.id === mls)?.mlsId}
            />
          );
        })}
      </ListWrapper>

      <ManualMLS
        onAdd={onAdd}
        mlsIds={mlsIds}
        errors={errors}
        subjectId={subjectId}
        setErrors={setErrors}
        innerValue={innerValue}
        setInnerValue={setInnerValue}
      />
    </div>
  );
};
