import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { MyMarketingPlan } from './MyMarketingPlan.component';
import MyMarketingPlanPDF from './MyMarketingPlanPDF';

const id = 'myMarketingPlan';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'My Marketing Plan',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: MyMarketingPlan,
  },
  pdf: {
    shared: MyMarketingPlanPDF,
  },
};

export type TMyMarketingPlan = typeof id;
export { config, mappings };
