import styled from 'styled-components';

export const Table = styled('div')({
  width: '100%',
  minWidth: '1480px',
});

export const TableHeadContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '7fr 12fr 9fr 7fr 10fr 8fr 9fr 9fr 9fr 10fr 10fr',
  gap: 5,
  padding: '18px 25px',
  backgroundColor: theme.colors.gray[100],
}));

export const TableField = styled('div')({
  display: 'grid',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const TableRow = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '7fr 12fr 9fr 7fr 10fr 8fr 9fr 9fr 9fr 10fr 10fr',
  gap: 5,
  padding: '18px 25px',
  borderBottom: `1px solid ${theme.colors.gray[100]}`,
}));
