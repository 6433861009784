import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { RATINGS, formatPrice } from '../services/formatBTAnalyticsTableData';

const columnHelper = createColumnHelper<{
  image: string;
  propertyPrice: number;
  propertyDetails: string;
  propertyAddress: string;
  propertyRating: number;
}>();

export const useBTAnalyticsColumns = () => {
  return useMemo(
    () => [
      columnHelper.accessor('image', {
        cell: info => {
          const src = info.getValue();
          return <img style={{ maxWidth: '97px' }} src={src} />;
        },
        header: '',
        enableSorting: false,
        meta: {
          style: {
            width: '10%',
          },
        },
      }),
      columnHelper.accessor('propertyPrice', {
        cell: info => <p>{formatPrice(info.getValue())}</p>,
        header: 'Property Price',
        enableSorting: true,
        meta: {
          style: {
            width: '15%',
          },
        },
      }),
      columnHelper.accessor('propertyDetails', {
        cell: info => <p>{info.getValue()}</p>,
        header: 'Property Details',
        enableSorting: false,
        meta: {
          style: {
            width: '20%',
          },
        },
      }),
      columnHelper.accessor('propertyAddress', {
        cell: info => <p>{info.getValue()}</p>,
        header: 'Property Address',
        enableSorting: false,
        meta: {
          style: {
            width: '35%',
          },
        },
      }),
      columnHelper.accessor('propertyRating', {
        cell: info => {
          const rating = Object.values(RATINGS).find(item => item.value === info.getValue());

          return <p style={{ color: rating?.color }}>{rating?.label}</p>;
        },
        header: 'Property Rating',
        enableSorting: true,
        meta: {
          style: {
            width: '20%',
          },
        },
      }),
    ],
    [],
  );
};
