import React from 'react';
import styled from 'styled-components';

import sign from './assets/sign.png';
import signCanada from './assets/canada/signCanada.png';
import overlay from './assets/overlay.png';
import overlayCanada from './assets/canada/overlayCanada.png';
import slideGraphics from './assets/slideGraphics';
import slideGraphicsCanada from './assets/canada/slideGraphicsCanada';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';
import { getDescription, slideContent } from '../../data';

type isWithCanadaUser = {
  isCanadaUser: boolean;
};

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  position: relative;
`;

const Image = styled('img')<isWithCanadaUser>(({ isCanadaUser }) => ({
  display: 'block',
  position: 'absolute',
  width: isCanadaUser ? 845 : 1222,
  bottom: isCanadaUser ? -12 : -5,
  right: isCanadaUser ? 343 : 169,
}));

const OverlayImage = styled('img')<isWithCanadaUser>(({ isCanadaUser }) => ({
  zIndex: 1,
  bottom: isCanadaUser ? -10 : 0,
  position: 'absolute',
  right: isCanadaUser ? 353 : 116,
  height: isCanadaUser ? 608 : 740,
}));

const Sign = styled('img')<isWithCanadaUser>(({ isCanadaUser }) => ({
  position: 'absolute',
  left: isCanadaUser ? 744 : -60,
  bottom: isCanadaUser ? 0 : -615,
  filter: 'drop-shadow(1.3534px 1.3534px 6.767px rgba(0,0,0,0.25)) grayscale(1)',
  zIndex: 2,
  height: isCanadaUser ? 779 : 1600,
}));

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 45px;
  margin-bottom: 10px;
  margin-top: 25px;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const DescriptionWrapper = styled('div')({
  position: 'relative',
  zIndex: 5,
  paddingLeft: 45,
  width: 408,
});

const Description = styled.p`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: ${() => useUiConfigBodyLetterSpacing()};
  margin-bottom: 40px;
`;

const FloatWrapper = styled('div')({
  '&::before': {
    content: '""',
    display: 'block',
    float: 'left',
    height: '290px',
  },
});

export const ValueOfAnAgentTraditional: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const { isCanadaUser } = useUser();

  const graphics = isCanadaUser ? slideGraphicsCanada : slideGraphics;

  const img = React.useMemo(() => svgStringToDataUrl(graphics, primary), [graphics, primary]);

  const headingStyles = {
    fontSize: 18,
    color: '#444',
    fontWeight: 700,
  };

  const textStyles = {
    fontSize: 16,
    fontWeight: 400,
    color: '#444',
  };

  return (
    <ImageWrapper>
      <Heading>Value Of An Agent</Heading>
      <DescriptionWrapper>
        {isCanadaUser && (
          <>
            <FloatWrapper></FloatWrapper>
            <div
              style={{
                shapeOutside: 'circle(50% at 103% 50%)',
                width: '170px',
                height: '170px',
                float: 'right',
                clear: 'both',
              }}
            ></div>
          </>
        )}
        {getDescription(isCanadaUser).map((paragraph, i) => (
          <Description key={i}>{paragraph}</Description>
        ))}
      </DescriptionWrapper>

      <Image src={img} isCanadaUser={isCanadaUser} />
      <OverlayImage src={isCanadaUser ? overlayCanada : overlay} isCanadaUser={isCanadaUser} />
      <Sign src={isCanadaUser ? signCanada : sign} isCanadaUser={isCanadaUser} />

      {!isCanadaUser && (
        <>
          <TraditionalSlideCircularCard
            heading={slideContent[2].title}
            headingStyles={headingStyles}
            textStyles={textStyles}
            text={slideContent[2].text}
            position={{ left: 160, bottom: 10 }}
            size={250}
          />
          <TraditionalSlideCircularCard
            heading={slideContent[1].title}
            headingStyles={headingStyles}
            textStyles={textStyles}
            text={slideContent[1].text}
            position={{ bottom: 285, left: 182 }}
            size={250}
          />
          <TraditionalSlideCircularCard
            heading={slideContent[3].title}
            headingStyles={headingStyles}
            textStyles={textStyles}
            text={slideContent[3].text}
            position={{ top: 3, right: 439 }}
            size={250}
          />
          <TraditionalSlideCircularCard
            heading={slideContent[4].title}
            headingStyles={headingStyles}
            textStyles={textStyles}
            text={slideContent[4].text}
            position={{ top: 147, right: 183 }}
            size={250}
          />
          <TraditionalSlideCircularCard
            heading={slideContent[5].title}
            headingStyles={headingStyles}
            textStyles={textStyles}
            text={slideContent[5].text}
            position={{ bottom: 57, right: 142 }}
            size={265}
          />
        </>
      )}
    </ImageWrapper>
  );
};
