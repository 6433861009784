import * as types from './types';

export const setOnStep = step => ({
  type: types.ON_STEP_SET,
  payload: step,
});

export const setAddress = address => ({
  type: types.ADDRESS_SET,
  payload: address,
});

export const setSearchCriteria = searchCriteria => ({
  type: types.SEARCH_CRITERIA_SET,
  payload: searchCriteria,
});

export const setApi = api => ({
  type: types.API_SET,
  payload: api,
});

export const setClients = step3 => ({
  type: types.REPORT_CLIENTS_SET,
  payload: step3,
});
