import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import bg from './bg.png';
import pdfGraphics from './pdfGraphics';

import { traditionalTitle, text, list } from '../../data';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const BackgroundImage = styled('div')({
  height: '100%',
  width: '100%',
  background: `url(${bg}) bottom center`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  padding: 33,
});

const Circles = styled('img')({
  position: 'absolute',
  width: '78%',
  top: 100,
  left: 55,
  display: 'block',
});

const Title = styled('h2')(({ theme }) => ({
  fontSize: 26,
  fontWeight: 900,
  color: useUiConfigColorsPrimary(),
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  textTransform: 'uppercase',
}));

const TextContainer = styled('div')(({ theme }) => ({
  marginTop: 10,
  width: 295,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const Paragraph = styled('p')({
  fontSize: 16,
  fontWeight: 500,
  color: '#010101',
  marginBottom: 25,
  lineHeight: 1.3,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

const CircleTextContainer = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const CircleTitle = styled('h4')(({ theme }) => ({
  color: theme.colors.gray[400],
  fontSize: 48,
  fontWeight: 900,
}));

const CircleText = styled('p')({
  fontSize: 16,
  color: '#010101',
  textAlign: 'center',
  fontWeight: 500,
  marginTop: 12,
  lineHeight: 1.3,
  width: '80%',
});

export const ImportanceOfStagingTraditionalPdf: React.FC = () => {
  const { category } = useCategory();
  const primary = useUiConfigColorsPrimary();

  const img = React.useMemo(() => svgStringToDataUrl(pdfGraphics, primary), [primary]);

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <BackgroundImage>
        <Circles src={img} />
        <Title>{traditionalTitle}</Title>
        <TextContainer>
          {text.map((textSection, i) => {
            const { description } = textSection;
            return <Paragraph key={i}>{description}</Paragraph>;
          })}
        </TextContainer>
        <CircleTextContainer style={{ width: 310.5, height: 310.5, top: 84, left: 455 }}>
          <CircleTitle>{list.listItems[1].percentage}</CircleTitle>
          <CircleText>{list.listItems[1].description}</CircleText>
        </CircleTextContainer>
        <CircleTextContainer style={{ width: 260.5, height: 260.5, top: 390, left: 49 }}>
          <CircleTitle>{list.listItems[0].percentage}</CircleTitle>
          <CircleText>{list.listItems[0].description}</CircleText>
        </CircleTextContainer>
        <CircleTextContainer style={{ width: 247.5, height: 247.5, top: 599, left: 500 }}>
          <CircleTitle>{list.listItems[2].percentage}</CircleTitle>
          <CircleText>{list.listItems[2].description}</CircleText>
        </CircleTextContainer>
      </BackgroundImage>
    </PdfFrame>
  );
};
