import { TProperty } from '../../../../types';

enum FeatureLabel {
  Pool = 'Pool',
  View = 'View',
  WaterFront = 'Water front',
  WaterView = 'Water view',
  Deck = 'Deck',
  FixerUpper = 'Fixer upper',
  NewConstruction = 'New construction',
  Adult55 = 'Adult 55+',
  GreenEnergy = 'Green/Energy Star',
  HorseProperty = 'Horse Property',
  GolfCourse = 'Golf Course',
  AirCondition = 'Air Condition',
  MasterOnMain = 'Master on Main',
}

type FeatureItem = {
  label: FeatureLabel;
  key: keyof TProperty;
};

const featuresList: FeatureItem[] = [
  {
    label: FeatureLabel.Pool,
    key: 'pool',
  },
  {
    label: FeatureLabel.View,
    key: 'view',
  },
  {
    label: FeatureLabel.WaterFront,
    key: 'isWaterFront',
  },
  {
    label: FeatureLabel.WaterView,
    key: 'isWaterView',
  },
  {
    label: FeatureLabel.Deck,
    key: 'isDeck',
  },
  {
    label: FeatureLabel.FixerUpper,
    key: 'isFixerUpper',
  },
  {
    label: FeatureLabel.NewConstruction,
    key: 'isNewConstruction',
  },
  {
    label: FeatureLabel.Adult55,
    key: 'isAdult55',
  },
  {
    label: FeatureLabel.GreenEnergy,
    key: 'isGreenEnergyStar',
  },
  {
    label: FeatureLabel.HorseProperty,
    key: 'isHorseProperty',
  },
  {
    label: FeatureLabel.GolfCourse,
    key: 'isGolfCourse',
  },
  {
    label: FeatureLabel.AirCondition,
    key: 'isAirConditioning',
  },
  {
    label: FeatureLabel.MasterOnMain,
    key: 'isMasterOnMain',
  },
];

export const getPropertyFeatures = (property: TProperty) => {
  return featuresList.filter(feature => property?.[feature.key]);
};
