import { toast } from 'react-toastify';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { DynamicSlidesApi } from '../../api/DynamicSlidesApi';
import { useUserCustomizations } from '../useUserCategoryCustomizations';
import { useInvalidateCustomizations } from '../../data/customizations/useRawCustomizations';

export const useDeleteDynamicSlide = (hash: string, entityId: number | null) => {
  const { data: customizationData } = useUserCustomizations(hash, entityId);
  const invalidateCustomizations = useInvalidateCustomizations();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (slideId: string) => DynamicSlidesApi.delete(slideId, hash, entityId, customizationData),
    {
      onSuccess: data => {
        queryClient.setQueryData(['dynamicSlides', hash], data.slides);
        invalidateCustomizations(hash);
      },
      onError: (err: any) => {
        toast.error(`Error while deleting dynamic slide: ${err.message}`);
      },
    },
  );
  return mutation;
};
