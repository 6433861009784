export default `<svg width="1640" height="1024" viewBox="0 0 1640 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1641" height="1024" fill="#027B76"/>
<rect width="1641" height="1024" fill="url(#paint0_linear)"/>
<g filter="url(#filter0_d)">
<path d="M0 0H1641V181L820.5 463.75L0 746.5L0 0Z" fill="white"/>
</g>
<path d="M1661 1052L884 1052L1208 711L1661 499L1661 1052Z" fill="#027B76"/>
<path d="M1661 1052L884 1052L1208 711L1661 499L1661 1052Z" fill="white" fill-opacity="0.9"/>
<defs>
<filter id="filter0_d" x="-25" y="-5" width="1691" height="796.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="20"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<linearGradient id="paint0_linear" x1="820.5" y1="0" x2="820.5" y2="1024" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>
`;
