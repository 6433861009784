import React from 'react';
import { Provider } from 'react-redux';

import { useStore } from './store';

interface ServerStoreProviderProps {
  initState: any;
}
export const ServerStoreProvider: React.FC<ServerStoreProviderProps> = ({
  initState,
  children,
}) => {
  const store = useStore(initState);

  return <Provider store={store}>{children}</Provider>;
};
