export const TRACKING_EVENTS = {
  ELEMENT_CLICKED: 'ELEMENT_CLICKED',
  NEXT_STEPS_ELEMENT_CLICKED: 'NEXT_STEPS_ELEMENT_CLICKED',
  COMPS_ELEMENT_CLICKED: 'COMPS_ELEMENT_CLICKED',
  GAUGES_ELEMENT_CLICKED: 'GAUGES_ELEMENT_CLICKED',
  PROPERTY_RATING_ELEMENT_CLICKED: 'PROPERTY_RATING_ELEMENT_CLICKED',
} as const;

export const NEXT_STEPS_TYPE = {
  LETS_GET_STARTED: 'LETS_GET_STARTED',
  MORE_INFO: 'MORE_INFO',
} as const;

export type TTrackingEventType =
  | typeof TRACKING_EVENTS.ELEMENT_CLICKED
  | typeof TRACKING_EVENTS.GAUGES_ELEMENT_CLICKED
  | typeof TRACKING_EVENTS.COMPS_ELEMENT_CLICKED
  | typeof TRACKING_EVENTS.NEXT_STEPS_ELEMENT_CLICKED
  | typeof TRACKING_EVENTS.PROPERTY_RATING_ELEMENT_CLICKED;

type TTrackingAdditionalInfoType =
  | typeof NEXT_STEPS_TYPE.LETS_GET_STARTED
  | typeof NEXT_STEPS_TYPE.MORE_INFO
  | null;

export type TTrackingEvent = {
  type: TTrackingEventType;
  description: string;
  additional_type: string | null;
  property_id?: number | string;
  property_rating?: string;
};

export class TrackingEvent {
  private static eventId = 'trackingEvent';

  public static click(
    description: string,
    type: TTrackingEventType = TRACKING_EVENTS.ELEMENT_CLICKED,
    additionalType: TTrackingAdditionalInfoType = null,
  ) {
    const payload: TTrackingEvent = {
      type: type,
      additional_type: additionalType,
      description,
    };

    const event = new CustomEvent(TrackingEvent.eventId, { detail: payload });
    window.document.dispatchEvent(event);
  }

  public static clickRating(
    description: string,
    property_id: number | string,
    property_rating: string,
  ) {
    const payload: TTrackingEvent = {
      type: TRACKING_EVENTS.PROPERTY_RATING_ELEMENT_CLICKED,
      additional_type: null,
      description,
      property_id,
      property_rating,
    };

    const event = new CustomEvent(TrackingEvent.eventId, { detail: payload });
    window.document.dispatchEvent(event);
  }

  public static subscribe(handlerFunction: EventListener) {
    window.document.addEventListener(TrackingEvent.eventId, handlerFunction);
  }

  public static unsubscribe(handlerFunction: EventListener) {
    window.document.removeEventListener(TrackingEvent.eventId, handlerFunction);
  }
}
