import React from 'react';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { SingleImagePdf } from '../singleImage/SingleImagePdf';
import { CATEGORIES } from '../../../../../../constants/categories';

export const SingleImageWithFramePdf: React.FC = () => {
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SingleImagePdf isWithFrame />
    </PdfFrame>
  );
};
