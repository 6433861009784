import styled from 'styled-components';

import { Typography, Input, Button } from '../../../components/Common';

export { Typography, Input, Button };

export const SectionContainer = styled('section')({
  backgroundColor: '#fff',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
  borderRadius: 10,
  marginTop: 40,
  padding: '31px 29px 38px 29px',
});

export const Content = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: 8,
  marginTop: 18,
  height: 700,
  display: 'flex',
  flexDirection: 'column',
}));

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  overflow: 'scroll',
});

export const ContentHeader = styled('div')({
  padding: '20px 25px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const ButtonText = styled('p')({
  fontSize: 13,
});
