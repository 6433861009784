import React from 'react';
import { useSetAtom } from 'jotai';
import { heatmapDataAtom } from './heatmapAtom';

export const useResetHeatmapData = () => {
  const setHeatmapData = useSetAtom(heatmapDataAtom);

  return React.useCallback(() => {
    setHeatmapData({
      clicks: [],
      movements: [],
    });
  }, [setHeatmapData]);
};
