import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FitContentModal } from '../../../components/Common';
import { AddClient } from '../../../components/Common/AddClient/AddClient.component';
import { TClient, TPresentationType } from '../../../types';

import { Container, Text } from './SaveReportMessageModal.styles';

interface SaveReportMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  presentationId?: string;
  presentationClient: TClient | null;
  setCreatedPresentationClient: Dispatch<SetStateAction<any>>;
  presentationType?: TPresentationType | null;
  hash?: string;
}

export const SaveReportMessageModal: React.FC<SaveReportMessageModalProps> = ({
  isOpen,
  onClose,
  presentationClient,
  presentationId,
  setCreatedPresentationClient,
  presentationType,
  hash,
}) => {
  const [openInfoModal, setOpenInfoModal] = useState(!!presentationClient?.id);

  useEffect(() => {
    setOpenInfoModal(!!presentationClient?.id);
  }, [presentationClient]);

  const onCloseAddClient = () => {
    setOpenInfoModal(true);
  };

  const onCloseInfoModal = () => {
    setOpenInfoModal(false);
    onClose();
  };
  if (!presentationId) return null;

  if (!presentationClient?.id && !openInfoModal) {
    return (
      <AddClient
        isOpen={isOpen}
        onClose={onCloseAddClient}
        presentationClient={presentationClient}
        id={presentationId}
        getSavedClient={client => setCreatedPresentationClient(client)}
        presentationType={presentationType}
        hash={hash}
      />
    );
  }

  return (
    <FitContentModal
      isOpen={openInfoModal}
      headerTitle='Save CMA Only'
      onClose={onCloseInfoModal}
      confirmBtnText='OK'
      onConfirmClick={onCloseInfoModal}
    >
      <Container>
        <Text>The report has been saved to Past Reports.</Text>
      </Container>
    </FitContentModal>
  );
};
