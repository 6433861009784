import { useState, useEffect } from 'react';

type Options = {
  onLoad?: () => void;
  enabled?: boolean;
};

export function useScript(src: string, { onLoad, enabled = true }: Options) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    if (!enabled) return;
    // Allow for aborting this fetch
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const onError = () => setStatus('error');
    const onLoadInternal = () => {
      setStatus('ready');
      onLoad?.();
    };

    script.addEventListener('load', onLoadInternal);
    script.addEventListener('error', onError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', onLoadInternal);
      script.removeEventListener('error', onError);
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, enabled]);

  return status;
}
