import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';
import starCS from '../../../../assets/images/star_coming_soon.svg?url';
import starCSBT from '../../../../assets/images/star_coming_soon_bt.svg?url';
import starActive from '../../../../assets/images/star_active.svg?url';
import starPending from '../../../../assets/images/star_pending.svg?url';
import starClosed from '../../../../assets/images/star_closed.svg?url';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface IconP {
  status: 'a' | 'c' | 'p' | 'coming_soon';
  size: number;
  text: string | number;
}

export const StarIcon: React.FC<IconP> = ({ status, size = 25, text }) => {
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const icon = useMemo(() => {
    if (status === 'a') {
      return starActive;
    }
    if (status === 'p') {
      return starPending;
    }
    if (status === 'c') {
      return starClosed;
    }
    if (status === 'coming_soon') {
      return shouldUseBtTheme ? starCSBT : starCS;
    }
  }, [shouldUseBtTheme, status]);

  return (
    <IconContainer style={{ position: 'relative' }}>
      <div style={{ position: 'relative', marginRight: 4 }}>
        <img src={icon} alt='star' style={{ width: size, height: 'auto' }} />
      </div>
      <span style={{ fontSize: size * 0.65 }}>{text}</span>
    </IconContainer>
  );
};
