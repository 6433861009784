import React, { createContext, useContext, useEffect, useState } from 'react';

import { TEmphasisConfig, TEmphasisMeta } from './types';
import { useEmphasisConfigs } from './hooks/useEmphasisConfigs';
import { stringifySlidesOrdering } from './services/stringifySlidesOrdering';
import { AppliedEmphasisExtractor } from './services/AppliedEmphasisExtractor';
import { ConfigItem } from '../../../../../../providers/providers/OrderProvider';
import { usePresentationMeta } from '../../../../../../providers/providers/PresentationMetaProvider';

type TEmphasisContext = {
  emphasis: TEmphasisMeta | null;
  setEmphasis: (e: TEmphasisMeta | null) => void;
  emphasisConfigs: TEmphasisConfig | null;
  calculateInitialEmphasis: (s: ConfigItem) => void;
};
const EmphasisContext = createContext<TEmphasisContext | undefined>(undefined);

export function useEmphasis() {
  const context = useContext(EmphasisContext);

  if (!context) {
    throw new Error('useEmphasis must be used within a EmphasisContext!');
  }

  return context;
}

export const EmphasisProvider: React.FC = ({ children }) => {
  const emphasisConfigs = useEmphasisConfigs();

  const [emphasis, setEmphasis] = useState<TEmphasisMeta | null>(null);

  const calculateInitialEmphasis = React.useCallback(
    (orderConfig: ConfigItem) => {
      if (emphasis || !emphasisConfigs || !orderConfig) return;

      const activeE = AppliedEmphasisExtractor.getBasedOnSectionsOrder(
        orderConfig.sections,
        emphasisConfigs,
      );

      const currentSlidesOrdering = stringifySlidesOrdering(orderConfig);

      if (
        activeE?.value === 'defaultOrder' &&
        !(activeE?.slidesOrder ?? '').endsWith(currentSlidesOrdering)
      ) {
        return setEmphasis(null);
      }

      setEmphasis(activeE);
    },
    [emphasis, emphasisConfigs],
  );

  useEffect(() => {
    if (emphasis?.value !== 'defaultOrder' || !emphasisConfigs?.configs.defaultOrder) return;

    setEmphasis(emphasisConfigs?.configs.defaultOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emphasisConfigs]);

  const value = React.useMemo(
    () => ({ emphasis, setEmphasis, emphasisConfigs, calculateInitialEmphasis }),
    [calculateInitialEmphasis, emphasis, emphasisConfigs],
  );

  return <EmphasisContext.Provider value={value}>{children}</EmphasisContext.Provider>;
};
