import React, { createContext, useCallback, useContext, useState } from 'react';

interface StepState {
  step: number;
  toNextStep: () => void;
  toPreviousStep: () => void;
}

const initStep: StepState = {
  step: 1,
  toNextStep: () => {},
  toPreviousStep: () => {},
};

const StepContext = createContext<StepState>(initStep);

const useStepInternal = () => {
  const [step, setStep] = useState(1);

  const toNextStep = useCallback(function toNextStep() {
    setStep(state => state + 1);
  }, []);

  const toPreviousStep = useCallback(function toPreviousStep() {
    setStep(state => state - 1);
  }, []);

  return React.useMemo(
    () => ({ step, toNextStep, toPreviousStep }),
    [step, toNextStep, toPreviousStep],
  );
};

export const useStep = () => {
  const context = useContext(StepContext);

  if (!context) throw new Error('useStep cannot be used without StepContext');

  return context;
};

export const StepProvider: React.FC = ({ children }) => {
  const value = useStepInternal();

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};
