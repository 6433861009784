import Frame from './LuxuryFrameBeta';
import thumbnail from './luxuryBeta.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'luxuryBeta';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TLuxuryFrameBeta = typeof id;
export default config;
