import React from 'react';
import { CATEGORIES } from '../../../../../../constants/categories';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { Container, ImagePreview, ImageWrapper } from './FourImagesAlphaLayout.styles';
import { useSlide } from '../../../../providers/SlideProvider';
import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { ImageProxy } from '../../../../services/ImageProxy';

interface Props {
  hideHeading?: boolean;
}

export const FourImagesAlphaPdf: React.FC<Props> = ({ hideHeading }) => {
  const { category } = useCategory();
  const slide = useSlide();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container isPdf style={{ gridTemplateRows: hideHeading ? '1fr 1fr' : 'auto 1fr 1fr' }}>
        {!hideHeading && (
          <SlideTextBlock
            headline={slide.data?.headline}
            isHeadlineCenter
            isEditable={false}
            containerStyles={{ gridColumn: '1 / -1', gridRow: ' 1 / 1' }}
          />
        )}
        <ImageWrapper>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image, { width: 750 })} />
        </ImageWrapper>
        <ImageWrapper>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image2, { width: 750 })} />
        </ImageWrapper>
        <ImageWrapper>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image3, { width: 750 })} />
        </ImageWrapper>
        <ImageWrapper>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image4, { width: 750 })} />
        </ImageWrapper>
      </Container>
    </PdfFrame>
  );
};

export default FourImagesAlphaPdf;
