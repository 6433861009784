import { TProperty } from '../../../../../types';

export const appendAdjustedPerSqft = (properties?: TProperty[] | null) => {
  if (!properties) return null;

  return properties?.map(p => {
    if (p.adjustedPrice && p.size) {
      return {
        ...p,
        adjustedPricePerSqft: Math.round((p.adjustedPrice || 0) / p.size),
      };
    }

    return p;
  });
};
