interface MonthData {
  name: string;
  count: number;
  count_per_agent: number;
  open_rate: number;
}
interface SellerBreakdown {
  competitive_market: number;
  hoping_to_list_for_more: number;
  meeting_with_other_agents: number;
  other: number;
  time_it_will_take_to_sell: number;
}
interface BuyerBreakdown {
  competitive_market: number;
  hoping_to_buy_for_less: number;
  meeting_with_other_agents: number;
  other: number;
  time_it_will_take_to_buy: number;
}
interface User {
  hash: string;
  last_presentation: number | null;
  name: string;
  open_rate: number;
  presentation_count: number;
  top_seller_barrier: string;
  top_feedback: string;
}
interface ReportPerMonth {
  months: MonthData[];
  total: number;
  total_open_rate: number;
  total_per_agent: number;
}

export interface ApiResponse {
  office: {
    reports_per_month: ReportPerMonth;
    tracking: {
      buyer: {
        lets_get_started: number;
        more_info: number;
        more_info_breakdown: BuyerBreakdown;
      };
      seller: {
        lets_get_started: number;
        more_info: number;
        more_info_breakdown: SellerBreakdown;
      };
    };
    users: User[];
  };
  user: {
    reports_per_month: ReportPerMonth;
    tracking: {
      buyer: {
        lets_get_started: number;
        more_info: number;
        more_info_breakdown: BuyerBreakdown;
      };
      seller: {
        lets_get_started: number;
        more_info: number;
        more_info_breakdown: SellerBreakdown;
      };
    };
    user: {
      name: string;
    };
  };
}
export const mapData = (apiData: ApiResponse) => {
  return {
    reportsSent: {
      reportsSentData: apiData?.office.reports_per_month.months.map((month: MonthData, index) => {
        return {
          month: month.name,
          office: month.count,
          agent: apiData?.user.reports_per_month.months[index].count,
        };
      }),
    },
    reportsSentPerAgent: {
      averageSentReports: 0,
      reportsSentPerAgentData: apiData?.office.reports_per_month?.months.map(
        (month: MonthData, index) => {
          return {
            month: month.name,
            office: month.count_per_agent,
            agent: apiData?.user.reports_per_month.months[index].count,
          };
        },
      ),
    },
    reportsOpenRate: {
      reportOpenRateData: apiData?.office.reports_per_month.months.map(
        (month: MonthData, index) => {
          return {
            month: month.name,
            office: Math.round(month.open_rate * 100),
            agent: Math.round(apiData?.user.reports_per_month.months[index].open_rate * 100),
          };
        },
      ),
    },
  };
};

export const mapTrackingData = (apiData: ApiResponse) => {
  return {
    nextStepsFeedback: {
      sellers: [
        {
          title: 'Lets get started',
          office: Math.round(apiData.office.tracking.seller.lets_get_started * 100) + '%',
          agent: Math.round(apiData.user.tracking.seller.lets_get_started * 100) + '%',
        },
        {
          title: 'Need more time',
          office: Math.round(apiData.office.tracking.seller.more_info * 100) + '%',
          agent: Math.round(apiData.user.tracking.seller.more_info * 100) + '%',
        },
      ],
      buyers: [
        {
          title: 'Lets get started',
          office: Math.round(apiData.office.tracking.buyer.lets_get_started * 100) + '%',
          agent: Math.round(apiData.user.tracking.buyer.lets_get_started * 100) + '%',
        },
        {
          title: 'Need more time',
          office: Math.round(apiData.office.tracking.buyer.more_info * 100) + '%',
          agent: Math.round(apiData.user.tracking.buyer.more_info * 100) + '%',
        },
      ],
    },
    sellerBarrierBreakdown: [
      {
        title: 'Time it will take to sell',
        office:
          Math.round(
            apiData.office.tracking.seller.more_info_breakdown.time_it_will_take_to_sell * 100,
          ) + '%',
        agent:
          Math.round(
            apiData.user.tracking.seller.more_info_breakdown.time_it_will_take_to_sell * 100,
          ) + '%',
      },
      {
        title: 'Competitive Market',
        office:
          Math.round(apiData.office.tracking.seller.more_info_breakdown.competitive_market * 100) +
          '%',
        agent:
          Math.round(apiData.user.tracking.seller.more_info_breakdown.competitive_market * 100) +
          '%',
      },
      {
        title: 'Hoping to list for more',
        office:
          Math.round(
            apiData.office.tracking.seller.more_info_breakdown.hoping_to_list_for_more * 100,
          ) + '%',
        agent:
          Math.round(
            apiData.user.tracking.seller.more_info_breakdown.hoping_to_list_for_more * 100,
          ) + '%',
      },
      {
        title: 'Meeting with other agents',
        office:
          Math.round(
            apiData.office.tracking.seller.more_info_breakdown.meeting_with_other_agents * 100,
          ) + '%',
        agent:
          Math.round(
            apiData.user.tracking.seller.more_info_breakdown.meeting_with_other_agents * 100,
          ) + '%',
      },
      {
        title: 'Other',
        office: Math.round(apiData.office.tracking.seller.more_info_breakdown.other * 100) + '%',
        agent: Math.round(apiData.user.tracking.seller.more_info_breakdown.other * 100) + '%',
      },
    ],
    buyerBarrierBreakdown: [
      {
        title: 'Time it will take to buy',
        office:
          Math.round(
            apiData.office.tracking.buyer.more_info_breakdown.time_it_will_take_to_buy * 100,
          ) + '%',
        agent:
          Math.round(
            apiData.user.tracking.buyer.more_info_breakdown.time_it_will_take_to_buy * 100,
          ) + '%',
      },
      {
        title: 'Competitive Market',
        office:
          Math.round(apiData.office.tracking.buyer.more_info_breakdown.competitive_market * 100) +
          '%',
        agent:
          Math.round(apiData.user.tracking.buyer.more_info_breakdown.competitive_market * 100) +
          '%',
      },
      {
        title: 'Hoping to buy for less',
        office:
          Math.round(
            apiData.office.tracking.buyer.more_info_breakdown.hoping_to_buy_for_less * 100,
          ) + '%',
        agent:
          Math.round(apiData.user.tracking.buyer.more_info_breakdown.hoping_to_buy_for_less * 100) +
          '%',
      },
      {
        title: 'Meeting with other agents',
        office:
          Math.round(
            apiData.office.tracking.buyer.more_info_breakdown.meeting_with_other_agents * 100,
          ) + '%',
        agent:
          Math.round(
            apiData.user.tracking.buyer.more_info_breakdown.meeting_with_other_agents * 100,
          ) + '%',
      },
      {
        title: 'Other',
        office: Math.round(apiData.office.tracking.buyer.more_info_breakdown.other * 100) + '%',
        agent: Math.round(apiData.user.tracking.buyer.more_info_breakdown.other * 100) + '%',
      },
    ],
  };
};
