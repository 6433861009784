import { FC, useEffect } from 'react';

import { withProviders } from '../../../../../../../../hoc/withProviders';

import { PRESENTATION_TYPES } from '../../../../../../../../constants/PresentationTypes';

import { PropsContent } from './types';

import { AnalyticsModalContent } from './AnalyticsModalContent';

import {
  CreatedAtProvider,
  useCreatedAt,
} from '../../../../../../../../providers/providers/CreatedAtProvider';

import { OrderProvider } from '../../../../../../../../providers/providers/OrderProvider';
import { ClientProvider } from '../../../../../../../../providers/providers/ClientProvider';
import { SlidesProvider } from '../../../../../../../../providers/providers/SlidesProvider';
import { CategoryProvider } from '../../../../../../../../providers/providers/CategoryProvider';
import { UiConfigProvider } from '../../../../../../../../providers/providers/UiConfigProvider';
import { SectionsProvider } from '../../../../../../../../providers/providers/SectionsProvider';
import { PropertiesProvider } from '../../../../../../../../providers/providers/PropertiesProvider';
import { SelectedSlideProvider } from '../../../../../../../../providers/providers/SelectedSlideProvider';
import { ActiveSectionProvider } from '../../../../../../../../components/Slide/providers/ActiveSectionProvider';
import {
  PresentationTypeProvider,
  usePresentationType,
} from '../../../../../../../../providers/providers/PresentationTypeProvider';
import {
  PresentationModeProvider,
  usePresentationMode,
} from '../../../../../../../../providers/providers/PresentationModeProvider';

export const AnalyticsModalContentProvider: FC<PropsContent> = withProviders<PropsContent>(
  OrderProvider,
  ClientProvider,
  SlidesProvider,
  SectionsProvider,
  CreatedAtProvider,
  PropertiesProvider,
  ActiveSectionProvider,
  SelectedSlideProvider,
  PresentationModeProvider,
  PresentationTypeProvider,
)(({ presentation: presentationConfig, onCloseModal }) => {
  const { presentationMode, setPresentationMode } = usePresentationMode();
  const { setPresentationType } = usePresentationType();
  const { setCreatedAt } = useCreatedAt();

  useEffect(() => {
    setPresentationMode((presentationConfig as any)?.mode ?? 'regular');
    setPresentationType(presentationConfig?.type ?? PRESENTATION_TYPES.WIN_THE_LISTING);
    setCreatedAt(presentationConfig?.createdAt as number);
  }, [presentationConfig, setCreatedAt, setPresentationMode, setPresentationType]);

  if (!presentationMode) return null;

  return (
    <CategoryProvider initValue={presentationConfig?.category}>
      <UiConfigProvider initValue={presentationConfig?.ui}>
        <AnalyticsModalContent presentation={presentationConfig} onCloseModal={onCloseModal} />
      </UiConfigProvider>
    </CategoryProvider>
  );
});
