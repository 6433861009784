export const data = [
  {
    room: 'GUEST ROOM',
    'Very Important': 8,
    'Somewhat Important': 22,
    Neutral: 46,
    'Not Very Important': 17,
    'Not At All Important': 7,
  },

  {
    room: "CHILDREN'S ROOM",
    'Very Important': 12,
    'Somewhat Important': 36,
    Neutral: 30,
    'Not Very Important': 16,
    'Not At All Important': 6,
  },
  {
    room: 'BATHROOM',
    'Very Important': 20,
    'Somewhat Important': 28,
    Neutral: 30,
    'Not Very Important': 15,
    'Not At All Important': 7,
  },
  {
    room: 'YARD/OUTSIDE SPACE',
    'Very Important': 22,
    'Somewhat Important': 33,
    Neutral: 28,
    'Not Very Important': 12,
    'Not At All Important': 5,
  },
  {
    room: 'DINING ROOM',
    'Very Important': 24,
    'Somewhat Important': 41,
    Neutral: 20,
    'Not Very Important': 10,
    'Not At All Important': 5,
  },
  {
    room: 'KITCHEN',
    'Very Important': 32,
    'Somewhat Important': 22,
    Neutral: 20,
    'Not Very Important': 18,
    'Not At All Important': 8,
  },
  {
    room: 'MASTER BEDROOM',
    'Very Important': 44,
    'Somewhat Important': 28,
    Neutral: 22,
    'Not Very Important': 4,
    'Not At All Important': 2,
  },
  {
    room: 'LIVING ROOM',
    'Very Important': 48,
    'Somewhat Important': 33,
    Neutral: 15,
    'Not Very Important': 3,
    'Not At All Important': 1,
  },
];

export const heading = 'Importance of rooms staged for buyers';

export const text = [
  {
    title: 'What is Staging?',
    description:
      'Home staging is the preparation of a private residence for sale in the real estate marketplace. ',
  },
  {
    title: 'Why stage a home?',
    description:
      'The goal of staging is to make a home appealing to the highest number of potential buyers, thereby selling a property more swiftly and for more money. ',
  },
];

export const list = {
  listTitle: 'NOTES:',
  listItems: [
    {
      percentage: '83%',
      description:
        'of buyers’ agents said staging a home made it easier for a buyer to visualize the property as a future home.',
    },
    {
      percentage: '25%',
      description:
        'of buyer’s agents said that staging a home increased the dollar value offered between one and five percent, compared to other similar homes on the market that are not staged.',
    },
    {
      percentage: '40%',
      description:
        'of buyers’ agents cited that home staging had an effect on most buyers’ view of the home. ',
    },
  ],
};

export const source = 'National Association of Realtors';

export const traditionalTitle = 'Staging';
