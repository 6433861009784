import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { OneImageAlpha } from './OneImageAlpha';
import { OneImageAlphaForm } from './OneImageAlphaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { OneImageAlphaPdf } from './OneImageAlphaPdf';

const template = 'oneImageLayoutAlpha';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - One Image',
  frame: true,
  data: {
    image: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: OneImageAlphaForm,
  web: OneImageAlpha,
  pdf: OneImageAlphaPdf,
};

export type TOneImageLayoutAlpha = typeof template;
export { config, mappings, thumbnail };
