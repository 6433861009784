import { useEffect, useState } from 'react';
import { TSlidePlatform } from '../types';

const predefined = {
  web: {
    width: 1600,
    height: 900,
  },
  pdf: {
    width: 1000,
    height: 1294,
  },
};

export const useCalculateScaleFactor = ({
  current,
  platform = 'web',
  forceRerenderDependencies = [],
}: {
  current: HTMLDivElement | null;
  platform?: TSlidePlatform;
  forceRerenderDependencies?: any[];
}) => {
  const [scaleFactor, setScaleFactor] = useState(0);
  const [forceRerender, setForceRerender] = useState(false);

  const onResize = () => {
    if (!current) return;

    const clientWidth = platform === 'web' ? current.clientWidth - 2 : current.clientWidth; // take into account borders
    const preferedWidth = platform === 'web' ? predefined.web.width : predefined.pdf.width;

    setScaleFactor(clientWidth / preferedWidth);
  };

  useEffect(() => {
    if (scaleFactor === 0 && !current && !forceRerender) setForceRerender(true);
    onResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleFactor, forceRerender, ...forceRerenderDependencies]);

  useEffect(() => {
    if (!current) return;

    window.addEventListener('resize', onResize);
    window.screen.orientation?.addEventListener?.('change', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      window.screen.orientation?.removeEventListener?.('change', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, platform]);

  return { scaleFactor, predefined };
};
