import { TablePropertyStatus } from '../../../../types';

const tableConfig = Object.freeze({
  address: 'Address',
  price: 'List price',
  salePrice: 'Sale price',
  bd: 'BR',
  bth: 'BA',
  sqft: 'Sqft',
  pricePerSq: '$/Sq',
  built: 'Built',
  listDate: 'List Date',
  cdom: 'DOM',
});

export const getTableColumns = (type: TablePropertyStatus, isDTC: boolean) => {
  if (type === 'sold') {
    return { ...tableConfig, listDate: 'Sold Date', cdom: isDTC ? 'Days To Close' : 'DOM' };
  }

  if (type === 'pending') {
    return { ...tableConfig, cdom: '' };
  }

  return tableConfig;
};

export const getTableTitle = (type: TablePropertyStatus) => {
  const titles = {
    active: 'Active',
    pending: 'Pending',
    sold: 'Closed',
    comingSoon: 'Coming Soon',
  };

  return titles[type];
};
