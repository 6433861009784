import React from 'react';

import { CommonFrameProps } from '../../../../../../../../types';
import { PersonalSlidesLayout } from '../../PersonalSlidesLayout';
import { useUser } from '../../../../../../providers/UserProvider';
import { useSlide, useSlideProps } from '../../../../../../providers/SlideProvider';
import { getTestimonials } from '../../../../../../../../services/getTestimonials';
import { TestimonialContent } from '../TestimonialContent/TestimonialContent.component';
import { ImageWrapper, ReadMoreLink, TestimonialWrapper, Icon } from './MyTestimonials.styles';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';

export const MyTestimonials: React.FC<CommonFrameProps> = () => {
  const slide = useSlide();

  const { isEditable } = useSlideProps();

  const { testimonials: userApiTestimonials } = useUser();
  const primaryColor = useUiConfigColorsPrimary();

  if (!slide) return null;
  const { links, testimonials } = slide.data!;
  if (!testimonials || !links) return null;

  const slideTestimonials = getTestimonials(testimonials, userApiTestimonials);
  const getLink = (link: string | null) => {
    if (!link || link.startsWith('http' || link.startsWith('https'))) {
      return link;
    }
    return `https://${link}`;
  };
  return (
    <PersonalSlidesLayout slideId='testimonials' isEditable={isEditable}>
      <div id='testimonialsContent'>
        <ImageWrapper>
          <Icon iconName='quote' style={{ width: '100%', height: 'auto' }} fill={primaryColor} />
        </ImageWrapper>
        <TestimonialWrapper>
          {slideTestimonials.map((testimonial, i) => (
            <TestimonialContent testimonial={testimonial} key={i} />
          ))}
        </TestimonialWrapper>
        {links.map((linkItem, i) => (
          <ReadMoreLink target='_blank' key={i} href={getLink(linkItem.url) || ''}>
            {linkItem.title}
          </ReadMoreLink>
        ))}
      </div>
    </PersonalSlidesLayout>
  );
};
