import React from 'react';
import styled from 'styled-components';
import { Button } from '../V2/Button/Button';

import { Modal } from '../Modal/Modal';

const Content = styled('div')(({ theme }) => ({
  minHeight: 106,
  position: 'relative',
  overflow: 'auto',
  padding: 20,
}));

const ModalFooter = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.gray[200]}`,
  display: 'flex',
  height: '60px',
  alignItems: 'center',
  padding: '0 10px',
  gap: 10,
}));

interface Props {
  heading: string;
  open: boolean;
  actionButtonText: string;
  actionButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
  footerActionsPosition?: 'left' | 'right';
  zIndex?: number;
  onClose: () => void;
  onAction: () => void;
  onCancel?: () => void;
}

export const QuickActionsModal: React.FC<Props> = ({
  open,
  heading,
  children,
  actionButtonText = 'Submit',
  actionButtonDisabled,
  cancelButtonDisabled,
  footerActionsPosition = 'left',
  zIndex,
  onAction,
  onCancel,
  onClose,
}) => {
  if (!open) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      isBlueHeader
      title={heading}
      zIndex={zIndex}
      modalContainerStyles={{
        alignSelf: 'center',
        minWidth: 681,
        maxWidth: 1045,
        height: 'fit-content',
        maxHeight: '100vh',
      }}
      contentStyles={{ height: 'max-content' }}
    >
      <Content>{children}</Content>
      <ModalFooter
        style={{ flexDirection: footerActionsPosition === 'left' ? 'row' : 'row-reverse' }}
      >
        <Button variant='primary' onClick={onAction} disabled={actionButtonDisabled}>
          {actionButtonText}
        </Button>
        {onCancel && (
          <Button variant='secondary' onClick={onCancel} disabled={cancelButtonDisabled}>
            Cancel
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
