import styled from 'styled-components';

export const PreviewContainer = styled('div')({
  position: 'relative',
  '&:after': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.15)',
    pointerEvents: 'none',
  },
});

export const Presentation = styled('img')({
  width: '100%',
});

export const ButtonWrapper = styled('div')({
  display: 'block',
  position: 'absolute',
  zIndex: 3,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
