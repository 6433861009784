import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  position: 'relative',
  paddingTop: 60,
  maxWidth: '100%',
});

export const ContentContainer = styled('main')<{ isWhiteBg?: boolean }>(
  ({ theme, isWhiteBg = false }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    backgroundColor: isWhiteBg ? '#fff' : theme.colors.gray[100],
    transition: 'all .4s',
    width: '100%',
    margin: '0 auto',
    position: 'relative',
  }),
);
