import React from 'react';
import styled from 'styled-components';
import WarningConfirmPopup from '../../../../../../components/Common/WarningConfirmPopup/WarningConfirmPopup';
import Loader from '../../../../../../components/Loader/Loader';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { useDeleteDynamicSlide } from '../../../../../../hooks/dynamicSlides/useDeleteDynamicSlide';
import { TSlide } from '../../../../../../types';
import { useCreateSlideProps } from '../../../../providers/CreateSlidePropsProvider';
import { Button } from '../../../../../../components/Common/V2/Button/Button';

const Container = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 8,
});

interface Props {
  slide: TSlide;
}

const SlideActions: React.FC<Props> = ({ slide }) => {
  const { hash } = useUser();
  const { mutate: deleteSlide, isLoading } = useDeleteDynamicSlide(
    hash,
    slide?.entityId as number | null,
  );
  const { editSlide } = useCreateSlideProps();
  const [open, setOpen] = React.useState(false);

  const onDeleteSlide = (slideId: string) => {
    deleteSlide(slideId, {
      onSuccess: () => {
        setOpen(false);
      },
    });
  };

  if (!slide?.template) return null;
  return (
    <Container>
      <Button
        variant='secondary'
        style={{ marginRight: 8, width: 70 }}
        onClick={() => editSlide(slide.id)}
        disabled={isLoading || !slide.isEditable}
      >
        {isLoading ? <Loader color={'#444'} centered /> : 'Edit'}
      </Button>
      <Button
        variant='danger'
        size='small'
        style={{ width: 70 }}
        onClick={() => setOpen(true)}
        disabled={isLoading || !slide.isEditable}
      >
        {isLoading ? <Loader color={'white'} centered /> : 'Delete'}
      </Button>
      {open && (
        <WarningConfirmPopup
          open={open}
          heading={`Delete slide`}
          description={`"${slide.label}" slide will be deleted`}
          isLoading={isLoading}
          onConfirm={() => onDeleteSlide(slide.id)}
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
        />
      )}
    </Container>
  );
};

export default SlideActions;
