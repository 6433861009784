import React from 'react';
import { ProgressTrackerComponentProps } from '../ProgressTracker';

import { Container, Typography, StepsWrapper, Step } from './ModernProgressTracker.styles';

const ModernProgressTracker: React.FC<ProgressTrackerComponentProps> = ({
  label,
  sectionIds,
  activeSectionPosition,
  containerStyles,
}) => (
  <Container style={containerStyles} id='progress-tracker'>
    <Typography variant='h4' align='center'>
      {label}
    </Typography>
    <StepsWrapper>
      {sectionIds.map((s, position) => (
        <Step
          key={s}
          completed={position < activeSectionPosition}
          active={activeSectionPosition === position}
        />
      ))}
    </StepsWrapper>
  </Container>
);

export default ModernProgressTracker;
