import styled from 'styled-components';
import { Textarea } from '../../../components/Common';

export { Textarea };

export const DashboardTextareaEl = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

export const DashboardLabel = styled.label`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.gray[400]};
`;
