import { TPersonalSlideExperienceLevel } from '../../../../../../../types';

export default (experienceLevel: TPersonalSlideExperienceLevel): string => {
  switch (experienceLevel) {
    case 'novice':
      return 'Highlight your hustle, area knowledge, etc';
    case 'experienced':
      return 'Highlight your growth & commitment';
    case 'seasoned':
      return 'Highlight your proven track record';
    default:
      return '';
  }
};
