import React, { useEffect, useState } from 'react';

import {
  TSlideData,
  TPersonalSlideExperienceDefaultText,
  TPersonalSlideExperienceLevel,
} from '../../../../../../../../types';
import getTooltipText from '../../services/getTooltipText';
import { useUser } from '../../../../../../../../components/Slide/providers/UserProvider';
import { ExperienceLevel } from '../../components/ExperienceLevel/ExperienceLevel.component';
import { useSlides } from '../../../../../../../../providers/providers/SlidesProvider';
import { TextEditor } from '../../../../../../../../components/Common/TextEditor/TextEditor.component';
import { Typography } from '../../../CustomizePresentation/CustomizationModal/CustomizationModal.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from '../../../../../../../../components/Common/V2/Accordion';
import { Description, DescriptionWrapper, TextWrapper } from '../UI';

const stripHtml = (text: string) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = text;
  return tmp.textContent || tmp.innerText || '';
};
export const AboutMeExpandible: React.FC = () => {
  const { getSlide, setSlideData } = useSlides();
  const slide = getSlide('aboutMe');

  const [errorMessage, setErrorMessage] = useState('');

  const experienceLevel = slide?.data?.experienceLevel;
  const slideTexts = slide?.data?.experienceDefaultText;

  const { bio } = useUser();
  const slideText = slide?.data?.text ? slide?.data?.text : '';
  const text = stripHtml(slideText).length > 0 ? slideText : bio;

  const [currentAboutMeText, setCurrentAboutMeText] = useState(text);
  const [currentExp, setCurrentExp] = useState(experienceLevel || 'none');
  const [forceUpdateEditor, setForceUpdateEditor] = useState(false);

  useEffect(() => {
    const elHeight = document.getElementById('aboutText')?.clientHeight ?? 0;
    if (elHeight > 818) {
      setErrorMessage(
        '* Your imported kvCORE data is too long and it cannot fit on the slide, or it contains images, which you can manually upload on the predefined slide placement.',
      );
      return;
    }
    setErrorMessage('');
  }, [currentAboutMeText]);

  useEffect(function onMount() {
    if (slide?.data?.text) return;
    onTextChange(currentAboutMeText, currentExp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTextChange = (val: string, currentExperience: TPersonalSlideExperienceLevel) => {
    if (slide?.id !== 'aboutMe') return;

    let slideData = slide?.data || {};

    slideData = {
      ...slideData,
      text: val,
      experienceLevel: currentExperience,
      experienceDefaultText: {
        ...(slideData.experienceDefaultText as TPersonalSlideExperienceDefaultText),
        [currentExperience]: val,
      },
    };

    setCurrentAboutMeText(val);
    setSlideData(slide.id, slideData);
  };

  const onExperienceChange = (exp: TPersonalSlideExperienceLevel) => {
    setCurrentExp(exp);
    if (!slideTexts || exp === currentExp) return;
    onTextChange(slideTexts[exp] || text, exp);
    setForceUpdateEditor(true);
  };

  if (!slide || !slide.data) return null;

  const descriptionText = getTooltipText(currentExp);
  const descriptionArray = Array.isArray(descriptionText) ? descriptionText : [descriptionText];

  return (
    <Accordion
      title='Slide Text'
      open
      prefixIcon={<FontAwesomeIcon icon='text-size' fontSize={15} />}
    >
      {descriptionText && (
        <Description>
          <DescriptionWrapper>
            <FontAwesomeIcon icon='circle-info' color='#fff' />
            <TextWrapper>
              {descriptionArray.map(s => (
                <Typography
                  key={s}
                  variant='small'
                  color='white'
                  style={{ fontWeight: 700, fontSize: 10, lineHeight: 'normal' }}
                >
                  {s}
                </Typography>
              ))}
            </TextWrapper>
          </DescriptionWrapper>
        </Description>
      )}
      <div style={{ padding: '20px' }}>
        <ExperienceLevel
          onChange={val => onExperienceChange(val as TPersonalSlideExperienceLevel)}
          value={experienceLevel ?? 'none'}
        />
        <TextEditor
          onChangeText={val => onTextChange(val, currentExp)}
          rawHtml={currentAboutMeText}
          editorWrapperStyles={{ height: 159 }}
          toolbarOptions={['inline', 'list', 'fontSize', 'textAlign']}
          isRefresh={forceUpdateEditor}
          setIsRefresh={setForceUpdateEditor}
        />
        {errorMessage && (
          <Typography variant={'body1'} color='error'>
            {errorMessage}
          </Typography>
        )}
      </div>
    </Accordion>
  );
};
