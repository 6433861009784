export const getPropertyStatus = status => {
  switch (status) {
    case 'sold':
      return 'c';
    case 'coming_soon':
      return 'coming_soon';
    default:
      return status?.charAt(0).toLowerCase();
  }
};
