import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Modal.module.scss';

export const Modal = ({ isOpen = false, children, onClose, className = '', customStyle }) => {
  const [afterOpen, setAfterOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => setAfterOpen(isOpen));
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDom.createPortal(
    <div className={styles.overlay} onClick={onClose}>
      <div
        onClick={e => e.stopPropagation()}
        className={`${styles.modal} ${afterOpen ? styles.afterOpen : ''} ${className}`}
        style={customStyle}
      >
        <FontAwesomeIcon icon={['far', 'times']} onClick={onClose} className={styles.close} />
        {children}
      </div>
    </div>,
    document.body,
  );
};
