import { TProperty } from '../../../../../types';
import { appendAdjustedPerSqft } from './appendAdjustedPerSqft';
import { getValidPropertyPrice } from './getValidPropertyPrice';

export const getHighestProperty = (
  properties?: TProperty[] | null,
  sqftModeOn = false,
  useAdjustedPrice = false,
) => {
  const propertiesList = appendAdjustedPerSqft(properties?.filter(p => !p.excluded));
  return propertiesList?.reduce((prev, curr) => {
    const prevPrice = getValidPropertyPrice(prev, sqftModeOn, useAdjustedPrice);
    const currPrice = getValidPropertyPrice(curr, sqftModeOn, useAdjustedPrice);

    return prevPrice > currPrice ? prev : curr;
  });
};
