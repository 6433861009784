import React from 'react';

import Logos from '../../../../components/Logos/Logos';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import { HeaderContainer, SlideContentWrapper } from './TraditionalFrameAlpha.styles';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';
import { TraditionalBackground } from '../../../../../TraditionalBackground/TraditionalBackground';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { CommonFrameProps } from '../../../../../../../../types';

const TraditionalFrameAlpha: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => (
  <PlacementAwareContainer infoPlacement={infoPlacement}>
    <HeaderContainer>
      <BrokerInfo
        width='33.333%'
        nameFontStyles={{ fontSize: 18 }}
        contactFontStyles={{ fontSize: 14 }}
        fontColor='#000'
        imageSize={82}
      />
      {!hideProgressTracker && (
        <ProgressTracker
          category={CATEGORIES.TRADITIONAL}
          fontColor='#000'
          containerStyles={{ transform: 'translate(-50%, -32px)' }}
        />
      )}
      <Logos />
    </HeaderContainer>
    <TocPortalContainer offset={-28} zIndex={20} />

    <TraditionalBackground style={{ height: '100%' }} frame>
      <SlideContentWrapper>{children}</SlideContentWrapper>
    </TraditionalBackground>
  </PlacementAwareContainer>
);

export default TraditionalFrameAlpha;
