import Frame from './TraditionalFrameGama';
import thumbnail from './traditionalGama.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'traditionalGama';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TTraditionalFrameGama = typeof id;
export default config;
