import { useAtomValue, useSetAtom } from 'jotai';
import { ButtonsGroup } from '../../../../components/Common/V2/ButtonsGroup';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { pricePerSqftModeAtom } from '../../state/pricePerSqftModeAtom';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';

export const PriceModeSelection = () => {
  const reportProperties = useAtomValue(reportPropertiesAtom);
  const pricePerSqftMode = useAtomValue(pricePerSqftModeAtom);

  const setPricePerSqftMode = useSetAtom(pricePerSqftModeAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primary = useUiConfigColorsPrimary();

  const bgColorStyle = isPresentationView ? { backgroundColor: primary } : {};

  const properties = reportProperties.map(property => property.property);
  const hasPropertiesWithoutSQFT = !!properties.find(property => !property.size);

  const priceModeButtons = [
    {
      label: 'Total Price',
      onClick: () => setPricePerSqftMode(false),
      isActive: !pricePerSqftMode,
      style: !pricePerSqftMode ? bgColorStyle : {},
    },
    {
      label: '$/SQFT',
      onClick: () => setPricePerSqftMode(true),
      isActive: pricePerSqftMode,
      style: pricePerSqftMode ? bgColorStyle : {},
    },
  ];

  return (
    <ButtonsGroup
      buttonsConfigs={priceModeButtons}
      groupWidth={'210px'}
      disabled={hasPropertiesWithoutSQFT}
      label='View as'
    />
  );
};
