import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

import Loader from '../../../../Loader/Loader';
import { MediaApi } from '../../../../../api/MediaApi';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import { UploadImageButton } from '../../../../Common/UploadImageButton/UploadImageButton.component';

export const ImageUploadContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: '#ddd',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
});

export const UploadIconContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const Image = styled('img')({
  width: '100%',
});

const UploadImageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const UploadImageText = styled('p')(({ theme }) => ({
  fontSize: 18,
  color: theme.colors.gray[400],
  marginTop: 15,
  textAlign: 'center',
}));

interface UploadImageProps {
  image?: string;
  slideDataConfigPropertyName: string;
  containerStyles?: React.CSSProperties;
  imageSizeSuggestion?: string;
}

export const UploadImage: React.FC<UploadImageProps> = ({
  image,
  slideDataConfigPropertyName,
  containerStyles = {},
  imageSizeSuggestion,
}) => {
  const color = useUiConfigColorsPrimary();
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const imageUpload = async (file: File) => {
      if (!selectedSlide) return;

      try {
        setUploading(true);
        const imageUrl = await MediaApi.upload(file);

        setSlideDataProperty(slideDataConfigPropertyName, imageUrl);
      } catch (error) {
        console.error(error);
      } finally {
        setUploading(false);
      }
    };
    if (file) {
      imageUpload(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  if (!selectedSlide) return null;

  return (
    <ImageUploadContainer
      style={{ backgroundImage: image ? `url("${image}")` : 'none', ...containerStyles }}
    >
      <UploadIconContainer>
        {uploading ? (
          <Loader size={'4x'} centered color={color} />
        ) : (
          <UploadImageContainer>
            <UploadImageButton setFile={setFile} />
            <UploadImageText>{imageSizeSuggestion} pixel images are best displayed</UploadImageText>
          </UploadImageContainer>
        )}
      </UploadIconContainer>
    </ImageUploadContainer>
  );
};
