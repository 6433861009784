import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { Icon } from '../../../../../components/Common';
import CardsWrapper from './CardsWrapper';
import { usePresentationCardsData } from './usePresentationCardsData';
import { useUser } from '../../../../../components/Slide/providers/UserProvider';

const scrollIntoViewOptions = {
  behavior: 'smooth',
  inline: 'center',
  block: 'nearest',
} as ScrollIntoViewOptions;

const DesktopCreatePresentationSwiper: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [allCardsVisible, setAllCardsVisible] = useState(false);
  const [showPreviousArrow, setShowPreviousArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);
  const { feedIds } = useUser();
  const userHasFeeds = feedIds?.length > 0;

  const { desktopData: data } = usePresentationCardsData();

  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const cardRefs = useRef<HTMLDivElement[]>([]);

  const isWideScreen = useMediaQuery('(min-width: 1247px)');

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        let intersectingCount = 0;
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            intersectingCount++;
            const index = cardRefs.current.indexOf(entry.target as HTMLDivElement);
            if (index !== 0 && intersectingCount > 1) {
              setCurrentIndex(0);
            } else {
              setCurrentIndex(index);
            }
          }
        });
      },
      { threshold: 1 },
    );

    const currentRefs = cardRefs.current;

    currentRefs.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => {
      currentRefs.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  useEffect(() => {
    const checkAllCardsVisible = () => {
      if (window) {
        setAllCardsVisible(userHasFeeds ? window.innerWidth >= 1610 : true);
        cardRefs.current[0].scrollIntoView(scrollIntoViewOptions);
        setCurrentIndex(0);
        setShowNextArrow(true);
      }
    };

    checkAllCardsVisible();
    window.addEventListener('resize', checkAllCardsVisible);

    return () => {
      window.removeEventListener('resize', checkAllCardsVisible);
    };
  }, [userHasFeeds]);

  useEffect(() => {
    const handleScroll = () => {
      if (cardsContainerRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } = cardsContainerRef.current;
        const atEnd = scrollLeft + clientWidth + 10 >= scrollWidth;

        setShowPreviousArrow(scrollLeft > 10);
        setShowNextArrow(!atEnd);

        if (atEnd) {
          setCurrentIndex(cardRefs.current.length - 1);
        }
      }
    };

    const cardsCurrent = cardsContainerRef.current;

    if (cardsCurrent) {
      cardsCurrent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (cardsCurrent) {
        cardsCurrent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [userHasFeeds]);

  const handleNext = () => {
    if (isWideScreen) {
      cardRefs.current[cardRefs.current.length - 1].scrollIntoView(scrollIntoViewOptions);
    } else if (cardRefs.current[currentIndex + 1]) {
      cardRefs.current[currentIndex + 1].scrollIntoView(scrollIntoViewOptions);
    } else if (currentIndex === cardRefs.current.length - 1) {
      cardRefs.current[cardRefs.current.length - 1].scrollIntoView(scrollIntoViewOptions);
    }
  };

  const handlePrevious = () => {
    if (isWideScreen) {
      cardRefs.current[0].scrollIntoView(scrollIntoViewOptions);
    } else if (cardRefs.current[currentIndex - 1]) {
      cardRefs.current[currentIndex - 1].scrollIntoView(scrollIntoViewOptions);
    } else if (currentIndex === 0) {
      cardRefs.current[0].scrollIntoView(scrollIntoViewOptions);
    }
  };

  return (
    <ScrollWrapper ref={cardsContainerRef}>
      <Container>
        <Icon
          iconName='arrow_left'
          onClick={handlePrevious}
          style={{
            position: 'absolute',
            left: 0,
            display: allCardsVisible ? 'none' : showPreviousArrow ? '' : 'none',
            cursor: 'pointer',
          }}
        />
        {data.map((item, index) => (
          <div
            id={`${index}`}
            key={`${item.title}_${index}`}
            ref={el => (cardRefs.current[index] = el as HTMLDivElement)}
            style={{
              paddingRight: index === data.length - 1 ? '30px' : '',
              paddingLeft: index === 0 ? '30px' : '',
            }}
          >
            <CardsWrapper data={item} />
          </div>
        ))}
        <Icon
          iconName='arrow_right'
          onClick={handleNext}
          style={{
            position: 'absolute',
            right: 0,
            display: allCardsVisible ? 'none' : showNextArrow ? '' : 'none',
            cursor: 'pointer',
          }}
        />
      </Container>
    </ScrollWrapper>
  );
};

export default DesktopCreatePresentationSwiper;

const ScrollWrapper = styled.div`
  overflow-x: auto;
  padding-bottom: 7px;
  scrollbar-width: none;

  width: calc(100vw + 30px);
  margin-left: -30px;
  padding-left: 30px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1610px;
  gap: 25px;
  margin: 0 auto;
  padding: 0 0px;
`;
