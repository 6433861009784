import equal from 'fast-deep-equal';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import { Map } from '../../../../../../components/Map/Map';

import { mapStyles } from '../DrawableMarkerPolylineMap/MapStyles';
import { MapHelpers } from '../DrawableMarkerPolylineMap/helpers';
import { getPropertyId } from '../../../../../../../../../../features/report/services/getPropertyId';
import { BOLD_TRAIL_THEME_NAME } from '../../../../../../../../../../config/themes/bold-trail';

class ManualReportMapImp extends Component {
  mapHelper;

  componentDidUpdate(prevProps) {
    const partialData = this.props.partialData;
    const subjectSize = this.props.subjectSize;
    if (!this.mapHelper) {
      return;
    }

    if (!equal(prevProps.partialData, partialData) || prevProps.subjectSize !== subjectSize) {
      if (!partialData) {
        this.mapHelper.clearMarkers();
        return;
      }

      const isBoldTrailTheme = this.props.theme.name === BOLD_TRAIL_THEME_NAME;

      const markers = this.mapHelper.setMarkersFromComps(
        partialData.properties,
        subjectSize,
        false,
        isBoldTrailTheme,
      );
      this.mapHelper.zoomOutToFitAllMarkers(markers, this.props.defaultCenter);
    }
  }

  render() {
    const { defaultCenter, style, onMapLoad = f => f } = this.props;

    return (
      <Map
        id={`manualReportMap`}
        options={{
          center: { ...defaultCenter },
          zoom: 14.25,
          styles: mapStyles,
          disableDefaultUI: true,
        }}
        onMapLoad={(map, google, oms) => {
          this.mapHelper = new MapHelpers(this.props.theme, map, google, oms);
          this.mapHelper.createSubjectMarker(defaultCenter);

          document.addEventListener('add-comp', ({ detail }) => {
            this.mapHelper.getInfoWindow().close();
            this.props.onAdd(detail);
          });

          document.addEventListener('remove-comp', ({ detail }) => {
            this.mapHelper.getInfoWindow().close();
            this.props.onExclude(detail);
          });

          document.addEventListener('more-info', ({ detail }) => {
            this.props.setIsModalOpen(true);

            const selectedProperty = this.props.partialData.properties?.[detail.status]?.find(
              property => getPropertyId(property) === getPropertyId(detail),
            );

            this.props.setSelectedProperty(selectedProperty || null);
          });

          onMapLoad();
        }}
        style={style}
      />
    );
  }
}

export const ManualReportMap = withTheme(ManualReportMapImp);
