import React from 'react';
import { useTheme } from 'styled-components';

import {
  useUiConfigFontsHeader,
  useUiConfigBodyLetterSpacing,
} from '../../../../providers/providers/UiConfigFontProvider';
import { Container, Typography } from './Card.styles';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';

export interface CardComponentProps {
  title: string;
  text?: string;
  icon?: string;
  cardStyles?: React.CSSProperties;
  titleStyles?: React.CSSProperties;
  textStyles?: React.CSSProperties;
  number?: number;
  primaryColorTitle?: boolean;
  subtitle?: string;
  type?: 'pdf' | 'web';
}

export const Card: React.FC<CardComponentProps> = ({
  title,
  text,
  icon,
  number,
  titleStyles = {},
  cardStyles = {},
  textStyles = {},
  primaryColorTitle,
  subtitle,
  type = 'web',
}) => {
  const theme = useTheme();

  const primaryColor = useUiConfigColorsPrimary();
  const headingStyles: React.CSSProperties = {
    letterSpacing: 3,
    wordSpacing: 2,
    fontFamily: useUiConfigFontsHeader(),
    color: primaryColorTitle ? primaryColor : '400',
    textTransform: 'uppercase',
    fontSize: type === 'web' ? 32 : 26,
  };

  const bodyStyles = type === 'pdf' ? { fontSize: '16px' } : {};
  return (
    <Container style={{ position: 'relative', ...cardStyles }}>
      <Typography
        variant='h1'
        style={{
          ...headingStyles,
          width: primaryColorTitle ? '90%' : '70%',
          height: 95,
          lineHeight: 1.2,
          fontWeight: 800,
          ...titleStyles,
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant='h3'
          color='400'
          style={{
            letterSpacing: 0.35,
            width: '87%',
            lineHeight: 1.4,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {subtitle}
        </Typography>
      )}
      <Typography
        variant='h3'
        color='400'
        style={{
          ...bodyStyles,
          width: '87%',
          lineHeight: 1.4,
          fontWeight: 500,
          fontSize: type === 'web' ? 18 : 16,
          letterSpacing: useUiConfigBodyLetterSpacing('0.35'),
          ...textStyles,
        }}
      >
        {text}
      </Typography>
      {icon && <img src={icon} style={{ position: 'absolute', top: '12.5%', right: '10%' }} />}
      {typeof number === 'number' && (
        <Typography
          variant='h1'
          style={{
            ...headingStyles,
            position: 'absolute',
            top: '7.5%',
            right: '10%',
            width: 'max-content',
            borderBottom: `4px solid ${theme.colors.gray[400]}`,
            lineHeight: 1.1,
          }}
        >
          {number}
        </Typography>
      )}
    </Container>
  );
};
