import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';

import Loader from '../../../components/Loader/Loader';
import { TPresentation, TPresentationMode } from '../../../types';
import { PresentationEditActions } from './PresentationEditActions';
import { PreviewModeProvider } from './providers/PreviewModeProvider';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { useSlides } from '../../../providers/providers/SlidesProvider';
import { useSections } from '../../../providers/providers/SectionsProvider';
import { SelectedSlideProvider } from '../../../providers/providers/SelectedSlideProvider';
import { usePresentationEditingSlides } from '../../../hooks/usePresentationEditingSlides';
import { ConfigItem, useOrder } from '../../../providers/providers/OrderProvider';
import { usePresentationMode } from '../../../providers/providers/PresentationModeProvider';
import { usePresentationType } from '../../../providers/providers/PresentationTypeProvider';
import { OrderValidator } from '../../../services/validators/OrderValidator';
import { addToModeOrderSlidesAddedOnDashboard } from './services/addToModeOrderSlidesAddedOnDashboard';
import { PresentationCreationBody } from './PresentationCreationBody/PresentationCreationBody.component';
import { useEmphasis } from './PresentationSettingsHeader/PresentationSettings/Emphasis/EmphasisProvider';
import { removeFromModeRemovedDashboardSlides } from './services/removeFromModeRemovedDashboardSlides';
import { PresentationBuildingLayout } from '../../../layouts/PresentationBuildingLayout/PresentationBuildingLayout';
import { PresentationSettingsHeader } from './PresentationSettingsHeader/PresentationSettingsHeader';
import { PresentationConfigsFactory } from './PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';
import { useRawCustomizations } from '../../../data/customizations/useRawCustomizations';

export const Wrapper = styled('div')({
  width: '100%',
  height: '100%',
});

interface PresentationEditContentProps {
  presentation: TPresentation;
}

const useSetSlidesSectionsAndOrder = (presentation: TPresentation) => {
  const { setSlides } = useSlides();
  const { setSections, activateSectionFromMode } = useSections();
  const {
    setSectionsOrder,
    setSectionsSlideOrder,
    hasOrderForMode,
    activateOrderFromMode,
    getOrderForMode,
  } = useOrder();

  const { presentationType } = usePresentationType();
  const { presentationMode } = usePresentationMode();

  const { hash, isCanadaUser } = useUser()!;

  const { isLoading: isLoadingCustomizations } = useRawCustomizations(hash);

  const {
    isLoading: isLoadingPresentationEditingSlides,
    isFetching,
    data: slides,
  } = usePresentationEditingSlides(hash, presentation);

  const isLoading = isLoadingCustomizations || isLoadingPresentationEditingSlides;

  const { calculateInitialEmphasis } = useEmphasis();

  const prevMode = useRef<TPresentationMode | null>(null);

  function isSwitchBetweenTwoModes() {
    return prevMode.current && prevMode.current !== presentationMode;
  }

  function reflectChangesOnDashboardSlides(order: ConfigItem) {
    if (!prevMode.current || !presentationMode) return order.sectionsSlide;

    const prevModeOrder = getOrderForMode(prevMode.current);

    let sectionsSlide = addToModeOrderSlidesAddedOnDashboard(
      presentationMode,
      order,
      prevMode.current,
      prevModeOrder as ConfigItem,
    );

    const newOrder = { ...order, sectionsSlide };

    sectionsSlide = removeFromModeRemovedDashboardSlides(
      presentationMode,
      newOrder,
      prevMode.current,
      prevModeOrder as ConfigItem,
    );

    return sectionsSlide;
  }

  useEffect(
    function onMount() {
      if (isLoading || isFetching || !slides || !presentationMode) return;

      if (hasOrderForMode(presentationMode)) {
        const order = activateOrderFromMode(presentationMode);
        calculateInitialEmphasis(order);
        activateSectionFromMode(presentationMode);

        if (isSwitchBetweenTwoModes()) {
          const sectionsSlide = reflectChangesOnDashboardSlides(order);
          setSectionsSlideOrder(sectionsSlide);
        }

        prevMode.current = presentationMode;
        return;
      }

      const configs = PresentationConfigsFactory.create(presentationType, presentationMode);
      const validator = new OrderValidator(presentationType, presentationMode);

      const sectionsOrder = presentation?.[presentationMode]?.order?.sections?.length
        ? validator.getPartialSectionsOrder(presentation?.[presentationMode]?.order?.sections ?? [])
        : configs.getDefaultOrder().sections;

      const hasSectionsSlide = Object.values(
        presentation?.[presentationMode]?.order?.sectionsSlide ?? {},
      )?.length;

      let sectionsSlide = hasSectionsSlide
        ? validator.getPartialSectionsSlidesOrder(
            presentation?.[presentationMode]?.order?.sectionsSlide ?? {},
            slides,
          )
        : configs.getDefaultOrder().sectionsSlide;

      if (isSwitchBetweenTwoModes()) {
        const order = { sections: sectionsOrder, sectionsSlide };
        sectionsSlide = reflectChangesOnDashboardSlides(order);
      }

      if (isCanadaUser && presentationMode === 'regular') {
        sectionsSlide.marketingAndBuyerActivity = sectionsSlide.marketingAndBuyerActivity.filter(
          slideId => slideId !== 'buyerInterest' && slideId !== 'buyerDemand',
        );
      }

      setSectionsOrder(sectionsOrder);
      setSectionsSlideOrder(sectionsSlide);
      calculateInitialEmphasis({ sections: sectionsOrder, sectionsSlide });
      setSections(presentation[presentationMode]?.sections ?? configs.getSections());

      prevMode.current = presentationMode;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isFetching, presentationMode],
  );

  useEffect(
    function setSlidesOnLoad() {
      if (isLoading || isFetching) return;

      setSlides(prev => {
        const combinedMap = {
          ...prev,
          ...slides,
        };

        if (
          presentationMode === 'oneSheeter' &&
          combinedMap.oneSheeter &&
          !combinedMap?.oneSheeter?.buyerInterestData
        ) {
          combinedMap.oneSheeter.buyerInterestData = combinedMap.buyerInterest?.buyerInterestData;
        }

        return combinedMap;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isFetching, slides, presentationMode],
  );

  return { isLoading };
};

export const PresentationEditContent: React.FC<PresentationEditContentProps> = ({
  presentation,
}) => {
  const { presentationType } = usePresentationType();
  const { isLoading } = useSetSlidesSectionsAndOrder(presentation);

  const isWithPresentationMode =
    presentationType === PRESENTATION_TYPES.WIN_THE_LISTING ||
    presentationType === PRESENTATION_TYPES.WIN_THE_OFFER;

  return (
    <SelectedSlideProvider>
      <PreviewModeProvider>
        <PresentationBuildingLayout
          HeaderActions={<PresentationEditActions isFetching={isLoading} />}
        >
          {isLoading ? (
            <Wrapper>
              <Loader size='3x' centered />
            </Wrapper>
          ) : (
            <>
              <PresentationSettingsHeader useMode={isWithPresentationMode} />
              <PresentationCreationBody presentation={presentation} />
            </>
          )}
        </PresentationBuildingLayout>
      </PreviewModeProvider>
    </SelectedSlideProvider>
  );
};
