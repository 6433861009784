import React from 'react';
import { Card } from './Card/Card';
import {
  Container,
  BackgroundWave,
  ContentContainer,
  Content,
  TouchPadImg,
  Logo,
  Text,
  CardsContainer,
} from './PropertyBoost.styles';
import pdf_bg from './assets/pdf_bg.png';
import touchpad_img from './assets/image.png';
import logoCP from './assets/logo.png';
import logoBT from './assets/logo_bt.png';
import { paragraphs, cards } from './data';
import { PdfFrame } from '../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../providers/providers/CategoryProvider';
import { CATEGORIES } from '../../../../../constants/categories';
import { useBrandName } from '../../../../../hooks/useBrandName';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';

const PropertyBoostPDF: React.FC = () => {
  const { category } = useCategory();
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const { propertyBoost } = useBrandName();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <ContentContainer style={{ padding: 40, flexDirection: 'column' }}>
          <Content style={{ width: '100%' }}>
            <Logo
              src={hasBtTheme ? logoBT : logoCP}
              style={{ width: hasBtTheme ? '35%' : '25%', height: 'auto', marginBottom: 10 }}
            />
            {paragraphs.map((p, i) => (
              <Text key={i} style={{ width: '45%', fontSize: 16 }}>
                {p.replaceAll('$brand', propertyBoost)}
              </Text>
            ))}
            <CardsContainer style={{ marginTop: 450 }}>
              {cards.map((c, i) => (
                <Card key={i} {...c} />
              ))}
            </CardsContainer>
          </Content>
        </ContentContainer>
        <BackgroundWave src={pdf_bg} />
        <TouchPadImg
          src={touchpad_img}
          style={{ position: 'absolute', width: 400, height: 'auto', top: 150, right: 40 }}
        />
      </Container>
    </PdfFrame>
  );
};

export default PropertyBoostPDF;
