import React from 'react';
import styled from 'styled-components';
import { useUiConfigFontsHeader } from '../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 5,
  overflow: 'hidden',
  border: '1px solid #ddd',
  paddingBottom: 16,
  flex: 1,
  maxHeight: 140,
  minHeight: 140,
});

const Title = styled('h3')(({ theme }) => ({
  fontFamily: useUiConfigFontsHeader(),
  fontWeight: 800,
  color: '#444',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '0.05'),
  padding: 8,
  textAlign: 'center',
}));

const Value = styled('h1')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontSize: 28,
  margin: '10px auto',
  textAlign: 'center',
  fontWeight: 800,
}));

const Desc = styled('p')({
  fontSize: 13,
  fontWeight: 300,
  padding: '0 12px',
  textAlign: 'center',
});

interface Props {
  title: string;
  value: string;
  description: string;
  days?: boolean;
}

export const Card: React.FC<Props> = ({ title, value, description, days }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Value>
        {value}
        {days ? <span style={{ fontSize: 20, fontWeight: 700 }}> Days</span> : null}
      </Value>
      <Desc>{description}</Desc>
    </Container>
  );
};
