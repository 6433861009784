import React from 'react';
import { useSelector, useStore } from 'react-redux';

export const useAllSearchCriteriaGetter = () => {
  const store = useStore();

  return () => store.getState()?.search?.searchCriteria || {};
};

export const useGetExclusions = () =>
  useSelector(({ report }) => {
    const rebuildData = report?.rebuildData ?? {};
    if (!rebuildData?.request?.excluded_ids) {
      return { excluded_ids: [] };
    }

    return {
      excluded_ids: rebuildData.request.excluded_ids,
    };
  });
