import React from 'react';

import {
  QuotesWrapper,
  TestimonialWrapper,
  TitlePdf,
  Icon,
  TestimonialsContainer,
} from './MyTestimonialsPdf.styles';
import { ScaleContent } from '../../ScaleContent';
import { CommonFrameProps } from '../../../../../../../../types';
import { useUser } from '../../../../../../providers/UserProvider';
import { useSlide } from '../../../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import { getTestimonials } from '../../../../../../../../services/getTestimonials';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { TestimonialContent } from '../TestimonialContent/TestimonialContent.component';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';

export const MyTestimonialsPdf: React.FC<CommonFrameProps> = () => {
  const { category } = useCategory();
  const slide = useSlide();
  const { testimonials: userApiTestimonials } = useUser();
  const primaryColor = useUiConfigColorsPrimary();

  if (!slide) return null;

  const { links, testimonials } = slide.data!;
  if (!testimonials || !links) return null;

  const slideTestimonials = getTestimonials(testimonials, userApiTestimonials);

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <ScaleContent predefinedHeight={980} predefinedWidth={900}>
        <TitlePdf>My Testimonials</TitlePdf>
        <TestimonialWrapper>
          <QuotesWrapper>
            <Icon iconName='quote' fill={primaryColor} style={{ width: 60, height: 60 }} />
          </QuotesWrapper>
          <TestimonialsContainer>
            {slideTestimonials.map((testimonial, i) => (
              <TestimonialContent testimonial={testimonial} key={i} />
            ))}
          </TestimonialsContainer>
        </TestimonialWrapper>
      </ScaleContent>
    </PdfFrame>
  );
};
