import styled from 'styled-components';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  borderBottom: `5px solid ${theme.colors.gray[100]}`,
  marginBottom: 0,
  zIndex: 2,
  background: '#fff',
  flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
}));

export const BrokerInfoContainer = styled('div')({
  width: '33.33%',
  filter: 'drop-shadow(2px 2px 15px rgba(0, 0, 0, 0.2))',
  height: 135,
});

export const BrokerInfoWrapper = styled('div')(({ theme }) => ({
  clipPath: `polygon(${
    useReverseHeader() ? '15% 0, 100% 0%, 100% 100%, 0% 100%' : '100% 0, 85% 100%, 0 100%, 0 0'
  })`,
  backgroundColor: '#fff',
  paddingLeft: useReverseHeader() ? 0 : '15%',
  paddingRight: !useReverseHeader() ? 0 : '15%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));
