import React from 'react';
import { useSetAtom } from 'jotai';
import styled from 'styled-components';

import { PropertyGroups } from '../../../../types';
import { useShowPropertyDetails } from '../../hooks/useShowPropertyDetails';
import { filteredPropertiesAtom } from '../../state/filteredPropertiesAtom';
import { extractFilteredProperties } from '../../services/extractFilteredProperties';
import { InfoCard } from './InfoCard';
import { ColoredBar } from './ColoredBar';

interface Props {
  properties: PropertyGroups;
  maxTotalCount: number;
  order: number;
  percent: string;
  priceRange: [number, number];
}

export const BigPictureItem: React.FC<Props> = ({
  properties,
  maxTotalCount,
  order,
  percent,
  priceRange,
}) => {
  const containerBg = order === 1 ? '#f7f7f7' : order === 2 ? '#f3f3f3' : '#efefef';
  const backgroundColor = order === 1 ? '#b2b2b2' : order === 2 ? '#8b8b8b' : '#656565';

  const { showPropertyDetails } = useShowPropertyDetails();
  const setFilteredProperties = useSetAtom(filteredPropertiesAtom);

  const { active, pending, sold, comingSoon } = properties;

  const homesCount = {
    active: active?.length || 0,
    pending: pending?.length || 0,
    closed: sold?.length || 0,
    comingSoon: comingSoon?.length || 0,
  };

  const onViewHomes = () => {
    const allProperties = [
      ...(properties?.active || []),
      ...(properties?.pending || []),
      ...(properties?.sold || []),
      ...(properties?.comingSoon || []),
    ];

    setFilteredProperties(extractFilteredProperties(properties));
    showPropertyDetails(allProperties[0]);
  };

  return (
    <Container style={{ backgroundColor: containerBg }}>
      <InfoCard priceRange={priceRange} homesCount={homesCount} onClick={onViewHomes} />
      <ColoredBar maxTotalCount={maxTotalCount} {...homesCount} />
      <Footer style={{ backgroundColor }}>{percent} of listings</Footer>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`;

const Footer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  width: 100%;
  flex-shrink: 0;
`;
