import React from 'react';
import { CheckboxContainer, CheckboxInput, CheckboxControl } from './Checkbox.styles';

interface CheckboxProps {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ isChecked, setIsChecked }) => {
  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isBoxChecked = e.currentTarget.checked;
    setIsChecked(isBoxChecked);
  };

  return (
    <CheckboxContainer>
      <CheckboxInput type='checkbox' onChange={onCheck} checked={isChecked} name={'check'} />
      <CheckboxControl isChecked={isChecked} />
    </CheckboxContainer>
  );
};
