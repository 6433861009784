import React, { useContext, useState } from 'react';

interface IProgressProviderContextData {
  progress: Record<string, string[]>;
  setProgress: (data: any) => void;
}
const ProgressProviderContext = React.createContext<IProgressProviderContextData | undefined>(
  undefined,
);

export const useProgressState = () => {
  const context = useContext(ProgressProviderContext);

  if (!context) throw new Error('useProgress cannot be used without ProgressProviderContext');

  return context;
};

export const ProgressProvider: React.FC = ({ children }) => {
  const [progress, setProgress] = useState({});

  const value = React.useMemo(() => ({ progress, setProgress }), [progress, setProgress]);

  return (
    <ProgressProviderContext.Provider value={value}>{children}</ProgressProviderContext.Provider>
  );
};
