import React, { useContext, useState } from 'react';
import { TPresentationMode } from '../../types';

type PresentationModeContextTypes = {
  presentationMode: TPresentationMode;
  setPresentationMode: (value: TPresentationMode) => void;
};
const PresentationModeContext = React.createContext<PresentationModeContextTypes>({
  presentationMode: 'regular',
  setPresentationMode: () => {},
});

export const usePresentationMode = () => {
  const context = useContext(PresentationModeContext);

  if (!context) {
    throw new Error('usePresentationMode cannot be used without PresentationModeContext');
  }

  return context;
};

export const PresentationModeProvider: React.FC = ({ children }) => {
  const [presentationMode, setPresentationMode] = useState<TPresentationMode>('regular');

  const value = React.useMemo(
    () => ({
      presentationMode,
      setPresentationMode,
    }),
    [presentationMode],
  );

  return (
    <PresentationModeContext.Provider value={value}>{children}</PresentationModeContext.Provider>
  );
};
