import styled from 'styled-components';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { Typography } from '../../../../../../Common';

export { Typography };

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2% 4%',
});

export const LeftCol = styled('div')({
  width: '24%',
});

export const LightCard = styled('div')(({ theme }) => ({
  borderRadius: 5,
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1'),
  padding: '25px 35px',
  marginTop: 20,
}));

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colors.gray[400],
  marginBottom: 30,
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const RightCol = styled('div')({
  width: '74%',
  borderRadius: 5,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  position: 'relative',
});

export const CardsContainer = styled('div')({
  padding: '0 42px',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 20,
});

export const SourceText = styled('p')({
  fontSize: 13,
  fontWeight: 300,
  paddingTop: 12,
  position: 'absolute',
  top: '100%',
  right: 0,
});
