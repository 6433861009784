import styled from 'styled-components';

interface Props {
  isactive?: boolean;
}

export const SlideWrapper = styled('div')<Props>(({ theme, isactive }) => ({
  filter: !isactive ? 'none' : 'drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.65))',
  marginBottom: 20,
  transition: 'all .2s',
  padding: 0,
  position: 'relative',
  overflow: 'hidden',
  border: `2px solid ${!isactive ? theme.colors.v2.gray[200] : 'transparent'}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  backgroundImage: !isactive ? 'none' : theme.colors.v2.gradient.border,
}));

export const InsideWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'transparent',
  cursor: 'grab',
  ':active': {
    cursor: 'grabbing',
  },
});

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 7px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  padding-right: 2px;
`;

export const RemoveIcon = styled.div`
  cursor: pointer;
`;
