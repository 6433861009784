import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import parse from 'html-react-parser';

import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import { TextEditor } from '../../../../Common/TextEditor/TextEditor.component';

type HeadlineProps = {
  isHeadlineCenter?: boolean;
};

const TextBlockContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

const HeadlineInputWrapper = styled('div')<HeadlineProps>(({ isHeadlineCenter }) => ({
  width: '100%',
  textAlign: isHeadlineCenter ? 'center' : 'start',
  color: useUiConfigColorsPrimary(),
  fontSize: 30,
  fontWeight: 900,
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  marginBottom: 20,
  display: 'grid',
  '&:after': {
    border: '1px solid #ddd',
    borderRadius: 3,
    padding: '12px 14px',
    content: 'attr(data-value) " "',
    gridArea: '1 / 1 / 2 / 2',
    whiteSpace: 'pre-wrap',
    visibility: 'hidden',
    lineHeight: '32px',
    height: '100%',
  },
}));

export const HeadlineInput = styled('textarea')<HeadlineProps>(({ isHeadlineCenter }) => ({
  padding: '12px 14px',
  border: '1px solid #ddd',
  borderRadius: 3,
  textAlign: isHeadlineCenter ? 'center' : 'start',
  color: useUiConfigColorsPrimary(),
  fontSize: 26,
  fontWeight: 900,
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  height: '100%',
  width: '100%',
  resize: 'none',
  overflow: 'hidden',
  gridArea: '1 / 1 / 2 / 2',
  lineHeight: '32px',
  '&::placeholder': {
    fontSize: 24,
    color: '#000',
    textAlign: isHeadlineCenter ? 'center' : 'start',
  },
}));

const Headline = styled('h2')<HeadlineProps>(({ theme, isHeadlineCenter }) => ({
  color: useUiConfigColorsPrimary(),
  fontSize: 26,
  fontWeight: 900,
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  marginBottom: 20,
  textAlign: isHeadlineCenter ? 'center' : 'start',
  padding: '12px 14px',
}));

const Text = styled('p')(({ theme }) => ({
  fontFamily: useUiConfigFontsBody(),
  padding: '0 15px',
  letterSpacing: useUiConfigBodyLetterSpacing(),
  '& p': {
    padding: '10px 0',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
  '& ul': {
    margin: '16px 0',
  },
  '& li': {
    margin: '10px 0',
    marginLeft: '1.5em',
  },
}));

interface SlideTextBlockProps {
  headline?: string;
  text?: string;
  onChangeHeadline?: (value: string) => void;
  onChangeText?: (value: string) => void;
  isEditable?: boolean;
  isHeadlineCenter?: boolean;
  textAreaStyles?: CSSObject;
  containerStyles?: React.CSSProperties;
  isInitialCenterAlignment?: boolean;
}

export const SlideTextBlock: React.FC<SlideTextBlockProps> = ({
  headline = '',
  text = '',
  onChangeHeadline,
  onChangeText,
  isEditable,
  isHeadlineCenter,
  containerStyles,
  textAreaStyles,
  isInitialCenterAlignment,
}) => {
  return (
    <TextBlockContainer style={containerStyles}>
      {isEditable && onChangeHeadline ? (
        <>
          <HeadlineInputWrapper data-value={headline}>
            <HeadlineInput
              placeholder='Enter headline here'
              value={headline}
              onChange={e => onChangeHeadline(e.target.value)}
              isHeadlineCenter={isHeadlineCenter}
              rows={1}
            ></HeadlineInput>
          </HeadlineInputWrapper>
          {onChangeText && (
            <TextEditor
              onChangeText={onChangeText}
              rawHtml={text}
              isInitialCenterAlignment={isInitialCenterAlignment}
              textAreaStyles={{ overflow: 'hidden', ...textAreaStyles }}
              toolbarSize='large'
              toolbarOptions={['inline', 'list', 'fontSize', 'textAlign']}
            />
          )}
        </>
      ) : (
        <>
          <Headline isHeadlineCenter={isHeadlineCenter}>{headline}</Headline>
          <Text style={textAreaStyles}>{parse(text)}</Text>
        </>
      )}
    </TextBlockContainer>
  );
};
