import styled from 'styled-components';
import { flexRender } from '@tanstack/react-table';

import { ColumnDef } from '../../../../types';

import { TPresentationSession } from '../../../../../../../../api/TrackingApi';

import { useBTAnalyticsTable } from '../../hooks/useBTAnalyticsTable';

import { Icon } from '../../../../../../../../components/Common';

type Props = {
  hash: string;
  session?: TPresentationSession;
};

export function BuyerTourTable({ hash, session }: Props) {
  const table = useBTAnalyticsTable(hash, session);

  return (
    <TableWrapper>
      <TableBorder>
        <Table>
          <THead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Th key={header.id}>
                    <Flex
                      hasCursorPointer={header.column.getCanSort()}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <p>{flexRender(header.column.columnDef.header, header.getContext())}</p>
                      {header.column.getCanSort() && <Icon iconName='sort' />}
                    </Flex>
                  </Th>
                ))}
              </tr>
            ))}
          </THead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id} style={(cell.column.columnDef.meta as ColumnDef)?.style}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableBorder>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  width: 100%;
  padding: 24px;
  overflow-x: auto;
`;

const TableBorder = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  min-width: 1202px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;
`;

const THead = styled.thead`
  background-color: #ddd;
  color: #444;

  font-family: Figtree;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

const Tr = styled.tr`
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const Th = styled.th`
  text-align: left;
  padding: 15px 10px;
`;

const Td = styled.td`
  text-align: left;
  padding: 11px 10px 14px;

  color: #444;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Flex = styled.div<{ hasCursorPointer: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: ${({ hasCursorPointer }) => (hasCursorPointer ? 'pointer' : 'default')};
`;
