import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper, ImagePreview, ImagesWrapper } from './FourImagesGamma.styles';

interface Props {
  reverse?: boolean;
  horizontal?: boolean;
}

export const FourImagesGamma: React.FC<Props> = ({ reverse, horizontal }) => {
  const slide = useSlide();

  let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'column';
  if (horizontal) flexDirection = 'row';
  if (horizontal && reverse) flexDirection = 'row-reverse';
  if (!horizontal && reverse) flexDirection = 'column-reverse';

  return (
    <Container style={{ flexDirection }}>
      <SlideTextBlock
        isEditable={false}
        text={slide.data?.text}
        headline={slide.data?.headline}
        containerStyles={{
          height: horizontal ? '100%' : '49%',
          width: horizontal ? 'calc(50% - 30px)' : '100%',
        }}
      />
      <ImagesWrapper
        style={{
          display: horizontal ? 'grid' : 'flex',
          gridTemplateColumns: '50% 50%',
          gridTemplateRows: '50% 50%',
          padding: horizontal && reverse ? '0 0 10% 0' : horizontal && !reverse ? '10% 0 0 0' : '0',
          flexDirection: 'row',
          width: horizontal ? 'calc(50% - 30px)' : '100%',
          marginRight: horizontal && reverse ? 60 : 0,
          marginLeft: horizontal && !reverse ? 60 : 0,
          marginBottom: horizontal || !reverse ? 0 : 40,
          marginTop: horizontal || reverse ? 0 : 40,
          height: horizontal ? '100%' : '45%',
          flexWrap: 'wrap',
        }}
      >
        <ImageWrapper>
          <ImagePreview src={slide.data?.image} />
        </ImageWrapper>
        <ImageWrapper>
          <ImagePreview src={slide.data?.image2} />
        </ImageWrapper>
        <ImageWrapper>
          <ImagePreview src={slide.data?.image3} />
        </ImageWrapper>
        <ImageWrapper>
          <ImagePreview src={slide.data?.image4} />
        </ImageWrapper>
      </ImagesWrapper>
    </Container>
  );
};
