import { corePresentApi } from './CorePresentApi';

export class MediaApi {
  static async upload(mediaFile: File): Promise<any> {
    const formData = new FormData();
    formData.append('media', mediaFile);

    const res = await corePresentApi.post(`/media`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res?.url || '';
  }
}
