import styled from 'styled-components';

type BarProps = {
  width: string;
  isOfficeData?: boolean;
};

export const BarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Bar = styled('div')<BarProps>(({ width, theme, isOfficeData }) => {
  return {
    height: 7,
    width: width,
    minWidth: '3%',
    borderRadius: 5,
    backgroundColor: isOfficeData ? theme.colors.v2.primary : theme.colors.v2.status.success,
  };
});

export const Percentage = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.colors.gray[400],
  marginLeft: 5,
}));
