import React from 'react';
import styled from 'styled-components';

import bg from './bg.png';
import overlay from './overlay.png';
import slideGraphics from './slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import { title, listItems } from '../../data';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background: url(${bg}) bottom center;
  position: relative;
`;

const Image = styled.img`
  margin: 0 auto;
  display: block;
  top: 4px;
  position: relative;
  width: 1100px;
`;

const OverlayImage = styled.img`
  z-index: 1;
  bottom: 0;
  position: absolute;
  right: 77px;
  height: 745px;
`;

const Title = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 40px;
  margin-bottom: 100px;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  left: 0;
  width: 50%;
  line-height: 1.3;
  z-index: 5;
  width: 525px;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigBodyLetterSpacing()};
`;

export const WhatBuyersWantFromTheirAgentsTraditional: React.FC = () => {
  const primary = useUiConfigColorsPrimary();

  const img = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  const headingStyles = {
    fontSize: 18,
    color: '#444',
    fontWeight: 700,
  };

  const textStyles = {
    fontSize: 16,
    fontWeight: 400,
    color: '#444',
  };

  return (
    <ImageWrapper>
      <Image src={img} />
      <OverlayImage src={overlay} />
      <Title>{title}</Title>
      <TraditionalSlideCircularCard
        heading={listItems[0].title}
        text={listItems[0].text}
        position={{ top: 436, left: 210 }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        size={266}
      />
      <TraditionalSlideCircularCard
        heading={listItems[1].title}
        text={listItems[1].text}
        position={{ top: 168, left: 266 }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        size={295}
      />
      <TraditionalSlideCircularCard
        heading={listItems[2].title}
        text={listItems[2].text}
        position={{ top: 0, left: 522 }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        size={283}
      />
      <TraditionalSlideCircularCard
        heading={listItems[3].title}
        text={listItems[3].text}
        position={{ top: 160, left: 1025 }}
        headingStyles={headingStyles}
        textStyles={{ ...textStyles, width: 171 }}
        size={294}
      />
      <TraditionalSlideCircularCard
        heading={listItems[4].title}
        text={'7% of buyers want to know the worth of nearby homes.'}
        position={{ top: 435, left: 1085 }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        size={275}
      />
    </ImageWrapper>
  );
};
