import { useEffect } from 'react';
import { flexRender, Table } from '@tanstack/react-table';
import styled from 'styled-components';
import { usePdfGenerationRetry } from '../../hooks/usePdfGenerationRetry';
import { useLoadPresentationsWithPdfPooling } from '../../hooks/useLoadPresentations';
import { ColumnDef, PastPresentationItem } from '../../types';
import { Icon } from '../../../../../../components/Common';

const TableWrapper = styled.div`
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  overflow: hidden;
  display: flex;
  font-family: Figtree;
  min-width: 1220px;
`;

const StyledTable = styled.table`
  background-color: #fff;
  overflow: hidden;
  flex: 1;
  border-collapse: collapse;
`;

const THead = styled.thead`
  background-color: ${({ theme }) => theme.colors.gray[100]};
`;

const TH = styled.th`
  padding: 17px 18px;

  &:first-of-type {
    padding-left: 52px;
  }
`;

const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderText = styled.p`
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: left;
  line-height: 1.2;
`;

const TableBodyRow = styled.tr`
  &:not(:first-of-type) {
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  }
`;

const TD = styled.td`
  padding: 14px 18px;
  text-align: left;
`;

interface Props {
  table: Table<PastPresentationItem>;
}

export const PastPresentationsTable = ({ table }: Props) => {
  const { data } = useLoadPresentationsWithPdfPooling();

  const { mutate: pdfGenerationRetry, isLoading: isPdfGenerationLoading } = usePdfGenerationRetry();

  useEffect(() => {
    if (!data) return;

    if (isPdfGenerationLoading) return;
    pdfGenerationRetry(data);
  }, [data, isPdfGenerationLoading, pdfGenerationRetry]);

  return (
    <TableWrapper>
      <StyledTable>
        <THead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TH
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{ cursor: header.column.getCanSort() ? 'pointer' : 'auto' }}
                  >
                    <HeaderTextWrapper>
                      <HeaderText>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </HeaderText>
                      {header.column.getCanSort() && (
                        <Icon iconName='sort' style={{ marginLeft: 4 }} />
                      )}
                    </HeaderTextWrapper>
                  </TH>
                );
              })}
            </tr>
          ))}
        </THead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <TableBodyRow key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TD key={cell.id} style={(cell.column.columnDef.meta as ColumnDef)?.style}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TD>
                  );
                })}
              </TableBodyRow>
            );
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};
