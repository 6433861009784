import React from 'react';
import { NavItemWrapper, NavItemText } from './Navigation.styles';

interface Props {
  onClick: () => void;
  isActive: boolean;
  text: string;
  style?: React.CSSProperties;
}

export const NavItem: React.FC<Props> = ({ onClick, isActive, text, style }) => {
  return (
    <NavItemWrapper onClick={onClick} isActive={isActive} style={style}>
      <NavItemText isActive={isActive}>{text}</NavItemText>
    </NavItemWrapper>
  );
};
