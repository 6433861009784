import React, { createContext, useContext, useState } from 'react';
import { TLogoListItem, TSlideLockableValue, TSlideLogos } from '../../types';

type UiLogosContextType = {
  logos: TSlideLogos;
  logosList: TSlideLockableValue[];
  setLogos: (value: TSlideLogos) => void;
};

const UiLogosContext = createContext<UiLogosContextType | undefined>(undefined);

export const useUiConfigLogos = () => {
  const context = useContext(UiLogosContext);

  if (!context) throw new Error('useUiConfigLogos cannot be used without UiLogosContext');

  return context;
};

interface Props {
  initValue?: TSlideLogos;
}
export const UiConfigLogosProvider: React.FC<Props> = ({ initValue, children }) => {
  const [logos, setLogos] = useState<TSlideLogos>(initValue || {});

  const logosList: TLogoListItem[] = React.useMemo<any[]>(() => {
    const logoKeys = Object.keys(logos) as (keyof TSlideLogos)[];
    return logoKeys
      ?.map(key => {
        let position = 1;
        if (key === 'second') {
          position = 2;
        }

        if (key === 'third') {
          position = 3;
        }
        return {
          position,
          value: logos?.[key]?.value,
        };
      })
      ?.filter(logo => Boolean(logo?.value))
      ?.sort((a, b) => a.position - b.position);
  }, [logos]);

  const value = React.useMemo(() => ({ logos, logosList, setLogos }), [logos, logosList]);

  return <UiLogosContext.Provider value={value}>{children}</UiLogosContext.Provider>;
};
