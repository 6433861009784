import { Intercom } from './Intercom';
import { TApiUser } from '../../types';
import { RemaxChat } from './RemaxChat';
import { useAuthMe } from '../../hooks/useAuthMe';
import { ACCOUNT_IDS } from '../../constants/accountIds';

function isRemaxAccount(me: TApiUser) {
  const accountId = me.kvcore_parent_account_id || me.kvcore_account_id;

  return [
    ACCOUNT_IDS.REMAX.remaxcorporate6207,
    ACCOUNT_IDS.REMAX.remaxcorporatecanada6389,
  ].includes(Number(accountId));
}

export function Chat() {
  const { data: me } = useAuthMe();

  if (!me) return null;

  const showRemaxChat = isRemaxAccount(me);

  return showRemaxChat ? <RemaxChat /> : <Intercom />;
}
