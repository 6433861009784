import styled from 'styled-components';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  marginBottom: 0,
  backgroundColor: '#fff',
  borderBottom: `2px solid ${theme.colors.gray[100]}`,
  paddingRight: useReverseHeader() ? 0 : 20,
  zIndex: 40,
  flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  width: '33.33%',
  display: 'flex',
  justifyContent: useReverseHeader() ? 'flex-start' : 'flex-end',
}));
