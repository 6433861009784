import styled from 'styled-components';

export const Container = styled('div')({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
});

export const AutocompleteWrapper = styled('div')({
  '-webkit-font-smoothing': 'antialiased',
});

export const AutocompleteLabel = styled('label')(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.v2.gray[400],
  marginBottom: 10,
  textTransform: 'uppercase',
  fontWeight: 700,
}));

export const AddClientLink = styled('p')(({ theme }) => ({
  color: theme.colors.v2.text.link,
  fontSize: 14,
  cursor: 'pointer',
  textDecoration: 'none',
  fontWeight: 500,
  marginTop: 20,
}));

export const ClientsInputWrapper = styled.div`
  padding-top: 10px;
  position: relative;
`;
