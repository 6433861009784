import { FEATURES, useHasFeatureFlag } from './useFeatureFlags';

export function useBrandName() {
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  if (hasBtTheme) {
    return {
      core: 'BoldTrail',
      present: 'Present',
      social: 'Social',
      propertyBoost: 'PropertyBoost',
    };
  }

  return {
    core: 'kvCore',
    present: 'CORE Present',
    social: 'CORE Social',
    propertyBoost: 'CORE PropertyBoost',
  };
}
