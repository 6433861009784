import { useUpdateAtomOnChange } from '../../../hooks/useUpdateAtomOnChange';
import { reportPropertiesAtom } from '../state/reportPropertiesAtom';
import { extractReportProperties } from '../services/extractReportProperties';
import { useMemo } from 'react';
import { useGetProperties } from './useGetProperties';

export const useSetProperties = () => {
  const listings = useGetProperties();

  const reportProperties = useMemo(() => {
    return extractReportProperties(listings);
  }, [listings]);

  useUpdateAtomOnChange(reportPropertiesAtom, reportProperties);
};
