import React, { useState } from 'react';

import { Container } from './SingleFramelessVideo.styles';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { UploadImageButton } from '../../../../../Common/UploadImageButton/UploadImageButton.component';
import { InsertVideoUrlModal } from '../../components/InsertVideoUrlModal/InsertVideoUrlModal';
import { getEmbedUrl } from '../../../../../../services/videoServices';

export const SingleFramelessVideoForm: React.FC = () => {
  const { selectedSlide } = useSelectedSlide();

  const videoUrl = selectedSlide?.data?.videoUrl;

  const [open, setOpen] = useState(!videoUrl);

  const iframeSrc = getEmbedUrl(videoUrl);

  if (!selectedSlide) return null;

  return (
    <Container style={{ padding: '2%' }}>
      {iframeSrc ? (
        <iframe
          src={iframeSrc}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          style={{ width: '100%', height: '100%' }}
        />
      ) : null}
      <UploadImageButton
        onClick={() => setOpen(true)}
        containerStyles={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      />
      <InsertVideoUrlModal open={open} onClose={() => setOpen(false)} />
    </Container>
  );
};
