import styled from 'styled-components';
import { Input } from '../../../components/Common';
export { Input };

export const DashboardInputEl = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const DashboardLabel = styled('label')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: theme.colors.v2.gray[400],
  textTransform: 'uppercase',
  marginBottom: 10,
}));

export const ErrorMessage = styled('p')(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.v2.status.error,
}));
