import React from 'react';
import {
  NetProceedsCards,
  NetProceedsContainer,
  NetProceedsText,
  NetProceedsTitle,
  NetProceedsWrapper,
} from './NetProceedsInfo.styles';
import { NetProceedsCard } from './NetProceedsCard/NetProceedsCard.component';

export type TNetProceedCard = {
  listPrice: number;
  estimatedPrice: number;
  costs: number;
  netProceeds: number;
};

interface NetProceedsProps {
  isWeb?: boolean;
  cards?: TNetProceedCard[];
  selectedItemIndex?: number;
  changeSelectedItem?: (i: number) => void;
}

export const NetProceedsInfo: React.FC<NetProceedsProps> = ({
  isWeb = true,
  cards = [],
  selectedItemIndex,
  changeSelectedItem,
}) => {
  return (
    <NetProceedsContainer style={{ marginBottom: isWeb ? 0 : 20 }}>
      <NetProceedsWrapper>
        <NetProceedsTitle>Net Proceeds</NetProceedsTitle>
        <NetProceedsText>
          How much will you walk away with once you’ve sold your home? This is an estimate of how
          much cash in hand you’ll have when you sell. It factors in listing fees, settlement costs,
          state tax, and other costs, detailed here, that are necessary to calculate the bottom
          line. You get the closest estimate on your net proceeds.
        </NetProceedsText>
      </NetProceedsWrapper>
      <NetProceedsCards
        style={{
          flexDirection: isWeb ? 'column' : 'row',
          justifyContent: isWeb ? 'flex-start' : 'space-between',
        }}
      >
        {cards.map((card, i) => {
          if (!card?.listPrice) return;
          return (
            <NetProceedsCard
              key={i}
              isWeb={isWeb}
              card={card}
              isActive={typeof selectedItemIndex === 'number' && i === selectedItemIndex}
              onClick={() => (changeSelectedItem ? changeSelectedItem(i) : {})}
            />
          );
        })}
      </NetProceedsCards>
    </NetProceedsContainer>
  );
};
