import React from 'react';

import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import styled from 'styled-components';
import { TProperty, TPropertyRowPdf, TablePDFAverageData } from '../../../../types';
import { DetailsRow } from './DetailsRow';

interface Props {
  title: string;
  columns: any;
  data: TPropertyRowPdf[];
  dataAvg?: TPropertyRowPdf | {};
  customColor?: string;
}

export const DetailsTable = ({ title, columns, data, dataAvg, customColor }: Props) => {
  const color = useUiConfigColorsPrimary();

  return (
    <Container>
      <TableTitle style={{ color: customColor || color }}>{title}</TableTitle>
      <HeadingsRow style={{ borderBottomColor: color }}>
        <DetailsRow data={columns} />
      </HeadingsRow>
      {data.map((item: any, i: any) => (
        <TableRow key={i}>
          <DetailsRow data={item} />
        </TableRow>
      ))}
      {dataAvg && Object.keys(dataAvg) && (
        <TableAvg>
          <DetailsRow data={dataAvg as TPropertyRowPdf} />
        </TableAvg>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 35px;
  color: #808080;
`;

const TableTitle = styled.div`
  color: #6ab4e4;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 700;
  text-transform: uppercase;
`;

const HeadingsRow = styled.div`
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.gray[400]}`};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 13px;
  text-transform: uppercase;
`;

const TableRow = styled.div`
  height: 62px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  font-weight: 500;

  &:first-child,
  &:last-child {
    border-bottom: none;
  }
`;

const TableAvg = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 13px;
  text-transform: uppercase;
`;
