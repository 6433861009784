import Frame from './ModernFrameGama';
import thumbnail from './modernGama.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'modernGama';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TModernFrameGama = typeof id;
export default config;
