import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { SingleVideo } from './SingleVideo';
import { SingleVideoForm } from './SingleVideoForm';
import { TDynamicSlideTypeMapping } from '../../../types';

import thumbnail from './thumbnail.png';

const template = 'singleVideo';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - Single video only',
  frame: true,
  data: {
    videoUrl: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: SingleVideoForm,
  web: SingleVideo,
  pdf: null,
};

export type TSingleVideoTemplate = typeof template;

export { config, mappings, thumbnail };
