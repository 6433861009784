import React from 'react';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { HomeStagingGraph } from '../HomeStagingGraph/HomeStagingGraph.component';
import {
  Container,
  DescriptionContainer,
  GraphContainer,
  SourceText,
} from './ImportanceOfStagingModernPdf.styles';

import { data, heading, text, list, source } from '../../data';
import { HomeStagingDescription } from '../HomeStagingDescription/HomeStagingDescription.component';

export const ImportanceOfStagingModernPdf: React.FC = () => {
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <GraphContainer>
          <HomeStagingGraph heading={heading} graphData={data} isPdf />
        </GraphContainer>
        <DescriptionContainer>
          <HomeStagingDescription isPdf text={text} list={list} />
        </DescriptionContainer>
        <SourceText>Source: {source}</SourceText>
      </Container>
    </PdfFrame>
  );
};
