import styled from 'styled-components';
import { Typography } from '../../../../../../components/Common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsToggleHidden } from './useIsToggleHidden';
import { Report } from '../../../../../PresentationCreate/dash';
import Tooltip from '../../../../../../components/Common/Tooltip/Tooltip.component';
import { useParams } from 'react-router-dom';
import { usePresentation } from '../../../../../../hooks/usePresentation';
import { TPresentation } from '../../../../../../types';
import WarningConfirmPopup from '../../../../../../components/Common/WarningConfirmPopup/WarningConfirmPopup';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { Switch } from '../../../../../../components/Common/V2/Switch';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IncludeAllPropertiesSwitch = () => {
  const [isChecked, setIsChecked] = useState<boolean | null>(null);
  const { hash } = useParams<{ hash: string }>();
  const { data: presentation } = usePresentation(hash) as { data: TPresentation };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isHidden = useIsToggleHidden();
  const allProperties = Report.data.properties.useAllProperties();

  const { selectedSlide, setSlideDataProperty } = useSelectedSlide()!;

  const { setSlide } = useSlides();

  const isDisabled = useMemo(() => {
    const allPropertiesList = !allProperties
      ? []
      : [
          ...allProperties.active,
          ...allProperties.sold,
          ...allProperties.pending,
          ...(allProperties.comingSoon ?? []),
        ];

    return allPropertiesList?.length > 50;
  }, [allProperties]);

  const onTogglePropertiesSettings = useCallback(
    (isSwitchOn: boolean) => {
      if (!presentation?.slidesMap) return;

      const updatedSlide = {
        cmaReport: {
          ...(presentation?.slidesMap?.cmaReport || {}),
          data: { cmaReportShowAllProperties: isSwitchOn },
        },
      };
      if (selectedSlide?.id === updatedSlide.cmaReport?.id) {
        setSlideDataProperty('cmaReportShowAllProperties', isSwitchOn);
      }

      setSlide(updatedSlide.cmaReport?.id, updatedSlide?.cmaReport);
    },
    [presentation?.slidesMap, selectedSlide?.id, setSlide, setSlideDataProperty],
  );

  useEffect(() => {
    if (!presentation?.slidesMap) return;
    const isShowAllProperties =
      presentation.slidesMap.cmaReport?.data?.cmaReportShowAllProperties || false;

    if (isShowAllProperties && isDisabled) {
      onTogglePropertiesSettings(false);
      setIsChecked(false);
      return;
    }

    if (isChecked !== null) return;

    setIsChecked(isShowAllProperties);
  }, [presentation, isDisabled, onTogglePropertiesSettings, isChecked]);

  const onCancelModal = () => {
    setIsChecked(false);
    setIsModalOpen(false);
  };

  const onSwitchOn = async () => {
    onTogglePropertiesSettings(true);
    setIsModalOpen(false);
  };

  const onToggleSwitch = (isSwitchOn: boolean) => {
    if (isSwitchOn) {
      setIsChecked(true);
      setIsModalOpen(true);
      return;
    }

    onTogglePropertiesSettings(false);

    setIsChecked(false);
  };

  if (isHidden) return <div />;

  return (
    <Container>
      <Tooltip
        text={isDisabled ? 'Listing count exceeds 50. Please reduce and try again.' : ''}
        tooltipContentStyle={{
          top: 'auto',
          bottom: 'calc(100% + 5px)',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Switch
          checked={!!isChecked}
          onChange={() => onToggleSwitch(!isChecked)}
          disabled={isDisabled}
        />
      </Tooltip>
      <SwitchLabel>Include page for every listing</SwitchLabel>
      <WarningConfirmPopup
        open={isModalOpen}
        heading={`Are you sure?`}
        description={`By including a page for every listing you will drastically increase the size of your PDF presentation and it may take up to 60 seconds to be created.`}
        onConfirm={onSwitchOn}
        onCancel={onCancelModal}
        onClose={onCancelModal}
        confirmButtonText='Yes, include'
        cancelButtonText='No, cancel'
      />
    </Container>
  );
};

const SwitchLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
