import React from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Draggable } from 'react-beautiful-dnd';
import { Switch } from '../../../../../../../../../components/Common/V2/Switch';
import { TSlide } from '../../../../../../../../../types';
import { FEATURES, useHasFeatureFlag } from '../../../../../../../../../hooks/useFeatureFlags';

interface SlidesConfigItemComponentProps {
  disabled: boolean;
  selected: boolean;
  slide: TSlide;
  checked?: boolean;
  index: number;
  onClick: () => void;
  onSwitch: () => void;
}

export const SlideItem: React.FC<SlidesConfigItemComponentProps> = ({
  disabled,
  selected,
  slide,
  checked,
  index,
  onClick,
  onSwitch,
}) => {
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  if (!slide || slide.excludeFromConfig) {
    return null;
  }

  const label = hasBtTheme && Boolean(slide.boldTrailLabel) ? slide.boldTrailLabel : slide.label;

  return (
    <Draggable
      key={slide.id}
      draggableId={slide.id}
      index={index}
      isDragDisabled={slide.id === 'closingAndNextSteps'}
    >
      {(provided, snapshot) => (
        <SlideItemContainer ref={provided.innerRef} {...provided.draggableProps}>
          <SlideConfigContainer
            isDragging={snapshot.isDragging}
            disabled={disabled}
            selected={selected}
            onClick={onClick}
          >
            <div {...provided.dragHandleProps}>
              {slide.id !== 'closingAndNextSteps' ? (
                <FontAwesomeIcon icon={'grip-dots-vertical'} />
              ) : null}
            </div>
            <TitleContainer>
              <SlideItemTitle>{label}</SlideItemTitle>
              <Switch
                onChange={onSwitch}
                checked={checked}
                disabled={['closingAndNextSteps'].includes(slide.id)}
              />
            </TitleContainer>
          </SlideConfigContainer>
        </SlideItemContainer>
      )}
    </Draggable>
  );
};

const SlideItemContainer = styled.div`
  padding: 5px 0;
`;

const SlideConfigContainer = styled.div<{
  disabled: boolean;
  selected: boolean;
  isDragging?: boolean;
}>`
  display: flex;
  padding: 15px;
  gap: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.v2.gray[100] : theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

const SlideItemTitle = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
