type Props = {
  idCSS: string;
};

export function GradientSvg({ idCSS }: Props) {
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={'rotate(44)'}>
          <stop offset='7%' stopColor='#3c1787' />
          <stop offset='14%' stopColor='#6d10ab' />
          <stop offset='21%' stopColor='#940ac9' />
          <stop offset='28%' stopColor='#b305e1' />
          <stop offset='35%' stopColor='#ca02f1' />
          <stop offset='41%' stopColor='#d700fb' />
          <stop offset='47%' stopColor='#dc00ff' />
          <stop offset='53%' stopColor='#dc00fb' />
          <stop offset='59%' stopColor='#de00ef' />
          <stop offset='65%' stopColor='#e000dc' />
          <stop offset='71%' stopColor='#e400c1' />
          <stop offset='77%' stopColor='#e9009d' />
          <stop offset='82%' stopColor='#ef0072' />
          <stop offset='88%' stopColor='#f60040' />
          <stop offset='94%' stopColor='#f00' />
        </linearGradient>
      </defs>
    </svg>
  );
}
