import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { corePresentApi } from '../../../api/CorePresentApi';
import { AddClientForm } from '../AddClientForm/AddClientForm.component';
import { FitContentModal } from '../../Common';
import Loader from '../../Loader/Loader';
import { AutosuggestClients } from '../AutosuggestClients/AutosuggestClients.component';
import { TClient, TPresentationType } from '../../../types';

import {
  AutocompleteWrapper,
  AutocompleteLabel,
  Container,
  AddClientLink,
  ClientsInputWrapper,
} from './AddClient.styles';
import { PresentationTypeProvider } from '../../../providers/providers/PresentationTypeProvider';
import { PresentationApi } from '../../../pages/Presentation/api/PresentationApi';
import { useInvalidatePresentation } from '../../../hooks/usePresentation';
import { useBrandName } from '../../../hooks/useBrandName';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';

interface AddClientProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  presentationClient: TClient | null;
  getSavedClient?: (client: any) => void;
  presentationType?: TPresentationType | null;
  triggerPdf?: boolean;
  hash?: string;
}

export const AddClient: React.FC<AddClientProps> = ({
  isOpen,
  onClose,
  id,
  presentationClient,
  getSavedClient = () => {},
  presentationType,
  triggerPdf,
  hash,
}) => {
  const { core } = useBrandName();
  const [client, setClient] = useState<TClient | null>(null);
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const [loading, setLoading] = useState(false);

  const [showClientForm, setShowClientForm] = useState(false);

  const queryClient = useQueryClient();

  const invalidatePresentation = useInvalidatePresentation(hash);

  const updatePresentationWithClient = async (client: TClient) => {
    setLoading(true);
    try {
      await corePresentApi.put(`/presentations/${id}`, {
        client_name: client?.name,
        client_id: client?.id,
        client_email: client?.email,
      });

      if (triggerPdf) {
        await PresentationApi.triggerPdfGeneration(id);

        queryClient.invalidateQueries(['presentation-pdf', id]);
      }

      invalidatePresentation();

      getSavedClient(client);
      setLoading(false);
      onClose();
    } catch (e: any) {
      console.error(e);
      setLoading(false);
    }
  };

  if (showClientForm) {
    return (
      <PresentationTypeProvider initValue={presentationType || undefined}>
        <AddClientForm
          isOpen={isOpen}
          onClose={onClose}
          setShowClientForm={setShowClientForm}
          setClient={updatePresentationWithClient}
        />
      </PresentationTypeProvider>
    );
  }

  const linkText = shouldUseBtTheme
    ? 'Don’t have a client created? Click here'
    : `Don’t have a ${core} client created? Click here`;

  return (
    <FitContentModal
      isOpen={isOpen}
      headerTitle='Add Client'
      onClose={onClose}
      confirmBtnText='Add'
      hasCancelBtn
      onConfirmClick={() => client && updatePresentationWithClient(client)}
    >
      {loading ? (
        <Container style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loader size={'4x'} />
        </Container>
      ) : (
        <Container>
          <AutocompleteWrapper>
            <AutocompleteLabel>Search for client</AutocompleteLabel>
            <ClientsInputWrapper>
              <AutosuggestClients
                initialValue={presentationClient ? presentationClient.name : ''}
                setClient={setClient}
              />
            </ClientsInputWrapper>
          </AutocompleteWrapper>
          <AddClientLink onClick={() => setShowClientForm(true)}>{linkText}</AddClientLink>
        </Container>
      )}
    </FitContentModal>
  );
};
