import { useMemo, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { TProperty } from '../../../types';
import { useReportData } from '../../../pages/PresentationCreate/useReportData';
import { getPropertyId } from '../services/getPropertyId';
import { usePresentationFromParams } from './usePresentationFromParams';
import { useMaxPropertiesLimitToast } from './useMaxPropertiesLimitToast';
import { excludedPropertyIdsAtom } from '../state/excludedPropertyIdsAtom';
import { useUpdatePresentation } from '../../../hooks/useUpdatePresentation';
import { propertyToMarker } from '../state/highlighted-properties/propertyToMarker';
import { highlightedPropertiesAtom } from '../state/highlighted-properties/highlightedPropertiesAtom';
import { isUpdatePresentationWithBuildAtom } from '../state/isUpdatePresentationWithBuildAtom';
import { useToggleProperty } from './useToggleProperty';

export const useActionButtons = (property: TProperty) => {
  const propertyId = getPropertyId(property);
  const excludedIds = useAtomValue(excludedPropertyIdsAtom);
  const setUpdatePresentationWithBuild = useSetAtom(isUpdatePresentationWithBuildAtom);
  const { toggleProperty, toggleExcludedProperty } = useToggleProperty();
  const getReportData = useReportData();

  const { data: presentation } = usePresentationFromParams();

  const { mutate: updatePresentation } = useUpdatePresentation(presentation?.hash);

  const { maxPropertiesLimitToast, canAddMoreItemsToScale } = useMaxPropertiesLimitToast();

  const highlightedProperties = useAtomValue(highlightedPropertiesAtom);

  const [excludeInProgress, setExcludeInProgress] = useState<string | null>(null);

  const isLiked = useMemo(
    () =>
      !highlightedProperties.some(p => getPropertyId(p) === propertyId) &&
      !excludedIds.includes(propertyId),
    [highlightedProperties, excludedIds, propertyId],
  );

  const isFeatured = useMemo(
    () => Boolean(highlightedProperties?.find(p => getPropertyId(p) === propertyId)),
    [highlightedProperties, propertyId],
  );

  const isDeleted = useMemo(() => excludedIds?.includes(propertyId), [excludedIds, propertyId]);

  const onToggleDelete = async (propertyId: string) => {
    setExcludeInProgress(propertyId);

    if (!presentation?.type || !presentation?.id) return;

    await toggleExcludedProperty(property, presentation?.type, presentation?.id);

    setExcludeInProgress(null);

    const reportData = getReportData();

    updatePresentation({
      ...presentation,
      reportData,
    });

    setUpdatePresentationWithBuild(false);
  };

  const onToggleHighlight = (property: TProperty) => {
    const formattedProperty = propertyToMarker(property) as unknown as TProperty; // TODO: fix types
    toggleProperty(formattedProperty);
  };

  const onHighlightClick = (property: TProperty) => {
    if (!canAddMoreItemsToScale && !isFeatured) {
      maxPropertiesLimitToast();
      return;
    }

    onToggleHighlight(property);
  };

  return {
    isLiked,
    isDeleted,
    isFeatured,
    excludeInProgress,
    onToggleDelete,
    onToggleHighlight,
    onHighlightClick,
  };
};
