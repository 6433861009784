import React, { createContext, useContext, useState } from 'react';

type UiUseKvCoreLogoContextType = {
  useKvCoreLogo: boolean;
  setUseKvCoreLogo: (value: boolean) => void;
};

interface Props {
  initValue?: boolean;
}

const UiUseKvCoreLogoContext = createContext<UiUseKvCoreLogoContextType | undefined>(undefined);

export const UiUseKvCoreLogoProvider: React.FC<Props> = ({ initValue, children }) => {
  const [useKvCoreLogo, setUseKvCoreLogo] = useState(initValue || false);

  const value = React.useMemo(() => ({ useKvCoreLogo, setUseKvCoreLogo }), [useKvCoreLogo]);

  return (
    <UiUseKvCoreLogoContext.Provider value={value}>{children}</UiUseKvCoreLogoContext.Provider>
  );
};

export const useUiUseKvCoreLogo = () => {
  const context = useContext(UiUseKvCoreLogoContext);

  if (!context)
    throw new Error('useUiUseKvCoreLogo cannot be used without UiUseKvCoreLogoProvider');

  return context;
};
