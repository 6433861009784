import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { ThreeImagesGamma } from './ThreeImagesGamma';
import { ThreeImagesGammaForm } from './ThreeImagesGammaForm';
import { ThreeImagesGammaPdf } from './ThreeImagesGammaPdf';
import thumbnail from './thumbnail.png';

const template = 'threeImagesLayoutGamma';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Three Image Layout',
  frame: true,
  data: {
    image: '',
    image2: '',
    image3: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: ThreeImagesGammaForm,
  web: ThreeImagesGamma,
  pdf: ThreeImagesGammaPdf,
};

export type TThreeImagesLayoutGamma = typeof template;
export { config, mappings, thumbnail };
