import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { CATEGORIES } from '../../../../../constants/categories';
import { WhatFactorsInfluencePricingModern } from './web/modern/WhatFactorsInfluencePricingModern';
import { WhatFactorsInfluencePricingLuxury } from './web/luxury/WhatFactorsInfluencePricingLuxury';
import { WhatFactorsInfluencePricingTraditional } from './web/traditional/WhatFactorsInfluencePricingTraditional';
import WhatFactorsInfluencePricingModernPDF from './pdf/modern/WhatFactorsInfluencePricingModernPDF';
import WhatFactorsInfluencePricingLuxuryPDF from './pdf/luxury/WhatFactorsInfluencePricingLuxuryPDF';
import WhatFactorsInfluencePricingTraditionalPDF from './pdf/traditional/WhatFactorsInfluencePricingTraditionalPDF';

const id = 'whatFactorsInfluencePricing';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'What factors influence pricing',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: WhatFactorsInfluencePricingTraditional,
    [CATEGORIES.MODERN]: WhatFactorsInfluencePricingModern,
    [CATEGORIES.LUXURY]: WhatFactorsInfluencePricingLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: WhatFactorsInfluencePricingTraditionalPDF,
    [CATEGORIES.MODERN]: WhatFactorsInfluencePricingModernPDF,
    [CATEGORIES.LUXURY]: WhatFactorsInfluencePricingLuxuryPDF,
  },
};

export type TWhatFactorsInfluencePricing = typeof id;
export { config, mappings };
