import styled from 'styled-components';

import { LargerFeatures } from './LargerFeatures';
import { SmallerFeatures } from './SmallerFeatures';
import { Disclaimer } from '../../components/Disclaimer';
import { SidebarTitle } from '../components/SidebarTitle';
import { useHasComparableAdjustments } from '../../../../hooks/useHasComparableAdjustments';

export const OutliersSidebarContent = () => {
  const hasAdjustments = useHasComparableAdjustments();

  return (
    <Container>
      <div>
        <SidebarTitle title='OUTLIERS' iconName='flag_pennant' />
        <Wrapper>
          <LargerFeatures />
          <SmallerFeatures />
        </Wrapper>
      </div>

      {!hasAdjustments ? null : (
        <Disclaimer
          text='Note: Actual prices are used in the outliers.'
          style={{ textAlign: 'center' }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
