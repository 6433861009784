import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { TSellingPace } from '../../../types';
import { useSellingPaceUI } from '../../../hooks/useSellingPaceUI';
import { replaceFillColors } from '../../../services/replaceFillColors';
import { useUiConfigColorsPrimary } from '../../../providers/providers/UiConfigColorProvider';
import { isPresentationViewAtom } from '../../../features/report/state/isPresentationViewAtom';
import { useWhiteLabelSettings } from '../../../features/whitelabel/useWhiteLabelSettings';

interface SellingPaceCardContentProps {
  data: TSellingPace | null;
}

export const SellingPaceCardContent = ({ data }: SellingPaceCardContentProps) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primaryColor = useUiConfigColorsPrimary();
  const { hasWL, primary } = useWhiteLabelSettings();
  const { image } = useSellingPaceUI(data);

  const color = useMemo(() => {
    if (isPresentationView) return primaryColor;

    if (!hasWL) return '#6ab4e4';

    return primary;
  }, [isPresentationView, hasWL, primaryColor, primary]);

  const imageUrl = replaceFillColors(image, color);

  return <Image src={imageUrl} />;
};

const Image = styled.img`
  height: 90px;
`;
