import { ReactElement } from 'react';
import { SlideFactory } from '../components/Slide/services/SlideFactory';
import { CATEGORIES } from '../constants/categories';
import { useCategory } from '../providers/providers/CategoryProvider';
import { TSlideTheme, TSlidePlatform, TSlide, TSlideId, TCategory } from '../types';

export const isMultiPageSlide = (platform: TSlidePlatform, slide: TSlide) => {
  return (
    platform === 'pdf' &&
    (slide.id === 'cmaReport' ||
      slide.id === 'marketOverview' ||
      slide.id === 'listingFeedback' ||
      slide.id === 'listingDetails')
  );
};

export const generateMultiPageSlide = (
  slide: TSlide,
  theme: TSlideTheme,
  platform: TSlidePlatform = 'web',
  category: TCategory | null,
  isEditable?: boolean,
): ReactElement[] | null => {
  const slideBuilderFunc = SlideFactory.create({
    slide: slide.id as TSlideId,
    theme,
    platform,
    isEditable,
    template: slide.template,
    category: category ?? CATEGORIES.TRADITIONAL,
  });

  return slideBuilderFunc?.() ?? null;
};
