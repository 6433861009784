import styled from 'styled-components';
import Loader from '../../../../../../../components/Loader/Loader';
import { Button } from './Button';
import { PresentationApi } from '../../../../../../Presentation/api/PresentationApi';
import { useState } from 'react';

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
`;

interface Props {
  buildInProgress: boolean;
  hash: string;
  pdf: string;
}

export const PreviewButtonsCell = ({ buildInProgress, hash, pdf }: Props) => {
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const onDownloadPdf = async () => {
    try {
      setIsPdfLoading(true);
      await PresentationApi.downloadPresentation(pdf, hash);
    } catch (e: any) {
      console.error('Saving PDF ERROR: ', e);
    } finally {
      setIsPdfLoading(false);
    }
  };

  return (
    <ButtonWrapper>
      <Button onClick={() => window.open(`/present/presentation/${hash}`, '_blank')}>Web</Button>
      <Button
        disabled={buildInProgress}
        onClick={onDownloadPdf}
        style={{ width: 53, justifyContent: 'center' }}
      >
        {buildInProgress || isPdfLoading ? <Loader /> : 'PDF'}
      </Button>
    </ButtonWrapper>
  );
};
