import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TAddSlideBtnStyle } from '../../../../../../../types';
import { useModalVisibility } from '../../providers/ModalVisibilityProvider';
import { Button as ButtonOld, Modal } from '../../../../../../../components/Common';
import { Button } from '../../../../../../../components/Common/V2/Button/Button';

const Container = styled.div``;

const AddButton = styled(ButtonOld)`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 60px;
  padding-right: 10px;
  gap: 10px;
  background-color: ${({ theme }) => theme.colors.v2.background};
  border-top: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

interface Props {
  buttonLabel?: string;
  onOpen?: () => void;
  onClose?: () => void;
  onAddClick?: () => void;
  btnStyle?: TAddSlideBtnStyle;
}

export const AddButtonWithModal: React.FC<Props> = ({
  children,
  buttonLabel = 'Add Slide',
  onOpen = () => {},
  onClose = () => {},
  onAddClick = () => {},
  btnStyle = 'add',
}) => {
  const { isOpen, openModal, closeModal } = useModalVisibility();

  const onOpenClick = React.useCallback(() => {
    openModal();
    onOpen();
  }, [onOpen, openModal]);

  const onCloseClick = React.useCallback(() => {
    closeModal();
    onClose();
  }, [closeModal, onClose]);

  return (
    <Container>
      <AddButton btnstyle={btnStyle} size='small' onClick={onOpenClick}>
        <FontAwesomeIcon icon={['fas', 'circle-plus']} style={{ marginRight: 10 }} />
        {buttonLabel}
      </AddButton>

      {!isOpen ? null : (
        <Modal
          title='Add a Slide'
          zIndex={100}
          open={isOpen}
          isBlueHeader
          onClose={onCloseClick}
          modalContainerStyles={{ height: 'max-content', alignSelf: 'center' }}
          contentStyles={{ height: 'max-content' }}
        >
          <Wrapper>{children}</Wrapper>
          <Footer>
            <Button variant='secondary' onClick={onCloseClick}>
              Cancel
            </Button>
            <Button variant='primary' onClick={onAddClick}>
              Add Selected Slide
            </Button>
          </Footer>
        </Modal>
      )}
    </Container>
  );
};
