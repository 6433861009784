import React, { useEffect, useState } from 'react';
import { AnalyticsGraphCard } from '../components/AnalyticsGraphsCard/AnalyticsGraphsCard.component';
import {
  PercentageGraph,
  TPercentagesBarData,
} from '../components/PercentageGraph/PercentageGraph.component';

interface BuyerBarrierBreakdownProps {
  buyerBarrierBreakdown: TPercentagesBarData[];
  isWithAgentData?: boolean;
}
export const BuyerBarrierBreakdown: React.FC<BuyerBarrierBreakdownProps> = ({
  buyerBarrierBreakdown,
  isWithAgentData,
}) => {
  const [buyerBarrierData, setBuyerBarrierData] = useState<TPercentagesBarData[]>([]);

  useEffect(() => {
    setBuyerBarrierData(buyerBarrierBreakdown);
  }, [buyerBarrierBreakdown]);

  const onCheckboxClick = (showOfficeData: boolean) => {
    if (showOfficeData) {
      setBuyerBarrierData(buyerBarrierBreakdown);
    } else {
      const filteredData = buyerBarrierBreakdown.map(item => {
        return {
          title: item.title,
          agent: item.agent,
        };
      });
      setBuyerBarrierData(filteredData);
    }
  };
  return (
    <AnalyticsGraphCard
      title='BUYER Barrier Breakdown'
      source='Based on data sourced from web reports'
      isWithAgentData={isWithAgentData}
      onCheckboxClick={onCheckboxClick}
    >
      {buyerBarrierData.map((dataItem, i) => (
        <PercentageGraph
          key={i}
          title={dataItem.title}
          office={dataItem.office}
          agent={dataItem.agent}
        />
      ))}
    </AnalyticsGraphCard>
  );
};
