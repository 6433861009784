import { useState } from 'react';
import styled from 'styled-components';

import { TProperty } from '../../../../../types';
import { PropertyActions } from './PropertyActions/PropertyActions';
import { ListingInfoAndDescription } from '../../../../../components/Property/DetailDescription/ListingInfoAndDescription/ListingInfoAndDescription';
import { ComparableDetails } from '../../../../../components/Property/DetailDescription/ComparableDetails';
import { Notes } from './Notes/Notes';
import { Intro } from '../../../../../components/Property/DetailDescription/Intro/Intro';

interface Props {
  property: TProperty;
  hideActions?: boolean;
}

export const DropdownKeys = {
  COMPARABLE_DETAILS: 'comparableDetails',
  LISTING_INFO_AND_DESCRIPTION: 'listingInfoAndDescription',
  NOTES: 'notes',
};

export const DetailDescription = ({ property, hideActions }: Props) => {
  const [openedDropdown, setOpenedDropdown] = useState(DropdownKeys.COMPARABLE_DETAILS);

  return (
    <Container>
      <PropertyActions property={property} hideActions={hideActions} />
      <Wrapper>
        <Intro property={property} hideActions={hideActions} />
        <ComparableDetails
          property={property}
          openedDropdown={openedDropdown}
          setOpenedDropdown={setOpenedDropdown}
        />
        <ListingInfoAndDescription
          property={property}
          openedDropdown={openedDropdown}
          setOpenedDropdown={setOpenedDropdown}
        />
        <Notes
          property={property}
          openedDropdown={openedDropdown}
          setOpenedDropdown={setOpenedDropdown}
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  overflow-y: auto;
  width: 38%;

  @media all and (max-width: 992px) {
    width: 100%;
    max-height: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
