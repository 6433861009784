import styled, { CSSObject } from 'styled-components';

interface Props {
  displayImage?: boolean;
}

export const backgroundGradient =
  'radial-gradient(33.33% 40.19% at 59.28% 61.43%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)';

export const Container = styled('div')<Props>(({ displayImage }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: displayImage ? 'flex-end' : 'flex-end',
  position: 'relative',
}));

export const Image = styled('img')({
  background: backgroundGradient,
  position: 'absolute',
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

const getWrapperStyles = (displayImage?: boolean): CSSObject => {
  if (displayImage) {
    return {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      paddingLeft: 55,
      paddingBottom: 36,
      paddingRight: 60,
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0) 19.31%, rgba(255, 255, 255, 0.83) 52.21%, rgba(255, 255, 255, 0.95) 71.67%, #FFFFFF 88.3%)',
      height: 378,
    };
  }

  return { flexDirection: 'column', alignItems: 'center', height: '62.5%', marginBottom: 90 };
};

export const Wrapper = styled('div')<Props>(({ displayImage }) => ({
  display: 'flex',
  position: 'relative',
  zIndex: 5,
  ...getWrapperStyles(displayImage),
}));

export const Text = styled('h2')<Props>(({ displayImage }) => ({
  fontFamily: 'Cinzel',
  textAlign: displayImage ? 'left' : 'center',
  width: displayImage ? 'max-content' : '100%',
  fontSize: displayImage ? 17 : 16,
  color: '#000',
  fontWeight: 400,
  marginBottom: 16,
}));

const getPropertyAddressStyles = (displayImage?: boolean): CSSObject => {
  const withImage: CSSObject = {
    background:
      'radial-gradient(48.32% 92.55% at 49.99% 38.3%, rgba(199, 199, 199, 0.6) 0%, rgba(114, 114, 114, 0.6) 61.49%, rgba(0, 0, 0, 0.9) 100%)',
    fontSize: 30,
    backgroundBlendMode: 'multiply',
    backgroundColor: '#f6f6f6',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
    fontWeight: 700,
    marginBottom: 0,
    width: displayImage ? 'auto' : '65%',
    maxWidth: '100%',
    boxShadow: '0px 1px 1px 0px #000000 30%',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  };

  const noImage: CSSObject = {
    fontSize: 73,
    textShadow: '0px 1.23242px 1.23242px rgba(0, 0, 0, 0.3)',
    padding: '10px 40px',
    width: 'max-content',
    borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
    backgroundBlendMode: 'screen',
    background:
      'radial-gradient(67.58% 179.59% at 49.99% 38.3%, rgba(255, 255, 255, 0.63) 0%, rgba(114, 114, 114, 0.63) 69.17%, rgba(0, 0, 0, 0.63) 100%)',
    backgroundColor: '#000000',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontWeight: 700,
  };

  return displayImage ? withImage : noImage;
};

export const PropertyInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  transform: 'translateY(10px)',
});

export const SlideTitle = styled(Text)<Props>(({ displayImage }) => ({
  ...getPropertyAddressStyles(displayImage),
}));

export const PropertyFeaturesContainer = styled('div')<Props>(({ displayImage }) => ({
  display: 'flex',
  justifyContent: displayImage ? 'flex-start' : 'center',
}));

export const PropertyFeature = styled(Text)<Props>(({ displayImage }) => ({
  fontSize: displayImage ? 18 : 24,
  color: '#000',
  marginTop: 10,
  opacity: 0.9,
  paddingLeft: displayImage ? 14 : 32,
  width: 'max-content',
  fontWeight: 400,
  '&:not(:last-of-type)': {
    paddingRight: displayImage ? 14 : 32,
    borderRight: `1px solid #000`,
  },
}));

export const TextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
