import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { TextEpsilon } from './TextEpsilon';
import { TextEpsilonForm } from './TextEpsilonForm';
import { TextEpsilonPdf } from './TextEpsilonPdf';

import thumbnail from './thumbnail.png';

const template = 'textLayoutEpsilon';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Text Only',
  frame: true,
  data: {
    text: '',
    text2: '',
    headline: '',
    headline2: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TextEpsilonForm,
  web: TextEpsilon,
  pdf: TextEpsilonPdf,
};

export type TTextLayoutEpsilon = typeof template;
export { config, mappings, thumbnail };
