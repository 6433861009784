import {
  ACTIVE_COLOR,
  CLOSED_COLOR,
  PENDING_COLOR,
} from '../pages/PresentationCreate/dash/config/constants';
import { PROPERTY_STATUS, StatusShort } from '../types';
import btTheme from '../config/themes/bold-trail/colors';
import cpTheme from '../config/themes/core-present/colors';
import { FEATURES, useHasFeatureFlag } from './useFeatureFlags';

const getColorBasedOnStatus = (
  isBT: boolean,
  status: PROPERTY_STATUS | 'coming_soon' | StatusShort,
) => {
  if (status === 'active' || status === 'a') {
    return ACTIVE_COLOR;
  }
  if (status === 'pending' || status === 'p') {
    return PENDING_COLOR;
  }
  if (status === 'sold' || status === 'c') {
    return CLOSED_COLOR;
  }
  if (status === 'coming_soon' || status === 'comingSoon') {
    return isBT ? btTheme.v2.primary : cpTheme.v2.primary;
  }
};

export const useColorBasedOnStatus = () => {
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  return (status: PROPERTY_STATUS | 'coming_soon' | StatusShort) =>
    getColorBasedOnStatus(shouldUseBtTheme, status) || '#000000';
};
