import React, { useEffect, useRef, useState } from 'react';

interface ScaleContentProps {
  skipScaling?: boolean;
  predefinedHeight: number;
  predefinedWidth: number;
}

export const ScaleContent: React.FC<ScaleContentProps> = ({
  skipScaling,
  predefinedHeight,
  predefinedWidth,
  children,
}) => {
  const [scaleFactor, setScaleFactor] = useState(0);
  const [forceRerender, setForceRerender] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const { current } = ref;

  useEffect(() => {
    if (skipScaling) return;
    if (!current?.clientHeight) return;
    if (current.clientHeight < predefinedHeight) {
      setScaleFactor(1);
      return;
    }
    if (scaleFactor === 0 && !current && !forceRerender) setForceRerender(true);
    if (current) {
      const width = (predefinedWidth * current.clientHeight) / predefinedHeight;
      const factor = predefinedWidth / width;

      setScaleFactor(factor);
    }
  }, [
    scaleFactor,
    forceRerender,
    current?.clientHeight,
    skipScaling,
    current,
    predefinedHeight,
    predefinedWidth,
  ]);

  useEffect(() => {
    if (!current) {
      setForceRerender(true);
    }
  }, [current]);

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <div
        style={{
          transform: skipScaling ? 'none' : `scale(${scaleFactor})`,
          transformOrigin: 'center 0',
        }}
      >
        {children}
      </div>
    </div>
  );
};
