import { useParams } from 'react-router-dom';
import { usePresentationCreationQuery } from '../../../hooks/usePresentationCreationQuery';
import { usePresentation } from '../../../hooks/usePresentation';
import { useMemo } from 'react';

export const usePresentationFromParams = () => {
  const { presentationId } = useParams<{ presentationId: string }>();
  const { presentation: presentationIdQuery } = usePresentationCreationQuery();

  const presentationHash = presentationId || presentationIdQuery;
  const { isLoading, data } = usePresentation(presentationHash, { omitGlobalStoreSet: true });

  return useMemo(() => ({ isLoading, data }), [data, isLoading]);
};
