import React, { useEffect, useMemo, useState } from 'react';
import { SlidesTags } from '../../../../components/Common/SlidesTags/SlidesTags.component';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { useTags } from '../../../../hooks/dynamicSlides/useTags';
import { useLibraryDynamicSlides } from '../../../../hooks/useLibraryDynamicSlides';
import { DynamicSlidesFilter } from '../../../../services/DynamicSlidesFilter';
import { TTag } from '../../../../types';
import { useCarouselControls } from '../providers/CarouselControlsProvider';

export const DynamicSlidesTags: React.FC = () => {
  const { hash } = useUser();

  const tags = useTags();

  const tagsList = useMemo(() => Object.values(tags), [tags]);

  const { activeSection, setActiveSlides, onSelectSection } = useCarouselControls();
  const { data: dynamicSlides } = useLibraryDynamicSlides(hash);
  const { slides } = useSlides();

  const [selectedTags, setSelectedTags] = useState<TTag[]>([]);

  useEffect(() => {
    if (activeSection) {
      setSelectedTags([]);
    }
  }, [activeSection]);

  useEffect(() => {
    if (!selectedTags.length && !activeSection) {
      onSelectSection('whyIAmTheRightFit');
      return;
    }
    if (!selectedTags.length) {
      return;
    }
    const selectedTagsIds = selectedTags.map(tag => tag.id);

    const selectedTagExists = selectedTagsIds.every(tagId => {
      return tagsList.find(tag => tagId === tag?.id);
    });

    if (!selectedTagExists) {
      onSelectSection('whyIAmTheRightFit');
      return;
    }

    if (!dynamicSlides) return;

    const activeDynamicSlides = DynamicSlidesFilter.byTags(
      Object.values(dynamicSlides ?? {}),
      selectedTagsIds,
    );

    if (!activeDynamicSlides || !activeDynamicSlides.length) {
      setActiveSlides([]);
      return;
    }

    onSelectSection(undefined);
    setActiveSlides(activeDynamicSlides);
  }, [
    activeSection,
    dynamicSlides,
    onSelectSection,
    selectedTags,
    setActiveSlides,
    slides,
    tagsList,
  ]);

  return (
    <SlidesTags tagsList={tagsList} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
  );
};
