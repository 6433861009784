export default `<svg width="1640" height="187" viewBox="0 0 1640 187" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path d="M1034 144.587C1269.34 150.301 1401.91 156.368 1640 174.699V0H1034V144.587Z" fill="white"/>
<path d="M1034 144.587C1269.34 150.301 1401.91 156.368 1640 174.699V0H1034V144.587Z" fill="url(#paint0_linear)" fill-opacity="0.09"/>
</g>
<g filter="url(#filter1_d)">
<path d="M606 144.587C370.665 150.301 238.09 156.368 0 174.699V0H606V144.587Z" fill="white"/>
<path d="M606 144.587C370.665 150.301 238.09 156.368 0 174.699V0H606V144.587Z" fill="url(#paint1_linear)" fill-opacity="0.09"/>
</g>
<path d="M-19 164.699C218.14 147.658 350.544 142.093 587.5 134.699" stroke="url(#paint2_radial)" stroke-opacity="0.2"/>
<path d="M1658.5 164.699C1421.36 147.658 1288.96 142.093 1052 134.699" stroke="url(#paint3_radial)" stroke-opacity="0.2"/>
<g filter="url(#filter2_d)">
<path d="M570 135.953C576.5 143.199 606 137.13 606 144.699V133.629C594.288 131.547 586.079 133.241 570 135.953Z" fill="#D8CB91"/>
<path d="M570 135.953C576.5 143.199 606 137.13 606 144.699V133.629C594.288 131.547 586.079 133.241 570 135.953Z" fill="url(#paint4_linear)" fill-opacity="0.3"/>
</g>
<g filter="url(#filter3_d)">
<path d="M1070 135.953C1070 145.593 1034 137.13 1034 144.699V133.629C1045.71 131.547 1053.92 133.241 1070 135.953Z" fill="#D8CB91"/>
<path d="M1070 135.953C1070 145.593 1034 137.13 1034 144.699V133.629C1045.71 131.547 1053.92 133.241 1070 135.953Z" fill="url(#paint5_linear)" fill-opacity="0.3"/>
</g>
<g filter="url(#filter4_d)">
<path d="M1070 136C876.727 130.246 767.306 130.017 569.997 136C573.515 86.7006 572.503 47.7495 567 0.000488281L1072.5 0.000489773C1068.43 47.881 1066.53 86.8438 1070 136Z" fill="white"/>
<path d="M1070 136C876.727 130.246 767.306 130.017 569.997 136C573.515 86.7006 572.503 47.7495 567 0.000488281L1072.5 0.000489773C1068.43 47.881 1066.53 86.8438 1070 136Z" fill="url(#paint6_radial)" fill-opacity="0.1"/>
</g>
<defs>
<filter id="filter0_d" x="1024" y="-8" width="626" height="194.699" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter1_d" x="-10" y="-8" width="626" height="194.699" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter2_d" x="560" y="124.699" width="56" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter3_d" x="1024" y="124.699" width="56" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter4_d" x="557" y="-9.99951" width="525.5" height="155.999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<linearGradient id="paint0_linear" x1="1034" y1="77.6997" x2="1680" y2="77.6997" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="0.184021" stop-opacity="0.12"/>
<stop offset="0.546875" stop-color="white" stop-opacity="0.59"/>
<stop offset="0.864583"/>
<stop offset="1" stop-opacity="0.99"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="606" y1="77.6997" x2="-40" y2="77.6997" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="0.184021" stop-opacity="0.12"/>
<stop offset="0.546875" stop-color="white" stop-opacity="0.59"/>
<stop offset="0.864583"/>
<stop offset="1" stop-opacity="0.99"/>
</linearGradient>
<radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(277.5 134.699) rotate(175.645) scale(355.527 7187.57)">
<stop stop-color="#AA9327"/>
<stop offset="1" stop-color="#AA9327" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1362 134.699) rotate(4.35543) scale(355.527 7187.57)">
<stop stop-color="#AA9327"/>
<stop offset="1" stop-color="#AA9327" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint4_linear" x1="606" y1="136.987" x2="568.188" y2="136.987" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="1034" y1="136.987" x2="1071.81" y2="136.987" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<radialGradient id="paint6_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(824.745 57.8966) scale(260.243 988.514)">
<stop stop-color="#ADADAD" stop-opacity="0.22"/>
<stop offset="0.302083" stop-color="white" stop-opacity="0.65"/>
<stop offset="0.916667" stop-opacity="0.6"/>
<stop offset="0.978292"/>
</radialGradient>
</defs>
</svg>
`;
