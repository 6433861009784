import React, { createContext, useContext, useState } from 'react';
import { TSlideInfoPlacement } from '../../types';

type InfoPlacementSetter = (value: TSlideInfoPlacement) => TSlideInfoPlacement;

type InfoPlacementContextType = {
  infoPlacement: TSlideInfoPlacement['value'];
  infoPlacementData: TSlideInfoPlacement;
  setInfoPlacement: (value: TSlideInfoPlacement | InfoPlacementSetter) => void;
};

const UiInfoPlacementContext = createContext<InfoPlacementContextType | undefined>(undefined);

export const useUiConfigInfoPlacement = () => {
  const context = useContext(UiInfoPlacementContext);

  if (!context) {
    throw new Error('useUiConfigInfoPlacement cannot be used without UiInfoPlacementContext');
  }

  return context;
};

interface Props {
  initValue?: TSlideInfoPlacement;
}
export const UiConfigInfoPlacementProvider: React.FC<Props> = ({ initValue, children }) => {
  const [infoPlacement, setInfoPlacement] = useState<TSlideInfoPlacement>(
    initValue ?? { value: 'top' },
  );

  const value = React.useMemo(
    () => ({
      infoPlacement: infoPlacement.value,
      infoPlacementData: infoPlacement,
      setInfoPlacement,
    }),
    [infoPlacement],
  );

  return (
    <UiInfoPlacementContext.Provider value={value}>{children}</UiInfoPlacementContext.Provider>
  );
};
