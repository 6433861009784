import React from 'react';
import styled from 'styled-components';
import { CommonFrameProps } from '../../../../../types';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
}));

interface Props extends CommonFrameProps {
  style?: React.CSSProperties;
}

export const PlacementAwareContainer: React.FC<Props> = ({ children, infoPlacement, style }) => (
  <Container
    style={{
      flexDirection: infoPlacement === 'top' ? 'column' : 'column-reverse',
      ...style,
    }}
  >
    {children}
  </Container>
);
