import styled from 'styled-components';
import { MoneyFormatter } from '../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { limitLongString } from '../../../../../services/limitLongString';
import { StatusShort, THighlightedProperty } from '../../../../../types';
import { getPropertyStatus } from '../../../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';
import { useAtom } from 'jotai';
import { isAdjustedPriceModeAtom } from '../../../state/isAdjustedPriceModeAtom';
import { useMemo } from 'react';
import { useColorBasedOnStatus } from '../../../../../hooks/useColorBasedOnStatus';
interface Props {
  property: THighlightedProperty | null;
  text: string;
  price: number;
  isLastMarker: boolean;
  slideMode: string;
  adjustedPrice?: number;
  overridePlotBy?: boolean;
  isPdf?: boolean;
}

export const LabelAndAddressWithPrice = ({
  property,
  text,
  price,
  isLastMarker,
  slideMode,
  adjustedPrice,
  overridePlotBy,
  isPdf,
}: Props) => {
  const [isAdjustedPriceMode] = useAtom(isAdjustedPriceModeAtom);

  const useAdjPrice = isPdf ? Boolean(overridePlotBy) : isAdjustedPriceMode;

  const status = getPropertyStatus(property?.status) as StatusShort;
  const getColorBasedOnStatus = useColorBasedOnStatus();
  const color = getColorBasedOnStatus(status);

  const mainPrice = useMemo(() => {
    if (!adjustedPrice) return MoneyFormatter.format(price);
    if (!useAdjPrice) return MoneyFormatter.format(price);

    return MoneyFormatter.format(adjustedPrice);
  }, [adjustedPrice, useAdjPrice, price]);

  const secondaryPrice = useMemo(() => {
    if (!adjustedPrice) return '';
    if (!useAdjPrice) return `${MoneyFormatter.format(adjustedPrice)} ADJ`;

    return `${MoneyFormatter.format(price)} ACTUAL`;
  }, [adjustedPrice, useAdjPrice, price]);

  let finalText = `${MoneyFormatter.format(price)} – ${text}`;
  if (isLastMarker) {
    const isWebSlide = slideMode === 'web';
    const isPdfSlide = slideMode === 'pdf';
    const limitChars = isWebSlide ? 28 : isPdfSlide ? 26 : 33;
    finalText = limitLongString(finalText, limitChars);
  }

  return (
    <>
      {property?.status ? (
        <div style={{ color, textAlign: 'left' }}>
          {text}
          <br />
          <PriceWrapper>
            <PriceText>{mainPrice}</PriceText>
            <PriceTextItalic>{secondaryPrice}</PriceTextItalic>
          </PriceWrapper>
        </div>
      ) : (
        <span>{text}</span>
      )}
    </>
  );
};

const PriceWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 10px;
  gap: 5px;
  margin-top: 2px;
`;

const PriceText = styled.span`
  font-weight: 700;
`;

const PriceTextItalic = styled.span`
  font-style: italic;

  font-weight: 500;
`;
