import React from 'react';
import image from './image.png';
import * as data from '../../data';
import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';

const ImportanceOfOpeningWeekendLuxuryPDF: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading={data.title}
      paragraphs={data.paragraphs}
      image={image}
      paragraphStyles={{ marginBottom: 20 }}
    />
  );
};

export default ImportanceOfOpeningWeekendLuxuryPDF;
