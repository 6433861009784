import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

type WithCanadaUser = {
  isCanadaUser?: boolean;
};

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  background: theme.colors.gray[100],
}));

export const LeftCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  padding: 80,
  width: 500,
  boxShadow: '4px 0px 30px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
});

export const Graphic = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  height: isCanadaUser ? 'auto' : '80%',
  width: isCanadaUser ? '100%' : 'auto',
  filter: isCanadaUser ? 'grayscale(1)' : 'none',
}));

export const RightCol = styled('div')<WithCanadaUser>(({ isCanadaUser }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  paddingRight: isCanadaUser ? 0 : 36,
  flex: 1,
}));

export const H2 = styled('h2')(({ theme }) => ({
  fontFamily: useUiConfigFontsHeader(),
  color: useUiConfigColorsPrimary(),
  fontWeight: 900,
  lineHeight: 1.95,
  fontSize: 20,
  marginBottom: 20,
  textTransform: 'uppercase',
  letterSpacing: useUiConfigHeaderLetterSpacing('0.08em'),
}));

const commonStyles = {
  fontSize: 18,
  color: '#000',
  lineHeight: 1.58,
  marginBottom: 20,
  textAling: 'left',
  width: '100%',
};

export const Text = styled('p')(({ theme }) => ({
  ...commonStyles,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const UL = styled('ul')({ paddingLeft: 10, listStyle: 'none' });

export const LI = styled('li')(({ theme }) => ({
  ...commonStyles,
  position: 'relative',
  letterSpacing: useUiConfigBodyLetterSpacing(),
  '&:before': {
    content: `" "`,
    display: 'block',
    position: 'absolute',
    width: 4,
    height: 4,
    left: -10,
    top: 10,
    borderRadius: 50,
    backgroundColor: '#000',
  },
}));

export const SourceText = styled('p')(({ theme }) => ({
  color: '#101010',
  fontSize: 13.5,
  fontWeight: 300,
  position: 'absolute',
  width: 'max-content',
  bottom: 20,
  right: 80,
}));
