import styled from 'styled-components';

type TSlideProps = {
  animate: boolean;
  isSingleSlide: boolean;
};

type WithSelected = {
  isSelected: boolean;
};

export const SlideLabel = styled('h6')<WithSelected>(({ theme, isSelected }) => ({
  fontSize: isSelected ? 18 : 12,
  fontWeight: 700,
  color: theme.colors.v2.text.regular,
  textTransform: 'capitalize',
  opacity: isSelected ? 1 : 0.4,
}));

export const SlideWrapper = styled('div')<TSlideProps>(({ animate, isSingleSlide }) => ({
  background: 'transparent',
  position: 'relative',
  transition: 'all 5s',
  width: isSingleSlide ? '70%' : 'auto',
  margin: isSingleSlide ? '0 auto' : 0,
  '@keyframes fadeOutIn': {
    '0%': {
      opacity: 0.7,
    },
    '100%': {
      opacity: 1,
    },
  },
  animationName: animate ? 'fadeOutIn' : '',
  animationDuration: '1s',
}));

export const SlideOverlay = styled('div')<WithSelected>(({ isSelected }) => ({
  position: 'relative',
  filter: isSelected ? 'drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.3))' : 'none',
  '&:after': {
    content: '""',
    display: isSelected ? 'none' : 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    left: 0,
  },
}));

export const SlideScale = styled('div')({
  height: 'max-content',
  width: 'max-content',
  transformOrigin: '0 0',
});

export const SlideInfo = styled('div')<WithSelected>(({ isSelected }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  top: isSelected ? -50 : -28,
  left: 0,
  width: '100%',
  alignItems: 'center',
}));
