import styled from 'styled-components';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  marginBottom: 0,
  padding: '0 20px',
  backgroundColor: '#fff',
  zIndex: 40,
  flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
}));

export const SlideContentWrapper = styled('div')({
  backgroundColor: '#fff',
  height: 745,
  boxShadow: '0px -2px 15px 1px rgba(0,0,0,0.1)',
  margin: '17px auto',
  width: 'calc(100% - 34px)',
  position: 'relative',
  borderRadius: 20,
  overflow: 'hidden',
});
