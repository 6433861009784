import React, { useState } from 'react';
import styled from 'styled-components';

import Loader from '../../../../Loader/Loader';
import { useSlideProps } from '../../../providers/SlideProvider';
import { ColorOpacityApplier } from '../../../services/ColorOpacityApplier';
import { usePresentationPdf } from '../../../../../hooks/usePresentationPdf';
import { usePresentation } from '../../../../../hooks/usePresentation';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import { PresentationApi } from '../../../../../pages/Presentation/api/PresentationApi';

const Button = styled('button')(({ theme }) => {
  const primaryColor = useUiConfigColorsPrimary();

  return {
    height: 40,
    borderRadius: 5,
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 500,
    backgroundColor: '#fff',
    border: `1px solid ${primaryColor}`,
    color: useUiConfigColorsPrimary(),
    textTransform: 'capitalize',
    width: 244,
    position: 'relative',
    '&:disabled': {
      opacity: 1,
      color: ColorOpacityApplier.hex(primaryColor, '0.5'),
      border: `1px solid ${ColorOpacityApplier.hex(primaryColor, '0.5')}`,
    },
  };
});

const DownloadLoader = styled(Loader)`
  margin-right: 5px;
`;

export const DownloadPdfButton: React.FC = () => {
  const { presentationHash } = useSlideProps();
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const { data } = usePresentation(presentationHash);
  const presentationId = data?.id ? data.id : '';

  const { data: pdfData, isLoading } = usePresentationPdf(presentationId, presentationHash ?? '');

  const onDownloadPdf = async () => {
    if (!pdfData?.pdf || !presentationHash) return;
    try {
      setIsPdfLoading(true);
      await PresentationApi.downloadPresentation(pdfData.pdf, presentationHash);
    } catch (e: any) {
      console.error('Saving PDF ERROR: ', e);
    } finally {
      setIsPdfLoading(false);
    }
  };

  return (
    <Button disabled={isLoading || !!pdfData?.isBuildInProgress} onClick={onDownloadPdf}>
      {(isLoading || !!pdfData?.isBuildInProgress || isPdfLoading) && <DownloadLoader />}
      Download Presentation
    </Button>
  );
};
