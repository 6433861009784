import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useTheme } from 'styled-components';

import { PROPERTY_STATUS } from '../../../../types';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';

export function usePropertiesForPricePerSqftChart() {
  const reportProperties = useAtomValue(reportPropertiesAtom);
  const { colors } = useTheme();

  const properties = useMemo(
    () =>
      reportProperties
        .map(property => property.property)
        .filter(property => !property.excluded && !!property.size),
    [reportProperties],
  );

  const sold = properties
    .filter(property => property.status === PROPERTY_STATUS.SOLD)
    .map(property => ({
      ...property,
      price: property.salePrice,
      pricePerSqft: property.salePricePerSqft,
    }));
  const active = properties.filter(property => property.status === PROPERTY_STATUS.ACTIVE);
  const pending = properties.filter(property => property.status === PROPERTY_STATUS.PENDING);
  const comingSoon = properties.filter(property => property.status === PROPERTY_STATUS.COMING_SOON);
  const groupedByStatus = [sold, active, pending, comingSoon];

  const scatterData = [
    {
      name: 'sold',
      data: sold,
      fill: '#F13B3B',
      line: sold.length > 0 ? { strokeWidth: 2 } : null,
      lineType: 'fitting',
    },
    {
      name: 'active',
      data: active,
      fill: '#7EBD39',
    },
    {
      name: 'pending',
      data: pending,
      fill: '#FF8D00',
    },
    {
      name: 'comingSoon',
      data: comingSoon,
      fill: colors.v2.primary,
    },
  ];

  return { groupedByStatus, properties, scatterData };
}
