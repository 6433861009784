import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PROPERTY_STATUS } from '../types';

export const propertyStatusBasedConfigs = {
  [PROPERTY_STATUS.ACTIVE]: {
    color: 'white',
    backgroundColor: 'rgb(58, 202, 70)',
    icon: ['far', 'sign'] as IconProp,
  },
  [PROPERTY_STATUS.PENDING]: {
    color: 'rgb(50, 50, 50)',
    backgroundColor: 'rgb(255, 217, 69)',
    icon: ['far', 'clipboard-list'] as IconProp,
  },
  [PROPERTY_STATUS.SOLD]: {
    color: 'white',
    backgroundColor: 'rgb(220, 53, 69)',
    icon: ['far', 'do-not-enter'] as IconProp,
  },
  [PROPERTY_STATUS.COMING_SOON]: {
    color: 'white',
    backgroundColor: '#6AB4E4',
    icon: ['far', 'home-alt'] as IconProp,
  },
};

export const getPropertyStatusBasedConfigs = (status: PROPERTY_STATUS | 'coming_soon') => {
  switch (status) {
    case 'coming_soon':
      return propertyStatusBasedConfigs.comingSoon;
    default:
      return propertyStatusBasedConfigs[status];
  }
};
