import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { useHasFeatureFlag, FEATURES } from './useFeatureFlags';
import { reportPropertiesAtom } from '../features/report/state/reportPropertiesAtom';

export function useHasComparableAdjustments() {
  const reportProperties = useAtomValue(reportPropertiesAtom);
  const hasCaFF = useHasFeatureFlag(FEATURES.CpComparableAdjustments, false);

  return useMemo(() => {
    if (!hasCaFF) return false;

    return reportProperties?.some(reportProperty => reportProperty?.property?.adjustedPrice);
  }, [hasCaFF, reportProperties]);
}
