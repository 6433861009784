import { useAtom, useSetAtom } from 'jotai';
import { TProperty } from '../../../types';
import { isPropertyModalOpenAtom } from '../state/isPropertyModalOpenAtom';
import { selectedPropertyAtom } from '../state/selectedPropertyAtom';
import { compViewModeAtom } from '../state/compViewModeAtom';

export const useShowPropertyDetails = () => {
  const [isPropertyModalOpen, setIsPropertyModelOpen] = useAtom(isPropertyModalOpenAtom);
  const setSelectedProperty = useSetAtom(selectedPropertyAtom);
  const setCompViewMode = useSetAtom(compViewModeAtom);

  const showPropertyDetails = (property: TProperty | null) => {
    setIsPropertyModelOpen(true);
    setSelectedProperty(property);
    setCompViewMode('slider');
  };

  return { showPropertyDetails, isPropertyModalOpen, setIsPropertyModelOpen };
};
