export default `
<svg width="1354" height="814" viewBox="0 0 1354 814" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M342.293 429.732L391.157 461.609" stroke="#444444" stroke-width="3.90244"/>
<path d="M276.439 662.998L350.549 661.493" stroke="#444444" stroke-width="3.90244"/>
<path d="M966.195 490.22L1052.05 435.098" stroke="#444444" stroke-width="3.90244"/>
<path d="M1004.73 674.122L1076.93 675.585" stroke="#444444" stroke-width="3.90244"/>
<path d="M581.278 314.245L564.244 271.683" stroke="#444444" stroke-width="3.90244"/>
<circle cx="139.366" cy="674.122" r="137.073" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="223.268" cy="362.902" r="137.073" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="527.658" cy="139.488" r="137.073" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="1158.88" cy="349.244" r="137.073" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="1214.49" cy="674.122" r="137.073" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
</svg>
`;
