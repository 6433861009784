import styled from 'styled-components';

export const SwitchWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '15px',
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
}));

export const Text = styled('p')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  marginLeft: '10px',
  color: `${theme.colors.v2.gray[400]}`,
}));
