import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Typography } from '..';

export const Heading = styled(Typography)`
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  margin-right: auto;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.contrastText};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: 100vh;
  max-width: 1496px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
`;

export const Header = styled.header<{ isBlue?: boolean }>`
  height: 35px;
  display: flex;
  flex-shrink: 0;
  padding: 20px 10px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 5px 5px 0px 0px;
  background-color: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.v2.primary : theme.colors.v2.gray[300]};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  background-color: ${({ theme }) => theme.colors.v2.background};
  border-radius: 0 0 5px 5px;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  cursor: pointer;
  font-weight: 900;
  font-style: normal;
  line-height: normal;
  transition: all 0.2s;
  '&:hover': {
    fill: #fff;
    transform: translateY(-0.5px);
  }
  color: ${({ theme }) => theme.colors.v2.text.contrastText};
`;
