import { TUserConfigs, TUserConfigsUi } from '../../types';
import { CATEGORIES } from '../../constants/categories';

export class UserConfigStructureMigrator {
  static migrateUiConfig(ui?: TUserConfigsUi) {
    if (!ui) return;

    if (Array.isArray(ui.logos)) {
      ui.logos = {};
    }

    delete ui?.colors?.primary?.locked;
    delete ui?.colors?.primary?.valueData;
    if (Array.isArray(ui?.colors?.primary?.lockedStatus)) {
      delete ui?.colors?.primary?.lockedStatus;
    }

    delete ui?.colors?.secondary?.locked;
    delete ui?.colors?.secondary?.valueData;
    if (Array.isArray(ui?.colors?.secondary?.lockedStatus)) {
      delete ui?.colors?.secondary?.lockedStatus;
    }

    delete ui?.fonts?.header?.locked;
    delete ui?.fonts?.header?.valueData;
    if (Array.isArray(ui?.fonts?.header?.lockedStatus)) {
      delete ui?.fonts?.header?.lockedStatus;
    }

    delete ui?.fonts?.body?.locked;
    delete ui?.fonts?.body?.valueData;
    if (Array.isArray(ui?.fonts?.body?.lockedStatus)) {
      delete ui?.fonts?.body?.lockedStatus;
    }

    delete ui?.logos?.first?.locked;
    delete ui?.logos?.first?.valueData;
    if (Array.isArray(ui?.logos?.first?.lockedStatus)) {
      delete ui?.logos?.first?.lockedStatus;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    if (Array.isArray(ui?.logos?.first)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ui.logos.first = { value: '' };
    }

    if (['top', 'bottom'].includes(String(ui?.infoPlacement))) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ui.infoPlacement = { value: ui.infoPlacement };
    }

    if (Array.isArray(ui?.colors?.primary) || Array.isArray(ui?.colors?.primary?.value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ui.colors.primary = {};
    }

    if (Array.isArray(ui?.colors?.secondary) || Array.isArray(ui?.colors?.secondary?.value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ui.colors.secondary = {};
    }

    if (Array.isArray(ui?.fonts?.header) || Array.isArray(ui?.fonts?.header?.value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ui.fonts.header = {};
    }

    if (Array.isArray(ui?.fonts?.body) || Array.isArray(ui?.fonts?.body?.value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ui.fonts.body = {};
    }
  }

  static migrate(config: TUserConfigs | null) {
    if (!config) return;

    if (Array.isArray(config)) {
      config = null;
      return;
    }

    Object.values(CATEGORIES).forEach(category => {
      UserConfigStructureMigrator.migrateUiConfig(config?.[category]?.ui);
    });
  }
}
