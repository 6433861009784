import NumberFormat from 'react-number-format';

import { Input } from '../../../../../../components/Common/V2/Input';

interface Props {
  value?: string | number;
  name?: string;
  placeholder?: string;
  onChange: (val?: number | null) => void;
  disabled?: boolean;
  prefix?: '' | '$';
  error?: string;
}

export const MoneyInput = ({
  placeholder,
  onChange,
  value,
  error,
  disabled,
  prefix = '$',
}: Props) => {
  return (
    <NumberFormat
      customInput={Input}
      value={value}
      onValueChange={({ floatValue }) => onChange(floatValue)}
      thousandSeparator={true}
      prefix={prefix}
      placeholder={placeholder}
      min='0'
      error={error}
      disabled={disabled}
      inputWrapperStyle={{ width: '100%' }}
    />
  );
};
