import { CSSObject } from 'styled-components';
import { ButtonVariants } from './types';
import { Theme } from '../../../../config/themes/index';

export function getButtonVariantStyles(theme: Theme, variant: ButtonVariants): CSSObject {
  const colors = theme.colors.v2;
  const weight = theme.text.weight;

  if (variant === 'primary') {
    return {
      color: colors.text.contrastText,
      fontWeight: weight.bold,
      background: colors.primary,
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
      border: 'none',
      '&:hover': {
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),${colors.primary}`,
      },
    };
  }

  if (variant === 'secondary') {
    return {
      color: colors.text.regular,
      fontWeight: 500,
      background: colors.background,
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
      border: `1px solid ${colors.gray[200]}`,
      '&:hover': {
        background: colors.gray[100],
      },
    };
  }

  if (variant === 'dark') {
    return {
      color: colors.text.contrastText,
      fontWeight: weight.bold,
      background: colors.gray[400],
      boxShadow: 'none',
      border: 'none',
      '&:hover': {
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),${colors.gray[400]}`,
      },
    };
  }

  if (variant === 'link') {
    return {
      color: colors.text.link,
      fontWeight: 500,
      background: colors.background,
      boxShadow: 'none',
      border: 'none',
      '&:hover': {
        background: colors.gray[100],
      },
    };
  }

  if (variant === 'clear') {
    return {
      color: colors.text.regular,
      fontWeight: 500,
      background: colors.background,
      boxShadow: 'none',
      border: 'none',
      '&:hover': {
        background: colors.gray[100],
      },
    };
  }

  if (variant === 'light') {
    return {
      color: colors.text.contrastText,
      fontWeight: weight.bold,
      background: '#00b2c8',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
      border: 'none',
      '&:hover': {
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),#00b2c8',
      },
    };
  }

  if (variant === 'danger') {
    return {
      color: colors.text.contrastText,
      fontWeight: weight.bold,
      background: colors.button.danger,
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
      border: 'none',
      '&:hover': {
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),${colors.button.danger}`,
      },
    };
  }

  return {
    color: colors.text.regular,
    fontWeight: 500,
    background: colors.background,
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
      background: colors.gray[100],
    },
  };
}
