import styled from 'styled-components';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')<WithPdf>(({ theme, isPdf }) => ({
  display: 'grid',
  height: isPdf ? '85%' : '100%',
  gridTemplateRows: isPdf ? '1fr 1fr' : 'auto 1fr 1fr',
  gridTemplateColumns: '1fr 1fr',
  gap: 32,
  padding: isPdf ? 0 : '2%',
}));

export const ImageWrapper = styled('div')({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

export const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'center',
});
