export const millisToHMS = (millis: number | null) => {
  if (!millis) return { hours: 0, minutes: 0, seconds: 0 };

  const hours = Math.floor(millis / 3600000);
  const minutes = Math.floor((millis % 3600000) / 60000);
  const seconds = Math.floor((millis % 60000) / 1000);

  let timeObject = { hours: 0, minutes: 0, seconds: seconds ? seconds : 0 };

  if (hours > 0) {
    timeObject.hours = hours;
  }
  if (minutes > 0) {
    timeObject.minutes = minutes;
  }

  return timeObject;
};
