const generateColors = (baseColor: string): string[] => {
  // Convert hex to RGB
  let r = parseInt(baseColor?.slice(1, 3), 16);
  let g = parseInt(baseColor?.slice(3, 5), 16);
  let b = parseInt(baseColor?.slice(5, 7), 16);

  // Calculate new colors based on the base color
  let colors = [
    `rgba(${r}, ${g}, ${b}, 1)`, // 4
    `rgba(${Math.round(r * 0.99)}, ${Math.round(g * 0.8)}, ${Math.round(b * 0.8)}, 1)`, // 5
    `rgba(${Math.round(r * 1.05)}, ${Math.round(g * 1.19)}, ${Math.round(b * 1.19)}, 0.9)`, // 3
    `rgba(${Math.round(r * 0.97)}, ${Math.round(g * 0.72)}, ${Math.round(b * 0.72)}, 1)`, // 6
    `rgba(${Math.round(r * 1.08)}, ${Math.round(g * 1.37)}, ${Math.round(b * 1.37)}, 0.8)`, // 2
    `rgba(${Math.round(r * 0.89)}, ${Math.round(g * 0.6)}, ${Math.round(b * 0.6)}, 1)`, // 7
    `rgba(${Math.round(r * 0.97)}, ${Math.round(g * 1.37)}, ${Math.round(b * 1.37)}, 1)`, // clockhand
    `rgba(${Math.round(r * 1.1)}, ${Math.round(g * 1.5)}, ${Math.round(b * 1.5)}, 0.7)`, // 1
    `rgba(${Math.round(r * 0.78)}, ${Math.round(g * 0.48)}, ${Math.round(b * 0.48)}, 1)`, // 8
    `rgba(${Math.round(r * 0.78)}, ${Math.round(g * 0.48)}, ${Math.round(b * 0.48)}, 1)`, //
    `rgba(${Math.round(r * 1.01)}, ${Math.round(g * 0.97)}, ${Math.round(b * 0.97)}, 0.8)`, // point
    `rgba(${Math.round(r * 0.78)}, ${Math.round(g * 0.48)}, ${Math.round(b * 0.48)}, 1)`, // clockhand
    `rgba(${Math.round(r * 0.78)}, ${Math.round(g * 0.48)}, ${Math.round(b * 0.48)}, 1)`, // clockhand
    `rgba(${Math.round(r * 0.78)}, ${Math.round(g * 0.48)}, ${Math.round(b * 0.48)}, 1)`,
  ];

  return colors;
};

export const replaceFillColors = (svgString: string, baseColor: string): string => {
  // Generate new colors based on the primary color
  const colors = generateColors(baseColor);

  // Replace fill properties in the SVG string with new colors
  let transformedSvg = svgString;
  for (let i = 0; i < colors.length; i++) {
    transformedSvg = transformedSvg.replace(/fill="#"/, `fill="${colors[i]}"`);
  }

  return `data:image/svg+xml;utf8,${encodeURIComponent(transformedSvg)}`;
};
