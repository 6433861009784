export const propertyTypeOptions = [
  { label: 'Single Family', value: 'SINGLE_FAMILY' },
  { label: 'Condo', value: 'CONDO' },
  { label: 'Townhouse', value: 'TOWNHOUSE' },
  { label: 'Multi-Unit', value: 'MULTI_UNIT' },
  { label: 'Mobile', value: 'MOBILE' },
  { label: 'Villa', value: 'VILLA' },
  // { label: 'Loft', value: 'LOFT' },
  // { label: 'Stock-Coop', value: 'COOP' },
];
export const getPropertyTypeString = string => {
  const found = propertyTypeOptions.filter(type => type.value === string);
  return found.length > 0 ? found[0].label : 'No Info';
};
