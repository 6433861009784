import React from 'react';

import { OrderProvider, useOrder } from './OrderProvider';
import { SlidesProvider, useSlides } from './SlidesProvider';
import { SectionsProvider, useSections } from './SectionsProvider';
import { TPresentationSlides, TPresentationMode } from '../../types';

interface Props {
  presentationMode?: TPresentationMode;
}

export const CoreSlidesConfigProvider: React.FC<Props> = ({ presentationMode, children }) => {
  return (
    <OrderProvider presentationMode={presentationMode}>
      <SectionsProvider presentationMode={presentationMode}>
        <SlidesProvider presentationMode={presentationMode}>{children}</SlidesProvider>
      </SectionsProvider>
    </OrderProvider>
  );
};
