import { useCallback } from 'react';
import { useAtom, useAtomValue } from 'jotai';

import { TPresentationType, TProperty } from '../../../types';
import { getPropertyId } from '../services/getPropertyId';
import { getDefaultHighlights } from '../services/getDefaultHighlights';
import { getDistinctListOfHighlights } from '../services/getDistinctListOfHighlights';
import { extractPropertyGroups } from '../services/extractPropertiesGroups';
import { highlightedPropertiesAtom } from '../state/highlighted-properties/highlightedPropertiesAtom';
import { excludedPropertyIdsAtom } from '../state/excludedPropertyIdsAtom';
import { reportPropertiesAtom } from '../state/reportPropertiesAtom';
import { useUpdateReport } from './useUpdateReport';

export const useToggleProperty = () => {
  const [highlighted, setHighlighted] = useAtom(highlightedPropertiesAtom);
  const [excludedIds, setExcludedIds] = useAtom(excludedPropertyIdsAtom);
  const reportProperties = useAtomValue(reportPropertiesAtom);

  const updateReport = useUpdateReport();

  const toggleProperty = useCallback(
    (property: TProperty) => {
      const propertyId = getPropertyId(property);

      const isInHighlighted = highlighted.some(p => getPropertyId(p) === propertyId);
      let filteredHighlighted = [...highlighted];

      if (isInHighlighted) {
        filteredHighlighted = highlighted.filter(p => getPropertyId(p) !== propertyId);
      } else {
        filteredHighlighted = [...highlighted, property];
      }

      setHighlighted(filteredHighlighted);
    },
    [highlighted, setHighlighted],
  );

  const toggleExcludedProperty = useCallback(
    async (property: TProperty, presentationType: TPresentationType, presentationId: string) => {
      const propertyId = getPropertyId(property);

      const isInHighlighted = highlighted.some(p => getPropertyId(p) === propertyId);
      let filteredHighlighted = [...highlighted];

      if (isInHighlighted) {
        filteredHighlighted = highlighted.filter(p => getPropertyId(p) !== propertyId);
        setHighlighted(filteredHighlighted);
      }

      const isExcluded = excludedIds.some(id => id === propertyId);
      let excluded = [...excludedIds];

      if (isExcluded) {
        excluded = excludedIds.filter(id => id !== propertyId);
      } else {
        excluded = [...excludedIds, propertyId];
      }

      setExcludedIds(excluded);

      await updateReport({
        excluded_ids: excluded,
        presentation_type: presentationType,
        presentation_id: presentationId,
      });

      const propertyGroups = extractPropertyGroups(reportProperties);

      const { highlights: newDefaultHighlights } = getDefaultHighlights(propertyGroups, {
        excluded,
      });

      const distinctHighlights = getDistinctListOfHighlights(
        filteredHighlighted,
        newDefaultHighlights,
      );
      setHighlighted(distinctHighlights);
    },
    [highlighted, excludedIds, reportProperties, setHighlighted, setExcludedIds, updateReport],
  );

  return { toggleProperty, toggleExcludedProperty };
};
