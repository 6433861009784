import React from 'react';
import { useTheme } from 'styled-components';

import Loader from './Loader';

export const FullScreenLoader: React.FC = () => {
  const { colors } = useTheme();

  const color = colors.v2.gray[400];

  return (
    <div style={{ height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0 }}>
      <Loader size='5x' centered color={color} />;
    </div>
  );
};
