import React from 'react';
import frameGraphics from './frameGraphics';
import Logos from '../../../../components/Logos/Logos';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import { HeaderContainer, BrokerInfoWrapper, Graphics } from './LuxuryFrameBeta.styles';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';
import { svgStringToDataUrl } from '../../../../../../services/svgStringToDataUrl';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { CommonFrameProps } from '../../../../../../../../types';

const frameImg = svgStringToDataUrl(frameGraphics);

const LuxuryFrameBeta: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => {
  const position = infoPlacement === 'bottom' ? 'bottom' : 'top';
  return (
    <PlacementAwareContainer infoPlacement={infoPlacement}>
      <Graphics
        src={frameImg}
        style={{
          [position]: 0,
          transform: `scale(${position === 'bottom' ? '-' : ''}1)`,
          zIndex: 7,
        }}
      />

      <TocPortalContainer offset={-20} zIndex={6} />
      <HeaderContainer>
        <BrokerInfoWrapper>
          <BrokerInfo fontColor='#000' nameFontStyles={{ fontSize: 18, fontWeight: 400 }} />
        </BrokerInfoWrapper>
        {!hideProgressTracker && (
          <ProgressTracker
            category={CATEGORIES.LUXURY}
            containerStyles={{ paddingBottom: infoPlacement === 'top' ? 16 : 42 }}
          />
        )}
        <Logos />
      </HeaderContainer>
      {children}
    </PlacementAwareContainer>
  );
};

export default LuxuryFrameBeta;
