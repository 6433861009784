import React from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './TotalLotSize.module.scss';
import { SearchCombiner } from '../../../../SearchCombiner';
import { SearchValidator } from '../../../../SearchValidator';
import { Button } from '../../../../../../../components/Button/Button';
import { MoneyInput } from '../../../../../../../components/Input/MoneyInput';
import { NumberFormatter } from '../../../../../../../../services/numberFormatter';
import { Switch } from '../../../../../../../../../../../components/Common/V2/Switch';
import { CriteriaAccordion } from '../components/CriteriaAccordion';
import { RangeForm } from '../components/RangeForm';
import { ActionsAndStatus } from '../components/ActionsAndStatus';

const info =
  'Displays the total land area as stated in the Title record for the property. The optional default range is intended to identify comparables with similar lot sizes.';

const SizeModeSwitchComponent = ({ onUnitChange, unit = 'sqft' }) => {
  const opposite = unit === 'sqft' ? 'acre' : 'sqft';
  return (
    <div className={classes.sizeModeSwitchWrapper}>
      <span>SQFT</span>
      <Switch checked={unit === 'acre'} onChange={() => onUnitChange(opposite)} />
      <span>ACRE</span>
    </div>
  );
};

class TotalLotSizeComponent extends React.Component {
  constructor(props) {
    super(props);

    this.validate = this.validate.bind(this);
    this.onCombine = this.onCombine.bind(this);

    this.state = {
      shouldShowForm: false,
      totalSizeFrom: 0,
      totalSizeTo: 5000,
      originalSubjectSize: null,
      originalSubjetUnit: null,
      saved: false,
      totalSizeRange: [0, 0],
      totalSizeSet: false,
      totalSizeUnit: 'sqft',
    };
  }

  componentDidMount() {
    SearchValidator.subscribe('totalSizeSet', this.validate);
    SearchCombiner.add('totalSizeSet', this.onCombine);

    let { defaultValue, totalSizeUnit, defaultSizeUnit } = this.props;
    if (!defaultValue || isNaN(defaultValue)) {
      return;
    }

    if (this.props.saved) {
      this.setState({
        totalSizeFrom: Math.min(...this.props.totalSizeRange),
        totalSizeTo: Math.max(...this.props.totalSizeRange),
        totalSizeRange: this.props.totalSizeRange,
        originalSubjectSize: defaultValue,
        originalSubjetUnit: defaultSizeUnit,
        totalSizeUnit: totalSizeUnit,
        totalSizeSet: this.props.totalSizeSet,
        saved: this.props.saved,
      });
      return;
    }
    this.setState({
      totalSizeTo: defaultValue,
      originalSubjectSize: defaultValue,
      originalSubjetUnit: defaultSizeUnit,
      saved: this.props.saved,
    });
  }

  onFieldChange(field) {
    return value => {
      this.setState({ [field]: value });
    };
  }

  startFilterByTotalSize() {
    this.setState({
      shouldShowForm: true,
    });
  }

  onUnitChange = unit => {
    const { totalSizeFrom, totalSizeTo } = this.state;
    this.setState(prev => ({
      ...prev,
      totalSizeUnit: unit,
      totalSizeFrom:
        unit === 'acre'
          ? parseFloat((totalSizeFrom / 43560).toFixed(4))
          : Math.round(totalSizeFrom * 43560),
      totalSizeTo:
        unit === 'acre'
          ? parseFloat((totalSizeTo / 43560).toFixed(4))
          : Math.round(totalSizeTo * 43560),
    }));
  };

  saveFilterByTotalSize() {
    const { totalSizeFrom, totalSizeTo } = this.state;
    // Validate and exit if required.
    if (totalSizeFrom === null || !totalSizeTo || isNaN(totalSizeFrom) || isNaN(totalSizeTo)) {
      return;
    }

    this.setState({
      saved: true,
      shouldShowForm: false,
      totalSizeRange: [parseFloat(totalSizeFrom.toFixed(4)), parseFloat(totalSizeTo.toFixed(4))],
      totalSizeSet: true,
    });
    this.props.onChange(SearchCombiner.combine());
  }

  cancelFilterByTotalSize() {
    this.setState(prevState => ({
      shouldShowForm: false,
      totalSizeFrom: 0,
      totalSizeTo: prevState.originalSubjectSize,
      totalSizeUnit: 'sqft',
      saved: false,
      totalSizeRange: [0, parseInt(prevState.originalSubjectSize)],
      totalSizeSet: false,
    }));
  }

  showFilteringForm = () => (
    <div className={classes.filterForm}>
      <SizeModeSwitchComponent onUnitChange={this.onUnitChange} unit={this.state.totalSizeUnit} />

      <RangeForm
        valueFrom={this.state.totalSizeFrom}
        valueTo={this.state.totalSizeTo}
        placeholderFrom='Total Size From'
        placeholderTo='Total Size  To'
        onChangeValueFrom={val => this.onFieldChange('totalSizeFrom')(val)}
        onChangeValueTo={val => this.onFieldChange('totalSizeTo')(val)}
        onCancel={() => this.cancelFilterByTotalSize()}
        onConfirm={() => this.saveFilterByTotalSize()}
      />
    </div>
  );

  showStatusDiv() {
    const { saved, totalSizeFrom, totalSizeTo, totalSizeUnit } = this.state;
    return (
      <ActionsAndStatus
        isRangeSet={saved}
        valueFrom={
          totalSizeUnit === 'sqft'
            ? NumberFormatter.format(totalSizeFrom)
            : totalSizeFrom.toFixed(2)
        }
        valueTo={
          totalSizeUnit === 'sqft' ? NumberFormatter.format(totalSizeTo) : totalSizeTo.toFixed(2)
        }
        onChangeRange={() => this.startFilterByTotalSize()}
        onResetRange={() => this.cancelFilterByTotalSize()}
        changeBtnText='Change Lot Size (optional)'
        resetBtnText='Reset Total Lot Size'
        rangeName={`Total Lot Size (${this.state.totalSizeUnit})`}
      />
    );
  }

  validate() {
    const min = Math.min(...this.state.totalSizeRange);
    const max = Math.max(...this.state.totalSizeRange);
    return !Number.isNaN(min) && !Number.isNaN(max);
  }

  onCombine = () => ({
    totalSizeRange: this.state.totalSizeRange,
    totalSizeSet: this.state.totalSizeSet,
    totalSizeUnit: this.state.totalSizeUnit,
  });

  componentDidUpdate(prevProps, prevState) {
    SearchValidator.unsubscribe('totalSizeSet');
    SearchCombiner.remove('totalSizeSet');

    SearchValidator.subscribe('totalSizeSet', this.validate);
    SearchCombiner.add('totalSizeSet', this.onCombine);

    if (
      prevState.totalSizeRange !== this.state.totalSizeRange ||
      this.state.totalSizeUnit !== prevState.totalSizeUnit ||
      this.state.totalSizeSet !== prevState.totalSizeSet
    ) {
      this.props.onChange(SearchCombiner.combine());
    }
  }

  render() {
    const { shouldShowForm } = this.state;

    return (
      <CriteriaAccordion info={info} title='TOTAL LOT SIZE'>
        <>{shouldShowForm ? this.showFilteringForm() : this.showStatusDiv()}</>
      </CriteriaAccordion>
    );
  }
}

const stateToProps = ({ search, report }) => {
  return {
    defaultValue: search.searchCriteria.totalSize * 2,
    saved:
      Object.entries(report.rebuildData) && report.rebuildData.request
        ? report.rebuildData.request.total_size_set
        : search.searchCriteria.totalSizeSet || false,
    totalSizeRange:
      Object.entries(report.rebuildData) && report.rebuildData.request
        ? [report.rebuildData.request.total_size_from, report.rebuildData.request.total_size_to]
        : search.searchCriteria.totalSizeRange,
    totalSizeUnit:
      search && search.searchCriteria && search.searchCriteria.totalSizeUnit
        ? search.searchCriteria.totalSizeUnit
        : 'sqft',
    totalSizeSet: Boolean(search?.searchCriteria?.totalSizeSet),
  };
};

export const TotalLotSize = connect(stateToProps)(withTheme(TotalLotSizeComponent));
