import { useState } from 'react';

import { Sidebar } from '../components/Sidebar';
import { OutliersSidebarContent } from './OutliersSidebarContent';
import { Button } from '../components/Button';

export const Outliers = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        label='Outliers'
        iconName='flag_pennant'
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
      />

      <Sidebar isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <OutliersSidebarContent />
      </Sidebar>
    </>
  );
};
