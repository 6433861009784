import React from 'react';
import styled from 'styled-components';

import bg from './bg.png';
import home from './home.png';
import slideGraphics from './slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background: url(${bg}) bottom center;
  position: relative;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 45px;
  margin-bottom: 10px;
  margin-top: 25px;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  width: 585px;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const Description = styled.p`
  position: relative;
  z-index: 5;
  padding-left: 45px;
  width: 585px;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: ${() => useUiConfigBodyLetterSpacing()};
`;

const Image = styled.img`
  /* max-height: 100%;
  max-width: 100%; */
  width: 89%;
  margin: 0 auto;
  display: block;
  /* top: 4px; */
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
`;

const OverlayImage = styled.img`
  z-index: 1;
  bottom: 0;
  position: absolute;
  right: 68px;
  height: 778px;
`;

export const WhatFactorsInfluencePricingTraditional: React.FC = () => {
  const primary = useUiConfigColorsPrimary();

  const img = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  const headingStyles = {
    fontSize: 18,
    fontWeight: 700,
    color: '#444',
  };

  const textStyles = {
    fontSize: 17,
    fontWeight: 400,
    color: '#444',
  };

  return (
    <ImageWrapper>
      <Image src={img} />
      <OverlayImage src={home} />
      <Heading>WHAT FACTORS INFLUENCE PRICING</Heading>

      <Description>
        Ultimately, a home’s value is based on what potential buyers will pay, to know what they’re
        looking for in a home can help you get top dollar. Here are ten factors potential buyers
        consider when comparing homes:{' '}
      </Description>

      <TraditionalSlideCircularCard
        heading={'UPDATES'}
        text={'HVAC, roof, plumbing, etc.'}
        position={{ left: 166, bottom: -6 }}
        textStyles={textStyles}
        headingStyles={headingStyles}
        size={240}
      />
      <TraditionalSlideCircularCard
        heading={'AREA'}
        text={'Views, community/ neighbors, etc.'}
        position={{ left: 261, bottom: 234 }}
        textStyles={textStyles}
        headingStyles={headingStyles}
        size={250}
      />
      <TraditionalSlideCircularCard
        heading={'SCHOOLS'}
        headingStyles={headingStyles}
        position={{ left: 46, bottom: 189 }}
        size={240}
      />
      <TraditionalSlideCircularCard
        heading={'LAYOUT'}
        headingStyles={headingStyles}
        position={{ left: 473, bottom: 389 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading={'NOISE'}
        headingStyles={{ ...headingStyles, width: 65 }}
        position={{ left: 641, bottom: 395 }}
        size={240}
      />
      <TraditionalSlideCircularCard
        heading={'REMODEL'}
        text={'Kitchen remodel, bathroom update, etc.'}
        position={{ left: 862, bottom: 452 }}
        textStyles={textStyles}
        headingStyles={headingStyles}
        size={240}
      />
      <TraditionalSlideCircularCard
        heading={'LEVEL OF UPKEEP'}
        headingStyles={headingStyles}
        position={{ left: 1224, bottom: 482 }}
        size={240}
      />
      <TraditionalSlideCircularCard
        heading={'WALK SCORE'}
        headingStyles={headingStyles}
        position={{ left: 1056, bottom: 236 }}
        size={240}
      />
      <TraditionalSlideCircularCard
        heading={'SAFETY'}
        headingStyles={headingStyles}
        position={{ left: 1280, bottom: 199 }}
        size={240}
      />
      <TraditionalSlideCircularCard
        heading={'RETURN VALUE'}
        headingStyles={headingStyles}
        position={{ left: 1146, bottom: 3 }}
        size={240}
      />
    </ImageWrapper>
  );
};
