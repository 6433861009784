import styled from 'styled-components';
import { useUiConfigBodyLetterSpacing } from '../../../../../../../../providers/providers/UiConfigFontProvider';
import { Icon } from '../../../../../../../Common';

export { Icon };

export const Text = styled('p')(({ theme }) => ({
  color: '#000',
  fontSize: 22,
  marginBottom: 30,
  textAlign: 'start',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const Checkboxes = styled('div')({
  marginLeft: 10,
});

export const TextAreaWrapper = styled('div')({
  marginTop: 40,
});
