import React, { useCallback, useContext, useState } from 'react';

type ModalVisibilityContextType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};
const ModalVisibilityContext = React.createContext<ModalVisibilityContextType | undefined>(
  undefined,
);

export const useModalVisibility = () => {
  const context = useContext(ModalVisibilityContext);

  if (context === undefined) {
    throw new Error('useModalVisibility cannot be used without ModalVisibilityContext');
  }

  return context;
};

export const ModalVisibilityProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const value = React.useMemo(
    () => ({
      isOpen,
      openModal,
      closeModal,
    }),
    [closeModal, isOpen, openModal],
  );

  return (
    <ModalVisibilityContext.Provider value={value}>{children}</ModalVisibilityContext.Provider>
  );
};
