import styled from 'styled-components';

import { ComparableExtension } from '../../../../../types';
import { ExtensionItem } from './ExtensionItem';
import { useAtomValue } from 'jotai';
import { isPresentationViewAtom } from '../../../../../features/report/state/isPresentationViewAtom';

interface Props {
  comparableExtensions?: ComparableExtension[];
  propertyId: string;
  hideActions?: boolean;
}
export const ComparableExtensionList = ({
  comparableExtensions,
  propertyId,
  hideActions = false,
}: Props) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  if (!comparableExtensions || !comparableExtensions.length) return null;

  return (
    <Wrapper isPresentationView={isPresentationView || (!isPresentationView && hideActions)}>
      {comparableExtensions.map(extension => {
        return (
          <ExtensionItem
            propertyId={propertyId}
            extension={extension}
            key={extension.id}
            hideActions={hideActions}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isPresentationView: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.isPresentationView ? 0 : '10px')};
  margin-top: ${props => (props.isPresentationView ? '10px' : 0)};
`;
