import styled from 'styled-components';
import { Icon, Typography, Button } from '../../../../components/Common';

export { Icon, Typography, Button };

export const Container = styled.div`
  display: flex;
  padding: 30px 9% 30px 6%;
  flex-direction: column;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const Title = styled.h2<{ isLight?: boolean }>`
  font-size: 30px;
  font-weight: 700;
  color: ${({ theme, isLight }) =>
    isLight ? theme.colors.v2.text.contrastText : theme.colors.v2.gray[400]};
`;

export const DescriptionWrapper = styled.div`
  width: 76%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Description = styled.p<{ isLight?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme, isLight }) =>
    isLight ? theme.colors.v2.text.contrastText : theme.colors.v2.gray[400]};
`;
