import React, { useRef } from 'react';

import {
  Container,
  Backdrop,
  ModalHeader,
  Title,
  ModalContent,
  CloseIcon,
  Icon,
  ModalFooter,
  ButtonsContainer,
  Button,
  DownloadPdfButtonWrapper,
} from './NextStepModal.styles';
import { DownloadPdfButton } from '../../../../../../components/common/DownloadPdfButton.componet';

interface NextStepModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  headerTitle: string;
  hideDownloadPdfButton?: boolean;
  onConfirmButtonClick?: () => void;
  confirmButtonText: string;
  isConfirmDisabled?: boolean;
}

export const NextStepModal: React.FC<NextStepModalProps> = ({
  isModalOpen,
  onClose,
  headerTitle,
  children,
  hideDownloadPdfButton,
  onConfirmButtonClick,
  confirmButtonText,
  isConfirmDisabled,
}) => {
  const el = useRef<HTMLDivElement>(null);

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (el.current && el.current === e.target) {
      onClose();
    }
  };

  return (
    <>
      {isModalOpen && (
        <Backdrop ref={el} onClick={handleClose}>
          <Container>
            <ModalHeader>
              <Title>Next step: {headerTitle}</Title>
              <CloseIcon onClick={onClose}>
                <Icon iconName='close' fill='#000' />
              </CloseIcon>
            </ModalHeader>
            <ModalContent>{children}</ModalContent>
            <ModalFooter>
              {!hideDownloadPdfButton && (
                <DownloadPdfButtonWrapper>
                  <DownloadPdfButton />
                </DownloadPdfButtonWrapper>
              )}
              <ButtonsContainer>
                <Button buttonStyle='cancel' onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  buttonStyle='submit'
                  onClick={onConfirmButtonClick}
                  disabled={isConfirmDisabled}
                >
                  {confirmButtonText}
                </Button>
              </ButtonsContainer>
            </ModalFooter>
          </Container>
        </Backdrop>
      )}
    </>
  );
};
