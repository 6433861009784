import styled from 'styled-components';
import { TProperty } from '../../../../types';
import { ComparableDetails } from './ComparableDetails';
import { Features } from './Features';
import { ListedInfo } from './ListedInfo';

interface Props {
  property: TProperty;
}

export const Details = ({ property }: Props) => {
  return (
    <Container>
      <ComparableDetailsWrapper>
        <ComparableDetails property={property} />
      </ComparableDetailsWrapper>
      <FeaturesWrapper>
        <Features property={property} />
      </FeaturesWrapper>
      <ListedInfoWrapper>
        <ListedInfo property={property} />
      </ListedInfoWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 0;
  display: flex;
`;

const ComparableDetailsWrapper = styled.div`
  width: 33%;
  border-right: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  padding-right: 33px;
`;

const FeaturesWrapper = styled.div`
  width: 35%;
  border-right: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  padding: 0 33px;
`;

const ListedInfoWrapper = styled.div`
  width: 32%;
  padding-left: 33px;
`;
