export const sellingPaceData = {
  heading: 'Selling Pace ',
  title: 'FAST',
  subtitle: 'Selling Pace',
  description: [
    {
      title: 'WHAT IS SELLING PACE?',
      text: 'Selling pace highlights the days on the market from the original listing date to the closing date of each comparable against the final closing sale price.',
    },
    {
      title: 'How is selling pace used in a price analysis?',
      text: 'Examining days on the market in the same view as sold price can help pinpoint whether pricing affects the selling pace of comparable homes in the neighborhood. In a market with low buyer momentum, a property that’s priced too high can sit too long and lead to costly price reductions.  ',
    },
  ],
};
