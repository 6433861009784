import styled from 'styled-components';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  marginBottom: 0,
  padding: '5px 20px 5px 20px',
  flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
  backgroundColor: '#fff',
  borderBottom: `2px solid ${theme.colors.gray[100]}`,
  zIndex: 40,
}));
