import React, { useState } from 'react';
import {
  LegendContainer,
  LegendItem,
  LegendText,
  CheckboxContainer,
  CheckboxInput,
  CheckboxControl,
} from './GraphCardLegend.styles';

interface GraphCardLegendProps {
  isAverage?: boolean;
  onCheckboxClick?: (showOfficeData: boolean) => void;
}

export const GraphCardLegend: React.FC<GraphCardLegendProps> = ({ isAverage, onCheckboxClick }) => {
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.currentTarget.checked);
    if (onCheckboxClick) {
      onCheckboxClick(e.currentTarget.checked);
    }
  };
  return (
    <LegendContainer>
      <LegendItem isGreenBorder>
        <LegendText>Agent</LegendText>
      </LegendItem>
      <LegendItem>
        <CheckboxContainer>
          <CheckboxInput type='checkbox' onChange={onCheck} checked={isChecked} />
          <CheckboxControl isChecked={isChecked} />
        </CheckboxContainer>
        <LegendText>Office {isAverage ? 'Avg.' : ''}</LegendText>
      </LegendItem>
    </LegendContainer>
  );
};
