import React from 'react';
import styled, { useTheme } from 'styled-components';
import slideGraphics from './slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { listItems, graphItems } from '../../data';

interface El {
  src?: string;
}

const ImageWrapper = styled.div<El>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${() => ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '0.05')};
`;

const Graphics = styled.img`
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateY(-30px);
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 45px;
  margin-bottom: 100px;
  margin-top: 45px;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  width: 525px;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

export const HomeSellingProcessTraditional: React.FC = () => {
  const theme = useTheme();

  const primary = useUiConfigColorsPrimary();
  const secondary = useUiConfigColorsSecondary();
  const img = React.useMemo(
    () => svgStringToDataUrl(slideGraphics, primary, secondary),
    [primary, secondary],
  );
  const letterSpacing = useUiConfigBodyLetterSpacing();

  const darkStyles = {
    color: theme.colors.gray[400],
    fontSize: 17,
    fontWeight: 900,
  };

  const headingStyles: React.CSSProperties = {
    color: theme.colors.gray[400],
    fontSize: 20,
    fontWeight: 900,
    textAlign: 'left',
  };

  const textStyles: React.CSSProperties = { textAlign: 'left', width: '83%', lineHeight: 1.4 };
  return (
    <ImageWrapper>
      <Heading>Home Selling Process</Heading>
      <Graphics src={img} />
      <TraditionalSlideCircularCard
        heading='Start'
        headingStyles={{ color: '#fff', fontSize: 16, fontWeight: 900 }}
        position={{ top: 437, left: 77 }}
        size={100}
      />
      <TraditionalSlideCircularCard
        heading={graphItems[0].shortText}
        headingStyles={darkStyles}
        position={{ top: 260, left: 90 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading={graphItems[1].shortText}
        headingStyles={darkStyles}
        position={{ top: 94, left: 248 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading={graphItems[2].shortText}
        headingStyles={darkStyles}
        position={{ top: 23, left: 431 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading={graphItems[3].shortText}
        headingStyles={darkStyles}
        position={{ top: 45, left: 601 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading={graphItems[4].shortText}
        headingStyles={darkStyles}
        position={{ top: 172, left: 773 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading={graphItems[5].shortText}
        headingStyles={darkStyles}
        position={{ top: 172, left: 943 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading={graphItems[6].shortText}
        headingStyles={darkStyles}
        position={{ top: 196, left: 1114 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading={graphItems[7].shortText}
        headingStyles={darkStyles}
        position={{ top: 255, right: 79 }}
        size={200}
      />
      <TraditionalSlideCircularCard
        heading='Home Sold'
        headingStyles={{ color: '#fff', fontSize: 16, fontWeight: 900 }}
        position={{ top: 454, right: 78 }}
        size={80}
      />
      <TraditionalSlideCircularCard
        heading={listItems[0].title}
        text={listItems[0].text}
        containerStyles={{ borderRadius: 0, alignItems: 'flex-start', letterSpacing }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        position={{ top: 485, left: 35 }}
        size={350}
      />
      <TraditionalSlideCircularCard
        heading={listItems[1].title}
        text={listItems[1].text}
        containerStyles={{ borderRadius: 0, alignItems: 'flex-start', letterSpacing }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        position={{ top: 495, left: 498 }}
        size={350}
      />
      <TraditionalSlideCircularCard
        heading={listItems[2].title}
        text={listItems[2].text}
        containerStyles={{ borderRadius: 0, alignItems: 'flex-start', letterSpacing }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        position={{ top: 485, left: 870 }}
        size={350}
      />
      <TraditionalSlideCircularCard
        heading={listItems[3].title}
        text={listItems[3].text}
        containerStyles={{ borderRadius: 0, alignItems: 'flex-start', letterSpacing }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        position={{ top: 475, right: -3 }}
        size={350}
      />
    </ImageWrapper>
  );
};
