import styled from 'styled-components';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  padding: isPdf ? '0 0 15% 0' : '2%',
}));

export const RightCol = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  width: isPdf ? '60%' : '57%',
  flexShrink: 0,
}));

export const LeftCol = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  width: isPdf ? '40%' : '41.5%',
  flexShrink: 0,
}));

export const ImageWrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  width: '100%',
  height: isPdf ? '40%' : '60%',
}));

export const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'center',
});
