import styled from 'styled-components';

type ContainerProps = {
  isSpaceBetween?: boolean;
  isPdf?: boolean;
  isRow?: boolean;
};

export const Container = styled('div')<ContainerProps>(({ isSpaceBetween, isPdf, isRow }) => ({
  display: 'flex',
  flexDirection: isRow ? 'row' : 'column',
  justifyContent: isSpaceBetween ? 'space-between' : 'flex-start',
  height: '100%',
  padding: isPdf ? 0 : '2%',
}));
