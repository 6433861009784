import React, { useCallback } from 'react';

import { useModalActiveSection } from '../providers/ModalActiveSectionProvider';
import { PresentationConfigsFactory } from '../services/PresentationSlidesFactory';
import { DynamicSlidesFilter } from '../../../../../../services/DynamicSlidesFilter';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { TTag, TSlide, TSection, TDynamicSectionId, TSectionsSlide } from '../../../../../../types';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../../../../../../providers/providers/PresentationModeProvider';

export function useModalActiveSectionSlides(
  selectedTags: TTag[],
  localSectionsSlide: TSectionsSlide,
) {
  const { slides } = useSlides();
  const { presentationType } = usePresentationType();
  const { presentationMode } = usePresentationMode();

  const { modalActiveSection } = useModalActiveSection();

  const getSlidesFromConfig = useCallback(
    (activeSection?: TDynamicSectionId): TSlide[] => {
      if (
        !activeSection ||
        (activeSection !== 'allSlides' && !localSectionsSlide[activeSection as TSection])
      )
        return [];

      if (activeSection !== 'allSlides') {
        const config = PresentationConfigsFactory.create(presentationType, presentationMode);
        const sectionSlides = config.getPredefinedSectionSlides();

        if (!sectionSlides[activeSection]) return [];

        const slideIds = sectionSlides[activeSection];
        const predefined = slideIds?.map(sId => slides?.[sId]).filter(Boolean) as TSlide[];

        const dynamic = Object.values(slides ?? {}).filter(
          s => s?.template && s.sectionId === activeSection,
        );

        return [...predefined, ...dynamic];
      }

      return Object.values(slides ?? {});
    },
    [localSectionsSlide, presentationMode, presentationType, slides],
  );

  return React.useMemo(() => {
    if (selectedTags.length) {
      const selectedTagsIds = selectedTags.map(tag => tag.id);

      const dynamicSlides =
        getSlidesFromConfig(modalActiveSection).filter(s => !!s?.template) ?? [];

      return DynamicSlidesFilter.byTags(dynamicSlides as TSlide[], selectedTagsIds);
    }

    return getSlidesFromConfig(modalActiveSection);
  }, [selectedTags, getSlidesFromConfig, modalActiveSection]);
}
