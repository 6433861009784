import styled from 'styled-components';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
});

export const DropdownWrapper = styled('div')({
  flex: 1,
});
