import React, { createContext, useContext, useState } from 'react';

import type { TDynamicSection, TDynamicSectionId, TPresentationMode } from '../../types';

type TSectionsContext = {
  sections: Record<TDynamicSectionId, TDynamicSection>;
  sectionsByMode: TConfig;
  setSections: (sectionsMap: Record<TDynamicSectionId, TDynamicSection>) => void;
  setSectionsFromArray: (sections: TDynamicSection[]) => void;
  hasSectionsForMode: (mode: TPresentationMode) => boolean;
  activateSectionFromMode: (mode: TPresentationMode) => Record<TDynamicSectionId, TDynamicSection>;
  getSectionsForMode: (
    mode: TPresentationMode,
  ) => Record<TDynamicSectionId, TDynamicSection> | null;
};
const SectionsContext = createContext<TSectionsContext | undefined>(undefined);

export function useSections() {
  const context = useContext(SectionsContext);

  if (!context) {
    throw new Error('useSections must be used within a SectionsContext');
  }

  return context;
}

export type TConfig = { [k in TPresentationMode]?: Record<TDynamicSectionId, TDynamicSection> };

const config: TConfig = {};

interface Props {
  presentationMode?: TPresentationMode;
}

export const SectionsProvider: React.FC<Props> = function SectionsProvider({
  children,
  presentationMode,
}) {
  const [sectionsByMode, _setSectionsByMode] = useState(config);
  const [sections, setSections] = useState<Record<TDynamicSectionId, TDynamicSection>>({});

  const setSectionsFromArray = React.useCallback(function setSectionsFromArray(
    sections: TDynamicSection[],
  ) {
    setSections(prev => {
      if (!sections.length) return prev;

      return sections.reduce(
        (map, section) => ({
          ...map,
          [section.id]: { ...section },
        }),
        {} as Record<TDynamicSectionId, TDynamicSection>,
      );
    });
  }, []);

  const setSectionsByMode = React.useCallback(
    (mode: TPresentationMode, config: Record<TDynamicSectionId, TDynamicSection>) => {
      _setSectionsByMode(prev => ({ ...prev, [mode]: config }));
    },
    [],
  );

  const hasSectionsForMode = React.useCallback(
    (mode: TPresentationMode) => {
      return !!Object.keys(sectionsByMode[mode] ?? {}).length;
    },
    [sectionsByMode],
  );

  const getSectionsForMode = React.useCallback(
    (mode: TPresentationMode) => {
      return sectionsByMode[mode] ?? null;
    },
    [sectionsByMode],
  );

  const activateSectionFromMode = React.useCallback(
    (mode: TPresentationMode) => {
      if (!mode) return {};

      const sectionsConfig = sectionsByMode[mode];
      if (!sectionsConfig) return {};

      setSections(sectionsConfig);

      return sectionsConfig;
    },
    [sectionsByMode],
  );

  React.useEffect(() => {
    if (!presentationMode) return;

    setSectionsByMode(presentationMode, sections);
  }, [presentationMode, sections, setSectionsByMode]);

  const value = React.useMemo(
    function memoizedOrder() {
      return {
        sectionsByMode,
        sections,
        setSections,
        setSectionsFromArray,
        hasSectionsForMode,
        getSectionsForMode,
        activateSectionFromMode,
      };
    },
    [
      activateSectionFromMode,
      getSectionsForMode,
      hasSectionsForMode,
      sections,
      sectionsByMode,
      setSectionsFromArray,
    ],
  );

  return <SectionsContext.Provider value={value}>{children}</SectionsContext.Provider>;
};
