import { CardsProps } from './types';
import { Container } from './Cards.styles';
import { AvgTimeSpentCard } from './AvgTimeSpentCard';
import { SlidesViewedCard } from './SlideViewedCard';
import { LastOpenedCard } from './LastOpenedCard';

export function Cards({ avgTimeSpent, slidesViewed, lastOpenedDate, totalSlides }: CardsProps) {
  return (
    <Container>
      <AvgTimeSpentCard avgTimeSpent={avgTimeSpent} />
      <SlidesViewedCard totalSlides={totalSlides} slidesViewed={slidesViewed} />
      <LastOpenedCard lastOpenedDate={lastOpenedDate} />
    </Container>
  );
}
