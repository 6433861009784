import React, { useMemo } from 'react';

import { useUser } from '../components/Slide/providers/UserProvider';
import { usePresentationType } from '../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../providers/providers/PresentationModeProvider';
import {
  EntityMode,
  TPresentation,
  TPresentationMode,
  TPresentationType,
  TSlideId,
} from '../types';
import { PresentationConfigsFactory } from '../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';
import { useLevelDynamicSlides } from './useLevelDynamicSlides';
import { useIsReferenceChanged } from './utils/useIsReferenceChanged';

function usePredefinedWithDynamic(
  hash: string,
  type: TPresentationType,
  mode: TPresentationMode,
  useSpecialBranding: boolean,
) {
  const {
    isLoading,
    isFetching,
    data: dynamicAndPersonalizedMap,
  } = useLevelDynamicSlides(hash, useSpecialBranding, null, EntityMode.READ);

  const predefinedSlidesMap = useMemo(() => {
    const config = PresentationConfigsFactory.create(type, mode);
    return config.getSlides();
  }, [mode, type]);

  const data = React.useMemo(() => {
    if (!dynamicAndPersonalizedMap) return null;

    return {
      ...predefinedSlidesMap,
      ...dynamicAndPersonalizedMap,
    };
  }, [dynamicAndPersonalizedMap, predefinedSlidesMap]);

  return React.useMemo(
    () => ({
      isFetching,
      isLoading,
      data,
    }),
    [isFetching, isLoading, data],
  );
}

function getPresentationSpecificSlides(presentation: TPresentation) {
  const slidesWithData: TSlideId[] = [
    'greetingVideo',
    'coverSlide',
    'netProceeds',
    'buyerInterest',
    'buyerDemand',
    'oneSheeter',
  ];

  return slidesWithData.reduce((map, slideId) => {
    if (!presentation.slidesMap?.[slideId]) return map;

    return {
      ...map,
      [slideId]: presentation.slidesMap?.[slideId],
    };
  }, {});
}

export function usePresentationEditingSlides(hash: string, presentation: TPresentation) {
  const { presentationType: type } = usePresentationType();
  const { presentationMode: mode } = usePresentationMode();

  const { isCanadaUser, useSpecialBranding } = useUser()!;
  const {
    isFetching,
    isLoading,
    data: slidesMap,
  } = usePredefinedWithDynamic(hash, type, mode, useSpecialBranding);
  const presentationSpecificSlidesMap = useMemo(
    () => getPresentationSpecificSlides(presentation),
    [presentation],
  );

  const data = React.useMemo(() => {
    if (!slidesMap) return null;

    const mergedSlidesMap = {
      ...slidesMap,
      ...presentationSpecificSlidesMap,
      ...presentation.slidesMap,
    };

    if (isCanadaUser) {
      delete mergedSlidesMap.buyerInterest;
      delete mergedSlidesMap.buyerDemand;
    }

    return mergedSlidesMap;
  }, [isCanadaUser, presentation.slidesMap, presentationSpecificSlidesMap, slidesMap]);

  return React.useMemo(
    () => ({
      isFetching,
      isLoading,
      data,
    }),
    [isFetching, isLoading, data],
  );
}
