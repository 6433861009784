import React from 'react';
import styled from 'styled-components';

import withHeaderFont from '../../../hoc/withHeaderFont';
import { useViewMode } from '../../../providers/ViewModeProvider';
import { useSelectedProperty } from '../../../providers/SelectedPropertyProvider';

const Text = withHeaderFont(styled.span`
  cursor: pointer;
`);

export const ShowPropertiesOnMap: React.FC = () => {
  const { setViewMode } = useViewMode();
  const { setSelectedProperty } = useSelectedProperty();

  const showPropertiesOnMap = () => {
    setSelectedProperty(null);
    setViewMode('all');
  };

  return <Text onClick={showPropertiesOnMap}>Show All On Map</Text>;
};
