import { useMemo } from 'react';

import ReactTooltip from 'react-tooltip';
import { TProperty } from '../../../../../../../../types';
import { useUrlSearchParam } from '../../../../../../../../hooks/useQueryParams';
import { MoneyFormatter } from '../../../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { useAtomValue } from 'jotai';
import { searchCriteriaAtom } from '../../../../../../state/searchCriteriaAtom';
import styled from 'styled-components';
import { ActionIndicator } from './ActionIndicator';

interface Props {
  property: TProperty;
}

export const PriceAndActions = ({ property }: Props) => {
  const source = useUrlSearchParam('source');
  const searchCriteria = useAtomValue(searchCriteriaAtom);

  const isNew = useMemo(() => {
    if (!searchCriteria?.reportCreatedAt) {
      return false;
    }
    return property.listDate > searchCriteria.reportCreatedAt;
  }, [property.listDate, searchCriteria?.reportCreatedAt]);

  const lastRebuild = useMemo(() => {
    if (!searchCriteria?.reportCreatedAt) {
      return false;
    }
    const date = new Date(searchCriteria.reportCreatedAt * 1000);
    return date.toLocaleString();
  }, [searchCriteria?.reportCreatedAt]);

  return (
    <Container>
      {!source && <ActionIndicator property={property} />}

      <Price isExcluded={property?.excluded}>
        {MoneyFormatter.format(property.status === 'sold' ? property.salePrice : property.price)}
      </Price>
      {property?.adjustedPrice && (
        <AdjustedPrice>ADJ {MoneyFormatter.format(property.adjustedPrice)}</AdjustedPrice>
      )}
      {isNew && (
        <New
          data-tip={`This property was listed after the last time you built this report. (${lastRebuild})`}
        >
          <p>NEW!</p>
          <ReactTooltip effect='solid' event='click' style={{ maxWidth: '300px' }} />
        </New>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`;

const Price = styled.p<{ isExcluded?: boolean }>`
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 12px;
  font-weight: 700;
  filter: ${({ isExcluded }) => (isExcluded ? 'grayscale(1)' : 'none')};
  opacity: ${({ isExcluded }) => (isExcluded ? 0.5 : 1)};
`;

const AdjustedPrice = styled.p`
  font-size: 12px;
  color: #b1b1b1;
  font-weight: 700;
  font-style: italic;
  white-space: nowrap;
`;

const New = styled.span`
  color: #3aca46;
  font-weight: bolder;
  padding: 5px;
`;
