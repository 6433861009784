import React, { createContext, useContext, useState } from 'react';

import type { TClient } from '../../types';

type TClientProvider = {
  client: TClient | null;
  setClient: (client: TClient | null) => void;
};

const ClientContext = createContext<TClientProvider | undefined>(undefined);

export function useClient() {
  const context = useContext(ClientContext);

  if (!context) {
    throw new Error('useClient must be used within a ClientContext');
  }

  return context;
}

export const ClientProvider: React.FC = ({ children }) => {
  const [client, setClient] = useState<TClient | null>(null);

  const value = React.useMemo(() => ({ client, setClient }), [client]);

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
};
