import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AnalyticsModalEvents } from '../components/AnalyticsModal/services/AnalyticsModalEvent';
import { AnalyticsModal } from '../components/AnalyticsModal/AnalyticsModal';

type AnalyticsModalContextType = {
  analyticsOpen: string | undefined;
  setAnalyticsOpen: (value: string | undefined) => void;
};
const AnalyticsModalContext = React.createContext<AnalyticsModalContextType | undefined>(undefined);

export const useAnalyticsModal = () => {
  const context = useContext(AnalyticsModalContext);

  if (!context) {
    throw new Error('useAnalyticsModal cannot be used without AnalyticsModalContext');
  }

  return context;
};

export const AnalyticsModalProvider: React.FC = ({ children }) => {
  const [analyticsOpen, setAnalyticsOpen] = useState<string | undefined>();

  const { search } = useLocation();
  const { replace } = useHistory();

  const openAnalytics = useCallback(
    (event: any) => {
      const presentationHash = event.detail?.payload;

      if (!presentationHash || analyticsOpen) return;

      setAnalyticsOpen(presentationHash);
    },
    [analyticsOpen],
  );

  useEffect(() => {
    AnalyticsModalEvents.subscribe(openAnalytics);
    return () => AnalyticsModalEvents.unsubscribe(openAnalytics);
  });

  useEffect(
    function openAnalyticsAuto() {
      const params = new URLSearchParams(search);
      const action = params.get('action');
      const presentationHash = params.get('presentation_hash');

      if (!presentationHash || action !== 'view_session') return;

      setAnalyticsOpen(presentationHash);
      replace({ search: '' });
    },
    [replace, search],
  );

  const value = React.useMemo(
    () => ({
      analyticsOpen,
      setAnalyticsOpen,
    }),
    [analyticsOpen],
  );

  return (
    <AnalyticsModalContext.Provider value={value}>
      <>
        {children}
        {analyticsOpen && (
          <AnalyticsModal hash={analyticsOpen} onClose={() => setAnalyticsOpen(undefined)} />
        )}
      </>
    </AnalyticsModalContext.Provider>
  );
};
