import styled from 'styled-components';
import { useUiConfigBodyLetterSpacing } from '../../../../../../../providers/providers/UiConfigFontProvider';

export const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  paddingBottom: 40,
  flex: 1,
});

export const Description = styled('p')(({ theme }) => ({
  width: '60%',
  fontSize: 26,
  color: '#000',
  lineHeight: 1.5,
  textAlign: 'center',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));
