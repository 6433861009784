import { useEffect, useState, useCallback } from 'react';

import { TSlide, TSlideId } from '../../../types';
import { TrackingApi } from '../../../api/TrackingApi';
import { useEventListener } from './useEventListener';
import { PageVisibility } from '../services/PageVisibility';
import { corePresentApi } from '../../../api/CorePresentApi';
import { TrackingEvent } from '../../../services/TrackingEvent';
import { useHeatmapData } from '../../../providers/providers/heatmap/useHeatmapData';
import { useResetHeatmapData } from '../../../providers/providers/heatmap/useResetHeatmapData';

// workaround for slides rerendering each time when mouse moves
let heatmapData: any = null;

export function HeatmapAtomValueUpdater() {
  const [heatmapDataValue] = useHeatmapData();

  useEffect(() => {
    heatmapData = heatmapDataValue;
  }, [heatmapDataValue]);

  return null;
}

export function useTracking(slides: TSlide[], Reveal: any, hash?: string) {
  const [prevSlideIndex, setPrevSlideIndex] = useState(0);
  const [session, setSession] = useState<null | string>(null);
  const [inProgress, setInProgress] = useState(false);

  const resetHeatmapData = useResetHeatmapData();

  useEffect(
    function openSession() {
      async function triggerStart() {
        if (!hash || !slides?.length) return;

        setInProgress(true);

        const loggedUser = await corePresentApi.get('/auth/me');
        if (loggedUser) return;

        const sessionId = await TrackingApi.startSession(hash, slides.length);
        setSession(sessionId);
        setPrevSlideIndex(0);

        setInProgress(false);

        TrackingApi.slideOpen(sessionId, {
          name: slides[0]?.label,
          id: slides[0]?.id,
        });
      }

      if (session || inProgress) return;

      triggerStart();
    },
    [hash, inProgress, session, slides],
  );

  useEffect(function presentationProgress() {
    async function onSlideChanged(e: any) {
      const index = e?.indexh as number | undefined;

      if (!session || typeof index === 'undefined') return;

      TrackingApi.slideClosed(session, {
        name: slides[prevSlideIndex]?.label,
        id: slides[prevSlideIndex].id,
        data: {
          clicks: heatmapData?.clicks ?? [],
          movements: heatmapData?.movements ?? [],
        },
      });

      TrackingApi.slideOpen(session, {
        name: slides[index]?.label,
        id: slides[index]?.id,
      });

      setPrevSlideIndex(index);
      resetHeatmapData();
    }

    if (!Reveal || !hash) return;

    Reveal.on('slidechanged', onSlideChanged);
    return () => Reveal.off('slidechanged', onSlideChanged);
  });

  useEffect(function eventTracking() {
    function onTrackingEvent(event: CustomEvent) {
      if (!session) return;

      const data = JSON.stringify({
        clicks: heatmapData?.clicks ?? [],
        movements: heatmapData?.movements ?? [],
      });

      TrackingApi.click(session, {
        ...event.detail,
        data,
      });
    }

    TrackingEvent.subscribe(onTrackingEvent as EventListener);
    return () => TrackingEvent.unsubscribe(onTrackingEvent as EventListener);
  });

  const closeSession = useCallback(
    async function closeSession(e) {
      e.stopPropagation();

      if (!session || inProgress) return;

      setInProgress(true);
      await TrackingApi.closeSession(
        session,
        heatmapData,
        slides?.[prevSlideIndex]?.id as TSlideId,
      );
      resetHeatmapData();
      setInProgress(false);
    },
    [slides, prevSlideIndex, session, inProgress, resetHeatmapData],
  );

  const closeVisibilitySession = useCallback(
    function closeVisibilitySession(e) {
      if (!PageVisibility.isVisible()) {
        closeSession(e);
      }
    },
    [closeSession],
  );

  useEventListener(PageVisibility.getEvent() as string, closeVisibilitySession, true);

  if ('onpagehide' in globalThis) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEventListener('pagehide', closeSession, true);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEventListener('unload', closeSession, true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEventListener('beforeunload', closeSession, true);
  }
}
