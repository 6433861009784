import { cloneDeep } from 'lodash';

import {
  TDynamicSectionId,
  TPresentationMode,
  TPresentationType,
  TSlide,
  TSlideId,
} from '../../../types';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';

class PersonalizedSlidesInjectorImp {
  private supportedTypes: TPresentationType[] = [
    PRESENTATION_TYPES.WIN_THE_LISTING,
    PRESENTATION_TYPES.WIN_THE_OFFER,
    PRESENTATION_TYPES.PRESENT_INFO,
    PRESENTATION_TYPES.WIN_THE_REPRESENTATION,
  ];

  private supportedModes = ['regular'];

  inject(
    type: TPresentationType,
    mode: TPresentationMode,
    sectionsSlides: Record<TDynamicSectionId, TSlideId[]>,
    dynamicSlidesMap: Record<string, TSlide>,
  ) {
    if (!this.isSupportedModeAndType(type, mode) || !this.hasSection(sectionsSlides))
      return sectionsSlides;

    let sectionSlidesCopy = cloneDeep(sectionsSlides);
    if (this.hasSlideToAdd('testimonials', dynamicSlidesMap)) {
      sectionSlidesCopy = this.addSlide('testimonials', sectionSlidesCopy);
    }

    if (this.hasSlideToAdd('aboutMe', dynamicSlidesMap)) {
      sectionSlidesCopy = this.addSlide('aboutMe', sectionSlidesCopy);
    }

    return sectionSlidesCopy;
  }

  private isSupportedModeAndType(type: TPresentationType, mode: TPresentationMode): boolean {
    return this.supportedTypes.includes(type) && this.supportedModes.includes(mode);
  }

  private hasSection(sectionsSlides: Record<TDynamicSectionId, TSlideId[]>) {
    return sectionsSlides.whyIAmTheRightFit;
  }

  private hasSlideToAdd(slide: TSlideId, dynamicSlidesMap: Record<string, TSlide>) {
    return dynamicSlidesMap[slide]?.includeSlide;
  }

  private addSlide(slide: TSlideId, sectionsSlides: Record<TDynamicSectionId, TSlideId[]>) {
    sectionsSlides.whyIAmTheRightFit = [slide, ...sectionsSlides.whyIAmTheRightFit];

    return sectionsSlides;
  }
}

export const PersonalizedSlidesInjector = new PersonalizedSlidesInjectorImp();
