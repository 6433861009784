import * as alpha from './alpha';
import * as beta from './beta';
import * as gamma from './gamma';
import * as delta from './delta';
import * as epsilon from './epsilon';
import * as zeta from './zeta';

import { TDynamicSlideConfigs } from '../../types';
import { TDynamicSlideTwoImagesTemplate } from '../../../../../types';

export const twoImagesTemplateConfigs: Record<
  TDynamicSlideTwoImagesTemplate,
  TDynamicSlideConfigs
> = {
  twoImagesLayoutAlpha: alpha,
  twoImagesLayoutBeta: beta,
  twoImagesLayoutGamma: gamma,
  twoImagesLayoutDelta: delta,
  twoImagesLayoutEpsilon: epsilon,
  twoImagesLayoutZeta: zeta,
};
