import React, { createContext, useContext, useState } from 'react';

import type { TSlideId, TPresentationMode } from '../../types';
import type { TDynamicOrder, TDynamicSectionId } from '../../types';

interface TOrderContext extends TDynamicOrder {
  orderByMode: IConfigKeeper;
  findSlideSection: (slideId?: TSlideId | string) => TDynamicSectionId | null;
  setSectionsOrder: (sections: TDynamicSectionId[]) => void;
  setSectionsSlideOrder: (sectionsSlide: Record<TDynamicSectionId, TSlideId[]>) => void;
  setSectionSlidesOrder: (section: TDynamicSectionId, slides: TSlideId[]) => void;
  addSlideToSection: (section: TDynamicSectionId, slideId: TSlideId) => void;
  removeSlideFromSection: (section: TDynamicSectionId, slideId: TSlideId) => void;
  getOrderedSlides: () => TSlideId[];
  getSlideSectionId: (slideId: string) => string | null;
  hasOrderForMode: (mode: TPresentationMode) => boolean;
  getOrderForMode: (mode?: TPresentationMode) => ConfigItem | null;
  activateOrderFromMode: (mode: TPresentationMode) => ConfigItem;
}
const OrderContext = createContext<TOrderContext | undefined>(undefined);

export function useOrder() {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within a OrderContext');
  }

  return context;
}

export interface ConfigItem {
  sections: TDynamicSectionId[];
  sectionsSlide: Record<TDynamicSectionId, TSlideId[]>;
}

export interface IConfigKeeper {
  cma?: ConfigItem;
  oneSheeter?: ConfigItem;
  regular?: ConfigItem;
}

interface Props {
  presentationMode?: TPresentationMode;
}

export const OrderProvider: React.FC<Props> = function OrderProvider({
  children,
  presentationMode,
}) {
  const [orderByMode, _setOrderByMode] = useState<IConfigKeeper>({});
  const [sections, setSectionsOrder] = useState<TDynamicSectionId[]>([]);
  const [sectionsSlide, setSectionsSlideOrder] = useState<Record<TDynamicSectionId, TSlideId[]>>(
    {},
  );

  const setSectionSlidesOrder = React.useCallback(function setSectionSlidesOrder(
    section: TDynamicSectionId,
    slides: TSlideId[],
  ) {
    setSectionsSlideOrder(prev => {
      if (!prev?.[section] || !Array.isArray(slides)) return prev;

      return {
        ...prev,
        [section]: [...slides],
      };
    });
  }, []);

  const addSlideToSection = React.useCallback(function addSlideToSection(
    section: TDynamicSectionId,
    slideId: TSlideId,
  ) {
    setSectionsSlideOrder(prev => {
      if (!prev?.[section] || !Array.isArray(prev[section])) return prev;

      return {
        [section]: [...prev[section], slideId],
      };
    });
  }, []);

  const removeSlideFromSection = React.useCallback(function removeSlideFromSection(
    section: TDynamicSectionId,
    slideId: TSlideId,
  ) {
    setSectionsSlideOrder(prev => {
      if (!prev?.[section] || !Array.isArray(prev[section])) return prev;

      return {
        ...prev,
        [section]: prev[section].filter(sId => sId !== slideId),
      };
    });
  }, []);

  const findSlideSection = React.useCallback(
    function findSlideSection(slideId?: TSlideId | string) {
      let slideSection: TDynamicSectionId | null | string = null;
      if (!slideId) return slideSection;

      sections.forEach(section => {
        const hasSlide = !!sectionsSlide[section]?.find(slide => slide === slideId);

        if (hasSlide && !slideSection) {
          slideSection = section;
        }
      });

      return slideSection;
    },
    [sectionsSlide, sections],
  );

  const getOrderedSlides = React.useCallback(
    function getOrderedSlides(): TSlideId[] {
      return sections.reduce(
        (slides: TSlideId[], section) => [...slides, ...(sectionsSlide[section] ?? [])],
        [],
      );
    },
    [sections, sectionsSlide],
  );

  const getSlideSectionId = React.useCallback(
    (slideId: string): string | null => {
      let section = null;

      sections.some(sectionId => {
        const found = sectionsSlide[sectionId]?.find(sId => sId === slideId);
        if (found) section = sectionId;
      });

      return section;
    },
    [sections, sectionsSlide],
  );

  const hasOrderForMode = React.useCallback(
    (mode: TPresentationMode) => {
      return (
        !!orderByMode[mode]?.sections?.length &&
        !!Object.keys(orderByMode[mode]?.sectionsSlide ?? {}).length
      );
    },
    [orderByMode],
  );

  const getOrderForMode = React.useCallback(
    (mode?: TPresentationMode) => {
      if (!mode || !hasOrderForMode(mode)) return null;

      return orderByMode[mode] ?? null;
    },
    [hasOrderForMode, orderByMode],
  );

  const activateOrderFromMode = React.useCallback(
    (mode: TPresentationMode) => {
      const dummyConfig = {
        sections: [],
        sectionsSlide: {},
      };

      if (!mode) return dummyConfig;

      const orderConfig = orderByMode[mode];
      if (!orderConfig) return dummyConfig;

      setSectionsOrder(orderConfig.sections);
      setSectionsSlideOrder(orderConfig.sectionsSlide);

      return orderConfig;
    },
    [orderByMode],
  );

  const setOrderByMode = React.useCallback((mode: TPresentationMode, config: ConfigItem) => {
    _setOrderByMode(prev => ({ ...prev, [mode]: config }));
  }, []);

  React.useEffect(() => {
    if (!presentationMode) return;

    setOrderByMode(presentationMode, { sections, sectionsSlide });
  }, [presentationMode, sections, sectionsSlide, setOrderByMode]);

  const value = React.useMemo(
    function memoizedOrder() {
      return {
        orderByMode,
        sections,
        sectionsSlide,
        setSectionsOrder,
        setSectionsSlideOrder,
        setSectionSlidesOrder,
        addSlideToSection,
        removeSlideFromSection,
        findSlideSection,
        getOrderedSlides,
        getSlideSectionId,
        hasOrderForMode,
        getOrderForMode,
        activateOrderFromMode,
      };
    },
    [
      orderByMode,
      sections,
      sectionsSlide,
      setSectionSlidesOrder,
      addSlideToSection,
      removeSlideFromSection,
      findSlideSection,
      getOrderedSlides,
      getSlideSectionId,
      hasOrderForMode,
      getOrderForMode,
      activateOrderFromMode,
    ],
  );

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};
