import styled from 'styled-components';

import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';
import starCS from '../../../assets/images/star_coming_soon.svg?url';
import starCSBT from '../../../assets/images/star_coming_soon_bt.svg?url';
import starActive from '../../../assets/images/star_active.svg?url';
import starPending from '../../../assets/images/star_pending.svg?url';
import starClosed from '../../../assets/images/star_closed.svg?url';

interface Props {
  isResponsive?: boolean;
}

export const ResultsTuningLegends = ({ isResponsive }: Props) => {
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const comingSoonIcon = shouldUseBtTheme ? starCSBT : starCS;

  return (
    <Wrapper isResponsive={isResponsive}>
      <Legend>
        <Image src={comingSoonIcon} alt='' />
        <Text>Coming Soon</Text>
      </Legend>
      <Legend>
        <Image src={starActive} alt='' />
        <Text>Active</Text>
      </Legend>
      <Legend>
        <Image src={starPending} alt='' />
        <Text>Pending</Text>
      </Legend>
      <Legend>
        <Image src={starClosed} alt='' />
        <Text>Closed</Text>
      </Legend>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isResponsive?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;

  ${props =>
    props.isResponsive &&
    `@media (max-width: 1080px) {
    gap: 7px;
  }`}
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;

const Image = styled.img`
  width: 26px;
  height: 26px;
`;

const Text = styled.div`
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
