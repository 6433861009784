import React from 'react';

import shape from './assets/shape_web.png';
import styled from 'styled-components';
import * as content from './content';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
} from '../../../../../providers/providers/UiConfigFontProvider';
import laptop from './assets/laptop.png';
import screen from './assets/screen.png';
import { useBrandName } from '../../../../../hooks/useBrandName';
import { useSlideLogo } from './useSlideLogo';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 100,
  position: 'relative',
  overflow: 'hidden',
  padding: '75px',
});

const TextContainer = styled('div')({
  width: 765,
  marginBottom: 55,
});

const Shape = styled('img')({
  position: 'absolute',
  zIndex: 1,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '55%',
});

const Text = styled('p')(({ theme }) => ({
  fontSize: 18,
  lineHeight: 1.8,
  letterSpacing: useUiConfigBodyLetterSpacing(),
  fontFamily: useUiConfigFontsBody(),
}));

const UL = styled('ul')(({ theme }) => ({
  zIndex: 2,
  paddingLeft: 30,
  '& li': { margin: 0 },
}));

const Logo = styled('img')({ alignSelf: 'flex-start', marginBottom: 30 });

const Laptop = styled('img')({ position: 'absolute', right: 20, top: 50, zIndex: 3, width: 1050 });
const Screen = styled('img')({ position: 'absolute', zIndex: 4, top: 158, right: 80, width: 555 });

export const CoreSocial: React.FC = () => {
  const { social } = useBrandName();

  const logo = useSlideLogo();

  const text = content.text.replaceAll('$brand', social);

  return (
    <Container>
      <Logo src={(logo as string) || ''} />
      <TextContainer>
        <Text>{text}</Text>
      </TextContainer>
      <Shape src={shape} />
      <Text style={{ zIndex: 50 }}>{content.heading}</Text>
      <UL>
        {content.listItems.map(item => (
          <li key={item}>
            <Text>{item}</Text>
          </li>
        ))}
      </UL>
      <Laptop src={laptop} />
      <Screen src={screen} />
      <Text
        style={{
          fontWeight: 700,
          color: '#000',
          position: 'absolute',
          zIndex: 10,
          right: 130,
          bottom: 75,
          fontSize: 14,
        }}
      >
        EXAMPLE
      </Text>
    </Container>
  );
};
