import styled from 'styled-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  BackButtonContainer,
  BackIcon,
  BackText,
} from '../AgentAdminAnalytics/AgentAdminAnalytics.styles';
import { Typography } from '../../components/Common';
import { corePresentApi } from '../../api/CorePresentApi';
import { MainLayout } from '../../layouts/MainLayout/MainLayout';
import { useUser } from '../../components/Slide/providers/UserProvider';
import { mapData, ApiResponse, mapUserData, mapTrackingData } from './data';
import { AgentPerformance } from './AgentPerformance/AgentPerformance.component';
import { AnalyticsSection } from '../../components/AnalyticsSection/AnalyticsSection.component';
import { AccountOfficeDropdown } from '../../components/Common/AccountOfficeDropdown/AccountOfficeDropdown.component';
import { withLoadedUser } from '../../hoc/withLoadedUser';
import Loader from '../../components/Loader/Loader';
import { endOfToday, startOfMonth, subMonths } from 'date-fns';
import { UtcTime } from '../../services/utcTime';
import { saveAs } from 'file-saver';
import { useBrandName } from '../../hooks/useBrandName';

const PageContainer = styled('div')({
  padding: '30px 30px 81px 30px',
});

const AccountOfficeDropdowns = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const NoOffice = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  flex: 1,
});

const LoaderDiv = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

const getRequestUrl = (entity: number | null) => {
  if (entity === 0) {
    return 'account';
  }

  if (entity === -1) {
    return 'superAccount';
  }
  return 'office';
};

const getRequestEntityId = (entity: number | null, accountId: number) => {
  if (entity === 0 || entity === -1) {
    return accountId;
  }

  return entity;
};

export const AdminAnalytics: React.FC = withLoadedUser(() => {
  const user = useUser();
  const fallBack = user.account.isAdmin ? 0 : user.primaryEntity;
  let { office } = useParams<{ office: string }>();
  if (office === undefined) {
    office = fallBack.toString();
  }
  const history = useHistory();
  const thirteenMonthsAgo = UtcTime.getInUtc(subMonths(startOfMonth(new Date()), 13));
  const endOfTodayDate = UtcTime.getInUtc(endOfToday());

  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [officeId, setOfficeId] = useState<number | null>(office ? parseInt(office) : null);
  const [type, setType] = useState<string | any>('all');
  const [date, setDate] = useState<string[]>([thirteenMonthsAgo, endOfTodayDate]);
  const [officeName, setOfficeName] = useState<string | null>(null);
  const [data, setData] = useState<ApiResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { present } = useBrandName();

  const onEntityChange = React.useCallback(
    async function onNameChange(entity: number | null, name?: string | null) {
      setOfficeId(entity);
      history.replace(`/admin-analytics/${entity}`);
      try {
        const url = getRequestUrl(entity);
        const entityId = getRequestEntityId(entity, user.accountId);
        setOfficeName(entity === 0 ? user.account.name : name ?? '');
        setLoading(true);
        const dateStr = `&start=${date[0]}&end=${date[1]}`;
        const data = await corePresentApi.get(
          `/analytics/${url}/${entityId}?type=${type}${dateStr}`,
        );
        setData(data);
        setLoading(false);
      } catch (e: any) {
        console.error(e);
        setErrorMessage(e.message);
      }
    },
    [date, history, type, user.account.name, user.accountId],
  );

  const onSubChange = React.useCallback(function onSubChange(name: string | null) {
    setOfficeName(name);
  }, []);

  const onTypeChange = React.useCallback(
    async function onTypeChange(type: string) {
      setType(type);
      try {
        const url = getRequestUrl(officeId);
        const entityId = getRequestEntityId(officeId, user.accountId);
        setLoading(true);
        const dateStr = `&start=${date[0]}&end=${date[1]}`;
        const data = await corePresentApi.get(
          `/analytics/${url}/${entityId}?type=${type}${dateStr}`,
        );
        setData(data);
        setLoading(false);
      } catch (e: any) {
        console.error(e);
        setErrorMessage(e.message);
      }
    },
    [date, officeId, user.accountId],
  );

  const onDateChange = React.useCallback(
    async function onTypeChange(start: string | null, end: string | null) {
      if (!start || !end) return;
      setDate([start, end]);
      try {
        const url = getRequestUrl(officeId);
        const entityId = getRequestEntityId(officeId, user.accountId);
        setLoading(true);
        const dateStr = `&start=${start}&end=${end}`;
        const data = await corePresentApi.get(
          `/analytics/${url}/${entityId}?type=${type}${dateStr}`,
        );
        setData(data);
        setLoading(false);
      } catch (e: any) {
        console.error(e);
        setErrorMessage(e.message);
      }
    },
    [officeId, type, user.accountId],
  );

  const onExportClick = React.useCallback(
    async function onExportClick() {
      try {
        const url = getRequestUrl(officeId);
        const entityId = getRequestEntityId(officeId, user.accountId);
        setButtonLoading(true);
        const dateStr = `&start=${date[0]}&end=${date[1]}`;
        const blob = await corePresentApi.fileDownload(
          `/analytics/${url}/${entityId}?type=${type}${dateStr}&csv=1`,
        );
        const fileName = date[0] + '_' + date[1] + '_' + type + '.csv';
        saveAs(new Blob([blob]), fileName);

        setButtonLoading(false);
      } catch (e: any) {
        setButtonLoading(false);
        console.error(e);
        setErrorMessage(e.message);
      }
    },
    [officeId, user.accountId, date, type],
  );

  useEffect(() => {
    if (office) {
      setOfficeId(parseInt(office));
      (async () => {
        try {
          setLoading(true);
          const officeInt = parseInt(office);
          const url = getRequestUrl(officeInt);
          const entityId = getRequestEntityId(officeInt, user.accountId);
          setOfficeName(officeInt === 0 ? user.account.name : '');
          const dateStr = `&start=${date[0]}&end=${date[1]}`;
          const data = await corePresentApi.get(
            `/analytics/${url}/${entityId}?type=${type}${dateStr}`,
          );
          setData(data);
          setLoading(false);
        } catch (e: any) {
          console.error(e);
          setErrorMessage(e.message);
          setLoading(false);
        }
      })();
    } else {
      const redirectToEntity = user.account.isAdmin ? 0 : user.primaryEntity;
      history.push(`/admin-analytics/${redirectToEntity}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeOptions = useMemo(() => {
    if (user?.superaccounts?.length) {
      return {
        myself: true,
        team: true,
      };
    }
    return {
      myself: true,
      team: true,
      superaccount: true,
    };
  }, [user?.superaccounts?.length]);

  const content =
    officeId !== undefined && officeId !== null && data ? (
      <>
        <AnalyticsSection
          name={officeName || ''}
          type={type}
          engagementMetricsData={mapData(data)}
          trackingFeedbackData={mapTrackingData(data)}
        />
        <AgentPerformance
          onExportClick={onExportClick}
          buttonLoading={buttonLoading}
          office={officeId}
          date={date}
          type={type}
          data={mapUserData(data)}
        />
      </>
    ) : (
      errorMessage && <NoOffice>{errorMessage}</NoOffice>
    );
  return (
    <MainLayout>
      <PageContainer>
        <BackButtonContainer to='/'>
          <BackIcon icon={['fas', 'chevron-left']} />
          <BackText>Back to {present}</BackText>
        </BackButtonContainer>
        <AccountOfficeDropdowns>
          <Typography variant='small'>View analytics for: </Typography>
          <AccountOfficeDropdown
            disabled={false}
            value={officeId}
            onChange={onEntityChange}
            onSubChange={onSubChange}
            onTypeChange={onTypeChange}
            onDateChange={onDateChange}
            removeOptions={removeOptions}
            hideSupperAccountSubOptions
          />
        </AccountOfficeDropdowns>
        {loading ? (
          <LoaderDiv>
            <Loader size='lg' />
          </LoaderDiv>
        ) : (
          content
        )}
      </PageContainer>
    </MainLayout>
  );
});
