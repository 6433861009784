import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { TSection, TSectionConfig, TSlide } from '../../types';
import { useOrder } from '../../providers/providers/OrderProvider';
import { useSections } from '../../providers/providers/SectionsProvider';
import { DynamicOrderableSections } from '../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/DynamicOrderableSections/DynamicOrderableSections.component';
import { CMADynamicOrderableSlides } from '../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/CMADynamicSections/CMADynamicOrderableSlides.component';

export const SectionsColumn: React.FC = () => {
  const { sections } = useSections();
  const { sections: sectionsOrder, findSlideSection } = useOrder();

  const dummyCb = React.useCallback(() => {}, []);

  const extractSlideSection = (slide: TSlide) => {
    const sectionId = findSlideSection(slide.id);

    if (!sectionId) return null;

    return sections[sectionId] as TSectionConfig;
  };

  return (
    <DynamicOrderableSections
      sectionsOrder={sectionsOrder as TSection[]}
      onSectionsOrderChange={dummyCb}
      sections={sections as Record<TSection, TSectionConfig>}
      OrderableSlidesComponent={CMADynamicOrderableSlides}
      extractSlideSection={extractSlideSection}
      openInitialSection={!isEmpty(sections)}
    />
  );
};
