import styled from 'styled-components';
import { ButtonsGroup } from '../../../../../components/Common/V2/ButtonsGroup';
import { AreaType } from '../../../../../types';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { areaTypeAtom } from '../state/areaTypeAtom';
import { mapModeAtom } from '../state/mapModeAtom';
import { searchAreaValueAtom } from '../state/searchAreaValueAtom';
import { mapCoordinatesAtom } from '../state/mapCoordinatesAtom';
import { areaDetailsAtom } from '../state/areaDetailsAtom';

const Container = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

export const AreaTypeSwitch = () => {
  const [areaType, setAreaType] = useAtom(areaTypeAtom);
  const setSearchAreaValue = useSetAtom(searchAreaValueAtom);
  const setMapCoordinates = useSetAtom(mapCoordinatesAtom);
  const setAreaDetails = useSetAtom(areaDetailsAtom);

  const mapMode = useAtomValue(mapModeAtom);

  const buttonsConfigs = [
    {
      label: 'Geographical Area',
      value: AreaType.geographical,
      onClick: () => {
        setAreaType(AreaType.geographical);
        setSearchAreaValue('');
        setAreaDetails(null);
        setMapCoordinates(null);
      },
      isActive: areaType === AreaType.geographical,
    },
    {
      label: 'MLS Area',
      value: AreaType.mls,
      onClick: () => {
        setAreaType(AreaType.mls);
        setSearchAreaValue('');
        setAreaDetails(null);
        setMapCoordinates(null);
      },
      isActive: areaType === AreaType.mls,
    },
  ];

  if (mapMode !== 'area') return null;

  return (
    <Container>
      <ButtonsGroup buttonsConfigs={buttonsConfigs} />
    </Container>
  );
};
