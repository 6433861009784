import { useState } from 'react';
import styled from 'styled-components';

import { MarketAssessmentCardCircle } from './MarketAssessmentCardCircle';

const getBgImgStyle = (backgroundImage: string) => ({
  backgroundImage: `url("${backgroundImage}")`,
  backgroundPosition: 'right 5px top 50%',
});

const getOneSheeterStyle = (show: boolean) => {
  return !show ? {} : { height: 213 };
};

interface CardProps {
  headline: string;
  content: string | number | JSX.Element;
  description: string;
  bgImg: string;
  bgImgLight: string;
  clicked: () => void;
  hasCircle?: boolean;
  disabled?: boolean;
  oneSheeter?: boolean;
  sup?: JSX.Element;
  sub?: string;
}

export const MarketAssessmentCard = ({
  headline,
  content,
  description,
  bgImg,
  bgImgLight,
  clicked,
  hasCircle,
  disabled,
  oneSheeter,
  sup,
  sub,
}: CardProps) => {
  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    if (disabled) return;

    clicked();
  };

  const backgroundImageStyle = getBgImgStyle(hovered ? bgImgLight : bgImg);
  const oneSheeterStyle = getOneSheeterStyle(Boolean(oneSheeter));

  return (
    <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <Wrapper
        oneSheeter={oneSheeter}
        disabled={disabled}
        onClick={handleClick}
        style={{
          ...backgroundImageStyle,
          ...oneSheeterStyle,
        }}
      >
        <Headline oneSheeter={oneSheeter}>{headline}</Headline>

        {hasCircle ? (
          <MarketAssessmentCardCircle oneSheeter={oneSheeter} hovered={hovered} sup={sup} sub={sub}>
            {content}
          </MarketAssessmentCardCircle>
        ) : (
          content
        )}

        <Description oneSheeter={oneSheeter}>{description}</Description>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div<{ oneSheeter?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 130px;
  background-color: #fff;
  position: relative;
  border-radius: 7px;
  border: 1px solid ${props => props.theme.colors.v2.gray[200]};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  padding: ${props => (props.oneSheeter ? '0' : '20px 20px 40px')};

  &:hover {
    background-color: ${props => props.theme.colors.v2.gray[100]};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`;

const Headline = styled.header<{ oneSheeter?: boolean }>`
  width: 100%;
  font-size: ${props => (props.oneSheeter ? '16px' : '18px')};
  padding: ${props => (props.oneSheeter ? '15px 0px' : '0 0 20px 0')};
  border-bottom: 1px solid ${props => props.theme.colors.v2.gray[200]};
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Description = styled.p<{ oneSheeter?: boolean }>`
  font-size: ${props => (props.oneSheeter ? '12.5px' : '1.125em')};
  color: ${props => props.theme.colors.v2.gray[400]};
  line-height: 1.33;
  text-align: center;
  margin: 30px 20px 0;
  transform: ${props => (props.oneSheeter ? 'translateY(-40px)' : 'none')};
`;
