import { TPresentationMode } from '../../../../types';
import { ConfigItem } from '../../../../providers/providers/OrderProvider';

export function hasGreetingVideoSlide(
  mode: TPresentationMode | undefined,
  order: ConfigItem | null,
) {
  if (!order || !mode) return false;

  if (mode === 'regular') {
    return order?.sectionsSlide?.startingSlides?.includes('greetingVideo');
  }

  const [firstSectionId] = order.sections;
  return order?.sectionsSlide?.[firstSectionId]?.includes('greetingVideo');
}
