import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { corePresentApi } from '../../../../api/CorePresentApi';
import { MediaApi } from '../../../../api/MediaApi';
import { DashboardInput, FilePicker, FitContentModal } from '../../../../components/Common';
import { PRESENTATION_TYPES } from '../../../../constants/PresentationTypes';
import { Button } from '../../../../components/Common/V2/Button/Button';

export const Container = styled('div')({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const Split = styled.span`
  content: 'OR';
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.gray[400]};
`;

interface AddAgreementLinkProps {
  isOpen: boolean;
  onClose: () => void;
  initialAgreementLink?: string;
  id: string;
  presentationHash: string;
  type?: string;
}

export const AddAgreementLink: React.FC<AddAgreementLinkProps> = ({
  isOpen,
  onClose,
  initialAgreementLink = '',
  id,
  type,
  presentationHash,
}) => {
  const [agreementLink, setAgreementLink] = useState(initialAgreementLink);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (file) {
      fileUpload(file);
    }
  }, [file]);

  const fileUpload = async (file: File) => {
    try {
      setIsUploading(true);
      const fileUrl = await MediaApi.upload(file);
      setAgreementLink(fileUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreementLink(e.currentTarget.value);
  };

  const onConfirmAgreementLink = async () => {
    try {
      const link = /(http(s?)):\/\//i.test(agreementLink)
        ? agreementLink
        : `https://${agreementLink}`;
      const { agreement_link } = await corePresentApi.put(`/presentations/${id}`, {
        agreement: agreementLink ? link : null,
      });

      setAgreementLink(agreement_link);
      queryClient.invalidateQueries(['presentation', presentationHash]);
    } catch (e: any) {
      console.error(e);
    }

    onClose();
  };

  const agreementLinkTitle = useMemo(() => {
    const isWinTheOffer = type === PRESENTATION_TYPES.WIN_THE_OFFER;
    const isPresentInfo = type === PRESENTATION_TYPES.PRESENT_INFO;
    const isWTR = type === PRESENTATION_TYPES.WIN_THE_REPRESENTATION;

    if (isWinTheOffer) {
      return 'Add Buyer’s Agreement Link';
    }

    if (isPresentInfo) {
      return 'Add Agreement Link';
    }

    if (isWTR) {
      return 'Add Buyer Representation Contract';
    }

    return 'Add Listing Agreement Link';
  }, [type]);

  const agreementLinkText = useMemo(() => {
    const isWinTheOffer = type === PRESENTATION_TYPES.WIN_THE_OFFER;
    const isPresentInfo = type === PRESENTATION_TYPES.PRESENT_INFO;
    const isWTR = type === PRESENTATION_TYPES.WIN_THE_REPRESENTATION;

    if (isWinTheOffer) {
      return {
        label: 'Buyer’s Agreement URL',
        placeholder: 'Add buyer’s agreement URL',
      };
    }

    if (isPresentInfo) {
      return {
        label: 'Agreement URL',
        placeholder: 'Add agreement URL',
      };
    }

    if (isWTR) {
      return {
        label: 'Buyer Representation Contract URL',
        placeholder: 'Add buyer representation contract URL',
      };
    }

    return {
      label: 'Listing Agreement URL',
      placeholder: 'Add listing agreement URL',
    };
  }, [type]);

  return (
    <FitContentModal
      isOpen={isOpen}
      headerTitle={agreementLinkTitle}
      onClose={onClose}
      confirmBtnText='Add'
      hasCancelBtn
      onConfirmClick={onConfirmAgreementLink}
    >
      <Container>
        <DashboardInput
          type='text'
          label={agreementLinkText.label}
          inputId='agreement-link'
          value={agreementLink}
          onChange={onChange}
          placeholder={agreementLinkText.placeholder}
        />
        <Split>OR</Split>
        <FilePicker setFile={setFile} accept='application/pdf'>
          <Button variant='dark' isLoading={isUploading} style={{ width: 112 }}>
            Upload PDF
          </Button>
        </FilePicker>
      </Container>
    </FitContentModal>
  );
};
