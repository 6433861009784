import React from 'react';
import { PotentialBuyerCard } from './PotentialBuyerCard/PotentialBuyerCard.component';
import { PotentialBuyersHeader } from './PotentialBuyers.styles';
interface PotentialBuyersProps {
  data: any[];
}
export const PotentialBuyers: React.FC<PotentialBuyersProps> = ({ data }) => (
  <>
    <PotentialBuyersHeader>Buyer Examples</PotentialBuyersHeader>
    {data.map(buyer => (
      <PotentialBuyerCard buyerCardInfo={buyer} key={buyer.id} />
    ))}
  </>
);
