import React from 'react';

import { PresentationNavItem } from '../../../../types';
import { NavItemContainer, Typography } from './Navigation.styles';

export const NavItemComponent: React.FC<PresentationNavItem> = ({ label, isActive }) => (
  <NavItemContainer isactive={isActive ? 1 : 0}>
    <Typography variant='body1' color='400' style={{ fontWeight: isActive ? 700 : 500 }}>
      {label}
    </Typography>
  </NavItemContainer>
);
