import { useAtomValue } from 'jotai';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { trackElementClick } from '../../../../../pages/PresentationCreate/dash/communicators/tracking';
import { MoneyFormatter } from '../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { isPresentationViewAtom } from '../../../state/isPresentationViewAtom';

interface BottomRangeMarkerProps {
  text: string;
  height: number;
  offsetLeft: string;
  price: string;
  icon: string;
  count?: number;
  onClick?: () => void;
  isPdf?: boolean;
}

export const BottomRangeMarker = ({
  text = '',
  height = 50,
  offsetLeft = '0',
  price = '',
  icon = '',
  count,
  onClick,
  isPdf = false,
}: BottomRangeMarkerProps) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const hasActionStyles = Boolean(onClick);
  const isReduced = parseInt(offsetLeft) > 95;
  const isReducedPdf = isReduced && isPdf;
  const allowTracking = isPresentationView && !isPdf;

  return (
    <RangeMarkerWrapper
      hasActionStyles={hasActionStyles}
      style={{ height: `${height}px`, left: `${offsetLeft}` }}
      onClick={() => {
        allowTracking && trackElementClick(`Clicked "${text}" on the scale`);
        onClick && onClick();
      }}
    >
      <Dot />
      <Tooltip
        title={price}
        hasActionStyles={hasActionStyles}
        isReducedPdfStyle={isReducedPdf}
        isPdf={isPdf}
      >
        <Circle>
          <img src={icon} alt='icon' />
        </Circle>
        <div>
          <TextWrapper isReducedPdfStyle={isReducedPdf}>
            {ReactHtmlParser(text)} {count !== undefined ? ` (${count})` : null}
            <Paragraph>{MoneyFormatter.format(price)}</Paragraph>
          </TextWrapper>
        </div>
      </Tooltip>
    </RangeMarkerWrapper>
  );
};

const RangeMarkerWrapper = styled.div<{ hasActionStyles: boolean }>`
  position: absolute;
  top: 0;
  border-left: 1px solid #ddd;

  cursor: ${({ hasActionStyles }) => (hasActionStyles ? 'pointer' : 'default')};
`;

const Tooltip = styled.div<{
  hasActionStyles?: boolean;
  isReducedPdfStyle?: boolean;
  isPdf?: boolean;
}>`
  min-width: 100px;
  background-color: #fff;
  color: #000;
  font-size: ${({ isPdf }) => (isPdf ? '12px' : '14px')};
  text-align: center;
  text-transform: uppercase;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  white-space: nowrap;

  position: absolute;
  left: 5px;
  bottom: 0;
  display: flex;
  align-items: center;

  & span {
    font-weight: bold;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -11px;
    margin-top: -5px;
    border: 5px solid transparent;
    border-right-color: #ddd;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -5px;
    border: 5px solid transparent;
    border-right-color: #fff;
  }

  ${({ hasActionStyles }) =>
    hasActionStyles &&
    `&:hover {
        background: #f4f4f4;
        border-color: #6ab4e4;
        &:after {
            border-right-color: #6ab4e4;
        }
    }`}
  ${({ isReducedPdfStyle }) =>
    isReducedPdfStyle &&
    `max-width: 215px;
      div {
        max-width: 170px;
      }`}
`;

const TextWrapper = styled.div<{ isReducedPdfStyle?: boolean }>`
  ${({ isReducedPdfStyle }) =>
    isReducedPdfStyle &&
    `text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      overflow: hidden;
      display: block;`}
`;

const Paragraph = styled.p`
  font-size: 14px;
  text-align: left;
  color: #808080;
  font-weight: normal;
`;

const Circle = styled.span`
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border: 1px solid #6ab4e4;

  & img {
    max-width: 80%;
  }
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ddd;
  position: absolute;
  left: -3px;
  top: 0;
`;
