import React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';

import bedIcon from './icons/bed.svg';
import dateIcon from './icons/date.svg';
import sizeIcon from './icons/area.svg';
import bathsIcon from './icons/baths.svg';
import distanceIcon from './icons/distance.svg';
import { NumberFormatter } from '../../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { TProperty } from '../../../../../../../types';
import { useProperties } from '../../../../../../../providers/providers/PropertiesProvider';
import { useAtomValue } from 'jotai';
import { searchCriteriaAtom } from '../../../../../../../features/report/state/searchCriteriaAtom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface TextProps {
  isPdf: boolean;
}
const Title = styled.h4<TextProps>`
  font-weight: 700;
  font-size: ${({ isPdf }) => (isPdf ? '12px' : '14px')};
  line-height: ${({ isPdf }) => (isPdf ? '15px' : '133%')};
  color: #333333;
  margin: 10px 0 8px;
  text-align: center;
`;

const Value = styled.p<TextProps>`
  font-weight: 800;
  font-size: ${({ isPdf }) => (isPdf ? '16px' : '20px')};
  line-height: ${({ isPdf }) => (isPdf ? '20px' : '133%')};
  color: #444444;
`;

type TCriteriaType = 'size' | 'bed' | 'distance' | 'date' | 'bath';
type TBTCriteriaType = 'size' | 'bed' | 'distance' | 'date';

type TCriteriaData = {
  icon: string | any;
  title: string;
  getValue: (val: any) => string;
};

const configs: Record<TCriteriaType, TCriteriaData> = {
  size: {
    icon: sizeIcon,
    title: 'Living Area Size',
    getValue: val => {
      const sizeFrom = val.sizeRange[0];
      const sizeTo = val.sizeRange[1];
      return `${NumberFormatter.format(sizeFrom)} - ${NumberFormatter.format(sizeTo)}`;
    },
  },
  bed: {
    icon: bedIcon,
    title: 'Bed Count',
    getValue: val => {
      const bedFrom = val.beds + val.bedsRange[0];
      const bedTo = val.beds + val.bedsRange[1];
      if (!bedFrom && !bedTo) return val.beds;

      if (bedFrom === bedTo) return bedFrom;

      return `${bedFrom} - ${bedTo}`;
    },
  },
  bath: {
    icon: bathsIcon,
    title: 'Total Baths',
    getValue: val => {
      const bathsFrom = val?.bathsRange?.[0];
      const bathsTo = val?.bathsRange?.[1];
      if (!bathsFrom && !bathsTo) return val?.baths || 'N/A';

      if (bathsFrom === bathsTo) return bathsFrom === 10 ? '10+' : bathsFrom;

      return `${bathsFrom} - ${bathsTo === 10 ? '10+' : bathsTo}`;
    },
  },
  distance: {
    icon: distanceIcon,
    title: 'Distance from Subject',
    getValue: val => {
      if ((val.mapMode === 'marker' || val.mapMode === null) && Boolean(val.radius.value)) {
        return `${val.radius.value} Mile`;
      }
      return 'Varies';
    },
  },
  date: {
    icon: dateIcon,
    title: 'Closed Date',
    getValue: val => {
      const saleDateLimitTo = val.saleDateLimitRange[1];
      return `In ${saleDateLimitTo} Months`;
    },
  },
};

const btConfigs: Record<TBTCriteriaType, TCriteriaData> = {
  size: {
    icon: sizeIcon,
    title: 'Living Area Size',
    getValue: (properties: TProperty[]) => {
      if (properties.length === 1) {
        return NumberFormatter.format(properties[0]?.size);
      }

      const sizeArr = properties.map(p => p.size).sort();
      const min = sizeArr[0];
      const max = sizeArr.slice(-1)[0];
      return `${NumberFormatter.format(min)} - ${NumberFormatter.format(max)}`;
    },
  },
  bed: {
    icon: bedIcon,
    title: 'Bed Count',
    getValue: (properties: TProperty[]) => {
      if (properties.length === 1) {
        return NumberFormatter.format(properties[0]?.beds);
      }

      const beds = properties.map(p => p.beds).sort();
      const min = beds[0];
      const max = beds.slice(-1)[0];
      return min === max ? `${min}` : `${min} - ${max}`;
    },
  },
  distance: {
    icon: distanceIcon,
    title: 'Properties Included in Tour',
    getValue: properties => properties.length,
  },
  date: {
    icon: dateIcon,
    title: 'Avg Days on Market',
    getValue: (properties: TProperty[]) => {
      let total = 0;
      properties.forEach(p => {
        total += p.daysOnMarket;
      });
      return `${Math.round(total / properties.length)} Days`;
    },
  },
};

interface CriteriaProps {
  isPdf: boolean;
  type: TCriteriaType;
}

export const Criteria: React.FC<CriteriaProps> = ({ isPdf = false, type }) => {
  const searchCriteria = useAtomValue(searchCriteriaAtom);
  const Icon = configs[type].icon as AnyStyledComponent;

  return (
    <Container>
      <Icon />
      <Title isPdf={isPdf}>{configs[type].title}</Title>
      <Value isPdf={isPdf}>{configs[type].getValue(searchCriteria)}</Value>
    </Container>
  );
};

interface BTCriteriaProps {
  isPdf: boolean;
  type: TBTCriteriaType;
}

export const CriteriaBT: React.FC<BTCriteriaProps> = ({ isPdf = false, type }) => {
  const { properties } = useProperties();
  const Icon = btConfigs[type].icon as AnyStyledComponent;
  return (
    <Container>
      <Icon />
      <Title isPdf={isPdf}>{btConfigs[type].title}</Title>
      <Value isPdf={isPdf}>{btConfigs[type].getValue(properties)}</Value>
    </Container>
  );
};
