export default `<svg width="535" height="518" viewBox="0 0 535 518" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M145.28 227.135L225.28 217.001" stroke="#444444" stroke-width="2"/>
<path d="M213.78 117L241.28 134" stroke="#444444" stroke-width="2"/>
<path d="M437.28 353.856L416.28 316.5" stroke="#444444" stroke-width="2"/>
<path d="M179.28 333.391L250.78 276" stroke="#444444" stroke-width="2"/>
<path d="M299.78 376L318.28 323.5" stroke="#444444" stroke-width="2"/>
<circle cx="357.572" cy="193.814" r="134.084" stroke="#027B76" stroke-width="4"/>
<circle cx="155.471" cy="75.4714" r="72.4714" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="2"/>
<circle cx="104.95" cy="15.7188" r="15.3966" fill="#444444"/>
<path d="M107.244 23.1035H103.677V11.7502L100.174 12.7737V10.0726L106.917 7.74071H107.244V23.1035Z" fill="white"/>
<circle cx="73.4714" cy="219.471" r="72.4714" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="2"/>
<circle cx="15.9503" cy="164.719" r="15.3966" fill="#444444"/>
<path d="M21.0821 172.104H10.3619V169.782L15.3 164.591C16.5169 163.205 17.1253 162.104 17.1253 161.288C17.1253 160.627 16.9811 160.124 16.6927 159.78C16.4043 159.435 15.9858 159.262 15.4371 159.262C14.8955 159.262 14.4558 159.495 14.1182 159.959C13.7806 160.416 13.6117 160.989 13.6117 161.679H10.0454C10.0454 160.736 10.281 159.867 10.7523 159.073C11.2236 158.271 11.8778 157.645 12.7149 157.194C13.5519 156.744 14.4875 156.519 15.5215 156.519C17.1816 156.519 18.4583 156.902 19.3517 157.669C20.2521 158.436 20.7023 159.537 20.7023 160.972C20.7023 161.577 20.5897 162.168 20.3646 162.744C20.1395 163.314 19.7878 163.916 19.3095 164.549C18.8382 165.175 18.075 166.015 17.0198 167.071L15.0362 169.36H21.0821V172.104Z" fill="white"/>
<circle cx="121.471" cy="376.471" r="72.4714" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="2"/>
<circle cx="66.3966" cy="322.344" r="15.3966" fill="#444444"/>
<path d="M64.059 320.559H65.7367C67.0661 320.559 67.7309 319.909 67.7309 318.607C67.7309 318.101 67.5726 317.689 67.2561 317.373C66.9395 317.049 66.4929 316.887 65.916 316.887C65.4447 316.887 65.0332 317.025 64.6815 317.299C64.3369 317.573 64.1645 317.914 64.1645 318.322H60.6087C60.6087 317.514 60.8338 316.793 61.284 316.159C61.7342 315.526 62.3567 315.034 63.1516 314.682C63.9535 314.324 64.8328 314.144 65.7894 314.144C67.4988 314.144 68.8423 314.535 69.8201 315.315C70.7978 316.096 71.2867 317.169 71.2867 318.534C71.2867 319.195 71.0827 319.821 70.6747 320.412C70.2738 320.995 69.6864 321.47 68.9126 321.836C69.7286 322.132 70.3687 322.582 70.833 323.187C71.2972 323.785 71.5294 324.527 71.5294 325.413C71.5294 326.785 71.0018 327.882 69.9467 328.705C68.8915 329.528 67.5058 329.94 65.7894 329.94C64.7835 329.94 63.848 329.75 62.9828 329.37C62.1246 328.983 61.4739 328.452 61.0308 327.777C60.5876 327.094 60.366 326.32 60.366 325.455H63.9429C63.9429 325.927 64.1329 326.334 64.5127 326.679C64.8926 327.024 65.3603 327.196 65.916 327.196C66.5421 327.196 67.0415 327.024 67.4143 326.679C67.7872 326.327 67.9736 325.881 67.9736 325.339C67.9736 324.565 67.7801 324.017 67.3932 323.693C67.0064 323.37 66.4717 323.208 65.7894 323.208H64.059V320.559Z" fill="white"/>
<circle cx="276.471" cy="444.471" r="72.4714" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="2"/>
<circle cx="220.397" cy="390.262" r="15.3966" fill="#444444"/>
<path d="M224.199 391.643H225.761V394.386H224.199V397.646H220.643V394.386H214.703L214.492 392.213L220.643 382.315V382.284H224.199V391.643ZM217.879 391.643H220.643V386.916L220.422 387.274L217.879 391.643Z" fill="white"/>
<circle cx="458.5" cy="426.5" r="75.5" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="2"/>
<circle cx="400.397" cy="367.659" r="15.3966" fill="#444444"/>
<path d="M395.02 367.532L395.959 359.682H404.938V362.447H398.85L398.502 365.496C398.755 365.348 399.086 365.218 399.494 365.106C399.909 364.993 400.313 364.937 400.707 364.937C402.234 364.937 403.405 365.39 404.221 366.298C405.044 367.198 405.456 368.464 405.456 370.096C405.456 371.081 405.234 371.974 404.791 372.776C404.355 373.571 403.739 374.183 402.944 374.612C402.149 375.041 401.21 375.256 400.127 375.256C399.163 375.256 398.259 375.059 397.415 374.665C396.571 374.264 395.91 373.719 395.432 373.03C394.953 372.333 394.718 371.549 394.725 370.677H398.291C398.326 371.239 398.506 371.686 398.829 372.017C399.153 372.347 399.578 372.513 400.106 372.513C401.302 372.513 401.9 371.626 401.9 369.854C401.9 368.215 401.168 367.395 399.705 367.395C398.875 367.395 398.256 367.662 397.848 368.197L395.02 367.532Z" fill="white"/>
</svg>
`;
