import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { Icon } from '..';
import { Typography } from '../Typography/Typography.component';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  // width: '100%',
  justifyContent: 'center',
});

const Img = styled('img')({
  marginLeft: 4,
  width: 4.2,
  height: 'auto',
});

interface TableHeadButtonProps {
  text: string;
  onClick: () => void;
  fontStyles?: CSSObject;
  color?: string;
  active?: boolean;
}

export const TableHeadButton: React.FC<TableHeadButtonProps> = ({
  text,
  onClick,
  fontStyles,
  color = '#444',
  active = false,
}) => {
  return (
    <Container>
      <Typography
        style={{ cursor: 'pointer', color, ...fontStyles, fontWeight: active ? 'bold' : 'normal' }}
        variant='body2'
        onClick={onClick}
      >
        {text}
      </Typography>
      <Icon iconName='smallArrows' fill={color} style={{ marginLeft: 4 }} />
    </Container>
  );
};
