import React from 'react';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { ContactItem } from '../../../../../../../Common';
import {
  ContactInfo,
  TContactIconName,
} from '../../../../../../../Common/ContactItem/ContactItem.component';
import BrokerInfo from '../../../../../../components/SlideFrame/components/BrokerInfo/BrokerInfo.component';
import { useUser } from '../../../../../../providers/UserProvider';
import {
  BrokerContactContainer,
  BrokerInfoContainer,
  BrokerInfoWrapper,
  Container,
} from './BrokerContactInfo.styles';

interface BrokerContactInfoProps {
  isLuxury?: boolean;
}

export const BrokerContactInfo: React.FC<BrokerContactInfoProps> = ({ isLuxury }) => {
  const user = useUser();
  const primaryColor = useUiConfigColorsPrimary();

  const styles = {
    height: '100%',
    marginLeft: 150,
    width: '43%',
  };

  if (!user?.userId) return null;

  const { phone, email, website, company, address, city, zip } = user;

  return (
    <Container isLuxury={isLuxury}>
      <BrokerInfoContainer isLuxury={isLuxury}>
        <BrokerInfoWrapper>
          <BrokerInfo
            imageSize={95}
            nameFontStyles={{
              color: primaryColor,
              fontSize: 20,
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
            contactFontStyles={{ fontSize: 16, fontWeight: 400, color: '#444' }}
            showLicenseNumber
          />
        </BrokerInfoWrapper>
      </BrokerInfoContainer>
      <BrokerContactContainer isLuxury={isLuxury}>
        {phone && <ContactItem iconName={'mobile-android'} phone={phone} color='#444' isPdf />}
        {/*{phone && <ContactItem iconName={'phone-office'} phone={phone} color='#444' isPdf />}*/}
        {email && <ContactItem iconName={'envelope'} email={email} color='#444' isPdf />}
        {website && <ContactItem iconName={'globe'} website={website} color='#444' isPdf />}
        {company && (
          <ContactItem
            iconName={'map-marker-alt'}
            contactAddress={{
              companyName: company,
              address,
              city: `${city}, ${zip}`,
            }}
            color='#444'
            containerStyles={styles}
            isPdf
          />
        )}
      </BrokerContactContainer>
    </Container>
  );
};
