import React from 'react';
import { CostBreakdownSection } from './CostBreakdownSection/CostBreakdownSection.component';
import {
  CostBreakdownRow,
  CostBreakdownTitle,
  CostBreakdownEmpty,
  TableContainer,
  TableHead,
  ListPriceTitle,
  ListPriceItem,
  TableBody,
} from './NetProceedsTable.styles';

import { TNetProceedsData } from '../../../../../../../types';
import { MoneyFormatter } from '../../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';

interface NetProceedsTableProps {
  data?: TNetProceedsData[];
  selectedItemIndex?: number;
  changeSelectedItem?: (i: number) => void;
}

export const NetProceedsTable: React.FC<NetProceedsTableProps> = ({
  data = [],
  selectedItemIndex,
  changeSelectedItem,
}) => {
  const mortgagePayoffsItems = [
    { title: '1st Mortgage', items: data.map(dataItem => dataItem.mortgagePayoffs.first) },
  ];

  const realEstateFees = [
    {
      title: 'Listing Office',
      items: data.map(dataItem => dataItem.realEstateFees.listingOffice),
    },
    {
      title: 'Selling Office',
      items: data.map(dataItem => dataItem.realEstateFees.sellingOffice),
    },
  ];

  const settlementCosts = [
    {
      title: 'Escrow Fee',
      items: data.map(dataItem => dataItem.settlementCosts.escrowFee),
    },
    {
      title: 'Title Insurance',
      items: data.map(dataItem => dataItem.settlementCosts.titleInsurance),
    },
    {
      title: 'Prorated Property Tax',
      items: data.map(dataItem => dataItem.settlementCosts.proratedPropertyTax),
    },
  ];

  const transferExciseTax = [
    {
      title: 'State Tax',
      items: data.map(dataItem => dataItem.transferExciseTax.stateTax),
    },
  ];

  const otherCosts = [
    {
      title: 'Assessments',
      items: data.map(dataItem => dataItem.otherCosts.assessments),
    },
    {
      title: 'HOA Fees',
      items: data.map(dataItem => dataItem.otherCosts.HOAFees),
    },
    {
      title: 'Administrative Fee',
      items: data.map(dataItem => dataItem.otherCosts.administrativeFee),
    },
    {
      title: 'Home Warranty Policy',
      items: data.map(dataItem => dataItem.otherCosts.homeWarrantyPolicy),
    },
    {
      title: 'Inspections',
      items: data.map(dataItem => dataItem.otherCosts.inspections),
    },
    {
      title: 'Closing Costs',
      items: data.map(dataItem => dataItem.otherCosts.closingCosts),
    },
  ];

  const hideCols = data.map((dataItem, i) => {
    if (!dataItem.listPrice.price) return true;

    return Object.values(dataItem)
      .flatMap(item => Object.values(item))
      .every(item => item === 0);
  });

  return (
    <TableContainer>
      <TableHead>
        <ListPriceTitle>List price</ListPriceTitle>
        {data.map((item, i) => {
          if (!item.listPrice.price) return;

          return (
            <ListPriceItem
              key={i}
              onClick={changeSelectedItem ? () => changeSelectedItem(i) : () => {}}
              isActive={selectedItemIndex === i}
              selectedIndex={selectedItemIndex}
            >
              {MoneyFormatter.format(item.listPrice.price)}
            </ListPriceItem>
          );
        })}
      </TableHead>
      <TableBody>
        <CostBreakdownRow>
          <CostBreakdownTitle>Cost breakdown</CostBreakdownTitle>
          {data.map((item, i) => {
            if (hideCols[i]) return;
            return <CostBreakdownEmpty key={i} isActive={selectedItemIndex === i} />;
          })}
        </CostBreakdownRow>
        <CostBreakdownSection
          title='Mortgage payoffs'
          selectedItemIndex={selectedItemIndex}
          costBreakdownItems={mortgagePayoffsItems}
          hideCols={hideCols}
        />
        <CostBreakdownSection
          title='Real Estate Fees'
          selectedItemIndex={selectedItemIndex}
          costBreakdownItems={realEstateFees}
          hideCols={hideCols}
        />
        <CostBreakdownSection
          title='Settlement Costs'
          selectedItemIndex={selectedItemIndex}
          costBreakdownItems={settlementCosts}
          hideCols={hideCols}
        />
        <CostBreakdownSection
          title='Transfer/Excise Tax'
          selectedItemIndex={selectedItemIndex}
          costBreakdownItems={transferExciseTax}
          hideCols={hideCols}
        />
        <CostBreakdownSection
          title='Other Costs'
          selectedItemIndex={selectedItemIndex}
          costBreakdownItems={otherCosts}
          hideCols={hideCols}
        />
      </TableBody>
    </TableContainer>
  );
};
