import {
  ScatterChart as RechartsScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ScatterProps,
  XAxisProps,
} from 'recharts';
import { useTheme } from 'styled-components';
import { BaseAxisProps, Margin } from 'recharts/types/util/types';

import { ChartFormatter } from '../../../../pages/PresentationCreate/dash/services/chartFormatter';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';

interface ScatterChartProps {
  data: ScatterProps['data'];
  xyAxisData?: ScatterProps['data'];
  dataKey?: string;
  customTooltip?: (props: any) => JSX.Element;
  moreInfo?: (id: string) => void;
  color?: string;
  xDataKey?: string;
  xLabel?: BaseAxisProps['label'];
  xTickFormatter?: XAxisProps['tickFormatter'];
  yDataKey?: string;
  yLabel?: BaseAxisProps['label'];
  id?: string;
  margin?: Margin;
  scatterDataSets: ScatterProps[];
}

export const ScatterChart = ({
  data,
  xyAxisData,
  dataKey,
  customTooltip,
  moreInfo = f => f,
  color,
  xDataKey,
  xLabel,
  xTickFormatter,
  yDataKey,
  yLabel,
  id,
  margin,
  scatterDataSets,
}: ScatterChartProps) => {
  const axisData = xyAxisData || data;
  const theme = useTheme();

  return (
    <ResponsiveContainer>
      <RechartsScatterChart data={data} margin={margin}>
        <CartesianGrid />
        <XAxis
          dataKey={xDataKey}
          type='number'
          padding={{ left: 20, right: 20 }}
          label={xLabel}
          domain={ChartFormatter.getDomain(axisData, xDataKey)}
          ticks={ChartFormatter.getTicks(axisData, xDataKey)}
          tickFormatter={xTickFormatter}
        />

        <YAxis
          type='number'
          domain={ChartFormatter.getDomain(axisData, yDataKey)}
          ticks={ChartFormatter.getTicks(axisData, yDataKey)}
          padding={{ bottom: 20 }}
          tickFormatter={number => MoneyFormatter.format(number)}
          dataKey={yDataKey}
          width={100}
          label={yLabel}
        />

        {customTooltip && typeof customTooltip === 'function' ? (
          <Tooltip content={customTooltip} />
        ) : (
          <Tooltip />
        )}

        <defs>
          <linearGradient id={id} x1='0' y1='0' x2='0' y2='1'>
            <stop offset='100%' stopColor={color || theme.colors.v2.primary} />
          </linearGradient>
        </defs>
        {scatterDataSets.map((scatterData, index) => {
          const { ref, ...scatterDataWithoutRef } = scatterData;

          return (
            <Scatter
              key={`${scatterData.name}_${index}`}
              dataKey={dataKey}
              onClick={data => moreInfo(data.id)}
              isAnimationActive={false}
              {...scatterDataWithoutRef}
            />
          );
        })}
      </RechartsScatterChart>
    </ResponsiveContainer>
  );
};
