import React, { ReactNode } from 'react';
import { IconName } from '../Icon/Icon.types';
import { InputEl, InputWrapper, Icon } from './Input.styles';

type IconProps = {
  iconName: IconName;
  fill?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
};

interface InputComponentProps {
  id?: string;
  type?: 'text' | 'number' | 'email' | 'date';
  value?: string;
  name?: string;
  placeholder?: string;
  inputStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  icon?: IconProps;
  disabled?: boolean;
  search?: boolean;
  width?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  inputId?: string;
  prefix?: ReactNode;
}

export const Input: React.FC<InputComponentProps> = ({
  icon,
  inputStyle,
  wrapperStyle,
  search,
  disabled,
  inputId,
  prefix,
  ...props
}) => {
  return (
    <InputWrapper style={wrapperStyle} search={search ? 1 : 0}>
      {prefix}
      <InputEl
        {...props}
        icon={icon ? 1 : 0}
        search={search ? 1 : 0}
        style={inputStyle}
        disabled={disabled}
        id={inputId}
      />
      {icon && <Icon {...icon} style={{ cursor: icon.onClick ? 'pointer' : 'auto' }} />}
    </InputWrapper>
  );
};
