import { corePresentApi } from './CorePresentApi';

export class UserApi {
  static async fetch(hash?: string) {
    const url = hash ? `/auth/me?hash=${hash}` : `/auth/me`;

    try {
      return await corePresentApi.get(url);
    } catch (e: any) {
      console.error(e);
    }
  }
}
