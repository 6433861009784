import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { sellingPaceAtom } from '../../state/sellingPaceAtom';

export function useSellingPaceData() {
  const data = useAtomValue(sellingPaceAtom);

  return useMemo(() => {
    const graph = data?.graph || [];

    return { ...data, graph };
  }, [data]);
}
