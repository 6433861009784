import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import styles from './PropertyInfoOverride.module.scss';
import { PropertyTypeSelector } from './PropertyTypeSelector/PropertyTypeSelector';
import { NextStepsFooter } from '../../components/NextStepsFooter/NextStepsFooter';
import { Input } from '../../../../../../../../../components/Common/V2/Input';
import { FEATURES, useHasFeatureFlag } from '../../../../../../../../../hooks/useFeatureFlags';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';

export const PropertyInfoOverride = ({ onPrevious, onContinue, loading, setLoading }) => {
  const searchCriteria = useSelector(({ search }) => search.searchCriteria);
  const [propertyType, setPropertyType] = useState('');
  const [error, setError] = useState(null);
  const [beds, setBeds] = useState(0);
  const [size, setSize] = useState(0);
  const [yearBuilt, setYearBuilt] = useState(0);
  const [baths, setBaths] = useState(0);
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  const { isCanadaUser } = useUser();

  useEffect(() => {
    setPropertyType(searchCriteria.propertyType || '');
    setBeds(isNaN(parseInt(searchCriteria.beds)) ? null : searchCriteria.beds);
    setSize(searchCriteria.size || 0);
    setYearBuilt(searchCriteria.yearBuilt || 0);
    setBaths(searchCriteria.baths || 0);
  }, []);

  const setBedsEnsureInt = beds => {
    setBeds(parseInt(beds));
  };
  const setBathsEnsureInt = baths => {
    setBaths(parseInt(baths));
  };
  const setSizeEnsureInt = size => {
    setSize(parseInt(size));
  };
  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    if (isNaN(parseInt(beds)) || !size) {
      setLoading(false);
      setError('Please enter all data.');
      return;
    }

    try {
      await onContinue({ beds, propertyType, size, yearBuilt, totalBaths: baths });
    } catch (e) {
      setLoading(false);
    }
  };

  const disableNext = beds < 0 || size < 0 || yearBuilt < 0 || isNaN(parseInt(beds)) || !size;
  const hasNegativeValues = beds < 0 || size < 0 || yearBuilt < 0 || baths < 0;

  return (
    <div>
      <h3 className={styles.title}>Confirm/Update subject property info</h3>
      <h4 className={styles.subtitle}>
        Information is provided by public records for the property and might not be
        current/accurate. Confirm and make adjustments as needed.
      </h4>

      <SectionWrapper>
        <GridWrapper hasBathCount={hasBathCountFF} isCanadaUser={isCanadaUser}>
          <PropertyTypeSelector propertyType={propertyType} onChange={setPropertyType} />

          <Input
            label='Bedroom Count'
            type='number'
            value={beds}
            placeholder='Please enter'
            onChange={e => setBedsEnsureInt(e.target.value)}
          />
          {hasBathCountFF && (
            <Input
              label='Total Bath Count:'
              type='number'
              value={baths}
              placeholder='Please enter'
              onChange={e => setBathsEnsureInt(e.target.value)}
            />
          )}
        </GridWrapper>

        <GridWrapper>
          <NumberFormat
            customInput={Input}
            label='Living Area Size (sqft)'
            value={size || undefined}
            placeholder='Please enter'
            onValueChange={({ floatValue }) => setSizeEnsureInt(floatValue)}
            thousandSeparator={true}
          />

          <Input
            label='Year Built'
            type='number'
            value={yearBuilt}
            onChange={e => setYearBuilt(e.target.value)}
          />
        </GridWrapper>
      </SectionWrapper>
      <span className={styles.errorText}>
        {hasNegativeValues ? 'Please enter positive numbers' : error}
      </span>
      <NextStepsFooter
        onPrev={onPrevious}
        onNext={onSubmit}
        loading={loading}
        className={styles.footer}
        disabled={disableNext}
      />
    </div>
  );
};

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ hasBathCount, isCanadaUser }) =>
    hasBathCount && !isCanadaUser ? '1fr 1fr 1fr' : '1fr 1fr'};
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
