import React, { useState, Fragment } from 'react';
import { Modal, Typography } from '../../../../components/Common';
import { useSelectedSlide } from '../../../../providers/providers/SelectedSlideProvider';
import { ModalContent, ExpandibleContainer, SwitchButtonWrapper } from './CreateSLideModal.styles';
import { THEMES } from '../../../../constants/themes';
import PreviewSlide from '../../../../components/PreviewSlide/PreviewSlide.component';
import { CreateSlideModalFooter } from './components/CreateSlideModalFooter/CreateSlideModalFooter.component';
import { CreateSlideModalHeader } from './components/CreateSlideModalHeader/CreateSlideModalHeader.component';
import { SlideLayoutExpandable } from './components/expandables/SlideLayoutExpandable/SlideLayoutExpandable.component';
import { usePreviewMode } from '../../../PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { useCreateSlideProps } from '../../providers/CreateSlidePropsProvider';
import { useUiConfigInfoPlacement } from '../../../../providers/providers/UiConfigInfoPlacementProvider';
import { useUiConfigTheme } from '../../../../providers/providers/UiConfigThemeProvider';
import { useParams } from 'react-router-dom';
import { TSection, TSlide, TTag } from '../../../../types';
import { TDynamicSlideDto } from '../../../../api/DynamicSlidesApi';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { useDynamicSlides } from '../../../../hooks/dynamicSlides/useDynamicSlides';
import { useActiveSection } from '../../../../components/Slide/providers/ActiveSectionProvider';
import { Switch } from '../../../../components/Common/V2/Switch';
import { useSlidePreviewWrappers } from '../../../../components/PreviewSlide/useSlidePreviewWrappers';

export const expandablesOpenState = {
  slideLayout: true,
  textFormatting: false,
  imageFormatting: false,
};

export interface CreateSlideExpandableCommon {
  open: boolean;
  onToggle: (key: keyof typeof expandablesOpenState) => void;
}

export type TModel = {
  entity: number | null;
  name: string;
  section: TSection;
  tags: TTag[];
};

const getInitSlideSection = (
  slide?: TSlide,
  dynamicSlides?: TDynamicSlideDto[],
  activeSection?: TSection,
): TSection => {
  const defaultSection = activeSection ?? 'whyIAmTheRightFit';
  if (!slide || !dynamicSlides) return defaultSection;

  const dynamicSlide = dynamicSlides?.find(ds => ds.slide.id === slide.id);

  return !dynamicSlide ? defaultSection : dynamicSlide.section;
};

export const CreateSlideModal: React.FC = () => {
  const { selectedSlide, setSlideFrame } = useSelectedSlide();
  const { previewMode: platform } = usePreviewMode();
  const { modalOpen, editing, closeModal } = useCreateSlideProps();

  const { infoPlacement } = useUiConfigInfoPlacement();
  const { theme } = useUiConfigTheme();
  const { hash: userHash } = useUser();

  const dynamicSlides = useDynamicSlides(userHash);

  const { activeSection } = useActiveSection();

  const [model, setModel] = useState<TModel>({
    entity: selectedSlide?.entityId ?? null,
    name: selectedSlide?.label ?? editing?.label ?? '',
    section: getInitSlideSection(
      selectedSlide ?? undefined,
      dynamicSlides.data,
      activeSection as TSection,
    ),
    tags: selectedSlide?.tags ?? [],
  });

  const { hash } = useParams<{ hash: string }>();

  const { OuterSlideWrapper, InnerSlideWrapper } = useSlidePreviewWrappers();

  const isPdfPresentationSlide = selectedSlide?.template === 'pdfPresentationTemplate';

  if (!modalOpen) return null;

  return (
    <Modal
      open={modalOpen}
      title='Create a Slide'
      onClose={closeModal}
      zIndex={100}
      contentStyles={{
        maxHeight: document.documentElement.clientHeight - 60,
        borderRadius: '0 0 8px 8px',
      }}
      isBlueHeader
    >
      <CreateSlideModalHeader model={model} setModel={setModel} />
      <ModalContent>
        <ExpandibleContainer>
          <SlideLayoutExpandable />

          {/*<TextFormattingExpandable onToggle={onToggle} open={open.textFormatting} />*/}
          {/*<ImageFormattingExpandable onToggle={onToggle} open={open.imageFormatting} />*/}
        </ExpandibleContainer>

        <OuterSlideWrapper>
          {isPdfPresentationSlide && platform === 'web' && (
            <SwitchButtonWrapper>
              <Typography variant='body2' color='400' style={{ marginRight: 10 }}>
                Toggle frame
              </Typography>
              <Switch
                onChange={() => setSlideFrame(!selectedSlide?.frame)}
                checked={selectedSlide?.frame}
              />
            </SwitchButtonWrapper>
          )}
          {selectedSlide && (
            <InnerSlideWrapper>
              <PreviewSlide
                isEditable
                platform={platform}
                slide={selectedSlide}
                theme={theme ?? THEMES.TRADITIONAL.DEFAULT}
                infoPlacement={infoPlacement}
                hideProgressTracker
                presentationHash={hash}
                style={{ boxShadow: ' 0px 2px 6px 0px rgba(0, 0, 0, 0.20)' }}
              />
            </InnerSlideWrapper>
          )}
        </OuterSlideWrapper>
      </ModalContent>
      <CreateSlideModalFooter model={model} onClose={closeModal} />
    </Modal>
  );
};
