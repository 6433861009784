import React from 'react';
import { DynamicSlidesDefaultConfigProvider } from '../../../../../../../../components/Slide/dynamic/DynamicSlidesDefaultConfigProvider';
import styled from 'styled-components';
import { useCreateSlideProps } from '../../../../../../providers/CreateSlidePropsProvider';
import { SectionTitle } from '../styles';
import { Button } from '../../../../../../../../components/Common/V2/Button/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 7px;
`;

export const Groups: React.FC = () => {
  const { selectGroup, activeGroup } = useCreateSlideProps();

  const groups = React.useMemo(() => {
    const { orderById, groups } = DynamicSlidesDefaultConfigProvider.getConfigs();
    return orderById.map(g => groups[g]);
  }, []);

  return (
    <Container>
      <SectionTitle>Photos</SectionTitle>
      <ButtonsWrapper>
        {groups.map((g, i) => (
          <Button
            key={g.id}
            variant={g.id === activeGroup ? 'primary' : 'secondary'}
            size='small'
            onClick={() => selectGroup(g.id)}
            style={{
              width: i === groups.length - 1 ? 'auto' : 32,
              textAlign: 'center',
              padding: i === groups.length - 1 ? '0 12px' : 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {g.label}
          </Button>
        ))}
      </ButtonsWrapper>
    </Container>
  );
};
