import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { PropertyGroups, TProperty } from '../../../../types';
import { usePropertiesForBigPicture } from './usePropertiesForBigPicture';
import { pricePerSqftModeAtom } from '../../state/pricePerSqftModeAtom';
import { BigPictureItem } from './BigPictureItem';
import { isAdjustedPriceModeAtom } from '../../state/isAdjustedPriceModeAtom';

interface IIndexPositions {
  from: number;
  to: number;
}

interface IPropertyGroup {
  priceRange: [number, number];
  properties: PropertyGroups;
  totalCount: number;
  averagePrice: number;
}

const generateGroup = (
  allProperties: TProperty[],
  indexPositions: IIndexPositions,
  pricePerSqftMode: boolean,
  isAdjustedPriceMode: boolean,
): IPropertyGroup => {
  const { from, to } = indexPositions;
  const totalCount = to - from + 1;
  const priceRange: [number, number] = [0, 0];

  const properties: PropertyGroups = {
    active: [],
    pending: [],
    sold: [],
    comingSoon: [],
  };

  let totalPrice = 0;

  for (let i = from; i <= to; i++) {
    if (i > to) break;
    const property = allProperties[i];
    const price = getPrice(property, pricePerSqftMode, isAdjustedPriceMode);
    if (i === from) priceRange[0] = price;
    if (i === to) priceRange[1] = price;
    totalPrice += price;
    properties?.[
      property.status === 'coming_soon' ? 'comingSoon' : (property.status as keyof PropertyGroups)
    ]?.push(property);
  }

  const averagePrice = Math.round(totalPrice / totalCount);

  return {
    priceRange,
    properties,
    totalCount,
    averagePrice,
  };
};

const getPrice = (
  property: TProperty,
  pricePerSqftMode: boolean,
  isAdjustedPriceMode: boolean,
): number => {
  const propToGet = pricePerSqftMode ? 'pricePerSqft' : 'price';
  const propToGetSold = pricePerSqftMode ? 'salePricePerSqft' : 'salePrice';
  const thePropToGet = property.status === 'sold' ? propToGetSold : propToGet;

  if (isAdjustedPriceMode && property?.adjustedPrice) {
    return pricePerSqftMode
      ? Math.round(property.adjustedPrice / property.size)
      : property.adjustedPrice;
  }

  return property[thePropToGet];
};

// Bad workaround, but this is reused in BuyerInterest Funnel...
export const groupProperties = (
  properties: TProperty[],
  pricePerSqftMode: boolean,
  isAdjustedPriceMode: boolean,
): [IPropertyGroup[], number, { lower: number; higher: number }] => {
  const propertiesCount = properties.length;

  properties.sort((a, b) => {
    const aPrice = getPrice(a, pricePerSqftMode, isAdjustedPriceMode);
    const bPrice = getPrice(b, pricePerSqftMode, isAdjustedPriceMode);
    return aPrice > bPrice ? 1 : bPrice > aPrice ? -1 : 0;
  });

  if (propertiesCount < 4) {
    return [
      [
        generateGroup(
          properties,
          { from: 0, to: propertiesCount - 1 },
          pricePerSqftMode,
          isAdjustedPriceMode,
        ),
      ],
      propertiesCount,
      { lower: 100, higher: 100 },
    ];
  }

  let bigMama = Math.floor(propertiesCount * 0.6);
  let rest = propertiesCount - bigMama;

  if (rest % 2 !== 0) {
    bigMama = Math.ceil(propertiesCount * 0.6);
    rest = propertiesCount - bigMama;
  }

  const smallMama = rest / 2;

  const indexing_1 = { from: 0, to: 0 + smallMama - 1 };
  const indexing_2 = {
    from: indexing_1.to + 1,
    to: indexing_1.to + 1 + bigMama,
  };
  const indexing_3 = { from: indexing_2.to + 1, to: propertiesCount - 1 };

  const groups = [indexing_1, indexing_2, indexing_3].map(indexPositions =>
    generateGroup(properties, indexPositions, pricePerSqftMode, isAdjustedPriceMode),
  );

  const maxTotalCount = Math.max(...groups.map(gr => gr.totalCount));

  const lowerPercentage = Math.floor((100 / propertiesCount) * smallMama);
  const higherPercentage = 100 - lowerPercentage * 2;
  const percentages = { lower: lowerPercentage, higher: higherPercentage };

  return [groups, maxTotalCount, percentages];
};

export const BigPicture: React.FC = () => {
  const properties = usePropertiesForBigPicture();
  const pricePerSqftMode = useAtomValue(pricePerSqftModeAtom);
  const isAdjustedPriceMode = useAtomValue(isAdjustedPriceModeAtom);

  const [groups, maxTotalCount, { lower, higher }] = useMemo(
    () => groupProperties(properties, pricePerSqftMode, isAdjustedPriceMode),
    [properties, pricePerSqftMode, isAdjustedPriceMode],
  );

  return (
    <Container>
      {groups.map(({ priceRange, properties }, i) => (
        <BigPictureItem
          key={i}
          priceRange={priceRange}
          properties={properties}
          maxTotalCount={maxTotalCount}
          percent={`${i === 1 ? higher : lower}%`}
          order={i + 1}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
