import styled from 'styled-components';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')<WithPdf>(({ theme, isPdf }) => ({
  display: 'flex',
  height: isPdf ? '84%' : '100%',
  justifyContent: 'space-between',
  padding: isPdf ? 0 : '2%',
}));

export const LeftCol = styled('div')<WithPdf>(({ isPdf }) => ({
  width: isPdf ? '43%' : '54%',
  display: 'flex',
  flexDirection: 'column',
}));

export const RightCol = styled('div')<WithPdf>(({ isPdf }) => ({
  width: isPdf ? '52%' : '44%',
  overflow: 'hidden',
}));

export const ImageWrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  width: '100%',
  height: '48.5%',
  '&:first-child': {
    marginBottom: 'auto',
  },
}));

export const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'center',
});
