import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';

export const TextAlphaPdf: React.FC = () => {
  const slide = useSlide();
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SlideTextBlock isEditable={false} text={slide.data?.text} headline={slide.data?.headline} />
    </PdfFrame>
  );
};
