import * as creators from './actions';

export const setOnStep = step => creators.setOnStep(step);

export const setAddress = address => creators.setAddress(address);
export const setSearchCriteria = searchCriteria => creators.setSearchCriteria(searchCriteria);
export const setClients = clients => creators.setClients(clients);

export const setApi = api => creators.setApi(api);

export const resetSearch = () => dispatch => {
  dispatch(setOnStep(1));
  dispatch(setAddress({}));
  dispatch(setSearchCriteria({}));
  dispatch(setClients({}));
  dispatch(setApi({}));
};
