import React from 'react';
import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

import { Container, LeftCol, RightCol, ImageWrapper } from './TwoImagesAlphaLayout.styles';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';

interface Props {
  reverse?: boolean;
  vertical?: boolean;
}

const TwoImagesAlphaForm: React.FC<Props> = ({ reverse, vertical }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  const onChangeText = (value: string) => {
    setSlideDataProperty('text', value);
  };

  if (!selectedSlide) return null;

  const styles: React.CSSProperties = {};

  if (vertical) {
    styles.width = '100%';
    styles.flex = 1;
    styles.flexDirection = 'row';
  }
  let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'row';
  if (vertical) flexDirection = 'column';
  if (vertical && reverse) flexDirection = 'column-reverse';
  if (!vertical && reverse) flexDirection = 'row-reverse';

  return (
    <Container
      style={{
        flexDirection,
      }}
    >
      <LeftCol
        style={{ ...styles, marginBottom: reverse ? 0 : 48, height: !vertical ? '100%' : '47%' }}
      >
        <ImageWrapper
          style={{ height: !vertical ? '48%' : '100%', marginRight: vertical ? 32 : 0 }}
        >
          <UploadImage
            slideDataConfigPropertyName={'image'}
            image={selectedSlide.data?.image}
            imageSizeSuggestion={vertical ? '736x321' : '812x328'}
          />
        </ImageWrapper>
        <ImageWrapper style={{ height: !vertical ? '48%' : '100%' }}>
          <UploadImage
            slideDataConfigPropertyName={'image2'}
            image={selectedSlide.data?.image2}
            imageSizeSuggestion={vertical ? '736x321' : '812x328'}
          />
        </ImageWrapper>
      </LeftCol>
      <RightCol
        style={{ ...styles, marginBottom: !reverse ? 0 : 48, height: !vertical ? '100%' : '48%' }}
      >
        <SlideTextBlock
          headline={selectedSlide.data?.headline}
          text={selectedSlide.data?.text}
          isEditable
          onChangeText={onChangeText}
          onChangeHeadline={onChangeHeadline}
        />
      </RightCol>
    </Container>
  );
};

export default TwoImagesAlphaForm;
