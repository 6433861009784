import { useLocation } from 'react-router-dom';
import React from 'react';

export function useUrlSearchParams() {
  const { search } = useLocation();

  return React.useMemo(
    function memoizedQueryParams() {
      return new URLSearchParams(search);
    },
    [search],
  );
}

export function useUrlSearchParam(param: string) {
  const params = useUrlSearchParams();

  return params.get(param);
}
