import React, { useEffect, useMemo } from 'react';

import {
  PresentationTypeProvider,
  usePresentationType,
} from '../../providers/providers/PresentationTypeProvider';
import {
  PresentationModeProvider,
  usePresentationMode,
} from '../../providers/providers/PresentationModeProvider';
import { withProviders } from '../../hoc/withProviders';
import { TPresentation, TSuperPresentation } from '../../types';
import { PRESENTATION_TYPES } from '../../constants/PresentationTypes';
import { DashboardItems } from './DashboardItems/DashboardItems.component';
import { DashboardContent } from '../../layouts/Dashboard/DashboardContent';
import { UiConfigProvider } from '../../providers/providers/UiConfigProvider';
import { CategoryProvider } from '../../providers/providers/CategoryProvider';
import { CoreSlidesConfigProvider } from '../../providers/providers/CoreSlidesConfigProvider';
import { UiConfigInfoPlacementProvider } from '../../providers/providers/UiConfigInfoPlacementProvider';
import { config as coverSlide } from '../../components/Slide/slides/startingSlides/Cover';
import { CreatedAtProvider, useCreatedAt } from '../../providers/providers/CreatedAtProvider';
import { SelectedSlideProvider } from '../../providers/providers/SelectedSlideProvider';
import { useSetPresentationToState } from '../../features/report/hooks/useSetPresentationToState';

interface DashboardProps {
  presentation: TSuperPresentation;
}

export const Dashboard: React.FC<DashboardProps> = withProviders<DashboardProps>(
  PresentationModeProvider,
  PresentationTypeProvider,
  UiConfigInfoPlacementProvider,
  CreatedAtProvider,
  SelectedSlideProvider,
)(({ presentation }) => {
  const { setPresentationType } = usePresentationType();
  const { presentationMode, setPresentationMode } = usePresentationMode();
  const { setCreatedAt } = useCreatedAt();

  useSetPresentationToState(presentation);

  useEffect(() => {
    setPresentationMode((presentation as any)?.mode ?? 'regular');
    setPresentationType(presentation?.type ?? PRESENTATION_TYPES.WIN_THE_LISTING);
    setCreatedAt(presentation.createdAt as number);
  }, [presentation, setCreatedAt, setPresentationMode, setPresentationType]);

  const previewCoverSlide = useMemo(
    () => (presentation as TPresentation)?.slidesMap?.['coverSlide'] || coverSlide,
    [presentation],
  );

  if (!presentationMode || !presentation?.hash) return null;

  return (
    <CategoryProvider initValue={presentation?.category}>
      <UiConfigProvider initValue={presentation?.ui}>
        <CoreSlidesConfigProvider presentationMode={presentationMode}>
          <DashboardContent presentationHash={presentation.hash} previewSlide={previewCoverSlide}>
            <DashboardItems presentationData={presentation as TPresentation} />
          </DashboardContent>
        </CoreSlidesConfigProvider>
      </UiConfigProvider>
    </CategoryProvider>
  );
});
