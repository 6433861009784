export const calculateRangeHighlightsHeight = (markers: any, markerBaseHeight: number) => {
  let height = 0;
  for (let i = markers.length - 1; i >= 0; i--) {
    height += markerBaseHeight + 10;
  }

  return height;
};

export const calculatePdfHeight = (
  numberOfSelected: number,
  markerBaseHeight: number,
  selectionsLength: number,
) => {
  switch (true) {
    case numberOfSelected > 20:
      return selectionsLength * (markerBaseHeight - 17) + 60;
    case numberOfSelected > 15:
      return selectionsLength * (markerBaseHeight - 8) + 60;
    default:
      return selectionsLength * markerBaseHeight + 60;
  }
};

export const calculateMarkerHeight = (position = 0, markerBaseHeight: number) => {
  let height = 0;
  for (let i = 0; i <= position; i++) {
    height += markerBaseHeight + 10;
  }

  return height;
};
