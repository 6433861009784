import { textTemplateConfigs } from './templates/textLayout';
import { TDynamicSlideConfigs, TTemplateGroup, TSingleTemplateGroup } from './types';
import { TDynamicSlideTemplate, TSlide } from '../../../types';
import { oneImageTemplateConfigs } from './templates/oneImageLayout';
import { twoImagesTemplateConfigs } from './templates/twoImagesLayout';
import { threeImagesTemplateConfigs } from './templates/threeImagesLayout';
import { fourImagesTemplateConfigs } from './templates/fourImagesLayout';
import { config as pdfPresentationConfig } from './templates/pdfPresentationLayout';
import { config as singleVideoConfig } from './templates/singleVideoLayout/singleVideo';
import { config as singleImageTemplateConfig } from './templates/singleImageLayout/singleImage';
import { config as singleFramelessVideoConfig } from './templates/singleVideoLayout/singleFramelessVideo';
import { config as singleImageWithFrameTemplateConfig } from './templates/singleImageLayout/singleImageWithFrame';
import * as singleImage from './templates/singleImageLayout/singleImage';
import * as singleImageWithFrame from './templates/singleImageLayout/singleImageWithFrame';
import * as singleFramelessVideo from './templates/singleVideoLayout/singleFramelessVideo';
import * as singleVideo from './templates/singleVideoLayout/singleVideo';
import * as pdfPresentation from './templates/pdfPresentationLayout';

type TTemplateGroupConfigs = {
  id: TTemplateGroup;
  label: string;
  templates: TDynamicSlideConfigs[];
};

type TSingleTemplateGroupConfig = {
  id: string;
  label: string;
  description: string;
  boldTrailDescription?: string;
  info?: string | boolean;
  templates: TDynamicSlideConfigs[];
};

type TConfigs = {
  orderById: TTemplateGroup[];
  groups: Record<TTemplateGroup, TTemplateGroupConfigs>;
  singleTemplate: {
    orderById: TSingleTemplateGroup[];
    groups: Record<TSingleTemplateGroup, TSingleTemplateGroupConfig>;
  };
};

export class DynamicSlidesDefaultConfigProvider {
  static getConfigs(): TConfigs {
    return {
      orderById: ['oneImage', 'twoImages', 'threeImages', 'fourImages', 'textOnly'],
      groups: {
        oneImage: {
          id: 'oneImage',
          label: '1',
          templates: [
            oneImageTemplateConfigs.oneImageLayoutAlpha,
            oneImageTemplateConfigs.oneImageLayoutBeta,
            oneImageTemplateConfigs.oneImageLayoutGamma,
            oneImageTemplateConfigs.oneImageLayoutDelta,
            oneImageTemplateConfigs.oneImageLayoutEpsilon,
            oneImageTemplateConfigs.oneImageLayoutZeta,
          ],
        },
        twoImages: {
          id: 'twoImages',
          label: '2',
          templates: [
            twoImagesTemplateConfigs.twoImagesLayoutAlpha,
            twoImagesTemplateConfigs.twoImagesLayoutBeta,
            twoImagesTemplateConfigs.twoImagesLayoutGamma,
            twoImagesTemplateConfigs.twoImagesLayoutDelta,
            twoImagesTemplateConfigs.twoImagesLayoutEpsilon,
            twoImagesTemplateConfigs.twoImagesLayoutZeta,
          ],
        },
        threeImages: {
          id: 'threeImages',
          label: '3',
          templates: [
            threeImagesTemplateConfigs.threeImagesLayoutAlpha,
            threeImagesTemplateConfigs.threeImagesLayoutBeta,
            threeImagesTemplateConfigs.threeImagesLayoutGamma,
            threeImagesTemplateConfigs.threeImagesLayoutDelta,
            threeImagesTemplateConfigs.threeImagesLayoutEpsilon,
            threeImagesTemplateConfigs.threeImagesLayoutZeta,
          ],
        },
        fourImages: {
          id: 'fourImages',
          label: '4',
          templates: [
            fourImagesTemplateConfigs.fourImagesLayoutAlpha,
            fourImagesTemplateConfigs.fourImagesLayoutBeta,
            fourImagesTemplateConfigs.fourImagesLayoutGamma,
            fourImagesTemplateConfigs.fourImagesLayoutDelta,
            fourImagesTemplateConfigs.fourImagesLayoutEpsilon,
            fourImagesTemplateConfigs.fourImagesLayoutZeta,
          ],
        },
        textOnly: {
          id: 'textOnly',
          label: 'Text Only',
          templates: [
            textTemplateConfigs.textLayoutAlpha,
            textTemplateConfigs.textLayoutBeta,
            textTemplateConfigs.textLayoutGamma,
            textTemplateConfigs.textLayoutDelta,
            textTemplateConfigs.textLayoutEpsilon,
            textTemplateConfigs.textLayoutZeta,
          ],
        },
      },
      singleTemplate: {
        orderById: ['imageSlide', 'videoSlide', 'pdfSlide'],
        groups: {
          imageSlide: {
            id: 'imageSlide',
            label: 'Add a single slide',
            description: 'Individual slides can be uploaded as image files',
            info: true,
            templates: [singleImage, singleImageWithFrame],
          },
          videoSlide: {
            id: 'videoSlide',
            label: 'Add a video slide',
            description: 'Videos can be added to web presentations',
            templates: [singleFramelessVideo, singleVideo],
          },
          pdfSlide: {
            id: 'pdfSlide',
            label: 'Add a full presentation',
            boldTrailDescription: `PDFs can be uploaded to keep all slides together in the same order at the start of a presentation`,
            description: `PDFs can be uploaded to keep all slides together in the same order at the start of a CORE Presentation`,
            templates: [pdfPresentation],
          },
        },
      },
    };
  }

  static getTemplateConfig(template?: TDynamicSlideTemplate): TSlide {
    const defaultTemplate = oneImageTemplateConfigs.oneImageLayoutAlpha.config;
    if (!template) return defaultTemplate;

    const templateConfigsMap: Record<TDynamicSlideTemplate, TSlide> = {
      oneImageLayoutAlpha: oneImageTemplateConfigs.oneImageLayoutAlpha.config,
      oneImageLayoutBeta: oneImageTemplateConfigs.oneImageLayoutBeta.config,
      oneImageLayoutGamma: oneImageTemplateConfigs.oneImageLayoutGamma.config,
      oneImageLayoutDelta: oneImageTemplateConfigs.oneImageLayoutDelta.config,
      oneImageLayoutEpsilon: oneImageTemplateConfigs.oneImageLayoutEpsilon.config,
      oneImageLayoutZeta: oneImageTemplateConfigs.oneImageLayoutZeta.config,
      twoImagesLayoutAlpha: twoImagesTemplateConfigs.twoImagesLayoutAlpha.config,
      twoImagesLayoutBeta: twoImagesTemplateConfigs.twoImagesLayoutBeta.config,
      twoImagesLayoutGamma: twoImagesTemplateConfigs.twoImagesLayoutGamma.config,
      twoImagesLayoutDelta: twoImagesTemplateConfigs.twoImagesLayoutDelta.config,
      twoImagesLayoutEpsilon: twoImagesTemplateConfigs.twoImagesLayoutEpsilon.config,
      twoImagesLayoutZeta: twoImagesTemplateConfigs.twoImagesLayoutZeta.config,
      threeImagesLayoutAlpha: threeImagesTemplateConfigs.threeImagesLayoutAlpha.config,
      threeImagesLayoutBeta: threeImagesTemplateConfigs.threeImagesLayoutBeta.config,
      threeImagesLayoutGamma: threeImagesTemplateConfigs.threeImagesLayoutGamma.config,
      threeImagesLayoutDelta: threeImagesTemplateConfigs.threeImagesLayoutDelta.config,
      threeImagesLayoutEpsilon: threeImagesTemplateConfigs.threeImagesLayoutEpsilon.config,
      threeImagesLayoutZeta: threeImagesTemplateConfigs.threeImagesLayoutZeta.config,
      fourImagesLayoutAlpha: fourImagesTemplateConfigs.fourImagesLayoutAlpha.config,
      fourImagesLayoutBeta: fourImagesTemplateConfigs.fourImagesLayoutBeta.config,
      fourImagesLayoutGamma: fourImagesTemplateConfigs.fourImagesLayoutGamma.config,
      fourImagesLayoutDelta: fourImagesTemplateConfigs.fourImagesLayoutDelta.config,
      fourImagesLayoutEpsilon: fourImagesTemplateConfigs.fourImagesLayoutEpsilon.config,
      fourImagesLayoutZeta: fourImagesTemplateConfigs.fourImagesLayoutZeta.config,
      textLayoutAlpha: textTemplateConfigs.textLayoutAlpha.config,
      textLayoutBeta: textTemplateConfigs.textLayoutBeta.config,
      textLayoutGamma: textTemplateConfigs.textLayoutGamma.config,
      textLayoutDelta: textTemplateConfigs.textLayoutDelta.config,
      textLayoutEpsilon: textTemplateConfigs.textLayoutEpsilon.config,
      textLayoutZeta: textTemplateConfigs.textLayoutZeta.config,
      singleImageTemplate: singleImageTemplateConfig,
      singleImageWithFrameTemplate: singleImageWithFrameTemplateConfig,
      singleFramelessVideo: singleFramelessVideoConfig,
      singleVideo: singleVideoConfig,
      pdfPresentationTemplate: pdfPresentationConfig,
    };

    return templateConfigsMap[template] ?? defaultTemplate;
  }

  static mergeWithEditingSlideData = (slide: TSlide, template?: TDynamicSlideTemplate) => {
    const data: TSlide['data'] = {};
    const templateConfig = DynamicSlidesDefaultConfigProvider.getTemplateConfig(template);

    data.headline = slide.data?.headline ?? '';
    data.headline2 = slide.data?.headline2 ?? '';
    data.headline3 = slide.data?.headline3 ?? '';

    data.text = slide.data?.text ?? '';
    data.text2 = slide.data?.text2 ?? '';
    data.text3 = slide.data?.text3 ?? '';

    data.image = slide.data?.image ?? '';
    data.image2 = slide.data?.image2 ?? '';
    data.image3 = slide.data?.image3 ?? '';
    data.image4 = slide.data?.image4 ?? '';

    data.videoUrl = slide.data?.videoUrl ?? '';

    return { ...templateConfig, id: slide.id, data };
  };

  static getAllTemplateConfigs() {
    const configs = DynamicSlidesDefaultConfigProvider.getConfigs();
    return configs.orderById.flatMap(groupId =>
      configs.groups[groupId].templates.map(t => t.config),
    );
  }

  static getAllTemplates() {
    return DynamicSlidesDefaultConfigProvider.getAllTemplateConfigs().map(tc => tc.template);
  }
}
