import { PresentLogo } from '../../../components/PresentLogo/PresentLogo';
import { Container } from './Header.styles';

export const Header = ({ text = '' }) => {
  return (
    <Container>
      <PresentLogo style={{ width: 170 }} /> {text}
    </Container>
  );
};
