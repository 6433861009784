import styled from 'styled-components';

import { useEntity } from '../../../../providers/EntityProvider';

import { AccountOfficeDropdown } from '../../../../../../components/Common/AccountOfficeDropdown/AccountOfficeDropdown.component';

export function AccountOfficesDropdowns() {
  const { entity, setEntity, setEntityLabel } = useEntity();

  return (
    <Container>
      <DropdownTitle>CUSTOMIZED FOR:</DropdownTitle>
      <AccountOfficeDropdown
        value={entity}
        onChange={setEntity}
        setSelectedOptionLabel={setEntityLabel}
        hidePresentationType
      />
    </Container>
  );
}

const DropdownTitle = styled.p`
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: 100%;
`;
