import { useQuery } from '@tanstack/react-query';

import { TSlideId } from '../types';
import { SlideApi, TSlideDummyData } from '../components/Slide/api/SlideApi';

export const useLoadSlideData = (slideId: TSlideId) => {
  const query = useQuery<TSlideDummyData, Error>(
    ['slideData', slideId],
    () => SlideApi.fetchSlideData(slideId),
    { refetchOnWindowFocus: false },
  );

  return query;
};
