import React from 'react';
import { ITestimonial } from '../../../../../../../../types';
import parse from 'html-react-parser';
import {
  TestimonialAuthor,
  TestimonialContainer,
  TestimonialText,
} from './TestimonialContent.styles';

interface TestimonialProps {
  testimonial: ITestimonial;
}

export const TestimonialContent: React.FC<TestimonialProps> = ({ testimonial }) => {
  const { text, client, isHidden } = testimonial;
  if (isHidden || !text) return null;
  return (
    <TestimonialContainer>
      <TestimonialText>{parse(text)}</TestimonialText>
      <TestimonialAuthor>{client}</TestimonialAuthor>
    </TestimonialContainer>
  );
};
