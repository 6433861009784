import { TCompConfigItem } from '../../../../../types';
import { FeatureGroup } from '../components/FeatureGroup';
import { Section } from '../components/Section';
import { FEATURES } from '../featuresConfig';
import { FEATURES_KEYS } from '../types';

export const InteriorFeatures = () => {
  return (
    <Section title='INTERIOR:'>
      <FeatureGroup
        title='BATHROOMS'
        fields={
          [
            FEATURES[FEATURES_KEYS.BATHROOM_MINUS_TWO],
            FEATURES[FEATURES_KEYS.BATHROOM_MINUS_ONE],
            FEATURES[FEATURES_KEYS.BATHROOM_SAME],
            FEATURES[FEATURES_KEYS.BATHROOM_ONE],
            FEATURES[FEATURES_KEYS.BATHROOM_TWO],
            FEATURES[FEATURES_KEYS.BATHROOM_THREE],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='LEVELS'
        fields={
          [
            FEATURES[FEATURES_KEYS.LEVELS_ONE],
            FEATURES[FEATURES_KEYS.LEVELS_TWO],
            FEATURES[FEATURES_KEYS.LEVELS_THREE],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='MASTER ON MAIN'
        fields={
          [
            FEATURES[FEATURES_KEYS.MASTER_MAIN_YES],
            FEATURES[FEATURES_KEYS.MASTER_MAIN_NO],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='AIR CONDITIONING'
        fields={
          [
            FEATURES[FEATURES_KEYS.AIR_CONDITIONING_YES],
            FEATURES[FEATURES_KEYS.AIR_CONDITIONING_NO],
          ] as TCompConfigItem[]
        }
      />
    </Section>
  );
};
