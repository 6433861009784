import styled from 'styled-components';
import { RadiusSelect } from './RadiusSelect';
import { AreaSelect } from './AreaSelect';
import { DrawControl } from './DrawControl';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';

const ControlsContainer = styled.div`
  position: absolute;
  top: 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0px 0px 10px 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`;

const OrText = styled.p`
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

interface Props {
  polygon: google.maps.Polygon | null;
  setPolygon: (polygon: google.maps.Polygon | null) => void;
  setDrawingMode: (mode: google.maps.drawing.OverlayType) => void;
}

export const MapControls = ({ polygon, setPolygon, setDrawingMode }: Props) => {
  const user = useUser();

  return (
    <ControlsContainer style={{ width: user?.hasValidWebsite ? '64%' : 'auto' }}>
      <RadiusSelect />
      <OrText>OR</OrText>
      {user?.hasValidWebsite && (
        <>
          <AreaSelect />
          <OrText>OR</OrText>
        </>
      )}
      <DrawControl polygon={polygon} setPolygon={setPolygon} setDrawingMode={setDrawingMode} />
    </ControlsContainer>
  );
};
