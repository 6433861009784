import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { TextZeta } from './TextZeta';
import { TextZetaForm } from './TextZetaForm';
import { TextZetaPdf } from './TextZetaPdf';

import thumbnail from './thumbnail.png';

const template = 'textLayoutZeta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Text Only',
  frame: true,
  data: {
    text: '',
    text2: '',
    text3: '',
    headline: '',
    headline2: '',
    headline3: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TextZetaForm,
  web: TextZeta,
  pdf: TextZetaPdf,
};

export type TTextLayoutZeta = typeof template;
export { config, mappings, thumbnail };
