const ILLUSTRATED_PROPERTIES = 609;
const THE_KEYES_COMPANY = 360;
const THE_KEYES_COMPANY_2 = 611;
const DILBECK_REAL_ESTATE = 2758;
const FRESH_LOOK_REAL_ESTATE = 3501;
const NEXT_HOME = 4352;
const EQUITY_UNION = 4962;

export const ACCOUNT_IDS = {
  ILLUSTRATED_PROPERTIES,
  THE_KEYES_COMPANY,
  THE_KEYES_COMPANY_2,
  DILBECK_REAL_ESTATE,
  FRESH_LOOK_REAL_ESTATE,
  NEXT_HOME,
  EQUITY_UNION,
  REMAX: {
    remaxcorporate6207: 6207,
    remaxcorporatecanada6389: 6389,
  } as Record<string, number>,
} as const;
