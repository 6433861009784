import React from 'react';
import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

import {
  Container,
  LeftCol,
  RightCol,
  ImageWrapper,
  ImagePreview,
} from './TwoImagesAlphaLayout.styles';
import { useSlide } from '../../../../providers/SlideProvider';

interface Props {
  reverse?: boolean;
  vertical?: boolean;
}

const TwoImagesAlpha: React.FC<Props> = ({ reverse, vertical }) => {
  const slide = useSlide();
  const styles: React.CSSProperties = {};

  if (vertical) {
    styles.width = '100%';
    styles.flex = 1;
    styles.flexDirection = 'row';
  }

  let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'row';
  if (vertical) flexDirection = 'column';
  if (vertical && reverse) flexDirection = 'column-reverse';
  if (!vertical && reverse) flexDirection = 'row-reverse';

  return (
    <Container
      style={{
        flexDirection,
      }}
    >
      <LeftCol
        style={{ ...styles, marginBottom: reverse ? 0 : 40, height: !vertical ? '100%' : '48%' }}
      >
        <ImageWrapper
          style={{ height: !vertical ? '48%' : '100%', marginRight: vertical ? 32 : 0 }}
        >
          <ImagePreview src={slide.data?.image} />
        </ImageWrapper>
        <ImageWrapper style={{ height: !vertical ? '48%' : '100%' }}>
          <ImagePreview src={slide.data?.image2} />
        </ImageWrapper>
      </LeftCol>
      <RightCol
        style={{ ...styles, marginBottom: !reverse ? 0 : 40, height: !vertical ? '100%' : '48%' }}
      >
        <SlideTextBlock
          headline={slide.data?.headline}
          text={slide.data?.text}
          isEditable={false}
        />
      </RightCol>
    </Container>
  );
};

export default TwoImagesAlpha;
