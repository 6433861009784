import { PropertyGroups, TProperty } from '../../../types';
import { getPropertyId } from './getPropertyId';

export const getPropertiesNotes = (properties: PropertyGroups) => {
  const presentationProperties = Object.values((properties || {}) as PropertyGroups)
    ?.flatMap(group => group)
    .filter(Boolean) as TProperty[];

  const notes = presentationProperties.map((property: TProperty) => ({
    property_id: getPropertyId(property),
    note: property.note,
  }));

  return notes;
};
