import React, { createContext, useContext, useState } from 'react';

import { TSlideFonts } from '../../types';

type FontsSetterFunc = (value: TSlideFonts) => TSlideFonts;

type FontsContextType = {
  fonts: TSlideFonts;
  setFonts: (value: TSlideFonts | FontsSetterFunc) => void;
};

const UiFontsContext = createContext<FontsContextType | undefined>(undefined);

export const useUiConfigFonts = () => {
  const context = useContext(UiFontsContext);

  if (!context) throw new Error('useUiConfigFonts cannot be used without UiFontsContext');

  return context;
};

export const useUiConfigFontsHeader = () => {
  const context = useContext(UiFontsContext);

  if (!context) throw new Error('useUiConfigFonts cannot be used without UiFontsContext');

  return context.fonts?.header?.value;
};

export const useUiConfigFontsBody = () => {
  const context = useContext(UiFontsContext);

  if (!context) throw new Error('useUiConfigFonts cannot be used without UiFontsContext');

  return context.fonts?.body?.value;
};

export const useUiConfigHeaderLetterSpacing = (initialValue = 'normal') => {
  const context = useContext(UiFontsContext);

  if (!context) throw new Error('useUiConfigFonts cannot be used without UiFontsContext');

  if (
    context.fonts.header.value === 'Akzidenz Grotesk Light' ||
    context.fonts.header.value === 'Akzidenz Grotesk Regular'
  ) {
    return '2px';
  }

  return initialValue;
};

export const useUiConfigBodyLetterSpacing = (initialValue = 'normal') => {
  const context = useContext(UiFontsContext);

  if (!context) throw new Error('useUiConfigFonts cannot be used without UiFontsContext');

  if (
    context.fonts.body.value === 'Akzidenz Grotesk Light' ||
    context.fonts.body.value === 'Akzidenz Grotesk Regular'
  ) {
    return '2px';
  }

  return initialValue;
};

const getValidInitFonts = (initValue: any) => {
  if (!initValue?.header?.value) {
    return {
      header: {
        value: 'Arial',
      },
      body: {
        value: 'Arial',
      },
    };
  }

  return initValue;
};

interface Props {
  initValue?: TSlideFonts;
}
export const UiConfigFontsProvider: React.FC<Props> = ({ initValue, children }) => {
  const [fonts, setFonts] = useState<TSlideFonts>(getValidInitFonts(initValue));

  const value = React.useMemo(() => ({ fonts, setFonts }), [fonts]);

  return <UiFontsContext.Provider value={value}>{children}</UiFontsContext.Provider>;
};
