import React from 'react';

import { ModalFooter } from './CreateSlideModalFooter.styles';
import { TSection } from '../../../../../../types';
import Loader from '../../../../../../components/Loader/Loader';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { useCreateSlideProps } from '../../../../providers/CreateSlidePropsProvider';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { useCreateDynamicSlide } from '../../../../../../hooks/dynamicSlides/useCreateDynamicSlide';
import { useUpdateDynamicSlide } from '../../../../../../hooks/dynamicSlides/useUpdateDynamicSlide';
import { usePreviewMode } from '../../../../../PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { TModel } from '../../CreateSlideModal.component';
import { Button } from '../../../../../../components/Common/V2/Button/Button';
import { useTheme } from 'styled-components';

interface Props {
  model: TModel;
  onClose: () => void;
}

export const CreateSlideModalFooter: React.FC<Props> = ({ model, onClose }) => {
  const { selectedSlide } = useSelectedSlide();
  const { closeModal, editing } = useCreateSlideProps();
  const { setPreviewMode, previewMode } = usePreviewMode();

  const { hash } = useUser();

  const { colors } = useTheme();

  const { mutate: createSlide, isLoading } = useCreateDynamicSlide(hash, model?.entity);
  const { mutate: editSlide, isLoading: editIsLoading } = useUpdateDynamicSlide(
    hash,
    model?.entity,
  );

  const onSave = React.useCallback(() => {
    if (!selectedSlide || !model.section) return;
    if (!model.name) return alert('Slide must have a name');

    if (editing) {
      editSlide(
        {
          section: model.section,
          slide: { ...selectedSlide, label: model.name, id: editing.id, tags: model.tags },
          entityId: model.entity,
        },
        {
          onSuccess: closeModal,
        },
      );
    } else {
      createSlide(
        {
          section: model.section as TSection,
          slide: { ...selectedSlide, label: model.name, tags: model.tags },
          entityId: model.entity,
        },
        {
          onSuccess: closeModal,
        },
      );
    }
  }, [
    selectedSlide,
    model.section,
    model.name,
    model.tags,
    model.entity,
    editing,
    editSlide,
    closeModal,
    createSlide,
  ]);

  return (
    <ModalFooter>
      <Button onClick={onClose} variant='secondary'>
        Cancel
      </Button>
      <Button onClick={onSave} variant='primary' isLoading={isLoading || editIsLoading}>
        {!editing ? 'Save & Add to Library' : 'Save changes'}
      </Button>
    </ModalFooter>
  );
};
