import styled from 'styled-components';
import { WebAspectRatioBox } from '../Slide/WebAspectRatioBox';
import { usePreviewMode } from '../../pages/PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';

export function useSlidePreviewWrappers() {
  const { previewMode } = usePreviewMode();

  const OuterSlideWrapper = previewMode === 'web' ? SlideWrapper : PdfWrapper;
  const InnerSlideWrapper = previewMode === 'web' ? WebAspectRatioBox : PdfInsideWrapper;

  return { OuterSlideWrapper, InnerSlideWrapper };
}

export const SlideWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  justifyContent: 'center',
});

export const PdfWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: 'calc(100vw - 338px)',
  maxHeight: 'calc(100vh - 300px)',
  minHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'flex',
    background: theme.colors.v2.gray[200],
    width: 6,
    paddingRight: 2,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.colors.v2.gray[400],
    borderRadius: 100,
    width: 4,
  },
}));

export const PdfInsideWrapper = styled('div')({
  width: '50%',
});
