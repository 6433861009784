import React from 'react';
import { TSlide, TSlideTheme } from '../../../../types';
import { SlideItem } from './SlideItem/SlideItem.component';
import {
  Container,
  CarouselContainer,
  StyledCarousel,
  PrevButton,
  NextButton,
  SingleSlideItemWrapper,
  NoSlidesMessageContainer,
} from './SlidesCarousel.styles';

import { useCarouselControls } from '../providers/CarouselControlsProvider';

export type SlideWithTheme = TSlide & { theme: TSlideTheme };

export const SlidesCarousel: React.FC = () => {
  const { activeSlides, selectedSlideIndex, isLastSlide, onCarouselSlideChange } =
    useCarouselControls();

  if (!activeSlides.length) {
    return (
      <NoSlidesMessageContainer>
        <div>No slides to show</div>
      </NoSlidesMessageContainer>
    );
  }

  return (
    <Container>
      <CarouselContainer>
        {activeSlides.length === 1 ? (
          <SingleSlideItemWrapper>
            <SlideItem slide={activeSlides[0]} currentSlideIndex={0} isSingleSlide />
          </SingleSlideItemWrapper>
        ) : (
          <StyledCarousel
            showArrows={false}
            showStatus={false}
            autoPlay={false}
            swipeable={true}
            infiniteLoop={true}
            showIndicators={false}
            showThumbs={false}
            transitionTime={0}
            centerMode={true}
            useKeyboardArrows={true}
            centerSlidePercentage={50}
            onChange={(index: number) => onCarouselSlideChange(index, activeSlides)}
            renderArrowNext={(next: () => void) => (
              <NextButton iconName='carousel_arrow' onClick={next} />
            )}
            renderArrowPrev={(prev: () => void) => (
              <PrevButton iconName='carousel_arrow' onClick={prev} />
            )}
            isLastSlide={isLastSlide}
            selectedItem={selectedSlideIndex}
          >
            {activeSlides.map((slide, i) => {
              return <SlideItem slide={slide} key={i} currentSlideIndex={i} />;
            })}
          </StyledCarousel>
        )}
      </CarouselContainer>
    </Container>
  );
};
