import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  description: ReactNode | string;
  titleStyles?: React.CSSProperties;
}

export const DescriptionBlock = ({ title, description, titleStyles }: Props) => {
  return (
    <Wrapper>
      <Title style={titleStyles}>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  );
};

const Title = styled.p`
  color: #444;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Description = styled.p`
  color: #444;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  & span {
    font-weight: 700;
  }
`;
