import { useAtom, useAtomValue } from 'jotai';
import { ButtonsGroup } from '../../../../components/Common/V2/ButtonsGroup';
import { isAdjustedPriceModeAtom } from '../../state/isAdjustedPriceModeAtom';
import { useHasComparableAdjustments } from '../../../../hooks/useHasComparableAdjustments';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';

export const PlotByPriceSelection = () => {
  const hasAdjustments = useHasComparableAdjustments();
  const [isAdjustedPriceMode, setIsAdjustedPriceMode] = useAtom(isAdjustedPriceModeAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primary = useUiConfigColorsPrimary();

  const bgColorStyle = isPresentationView ? { backgroundColor: primary } : {};

  const plotByButtons = [
    {
      label: 'Actual Price',
      onClick: () => setIsAdjustedPriceMode(false),
      isActive: !isAdjustedPriceMode,
      style: !isAdjustedPriceMode ? bgColorStyle : {},
    },
    {
      label: 'Adjusted Price',
      onClick: () => setIsAdjustedPriceMode(true),
      isActive: isAdjustedPriceMode,
      style: isAdjustedPriceMode ? bgColorStyle : {},
    },
  ];

  if (!hasAdjustments) return null;

  return (
    <ButtonsGroup
      buttonsConfigs={plotByButtons}
      groupWidth={'242px'}
      disabled={false}
      label='Plot by'
    />
  );
};
