import React from 'react';

import { Wrapper, Image, InfoWrapper, Typography, Name, ImageWrapper } from './BrokerInfo.styles';
import { useUser } from '../../../../providers/UserProvider';
import { ImageProxy } from '../../../../services/ImageProxy';

interface BrokerInfoComponentProps {
  nameFontStyles?: React.CSSProperties;
  positionFontStyles?: React.CSSProperties;
  contactFontStyles?: React.CSSProperties;
  imageStyles?: React.CSSProperties;
  width?: string | number;
  imageSize?: number;
  fontColor?: string;
  showLicenseNumber?: boolean;
  containerStyles?: React.CSSProperties;
}

const BrokerInfo: React.FC<BrokerInfoComponentProps> = ({
  nameFontStyles,
  contactFontStyles,
  imageStyles,
  width,
  imageSize = 85,
  fontColor = '#fff',
  showLicenseNumber,
  containerStyles = {},
}) => {
  const user = useUser();

  if (!user) return null;

  const { name, email, phone, image, position, cal_bre } = user;

  return (
    <Wrapper style={{ width: width ? width : '100%', ...containerStyles }}>
      <ImageWrapper style={{ width: imageSize, height: imageSize, ...imageStyles }}>
        <Image src={ImageProxy.optimize(image)} alt={`${name}`} />
      </ImageWrapper>
      <InfoWrapper>
        <Name style={{ color: fontColor, ...nameFontStyles }}>{name}</Name>
        <Typography
          variant='body2'
          style={{ color: fontColor, textTransform: 'uppercase', ...contactFontStyles }}
        >
          {position}
        </Typography>
        {showLicenseNumber && cal_bre ? (
          <Typography variant='body2' style={{ color: fontColor, ...contactFontStyles }}>
            License #{cal_bre}
          </Typography>
        ) : (
          <>
            <Typography variant='body2' style={{ color: fontColor, ...contactFontStyles }}>
              {email}
            </Typography>
            <Typography variant='body2' style={{ color: fontColor, ...contactFontStyles }}>
              {phone}
            </Typography>
          </>
        )}
      </InfoWrapper>
    </Wrapper>
  );
};

export default BrokerInfo;
