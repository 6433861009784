import styled from 'styled-components';

export const TextareaEl = styled('textarea')(({ theme }) => ({
  width: '100%',
  padding: '10px 14px 3px 14px',
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: '5px',
  resize: 'none',
  color: theme.colors.gray[400],
  fontSize: '12px',
  lineHeight: '18px',
  marginBottom: '8px',
}));
