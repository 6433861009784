import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 15px;
`;

export const TextDescription = styled.p`
  color: #444;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
`;
