import styled from 'styled-components';
import { DetailDescription } from './DetailDescription/DetailDescription';
import { TProperty } from '../../../../types';
import { MapAndPhotos } from '../../../../components/Property/MapAndPhotos/MapAndPhotos';

interface Props {
  property: TProperty | null;
  hideActions?: boolean;
}

export const Property = ({ property, hideActions }: Props) => {
  if (!property) {
    return null;
  }

  return (
    <Container>
      <MapAndPhotos property={property} />
      <DetailDescription property={property} hideActions={hideActions} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;
