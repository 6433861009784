export const cards = [
  {
    title: 'The home buying process',
    primaryColorTitle: true,
    icon: undefined,
  },
  {
    title: 'Wishlist',
    text: 'What are you looking for in a home? What are “must-haves,” and what are just “nice-to-haves”?',
    number: 2,
  },
  {
    title: 'Make an offer',
    text: 'I’ll run a comparative market analysis (CMA), and we’ll discuss a fair market value.',
    number: 4,
  },
  {
    title: 'Establish Budget',
    text: 'I highly recommend getting pre-qualified for a loan amount before you start your home search.',
    number: 1,
  },
  {
    title: 'Buyer Tour',
    text: 'To find the perfect property to write an offer on, let’s look at properties, revise, and refine the wishlist.',
    number: 3,
  },
  {
    title: 'Offer Accepted',
    text: 'From the start of escrow opening, I’ll guide you through inspections and other critical decisions until keys are exchanged – and beyond.',
    number: 5,
  },
];

export const getOrderedCards = () =>
  [...cards].sort((a, b) => {
    if (a.number && b.number) {
      return a?.number - b?.number;
    }

    return 1;
  });
