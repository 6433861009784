import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AreaType, SearchedArea } from '../../../../../types';
import { useAtomValue } from 'jotai';
import { areaTypeAtom } from '../state/areaTypeAtom';
import { corePresentApi } from '../../../../../api/CorePresentApi';

const fetchSuggestions = async (value: string, useGeo: number): Promise<SearchedArea[] | null> => {
  const response = await corePresentApi.get(`/locations/search?keyword=${value}&useGeo=${useGeo}`);

  return response;
};

export function useAreaSuggestions(value: string): UseQueryResult<SearchedArea[]> {
  const areaType = useAtomValue(areaTypeAtom);
  const useGeo = areaType === AreaType.geographical ? 1 : 0;

  return useQuery(['areaSuggestions', value, areaType], () => fetchSuggestions(value, useGeo), {
    enabled: Boolean(value),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
}
