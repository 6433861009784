import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
});

export const LeftCol = styled('div')({
  padding: '45px 0 0 45px',
  backgroundColor: '#fff',
  textAlign: 'left',
  width: '35%',
  position: 'relative',
  '&:after': {
    content: '""',
    width: '164%',
    height: '191%',
    backgroundColor: '#f7f7f7',
    boxShadow: 'inset 3.90244px 0px 29.2683px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    borderRadius: '50%',
    top: '-46%',
    right: '-151%',
  },
});

export const Title = styled('h2')(() => ({
  fontSize: 30,
  fontWeight: 900,
  color: useUiConfigColorsPrimary(),
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const Subtitle = styled('h5')(() => ({
  fontSize: 18,
  color: '#000',
  marginTop: 20,
  fontWeight: 400,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const DescriptionSection = styled('div')(() => ({
  marginTop: 30,
  color: '#000',
  width: 380,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const DescriptionTitle = styled('h5')({
  fontSize: 18,
  fontWeight: 700,
});

export const Uppercase = styled('span')({
  textTransform: 'uppercase',
});

export const Text = styled('p')({
  fontSize: 18,
  marginBottom: 30,
  marginTop: 10,
  lineHeight: '28.41px',
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const RightCol = styled('div')({
  width: '63%',
  height: '100%',
  backgroundColor: '#f7f7f7',
  position: 'relative',
  zIndex: 1,
  paddingTop: 50,
  paddingRight: 65,
});

export const RightColTitle = styled('h4')({
  fontSize: 20,
  fontWeight: 700,
  textTransform: 'uppercase',
  color: '#000',
  textAlign: 'left',
});

export const CardsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 64,
});

export const SourceText = styled('p')({
  fontSize: 13,
  fontWeight: 300,
  color: '#101010',
  marginTop: 24,
  textAlign: 'end',
});
