import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { OneImageEpsilon } from './OneImageEpsilon';
import { OneImageEpsilonForm } from './OneImageEpsilonForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { OneImageEpsilonPdf } from './OneImageEpsilonPdf';

const template = 'oneImageLayoutEpsilon';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - One Image',
  frame: true,
  data: {
    image: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: OneImageEpsilonForm,
  web: OneImageEpsilon,
  pdf: OneImageEpsilonPdf,
};

export type TOneImageLayoutEpsilon = typeof template;
export { config, mappings, thumbnail };
