import { TSlide } from '../types';

export const isPopulatedPersonalizedSlide = (
  slideId: TSlide['id'],
  personalizedData: Record<TSlide['id'], TSlide> | TSlide[],
) => {
  const personalizedDataHasSlide =
    (Array.isArray(personalizedData) && personalizedData.find(item => item.id === slideId)) ||
    (!Array.isArray(personalizedData) &&
      typeof personalizedData === 'object' &&
      personalizedData[slideId]);

  return !(slideId && ['aboutMe', 'testimonials'].includes(slideId) && !personalizedDataHasSlide);
};
