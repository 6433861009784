import { THEMES } from '../constants/themes';
import { CATEGORIES } from '../constants/categories';
import { CurrentUserService } from './CurrentUserService';
import { CanadaSlidesAdapter } from './CanadaSlidesAdapter';
import {
  TPersonalizeSlide,
  TPresentationSlides,
  TPresentationUiConfig,
  TSlide,
  TUserConfigs,
} from '../types';
import { config as coverConfig } from '../components/Slide/slides/startingSlides/Cover';
import { config as cmaReportConfig } from '../components/Slide/slides/whatsMyHomeWorth/cmaReport';
import { config as marketOverview } from '../components/Slide/slides/marketAnalysis/marketOverview';
import { config as netProceeds } from '../components/Slide/slides/closingAndNextSteps/netProceeds';
import { config as closingConfig } from '../components/Slide/slides/closingAndNextSteps/closingAndNextSteps';
import { config as buyerDemand } from '../components/Slide/slides/marketingAndBuyerActivitySection/buyerDemand';
import { config as valueOfAgentConfig } from '../components/Slide/slides/whyIAmTheRightFitSection/valueOfAnAgent';
import { config as homeBuyingConfig } from '../components/Slide/slides/whyIAmTheRightFitSection/homeBuyingProcess';
import { config as homeSellingConfig } from '../components/Slide/slides/whyIAmTheRightFitSection/homeSellingProcess';
import { config as realtorVSIBuyerConfig } from '../components/Slide/slides/whyIAmTheRightFitSection/realtorVSIBuyer';
import { config as propertyBoostConfig } from '../components/Slide/slides/marketingAndBuyerActivitySection/propertyBoost';
import { config as buyerInterestConfig } from '../components/Slide/slides/marketingAndBuyerActivitySection/BuyerInterest';
import { config as pricingMisconceptionsConfig } from '../components/Slide/slides/whatsMyHomeWorth/PricingMisconceptions';
import { config as aboutMeConfig } from '../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/AboutMe';
import { config as whereBuyersGetTheirInfoConfig } from '../components/Slide/slides/whatsMyHomeWorth/whereBuyersGetTheirInfo';
import { config as myMarketingPlanConfig } from '../components/Slide/slides/marketingAndBuyerActivitySection/MyMarketingPlan';
import { config as comparativeMarketAnalysisConfig } from '../components/Slide/slides/whatsMyHomeWorth/comparativeMarketAnalysis';
import { config as importanceOfOpeningWeekendConfig } from '../components/Slide/slides/marketAnalysis/importanceOfOpeningWeekend';
import { config as whatBuyersWantConfig } from '../components/Slide/slides/whyIAmTheRightFitSection/whatBuyersWantFromTheirAgents';
import { config as importanceOfStagingConfig } from '../components/Slide/slides/marketingAndBuyerActivitySection/ImportanceOfStaging';
import { config as myTestimonialsConfig } from '../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/MyTestimonials';
import { config as whatFactorsInfluencePricingConfig } from '../components/Slide/slides/marketAnalysis/whatFactorsInfluencePricing';
import { config as whyPricingCorrectlyIsImportantConfig } from '../components/Slide/slides/marketAnalysis/whyPricingCorrectlyIsImportant';
import { config as greetingVideoConfig } from '../components/Slide/slides/startingSlides/GreetingVideo';
import { config as coreSocialConfig } from '../components/Slide/slides/marketingAndBuyerActivitySection/CoreSocial';
import { config as oneSheeterConfig } from '../components/Slide/slides/whyIAmTheRightFitSection/oneSheeter';
import { cloneDeep } from 'lodash';
export class DefaultConfigProvider {
  static getAllSlides(): TPresentationSlides {
    return {
      orderById: [
        'startingSlides',
        'whyIAmTheRightFit',
        'whatsMyHomeWorth',
        'marketAnalysis',
        'marketingAndBuyerActivity',
        'closingAndNextSteps',
      ],
      sections: {
        startingSlides: {
          id: 'startingSlides',
          label: 'Introduction',
          slides: [greetingVideoConfig, coverConfig],
        },
        marketAnalysis: {
          id: 'marketAnalysis',
          label: 'Market Analysis',
          slides: [
            marketOverview,
            whatFactorsInfluencePricingConfig,
            whyPricingCorrectlyIsImportantConfig,
            importanceOfOpeningWeekendConfig,
          ],
        },
        whyIAmTheRightFit: {
          id: 'whyIAmTheRightFit',
          label: 'Why I Am The Right Fit',
          slides: [
            aboutMeConfig,
            myTestimonialsConfig,
            valueOfAgentConfig,
            homeBuyingConfig,
            homeSellingConfig,
            realtorVSIBuyerConfig,
            whatBuyersWantConfig,
            oneSheeterConfig,
          ],
        },
        marketingAndBuyerActivity: {
          id: 'marketingAndBuyerActivity',
          label: 'Marketing & Buyer Activity',
          slides: [
            buyerDemand,
            buyerInterestConfig,
            importanceOfStagingConfig,
            myMarketingPlanConfig,
            propertyBoostConfig,
            coreSocialConfig,
          ],
        },
        closingAndNextSteps: {
          id: 'closingAndNextSteps',
          label: 'Next Steps',
          slides: [netProceeds, closingConfig],
        },
        whatsMyHomeWorth: {
          id: 'whatsMyHomeWorth',
          label: 'What’s My Home Worth',
          slides: [
            comparativeMarketAnalysisConfig,
            cmaReportConfig,
            pricingMisconceptionsConfig,
            whereBuyersGetTheirInfoConfig,
          ],
        },
      },
    };
  }

  static getPersonalizedSlides(): Record<TPersonalizeSlide, TSlide> {
    const personalized = {} as Record<TPersonalizeSlide, TSlide>;
    const personalSlideIds: TPersonalizeSlide[] = ['aboutMe', 'testimonials'];
    const witrfSlides = DefaultConfigProvider.getSlides().sections.whyIAmTheRightFit.slides;

    witrfSlides
      .filter(slide => personalSlideIds.includes(slide.id as TPersonalizeSlide))
      .forEach(slide => {
        personalized[slide.id as TPersonalizeSlide] = cloneDeep(slide);
      });

    return personalized as Record<TPersonalizeSlide, TSlide>;
  }

  static getSlides(): TPresentationSlides {
    const isCanadaUser = CurrentUserService.isCanadaUser();

    if (isCanadaUser) {
      return CanadaSlidesAdapter.getCanadaSlidesFromSlidesConfig(this.getAllSlides());
    }

    return this.getAllSlides();
  }

  static getUiConfig(): TPresentationUiConfig {
    const user = CurrentUserService.getUser();

    return {
      useKvCoreLogo: false,
      logos: user?.companyLogo ? { first: { value: user.companyLogo } } : {},
      infoPlacement: { value: 'top' },
      theme: THEMES.TRADITIONAL.DEFAULT,
      colors: {
        primary: {
          value: user?.companyColor || '#000000',
        },
      },
      fonts: {
        header: {
          value: 'Arial',
        },
        body: {
          value: 'Arial',
        },
      },
    };
  }

  static getConfigs(): TUserConfigs {
    return {
      [CATEGORIES.TRADITIONAL]: {
        category: CATEGORIES.TRADITIONAL,
        ui: {
          ...DefaultConfigProvider.getUiConfig(),
          theme: THEMES.TRADITIONAL.DEFAULT,
          fonts: {
            header: {
              value: 'Open Sans',
            },
            body: {
              value: 'Open Sans',
            },
          },
        },
        slides: DefaultConfigProvider.getSlides(),
      },
      [CATEGORIES.MODERN]: {
        category: CATEGORIES.MODERN,
        ui: {
          ...DefaultConfigProvider.getUiConfig(),
          theme: THEMES.MODERN.DEFAULT,
          fonts: {
            header: {
              value: 'Montserrat',
            },
            body: {
              value: 'Montserrat',
            },
          },
        },
        slides: DefaultConfigProvider.getSlides(),
      },
      [CATEGORIES.LUXURY]: {
        category: CATEGORIES.LUXURY,
        ui: {
          ...DefaultConfigProvider.getUiConfig(),
          theme: THEMES.LUXURY.DEFAULT,
          fonts: {
            header: {
              value: 'Georgia',
            },
            body: {
              value: 'Georgia',
            },
          },
        },
        slides: DefaultConfigProvider.getSlides(),
      },
    };
  }
}
