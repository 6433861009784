import { createPortal } from 'react-dom';
import { ReactNode, memo, useEffect, useRef, useState } from 'react';

const getElement = (id: string) => {
  const elements = document.querySelectorAll(`#${id}`);

  if (!elements.length) {
    return document.createElement('div');
  }

  if (elements.length === 1) {
    return elements[0];
  }

  return elements[elements.length - 1];
};

interface Props {
  id: string;
  children: ReactNode;
}

const Portal = ({ id, children }: Props) => {
  const el = useRef(getElement(id));

  const [dynamic] = useState(!el.current.parentElement);

  useEffect(() => {
    const currentEl = el.current;

    if (dynamic) {
      currentEl.id = id;
      document.body.appendChild(currentEl);
    }

    return () => {
      if (dynamic && currentEl.parentElement) {
        currentEl.parentElement.removeChild(currentEl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return createPortal(children, el.current);
};

export default memo(Portal);
