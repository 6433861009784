import { CmaReport } from './web/CmaReport';
import { TSlide } from '../../../../../types';
import { CmaReportPdf } from './pdf/CmaReportPdf';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';

const id = 'cmaReport';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'CMA - Big Picture Scale',
  frame: true,
  isInteractive: true,
  data: { cmaReportShowAllProperties: false },
};

const mappings: TSlideTypeMapping = {
  pdf: {
    shared: CmaReportPdf,
  },
  web: {
    shared: CmaReport,
  },
};

export type TCmaReport = typeof id;
export { config, mappings };
