import React, { Dispatch, SetStateAction, useState } from 'react';
import { UserApi } from '../../../../../../../../../../../api/UserApi';
import { getTestimonials } from '../../../../../../../../../../../services/getTestimonials';
import { IUserApiTestimonial } from '../../../../../../../../../../../types';
import { useSlides } from '../../../../../../../../../../../providers/providers/SlidesProvider';
import WarningConfirmPopup from '../../../../../../../../../../../components/Common/WarningConfirmPopup/WarningConfirmPopup';
import { config as myTestimonialsConfig } from '../../../../../../../../../../../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/MyTestimonials';
import { useBrandName } from '../../../../../../../../../../../hooks/useBrandName';
import { Button } from '../../../../../../../../../../../components/Common/V2/Button/Button';
import {
  FEATURES,
  useHasFeatureFlag,
} from '../../../../../../../../../../../hooks/useFeatureFlags';

interface Props {
  setIsResynced: Dispatch<SetStateAction<boolean>>;
}

export const ResyncTestimonials: React.FC<Props> = ({ setIsResynced }) => {
  const { getSlide, setSlideData } = useSlides();
  const { core } = useBrandName();
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const slide = getSlide('testimonials');
  const [isOpen, setIsOpen] = useState(false);

  const onResync = async () => {
    const apiUser = await UserApi.fetch();

    const userTestimonials = apiUser?.testimonials;

    const slideDefaultTestimonials = myTestimonialsConfig.data?.testimonials || [];

    const testimonials = userTestimonials ? JSON.parse(userTestimonials) : [];

    const adaptedTestimonials = testimonials.length
      ? testimonials.map((t: IUserApiTestimonial) => {
          return {
            text: t.testimonial,
            client: t.who,
            isHidden: false,
          };
        })
      : [];

    const kvCoreTestimonials = getTestimonials(slideDefaultTestimonials, adaptedTestimonials);

    const slideData = { ...slide?.data, testimonials: kvCoreTestimonials };

    setSlideData('testimonials', slideData);
    setIsResynced(true);
    setIsOpen(false);
  };

  const buttonText = shouldUseBtTheme
    ? 'Re-sync testimonials'
    : `Re-sync testimonials from ${core}`;
  const description = shouldUseBtTheme
    ? `By re-syncing your testimonials, any current testimonials will be lost. Do you wish to re-sync?`
    : `By re-syncing your testimonials from ${core}, any current testimonials will be lost. Do you wish to re-sync?`;

  return (
    <>
      <Button
        style={{ width: '100%' }}
        onClick={() => setIsOpen(true)}
        variant='primary'
        size='small'
      >
        {buttonText}
      </Button>
      <WarningConfirmPopup
        open={isOpen}
        heading={`Are you sure?`}
        description={description}
        onConfirm={onResync}
        onCancel={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        confirmButtonText='Yes, re-sync'
        cancelButtonText='No, cancel'
      />
    </>
  );
};
