import styled from 'styled-components';

export const SlidePreviewContainer = styled('div')({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

export const SlidePreviewWrapper = styled('div')({
  position: 'relative',
  maxWidth: 'calc(100vh * 1.78)',
  width: '100%',
  height: '100vh',
});
