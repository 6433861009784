import styled from 'styled-components';
import { TProperty } from '../../../types';
import { Title } from './Title';
import { PropertyMap } from './PropertyMap';
import { GalleryWithThumbnails } from './GalleryWithThumbnails';
import { Map } from '../../../features/BuyerTour/components/Map/Map.component';

interface Props {
  property: TProperty;
  isBT?: boolean;
}

export const MapAndPhotos = ({ property, isBT }: Props) => {
  return (
    <CenterColumn>
      <Title property={property} />
      {!isBT ? (
        <PropertyMap property={property} />
      ) : (
        <Map properties={[property]} style={{ height: 200 }} isViewModeProperty />
      )}
      <GalleryWithThumbnails property={property} />
    </CenterColumn>
  );
};

const CenterColumn = styled.div`
  width: 60%;
  margin: 0 25px 0 27px;
`;
