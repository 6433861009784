import * as types from './types';

export const setRebuildData = data => ({
  type: types.REBUILD_DATA,
  payload: data,
});

export const setReportDetailed = reportDetailed => ({
  type: types.REPORT_DETAILED_SET,
  payload: reportDetailed,
});
