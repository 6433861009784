import React, { createContext, useContext, useState } from 'react';

import { TSlideColors } from '../../types';
import { useUser } from '../../components/Slide/providers/UserProvider';

type ColorsSetterFunc = (value: TSlideColors) => TSlideColors;

type ColorsContextType = {
  colors: TSlideColors;
  setColors: (value: TSlideColors | ColorsSetterFunc) => void;
};

const UiColorsContext = createContext<ColorsContextType | undefined>(undefined);

export const useUiConfigColors = () => {
  const context = useContext(UiColorsContext);

  if (!context) throw new Error('useUiConfigColors cannot be used without UiColorsContext');

  return context;
};

export const useUiConfigColorsPrimary = () => {
  const context = useContext(UiColorsContext);

  if (!context) throw new Error('useUiConfigColors cannot be used without UiColorsContext');

  return context.colors?.primary?.value;
};

export const useUiConfigColorsSecondary = () => {
  const context = useContext(UiColorsContext);

  if (!context) throw new Error('useUiConfigColors cannot be used without UiColorsContext');

  return context.colors?.secondary?.value ?? context.colors?.primary?.value;
};

function getColorValue(color: any = '') {
  if (!color) return '#000000';

  if (!color.startsWith('#')) return `#${color}`;

  return color;
}

const getValidInitColor = (initValue: any) => {
  if (!initValue?.primary?.value) {
    return {
      primary: {
        value: '#000000',
      },
    };
  }

  return {
    primary: {
      ...(initValue?.primary ?? {}),
      value: getColorValue(initValue?.primary?.value),
    },
    secondary: {
      ...(initValue?.secondary ?? {}),
      value: getColorValue(initValue?.secondary?.value ?? initValue?.primary?.value),
    },
  };
};

interface Props {
  initValue?: TSlideColors;
}
export const UiConfigColorsProvider: React.FC<Props> = ({ initValue, children }) => {
  const [colors, setColors] = useState<TSlideColors>(getValidInitColor(initValue));

  const value = React.useMemo(() => ({ colors, setColors }), [colors]);

  return <UiColorsContext.Provider value={value}>{children}</UiColorsContext.Provider>;
};
