import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { OneImageBeta } from './OneImageBeta';
import { OneImageBetaForm } from './OneImageBetaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { OneImageBetaPdf } from './OneImageBetaPdf';

const template = 'oneImageLayoutBeta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - One Image',
  frame: true,
  data: {
    image: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: OneImageBetaForm,
  web: OneImageBeta,
  pdf: OneImageBetaPdf,
};

export type TOneImageLayoutBeta = typeof template;
export { config, mappings, thumbnail };
