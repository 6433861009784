import React, { useState, useEffect } from 'react';

import * as E from './components/Expandables';
import { AccountOfficesDropdowns } from '../AccountOfficeDropdowns';
import { useUpdateUiConfigWhenEntityChanges } from './useUpdateUiConfigWhenEntityChanges';
import PreviewSlide from '../../../../../../../components/PreviewSlide/PreviewSlide.component';
import { useUiConfigTheme } from '../../../../../../../providers/providers/UiConfigThemeProvider';
import { useUiConfigInfoPlacement } from '../../../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { config as wbwfta } from '../../../../../../../components/Slide/slides/whyIAmTheRightFitSection/whatBuyersWantFromTheirAgents';
import { useEntity } from '../../../../../providers/EntityProvider';
import styled from 'styled-components';

export interface CustomizationSettingsExpandableCommon {
  open: boolean;
  onToggle: (key: keyof typeof initialOpenState) => void;
  onApplySettings: (key: keyof typeof initialOpenState) => void;
}

const initialOpenState = {
  theme: false,
  colors: false,
  fonts: false,
  logos: false,
  info: false,
};

export const CustomizationModalComponentStepOne: React.FC = () => {
  const [open, setOpen] = useState<typeof initialOpenState>(initialOpenState);
  const { theme } = useUiConfigTheme();
  const { infoPlacement } = useUiConfigInfoPlacement();
  const { entity } = useEntity();

  const onToggle = (field: keyof typeof initialOpenState): void => {
    setOpen({ ...initialOpenState, [field]: !open[field] });
  };

  const onApplySettings = (expandibleType: keyof typeof initialOpenState): void => {
    const orderedState = Object.keys(initialOpenState);
    const nextStateItem = orderedState[orderedState.indexOf(expandibleType) + 1];
    setOpen({ ...initialOpenState, [expandibleType]: false, [nextStateItem]: true });
  };

  useEffect(() => {
    setOpen({ ...initialOpenState, theme: true });
  }, []);

  useUpdateUiConfigWhenEntityChanges();

  return (
    <>
      <ModalContentLeft>
        <PreviewSlide slide={wbwfta} theme={theme} infoPlacement={infoPlacement} />
      </ModalContentLeft>

      <ModalContentRight>
        <AccountOfficesDropdowns />

        <ExpandablesSection>
          <E.ExpandableThemeComponent
            key={entity + '-1'}
            open={open.theme}
            onToggle={onToggle}
            onApplySettings={onApplySettings}
          />
          <E.ExpandablesColorsComponent
            key={entity + '-2'}
            open={open.colors}
            onToggle={onToggle}
            onApplySettings={onApplySettings}
          />
          <E.ExpandablesFontsComponent
            key={entity + '-3'}
            open={open.fonts}
            onToggle={onToggle}
            onApplySettings={onApplySettings}
          />
          <E.ExpandablesLogosComponent
            key={entity + '-4'}
            open={open.logos}
            onToggle={onToggle}
            onApplySettings={onApplySettings}
          />
          <E.ExpandablesInfoPlacementComponent
            key={entity + '-5'}
            open={open.info}
            onToggle={onToggle}
            onApplySettings={onApplySettings}
          />
        </ExpandablesSection>
      </ModalContentRight>
    </>
  );
};

const ModalContentLeft = styled.div`
  min-width: 862px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

const ModalContentRight = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const ExpandablesSection = styled.div`
  display: flex;
  height: 459px;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`;
