import styled from 'styled-components';
import { PROPERTY_STATUS } from '../../../../types';
import { useColorBasedOnStatus } from '../../../../hooks/useColorBasedOnStatus';

interface Props {
  propertiesStatus: PROPERTY_STATUS;
  label: string;
  count: number;
}

export const PropertiesCountItem = ({ propertiesStatus, label, count }: Props) => {
  const getColorBasedOnStatus = useColorBasedOnStatus();

  return (
    <PropertiesCount>
      <Badge bgColor={getColorBasedOnStatus(propertiesStatus)}>
        <Count>{count}</Count>
      </Badge>
      <Label>{label}</Label>
    </PropertiesCount>
  );
};

const PropertiesCount = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const Label = styled.p`
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-transform: uppercase;
  line-height: 1.2;
`;

const Badge = styled.div<{ bgColor: string }>`
  min-width: 14px;
  padding: 2px;
  height: 14px;
  border-radius: 50%;
  border-radius: 10px;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Count = styled.div`
  color: #fff;
  font-size: 10px;
  font-weight: 900;
  line-height: 0;
`;
