import styled from 'styled-components';
import {
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  position: 'absolute',
  padding: '3%',
  zIndex: 5,
}));

export const Heading = styled('div')(({ theme }) => ({
  fontFamily: useUiConfigFontsBody(),
  fontSize: 15,
  fontWeight: 900,
  marginBottom: 5,
  textAlign: 'center',
  textTransform: 'uppercase',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const Text = styled('div')(({ theme }) => ({
  fontFamily: useUiConfigFontsBody(),
  fontSize: 14,
  fontWeight: 400,
  textAlign: 'center',
  lineHeight: 1.3,
}));

export const TopText = styled('div')(({ theme }) => ({
  fontFamily: useUiConfigFontsBody(),
  fontSize: 14,
  textTransform: 'uppercase',
  fontWeight: 900,
  textAlign: 'center',
  lineHeight: 1.3,
  color: '#666666',
}));
