import type { TSlide } from '../../../../../types';
import { ClosingAndNextStepsPDF } from './pdf/ClosingAndNextStepsPDF';
import { ClosingAndNextSteps } from './web/ClosingAndNextSteps.component';
import type { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';

const id = 'closingAndNextSteps';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Closing & Next Steps',
  frame: false,
  isInteractive: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: ClosingAndNextSteps,
  },
  pdf: {
    shared: ClosingAndNextStepsPDF,
  },
};

export type TClosingAndNextSteps = typeof id;
export { config, mappings };
