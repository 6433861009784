import React, { useMemo } from 'react';

import { ImageProxy } from '../../../../services/ImageProxy';
import { Container, Logo, LogoWrapper } from './Logos.styles';
import { useUiConfigLogos } from '../../../../../../providers/providers/UiConfigLogosProvider';
import { useUiUseKvCoreLogo } from '../../../../../../providers/providers/UiUseKvCoreLogoProvider';
import { useUser } from '../../../../providers/UserProvider';

interface Props {
  containerStyles?: React.CSSProperties;
  logoStyles?: React.CSSProperties;
}
const Logos: React.FC<Props> = ({ containerStyles, logoStyles }) => {
  const { useKvCoreLogo } = useUiUseKvCoreLogo();
  const { logosList } = useUiConfigLogos();
  const user = useUser();

  const logos = useMemo(() => {
    if (useKvCoreLogo) return [user?.companyLogo];

    return logosList.map(logo => logo.value);
  }, [useKvCoreLogo, logosList, user?.companyLogo]);

  const { height, ...restLogoStyles } = logoStyles || {};

  const getLogoHeight = (index: number, height: string | number = 100) => {
    if (index === 0) return `${height}px`;
    if (index > 0) return `calc(${height}px * 0.8)`;
  };

  return (
    <Container style={containerStyles}>
      {logos.map((logo, index) => (
        <LogoWrapper key={logo}>
          {logo && (
            <Logo
              src={ImageProxy.optimize(logo, { width: 1500 })}
              alt='logo'
              height={getLogoHeight(index, height)}
              style={restLogoStyles}
            />
          )}
        </LogoWrapper>
      ))}
    </Container>
  );
};

export default Logos;
