import styled from 'styled-components';

export const CheckboxGroups = styled.div`
  display: flex;
  gap: 20px;
`;

export const FlagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
