import React from 'react';
import { RadioButtons } from '../../../../../../../../components/Common/RadioButtons/RadioButtons.component';
import { TPersonalSlideExperienceLevel } from '../../../../../../../../types';
import { ExperienceLevelContainer, ExperienceLevelDescription } from './ExperienceLevel.style';

const experienceLevelButtons: { value: TPersonalSlideExperienceLevel; label: string }[] = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'novice',
    label: 'Novice',
  },
  {
    value: 'experienced',
    label: 'Experienced',
  },
  {
    value: 'seasoned',
    label: 'Seasoned',
  },
];

interface Props {
  value: TPersonalSlideExperienceLevel;
  onChange: (val: string) => void;
}

export const ExperienceLevel: React.FC<Props> = ({ onChange, value }) => {
  return (
    <ExperienceLevelContainer>
      <ExperienceLevelDescription>
        Choose experience level for help writing this section:
      </ExperienceLevelDescription>
      <RadioButtons
        options={experienceLevelButtons}
        onChange={val => onChange(val)}
        defaultSelected={value}
      />
    </ExperienceLevelContainer>
  );
};
