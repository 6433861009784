import { useEffect, useState } from 'react';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { ConfigItem, useOrder } from '../../../../providers/providers/OrderProvider';
import { stringifySlidesOrdering } from '../PresentationSettingsHeader/PresentationSettings/Emphasis/services/stringifySlidesOrdering';
import { useUserCategoryCustomizationOrder } from '../PresentationSettingsHeader/PresentationSettings/Emphasis/hooks/ordering/useUserCategoryCustomizationOrder';
import { useUpdateOrderingOnCategoryChange } from '../PresentationSettingsHeader/PresentationSettings/Emphasis/hooks/ordering/useUpdateOrderingOnCategoryChange';

export const useOrderingChange = () => {
  const { hash, useSpecialBranding } = useUser()!;
  const { sections, sectionsSlide } = useOrder();

  const [isDefaultOrder, setIsDefaultOrder] = useState(true);

  const { data: agentCustomizations, isLoading } = useUserCategoryCustomizationOrder(
    hash,
    null,
    useSpecialBranding,
  );

  const currentOrdering = stringifySlidesOrdering({ sections, sectionsSlide });
  const agentDefaultOrdering = stringifySlidesOrdering(agentCustomizations as ConfigItem);

  useEffect(() => {
    if (isLoading) return;

    setTimeout(() => {
      setIsDefaultOrder(currentOrdering === agentDefaultOrdering);
    }, 1000);
  }, [agentDefaultOrdering, currentOrdering, isLoading]);

  useUpdateOrderingOnCategoryChange({ isDefaultOrder });
};
