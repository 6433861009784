import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../../../components/Common';

import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { usePresentationMode } from '../../../../../providers/providers/PresentationModeProvider';
import { TPresentationMode, TSectionsSlide } from '../../../../../types';

export const SectionsInfoContainer = styled('div')({
  display: 'flex',
  width: 289,
});

export const InfoText = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontSize: 14,
  fontWeight: 700,
  '& span': {
    color: theme.colors.v2.text.regular,
    fontWeight: 500,
  },
}));

const getSectionsAndSlidesCount = (
  sections: string[],
  sectionsSlide: TSectionsSlide,
): { sectionCount: number; slidesCount: number } => {
  return {
    sectionCount: sections?.length ?? 0,
    slidesCount: Object.values(sectionsSlide).reduce((sum, slides) => {
      const countOfSectionSlides = slides.length;
      return sum + (countOfSectionSlides ?? 0);
    }, 0),
  };
};

export const SectionsInfo = () => {
  const { sections, sectionsSlide } = useOrder();
  const { sectionCount, slidesCount } = getSectionsAndSlidesCount(sections, sectionsSlide);
  const { presentationMode } = usePresentationMode();

  const getLabel = (presentationMode: TPresentationMode) => {
    switch (presentationMode) {
      case 'cma':
        return 'CMA';
      case 'oneSheeter':
        return 'Single Page Review';
      default:
        return 'Presentation';
    }
  };

  return (
    <SectionsInfoContainer>
      <InfoText variant='h4' color='400'>
        {getLabel(presentationMode)}:{' '}
        <span>{`${sectionCount} sections, ${slidesCount} slides`}</span>
      </InfoText>
    </SectionsInfoContainer>
  );
};
