import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { FourImagesDelta } from './FourImagesDelta';
import { FourImagesDeltaForm } from './FourImagesDeltaForm';
import { FourImagesDeltaPdf } from './FourImagesDeltaPdf';
import thumbnail from './thumbnail.png';

const template = 'fourImagesLayoutDelta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Four Image Layout',
  frame: true,
  data: {
    image: '',
    image2: '',
    image3: '',
    image4: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: FourImagesDeltaForm,
  web: FourImagesDelta,
  pdf: FourImagesDeltaPdf,
};

export type TFourImagesLayoutDelta = typeof template;
export { config, mappings, thumbnail };
