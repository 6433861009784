import React from 'react';
import styled from 'styled-components';

const InfoItemText = styled('div')({
  display: 'flex',
  color: '#444',
  fontSize: 16,
  lineHeight: 1.8,
});

const IconWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: 20,
  marginRight: 20,
  transform: 'translateY(7px)',
});

const Icon = styled('img')({
  alignSelf: 'flex-start',
  height: 14,
  width: 'auto',
});

interface Props {
  icon: string;
  text?: string;
}

const InfoItem: React.FC<Props> = ({ icon, text = '', children }) => {
  return (
    <InfoItemText>
      <IconWrapper>
        <Icon src={icon} />
      </IconWrapper>
      {text || children}
    </InfoItemText>
  );
};

export default InfoItem;
