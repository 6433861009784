import styled from 'styled-components';
import { Typography, Icon } from '../../../../../components/Common';

export { Typography, Icon };

type WithOpen = {
  isOpen: boolean;
};

export const AllSlidesContainer = styled('div')<WithOpen>(({ theme, isOpen }) => ({
  width: 258,
  boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.25)',
  position: 'absolute',
  top: 70,
  left: isOpen ? 0 : -258,
  transition: 'left .3s ease-in-out',
  maxHeight: 'calc(100% - 70px)',
  minHeight: 'calc(100% - 70px)',
  overflowY: 'auto',
  zIndex: 2,
  backgroundColor: '#fff',
  '&::-webkit-scrollbar': {
    width: '.3em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colors.gray[200],
    outline: 'none',
  },
  'scroll-behavior': 'smooth',
}));

export const SlideItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 15px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  gap: 10px;
`;

export const SlideTitle = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-weight: 700;
  text-transform: capitalize;
`;
