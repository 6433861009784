import { useEffect } from 'react';
import { useOrder } from '../../../providers/providers/OrderProvider';
import { useSlides } from '../../../providers/providers/SlidesProvider';
import { useSections } from '../../../providers/providers/SectionsProvider';
import { useCategory } from '../../../providers/providers/CategoryProvider';
import { useCreatedAt } from '../../../providers/providers/CreatedAtProvider';
import { useSetUiConfigs } from '../../../providers/providers/UiConfigProvider';
import { OrderValidator } from '../../../services/validators/OrderValidator';
import { usePresentationType } from '../../../providers/providers/PresentationTypeProvider';
import { useActiveSection } from '../../../components/Slide/providers/ActiveSectionProvider';
import { SlidesValidator } from '../../../services/validators/SlidesValidator';
import { SectionsValidator } from '../../../services/validators/SectionsValidator';
import {
  TPresentation,
  TPresentationModeConfig,
  TPresentationType,
  TSection,
} from '../../../types';

export function useSetWTRPresentationFullConfigToProviders(presentation: TPresentation) {
  // const { setClient } = useClient();
  const { setSlides } = useSlides();
  const setUiConfigs = useSetUiConfigs();
  const { setSections } = useSections();
  const { setCategory } = useCategory();
  const { setActiveSection } = useActiveSection();
  const { setPresentationType } = usePresentationType();
  const { setSectionsOrder, setSectionsSlideOrder } = useOrder();
  const { setCreatedAt } = useCreatedAt();

  useEffect(
    function setBuyerTourDefaultPresentationConfig() {
      const type = presentation.type as TPresentationType;

      setUiConfigs(presentation.ui);
      setPresentationType(type);
      setCategory(presentation.category);
      setCreatedAt(presentation.createdAt as number);

      const slideValidator = new SlidesValidator(type);
      setSlides(slideValidator.getPartialConfig(presentation.slidesMap));

      const slidesAndSections = presentation.regular as TPresentationModeConfig;
      const sectionsValidator = new SectionsValidator(type);
      setSections(sectionsValidator.getPartialConfig(slidesAndSections?.sections));

      const validator = new OrderValidator(type);
      const sectionOrder = validator.getPartialSectionsOrder(slidesAndSections?.order?.sections);
      const sectionsSlideOrder = validator.getPartialSectionsSlidesOrder(
        slidesAndSections.order.sectionsSlide,
        presentation.slidesMap,
      );

      setSectionsOrder(sectionOrder);
      setSectionsSlideOrder(sectionsSlideOrder);

      // setClient({
      //   id: presentation.clientId,
      //   name: presentation.clientName,
      //   email: presentation.clientEmail,
      // });

      setActiveSection(sectionOrder[0] as TSection);
    },
    [
      presentation,
      setActiveSection,
      setCategory,
      // setClient,
      setCreatedAt,
      setPresentationType,
      setSections,
      setSectionsOrder,
      setSectionsSlideOrder,
      setSlides,
      setUiConfigs,
    ],
  );
}
