import React, { createContext, useContext, useMemo } from 'react';
import { TSlide, TWhiteLabelSlidesProp, WhiteLabelSettings } from '../../../types';
import { useDomainWhitelabelSettings } from '../../../features/whitelabel/useDomainWhitelabelSettings';

interface Props {
  slide: TSlide;
  isEditable?: boolean;
  isDefaultConfig?: boolean;
  presentationHash?: string;
  hideProgressTracker?: boolean;
  showTemplatePreview?: boolean;
  whiteLabelSettings?: TWhiteLabelSlidesProp | null;
}

const SlidePropsContext = createContext<Props | undefined>(undefined);

export const SlidePropsProvider: React.FC<Props> = ({
  children,
  slide,
  isEditable,
  isDefaultConfig,
  presentationHash,
  hideProgressTracker,
  showTemplatePreview,
  whiteLabelSettings,
}) => {
  const { data } = useDomainWhitelabelSettings();

  const wlSettings: TWhiteLabelSlidesProp | null = useMemo(() => {
    if (whiteLabelSettings) return whiteLabelSettings;

    if (!whiteLabelSettings && data?.whiteLabelSettings) {
      return {
        packageLevel: data.whiteLabelSettings.packageLevel,
        primaryColor: data.whiteLabelSettings.primaryColor,
        secondaryColor: data.whiteLabelSettings.secondaryColor,
      };
    }

    return null;
  }, [data?.whiteLabelSettings, whiteLabelSettings]);

  const value: Props = {
    slide,
    isEditable,
    isDefaultConfig,
    presentationHash,
    hideProgressTracker,
    showTemplatePreview,
    whiteLabelSettings: wlSettings,
  };
  return <SlidePropsContext.Provider value={value}>{children}</SlidePropsContext.Provider>;
};

export const useSlideProps = (): Props => {
  const context = useContext(SlidePropsContext);

  if (!context) {
    throw new Error('useSlideProps must be used within a SlidePropsContext');
  }

  return context;
};

export const useSlide = (): TSlide => {
  return useSlideProps().slide;
};
