import React from 'react';
import { ImageProxy } from '../../../../../../services/ImageProxy';
import { Gallery, GalleryImage, NoImage } from './ListingDetailsGallery.styles';
import noImage from '../../../../../../../../assets/images/no-image.png';

interface ListingDetailsGalleryProps {
  images: string[];
}

export const ListingDetailsGallery: React.FC<ListingDetailsGalleryProps> = ({ images }) => {
  if (images.length === 0) {
    return <NoImage src={noImage} alt='' />;
  }

  return (
    <Gallery>
      {images.slice(0, 6).map(image => {
        return (
          <GalleryImage
            key={image}
            style={{ backgroundImage: `url("${ImageProxy.optimize(image, { width: 500 })}")` }}
          />
        );
      })}
    </Gallery>
  );
};
