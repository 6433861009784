import React from 'react';
import { TProperty } from '../../../../../../../types';
import {
  ListingDetailsContainer,
  GalleryAndNotes,
  Wrapper,
  Description,
} from './ListingDetails.styles';
import { ListingDetailsGallery } from './ListingDetailsGallery/ListingDetailsGallery.component';
import { Notes } from './Notes/Notes.component';
import { limitLongString } from '../../../../../../../services/limitLongString';
import { Details } from '../../../../../../../features/report/listing-details-pdf/Details/Details';
import { createSvgWithText } from '../../../../../../../features/BuyerTour/components/Map/Markers/Markers.component';
import { PageHeader } from '../../../../../../../features/report/listing-details-pdf/PageHeader/PageHeader';

interface ListingDetailsProps {
  property: TProperty;
  index: number;
}

export const ListingDetails: React.FC<ListingDetailsProps> = ({ property, index }) => {
  const icon = React.useMemo(() => createSvgWithText(index + 1), [index]);

  return (
    <ListingDetailsContainer>
      <PageHeader property={property} icon={<img src={icon} />} isBT />
      <Description>{limitLongString(property.description, 510)}</Description>

      <Details property={property} />
      <GalleryAndNotes>
        <Wrapper style={{ width: '44%' }}>
          <ListingDetailsGallery images={property.images} />
        </Wrapper>
        <Wrapper style={{ width: '56%' }}>
          <Notes />
        </Wrapper>
      </GalleryAndNotes>
    </ListingDetailsContainer>
  );
};
