import React from 'react';
import { PercentageBar } from './PercentageBar/PercentageBar.component';
import { PercentagesContainer, PercentagesTitle } from './PercentageGraph.styles';

export type TPercentagesBarData = {
  title: string;
  office?: string;
  agent?: string;
};

export const PercentageGraph: React.FC<TPercentagesBarData> = ({ title, office, agent }) => {
  return (
    <PercentagesContainer>
      <PercentagesTitle>{title}</PercentagesTitle>
      {office && <PercentageBar percentage={office} isOfficeData />}
      {agent && <PercentageBar percentage={agent} />}
    </PercentagesContainer>
  );
};
