import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';

export const Button = ({
  text,
  children = null,
  loading,
  disabled,
  fluid,
  onClick,
  primary = false,
  className,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`${styles.button} ${primary ? styles.primary : ''} ${fluid ? styles.fluid : ''} ${
      loading ? styles.loading : ''
    } ${className}`}
  >
    {!loading && (text || children)}
  </button>
);

Button.propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  text: '',
  loading: false,
  disabled: false,
  fluid: false,
  className: '',
  onClick: f => f,
};
