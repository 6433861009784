import styled from 'styled-components';

import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { Card } from '../../../../../components/Card/Card.component';
import { data } from '../../data';

const cardStyles = {
  padding: '35px 37px',
};

const titleStyles = (index: number) => {
  return {
    fontSize: '24px',
    height: 'unset',
    marginBottom: '24px',
    letterSpacing: '1px',
    width: index === 1 ? '73%' : '100%',
  };
};

const textStyles = {
  width: '100%',
};

export const PurposeOfBuyersAgentModernPDF: React.FC = () => {
  const primaryColor = useUiConfigColorsPrimary();
  const secondaryColor = useUiConfigColorsSecondary();

  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      <Title color={primaryColor}>Purpose & Importance of a buyers’s agent</Title>
      <Grid color={secondaryColor}>
        {data.map((item, i) => (
          <Card
            key={`${item.title}_${i}`}
            type='pdf'
            cardStyles={cardStyles}
            titleStyles={titleStyles(i)}
            textStyles={textStyles}
            {...item}
          />
        ))}
      </Grid>
    </PdfFrame>
  );
};

const Grid = styled.div<{ color: string }>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
  height: calc(100% - 100px);

  & > :nth-child(3n + 1),
  & > :nth-child(3n + 2),
  & > :nth-child(3n) {
    grid-column: span 4;
  }

  & > :nth-child(n + 4) {
    grid-column: span 6;
  }

  & > :first-of-type {
    border: 1.5px solid #f2f8f8;
    background-color: ${props => ColorOpacityApplier.hex(props.color, '.1')};
  }
`;

const Title = styled.h1<{ color: string }>`
  font-size: 24px;
  font-weight: 800;
  line-height: 41px;
  text-transform: uppercase;
  color: ${props => props.color};
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  padding: 14px 0;
  margin-top: 0;
`;
