import { TSlideId, TSlide, TPresentationSlides, TSection, TSectionConfig } from '../types';

export class CanadaSlidesAdapter {
  private static slidesToRemove = ['pricingMisconceptions', 'realtorVSIBuyer'];

  static getSlidesFromSlidesMap(
    slidesMap: Partial<Record<TSlideId, TSlide>>,
  ): Partial<Record<TSlideId, TSlide>> {
    const slidesList = Object.values(slidesMap);
    const canadaSlides = slidesList.filter(slide => !this.slidesToRemove.includes(slide?.id));

    return Object.fromEntries(canadaSlides.map(slide => [slide.id, { ...slide }]));
  }

  static getCanadaSlidesFromSlidesConfig(slidesConfig: TPresentationSlides): TPresentationSlides {
    const slides = slidesConfig.sections;
    const canadaSections = Object.values(slides).map(section => ({
      ...section,
      slides: section.slides.filter(slide => !this.slidesToRemove.includes(slide.id)),
    }));

    return {
      orderById: slidesConfig.orderById,
      sections: Object.fromEntries(
        canadaSections.map(section => [section.id, { ...section }]),
      ) as Record<TSection, TSectionConfig>,
    };
  }
}
