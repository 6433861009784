import React from 'react';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import {
  Container,
  PropertyAddress,
  PropertyInfoContainer,
  PropertyPreview,
  PresentedTitle,
} from './CoverPdf.styles';

import { useUser } from '../../../../../providers/UserProvider';
import { useClient } from '../../../../../../../providers/providers/ClientProvider';
import { BrokerContactInfo } from '../components/BrokerContactInfo/BrokerContactInfo';

export const CoverBTPdf: React.FC = () => {
  const { category } = useCategory();
  const user = useUser();

  const { client } = useClient();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL} isCover>
      <Container>
        <PropertyPreview>
          <PropertyInfoContainer>
            <PropertyAddress>{client?.name}</PropertyAddress>
          </PropertyInfoContainer>
        </PropertyPreview>
        <PresentedTitle>Prepared by: {user?.name}</PresentedTitle>
        <BrokerContactInfo />
      </Container>
    </PdfFrame>
  );
};
