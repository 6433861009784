import { cloneDeep } from 'lodash';

import { hasNetProceedsSlide } from './hasNetProceedsSlide';
import { hasGreetingVideoSlide } from './hasGreetingVideoSlide';
import { TSlideId, TPresentationMode, TDynamicSectionId } from '../../../../types';
import { ConfigItem } from '../../../../providers/providers/OrderProvider';

function removeSlideFromOrder(slide: TSlideId, mode: TPresentationMode, order: ConfigItem) {
  const adaptedSectionSlide = cloneDeep(order.sectionsSlide);

  return Object.keys(adaptedSectionSlide).reduce(
    (map, section) => ({
      ...map,
      [section]: adaptedSectionSlide[section].filter(sId => sId !== slide),
    }),
    {},
  );
}

export function removeFromModeRemovedDashboardSlides(
  mode: TPresentationMode,
  modeOrder: ConfigItem,
  previousMode: TPresentationMode,
  previousModeOrder: ConfigItem,
): Record<TDynamicSectionId, TSlideId[]> {
  const order = cloneDeep(modeOrder);

  if (!hasGreetingVideoSlide(previousMode, previousModeOrder)) {
    order.sectionsSlide = removeSlideFromOrder('greetingVideo', mode, order);
  }

  if (!hasNetProceedsSlide(previousMode, previousModeOrder)) {
    order.sectionsSlide = removeSlideFromOrder('netProceeds', mode, order);
  }

  return order.sectionsSlide;
}
