import { THighlightedProperty } from '../../../../../types';

const defaultCategories = ['average_pending', 'average_active', 'average_closed'];

type Params = {
  categories: any;
  highlights: THighlightedProperty[];
  useSqft: boolean;
  defaultsOnly?: boolean;
  useAdjustedPrice?: boolean;
};

export const sortScaleProperties = ({
  categories,
  highlights,
  useSqft,
  defaultsOnly,
  useAdjustedPrice,
}: Params) => {
  const sorted = {
    sortedCategories: Array.isArray(categories) ? [...categories] : [],
    sortedHighlights: Array.isArray(highlights) ? highlights : [],
  };

  const valuePropertyName = useSqft ? 'valuePerSqft' : 'value';
  const pricePropertyName = useSqft ? 'pricePerSqft' : 'price';

  if (categories && categories.length) {
    sorted.sortedCategories.sort(
      (a, b) => (a[valuePropertyName] || 0) - (b[valuePropertyName] || 0),
    );
  }

  if (defaultsOnly)
    sorted.sortedCategories = sorted.sortedCategories.filter(c => defaultCategories.includes(c.id));

  if (highlights && highlights.length) {
    sorted.sortedHighlights.sort((a, b) => {
      if (useSqft && useAdjustedPrice) {
        return (
          (a.adjustedPricePerSqft || a.pricePerSqft) - (b.adjustedPricePerSqft || b.pricePerSqft)
        );
      }

      if (useAdjustedPrice) {
        return (a.adjustedPrice || a.price) - (b.adjustedPrice || b.price);
      }

      if (useSqft) {
        return a.pricePerSqft - b.pricePerSqft;
      }

      return a.price - b.price;
    });
  }

  return sorted;
};
