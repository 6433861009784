import React, { useEffect, useState } from 'react';

import styles from './NextStepsFooter.module.scss';
import {
  usePresentationBuildFlow,
  usePresentationBuildFlowConstants,
} from '../../../../../../ReportFacade/providers/PresentationBuildFlowProvider';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Button } from '../../../../../../../../../components/Common/V2/Button/Button';

export const NextStepsFooter = ({
  onPrev = f => f,
  onNext = f => f,
  loading = false,
  disabled = false,
  className = '',
}) => {
  const { search } = useLocation();
  const searchCriteria = useSelector(state => state.search.searchCriteria);
  const [toPresentationFlow, setToPresentationFlow] = useState(false);
  const FLOWS = usePresentationBuildFlowConstants();
  const [flow, setFlow] = usePresentationBuildFlow();
  const showActions = searchCriteria?.id && search.includes('step=info');

  useEffect(
    function onFlowChanged() {
      if (toPresentationFlow && flow === FLOWS.ADDRESS_TO_PRESENTATION) {
        onNext();
      }
    },
    [toPresentationFlow, flow],
  );

  const onPresentation = () => {
    setFlow(FLOWS.ADDRESS_TO_PRESENTATION);
    setToPresentationFlow(true);
  };

  return (
    <footer className={`${styles.footer} ${className}`}>
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        <Button
          onClick={onNext}
          disabled={disabled || loading}
          isLoading={loading && flow === FLOWS.ALL_STEPS}
        >
          Next: Comparable Details
        </Button>
      </div>
      {showActions && (
        <div
          style={{
            marginLeft: 'auto',
            marginTop: 10,
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Button variant='secondary' onClick={onPrev} disabled={loading}>
            Previous
          </Button>
          <Button
            variant='secondary'
            onClick={onPresentation}
            disabled={disabled || loading}
            isLoading={loading && flow === FLOWS.ADDRESS_TO_PRESENTATION}
          >
            Skip To Presentation
          </Button>
        </div>
      )}
    </footer>
  );
};
