import React from 'react';
import styled from 'styled-components';
import TocSection from './TocSection/TocSection';
import { TSlide } from '../../../../types';
import { GoToSlide } from '../../../../services/GoToSlide';
import { useUiConfigInfoPlacement } from '../../../../providers/providers/UiConfigInfoPlacementProvider';
import { createPortal } from 'react-dom';
import { useOrder } from '../../../../providers/providers/OrderProvider';
import { useSections } from '../../../../providers/providers/SectionsProvider';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: 50,
  padding: 30,
  background: '#fff',
  position: 'absolute',
  left: 0,
  right: 0,
  '@keyframes slideUp': {
    '0%': {
      bottom: -150,
    },
    '60%': {
      bottom: -100,
    },
    '100%': {
      bottom: 0,
    },
  },
  '@keyframes slideDown': {
    '0%': {
      top: -150,
    },
    '60%': {
      top: -100,
    },
    '100%': {
      top: 0,
    },
  },
  animationDuration: '300ms',
  animationTimingFunction: 'linear',
});

const Portal: React.NamedExoticComponent<{
  children: React.ReactNode;
  activeSlide: any;
}> = React.memo(({ children, activeSlide }) => {
  const rootModalEl = document.getElementById('presentation-toc-portal-' + activeSlide);
  const [mounted, setMounted] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (mounted) setMounted(true);
  });

  const el = React.useRef<null | HTMLDivElement>(null);
  if (!el.current) el.current = document.createElement('div');

  if (!rootModalEl) return null;

  return createPortal(children, rootModalEl);
});

Portal.displayName = 'Portalss';

interface Props {
  activeSlide: TSlide['id'];
  onClose: () => void;
}

const TableOfContent: React.FC<Props> = ({ activeSlide, onClose }) => {
  const { sections: orderedSections, sectionsSlide } = useOrder();
  const { sections } = useSections();

  const { infoPlacement } = useUiConfigInfoPlacement();

  const paddingProp = infoPlacement === 'top' ? 'paddingTop' : 'paddingBottom';

  const getPositions = () => {
    const headerHeight = 109;
    if (infoPlacement === 'top') return { top: headerHeight };
    return { bottom: headerHeight };
  };

  return (
    <Portal activeSlide={activeSlide}>
      <Container
        id='presentation-toc'
        onMouseLeave={onClose}
        style={{
          ...getPositions(),
          boxShadow:
            infoPlacement === 'bottom'
              ? '0px 0px 0px rgba(0, 0, 0, 0.0), 0px -4px 10px rgba(0, 0, 0, 0.15)'
              : '0px 4px 10px rgba(0, 0, 0, 0.15)',
          [paddingProp]: 80,
          animationName: infoPlacement === 'top' ? 'slideDown' : 'slideUp',
        }}
      >
        {orderedSections.map(section => (
          <TocSection
            key={section}
            {...sections[section]}
            slidesIds={sectionsSlide[section]}
            activeSlide={activeSlide}
            onSlideClick={slideId => {
              onClose();
              GoToSlide.dispatch(slideId as any);
            }}
          />
        ))}
      </Container>
    </Portal>
  );
};

export default TableOfContent;
