import React from 'react';
import { ClosingAndNextStepsAside } from '../../../components/common/ClosingAndNextStepsAside/ClosingAndNextStepsAside.component';
import { ClosingAndNextStepsTitle } from '../../../components/common/ClosingAndNextStepsTitle.component';
import { DownloadPdfButton } from '../../../components/common/DownloadPdfButton.componet';

import {
  Container,
  Content,
  ContentWrapper,
  DownloadPdfWrapper,
  Text,
} from './ListingFeedback.styles';
import { ListingFeedbackTable } from './ListingFeedbackTable/ListingFeedbackTable.component';

export const ListingFeedback: React.FC = () => {
  return (
    <Container>
      <ClosingAndNextStepsAside />
      <Content>
        <ContentWrapper>
          <ClosingAndNextStepsTitle />
          <Text>
            On a scale of &quot;Not Interested&quot; to &quot;Make An Offer&quot; - how would you
            rate each property?
          </Text>
          <ListingFeedbackTable />
          <DownloadPdfWrapper>
            <DownloadPdfButton />
          </DownloadPdfWrapper>
        </ContentWrapper>
      </Content>
    </Container>
  );
};
