import React from 'react';

import svgShape from './svgShape';
import { useSlide } from '../../../../../../providers/SlideProvider';
import { CoverContent } from '../../components/CoverContent/CoverContent';
import { svgStringToDataUrl } from '../../../../../../services/svgStringToDataUrl';
import { ContentContainer, ContentWrapper, Shape } from './TraditionalCoverGamma.styles';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalTwoBackground } from '../../../../../../components/TraditionalTwoBackground/TraditionalTwoBackground.component';

export const TraditionalCoverGamma: React.FC = () => {
  const shape = svgStringToDataUrl(svgShape, useUiConfigColorsPrimary());
  const slide = useSlide();

  const displayImage = slide?.data?.displayImage;

  return (
    <TraditionalTwoBackground>
      {!displayImage && <Shape src={shape} />}
      <ContentContainer>
        <ContentWrapper>
          <CoverContent />
        </ContentWrapper>
      </ContentContainer>
    </TraditionalTwoBackground>
  );
};
