import { THighlightedProperty, TReportSelectedCategory } from '../../../../../types';

export const extractMinMaxPrices = (
  categories: TReportSelectedCategory[],
  highlights: THighlightedProperty[],
  useSqft: boolean,
  useAdjPrice?: boolean,
) => {
  const mins = [];
  const maxs = [];

  const valuePropertyName = useSqft ? 'valuePerSqft' : 'value';

  if (categories && categories.length) {
    categories.sort((a, b) => (a[valuePropertyName] || 0) - (b[valuePropertyName] || 0));
    mins.push(+(categories[0][valuePropertyName] || 0));
    maxs.push(+(categories[categories.length - 1][valuePropertyName] || 0));
  }

  if (highlights && highlights.length) {
    const prices = highlights?.map(highlightedProperty => {
      if (useSqft && useAdjPrice) {
        return highlightedProperty?.adjustedPricePerSqft || highlightedProperty?.pricePerSqft;
      }

      if (useAdjPrice) {
        return highlightedProperty?.adjustedPrice || highlightedProperty.price;
      }

      if (useSqft) {
        return highlightedProperty?.pricePerSqft;
      }

      return highlightedProperty.price;
    });

    prices.sort((a, b) => a - b);
    mins.push(prices[0]);
    maxs.push(prices[prices.length - 1]);
  }

  return {
    min: Math.min(...mins),
    max: Math.max(...maxs),
  };
};
