import React from 'react';

import { useStep } from '../../../../../../../providers/providers/StepProvider';
import { CustomizationModalComponentStepTwo } from './CustomizationModalStepTwo.componen';
import { CustomizationModalComponentStepOne } from './CustomizationModalStepOne.component';

export const StepContent: React.FC = () => {
  const { step } = useStep();

  const ComponentsMap: Record<number, React.FC> = {
    1: CustomizationModalComponentStepOne,
    2: CustomizationModalComponentStepTwo,
  };

  const Component = ComponentsMap[step];
  if (!Component) return null;

  return <Component />;
};
