import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../../../components/Card/Card.component';
import { Typography } from '../../../../../components/Card/Card.styles';
import { useUser } from '../../../../../providers/UserProvider';
import { slideContent } from '../../data';
import { ValueOfAnAgentModernCanada } from '../../components/ValueOfAnAgentModernCanada/ValueOfAnAgentModernCanada';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '300px 300px 30px',
  rowGap: 20,
  columnGap: 20,
  padding: '4%',
});

const SourceText = styled(Typography)(() => ({
  gridColumn: '1 / -1',
  opacity: 0.6,
  fontWeight: 200,
  letterSpacing: 0.35,
}));

export const ValueOfAnAgentModern: React.FC = () => {
  const { isCanadaUser } = useUser();

  if (isCanadaUser) return <ValueOfAnAgentModernCanada />;

  return (
    <Grid>
      {slideContent.map((card, i) => (
        <Card key={i} {...card} primaryColorTitle={i === 0} />
      ))}
      <SourceText variant='body1' align='right' color='300'>
        Source: National Association of Realtors
      </SourceText>
    </Grid>
  );
};
