import React, { Dispatch, SetStateAction } from 'react';

import { Table, TableRow } from './AgentPerformanceTable.styles';
import { AgentPerformanceTableHead } from './AgentPerformanceTableHead.component';
import TooltipEllipsisText from './TooltipEllipsisText';

export type TAgentPerformanceSortOption =
  | 'name_asc'
  | 'name_dsc'
  | 'email_asc'
  | 'email_dsc'
  | 'lastDate_asc'
  | 'lastDate_dsc'
  | 'office_dsc'
  | 'office_asc'
  | 'reportsSent_asc'
  | 'reportsSent_dsc'
  | 'uniqueClients_asc'
  | 'uniqueClients_dsc'
  | 'uniqueAddresses_asc'
  | 'uniqueAddresses_dsc'
  | 'uniqueReports_asc'
  | 'uniqueReports_dsc'
  | 'openRate_asc'
  | 'openRate_dsc'
  | 'topSellerFeedback_asc'
  | 'topSellerFeedback_dsc'
  | 'topBuyerFeedback_asc'
  | 'topBuyerFeedback_dsc';

export type TAgentPerformance = {
  name: string;
  officeName: string;
  email: string;
  dateLastUsed: string;
  hash: string;
  reportsSent: number;
  openRate: string;
  topBuyerFeedback: string;
  topSellerFeedback: string;
  uniqueAddresses: number;
  uniquePresentations: number;
  uniqueClients: number;
};

interface AgentPerformanceTableProps {
  agentPerformanceData: TAgentPerformance[];
  office: number;
  setAgentPerformanceData: Dispatch<SetStateAction<TAgentPerformance[]>>;
  date: string[];
  type: string;
}

export const AgentPerformanceTable: React.FC<AgentPerformanceTableProps> = ({
  agentPerformanceData,
  office,
  setAgentPerformanceData,
  date,
  type,
}) => {
  return (
    <Table>
      <AgentPerformanceTableHead setAgentPerformanceData={setAgentPerformanceData} />
      {agentPerformanceData.map((agentPerformance, i) => {
        const {
          name,
          email,
          hash,
          dateLastUsed,
          reportsSent,
          openRate,
          officeName,
          uniqueAddresses,
          uniqueClients,
          uniquePresentations,
          topBuyerFeedback,
          topSellerFeedback,
        } = agentPerformance;

        return (
          <TableRow key={`${name}_${i}`}>
            <TooltipEllipsisText
              text={name}
              link={`/admin-analytics/user/${hash}/${office}/${type}/${date[0]}/${date[1]}`}
              justify='flex-start'
            />
            <TooltipEllipsisText text={email} />
            <TooltipEllipsisText text={officeName} />
            <TooltipEllipsisText text={reportsSent} />
            <TooltipEllipsisText text={dateLastUsed} />
            <TooltipEllipsisText text={uniqueClients} />
            <TooltipEllipsisText text={uniqueAddresses} />
            <TooltipEllipsisText text={uniquePresentations} />
            <TooltipEllipsisText text={openRate} />
            <TooltipEllipsisText text={topSellerFeedback} />
            <TooltipEllipsisText text={topBuyerFeedback} />
          </TableRow>
        );
      })}
    </Table>
  );
};
