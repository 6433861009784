import { AboutMe } from './web/AboutMe.component';
import { TSlideTypeMapping } from '../../../../../services/PredefinedSlideFactory';
import { TPersonalSlideExperienceDefaultText, TSlide } from '../../../../../../../types';
import { AboutMePdf } from './pdf/AboutMePdf';

const id = 'aboutMe';

const experienceDefaultText: TPersonalSlideExperienceDefaultText = {
  none: '',
  novice: '',
  experienced: '',
  seasoned: '',
};

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'About Me',
  data: {
    experienceLevel: 'none',
    text: experienceDefaultText.none,
    displayImage: false,
    image: 'https://via.placeholder.com/1920x1080/eee?text=16:9',
    experienceDefaultText,
  },
  isInteractive: true,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    shared: AboutMePdf,
  },
  web: {
    shared: AboutMe,
  },
};

export type TAboutMe = typeof id;
export { config, mappings };
