import React, { createContext, useContext, useState } from 'react';

import type { TPresentationType, TSlide } from '../../types';
import type { TDynamicSectionId } from '../../types';
import { usePresentationType } from './PresentationTypeProvider';

interface TCustomizationOrderContext {
  setCustomizationSectionSlidesOrderByType: (
    section: TDynamicSectionId,
    slides: Partial<TSlide>[],
    presentationType: TPresentationType,
  ) => void;

  customizationSectionsByType: Partial<Record<TPresentationType, TDynamicSectionId[]>>;
  setCustomizationSectionsByType: (
    sectionsByType: Partial<Record<TPresentationType, TDynamicSectionId[]>>,
  ) => void;
  customizationSectionsSlideByType: Partial<
    Record<TPresentationType, Record<TDynamicSectionId, Partial<TSlide>[]>>
  >;
  setCustomizationSectionsSlideByType: (
    sectionsSlideByType: Partial<
      Record<TPresentationType, Record<TDynamicSectionId, Partial<TSlide>[]>>
    >,
  ) => void;
}
const CustomizationOrderContext = createContext<TCustomizationOrderContext | undefined>(undefined);

export function useCustomizationOrder() {
  const context = useContext(CustomizationOrderContext);

  if (!context) {
    throw new Error('useCustomizationOrder must be used within a CustomizationOrderContext');
  }

  return context;
}

export const CustomizationOrderProvider: React.FC = function CustomizationOrderProvider({
  children,
}) {
  const [customizationSectionsByType, setCustomizationSectionsByType] = useState<
    Partial<Record<TPresentationType, TDynamicSectionId[]>>
  >({});

  const [customizationSectionsSlideByType, setCustomizationSectionsSlideByType] = useState<
    Partial<Record<TPresentationType, Record<TDynamicSectionId, Partial<TSlide>[]>>>
  >({});

  const setCustomizationSectionSlidesOrderByType = React.useCallback(
    function setCustomizationSectionSlidesOrder(
      section: TDynamicSectionId,
      slides: Partial<TSlide>[],
      presentationType: TPresentationType,
    ) {
      setCustomizationSectionsSlideByType(prev => {
        if (!prev?.[presentationType]?.[section] || !Array.isArray(slides)) return prev;

        return {
          ...prev,
          [presentationType]: {
            ...prev?.[presentationType],
            [section]: [...slides],
          },
        };
      });
    },
    [],
  );

  const value = React.useMemo(
    function memoizedOrder() {
      return {
        setCustomizationSectionsByType,
        setCustomizationSectionsSlideByType,
        customizationSectionsByType,
        customizationSectionsSlideByType,
        setCustomizationSectionSlidesOrderByType,
      };
    },
    [
      customizationSectionsByType,
      customizationSectionsSlideByType,
      setCustomizationSectionSlidesOrderByType,
    ],
  );

  return (
    <CustomizationOrderContext.Provider value={value}>
      {children}
    </CustomizationOrderContext.Provider>
  );
};
