import { TCategory, TSlideTheme } from '../types';
import { THEMES } from './themes';

const TRADITIONAL: TCategory = 'traditional';
const MODERN: TCategory = 'modern';
const LUXURY: TCategory = 'luxury';

export const CATEGORIES = {
  TRADITIONAL,
  MODERN,
  LUXURY,
} as const;

type TCategoryConfig = {
  defaultTheme: TSlideTheme;
  value: TCategory;
  label: string;
};
const categoryConfigs: Record<TCategory, TCategoryConfig> = {
  [CATEGORIES.TRADITIONAL]: {
    defaultTheme: THEMES.TRADITIONAL.DEFAULT,
    value: CATEGORIES.TRADITIONAL,
    label: 'Traditional',
  },
  [CATEGORIES.MODERN]: {
    defaultTheme: THEMES.MODERN.DEFAULT,
    value: CATEGORIES.MODERN,
    label: 'Modern',
  },
  [CATEGORIES.LUXURY]: {
    defaultTheme: THEMES.LUXURY.DEFAULT,
    value: CATEGORIES.LUXURY,
    label: 'Luxury',
  },
};

export const getCategoryConfigs = (category: TCategory): TCategoryConfig | null => {
  return categoryConfigs[category] ?? null;
};

export const getCategoriesConfig = (): TCategoryConfig[] => Object.values(categoryConfigs);
