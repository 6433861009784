import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { PROPERTY_STATUS, TCompConfigItem } from '../../../../types';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { PENDING, TRENDS_KEYS } from './trendsConfig';
import { TrendsSection } from './TrendsSection';

const pendingGroups = [
  [PENDING[TRENDS_KEYS.HIGHEST], PENDING[TRENDS_KEYS.AVERAGE], PENDING[TRENDS_KEYS.MEDIAN]],
  [PENDING[TRENDS_KEYS.LOWEST], PENDING[TRENDS_KEYS.CLOSEST], PENDING[TRENDS_KEYS.RECENT]],
] as TCompConfigItem[][];

export const PendingTrends = () => {
  const properties = useAtomValue(reportPropertiesAtom);

  const count = useMemo(() => {
    return properties?.filter(p => p.status === PROPERTY_STATUS.PENDING && !p.excluded)?.length;
  }, [properties]);

  return (
    <TrendsSection
      title='PENDING PROPERTIES'
      count={count}
      groups={pendingGroups}
      status={PROPERTY_STATUS.PENDING}
    />
  );
};
