import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { corePresentApi } from '../../../api/CorePresentApi';
import { DashboardInput, FitContentModal } from '../../../components/Common';
import Loader from '../../../components/Loader/Loader';
import { TClient } from '../../../types';
import { usePresentationType } from '../../../providers/providers/PresentationTypeProvider';
import { getDealType } from '../../../services/getDealType';
import { Button } from '../../Common/V2/Button/Button';

export const Container = styled('div')({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

export const AddClientLink = styled('p')(({ theme }) => ({
  color: theme.colors.primary.dark,
  fontSize: 13,
  cursor: 'pointer',
  textDecoration: 'none',
  marginTop: 20,
}));

interface AddClientFormProps {
  isOpen: boolean;
  onClose: () => void;
  setShowClientForm?: Dispatch<SetStateAction<boolean>>;
  setClient: (client: TClient) => void;
}

export const AddClientForm: React.FC<AddClientFormProps> = ({
  isOpen,
  onClose,
  setShowClientForm,
  setClient,
}) => {
  const { presentationType } = usePresentationType();

  const [clientName, setClientName] = useState('');
  const [clientLastName, setClientLastName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [errors, setErrors] = useState({
    first_name: [''],
    last_name: [''],
    email: [''],
  });

  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    if (!clientName) {
      setErrors(prev => ({
        ...prev,
        first_name: ["The client's name must be entered."],
      }));
    }
    if (!clientLastName) {
      setErrors(prev => ({
        ...prev,
        last_name: ["The client's last name must be entered."],
      }));
    }
    if (!clientEmail) {
      setErrors(prev => ({
        ...prev,
        email: ["The client's email must be entered."],
      }));
    }
  };

  const onConfirmClient = async () => {
    setLoading(true);
    if (!clientName || !clientLastName || !clientEmail) {
      validateForm();
      setLoading(false);
      return;
    }
    try {
      const client = await corePresentApi.post('/clients', {
        first_name: clientName,
        last_name: clientLastName,
        email: clientEmail,
        deal_type: getDealType(presentationType),
      });

      if (client.already_created) {
        toast(
          'That email is already assigned to an existing client. We have updated the name to the contact on file with the existing email address.',
          { type: 'info', position: 'bottom-center' },
        );
      }
      setClient(client);
      setLoading(false);
      onClose();
    } catch (e: any) {
      if (e.errors && Object.entries(e.errors).length > 0) {
        setErrors(e.errors);
        return;
      }
    }
    onClose();
  };

  const onInputChange = (
    errorField: 'first_name' | 'last_name' | 'email',
    setFieldValue: Dispatch<SetStateAction<string>>,
    value: string,
  ) => {
    setErrors({ ...errors, [errorField]: [''] });
    setFieldValue(value);
  };

  const BackBtn = !setShowClientForm ? null : (
    <Button onClick={() => setShowClientForm(false)} variant='link'>
      <FontAwesomeIcon style={{ fontSize: 10 }} icon={['fas', 'chevron-left']} /> Back
    </Button>
  );

  return (
    <FitContentModal
      isOpen={isOpen}
      headerTitle='Add Client'
      onClose={onClose}
      confirmBtnText='Add'
      hasCancelBtn
      onConfirmClick={onConfirmClient}
      contentStyles={{ height: '100%' }}
      prefixButtonsContent={BackBtn}
    >
      {loading ? (
        <Container style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loader size={'4x'} />
        </Container>
      ) : (
        <Container>
          <DashboardInput
            type='text'
            label='First Name'
            inputId='name'
            value={clientName}
            placeholder='First Name'
            onChange={e => {
              onInputChange('first_name', setClientName, e.target.value);
            }}
            errorMessage={errors.first_name[0]}
          />
          <DashboardInput
            type='text'
            label='Last Name'
            inputId='lastName'
            placeholder='Last Name'
            value={clientLastName}
            onChange={e => {
              onInputChange('last_name', setClientLastName, e.target.value);
            }}
            errorMessage={errors.last_name[0]}
          />
          <DashboardInput
            type='text'
            label='Email'
            inputId='email'
            placeholder='Email address'
            value={clientEmail}
            onChange={e => {
              onInputChange('email', setClientEmail, e.target.value);
            }}
            errorMessage={errors.email[0]}
          />
        </Container>
      )}
    </FitContentModal>
  );
};
