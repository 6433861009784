import { useAtom, useAtomValue } from 'jotai';
import styled from 'styled-components';

import { SidebarTitle } from '../components/SidebarTitle';
import { ButtonsGroup } from '../../../../components/Common/V2/ButtonsGroup';
import { Disclaimer } from '../../components/Disclaimer';
import { OnMarketFeatures } from './on-market-features/OnMarketFeatures';
import { showDiffValuesAtom } from './state/showDiffValuesAtom';
import { OffMarketFeatures } from './off-market-features/OffMarketFeatures';
import { useHasComparableAdjustments } from '../../../../hooks/useHasComparableAdjustments';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';

interface Props {
  isOffMarket: boolean;
  setIsOffMarket: (value: boolean) => void;
}

export const FeaturesSidebarContent = ({ isOffMarket, setIsOffMarket }: Props) => {
  const hasAdjustments = useHasComparableAdjustments();
  const [showDiffValues, setShowDiffValues] = useAtom(showDiffValuesAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primary = useUiConfigColorsPrimary();

  const bgColorStyle = isPresentationView ? { backgroundColor: primary } : {};

  const statusButtons = [
    {
      label: 'Active/Pending',
      onClick: () => setIsOffMarket(false),
      isActive: !isOffMarket,
      style: !isOffMarket ? bgColorStyle : {},
    },
    {
      label: 'Closed',
      onClick: () => setIsOffMarket(true),
      isActive: isOffMarket,
      style: isOffMarket ? bgColorStyle : {},
    },
  ];

  const featuresButtons = [
    {
      label: 'Show All Features',
      onClick: () => setShowDiffValues(true),
      isActive: showDiffValues,
      style: showDiffValues ? bgColorStyle : {},
    },
    {
      label: 'Show Only Comparable Features',
      onClick: () => setShowDiffValues(false),
      isActive: !showDiffValues,
      style: !showDiffValues ? bgColorStyle : {},
    },
  ];

  return (
    <Container>
      <div>
        <SidebarTitle title='FEATURES' iconName='house_flag' />
        <Wrapper>
          <ButtonsGroup buttonsConfigs={statusButtons} groupWidth='244px' />
          <ButtonsGroup buttonsConfigs={featuresButtons} />
        </Wrapper>
        {isOffMarket ? <OffMarketFeatures /> : <OnMarketFeatures />}
      </div>
      {!hasAdjustments ? null : (
        <Disclaimer text="This section is using the actual price. Click into the property to see it's adjusted price." />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;
