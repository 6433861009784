import React, { useContext, useState } from 'react';
import { TSlide } from '../../types';

type CategoryContextType = {
  selectedSlide: TSlide | null | undefined;
  setSelectedSlide: (value?: TSlide | null) => void;
  setSlideDataProperty: (property: string, value: any) => void;
  setSlideFrame: (value: boolean) => void;
};
const SelectedSlideContext = React.createContext<CategoryContextType | undefined>(undefined);

export function useSelectedSlide() {
  const context = useContext(SelectedSlideContext);

  if (!context) {
    throw Error('useSelectedSlide cannot be used without SelectedSlideContext');
  }

  return context;
}

export const SelectedSlideProvider: React.FC = ({ children }) => {
  const [selectedSlide, setSelectedSlide] = useState<TSlide | undefined | null>(undefined);

  const setSlideDataProperty = React.useCallback((property: string, value: any) => {
    setSelectedSlide(prev => {
      if (!prev) return undefined;

      return {
        ...prev,
        data: { ...prev.data, [property]: value },
      };
    });
  }, []);

  const setSlideFrame = React.useCallback((value: boolean) => {
    setSelectedSlide(prev => {
      if (!prev) return undefined;

      return {
        ...prev,
        frame: value,
      };
    });
  }, []);

  const value = React.useMemo(
    () => ({
      selectedSlide,
      setSelectedSlide,
      setSlideDataProperty,
      setSlideFrame,
    }),
    [selectedSlide, setSlideDataProperty, setSlideFrame],
  );

  return <SelectedSlideContext.Provider value={value}>{children}</SelectedSlideContext.Provider>;
};
