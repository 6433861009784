import React from 'react';

import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper, LeftCol, RightCol } from './OneImageEpsilon.styles';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';

interface Props {
  reverse?: boolean;
}

export const OneImageEpsilonForm: React.FC<Props> = ({ reverse }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  const onChangeText = (value: string) => {
    setSlideDataProperty('text', value);
  };

  if (!selectedSlide) return null;

  return (
    <Container style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
      <LeftCol style={{ alignItems: reverse ? 'flex-end' : 'flex-start' }}>
        <ImageWrapper>
          <UploadImage
            image={selectedSlide.data?.image}
            slideDataConfigPropertyName={'image'}
            imageSizeSuggestion='624x410'
          />
        </ImageWrapper>
      </LeftCol>
      <RightCol style={{ alignItems: !reverse ? 'flex-end' : 'flex-start' }}>
        <SlideTextBlock
          isEditable
          text={selectedSlide.data?.text}
          headline={selectedSlide.data?.headline}
          onChangeText={onChangeText}
          onChangeHeadline={onChangeHeadline}
          containerStyles={{ minWidth: '100%', height: '70%' }}
        />
      </RightCol>
    </Container>
  );
};
