import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { corePresentApi } from '../../../../../api/CorePresentApi';
import { TPolygonDetails } from '../../../../../types';

const fetchPolygonDetails = async (polygonId: string, areaType?: string): Promise<any | null> => {
  const response = await corePresentApi.get(
    `/polygonDetail?type=${areaType}&polygonId=${polygonId}`,
  );

  return response;
};

export function usePolygonDetails(
  polygonId?: string | null,
  areaType?: string,
): UseQueryResult<TPolygonDetails> {
  return useQuery(
    ['polygonDetails', polygonId],
    () => fetchPolygonDetails(polygonId ?? '', areaType),
    {
      enabled: Boolean(polygonId),
      refetchOnWindowFocus: false,
    },
  );
}
