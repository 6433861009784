import React from 'react';
import styled from 'styled-components';

import { usePreviewMode } from '../providers/PreviewModeProvider';
import { HeaderLabel } from './PresentationSettings/components/HeaderLabel';
import { ButtonsGroup } from '../../../../components/Common/V2/ButtonsGroup';

export const PresentationPreview: React.FC = () => {
  const { previewMode, setPreviewMode } = usePreviewMode();

  const options = [
    {
      label: 'Web',
      onClick: () => setPreviewMode('web'),
      isActive: previewMode === 'web',
    },
    {
      label: 'PDF',
      onClick: () => setPreviewMode('pdf'),
      isActive: previewMode === 'pdf',
    },
  ];

  return (
    <Container>
      <HeaderLabel>Preview:</HeaderLabel>
      <ButtonsGroup buttonsConfigs={options} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
`;
