import { TPresentationType, TSlide } from '../../../../../../types';
import { usePreviewMode } from '../../../providers/PreviewModeProvider';
import { PRESENTATION_TYPES } from '../../../../../../constants/PresentationTypes';
import { useOrder } from '../../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { usePresentationMode } from '../../../../../../providers/providers/PresentationModeProvider';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';
import { useActiveSection } from '../../../../../../components/Slide/providers/ActiveSectionProvider';
import { config as sectionContentsSlide } from '../../../../../../components/Slide/slides/sectionContents';

function useHasSectionsContentSlides() {
  const { previewMode } = usePreviewMode();
  const { presentationMode } = usePresentationMode();
  const { presentationType } = usePresentationType();

  if (!presentationMode || !presentationType || previewMode !== 'pdf') return false;

  const supportedModes = ['regular'];
  const supportedTypes: TPresentationType[] = [
    PRESENTATION_TYPES.WIN_THE_LISTING,
    PRESENTATION_TYPES.WIN_THE_OFFER,
    PRESENTATION_TYPES.PRESENT_INFO,
  ];

  return supportedModes.includes(presentationMode) && supportedTypes.includes(presentationType);
}

function getNextSlide(filteredSlides: TSlide[], currentSlide: string): TSlide | null {
  const selectedSIndex = filteredSlides.findIndex(s => s?.id === currentSlide);
  const isLastSlide = selectedSIndex === filteredSlides.length - 1;
  const isMissingNextSlide = selectedSIndex === -1 || !filteredSlides[selectedSIndex + 1];

  if (isLastSlide || isMissingNextSlide) return null;

  return filteredSlides[selectedSIndex + 1] ?? null;
}

function getPrevSlide(filteredSlides: TSlide[], currentSlide: string): TSlide | null {
  const selectedSIndex = filteredSlides.findIndex(s => s?.id === currentSlide);
  const isFirstSlide = selectedSIndex === 0;
  const isMissingPrevSlide = selectedSIndex === -1 || !filteredSlides[selectedSIndex - 1];

  if (isFirstSlide || isMissingPrevSlide) return null;

  return filteredSlides[selectedSIndex - 1] ?? null;
}

export function useSelectedSlideNavigators(orderedSlides: TSlide[]) {
  const {
    findSlideSection,
    sections: sectionsOrder,
    sectionsSlide: sectionsSlideOrder,
  } = useOrder();

  const { slides } = useSlides();
  const { activeSection, setActiveSection } = useActiveSection();
  const { selectedSlide, setSelectedSlide } = useSelectedSlide();

  const hasSectionContentSlides = useHasSectionsContentSlides();

  function isSectionContentsInFocus() {
    return selectedSlide?.id === 'sectionContents' && activeSection;
  }

  function focusFirstSlideInActiveSection() {
    if (!activeSection) return;

    const [firstSlide] = sectionsSlideOrder[activeSection];
    setSelectedSlide(slides?.[firstSlide]);
  }

  function getSlidePositionInSection(slideId: string) {
    const section = findSlideSection(slideId);
    const slidesInSection = sectionsSlideOrder[section ?? ''] ?? [];

    return slidesInSection.findIndex(sId => sId === slideId);
  }

  function shouldFocusSectionContents(positionInSection?: number) {
    return hasSectionContentSlides && positionInSection === 0;
  }

  function getPrevSection() {
    if (!activeSection) return null;

    const currentSectionPosition = sectionsOrder.findIndex(sId => sId === activeSection);
    if (currentSectionPosition === -1 || currentSectionPosition < 1) return null;

    return sectionsOrder[currentSectionPosition - 1];
  }

  function focuseLastSlideInPrevSection() {
    const prevSection = getPrevSection();
    if (!prevSection) return;

    const [lastSlide] = [...sectionsSlideOrder[prevSection]].reverse();

    setActiveSection(prevSection);
    setSelectedSlide(slides?.[lastSlide]);
  }

  const onNext = () => {
    if (!selectedSlide || !slides) return;

    if (isSectionContentsInFocus()) {
      focusFirstSlideInActiveSection();
      return;
    }

    const nextSlide = getNextSlide(orderedSlides, selectedSlide.id);
    if (!nextSlide) return;

    const nextSlideSection = findSlideSection(nextSlide.id);
    const positionInSection = getSlidePositionInSection(nextSlide.id);

    if (shouldFocusSectionContents(positionInSection)) {
      setActiveSection(nextSlideSection);
      setSelectedSlide(sectionContentsSlide);
      return;
    }

    setSelectedSlide(nextSlide);
  };

  const onBack = () => {
    if (!selectedSlide || !slides) return;

    if (isSectionContentsInFocus()) {
      focuseLastSlideInPrevSection();
      return;
    }

    const positionInSection = getSlidePositionInSection(selectedSlide.id);
    if (shouldFocusSectionContents(positionInSection)) {
      setSelectedSlide(sectionContentsSlide);
      return;
    }

    const prevSlide = getPrevSlide(orderedSlides, selectedSlide.id);
    if (!prevSlide) return;

    setSelectedSlide(prevSlide);
  };

  return { onNext, onBack };
}
