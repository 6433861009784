import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  children: ReactNode;
}

export const HeaderCard = ({ title, children }: Props) => {
  return (
    <Card>
      <Title>{title}</Title>
      {children}
    </Card>
  );
};

const Card = styled.div`
  flex: 1;
  padding: 20px 15px;
  background-color: #fff;
  border-radius: 10px;
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};

  @media (max-width: 991px) {
    flex: 0 1 auto;
    width: 100%;
  }
`;

const Title = styled.h6`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: center;
  margin-bottom: 23px;
  font-weight: 700;
`;
