import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import ComparativeMarketAnalysis from './web/ComparativeMarketAnalysis';
import ComparativeMarketAnalysisPdf from './pdf/ComparativeMarketAnalysisPdf';

const id = 'comparativeMarketAnalysis';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'CMA - Overview',
  frame: true,
  isInteractive: true,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    shared: ComparativeMarketAnalysisPdf,
  },
  web: {
    shared: ComparativeMarketAnalysis,
  },
};

export type TComparativeMarketAnalysis = typeof id;
export { config, mappings };
