import { ITestimonial, TSlide } from '../../../../../../../types';
import { TSlideTypeMapping } from '../../../../../services/PredefinedSlideFactory';
import { MyTestimonialsPdf } from './pdf/MyTestimonialsPdf';
import { MyTestimonials } from './web/MyTestimonials.component';

const id = 'testimonials';

const testimonialsData: ITestimonial[] = [
  { id: 'testimonial_1', text: '', client: '', isHidden: false },
  { id: 'testimonial_2', text: '', client: '', isHidden: false },
  { id: 'testimonial_3', text: '', client: '', isHidden: false },
];

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'My Testimonials',
  data: {
    testimonials: testimonialsData,
    links: [],
  },
  isInteractive: true,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    shared: MyTestimonialsPdf,
  },
  web: {
    shared: MyTestimonials,
  },
};

export type TMyTestimonials = typeof id;
export { config, mappings };
