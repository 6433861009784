import { useEffect } from 'react';
import { useSetAtom } from 'jotai/react';
import { presentationHashAtom } from './presentationHashAtom';

export function useSetPresentationHash(hash?: string) {
  const setPresentationHash = useSetAtom(presentationHashAtom);

  useEffect(() => {
    setPresentationHash(hash || '');
  }, [hash, setPresentationHash]);
}
