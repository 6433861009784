import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PillsPlaceholder } from '../Pills';
import classes from '../AdrSection.module.scss';
import { ManualReportMap } from './ManualReportMap';
import { LoadingOverlay } from './LoadingOverlay';
import { SearchCombiner } from '../../../SearchCombiner';

export const ManualArea = ({
  partialLoading,
  partialData,
  onChange,
  exclusions,
  setExclusions,
  additional,
  setAdditional,
  getAdditional,
  getExclusions,
  excludeComp,
  addComp,
  setSelectedProperty,
  setIsModalOpen,
}) => {
  const searchCriteria = useSelector(({ search }) => search?.searchCriteria);
  const { coordinates } = searchCriteria;

  if (!coordinates) return;

  const defaultCenter = {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  };

  const onCombine = () => ({
    additional,
    exclusions,
  });

  useEffect(() => {
    setExclusions(getExclusions('exclusions'));
    setAdditional(getAdditional('additional'));
  }, []);

  useEffect(() => {
    SearchCombiner.add('map', onCombine);
    return () => SearchCombiner.remove('map', onCombine);
  });

  useEffect(() => {
    onChange(SearchCombiner.combine());
  }, [exclusions, additional]);

  return (
    <div className={classes.adrSection}>
      <PillsPlaceholder />
      <LoadingOverlay loading={partialLoading}>
        <ManualReportMap
          key={'manual'}
          defaultCenter={defaultCenter}
          subjectSize={searchCriteria.size || searchCriteria.sizeInit}
          partialData={partialData}
          style={{
            height: `500px`,
            width: `100%`,
          }}
          onExclude={excludeComp}
          onAdd={addComp}
          setSelectedProperty={setSelectedProperty}
          setIsModalOpen={setIsModalOpen}
        />
      </LoadingOverlay>
    </div>
  );
};
