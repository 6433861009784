import styled from 'styled-components';

interface WithOpen {
  open: 0 | 1;
}

interface BodyEl extends WithOpen {
  contentheight?: number | string;
}

export const Body = styled('div')<BodyEl>(({ theme, contentheight, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  transition: 'height .4s, border .2s linear',
  height: open ? contentheight : 0,
  position: 'relative',
  backgroundColor: '#fff',
}));

export const BodyContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const TheContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 20,
  '&::-webkit-scrollbar': {
    width: '.3em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colors.gray[200],
    outline: 'none',
  },
  'scroll-behavior': 'smooth',
}));
