import React from 'react';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  Container,
  Heading,
  LeftCol,
  RightCol,
  Graphic,
  H2,
  Text,
  UL,
  LI,
  SourceText,
} from './WhyPricingCorrectlyIsImportantModern.styles';
import svgString from '../../assets/slideGraphics';
import svgStringCanada from './slideGraphicsCanada';
import * as data from '../../data';
import { useUser } from '../../../../../providers/UserProvider';

export const WhyPricingCorrectlyIsImportantModern: React.FC = () => {
  const { isCanadaUser } = useUser();
  const primary = useUiConfigColorsPrimary();

  const graphic = isCanadaUser ? svgStringCanada : svgString;

  const image = React.useMemo(() => svgStringToDataUrl(graphic, primary), [graphic, primary]);
  return (
    <Container>
      <LeftCol>
        <Heading>
          {isCanadaUser
            ? 'ATTRACT MORE BUYERS WHEN PRICED CORRECTLY'
            : 'Percentage of Potential Buyers when priced correctly'}
        </Heading>
        <Graphic src={image} isCanadaUser={isCanadaUser} />
      </LeftCol>
      <RightCol>
        <H2>{data.heading}</H2>
        <Text>{data.paragraphs[0]}</Text>
        <H2>{data.paragraphs[1]}</H2>
        <UL>
          {data.listItems.map(li => (
            <LI key={li}>{li}</LI>
          ))}
        </UL>
      </RightCol>
      {!isCanadaUser && <SourceText>Source: Zillow Listing Data</SourceText>}
    </Container>
  );
};
