import React, { useContext, useState } from 'react';

const PRESENTATION_BUILD_FLOW = {
  ALL_STEPS: 'all_steps',
  ADDRESS_TO_PRESENTATION: 'address_to_presentation',
};

const PresentationBuildFlowContext = React.createContext(PRESENTATION_BUILD_FLOW.ALL_STEPS);

export const usePresentationBuildFlowConstants = () => PRESENTATION_BUILD_FLOW;

export const usePresentationBuildFlow = () => {
  const context = useContext(PresentationBuildFlowContext);

  if (!context) {
    throw new Error('usePresentationBuildFlow cannot be used without PresentationBuildFlowContext');
  }

  return context;
};

export const PresentationBuildFlowProvider = ({
  initValue = PRESENTATION_BUILD_FLOW.ALL_STEPS,
  children,
}) => {
  const [presentationBuildFlow, setPresentationBuildFlow] = useState(initValue);

  const value = React.useMemo(() => [presentationBuildFlow, setPresentationBuildFlow], [
    presentationBuildFlow,
  ]);

  return (
    <PresentationBuildFlowContext.Provider value={value}>
      {children}
    </PresentationBuildFlowContext.Provider>
  );
};
