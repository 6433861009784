import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { pricingRateOfChangeAtom } from '../../state/pricingRateOfChangeAtom';

export function usePricingRateOfChangeData() {
  const data = useAtomValue(pricingRateOfChangeAtom);

  return useMemo(() => {
    const props = data?.props || [];
    const text = data?.text;

    return { props, text };
  }, [data]);
}
