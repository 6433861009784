import React from 'react';
import { propertyTypeOptions } from '../../../PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';
import { Autocomplete } from '../../../../../../../../../../components/Common/Autocomplete/Autocomplete';

export const PropertyTypeSelector = ({ propertyType, onChange, className = '' }) => {
  const getPropertyType = async () => [...propertyTypeOptions];

  const onPropertyTypeChange = pt => {
    const propertyTypeOption = propertyTypeOptions.find(({ label }) => label === pt);

    onChange(propertyTypeOption?.value ?? '');
  };

  if (!propertyType) return null;

  const selectedLabel =
    propertyTypeOptions.find(({ value }) => value === propertyType)?.label ?? '';

  return (
    <Autocomplete
      label='Property Type'
      value={selectedLabel}
      onChange={onPropertyTypeChange}
      asyncOptionsProvider={getPropertyType}
      className={className}
      suggestions={propertyTypeOptions}
    />
  );
};
