import pinA from '../assets/icons/map/pin-a.svg?url';
import pinAGray from '../assets/icons/map/pin-gray-a.svg?url';
import pinP from '../assets/icons/map/pin-p.svg?url';
import pinPGray from '../assets/icons/map/pin-gray-p.svg?url';
import pinC from '../assets/icons/map/pin-c.svg?url';
import pinCGray from '../assets/icons/map/pin-gray-c.svg?url';
import pinCS from '../assets/icons/map/pin-coming_soon.svg?url';
import pinCSGray from '../assets/icons/map/pin-gray-coming_soon.svg?url';
import pinCSBT from '../assets/icons/map/pin-coming_soon_bt.svg?url';
import pinCSBTGray from '../assets/icons/map/pin-gray-coming_soon_bt.svg?url';

export const getMapPinBasedOnStatus = ({
  status,
  isGray,
  hasBtTheme,
}: {
  status: string;
  isGray?: boolean;
  hasBtTheme?: boolean;
}) => {
  const activeIcon = isGray ? pinAGray : pinA;
  const closedIcon = isGray ? pinCGray : pinC;
  const pendingIcon = isGray ? pinPGray : pinP;
  const comingSoonCPIcon = isGray ? pinCSGray : pinCS;
  const comingSoonBTIcon = isGray ? pinCSBTGray : pinCSBT;
  const comingSoonIcon = hasBtTheme ? comingSoonBTIcon : comingSoonCPIcon;

  if (status === 'a') return activeIcon;
  if (status === 'c') return closedIcon;
  if (status === 'p') return pendingIcon;
  if (status === 'coming_soon') return comingSoonIcon;

  return '';
};
