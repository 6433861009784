import React from 'react';
import styled from 'styled-components';

import { HomePreview } from './HomePreview/HomePreview';
import { TProperty } from '../../../../../../../../../types';

const Container = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const List = styled.ul`
  overflow: hidden;
  transition: all 0.5s ease;
  margin-bottom: 10px;
  list-style-type: none;
  max-height: none;
`;

interface Props {
  properties: TProperty[];
  propertiesRef: React.RefObject<HTMLElement>;
}
export const HomesGroup: React.FC<Props> = ({ properties, propertiesRef }) => {
  return (
    <Container>
      <List>
        {properties
          .filter(property => !property.excluded)
          .map(property => (
            <HomePreview
              excluded={!!property.excluded}
              key={property.id}
              property={property}
              propertiesRef={propertiesRef}
            />
          ))}
      </List>
    </Container>
  );
};
