import React, { useEffect } from 'react';
import { useUser } from '../Slide/providers/UserProvider';

export const DelightedTracking: React.FC = () => {
  const user = useUser();

  useEffect(() => {
    if (!user || (window as any).delighted) {
      return;
    }

    const $script = document.createElement('script');
    $script.type = 'text/javascript';
    $script.async = true;
    const scriptText = document.createTextNode(`
    !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"4R1nH721T4PsJNwE","delighted");
    delighted.survey({
      email: "${user?.email}", 
      name: "${user?.name}",              
      properties: {                      
        questionProductName: "CORE Present",
        account_id: "${user?.accountId}" 
      }
    });`);
    $script.appendChild(scriptText);
    document.head.appendChild($script);
    return () => {
      document.head.removeChild($script);

      const $delightedDiv = document.querySelector('.delighted-web.delighted-web-desktop');
      if (!$delightedDiv) return;

      ($delightedDiv as HTMLElement).style.visibility = 'hidden';
    };
  }, [user]);

  return <React.Fragment />;
};
