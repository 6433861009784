import React from 'react';
import { useSelector } from 'react-redux';

import styles from './AddressOverview.module.scss';

export const AddressOverview = () => {
  const address = useSelector(({ search }) => search.address);

  return (
    <h4 className={styles.addressOverview}>
      {address.address},
      <span>
        {address.city}, {address.state.value}
      </span>
    </h4>
  );
};
