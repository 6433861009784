import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';
import image from './image.png';
import * as data from '../../data';

export const PurposeOfBuyersAgentLuxury = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading={data.heading}
      subtitle={data.subtitle}
      listItems={data.data}
      imagePosition='right'
      image={image}
      contentStyles={{ fontSize: 18, lineHeight: 1.3 }}
      textContainerStyles={{ padding: 0 }}
    />
  );
};
