import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  overflow: 'visible',
  marginBottom: 0,
  zIndex: 8,
  paddingRight: useReverseHeader() ? 40 : 20,
  paddingLeft: useReverseHeader() ? 20 : 0,
  flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
}));

export const BrokerInfoWrapper = styled('div')({
  position: 'relative',
  zIndex: 6,
  width: '33.33%',
  paddingLeft: '3%',
});

export const Graphics = styled('img')({
  width: 1600,
});

export const Line = styled('div')(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  content: '" "',
  height: 4,
  backgroundColor: useUiConfigColorsPrimary(),
  zIndex: 7,
  width: '100%',
}));

export const BackgroundWrapper = styled('div')({
  backgroundColor: '#fff',
  position: 'absolute',
  left: 0,
  width: 1600,
  zIndex: 6,
  height: 139.5,
});
