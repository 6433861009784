import React from 'react';
import { useAtomValue } from 'jotai';

import { CATEGORIES } from '../../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { SlideWithGraphWrapperPdf } from '../../../../../components/common/SlideWithGraphWrapperPdf';

import { SellingPaceChart } from '../../../../../../../../features/report/charts/selling-pace/SellingPaceChart';
import { sellingPaceAtom } from '../../../../../../../../features/report/state/sellingPaceAtom';
import { sellingPaceData } from '../data';
import { useSellingPaceTookTimeMessage } from '../hooks/useSellingPaceTookTimeMessage';

const SellingPacePdf: React.FC = () => {
  const { category } = useCategory();
  const { heading, description } = sellingPaceData;

  const data = useAtomValue(sellingPaceAtom);

  const subtitle = useSellingPaceTookTimeMessage(data);

  const sellingPaceNum = data?.median_of_all || data?.average_of_all;

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SlideWithGraphWrapperPdf
        heading={heading}
        title={sellingPaceNum ? String(sellingPaceNum) : ''}
        subtitle={subtitle}
        description={description}
      >
        <SellingPaceChart style={{ height: 470 }} />
      </SlideWithGraphWrapperPdf>
    </PdfFrame>
  );
};

export default SellingPacePdf;
