import styled from 'styled-components';
import { Typography } from '../../../../../../components/Common';
import { media } from '../../../../../../config/themes';
export { Typography };

export const ColRightItem = styled('div')({
  display: 'flex',
  flex: 1,
});

export const ColLeft = styled.div`
  width: 24%;
`;

export const ColRight = styled.div`
  width: 76%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  ${media.tablet`
    gap: 30px;
  `}
`;
