export default `<svg width="481" height="378" viewBox="0 0 481 378" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M240.313 241.373L80.9673 295.503M240.313 241.373L73 223.563M240.313 241.373L96.1989 155.138M240.313 241.373L154.782 97.4927M240.313 241.373V73.5908M240.313 241.373L328.422 98.6643M240.313 241.373L385.365 157.247M240.313 241.373L407.86 224.735M240.313 241.373L399.19 295.503" stroke="#DDDDDD" stroke-width="2"/>
<circle cx="240.71" cy="241.529" r="132.796" stroke="#027B76" stroke-width="6"/>
<circle cx="46.6012" cy="307.454" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
<circle cx="433.736" cy="307.922" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
<circle cx="37.1895" cy="218.408" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
<circle cx="443.99" cy="218.408" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
<circle cx="64.3722" cy="137.797" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
<circle cx="416.807" cy="137.797" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
<circle cx="135.609" cy="66.5606" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
<circle cx="240.59" cy="37.5035" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
<circle cx="346.039" cy="66.5606" r="35.7901" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="2"/>
</svg>
`;
