import React, { CSSProperties, ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { corePresentApi } from '../../../api/CorePresentApi';
import { TClient } from '../../../types';
import { Autocomplete } from '../Autocomplete/Autocomplete';

type TClientOption = {
  email: string;
  id: number | string;
  name: string;
  label: string;
  value: number;
  autoComplete: string;
  onChange: (event: FormEvent<HTMLElement>, params: ChangeEvent) => void;
};

interface AutosuggestClientsProps {
  setClient: (client: TClient | null) => void;
  initialValue?: string;
  placeholder?: string;
  suggestionsContainerStyles?: CSSProperties;
}

export const AutosuggestClients: React.FC<AutosuggestClientsProps> = ({
  setClient,
  initialValue,
  placeholder,
  suggestionsContainerStyles = {},
}) => {
  const [value, setValue] = useState(initialValue ?? '');
  const [error, setError] = useState('');
  const [suggestions, setSuggestions] = useState<TClientOption[]>([]);

  useEffect(() => {
    if (!initialValue) return;
    setValue(initialValue);
    setError('');
  }, [initialValue]);

  const getClientOptions = async (value: any) => {
    const val = value;
    try {
      const data = await corePresentApi.get(`/clients?query=${val}`);

      const autocompleteClients = data.map((c: TClient) => ({
        ...c,
        label: c.name,
        value: c.id,
      }));

      setError(!data.length ? 'Client not found' : '');
      setSuggestions(autocompleteClients);
    } catch (e: any) {
      console.error(e);
    }
  };

  const onSuggestionSelected = (suggestion: TClientOption) => {
    setClient(suggestion as TClient);
  };

  const onChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      asyncOptionsProvider={getClientOptions}
      onSuggestionSelected={onSuggestionSelected}
      error={error}
      placeholder={placeholder}
      suggestions={suggestions}
      suggestionsContainerStyles={{
        position: 'relative',
        maxHeight: '180px',
        ...suggestionsContainerStyles,
      }}
    />
  );
};
