import React, { useEffect, useState } from 'react';
import { AnalyticsGraphCard } from '../components/AnalyticsGraphsCard/AnalyticsGraphsCard.component';
import { GraphBars, TGraphData } from '../components/GraphBars/GraphBars.component';

export type TReportOpenRate = {
  averageOpenRate?: string | number;
  reportOpenRateData: TGraphData[];
};

interface ReportOpenRateProps {
  reportsOpenRate: TReportOpenRate;
  isWithAgentData?: boolean;
}

export const ReportOpenRate: React.FC<ReportOpenRateProps> = ({
  reportsOpenRate,
  isWithAgentData,
}) => {
  const [reportsData, setReportsData] = useState<TGraphData[]>([]);

  useEffect(() => {
    setReportsData(reportsOpenRate.reportOpenRateData);
  }, [reportsOpenRate.reportOpenRateData]);

  const onCheckboxClick = (showOfficeData: boolean) => {
    if (showOfficeData) {
      setReportsData(reportsOpenRate.reportOpenRateData);
    } else {
      const filteredData = reportsOpenRate.reportOpenRateData.map(reportDataItem => {
        return {
          month: reportDataItem.month,
          agent: reportDataItem.agent,
        };
      });
      setReportsData(filteredData);
    }
  };
  return (
    <AnalyticsGraphCard
      height={175}
      title='Report Open Rate'
      isWithAgentData={isWithAgentData}
      onCheckboxClick={onCheckboxClick}
      subtitle={
        reportsOpenRate.averageOpenRate
          ? `Average open rate: ${reportsOpenRate.averageOpenRate} %`
          : ''
      }
      cardContainerStyles={{ justifyContent: 'space-between' }}
    >
      <GraphBars graphData={reportsData} isPercentageValue graphMaxValue={100} />
    </AnalyticsGraphCard>
  );
};
