import styles from './Footer.module.scss';
import { URLS } from '../../../../../constants/URLS';
import { useHistory } from 'react-router-dom';
import {
  usePresentationBuildFlow,
  usePresentationBuildFlowConstants,
} from '../../../../../../ReportFacade/providers/PresentationBuildFlowProvider';
import { Button } from '../../../../../../../../../components/Common/V2/Button/Button';

export const Footer = ({
  nextText = 'Next',
  onNext = f => f,
  loadingNext = false,
  disabledNext = false,
  prevText = 'Previous',
  onPrev = f => f,
  loadingPrev = false,
  disabledPrev = false,
  hidePrev = false,
  className = '',
}) => {
  const FLOWS = usePresentationBuildFlowConstants();
  const [flow, setFlow] = usePresentationBuildFlow();
  const history = useHistory();
  const showButton = window.location.pathname.endsWith('details');

  const onPresentation = () => {
    setFlow(FLOWS.ADDRESS_TO_PRESENTATION);
    onNext();
  };

  return (
    <footer className={`${styles.footer} ${className}`}>
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        {!hidePrev && !showButton && (
          <Button
            text={prevText}
            onClick={() => history.push(`${URLS.SEARCH.ADDRESS}?new=true`)}
            disabled={disabledPrev}
            isLoading={loadingPrev}
          >
            {prevText}
          </Button>
        )}
        <Button
          onClick={() => onNext(false)}
          disabled={disabledNext}
          isLoading={loadingNext && flow === FLOWS.ALL_STEPS}
          style={{ minWidth: 132 }}
        >
          {nextText}
        </Button>
      </div>
      {showButton && (
        <div style={{ marginLeft: 'auto', marginTop: 8, display: 'flex', alignItems: 'center' }}>
          {!hidePrev && (
            <Button
              text={prevText}
              onClick={onPrev}
              disabled={disabledPrev}
              isLoading={loadingPrev}
            >
              {prevText}
            </Button>
          )}
          <Button
            variant='secondary'
            onClick={onPresentation}
            disabled={loadingNext || loadingPrev || disabledPrev}
            isLoading={(loadingNext || loadingPrev) && flow === FLOWS.ADDRESS_TO_PRESENTATION}
          >
            Skip To Presentation
          </Button>
        </div>
      )}
    </footer>
  );
};
