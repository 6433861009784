import React from 'react';

import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { Container } from './ThreeImagesAlpha.styles';

interface Props {
  reverse?: boolean;
}
export const ThreeImagesAlphaForm: React.FC<Props> = ({ reverse }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  const onChangeText = (value: string) => {
    setSlideDataProperty('text', value);
  };

  if (!selectedSlide) return null;

  return (
    <Container>
      <UploadImage
        image={selectedSlide.data?.image}
        slideDataConfigPropertyName={'image'}
        containerStyles={{ gridColumn: reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}
        imageSizeSuggestion='736x325'
      />
      <SlideTextBlock
        isEditable
        text={selectedSlide.data?.text}
        headline={selectedSlide.data?.headline}
        onChangeText={onChangeText}
        onChangeHeadline={onChangeHeadline}
        containerStyles={{ gridColumn: !reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}
      />
      <UploadImage
        image={selectedSlide.data?.image2}
        slideDataConfigPropertyName={'image2'}
        containerStyles={{ gridRow: '2 / 2' }}
        imageSizeSuggestion='736x325'
      />
      <UploadImage
        image={selectedSlide.data?.image3}
        slideDataConfigPropertyName={'image3'}
        containerStyles={{ gridRow: '2 / 2' }}
        imageSizeSuggestion='736x325'
      />
    </Container>
  );
};
