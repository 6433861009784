import React, { createContext, useEffect, useState } from 'react';
import { PropertyMarker } from '../../../types';

type OMS = {
  addMarker: (marker: PropertyMarker) => void;
};

type Props = {
  map: google.maps.Map | null;
  children: React.ReactNode;
};

const OMSContext = createContext<OMS | undefined>(undefined);

export function MapOMSProvider({ map, children }: Props) {
  const [oms, setOms] = useState<OMS | undefined>(undefined);

  useEffect(() => {
    if (oms || !map) return;

    async function initOMS() {
      const OverlappingMarkerSpiderfier = await import('overlapping-marker-spiderfier');

      const newOms = new OverlappingMarkerSpiderfier.default(map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
        keepSpiderfied: true,
      });
      const iw = new google.maps.InfoWindow();

      newOms.addListener('click', function (marker: PropertyMarker) {
        if (!marker?.infoWindowContent) return;
        iw.setContent(marker.infoWindowContent);
        iw.open(map, marker);
      });

      newOms.addListener('spiderfy', function () {
        iw.close();
      });

      document.addEventListener('close-info-window', () => {
        iw.close();
        newOms.unspiderfy();
      });

      setOms(newOms as OMS);
    }

    initOMS();
  }, [map, oms]);

  return <OMSContext.Provider value={oms}>{!oms ? null : children}</OMSContext.Provider>;
}

export const useOMS = () => {
  const oms = React.useContext(OMSContext);

  if (!oms) {
    throw new Error('useOMS must be used within a MapOMSProvider');
  }

  return oms;
};
