import styled from 'styled-components';
import { useEffect } from 'react';

import { PropertyModal } from '../../../../../../features/report/components/PropertyModal/PropertyModal';
import { useAtom } from 'jotai';
import { isPropertyModalOpenAtom } from '../../../../../../features/report/state/isPropertyModalOpenAtom';
import { ScaleOverviewPresentation } from '../../../../../../features/report/scale-overview/ScaleOverviewPresentation';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
`;

const Scale = () => {
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useAtom(isPropertyModalOpenAtom);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container id='scale-frame'>
      <ScaleOverviewPresentation />
      <PropertyModal
        isModalOpen={isPropertyModalOpen}
        onCloseModal={() => setIsPropertyModalOpen(false)}
      />
    </Container>
  );
};

export default Scale;
