import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

export const PropertyPreview = styled('div')({
  marginTop: 23,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 535,
  maxHeight: 585,
});

export const ReportInfo = styled('p')({
  fontSize: 14,
  fontWeight: 300,
  textAlign: 'right',
  marginBottom: 7,
});

export const PropertyInfoContainer = styled('div')<T>(({ displayImage }) => ({
  width: '100%',
  position: 'relative',
  borderRadius: 5,
  overflow: 'hidden',
  minHeight: 535,
}));

export const PropertyImage = styled('img')<{ height?: number }>(({ height = 64 }) => ({
  position: 'absolute',
  top: height + 8,
  left: '50%',
  bottom: 0,
  right: '50%',
  transform: 'translate(-50%)',
  maxHeight: 535 - height + 8,
  minHeight: 535 - height + 8,
  height: 'auto',
  width: 'auto',
}));

interface T {
  displayImage?: boolean;
}

const getStyles = (displayImage?: boolean): React.CSSProperties => {
  if (displayImage) return {};
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 500,
    flexDirection: 'column',
    padding: '0 12.5%',
  };
};

export const PropertyAddress = styled('div')<T>(({ theme, displayImage }) => ({
  backgroundColor: displayImage
    ? useUiConfigColorsPrimary()
    : ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '0.05'),
  width: '100%',
  padding: '12px 0',
  color: displayImage ? '#fff' : useUiConfigColorsPrimary(),
  height: displayImage ? 'auto' : 500,
  fontSize: displayImage ? 18 : 34,
  fontWeight: 700,
  textTransform: 'uppercase',
  textAlign: 'center',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing('0.08em'),
  ...getStyles(displayImage),
}));

export const PropertyInfoItems = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 8,
});

export const PropertyInfoItem = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.colors.gray[400],
  textTransform: 'uppercase',
  paddingRight: 10,
  marginRight: 10,
  borderRight: `1px solid ${theme.colors.gray[400]}`,
  '&:last-of-type': {
    paddingRight: 0,
    marginRight: 0,
    borderRight: 'none',
  },
}));

export const PresentedTitle = styled('h5')({
  fontSize: 14,
  fontWeight: 300,
  margin: '10px 0 4px 0',
});
