import { useStep } from '../../../../../../../providers/providers/StepProvider';

import { Button } from '../../../../../../../components/Common/V2/Button/Button';

type Props = {
  onClose: () => void;
};

export function GoBack({ onClose }: Props) {
  const { step, toPreviousStep } = useStep();

  return (
    <Button variant='secondary' onClick={step <= 1 ? onClose : toPreviousStep}>
      Cancel
    </Button>
  );
}
