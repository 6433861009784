import styled, { CSSObject, DefaultTheme } from 'styled-components';
import { Icon } from '../../../../components/Common';
import { media } from '../../../../config/themes';
import { Button } from '../../../../components/Common/V2/Button/Button';

export { Icon };

interface WithSmallFont {
  isSmallFont: boolean;
}

interface WithButton {
  isButton: boolean;
}

type ButtonProps = {
  buttonType?: 'save' | 'remove';
};

type WithHighlighted = {
  isHighlighted?: boolean;
};

type WithDisabled = {
  isDisabled?: boolean;
};

export const DashItemContainer = styled('div')<WithButton & WithDisabled>(
  ({ theme, isButton, isDisabled }) => ({
    color: theme.colors.gray[400],
    backgroundColor: isDisabled ? theme.colors.gray[100] : '#fff',
    padding: '0 15px',
    border: `1px solid ${theme.colors.gray[200]}`,
    borderRadius: 5,
    cursor: isButton && !isDisabled ? 'pointer' : isDisabled ? 'not-allowed' : 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    height: 55,
  }),
);

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
  overflow: 'hidden',
});

export const Text = styled('div')<WithSmallFont & WithHighlighted>(
  ({ theme, isSmallFont, isHighlighted }) => ({
    fontSize: isSmallFont ? 12 : 14,
    fontWeight: isSmallFont ? 500 : 700,
    color: isHighlighted ? theme.colors.primary.dark : theme.colors.gray[400],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [`@media all and (max-width: ${media.desktop})`]: {
      fontSize: isSmallFont ? 12 : 14,
      marginLeft: isSmallFont ? 18 : 15,
    },
  }),
);

export const ButtonsContainer = styled('div')({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

export const SavedIndicatorButton = styled(Button)`
  cursor: default;
  &:hover {
    background: transparent;
  }
`;
