import React from 'react';
import { TNetProceedsData } from '../../../../../types';
import { TInputTarget } from './AddNetSheet.component';
import { InputsSection } from './components/InputsSection.component';
import { NetSheetsColumnTitles } from './components/NetSheetsColumnTitles.component';
import { NetSheetSection } from './components/NetSheetSection.component';

interface AddNetSheetInputsTableProps {
  netProceed1: TNetProceedsData;
  netProceed2: TNetProceedsData;
  netProceed3: TNetProceedsData;
  onInputChange: (target: TInputTarget) => void;
}

export const AddNetSheetInputsTable: React.FC<AddNetSheetInputsTableProps> = ({
  netProceed1,
  netProceed2,
  netProceed3,
  onInputChange,
}) => {
  const mortgagePayoffs = {
    title: 'MORTGAGE PAYOFF',
    inputSections: [
      {
        title: '1st Mortgage',
        inputNamePrefix: 'first',
        sectionName: 'mortgagePayoffs' as keyof TNetProceedsData,
        value1: netProceed1.mortgagePayoffs.first,
        value2: netProceed2.mortgagePayoffs.first,
        value3: netProceed3.mortgagePayoffs.first,
        onInputChange: onInputChange,
      },
    ],
  };

  const realEstateFees = {
    title: 'REAL ESTATE FEES',
    inputSections: [
      {
        title: 'Listing Office',
        inputNamePrefix: 'listingOffice',
        sectionName: 'realEstateFees' as keyof TNetProceedsData,
        value1: netProceed1.realEstateFees.listingOffice,
        value2: netProceed2.realEstateFees.listingOffice,
        value3: netProceed3.realEstateFees.listingOffice,
        onInputChange: onInputChange,
      },
      {
        title: 'Selling Office',
        inputNamePrefix: 'sellingOffice',
        sectionName: 'realEstateFees' as keyof TNetProceedsData,
        value1: netProceed1.realEstateFees.sellingOffice,
        value2: netProceed2.realEstateFees.sellingOffice,
        value3: netProceed3.realEstateFees.sellingOffice,
        onInputChange: onInputChange,
      },
    ],
  };

  const settlementCosts = {
    title: 'SETTLEMENT COSTS',
    inputSections: [
      {
        title: 'Escrow Fee',
        inputNamePrefix: 'escrowFee',
        sectionName: 'settlementCosts' as keyof TNetProceedsData,
        value1: netProceed1.settlementCosts.escrowFee,
        value2: netProceed2.settlementCosts.escrowFee,
        value3: netProceed3.settlementCosts.escrowFee,
        onInputChange: onInputChange,
      },
      {
        title: 'Title Insurance',
        inputNamePrefix: 'titleInsurance',
        sectionName: 'settlementCosts' as keyof TNetProceedsData,
        value1: netProceed1.settlementCosts.titleInsurance,
        value2: netProceed2.settlementCosts.titleInsurance,
        value3: netProceed3.settlementCosts.titleInsurance,
        onInputChange: onInputChange,
      },
      {
        title: 'Prorated Property Tax',
        inputNamePrefix: 'proratedPropertyTax',
        sectionName: 'settlementCosts' as keyof TNetProceedsData,
        value1: netProceed1.settlementCosts.proratedPropertyTax,
        value2: netProceed2.settlementCosts.proratedPropertyTax,
        value3: netProceed3.settlementCosts.proratedPropertyTax,
        onInputChange: onInputChange,
      },
    ],
  };
  const transferExciseTax = {
    title: 'TRANSFER/EXCISE TAX',
    inputSections: [
      {
        title: 'State Tax',
        inputNamePrefix: 'stateTax',
        sectionName: 'transferExciseTax' as keyof TNetProceedsData,
        value1: netProceed1.transferExciseTax.stateTax,
        value2: netProceed2.transferExciseTax.stateTax,
        value3: netProceed3.transferExciseTax.stateTax,
        onInputChange: onInputChange,
      },
    ],
  };
  const otherCosts = {
    title: 'OTHER COSTS',
    inputSections: [
      {
        title: 'Assessments',
        inputNamePrefix: 'assessments',
        sectionName: 'otherCosts' as keyof TNetProceedsData,
        value1: netProceed1.otherCosts.assessments,
        value2: netProceed2.otherCosts.assessments,
        value3: netProceed3.otherCosts.assessments,
        onInputChange: onInputChange,
      },
      {
        title: 'HOA Fees',
        inputNamePrefix: 'HOAFees',
        sectionName: 'otherCosts' as keyof TNetProceedsData,
        value1: netProceed1.otherCosts.HOAFees,
        value2: netProceed2.otherCosts.HOAFees,
        value3: netProceed3.otherCosts.HOAFees,
        onInputChange: onInputChange,
      },
      {
        title: 'Administrative Fee',
        inputNamePrefix: 'administrativeFee',
        sectionName: 'otherCosts' as keyof TNetProceedsData,
        value1: netProceed1.otherCosts.administrativeFee,
        value2: netProceed2.otherCosts.administrativeFee,
        value3: netProceed3.otherCosts.administrativeFee,
        onInputChange: onInputChange,
      },
      {
        title: 'Home Warranty Policy',
        inputNamePrefix: 'homeWarrantyPolicy',
        sectionName: 'otherCosts' as keyof TNetProceedsData,
        value1: netProceed1.otherCosts.homeWarrantyPolicy,
        value2: netProceed2.otherCosts.homeWarrantyPolicy,
        value3: netProceed3.otherCosts.homeWarrantyPolicy,
        onInputChange: onInputChange,
      },
      {
        title: 'Inspections',
        inputNamePrefix: 'inspections',
        sectionName: 'otherCosts' as keyof TNetProceedsData,
        value1: netProceed1.otherCosts.inspections,
        value2: netProceed2.otherCosts.inspections,
        value3: netProceed3.otherCosts.inspections,
        onInputChange: onInputChange,
      },
      {
        title: 'Closing Costs',
        inputNamePrefix: 'closingCosts',
        sectionName: 'otherCosts' as keyof TNetProceedsData,
        value1: netProceed1.otherCosts.closingCosts,
        value2: netProceed2.otherCosts.closingCosts,
        value3: netProceed3.otherCosts.closingCosts,
        onInputChange: onInputChange,
      },
    ],
  };
  return (
    <>
      <NetSheetsColumnTitles />
      <InputsSection
        title='List Price'
        inputNamePrefix='price'
        isListPrice
        value1={netProceed1.listPrice.price}
        value2={netProceed2.listPrice.price}
        value3={netProceed3.listPrice.price}
        onInputChange={onInputChange}
        sectionName='listPrice'
      />
      <NetSheetSection
        sectionTitle={mortgagePayoffs.title}
        inputSections={mortgagePayoffs.inputSections}
      />
      <NetSheetSection
        sectionTitle={realEstateFees.title}
        inputSections={realEstateFees.inputSections}
      />
      <NetSheetSection
        sectionTitle={settlementCosts.title}
        inputSections={settlementCosts.inputSections}
      />
      <NetSheetSection
        sectionTitle={transferExciseTax.title}
        inputSections={transferExciseTax.inputSections}
      />
      <NetSheetSection sectionTitle={otherCosts.title} inputSections={otherCosts.inputSections} />
    </>
  );
};
