import React from 'react';
import { SlideSectionHeaderComponent } from '../../../../components/common/SlideSectionHeader.component';
import { Card } from '../../../../../components/Card/Card.component';
import { realtorData, iBuyerData, staticText } from '../../data';
import { RealtorVSIBuyerCard } from '../../components/RealtorVSIBuyerCard/RealtorVSIBuyerCard.component';
import {
  CardsContainer,
  Container,
  LeftCol,
  LightCard,
  RightCol,
  Text,
  Typography,
} from './ModernRealtorVSIBuyer.styles';

export const ModernRealtorVSIBuyer: React.FC = () => {
  const { title, subtitle, descriptionTitle, descriptionText } = staticText;
  return (
    <Container>
      <LeftCol>
        <Card
          title={title}
          primaryColorTitle
          subtitle={subtitle}
          cardStyles={{ paddingBottom: '10%' }}
        />
        <LightCard>
          <Typography variant='h4' style={{ fontWeight: 900, marginBottom: 15 }} color='400'>
            {descriptionTitle.part1} {descriptionTitle.part2}
          </Typography>
          <Text variant='body1'>{descriptionText.p1}</Text>
          <Text variant='body1'>{descriptionText.p2}</Text>
          <Text variant='body1'>{descriptionText.p3}</Text>
        </LightCard>
      </LeftCol>
      <RightCol>
        {/*<SlideSectionHeaderComponent heading='A comparison of what you could net' />*/}
        <CardsContainer>
          <RealtorVSIBuyerCard cardData={realtorData} category='modern' />
          <RealtorVSIBuyerCard cardData={iBuyerData} isIBuyer category='modern' />
        </CardsContainer>
      </RightCol>
    </Container>
  );
};
