import React from 'react';

import { ContentContainer, ContentWrapper } from './TraditionalCoverAlpha.styles';
import { CoverContent } from '../../components/CoverContent/CoverContent';
import { TraditionalBackground } from '../../../../../../components/TraditionalBackground/TraditionalBackground';

export const TraditionalCoverAlpha: React.FC = () => {
  return (
    <TraditionalBackground>
      <ContentContainer>
        <ContentWrapper>
          <CoverContent />
        </ContentWrapper>
      </ContentContainer>
    </TraditionalBackground>
  );
};
