import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';

export const Border = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  backgroundColor: '#F3F3F3',
  position: 'relative',
  '&:before': {
    content: '""',
    width: '33.3%',
    position: 'absolute',
    height: '100%',
    backgroundColor: '#E4E4E4',
    top: 0,
    left: 0,
  },
  '&:after': {
    content: '""',
    width: '33.3%',
    position: 'absolute',
    height: '100%',
    backgroundColor: useUiConfigColorsPrimary(),
    top: 0,
    right: 0,
  },
}));
