export const ANALYTIC_EVENTS = {
  OPEN_MODAL: 'OPEN_MODAL',
} as const;

export type TAnalyticEventType = typeof ANALYTIC_EVENTS.OPEN_MODAL;

export type TTrackingEvent = {
  type: TAnalyticEventType;
  payload: any;
};

export class AnalyticsModalEvents {
  private static eventId = 'analyticsModalEvent';

  public static open(hash: string | null) {
    if (!hash) return;

    const data: TTrackingEvent = {
      type: ANALYTIC_EVENTS.OPEN_MODAL,
      payload: hash,
    };

    const event = new CustomEvent(AnalyticsModalEvents.eventId, { detail: data });
    window.document.dispatchEvent(event);
  }

  public static subscribe(handlerFunction: EventListener) {
    window.document.addEventListener(AnalyticsModalEvents.eventId, handlerFunction);
  }

  public static unsubscribe(handlerFunction: EventListener) {
    window.document.removeEventListener(AnalyticsModalEvents.eventId, handlerFunction);
  }
}
