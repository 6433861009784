import React from 'react';
import { MoneyFormatter } from '../../../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import {
  CostBreakdownTitleRow,
  CostBreakdownSectionTitle,
  CostBreakdownItemRow,
  CostBreakdownItem,
  CostBreakdownItemTitle,
} from './CostBreakdownSection.styles';

type TCostBreakdownItems = {
  title: string;
  items: (string | number)[];
};

interface CostBreakdownSectionProps {
  title: string;
  costBreakdownItems: TCostBreakdownItems[];
  selectedItemIndex?: number;
  hideCols: boolean[];
}

export const CostBreakdownSection: React.FC<CostBreakdownSectionProps> = ({
  title,
  costBreakdownItems,
  selectedItemIndex,
  hideCols,
}) => (
  <>
    <CostBreakdownTitleRow>
      <CostBreakdownSectionTitle>{title}</CostBreakdownSectionTitle>
      {costBreakdownItems[0].items.map((item, i) => {
        if (hideCols[i]) return;
        return (
          <CostBreakdownItem isActive={selectedItemIndex === i} key={i}>
            <div style={{ height: '100%' }}>&nbsp;</div>
          </CostBreakdownItem>
        );
      })}
    </CostBreakdownTitleRow>
    <div>
      {costBreakdownItems.map((costBreakdownItem, i) => {
        return (
          <CostBreakdownItemRow key={i}>
            <CostBreakdownItemTitle>{costBreakdownItem.title}</CostBreakdownItemTitle>
            {costBreakdownItem.items.map((item: number | string, j) => {
              if (hideCols[j]) return;
              return (
                <CostBreakdownItem isActive={selectedItemIndex === j} key={j}>
                  {item ? MoneyFormatter.format(item) : ''}
                </CostBreakdownItem>
              );
            })}
          </CostBreakdownItemRow>
        );
      })}
    </div>
  </>
);
