import styled from 'styled-components';
import { TextCell } from './TextCell';
import { useCallback, useState } from 'react';
import { PRESENTATION_TYPES } from '../../../../../../../constants/PresentationTypes';
import { TPresentationType } from '../../../../../../../types';
import { TPresentationTypeApi } from '../../../types';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../../Router/Routes';
import { Button } from '../../../../../../../components/Common/V2/Button/Button';
import { Icon } from '../../../../../../../components/Common';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;

interface Props {
  lastUpdatedDate: string;
  presentationHash: string;
  presentationType: TPresentationTypeApi;
  presentationId: number;
  canBeRebuilt?: boolean;
}

export const LastUpdatedCell = ({
  lastUpdatedDate,
  presentationHash,
  presentationType,
  presentationId,
  canBeRebuilt,
}: Props) => {
  const [rebuildInProgress, setRebuildInProgress] = useState(false);
  const { push } = useHistory();

  const onRebuild = useCallback(
    (hash: string, type: TPresentationType, presentationId: number) => {
      const queryParams = `?type=${type}&action=rebuild&presentation=${hash}&presentation_id=${presentationId}`;

      const pages: Record<TPresentationType, string> = {
        [PRESENTATION_TYPES.BUYER_TOUR]: Routes.presentationNew,
        [PRESENTATION_TYPES.WIN_THE_LISTING]: `/create/search/details`,
        [PRESENTATION_TYPES.WIN_THE_OFFER]: `/create/search/details`,
        [PRESENTATION_TYPES.PRESENT_INFO]: Routes.presentationNew,
        [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: Routes.presentationNew,
      };

      if (!pages[type]) return;

      push(pages[type] + queryParams);
    },
    [push],
  );

  const onRebuildClick = useCallback(() => {
    const typeToPresentationType = {
      seller: PRESENTATION_TYPES.WIN_THE_LISTING,
      buyer: PRESENTATION_TYPES.WIN_THE_OFFER,
      info: PRESENTATION_TYPES.PRESENT_INFO,
      [PRESENTATION_TYPES.BUYER_TOUR]: PRESENTATION_TYPES.BUYER_TOUR,
      [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: PRESENTATION_TYPES.WIN_THE_REPRESENTATION,
    };

    onRebuild(
      presentationHash,
      typeToPresentationType[presentationType] as TPresentationType,
      presentationId,
    );
    setRebuildInProgress(true);
  }, [onRebuild, presentationHash, presentationType, presentationId]);

  return (
    <Container>
      <TextCell text={lastUpdatedDate} />
      <Button
        variant='primary'
        prefixIcon={<Icon iconName='rebuild' fill='#fff' />}
        isLoading={rebuildInProgress}
        disabled={rebuildInProgress || !canBeRebuilt}
        onClick={onRebuildClick}
      >
        Update
      </Button>
    </Container>
  );
};
