import React from 'react';
import image from './image.png';
import { listItems } from '../../data';
import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';

export const WhatBuyersWantFromTheirAgentsLuxury: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading='What Buyers want from Their Agent'
      imagePosition='left'
      image={image}
      listItems={listItems}
      isEqualWidth
    />
  );
};
