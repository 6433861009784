import { TSlide } from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { ValueOfAnAgentLuxury } from './web/luxury/ValueOfAnAgentLuxury';
import { ValueOfAnAgentModern } from './web/modern/ValueOfAnAgentModern';
import { ValueOfAnAgentTraditional } from './web/traditional/ValueOfAnAgentTraditional';
import ValueOfAnAgentTraditionalPDF from './pdf/traditional/ValueOfAnAgentTraditionalPDF';
import ValueOfAnAgentModernPDF from './pdf/modern/ValueOfAnAgentModernPDF';
import ValueOfAnAgentLuxuryPDF from './pdf/luxury/ValueOfAnAgentLuxuryPDF';

const id = 'valueOfAgent';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Value of an agent',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: ValueOfAnAgentTraditional,
    [CATEGORIES.MODERN]: ValueOfAnAgentModern,
    [CATEGORIES.LUXURY]: ValueOfAnAgentLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: ValueOfAnAgentTraditionalPDF,
    [CATEGORIES.MODERN]: ValueOfAnAgentModernPDF,
    [CATEGORIES.LUXURY]: ValueOfAnAgentLuxuryPDF,
  },
};

export type TValueOfAgent = typeof id;
export { config, mappings };
