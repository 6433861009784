import React, { useMemo } from 'react';

import { CoverLogos } from '../CoverLogos/CoverLogos.component';
import { PropertyInfo } from '../PropertyInfo/PropertyInfo.component';
import { useSlideProps } from '../../../../../../providers/SlideProvider';
import { Report } from '../../../../../../../../pages/PresentationCreate/dash';
import { ContentWrapper, ImagePreview, InfoContainer } from './CoverContent.styles';
import { TAddress } from '../../../../../../../../types';

export const CoverContentRegular: React.FC = () => {
  const data = Report.data?.subject?.useSubjectInfo();

  const { slide } = useSlideProps();

  const { addressShort, beds, totalBaths, size, address } = data || {};
  const withImage = slide?.data?.displayImage;

  const addressLong = useMemo(() => {
    if (!addressShort) return '';

    return `${addressShort} | ${address.city}, ${address.state} ${address.zip}`;
  }, [address, addressShort]);

  return (
    <ContentWrapper>
      {withImage && (
        <ImagePreview style={{ backgroundImage: `url("${slide?.data?.image}")` }}></ImagePreview>
      )}
      <InfoContainer hasImage={withImage}>
        <PropertyInfo
          addressShort={withImage ? addressLong : addressShort}
          beds={beds}
          baths={totalBaths}
          size={size}
          withImage={withImage}
        />
        <CoverLogos
          displayImage={withImage}
          logoContainerStyles={{ height: withImage ? 80 : 100 }}
          logoStyles={{ height: withImage ? 90 : 100 }}
        />
      </InfoContainer>
    </ContentWrapper>
  );
};
