import React from 'react';
import { BarItemProps, LabelFormatter, ResponsiveBar } from '@nivo/bar';

import styled, { useTheme } from 'styled-components';

const GraphWrapper = styled('div')({
  height: 'calc(100% + 30px)',
  marginLeft: -20,
  position: 'relative',
  top: -10,
});

export type TGraphData = {
  month: string;
  office?: number;
  agent?: number | undefined;
};

interface GraphBarsProps {
  graphData: TGraphData[];
  isPercentageValue?: boolean;
  graphMaxValue?: number;
}

export const GraphBars: React.FC<GraphBarsProps> = ({
  graphData,
  isPercentageValue,
  graphMaxValue,
}) => {
  const format: LabelFormatter = (v: string | number) => {
    return isPercentageValue ? `${v}%` : v;
  };

  const { colors } = useTheme();

  const CustomBarComponent: React.FC<BarItemProps> = ({ x, y, width, height, color, label }) => {
    const value = parseInt(label);
    return (
      <g>
        <path
          d={`M${x - 5},${y - 5} h${width} q5,0 5,5 v${height} h${-width} v${-height} q0,-5 5,-5 z`}
          fill={color}
          strokeWidth='0'
          stroke={color}
        ></path>
        {value > 20 ? (
          <text
            x={x + width / 2}
            y={y + 8}
            textAnchor='middle'
            dominantBaseline='central'
            style={{
              fontFamily: 'sans-serif',
              fontSize: 12,
              fill: 'rgb(255, 255, 255)',
              pointerEvents: 'none',
            }}
          >
            {label}
          </text>
        ) : (
          <text
            x={x + width / 2}
            y={y - 14}
            textAnchor='middle'
            dominantBaseline='central'
            style={{
              fontFamily: 'sans-serif',
              fontSize: 12,
              fill: '#000',
              pointerEvents: 'none',
            }}
          >
            {label}
          </text>
        )}
      </g>
    );
  };

  const hasOfficeOnly = graphData.every(graphItem => !graphItem.agent);
  const hasAgentOnly = graphData.every(graphItem => !graphItem.office);
  const hasOfficeAndAgent = graphData.every(graphItem => graphItem.agent && graphItem.office);

  const getKeys = () => {
    switch (true) {
      case hasOfficeOnly:
        return ['office'];
      case hasAgentOnly:
        return ['agent'];
      default:
        return ['agent', 'office'];
    }
  };

  const minWidth = graphData.length * 68;

  return (
    <GraphWrapper style={{ minWidth, maxWidth: `max(${minWidth}px, 100%)` }}>
      <ResponsiveBar
        data={graphData}
        indexBy='month'
        keys={getKeys()}
        valueScale={{ type: 'linear' }}
        colors={hasOfficeOnly ? [colors.v2.primary] : [colors.v2.status.success, colors.v2.primary]}
        margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
        indexScale={{ type: 'band', round: true }}
        labelTextColor='#fff'
        theme={{ fontSize: 12 }}
        padding={hasOfficeAndAgent ? 0.55 : 0.5}
        groupMode='grouped'
        axisBottom={{
          tickSize: 0,
        }}
        gridYValues={[0]}
        labelFormat={format}
        maxValue={graphMaxValue}
        borderRadius={5}
        barComponent={CustomBarComponent}
        innerPadding={2}
      />
    </GraphWrapper>
  );
};
