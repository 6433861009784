import { useAtomValue } from 'jotai';
import { TProperty } from '../../../../../../types';
import { ActionIndicators } from './ActionIndicators';
import { isPresentationViewAtom } from '../../../../state/isPresentationViewAtom';
import { ActionButtons } from './ActionButtons';

interface Props {
  property: TProperty;
  hideActions?: boolean;
}

export const PropertyActions = ({ property, hideActions }: Props) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  if (hideActions) return null;

  if (isPresentationView) return <ActionIndicators property={property} />;

  return <ActionButtons property={property} />;
};
