import * as types from './types';

export const rebuildData = (rebuildData = {}, action) => {
  switch (action.type) {
    case types.REBUILD_DATA:
      return action.payload;
    default:
      return rebuildData;
  }
};

export const reportDetailed = (reportDetailedData = {}, action) => {
  switch (action.type) {
    case types.REPORT_DETAILED_SET:
      return action.payload;
    default:
      return reportDetailedData;
  }
};
