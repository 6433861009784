import { useDispatch } from 'react-redux';

import {
  ADDRESS_SET,
  REPORT_CLIENTS_SET,
  SEARCH_CRITERIA_SET,
} from '../pages/PresentationCreate/dash/store/search/types';
import {
  rebuildAddressFromSubject,
  rebuildSearchCriteriaFromReport,
} from '../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/SearchRebuild';
import { corePresentApi } from '../api/CorePresentApi';
import { REBUILD_DATA } from '../pages/PresentationCreate/dash/store/report/types';
import { TPresentationType } from '../types';

function stateSubjectToReportSubject(stateSubject: any) {
  return {
    baths: stateSubject.baths,
    total_baths: stateSubject.totalBaths || stateSubject.baths,
    beds: stateSubject.beds,
    coordinates: stateSubject.coordinates,
    full_address: {
      delivery_line: stateSubject.address?.deliveryLine,
      city: stateSubject.address?.city,
      state: stateSubject.address?.state,
      zip: stateSubject.address?.zip,
    },
    id: stateSubject.id,
    property_type: stateSubject.propertyType,
    size: stateSubject.size,
    total_size: stateSubject.totalSize,
    year_built: stateSubject.yearBuilt,
  };
}

export const useRebuildUtils = () => {
  const dispatch = useDispatch();

  const rebuildPresentationWithReport = async (data: any, id: string) => {
    const reportData = data?.data?.reportData;
    if (!reportData?.search?.api) return;

    const reportResult = reportData.result;
    const notes = reportResult?.notes ?? [];
    const request = { ...reportData.search?.api, excluded_ids: reportResult.excluded };
    const subject = stateSubjectToReportSubject(reportData.report.reportDetailed.subject);

    const createdAt = Math.floor(new Date().getTime() / 1000);

    dispatch({
      type: REBUILD_DATA,
      payload: {
        request: request,
        report_state: {
          custom_highlights: reportResult.customMarkers,
          highlights: reportResult.highlighted.map((h: any) => h.id),
          categories: reportResult.selectedCategories.map((c: any) => c.id),
          custom_categories: reportResult.customEstimates,
        },
        report_created_at: createdAt,
        notes,
      },
    });

    let searchCriteria: any = rebuildSearchCriteriaFromReport(
      request,
      subject,
      reportData?.search?.searchCriteria,
    );

    searchCriteria = {
      ...searchCriteria,
      reportCreatedAt: createdAt,
      yearBuiltFrom: request.year_built_from,
      yearBuiltTo: request.year_built_to,
      yearBuiltRangeSet: request.year_built_range_set,
      ignoreSize: request.ignore_size,
    };

    const address = rebuildAddressFromSubject(subject);

    dispatch({ type: ADDRESS_SET, payload: address });
    dispatch({ type: SEARCH_CRITERIA_SET, payload: searchCriteria });
    dispatch({ type: REPORT_CLIENTS_SET, payload: {} });

    if (data.client_id) {
      dispatch({
        type: REPORT_CLIENTS_SET,
        payload: {
          name: data.client_name,
          id: data.client_id,
          email: data.client_email,
        },
      });
    }
  };

  const fetchPresentationAndSetToStore = async (hash: string, type: TPresentationType) => {
    const data: any = await corePresentApi.get(`/presentations/${hash}`);

    try {
      if (!data) {
        throw new Error('There is no presentation!');
      }

      return rebuildPresentationWithReport(data, hash);
    } catch (err) {
      console.error('ERROR ON REBUILD', err);
    }
  };

  return { fetchPresentationAndSetToStore };
};
