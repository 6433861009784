import styled from 'styled-components';
import { Typography, Icon } from '../../../../../../../components/Common';

export { Typography, Icon };

export const Body = styled('div')({
  display: 'flex',
  marginTop: 17,
});

export const IconWrapper = styled('div')({
  width: 30,
  flexShrink: 0,
});
