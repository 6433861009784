import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../types';
import { TDynamicSlideTypeMapping } from '../../types';
import { PdfPresentation } from './PdfPresentation';
import { PdfPresentationForm } from './PdfPresentationForm';
import { PdfPresentationPdf } from './PdfPresentationPdf';

import thumbnail from './thumbnail.png';

const template = 'pdfPresentationTemplate';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: false,
  label: 'Custom Slide - Pdf presentation',
  frame: false,
  data: {
    pdf: '',
    webPdf: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: PdfPresentationForm,
  web: PdfPresentation,
  pdf: PdfPresentationPdf,
};

export type TPdfPresentationTemplate = typeof template;

export { config, mappings, thumbnail };
