import text from './text';
import colors from './colors';
import typography from './typography';
import { spacing } from '../utils';
import { Theme } from '../types';
import { breakpoints } from '../breakpoints';

export const BOLD_TRAIL_THEME_NAME = 'bold-trail';

const theme: Theme = {
  name: BOLD_TRAIL_THEME_NAME,
  colors,
  text,
  typography,
  spacing,
  shadows: {},
  breakpoints,
};

export default theme;
