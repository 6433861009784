import { useQuery } from '@tanstack/react-query';
import { TrackingApi } from '../../../../../../../api/TrackingApi';

export const useLoadPresentationAnalytics = (hash: string = '') => {
  return useQuery(
    ['presentationAnalytics', hash],
    async () => {
      const sessions = await TrackingApi.fetchSessions(hash);

      return sessions;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};
