import styled from 'styled-components';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  position: 'relative',
  padding: '2% 0',
  backgroundColor: '#fff',
}));

export const ImagePreview = styled('img')({});
