import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Wrapper = styled.div<{ noTopPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding-top: ${({ noTopPadding }) => (noTopPadding ? '0' : '60px')};
`;

export const ContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
  transition: all 0.4s;
  max-width: 100vw;
  position: relative;
`;

export const ContentBox = styled.div`
  background: ${({ theme }) => theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  border-radius: 10px;
  width: 600px;
  height: 350px;
  display: flex;
  padding: 60px;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`;

export const Copyright = styled.div`
  font-size: 12px;
`;
