import React from 'react';

import Logos from '../../../../components/Logos/Logos';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import {
  Layer1,
  Layer2,
  Layer3,
  HeaderContainer,
  BrokerInfoWrapper,
  LogoWrapper,
} from './LuxuryFrameAlpha.styles';
import { CommonFrameProps } from '../../../../../../../../types';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';

const LuxuryFrameAlpha: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => {
  return (
    <PlacementAwareContainer infoPlacement={infoPlacement}>
      <HeaderContainer>
        <Layer1 infoPlacement={infoPlacement} />
        <Layer2 infoPlacement={infoPlacement} />
        <Layer3 infoPlacement={infoPlacement} />
        <BrokerInfoWrapper>
          <BrokerInfo fontColor='#000' nameFontStyles={{ fontSize: 18, fontWeight: 400 }} />
        </BrokerInfoWrapper>
        {!hideProgressTracker && (
          <ProgressTracker
            category={CATEGORIES.LUXURY}
            containerStyles={{ paddingBottom: infoPlacement === 'top' ? 16 : 42 }}
          />
        )}
        <LogoWrapper>
          <Logos containerStyles={{ width: '100%' }} />
        </LogoWrapper>
      </HeaderContainer>
      <TocPortalContainer offset={0} zIndex={7} />

      {children}
    </PlacementAwareContainer>
  );
};

export default LuxuryFrameAlpha;
