import React, { createContext, useContext } from 'react';

import { TSection } from '../../../types';
import { TDynamicSectionId } from '../../../types';

interface Props {
  activeSection?: TSection | TDynamicSectionId | null;
}

interface ContextProps extends Props {
  setActiveSection: (sectionId?: TSection | TDynamicSectionId | null) => void;
}

const ActiveSectionContext = createContext<ContextProps | undefined>(undefined);

export const ActiveSectionProvider: React.FC<Props> = ({ activeSection, children }) => {
  const [state, setState] = React.useState<Props>({ activeSection });

  const setActiveSection = React.useCallback((sectionId?: TSection | TDynamicSectionId | null) => {
    setState({ activeSection: sectionId });
  }, []);

  const value = {
    ...state,
    setActiveSection,
  };

  return <ActiveSectionContext.Provider value={value}>{children}</ActiveSectionContext.Provider>;
};

export const useActiveSection = (): ContextProps => {
  const context = useContext(ActiveSectionContext);

  if (!context) {
    throw new Error('useActiveSection must be used within a ActiveSectionContext');
  }

  return context;
};
