export const updateEntityProperties = (entity, newEntityState) => {
  const updatedEntity = { ...entity };

  Object.keys(newEntityState).forEach(propertyName => {
    if (newEntityState[propertyName] === undefined) {
      return;
    }

    if (entity[propertyName] === undefined) {
      return;
    }

    updatedEntity[propertyName] = newEntityState[propertyName];
  });

  return updatedEntity;
};

export const getEntityWithoutNullProperties = entity => {
  const nullFreeEntity = {};

  Object.keys(entity).forEach(property => {
    if (entity[property] === null) {
      nullFreeEntity[property] = [property];
    }
  });

  return nullFreeEntity;
};
