import React from 'react';
import styled from 'styled-components';

import bg from './assets/bg.png';
import overlay from './assets/layer.png';
import slideGraphics from './assets/slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';
import { paragraphs } from '../../data';
import calendar from './assets/calendar';

type WithCanadaUser = {
  isCanadaUser?: boolean;
};

const ImageWrapper = styled('div')<WithCanadaUser>(({ isCanadaUser }) => ({
  width: '100%',
  height: '100%',
  background: isCanadaUser
    ? '#fff'
    : `linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    rgba(0, 0, 0, 0.1)`,
  position: 'relative',
}));

const ShapeImg = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  margin: ' 0 auto',
  display: 'block',
  position: 'absolute',
  right: isCanadaUser ? 55 : 321,
  width: isCanadaUser ? 970 : 920,
  bottom: isCanadaUser ? '50%' : -5,
  transform: isCanadaUser ? 'translateY(50%)' : 'none',
}));

const BgImage = styled('img')({
  zIndex: 1,
  position: 'absolute',
  right: 105,
  height: 740,
  bottom: 0,
});

const OverlayImage = styled('img')({
  zIndex: 2,
  position: 'absolute',
  right: 635,
  height: '80%',
  bottom: 0,
});

const Heading = styled('h1')<WithCanadaUser>(({ isCanadaUser }) => ({
  color: useUiConfigColorsPrimary(),
  paddingLeft: 45,
  paddingTop: 45,
  marginBottom: isCanadaUser ? 20 : 10,
  fontSize: 30,
  fontWeight: 900,
  textTransform: 'uppercase',
  position: 'relative',
  zIndex: 5,
  marginTop: 0,
  width: 525,
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const Description = styled('p')<WithCanadaUser>(({ isCanadaUser }) => ({
  position: 'relative',
  zIndex: 5,
  lineHeight: '29px',
  fontSize: 18,
  paddingLeft: 45,
  width: isCanadaUser ? 447 : 525,
  letterSpacing: useUiConfigBodyLetterSpacing(),
  marginBottom: isCanadaUser ? 30 : 0,
}));

export const ImportanceOfOpeningWeekendTraditional: React.FC = () => {
  const { isCanadaUser } = useUser();

  const primary = useUiConfigColorsPrimary();
  const graph = isCanadaUser ? calendar : slideGraphics;

  const img = React.useMemo(() => svgStringToDataUrl(graph, primary), [graph, primary]);
  const textStyles = {
    fontSize: 30,
    fontWeight: 900,
    marginBottom: 0,
    lineHeight: 1.2,
    color: '#444',
  };

  const headingStyles = { fontSize: 18, color: '#666666', marginBottom: 0 };
  return (
    <ImageWrapper isCanadaUser={isCanadaUser}>
      <Heading isCanadaUser={isCanadaUser}>Odds of selling at or above list price</Heading>
      {isCanadaUser ? (
        paragraphs.map((paragraph, i) => (
          <Description key={i} isCanadaUser>
            {paragraph}
          </Description>
        ))
      ) : (
        <Description>
          On average, the longer a home sits on the market, the lower the odds of selling at or
          above list price.
        </Description>
      )}

      <ShapeImg src={img} isCanadaUser={isCanadaUser} />

      {!isCanadaUser && (
        <>
          <BgImage src={bg} />
          <OverlayImage src={overlay} />
          <TraditionalSlideCircularCard
            heading={'WEEK 1'}
            text={'57%'}
            position={{ left: '20.2%', bottom: '-2%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
          <TraditionalSlideCircularCard
            heading={'WEEK 2'}
            text={'50%'}
            position={{ left: '19.1%', bottom: '20.5%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
          <TraditionalSlideCircularCard
            heading={'WEEK 3'}
            text={'39%'}
            position={{ left: '22.3%', bottom: '41%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
          <TraditionalSlideCircularCard
            heading={'WEEK 4'}
            text={'32%'}
            position={{ left: '31%', bottom: '59.5%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
          <TraditionalSlideCircularCard
            heading={'WEEK 5'}
            text={'27%'}
            position={{ left: '43.9%', bottom: '67%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
          <TraditionalSlideCircularCard
            heading={'WEEK 6'}
            text={'23%'}
            position={{ left: '56.7%', bottom: '60%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
          <TraditionalSlideCircularCard
            heading={'WEEK 7'}
            text={'20%'}
            position={{ left: '65.5%', bottom: '41.8%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
          <TraditionalSlideCircularCard
            heading={'WEEK 8'}
            text={'18%'}
            position={{ left: '68.7%', bottom: '21%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
          <TraditionalSlideCircularCard
            heading={'WEEK 9'}
            text={'16%'}
            position={{ left: '67.5%', bottom: '-2%' }}
            textStyles={textStyles}
            headingStyles={headingStyles}
            size={200}
          />
        </>
      )}
    </ImageWrapper>
  );
};
