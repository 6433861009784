import React, { createContext, useContext, useState } from 'react';

interface TVideoLinkContext {
  videoLink: string | null;
  setVideoLink: (vl: string | null) => void;
}
const VideoLinkContext = createContext<TVideoLinkContext | undefined>(undefined);

export function useVideoLink() {
  const context = useContext(VideoLinkContext);

  if (!context) throw new Error('useVideoLink cannot be used outside/without VideoLinkProvider');

  return context;
}

export const VideoLinkProvider: React.FC = ({ children }) => {
  const [videoLink, setVideoLink] = useState<string | null>(null);

  const value = React.useMemo(
    function memoizeValue() {
      return {
        videoLink,
        setVideoLink,
      };
    },
    [videoLink],
  );

  return <VideoLinkContext.Provider value={value}>{children}</VideoLinkContext.Provider>;
};
