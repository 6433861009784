import React from 'react';
import image from './image.png';
import * as data from '../../data';
import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';

const WhatBuyersWantFromTheirAgentsLuxuryPDF: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading={data.title}
      listItems={data.listItems}
      image={image}
      paragraphStyles={{ marginBottom: 8 }}
    />
  );
};

export default WhatBuyersWantFromTheirAgentsLuxuryPDF;
