import React, { useEffect, useState } from 'react';
import { AnalyticsGraphCard } from '../components/AnalyticsGraphsCard/AnalyticsGraphsCard.component';
import {
  PercentageGraph,
  TPercentagesBarData,
} from '../components/PercentageGraph/PercentageGraph.component';

interface SellerBarrierBreakdownProps {
  sellerBarrierBreakdown: TPercentagesBarData[];
  isWithAgentData?: boolean;
}
export const SellerBarrierBreakdown: React.FC<SellerBarrierBreakdownProps> = ({
  sellerBarrierBreakdown,
  isWithAgentData,
}) => {
  const [sellerBarrierData, setSellerBarrierData] = useState<TPercentagesBarData[]>([]);

  useEffect(() => {
    setSellerBarrierData(sellerBarrierBreakdown);
  }, [sellerBarrierBreakdown]);

  const onCheckboxClick = (showOfficeData: boolean) => {
    if (showOfficeData) {
      setSellerBarrierData(sellerBarrierBreakdown);
    } else {
      const filteredData = sellerBarrierBreakdown.map(item => {
        return {
          title: item.title,
          agent: item.agent,
        };
      });
      setSellerBarrierData(filteredData);
    }
  };
  return (
    <AnalyticsGraphCard
      title='SELLER Barrier Breakdown'
      source='Based on data sourced from web reports'
      isWithAgentData={isWithAgentData}
      onCheckboxClick={onCheckboxClick}
    >
      {sellerBarrierData.map((dataItem, i) => (
        <PercentageGraph
          key={i}
          title={dataItem.title}
          office={dataItem.office}
          agent={dataItem.agent}
        />
      ))}
    </AnalyticsGraphCard>
  );
};
