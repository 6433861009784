import Frame from './TraditionalFrameBeta';
import thumbnail from './traditionalBeta.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'traditionalBeta';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TTraditionalFrameBeta = typeof id;
export default config;
