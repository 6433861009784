import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { StepContent } from './StepContent';
import { TCategory } from '../../../../../../../types';
import { Modal } from '../../../../../../../components/Common/';
import { useStep } from '../../../../../../../providers/providers/StepProvider';
import { ModalInfoHeader } from '../../../../../components/ModalInfoHeader/ModalInfoHeader.component';

interface ModalLayoutProps {
  footer: React.ReactNode;
  category: TCategory;
  onClose: () => void;
}
export const ModalLayout: React.FC<ModalLayoutProps> = ({ footer, onClose, category }) => {
  const { step } = useStep();
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bodyRef.current) bodyRef.current.scrollTo(0, 0);
  }, [step, bodyRef]);

  const getCategoryName = (category: TCategory) => {
    switch (category) {
      case 'traditional':
        return 'Traditional';
      case 'modern':
        return 'Modern';
      case 'luxury':
        return 'Luxury';
      default:
        return '';
    }
  };

  return (
    <Modal
      open
      title={`Customize for Presentation Type: ${getCategoryName(category)}`}
      onClose={onClose}
      isBlueHeader
    >
      <ModalInfoHeader
        title='Customize this presentation to match your business needs.'
        description='Once the selections are made, these will be your default presentation settings. When building presentations for clients you will be given options to adjust the layout and content to match your audience.'
      />
      <ModalBody ref={bodyRef}>
        <ModalContent>
          <StepContainer>
            <StepContent />
          </StepContainer>
        </ModalContent>

        <ModalFooter>{footer}</ModalFooter>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
`;

const ModalContent = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
`;

const StepContainer = styled.div`
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: scroll;
  border-radius: 10px;
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.07);
  background: ${({ theme }) => theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

const ModalFooter = styled.div`
  display: flex;
  height: 60px;
  padding: 24px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  background: ${({ theme }) => theme.colors.v2.background};
  border-top: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;
