import { isEmpty } from 'lodash';
import React, { useEffect, useCallback } from 'react';

import { OneSheeterOrderableSlides } from './OneSheeterOrderableSlides';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { TSection, TSectionConfig, TSlide, TPresentation } from '../../../../../types';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../../providers/providers/SectionsProvider';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { useActiveSection } from '../../../../../components/Slide/providers/ActiveSectionProvider';
import { DynamicOrderableSections } from '../DynamicOrderableSections/DynamicOrderableSections.component';

interface Props {
  presentation: TPresentation;
}

export const OneSheeterSections: React.FC<Props> = () => {
  const { slides } = useSlides();
  const { activeSection, setActiveSection } = useActiveSection();
  const { sections: useSectionsSections } = useSections();
  const { selectedSlide, setSelectedSlide } = useSelectedSlide();
  const { sections, findSlideSection, getOrderedSlides } = useOrder();

  useEffect(() => {
    if (!sections.includes('oneSheeterSection') || activeSection || selectedSlide || !slides)
      return;

    const [firstSectionId] = sections ?? [];
    if (!firstSectionId) return;

    setActiveSection(firstSectionId);

    const orderedSlides = getOrderedSlides();
    const toSelectSlideId = orderedSlides.find(
      slideId => slides[slideId] && slideId !== 'greetingVideo',
    );

    setSelectedSlide(slides[toSelectSlideId || 'oneSheeter']);
  }, [
    useSectionsSections,
    slides,
    sections,
    activeSection,
    selectedSlide,
    setActiveSection,
    getOrderedSlides,
    setSelectedSlide,
  ]);

  const extractSlideSection = (slide: TSlide) => {
    const sectionId = findSlideSection(slide.id);

    if (!sectionId) return null;

    return useSectionsSections[sectionId] as TSectionConfig;
  };

  const dummyCb = useCallback(() => {}, []);

  if (!useSectionsSections?.oneSheeterSection) return null;

  return (
    <DynamicOrderableSections
      sectionsOrder={sections as TSection[]}
      onSectionsOrderChange={dummyCb}
      sections={useSectionsSections as Record<TSection, TSectionConfig>}
      OrderableSlidesComponent={OneSheeterOrderableSlides}
      extractSlideSection={extractSlideSection}
      openInitialSection={!isEmpty(useSectionsSections)}
    />
  );
};
