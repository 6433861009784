import React, { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { corePresentApi } from '../../../../../../../../api/CorePresentApi';
import { TPresentation, TPresentationType } from '../../../../../../../../types';
import { Textarea } from '../../../../../../../Common';
import { Checkbox } from './Checkbox/Checkbox.component';
import { Text, Checkboxes, TextAreaWrapper } from './MoreTimeInfoModal.styles';
import { NextStepModal } from '../components/NextStepModal/NextStepModal.component';

interface MoreTimeInfoModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  data?: TPresentation;
}

interface TFeedbackState {
  HOPING_TO_LIST_FOR_MORE: boolean;
  HOPING_TO_BUY_FOR_LESS: boolean;
  COMPETITIVE_MARKET: boolean;
  TIME_IT_WILL_TAKE_TO_SELL: boolean;
  TIME_IT_WILL_TAKE_TO_BUY: boolean;
  MEETING_WITH_OTHER_AGENTS: boolean;
  OTHER: boolean;
}

export type TFeedbackItem = keyof TFeedbackState;

const initialState = {
  HOPING_TO_LIST_FOR_MORE: false,
  HOPING_TO_BUY_FOR_LESS: false,
  COMPETITIVE_MARKET: false,
  TIME_IT_WILL_TAKE_TO_SELL: false,
  TIME_IT_WILL_TAKE_TO_BUY: false,
  MEETING_WITH_OTHER_AGENTS: false,
  OTHER: false,
};

export const MoreTimeInfoModal: React.FC<MoreTimeInfoModalProps> = ({
  isModalOpen,
  onClose,
  data,
}) => {
  const [feedback, setFeedback] = useState<TFeedbackState>(initialState);
  const [comment, setComment] = useState('');

  const { hash } = useParams<{ hash: string }>();

  const onChangeFeedbackState = (feedbackItem: TFeedbackItem, isChecked: boolean) => {
    setFeedback(prevState => ({
      ...prevState,
      [feedbackItem]: isChecked,
    }));
  };

  const location = useLocation();

  const feedbackData = useMemo(
    () =>
      Object.entries(feedback).flatMap(([key, value]) => {
        if (value === true) {
          return key;
        }

        return [];
      }),
    [feedback],
  );

  const onSubmit = async () => {
    const { pathname } = location;

    if (pathname === `/presentation/${hash}`) {
      try {
        const presentationType = data?.type;

        const getType = (presentationType?: TPresentationType) => {
          switch (presentationType) {
            case 'winTheListing':
              return 'seller';
            case 'winTheOffer':
              return 'buyer';
            default:
              return null;
          }
        };
        await corePresentApi.post(`/feedbacks`, {
          presentation_id: data?.id,
          feedback: feedbackData,
          comments: comment ? comment : null,
          type: getType(presentationType),
        });

        setFeedback(initialState);
        setComment('');
      } catch (e: any) {
        console.error(e);
      }
    }

    onClose();
  };

  const isBuyer = data?.type === 'winTheOffer';

  return (
    <NextStepModal
      isModalOpen={isModalOpen}
      onClose={onClose}
      headerTitle='Need more time / info'
      onConfirmButtonClick={onSubmit}
      confirmButtonText='Submit'
      isConfirmDisabled={!feedbackData?.length}
    >
      <>
        <Text>Thank you for the feedback.</Text>
        <Text>I&apos;d love to better understand where your concerns are regarding timing:</Text>
        <Checkboxes>
          <Checkbox
            text={isBuyer ? 'Hoping to buy for less' : 'Hoping to list for more'}
            isChecked={isBuyer ? feedback.HOPING_TO_BUY_FOR_LESS : feedback.HOPING_TO_LIST_FOR_MORE}
            setIsChecked={onChangeFeedbackState}
            name={isBuyer ? 'HOPING_TO_BUY_FOR_LESS' : 'HOPING_TO_LIST_FOR_MORE'}
          />
          <Checkbox
            text='Competitive market'
            isChecked={feedback.COMPETITIVE_MARKET}
            setIsChecked={onChangeFeedbackState}
            name='COMPETITIVE_MARKET'
          />
          <Checkbox
            text={isBuyer ? 'Time it will take to buy' : 'Time it will take to sell'}
            isChecked={
              isBuyer ? feedback.TIME_IT_WILL_TAKE_TO_BUY : feedback.TIME_IT_WILL_TAKE_TO_SELL
            }
            setIsChecked={onChangeFeedbackState}
            name={isBuyer ? 'TIME_IT_WILL_TAKE_TO_BUY' : 'TIME_IT_WILL_TAKE_TO_SELL'}
          />
          <Checkbox
            text='Meeting with other agents'
            isChecked={feedback.MEETING_WITH_OTHER_AGENTS}
            setIsChecked={onChangeFeedbackState}
            name='MEETING_WITH_OTHER_AGENTS'
          />
          <Checkbox
            text='Other:'
            isChecked={feedback.OTHER}
            setIsChecked={onChangeFeedbackState}
            name='OTHER'
          />
          {feedback.OTHER && (
            <TextAreaWrapper>
              <Textarea
                value={comment}
                onChange={e => {
                  setComment(e.target.value);
                }}
              ></Textarea>
            </TextAreaWrapper>
          )}
        </Checkboxes>
      </>
    </NextStepModal>
  );
};
