import styled from 'styled-components';
import { TProperty } from '../../../types';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { getPropertyStatusBasedConfigs } from '../../../services/getPropertyStatusBasedConfigs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  property: TProperty;
}

export const Title = ({ property }: Props) => {
  const { backgroundColor } = getPropertyStatusBasedConfigs(property.status);
  return (
    <Wrapper>
      <Icon icon={faMapMarkerAlt} style={{ color: backgroundColor, fontSize: 18 }} />
      <AddressTitle>
        <AddressMain>{property?.address?.deliveryLine}</AddressMain>
        <AddressSub>
          {property?.address?.city}, {property?.address?.state} {property?.address?.zip}
        </AddressSub>
      </AddressTitle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const AddressTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray[400]};
  text-transform: uppercase;
`;

const AddressMain = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const AddressSub = styled.div`
  font-size: 10px;
  font-weight: 700;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
