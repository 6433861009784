import React, { Dispatch, SetStateAction, useState } from 'react';
import { ITEMS_PER_PAGE } from '../AgentPerformance.component';
import { PaginationButtonsContainer, PaginationContainer, Typography } from './Pagination.styles';
import { Button } from '../../../../components/Common/V2/Button/Button';

interface PaginationProps {
  numberOfItems: number;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
  onChangeActivePage: (activePage: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  numberOfItems,
  activePage,
  setActivePage,
  onChangeActivePage,
}) => {
  const numberOfPages = Math.ceil(numberOfItems / ITEMS_PER_PAGE);
  const totalPages = Array.from({ length: numberOfPages }, (_, index) => index + 1);
  const totalNumOfPages = totalPages.length;
  const pages = totalPages.slice(0, 3);

  const [pagesToShow, setPagesToShow] = useState(pages);

  const onChangePage = (pageNum: number) => {
    setActivePage(pageNum);

    if (pageNum > 2) {
      const newPages = totalPages.slice(pageNum - 3, pageNum);
      setPagesToShow(newPages);
    } else {
      const newPages = totalPages.slice(0, 3);
      setPagesToShow(newPages);
    }
    onChangeActivePage(pageNum);
  };

  const onNextClick = () => {
    if (activePage === totalNumOfPages) return;
    setActivePage(prev => prev + 1);
    if (activePage > 1) {
      const newPages = totalPages.slice(activePage - 2, activePage + 1);
      setPagesToShow(newPages);
    }

    onChangeActivePage(activePage + 1);
  };

  const numberOfItemsShown = () => {
    const maxItemsPossible = activePage * ITEMS_PER_PAGE;
    const maxItemsShown = numberOfItems < maxItemsPossible ? numberOfItems : maxItemsPossible;
    const starterItemNum = maxItemsPossible - (ITEMS_PER_PAGE - 1);
    return `${starterItemNum}-${maxItemsShown}`;
  };

  return (
    <PaginationContainer>
      <Typography variant='body2' style={{ marginRight: 20 }}>
        {numberOfItemsShown()} of {numberOfItems}
      </Typography>
      <PaginationButtonsContainer>
        {pagesToShow.map(page => (
          <Button
            onClick={() => onChangePage(page)}
            key={page}
            variant={page === activePage ? 'primary' : 'clear'}
          >
            {page}
          </Button>
        ))}
        <Button onClick={onNextClick} variant='clear' disabled={activePage === totalNumOfPages}>
          Next
        </Button>
      </PaginationButtonsContainer>
    </PaginationContainer>
  );
};
