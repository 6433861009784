import React from 'react';

import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { Container, ImagesWrapper } from './FourImagesGamma.styles';

interface Props {
  reverse?: boolean;
  horizontal?: boolean;
}
export const FourImagesGammaForm: React.FC<Props> = ({ reverse, horizontal }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  const onChangeText = (value: string) => {
    setSlideDataProperty('text', value);
  };

  if (!selectedSlide) return null;

  let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'column';
  if (horizontal) flexDirection = 'row';
  if (horizontal && reverse) flexDirection = 'row-reverse';
  if (!horizontal && reverse) flexDirection = 'column-reverse';

  return (
    <Container style={{ flexDirection }}>
      <SlideTextBlock
        isEditable
        text={selectedSlide.data?.text}
        headline={selectedSlide.data?.headline}
        onChangeText={onChangeText}
        onChangeHeadline={onChangeHeadline}
        containerStyles={{
          height: horizontal ? '100%' : '49%',
          width: horizontal ? 'calc(50% - 30px)' : '100%',
        }}
      />
      <ImagesWrapper
        style={{
          display: horizontal ? 'grid' : 'flex',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr 1fr',
          flexDirection: 'row',
          width: horizontal ? 'calc(50% - 30px)' : '100%',
          marginRight: horizontal && reverse ? 60 : 0,
          marginLeft: horizontal && !reverse ? 60 : 0,
          marginBottom: horizontal || !reverse ? 0 : 40,
          marginTop: horizontal || reverse ? 0 : 40,
          height: horizontal ? '100%' : '45%',
          padding: horizontal && reverse ? '0 0 10% 0' : horizontal && !reverse ? '10% 0 0 0' : '0',
        }}
      >
        <UploadImage
          image={selectedSlide.data?.image}
          slideDataConfigPropertyName={'image'}
          imageSizeSuggestion={horizontal ? '361x266' : '358x307'}
        />
        <UploadImage
          image={selectedSlide.data?.image2}
          slideDataConfigPropertyName={'image2'}
          imageSizeSuggestion={horizontal ? '361x266' : '358x307'}
        />
        <UploadImage
          image={selectedSlide.data?.image3}
          slideDataConfigPropertyName={'image3'}
          imageSizeSuggestion={horizontal ? '361x266' : '358x307'}
        />
        <UploadImage
          image={selectedSlide.data?.image4}
          slideDataConfigPropertyName={'image4'}
          imageSizeSuggestion={horizontal ? '361x266' : '358x307'}
        />
      </ImagesWrapper>
    </Container>
  );
};
