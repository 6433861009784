import { TSlide } from '../../../../../types';
import { ListingDetails } from './web/ListingDetails';
import ListingDetailsPDF from './pdf/ListingDetailsPDF';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';

const id = 'listingDetails';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Listing Details',
  frame: true,
  isInteractive: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: ListingDetails,
  },
  pdf: {
    shared: ListingDetailsPDF,
  },
};

export type TListingDetails = typeof id;
export { config, mappings };
