import React from 'react';
import { useParams } from 'react-router';

import { TPresentationType } from '../../types';
import { PresentationEdit } from './PresentationEdit';
import { usePresentation } from '../../hooks/usePresentation';
import { BuyerTourPresentationEdit } from '../../features/BuyerTour';
import { PRESENTATION_TYPES } from '../../constants/PresentationTypes';
import { useRedirectIfUserDontMatch } from './useRedirectIfUserDontMatch';
import { FullScreenLoader } from '../../components/Loader/FullScreenLoader';
import { withLoadedUser } from '../../hoc/withLoadedUser';
import { useSetPresentationHash } from '../../state/presentationHash/useSetPresentationHash';
import { WTRPresentationEdit } from '../../features/WinTheRepresentation/edit/WTRPresentationEdit';
import { useSetPresentationToState } from '../../features/report/hooks/useSetPresentationToState';

const editComponentByTypeMap = {
  [PRESENTATION_TYPES.PRESENT_INFO]: PresentationEdit,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: WTRPresentationEdit,
  [PRESENTATION_TYPES.WIN_THE_LISTING]: PresentationEdit,
  [PRESENTATION_TYPES.WIN_THE_OFFER]: PresentationEdit,
  [PRESENTATION_TYPES.BUYER_TOUR]: BuyerTourPresentationEdit,
};

export const PresentationEditPage: React.FC = withLoadedUser(() => {
  const { hash } = useParams<{ hash: string }>();
  const { data: presentation, isLoading } = usePresentation(hash);
  useSetPresentationToState(presentation);

  useSetPresentationHash(hash);
  useRedirectIfUserDontMatch(presentation);

  if (isLoading || !presentation) return <FullScreenLoader />;

  const Component = editComponentByTypeMap[presentation.type as TPresentationType] as any;

  return !Component ? null : <Component presentation={presentation} />;
});
