import React from 'react';

import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';

import { Container } from '../TextLayout.styles';

export const TextEpsilonForm: React.FC = () => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string, field: 'headline' | 'headline2') => {
    setSlideDataProperty(field, value);
  };

  const onChangeText = (value: string, field: 'text' | 'text2') => {
    setSlideDataProperty(field, value);
  };

  if (!selectedSlide) return null;

  return (
    <Container isSpaceBetween isRow>
      <SlideTextBlock
        isEditable
        text={selectedSlide.data?.text}
        headline={selectedSlide.data?.headline}
        onChangeText={val => onChangeText(val, 'text')}
        onChangeHeadline={val => onChangeHeadline(val, 'headline')}
        containerStyles={{ width: '45%' }}
      />
      <SlideTextBlock
        isEditable
        text={selectedSlide.data?.text2}
        headline={selectedSlide.data?.headline2}
        onChangeText={val => onChangeText(val, 'text2')}
        onChangeHeadline={val => onChangeHeadline(val, 'headline2')}
        containerStyles={{ width: '45%' }}
      />
    </Container>
  );
};
