import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { OneImageGamma } from './OneImageGamma';
import { OneImageGammaForm } from './OneImageGammaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { OneImageGammaPdf } from './OneImageGammaPdf';

const template = 'oneImageLayoutGamma';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - One Image',
  frame: true,
  data: {
    image: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: OneImageGammaForm,
  web: OneImageGamma,
  pdf: OneImageGammaPdf,
};

export type TOneImageLayoutGamma = typeof template;
export { config, mappings, thumbnail };
