import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { toast } from 'react-toastify';

import { selectedCategoriesAtom } from '../state/selectedCategoriesAtom';
import { highlightedPropertiesAtom } from '../state/highlighted-properties/highlightedPropertiesAtom';
import { MAX_SCALE_SELECTIONS } from '../../../pages/PresentationCreate/dash/config/constants';

export const useMaxPropertiesLimitToast = () => {
  const selectedCategories = useAtomValue(selectedCategoriesAtom);
  const highlights = useAtomValue(highlightedPropertiesAtom);

  const canAddMoreItemsToScale = useMemo(() => {
    return selectedCategories?.length + highlights?.length < MAX_SCALE_SELECTIONS;
  }, [highlights?.length, selectedCategories?.length]);

  const maxPropertiesLimitToast = () => {
    toast(`You have reached the limit of ${MAX_SCALE_SELECTIONS} selections.`, {
      type: 'warning',
      position: 'top-center',
    });
  };

  return { maxPropertiesLimitToast, canAddMoreItemsToScale };
};
