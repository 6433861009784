import React from 'react';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  Container,
  LeftCol,
  RightCol,
  Graphic,
  H2,
  Text,
  UL,
  LI,
  SourceText,
} from './WhyPricingCorrectlyIsImportantLuxury.styles';
import svgString from '../../assets/slideGraphics';
import * as data from '../../data';
import { useUser } from '../../../../../providers/UserProvider';
import canadaImage from './image.png';

export const WhyPricingCorrectlyIsImportantLuxury: React.FC = () => {
  const { isCanadaUser } = useUser();
  const primary = useUiConfigColorsPrimary();
  const graph = React.useMemo(() => svgStringToDataUrl(svgString, primary), [primary]);

  return (
    <Container>
      <LeftCol>
        <H2>{data.heading}</H2>
        {data.paragraphs.map(p => (
          <Text key={p}>{p}</Text>
        ))}
        <UL>
          {data.listItems.map(li => (
            <LI key={li}>{li}</LI>
          ))}
        </UL>
      </LeftCol>
      <RightCol isCanadaUser={isCanadaUser}>
        <Graphic src={isCanadaUser ? canadaImage : graph} isCanadaUser={isCanadaUser} />
      </RightCol>
      {!isCanadaUser && <SourceText>Source: Zillow Listing Data</SourceText>}
    </Container>
  );
};
