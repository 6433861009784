import React, { useEffect } from 'react';

import {
  usePresentationType,
  PresentationTypeProvider,
} from '../providers/providers/PresentationTypeProvider';
import {
  usePresentationMode,
  PresentationModeProvider,
} from '../providers/providers/PresentationModeProvider';
import { Slides } from './Slides';
import { TPresentation, TUser } from '../types';
import { withProviders } from '../hoc/withProviders';
import { PRESENTATION_TYPES } from '../constants/PresentationTypes';
import { CategoryProvider } from '../providers/providers/CategoryProvider';
import { UiConfigProvider } from '../providers/providers/UiConfigProvider';
import { CoreSlidesConfigProvider } from '../providers/providers/CoreSlidesConfigProvider';
import { CreatedAtProvider, useCreatedAt } from '../providers/providers/CreatedAtProvider';
import { useSetPresentationToState } from '../features/report/hooks/useSetPresentationToState';

interface PresentationSsrProps {
  presentation: TPresentation;
  user: TUser;
}

export const PresentationSsr: React.FC<PresentationSsrProps> = withProviders<PresentationSsrProps>(
  PresentationModeProvider,
  PresentationTypeProvider,
  CreatedAtProvider,
)(({ presentation, user }) => {
  const { presentationMode, setPresentationMode } = usePresentationMode();
  const { setPresentationType } = usePresentationType();
  const { setCreatedAt } = useCreatedAt();

  useSetPresentationToState(presentation);

  useEffect(() => {
    setPresentationMode(presentation?.mode ?? 'regular');
    setPresentationType(presentation?.type || PRESENTATION_TYPES.WIN_THE_LISTING);
    setCreatedAt(presentation.createdAt as number);
  }, [
    presentation.createdAt,
    presentation?.mode,
    presentation?.type,
    setCreatedAt,
    setPresentationMode,
    setPresentationType,
  ]);

  if (!presentationMode) return null;

  return (
    <CategoryProvider initValue={presentation?.category}>
      <UiConfigProvider initValue={presentation?.ui}>
        <CoreSlidesConfigProvider presentationMode={presentationMode}>
          <Slides presentation={presentation} user={user} />
        </CoreSlidesConfigProvider>
      </UiConfigProvider>
    </CategoryProvider>
  );
});
