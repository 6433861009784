import React from 'react';
import { CardHeader, CardHeaderTitleModern, IconWrapper, Icon } from './CardHeaderModern.styles';

interface CardHeaderModernProps {
  isIBuyer?: boolean;
  isPdf?: boolean;
}

export const CardHeaderModern: React.FC<CardHeaderModernProps> = ({ isIBuyer, isPdf }) => {
  return (
    <CardHeader isPdf={isPdf}>
      <CardHeaderTitleModern>{isIBuyer ? 'iBUYER' : 'REALTOR'}</CardHeaderTitleModern>
      <IconWrapper>
        <Icon iconName={isIBuyer ? 'ibuyer' : 'realtor'} />
      </IconWrapper>
    </CardHeader>
  );
};
