import styled, { CSSObject } from 'styled-components';
import { Theme } from '../../../config/themes/types';
import { TypographyColor } from './Typography.component';

const common: CSSObject = {
  lineHeight: 1.8,
  textAlign: 'left',
};

const getStylesFromProps = (theme: Theme, color?: TypographyColor): CSSObject => {
  switch (color) {
    case 'primary':
      return { color: theme.colors.primary.main };
    case '400':
    case '300':
    case '200':
    case '100':
      return { color: theme.colors.gray[color] };
    case 'error':
      return { color: theme.colors.error };
    default:
      return { color: color ?? '#000' };
  }
};

interface TextEl {
  color?: TypographyColor;
}

const h1 = styled('h1')<TextEl>(({ theme, color }) => ({
  ...common,
  fontSize: 32,
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  ...getStylesFromProps(theme, color),
}));

const h2 = styled('h2')<TextEl>(({ theme, color }) => ({
  ...common,
  fontSize: 24,
  fontWeight: 700,
  ...getStylesFromProps(theme, color),
}));

const h3 = styled('h3')<TextEl>(({ theme, color }) => ({
  ...common,
  fontSize: 18,
  fontWeight: 500,
  ...getStylesFromProps(theme, color),
}));

const h4 = styled('h4')<TextEl>(({ theme, color }) => ({
  ...common,
  fontSize: 16,
  fontWeight: 500,
  ...getStylesFromProps(theme, color),
}));

const body1 = styled('p')<TextEl>(({ theme, color }) => ({
  ...common,
  fontSize: 14,
  fontWeight: 400,
  ...getStylesFromProps(theme, color),
}));

const body2 = styled('p')<TextEl>(({ theme, color }) => ({
  ...common,
  fontSize: 13,
  fontWeight: 400,
  ...getStylesFromProps(theme, color),
}));

const small = styled('p')<TextEl>(({ theme, color }) => ({
  ...common,
  fontSize: 12,
  fontWeight: 400,
  ...getStylesFromProps(theme, color),
}));

const upper = styled('p')<TextEl>(({ theme, color }) => ({
  ...common,
  fontSize: 10,
  fontWeight: 500,
  textTransform: 'uppercase',
  ...getStylesFromProps(theme, color),
}));

export { h1, h2, h3, h4, body1, body2, small, upper };
