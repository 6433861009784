import styled from 'styled-components';

import { PROPERTY_STATUS, TCompConfigItem } from '../../../../types';
import { TrendsGroups } from './TrendsGroups';
import { useColorBasedOnStatus } from '../../../../hooks/useColorBasedOnStatus';

interface Props {
  title: string;
  count: number;
  groups: TCompConfigItem[][];
  status: PROPERTY_STATUS | 'coming_soon';
}

export const TrendsSection = ({ title, count, groups, status }: Props) => {
  const getColorBasedOnStatus = useColorBasedOnStatus();
  const color = getColorBasedOnStatus(status);

  return (
    <Section>
      <Wrapper>
        <TitleWrapper>
          <Title style={{ color }}>{title}</Title>
          <CountText>{`${count} Comparables`}</CountText>
        </TitleWrapper>
        <Description>
          Choose the trends below that you would like to feature on the pricing scale.
        </Description>
      </Wrapper>
      <TrendsGroups groups={groups} />
    </Section>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const Title = styled.p`
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const CountText = styled.p`
  color: #444;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Description = styled.p`
  color: #444;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
