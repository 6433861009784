import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
  Container,
  GraphContainer,
  LegendContainer,
  LegendItem,
  LegendItemsContainer,
  LegendTitle,
} from './HomeStagingGraph.styles';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { useUiConfigFontsBody } from '../../../../../../../providers/providers/UiConfigFontProvider';
import { GraphWrapper } from '../../../../components/common/GraphWrapper';

export type TStages =
  | 'Very Important'
  | 'Somewhat Important'
  | 'Neutral'
  | 'Not Very Important'
  | 'Not At All Important';

type TDataImportanceItem = {
  [key in TStages]: number;
};

type TDataLabelItem = {
  room: string;
};

interface HomeStagingGraphProps {
  graphData: (TDataLabelItem & TDataImportanceItem)[];
  isPdf?: boolean;
  heading: string;
}

const importanceStages: TStages[] = [
  'Very Important',
  'Somewhat Important',
  'Neutral',
  'Not Very Important',
  'Not At All Important',
];

export const HomeStagingGraph: React.FC<HomeStagingGraphProps> = ({
  graphData,
  isPdf,
  heading,
}) => {
  const graphTheme = {
    fontSize: 14,
    fontFamily: useUiConfigFontsBody(),
    textColor: '#000',
    axis: { ticks: { line: { stroke: '#CBCBCB' } } },
    grid: { line: { stroke: '#CBCBCB' } },
  };

  const axisBottomStyle = {
    tickValues: [0, 25, 50, 75, 100],
    format: (v: string | number | Date) => `${v}%`,
    tickSize: 10,
  };

  const primary = useUiConfigColorsPrimary();
  const graphColors = React.useMemo(
    () => [
      ColorOpacityApplier.hex(primary, '1'),
      ColorOpacityApplier.hex(primary, '.5'),
      ColorOpacityApplier.hex(primary, '.3'),
      ColorOpacityApplier.hex(primary, '.1'),
      ColorOpacityApplier.hex(primary, '.03'),
    ],
    [primary],
  );

  return (
    <GraphWrapper heading={heading} isPdf={isPdf}>
      <Container>
        <GraphContainer>
          <ResponsiveBar
            data={graphData}
            theme={graphTheme}
            keys={importanceStages}
            indexBy='room'
            layout='horizontal'
            minValue={0}
            maxValue={100}
            colors={graphColors}
            enableLabel={false}
            enableGridX={true}
            gridXValues={[0, 25, 50, 75, 100]}
            padding={0.5}
            axisLeft={{ tickSize: 0 }}
            axisBottom={axisBottomStyle}
            margin={{ top: 15, right: 20, bottom: 30, left: 180 }}
          />
        </GraphContainer>
        <LegendContainer>
          <LegendTitle>Importance of staging</LegendTitle>
          <LegendItemsContainer>
            {importanceStages.map((stage, i) => (
              <LegendItem stage={stage} key={i} colors={graphColors}>
                {stage}
              </LegendItem>
            ))}
          </LegendItemsContainer>
        </LegendContainer>
      </Container>
    </GraphWrapper>
  );
};
