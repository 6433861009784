import { useMutation } from '@tanstack/react-query';
import { corePresentApi } from '../../../../../api/CorePresentApi';
import { useInvalidateComparableExtensions } from './useComparableExtensions';
import { useInvalidatePresentation } from '../../../../../hooks/usePresentation';
import { usePresentationCreationQuery } from '../../../../../hooks/usePresentationCreationQuery';
import { useSetAtom } from 'jotai';
import { isUpdatePresentationWithBuildAtom } from '../../../../../features/report/state/isUpdatePresentationWithBuildAtom';

type CreatePayload = {
  extensionId: string;
  propertyId: string;
};

async function deleteExtension(payload: CreatePayload) {
  return corePresentApi.delete(
    `/comparable-property/${payload.propertyId}/extensions/${payload.extensionId}`,
  );
}

export function useComparableExtensionDelete() {
  const { presentation: presentationHash } = usePresentationCreationQuery();
  const invalidatePresentation = useInvalidatePresentation(presentationHash);
  const invalidateExtensions = useInvalidateComparableExtensions();
  const setIsUpdatePresentationWithBuild = useSetAtom(isUpdatePresentationWithBuildAtom);

  return useMutation(deleteExtension, {
    onSuccess: (_, variables) => {
      invalidateExtensions(variables.propertyId);
      invalidatePresentation();
      setIsUpdatePresentationWithBuild(true);
    },
  });
}
