import axios, { Method } from 'axios';
import { URLS } from '../constants/urls';

const instance = axios.create({
  baseURL: URLS.CORE_PRESENT_API,
});

const sendRequest = (method: Method, url: string, options = {}, params = {}) => {
  return instance({
    method,
    url,
    params,
    ...options,
  }).then(res => {
    return res.data;
  });
};

class Http {
  get(url: string, options = {}, params = {}): Promise<any> {
    return sendRequest('GET', url, options, params);
  }

  post(url: string, options = {}, params = {}): Promise<any> {
    return sendRequest('POST', url, options, params);
  }

  put(url: string, options = {}, params = {}): Promise<any> {
    return sendRequest('PUT', url, options, params);
  }

  delete(url: string, options = {}, params = {}): Promise<any> {
    return sendRequest('DELETE', url, options, params);
  }
}

export const http = new Http();
