import React from 'react';
import { CATEGORIES } from '../../../../../../constants/categories';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';

import { ListingDetails } from './ListingDetails/ListingDetails.component';

export const useListingDetailsPdf = (properties: any[]) => {
  const { category } = useCategory();

  return properties.map((property, i) => (
    <PdfFrame key={property.id} category={category ?? CATEGORIES.TRADITIONAL}>
      <ListingDetails property={property} index={i} />
    </PdfFrame>
  ));
};
