import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { AuthApi } from '../../api/AuthApi';
import { GuestLayout } from '../../layouts/GuestLayout/GuestLayout';
import { Button } from '../../components/Common/V2/Button/Button';
import { useIsBoldTrailDomain } from '../../hooks/useIsBoldTrailDomain';

export const LoginPage: React.FC = () => {
  const KVCORE_PROVIDER = 'kvcore';
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const isBoldTrailDomain = useIsBoldTrailDomain();

  useEffect(() => {
    (async () => {
      const url = await AuthApi.getSsoUrl(KVCORE_PROVIDER);
      await setRedirectUrl(url);
    })();
  }, []);
  console.log('running version:' + import.meta.env.VITE_APP_TAG);

  const title = isBoldTrailDomain ? 'Welcome to Present' : 'Welcome to CORE Present';
  const description = isBoldTrailDomain
    ? 'To use Present, you must have a BoldTrail subscription. Please login using your BoldTrail account.'
    : 'To use CORE Present, you must have a kvCORE subscription. Please login using your kvCORE account.';
  const buttonText = isBoldTrailDomain ? 'Login with BoldTrail' : 'Login with kvCORE';

  return (
    <GuestLayout>
      <Wrapper>
        <h3>{title}</h3>
        <p>{description}</p>

        {redirectUrl && (
          <Button variant='primary' onClick={() => (window.location.href = redirectUrl)}>
            {buttonText}
          </Button>
        )}
      </Wrapper>
    </GuestLayout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
