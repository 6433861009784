import { css } from 'styled-components';

import text from './text';
const { font, weight } = text;

const typography = css`
  font-family: ${font.body};
  font-weight: ${weight.regular};
`;

export default typography;
