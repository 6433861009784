import styled from 'styled-components';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';

type WithCanadaUser = {
  isCanadaUser?: boolean;
};

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  padding: 50,
}));

export const LeftCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  marginRight: 40,
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
  borderRadius: 5,
  backgroundColor: '#fff',
});

export const Graphic = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  width: isCanadaUser ? '80%' : '68%',
  height: 'auto',
  marginTop: 15,
}));

export const Heading = styled('h1')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontWeight: 800,
  fontSize: 18,
  textTransform: 'uppercase',
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: '100%',
  margin: 0,
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing('0.11em'),
}));

export const RightCol = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 360,
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
  padding: 22,
}));

export const H2 = styled('h2')(({ theme }) => ({
  fontFamily: useUiConfigFontsHeader(),
  color: useUiConfigColorsPrimary(),
  fontWeight: 900,
  lineHeight: 1.95,
  fontSize: 20,
  marginBottom: 20,
  textTransform: 'uppercase',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const commonStyles = { fontSize: 18, color: '#000', lineHeight: 1.58, marginBottom: 20 };

export const Text = styled('p')(({ theme }) => ({
  ...commonStyles,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const UL = styled('ul')({ paddingLeft: 10, listStyle: 'none' });

export const LI = styled('li')(({ theme }) => ({
  ...commonStyles,
  position: 'relative',
  letterSpacing: useUiConfigBodyLetterSpacing(),
  '&:before': {
    content: `" "`,
    display: 'block',
    position: 'absolute',
    width: 4,
    height: 4,
    left: -10,
    top: 10,
    borderRadius: 50,
    backgroundColor: '#000',
  },
}));

export const SourceText = styled('p')(({ theme }) => ({
  color: '#101010',
  fontSize: 13.5,
  fontWeight: 300,
  position: 'absolute',
  width: 'max-content',
  bottom: 20,
  right: 60,
}));
