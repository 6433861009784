import { getAnalyticsFeedbackText } from '../../../../../../../services/getAnalyticsFeedbackText';
import { FEEDBACK_RESPONSE, MORE_TIME_DESCRIPTIONS } from '../../../../../../../types';
import { TextCell } from './TextCell';

interface Props {
  topFeedback: string;
  feedbackOption: string;
}

export const FeedbackCell = ({ topFeedback, feedbackOption }: Props) => {
  const feedbackText = getAnalyticsFeedbackText(
    topFeedback as FEEDBACK_RESPONSE,
    feedbackOption as keyof typeof MORE_TIME_DESCRIPTIONS,
  );

  return <TextCell text={feedbackText} />;
};
