import { ReportProperty } from '../../../../../types';
import { getPropertyId } from '../../../services/getPropertyId';

type AdjustedPriceParams = {
  id: string;
  properties: ReportProperty[];
  useSqftPrice: boolean;
};

export function getPropertyAdjustedPrice({ id, properties, useSqftPrice }: AdjustedPriceParams) {
  const property = properties.find(rp => getPropertyId(rp.property) === id)?.property;

  if (!property) return;

  if (!useSqftPrice) return property.adjustedPrice;

  return Math.round((property.adjustedPrice || 0) / property.size);
}
