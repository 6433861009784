import React from 'react';

import { BarWrapper, Bar, Percentage } from './PercentageBar.styles';

interface PercentageBarProps {
  percentage: string;
  isOfficeData?: boolean;
}

export const PercentageBar: React.FC<PercentageBarProps> = ({ percentage, isOfficeData }) => {
  return (
    <BarWrapper>
      <Bar width={percentage} isOfficeData={isOfficeData} />
      <Percentage>{percentage}</Percentage>
    </BarWrapper>
  );
};
