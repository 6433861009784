import { SlidesConfigOrderAdapter } from '../../../services/SlidesConfigOrderAdapter';
import { TPresentationType, TSlide, TSlideId, TUserConfigs } from '../../../types';
import { CustomizationSettingsApi } from './CustomizationSettingsApi';

export const syncCustomizationSettingsWithPersonilzedSlides = async (
  customizationConfig: TUserConfigs,
  personalizationConfig: Record<TSlideId, Partial<TSlide>>,
) => {
  const updatedConfig = { ...customizationConfig };

  const personalizedSlidesIds = ['aboutMe', 'testimonials'];

  Object.keys(updatedConfig).forEach(key => {
    const category = updatedConfig[key as keyof TUserConfigs];
    if (category.slides) {
      const { sections, orderById } = category.slides;
      orderById.forEach(sectionId => {
        if (sectionId === 'whyIAmTheRightFit') {
          sections[sectionId].slides = sections[sectionId].slides.map(slide => {
            if (personalizedSlidesIds.includes(slide.id)) {
              return {
                ...(personalizationConfig[slide.id as TSlideId] as TSlide),
              };
            }
            return slide;
          });
        }
      });
      category.order = SlidesConfigOrderAdapter.toNewConfig(category.slides);
      delete category.slides;
    }

    if (category?.order?.sectionsSlide) {
      const { sectionsSlide } = category.order;

      Object.keys(sectionsSlide).forEach(sectionId => {
        if (sectionId === 'whyIAmTheRightFit') {
          sectionsSlide[sectionId] = [...sectionsSlide[sectionId]].map(slide => {
            if (personalizedSlidesIds.includes(slide.id as TSlideId)) {
              return {
                id: slide.id,
                includeSlide: personalizationConfig?.[slide.id as TSlideId]?.includeSlide ?? false,
              };
            }
            return slide;
          });
        }
      });
    }

    if (category?.orderByType) {
      Object.keys(category.orderByType).forEach(type => {
        const { sectionsSlide } = category.orderByType?.[type as TPresentationType] ?? {};
        if (!sectionsSlide) return;

        Object.keys(sectionsSlide).forEach(sectionId => {
          if (sectionId === 'whyIAmTheRightFit') {
            sectionsSlide[sectionId] = [...sectionsSlide[sectionId]].map(slide => {
              if (personalizedSlidesIds.includes(slide.id as TSlideId)) {
                return {
                  id: slide.id,
                  includeSlide:
                    personalizationConfig?.[slide.id as TSlideId]?.includeSlide ?? false,
                };
              }
              return slide;
            });
          }
        });
      });
    }
  });

  const userConfig = await CustomizationSettingsApi.update({
    entityId: null,
    config: updatedConfig,
  });

  return userConfig;
};
