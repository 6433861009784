import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigFontsHeader } from '../../../../../../../../providers/providers/UiConfigFontProvider';
import { svgStringToDataUrl } from '../../../../../../services/svgStringToDataUrl';
import slideGraphics from '../../../pdf/modern/slideGraphics';

const Col = styled('div')({ display: 'flex', flexDirection: 'column' });

const Wrapper = styled(Col)({
  flex: 1,
  position: 'relative',
  padding: 30,
  height: 530,
});

const Graphics = styled('img')({
  width: '90%',
  alignSelf: 'center',
  margin: '10px 0 10px 0',
});

const ChartText = styled('p')({ fontWeight: 900, textAlign: 'center', fontSize: 14 });

export const Chart: React.FC = () => {
  const fontFamily = useUiConfigFontsHeader();
  const primary = useUiConfigColorsPrimary();

  const image = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  return (
    <Wrapper>
      <Graphics src={image} />
      <ChartText
        style={{
          textTransform: 'uppercase',
          fontSize: 13,
          fontFamily,
        }}
      >
        Weeks On Market
      </ChartText>
      <ChartText
        style={{
          transform: 'rotate(-90deg)',
          position: 'absolute',
          left: -120,
          top: 272,
          textTransform: 'uppercase',
          fontSize: 13,
          fontFamily,
        }}
      >
        Odds of home selling at or above list price
      </ChartText>
    </Wrapper>
  );
};
