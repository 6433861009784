import styled from 'styled-components';
import { Typography } from '../../../../components/Common';

export { Typography };

export const PaginationContainer = styled('div')({
  display: 'flex',
  padding: '26px 21px',
  alignItems: 'center',
});

export const PaginationButtonsContainer = styled('div')({
  display: 'flex',
  gap: '5px',
});
