import styled, { CSSObject } from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigFontsBody } from '../../../../../../../providers/providers/UiConfigFontProvider';
import { TCategory } from '../../../../../../../types';

type WithTotal = {
  isTotal?: boolean;
};

type WithIBuyer = {
  isIBuyer?: boolean;
};

type WithCategory = {
  category: TCategory;
};

type WithPdf = {
  isPdf?: boolean;
};

export const CardContainer = styled('div')<WithCategory>(({ category }) => ({
  width: '48.5%',
  boxShadow: category === 'modern' ? 'none' : '2px 2px 7px 2px rgba(0,0,0,0.1)',
  borderRadius: '10px 10px 0 0',
  border: '1px solid #ececec',
}));

export const CardContent = styled('div')(() => ({
  fontFamily: useUiConfigFontsBody(),
}));

export const CardRow = styled('div')<WithTotal & WithIBuyer>(({ theme, isTotal, isIBuyer }) => ({
  backgroundColor: isTotal ? useUiConfigColorsPrimary() : '#fff',
  color: isTotal ? '#fff' : theme.colors.gray[400],
  display: 'flex',
  flexDirection: isIBuyer ? 'row-reverse' : 'row',
  borderRadius: isTotal ? '0 0 10px 10px' : 0,
  '&:nth-of-type(even)': {
    backgroundColor: isTotal ? useUiConfigColorsPrimary() : '#f9f9f9',
  },
}));

export const CardColumn = styled('div')<WithTotal & WithIBuyer & WithPdf>(
  ({ isTotal, isIBuyer, isPdf }) => {
    const borderStyle = isTotal ? '1px solid #ececec' : '1px solid #ececec';
    return {
      height: isPdf ? 70 : 85,
      display: 'flex',
      alignItems: 'center',
      width: '40%',
      '&:first-of-type': {
        borderRight: isIBuyer ? 'none' : borderStyle,
        width: '60%',
      },
      '&:last-of-type': {
        borderRight: isIBuyer ? borderStyle : 'none',
      },
    };
  },
);

export const ColumnText = styled('p')<WithTotal>(({ isTotal }) => ({
  fontSize: isTotal ? 18 : 14,
  fontWeight: 400,
  maxWidth: '100%',
  textAlign: 'start',
  padding: '0 20px',
  lineHeight: 1.3,
}));

export const ColumnValue = styled('p')<WithTotal>(({ isTotal }) => ({
  fontSize: isTotal ? 29 : 20,
  fontWeight: 800,
  width: '100%',
  textAlign: 'center',
}));
