import React from 'react';
import image from './image.png';
import * as data from '../../data';

import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';

export const ImportanceOfOpeningWeekendLuxury: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading={data.title}
      paragraphs={data.paragraphs}
      imagePosition='right'
      imageStyles={{
        linearGradient: `(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${image})`,
        backgroundSize: 'cover',
      }}
      image={image}
      contentStyles={{ fontSize: 18, lineHeight: 1.3 }}
      imageDominant
      listType='ul'
    />
  );
};
