import { cloneDeep } from 'lodash';

import commonSlides from '../../../services/CommonSlides';
import { CurrentUserService } from '../../../services/CurrentUserService';
import { CanadaSlidesAdapter } from '../../../services/CanadaSlidesAdapter';
import type { IPresentationConfigs, TSlide, TSlideId } from '../../../types';
import { config as cover } from '../../../components/Slide/slides/startingSlides/Cover';
import type { TDynamicOrder, TDynamicSection, TDynamicSectionId } from '../../../types';
import { config as cmaReportConfig } from '../../../components/Slide/slides/whatsMyHomeWorth/cmaReport';
import { config as greetingVideo } from '../../../components/Slide/slides/startingSlides/GreetingVideo';
import { config as marketOverview } from '../../../components/Slide/slides/marketAnalysis/marketOverview';
import { config as netProceeds } from '../../../components/Slide/slides/closingAndNextSteps/netProceeds';
import { config as pdfSlideConfig } from '../../../components/Slide/dynamic/templates/pdfPresentationLayout';
import { config as oneSheeterConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/oneSheeter';
import { config as closingConfig } from '../../../components/Slide/slides/closingAndNextSteps/closingAndNextSteps';
import { config as buyerDemand } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/buyerDemand';
import { config as valueOfAgentConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/valueOfAnAgent';
import { config as coreSocialConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/CoreSocial';
import { config as homeBuyingConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/homeBuyingProcess';
import { config as realtorVSIBuyerConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/realtorVSIBuyer';
import { config as propertyBoostConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/propertyBoost';
import { config as buyerInterestConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/BuyerInterest';
import { config as pricingMisconceptionsConfig } from '../../../components/Slide/slides/whatsMyHomeWorth/PricingMisconceptions';
import { config as aboutMeConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/AboutMe';
import { config as myMarketingPlanConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/MyMarketingPlan';
import { config as comparativeMarketAnalysisConfig } from '../../../components/Slide/slides/whatsMyHomeWorth/comparativeMarketAnalysis';
import { config as importanceOfOpeningWeekendConfig } from '../../../components/Slide/slides/marketAnalysis/importanceOfOpeningWeekend';
import { config as whatBuyersWantConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/whatBuyersWantFromTheirAgents';
import { config as whatFactorsInfluencePricingConfig } from '../../../components/Slide/slides/marketAnalysis/whatFactorsInfluencePricing';
import { config as importanceOfStagingConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/ImportanceOfStaging';
import { config as whyPricingCorrectlyIsImportantConfig } from '../../../components/Slide/slides/marketAnalysis/whyPricingCorrectlyIsImportant';
import { config as myTestimonialsConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/MyTestimonials';

const startingSlides: TDynamicSection = {
  id: 'startingSlides',
  label: 'Introduction',
};
const whyIAmTheRightFit: TDynamicSection = {
  id: 'whyIAmTheRightFit',
  label: 'Why I Am The Right Fit',
};
const whatsMyHomeWorth: TDynamicSection = {
  id: 'whatsMyHomeWorth',
  label: 'What’s My Home Worth',
};
const marketAnalysis: TDynamicSection = {
  id: 'marketAnalysis',
  label: 'Market Analysis',
};
const marketingAndBuyerActivity: TDynamicSection = {
  id: 'marketingAndBuyerActivity',
  label: 'Marketing & Buyer Activity',
};
const closingAndNextSteps: TDynamicSection = {
  id: 'closingAndNextSteps',
  label: 'Next Steps',
};

const sections: Record<TDynamicSectionId, TDynamicSection> = {
  startingSlides,
  whyIAmTheRightFit,
  whatsMyHomeWorth,
  marketAnalysis,
  marketingAndBuyerActivity,
  closingAndNextSteps,
} as const;

class WinTheOfferConfigsImp implements IPresentationConfigs {
  getSlides(): Partial<Record<TSlideId, TSlide>> {
    const sharedSlidesConfigs = CurrentUserService.isCanadaUser()
      ? CanadaSlidesAdapter.getSlidesFromSlidesMap(commonSlides)
      : commonSlides;

    return cloneDeep({
      ...sharedSlidesConfigs,
      [homeBuyingConfig.id]: homeBuyingConfig,
      [whatBuyersWantConfig.id]: whatBuyersWantConfig,
    });
  }

  getSections(): Record<TDynamicSectionId, TDynamicSection> {
    return cloneDeep(sections);
  }

  getFullOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [
        sections.startingSlides.id,
        sections.whyIAmTheRightFit.id,
        sections.whatsMyHomeWorth.id,
        sections.marketAnalysis.id,
        sections.marketingAndBuyerActivity.id,
        sections.closingAndNextSteps.id,
      ],
      sectionsSlide: {
        [sections.startingSlides.id]: [cover.id] as TSlideId[],
        [sections.marketAnalysis.id]: [
          marketOverview.id,
          whatFactorsInfluencePricingConfig.id,
          whyPricingCorrectlyIsImportantConfig.id,
          importanceOfOpeningWeekendConfig.id,
        ] as TSlideId[],
        [sections.whyIAmTheRightFit.id]: [
          aboutMeConfig.id,
          myTestimonialsConfig.id,
          valueOfAgentConfig.id,
          homeBuyingConfig.id,
          realtorVSIBuyerConfig.id,
          whatBuyersWantConfig.id,
          oneSheeterConfig.id,
        ] as TSlideId[],
        [sections.marketingAndBuyerActivity.id]: [
          buyerDemand.id,
          buyerInterestConfig.id,
          importanceOfStagingConfig.id,
          myMarketingPlanConfig.id,
          propertyBoostConfig.id,
          coreSocialConfig.id,
        ] as TSlideId[],
        [sections.whatsMyHomeWorth.id]: [
          comparativeMarketAnalysisConfig.id,
          cmaReportConfig.id,
          pricingMisconceptionsConfig.id,
        ] as TSlideId[],
        [sections.closingAndNextSteps.id]: [closingConfig.id] as TSlideId[],
      },
    });
  }

  getDefaultOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [
        sections.startingSlides.id,
        sections.whyIAmTheRightFit.id,
        sections.whatsMyHomeWorth.id,
        sections.marketAnalysis.id,
        sections.marketingAndBuyerActivity.id,
        sections.closingAndNextSteps.id,
      ],
      sectionsSlide: {
        [sections.startingSlides.id]: [cover.id] as TSlideId[],
        [sections.marketAnalysis.id]: [
          marketOverview.id,
          whyPricingCorrectlyIsImportantConfig.id,
        ] as TSlideId[],
        [sections.whyIAmTheRightFit.id]: [valueOfAgentConfig.id] as TSlideId[],
        [sections.marketingAndBuyerActivity.id]: [
          buyerDemand.id,
          buyerInterestConfig.id,
          myMarketingPlanConfig.id,
          coreSocialConfig.id,
        ] as TSlideId[],
        [sections.whatsMyHomeWorth.id]: [
          comparativeMarketAnalysisConfig.id,
          cmaReportConfig.id,
        ] as TSlideId[],
        [sections.closingAndNextSteps.id]: [closingConfig.id] as TSlideId[],
      },
    });
  }

  getPredefinedSectionSlides(): Record<TDynamicSectionId, TSlideId[]> {
    return cloneDeep({
      [sections.startingSlides.id]: [
        greetingVideo.id,
        pdfSlideConfig.template,
        cover.id,
      ] as TSlideId[],
      [sections.marketAnalysis.id]: [
        marketOverview.id,
        whatFactorsInfluencePricingConfig.id,
        whyPricingCorrectlyIsImportantConfig.id,
        importanceOfOpeningWeekendConfig.id,
      ] as TSlideId[],
      [sections.whyIAmTheRightFit.id]: [
        aboutMeConfig.id,
        myTestimonialsConfig.id,
        valueOfAgentConfig.id,
        homeBuyingConfig.id,
        realtorVSIBuyerConfig.id,
        whatBuyersWantConfig.id,
        oneSheeterConfig.id,
      ] as TSlideId[],
      [sections.marketingAndBuyerActivity.id]: [
        buyerDemand.id,
        buyerInterestConfig.id,
        importanceOfStagingConfig.id,
        myMarketingPlanConfig.id,
        propertyBoostConfig.id,
        coreSocialConfig.id,
      ] as TSlideId[],
      [sections.whatsMyHomeWorth.id]: [
        comparativeMarketAnalysisConfig.id,
        cmaReportConfig.id,
        pricingMisconceptionsConfig.id,
      ] as TSlideId[],
      [sections.closingAndNextSteps.id]: [netProceeds.id, closingConfig.id] as TSlideId[],
    });
  }
}

export const WinTheOfferConfigs = new WinTheOfferConfigsImp();
