import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { Typography } from '../../components/Common';
export { Typography };

export const PageContainer = styled('div')({
  padding: '30px 30px 81px 30px',
});

export const BackButtonContainer = styled(Link)({
  display: 'flex',
  marginBottom: 40,
  alignItems: 'center',
  textDecoration: 'none',
});

export const BackIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.primary.main,
  marginRight: 12,
}));

export const BackText = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.colors.primary.main,
}));
