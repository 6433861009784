import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { getYouTubeID, isLoomVideo, isYoutubeVideo } from '../../../../../services/videoServices';
import { usePresentation } from '../../../../../hooks/usePresentation';

const Container = styled('div')({
  width: '100%',
  height: '100%',
});

export const GreetingVideo: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();

  const { data: presentation } = usePresentation(hash);

  const videoLink = useMemo(() => {
    const presentationVideoLink = presentation?.videoLink || '';

    const _isYoutubeVideo = isYoutubeVideo(presentationVideoLink);
    const _isLoomVideo = isLoomVideo(presentationVideoLink);

    if (_isYoutubeVideo) {
      const id = getYouTubeID(presentationVideoLink);
      return `https://www.youtube.com/embed/${id}`;
    }

    if (_isLoomVideo) {
      const id = presentationVideoLink.split('/').reverse()[0];
      return `https://www.loom.com/embed/${id}`;
    }

    return '';
  }, [presentation?.videoLink]);

  if (!videoLink) return null;

  return (
    <Container>
      <iframe
        src={videoLink}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        style={{ width: '100%', height: '100%' }}
      />
    </Container>
  );
};
