import React, { useEffect, useState } from 'react';
import { TableHeadButton } from '../../../../../../Common/TableHeadButton/TableHeadButton.component';
import { ListingFeedbackItem } from './ListingFeedbackItem/ListingFeedbackItem.component';
import { PropertiesSort } from './services/PropertiesSort';

import {
  Table,
  TableButtonSubtext,
  TableButtonWrapper,
  TableHead,
  TableBody,
} from './ListingFeedbackTable.styles';
import { useProperties } from '../../../../../../../providers/providers/PropertiesProvider';
import { getPropertyId } from '../../../../../../../features/report/services/getPropertyId';

export type TSortOption =
  | 'price_asc'
  | 'price_dsc'
  | 'address_asc'
  | 'address_dsc'
  | 'rating_asc'
  | 'rating_dsc';

export const ListingFeedbackTable = () => {
  const [sortOption, setSortOption] = useState<TSortOption | null>('price_dsc');
  const { properties } = useProperties();
  const [tableProperties, setTableProperties] = useState(properties);
  const [propertyRatings, setPropertyRatings] = useState<Record<string, number>>({});

  useEffect(() => {
    if (!Object.keys(propertyRatings).length) {
      const ratingsMap: Record<string, number> = {};
      tableProperties.map(property => {
        ratingsMap[getPropertyId(property)] = 1;
      });
      setPropertyRatings(ratingsMap);
    }
  }, [tableProperties, propertyRatings]);

  useEffect(() => {
    setTableProperties(PropertiesSort.sort(tableProperties, sortOption, propertyRatings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption]);

  const onPriceClick = () => {
    const nextVal = sortOption === 'price_dsc' ? 'price_asc' : 'price_dsc';
    setSortOption(nextVal);
  };

  const onAddressClick = () => {
    const nextVal = sortOption === 'address_dsc' ? 'address_asc' : 'address_dsc';
    setSortOption(nextVal);
  };

  const onRatingsClick = () => {
    const nextVal = sortOption === 'rating_dsc' ? 'rating_asc' : 'rating_dsc';
    setSortOption(nextVal);
  };

  return (
    <Table>
      <TableHead>
        <TableButtonWrapper>
          <TableHeadButton
            text='Property Details'
            onClick={onPriceClick}
            fontStyles={{ textTransform: 'uppercase' }}
          />
        </TableButtonWrapper>
        <TableButtonWrapper style={{ width: 264 }}>
          <TableHeadButton
            text='Property Address'
            onClick={onAddressClick}
            fontStyles={{ textTransform: 'uppercase' }}
          />
        </TableButtonWrapper>
        <TableButtonWrapper style={{ width: 182 }}>
          <TableHeadButton
            text='Property Rating'
            onClick={onRatingsClick}
            fontStyles={{ textTransform: 'uppercase' }}
          />
          <TableButtonSubtext>Please rate this property using the slider</TableButtonSubtext>
        </TableButtonWrapper>
      </TableHead>
      <TableBody>
        {tableProperties.map(property => (
          <ListingFeedbackItem
            key={property.id}
            property={property as any}
            propertyRatings={propertyRatings}
            setPropertyRatings={setPropertyRatings}
          />
        ))}
      </TableBody>
    </Table>
  );
};
