import styled from 'styled-components';
import { ReportInfo } from './ReportInfo';
import { WhatIsCMA } from './what-is-cma/WhatIsCMA';
import { ComparableCriteriaReview } from './comparable-criteria-review/ComparableCriteriaReview';

export const ResultsHeader = () => (
  <Content>
    <Details>
      <ReportInfo />
      <WhatIsCMA />
      <ComparableCriteriaReview />
    </Details>
  </Content>
);

const Content = styled.div`
  width: 100%;
  max-width: 1700px;
  margin: -20px auto;
  font-family: Figtree;
`;

const Details = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 22px;

  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
