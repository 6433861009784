import React from 'react';
import { CardsSectionTitle, CardsSectionEl, CardsSectionContent } from './CardsSection.styles';

interface CardsSectionProps {
  title: string;
}

export const CardsSection: React.FC<CardsSectionProps> = ({ children, title }) => (
  <CardsSectionEl>
    <CardsSectionTitle>{title}</CardsSectionTitle>
    <CardsSectionContent>{children}</CardsSectionContent>
  </CardsSectionEl>
);
