import { TPresentation, TSection, TSlide } from '../../../types';

export const extractSlidesFromPresentationData = (presentation: TPresentation): TSlide[] => {
  if (!presentation?.slides) return [];

  const { slides } = presentation;

  const orderedSlides = slides.orderById.reduce(
    (combinedSlides: TSlide[], section: TSection) => [
      ...combinedSlides,
      ...(slides?.sections?.[section]?.slides ?? []),
    ],
    [],
  );

  return orderedSlides.filter(s => s.includeSlide);
};

type T = {
  [key in TSlide['id']]?: TSection;
};

export const extractFirstSectionSlideMap = (presentation: TPresentation): T => {
  const map: T = {};

  if (
    !presentation?.regular ||
    (presentation.mode && ['oneSheeter', 'cma'].includes(presentation.mode))
  )
    return map;

  const { order } = presentation?.regular ?? {};

  order?.sections.forEach(section => {
    if (section === 'startingSlides') return;

    const [firstSlideInSection] = order.sectionsSlide[section];
    if (!firstSlideInSection) return;

    map[firstSlideInSection] = section as TSection;
  });

  return map;
};
