import styled from 'styled-components';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { Property } from './components/Property/Property';
import { PropertiesMap } from './components/PropertiesMap/PropertiesMap';
import { PropertiesList } from './components/PropertiesList/PropertiesList';
import { useViewMode, ViewModeProvider } from './providers/ViewModeProvider';
import { withProviders } from '../../../../../../hoc/withProviders';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import {
  SelectedPropertyProvider,
  useSelectedProperty,
} from './providers/SelectedPropertyProvider';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { useUiConfigInfoPlacement } from '../../../../../../providers/providers/UiConfigInfoPlacementProvider';

const Container = styled.div`
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  padding-top: ${() => {
    const { category } = useCategory();
    const { infoPlacement } = useUiConfigInfoPlacement();

    return category === CATEGORIES.LUXURY && infoPlacement === 'top' ? '40px' : '20px';
  }};
  padding-bottom: ${() => {
    const { infoPlacement } = useUiConfigInfoPlacement();
    return infoPlacement === 'bottom' ? '12px' : 0;
  }};
`;

const RightSection = styled.div`
  display: flex;
  flex-grow: 1;
  width: 60%;
  overflow-y: auto;
  padding-right: 10px;
`;

export const ListingDetails: React.FC = withProviders(
  ViewModeProvider,
  SelectedPropertyProvider,
)(function ListingDetails() {
  const { properties } = useProperties();
  const { viewMode } = useViewMode();
  const { selectedProperty, setSelectedProperty } = useSelectedProperty();

  useEffect(() => {
    if (!selectedProperty && viewMode === 'property') {
      const [property] = properties || [];
      setSelectedProperty(property || null);
    }
  }, [properties, selectedProperty, setSelectedProperty, viewMode]);

  return (
    <Container>
      <ToastContainer />
      <PropertiesList />

      <RightSection>
        {viewMode === 'all' && <PropertiesMap properties={properties} useNumberedMarkers />}
        {viewMode === 'property' && <Property property={selectedProperty} />}
      </RightSection>
    </Container>
  );
});
