import Frame from './ModernFrameAlpha';
import thumbnail from './modernAlpha.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'modernAlpha';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TModernFrameAlpha = typeof id;
export default config;
