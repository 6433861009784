import styled from 'styled-components';

import { TViewModes } from './Actions';

import { TPresentationSession } from '../../../../../../../../api/TrackingApi';

import { BuyerTourTable } from '../BuyerTourTable/BuyerTourTable';
import { DataVisualization } from './DataVisualization/DataVisualization';

type AnalyticsContent = {
  hash: string;
  viewMode: TViewModes;
  showPagesWithNoViews: boolean;
  session?: TPresentationSession;
};

export function AnalyticsViewContent({
  viewMode,
  showPagesWithNoViews,
  session,
  hash,
}: AnalyticsContent) {
  if (viewMode === 'propertyRatings') {
    return <BuyerTourTable hash={hash} session={session} />;
  }

  return (
    <Container>
      <Unit>{viewMode === 'timeOnPage' ? 'Time (mm:ss)' : 'Views per page'}</Unit>
      <DataVisualization
        showPagesWithNoViews={showPagesWithNoViews}
        viewMode={viewMode}
        session={session}
        hash={hash}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 17px;
  padding: 20px;
  padding-bottom: 0;
  height: 100%;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  background-color: ${({ theme }) => theme.colors.v2.background};
`;

const Unit = styled.p`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
