import styled, { useTheme } from 'styled-components';
import { Input } from '../../../../../../../../../../../components/Common/V2/Input';
import { Button } from '../../../../../../../../../../../components/Common/V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';

interface Props {
  valueFrom: string;
  onChangeValueFrom: (val?: number) => void;
  valueTo: number;
  onChangeValueTo: (val?: number) => void;
  onConfirm: () => void;
  onCancel: () => void;
  thousandSeparator?: boolean;
  placeholderFrom: string;
  placeholderTo: string;
}

export const RangeForm = ({
  valueFrom,
  valueTo,
  onCancel,
  onConfirm,
  onChangeValueTo,
  onChangeValueFrom,
  thousandSeparator = true,
  placeholderFrom,
  placeholderTo,
}: Props) => {
  const { colors } = useTheme();

  return (
    <Container>
      <InputFields>
        <NumberFormat
          customInput={Input}
          value={valueFrom}
          onValueChange={({ floatValue }) => onChangeValueFrom(floatValue)}
          thousandSeparator={thousandSeparator}
          min='0'
          placeholder={placeholderFrom}
          label='From'
          wrapperStyle={{ flex: 1 }}
        />
        <NumberFormat
          customInput={Input}
          value={valueTo}
          onValueChange={({ floatValue }) => onChangeValueTo(floatValue)}
          thousandSeparator={thousandSeparator}
          min='0'
          placeholder={placeholderTo}
          label='To'
          wrapperStyle={{ flex: 1 }}
        />
      </InputFields>

      <ActionButtons>
        <Button onClick={onCancel} variant='secondary'>
          <FontAwesomeIcon icon={['fas', 'times']} size='1x' color='#373e43' />
        </Button>
        <Button onClick={onConfirm} variant='primary'>
          <FontAwesomeIcon icon={['fas', 'check']} size='1x' color={colors.v2.background} />
        </Button>
      </ActionButtons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const InputFields = styled.div`
  display: flex;
  gap: 10px;

  &:first-child {
    margin-right: 5px;
  }
`;

const ActionButtons = styled.div`
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
