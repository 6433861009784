const ucfirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPropertyTypeText = status => {
  if (!status) return;
  if (status === 'coming_soon') {
    return 'Coming Soon';
  }

  return ucfirst(status);
};
