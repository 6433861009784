import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { UserApi } from '../../api/UserApi';
import { THEMES } from '../../constants/themes';
import { CATEGORIES } from '../../constants/categories';
import { TCategory, TSlideInfoPlacement, TSlideTheme } from '../../types';
import { DefaultConfigProvider } from '../../services/DefaultConfigProvider';
import { UiConfigProvider } from '../../providers/providers/UiConfigProvider';
import { CategoryProvider } from '../../providers/providers/CategoryProvider';
import { useSlides } from '../../providers/providers/SlidesProvider';
import { useUserCustomizations } from '../../hooks/useUserCategoryCustomizations';
import { useSetApiUser, useUser } from '../../components/Slide/providers/UserProvider';
import { SelectedSlideProvider } from '../../providers/providers/SelectedSlideProvider';
import { SlidePreviewContent } from './SlidePreviewContent/SlidePreviewContent.component';
import { PresentationTypeProvider } from '../../providers/providers/PresentationTypeProvider';
import { CoreSlidesConfigProvider } from '../../providers/providers/CoreSlidesConfigProvider';
import { withProviders } from '../../hoc/withProviders';
import { CreatedAtProvider, useCreatedAt } from '../../providers/providers/CreatedAtProvider';
import { Timestamp } from '../../services/Timestamp';
import { useLevelDynamicSlides } from '../../hooks/useLevelDynamicSlides';
import { LibraryConfigs } from '../../features/Library/services/LibraryConfigs';

type TQueryParams = {
  category: string;
  theme: string;
  infoPlacement: string;
  content: string;
  userId: string;
  hash: string;
};

const queryParams: TQueryParams = {
  category: 'category',
  theme: 'theme',
  infoPlacement: 'infoPlacement',
  content: 'content',
  userId: 'userId',
  hash: 'hash',
};

interface SlidePreviewWrapperProps {
  slideId: string;
  theme: TSlideTheme;
  infoPlacement: TSlideInfoPlacement;
  content: string;
  userId: string;
  hash: string;
  category: TCategory;
}

const SlidePreviewWrapper: React.FC<SlidePreviewWrapperProps> = ({
  category,
  slideId,
  theme,
  infoPlacement,
  content,
  hash,
}) => {
  const user = useUser();
  const { setSlides } = useSlides();
  const setApiUser = useSetApiUser();

  const { isLoading: isLoadingCustomizations, data: configs } = useUserCustomizations(
    hash,
    null,
    user?.useSpecialBranding,
  );
  const { isLoading: isLoadingDynamic, data: dynamic } = useLevelDynamicSlides(
    hash,
    user?.useSpecialBranding,
  );

  const isLoading = isLoadingCustomizations || isLoadingDynamic;

  useEffect(() => {
    if (isLoadingDynamic) return;

    setSlides({
      ...LibraryConfigs.getSlides(),
      ...(dynamic ?? {}),
    });
  }, [dynamic, isLoadingDynamic, setSlides]);

  useEffect(() => {
    (async () => {
      const apiUser = await UserApi.fetch(hash);
      if (!apiUser) return;

      setApiUser(apiUser);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return null;

  return (
    <UiConfigProvider
      initValue={configs?.[category]?.ui || DefaultConfigProvider.getConfigs()[category].ui}
    >
      <SlidePreviewContent
        slideId={slideId}
        theme={theme || THEMES.TRADITIONAL.DEFAULT}
        infoPlacement={infoPlacement || { value: 'top' }}
        content={content || 'someContent'}
        user={user}
        isLoading={isLoading}
      />
    </UiConfigProvider>
  );
};

export const SlidePreview = withProviders<React.FC>(CreatedAtProvider)(() => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const { setCreatedAt } = useCreatedAt();

  const query = useQuery();

  const themeQuery = query.get(queryParams.theme);
  const infoPlacementQuery = { value: query.get(queryParams.infoPlacement) };
  const contentQuery = query.get(queryParams.content);
  const categoryQuery = query.get(queryParams.category);
  const userId = query.get(queryParams.userId) ?? '';
  const hash = query.get(queryParams.hash) ?? '';

  const { slideUuid } = useParams<{ slideUuid: string }>();

  useEffect(() => setCreatedAt(Timestamp.now()), [setCreatedAt]);

  return (
    <PresentationTypeProvider>
      <CategoryProvider initValue={(categoryQuery as TCategory) || CATEGORIES.TRADITIONAL}>
        <SelectedSlideProvider>
          <CoreSlidesConfigProvider>
            <SlidePreviewWrapper
              category={categoryQuery as TCategory}
              slideId={slideUuid}
              theme={(themeQuery as TSlideTheme) || THEMES.TRADITIONAL.DEFAULT}
              infoPlacement={infoPlacementQuery as TSlideInfoPlacement}
              content={contentQuery || 'someContent'}
              userId={userId}
              hash={hash}
            />
          </CoreSlidesConfigProvider>
        </SelectedSlideProvider>
      </CategoryProvider>
    </PresentationTypeProvider>
  );
});
