import { TablePropertyStatus } from '../../../types';

interface Props {
  type: string;
}

export const PageBreak = ({ type }: Props) => (
  <div style={{ color: '#808080', textAlign: 'center', marginBottom: '20px', fontSize: '14px' }}>
    {' '}
    {type.toUpperCase()} continued on next page{' '}
  </div>
);
