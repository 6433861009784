import React, { useEffect } from 'react';

import {
  usePresentationMode,
  PresentationModeProvider,
} from '../../providers/providers/PresentationModeProvider';
import { Spacer } from './Presentation.styles';
import Loader from '../../components/Loader/Loader';
import { withProviders } from '../../hoc/withProviders';
import { PresentationContent } from './PresentationContent';
import { TPresentation, TPresentationMode } from '../../types';
import { useIsReportInStore } from '../../hooks/usePresentation';
import { useSections } from '../../providers/providers/SectionsProvider';
import { usePresentationSlides } from '../../hooks/useModePresentationSlides';
import { CategoryProvider } from '../../providers/providers/CategoryProvider';
import { UiConfigProvider } from '../../providers/providers/UiConfigProvider';
import { SelectedSlideProvider } from '../../providers/providers/SelectedSlideProvider';
import { PresentationTypeProvider } from '../../providers/providers/PresentationTypeProvider';
import { CoreSlidesConfigProvider } from '../../providers/providers/CoreSlidesConfigProvider';
import { CreatedAtProvider, useCreatedAt } from '../../providers/providers/CreatedAtProvider';
interface PresentationProps {
  presentation: TPresentation;
}

const PresentationSlidesContent: React.FC<PresentationProps> = ({ presentation }) => {
  const { presentationMode } = usePresentationMode();
  const { setSections } = useSections();

  const slides = usePresentationSlides(presentation as TPresentation);

  useEffect(() => {
    if (!presentationMode) return;
    setSections(presentation?.[presentationMode as TPresentationMode]?.sections ?? {});
  }, [presentation, presentationMode, setSections]);

  return (
    <PresentationContent
      presentation={presentation}
      slides={slides}
      hideProgressTracker={presentationMode !== 'regular'}
    />
  );
};

export const Presentation: React.FC<PresentationProps> = withProviders<PresentationProps>(
  PresentationModeProvider,
  SelectedSlideProvider,
  CreatedAtProvider,
)(({ presentation }) => {
  const isReportInStore = useIsReportInStore();
  const { presentationMode, setPresentationMode } = usePresentationMode();
  const { setCreatedAt } = useCreatedAt();

  useEffect(() => {
    setPresentationMode(presentation?.mode ?? 'regular');
    setCreatedAt(presentation.createdAt as number);
  }, [presentation.createdAt, presentation?.mode, setCreatedAt, setPresentationMode]);

  if (!isReportInStore || !presentationMode) {
    return (
      <Spacer>
        <Loader size='4x' centered />
      </Spacer>
    );
  }

  return (
    <CategoryProvider initValue={presentation?.category}>
      <UiConfigProvider initValue={presentation?.ui}>
        <CoreSlidesConfigProvider presentationMode={presentation?.mode}>
          <PresentationTypeProvider initValue={presentation?.type}>
            <PresentationSlidesContent presentation={presentation} />
          </PresentationTypeProvider>
        </CoreSlidesConfigProvider>
      </UiConfigProvider>
    </CategoryProvider>
  );
});
