import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { ThreeImagesAlpha } from './ThreeImagesAlpha';
import { ThreeImagesAlphaForm } from './ThreeImagesAlphaForm';
import { ThreeImagesAlphaPdf } from './ThreeImagesAlphaPdf';
import thumbnail from './thumbnail.png';

const template = 'threeImagesLayoutAlpha';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Three Image Layout',
  frame: true,
  data: {
    image: '',
    image2: '',
    image3: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: ThreeImagesAlphaForm,
  web: ThreeImagesAlpha,
  pdf: ThreeImagesAlphaPdf,
};

export type TThreeImagesLayoutAlpha = typeof template;
export { config, mappings, thumbnail };
