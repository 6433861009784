import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import BuyerDemand from './BuyerDemand';
import { BuyerDemandPdf } from './pdf/BuyerDemandPdf';

const id = 'buyerDemand';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Buyer Demand',
  frame: true,
  data: {
    text: `The law of supply and demand dictates the equilibrium price of a property, and I can show you if buyers are searching in your area now. Even better, I can show you how many sellers there are in a specific area -- in the same view! I’ll also show you how to distinguish which properties are “comparable” (or not) to your home. `,
    sellerSupplyOn: false,
    featuresOfInterestOn: false,
  },
  buyerDemandData: {
    zipData: null,
    properties: [],
  },
  isInteractive: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: BuyerDemand,
  },
  pdf: {
    shared: BuyerDemandPdf,
  },
};

export type TBuyerDemand = typeof id;
export { config, mappings };
