import React from 'react';
import styled from 'styled-components';
import { useUiConfigInfoPlacement } from '../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { useSlide } from '../../../providers/SlideProvider';

const Container = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
});

interface Props {
  offset?: number;
  zIndex?: number;
}

const TocPortalContainer: React.FC<Props> = ({ children, offset = 20, zIndex = 1 }) => {
  const { infoPlacement } = useUiConfigInfoPlacement();
  const prop = infoPlacement === 'top' ? 'top' : 'bottom';

  const slide = useSlide();

  return (
    <Container id={`presentation-toc-portal-${slide.id}`} style={{ [prop]: offset, zIndex }}>
      {children}
    </Container>
  );
};

export default TocPortalContainer;
