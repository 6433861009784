import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { PresentationNavItem } from '../../../types';
import { HeaderComponent } from '../../../components/Common';
import { Button } from '../../../components/Common/V2/Button/Button';
import { useCreateSlideProps } from '../providers/CreateSlidePropsProvider';
import { CreateSlideModal } from './CreateSlideModal/CreateSlideModal.component';
import {
  HeaderSectionsContainer,
  LeftSection,
  LeftSectionWrapper,
  RightSection,
} from './LibraryHeader.styles';
import { LibraryHeaderSection } from './LibraryHeaderSection/LibraryHeaderSection.component';
import { PreviewModeProvider } from '../../PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const headerNav: PresentationNavItem[] = [
  {
    label: 'Slide library',
    isActive: true,
  },
];

export const LibraryHeader: React.FC = () => {
  const { goBack } = useHistory();
  const { openModal, modalOpen } = useCreateSlideProps();

  return (
    <Fragment>
      <HeaderComponent navItems={headerNav}>
        <Button
          variant='secondary'
          prefixIcon={<FontAwesomeIcon style={{ marginTop: '1px' }} icon={'chevron-left'} />}
          onClick={goBack}
        >
          Back
        </Button>
      </HeaderComponent>

      <HeaderSectionsContainer>
        <LeftSection>
          <LeftSectionWrapper>
            <LibraryHeaderSection
              title='Single Slide Library'
              iconName={['fas', 'books']}
              text="Not every conversation needs a full presentation. Often times, a single slide addressing your client's main hurdle is enough to get the deal. "
            />
          </LeftSectionWrapper>
        </LeftSection>
        <RightSection>
          <LibraryHeaderSection
            title='Create/Add a Slide'
            iconName={['fas', 'rectangle-history-circle-plus']}
            text='Want to add your own content to the library of available slides? Upload/create your own slides here.'
            button={{ text: 'Add a Slide', iconName: ['fas', 'plus'] }}
            onButtonClick={openModal}
            isLight
          />
        </RightSection>
      </HeaderSectionsContainer>

      <PreviewModeProvider>{modalOpen && <CreateSlideModal />}</PreviewModeProvider>
    </Fragment>
  );
};
