import styled from 'styled-components';
import { DynamicSlidesDefaultConfigProvider } from '../../../../../../../components/Slide/dynamic/DynamicSlidesDefaultConfigProvider';
import { useSelectedSlide } from '../../../../../../../providers/providers/SelectedSlideProvider';
import { useCreateSlideProps } from '../../../../../providers/CreateSlidePropsProvider';
import { Accordion } from '../../../../../../../components/Common/V2/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, SectionTitle, ThumbnailImage, ThumbnailWrapper } from './styles';
import { FEATURES, useHasFeatureFlag } from '../../../../../../../hooks/useFeatureFlags';

const Wrapper = styled.div`
  padding: 20px 0;
`;

export const UploadContentExpandable = () => {
  const { orderById, groups } = DynamicSlidesDefaultConfigProvider.getConfigs().singleTemplate;
  const { selectedSlide, setSelectedSlide } = useSelectedSlide();
  const { editing } = useCreateSlideProps();

  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const theSlide = editing ? editing : selectedSlide ? selectedSlide : null;

  return (
    <Accordion
      prefixIcon={<FontAwesomeIcon icon={['fas', 'upload']} />}
      title='Upload Your Content'
      open={true}
      containerStyle={{
        width: '100%',
        border: 'none',
      }}
    >
      <Wrapper>
        <Container>
          {orderById.map(groupId => {
            const group = groups[groupId];

            return (
              <Col2 key={groupId}>
                <SectionTitle>{group.label}</SectionTitle>
                <Description>
                  {hasBtTheme && Boolean(group.boldTrailDescription)
                    ? group.boldTrailDescription
                    : group.description}
                </Description>
                <Row>
                  {group.templates.map(t => (
                    <ThumbnailWrapper
                      key={t.config.template}
                      active={t.config.template === selectedSlide?.template}
                    >
                      <ThumbnailImage
                        style={{
                          backgroundImage: `url('${t.thumbnail}')`,
                        }}
                        active={t.config.template === selectedSlide?.template}
                        onClick={() =>
                          setSelectedSlide(
                            theSlide
                              ? DynamicSlidesDefaultConfigProvider.mergeWithEditingSlideData(
                                  theSlide,
                                  t.config.template,
                                )
                              : DynamicSlidesDefaultConfigProvider.getTemplateConfig(
                                  t.config.template,
                                ),
                          )
                        }
                      >
                        {groupId !== 'pdfSlide' && (
                          <ThumbnailLabel>
                            {t.config.frame ? 'With header' : 'Without header'}
                          </ThumbnailLabel>
                        )}
                      </ThumbnailImage>
                    </ThumbnailWrapper>
                  ))}
                </Row>
              </Col2>
            );
          })}
        </Container>
      </Wrapper>
    </Accordion>
  );
};

const Col2 = styled(Col)({
  '&:not(:last-child)': {
    marginBottom: 28,
  },
});

const Row = styled('div')({
  display: 'flex',
  gap: '2%',
});

const Description = styled('p')(({ theme }) => ({
  color: theme.colors.v2.text.regular,
  fontSize: 10,
  marginBottom: 10,
  fontWeight: 500,
  position: 'relative',
}));

const ThumbnailLabel = styled('span')(({ theme }) => ({
  color: theme.colors.v2.text.regular,
  fontWeight: 500,
  fontSize: 10,
  position: 'absolute',
  bottom: -20,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 80,
  textAlign: 'center',
}));
