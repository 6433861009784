import React from 'react';
import styled from 'styled-components';

import { TProperty } from '../../../../../../../../types';
import { MapAndPhotos } from '../../../../../../../Property/MapAndPhotos/MapAndPhotos';
import { DetailDescription } from './DetailDescription/DetailDescription';

interface Props {
  property: TProperty | null;
}
export const Property: React.FC<Props> = ({ property }) => {
  if (!property) {
    return null;
  }

  return (
    <Wrapper>
      <MapAndPhotos property={property} isBT />
      <DetailDescription property={property} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
