import React from 'react';

import { SaveAndContinue } from './SaveAndContinue';
import { TPersonalizeSlide } from '../../../../../../types';
import { ModalBody, ModalFooter } from '../sections.styles';
import { Modal } from '../../../../../../components/Common/';
import { Button } from '../../../../../../components/Common/V2/Button/Button';
import { CategoryProvider } from '../../../../../../providers/providers/CategoryProvider';
import { UiConfigFontsProvider } from '../../../../../../providers/providers/UiConfigFontProvider';
import { UiConfigThemeProvider } from '../../../../../../providers/providers/UiConfigThemeProvider';
import { UiConfigColorsProvider } from '../../../../../../providers/providers/UiConfigColorProvider';
import { ModalContentAbout } from './PersonalizationModalContents/ModalContentAbout/ModalContentAbout';
import { ModalInfoHeader } from '../../../../components/ModalInfoHeader/ModalInfoHeader.component';
import { ModalContentTestimonials } from './PersonalizationModalContents/ModalContentTestimonials/ModalContentTestimonials.component';

interface PersonalizationModalComponentProps {
  personalizeSlide: TPersonalizeSlide | null;
  onClose: () => void;
}

const slideSpecifics = {
  aboutMe: {
    label: 'About Me',
    component: ModalContentAbout,
  },
  testimonials: {
    label: 'Testimonials',
    component: ModalContentTestimonials,
  },
};

export const PersonalizationModalComponent: React.FC<PersonalizationModalComponentProps> = ({
  personalizeSlide,
  onClose,
}) => {
  if (!personalizeSlide) return null;

  const description = slideSpecifics[personalizeSlide]?.label ?? '';
  const Content = slideSpecifics[personalizeSlide]?.component ?? (() => null);

  return (
    <CategoryProvider>
      <UiConfigFontsProvider>
        <UiConfigColorsProvider>
          <UiConfigThemeProvider>
            <Modal
              open={!!personalizeSlide}
              title='Personalize Your Content'
              onClose={onClose}
              isBlueHeader
            >
              <ModalInfoHeader
                title='Personalize your content to showcase your strengths, abilities and experience.'
                description={`Once the selections are made, this will be your default “${description}” slide. We have provided some helps to better cater the contents to better reflect you and your business.`}
              />

              <ModalBody>
                <Content />
              </ModalBody>
              <ModalFooter>
                <Button variant='secondary' onClick={onClose}>
                  Cancel
                </Button>
                <SaveAndContinue onClose={onClose} />
              </ModalFooter>
            </Modal>
          </UiConfigThemeProvider>
        </UiConfigColorsProvider>
      </UiConfigFontsProvider>
    </CategoryProvider>
  );
};
