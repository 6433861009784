import React, { Component } from 'react';
import { Range as RcRange } from 'rc-slider';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import { withTheme } from 'styled-components';
class RangeComponent extends Component {
  static propTypes = {
    marks: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
    initValue: PropTypes.arrayOf(PropTypes.any),
    changed: PropTypes.func,
    step: PropTypes.number,
    customHandle: PropTypes.func,
  };

  static defaultProps = {
    marks: [],
    initValue: null,
    changed: f => f,
    step: 1,
    customHandle: null,
  };

  defaultStyle = {
    trackStyle: [
      {
        backgroundColor: this.props.theme.colors.v2.primary,
        height: '2px',
        top: '6px',
      },
    ],
    handleStyle: [
      {
        borderColor: this.props.theme.colors.v2.primary,
        backgroundColor: this.props.theme.colors.v2.primary,
        borderWidth: '3px',
        boxShadow: 'none',
      },
      {
        borderColor: this.props.theme.colors.v2.primary,
        backgroundColor: this.props.theme.colors.v2.primary,
        borderWidth: '3px',
        boxShadow: 'none',
      },
    ],
    railStyle: {
      backgroundColor: this.props.theme.colors.v2.gray[200],
      height: '2px',
      top: '6px',
    },
    dotStyle: { display: 'none' },
    activeDotStyle: { display: 'none' },
  };

  render() {
    const { marks, changed, initValue, step, customHandle } = this.props;

    const sliderMarks = marks.reduce(
      (combined, mark) => ({
        ...combined,
        [mark.value]: {
          label: mark.label,
          style: {
            fontSize: '12px',
            fontWeight: '700',
            color: this.props.theme.colors.v2.gray[400],
            marginTop: '10px',
          },
        },
      }),
      {},
    );

    const min = Object.keys(sliderMarks).length
      ? Math.min(...Object.keys(sliderMarks).map(Number))
      : 0;

    const max = Object.keys(sliderMarks).length
      ? Math.max(...Object.keys(sliderMarks).map(Number))
      : 0;

    const handle = customHandle ? { handle: customHandle } : {};

    return (
      <div data-test-card-slider={true}>
        <RcRange
          min={min}
          max={max}
          marks={sliderMarks}
          onChange={changed}
          value={initValue}
          step={step}
          {...this.defaultStyle}
          {...handle}
        />
      </div>
    );
  }
}

const Range = withTheme(RangeComponent);

export { Range };
