import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { PricingMisconceptionsPDF } from './pdf/PricingMisconceptionsPDF';
import { PricingMisconceptions } from './web/PricingMisconceptions.component';

const id = 'pricingMisconceptions';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Pricing Misconceptions',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: PricingMisconceptions,
  },
  pdf: {
    shared: PricingMisconceptionsPDF,
  },
};

export type TPricingMisconceptions = typeof id;
export { config, mappings };
