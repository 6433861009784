import React from 'react';
import { UploadFileModal } from './UploadFileModal/UploadFileModal.component';

interface Props {
  open: boolean;
  onClose: () => void;
  initialSrcWeb?: string;
  initialSrcPdf?: string;
}

export const UploadPdfPresentationModal: React.FC<Props> = ({
  open,
  onClose,
  initialSrcPdf,
  initialSrcWeb,
}) => {
  return (
    <UploadFileModal
      open={open}
      onClose={onClose}
      webDataProperty='webPdf'
      pdfDataProperty='pdf'
      fileType='pdf'
      heading='Upload PDF'
      initialSrcPdf={initialSrcPdf}
      initialSrcWeb={initialSrcWeb}
    />
  );
};
