import React, { useEffect, useState } from 'react';
import { StorageService } from '../../../../services/storageService';
import { TSlide, TSlideInfoPlacement } from '../../../../types';
import {
  IconContainer,
  Icon,
  MessageContainer,
  Typography,
  AgreeText,
} from './InteractiveSlideIcon.styles';

interface InteractiveSlideIconProps {
  infoPlacement?: TSlideInfoPlacement;
  isFirstInteractiveSlide: boolean;
}

export const InteractiveSlideIcon: React.FC<InteractiveSlideIconProps> = ({
  infoPlacement,
  isFirstInteractiveSlide,
}) => {
  const seen = StorageService.read('interactiveIconSeen');
  const [showInteractiveSlideInfo, setShowInteractiveSlideInfo] = useState(
    seen ? false : isFirstInteractiveSlide,
  );

  const onAgreeClick = () => {
    setShowInteractiveSlideInfo(false);
    StorageService.save('interactiveIconSeen', true);
  };

  return (
    <IconContainer isInfoPlacementBottom={infoPlacement?.value === 'bottom'}>
      {showInteractiveSlideInfo && (
        <MessageContainer>
          <Typography variant='body1' color='#fff'>
            This icon will appear on pages that have interactivity.
          </Typography>
          <AgreeText variant='body1' onClick={onAgreeClick}>
            Got it!
          </AgreeText>
        </MessageContainer>
      )}
      <Icon iconName='interactive_slide' fill='#444' />
    </IconContainer>
  );
};
