import React from 'react';
import image from './image.png';
import * as data from '../../data';
import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';
import { useUser } from '../../../../../providers/UserProvider';

const WhereBuyersGetTheirInfoLuxuryPDF: React.FC = () => {
  const { isCanadaUser } = useUser();
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading={data.heading}
      listItems={data.getListItems(isCanadaUser)}
      paragraphs={data.paragraphs}
      image={image}
      noColons
    />
  );
};

export default WhereBuyersGetTheirInfoLuxuryPDF;
