import profit_img from '../../assets/icons/profit.png';
import efficient_img from '../../assets/icons/efficient.png';
import effective_img from '../../assets/icons/effective.png';
import trusted_img from '../../assets/icons/trusted.png';
import experience_img from '../../assets/icons/experience.png';
import { CardComponentProps } from '../../../../../components/Card/Card.component';

export const cards: CardComponentProps[] = [
  {
    title: 'VALUE OF AN AGENT',
    text: 'According to a Zillow 2019 study, more than a third of Americans cry while selling their home',
    icon: undefined,
    primaryColorTitle: true,
  },
  {
    title: 'PROFIT',
    text: 'The typical FSBO home sold for, on average, 40% less than agent-assisted home sales',
    icon: profit_img,
  },
  {
    title: 'EFFICIENT',
    text: 'On average, FSBO homes take 19 days longer to sell than homes listed with an agent',
    icon: efficient_img,
  },
  {
    title: 'EFFECTIVE',
    text: '36% of sellers attempted a FSBO sale before turning to an agent',
    icon: effective_img,
  },
  {
    title: 'TRUSTED',
    text: '92% of homes in the US are sold using an agent or a broker',
    icon: trusted_img,
  },
  {
    title: 'EXPERIENCE',
    text: '90% of buyers would use their agent again or recommend their agent to others',
    icon: experience_img,
  },
];
