import styled from 'styled-components';

import { TCompConfigItem } from '../../../../types';
import { CategoryCheckbox } from '../components/CategoryCheckbox';

interface Props {
  groups: TCompConfigItem[][];
}

export const TrendsGroups = ({ groups }: Props) => {
  return (
    <CheckboxGroups>
      {groups.map((group, i) => {
        return (
          <CheckboxGroup key={i}>
            {group.map(item => {
              return <CategoryCheckbox key={item.id} field={item} />;
            })}
          </CheckboxGroup>
        );
      })}
    </CheckboxGroups>
  );
};

const CheckboxGroups = styled.div`
  display: flex;
`;

const CheckboxGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
