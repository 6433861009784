export default `
  <svg width="1539" height="797" viewBox="0 0 1539 797" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="89.0488" cy="450.732" r="86.8293" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <circle cx="221.732" cy="658.537" r="110.244" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <circle cx="330.029" cy="387.322" r="120.405" transform="rotate(-0.269828 330.029 387.322)" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <path d="M1104.66 150.244C1104.66 213.293 1053.76 264.39 991 264.39C928.236 264.39 877.341 213.293 877.341 150.244C877.341 87.195 928.236 36.0977 991 36.0977C1053.76 36.0977 1104.66 87.195 1104.66 150.244Z" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <circle cx="1387.1" cy="134.146" r="132.195" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <circle cx="1450.02" cy="440" r="86.8293" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <circle cx="1204.17" cy="397.073" r="104.39" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <circle cx="748.561" cy="226.341" r="60.4878" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <circle cx="1303.68" cy="653.658" r="87.8049" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
  <path d="M331.488 668.781L396.854 677.074" stroke="#888888" stroke-width="3.90244"/>
  <path d="M169.049 488.293L415.39 579.025" stroke="#888888" stroke-width="3.90244"/>
  <path d="M458.317 479.513L425.634 457.562" stroke="#888888" stroke-width="3.90244"/>
  <path d="M572.951 314.146L603.683 361.951" stroke="#888888" stroke-width="3.90244"/>
  <path d="M749.537 287.317L751.488 316.098" stroke="#888888" stroke-width="3.90244"/>
  <path d="M1215.88 667.806L1143.68 677.074" stroke="#888888" stroke-width="3.90244"/>
  <path d="M1371.98 478.05L1124.17 570.245" stroke="#888888" stroke-width="3.90244"/>
  <path d="M1077.34 481.951L1118.32 456.098" stroke="#888888" stroke-width="3.90244"/>
  <path d="M946.61 256.099L906.61 334.635" stroke="#888888" stroke-width="3.90244"/>
  <path d="M991.488 397.074L1210.02 134.635H1252.95" stroke="#888888" stroke-width="3.90244"/>
  <circle cx="771.323" cy="687.152" r="373.006" stroke="#027B76" stroke-width="15.6098"/>
  <circle cx="537.829" cy="254.635" r="68.2927" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
</svg>
`;
