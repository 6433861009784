import { TSlide, TSlideId } from '../types';
import { config as cover } from '../components/Slide/slides/startingSlides/Cover';
import standaloneSlidesConfig from '../components/Slide/services/StandaloneSlidesGroup';
import { config as cmaReport } from '../components/Slide/slides/whatsMyHomeWorth/cmaReport';
import { config as netProceeds } from '../components/Slide/slides/closingAndNextSteps/netProceeds';
import { config as marketOverview } from '../components/Slide/slides/marketAnalysis/marketOverview';
import { config as greetingVideoConfig } from '../components/Slide/slides/startingSlides/GreetingVideo';
import { config as closingConfig } from '../components/Slide/slides/closingAndNextSteps/closingAndNextSteps';
import { config as buyerDemand } from '../components/Slide/slides/marketingAndBuyerActivitySection/buyerDemand';
import { config as buyerInterestConfig } from '../components/Slide/slides/marketingAndBuyerActivitySection/BuyerInterest';
import { config as comparativeMarketAnalysisConfig } from '../components/Slide/slides/whatsMyHomeWorth/comparativeMarketAnalysis';

const common: Partial<Record<TSlideId, TSlide>> = {
  ...standaloneSlidesConfig,
  [cover.id]: cover,
  [cmaReport.id]: cmaReport,
  [marketOverview.id]: marketOverview,
  [netProceeds.id]: netProceeds,
  [closingConfig.id]: closingConfig,
  [buyerDemand.id]: buyerDemand,
  [buyerInterestConfig.id]: buyerInterestConfig,
  [greetingVideoConfig.id]: greetingVideoConfig,
  [comparativeMarketAnalysisConfig.id]: comparativeMarketAnalysisConfig,
};

export default common;
