import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 20px;
`;
export const SmallText = styled.span`
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

export const LargeText = styled.p`
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
