import React, { useState, useEffect, Fragment } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';

import {
  DynamicSection,
  OrderableSlidesComponentProps,
} from './DynamicSection/DynamicSection.component';
import { Container } from './DynamicOrderableSections.styles';
import {
  TDynamicSection,
  TDynamicSectionId,
  TSection,
  TSectionConfig,
  TSlide,
} from '../../../../../types';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { useActiveSection } from '../../../../../components/Slide/providers/ActiveSectionProvider';
import { usePresentationType } from '../../../../../providers/providers/PresentationTypeProvider';

const sectionsOpenState = (sections: TSection[]): { [key in TSection]?: boolean } => {
  const obj: { [key in TSection]?: boolean } = {};
  sections.forEach((s, i) => (obj[s] = false));

  return obj;
};

interface SlideSectionsProps {
  sectionsOrder: TSection[];
  onSectionsOrderChange: (updatedSectionsOrder: TSection[]) => void;
  sections: Record<TDynamicSectionId, TDynamicSection>;
  extractSlideSection?: (slide: TSlide) => TSectionConfig | null;
  OrderableSlidesComponent: React.FC<OrderableSlidesComponentProps>;
  openInitialSection?: boolean;
}

export const DynamicOrderableSections: React.FC<SlideSectionsProps> = ({
  sectionsOrder,
  onSectionsOrderChange,
  sections,
  extractSlideSection = () => {},
  OrderableSlidesComponent,
  openInitialSection,
}) => {
  const { selectedSlide } = useSelectedSlide();
  const { activeSection, setActiveSection } = useActiveSection();
  const { presentationType } = usePresentationType();

  const [open, setOpen] = useState({
    ...sectionsOpenState(sectionsOrder),
    [sectionsOrder[0]]: true,
  });

  useEffect(() => {
    if (openInitialSection) {
      setOpen({
        ...sectionsOpenState(sectionsOrder),
        [sectionsOrder[0]]: true,
      });
    }
  }, [openInitialSection, sectionsOrder]);

  const onToggle = React.useCallback(
    (sectionId: TSection) => {
      setOpen(prev => ({ ...sectionsOpenState(sectionsOrder), [sectionId]: !prev[sectionId] }));
      setActiveSection(sectionId);
    },
    [sectionsOrder, setActiveSection],
  );

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    if (destinationIndex === sectionsOrder.length - 1) return;
    if (destinationIndex === 0 && presentationType !== 'presentInfo') return;

    const updatedOrderById = [...sectionsOrder];
    const movingItem = updatedOrderById.splice(sourceIndex, 1);
    updatedOrderById.splice(destinationIndex, 0, movingItem[0]);

    onSectionsOrderChange(updatedOrderById);
  };

  useEffect(() => {
    if (!selectedSlide) return;

    const isSectionContents = selectedSlide?.id === 'sectionContents';
    if (isSectionContents && activeSection && !open[activeSection as TSection]) {
      onToggle(activeSection as TSection);
      return;
    }

    const sectionId = extractSlideSection(selectedSlide)?.id;
    if (sectionId && !open[sectionId]) {
      onToggle(sectionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlide]);

  if (!Object.keys(sections).length) return null;

  return (
    <Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'sections-content'} type={'sections-content'}>
          {(provided, snapshot) => (
            <Container ref={provided.innerRef} {...provided.droppableProps}>
              {sectionsOrder.map((sec, i) => (
                <DynamicSection
                  key={sec}
                  index={i}
                  section={sections[sec]}
                  open={!!open[sec]}
                  onToggle={onToggle}
                  OrderableSlidesComponent={OrderableSlidesComponent}
                />
              ))}
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
};
