import styled from 'styled-components';
import { useUiConfigFontsHeader } from '../../../../../../../../../providers/providers/UiConfigFontProvider';

type WithIBuyer = {
  isIBuyer?: boolean;
};

export const CardHeader = styled('div')<WithIBuyer>(({ isIBuyer }) => ({
  display: 'flex',
  flexDirection: isIBuyer ? 'row-reverse' : 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: useUiConfigFontsHeader(),
  height: 110,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  padding: isIBuyer ? '0 50px 0 40px' : '0 40px 0 50px',
}));

export const CardImageContainer = styled('div')({
  height: '145%',
  marginTop: -10,
});

export const Image = styled('img')({
  height: '100%',
});

export const CardHeaderTitle = styled('h6')({
  fontSize: 25,
  fontWeight: 900,
  color: '#000',
  marginTop: 20,
  alignSelf: 'flex-start',
});
