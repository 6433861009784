import React, { useMemo } from 'react';

import {
  LeftCol,
  RightCol,
  TitleSection,
  Title,
  SubTitle,
  BuyerGraphWrapper,
} from './BuyerInterest.styles';
import { SlideContainer } from '../../../../Slide.styles';
import { useSlide, useSlideProps } from '../../../../providers/SlideProvider';
import { PotentialBuyers } from './PotentialBuyers/PotentialBuyers.component';
import { BuyerInterestInfo } from './BuyerInterestInfo/BuyerInterestInfo.component';
import { mockedData } from '../data';

interface BuyerInterestProps {
  isWeb: boolean;
}
export const BuyerInterest: React.FC<BuyerInterestProps> = ({ isWeb = true }) => {
  const slide = useSlide();

  const { showTemplatePreview } = useSlideProps();

  const data = useMemo(
    () => (showTemplatePreview ? { data: mockedData } : slide?.buyerInterestData),
    [showTemplatePreview, slide?.buyerInterestData],
  );

  if (!data) {
    return (
      <SlideContainer
        style={{
          padding: isWeb ? '3%' : '0',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>There is no data for this slide</p>
      </SlideContainer>
    );
  }
  const leads = data?.data?.leads?.contacts?.details?.slice(0, 4) ?? [];

  return (
    <SlideContainer
      style={{
        flexDirection: isWeb ? 'row' : 'column',
        padding: isWeb ? '3%' : '0',
      }}
    >
      <LeftCol
        isWeb={isWeb}
        style={{
          width: isWeb ? (leads?.length > 0 ? '76%' : '100%') : '100%',
        }}
      >
        {isWeb && (
          <TitleSection>
            <Title>I have interested buyers searching in this area</Title>
            <SubTitle>
              Our office works with both buyers and sellers and keeps an up to date funnel of
              interest.
            </SubTitle>
          </TitleSection>
        )}
        <BuyerGraphWrapper isWeb={isWeb}>
          <BuyerInterestInfo data={data.data} isWeb={isWeb} />
        </BuyerGraphWrapper>
      </LeftCol>
      {leads?.length > 0 && isWeb && (
        <RightCol>
          <PotentialBuyers data={leads} />
        </RightCol>
      )}
    </SlideContainer>
  );
};
