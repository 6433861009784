import React from 'react';

import { useViewMode } from '../../providers/ViewModeProvider';
import { useSelectedProperty } from '../../providers/SelectedPropertyProvider';
import { Map } from '../../../../../../../../features/BuyerTour/components/Map/Map.component';
import { TProperty } from '../../../../../../../../types';

interface Props {
  properties: TProperty[];
  useNumberedMarkers?: boolean;
}
export const PropertiesMap: React.FC<Props> = ({ properties, useNumberedMarkers }) => {
  const { setViewMode } = useViewMode();
  const { setSelectedProperty } = useSelectedProperty();

  const onMoreDetailsClick = React.useCallback(
    function onMoreDetailsClick(property) {
      setSelectedProperty(property);
      setViewMode('property');
    },
    [setSelectedProperty, setViewMode],
  );

  return (
    <Map
      style={{ height: 730 }}
      properties={properties}
      onMoreDetailsClick={onMoreDetailsClick}
      useNumberedMarkers={useNumberedMarkers}
    />
  );
};
