import React from 'react';
import { CSSProperties } from 'styled-components';
import { useInvertedScaleFactor } from '../PreviewSlide/ScaleFactorProvider';

interface ScaleInverterProps {
  style: CSSProperties;
}

export const MapScaleInverter: React.FC<ScaleInverterProps> = ({
  style = { width: '100%', height: '100%' },
  children,
}) => {
  const invertedScaleFactor = useInvertedScaleFactor();

  const calculateAdaptedDimension = (factor: number, dimension: string) => {
    const val = Number.parseInt(dimension) / factor;
    const isPixelUnit = String(dimension).endsWith('px');

    return isPixelUnit ? `${val}px` : `${val}%`;
  };

  const adaptedWidth = React.useMemo(
    () => calculateAdaptedDimension(invertedScaleFactor, String(style.width)),
    [invertedScaleFactor, style.width],
  );

  const adaptedHeight = React.useMemo(
    () => calculateAdaptedDimension(invertedScaleFactor, String(style.height)),
    [invertedScaleFactor, style.height],
  );

  return (
    <div style={style}>
      <div
        style={{
          transform: `scale(${invertedScaleFactor})`,
          transformOrigin: '0 0',
          transition: 'transform 0.1s linear',
          width: adaptedWidth,
          height: adaptedHeight,
        }}
      >
        {children}
      </div>
    </div>
  );
};
