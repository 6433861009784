import React from 'react';

export const withHeight =
  Component =>
  ({ height, ...props }) => {
    return (
      <div style={{ height: `${height}px` }}>
        <Component {...props} height={height} />
      </div>
    );
  };
