class DB {
  save(key, value) {
    if (!process.browser) return null;
    return localStorage.setItem(key, JSON.stringify(value));
  }

  read(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  has(key) {
    return localStorage.getItem(key) !== null;
  }
}

export const StorageService = new DB();
