import React from 'react';
import { Container, Title, Text, Arrow } from './TocSection.styles';
import { TDynamicSection, TSlide, TSlideId } from '../../../../../types';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import { svgStringToDataUrl } from '../../../../../components/Slide/services/svgStringToDataUrl';
import svg from './arrowSvgString';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';

interface Props extends TDynamicSection {
  activeSlide?: TSlide['id'];
  onSlideClick: (id: TSlide['id']) => void;
  slidesIds: TSlideId[];
}

const TocSection: React.FC<Props> = ({ id, label, slidesIds, activeSlide, onSlideClick }) => {
  const primary = useUiConfigColorsPrimary();
  const img = React.useMemo(
    () => (activeSlide ? svgStringToDataUrl(svg, primary) : null),
    [primary, activeSlide],
  );
  const { slides } = useSlides();

  const slidesList = slidesIds.map(slideId => slides?.[slideId]);

  if (['startingSlides'].includes(id)) return null;
  return (
    <Container>
      <Title onClick={() => onSlideClick(slidesIds[0])}>{label}</Title>
      {slidesList.map((s, i) => (
        <Text key={i} onClick={() => onSlideClick(s?.id ?? '')} active={activeSlide === s?.id}>
          {img && activeSlide === s?.id && <Arrow src={img} />}
          {s?.label}
        </Text>
      ))}
    </Container>
  );
};

export default TocSection;
