import React, { useState } from 'react';

import {
  THeatmapTracking,
  TSection,
  TSlide,
  TSlideInfoPlacement,
  TSlidePlatform,
  TSlideTheme,
  TUser,
  TWhiteLabelSlidesProp,
} from '../../../../types';
import Slide from '../../../../components/Slide/Slide';
import PreviewSlide from '../../../../components/PreviewSlide/PreviewSlide.component';
import { useSetHeatmapData } from '../../../../providers/providers/heatmap/useHeatmapData';

export interface SlideProps {
  platform?: TSlidePlatform;
  slide: TSlide;
  user?: TUser;
  resizable?: boolean;
  activeSection?: TSection;
  theme?: TSlideTheme;
  infoPlacement?: TSlideInfoPlacement;
  presentationHash?: string;
  hideProgressTracker?: boolean;
  whiteLabelSettings?: TWhiteLabelSlidesProp | null;
}

const PresentationSlide: React.FC<SlideProps> = ({
  platform = 'web',
  user,
  theme,
  infoPlacement,
  slide,
  resizable = false,
  activeSection,
  presentationHash,
  hideProgressTracker,
  whiteLabelSettings,
}) => {
  const setHeatmapData = useSetHeatmapData();

  if (!theme || !infoPlacement) return null;

  const Component = resizable ? PreviewSlide : Slide;

  const onCursorTracking = (trackingEvent: THeatmapTracking) => {
    const cursorEvent: keyof THeatmapTracking = trackingEvent.clicks ? 'clicks' : 'movements';

    if (!trackingEvent[cursorEvent]) return;

    const e = trackingEvent[cursorEvent] as unknown as MouseEvent;

    const pageX = e.pageX;
    const pageY = e.pageY;
    const innerWidth = e.view?.innerWidth ?? 0;
    const innerHeight = e.view?.innerHeight ?? 0;

    const percentage = innerWidth / 16;
    const slideHeight = (innerWidth * 9) / 16;
    const heightOffset = innerHeight - slideHeight;

    const pY = pageY - heightOffset / 2;

    const x = (pageX * 100) / percentage;
    const y = (pY * 100) / percentage;

    const data = {
      x: Math.round(x),
      y: Math.round(y),
      value: 1,
    };

    setHeatmapData(prev => ({
      ...prev,
      [cursorEvent]: [...(prev[cursorEvent] ?? []), data],
    }));
  };

  return (
    <Component
      platform={platform}
      user={user}
      slide={slide}
      theme={theme}
      infoPlacement={infoPlacement?.value}
      activeSection={activeSection}
      showMultiPages
      presentationHash={presentationHash}
      hideProgressTracker={hideProgressTracker}
      onCursorTracking={onCursorTracking}
      whiteLabelSettings={whiteLabelSettings}
    />
  );
};

export default PresentationSlide;
