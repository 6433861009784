import { TSlide } from '../../../../../types';
import { NetProceeds } from './web/NetProceeds.component';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { NetProceedsPDF } from './pdf/NetProceedsPDF';

const id = 'netProceeds';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Net Proceeds',
  frame: true,
  netProceedsData: [],
  excludeFromConfig: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: NetProceeds,
  },
  pdf: {
    shared: NetProceedsPDF,
  },
};

export type TNetProceeds = typeof id;
export { config, mappings };
