import React, { useEffect } from 'react';

import { GoBack } from './GoBack';
import { ModalLayout } from './ModalLayout';
import { SaveAndContinue } from './SaveAndContinue';
import { useEntity } from '../../../../../providers/EntityProvider';
import { StepProvider } from '../../../../../../../providers/providers/StepProvider';
import { useUser } from '../../../../../../../components/Slide/providers/UserProvider';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { DefaultConfigProvider } from '../../../../../../../services/DefaultConfigProvider';
import { UiConfigProvider } from '../../../../../../../providers/providers/UiConfigProvider';
import { useSlides } from '../../../../../../../providers/providers/SlidesProvider';
import { useCustomizationSettings } from '../../../../../../../hooks/useCustomizationSettings';
import { useSections } from '../../../../../../../providers/providers/SectionsProvider';
import { useCustomizationOrder } from '../../../../../../../providers/providers/CustomizationOrderProvider';
import { CustomizationConfigs } from '../../../../../../../features/Customization/services/CustomizationConfigs';
import { useUserCustomizations } from '../../../../../../../hooks/useUserCategoryCustomizations';
import { useIsReferenceChanged } from '../../../../../../../hooks/utils/useIsReferenceChanged';

export const CustomizationModalComponent: React.FC = () => {
  const user = useUser();
  const hash = user?.hash ?? '';
  const { entity, entityMode, resetEntity } = useEntity();
  const { category, resetCategory } = useCategory();

  const { data } = useUserCustomizations(hash, entity, user?.useSpecialBranding, entityMode);

  const { setCustomizationSectionsSlideByType, setCustomizationSectionsByType } =
    useCustomizationOrder();
  const { setSlides } = useSlides();
  const { setSections } = useSections();

  const resetCategoryAndEntity = React.useCallback(() => {
    resetCategory();
    resetEntity();
  }, [resetCategory, resetEntity]);

  const defaultConfigs = DefaultConfigProvider.getConfigs();

  const { orderByType, slides } = useCustomizationSettings(hash, entityMode, entity);

  useEffect(() => {
    if (!orderByType?.winTheListing?.sectionSlides || !slides) return;

    setCustomizationSectionsByType({
      winTheListing: orderByType?.winTheListing?.sections,
      winTheOffer: orderByType?.winTheOffer?.sections,
      presentInfo: orderByType?.presentInfo?.sections,
      winTheRepresentation: orderByType?.winTheRepresentation?.sections,
    });

    setCustomizationSectionsSlideByType({
      winTheListing: orderByType?.winTheListing?.sectionSlides,
      winTheOffer: orderByType?.winTheOffer?.sectionSlides,
      presentInfo: orderByType?.presentInfo?.sectionSlides,
      winTheRepresentation: orderByType?.winTheRepresentation?.sectionSlides,
    });
    setSlides(slides);
    setSections(CustomizationConfigs.getSections());
  }, [
    orderByType,
    setCustomizationSectionsByType,
    setCustomizationSectionsSlideByType,
    setSections,
    setSlides,
    slides,
  ]);

  if (!category) return null;

  return (
    <StepProvider>
      <UiConfigProvider
        key={entity}
        initValue={data?.[category]?.ui}
        fallback={defaultConfigs[category].ui}
      >
        <ModalLayout
          key={entity}
          category={category}
          footer={
            <>
              <GoBack onClose={resetCategoryAndEntity} />
              <SaveAndContinue category={category} onFinish={resetCategoryAndEntity} />
            </>
          }
          onClose={resetCategoryAndEntity}
        />
      </UiConfigProvider>
    </StepProvider>
  );
};
