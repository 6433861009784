import React from 'react';
import { Container, Row, RowItem } from './PotentialBuyersTable.styles';
import { StarRating } from '../../components/StarRating/StarRating.component';
interface PotentialBuyersTableProps {
  leads: any[];
}
export const PotentialBuyersTable: React.FC<PotentialBuyersTableProps> = ({ leads }) => {
  return (
    <Container>
      <Row>
        <RowItem isHead>BUYER ID</RowItem>
        <RowItem isHead>EMAIL</RowItem>
        <RowItem isHead>INTERESTS</RowItem>
      </Row>
      {leads?.slice(0, 4).map(buyer => (
        <Row key={buyer.id}>
          <RowItem>{buyer.id}</RowItem>
          <RowItem>xxxx{`@${buyer.contact_mask.split('@')[1]}`}</RowItem>
          <RowItem>{buyer.interest_description}</RowItem>
        </Row>
      ))}
    </Container>
  );
};
