import React from 'react';
import styled from 'styled-components';
import slideGraphics from './assets/slideGraphics';
import circle from './assets/circle';
import sign from './assets/sign.png';
import bg from './assets/bg.png';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { alternativeSlideContent, slideContent } from '../../data';
import { useUser } from '../../../../../providers/UserProvider';

type WithCanadaUser = {
  isCanadaUser: boolean;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 40px;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const DescriptionWrapper = styled('div')<WithCanadaUser>(({ isCanadaUser }) => ({
  width: isCanadaUser ? '100%' : 353,
}));

const Description = styled.p`
  position: relative;
  z-index: 5;
  padding-left: 40px;
  letter-spacing: ${() => useUiConfigBodyLetterSpacing()};
  margin-bottom: 20px;
  line-height: 1.3;
  font-size: 16px;
`;

const Background = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  width: isCanadaUser ? 668 : 393,
  position: 'absolute',
  right: isCanadaUser ? 109 : 122,
  bottom: isCanadaUser ? 10 : 284,
}));

const Sign = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  position: 'absolute',
  height: isCanadaUser ? 727 : 397,
  right: isCanadaUser ? 288 : 242,
  top: isCanadaUser ? 258 : 314,
  filter: 'drop-shadow(1.3534px 1.3534px 6.767px rgba(0,0,0,0.25)) grayscale(1)',
  clipPath:
    'polygon(50% 0%, 100% 0px, 100% 35%, 100% 100%, 60% 100%, 41.67% 97.62%, 15.22% 82.13%, 0px 68%, 0px 0px, 20% 0px)',
}));

const Graphics = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  position: 'absolute',
  width: isCanadaUser ? '76%' : '88%',
  height: 'auto',
  right: isCanadaUser ? 100 : 55,
  bottom: 0,
}));

const ValueOfAnAgentTraditionalPdf: React.FC = () => {
  const { isCanadaUser } = useUser();
  const primary = useUiConfigColorsPrimary();

  const graphics = isCanadaUser ? circle : slideGraphics;
  const image = React.useMemo(() => svgStringToDataUrl(graphics, primary), [graphics, primary]);

  const paragraphs = isCanadaUser ? alternativeSlideContent.paragraphs : [slideContent[0].text];

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container>
        <Heading>{slideContent[0].title}</Heading>
        <DescriptionWrapper isCanadaUser={isCanadaUser}>
          {paragraphs.map((paragraph, i) => (
            <Description key={i}>{paragraph}</Description>
          ))}
        </DescriptionWrapper>

        <Background src={bg} isCanadaUser={isCanadaUser} />
        <Graphics src={image} isCanadaUser={isCanadaUser} />
        <Sign src={sign} isCanadaUser={isCanadaUser} />

        {!isCanadaUser && (
          <>
            <TraditionalSlideCircularCard
              heading={slideContent[2].title}
              text={slideContent[2].text}
              position={{ left: 155, top: 215 }}
              size={250}
            />
            <TraditionalSlideCircularCard
              heading={slideContent[1].title}
              text={slideContent[1].text}
              position={{ bottom: 320, left: 35 }}
              size={250}
            />
            <TraditionalSlideCircularCard
              heading={slideContent[3].title}
              text={slideContent[3].text}
              position={{ bottom: 85, left: 105 }}
              size={250}
            />
            <TraditionalSlideCircularCard
              heading={slideContent[4].title}
              text={slideContent[4].text}
              position={{ bottom: -5, right: 320 }}
              size={230}
            />
            <TraditionalSlideCircularCard
              heading={slideContent[5].title}
              text={slideContent[5].text}
              position={{ bottom: 25, right: 55 }}
              size={230}
            />
          </>
        )}
      </Container>
    </PdfFrame>
  );
};

export default ValueOfAnAgentTraditionalPdf;
