import { useQueryClient } from '@tanstack/react-query';

import { usePresentation } from '../../../../../../hooks/usePresentation';

import { AnalyticsModalContentProvider } from './components/AnalyticsModalContent/AnalyticsModalContentProvider';

import Loader from '../../../../../../components/Loader/Loader';
import { Wrapper } from './components/AnalyticsModalContent/AnalyticsModalContent.styles';
import { FitContentModal } from '../../../../../../components/Common';

type Props = {
  hash: string;
  onClose: () => void;
};

export function AnalyticsModal({ hash, onClose }: Props) {
  const { data, isLoading, isError, error } = usePresentation(hash);

  const queryClient = useQueryClient();

  function close() {
    onClose();
    queryClient.invalidateQueries(['presentationAnalytics', hash]);
  }

  let content = null;

  if (isError) {
    content = (
      <Wrapper>
        <h3>{error?.message}</h3>
      </Wrapper>
    );
  } else if (isLoading || !data) {
    content = (
      <Wrapper>
        <Loader size='4x' centered />
      </Wrapper>
    );
  } else if (data) {
    content = <AnalyticsModalContentProvider presentation={data} onCloseModal={close} />;
  }

  return (
    <FitContentModal
      isOpen={true}
      headerTitle='Analytics'
      onClose={close}
      hasConfirmBtn={false}
      modalContainerStyles={{ width: '90%', height: 'max-content' }}
      contentStyles={{
        minHeight: 'calc(100vh - 200px)',
      }}
    >
      {content}
    </FitContentModal>
  );
}
