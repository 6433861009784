import { useAtomValue } from 'jotai';
import { subjectAtom } from '../../../features/report/state/subjectAtom';
import { TProperty } from '../../../types';
import { CompettionMap } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/CompResults/CompettionMap/CompettionMap';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';

function adaptProperty(property: TProperty) {
  return [
    {
      lat: property.coordinates.latitude,
      lng: property.coordinates.longitude,
      address: property.address.deliveryLine,
      excluded: property.excluded,
      status: property.status,
      property: property,
    },
  ];
}

interface Props {
  property: TProperty;
}

export const PropertyMap = ({ property }: Props) => {
  const subject = useAtomValue(subjectAtom);
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  return (
    <CompettionMap
      noModal
      subject={subject}
      properties={adaptProperty(property)}
      style={{ height: `200px`, width: `100%` }}
      hasBtTheme={hasBtTheme}
    />
  );
};
