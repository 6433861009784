import { useEffect } from 'react';

import { TPresentation, TPresentationMode, TSlide } from '../types';
import { useOrder } from '../providers/providers/OrderProvider';
import { useSlides } from '../providers/providers/SlidesProvider';

export const usePresentationSlides = (presentation: TPresentation) => {
  const { sections, sectionsSlide, setSectionsOrder, setSectionsSlideOrder } = useOrder();
  const { setSlides } = useSlides();

  useEffect(() => {
    if (!presentation) return;
    const config = presentation[presentation.mode as TPresentationMode];

    if (!config?.order.sections || !config?.order.sectionsSlide) return;

    setSectionsOrder(config.order.sections);
    setSectionsSlideOrder(config.order.sectionsSlide);

    setSlides(presentation.slidesMap);
  }, [presentation, setSectionsOrder, setSectionsSlideOrder, setSlides]);

  if (!sections || !Array.isArray(sections)) return [];

  return (
    sections?.flatMap(sectionId => {
      return sectionsSlide[sectionId]
        .map(slideId => presentation.slidesMap?.[slideId] as TSlide)
        .filter(s => !!s);
    }) ?? []
  );
};
