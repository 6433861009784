import { PRESENTATION_TYPES } from '../../../../../constants/PresentationTypes';
import { BUYER_TOUR } from '../../../../../features/BuyerTour/constants';
import { FEEDBACK_RESPONSE, MORE_TIME_DESCRIPTIONS } from '../../../../../types';
import * as dummyData from '../dummy.data';
import { PastPresentationItem, TPastPresentationSortOption } from '../types';

const defaultItem: PastPresentationItem = {
  ...dummyData.row1[0],
};

type Feedback = {
  feedback: string;
  sellerBarrier: string;
};

type AddresslessPresentation =
  | typeof PRESENTATION_TYPES.BUYER_TOUR
  | typeof PRESENTATION_TYPES.WIN_THE_REPRESENTATION
  | 'info';

export class PastPresentationsUtils {
  static filter = (
    presentations: PastPresentationItem[][],
    filter: string,
  ): PastPresentationItem[][] => {
    if (!filter.trim()) return presentations;
    return presentations.filter(group => {
      const items = group.filter(item => {
        return (
          item.clientName.toLowerCase().includes(filter.toLowerCase()) ||
          item.address.toLowerCase().includes(filter.toLowerCase())
        );
      });

      return !!items.length;
    });
  };

  static sort = (
    presentations: PastPresentationItem[][],
    sort: TPastPresentationSortOption,
  ): PastPresentationItem[][] => {
    switch (sort) {
      case 'client_asc':
        return presentations
          .map(item =>
            item.sort((a, b) => {
              return a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0;
            }),
          )
          .sort((a, b) => {
            return a[0].clientName > b[0].clientName
              ? 1
              : b[0].clientName > a[0].clientName
                ? -1
                : 0;
          });
      case 'client_dsc':
        return presentations
          .map(item =>
            item.sort((a, b) => {
              return a.clientName < b.clientName ? 1 : b.clientName < a.clientName ? -1 : 0;
            }),
          )
          .sort((a, b) => {
            return a[0].clientName < b[0].clientName
              ? 1
              : b[0].clientName < a[0].clientName
                ? -1
                : 0;
          });
      case 'address_asc':
        return presentations
          .map(item =>
            item.sort((a, b) => {
              return a.address > b.address ? 1 : b.address > a.address ? -1 : 0;
            }),
          )
          .sort((a, b) => {
            return a[0].address > b[0].address ? 1 : b[0].address > a[0].address ? -1 : 0;
          });
      case 'address_dsc':
        return presentations
          .map(item =>
            item.sort((a, b) => {
              return a.address < b.address ? 1 : b.address < a.address ? -1 : 0;
            }),
          )
          .sort((a, b) => {
            return a[0].address < b[0].address ? 1 : b[0].address < a[0].address ? -1 : 0;
          });
      case 'topFeedback_asc':
        return presentations
          .map(item =>
            item.sort((a, b) => {
              return a.topFeedback > b.topFeedback ? 1 : b.topFeedback > a.topFeedback ? -1 : 0;
            }),
          )
          .sort((a, b) => {
            return a[0].topFeedback > b[0].topFeedback
              ? 1
              : b[0].topFeedback > a[0].topFeedback
                ? -1
                : 0;
          });
      case 'topFeedback_dsc':
        return presentations
          .map(item =>
            item.sort((a, b) => {
              return a.topFeedback < b.topFeedback ? 1 : b.topFeedback < a.topFeedback ? -1 : 0;
            }),
          )
          .sort((a, b) => {
            return a[0].topFeedback < b[0].topFeedback
              ? 1
              : b[0].topFeedback < a[0].topFeedback
                ? -1
                : 0;
          });
      case 'lastUpdated_dsc':
        return presentations
          .map(item =>
            item.sort(
              (a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime(),
            ),
          )
          .sort((a, b) => {
            {
              const aUpdated = a[0]?.lastUpdated ?? 0;
              const bUpdated = b[0]?.lastUpdated ?? 0;
              return aUpdated && bUpdated
                ? new Date(bUpdated).getTime() - new Date(aUpdated).getTime()
                : 0;
            }
          });
      case 'lastUpdated_asc':
      default:
        return presentations
          .map(item =>
            item.sort(
              (a, b) => new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime(),
            ),
          )
          .sort((a, b) => {
            const aUpdated = a[0]?.lastUpdated ?? 0;
            const bUpdated = b[0]?.lastUpdated ?? 0;
            return aUpdated && bUpdated
              ? new Date(aUpdated).getTime() - new Date(bUpdated).getTime()
              : 0;
          });
    }
  };

  private static getPresentationFeedback(presentationAPI: any): Feedback {
    if (!presentationAPI?.feedback_type) {
      return {
        feedback: '',
        sellerBarrier: '',
      };
    }

    if (presentationAPI.feedback_type === 'LETS_GET_STARTED') {
      return {
        feedback: "Let's get started",
        sellerBarrier: '',
      };
    }

    const lastFeedbackInfo: keyof typeof MORE_TIME_DESCRIPTIONS =
      presentationAPI.feedback_more_info[presentationAPI.feedback_more_info?.length - 1] || '';

    return {
      feedback: 'Need more time',
      sellerBarrier: MORE_TIME_DESCRIPTIONS[lastFeedbackInfo] || '',
    };
  }

  static getTableRows = (
    data: any,
    sort: TPastPresentationSortOption,
  ): PastPresentationItem[][] => {
    if (!data) return [];

    const presentationsMap = {
      info: [] as PastPresentationItem[],
      [PRESENTATION_TYPES.BUYER_TOUR]: [] as PastPresentationItem[],
      [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: [] as PastPresentationItem[],
      addressMap: {} as Record<string, PastPresentationItem[]>,
    };

    data.forEach((item: any, i: number) => {
      const createdAt = new Date(item.created_at_timestamp * 1000);

      const feedback = PastPresentationsUtils.getPresentationFeedback(item);

      const presentationItem = {
        ...defaultItem,
        id: item.id,
        hash: item.hash,
        address: item.address || '',
        lastUpdated: createdAt,
        booked: i === 1,
        createdAt,
        buildInProgress: item.build_in_progress,
        pdf: item.pdf,
        clientName: item.client_name || 'No client',
        clientId: item.client_id,
        clientEmail: item.client_email,
        type: item.type,
        mode: item.mode,
        sessionsCount: item.sessions_count,
        canBeRebuilt: item.can_be_rebuilt ?? true,
        topFeedback: feedback.feedback,
        topSellerBarrier: feedback.sellerBarrier,
        feedbackMoreInfo: item.feedback_more_info,
      };

      const addresslessPresentationTypes: AddresslessPresentation[] = [
        PRESENTATION_TYPES.WIN_THE_REPRESENTATION,
        PRESENTATION_TYPES.BUYER_TOUR,
        'info',
      ];

      if (addresslessPresentationTypes.includes(presentationItem.type)) {
        return presentationsMap[presentationItem.type as AddresslessPresentation].push(
          presentationItem,
        );
      }

      if (!presentationsMap.addressMap[presentationItem.address]) {
        presentationsMap.addressMap[presentationItem.address] = [];
      }

      presentationsMap.addressMap[presentationItem.address].push(presentationItem);
    });

    return PastPresentationsUtils.sort(
      [
        presentationsMap.info,
        presentationsMap[PRESENTATION_TYPES.WIN_THE_REPRESENTATION],
        presentationsMap[PRESENTATION_TYPES.BUYER_TOUR],
        ...Object.values(presentationsMap.addressMap),
      ].filter(p => !!p.length),
      sort,
    );
  };

  static deleteOne = (
    presentations: PastPresentationItem[][],
    id: string,
  ): PastPresentationItem[][] => {
    return presentations
      .map(group => group.filter(item => item.id !== id))
      .filter(group => !!group.length);
  };
}
