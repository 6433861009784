import { toast } from 'react-toastify';
import { batch, connect } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';

import {
  usePresentationBuildFlow,
  usePresentationBuildFlowConstants,
} from '../../../../ReportFacade/providers/PresentationBuildFlowProvider';
import { URLS } from '../../../constants/URLS';
import styles from './PropertySearch.module.scss';
import { operations } from '../../../../store/search';
import { criteriaToApiMapper } from './SearchApiMapper';
import { StepsCard } from './components/StepsCard/StepsCard';
import { PropertyDetails } from './PropertyDetails/PropertyDetails';
import { PropertyAddress } from './PropertyAddress/PropertyAddress';
import { operations as reportOperations } from '../../../../store/report';
import { usePresentationCreator } from '../../../../../hooks/usePresentationCreator';
import { useUser } from '../../../../../../../components/Slide/providers/UserProvider';
import { useResultRebuildStateSetter } from '../CompResults/useResultRebuildStateSetter';
import { useSetSearchParameter } from '../../../../../../../hooks/useSetSearchParameter';
import { useUpdatePresentation } from '../../../../../../../hooks/useUpdatePresentation';
import { usePresentationCreationQuery } from '../../../../../../../hooks/usePresentationCreationQuery';
import { ReportDetailedCommunicator } from '../../../../communicators/ReportDetailed/ReportDetailedCommunicator';
import { AddressToReportBuildRequestAdapter } from '../../../../ReportFacade/services/AddressToReportBuildRequestAdapter';
import { PresentationApi } from '../../../../../../Presentation/api/PresentationApi';
import { useUpdatePresentationWithoutSaving } from '../../../../../../../hooks/useUpdatePresentationWithoutSaving';
import { useResetAtomState } from '../../../../../../../features/report/hooks/useResetAtomState';
import { useSetDefaultResultState } from '../../../../../../../features/report/hooks/useSetDefaultResultState';
import { AreaType } from '../../../../../../../types';

export const PropertySearchComponent = ({
  setReportDetailed,
  setApi,
  searchCriteria,
  setSearchCriteria,
  rebuildData,
  client,
  excludedIdsInResult,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { hash } = useUser();

  const [createPresentation, setCreatePresentation] = useState(false);
  const [createdPresentationHash, setCreatedPresentationHash] = useState(null);
  const FLOWS = usePresentationBuildFlowConstants();
  const [presentationBuildFlow, setPresentationBuildFlow] = usePresentationBuildFlow();

  const [partialData, setPartialData] = useState(false);
  const [partialLoading, setPartialLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  useResetAtomState();

  const setDefaultResultState = useSetDefaultResultState();

  const setResultRebuildState = useResultRebuildStateSetter();
  const {
    type,
    isRebuild,
    presentation: presentationHash,
    presentationId,
  } = usePresentationCreationQuery();

  const { getCreatePresentationPayload } = usePresentationCreator();
  const { mutateAsync: updatePresentation } = useUpdatePresentation(presentationHash);
  const { mutateAsync: updatePresentationWithoutSaving } =
    useUpdatePresentationWithoutSaving(presentationHash);

  const setSearchParam = useSetSearchParameter();

  const isAddressToPresentationFlow = useMemo(() => {
    return presentationBuildFlow === FLOWS.ADDRESS_TO_PRESENTATION;
  }, [presentationBuildFlow]);

  const shouldRedirectToAddress = () =>
    !location.pathname.includes(URLS.SEARCH.ADDRESS) && !searchCriteria.id;

  const buildCreationParams = () => {
    const params = new URLSearchParams();

    if (type) params.append('type', type);

    if (presentationHash) params.append('presentation', presentationHash);
    if (presentationId) params.append('presentation_id', presentationId);
    if (isRebuild) params.append('action', 'rebuild');

    return params;
  };

  useEffect(() => {
    if (shouldRedirectToAddress()) {
      const params = buildCreationParams();
      history.push(`${URLS.SEARCH.ADDRESS}?${params?.toString()}`);
    }
  }, []);

  const getExclusions = rebuildData => {
    if (!rebuildData?.request?.excluded_ids) {
      return { excluded_ids: [] };
    }

    return {
      excluded_ids: rebuildData.request.excluded_ids,
    };
  };

  const updateCriteria = updatedCriteria => {
    const combinedCriteria = {
      ...searchCriteria,
      ...updatedCriteria,
    };
    setSearchCriteria(combinedCriteria);
    return combinedCriteria;
  };

  useEffect(
    function onCreatePresentationTrigger() {
      (async function () {
        if (!createPresentation) return;

        try {
          if (isAddressToPresentationFlow && createdPresentationHash) {
            history.push(`/presentation/${createdPresentationHash}/edit`);

            return;
          }

          const params = buildCreationParams();

          history.push(
            `${URLS.RESULT}${!params?.toString()?.length ? '' : '?' + params?.toString()}`,
          );
        } catch (e) {
          console.error(e);
          setLoading(false);
          setCreatePresentation(false);
          setPresentationBuildFlow(FLOWS.ALL_STEPS);
        }
      })(hash);
    },
    [createPresentation, isAddressToPresentationFlow],
  );

  const buildReportFromBasicInfo = async criterias => {
    const api = AddressToReportBuildRequestAdapter.toApi(criterias);
    const sc = AddressToReportBuildRequestAdapter.toDefaultSearchCriteria(api, criterias);

    try {
      const payload = {
        ...api,
        excluded_ids: [],
        presentation_type: type,
        rebuild: isRebuild || undefined,
      };

      if (presentationId) {
        payload.presentation_id = !presentationId ? undefined : Number(presentationId);
      }

      setSearchCriteria(sc);
      setApi({ ...api, excluded_ids: [] });

      const reportDetailed = await ReportDetailedCommunicator.build(payload);
      setSearchParam('presentation', reportDetailed.presentationHash);

      setReportDetailed(reportDetailed);
      setDefaultResultState(reportDetailed);

      const presentationPayload = await getCreatePresentationPayload();
      await updatePresentation(
        { ...presentationPayload, id: reportDetailed.presentationId },
        {
          onSuccess: () => {
            setCreatedPresentationHash(reportDetailed.presentationHash);
            setCreatePresentation(true);
          },
        },
      );

      await PresentationApi.triggerPdfGeneration(reportDetailed.presentationId);
    } catch (e) {
      console.error(e);
      toast(
        'We had trouble creating your presentation. Please try again. If the issue persists, please contact our support team.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
      setLoading(false);
      setCreatePresentation(false);
      setPresentationBuildFlow(FLOWS.ALL_STEPS);
    }
  };

  const onAddressContinue = addressCriteria => {
    const updated = updateCriteria(addressCriteria);

    if (isAddressToPresentationFlow) {
      return buildReportFromBasicInfo(updated);
    }

    const params = buildCreationParams();

    history.push(
      `${URLS.SEARCH.DETAILS}${!params?.toString()?.length ? '' : '?' + params?.toString()}`,
    );
  };

  const onDetailsContinue = async (detailsCriteria, shouldBeSaved) => {
    try {
      if (partialData.propertiesLimitHit) {
        toast('Comparable property count exceeds 400. Please reduce and try again.', {
          type: 'error',
          position: 'bottom-center',
        });
        setLoading(false);
        return;
      }

      const combinedCriteria = updateCriteria(detailsCriteria);
      const api = criteriaToApiMapper(combinedCriteria);
      const exclusions = getExclusions(rebuildData);
      const exclusionsFromMap = combinedCriteria.exclusions || [];
      const allExclusions = Array.from(
        new Set([...exclusions.excluded_ids, ...exclusionsFromMap, ...excludedIdsInResult]),
      );
      const reportDetailed = await ReportDetailedCommunicator.build({
        ...api,
        excluded_ids: allExclusions,
        presentation_type: type,
        presentation_id: !presentationId ? undefined : Number(presentationId),
        rebuild: isRebuild || undefined,
      });
      setSearchParam('presentation', reportDetailed.presentationHash);

      batch(() => {
        setApi({ ...api, excluded_ids: allExclusions });
        setReportDetailed(reportDetailed);
        setDefaultResultState(reportDetailed);
      });

      const update = shouldBeSaved ? updatePresentation : updatePresentationWithoutSaving;

      if (isRebuild) {
        setResultRebuildState(reportDetailed, async () => {
          const presentationPayload = await getCreatePresentationPayload();

          await update(
            { ...presentationPayload, id: reportDetailed.presentationId },
            {
              onSuccess: async () => {
                if (client) {
                  await PresentationApi.updateClient(reportDetailed.presentationId, client);
                }

                await PresentationApi.triggerPdfGeneration(reportDetailed.presentationId);

                setCreatedPresentationHash(reportDetailed.presentationHash);
                setCreatePresentation(true);
              },
            },
          );
        });
        return;
      }

      const presentationPayload = await getCreatePresentationPayload();
      await update({ ...presentationPayload, id: reportDetailed.presentationId });

      await PresentationApi.triggerPdfGeneration(reportDetailed.presentationId);

      setCreatedPresentationHash(reportDetailed.presentationHash);

      setCreatePresentation(true);
    } catch (e) {
      console.error(e);
      toast(
        'We had trouble building report. Please try again. If the issue persists, please contact our support team.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
      setLoading(false);
      setCreatePresentation(false);
      setPresentationBuildFlow(FLOWS.ALL_STEPS);
    }
  };

  const resetPartial = () => setPartialData(false);

  const partialReport = async detailsCriteria => {
    const combinedCriteria = {
      ...searchCriteria,
      ...detailsCriteria,
    };

    const isSwitchedToManual =
      combinedCriteria.reportType === 'manual' && !combinedCriteria.manualMlsIds?.length;
    const isSwitchedToGeoArea =
      combinedCriteria.areaType === AreaType.geographical &&
      !combinedCriteria.mapCoordinates?.length;
    const isSwitchedToMlsArea =
      combinedCriteria.areaType === AreaType.mls && !combinedCriteria.areaDetails;

    const isSwitchedToPolygon =
      combinedCriteria.mapMode === 'polygon' && !combinedCriteria.mapCoordinates;

    if (isSwitchedToManual || isSwitchedToGeoArea || isSwitchedToMlsArea || isSwitchedToPolygon) {
      setPartialData(false);
      return;
    }

    setPartialLoading(true);
    setSearchCriteria(combinedCriteria);
    const api = criteriaToApiMapper(combinedCriteria);
    const exclusions = getExclusions(rebuildData);
    const exclusionsFromMap = combinedCriteria.exclusions || [];
    const allExclusions = Array.from(
      new Set([...exclusions.excluded_ids, ...exclusionsFromMap, ...excludedIdsInResult]),
    );

    if (isNaN(parseInt(api?.bed_from))) {
      return; //not yet mounted;
    }

    try {
      const partialReport = await ReportDetailedCommunicator.buildPartial({
        ...api,
        excluded_ids: allExclusions,
        partial: true,
      });
      setPartialData(partialReport);
      setReportDetailed({ subject: partialReport.subject });
      setApi({ ...api, excluded_ids: allExclusions });

      if (partialReport.propertiesLimitHit) {
        toast('Comparable property count exceeds 400. Please reduce and try again.', {
          type: 'error',
          position: 'bottom-center',
        });
      }
    } catch (e) {
      setPartialData(false);
      toast(
        'We had trouble building partial report. Please try again. If the issue persists, please contact our support team.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
    } finally {
      setPartialLoading(false);
    }
  };

  const onBackToAddress = () => {
    history.push(`${URLS.SEARCH.ADDRESS}?new=true&type=${type}`);
  };

  if (shouldRedirectToAddress()) {
    return null;
  }

  return (
    <>
      <div className={styles.propertySearch}>
        <StepsCard>
          <Switch>
            <Route path={URLS.SEARCH.ADDRESS}>
              <PropertyAddress
                onContinue={onAddressContinue}
                loading={loading}
                setLoading={setLoading}
              />
            </Route>

            <Route path={URLS.SEARCH.DETAILS}>
              <PropertyDetails
                onContinue={onDetailsContinue}
                partialLoading={partialLoading}
                partialData={partialData}
                partialReset={resetPartial}
                onPartial={partialReport}
                onBack={onBackToAddress}
                loading={loading}
                setLoading={setLoading}
              />
            </Route>

            <Redirect to={URLS.SEARCH.ADDRESS} />
          </Switch>
        </StepsCard>
      </div>
    </>
  );
};

const stateToProps = ({ report, search, result }) => ({
  rebuildData: report.rebuildData,
  searchCriteria: search.searchCriteria,
  client: search.clients,
  excludedIdsInResult: result?.excluded ?? [],
});

const dispatchToProps = dispatch => ({
  setReportDetailed: reportDetailed => dispatch(reportOperations.setReportDetailed(reportDetailed)),
  setSearchCriteria: searchCriteria => dispatch(operations.setSearchCriteria(searchCriteria)),
  setApi: api => dispatch(operations.setApi(api)),
});

export const PropertySearch = connect(stateToProps, dispatchToProps)(PropertySearchComponent);
