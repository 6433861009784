import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import * as data from '../../data';

import slideGraphics from './assets/slideGraphics.png';
import { useMemo } from 'react';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import pieGraphic from './assets/pieGraphic';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  position: relative;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  width: 420px;
  font-size: 34px;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
  z-index: 1;
  padding-left: 45px;
  margin-top: 25px;
`;

const SlideGraphicsImg = styled('img')`
  display: block;
  width: 100%;
  position: absolute;
  bottom: -61px;
`;

const PieGraphic = styled('img')`
  display: block;
  position: absolute;
  top: -6px;
  left: 50%;
  width: 1024px;
  transform: translateX(-50%);
`;

export const BuyersAgentCompensationTraditional = () => {
  const primary = useUiConfigColorsPrimary();

  const img = useMemo(() => svgStringToDataUrl(pieGraphic, primary), [primary]);

  const fontBody = useUiConfigFontsBody();

  const isCourierNew = useMemo(() => fontBody === 'Courier New', [fontBody]);

  const headingStyles = {
    fontSize: 20,
    color: '#101010',
    fontWeight: 800,
    lineHeight: '25px',
    marginBottom: 8,
  };

  const textStyles = {
    fontSize: 18,
    fontWeight: 500,
    color: '#101010',
    lineHeight: isCourierNew ? '19px' : '22.5px',
  };

  const containerStyles = {
    padding: 0,
    justifyContent: 'flex-start',
  };

  return (
    <Wrapper>
      <Heading>{data.title}</Heading>
      <SlideGraphicsImg src={slideGraphics} />
      <PieGraphic src={img} />
      <TraditionalSlideCircularCard
        heading={data.data[0].title}
        headingStyles={{ ...headingStyles, width: 186 }}
        textStyles={{ ...textStyles, width: 257 }}
        text={data.data[0].text}
        position={{ left: 396, top: 61 }}
        size={380}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data.data[1].title}
        headingStyles={{ ...headingStyles, width: 275 }}
        textStyles={{ ...textStyles, width: 328 }}
        text={data.data[1].text}
        position={{ left: 787, top: 61 }}
        size={401}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data.data[2].title}
        headingStyles={{ ...headingStyles, width: 286 }}
        textStyles={{ ...textStyles, width: 265 }}
        text={data.data[2].text}
        position={{ left: 396, top: 450 }}
        size={380}
        containerStyles={containerStyles}
      />
      <TraditionalSlideCircularCard
        heading={data.data[3].title}
        headingStyles={{ ...headingStyles, width: 302 }}
        textStyles={{ ...textStyles, width: 267 }}
        text={data.data[3].text}
        position={{ left: 782, top: 450 }}
        size={380}
        containerStyles={containerStyles}
      />
    </Wrapper>
  );
};
