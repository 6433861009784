import { TProperty } from '../../../../../../../../types';
import { TSortOption } from '../ListingFeedbackTable.component';

export class PropertiesSort {
  static sort(
    properties: TProperty[],
    sortOption: TSortOption | null,
    propertyRatings: Record<string, number>,
  ) {
    switch (sortOption) {
      case 'price_asc':
        return [...properties].sort((a, b) => {
          return a.price > b.price ? 1 : b.price > a.price ? -1 : 0;
        });
      case 'price_dsc':
        return [...properties].sort((a, b) => {
          return a.price < b.price ? 1 : b.price < a.price ? -1 : 0;
        });
      case 'address_asc':
        return [...properties].sort((a, b) => {
          return a.address.deliveryLine > b.address.deliveryLine
            ? 1
            : b.address.deliveryLine > a.address.deliveryLine
            ? -1
            : 0;
        });
      case 'address_dsc':
        return [...properties].sort((a, b) => {
          return a.address.deliveryLine < b.address.deliveryLine
            ? 1
            : b.address.deliveryLine < a.address.deliveryLine
            ? -1
            : 0;
        });
      case 'rating_asc':
        return [...properties].sort((a, b) => {
          return propertyRatings[a.id] > propertyRatings[b.id]
            ? 1
            : propertyRatings[b.id] > propertyRatings[a.id]
            ? -1
            : 0;
        });
      case 'rating_dsc':
        return [...properties].sort((a, b) => {
          return propertyRatings[a.id] < propertyRatings[b.id]
            ? 1
            : propertyRatings[b.id] < propertyRatings[a.id]
            ? -1
            : 0;
        });
      default:
        return properties;
    }
  }
}
