import React from 'react';

import { IconName } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleButton, ButtonsWrapper } from './controls.styles';
import { TToolbarSize } from '../TextEditor.component';

export type TInlineStyle = 'bold' | 'italic';

type TInlineOption = {
  style: TInlineStyle;
  icon: string;
};

interface InlineControlsProps {
  onChange: (inlineStyle: TInlineStyle, value: boolean) => void;
  currentState: { [key: string]: boolean };
  toolbarSize?: TToolbarSize;
}

export const InlineStyleControls: React.FC<InlineControlsProps> = ({
  onChange,
  currentState,
  toolbarSize,
}) => {
  const options: TInlineOption[] = [
    {
      style: 'bold',
      icon: 'bold',
    },
    {
      style: 'italic',
      icon: 'italic',
    },
  ];

  return (
    <ButtonsWrapper toolbarSize={toolbarSize}>
      {options.map(option => (
        <StyleButton
          key={option.style}
          isActive={currentState[option.style]}
          onClick={() => onChange(option.style, !currentState[option.style])}
          toolbarSize={toolbarSize}
        >
          <FontAwesomeIcon icon={['fas', option.icon as IconName]} />
        </StyleButton>
      ))}
    </ButtonsWrapper>
  );
};
