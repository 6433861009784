import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { TextDelta } from './TextDelta';
import { TextDeltaForm } from './TextDeltaForm';
import { TextDeltaPdf } from './TextDeltaPdf';

import thumbnail from './thumbnail.png';

const template = 'textLayoutDelta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Text Only',
  frame: true,
  data: {
    text: '',
    text2: '',
    text3: '',
    headline: '',
    headline2: '',
    headline3: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TextDeltaForm,
  web: TextDelta,
  pdf: TextDeltaPdf,
};

export type TTextLayoutDelta = typeof template;
export { config, mappings, thumbnail };
