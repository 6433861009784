import React from 'react';
import styled from 'styled-components';
import slideGraphics from './slideGraphics';
import ppl from './ppl.png';
import cat from './cat.png';
import bg from './bg.png';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-size: 100%;
  background-position: bottom;
  background-image: url('${bg}');
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 30px;
  font-size: 26px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const PPL = styled('img')({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  height: 580,
  filter: 'grayscale(1)',
});

const Cat = styled('img')({
  position: 'absolute',
  bottom: 2,
  height: 1040,
  left: '50.3%',
  transform: 'translateX(-50%)',
});

const Graphics = styled('img')({
  position: 'absolute',
  width: '94%',
  transform: 'translateX(3%)',
  bottom: 0,
});

const WhiteBg = styled('div')({
  height: 100,
  backgroundColor: '#fff',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
});

const WhereBuyersGetTheirInfoTraditionalPDF: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const image = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);
  const { isCanadaUser } = useUser();

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container>
        <WhiteBg />
        <Heading>WHERE BUYERS GET THEIR INFORMATION</Heading>
        <Graphics src={image} />
        <Cat src={cat} />
        <PPL src={ppl} />
        <TraditionalSlideCircularCard
          heading='Sphere'
          text='It’s natural to lean on the advice and experience from friends, family, and neighbors.'
          position={{ top: 430, left: 24 }}
          size={200}
          headingStyles={{ fontSize: 18, color: '#444' }}
        />
        <TraditionalSlideCircularCard
          heading='Web'
          text={
            isCanadaUser
              ? 'The first step the average buyer takes in their home buying process is to look online at properties for sale. '
              : 'The first step 44% of recent buyers took in the home buying process was to look online at properties for sale.'
          }
          position={{ top: 190, left: 130 }}
          size={220}
          headingStyles={{ fontSize: 18, color: '#444' }}
        />
        <TraditionalSlideCircularCard
          heading='Experts'
          text='Real Estate Agents, Lenders, Financial Planners, and other professionals can balance and weigh the market information in conjunction with each 
          buyer’s situation.'
          position={{ top: 170, right: 170 }}
          size={230}
          headingStyles={{ fontSize: 18, color: '#444' }}
        />
        <TraditionalSlideCircularCard
          heading='Experience'
          text='It is easy to form an opinion on what something might be worth based on firsthand knowledge or perceived value.'
          position={{ top: 362, right: 15 }}
          size={230}
          headingStyles={{ fontSize: 18, color: '#444' }}
        />
      </Container>
    </PdfFrame>
  );
};

export default WhereBuyersGetTheirInfoTraditionalPDF;
