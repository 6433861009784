import React, { useState } from 'react';
import { useSetAtom } from 'jotai';
import styled from 'styled-components';

import { useOutsideClick } from '../../../../../services/useOutsideClick';
import { useUiConfigTheme } from '../../../../../providers/providers/UiConfigThemeProvider';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { ThemeSelection } from '../../../../../components/ThemeSelection/ThemeSelection.component';
import { isPropertyModalOpenAtom } from '../../../../../features/report/state/isPropertyModalOpenAtom';

const ButtonWrapper = styled('div')({
  position: 'relative',
});

export const Themes: React.FC = () => {
  const { selectedSlide } = useSelectedSlide();
  const { theme, setTheme } = useUiConfigTheme();

  const setPropertyModalOpen = useSetAtom(isPropertyModalOpenAtom);

  const themeElRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<React.RefObject<HTMLDivElement> | null>(null);

  const onCloseDropdown = () => {
    setOpen(null);
  };

  useOutsideClick(open, onCloseDropdown);

  return (
    <ButtonWrapper ref={themeElRef}>
      <ThemeSelection
        onOpenDropdown={() => {
          setPropertyModalOpen(false);
          setOpen(themeElRef);
        }}
        onCloseDropdown={onCloseDropdown}
        isOpen={!!open && open.current === themeElRef?.current}
        selectedTheme={theme}
        onChooseTheme={theme => setTheme(theme)}
        slide={selectedSlide ?? undefined}
      />
    </ButtonWrapper>
  );
};
