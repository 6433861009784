import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { CATEGORIES } from '../../../../../constants/categories';
import { ImportanceOfOpeningWeekendLuxury } from './web/luxury/ImportanceOfOpeningWeekend';
import { ImportanceOfOpeningWeekendTraditional } from './web/traditional/ImportanceOfOpeningWeekendTraditional';
import ImportanceOfOpeningWeekendTraditionalPDF from './pdf/traditional/ImportanceOfOpeningWeekendTraditionalPDF';
import ImportanceOfOpeningWeekendLuxuryPDF from './pdf/luxury/ImportanceOfOpeningWeekendLuxuryPDF';
import { ImportanceOfOpeningWeekendModernPDF } from './pdf/modern/ImportanceOfOpeningWeekendModernPDF';
import { ImportanceOfOpeningWeekendModern } from './web/modern/ImportanceOfOpeningWeekendModern';

const id = 'importanceOfOpeningWeekend';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Importance of Opening Weekend',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: ImportanceOfOpeningWeekendTraditional,
    [CATEGORIES.LUXURY]: ImportanceOfOpeningWeekendLuxury,
    [CATEGORIES.MODERN]: ImportanceOfOpeningWeekendModern,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: ImportanceOfOpeningWeekendTraditionalPDF,
    [CATEGORIES.LUXURY]: ImportanceOfOpeningWeekendLuxuryPDF,
    [CATEGORIES.MODERN]: ImportanceOfOpeningWeekendModernPDF,
  },
};

export type TImportanceOfOpeningWeekend = typeof id;
export { config, mappings };
