import React from 'react';
import { useSlide } from '../../../providers/SlideProvider';
import PdfViewer from '../components/UploadFileModal/PdfViewer';
import { PdfViewerWrapper, Container } from './PdfPresentation.styles';

export const PdfPresentationPdf: React.FC = () => {
  const slide = useSlide();

  if (!slide) return null;

  const webPdf = slide.data?.webPdf;
  const pdf = slide.data?.pdf;

  return (
    <Container style={{ width: 1000, height: 1294 }}>
      <PdfViewerWrapper>
        <PdfViewer pdfUrl={pdf || webPdf} containerHeight={1294} />
      </PdfViewerWrapper>
    </Container>
  );
};
