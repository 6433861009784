import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserSettings } from '../../types';
import { corePresentApi } from '../../api/CorePresentApi';
import { useUrlSearchParam } from '../../hooks/useQueryParams';
import { StorageService } from '../../services/storageService';
import { usePresentationHashFromUrl } from '../../pages/Presentation/hooks/usePresentationHashFromUrl';
import { usePresentation } from '../../hooks/usePresentation';
import { StandaloneAppDomains } from '../../services/DevelopmentUtilities/StandaloneAppDomains';
import { useAtomValue } from 'jotai';
import { presentationHashAtom } from '../../state/presentationHash/presentationHashAtom';

const fetchUserSettings = async (agentId: string | null): Promise<UserSettings | undefined> => {
  return await corePresentApi.get(`/accountDetail/whitelabelSettings`, {}, { agentId });
};

export function useDomainWhitelabelSettings(forcedAgentId?: number): UseQueryResult<UserSettings> {
  const agentIdSearchParam = useUrlSearchParam('agentId');
  let agentId = forcedAgentId || StorageService.read('agentId') || agentIdSearchParam;

  const hash = useAtomValue(presentationHashAtom);

  const { data: presentation } = usePresentation(hash, { omitGlobalStoreSet: true });

  if (!StandaloneAppDomains.isStandaloneInstance() && !agentId && hash) {
    agentId = (presentation?.user as any)?.kvcore_user_id || 0;
  }

  return useQuery(['userSettings', agentId], () => fetchUserSettings(agentId), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: Boolean(agentId),
    retry: 1,
  });
}
