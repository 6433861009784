import React from 'react';
import { NumberFormatter } from '../../../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { TrapezoidContainer, TrapezoidShape, NumberInfo, Text } from './BuyerInterestGraph.styles';
interface BuyerInterestGraphProps {
  data: any;
  isWeb?: boolean;
}
export const BuyerInterestGraph: React.FC<BuyerInterestGraphProps> = ({ data, isWeb }) => {
  return (
    <TrapezoidContainer isWeb={isWeb}>
      <TrapezoidShape trapezoidColor='darkGrey' isWeb={isWeb}>
        <NumberInfo isWeb={isWeb}>{NumberFormatter.format(data?.top?.value) || 0}</NumberInfo>
        <Text isWeb={isWeb}>{data?.top?.label}</Text>
      </TrapezoidShape>
      <TrapezoidShape trapezoidColor='grey' isWeb={isWeb}>
        <NumberInfo isWeb={isWeb}>{NumberFormatter.format(data?.mid?.value) || 0}</NumberInfo>
        <Text isWeb={isWeb}>{data?.mid?.label}</Text>
      </TrapezoidShape>
      <TrapezoidShape trapezoidColor='lightGrey' isWeb={isWeb}>
        <NumberInfo isWeb={isWeb}>{NumberFormatter.format(data?.bottom?.value) || 0}</NumberInfo>
        <Text isWeb={isWeb}>{data?.bottom?.label}</Text>
      </TrapezoidShape>
    </TrapezoidContainer>
  );
};
