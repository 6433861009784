import React from 'react';

import { Content, Container } from './ClosingAndNextSteps.styles';
import { ClosingAndNextStepsAside } from '../../../components/common/ClosingAndNextStepsAside/ClosingAndNextStepsAside.component';
import { ClosingAndNextStepsContent } from './ClosingAndNextStepsContent/ClosingAndNextStepsContent.component';

export const ClosingAndNextSteps: React.FC = () => {
  return (
    <Container>
      <ClosingAndNextStepsAside />
      <Content>
        <ClosingAndNextStepsContent />
      </Content>
    </Container>
  );
};
