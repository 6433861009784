import * as alpha from './alpha';
import * as beta from './beta';
import * as gamma from './gamma';
import * as delta from './delta';
import * as epsilon from './epsilon';
import * as zeta from './zeta';

import { TDynamicSlideConfigs } from '../../types';
import { TDynamicSlideFourImagesTemplate } from '../../../../../types';

export const fourImagesTemplateConfigs: Record<
  TDynamicSlideFourImagesTemplate,
  TDynamicSlideConfigs
> = {
  fourImagesLayoutAlpha: alpha,
  fourImagesLayoutBeta: beta,
  fourImagesLayoutGamma: gamma,
  fourImagesLayoutDelta: delta,
  fourImagesLayoutEpsilon: epsilon,
  fourImagesLayoutZeta: zeta,
};
