import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../../../Common';
import { useUiConfigFontsBody } from '../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { H3 } from './CriteriaReviewBox';

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 35,
  alignItems: 'center',
  paddingLeft: 20,
  '&:nth-child(odd)': {
    backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '0.1'),
  },
}));

const Col = styled('div')({
  // '&:nth-of-type(1)': {
  //   flexBasis: '10%',
  // },
  // '&:nth-of-type(2)': {
  //   flexBasis: '10%',
  // },
  // '&:nth-of-type(3)': {
  //   flexBasis: '15%',
  // },
  // '&:nth-of-type(4)': {
  //   flexBasis: '25%',
  // },
  // '&:nth-of-type(5)': {
  //   flexBasis: '12.5%',
  // },
  // '&:nth-of-type(6)': {
  //   flexBasis: '27.5%',
  // },
  '&:nth-of-type(1)': {
    flexBasis: '20%',
  },
  '&:nth-of-type(2)': {
    flexBasis: '40%',
  },
  '&:nth-of-type(3)': {
    flexBasis: '40%',
  },
});

const TableEl = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: 5,
  overflow: 'hidden',
  border: '1px solid #ddd',
});

const HeadWrapper = styled(Row)({
  background: '#efefef',
  paddingLeft: 20,
});

interface T {
  light?: boolean;
}

const Text = styled('p')<T>(({ theme, light }) => ({
  color: light ? '#444' : '#333',
  textTransform: light ? 'initial' : 'uppercase',
  fontFamily: useUiConfigFontsBody(),
  fontSize: light ? 13 : 12,
}));

const Head: React.FC = () => (
  <HeadWrapper>
    <Col>
      <Text>Buyer id</Text>
    </Col>
    {/* <Col><Text>Rating</Text></Col> */}
    {/* <Col><Text>Phone</Text></Col> */}
    <Col>
      <Text>Email</Text>
    </Col>
    {/* <Col><Text>Location</Text></Col> */}
    <Col>
      <Text>Interests</Text>
    </Col>
  </HeadWrapper>
);

interface Props {
  items: {
    buyerId: string;
    rating: number;
    phone: string;
    email: string;
    location: string;
    interests: string;
  }[];
}

export const Table: React.FC<Props> = ({ items }) => {
  return !items.length ? null : (
    <TableEl>
      <H3 style={{ padding: '4px 20px' }}>I have interested buyers searching in this area</H3>
      <Head />
      {items.map((item, i) => (
        <Row key={i}>
          <Col>
            <Text light>{item.buyerId}</Text>
          </Col>
          {/* <Col><Text light>{item.rating}</Text></Col> */}
          {/* <Col><Text light>{item.phone}</Text></Col> */}
          <Col>
            <Text light>{item.email}</Text>
          </Col>
          {/* <Col><Text light>{item.location}</Text></Col> */}
          <Col>
            <Text light>{item.interests}</Text>
          </Col>
        </Row>
      ))}
    </TableEl>
  );
};
