import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

interface WithActive {
  isActive: boolean;
}

interface Props {
  // hasBorder: boolean;
  src: string;
}

interface WithAllCaps {
  isAllCaps: boolean;
}

interface WithDefaultConfig {
  isDefaultConfig?: boolean;
}

const getBackgroundColor = (isDefaultConfig: boolean, primaryColor: string) => {
  return isDefaultConfig ? '#444' : primaryColor;
};

export const Container = styled('div')<WithDefaultConfig>(({ theme, isDefaultConfig = false }) => ({
  backgroundColor: getBackgroundColor(isDefaultConfig, useUiConfigColorsPrimary()),
  padding: '25px 0 50px 0',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '33%',
  position: 'relative',
}));

export const ImagePreview = styled('div')<Props>(({ src }) => ({
  width: 250,
  height: 250,
  backgroundColor: '#fff',
  overflow: 'hidden',
  borderRadius: '50%',
  margin: '0 auto',
  position: 'relative',
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'top',
}));

export const UploadImageWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const GreetingTextWrapper = styled('div')(() => ({
  margin: '40px 0',
  padding: '0 15px',
  zIndex: 2,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background:
    'linear-gradient(163.23deg, rgba(0, 0, 0, 0.304) -3.3%, rgba(236, 236, 236, 0.204) 49.23%, rgba(0, 0, 0, 0.276) 103.99%)',
  backdropFilter: 'blur(10px)',
});

export const GreetingText = styled('p')(({ theme }) => ({
  fontSize: '25px',
  color: theme.colors.primary.contrastText,
  textAlign: 'center',
  marginBottom: '5px',
  '&:last-of-type': {
    marginBottom: '0',
  },
}));

export const SlidesNavigationList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const NavigationItem = styled('div')<WithActive & WithDefaultConfig>(
  ({ isActive, theme, isDefaultConfig = false }) => ({
    padding: '5% 10%',
    fontSize: 30,
    color: isActive ? theme.colors.gray[400] : theme.colors.primary.contrastText,
    backgroundColor: isActive ? theme.colors.primary.contrastText : 'transparent',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    letterSpacing: useUiConfigBodyLetterSpacing(),
  }),
);

export const Circle = styled('div')<WithActive & WithDefaultConfig>(
  ({ isActive, theme, isDefaultConfig = false }) => ({
    width: '28px',
    height: '28px',
    border: '2.5px solid',
    borderColor: isActive
      ? getBackgroundColor(isDefaultConfig, useUiConfigColorsPrimary())
      : theme.colors.primary.contrastText,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:after': {
      content: '""',
      display: isActive ? 'block' : 'none',
      width: '14px',
      height: '14px',
      backgroundColor: getBackgroundColor(isDefaultConfig, useUiConfigColorsPrimary()),
      borderRadius: '50%',
    },
  }),
);

export const NavigationTextWrapper = styled('div')(({ theme }) => ({
  fontFamily: useUiConfigFontsHeader(),
  width: '85%',
  textAlign: 'left',
  cursor: 'pointer',
}));

export const NavigationTextLight = styled('span')(() => ({
  fontWeight: 200,
  marginRight: '5px',
}));

export const NavigationTextBold = styled('span')({
  fontWeight: 500,
});
