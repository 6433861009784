import * as cover from '../slides/startingSlides/Cover';
import * as sectionContents from '../slides/sectionContents';
import * as cmaReport from '../slides/whatsMyHomeWorth/cmaReport';
import * as listingDetails from '../slides/buyerTour/listingDetails';
import * as listingFeedback from '../slides/buyerTour/listingFeedback';
import * as greetingVideo from '../slides/startingSlides/GreetingVideo';
import * as netProceeds from '../slides/closingAndNextSteps/netProceeds';
import * as marketOverview from '../slides/marketAnalysis/marketOverview';
import * as oneSheeter from '../slides/whyIAmTheRightFitSection/oneSheeter';
import * as closing from '../slides/closingAndNextSteps/closingAndNextSteps';
import * as valueOfAgent from '../slides/whyIAmTheRightFitSection/valueOfAnAgent';
import * as coreSocial from '../slides/marketingAndBuyerActivitySection/CoreSocial';
import * as buyerDemand from '../slides/marketingAndBuyerActivitySection/buyerDemand';
import * as realtorVSIBuyer from '../slides/whyIAmTheRightFitSection/realtorVSIBuyer';
import type { TCategory, TSlideId, TSlideTheme, TSlidePlatform } from '../../../types';
import * as homeBuyingProcess from '../slides/whyIAmTheRightFitSection/homeBuyingProcess';
import * as pricingMisconceptions from '../slides/whatsMyHomeWorth/PricingMisconceptions';
import * as propertyBoost from '../slides/marketingAndBuyerActivitySection/propertyBoost';
import * as buyerInterest from '../slides/marketingAndBuyerActivitySection/BuyerInterest';
import * as homeSellingProcess from '../slides/whyIAmTheRightFitSection/homeSellingProcess';
import * as aboutMe from '../slides/whyIAmTheRightFitSection/personalSlides/slides/AboutMe';
import * as myMarketingPlan from '../slides/marketingAndBuyerActivitySection/MyMarketingPlan';
import * as whereBuyersGetTheirInfo from '../slides/whatsMyHomeWorth/whereBuyersGetTheirInfo';
import * as comparativeMarketAnalysis from '../slides/whatsMyHomeWorth/comparativeMarketAnalysis';
import * as importanceOfOpeningWeekend from '../slides/marketAnalysis/importanceOfOpeningWeekend';
import * as whatFactorsInfluencePricing from '../slides/marketAnalysis/whatFactorsInfluencePricing';
import * as importanceOfStaging from '../slides/marketingAndBuyerActivitySection/ImportanceOfStaging';
import * as myTestimonials from '../slides/whyIAmTheRightFitSection/personalSlides/slides/MyTestimonials';
import * as whatBuyersWantFromTheirAgents from '../slides/whyIAmTheRightFitSection/whatBuyersWantFromTheirAgents';
import * as whyPricingCorrectlyIsImportant from '../slides/marketAnalysis/whyPricingCorrectlyIsImportant';
import * as whyIAmTheRightBuyersAgent from '../slides/whyIAmTheRightFitSection/whyIAmTheRightBuyersAgent';
import * as purposeOfBuyersAgent from '../slides/marketingAndBuyerActivitySection/purposeOfBuyersAgent';
import * as buyersAgentCompensation from '../slides/marketingAndBuyerActivitySection/buyersAgentCompensation';

type TSlideCategoryMappingC = {
  [TC in TCategory]?: any;
};
type TSlideCategoryMapping = TSlideCategoryMappingC & {
  shared?: any;
};

export type TSlideTypeMapping = {
  pdf?: TSlideCategoryMapping;
  web?: TSlideCategoryMapping;
};

class PredefinedSlideFactoryImp {
  private getSlidesMappings(): Record<TSlideId, TSlideTypeMapping> {
    return {
      greetingVideo: greetingVideo.mappings,
      coverSlide: cover.mappings,
      cmaReport: cmaReport.mappings,
      valueOfAgent: valueOfAgent.mappings,
      buyerInterest: buyerInterest.mappings,
      importanceOfStaging: importanceOfStaging.mappings,
      homeBuyingProcess: homeBuyingProcess.mappings,
      homeSellingProcess: homeSellingProcess.mappings,
      whatBuyersWantFromTheirAgents: whatBuyersWantFromTheirAgents.mappings,
      whatFactorsInfluencePricing: whatFactorsInfluencePricing.mappings,
      whereDoBuyersGetTheirInfo: whereBuyersGetTheirInfo.mappings,
      importanceOfOpeningWeekend: importanceOfOpeningWeekend.mappings,
      netProceeds: netProceeds.mappings,
      closingAndNextSteps: closing.mappings,
      aboutMe: aboutMe.mappings,
      testimonials: myTestimonials.mappings,
      pricingMisconceptions: pricingMisconceptions.mappings,
      realtorVSIBuyer: realtorVSIBuyer.mappings,
      myMarketingPlan: myMarketingPlan.mappings,
      propertyBoost: propertyBoost.mappings,
      whyPricingCorrectlyIsImportant: whyPricingCorrectlyIsImportant.mappings,
      comparativeMarketAnalysis: comparativeMarketAnalysis.mappings,
      sectionContents: sectionContents.mappings,
      buyerDemand: buyerDemand.mappings,
      marketOverview: marketOverview.mappings,
      listingDetails: listingDetails.mappings,
      listingFeedback: listingFeedback.mappings,
      coreSocial: coreSocial.mappings,
      oneSheeter: oneSheeter.mappings,
      purposeOfBuyersAgent: purposeOfBuyersAgent.mappings,
      buyersAgentCompensation: buyersAgentCompensation.mappings,
      whyIAmTheRightBuyersAgent: whyIAmTheRightBuyersAgent.mappings,
    };
  }

  create(theme: TSlideTheme, category: TCategory, platform: TSlidePlatform, slide?: TSlideId) {
    if (!slide) return null;

    const categoriesMapping = this.getSlidesMappings()?.[slide]?.[platform];

    if (categoriesMapping?.[category]?.[theme]) {
      return categoriesMapping?.[category]?.[theme];
    }

    if (categoriesMapping?.[category]?.shared) {
      return categoriesMapping?.[category]?.shared;
    }

    if (categoriesMapping?.[category]) {
      return categoriesMapping?.[category];
    }

    if (categoriesMapping?.shared) {
      return categoriesMapping?.shared;
    }

    return null;
  }
}

export const PredefinedSlideFactory = new PredefinedSlideFactoryImp();
