import React from 'react';
import { Container, Heading, Text, TopText } from './TraditionalSlideCircularCard.styles';

interface Props {
  heading: string;
  text?: string;
  subText?: string;
  topText?: string;
  size: number;
  headingStyles?: React.CSSProperties;
  textStyles?: React.CSSProperties;
  topTextStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
  subTextStyles?: React.CSSProperties;
  position: {
    top?: number | string;
    left?: number | string;
    bottom?: number | string;
    right?: number | string;
  };
}

export const TraditionalSlideCircularCard: React.FC<Props> = ({
  heading,
  text,
  subText,
  position,
  size,
  headingStyles,
  containerStyles,
  textStyles,
  subTextStyles,
  topText,
  topTextStyles,
}) => {
  return (
    <Container style={{ ...containerStyles, ...position, width: size, height: size }}>
      {topText && <TopText style={topTextStyles}>{topText}</TopText>}
      <Heading style={headingStyles}>{heading}</Heading>
      {text && <Text style={textStyles}>{text}</Text>}
      {subText && <Text style={subTextStyles}>{subText}</Text>}
    </Container>
  );
};
