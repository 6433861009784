import React from 'react';

import { TSlide } from '../../../../types';
import { withProviders } from '../../../../hoc/withProviders';
import type { TBuyerTourPresentation } from '../../../../types';
import { ClientProvider } from '../../../../providers/providers/ClientProvider';
import { SectionsProvider } from '../../../../providers/providers/SectionsProvider';
import { PropertiesProvider } from '../../../../providers/providers/PropertiesProvider';
import { OrderProvider, useOrder } from '../../../../providers/providers/OrderProvider';
import { SlidesProvider, useSlides } from '../../../../providers/providers/SlidesProvider';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { UiConfigProvider } from '../../../../providers/providers/UiConfigProvider';
import { CategoryProvider } from '../../../../providers/providers/CategoryProvider';
import { PresentationContent } from '../../../../pages/Presentation/PresentationContent';
import { SelectedSlideProvider } from '../../../../providers/providers/SelectedSlideProvider';
import { PresentationTypeProvider } from '../../../../providers/providers/PresentationTypeProvider';
import { ActiveSectionProvider } from '../../../../components/Slide/providers/ActiveSectionProvider';
import { useSetBuyerTourPresentationToProviders } from '../../hooks/useSetBuyerTourPresentationToProviders';
import { CreatedAtProvider } from '../../../../providers/providers/CreatedAtProvider';
interface Props {
  presentation: TBuyerTourPresentation;
}
export const BuyerTourPresentation: React.FC<Props> = withProviders<Props>(
  UiConfigProvider,
  PresentationTypeProvider,
  CategoryProvider,
  PropertiesProvider,
  ClientProvider,
  SlidesProvider,
  SectionsProvider,
  OrderProvider,
  ActiveSectionProvider,
  CreatedAtProvider,
)(function BuyerTourPresentation({ presentation }) {
  useSetBuyerTourPresentationToProviders(presentation as TBuyerTourPresentation);

  const { sectionsSlide, sections } = useOrder();

  const { slides: presentationSlides } = useSlides();

  const slides = sections.flatMap(sectionId => {
    return sectionsSlide[sectionId].map(slideId => presentationSlides?.[slideId] as TSlide);
  });

  return (
    <SelectedSlideProvider>
      <PresentationContent presentation={presentation} slides={slides} hideProgressTracker />
    </SelectedSlideProvider>
  );
});
