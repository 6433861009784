import styled from 'styled-components';
import { data, title } from '../../data';
import { Card } from '../../../../../components/Card/Card.component';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  rowGap: 20,
  columnGap: 20,
  padding: '4%',
  gridTemplateRows: '300px 300px',
});

export const WhyIAmTheRightBuyersAgentModern = () => {
  const cards = [{ title, primaryColorTitle: true }, ...data];

  return (
    <Grid>
      {cards.map((card, i) => (
        <Card
          key={i}
          {...card}
          cardStyles={{ padding: '5% 8%' }}
          titleStyles={{
            fontSize: '28px',
            width: '100%',
            letterSpacing: '0.84px',
            marginBottom: '20px',
            lineHeight: '33px',
            marginTop: '5px',
          }}
          textStyles={{ width: '97%', lineHeight: '23px' }}
        />
      ))}
    </Grid>
  );
};
