import React from 'react';
import { BoxContainer, IconWrapper, Img, Title, Text } from './Card.styles';

export interface PropertyBoostCardProps {
  title: string;
  text: string;
  image: string;
}

export const Card: React.FC<PropertyBoostCardProps> = ({ title, text, image }) => {
  return (
    <BoxContainer>
      <IconWrapper>
        <Img src={image} />
      </IconWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </BoxContainer>
  );
};
