import React from 'react';

import Logos from '../../../../components/Logos/Logos';
import { BrokerInfoContainer, BrokerInfoWrapper, HeaderContainer } from './ModernFrameAlpha.styles';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { CommonFrameProps } from '../../../../../../../../types';

const ModernFrameAlpha: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => (
  <PlacementAwareContainer infoPlacement={infoPlacement}>
    <HeaderContainer style={{ zIndex: 40 }}>
      <BrokerInfoContainer>
        <BrokerInfoWrapper>
          <BrokerInfo fontColor='#000' nameFontStyles={{ fontSize: 18, fontWeight: 700 }} />
        </BrokerInfoWrapper>
      </BrokerInfoContainer>
      {!hideProgressTracker && <ProgressTracker category={CATEGORIES.MODERN} />}
      <Logos />
    </HeaderContainer>
    <TocPortalContainer offset={-16} zIndex={20} />
    {children}
  </PlacementAwareContainer>
);

export default ModernFrameAlpha;
