import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { filteredPropertiesAtom } from '../../../state/filteredPropertiesAtom';
import { compViewModeAtom } from '../../../state/compViewModeAtom';
import { selectedPropertyAtom } from '../../../state/selectedPropertyAtom';
import { activePropertyTypeAtom } from '../../../state/activePropertyTypeAtom';
import { reportPropertiesAtom } from '../../../state/reportPropertiesAtom';
import styled from 'styled-components';
import { PropertiesList } from './PropertiesList';
import { PropertiesMap } from './PropertiesMap';
import { PropertiesMapLegend } from './PropertiesMapLegend';
import { Property } from '../../Property/Property';
import { isPresentationViewAtom } from '../../../state/isPresentationViewAtom';
import { useUiConfigFontsBody } from '../../../../../providers/providers/UiConfigFontProvider';

export const PropertiesOverview = () => {
  const [filteredProperties] = useAtom(filteredPropertiesAtom);
  const [viewMode] = useAtom(compViewModeAtom);
  const [selectedProperty, setSelectedProperty] = useAtom(selectedPropertyAtom);
  const [activePropertyType] = useAtom(activePropertyTypeAtom);
  const [properties] = useAtom(reportPropertiesAtom);

  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const bodyFont = useUiConfigFontsBody();

  const propertiesToShow = useMemo(
    () => filteredProperties || properties,
    [filteredProperties, properties],
  );

  useEffect(() => {
    if (!selectedProperty && viewMode === 'slider') {
      const property = propertiesToShow?.[0]?.property;
      setSelectedProperty(property || null);
    }
  }, [propertiesToShow, selectedProperty, setSelectedProperty, viewMode]);

  return (
    <Container style={{ fontFamily: isPresentationView ? bodyFont : 'Figtree' }}>
      <PropertiesList />

      <Content>
        {viewMode === 'all' && (
          <MapContainer>
            <PropertiesMap type='all' />
            <PropertiesMapLegend />
          </MapContainer>
        )}

        {viewMode === 'map' ? (
          <PropertiesMap type={selectedProperty ? selectedProperty.status : activePropertyType} />
        ) : (
          <Property property={selectedProperty} />
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 420px;
  display: flex;

  @media all and (max-width: 992px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  width: 60%;
  overflow-y: auto;
  padding-right: 10px;

  @media all and (max-width: 992px) {
    flex-direction: column;
    width: 100%;
  }
`;

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
