import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { AuthApi } from '../../api/AuthApi';
import { corePresentApi } from '../../api/CorePresentApi';
import { StorageService } from '../../services/storageService';
import { ROUTING_PREFIX } from '../../constants/routingPrefix';
import { useIsLoggedIn } from '../../features/auth/useIsLoggedIn';
import { REDIRECT_TO_PARAM } from '../../constants/redirectToParam';
import { GuestLayout } from '../../layouts/GuestLayout/GuestLayout';
import { CurrentUserService } from '../../services/CurrentUserService';
import { ANIMATION_DURATION, AnimationLoader } from './AnimationLoader';
import { useSetSearchParameter } from '../../hooks/useSetSearchParameter';
import { FEATURES, useFeatureFlags, useHasFeatureFlag } from '../../hooks/useFeatureFlags';
import { constructWhitelabelLoginUrl } from '../../features/whitelabel/constructWhitelabelLoginUrl';
import { useDomainWhitelabelSettings } from '../../features/whitelabel/useDomainWhitelabelSettings';
import { useAuthMe } from '../../hooks/useAuthMe';

const Container = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '15vh',
});

export const SsoPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const setSearchParam = useSetSearchParameter();

  const redirectTo = query.get(REDIRECT_TO_PARAM);

  const isAuthenticated = useIsLoggedIn();
  const { isLoading: isLoadingFF } = useFeatureFlags();
  const hasWhiteLabelFF = useHasFeatureFlag(FEATURES.BoldTrailWhiteLabel);

  const { data: me, isLoading: isLoadingMe } = useAuthMe(Boolean(isAuthenticated));
  const { data: wlSettings, isLoading: isLoadingWlSettings } = useDomainWhitelabelSettings(
    me?.kvcore_user_id,
  );

  async function logoutIfLoggedIn() {
    if (isAuthenticated) {
      await corePresentApi.post('/auth/logout');
      StorageService.remove('token');
      StorageService.remove('agentId');
      CurrentUserService.remove();
    }
  }

  async function exchangeAuthCodeForToken(query: string) {
    const ssoTimer = new Date();
    const response = await AuthApi.processSsoCallback('?' + query);
    const duration = new Date().getTime() - ssoTimer.getTime();

    return { token: response.token, duration, user: response.user };
  }

  function redirectToHomePage() {
    const baseUrl = window.location.origin + ROUTING_PREFIX;

    if (redirectTo) {
      window.location.href = baseUrl + redirectTo;
      return;
    }

    window.location.href = baseUrl;
  }

  const processSsoLink = async (query: string) => {
    try {
      await logoutIfLoggedIn();
      const response = await exchangeAuthCodeForToken(query);

      StorageService.save('token', response.token);
      StorageService.save('agentId', response.user.kvcore_user_id);
      setSearchParam('agentId', response.user.kvcore_user_id);

      setTimeout(() => {
        setSearchParam('isAuthOnSSO', 'true');
      }, ANIMATION_DURATION - response.duration);
    } catch (e: any) {
      setLoading(false);
      setError(true);
      setMessage('An error occurred. Please get in touch with us.');
    }
  };

  useEffect(() => {
    processSsoLink(query.toString());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = isLoadingMe || isLoadingWlSettings || isLoadingFF;
  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    if (hasWhiteLabelFF && me?.has_valid_website && me?.website && wlSettings?.whiteLabelSettings) {
      window.location.href = constructWhitelabelLoginUrl(me.website, me.kvcore_user_id);
      return;
    }

    redirectToHomePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.website, isAuthenticated, isLoading, wlSettings, hasWhiteLabelFF]);

  return (
    <GuestLayout hideHeader>
      <Container>
        {loading && <AnimationLoader />}
        {error && <p style={{ color: 'red' }}>{message}</p>}
      </Container>
    </GuestLayout>
  );
};
