import React from 'react';

import { Details } from '../../../../../../../../../../features/report/components/PropertyModal/PropertiesOverview/HomesGroup/HomePreview/Details';
import { Picture } from './Picture/Picture';
import { ItemContainer } from './ItemContainer/ItemContainer';
import { useViewMode } from '../../../../providers/ViewModeProvider';
import { PriceAndActions } from './PriceAndActions/PriceAndActions';
import { TProperty } from '../../../../../../../../../../types';
import { useSelectedProperty } from '../../../../providers/SelectedPropertyProvider';
import { trackElementClick } from '../../../../../../../../../../pages/PresentationCreate/dash/communicators/tracking';
import { TRACKING_EVENTS } from '../../../../../../../../../../services/TrackingEvent';

interface Props {
  property: TProperty;
  excluded: boolean;
  propertiesRef: React.RefObject<HTMLElement>;
}
export const HomePreview: React.FC<Props> = ({ property, excluded, propertiesRef }) => {
  const { setViewMode } = useViewMode();
  const { selectedProperty, setSelectedProperty } = useSelectedProperty();

  const isSelected = selectedProperty?.id === property?.id;

  const onClick = () => {
    setSelectedProperty(property);
    setViewMode('property');
    trackElementClick(property?.address?.deliveryLine, TRACKING_EVENTS.ELEMENT_CLICKED);
  };

  const containerStyle = React.useMemo(
    () => ({
      display: 'flex',
      alignItems: 'stretch',
    }),
    [],
  );

  return (
    <ItemContainer
      isActive={isSelected}
      onClick={onClick}
      propertiesRef={propertiesRef}
      inactive={excluded}
      style={containerStyle}
    >
      <Picture property={property} />
      <Details property={property} />
      <PriceAndActions property={property} />
    </ItemContainer>
  );
};
