import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { CATEGORIES } from '../../../../../constants/categories';
import { ModernRealtorVSIBuyer, TraditionalRealtorVSIBuyer } from './web';
import { ModernRealtorVSIBuyerPdf, TraditionalRealtorVSIBuyerPdf } from './pdf';

const id = 'realtorVSIBuyer';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Realtor VS iBuyer',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    [CATEGORIES.TRADITIONAL]: TraditionalRealtorVSIBuyerPdf,
    [CATEGORIES.MODERN]: ModernRealtorVSIBuyerPdf,
  },
  web: {
    [CATEGORIES.TRADITIONAL]: TraditionalRealtorVSIBuyer,
    [CATEGORIES.MODERN]: ModernRealtorVSIBuyer,
    shared: TraditionalRealtorVSIBuyer,
  },
};

export type TRealtorVSIBuyer = typeof id;
export { config, mappings };
