import React from 'react';
import { IconName } from '../../../../components/Common/Icon/Icon.types';
import {
  Container,
  TitleWrapper,
  Title,
  Description,
  DescriptionWrapper,
} from './LibraryHeaderSection.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTheme } from 'styled-components';
import { Button } from '../../../../components/Common/V2/Button/Button';

interface LibraryHeaderSectionProps {
  iconName: IconProp;
  title: string;
  text: string;
  button?: { text: string; iconName: IconProp; iconFillColor?: string };
  onButtonClick?: () => void;
  isLight?: boolean;
}

export const LibraryHeaderSection: React.FC<LibraryHeaderSectionProps> = ({
  iconName,
  title,
  text,
  button,
  onButtonClick,
  isLight,
}) => {
  const { colors } = useTheme();

  return (
    <Container>
      <TitleWrapper>
        <FontAwesomeIcon
          icon={iconName}
          fontSize={30}
          color={isLight ? colors.v2.background : colors.v2.gray[400]}
        />
        <Title isLight={isLight}>{title}</Title>
      </TitleWrapper>
      <DescriptionWrapper>
        <Description isLight={isLight}>{text}</Description>
        {button && (
          <Button
            variant='secondary'
            prefixIcon={<FontAwesomeIcon icon={button.iconName} />}
            onClick={onButtonClick}
          >
            {button.text}
          </Button>
        )}
      </DescriptionWrapper>
    </Container>
  );
};
