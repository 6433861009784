import { PastPresentationItem } from './types';

const item1: PastPresentationItem = {
  type: 'seller',
  imgUrl: '',
  id: '1',
  address: '7665 North Cruzer Heights',
  clientId: 'No client',
  lastUpdated: new Date(),
  versions: 3,
  createdAt: new Date(),
  buildInProgress: false,
  pdf: '',
  hash: 'abcd',
  clientName: '',
  clientEmail: '',
  sessionsCount: 0,
  canBeRebuilt: false,
  topFeedback: '',
  topSellerBarrier: '',
  feedbackMoreInfo: '',
};

export const row1 = [item1];
