import { useState } from 'react';

import { Sidebar } from '../components/Sidebar';
import { FeaturesSidebarContent } from './FeaturesSidebarContent';
import { Button } from '../components/Button';

export const Features = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOffMarket, setIsOffMarket] = useState(false);

  return (
    <>
      <Button
        label='Features'
        iconName='house_flag'
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
      />

      <Sidebar isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <FeaturesSidebarContent isOffMarket={isOffMarket} setIsOffMarket={setIsOffMarket} />
      </Sidebar>
    </>
  );
};
