import React from 'react';
import { usePresentationType } from '../../../../../../../providers/providers/PresentationTypeProvider';
import { LuxuryCoverBT } from './LuxuryCoverBT';
import { LuxuryCoverRegular } from './LuxuryCoverRegular';

export const LuxuryCover: React.FC = () => {
  const { presentationType } = usePresentationType();
  if (presentationType === 'buyerTour') return <LuxuryCoverBT />;
  return <LuxuryCoverRegular />;
};
