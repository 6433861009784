import React, { ReactElement } from 'react';
import { Report } from '../../../../../../pages/PresentationCreate/dash';
import { CATEGORIES } from '../../../../../../constants/categories';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { usePropertiesTablesPdfPages } from '../../../../../../features/report/properties-table-pdf/usePropertiesTablePdfPages';

export const usePropertiesListPdfPages = () => {
  const { category } = useCategory();
  const pages = usePropertiesTablesPdfPages();

  return (
    pages?.map((page, i) => (
      <PdfFrame key={i} category={category ?? CATEGORIES.TRADITIONAL}>
        {page}
      </PdfFrame>
    )) || []
  );
};
