import React, { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

type TooltipProps = {
  text: string;
  children: React.ReactNode;
  tooltipContentStyle?: CSSProperties;
};

export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TooltipChildrenWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const TooltipText = styled.span`
  font-size: 12px;
  color: #fff;
  background-color: #555;
  padding: 5px 10px;
  border-radius: 6px;

  position: absolute;
  bottom: 25px;
  z-index: 1;
  transform: translateX(-90%);
  width: 320px;
`;

const Tooltip: React.FC<TooltipProps> = ({ text, children, tooltipContentStyle }) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <TooltipContainer>
      <TooltipChildrenWrapper
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {children}
      </TooltipChildrenWrapper>
      {isHovered && text && <TooltipText style={tooltipContentStyle}>{text}</TooltipText>}
    </TooltipContainer>
  );
};

export default Tooltip;
