import styled from 'styled-components';
import { Textarea } from '../../../../../../../../../../components/Common/V2/Textarea';
import { Input } from '../../../../../../../../../../components/Common/V2/Input';

export { Textarea, Input };

type WithHidden = {
  isHidden?: boolean;
};

export const TestimonialEditWrapper = styled('div')(({ theme }) => ({
  paddingBottom: 20,
  borderBottom: `1px solid ${theme.colors.v2.gray[200]}`,
  ':last-child': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
}));

export const TestimonialEditInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 9,
  alignItems: 'center',
});

export const TestimonialEditTitle = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.colors.v2.gray[400],
}));

export const TestimonialEditNote = styled('p')(({ theme }) => ({
  fontSize: 10,
  color: theme.colors.v2.gray[400],
  marginRight: 10,
}));

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  // flexDirection: 'column',
});

export const ToggleButton = styled('div')<WithHidden>(({ theme, isHidden }) => ({
  display: 'inline-block',
  backgroundColor: theme.colors.primary.main,
  color: '#fff',
  fontSize: 14,
  borderRadius: 3,
  padding: 10,
  cursor: 'pointer',
  maxHeight: 35,
  fontWeight: 700,
}));
