import React from 'react';

import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';

export const TextAlphaForm: React.FC = () => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  const onChangeText = (value: string) => {
    setSlideDataProperty('text', value);
  };

  if (!selectedSlide) return null;

  return (
    <SlideTextBlock
      isEditable
      text={selectedSlide.data?.text}
      headline={selectedSlide.data?.headline}
      onChangeText={onChangeText}
      onChangeHeadline={onChangeHeadline}
      containerStyles={{ padding: '2%' }}
    />
  );
};
