import React from 'react';

import { CATEGORIES } from '../../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { SlideWithGraphWrapperPdf } from '../../../../../components/common/SlideWithGraphWrapperPdf';
import { PricingRateOfChangeChart } from '../../../../../../../../features/report/charts/pricing-rate-of-change/PricingRateOfChangeChart';

import { pricingRateOfChangeData } from '../data';

const PricingRateOfChangePdf: React.FC = () => {
  const { category } = useCategory();
  const { heading, value, subtitle, description } = pricingRateOfChangeData;

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SlideWithGraphWrapperPdf
        heading={heading}
        title={value}
        subtitle={subtitle}
        description={description}
      >
        <PricingRateOfChangeChart />
      </SlideWithGraphWrapperPdf>
    </PdfFrame>
  );
};

export default PricingRateOfChangePdf;
