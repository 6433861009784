import { Helmet as ReactHelmet } from 'react-helmet';
import { FEATURES, useHasFeatureFlag } from '../hooks/useFeatureFlags';
import { useIsBoldTrailDomain } from '../hooks/useIsBoldTrailDomain';
import { useWhiteLabelSettings } from '../features/whitelabel/useWhiteLabelSettings';

export const Helmet = () => {
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const isBoldTrailDomain = useIsBoldTrailDomain();
  const { hasWL, favicon } = useWhiteLabelSettings();

  if (hasWL) {
    return (
      <ReactHelmet>
        <link rel='icon' href={favicon} />
        <link rel='apple-touch-icon' href='/logo192.png' />
        <meta name='description' content='Present' />
        <title>Present</title>
      </ReactHelmet>
    );
  }

  if (hasBtTheme || isBoldTrailDomain) {
    return (
      <ReactHelmet>
        <link rel='icon' href='/boldTrail/favicon.ico' />
        <link rel='apple-touch-icon' href='/boldTrail/logo-bt.png' />
        <meta name='description' content='Present' />
        <title>Present</title>
      </ReactHelmet>
    );
  }

  return (
    <ReactHelmet>
      <link rel='icon' href='/favicon.ico' />
      <link rel='apple-touch-icon' href='/logo192.png' />
      <meta name='description' content='CORE Present' />
      <title>CORE Present</title>
    </ReactHelmet>
  );
};
