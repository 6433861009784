class ReportUpdateObservableImp {
  subscribers = {};

  subscribe(key, func) {
    this.subscribers[key] = func;
  }

  unsubscribe(key) {
    delete this.subscribers[key];
  }

  onUpdate(report) {
    Object.values(this.subscribers).forEach(func => {
      if (typeof func === 'function') {
        func(report);
      }
    });
  }
}

export const ReportUpdateObservable = new ReportUpdateObservableImp();
