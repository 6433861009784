import { TFrameConfig } from '../../../../../../../types';
import { THEMES } from '../../../../../../../constants/themes';
import TraditionalFrameBeta, { TTraditionalFrameBeta } from './TraditionalFrameBeta';
import TraditionalFrameGama, { TTraditionalFrameGama } from './TraditionalFrameGama';
import TraditionalFrameAlpha, { TTraditionalFrameAlpha } from './TraditionalFrameAlpha';

export type TSlideThemeTraditional =
  | TTraditionalFrameAlpha
  | TTraditionalFrameBeta
  | TTraditionalFrameGama;

const traditionConfig: Record<TSlideThemeTraditional, TFrameConfig> = {
  [THEMES.TRADITIONAL.ALPHA]: TraditionalFrameAlpha,
  [THEMES.TRADITIONAL.BETA]: TraditionalFrameBeta,
  [THEMES.TRADITIONAL.GAMA]: TraditionalFrameGama,
};

export default traditionConfig;
