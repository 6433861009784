import styled from 'styled-components';
import { Typography } from '../../Typography/Typography.component';

export { Typography };

export const Container = styled('nav')(({ theme }) => ({
  height: '100%',
  width: 'max-content',
  marginRight: 'auto',
  display: 'flex',
}));

interface WithActive {
  isactive?: number;
}

export const NavItemContainer = styled('div')<WithActive>(({ theme, isactive }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: 6,
  borderBottom: `5px solid ${isactive ? theme.colors.v2.primary : 'transparent'}`,
  pointerEvents: 'none',
  width: 'max-content',
  flexShrink: 0,
  textDecoration: 'none',
  marginRight: 40,
  '&:last-of-type': {
    marginRight: 0,
  },
}));
