import styled, { useTheme } from 'styled-components';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { GradientSvg } from './GradientSvg';
import { useHasFeatureFlag, FEATURES } from '../../../hooks/useFeatureFlags';

type Props = {
  value: number;
};

export function CircularProgress({ value }: Props) {
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const theme = useTheme();

  const gradientId = 'gradient';

  return (
    <Container>
      <GradientSvg idCSS={gradientId} />
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={12}
        styles={{
          root: {
            height: '36px',
            width: '36px',
            rotate: '-60deg',
          },
          path: {
            stroke: hasBtTheme ? `url(#${gradientId})` : theme.colors.v2.accent,
            strokeLinecap: 'round',
            transitionDuration: '0.5',
          },
          trail: {
            stroke: theme.colors.v2.gray[100],
          },
        }}
      >
        <Text>{`${value}%`}</Text>
      </CircularProgressbarWithChildren>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
`;

const Text = styled.p`
  font-size: 10px;
  font-weight: 700;
  margin-top: -13px;
  font-style: normal;
  text-align: center;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
