import { CSSProperties, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '../../../Common';
import { SectionTitle } from './SectionTitle';

interface Props {
  title?: string;
  containerStyle?: CSSProperties;
  children: ReactNode;
  isOpen: boolean;
  setOpen: () => void;
}

export const SectionDropdown = ({ title, containerStyle, children, isOpen, setOpen }: Props) => {
  return (
    <Container style={containerStyle}>
      <Wrapper onClick={setOpen}>
        <SectionTitle>{title}</SectionTitle>
        <Icon iconName='caret_up' style={{ rotate: isOpen ? '0deg' : '180deg' }} />
      </Wrapper>
      <Dropdown isOpen={isOpen}>{children}</Dropdown>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 15px;
  border-radius: 3px;
  border: 1px solid #ddd;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Dropdown = styled.div<{ isOpen?: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;
