export const pricePerSquareFootData = {
  heading: 'Price Per Square FOot ($/SQFT)',
  summary: ['$415 - Average price per square foot of all comparable properties'],
  value: '$415',
  subtitle: 'PRICE PER SQUARE FOOT',
  description: [
    {
      title: 'WHAT IS PRICE PER SQUARE FOOT?',
      text: 'The average price per square foot is calculated by dividing the list/sold price by the property’s living area size.',
    },
    {
      title: 'How is price per square foot used in a price analysis?',
      text: 'On its own, the price per square foot is not a very insightful metric. However, when compared to other similar-sized properties in the area, the price per square foot metric can be beneficial for illustrating norms and outliers.',
    },
  ],
};
