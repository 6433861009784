import React from 'react';
import PropTypes from 'prop-types';

import { Range } from '../../../../../../../../components/Range/Range';

import classes from './SliderOption.module.scss';

const SliderOption = ({
  description = '',
  options,
  initValue,
  changed = f => f,
  specialMargin,
  className = '',
}) => (
  <div
    className={`${classes.sliderOption} ${specialMargin ? classes.specialMargin : ''} ${className}`}
    data-test-card-content={true}
  >
    {description && <h3>{description}</h3>}
    <Range allowCross={false} changed={changed} marks={options} initValue={initValue} />
  </div>
);

SliderOption.propTypes = {
  description: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
};

export { SliderOption };
