import styled from 'styled-components';
import { Input } from './components/Input';
import { MoneyInput } from './components/MoneyInput';

interface Props {
  price?: number | null;
  onChange: (price?: number | null) => void;
  isActivated: boolean;
}

export const SinglePrice = ({ price, onChange, isActivated = false }: Props) => (
  <Wrapper disabled={isActivated}>
    <MoneyInput
      placeholder='Enter Your Own Price'
      value={price || ''}
      onChange={onChange}
      disabled={isActivated}
    />
  </Wrapper>
);

const Wrapper = styled.div<{ disabled: boolean }>`
  flex-grow: 1;
  width: 25%;

  @media all and (max-width: 814px) {
    width: 100%;
  }
`;
