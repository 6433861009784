import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useGoogleMap } from '@react-google-maps/api';

interface Props {
  position: any;
}

export const CustomDrawingManagerControl: React.FC<Props> = ({
  position = (window as any).google.maps.ControlPosition.TOP_LEFT,
  children,
}) => {
  const map = useGoogleMap();
  const controlDiv = document.createElement('div');

  useEffect(() => {
    if (map) {
      const controls = map.controls[position];
      const index = controls.getLength();
      controls.push(controlDiv);
      return () => {
        controls.removeAt(index);
      };
    }
  }, [map, position, controlDiv]);

  return createPortal(children, controlDiv);
};
