import styled, { CSSObject } from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';

type TrapezoidColor = 'darkGrey' | 'grey' | 'lightGrey' | 'green';

type WithTrapezoidColor = {
  trapezoidColor: TrapezoidColor;
};

type WithWeb = {
  isWeb?: boolean;
};

const getTrapezoidContainerStyle = (trapezoidColor: TrapezoidColor): CSSObject => {
  switch (trapezoidColor) {
    case 'darkGrey':
      return {
        width: '100%',
        zIndex: 3,
      };
    case 'grey':
      return {
        width: '82%',
        zIndex: 2,
      };
    case 'lightGrey':
      return {
        width: '64%',
      };
    default:
      return {};
  }
};

const getTrapezoidStyle = (trapezoidColor: TrapezoidColor, primary: string): CSSObject => {
  switch (trapezoidColor) {
    case 'darkGrey':
      return {
        backgroundColor: '#666666',
        clipPath: 'polygon(0 0, 100% 0, 93% 100%, 7% 100%)',
      };
    case 'grey':
      return {
        backgroundColor: '#888888',
        clipPath: 'polygon(0 0, 100% 0, 91.5% 100%, 8.5% 100%)',
      };
    case 'lightGrey':
      return {
        backgroundColor: '#AAAAAA',
        clipPath: 'polygon(0 0, 100% 0, 89% 100%, 11% 100%)',
      };
    default:
      return {};
  }
};

const getTopOfTrapezoidStyle = (trapezoidColor: TrapezoidColor): CSSObject => {
  switch (trapezoidColor) {
    case 'darkGrey':
      return {
        clipPath: 'polygon(5% 0, 95% 0, 100% 100%, 0 100%)',
      };
    case 'grey':
      return {
        clipPath: 'polygon(5.5% 0, 94.5% 0, 100% 100%, 0 100%)',
      };
    case 'lightGrey':
      return {
        clipPath: 'polygon(7% 0, 93% 0, 100% 100%, 0 100%)',
      };
    default:
      return {};
  }
};

export const TrapezoidContainer = styled('div')<WithWeb>(({ isWeb }) => ({
  width: isWeb ? '85%' : '90%',
  margin: '62px auto 0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const TrapezoidShape = styled('div')<WithTrapezoidColor & WithWeb>(
  ({ trapezoidColor, isWeb }) => {
    return {
      height: 85,
      marginBottom: 22,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      ...getTrapezoidContainerStyle(trapezoidColor),
      '&:after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        ...getTrapezoidStyle(trapezoidColor, useUiConfigColorsPrimary()),
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: 24,
        bottom: '100%',
        left: 0,
        backgroundColor: '#F1F1F1',
        ...getTopOfTrapezoidStyle(trapezoidColor),
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    };
  },
);

export const TrapezoidText = styled('h5')({
  position: 'relative',
  zIndex: 1,
  color: '#fff',
});

export const NumberInfo = styled(TrapezoidText)<WithWeb>(({ isWeb }) => ({
  fontSize: isWeb ? 30 : 26,
  fontWeight: 500,
}));

export const Text = styled(TrapezoidText)<WithWeb>(({ isWeb }) => ({
  fontSize: isWeb ? 17 : 15,
  fontWeight: 400,
  width: '80%',
  textAlign: 'center',
  marginLeft: 0,
}));
