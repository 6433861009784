import { useLocation } from 'react-router-dom';
import { AuthApi } from '../../api/AuthApi';
import { Routes } from '../../pages/Router/Routes';
import { useUrlSearchParam } from '../../hooks/useQueryParams';

export function useIsLoggedIn() {
  const tokenInUrl = useUrlSearchParam('token');
  const isAuthOnSSO = useUrlSearchParam('isAuthOnSSO');

  const loacation = useLocation();
  const isSsoPage = loacation.pathname.endsWith('/')
    ? loacation.pathname === Routes.sso + '/'
    : loacation.pathname === Routes.sso;

  if (!isSsoPage) {
    return AuthApi.isLoggedIn() && !tokenInUrl;
  }

  return AuthApi.isLoggedIn() && Boolean(isAuthOnSSO);
}
