import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, ICONS } from './Icon/Icon';

import classes from './Editable.module.scss';

const NO_VALUE = 'n/a';
const INFO_REQUIRED = 'INFO_REQUIRED';

export const Editable = ({
  label,
  value,
  defaultValue,
  editComponent,
  enterPressed,
  onConfirm,
  onUndo,
  className,
}) => {
  let [editing, setEditing] = useState(false);
  editing = enterPressed === true ? false : editing;

  const isValueChanged =
    value &&
    JSON.parse(JSON.stringify(value)) !== JSON.parse(JSON.stringify(defaultValue)) &&
    defaultValue !== INFO_REQUIRED;

  const applyChanges = () => {
    onConfirm();
    setEditing(false);
  };

  if (!editing) {
    return (
      <div className={classes.container}>
        <span className={`${classes.editable} ${className}`}>
          <span className={classes.value}>{label || NO_VALUE}</span>
        </span>
        {isValueChanged ? (
          <Icon
            icon={ICONS.UNDO}
            onClick={() => {
              setEditing(false);
              onUndo(defaultValue);
            }}
            className={classes.icon}
          />
        ) : (
          <Icon icon={ICONS.EDIT} onClick={() => setEditing(true)} className={classes.icon} />
        )}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {editComponent}
      <Icon icon={ICONS.CHECK} onClick={applyChanges} className={classes.icon} />
    </div>
  );
};

Editable.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  editComponent: PropTypes.element,
  onConfirm: PropTypes.func,
  onUndo: PropTypes.func,
  highlight: PropTypes.bool,
  className: PropTypes.string,
};

Editable.defaultProps = {
  label: '',
  value: null,
  defaultValue: null,
  editComponent: null,
  highlight: false,
  className: '',
};
