import styled from 'styled-components';

export const SlideSettingsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Title = styled('div')(({ theme }) => ({
  fontSize: '10px',
  textTransform: 'uppercase',
  color: theme.colors.v2.text.regular,
  marginRight: '7px',
  fontWeight: 700,
}));

export const ButtonWrapper = styled('div')({ position: 'relative', marginLeft: 8 });

export const ButtonTextBold = styled('span')({
  fontWeight: 500,
});
