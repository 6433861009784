import React, { useMemo, useState } from 'react';
import { FitContentModal } from '../../../../../components/Common';
import { useQueryClient } from '@tanstack/react-query';

import { config as netProceedsConfig } from '../../../../../components/Slide/slides/closingAndNextSteps/netProceeds';
import { PresentationApi } from '../../../../Presentation/api/PresentationApi';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../../providers/providers/SectionsProvider';
import PreviewSlide from '../../../../../components/PreviewSlide/PreviewSlide.component';
import { AddNetSheetInputsTable } from './AddNetSheetInputsTable.component';
import { Container } from './AddNetSheet.styles';
import {
  TNetProceedsData,
  TPresentation,
  TPresentationMode,
  TPresentationModeConfig,
  TSlide,
  TSlideId,
} from '../../../../../types';
import { usePresentationMode } from '../../../../../providers/providers/PresentationModeProvider';
import { hasNetProceedsSlide } from '../../../../PresentationEdit/PresentationCreationContent/services/hasNetProceedsSlide';

export type TInputTarget = {
  value: string;
  order: string;
  sectionName?: string;
  name: string;
};

interface AddNetSheetProps {
  isOpen: boolean;
  onClose: () => void;
  netSheetData?: TNetProceedsData[];
  presentation?: TPresentation;
}

const netProceed = {
  listPrice: {
    price: 0,
  },
  mortgagePayoffs: {
    first: 0,
  },
  realEstateFees: {
    listingOffice: 0,
    sellingOffice: 0,
  },
  settlementCosts: {
    escrowFee: 0,
    titleInsurance: 0,
    proratedPropertyTax: 0,
  },
  transferExciseTax: {
    stateTax: 0,
  },
  otherCosts: {
    assessments: 0,
    HOAFees: 0,
    administrativeFee: 0,
    homeWarrantyPolicy: 0,
    inspections: 0,
    closingCosts: 0,
  },
};

export const AddNetSheet: React.FC<AddNetSheetProps> = ({
  isOpen,
  onClose,
  netSheetData = [],
  presentation,
}) => {
  const { slides } = useSlides();
  const { getSectionsForMode } = useSections();
  const { sections, sectionsSlide, setSectionsSlideOrder, getOrderForMode } = useOrder();
  const [loading, setLoading] = useState(false);

  const { presentationMode } = usePresentationMode();
  const hasNS = hasNetProceedsSlide(presentationMode, { sections, sectionsSlide });

  const [netProceed1, setNetProceed1] = useState(
    hasNS && netSheetData[0] ? netSheetData[0] : netProceed,
  );
  const [netProceed2, setNetProceed2] = useState(
    hasNS && netSheetData[1] ? netSheetData[1] : netProceed,
  );
  const [netProceed3, setNetProceed3] = useState(
    hasNS && netSheetData[2] ? netSheetData[2] : netProceed,
  );

  const [isSlidePreview, setIsSlidePreview] = useState(false);

  const queryClient = useQueryClient();

  const netProceedsSlide = netProceedsConfig;

  const onInputChange = (target: TInputTarget) => {
    const sectionName = target.sectionName as keyof TNetProceedsData;
    const name = target.name;
    const order = target.order;

    const value = target.value === '' ? 0 : parseInt(target.value);

    const updateState = (prevState: TNetProceedsData) => {
      return {
        ...prevState,
        [sectionName]: {
          ...prevState[sectionName],
          [name]: value,
        },
      };
    };
    switch (order) {
      case '1':
        setNetProceed1(updateState);
        break;
      case '2':
        setNetProceed2(updateState);
        break;
      case '3':
        setNetProceed3(updateState);
    }
  };

  const updateSlide = (): Partial<Record<TSlideId, TSlide>> | undefined => {
    const netProceeds = [netProceed1, netProceed2, netProceed3];

    if (!slides) return;

    return {
      netProceeds: {
        ...netProceedsSlide,
        netProceedsData: netProceeds,
      } as TSlide,
    };
  };

  const onPreviewSlide = () => {
    setIsSlidePreview(true);
  };

  const getModes = () => {
    const modesConfig: Partial<Record<TPresentationMode, TPresentationModeConfig>> = {};
    const modes: TPresentationMode[] = ['cma', 'oneSheeter', 'regular'];

    modes.forEach(mode => {
      const modeOrderConfig = getOrderForMode(mode);
      const modeSectionsConfig = getSectionsForMode(mode);

      if (!modeOrderConfig || !modeSectionsConfig) return;

      const nextProceedsSection =
        mode === 'regular' ? 'closingAndNextSteps' : modeOrderConfig.sections[0];

      const modeSectionSlideOrder = modeOrderConfig.sectionsSlide[nextProceedsSection].filter(
        sId => sId !== 'netProceeds',
      );

      const hasNextSteps = modeSectionSlideOrder.find(slideId => slideId === 'closingAndNextSteps');

      const filteredSlidesOrder = modeSectionSlideOrder.filter(
        slideId => slideId !== 'closingAndNextSteps',
      );

      const validOrder = hasNextSteps
        ? [...filteredSlidesOrder, 'netProceeds', 'closingAndNextSteps']
        : [...modeSectionSlideOrder, 'netProceeds'];

      const newSectionSlidesOrder = {
        ...modeOrderConfig.sectionsSlide,
        [nextProceedsSection]: validOrder as TSlideId[],
      };

      setSectionsSlideOrder(newSectionSlidesOrder);

      modesConfig[mode] = {
        order: {
          sections: modeOrderConfig.sections,
          sectionsSlide: newSectionSlidesOrder,
        },
        sections: modeSectionsConfig,
      };
    });

    return modesConfig;
  };

  const onCreate = async () => {
    if (!presentation) return;

    const updatedSlide = updateSlide();
    if (!updatedSlide) return;

    if (!presentation.id) return;

    setLoading(true);

    await PresentationApi.update(presentation.id, {
      ...presentation,
      slidesMap: {
        ...presentation.slidesMap,
        ...updatedSlide,
      },
      ...getModes(),
    });
    setLoading(false);
    PresentationApi.triggerPdfGeneration(presentation.id);
    queryClient.invalidateQueries(['presentation', presentation?.hash]);
    queryClient.invalidateQueries(['presentation-pdf', presentation.id]);

    onClose();
  };

  const isDisabled = [netProceed1, netProceed2, netProceed3].every(np => !np.listPrice?.price);

  if (!presentation) return null;

  return (
    <FitContentModal
      isOpen={isOpen}
      headerTitle='Create Net Sheet'
      onClose={onClose}
      confirmBtnText='Create'
      loading={loading}
      hasCancelBtn
      modalDescription={{
        buttonText: isSlidePreview ? 'Back to creation' : 'Preview Slide',
        onButtonClick: isSlidePreview
          ? () => {
              setIsSlidePreview(false);
            }
          : onPreviewSlide,
      }}
      modalContainerStyles={{ maxWidth: 1041, width: '80%' }}
      contentStyles={{ height: 'calc(100vh - 120px)' }}
      onConfirmClick={onCreate}
      disabled={isDisabled}
    >
      <Container>
        {isSlidePreview ? (
          <PreviewSlide
            slide={{
              ...netProceedsSlide,
              netProceedsData: [netProceed1, netProceed2, netProceed3],
            }}
            theme={presentation.ui.theme}
            infoPlacement={presentation.ui.infoPlacement.value}
          />
        ) : (
          <AddNetSheetInputsTable
            netProceed1={netProceed1}
            netProceed2={netProceed2}
            netProceed3={netProceed3}
            onInputChange={onInputChange}
          />
        )}
      </Container>
    </FitContentModal>
  );
};
