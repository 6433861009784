export default `<svg width="1192" height="727" viewBox="0 0 1192 727" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_329_4632)">
<path d="M305.557 382.685L171.4 319.44H441.8L307.643 382.685C306.983 382.997 306.217 382.997 305.557 382.685Z" fill="white"/>
<path d="M171.4 85.7372H441.8V319.44H171.4V85.7372Z" fill="white"/>
<path d="M305.557 22.4919L171.4 85.7372H441.8L307.643 22.4919C306.983 22.1804 306.217 22.1804 305.557 22.4919Z" fill="white"/>
<mask id="path-6-outside-1_329_4632" maskUnits="userSpaceOnUse" x="169.4" y="20.2582" width="275" height="365" fill="black">
<rect fill="white" x="169.4" y="20.2582" width="275" height="365"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M305.557 22.4918L171.4 85.7371H441.8L307.643 22.4918C306.983 22.1803 306.217 22.1803 305.557 22.4918ZM305.557 382.685L171.4 319.44V85.7371H441.8V319.44L307.643 382.685C306.983 382.997 306.217 382.997 305.557 382.685Z"/>
</mask>
<path d="M171.4 85.7371L170.576 83.9901L171.4 87.6685V85.7371ZM305.557 22.4918L306.38 24.2388V24.2388L305.557 22.4918ZM441.8 85.7371V87.6685L442.624 83.9901L441.8 85.7371ZM307.643 22.4918L306.82 24.2388V24.2388L307.643 22.4918ZM171.4 319.44H169.468V320.665L170.576 321.187L171.4 319.44ZM305.557 382.685L306.38 380.938L306.38 380.938L305.557 382.685ZM171.4 85.7371V83.8057H169.468V85.7371H171.4ZM441.8 85.7371H443.731V83.8057H441.8V85.7371ZM441.8 319.44L442.624 321.187L443.731 320.665V319.44H441.8ZM307.643 382.685L308.467 384.432H308.467L307.643 382.685ZM172.224 87.4841L306.38 24.2388L304.733 20.7447L170.576 83.9901L172.224 87.4841ZM441.8 83.8057H171.4V87.6685H441.8V83.8057ZM306.82 24.2388L440.976 87.4841L442.624 83.9901L308.467 20.7447L306.82 24.2388ZM306.38 24.2388C306.519 24.1732 306.68 24.1732 306.82 24.2388L308.467 20.7447C307.285 20.1874 305.915 20.1874 304.733 20.7447L306.38 24.2388ZM170.576 321.187L304.733 384.432L306.38 380.938L172.224 317.693L170.576 321.187ZM173.331 319.44V85.7371H169.468V319.44H173.331ZM171.4 87.6686H441.8V83.8057H171.4V87.6686ZM439.868 85.7371V319.44H443.731V85.7371H439.868ZM308.467 384.432L442.624 321.187L440.976 317.693L306.82 380.938L308.467 384.432ZM304.733 384.432C305.915 384.99 307.285 384.99 308.467 384.432L306.82 380.938C306.68 381.004 306.519 381.004 306.38 380.938L304.733 384.432Z" fill="#027B76" mask="url(#path-6-outside-1_329_4632)"/>
<path d="M594.957 382.685L460.8 319.44H731.2L597.043 382.685C596.382 382.997 595.617 382.997 594.957 382.685Z" fill="white"/>
<path d="M460.8 85.7372H731.2V319.44H460.8V85.7372Z" fill="white"/>
<path d="M594.957 22.4919L460.8 85.7372H731.2L597.043 22.4919C596.382 22.1804 595.617 22.1804 594.957 22.4919Z" fill="white"/>
<mask id="path-13-outside-2_329_4632" maskUnits="userSpaceOnUse" x="458.8" y="20.2582" width="275" height="365" fill="black">
<rect fill="white" x="458.8" y="20.2582" width="275" height="365"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M594.957 22.4918L460.8 85.7371H731.2L597.043 22.4918C596.382 22.1803 595.617 22.1803 594.957 22.4918ZM594.957 382.685L460.8 319.44V85.7371H731.2V319.44L597.043 382.685C596.382 382.997 595.617 382.997 594.957 382.685Z"/>
</mask>
<path d="M460.8 85.7371L459.976 83.9901L460.8 87.6685V85.7371ZM594.957 22.4918L595.78 24.2388V24.2388L594.957 22.4918ZM731.2 85.7371V87.6685L732.023 83.9901L731.2 85.7371ZM597.043 22.4918L596.219 24.2388V24.2388L597.043 22.4918ZM460.8 319.44H458.868V320.665L459.976 321.187L460.8 319.44ZM594.957 382.685L595.78 380.938L595.78 380.938L594.957 382.685ZM460.8 85.7371V83.8057H458.868V85.7371H460.8ZM731.2 85.7371H733.131V83.8057H731.2V85.7371ZM731.2 319.44L732.023 321.187L733.131 320.665V319.44H731.2ZM597.043 382.685L597.867 384.432H597.867L597.043 382.685ZM461.623 87.4841L595.78 24.2388L594.133 20.7447L459.976 83.9901L461.623 87.4841ZM731.2 83.8057H460.8V87.6685H731.2V83.8057ZM596.219 24.2388L730.376 87.4841L732.023 83.9901L597.867 20.7447L596.219 24.2388ZM595.78 24.2388C595.919 24.1732 596.08 24.1732 596.219 24.2388L597.867 20.7447C596.684 20.1874 595.315 20.1874 594.133 20.7447L595.78 24.2388ZM459.976 321.187L594.133 384.432L595.78 380.938L461.623 317.693L459.976 321.187ZM462.731 319.44V85.7371H458.868V319.44H462.731ZM460.8 87.6686H731.2V83.8057H460.8V87.6686ZM729.268 85.7371V319.44H733.131V85.7371H729.268ZM597.867 384.432L732.023 321.187L730.376 317.693L596.219 380.938L597.867 384.432ZM594.133 384.432C595.315 384.99 596.684 384.99 597.867 384.432L596.22 380.938C596.08 381.004 595.919 381.004 595.78 380.938L594.133 384.432Z" fill="#027B76" mask="url(#path-13-outside-2_329_4632)"/>
<path d="M884.357 382.685L750.2 319.44H1020.6L886.443 382.685C885.783 382.997 885.017 382.997 884.357 382.685Z" fill="white"/>
<path d="M750.2 85.7372H1020.6V319.44H750.2V85.7372Z" fill="white"/>
<path d="M884.357 22.4919L750.2 85.7372H1020.6L886.443 22.4919C885.783 22.1804 885.017 22.1804 884.357 22.4919Z" fill="white"/>
<mask id="path-20-outside-3_329_4632" maskUnits="userSpaceOnUse" x="748.2" y="20.2582" width="275" height="365" fill="black">
<rect fill="white" x="748.2" y="20.2582" width="275" height="365"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M884.357 22.4918L750.2 85.7371H1020.6L886.443 22.4918C885.783 22.1803 885.017 22.1803 884.357 22.4918ZM884.357 382.685L750.2 319.44V85.7371H1020.6V319.44L886.443 382.685C885.783 382.997 885.017 382.997 884.357 382.685Z"/>
</mask>
<path d="M750.2 85.7371L749.376 83.9901L750.2 87.6685V85.7371ZM884.357 22.4918L885.18 24.2388V24.2388L884.357 22.4918ZM1020.6 85.7371V87.6685L1021.42 83.9901L1020.6 85.7371ZM886.443 22.4918L885.62 24.2388V24.2388L886.443 22.4918ZM750.2 319.44H748.269V320.665L749.376 321.187L750.2 319.44ZM884.357 382.685L885.18 380.938L885.18 380.938L884.357 382.685ZM750.2 85.7371V83.8057H748.269V85.7371H750.2ZM1020.6 85.7371H1022.53V83.8057H1020.6V85.7371ZM1020.6 319.44L1021.42 321.187L1022.53 320.665V319.44H1020.6ZM886.443 382.685L887.267 384.432H887.267L886.443 382.685ZM751.024 87.4841L885.18 24.2388L883.533 20.7447L749.376 83.9901L751.024 87.4841ZM1020.6 83.8057H750.2V87.6685H1020.6V83.8057ZM885.62 24.2388L1019.78 87.4841L1021.42 83.9901L887.267 20.7447L885.62 24.2388ZM885.18 24.2388C885.319 24.1732 885.481 24.1732 885.62 24.2388L887.267 20.7447C886.085 20.1874 884.715 20.1874 883.533 20.7447L885.18 24.2388ZM749.376 321.187L883.533 384.432L885.18 380.938L751.024 317.693L749.376 321.187ZM752.131 319.44V85.7371H748.269V319.44H752.131ZM750.2 87.6686H1020.6V83.8057H750.2V87.6686ZM1018.67 85.7371V319.44H1022.53V85.7371H1018.67ZM887.267 384.432L1021.42 321.187L1019.78 317.693L885.62 380.938L887.267 384.432ZM883.533 384.432C884.715 384.99 886.085 384.99 887.267 384.432L885.62 380.938C885.481 381.004 885.319 381.004 885.18 380.938L883.533 384.432Z" fill="#027B76" mask="url(#path-20-outside-3_329_4632)"/>
<path d="M160.857 696.685L26.7 633.44H297.1L162.943 696.685C162.283 696.997 161.517 696.997 160.857 696.685Z" fill="white"/>
<path d="M26.7 399.737H297.1V633.44H26.7V399.737Z" fill="white"/>
<path d="M160.857 336.492L26.7 399.737H297.1L162.943 336.492C162.283 336.18 161.517 336.18 160.857 336.492Z" fill="white"/>
<mask id="path-27-outside-4_329_4632" maskUnits="userSpaceOnUse" x="24.7" y="334.258" width="275" height="365" fill="black">
<rect fill="white" x="24.7" y="334.258" width="275" height="365"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M160.857 336.492L26.7 399.737V633.44H297.1V399.737L162.943 336.492C162.283 336.18 161.517 336.18 160.857 336.492ZM160.857 696.685L26.7 633.44H297.1L162.943 696.685C162.283 696.997 161.517 696.997 160.857 696.685Z"/>
</mask>
<path d="M26.7 399.737L25.8764 397.99L24.7685 398.512V399.737H26.7ZM160.857 336.492L161.68 338.239V338.239L160.857 336.492ZM26.7 633.44H24.7685V635.371H26.7V633.44ZM297.1 633.44V635.371H299.031V633.44H297.1ZM297.1 399.737H299.031V398.512L297.924 397.99L297.1 399.737ZM162.943 336.492L162.12 338.239V338.239L162.943 336.492ZM26.7 633.44V631.509L25.8764 635.187L26.7 633.44ZM160.857 696.685L161.68 694.938L161.68 694.938L160.857 696.685ZM297.1 633.44L297.924 635.187L297.1 631.509V633.44ZM162.943 696.685L163.767 698.432H163.767L162.943 696.685ZM27.5235 401.484L161.68 338.239L160.033 334.745L25.8764 397.99L27.5235 401.484ZM28.6314 633.44V399.737H24.7685V633.44H28.6314ZM297.1 631.509H26.7V635.371H297.1V631.509ZM295.169 399.737V633.44H299.031V399.737H295.169ZM162.12 338.239L296.276 401.484L297.924 397.99L163.767 334.745L162.12 338.239ZM161.68 338.239C161.819 338.173 161.981 338.173 162.12 338.239L163.767 334.745C162.585 334.188 161.215 334.188 160.033 334.745L161.68 338.239ZM25.8764 635.187L160.033 698.432L161.68 694.938L27.5235 631.693L25.8764 635.187ZM297.1 631.509H26.7V635.371H297.1V631.509ZM163.767 698.432L297.924 635.187L296.276 631.693L162.12 694.938L163.767 698.432ZM160.033 698.432C161.215 698.99 162.585 698.99 163.767 698.432L162.12 694.938C161.981 695.004 161.819 695.004 161.68 694.938L160.033 698.432Z" fill="#027B76" mask="url(#path-27-outside-4_329_4632)"/>
<path d="M450.257 696.685L316.1 633.44H586.5L452.343 696.685C451.683 696.997 450.917 696.997 450.257 696.685Z" fill="white"/>
<path d="M316.1 399.737H586.5V633.44H316.1V399.737Z" fill="white"/>
<path d="M450.257 336.492L316.1 399.737H586.5L452.343 336.492C451.683 336.18 450.917 336.18 450.257 336.492Z" fill="white"/>
<mask id="path-34-outside-5_329_4632" maskUnits="userSpaceOnUse" x="314.1" y="334.258" width="275" height="365" fill="black">
<rect fill="white" x="314.1" y="334.258" width="275" height="365"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M450.257 336.492L316.1 399.737V633.44H586.5V399.737L452.343 336.492C451.683 336.18 450.917 336.18 450.257 336.492ZM450.257 696.685L316.1 633.44H586.5L452.343 696.685C451.683 696.997 450.917 696.997 450.257 696.685Z"/>
</mask>
<path d="M316.1 399.737L315.276 397.99L314.169 398.512V399.737H316.1ZM450.257 336.492L451.08 338.239V338.239L450.257 336.492ZM316.1 633.44H314.169V635.371H316.1V633.44ZM586.5 633.44V635.371H588.432V633.44H586.5ZM586.5 399.737H588.432V398.512L587.324 397.99L586.5 399.737ZM452.343 336.492L451.52 338.239V338.239L452.343 336.492ZM316.1 633.44V631.509L315.276 635.187L316.1 633.44ZM450.257 696.685L451.08 694.938L451.08 694.938L450.257 696.685ZM586.5 633.44L587.324 635.187L586.5 631.509V633.44ZM452.343 696.685L453.167 698.432H453.167L452.343 696.685ZM316.924 401.484L451.08 338.239L449.433 334.745L315.276 397.99L316.924 401.484ZM318.032 633.44V399.737H314.169V633.44H318.032ZM586.5 631.509H316.1V635.371H586.5V631.509ZM584.569 399.737V633.44H588.432V399.737H584.569ZM451.52 338.239L585.677 401.484L587.324 397.99L453.167 334.745L451.52 338.239ZM451.08 338.239C451.22 338.173 451.381 338.173 451.52 338.239L453.167 334.745C451.985 334.188 450.615 334.188 449.433 334.745L451.08 338.239ZM315.276 635.187L449.433 698.432L451.08 694.938L316.924 631.693L315.276 635.187ZM586.5 631.509H316.1V635.371H586.5V631.509ZM453.167 698.432L587.324 635.187L585.677 631.693L451.52 694.938L453.167 698.432ZM449.433 698.432C450.615 698.99 451.985 698.99 453.167 698.432L451.52 694.938C451.381 695.004 451.22 695.004 451.08 694.938L449.433 698.432Z" fill="#027B76" mask="url(#path-34-outside-5_329_4632)"/>
<path d="M739.657 696.685L605.5 633.44H875.9L741.743 696.685C741.083 696.997 740.317 696.997 739.657 696.685Z" fill="white"/>
<path d="M605.5 399.737H875.9V633.44H605.5V399.737Z" fill="white"/>
<path d="M739.657 336.492L605.5 399.737H875.9L741.743 336.492C741.083 336.18 740.317 336.18 739.657 336.492Z" fill="white"/>
<mask id="path-41-outside-6_329_4632" maskUnits="userSpaceOnUse" x="603.5" y="334.258" width="275" height="365" fill="black">
<rect fill="white" x="603.5" y="334.258" width="275" height="365"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M739.657 336.492L605.5 399.737V633.44H875.9V399.737L741.743 336.492C741.083 336.18 740.317 336.18 739.657 336.492ZM739.657 696.685L605.5 633.44H875.9L741.743 696.685C741.083 696.997 740.317 696.997 739.657 696.685Z"/>
</mask>
<path d="M605.5 399.737L604.676 397.99L603.569 398.512V399.737H605.5ZM739.657 336.492L740.48 338.239V338.239L739.657 336.492ZM605.5 633.44H603.569V635.371H605.5V633.44ZM875.9 633.44V635.371H877.831V633.44H875.9ZM875.9 399.737H877.831V398.512L876.724 397.99L875.9 399.737ZM741.743 336.492L740.92 338.239V338.239L741.743 336.492ZM605.5 633.44V631.509L604.676 635.187L605.5 633.44ZM739.657 696.685L740.48 694.938L740.48 694.938L739.657 696.685ZM875.9 633.44L876.724 635.187L875.9 631.509V633.44ZM741.743 696.685L742.567 698.432H742.567L741.743 696.685ZM606.324 401.484L740.48 338.239L738.833 334.745L604.676 397.99L606.324 401.484ZM607.431 633.44V399.737H603.569V633.44H607.431ZM875.9 631.509H605.5V635.371H875.9V631.509ZM873.969 399.737V633.44H877.831V399.737H873.969ZM740.92 338.239L875.076 401.484L876.724 397.99L742.567 334.745L740.92 338.239ZM740.48 338.239C740.619 338.173 740.781 338.173 740.92 338.239L742.567 334.745C741.385 334.188 740.015 334.188 738.833 334.745L740.48 338.239ZM604.676 635.187L738.833 698.432L740.48 694.938L606.324 631.693L604.676 635.187ZM875.9 631.509H605.5V635.371H875.9V631.509ZM742.567 698.432L876.724 635.187L875.076 631.693L740.92 694.938L742.567 698.432ZM738.833 698.432C740.015 698.99 741.385 698.99 742.567 698.432L740.92 694.938C740.781 695.004 740.619 695.004 740.48 694.938L738.833 698.432Z" fill="#027B76" mask="url(#path-41-outside-6_329_4632)"/>
<path d="M1029.06 696.685L894.9 633.44H1165.3L1031.14 696.685C1030.48 696.997 1029.72 696.997 1029.06 696.685Z" fill="white"/>
<path d="M894.9 399.737H1165.3V633.44H894.9V399.737Z" fill="white"/>
<path d="M1029.06 336.492L894.9 399.737H1165.3L1031.14 336.492C1030.48 336.18 1029.72 336.18 1029.06 336.492Z" fill="white"/>
<mask id="path-48-outside-7_329_4632" maskUnits="userSpaceOnUse" x="892.9" y="334.258" width="275" height="365" fill="black">
<rect fill="white" x="892.9" y="334.258" width="275" height="365"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1029.06 336.492L894.9 399.737V633.44H1165.3V399.737L1031.14 336.492C1030.48 336.18 1029.72 336.18 1029.06 336.492ZM1029.06 696.685L894.9 633.44H1165.3L1031.14 696.685C1030.48 696.997 1029.72 696.997 1029.06 696.685Z"/>
</mask>
<path d="M894.9 399.737L894.076 397.99L892.968 398.512V399.737H894.9ZM1029.06 336.492L1029.88 338.239V338.239L1029.06 336.492ZM894.9 633.44H892.968V635.371H894.9V633.44ZM1165.3 633.44V635.371H1167.23V633.44H1165.3ZM1165.3 399.737H1167.23V398.512L1166.12 397.99L1165.3 399.737ZM1031.14 336.492L1030.32 338.239V338.239L1031.14 336.492ZM894.9 633.44V631.509L894.076 635.187L894.9 633.44ZM1029.06 696.685L1029.88 694.938L1029.88 694.938L1029.06 696.685ZM1165.3 633.44L1166.12 635.187L1165.3 631.509V633.44ZM1031.14 696.685L1031.97 698.432H1031.97L1031.14 696.685ZM895.724 401.484L1029.88 338.239L1028.23 334.745L894.076 397.99L895.724 401.484ZM896.831 633.44V399.737H892.968V633.44H896.831ZM1165.3 631.509H894.9V635.371H1165.3V631.509ZM1163.37 399.737V633.44H1167.23V399.737H1163.37ZM1030.32 338.239L1164.48 401.484L1166.12 397.99L1031.97 334.745L1030.32 338.239ZM1029.88 338.239C1030.02 338.173 1030.18 338.173 1030.32 338.239L1031.97 334.745C1030.78 334.188 1029.42 334.188 1028.23 334.745L1029.88 338.239ZM894.076 635.187L1028.23 698.432L1029.88 694.938L895.724 631.693L894.076 635.187ZM1165.3 631.509H894.9V635.371H1165.3V631.509ZM1031.97 698.432L1166.12 635.187L1164.48 631.693L1030.32 694.938L1031.97 698.432ZM1028.23 698.432C1029.42 698.99 1030.78 698.99 1031.97 698.432L1030.32 694.938C1030.18 695.004 1030.02 695.004 1029.88 694.938L1028.23 698.432Z" fill="#027B76" mask="url(#path-48-outside-7_329_4632)"/>
</g>
<defs>
<filter id="filter0_d_329_4632" x="0.768555" y="0.326782" width="1190.46" height="726.524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_329_4632"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_329_4632" result="shape"/>
</filter>
</defs>
</svg>`;
