import React from 'react';
import styled from 'styled-components';
import { ColRight, ColRightItem } from './common.styles';

const HeadContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 0,
});

const HeadTitle = styled('h3')({
  fontSize: 12,
  fontWeight: 700,
  textTransform: 'capitalize',
});

export const NetSheetsColumnTitles = () => {
  return (
    <HeadContainer>
      <ColRight>
        <ColRightItem>
          <HeadTitle>Price 1</HeadTitle>
        </ColRightItem>
        <ColRightItem>
          <HeadTitle>Price 2 - Optional</HeadTitle>
        </ColRightItem>
        <ColRightItem>
          <HeadTitle>Price 3 - Optional</HeadTitle>
        </ColRightItem>
      </ColRight>
    </HeadContainer>
  );
};
