import styled from 'styled-components';
import { Icon } from '../../../../../../../Common';

export { Icon };

export const ImageWrapper = styled('div')({
  width: 79,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
});

export const TestimonialWrapper = styled('div')({
  marginTop: 25,
});

export const ReadMoreLink = styled('a')(({ theme }) => ({
  fontSize: 20,
  color: '#666',
  textDecoration: 'none',
  fontFamily: 'inherit',
  display: 'block',
  textAlign: 'center',
  '&:not(:last-child)': {
    marginBottom: 10,
  },
}));
