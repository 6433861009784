import styled from 'styled-components';
import { useUiConfigFontsHeader } from '../../../../../../../providers/providers/UiConfigFontProvider';

type WithImage = {
  hasImage?: boolean;
};

export const CoverHeading = styled('h2')<WithImage>(({ hasImage }) => ({
  fontFamily: useUiConfigFontsHeader(),
  fontSize: hasImage ? 34 : 75,
  fontWeight: 700,
  color: '#000',
  margin: hasImage ? 0 : '20px 0',
}));

export const BTPreHeading = styled('span')({
  fontSize: 20,
  color: '#000',
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, 200px)',
});
