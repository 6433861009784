import React, {
  createContext,
  useContext,
  useState,
  SetStateAction,
  Dispatch,
  useEffect,
  useCallback,
} from 'react';
import { TSection, TSlide, TSlideId } from '../../../../types';
import { LibrarySlidesExtractor } from '../../services/LibrarySlidesExtractor';
import { useSelectedSlide } from '../../../../providers/providers/SelectedSlideProvider';
import { useActiveSection } from '../../../../components/Slide/providers/ActiveSectionProvider';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { useOrder } from '../../../../providers/providers/OrderProvider';

interface Props {
  selectedSlideIndex: number;
  allSlides: TSlide[];
  activeSlides: TSlide[];
  animate: boolean;
  isLastSlide: boolean;
  activeSection?: TSection;
  setActiveSlides: Dispatch<SetStateAction<TSlide[]>>;
  setAnimate: Dispatch<SetStateAction<boolean>>;
  setSelectedSlideIndex: Dispatch<SetStateAction<number>>;
  onCarouselSlideChange: (index: number, activeSlides: TSlide[]) => void;
  onAnimationEnd: () => void;
  onSelectSlide: (slide: TSlide) => void;
  onSelectSection: (section: TSection | undefined) => void;
}

const CarouselControlsContext = createContext<Props | undefined>(undefined);

const sortSlides = (slidesMap: Partial<Record<TSlideId, TSlide>>) => {
  return LibrarySlidesExtractor.getLibrarySlides(slidesMap).sort((a, b) =>
    a.label.toLowerCase() > b.label.toLowerCase()
      ? 1
      : b.label.toLowerCase() > a.label.toLowerCase()
        ? -1
        : 0,
  );
};

export const CarouselControlsProvider: React.FC = ({ children }) => {
  const { slides } = useSlides();
  const { sectionsSlide, getSlideSectionId } = useOrder();

  const { activeSection, setActiveSection } = useActiveSection();
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  const [activeSlides, setActiveSlides] = useState<TSlide[]>(
    LibrarySlidesExtractor.getLibrarySectionSlides(
      sectionsSlide,
      slides ?? {},
      'whyIAmTheRightFit',
    ),
  );
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [allSlides, setAllSlides] = useState(sortSlides(slides ?? {}));

  const { selectedSlide } = useSelectedSlide();

  const onCarouselSlideChange = (index: number, activeSlides: TSlide[]) => {
    if (index === activeSlides.length - 1) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
    setSelectedSlideIndex(index);
    setAnimate(true);
  };

  const onSelectSlide = (slide: TSlide) => {
    const section = getSlideSectionId(slide.id);
    if (!section || !slides) return;
    const librarySlides = LibrarySlidesExtractor.getLibrarySectionSlides(
      sectionsSlide,
      slides,
      section as TSection,
    );

    setActiveSlides(librarySlides);
    const slideIndex = librarySlides.findIndex(s => s.id === slide.id);
    setSelectedSlideIndex(slideIndex);
    setActiveSection(section);
  };

  const onSelectSection = useCallback(
    (section: TSection | undefined) => {
      if (!section || !slides) {
        setActiveSection(section);
        setSelectedSlideIndex(0);
        setIsLastSlide(false);
        return;
      }
      const activeSlides = LibrarySlidesExtractor.getLibrarySectionSlides(
        sectionsSlide,
        slides,
        section,
      );

      setActiveSlides(activeSlides);
      setActiveSection(section);
      setSelectedSlideIndex(0);
      setIsLastSlide(false);
    },
    [sectionsSlide, setActiveSection, slides],
  );

  const onAnimationEnd = useCallback(() => setAnimate(false), []);

  useEffect(() => {
    if (!activeSection || !slides) return;
    const activeSlides = LibrarySlidesExtractor.getLibrarySectionSlides(
      sectionsSlide,
      slides,
      activeSection as TSection,
    );
    setActiveSlides(activeSlides);
  }, [activeSection, sectionsSlide, slides]);

  useEffect(() => {
    const nextIndex = activeSlides.findIndex(slide => slide.id === selectedSlide?.id);

    if (nextIndex > -1) onCarouselSlideChange(nextIndex, activeSlides);
  }, [activeSlides, selectedSlide?.id]);

  useEffect(() => {
    if (!activeSection || !slides) return;
    const newActiveSlides = LibrarySlidesExtractor.getLibrarySectionSlides(
      sectionsSlide,
      slides,
      activeSection as TSection,
    );

    const currentSlidesCount = newActiveSlides.length;
    const prevSlidesCount = activeSlides.length;

    if (currentSlidesCount) setActiveSlides(newActiveSlides);

    const exists = newActiveSlides[selectedSlideIndex];

    // a last slide got deleted
    if (!exists) {
      const nextIndex =
        selectedSlideIndex >= currentSlidesCount ? currentSlidesCount - 1 : selectedSlideIndex;
      onCarouselSlideChange(nextIndex, newActiveSlides);
    }

    if (!currentSlidesCount) {
      onSelectSection('whyIAmTheRightFit');
    }

    if (selectedSlideIndex === currentSlidesCount - 1) {
      setIsLastSlide(true);
    }

    // a slide got created => display created slide
    if (currentSlidesCount !== prevSlidesCount) {
      const createdSlideIndex = newActiveSlides.findIndex(
        upcomingSlide => !allSlides.some(slide => slide.id === upcomingSlide.id),
      );
      if (createdSlideIndex > -1) onCarouselSlideChange(createdSlideIndex, newActiveSlides);
    }

    // display edited slide
    if (selectedSlide) {
      const editedSlideIndex = newActiveSlides.findIndex(slide => slide.id === selectedSlide.id);

      if (editedSlideIndex > -1) onCarouselSlideChange(editedSlideIndex, newActiveSlides);
    }

    setAllSlides(sortSlides(slides));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  return (
    <CarouselControlsContext.Provider
      value={{
        selectedSlideIndex,
        activeSlides,
        animate,
        isLastSlide,
        activeSection: activeSection as TSection,
        allSlides,
        setSelectedSlideIndex,
        setActiveSlides,
        setAnimate,
        onCarouselSlideChange,
        onAnimationEnd,
        onSelectSlide,
        onSelectSection,
      }}
    >
      {children}
    </CarouselControlsContext.Provider>
  );
};

export const useCarouselControls = (): Props => {
  const context = useContext(CarouselControlsContext);

  if (!context) {
    throw new Error('useCarouselControls must be used within a CarouselControlsContext');
  }

  return context;
};
