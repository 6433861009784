export default `
<svg width="1066" height="786" viewBox="0 0 1066 786" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M533.006 534.024L179.885 653.982M533.006 534.024L162.229 494.558M533.006 534.024L213.639 342.924M533.006 534.024L343.463 215.177M533.006 534.024V162.209M533.006 534.024L728.261 217.774M533.006 534.024L854.449 347.597M533.006 534.024L904.302 497.154M533.006 534.024L885.088 653.982" stroke="#DDDDDD" stroke-width="3.90244"/>
<circle cx="532.407" cy="534.37" r="294.286" stroke="#027B76" stroke-width="13.5017"/>
<circle cx="103.31" cy="680.463" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
<circle cx="961.227" cy="681.502" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
<circle cx="82.453" cy="483.131" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
<circle cx="983.95" cy="483.131" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
<circle cx="142.691" cy="304.494" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
<circle cx="923.711" cy="304.494" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
<circle cx="300.557" cy="146.629" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
<circle cx="533.201" cy="82.2364" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
<circle cx="766.884" cy="146.629" r="79.4522" fill="white" fill-opacity="0.6" stroke="#027B76" stroke-width="4.15436"/>
</svg>
`;
