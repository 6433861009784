import { useMemo } from 'react';

import { InheritanceLevel } from '../../../../../../../../types';
import { useUserCategoryCustomizationOrder } from './useUserCategoryCustomizationOrder';
import { useUser } from '../../../../../../../../components/Slide/providers/UserProvider';
import { usePresentationMeta } from '../../../../../../../../providers/providers/PresentationMetaProvider';

export function useInheritanceCategoryCustomizations() {
  const { hash, teamsPrimaryEntity, officePrimaryEntity, useSpecialBranding } = useUser()!;

  const {
    data: agentCustomizations,
    isLoading,
    isFetching,
  } = useUserCategoryCustomizationOrder(hash, null, useSpecialBranding);

  const { data: teamCustomization } = useUserCategoryCustomizationOrder(
    hash,
    teamsPrimaryEntity,
    useSpecialBranding,
  );

  const { data: officeCustomizations } = useUserCategoryCustomizationOrder(
    hash,
    officePrimaryEntity,
    useSpecialBranding,
  );
  const { data: accountCustomizations } = useUserCategoryCustomizationOrder(
    hash,
    0,
    useSpecialBranding,
  );
  const { data: superaccountCustomizations } = useUserCategoryCustomizationOrder(
    hash,
    useSpecialBranding ? -2 : -1,
    useSpecialBranding,
  );

  const { inheritanceLevel } = usePresentationMeta();

  const categoryCustomizations = useMemo(() => {
    const customizations = {
      [InheritanceLevel.AGENT]: agentCustomizations,
      [InheritanceLevel.TEAM]: teamCustomization,
      [InheritanceLevel.OFFICE]: officeCustomizations,
      [InheritanceLevel.ACCOUNT]: accountCustomizations,
      [InheritanceLevel.SUPERACCOUNT]: superaccountCustomizations,
    };

    if (!inheritanceLevel || !customizations[inheritanceLevel]) return null;

    return customizations[inheritanceLevel];
  }, [
    agentCustomizations,
    teamCustomization,
    officeCustomizations,
    accountCustomizations,
    superaccountCustomizations,
    inheritanceLevel,
  ]);

  return { categoryCustomizations, isLoading, isFetching };
}
