import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TCountableTag } from '../../../hooks/dynamicSlides/useTags';
import { TTag } from '../../../types';

import { Tag, TagCount, TagsContainer } from './SlidesTags.style';

interface SlidesTagsProps {
  tagsList: (TCountableTag | undefined)[];
  selectedTags: TTag[];
  setSelectedTags: Dispatch<SetStateAction<TTag[]>>;
  noPadding?: boolean;
}

export const SlidesTags: React.FC<SlidesTagsProps> = ({
  tagsList,
  selectedTags,
  setSelectedTags,
  noPadding = false,
}) => {
  const isTagSelected = (tag: TTag) => {
    return !!selectedTags.find(selectedTag => selectedTag.id === tag.id);
  };

  const onToggleSelectedTags = (tag: TTag) => {
    const isSelected = isTagSelected(tag);

    if (isSelected) {
      setSelectedTags(prev => prev.filter(selectedTag => selectedTag.id !== tag.id));
      return;
    }
    setSelectedTags(prev => [...prev, tag]);
  };

  if (!tagsList.length) return null;

  return (
    <TagsContainer noPadding={noPadding}>
      {tagsList.map(tag => {
        if (!tag) return;
        return (
          <Tag
            isActive={isTagSelected(tag)}
            key={tag?.id}
            onClick={() => onToggleSelectedTags(tag)}
          >
            #{tag?.label}
            {tag?.count > 1 && (
              <TagCount isActive={isTagSelected(tag)} count={tag?.count}>
                {tag?.count}
              </TagCount>
            )}
          </Tag>
        );
      })}
    </TagsContainer>
  );
};
