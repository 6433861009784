import React, { useEffect, useState } from 'react';
import { AnalyticsSection } from '../../components/AnalyticsSection/AnalyticsSection.component';

import {
  BackButtonContainer,
  BackIcon,
  BackText,
  PageContainer,
} from './AgentAdminAnalytics.styles';
import { useParams } from 'react-router-dom';
import { corePresentApi } from '../../api/CorePresentApi';
import { ApiResponse, mapData, mapTrackingData } from './data';
import { MainLayout } from '../../layouts/MainLayout/MainLayout';
import Loader from '../../components/Loader/Loader';
import styled, { useTheme } from 'styled-components';
import { DropdownWrapper } from '../../components/Common/AccountOfficeDropdown/AccountOfficeDropdown.styles';
import DateRangePicker from 'rsuite/DateRangePicker';
import { UtcTime } from '../../services/utcTime';
import { afterToday, before, combine } from 'rsuite/cjs/DateRangePicker/disabledDateUtils';
import { endOfToday, startOfMonth, subDays, subMonths } from 'date-fns';
import { Select } from '../../components/Common';
const LoaderDiv = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

// Bunch of copy/paste here from AdminAnalytics, as we needed a quick fix.
// Really needs to be refactored to common component.
export const AgentAdminAnalytics: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();
  const { office } = useParams<{ office: string }>();
  const { startParam } = useParams<{ startParam: string }>();
  const { endParam } = useParams<{ endParam: string }>();
  const { typeParam } = useParams<{ typeParam: string }>();
  const [type, setType] = useState<string>('all');
  const [date, setDate] = useState<string[]>([startParam, endParam]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ApiResponse | null>(null);
  const { colors } = useTheme();

  const typeOptions = [
    {
      value: 'all',
      label: 'All Presentations',
    },
    {
      value: 'seller',
      label: 'Seller Presentations',
    },
    {
      value: 'buyer',
      label: 'Buyer Presentations',
    },
  ];

  const getOption = (value: string) => {
    return typeOptions.filter(opt => opt.value === value)[0];
  };
  const styles = {
    fontSize: 14,
    selectedBgColor: '#ddd',
    selectedFontColor: '#444',
    menuMarginTop: '0px',
    menuMarginBottom: '0px',
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data = await corePresentApi.get(
          `/analytics/user/${hash}/?start=${date[0]}&end=${date[1]}&type=${type}`,
        );
        await setData(data);
        setLoading(false);
      } catch (e: any) {
        console.error(e);
        setLoading(false);
      }
    })();
  }, [date, hash, type]);

  const thirteenMonthsAgo = subMonths(startOfMonth(new Date()), 13);
  const sixMonthsAgo = subMonths(startOfMonth(new Date()), 6);
  const sevenDaysAgo = subDays(new Date(), 7);
  const startOfCurrentMonth = startOfMonth(new Date());

  return (
    <MainLayout>
      <PageContainer>
        <BackButtonContainer to={`/admin-analytics/${office}`}>
          <BackIcon icon={['fas', 'chevron-left']} />
          <BackText>Back to Office</BackText>
        </BackButtonContainer>
        <DropdownWrapper
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
          }}
        >
          <DateRangePicker
            placeholder={'Select Date Range'}
            onChange={dates => {
              if (!dates) {
                setDate([]);
                return;
              }
              setDate([UtcTime.getInUtc(dates[0]), UtcTime.getInUtc(dates[1])]);
            }}
            disabled={loading}
            shouldDisableDate={combine(afterToday(), before(thirteenMonthsAgo))}
            defaultValue={[thirteenMonthsAgo, endOfToday()]}
            cleanable={false}
            placement='autoVertical'
            ranges={[
              {
                label: 'Last 7 Days',
                value: [sevenDaysAgo, endOfToday()],
              },
              {
                label: 'Month To Date',
                value: [startOfCurrentMonth, endOfToday()],
              },
              {
                label: 'Last 6 Months',
                value: [sixMonthsAgo, endOfToday()], // Adding one more day due to some weird datepicker stuff
              },
              {
                label: 'Last 13 Months',
                value: [thirteenMonthsAgo, endOfToday()], // Adding one more day due to some weird datepicker stuff
              },
            ]}
            appearance='subtle'
            style={{
              display: 'flex',
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset',
              background: colors.v2.background,
              border: `1px solid ${colors.v2.gray[300]}`,
              cursor: 'pointer',
              borderRadius: '5px',
              height: '35px',
              outline: 'none',
            }}
          />
          <Select
            disabled={loading}
            options={typeOptions}
            selectedOption={getOption(type)}
            onChange={(option: any) => {
              setType(option?.value);
            }}
            styles={{ ...styles, flex: 'initial', width: '180px' }}
          />
        </DropdownWrapper>
        {loading && (
          <LoaderDiv>
            <Loader size='lg' />
          </LoaderDiv>
        )}
        {data && !loading && (
          <AnalyticsSection
            name={data?.user?.user?.name}
            engagementMetricsData={mapData(data)}
            trackingFeedbackData={mapTrackingData(data)}
            isWithAgentData
            type='all'
          />
        )}
      </PageContainer>
    </MainLayout>
  );
};
