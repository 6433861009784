import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  description: string;
  title?: string;
  progress?: number;
};

export function ModalInfoHeader({ title, description }: Props) {
  return (
    <Container>
      <HeaderTitle>{title}</HeaderTitle>
      <DescriptionWrapper>
        <Icon icon='circle-info' />
        <DescriptionText>{description}</DescriptionText>
      </DescriptionWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.v2.background};
`;

const HeaderTitle = styled.p`
  display: flex;
  align-self: stretch;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const DescriptionWrapper = styled.div`
  display: flex;
  gap: 7px;
  align-self: stretch;
  align-items: flex-start;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  font-weight: 900;
  line-height: 18px;
  font-style: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const DescriptionText = styled.p`
  width: 596px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
