import React from 'react';
import { useHistory } from 'react-router-dom';

import { PresentationNavItem } from '../../../types';
import { Container, ChildrenWrapper, Icon } from './Header.styles';
import { NavigationComponent } from './Navigation/Navigation.component';
import { PresentLogo } from '../../PresentLogo/PresentLogo';

interface Props {
  navItems: PresentationNavItem[];
}

export const HeaderComponent: React.FC<Props> = ({ navItems, children }) => {
  const { push } = useHistory();

  return (
    <Container>
      <PresentLogo style={{ cursor: 'pointer', width: 170 }} onClick={() => push('/')} />
      <NavigationComponent navItems={navItems} />
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Container>
  );
};
