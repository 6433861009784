import React from 'react';
import { TCategory } from '../../../../../../../types';
import { CardHeaderTraditional } from './cardHeaders/CardHeaderTraditional/CardHeaderTraditional.component';
import { CardHeaderModern } from './cardHeaders/CardHeaderModern/CardHeaderModern.component';
import {
  CardColumn,
  CardContainer,
  CardContent,
  CardRow,
  ColumnText,
  ColumnValue,
} from './RealtorVSIBuyerCard.styles';

type TData = {
  propertyValue: string;
  commission: string;
  serviceCharge: string;
  closingCosts: string;
  total: string;
};

interface RealtorVSIBuyerCardProps {
  cardData: TData;
  isIBuyer?: boolean;
  category?: TCategory;
  isPdf?: boolean;
}

export const RealtorVSIBuyerCard: React.FC<RealtorVSIBuyerCardProps> = ({
  cardData,
  isIBuyer,
  category = 'traditional',
  isPdf = false,
}) => {
  const cardContent = [
    {
      text: isIBuyer
        ? 'iBuyer buys your home, on average, for 10% under market value.'
        : 'Your Realtor will test the market to get top dollar for your home.',
      data: cardData.propertyValue,
    },
    {
      text: isIBuyer
        ? 'iBuyers roll the commission into the Service Charge.'
        : 'In this example, both parties agreed on a 6% rate, reflecting the negotiable nature of realtor commission.',
      data: cardData.commission,
    },
    {
      text: isIBuyer
        ? 'iBuyers Service Charge ranges from 7%-13% with an average of 9.5%.'
        : 'Realtors typically don’t have a service charge.',
      data: cardData.serviceCharge,
    },
    {
      text: isIBuyer
        ? 'Closing costs and taxes are paid by the seller.'
        : 'Closing costs and taxes are paid by the seller.',
      data: cardData.closingCosts,
    },
    {
      text: isIBuyer ? 'Total Net using an iBuyer' : 'Total Net using a Realtor',
      data: cardData.total,
      isTotal: true,
    },
  ];

  const getCardHeader = (category: TCategory) => {
    switch (category) {
      case 'traditional':
        return <CardHeaderTraditional isIBuyer={isIBuyer} />;
      case 'modern':
        return <CardHeaderModern isIBuyer={isIBuyer} isPdf={isPdf} />;
      default:
        return <></>;
    }
  };

  return (
    <CardContainer category={category}>
      {getCardHeader(category)}
      <CardContent>
        {cardContent.map((content, i) => (
          <CardRow key={i} isTotal={content.isTotal} isIBuyer={isIBuyer}>
            <CardColumn isTotal={content.isTotal} isIBuyer={isIBuyer} isPdf={isPdf}>
              <ColumnText isTotal={content.isTotal}>{content.text}</ColumnText>
            </CardColumn>
            <CardColumn isTotal={content.isTotal} isIBuyer={isIBuyer} isPdf={isPdf}>
              <ColumnValue isTotal={content.isTotal}>{content.data}</ColumnValue>
            </CardColumn>
          </CardRow>
        ))}
      </CardContent>
    </CardContainer>
  );
};
