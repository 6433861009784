import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigBodyLetterSpacing } from '../../../../../providers/providers/UiConfigFontProvider';
import { Typography } from '../../../../Common/Typography/Typography.component';

export const Container = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const ContentContainer = styled('div')({
  width: '100%',
  height: '100%',
  padding: '45px 80px 0 45px',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  zIndex: 1,
});

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '60%',
});

export const CardsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 'auto',
  marginBottom: '5%',
  marginLeft: 30,
});

export const Logo = styled('img')({
  marginBottom: 16,
  alignSelf: 'flex-start',
});

export const TouchPadWrapper = styled('div')({
  width: '38%',
});

export const TouchPadImg = styled('img')({
  width: '100%',
  transform: 'translateX(30px)',
});

export const BackgroundWave = styled('img')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
});

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: '#000',
  letterSpacing: useUiConfigBodyLetterSpacing(),
  lineHeight: 1.3,
  '&:not(:last-child)': {
    marginBottom: 20,
  },
}));

export const BoxContainer = styled('div')(() => ({
  width: 257,
  height: 176,
  border: `1.11173px solid ${useUiConfigColorsPrimary()}`,
  borderRadius: 10.5,
}));
