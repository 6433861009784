import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { FourImagesBeta } from './FourImagesBeta';
import { FourImagesBetaForm } from './FourImagesBetaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { FourImagesBetaPdf } from './FourImagesBetaPdf';

const template = 'fourImagesLayoutBeta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - Four Images',
  frame: true,
  data: {
    image: '',
    image2: '',
    image3: '',
    image4: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: FourImagesBetaForm,
  web: FourImagesBeta,
  pdf: FourImagesBetaPdf,
};

export type TFourImagesLayoutBeta = typeof template;
export { config, mappings, thumbnail };
