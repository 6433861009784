import React from 'react';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { SlideSectionHeaderComponent } from '../../../../components/common/SlideSectionHeader.component';
import { RealtorVSIBuyerCard } from '../../components/RealtorVSIBuyerCard/RealtorVSIBuyerCard.component';

import { staticText, iBuyerData, realtorData } from '../../data';
import {
  TitleContainer,
  Title,
  Subtitle,
  DescriptionContainer,
  DescriptionTitle,
  DescriptionText,
  Cards,
  CardsContainer,
} from './ModernRealtorVSIBuyerPdf.styles';

export const ModernRealtorVSIBuyerPdf: React.FC = () => {
  const { title, subtitle, descriptionTitle, descriptionText, comparisonTitle } = staticText;

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <TitleContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      <DescriptionContainer>
        <DescriptionTitle>
          {descriptionTitle.part1} {descriptionTitle.part2}
        </DescriptionTitle>
        <DescriptionText>{descriptionText.p1}</DescriptionText>
        <DescriptionText>{descriptionText.p2}</DescriptionText>
        <DescriptionText>{descriptionText.p3}</DescriptionText>
      </DescriptionContainer>
      <CardsContainer>
        {/*<SlideSectionHeaderComponent heading={comparisonTitle} isPdf />*/}
        <Cards>
          <RealtorVSIBuyerCard cardData={realtorData} category='modern' isPdf />
          <RealtorVSIBuyerCard cardData={iBuyerData} isIBuyer category='modern' isPdf />
        </Cards>
      </CardsContainer>
    </PdfFrame>
  );
};
