import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  width: '33.333%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 20,
  flexDirection: 'row',
  height: 120,
  zIndex: 10,
}));

export const LogoWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  &:nth-child(2),
  &:nth-child(3) {
    height: 80%;
  }
`;

export const Logo = styled.img<{ height?: string | number }>`
  max-width: 100%;
  height: ${props => (props.height ? `${props.height}` : '100%')};
  object-fit: contain;
  object-position: right;
`;
