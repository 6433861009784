import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  isSelected: boolean;
  children: ReactNode;
  onClick: () => void;
  propertiesRef: React.MutableRefObject<HTMLElement | null>;
}

export const ItemContainer = ({ isSelected, children, onClick, propertiesRef }: Props) => {
  const ref = useRef<HTMLLIElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (isSelected && mounted) {
      const propertiesList = propertiesRef.current;
      const itemContainer = ref.current;

      var propertiesListRect = propertiesList?.getBoundingClientRect();
      var itemContainerRect = itemContainer?.getBoundingClientRect();

      if (!propertiesList || !propertiesListRect?.height || !itemContainerRect?.top) return;

      const itemOffset = propertiesList?.scrollTop + itemContainerRect?.top;

      const propertiesListBottom = propertiesList?.scrollTop + propertiesListRect?.height;
      const propertiesListTop = propertiesList?.scrollTop;

      if (propertiesListTop < itemOffset && propertiesListBottom > itemOffset) {
        return;
      }
      propertiesList.scrollTop =
        itemContainerRect.top + propertiesList.scrollTop - propertiesListRect.top - 60;
    }
  }, [isSelected, mounted, propertiesRef]);

  return (
    <Container ref={ref} onClick={onClick} isActive={isSelected || false}>
      {children}
    </Container>
  );
};

const Container = styled.li<{ isActive?: boolean }>`
  font-weight: 500;
  letter-spacing: 0.6px;
  color: ${({ theme }) => theme.colors.secondary.textLight};
  font-size: 12px;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  background: ${({ isActive }) =>
    isActive
      ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), #FDF6E0'
      : 'transparent'};
  align-items: stretch;

  &:not(:last-of-type) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  }

  &:hover {
    background-color: #daeffd;
  }
`;
