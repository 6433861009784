import { useMemo } from 'react';
import styled from 'styled-components';

import hero_banner_kvc_webp from './assets/hero_banner_kvcore.webp';
import hero_banner_bt_png from './assets/hero_banner_boldtrail.png';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { useWhiteLabelSettings } from '../../../../../features/whitelabel/useWhiteLabelSettings';
import { WhiteLabelHeroImage } from './WhiteLabelHeroImage';
import useMediaQuery from '../../../../../hooks/useMediaQuery';

const HeroBanner = () => {
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const { hasWL } = useWhiteLabelSettings();
  const isMobile = useMediaQuery('(max-width: 980px)');

  const marginBottom = useMemo(() => {
    if (isMobile) {
      return 0;
    }
    if (hasWL) {
      return -18;
    }
    if (shouldUseBtTheme) {
      return -23;
    }
    return 0;
  }, [isMobile, hasWL, shouldUseBtTheme]);

  const src = shouldUseBtTheme ? hero_banner_bt_png : hero_banner_kvc_webp;

  return (
    <Container style={{ marginBottom }}>
      <TextWrapper>
        <Title>Get Ready to Win</Title>
        <Subtitle>
          Pick a presentation below to get started{' '}
          <span>
            <FontAwesomeIcon icon={faArrowDown} />
          </span>
        </Subtitle>
      </TextWrapper>
      {!hasWL ? <Image src={src} alt='hero banner' /> : <WhiteLabelHeroImage />}
    </Container>
  );
};

export default HeroBanner;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1450px;
  gap: 80px;
  margin: 0 auto;
  padding: 0 30px;
  min-height: 180px;

  @media (max-width: 1180px) {
    gap: 20px;
  }
  @media (max-width: 1080px) {
    gap: 10px;
  }
  @media (max-width: 980px) {
    padding: 0 15px;
    min-height: auto;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 10px;
  font-family: Figtree;

  @media (max-width: 1080px) {
    padding-top: 0;
    padding-bottom: 24px;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  font-size: 46px;
  font-style: normal;
  font-weight: 800;
  line-height: 33.796px;
  margin: 0;

  @media (max-width: 1180px) {
    font-size: 36px;
  }
`;

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  @media (max-width: 1180px) {
    font-size: 24px;
  }
`;

const Image = styled.img`
  display: block;
  max-width: 662px;

  @media (max-width: 980px) {
    display: none;
  }
`;
