import React, { useEffect, useMemo, useState } from 'react';

import { SearchCombiner } from '../SearchCombiner';
import { AdrSection } from './AdrSelection/AdrSelection';

import styles from './PropertyArea.module.scss';
import { ManualArea } from './AdrSelection/ManualReportMap/ManualArea';
import { connect, useSelector } from 'react-redux';
import { Modal } from '../../../../components/Modal/Modal';
import { Property } from '../../../../../../../../features/report/components/Property/Property';
import { useAtom, useSetAtom } from 'jotai';
import { selectedPropertyAtom } from '../../../../../../../../features/report/state/selectedPropertyAtom';
import { extractSubject } from '../../../../../../../../features/report/services/extractSubject';
import { useUpdateAtomOnChange } from '../../../../../../../../hooks/useUpdateAtomOnChange';
import { subjectAtom } from '../../../../../../../../features/report/state/subjectAtom';

export const PropertyArea = ({
  onChange = f => f,
  searchData,
  partialData,
  partialLoading,
  isManual,
  className = '',
}) => {
  useEffect(() => {
    SearchCombiner.clear();
  }, []);

  const searchCriteria = useSelector(({ search }) => search?.searchCriteria);
  const reportDetailed = useSelector(({ report }) => report.reportDetailed);

  const subject = useMemo(() => extractSubject(reportDetailed), [reportDetailed]);
  useUpdateAtomOnChange(subjectAtom, subject);

  const [selectedProperty, setSelectedProperty] = useAtom(selectedPropertyAtom);

  const [exclusions, setExclusions] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const getExclusions = propertyName => searchCriteria[propertyName] || [];
  const getAdditional = propertyName => searchCriteria[propertyName] || [];

  const excludeComp = comp => {
    const ex = exclusions;
    // first lets check if in additional, if it is, we can just delete from there.
    const add = additional;
    if (add.indexOf(comp) !== -1) {
      add.splice(add.indexOf(comp), 1);
      setAdditional([...add]);
      return;
    }

    if (ex.indexOf(comp) === -1) {
      ex.push(comp);
    }
    setExclusions([...ex]);
  };

  const addComp = comp => {
    const add = additional;
    const ex = exclusions;
    if (ex.indexOf(comp) !== -1) {
      ex.splice(add.indexOf(comp), 1);
      setExclusions([...ex]);
      return;
    }
    if (add.indexOf(comp) === -1) {
      add.push(comp);
    }
    setAdditional([...add]);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setSelectedProperty(null);
  };

  return (
    <div className={`${styles.propertyArea} ${className}`}>
      {!isManual ? (
        <AdrSection
          onChange={onChange}
          partialData={partialData}
          partialLoading={partialLoading}
          searchData={searchData}
          exclusions={exclusions}
          setExclusions={setExclusions}
          additional={additional}
          setAdditional={setAdditional}
          getExclusions={getExclusions}
          getAdditional={getAdditional}
          excludeComp={excludeComp}
          addComp={addComp}
          setSelectedProperty={setSelectedProperty}
          setIsModalOpen={setIsOpen}
        />
      ) : (
        <ManualArea
          partialData={partialData}
          partialLoading={partialLoading}
          searchData={searchData}
          onChange={onChange}
          exclusions={exclusions}
          setExclusions={setExclusions}
          additional={additional}
          setAdditional={setAdditional}
          getExclusions={getExclusions}
          getAdditional={getAdditional}
          excludeComp={excludeComp}
          addComp={addComp}
          setSelectedProperty={setSelectedProperty}
          setIsModalOpen={setIsOpen}
        />
      )}
      <Modal isOpen={isOpen} onClose={onCloseModal} customStyle={{ maxHeight: '95vh' }}>
        <Property property={selectedProperty} hideActions />
      </Modal>
    </div>
  );
};
