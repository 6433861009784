import React from 'react';
import { SlideContainer } from '../../../../../Slide.styles';
import { HomeStagingDescription } from '../HomeStagingDescription/HomeStagingDescription.component';
import { HomeStagingGraph } from '../HomeStagingGraph/HomeStagingGraph.component';
import { LeftCol, RightCol, SourceText } from './ImportanceOfStaging.styles';

import { data, heading, text, source, list } from '../../data';

export const ImportanceOfStagingModern: React.FC = () => (
  <SlideContainer>
    <LeftCol>
      <HomeStagingDescription text={text} list={list} />
    </LeftCol>
    <RightCol>
      <HomeStagingGraph graphData={data} heading={heading} />
    </RightCol>
    <SourceText>Source: {source}</SourceText>
  </SlideContainer>
);
