import * as FSLib from '@fullstory/browser';
import { TUser } from '../../types';
import { Environment } from '../../services/DevelopmentUtilities/Environment';

export class FullStory {
  static init() {
    FSLib.init({ orgId: 'o-1P34EV-na1', devMode: !Environment.isProduction() });
  }

  static identify(user: TUser | null): void {
    if (!user?.userId) return;

    FSLib.FullStory('setIdentity', {
      uid: String(user.kvcoreUserId),
      properties: { ...user, displayName: user.name },
    });
  }
}
