export default `<svg width="623" height="339" viewBox="0 0 623 339" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path d="M-41 -37.502L608 -37.502L489 170.498L-41 313.498L-41 -37.502Z" fill="#027B76"/>
<path d="M-41 -37.502L608 -37.502L489 170.498L-41 313.498L-41 -37.502Z" fill="white" fill-opacity="0.9"/>
</g>
<defs>
<filter id="filter0_d" x="-56" y="-42.502" width="679" height="381" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="10"/>
<feGaussianBlur stdDeviation="7.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
`;
