import React from 'react';
import { THEMES } from '../../../../../../../constants/themes';
import { ModernOneCover } from './ModernOneCover/ModernOneCover.component';
import { ModernTwoCover } from './ModernTwoCover/ModernTwoCover.component';
import { ModernDefaultCover } from './ModernDefaultCover/ModernDefaultCover.component';
import { TSlideThemeModern } from '../../../../../components/SlideFrame/frames/web/Modern';

interface ModernCoverProps {
  theme: TSlideThemeModern;
}

export const ModernCover: React.FC<ModernCoverProps> = ({ theme, children }) => {
  const coverMap: Record<TSlideThemeModern, React.FC> = {
    [THEMES.MODERN.DEFAULT]: ModernOneCover,
    [THEMES.MODERN.ALPHA]: ModernDefaultCover,
    [THEMES.MODERN.GAMA]: ModernTwoCover,
  };

  const Component: React.FC<ModernCoverProps> = coverMap[theme];
  if (!Component) return null;

  return <Component theme={theme}>{children}</Component>;
};
