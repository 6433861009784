import React, { createContext, useContext, useState, useCallback } from 'react';

import { TSlide } from '../../../types';
import { TTemplateGroup } from '../../../components/Slide/dynamic/types';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { useSelectedSlide } from '../../../providers/providers/SelectedSlideProvider';
import { useActiveSection } from '../../../components/Slide/providers/ActiveSectionProvider';
import { DynamicSlidesDefaultConfigProvider } from '../../../components/Slide/dynamic/DynamicSlidesDefaultConfigProvider';
import { useDynamicSlides } from '../../../hooks/dynamicSlides/useDynamicSlides';

interface Props {
  modalOpen: boolean;
  activeGroup: TTemplateGroup;
  editing: null | TSlide;
  openModal: () => void;
  closeModal: () => void;
  editSlide: (slideId: string) => void;
  selectGroup: (group: TTemplateGroup) => void;
}

const getInitGroup = () => {
  const configs = DynamicSlidesDefaultConfigProvider.getConfigs();
  const [firstGroupId] = configs.orderById;

  return DynamicSlidesDefaultConfigProvider.getConfigs().groups[firstGroupId].id;
};

const getSlideGroup = (templateId: string) => {
  const { orderById, groups } = DynamicSlidesDefaultConfigProvider.getConfigs();
  const slideGroup = orderById.find(groupId =>
    groups[groupId].templates.some(template => template.config.template === templateId),
  );
  return slideGroup;
};

const CreateSlidePropsContext = createContext<Props | undefined>(undefined);

export const CreateSlidePropsProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { setSelectedSlide, setSlideDataProperty } = useSelectedSlide();
  const { setActiveSection } = useActiveSection();
  const [activeGroup, setActiveGroup] = useState(getInitGroup());
  const [editing, setEditing] = useState<TSlide | null>(null);

  const { hash } = useUser();
  const { data } = useDynamicSlides(hash);

  const closeModal = useCallback(() => {
    setOpen(false);
    setEditing(null);
  }, []);

  const editSlide = useCallback(
    (slideId: string) => {
      if (slideId && data) {
        const slideToEdit = data && data.find(slide => slide.slide.id === slideId);
        if (slideToEdit) {
          setSelectedSlide(slideToEdit.slide);
          setActiveSection(slideToEdit.section);
          setSlideDataProperty('headline', slideToEdit.slide.data?.headline);
          const template = getSlideGroup(slideToEdit.slide.template!);
          if (template) setActiveGroup(template);
          setEditing(slideToEdit.slide);
          setOpen(true);
        }
      }
    },
    [data, setActiveSection, setSelectedSlide, setSlideDataProperty],
  );

  const openModal = () => {
    const config = DynamicSlidesDefaultConfigProvider.getTemplateConfig('oneImageLayoutAlpha');
    setSelectedSlide(config);
    setActiveGroup(getInitGroup());
    setOpen(true);
    setEditing(null);
  };

  const selectGroup = (group: TTemplateGroup) => setActiveGroup(group);

  return (
    <CreateSlidePropsContext.Provider
      value={{
        modalOpen: open,
        activeGroup,
        editing,
        closeModal,
        openModal,
        editSlide,
        selectGroup,
      }}
    >
      {children}
    </CreateSlidePropsContext.Provider>
  );
};

export const useCreateSlideProps = (): Props => {
  const context = useContext(CreateSlidePropsContext);

  if (!context) {
    throw new Error('useCreateSlideProps must be used within a CreateSlidePropsContext');
  }

  return context;
};
