import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { SingleFramelessVideo } from './SingleFramelessVideo';
import { SingleFramelessVideoForm } from './SingleFramelessVideoForm';
import { TDynamicSlideTypeMapping } from '../../../types';

import thumbnail from './thumbnail.png';

const template = 'singleFramelessVideo';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - Single video only',
  frame: false,
  data: {
    videoUrl: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: SingleFramelessVideoForm,
  web: SingleFramelessVideo,
  pdf: null,
};

export type TSingleFramelessVideoTemplate = typeof template;

export { config, mappings, thumbnail };
