import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper, ImagePreview } from './OneImageGamma.styles';

interface Props {
  reverse?: boolean;
}

export const OneImageGamma: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();

  return (
    <Container style={{ flexDirection: reverse ? 'column-reverse' : 'column' }}>
      <SlideTextBlock
        isEditable={false}
        text={slide.data?.text}
        headline={slide.data?.headline}
        containerStyles={{ marginBottom: reverse ? 0 : 40, height: '46%' }}
      />
      <ImageWrapper style={{ marginBottom: !reverse ? 0 : 40, height: '48%' }}>
        <ImagePreview src={slide.data?.image} />
      </ImageWrapper>
    </Container>
  );
};
