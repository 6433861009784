export const getUtcDateDiffDays = (date1: number, date2: number): number => {
  const d1 = new Date(date1 * 1000).getTime();
  const d2 = new Date(date2 * 1000).getTime();

  const diff = d1 > d2 ? d1 - d2 : d2 - d1;

  return diff / (1000 * 3600 * 24);
};

const MS_PER_DAY = 1000 * 60 * 60 * 24;
export const getDateDiffDays = (date1: Date, date2: Date): number => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const greaterTime = d1 > d2 ? d1 : d2;
  const lowerTime = greaterTime === d1 ? d2 : greaterTime;

  const utc1 = Date.UTC(greaterTime.getFullYear(), greaterTime.getMonth(), greaterTime.getDate());
  const utc2 = Date.UTC(lowerTime.getFullYear(), lowerTime.getMonth(), lowerTime.getDate());
  return Math.floor((utc1 - utc2) / MS_PER_DAY);
};
