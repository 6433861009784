export default `
<svg width="1432" height="852" viewBox="0 0 1432 852" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M263.463 432.073L371.268 490.122" stroke="#444444" stroke-width="3.90244"/>
<path d="M917.61 228.658L880.537 316.951" stroke="#444444" stroke-width="3.90244"/>
<path d="M1167.37 361.341L1046.39 447.195" stroke="#444444" stroke-width="3.90244"/>
<path d="M1178.59 642.317L1121.51 649.146" stroke="#444444" stroke-width="3.90244"/>
<path d="M324.927 693.536H260.049" stroke="#444444" stroke-width="3.90244"/>
<circle cx="725.737" cy="686.542" r="401.298" stroke="#027B76" stroke-width="12.6829"/>
<circle cx="158.586" cy="371.098" r="120" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="131.268" cy="693.049" r="128.78" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="968.342" cy="122.316" r="120" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="1265.41" cy="288.659" r="122.439" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="1304.93" cy="627.683" r="124.878" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="3.90244"/>
</svg>
`;
