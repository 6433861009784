import {
  PROPERTY_STATUS,
  PropertyGroups,
  TProperty,
  TablePDFAverageData,
  TablePDFPageData,
  TablePDFPropertiesPage,
  TablePropertyStatus,
} from '../../../../types';
import { calculateAverageRow } from './averageRowCalculations';

const hasSpaceOnPage = (rowsOnPage: number, tablesOnPage: number, pageNumber: number) => {
  if (tablesOnPage === 0) {
    return true;
  }

  if (tablesOnPage === 1) {
    const maxFirstTableSize = pageNumber === 1 ? 10 : 12;
    return rowsOnPage < maxFirstTableSize;
  }

  if (tablesOnPage === 2) {
    const maxTwoTablesSize = pageNumber === 1 ? 9 : 10;
    return rowsOnPage < maxTwoTablesSize;
  }
};

const getNumberOfRowsToAddOnPage = (
  tablesOnPage: number,
  rowsOnPage: number,
  rowsToAdd: number,
  pageNumber: number,
) => {
  if (!hasSpaceOnPage(rowsOnPage, tablesOnPage, pageNumber)) {
    return 0;
  }

  let maxRowsToAdd = 0;

  if (tablesOnPage === 0) {
    maxRowsToAdd = pageNumber === 1 ? 12 : 13;
  }

  if (tablesOnPage === 1) {
    const twoTablesOnSamePageMaxRows = pageNumber === 1 ? 10 : 12;
    maxRowsToAdd = twoTablesOnSamePageMaxRows - rowsOnPage;
  }

  if (tablesOnPage === 2) {
    const threeTablesOnSamePageMaxRows = pageNumber === 1 ? 9 : 10;
    maxRowsToAdd = threeTablesOnSamePageMaxRows - rowsOnPage;
  }

  return rowsToAdd < maxRowsToAdd ? rowsToAdd : maxRowsToAdd;
};

const getNumberOfTablesOnPage = (page: Partial<TablePDFPageData>) => Object.keys(page).length;
const getCurrentPageNumber = (pages: TablePDFPropertiesPage) => Object.keys(pages).length;
const getCurrentPage = (pages: TablePDFPropertiesPage) => pages[getCurrentPageNumber(pages)];
const getRowsOnPage = (page: Partial<TablePDFPageData>) => {
  let rows = 0;

  Object.values(page).forEach(table => {
    if (table.rows && table.rows.length) {
      rows += table.rows.length;
    }

    if (table.average) {
      rows++;
    }
  });

  return rows;
};

const addToPages = (
  pages: TablePDFPropertiesPage,
  properties: TProperty[],
  type: TablePropertyStatus,
) => {
  if (!properties.length) {
    return;
  }

  const currentPage = getCurrentPage(pages);
  const currentPageNumber = getCurrentPageNumber(pages);

  const tablesOnPage = getNumberOfTablesOnPage(currentPage);
  const rowsOnPage = getRowsOnPage(currentPage);

  if (!hasSpaceOnPage(rowsOnPage, tablesOnPage, currentPageNumber)) {
    pages[currentPageNumber + 1] = {};
    addToPages(pages, properties, type);
    return;
  }

  const propertiesToAdd = getNumberOfRowsToAddOnPage(
    tablesOnPage,
    rowsOnPage,
    properties.length,
    currentPageNumber,
  );

  if (propertiesToAdd === 0) {
    pages[currentPageNumber + 1] = {};
    addToPages(pages, properties, type);
    return;
  }

  pages[currentPageNumber] = {
    ...pages[currentPageNumber],
    [type]: { rows: properties.splice(0, propertiesToAdd) },
  };

  if (properties.length) {
    addToPages(pages, properties, type);
  }
};

const appendAverageRow = (
  pages: TablePDFPropertiesPage,
  type: TablePropertyStatus,
  average: TablePDFAverageData | {},
) => {
  if (!(pages[getCurrentPageNumber(pages)] as TablePDFPageData)[type]) {
    return;
  }

  (pages[getCurrentPageNumber(pages)] as TablePDFPageData)[type].average = average;
};

function filterOutExcluded(properties?: TProperty[] | null) {
  return properties?.filter(p => p.excluded === false) ?? [];
}

export const splitOnPages = (properties: PropertyGroups, isDTC: boolean) => {
  const pages: TablePDFPropertiesPage = {
    1: {},
  };

  const sorting: Record<TablePropertyStatus, number> = {
    active: 3,
    pending: 2,
    sold: 1,
  };

  properties.active = filterOutExcluded(properties?.active) as TProperty[];
  properties.pending = filterOutExcluded(properties?.pending) as TProperty[];
  properties.sold = filterOutExcluded(properties?.sold) as TProperty[];

  Object.keys(properties)
    ?.sort((a, b) => sorting[b as TablePropertyStatus] - sorting[a as TablePropertyStatus])
    .forEach(type => {
      const average = calculateAverageRow(
        properties[type as keyof PropertyGroups] || [],
        type.toLowerCase() as TablePropertyStatus,
        isDTC,
      );
      addToPages(
        pages,
        properties[type as TablePropertyStatus] as TProperty[],
        type as TablePropertyStatus,
      );
      appendAverageRow(pages, type as TablePropertyStatus, average);
    });

  return pages;
};
