import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')({
  border: '0.5px solid #DDDDDD',
  margin: '20px 0',
  padding: '30px 33px',
  height: 'calc(100% - 40px)',
  position: 'relative',
  overflow: 'hidden',
});

export const GrayBg = styled('div')({
  height: '23%',
  width: '100%',
  position: 'absolute',
  backgroundColor: '#F6F6F6',
  bottom: 0,
  left: 0,
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '150%',
    paddingBottom: '100%',
    borderRadius: '50%',
    backgroundColor: '#F6F6F6',
    top: '-53%',
    left: '50%',
    transform: 'translateX(-50%)',
    border: '0.5px solid #DDDDDD',
  },
});

export const Title = styled('h2')(({ theme }) => ({
  fontSize: 26,
  textTransform: 'uppercase',
  color: useUiConfigColorsPrimary(),
  fontWeight: 900,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  fontFamily: useUiConfigFontsHeader(),
}));

export const Subtitle = styled('h4')(({ theme }) => ({
  fontSize: 18,
  textTransform: 'uppercase',
  color: '#000',
  marginTop: 7,
  fontWeight: 500,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const DescriptionWrapper = styled('div')(({ theme }) => ({
  marginTop: 20,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const DescriptionTitle = styled('h6')({
  fontSize: 16,
  textTransform: 'uppercase',
  fontWeight: 500,
  color: '#000',
  marginBottom: 8,
});

export const DescriptionText = styled('p')({
  fontSize: 16,
  fontWeight: 400,
  color: '#000',
  marginBottom: 15,
  lineHeight: 1.3,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const CardsContainer = styled('div')({
  marginTop: 72,
});

export const Cards = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 35,
  position: 'relative',
  zIndex: 1,
});

export const SourceText = styled('p')({
  fontSize: 12,
  fontWeight: 300,
  color: '#101010',
  textAlign: 'right',
  position: 'relative',
  zIndex: 1,
  marginTop: 9,
});
