import { TFrameConfig } from '../../../../../../../types';
import { THEMES } from '../../../../../../../constants/themes';
import ModernFrameAlpha, { TModernFrameAlpha } from './ModernFrameAlpha';
import ModernFrameBeta, { TModernFrameBeta } from './ModernFrameOne';
import ModernFrameGama, { TModernFrameGama } from './ModernFrameTwo';

export type TSlideThemeModern = TModernFrameAlpha | TModernFrameBeta | TModernFrameGama;

const modernConfig: Record<TSlideThemeModern, TFrameConfig> = {
  [THEMES.MODERN.ALPHA]: ModernFrameAlpha,
  [THEMES.MODERN.BETA]: ModernFrameBeta,
  [THEMES.MODERN.GAMA]: ModernFrameGama,
};

export default modernConfig;
