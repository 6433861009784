import { TEmphasisConfig, TEmphasisMeta } from '../types';

export class AppliedEmphasisExtractor {
  public static getBasedOnSectionsOrder(
    sectionsOrder: string[],
    emphasisConfigs?: TEmphasisConfig | null,
  ): TEmphasisMeta | null {
    if (!emphasisConfigs) return null;

    const serializedOrder = String(sectionsOrder);

    let usedConfig = null;
    emphasisConfigs.orderById.forEach(em => {
      if (!emphasisConfigs.configs[em]) return;

      if (String(emphasisConfigs.configs[em].order) === serializedOrder) {
        usedConfig = emphasisConfigs.configs[em];
      }
    });

    return usedConfig;
  }
}
