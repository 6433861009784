import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import { Icon, Typography } from './DynamicSection.styles';
import { TDynamicSection, TSection } from '../../../../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ExpandableStyles {
  root?: React.CSSProperties;
}

export interface OrderableSlidesComponentProps {
  section: TDynamicSection;
  open: boolean;
}

interface Props {
  open: boolean;
  section: TDynamicSection;
  styles?: ExpandableStyles;
  index: number;
  onToggle: (sectionId: TSection) => void;
  OrderableSlidesComponent: React.FC<OrderableSlidesComponentProps>;
}

export const DynamicSection: React.FC<Props> = ({
  open,
  section,
  styles,
  index,
  onToggle,
  OrderableSlidesComponent,
}) => {
  const style: React.CSSProperties = {};

  const isCoverSlidesSection = section?.id === 'startingSlides';

  return (
    <Draggable
      key={section.id}
      draggableId={section.id}
      index={index}
      isDragDisabled={['closingAndNextSteps', 'startingSlides'].includes(section.id)}
    >
      {(provided, snapshot) => (
        <Container
          style={styles?.root ? { ...styles.root, ...style } : style}
          ref={provided && provided.innerRef}
          {...provided?.draggableProps}
        >
          <Head onClick={() => onToggle(section.id as TSection)} {...provided?.dragHandleProps}>
            <TextWrapper>
              {!isCoverSlidesSection && <Label>Section</Label>}
              {section.label}
            </TextWrapper>
            <FontAwesomeIcon icon={['fas', 'caret-up']} rotation={open ? 180 : undefined} />
          </Head>

          <OrderableSlidesComponent section={section} open={open} />
        </Container>
      )}
    </Draggable>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  border-top: none;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
  font-weight: 700;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 20px 25px;
  cursor: pointer;
  flex-shrink: 0;
`;

const Label = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.gray[300]};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;
