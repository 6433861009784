import styled from 'styled-components';
import { useUiConfigFontsHeader } from '../../../../../../../providers/providers/UiConfigFontProvider';

export const PotentialBuyersHeader = styled('div')(({ theme }) => ({
  padding: '17px 0',
  color: '#000',
  textTransform: 'uppercase',
  fontSize: 14,
  borderBottom: '1px solid #DEDEDE',
  fontWeight: 500,
  letterSpacing: 1.5,
  fontFamily: useUiConfigFontsHeader(),
  textAlign: 'center',
  height: 52,
}));
