import React, { useContext, useState } from 'react';
import { PRESENTATION_TYPES } from '../../constants/PresentationTypes';
import { TPresentationType } from '../../types';

type PresentationTypeContextTypes = {
  presentationType: TPresentationType;
  setPresentationType: (value: TPresentationType) => void;
};
const PresentationTypeContext = React.createContext<PresentationTypeContextTypes | undefined>(
  undefined,
);

export const usePresentationType = () => {
  const context = useContext(PresentationTypeContext);

  if (!context) {
    throw new Error('usePresentationType cannot be used without PresentationTypeContext');
  }

  return context;
};

interface PresentationTypeProviderProps {
  initValue?: TPresentationType;
}

export const PresentationTypeProvider: React.FC<PresentationTypeProviderProps> = ({
  initValue,
  children,
}) => {
  const [presentationType, setPresentationType] = useState<TPresentationType>(
    initValue ?? PRESENTATION_TYPES.WIN_THE_LISTING,
  );

  const value = React.useMemo(
    () => ({
      presentationType,
      setPresentationType,
    }),
    [presentationType],
  );

  return (
    <PresentationTypeContext.Provider value={value}>{children}</PresentationTypeContext.Provider>
  );
};
