import styled from 'styled-components';
import { HeaderCard } from '../components/HeaderCard';
import { Separator } from '../components/Separator';
import { useAtomValue } from 'jotai';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { useMemo } from 'react';
import { extractPropertyGroups } from '../../services/extractPropertiesGroups';
import { PROPERTY_STATUS, TProperty } from '../../../../types';
import { PropertiesCountItem } from './PropertiesCountItem';
import { useUser } from '../../../../components/Slide/providers/UserProvider';

const getPropertiesCount = (properties?: TProperty[] | null) =>
  properties?.filter?.(p => !p?.excluded)?.length || 0;

export const WhatIsCMA = () => {
  const properties = useAtomValue(reportPropertiesAtom);
  const { isCanadaUser } = useUser();

  const propertiesTotalNum = useMemo(() => {
    return properties?.filter(p => !p.excluded)?.length;
  }, [properties]);

  const propertiesGroups = useMemo(() => extractPropertyGroups(properties), [properties]);

  return (
    <HeaderCard title='What is a Comparable Market Analysis'>
      <Container>
        <Description>
          A Comparable Market Analysis helps identify & analyze a property’s value based on sales of
          “comparable” homes and market trends.
        </Description>
        <Separator />
        <ComparableTitle>
          {propertiesTotalNum} total comparables were used in this analysis
        </ComparableTitle>
        <PropertiesCountWrapper>
          <PropertiesCountItem
            propertiesStatus={PROPERTY_STATUS.COMING_SOON}
            label='Coming Soon'
            count={getPropertiesCount(propertiesGroups.comingSoon)}
          />
          <PropertiesCountItem
            propertiesStatus={PROPERTY_STATUS.ACTIVE}
            label='Active'
            count={getPropertiesCount(propertiesGroups.active) || 0}
          />
          {!isCanadaUser && (
            <PropertiesCountItem
              propertiesStatus={PROPERTY_STATUS.PENDING}
              label='Pending'
              count={getPropertiesCount(propertiesGroups.pending) || 0}
            />
          )}
          <PropertiesCountItem
            propertiesStatus={PROPERTY_STATUS.SOLD}
            label='Closed'
            count={getPropertiesCount(propertiesGroups.sold) || 0}
          />
        </PropertiesCountWrapper>
      </Container>
    </HeaderCard>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Description = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: center;
  max-width: 376px;
`;

const ComparableTitle = styled.p`
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: center;
  text-transform: uppercase;
`;

const PropertiesCountWrapper = styled.div`
  display: flex;
  gap: 18px;
  margin: 10px 0;
`;
