export default `<svg width="754" height="745" viewBox="0 0 754 745" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="378.5" cy="371.485" rx="163.5" ry="163.507" fill="#fff"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M535.666 377.389L537.367 366.521L649.7 384.114L758.294 350.419L761.554 360.925L650.521 395.377L535.666 377.389Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-4.69043 381.554L-1.40544 392.052L107.108 358.096L219.482 375.418L221.158 364.546L106.26 346.835L-4.69043 381.554Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M371.981 528.243L382.849 529.945L365.256 642.282L398.95 750.881L388.444 754.141L353.993 643.103L371.981 528.243Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M382.931 212.978L382.931 -10.0312L371.931 -10.0312L371.931 212.978H382.931Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M262.658 477.641L269.139 486.53L177.268 553.524L124.306 654.141L114.572 649.017L168.724 546.14L262.658 477.641Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M641.802 92.5902L632.056 87.4895L579.335 188.234L487.626 255.449L494.129 264.322L587.897 195.597L641.802 92.5902Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M271.377 255.256L262.489 261.738L195.497 169.863L94.8845 116.898L100.008 107.164L202.881 161.319L271.377 255.256Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M656.411 634.416L661.511 624.67L560.771 571.947L493.559 480.234L484.686 486.737L553.408 580.51L656.411 634.416Z" fill="white"/>
<circle cx="378.5" cy="371.5" r="152.5" fill="#F2F2F2"/>
<path d="M421.522 328.65L429.79 334.849C430.303 335.234 430.72 335.733 431.006 336.307C431.293 336.88 431.442 337.513 431.442 338.154C431.442 338.796 431.293 339.428 431.006 340.002C430.72 340.576 430.303 341.075 429.79 341.46L421.522 347.659C420.271 348.6 410.147 343.971 410.147 343.971C410.147 343.971 409.951 346.534 409.565 347.276C408.741 348.86 406.388 348.851 404.795 348.048L398.485 344C398.485 344 397.652 346.54 397.167 347.276C396.55 348.214 395.317 348.489 394.244 348.486C393.603 348.487 392.97 348.337 392.397 348.048L386.794 344C386.794 344 385.368 346.519 384.772 347.273C384.091 348.13 382.951 348.486 381.849 348.483L375.2 348.486C373.297 352.214 370.402 355.344 366.834 357.532C363.266 359.72 359.163 360.881 354.978 360.887C348.951 360.879 343.173 358.482 338.912 354.22C334.65 349.959 332.253 344.181 332.245 338.154C332.253 332.128 334.65 326.35 338.912 322.088C343.173 317.827 348.951 315.429 354.978 315.421C359.163 315.427 363.266 316.587 366.834 318.775C370.403 320.963 373.297 324.094 375.2 327.823L419.044 327.82C419.938 327.819 420.808 328.111 421.522 328.65ZM342.93 338.154C342.935 341.348 344.206 344.41 346.464 346.668C348.722 348.926 351.784 350.197 354.978 350.202L354.978 326.107C351.784 326.112 348.722 327.382 346.464 329.641C344.206 331.899 342.935 334.961 342.93 338.154Z" fill="#4D4D4D"/>
<path d="M331.5 254.5L372 216V192C325.608 196.043 293.398 206.127 258 237L274.5 259.5L331.5 254.5Z" fill="url(#paint0_linear)"/>
<path d="M269.5 258H334L376 213.5" stroke="white" stroke-width="11"/>
<circle cx="29" cy="222" r="27" fill="white" stroke="#DDDDDD" stroke-width="4"/>
<circle cx="235" cy="716" r="27" fill="white" stroke="#DDDDDD" stroke-width="4"/>
<circle cx="527" cy="716" r="27" fill="white" stroke="#DDDDDD" stroke-width="4"/>
<circle cx="725" cy="222" r="27" fill="white" stroke="#DDDDDD" stroke-width="4"/>
<circle cx="518" cy="26" r="24" fill="#027B76" stroke="#DDDDDD" stroke-width="4"/>
<defs>
<linearGradient id="paint0_linear" x1="372" y1="207.5" x2="267.289" y2="249.48" gradientUnits="userSpaceOnUse">
<stop stop-color="#027B76"/>
<stop offset="1" stop-color="#027B76"/>
</linearGradient>
</defs>
</svg>
`;
