import React from 'react';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '../../../../components/Loader/Loader';
import { Button } from '../../../../components/Common/V2/Button/Button';
import Tooltip from '../../../../components/Common/Tooltip/Tooltip.component';
import {
  DashItemContainer,
  Wrapper,
  Text,
  ButtonsContainer,
  SavedIndicatorButton,
} from './DashboardItem.styles';

interface DashboardItemProps {
  icon: IconProp;
  text: string;
  buttonText?: string;
  loading?: boolean;
  onClick?: () => void;
  keepFontSize?: boolean;
  onRemoveClick?: () => void;
  hasRemoveButton?: boolean;
  isHighlighted?: boolean;
  hasSavedLabel?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

export const DashboardItem: React.FC<DashboardItemProps> = ({
  icon,
  text,
  buttonText,
  loading = false,
  onClick,
  keepFontSize,
  onRemoveClick,
  hasRemoveButton,
  isHighlighted,
  hasSavedLabel,
  disabled,
  tooltip,
}) => {
  const triggerClick = React.useCallback(() => {
    if (loading) return;

    onClick?.();
  }, [loading, onClick]);

  return (
    <DashItemContainer
      isButton={!buttonText}
      onClick={!buttonText ? triggerClick : undefined}
      isDisabled={disabled}
    >
      <Wrapper>
        <IconWrapper>{loading ? <Loader /> : <FontAwesomeIcon icon={icon} />}</IconWrapper>
        <Text isHighlighted={isHighlighted} isSmallFont={!!buttonText && !keepFontSize}>
          {text}
        </Text>
      </Wrapper>
      <ButtonsContainer>
        {hasRemoveButton && (
          <Button onClick={onRemoveClick} variant='secondary'>
            Remove
          </Button>
        )}
        {buttonText && (
          <Button onClick={onClick} variant='primary' size='small'>
            {buttonText}
          </Button>
        )}

        {hasSavedLabel && <SavedIndicatorButton variant='clear'>Saved</SavedIndicatorButton>}
        {tooltip && (
          <Tooltip text={tooltip}>
            <FontAwesomeIcon icon={['fas', 'info-circle']} />
          </Tooltip>
        )}
      </ButtonsContainer>
    </DashItemContainer>
  );
};

const IconWrapper = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
`;
