import { useStore } from 'react-redux';

import { TPresentationType } from '../../../types';
import { useUpdateReport } from './useUpdateReport';

export const useRefreshBuild = () => {
  const updateReport = useUpdateReport();

  const getState = useStore().getState;

  const refreshBuild = (presentationType: TPresentationType, presentationId: string) => {
    const { result } = getState();
    const excludedIds = result?.excluded || [];

    return updateReport({
      excluded_ids: excludedIds,
      presentation_type: presentationType,
      presentation_id: presentationId,
    });
  };

  return refreshBuild;
};
