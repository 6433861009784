import React from 'react';

import { Container } from './Navigation.styles';
import { NavItemComponent } from './NavItem.component';
import { PresentationNavItem } from '../../../../types';

type Props = {
  navItems: PresentationNavItem[];
};

export const NavigationComponent: React.FC<Props> = ({ navItems }) => (
  <Container>
    {navItems.map(item => (
      <NavItemComponent key={item.label} {...item} />
    ))}
  </Container>
);
