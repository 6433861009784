import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MediaApi } from '../../../../../api/MediaApi';
import { toBase64 } from '../../../../../services/toBase64';
import { Button } from '../../../../../components/Common/V2/Button/Button';
import { FilePicker, FitContentModal } from '../../../../../components/Common';
import { Typography } from '../../../../../components/Slide/components/SlideFrame/components/BrokerInfo/BrokerInfo.styles';

const ModalContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  flex: 1,
});

const ImageSelectedContainer = styled.div({
  display: 'flex',
  gap: 20,
  maxWidth: 680,
});

const Col = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const Image = styled('img')({
  width: '100%',
  height: 'auto',
  marginBottom: 8,
});

const RecommendationBanner = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.colors.v2.gray[400],
  color: theme.colors.v2.text.contrastText,
  padding: '10px 15px',
}));

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  fontSize: 14,
});

const NoImgSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 10px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-size: 14px;
  font-weight: 500;
`;

interface Props {
  open: boolean;
  onClose: () => void;
  setConfig: (imageUrl?: string) => void;
}

export const UploadImageModal: React.FC<Props> = ({ open, onClose, setConfig }) => {
  const [file, setFile] = useState<File | null>(null);
  const [imgSrc, setImgSrc] = useState('');
  const [loading, setLoading] = useState(false);

  const cleanUp = () => {
    setFile(null);
    setImgSrc('');
    setLoading(false);
  };

  const handleClose = () => {
    setConfig();
    cleanUp();
    onClose();
  };

  useEffect(() => {
    if (file) {
      toBase64(file).then(base64img => setImgSrc(base64img));
    } else {
      if (imgSrc) setImgSrc('');
    }
  }, [file, imgSrc]);

  const onUsePhoto = async () => {
    if (!file) return;
    setLoading(true);
    try {
      const imageUrl = await MediaApi.upload(file);
      setConfig(imageUrl);
      cleanUp();
      onClose();
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const content = file ? (
    <ImageSelectedContainer>
      <Col>
        <Image src={imgSrc} />
        <Typography color='400' variant='body2'>
          {file.name}
        </Typography>
      </Col>
      <Col style={{ flexShrink: 1 }}>
        Select a Different Cover Photo
        <Button
          variant='primary'
          disabled={loading}
          onClick={() => setFile(null)}
          style={{ marginTop: 10 }}
        >
          Select a File
        </Button>
      </Col>
    </ImageSelectedContainer>
  ) : (
    <>
      <RecommendationBanner>
        <Wrapper>
          <FontAwesomeIcon icon={['fas', 'info-circle']} />
          Photos with a resolution of 1920x790 pixels or an aspect ratio of 17:7 display best.
        </Wrapper>
      </RecommendationBanner>

      <NoImgSelectedContainer>
        Select a Cover Photo
        <FilePicker accept='image/*' setFile={setFile}>
          <Button variant='primary'>Select a File</Button>
        </FilePicker>
      </NoImgSelectedContainer>
    </>
  );

  return (
    <FitContentModal
      headerTitle='Upload Cover Photo'
      isOpen={open}
      hasCancelBtn
      onClose={handleClose}
      onConfirmClick={onUsePhoto}
      disabled={loading || !imgSrc}
      confirmBtnText='Use Photo'
      contentDisplay='flex'
    >
      <ModalContentWrapper data-test='23'>{content}</ModalContentWrapper>
    </FitContentModal>
  );
};
