import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { Value } from '../../components/Value';
import { Label } from '../../components/Label';
import { SectionTitle } from '../../components/SectionTitle';
import { PROPERTY_STATUS, TProperty } from '../../../../../types';
import { useComparableExtensions } from './useComparableExtensions';
import { AddComparableExtension } from './AddComparableExtension/AddComparableExtension';
import { isPresentationViewAtom } from '../../../../../features/report/state/isPresentationViewAtom';
import { MoneyFormatter } from '../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import { ComparableExtensionList } from './ComparableExtensionList';

interface Props {
  property: TProperty;
  hideActions?: boolean;
}

export const PriceAdjustments = ({ property, hideActions }: Props) => {
  const hasCaFF = useHasFeatureFlag(FEATURES.CpComparableAdjustments);
  const { data: compExtensions } = useComparableExtensions(property.id);

  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const totalAdjustments = useMemo(() => {
    return compExtensions?.reduce((acc, extension) => acc + extension.value, 0) ?? 0;
  }, [compExtensions]);

  const adjustedPrice = useMemo(() => {
    if (!compExtensions?.length) return;

    if (property.status === PROPERTY_STATUS.SOLD) {
      return property.salePrice + totalAdjustments;
    }

    return property.price + totalAdjustments;
  }, [
    compExtensions?.length,
    property.price,
    property.salePrice,
    property.status,
    totalAdjustments,
  ]);

  const adjustedPriceLabel = useMemo(() => {
    if (property?.status === PROPERTY_STATUS.SOLD) return 'ADJUSTED SOLD PRICE';

    return 'ADJUSTED  PRICE';
  }, [property?.status]);

  if (!hasCaFF || (isPresentationView && !compExtensions?.length)) return null;

  const showAddComparableExtension = !isPresentationView && !hideActions;

  return (
    <Container>
      <SectionTitle>PRICE ADJUSTMENTS (ADJ)</SectionTitle>
      {showAddComparableExtension && (
        <AddComparableExtension propertyId={property.id} comparableExtensions={compExtensions} />
      )}

      {!showAddComparableExtension && (
        <ComparableExtensionList
          comparableExtensions={compExtensions}
          propertyId={property.id}
          hideActions={hideActions}
        />
      )}

      <TotalResultContainer style={{ paddingTop: isPresentationView ? 8 : 15 }}>
        <TotalResults>
          <Label>TOTAL ADJUSTMENTS</Label>
          <Value>{MoneyFormatter.format(totalAdjustments)}</Value>
        </TotalResults>
        <TotalResults style={{ marginTop: '12px' }}>
          <Label style={{ fontWeight: 700 }}>{adjustedPriceLabel}</Label>
          <Value>{MoneyFormatter.format(adjustedPrice)}</Value>
        </TotalResults>
      </TotalResultContainer>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 10px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
`;

const TotalResultContainer = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
`;

const TotalResults = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
