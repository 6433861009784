import * as React from 'react';

import { TPresentation, TUser } from '../types';
import { PresentationSsr } from './PresentationSsr';
import { BuyerTourSsr } from '../features/BuyerTour';
import { SsrLayout } from '../layouts/SsrLayout/SsrLayout';
import { PRESENTATION_TYPES } from '../constants/PresentationTypes';
import { ServerStoreProvider } from '../store/server/ServerStoreProvider';

const presentationSsrComponentByTypeMap = {
  [PRESENTATION_TYPES.PRESENT_INFO]: PresentationSsr,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: PresentationSsr,
  [PRESENTATION_TYPES.WIN_THE_LISTING]: PresentationSsr,
  [PRESENTATION_TYPES.WIN_THE_OFFER]: PresentationSsr,
  [PRESENTATION_TYPES.BUYER_TOUR]: BuyerTourSsr,
};

interface Props {
  data: TPresentation;
  user: TUser;
}

const SsrComponent: React.FC<Props> = ({ data, user }) => {
  if (!data?.type) return null;
  const Component = presentationSsrComponentByTypeMap[data.type];

  return !Component ? null : (
    <ServerStoreProvider initState={data.reportData}>
      <SsrLayout>
        <Component presentation={data as any} user={user} />
      </SsrLayout>
    </ServerStoreProvider>
  );
};

export default SsrComponent;
