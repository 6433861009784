import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { UserApi } from '../api/UserApi';
import { TApiUser } from '../types';

const fetchAuthMe = async (): Promise<TApiUser> => {
  const apiUser = await UserApi.fetch();
  return apiUser;
};

export function useAuthMe(fetch?: boolean): UseQueryResult<TApiUser> {
  return useQuery(['user'], () => fetchAuthMe(), {
    enabled: Boolean(fetch),
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}
