export const pricingRateOfChangeData = {
  heading: 'Pricing rate of Change',
  value: '2%',
  subtitle: 'Pricing Rate of Change',
  description: [
    {
      title: 'WHAT IS PRICING RATE OF CHANGE?',
      text: 'Pricing rate of change is the difference between the list price at the start of escrow and the sold price at the close of escrow.',
    },
    {
      title: 'How is pricing rate of change used in a price analysis?',
      text: 'The pricing rate of change can help determine momentum and gauge the possibility of a multiple offer scenario. Given that the data’s crux is rooted in sold data, it is imperative to remember what has happened and does not necessarily represent recent trends or what might currently be happening in the active market.',
    },
  ],
};
