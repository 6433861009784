import { PropertyGroups, TProperty } from '../../../types';

export const extractExcluded = (propertyGroups: PropertyGroups) => {
  const properties = Object.values(propertyGroups)
    .flatMap(group => group)
    .filter(property => property?.excluded)
    .map(property => property?.id);

  return properties.filter(Boolean) as TProperty['id'][];
};
