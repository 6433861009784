import React from 'react';
import styled from 'styled-components';

import { CATEGORIES } from '../../../../../../constants/categories';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { ScalePDF } from '../../../../../../features/report/scale-overview/scale/pdf/ScalePDF';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const ScaleWrapper = styled.div`
  flex-grow: 1;
`;

interface ScalePdfProps {
  isPricePerSqftMode?: boolean;
  isAdjustedPricePlotBy?: boolean;
}

export const ScalePdf: React.FC<ScalePdfProps> = ({
  isPricePerSqftMode,
  isAdjustedPricePlotBy,
}) => {
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <ScaleWrapper>
          <ScalePDF
            pricePerSqftMode={isPricePerSqftMode}
            isAdjustedPricePlotBy={isAdjustedPricePlotBy}
          />
        </ScaleWrapper>
      </Container>
    </PdfFrame>
  );
};
