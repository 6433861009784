import { TPresentationSessionRatings } from '../../../../../../../api/TrackingApi';
import { TProperty } from '../../../../../../../types';

export const RATINGS: {
  [key: string]: { value: number; key: string; label: string; color: string };
} = {
  NOT_INTERESTED: {
    value: 1,
    key: 'NOT_INTERESTED',
    label: '1 - Not Interested',
    color: '#ED0D21',
  },
  CONSIDERING: {
    value: 2,
    key: 'CONSIDERING',
    label: '2 - Considering',
    color: '#444',
  },
  MAKE_AN_OFFER: {
    value: 3,
    key: 'MAKE_AN_OFFER',
    label: '3 - Make an Offer',
    color: '#89C541',
  },
};

export const formatBTAnalyticsTableData = (
  properties?: TProperty[],
  ratings?: TPresentationSessionRatings[],
) => {
  return (properties || []).map(property => {
    const foundRating = ratings?.find(rating => rating?.property_id === property?.id)
      ?.property_rating;

    const rating = foundRating ? RATINGS[foundRating].value : RATINGS.NOT_INTERESTED.value;

    return {
      image: property?.images?.[0],
      propertyPrice: property?.price,
      propertyDetails: `${property?.beds} beds, ${property?.baths} baths, ${property?.size} sqft`,
      propertyAddress: `${property?.address?.deliveryLine}, ${property?.address?.city}`,
      propertyRating: rating,
    };
  });
};

export const formatPrice = (price: number | string) => {
  const convertedPrice = Number(price);
  return '$' + convertedPrice.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
