import styled from 'styled-components';

export const AddClientContainer = styled('div')({
  marginTop: 34,
});

export const AddClientTitle = styled('h6')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 8,
  color: theme.colors.gray[400],
}));

export const AddClientManuallyLink = styled('div')(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.primary.main,
  marginTop: 10,
  cursor: 'pointer',
}));
