import styled from 'styled-components';

type WithImage = {
  hasImage?: boolean;
};

export const PropertyInfoContainer = styled('div')<WithImage>(({ hasImage }) => ({
  width: hasImage ? '60%' : '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: hasImage ? 'space-between' : 'center',
  alignItems: hasImage ? 'flex-start' : 'center',
  height: '100%',
}));

export const PropertyText = styled('h3')<WithImage>(({ hasImage }) => ({
  fontSize: hasImage ? 20 : 18,
  fontWeight: 300,
  color: '#000',
  textAlign: 'start',
}));

export const PropertyFeaturesContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const PropertyFeature = styled(PropertyText)<WithImage>(({ hasImage }) => ({
  fontSize: 20,
  fontWeight: hasImage ? 300 : 400,
  marginRight: 10,
  '&:last-of-type': {
    marginRight: 0,
  },
}));

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
