import React from 'react';
import { Input } from '../../../../../../../components/Common/V2/Input';
import { FlexWrapper, InputWrapper, Label } from './styles';

interface Props {
  value: string;
  onChange: (val: string) => void;
}

const SlideName: React.FC<Props> = ({ value, onChange }) => {
  const onChangeInner = React.useCallback(
    function onChangeInner(e: React.ChangeEvent<HTMLInputElement>) {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <FlexWrapper>
      <Label>Add slide name</Label>
      <InputWrapper>
        <Input
          value={value}
          onChange={onChangeInner}
          wrapperStyle={{ width: '100%' }}
          inputWrapperStyle={{ width: '100%' }}
        />
      </InputWrapper>
    </FlexWrapper>
  );
};

export { SlideName };
