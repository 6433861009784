import React from 'react';

import { CATEGORIES } from '../../../../../../../../constants/categories';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import Logos from '../../../../components/Logos/Logos';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import { HeaderContainer, SlideContentWrapper } from './TraditionalFrameGama.styles';
import { TraditionalTwoBackground } from '../../../../../TraditionalTwoBackground/TraditionalTwoBackground.component';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { CommonFrameProps } from '../../../../../../../../types';

const TraditionalFrameGama: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => {
  return (
    <TraditionalTwoBackground>
      <PlacementAwareContainer infoPlacement={infoPlacement} style={{ background: 'transparent' }}>
        <HeaderContainer>
          <BrokerInfo
            width='33.333%'
            nameFontStyles={{ fontSize: 18 }}
            contactFontStyles={{ fontSize: 14 }}
            fontColor='#000'
            imageSize={82}
          />
          {!hideProgressTracker && (
            <ProgressTracker
              category={CATEGORIES.TRADITIONAL}
              fontColor='#000'
              containerStyles={{ transform: 'translate(-50%, -32px)' }}
            />
          )}
          <Logos />
        </HeaderContainer>
        <TocPortalContainer offset={-28} zIndex={20} />

        <SlideContentWrapper>{children}</SlideContentWrapper>
      </PlacementAwareContainer>
    </TraditionalTwoBackground>
  );
};

export default TraditionalFrameGama;
