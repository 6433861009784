import { ReactNode } from 'react';
import styled from 'styled-components';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';

interface Props {
  title: string;
  icon?: ReactNode;
  description: string;
}

export const CriteriaReviewItem = ({ title, icon, description }: Props) => {
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  return (
    <Container hasBathCountFF={hasBathCountFF}>
      {!hasBathCountFF && <div>{icon}</div>}
      <DetailsContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled.div<{ hasBathCountFF: boolean }>`
  display: flex;

  ${({ hasBathCountFF, theme }) =>
    hasBathCountFF
      ? `
    padding: 9px 0px;
    background-color: ${theme.colors.v2.gray[100]};

    &:first-of-type {
      border-radius: 5px 5px 0 0;
    }
    &:nth-of-type(2) {
      border-radius: 0 0 0 5px;
    }
    &:last-of-type {
      border-radius: 0 0 5px 0;
    }
  `
      : `
    padding: 10px 25px;
    gap: 20px;
    align-items: center;
    &:not(:last-of-type) {
      border-bottom: 1px solid #fff;
    }
  `}
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: center;
  margin-top: 2px;
`;
