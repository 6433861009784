import { CATEGORIES } from '../../../../../constants/categories';
import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { WhyIAmTheRightBuyersAgentLuxuryPDF } from './pdf/luxury/WhyIAmTheRightBuyersAgentLuxuryPDF';
import { WhyIAmTheRightBuyersAgentTraditionalPDF } from './pdf/traditional/WhyIAmTheBuyersAgentTraditionalPDF';
import { WhyIAmTheRightBuyersAgentTraditional } from './web/traditional/WhyIAmTheRightBuyersAgentTraditional';
import { WhyIAmTheRightBuyersAgentModernPDF } from './pdf/modern/WhyIAmTheRightBuyersAgentModernPDF';
import { WhyIAmTheRightBuyersAgentLuxury } from './web/luxury/WhyIAmTheRightBuyersAgentLuxury';
import { WhyIAmTheRightBuyersAgentModern } from './web/modern/WhyIAmTheRightBuyersAgentModern';

const id = 'whyIAmTheRightBuyersAgent';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Why I am the Right Buyer’s Agent',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: WhyIAmTheRightBuyersAgentTraditional,
    [CATEGORIES.MODERN]: WhyIAmTheRightBuyersAgentModern,
    [CATEGORIES.LUXURY]: WhyIAmTheRightBuyersAgentLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: WhyIAmTheRightBuyersAgentTraditionalPDF,
    [CATEGORIES.MODERN]: WhyIAmTheRightBuyersAgentModernPDF,
    [CATEGORIES.LUXURY]: WhyIAmTheRightBuyersAgentLuxuryPDF,
  },
};

export type TWhyIAmTheRightBuyersAgent = typeof id;
export { config, mappings };
