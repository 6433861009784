import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useAtom, useSetAtom } from 'jotai';

import styles from './CompResults.module.scss';
import { useResultRebuildStateSetter } from './useResultRebuildStateSetter';

import { PropertyModal } from '../../../../../../../features/report/components/PropertyModal/PropertyModal';

import { isPropertyModalOpenAtom } from '../../../../../../../features/report/state/isPropertyModalOpenAtom';
import { useSyncStateWithRedux } from '../../../../../../../features/report/hooks/useSyncStateWithRedux';
import { ResultsHeader } from '../../../../../../../features/report/results-header/ResultsHeader';
import { ScaleOverviewReport } from '../../../../../../../features/report/scale-overview/ScaleOverviewReport';
import { useSetDefaultHighlights } from '../../../../../../../features/report/hooks/useSetDefaultHighlights';
import { MarketAssessmentReport } from '../../../../../../../features/report/market-assessment/MarketAssessmentReport';
import { useSetDefaultCategories } from '../../../../../../../features/report/hooks/useSetDefaultCategories';
import { customEstimatesAtom } from '../../../../../../../features/report/state/customEstimatesAtom';

export const CompResultsComponent = ({ rebuildData, reportDetailed }) => {
  const setResultRebuildState = useResultRebuildStateSetter();
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useAtom(isPropertyModalOpenAtom);
  const setCustomEstimates = useSetAtom(customEstimatesAtom);
  const setDefaultHighlights = useSetDefaultHighlights();
  const setDefaultCategories = useSetDefaultCategories();

  useSyncStateWithRedux();

  useEffect(() => {
    window.scrollTo(0, 0);
    setCustomEstimates([]);

    if (Object.entries(rebuildData) && undefined !== rebuildData.request) {
      setResultRebuildState(reportDetailed);
    } else {
      setDefaultHighlights(reportDetailed);
      setDefaultCategories(reportDetailed);
    }
  }, []);

  return (
    <>
      <ResultsHeader />
      <div className={styles.compResults}>
        <ScaleOverviewReport />
        <MarketAssessmentReport />

        <PropertyModal
          isModalOpen={isPropertyModalOpen}
          onCloseModal={() => setIsPropertyModalOpen(false)}
        />
      </div>
    </>
  );
};

const stateToProps = ({ report }) => ({
  rebuildData: report.rebuildData,
  reportDetailed: report.reportDetailed,
});

export const CompResults = connect(stateToProps)(CompResultsComponent);
