import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import * as data from '../../data';
import slideGraphics from './assets/slideGraphics.png';
import pieGraphic from './assets/pieGraphic';
import { useMemo } from 'react';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 40px;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
  width: 317px;
  margin-top: 50px;
`;

const SlideGraphics = styled('img')`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const PieGraphic = styled('img')`
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const BuyersAgentCompensationTraditionalPDF = () => {
  const primary = useUiConfigColorsPrimary();
  const image = useMemo(() => svgStringToDataUrl(pieGraphic, primary), [primary]);

  const headingStyles = {
    fontSize: 19,
    color: '#101010',
    fontWeight: 800,
    lineHeight: '26.2px',
    marginBottom: '15px',
  };

  const textStyles = {
    fontSize: 18,
    fontWeight: 500,
    color: '#101010',
    lineHeight: '22.35px',
  };

  const containerStyles = {
    justifyContent: 'flex-start',
    padding: 0,
  };

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container>
        <Heading>{data.title}</Heading>
        <SlideGraphics src={slideGraphics} />
        <PieGraphic src={image} />
        <TraditionalSlideCircularCard
          heading={data.data[0].title}
          headingStyles={{ ...headingStyles, width: 245 }}
          text={data.data[0].text}
          textStyles={{ ...textStyles, width: 245 }}
          position={{ left: 90, top: 250 }}
          size={330}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data.data[1].title}
          headingStyles={{ ...headingStyles, width: 261 }}
          text={data.data[1].text}
          textStyles={{ ...textStyles, width: 337 }}
          position={{ left: 488, top: 250 }}
          size={330}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data.data[2].title}
          headingStyles={{ ...headingStyles, width: 271 }}
          text={data.data[2].text}
          textStyles={{ ...textStyles, width: 245 }}
          position={{ left: 90, top: 670 }}
          size={330}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data.data[3].title}
          headingStyles={{ ...headingStyles, width: 275 }}
          text={data.data[3].text}
          textStyles={{ ...textStyles, width: 245 }}
          position={{ left: 488, top: 670 }}
          size={330}
          containerStyles={containerStyles}
        />
      </Container>
    </PdfFrame>
  );
};
