import styled from 'styled-components';
import { CategoryCheckbox } from '../../components/CategoryCheckbox';
import { TCompConfigItem } from '../../../../../types';
import { useShouldDisplay } from '../hooks/useShouldDisplay';

interface Props {
  title: string;
  fields: TCompConfigItem[];
}

export const FeatureGroup = ({ title, fields }: Props) => {
  const { shouldDisplay } = useShouldDisplay();

  if (!shouldDisplay(fields)) return null;

  return (
    <Container>
      <Title>{title}</Title>
      <CheckboxGroup>
        {fields.map(field => {
          return (
            <CheckboxWrapper key={field.id}>
              <CategoryCheckbox field={field} />
            </CheckboxWrapper>
          );
        })}
      </CheckboxGroup>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;

  &:last-child {
    border-bottom: 1px solid #b1b1b1;
  }
`;

const CheckboxGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
`;

const CheckboxWrapper = styled.div`
  min-width: 100px;
  flex-shrink: 0;
`;

const Title = styled.h5`
  color: #444;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;
