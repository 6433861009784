import React from 'react';

import { EntityMode, TPresentationType, TSlide } from '../types';
import { useUserCategoryCustomizations } from './useUserCategoryCustomizations';
import { OrderValidator } from '../services/validators/OrderValidator';
import { CustomizationConfigs } from '../features/Customization/services/CustomizationConfigs';
import { PresentationConfigsFactory } from '../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';
import { useUser } from '../components/Slide/providers/UserProvider';
import { useLevelDynamicSlides } from './useLevelDynamicSlides';

function getDefaultConfigByType(presentationType: TPresentationType) {
  const defaultSectionsSlides: Record<string, Partial<TSlide>[]> = {};

  const typeConfig = PresentationConfigsFactory.create(presentationType);

  const predefinedSectionSlides = typeConfig.getPredefinedSectionSlides();
  const defaultActiveSectionSlides = typeConfig.getDefaultOrder().sectionsSlide;

  Object.keys(predefinedSectionSlides).map(sectionId => {
    defaultSectionsSlides[sectionId] = predefinedSectionSlides[sectionId].map(slideId => ({
      id: slideId,
      includeSlide: !!defaultActiveSectionSlides[sectionId].find(sId => sId === slideId),
    }));
  });

  return {
    sections: typeConfig.getFullOrder().sections,
    sectionsSlide: defaultSectionsSlides,
  };
}

export const useCustomizationSettings = (
  hash: string,
  entityMode = EntityMode.READ,
  entity: number | null = null,
) => {
  const user = useUser();
  const dynamic = useLevelDynamicSlides(
    hash,
    user?.useSpecialBranding || false,
    entity,
    entityMode,
  );
  const customizations = useUserCategoryCustomizations(
    hash,
    entity,
    user?.useSpecialBranding,
    entityMode,
  );

  const isLoading = customizations.isLoading || dynamic.isLoading;
  const isFetching = customizations.isFetching || dynamic.isFetching;

  const winTheListingCustomizations = React.useMemo(() => {
    const orderValidator = new OrderValidator('winTheListing');

    return {
      sections: orderValidator.getFullSectionsOrder(
        customizations?.data?.orderByType?.winTheListing?.sections ??
          getDefaultConfigByType('winTheListing').sections,
      ),
      sectionSlides: orderValidator.getFullExtendedSectionsSlidesOrder(
        (customizations?.data?.orderByType?.winTheListing?.sectionsSlide as Record<
          string,
          Partial<TSlide>[]
        >) ?? getDefaultConfigByType('winTheListing').sectionsSlide,
        dynamic.data ?? {},
      ),
    };
  }, [customizations?.data, dynamic?.data]);

  const winTheOfferCustomizations = React.useMemo(() => {
    const orderValidator = new OrderValidator('winTheOffer');
    return {
      sections: orderValidator.getFullSectionsOrder(
        customizations?.data?.orderByType?.winTheOffer?.sections ??
          getDefaultConfigByType('winTheOffer').sections,
      ),
      sectionSlides: orderValidator.getFullExtendedSectionsSlidesOrder(
        (customizations?.data?.orderByType?.winTheOffer?.sectionsSlide as Record<
          string,
          Partial<TSlide>[]
        >) ?? getDefaultConfigByType('winTheOffer').sectionsSlide,
        dynamic.data ?? {},
      ),
    };
  }, [customizations?.data, dynamic?.data]);

  const presentInfoCustomizations = React.useMemo(() => {
    const orderValidator = new OrderValidator('presentInfo');

    return {
      sections: orderValidator.getFullSectionsOrder(
        customizations?.data?.orderByType?.presentInfo?.sections ??
          getDefaultConfigByType('presentInfo').sections,
      ),
      sectionSlides: orderValidator.getFullExtendedSectionsSlidesOrder(
        (customizations?.data?.orderByType?.presentInfo?.sectionsSlide as Record<
          string,
          Partial<TSlide>[]
        >) ?? getDefaultConfigByType('presentInfo').sectionsSlide,
        dynamic.data ?? {},
      ),
    };
  }, [customizations?.data, dynamic?.data]);

  const winTheRepresetationCustomizations = React.useMemo(() => {
    const orderValidator = new OrderValidator('winTheRepresentation');

    return {
      sections: orderValidator.getFullSectionsOrder(
        customizations?.data?.orderByType?.winTheRepresentation?.sections ??
          getDefaultConfigByType('winTheRepresentation').sections,
      ),
      sectionSlides: orderValidator.getFullExtendedSectionsSlidesOrder(
        (customizations?.data?.orderByType?.winTheRepresentation?.sectionsSlide as Record<
          string,
          Partial<TSlide>[]
        >) ?? getDefaultConfigByType('winTheRepresentation').sectionsSlide,
        dynamic.data ?? {},
      ),
    };
  }, [customizations?.data, dynamic?.data]);

  const slides = React.useMemo(() => {
    if (isLoading || isFetching) return null;
    return { ...CustomizationConfigs.getSlides(), ...dynamic.data };
  }, [dynamic?.data, isFetching, isLoading]);

  return React.useMemo(
    () => ({
      isLoading,
      isFetching,
      order: customizations?.data?.order,
      orderByType: {
        winTheListing: winTheListingCustomizations,
        winTheOffer: winTheOfferCustomizations,
        presentInfo: presentInfoCustomizations,
        winTheRepresentation: winTheRepresetationCustomizations,
      } as Partial<Record<TPresentationType, any>>,
      slides,
      ui: customizations.data?.ui,
    }),
    [
      isLoading,
      isFetching,
      customizations.data?.order,
      customizations.data?.ui,
      winTheListingCustomizations,
      winTheOfferCustomizations,
      presentInfoCustomizations,
      winTheRepresetationCustomizations,
      slides,
    ],
  );
};
