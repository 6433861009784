import { useHistory } from 'react-router-dom';

export function useSetSearchParameter() {
  const history = useHistory();

  return (key: string, value: string) => {
    const params = new URLSearchParams(history.location.search);

    params.delete(key);
    params.append(key, value);

    history.push({ search: params.toString() });
  };
}
