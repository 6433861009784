import React from 'react';
import { CSSProperties } from 'styled-components';
import * as typographyElement from './Typography.styles';

type TypographyTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2' | 'small' | 'upper';
export type TypographyColor =
  | 'primary'
  | '400'
  | '300'
  | '200'
  | '100'
  | 'white'
  | 'error'
  | string;

interface TypographyComponentProps {
  variant?: TypographyTypes;
  style?: CSSProperties;
  align?: 'left' | 'right' | 'center';
  color?: TypographyColor | false | null;
  onClick?: React.MouseEventHandler<HTMLHeadingElement | HTMLParagraphElement>;
}

export const Typography: React.FC<TypographyComponentProps> = ({
  variant = 'body1',
  children,
  style,
  align,
  color,
  ...props
}) => {
  const styles: CSSProperties = { ...style };
  if (align) styles.textAlign = align;
  const Component = typographyElement[variant];
  return (
    <Component style={styles} color={color ? color : undefined} {...props}>
      {children}
    </Component>
  );
};
