import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import slideGraphics from './assets/slideGraphics.png';
import { useMemo } from 'react';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import hexagonCells from './assets/hexagonCells';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import { data } from '../../data';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 40px;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const SlideGraphics = styled('img')`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const HexagonCells = styled('img')`
  position: absolute;
  display: block;
  width: 816px;
  bottom: -21px;
  left: 44px;
`;

export const PurposeOfBuyersAgentTraditionalPDF = () => {
  const primary = useUiConfigColorsPrimary();
  const image = useMemo(() => svgStringToDataUrl(hexagonCells, primary), [primary]);

  const headingStyles = {
    fontSize: 18,
    color: '#444',
    fontWeight: 800,
    lineHeight: '21.2px',
    marginBottom: '15px',
  };

  const textStyles = {
    fontSize: 13,
    fontWeight: 500,
    color: '#101010',
    lineHeight: '20.35px',
  };

  const containerStyles = {
    justifyContent: 'flex-start',
  };

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container>
        <Heading>
          Purpose & Importance of <br /> a Buyer&apos;s Agent
        </Heading>
        <SlideGraphics src={slideGraphics} />
        <HexagonCells src={image} />

        <TraditionalSlideCircularCard
          heading={data[0].title}
          headingStyles={{ ...headingStyles, width: 156 }}
          text={data[0].text}
          textStyles={{ ...textStyles, width: 196 }}
          position={{ left: 191, top: 141 }}
          size={270}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data[1].title}
          headingStyles={{ ...headingStyles, width: 109 }}
          text={data[1].text}
          textStyles={{ ...textStyles, width: 188 }}
          position={{ left: 441, top: 141 }}
          size={270}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data[2].title}
          headingStyles={{ ...headingStyles, width: 187 }}
          text={data[2].text}
          textStyles={{ ...textStyles, width: 193 }}
          position={{ left: 67, top: 417 }}
          size={270}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data[3].title}
          headingStyles={{ ...headingStyles, width: 122 }}
          text={data[3].text}
          textStyles={{ ...textStyles, width: 187 }}
          position={{ left: 316, top: 417 }}
          size={270}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data[4].title}
          headingStyles={{ ...headingStyles, width: 109 }}
          text={data[4].text}
          textStyles={{ ...textStyles, width: 188 }}
          position={{ left: 566, top: 417 }}
          size={270}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data[5].title}
          headingStyles={{ ...headingStyles, width: 125 }}
          text={data[5].text}
          textStyles={{ ...textStyles, width: 188 }}
          position={{ left: 192, top: 688 }}
          size={270}
          containerStyles={containerStyles}
        />
        <TraditionalSlideCircularCard
          heading={data[6].title}
          headingStyles={{ ...headingStyles, width: 122 }}
          text={data[6].text}
          textStyles={{ ...textStyles, width: 183 }}
          position={{ left: 441, top: 688 }}
          size={270}
          containerStyles={containerStyles}
        />
      </Container>
    </PdfFrame>
  );
};
