import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../../../services/ColorOpacityApplier';

import { TableRow, TableHeading, TableItem } from '../NetProceedsTable.styles';

type WithActive = {
  isActive: boolean;
};

export const CostBreakdownTitleRow = styled(TableRow)({
  backgroundColor: '#dedede',
});

export const CostBreakdownItemRow = styled(TableRow)({
  height: 30,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  '&:nth-child(even)': {
    backgroundColor: '#f1f1f1',
  },
});

export const CostBreakdownSectionTitle = styled(TableHeading)({
  fontSize: 14,
  height: 35,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 20,
  color: '#444',
  fontWeight: 800,
  textTransform: 'uppercase',
  width: '36%',
  borderLeft: '1px solid #DEDEDE',
});

export const CostBreakdownItem = styled(TableItem)<WithActive>(({ isActive }) => ({
  fontSize: 14,
  color: '#444',
  borderLeft: isActive ? 'none !important' : '1px solid #DEDEDE',
  position: 'relative',
  paddingLeft: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100%',
  '& + div': {
    borderLeft: isActive ? 'none' : '1px solid #DEDEDE',
  },
  '&:after': {
    display: isActive ? 'block' : 'none',
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transition: 'all .2s ease-in-out',
    backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '.125'),
  },
}));

export const CostBreakdownItemTitle = styled(TableHeading)({
  fontSize: 14,
  color: '#444',
  paddingLeft: 20,
  flex: 1,
});
