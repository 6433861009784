import styled from 'styled-components';
import { TModalType } from './ClosingAndNextStepsContent.component';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useSlideProps } from '../../../../../providers/SlideProvider';
import { usePresentation } from '../../../../../../../hooks/usePresentation';

type WithDisabled = {
  isDisabled?: boolean;
};

export const ButtonContainer = styled('div')({
  marginTop: 75,
  width: '77%',
});

export const ButtonsContainerTitle = styled('div')({
  fontSize: 20,
  color: '#000',
  textTransform: 'uppercase',
  textAlign: 'start',
  marginBottom: 15,
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Button = styled('button')(({ theme }) => ({
  width: '49%',
  border: 'none',
  height: 120,
  borderRadius: 5,
  backgroundColor: '#DEDEDE',
  cursor: 'pointer',
  transition: 'all .2s ease-in-out',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  '&:hover': {
    backgroundColor: '#c7c7c7',
  },
}));

export const ContractButton = styled(Button)<WithDisabled>(({ isDisabled }) => ({
  backgroundColor: isDisabled ? '#adadad' : '#DEDEDE',
  '&:hover': {
    backgroundColor: isDisabled ? '#adadad' : '#c7c7c7',
  },
}));

export const ButtonText = styled('div')({
  fontSize: 16,
  color: '#444444',
  textTransform: 'uppercase',
  marginBottom: 10,
});

export const ButtonTextBold = styled('div')({
  fontSize: 26,
  fontWeight: 500,
  color: '#444444',
  textTransform: 'uppercase',
});

interface Props {
  onOpenModal: (val: TModalType) => void;
}

export const ClosingAndNextStepsButtons = ({ onOpenModal }: Props) => {
  const { presentationHash } = useSlideProps();

  const { data: presentation } = usePresentation(presentationHash);

  if (presentation?.type === 'winTheRepresentation') {
    return (
      <ButtonContainer>
        <ButtonsWrapper style={{ justifyContent: 'center' }}>
          <ContractButton onClick={() => onOpenModal('getStarted')}>
            <ButtonText>Let&apos;s</ButtonText>
            <ButtonTextBold>get started</ButtonTextBold>
          </ContractButton>
        </ButtonsWrapper>
      </ButtonContainer>
    );
  }

  return (
    <ButtonContainer>
      <ButtonsContainerTitle>Choose your next step:</ButtonsContainerTitle>
      <ButtonsWrapper>
        <ContractButton onClick={() => onOpenModal('getStarted')}>
          <ButtonText>Let&apos;s</ButtonText>
          <ButtonTextBold>get started</ButtonTextBold>
        </ContractButton>
        <Button onClick={() => onOpenModal('moreTime')}>
          <ButtonText>I need</ButtonText>
          <ButtonTextBold>More time / info</ButtonTextBold>
        </Button>
      </ButtonsWrapper>
    </ButtonContainer>
  );
};
