import { RawReport, TProperty } from '../../../types';

export function extractSubject(report: RawReport) {
  const property = report?.subject ?? ({} as TProperty);
  const lat = property?.coordinates?.latitude || 0;
  const lng = property?.coordinates?.longitude || 0;
  const address = property?.address?.deliveryLine || '';

  return {
    lat,
    lng,
    address,
    property,
  };
}
