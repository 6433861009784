import React from 'react';

import { CATEGORIES } from '../../../constants/categories';
import { PresentationApi } from '../../Presentation/api/PresentationApi';
import {
  TCategory,
  TClient,
  TPresentation,
  TPresentationMeta,
  TPresentationMode,
  TPresentationType,
} from '../../../types';

type TPredefinedProperties = {
  category: TCategory;
  type: TPresentationType;
  mode: TPresentationMode;
  slides?: any;
  regular?: any;
  videoLink?: any;
  agreementLink?: any;
  theme?: any;
  cma?: any;
  oneSheeter?: any;
  meta?: TPresentationMeta;
  client?: TClient;
};

type TPredefinedPropertiesState = {
  getCategory: () => TCategory;
  getPredefinedProperties: () => TPredefinedProperties;
  buildPredefinedProperties: (
    isRebuid: boolean,
    presHash?: string,
  ) => Promise<TPredefinedProperties>;
};

export function usePredefinedProperties(type: TPresentationType): TPredefinedPropertiesState {
  const predefinedProperties = React.useRef<TPredefinedProperties>({
    type: type,
    category: CATEGORIES.TRADITIONAL,
    mode: 'regular',
  });

  function setPredefinedProperties(properties: TPredefinedProperties): void {
    predefinedProperties.current = {
      ...predefinedProperties.current,
      ...properties,
    };
  }

  function getClient(presentation: TPresentation): TClient | undefined {
    if (!presentation?.clientId) return;

    return {
      id: presentation?.clientId,
      name: presentation?.clientName,
      email: presentation?.clientEmail,
    };
  }

  async function buildPredefinedProperties(
    isRebuild: boolean,
    presHash?: string,
  ): Promise<TPredefinedProperties> {
    if (!isRebuild || !presHash) {
      return predefinedProperties.current;
    }

    const presentation = await PresentationApi.fetch(presHash);

    const predefinedPopertiesFromPresentation = {
      type: presentation.type ?? predefinedProperties.current.type,
      mode: presentation.mode ?? predefinedProperties.current.mode,
      category: presentation.category ?? predefinedProperties.current.category,
      regular: presentation?.regular,
      cma: presentation?.cma,
      oneSheeter: presentation?.oneSheeter,
      slides: presentation?.slidesMap,
      videoLink: presentation?.videoLink,
      agreementLink: presentation?.agreementLink,
      theme: presentation?.ui?.theme,
      meta: presentation?.meta,
      client: getClient(presentation),
    };

    setPredefinedProperties(predefinedPopertiesFromPresentation);

    return predefinedPopertiesFromPresentation;
  }

  function getCategory() {
    return predefinedProperties.current.category;
  }

  function getPredefinedProperties() {
    return predefinedProperties.current;
  }

  const value: TPredefinedPropertiesState = {
    getPredefinedProperties,
    buildPredefinedProperties,
    getCategory,
  };

  return value;
}
