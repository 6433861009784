import React from 'react';
import styled from 'styled-components';

import { TProperty } from '../../../../../../../../../../../types';
import { MoneyFormatter } from '../../../../../../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const Price = styled.p`
  color: #121212;
  font-size: 11px;
  opacity: 0.46;
  font-weight: bolder;
`;

interface Props {
  property: TProperty;
}
export const PriceAndActions: React.FC<Props> = ({ property }) => (
  <Container>
    <Price>
      {MoneyFormatter.format(property.status === 'sold' ? property.salePrice : property.price)}
    </Price>
  </Container>
);
