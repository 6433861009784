import styled from 'styled-components';
import { useUser } from '../../../components/Slide/providers/UserProvider';

interface Props {
  note: string;
}

export const Notes = ({ note }: Props) => {
  const user = useUser();
  return (
    <Container>
      <Title>Notes from: {user.name}</Title>
      <Text>{note}</Text>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
`;
