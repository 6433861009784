import { THighlightedProperty } from '../../../types';

export const getDistinctListOfHighlights = (
  oldHighlights: THighlightedProperty[],
  newDefaultHighlights: THighlightedProperty[],
) => {
  const manualHighlights = (oldHighlights || []).filter((h: any) => !h.label);
  const newDefaultHighlightsMap = newDefaultHighlights.reduce(
    (map: any, h: any) => ({ ...map, [h.id]: h }),
    {},
  );

  const distinctHighlights = manualHighlights.filter((h: any) => !newDefaultHighlightsMap[h.id]);
  return [...newDefaultHighlights, ...distinctHighlights];
};
