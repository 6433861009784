import { FlattenSimpleInterpolation, css } from 'styled-components';
import { breakpoints } from './breakpoints';
import { ThemeBreakpoints } from './types';

export const media = Object.keys(breakpoints).reduce(
  (map, breakpoint) => {
    map[breakpoint as keyof ThemeBreakpoints] = (
      first: TemplateStringsArray,
      ...interpolations: FlattenSimpleInterpolation[]
    ) => css`
      @media (min-width: ${breakpoints[breakpoint as keyof ThemeBreakpoints]}) {
        ${css(first, ...interpolations)};
      }
    `;

    return map;
  },
  {} as Record<keyof ThemeBreakpoints, (...args: any) => any>,
);
