import React from 'react';

import { FixedSizeWrapper } from './WebFrame.styles';
import { FrameConfigsProvider } from './FrameConfigsProvider';
import { CommonFrameProps, TSlideTheme } from '../../../../../../types';

interface SlideFrameFactoryProps extends CommonFrameProps {
  theme: TSlideTheme;
}

const WebFrame: React.FC<SlideFrameFactoryProps> = ({
  theme,
  children,
  infoPlacement,
  hideProgressTracker,
}) => {
  const Component: React.FC<CommonFrameProps> = FrameConfigsProvider.get(theme)?.Frame;
  if (!Component) return null;

  return (
    <FixedSizeWrapper>
      <Component infoPlacement={infoPlacement} hideProgressTracker={hideProgressTracker}>
        {children}
      </Component>
    </FixedSizeWrapper>
  );
};

export default WebFrame;
