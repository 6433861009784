import { useSetAtom } from 'jotai';
import { batch, useDispatch, useSelector } from 'react-redux';

import { customMarkersAtom } from '../../../../../../../features/report/state/customMarkersAtom';
import { useSetDefaultHighlights } from '../../../../../../../features/report/hooks/useSetDefaultHighlights';
import { useSetDefaultCategories } from '../../../../../../../features/report/hooks/useSetDefaultCategories';
import { getCategoriesFromIds } from '../../../../../../../features/report/services/getCategoriesFromIds';
import { customEstimatesAtom } from '../../../../../../../features/report/state/customEstimatesAtom';
import { getHighlightsByIds } from '../../../../../../../features/report/services/getHighlightsFromIds';
import { selectedCategoriesAtom } from '../../../../../../../features/report/state/selectedCategoriesAtom';
import { highlightedPropertiesAtom } from '../../../../../../../features/report/state/highlighted-properties/highlightedPropertiesAtom';
import { excludedPropertyIdsAtom } from '../../../../../../../features/report/state/excludedPropertyIdsAtom';

export function useResultRebuildStateSetter() {
  const setCustomMarkers = useSetAtom(customMarkersAtom);
  const setSelectedCategories = useSetAtom(selectedCategoriesAtom);
  const setCustomEstimates = useSetAtom(customEstimatesAtom);
  const setHighlighted = useSetAtom(highlightedPropertiesAtom);
  const setExcluded = useSetAtom(excludedPropertyIdsAtom);

  const setDefaultCategories = useSetDefaultCategories();
  const setDefaultHighlights = useSetDefaultHighlights();

  const dispatch = useDispatch();
  const rebuildData = useSelector(({ report }) => report.rebuildData);

  return (reportDetailed, onSuccess) => {
    const properties = [
      ...(reportDetailed.properties?.active ?? []),
      ...(reportDetailed.properties?.pending ?? []),
      ...(reportDetailed.properties?.sold ?? []),
    ];

    batch(async () => {
      let excludedIds = properties.filter(p => p.excluded).map(p => p.id);
      excludedIds = [...new Set(excludedIds.concat(rebuildData.request.excluded_ids || []))];
      setExcluded(excludedIds);

      if (rebuildData?.report_state.custom_highlights?.length > 0) {
        setCustomMarkers(rebuildData.report_state.custom_highlights);
      }

      if (rebuildData?.report_state?.highlights?.length > 0) {
        const highlights = getHighlightsByIds(rebuildData.report_state.highlights, reportDetailed);

        setHighlighted(highlights);
      } else {
        setDefaultHighlights(reportDetailed);
      }

      if (rebuildData?.report_state?.categories?.length > 0) {
        const categories = getCategoriesFromIds(
          rebuildData.report_state.categories,
          reportDetailed,
        );
        setSelectedCategories(categories);
        setCustomEstimates(rebuildData.report_state.custom_categories);
      } else {
        setDefaultCategories(reportDetailed);
      }

      setTimeout(() => onSuccess?.(), 1000);
    });
  };
}
