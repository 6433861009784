import { memo, NamedExoticComponent, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  modalRoot: string;
  children: ReactNode;
  anchorEl?: Element | null;
};

export const Portal: NamedExoticComponent<PortalProps> = memo(
  ({ children, modalRoot, anchorEl }) => {
    const el = useRef<null | HTMLDivElement>(null);

    let rootModalEl = document.getElementById(anchorEl ? 'popup' : modalRoot);

    useEffect(() => {
      const { current } = el;

      if (current && rootModalEl) rootModalEl.append(current);

      return () => {
        if (current && rootModalEl) rootModalEl.removeChild(current);
      };
    }, [modalRoot, rootModalEl]);

    const root = document.querySelector('body');
    if (!root) return null;

    if (!rootModalEl) {
      const theEl = document.createElement('div');
      theEl.id = anchorEl ? 'popup' : modalRoot;
      root?.appendChild(theEl);
      rootModalEl = theEl;
    }

    if (!rootModalEl) return null;

    if (!el.current) el.current = document.createElement('div');

    return createPortal(children, rootModalEl);
  },
);

Portal.displayName = 'Portal';
