import { TCategory, TSlideTheme } from '../../../../../../types';
import { THEMES } from '../../../../../../constants/themes';
import { FrameConfigsProvider } from './FrameConfigsProvider';
import { CATEGORIES } from '../../../../../../constants/categories';

type FrameThumbnailMeta = {
  id: TSlideTheme;
  label: string;
  thumbnail: string;
};

export class FrameThumbnailConfigsProvider {
  static getByCategory(category: TCategory): FrameThumbnailMeta[] {
    return FrameThumbnailConfigsProvider.configs[category];
  }

  private static configs: Record<TCategory, FrameThumbnailMeta[]> = {
    [CATEGORIES.TRADITIONAL]: [
      {
        id: FrameConfigsProvider.get(THEMES.TRADITIONAL.GAMA).id,
        label: 'Traditional One',
        thumbnail: FrameConfigsProvider.get(THEMES.TRADITIONAL.GAMA).thumbnail,
      },
      {
        id: FrameConfigsProvider.get(THEMES.TRADITIONAL.BETA).id,
        label: 'DEFAULT',
        thumbnail: FrameConfigsProvider.get(THEMES.TRADITIONAL.BETA).thumbnail,
      },
      {
        id: FrameConfigsProvider.get(THEMES.TRADITIONAL.ALPHA).id,
        label: 'Traditional Two',
        thumbnail: FrameConfigsProvider.get(THEMES.TRADITIONAL.ALPHA).thumbnail,
      },
    ],
    [CATEGORIES.MODERN]: [
      {
        id: FrameConfigsProvider.get(THEMES.MODERN.ALPHA).id,
        label: 'Modern One',
        thumbnail: FrameConfigsProvider.get(THEMES.MODERN.ALPHA).thumbnail,
      },
      {
        id: FrameConfigsProvider.get(THEMES.MODERN.BETA).id,
        label: 'DEFAULT',
        thumbnail: FrameConfigsProvider.get(THEMES.MODERN.BETA).thumbnail,
      },
      {
        id: FrameConfigsProvider.get(THEMES.MODERN.GAMA).id,
        label: 'Modern Two',
        thumbnail: FrameConfigsProvider.get(THEMES.MODERN.GAMA).thumbnail,
      },
    ],
    [CATEGORIES.LUXURY]: [
      {
        id: FrameConfigsProvider.get(THEMES.LUXURY.ALPHA).id,
        label: 'Luxury One',
        thumbnail: FrameConfigsProvider.get(THEMES.LUXURY.ALPHA).thumbnail,
      },
      {
        id: FrameConfigsProvider.get(THEMES.LUXURY.BETA).id,
        label: 'DEFAULT',
        thumbnail: FrameConfigsProvider.get(THEMES.LUXURY.BETA).thumbnail,
      },
      {
        id: FrameConfigsProvider.get(THEMES.LUXURY.GAMA).id,
        label: 'Luxury Two',
        thumbnail: FrameConfigsProvider.get(THEMES.LUXURY.GAMA).thumbnail,
      },
    ],
  };
}
