import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography } from '../../../../../Common';

const Container = styled('div')({
  marginBottom: 10,
  position: 'relative',
  width: '100%',
});

const Text = styled('p')({
  textAlign: 'center',
  color: '#000',
  fontSize: 16,
  cursor: 'pointer',
  fontWeight: 500,
});

const MessageContainer = styled('div')(({ theme }) => ({
  padding: 10,
  backgroundColor: theme.colors.gray[400],
  borderRadius: 5,
  marginRight: 10,
  position: 'absolute',
  bottom: '100%',
  left: 0,
  width: '100%',
}));

const AgreeText = styled(Typography)(({ theme }) => ({
  color: theme.colors.primary.main,
  fontWeight: 500,
  cursor: 'pointer',
}));

export const DataValidation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      <Text onClick={() => setIsOpen(true)}>Data Validation (click to view)</Text>
      {isOpen && (
        <MessageContainer>
          <Typography variant='body1' color='#fff' style={{ fontSize: 16, lineHeight: 1.6 }}>
            Buyer data used in the heatmap represents consumer search activity, property views, and
            saved search alerts across Inside Real Estate’s network of over 250K real estate sites.
          </Typography>
          <AgreeText
            variant='body1'
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Ok
          </AgreeText>
        </MessageContainer>
      )}
    </Container>
  );
};
