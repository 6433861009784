import styled from 'styled-components';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: isPdf ? 0 : '2%',
}));

export const ImageWrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  width: '100%',
  flex: isPdf ? 0.6 : 1,
  overflow: 'hidden',
}));

export const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'center',
});
