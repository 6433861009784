import { useEffect } from 'react';

import { TPresentation, TSection, TSlide, TSlideId } from '../../../../../../../types';
import { useOrder } from '../../../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../../../providers/providers/SlidesProvider';
import { useClient } from '../../../../../../../providers/providers/ClientProvider';
import { useProperties } from '../../../../../../../providers/providers/PropertiesProvider';
import { usePresentationMode } from '../../../../../../../providers/providers/PresentationModeProvider';

export const useConfigurePresentationAnalyticsProviders = (presentation: TPresentation) => {
  const { presentationMode } = usePresentationMode();
  const { setSectionsOrder, setSectionsSlideOrder } = useOrder();
  const { setSlides } = useSlides();
  const { setProperties } = useProperties();
  const { setClient } = useClient();

  const toSlidesMap = (_slides: TSlide[]) => {
    return Object.fromEntries(_slides.map(slide => [slide?.id, { ...slide }]));
  };

  const isBt = presentation.type === 'buyerTour';

  useEffect(() => {
    if (isBt) {
      setSectionsOrder((presentation as any).order.sections);
      setSectionsSlideOrder((presentation as any).order.sectionsSlide);
      setProperties((presentation as any).properties);

      const _theSlides = (presentation as any).order.sections.flatMap((sectionId: TSection) => {
        return (presentation as any).order.sectionsSlide[sectionId].map(
          (slideId: TSlideId) => (presentation as any)?.slides?.[slideId] as TSlide,
        );
      });

      setSlides(toSlidesMap(_theSlides));

      setClient({
        id: presentation.clientId,
        name: presentation.clientName,
        email: presentation.clientEmail,
      });
      return;
    }

    const config = presentation[presentationMode];
    if (!config?.order.sections || !config?.order.sectionsSlide) {
      return;
    }
    const _theSlides = config.order.sections.flatMap(sectionId => {
      return config.order.sectionsSlide[sectionId].map(
        slideId => presentation?.slidesMap?.[slideId] as TSlide,
      );
    });

    setSectionsOrder(config.order.sections);
    setSectionsSlideOrder(config.order.sectionsSlide);

    setSlides(toSlidesMap(_theSlides));
  }, [
    isBt,
    presentation,
    presentationMode,
    setClient,
    setProperties,
    setSectionsOrder,
    setSectionsSlideOrder,
    setSlides,
  ]);
};
