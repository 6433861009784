import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { Text } from '../../../common.styles';

export const TestimonialContainer = styled('div')(({ theme }) => ({
  marginBottom: 25,
  paddingBottom: 10,
  position: 'relative',
  '&:after': {
    content: '""',
    width: 350,
    height: 1,
    backgroundColor: useUiConfigColorsPrimary(),
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  '&:last-of-type': {
    paddingBottom: 0,
    '&:after': {
      display: 'none',
    },
  },
}));

export const TestimonialText = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 16,
  lineHeight: 'normal',
}));

export const TestimonialAuthor = styled(Text)(({ theme }) => ({
  fontStyle: 'italic',
  textAlign: 'center',
  fontWeight: 'bold',
}));
