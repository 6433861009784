import React from 'react';

import { usePresentationType } from '../../../../../../../providers/providers/PresentationTypeProvider';
import { LuxuryCoverBTPdf } from './LuxuryCoverBTPdf';
import { LuxuryCoverRegularPdf } from './LuxuryCoverRegularPdf';

export const LuxuryCoverPdf: React.FC = () => {
  const { presentationType } = usePresentationType();
  if (presentationType === 'buyerTour') return <LuxuryCoverBTPdf />;
  return <LuxuryCoverRegularPdf />;
};
