import styled from 'styled-components';
import { Input } from '../../../../../Common/V2/Input';

interface Props {
  label: string;
  name: string;
}

type ComparableExtensionInputProps = Props & React.ComponentProps<typeof Input>;

export const ComparableExtensionInput = ({
  label,
  name,
  ...props
}: ComparableExtensionInputProps) => {
  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <Input name={name} {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.label`
  color: ${props => props.theme.colors.v2.gray[400]};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;
