import React, { Dispatch, SetStateAction } from 'react';
import { FilePicker } from '../FilePicker/FilePicker.component';

import { UploadImage, UploadImageIconWrapper, UploadImageIcon } from './UploadImageButton.styles';
import pencilIcon from './assets/pencil.svg?url';

interface UploadImageButtonProps {
  setFile?: Dispatch<SetStateAction<null | File>>;
  onClick?: () => void;
  containerStyles?: React.CSSProperties;
}

export const UploadImageButton: React.FC<UploadImageButtonProps> = ({
  setFile,
  onClick,
  containerStyles,
}) => {
  return (
    <UploadImage style={containerStyles}>
      {setFile ? (
        <FilePicker accept='image/*' setFile={setFile}>
          <UploadImageIconWrapper>
            <UploadImageIcon src={pencilIcon} />
          </UploadImageIconWrapper>
        </FilePicker>
      ) : (
        <UploadImageIconWrapper onClick={onClick}>
          <UploadImageIcon src={pencilIcon} />
        </UploadImageIconWrapper>
      )}
    </UploadImage>
  );
};
