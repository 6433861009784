import { FC } from 'react';

import image from './image.png';
import { data, title, subtitle } from '../../data';
import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';

export const WhyIAmTheRightBuyersAgentLuxury: FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading={title}
      subtitle={subtitle}
      listItems={data}
      contentStyles={{ fontSize: 19.5, lineHeight: 1.45 }}
      image={image}
      imagePosition='left'
      imageStyles={{ width: '50%' }}
      textContainerStyles={{ padding: 0 }}
    />
  );
};
