import React from 'react';

import { sellingPaceData } from '../data';
import { SlideWithGraphWrapper } from '../../../../../components/common/SlideWithGraphWrapper';
import { TCategory } from '../../../../../../../../types';
import { SellingPaceChart } from '../../../../../../../../features/report/charts/selling-pace/SellingPaceChart';

interface SellingPaceSlideProps {
  category: TCategory;
}

const SellingPaceSlide: React.FC<SellingPaceSlideProps> = ({ category }) => (
  <SlideWithGraphWrapper
    description={sellingPaceData.description}
    heading={sellingPaceData.heading}
    category={category}
  >
    <SellingPaceChart style={{ height: 580 }} />
  </SlideWithGraphWrapper>
);

export default SellingPaceSlide;
