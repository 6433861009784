import { RangeForm } from '../components/RangeForm';

export const YearBuiltRangeForm = ({
  onFieldChange = prop => prop,
  onConfirm = f => f,
  onCancel = f => f,
  values,
}) => {
  const { yearBuiltFrom, yearBuiltTo } = values;
  return (
    <RangeForm
      valueFrom={yearBuiltFrom}
      valueTo={yearBuiltTo}
      placeholderFrom='Year Built From'
      placeholderTo='Year Built To'
      onChangeValueFrom={val => onFieldChange('yearBuiltFrom')(val)}
      onChangeValueTo={val => onFieldChange('yearBuiltTo')(val)}
      onCancel={onCancel}
      onConfirm={onConfirm}
      thousandSeparator={false}
    />
  );
};
