import React from 'react';
import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

import { Container } from './TwoImagesEpsilon.styles';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';

interface Props {
  reverse?: boolean;
}
const TwoImagesEpsilonForm: React.FC<Props> = ({ reverse }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string, field: 'headline' | 'headline2') => {
    setSlideDataProperty(field, value);
  };

  const onChangeText = (value: string, field: 'text' | 'text2') => {
    setSlideDataProperty(field, value);
  };

  if (!selectedSlide) return null;

  return (
    <Container>
      <UploadImage
        slideDataConfigPropertyName={'image'}
        image={selectedSlide.data?.image}
        containerStyles={{ gridColumn: reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}
        imageSizeSuggestion='722x328'
      />
      <SlideTextBlock
        headline={selectedSlide.data?.headline}
        text={selectedSlide.data?.text}
        onChangeHeadline={val => onChangeHeadline(val, 'headline')}
        onChangeText={val => onChangeText(val, 'text')}
        isEditable
        containerStyles={{ gridColumn: !reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}
      />
      <SlideTextBlock
        isEditable
        text={selectedSlide.data?.text2}
        headline={selectedSlide.data?.headline2}
        onChangeHeadline={val => onChangeHeadline(val, 'headline2')}
        onChangeText={val => onChangeText(val, 'text2')}
      />
      <UploadImage
        slideDataConfigPropertyName={'image2'}
        image={selectedSlide.data?.image2}
        containerStyles={{ gridColumn: !reverse ? '2 / 2' : '1 / 1', gridRow: '2 / 2' }}
        imageSizeSuggestion='722x328'
      />
    </Container>
  );
};

export default TwoImagesEpsilonForm;
