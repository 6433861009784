import styled, { CSSObject } from 'styled-components';
import { ButtonHTMLAttributes } from 'react';
import { Icon } from '../../../../../../../../Common';
import { useUiConfigColorsPrimary } from '../../../../../../../../../providers/providers/UiConfigColorProvider';
import { ColorOpacityApplier } from '../../../../../../../services/ColorOpacityApplier';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../../../providers/providers/UiConfigFontProvider';

export { Icon };

export type ButtonStyle = 'download' | 'cancel' | 'submit';

interface ButtonProps extends ButtonHTMLAttributes<unknown> {
  buttonStyle: ButtonStyle;
}

const getButtonStyle = (buttonStyle: ButtonStyle, primary: string): CSSObject => {
  switch (buttonStyle) {
    case 'cancel':
      return {
        backgroundColor: '#D0D0D0',
        color: '#444444',
        '&:hover': {
          backgroundColor: '#c7c7c7',
        },
      };
    case 'submit':
      return {
        backgroundColor: primary,
        color: '#fff',
        '&:hover': {
          backgroundColor: ColorOpacityApplier.hex(primary, '.8'),
        },
      };
    default:
      return {};
  }
};

export const Backdrop = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .5)',
  display: 'flex',
  justifyContent: 'center',
});

export const Container = styled('div')({
  height: 'fit-content',
  width: '78%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.25)',
  transition: 'all .4s',
  transform: 'translateY(-50%)',
  position: 'relative',
  backgroundColor: '#fff',
  top: '50%',
  borderRadius: 5,
  overflow: 'hidden',
  '@keyframes slideDown': {
    from: {
      top: -1000,
      opacity: 0.7,
    },
    to: {
      top: '50%',

      opacity: 1,
    },
  },
  animationName: 'slideDown',
  animationDuration: '400ms',
  '@media all and (max-width: 1241px)': {
    maxWidth: '90%',
  },
});

export const ModalHeader = styled('div')(({ theme }) => ({
  height: 80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '.05'),
  position: 'relative',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const CloseIcon = styled('div')({
  position: 'absolute',
  top: 17,
  right: 17,
  cursor: 'pointer',
});

export const Title = styled('h5')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontSize: 22,
  textTransform: 'uppercase',
  letterSpacing: 3,
}));

export const ModalContent = styled('div')({
  padding: '30px 30px 67px 30px',
  backgroundColor: '#fff',
});

export const ModalFooter = styled('div')({
  padding: '25px 20px',
  borderTop: '2px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  width: '42%',
  justifyContent: 'space-between',
});

export const Button = styled('button')<ButtonProps>(({ buttonStyle }) => {
  const primaryColor = useUiConfigColorsPrimary();
  return {
    border: 'none',
    width: 140,
    height: 40,
    borderRadius: 5,
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 500,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    ...getButtonStyle(buttonStyle, primaryColor),
  };
});

export const DownloadPdfButtonWrapper = styled('div')({
  marginRight: 'auto',
});
