import { CardComponentProps } from '../../../components/Card/Card.component';
import icon1 from './assets/icons/1.png';
import icon2 from './assets/icons/2.png';
import icon3 from './assets/icons/3.png';
import icon4 from './assets/icons/4.png';
import icon5 from './assets/icons/5.png';
import icon6 from './assets/icons/6.png';
import icon7 from './assets/icons/7.png';
import icon8 from './assets/icons/8.png';
import icon9 from './assets/icons/9.png';
import icon10 from './assets/icons/10.png';

export const heading = 'what factors influence pricing';

export const cards: CardComponentProps[] = [
  {
    title: 'What factors influence pricing',
    text: `Ultimately, a home’s value is based on what potential buyers will pay, to know what they’re looking for in a home can help you get top dollar. Here are ten factors potential buyers consider when comparing homes:`,
    primaryColorTitle: true,
    cardStyles: { gridRow: '1 / -1', border: `1px solid #DDDDDD` },
    titleStyles: { marginBottom: 50 },
  },
  {
    title: 'layout',
    icon: icon1,
  },
  {
    title: 'aesthetic updates',
    icon: icon2,
  },
  {
    title: 'walkability',
    icon: icon3,
  },
  {
    title: 'Noise Level',
    icon: icon4,
  },
  {
    title: 'schools',
    icon: icon5,
  },
  {
    title: 'required level of maintenancees',
    icon: icon6,
  },
  {
    title: 'safety',
    icon: icon7,
  },
  {
    title: 'neighborhood',
    icon: icon8,
  },
  {
    title: 'functional updates',
    icon: icon9,
  },
  {
    title: 'return value',
    icon: icon10,
  },
];

export const listItemsLuxury = [
  { text: 'Layout' },
  { text: 'Walkability' },
  { text: 'Schools' },
  { text: 'Safety' },
  { text: 'Functional updates (HVAC, roof, plumbing, etc.)' },
  { text: 'Aesthetic updates (Kitchen remodel, bathroom update, etc.)' },
  { text: 'Noise level' },
  { text: 'Required level of maintenance' },
  { text: 'Neighborhood (views, community/neighbors, etc.)' },
  { text: 'Return value' },
];

export const paragraphs = [cards[0].text!];
