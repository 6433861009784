import styled from 'styled-components';
import { ReactNode, useMemo } from 'react';
import { TProperty } from '../../../../types';
import { DetailsItem } from './DetailsItem';
import { useColorBasedOnStatus } from '../../../../hooks/useColorBasedOnStatus';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { getPropertyTypeText } from '../../../../pages/PresentationCreate/dash/view/services/getPropertyTypeText';

interface Props {
  property: TProperty;
  icon?: ReactNode;
  isBT?: boolean;
}
const formatSqftPrice = (price?: number) => {
  if (!price) return '';

  return `(${MoneyFormatter.format(price)}/ft)`;
};

export const PageHeader = ({ property, icon, isBT }: Props) => {
  const propertyStatus = useMemo(
    () => (property.status === 'sold' ? 'Closed' : getPropertyTypeText(property.status)),
    [property.status],
  );

  const getColorBasedOnStatus = useColorBasedOnStatus();

  const adjustedPrice = useMemo(() => {
    if (!property.adjustedPrice) return '';

    const adjustedPerSqft = Math.round(property.adjustedPrice / property.size);

    return `${MoneyFormatter.format(property.adjustedPrice) || 'n/a'} ${formatSqftPrice(
      adjustedPerSqft,
    )}`;
  }, [property.adjustedPrice, property.size]);
  return (
    <Container>
      <TopSectionWrapper>
        <TitleWrapper>
          {icon}
          <Title>{property.address.deliveryLine}</Title>
        </TitleWrapper>
        <PricesWrapper>
          <DetailsItem
            label='Listed Price'
            value={`${MoneyFormatter.format(property.price)} ${formatSqftPrice(
              property.pricePerSqft,
            )}`}
            valueStyles={{ marginLeft: '8px' }}
          />
          {!isBT && (
            <DetailsItem
              label='SOLD Price'
              value={`${MoneyFormatter.format(property.salePrice) || 'n/a'} ${formatSqftPrice(
                property.salePricePerSqft,
              )}`}
              valueStyles={{ marginLeft: '8px' }}
            />
          )}
        </PricesWrapper>
      </TopSectionWrapper>
      <BottomSectionWrapper>
        <ListingDetails>
          <DetailsItem
            label='Bed'
            value={!isNaN(parseInt(String(property.beds))) ? String(property.beds) : 'N/A'}
          />
          <DetailsItem label='Bath' value={`${property.totalBaths} (Full: ${property.baths})`} />
          <DetailsItem label='SQFT' value={NumberFormatter.format(property.size)} />
          <DetailsItem
            label='status'
            value={propertyStatus}
            valueStyles={{
              textTransform: 'uppercase',
              color: getColorBasedOnStatus(property.status),
            }}
          />
        </ListingDetails>
        {adjustedPrice && (
          <DetailsItem
            label='Adjusted'
            value={adjustedPrice}
            valueStyles={{ fontStyle: 'italic', fontWeight: 500, marginLeft: '8px' }}
          />
        )}
      </BottomSectionWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
`;

const TopSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h3`
  font-size: 23px;
  color: #000;
  font-weight: 700;
`;

const BottomSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListingDetails = styled.div`
  display: flex;
  margin-top: 5px;

  & > div:not(:first-of-type) {
    padding-left: 8px;
  }

  & > div:not(:last-of-type) {
    padding-right: 8px;
    border-right: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  }
`;

const PricesWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
