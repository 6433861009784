export const cards = [
  {
    title: 'What buyers want from their agents',
    text: 'TOP 5 THINGS BUYERS WANT FROM A REAL ESTATE AGENT',
    primaryColorTitle: true,
    icon: undefined,
  },
  {
    title: 'paperwork',
    text: '7% of buyers require their agent’s help with paperwork. 92% of homes in the US are sold using an agent or a broker.',
    number: 3,
  },
  {
    title: 'HELP FINDING THE RIGHT HOME',
    text: '50% of buyers look to an agent to help them find the right home to purchase.',
    number: 1,
  },
  {
    title: 'FInancing',
    text: '3% of buyers need help determining how much house they can afford as well as helping finding/arranging financing.',
    number: 4,
  },
  {
    title: 'Negotiations',
    text: '12% of buyers depend on their agents to negotiate the price and terms of the sale.',
    number: 2,
  },
  {
    title: 'Comparable Market analysis',
    text: '7% of buyers want to know the worth of nearby homes to gain better insight into how much they should spend in a specific area.',
    number: 5,
  },
];
