import styled, { useTheme } from 'styled-components';
import { AgentPrice } from './components/agent-price/AgentPrice';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { PriceModeSelection } from './components/PriceModeSelection';
import Tooltip from '../../../components/Common/Tooltip/Tooltip.component';
import { Icon } from '../../../components/Common';
import { ResultsTuning } from '../results-tuning/ResultsTuning';
import { Scale } from './scale/web/Scale';
import { PlotByPriceSelection } from './components/PlotByPriceSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ScaleOverviewReport = () => {
  const { colors } = useTheme();
  const { isCanadaUser } = useUser();
  return (
    <Container>
      <TitleWrapper>
        <Title>CMA</Title>
        <Tooltip
          text={
            'Display up to 18 data points across 5 categories in a single view for a comprehensive pricing overview. Featured comparables must be chosen during CMA creation, they cannot be added or adjusted once the presentation has been created'
          }
          tooltipContentStyle={{
            transform: 'translateX(0)',
          }}
        >
          <TooltipIconWrapper>
            <FontAwesomeIcon icon='circle-info' color={colors.v2.primary} />
          </TooltipIconWrapper>
        </Tooltip>
      </TitleWrapper>
      <SelectionsWrapper>
        <AgentPriceWrapper>
          <AgentPrice />
        </AgentPriceWrapper>
        <ModesWrapper>
          <PlotByPriceSelection />
          {!isCanadaUser && <PriceModeSelection />}
        </ModesWrapper>
      </SelectionsWrapper>
      <ResultsTuning isResponsive />
      <Scale />
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin: 40px 0 20px;
  font-family: Figtree;
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 30px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
`;

const TooltipIconWrapper = styled.div`
  svg {
    width: 14px;
  }
`;

const AgentPriceWrapper = styled.div``;

const SelectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 10px;

  @media all and (max-width: 941px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ModesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
