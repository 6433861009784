import { Accordion } from '../../../../../../../components/Common/V2/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TemplateSelector } from './TemplateSelector/TemplateSelector';
import { UploadContentExpandable } from './UploadContentExpandable';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px 0 0 0;
`;

export const SlideLayoutExpandable = () => (
  <Accordion
    prefixIcon={<FontAwesomeIcon icon={['fas', 'table-layout']} />}
    title='Slide Layout'
    open={true}
  >
    <Container>
      <TemplateSelector />
      <UploadContentExpandable />
    </Container>
  </Accordion>
);
