import React from 'react';
import image from './image.png';
import { alternativeSlideContent } from '../../data';
import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';

const ValueOfAnAgentLuxuryPDF: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading='Value of an Agent'
      image={image}
      paragraphs={alternativeSlideContent.paragraphs}
    />
  );
};

export default ValueOfAnAgentLuxuryPDF;
