import React from 'react';

import { TCategory } from '../../../../../../types';
import { useProgressState } from '../../../../ProgressProvider';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { CustomizationSettingsItem } from '../../components/CustomizationSettingsItem/CustomizationSettingsItem.component';

export const Categories: React.FC = () => {
  const { setCategory } = useCategory();
  const { progress } = useProgressState();

  const isCompleted = React.useCallback(
    (category: TCategory) => {
      return !!progress[category]?.length;
    },
    [progress],
  );

  return (
    <>
      <CustomizationSettingsItem
        section='customize'
        title='Traditional Presentation'
        edited={isCompleted('traditional')}
        completed={isCompleted('traditional')}
        onButtonClick={() => setCategory('traditional')}
      />
      <CustomizationSettingsItem
        section='customize'
        title='Modern Presentation'
        edited={isCompleted('modern')}
        completed={isCompleted('modern')}
        onButtonClick={() => setCategory('modern')}
      />
      <CustomizationSettingsItem
        section='customize'
        title='Luxury Presentation'
        edited={isCompleted('luxury')}
        completed={isCompleted('luxury')}
        onButtonClick={() => setCategory('luxury')}
      />
    </>
  );
};
