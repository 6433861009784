import { useTheme } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSelect, { ValueType, components } from 'react-select';

import { customStyles, CustomSelectStyles } from './Select.styles';

export interface SelectOption {
  value?: string | number | any;
  label: string;
  options?: { label: string; value: string | number }[];
}

export type SelectOptionChangeEventValue = ValueType<SelectOption, any>;

interface Props {
  selectedOption?: SelectOption;
  options: SelectOption[];
  disabled?: boolean;
  styles?: CustomSelectStyles;
  placeholder?: string;
  onChange: (selectedOption: SelectOptionChangeEventValue | SelectOptionChangeEventValue[]) => void;
  setIsMenuOpen?: Dispatch<SetStateAction<boolean>>;
  useDataFonts?: boolean;
  menuPlacementTop?: boolean;
  isMulti?: boolean;
  createable?: boolean;
  defaultValue?: SelectOption[];
  menuPlacement?: string;
  menuPortalTarget?: HTMLElement;
}

export const Select: React.FC<Props> = ({
  options,
  selectedOption,
  disabled,
  styles = {},
  useDataFonts,
  placeholder,
  createable,
  onChange,
  setIsMenuOpen,
  menuPlacementTop,
  isMulti,
  defaultValue,
  menuPlacement,
  menuPortalTarget,
}) => {
  const { colors } = useTheme();

  const Component = createable ? CreatableSelect : ReactSelect;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component
      defaultValue={defaultValue}
      value={selectedOption ? selectedOption : defaultValue}
      styles={customStyles(colors, styles, useDataFonts)}
      isDisabled={disabled}
      isSearchable={isMulti || false}
      placeholder={placeholder}
      isClearable={false}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary25: colors.gray[100],
          primary: colors.primary.main,
        },
      })}
      options={options}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      onChange={onChange}
      onMenuOpen={() => {
        if (setIsMenuOpen) setIsMenuOpen(true);
      }}
      onMenuClose={() => {
        if (setIsMenuOpen) setIsMenuOpen(false);
      }}
      menuPlacement={menuPlacementTop ? 'top' : menuPlacement ? menuPlacement : 'bottom'}
      menuPortalTarget={menuPortalTarget}
    />
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={['fas', 'chevron-down']} />
    </components.DropdownIndicator>
  );
};
