import styled from 'styled-components';

import { TSlide } from '../../types';
import { BackToHome } from './BackToHome';
import { DashboardHeader } from './DashboardHeader';
import { MainLayout } from '../MainLayout/MainLayout';

type Props = {
  presentationHash: string;
  children: React.ReactNode;
};

export function DashboardLayout({ presentationHash, children }: Props) {
  return (
    <Layout>
      <BackToHome />

      <Container>
        <DashboardHeader hash={presentationHash} />
        <Content>{children}</Content>
      </Container>
    </Layout>
  );
}

const Layout = styled(MainLayout)`
  max-width: ${({ theme }) => theme.breakpoints.max};
  padding: 28px 30px 0 30px;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
`;

const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
`;

const Content = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;
