import { IconName } from '../../../../../components/Common/Icon/Icon.types';
import { useUser } from '../../../../../components/Slide/providers/UserProvider';
import { PRESENTATION_TYPES } from '../../../../../constants/PresentationTypes';
import { presentationCards } from './presentationCards';

export const usePresentationCardsData = () => {
  const { feedIds } = useUser();
  const userHasFeeds = feedIds?.length > 0;

  const sellers = {
    title: 'Sellers',
    iconName: 'sellers' as IconName,
    cards: [presentationCards[PRESENTATION_TYPES.WIN_THE_LISTING]],
  };

  const buyersCards = userHasFeeds
    ? [
        presentationCards[PRESENTATION_TYPES.WIN_THE_REPRESENTATION],
        presentationCards[PRESENTATION_TYPES.BUYER_TOUR],
        presentationCards[PRESENTATION_TYPES.WIN_THE_OFFER],
      ]
    : [presentationCards[PRESENTATION_TYPES.WIN_THE_REPRESENTATION]];

  const buyers = {
    title: 'Buyers',
    iconName: 'buyers' as IconName,
    cards: buyersCards,
  };

  const anyone = {
    title: 'Anyone',
    iconName: 'play' as IconName,
    cards: [presentationCards[PRESENTATION_TYPES.PRESENT_INFO]],
  };

  const desktopData = userHasFeeds ? [sellers, buyers, anyone] : [buyers, anyone];

  const mobileData = userHasFeeds
    ? [
        sellers,
        { ...buyers, cards: [buyers.cards[0]] },
        { ...buyers, cards: [buyers.cards[1]] },
        { ...buyers, cards: [buyers.cards[2]] },
        anyone,
      ]
    : [{ ...buyers, cards: [buyers.cards[0]] }, anyone];

  return { desktopData, mobileData };
};
