import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper, ImagePreview } from './ThreeImagesAlpha.styles';

interface Props {
  reverse?: boolean;
}

export const ThreeImagesAlpha: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();

  return (
    <Container>
      <ImageWrapper style={{ gridColumn: reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}>
        <ImagePreview src={slide.data?.image} />
      </ImageWrapper>
      <SlideTextBlock
        isEditable={false}
        text={slide.data?.text}
        headline={slide.data?.headline}
        containerStyles={{ gridColumn: !reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}
      />
      <ImageWrapper style={{ gridRow: '2 / 2' }}>
        <ImagePreview src={slide.data?.image2} />
      </ImageWrapper>
      <ImageWrapper style={{ gridRow: '2 / 2' }}>
        <ImagePreview src={slide.data?.image3} />
      </ImageWrapper>
    </Container>
  );
};
