import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';

import { TCategory } from '../../../../types';
import { getShelflifeAverage } from '../../../../pages/PresentationCreate/dash/services/getShelfLifeAverage';
import { BasicModal } from '../../../../components/Common/BasicModal';
import ActiveShelfLife from '../../../../components/Slide/slides/marketAnalysis/marketOverview/components/activeShelfLife/web/ActiveShelfLife';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { MarketAssessmentCard } from './MarketAssessmentCard';
import bgImg from './assets/current-active-shelf-life-dark.svg?url';
import bgImgLight from './assets/current-active-shelf-life.svg?url';

interface ActiveShelfLifeGaugeProps {
  trackClick?: () => void;
  category?: TCategory;
  oneSheeterSlide?: boolean;
}

export const ActiveShelfLifeGauge = ({
  trackClick,
  category,
  oneSheeterSlide,
}: ActiveShelfLifeGaugeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const propertiesRaw = useAtomValue(reportPropertiesAtom);

  const properties = propertiesRaw.map(prop => prop.property);

  const value = getShelflifeAverage(properties);

  const openPopup = () => {
    setIsOpen(true);
    trackClick?.();
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const id = oneSheeterSlide ? 'oneSheeter' : 'marketOverview';
  const content = !value ? 'N/A' : `${value}`;
  const isDisabled = !value;

  return (
    <Fragment>
      <MarketAssessmentCard
        headline='Current Active Shelf Life'
        content={content}
        description='Average days comps are sitting on the market'
        bgImg={bgImg}
        bgImgLight={bgImgLight}
        clicked={openPopup}
        hasCircle
        disabled={isDisabled}
        oneSheeter={oneSheeterSlide}
        sub='Days'
      />

      <BasicModal id={id} isOpen={isOpen} onClose={closePopup}>
        <ActiveShelfLife category={category || 'traditional'} />
      </BasicModal>
    </Fragment>
  );
};
