import { TSection } from '../../../../types';

interface TSectionContentItem {
  title: string;
  description: string;
}

const sections: Record<TSection, TSectionContentItem> = {
  startingSlides: {
    title: '',
    description: '',
  },
  whyIAmTheRightFit: {
    title: 'Why I am the right Fit',
    description: 'My value proposition, expertise, and track record.',
  },
  whatsMyHomeWorth: {
    title: `What's my home worth?`,
    description: `Comparable properties, trends, features, public estimates and 
      outliers are highlighted to give the full pricing picture in a single visual.`,
  },
  marketingAndBuyerActivity: {
    title: 'Marketing & Buyer Activity',
    description: `Each home has its own marketing strategy based on supply and 
      demand, and understanding buyer activity is a critical component.`,
  },
  closingAndNextSteps: {
    title: 'Next Steps',
    description: `Let's recap, address questions, and align on our next steps.`,
  },
  marketAnalysis: {
    title: 'Market Analysis',
    description: `Trends, such as selling pace, inventory and active shelf-life, are analyzed to help provide perspective around pricing favoring buyers/sellers.`,
  },
};

export default sections;
