// interface Props {

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../../../components/Common/V2/Button/Button';
import { useAtom, useSetAtom } from 'jotai';
import { mapModeAtom } from '../../state/mapModeAtom';
import { searchAreaValueAtom } from '../../state/searchAreaValueAtom';
import { mapCoordinatesAtom } from '../../state/mapCoordinatesAtom';
import { areaDetailsAtom } from '../../state/areaDetailsAtom';
import { areaTypeAtom } from '../../state/areaTypeAtom';

interface Props {
  polygon: google.maps.Polygon | null;
  setPolygon: (polygon: google.maps.Polygon | null) => void;
  setDrawingMode: (mode: google.maps.drawing.OverlayType) => void;
}

export const DrawControl = ({ polygon, setPolygon, setDrawingMode }: Props) => {
  const [mapMode, setMapMode] = useAtom(mapModeAtom);
  const setSearchAreaValue = useSetAtom(searchAreaValueAtom);
  const setMapCoordinates = useSetAtom(mapCoordinatesAtom);
  const setAreaDetails = useSetAtom(areaDetailsAtom);
  const setAreaType = useSetAtom(areaTypeAtom);

  const isActive = mapMode === 'polygon';

  const onDraw = () => {
    polygon?.setMap(null);
    setPolygon(null);
    setMapMode('polygon');
    setSearchAreaValue('');
    setMapCoordinates(null);
    setAreaDetails(null);
    setAreaType(null);
    setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  };

  return (
    <Button
      variant='secondary'
      disabled={false}
      onClick={onDraw}
      prefixIcon={<FontAwesomeIcon icon={['far', 'pen-line']} />}
      style={{ opacity: isActive ? 1 : 0.5 }}
    >
      Draw
    </Button>
  );
};
