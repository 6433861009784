import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { TInputTarget } from '../AddNetSheet.component';
import { ColLeft, ColRight, ColRightItem } from './common.styles';
import { Input as SharedInput } from '../../../../../../components/Common/V2/Input';

export const InputSectionEl = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: `10px 20px 20px`,
});

const Input = styled(SharedInput)`
  flex: 1;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  font-size: 12px;
  font-weight: 500;
`;

interface InputsSectionProps {
  title: string;
  inputNamePrefix: string;
  isListPrice?: boolean;
  value1: number;
  value2: number;
  value3: number;
  onInputChange: (target: TInputTarget) => void;
  sectionName?: string;
}

export const InputsSection: React.FC<InputsSectionProps> = ({
  title,
  inputNamePrefix,
  isListPrice,
  value1,
  value2,
  value3,
  onInputChange,
  sectionName,
}) => {
  return (
    <InputSectionEl>
      <ColLeft>
        <Title>{title}</Title>
        {isListPrice && <Description>(required)</Description>}
      </ColLeft>
      <ColRight>
        <ColRightItem>
          <NumberFormat
            customInput={Input}
            value={value1 === 0 ? '' : value1}
            onValueChange={({ value }) =>
              onInputChange({
                value: value,
                order: '1',
                sectionName: sectionName,
                name: `${inputNamePrefix}`,
              })
            }
            thousandSeparator={true}
            prefix={'$'}
          />
        </ColRightItem>
        <ColRightItem>
          <NumberFormat
            customInput={Input}
            value={value2 === 0 ? '' : value2}
            onValueChange={({ value }) =>
              onInputChange({
                value: value,
                order: '2',
                sectionName: sectionName,
                name: `${inputNamePrefix}`,
              })
            }
            thousandSeparator={true}
            prefix={'$'}
          />
        </ColRightItem>
        <ColRightItem>
          <NumberFormat
            customInput={Input}
            value={value3 === 0 ? '' : value3}
            onValueChange={({ value }) =>
              onInputChange({
                value: value,
                order: '3',
                sectionName: sectionName,
                name: `${inputNamePrefix}`,
              })
            }
            thousandSeparator={true}
            prefix={'$'}
          />
        </ColRightItem>
      </ColRight>
    </InputSectionEl>
  );
};
