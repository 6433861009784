import { TPresentationType } from '../types';
import { useUrlSearchParam } from './useQueryParams';

type TPresentationCreationQuery = {
  type: TPresentationType;
  isRebuild: boolean;
  presentation?: string;
  presentationId?: string;
};

export function usePresentationCreationQuery(): TPresentationCreationQuery {
  const type = useUrlSearchParam('type') as TPresentationType;
  const action = useUrlSearchParam('action');
  const presentation = useUrlSearchParam('presentation') ?? '';
  const presentationId = useUrlSearchParam('presentation_id') ?? '';

  return {
    type,
    isRebuild: action === 'rebuild',
    presentation,
    presentationId,
  };
}
