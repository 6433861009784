import { useEffect } from 'react';
import { FullStory } from './FullStory';
import { useUser } from '../../components/Slide/providers/UserProvider';

export function useIdentifyFullstory() {
  const user = useUser();

  useEffect(() => {
    FullStory.identify(user);
  }, [user, user.userId]);
}
