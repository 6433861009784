import React from 'react';
import { Card } from './Card/Card';
import {
  Container,
  BackgroundWave,
  ContentContainer,
  Content,
  TouchPadImg,
  Logo,
  Text,
  CardsContainer,
  TouchPadWrapper,
} from './PropertyBoost.styles';
import wave_bg from './assets/wave_bg.png';
import touchpad_img from './assets/image.png';
import logoCP from './assets/logo.png';
import logoBT from './assets/logo_bt.png';
import { paragraphs, cards } from './data';
import { useBrandName } from '../../../../../hooks/useBrandName';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';

export const PropertyBoost: React.FC = () => {
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const { propertyBoost } = useBrandName();
  return (
    <Container>
      <ContentContainer>
        <Content>
          <Logo src={hasBtTheme ? logoBT : logoCP} />
          {paragraphs.map((p, i) => (
            <Text key={i}>{p.replaceAll('$brand', propertyBoost)}</Text>
          ))}
          <CardsContainer>
            {cards.map((c, i) => (
              <Card key={i} {...c} />
            ))}
          </CardsContainer>
        </Content>
        <TouchPadWrapper>
          <TouchPadImg src={touchpad_img} />
        </TouchPadWrapper>
      </ContentContainer>
      <BackgroundWave src={wave_bg} />
    </Container>
  );
};
