import React, { useMemo } from 'react';

import { Icon } from '../../../components/Common';
import {
  ThemeSelectionContainer,
  ThemeImagesWrapper,
  ThemeImage,
  ThemeImageWrapper,
  PreviewSlideWrapper,
  ThemeSelectionWrapper,
  CloseIconWrapper,
  ContentWrapper,
} from './ThemeSelectionDropdown.styles';

import { useUser } from '../../Slide/providers/UserProvider';
import { getCategoriesConfig } from '../../../constants/categories';
import PreviewSlide from '../../PreviewSlide/PreviewSlide.component';
import { useCategory } from '../../../providers/providers/CategoryProvider';
import { useSetUiConfigs } from '../../../providers/providers/UiConfigProvider';
import { DefaultConfigProvider } from '../../../services/DefaultConfigProvider';
import type { TCategory, TSlide, TSlideTheme } from '../../../types';
import { FrameThumbnailConfigsProvider } from '../../Slide/components/SlideFrame/frames/web/FrameThumbnailConfigsProvider';
import { usePreviewMode } from '../../../pages/PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { useUserCustomizations } from '../../../hooks/useUserCategoryCustomizations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';
import { ButtonsGroup } from '../../Common/V2/ButtonsGroup';

interface ThemeSelectionProps {
  isOpen: boolean;
  onClose: () => void;
  onChooseTheme: (theme: TSlideTheme) => void;
  selectedTheme: TSlideTheme;
  slide?: TSlide;
  isPositionLeft?: boolean;
}

export const ThemeSelectionDropdown: React.FC<ThemeSelectionProps> = ({
  isOpen,
  selectedTheme,
  onClose,
  onChooseTheme,
  slide,
  isPositionLeft,
}) => {
  const setUiConfigs = useSetUiConfigs();
  const categoryOptions = getCategoriesConfig();
  const { category, setCategory } = useCategory();
  const { hash } = useUser();
  const { previewMode } = usePreviewMode();

  const { colors } = useTheme();

  const { data: apiConfigs } = useUserCustomizations(hash);

  const onCategorySelect = React.useCallback(
    cat => {
      if (!cat) return;

      setCategory(cat);

      const uiConfigs =
        apiConfigs?.[cat as TCategory]?.ui ??
        DefaultConfigProvider.getConfigs()[cat as TCategory].ui;

      setUiConfigs(uiConfigs);
    },
    [apiConfigs, setCategory, setUiConfigs],
  );

  function renderThemes(themes: any[]) {
    if (!isOpen) return null;

    return themes.map(themeItem => (
      <ThemeImageWrapper key={themeItem.id} onClick={() => onChooseTheme(themeItem.id)}>
        {slide ? (
          <PreviewSlideWrapper isSelected={themeItem.id === selectedTheme}>
            <PreviewSlide slide={slide} theme={themeItem.id} infoPlacement='top' width={98} />
          </PreviewSlideWrapper>
        ) : (
          <ThemeImage
            src={themeItem.thumbnail}
            alt='Theme'
            isSelected={themeItem.id === selectedTheme}
          />
        )}
      </ThemeImageWrapper>
    ));
  }

  const options = useMemo(
    () =>
      categoryOptions?.map(categoryOption => {
        return {
          label: categoryOption.label,
          onClick: () => onCategorySelect(categoryOption?.value),
          isActive: category === categoryOption?.value,
        };
      }),
    [category, categoryOptions, onCategorySelect],
  );

  if (!category || !isOpen) return null;

  const themes = FrameThumbnailConfigsProvider.getByCategory(category);

  return (
    <ThemeSelectionContainer isOpen={isOpen} isPositionLeft={isPositionLeft}>
      <ThemeSelectionWrapper>
        <CloseIconWrapper>
          <FontAwesomeIcon
            icon={['fas', 'xmark']}
            onClick={onClose}
            fontSize={14}
            color={colors.v2.gray[400]}
            style={{ cursor: 'pointer' }}
          />
        </CloseIconWrapper>
        <ContentWrapper>
          <ButtonsGroup buttonsConfigs={options} />
          {previewMode === 'web' && <ThemeImagesWrapper>{renderThemes(themes)}</ThemeImagesWrapper>}
        </ContentWrapper>
      </ThemeSelectionWrapper>
    </ThemeSelectionContainer>
  );
};
