import styled from 'styled-components';
import { TitlePdf, ParagraphPdf } from '../../../common.styles';

export { TitlePdf, ParagraphPdf };

export const FloatWrapper = styled('div')({
  '&::before': {
    content: '""',
    display: 'block',
    float: 'left',
    height: '150px',
  },
});

const imageWidth = 400;
const imageHeight = imageWidth * 1.25;

export const ImageContainer = styled('div')({
  float: 'right',
  clear: 'both',
  width: imageWidth,
  height: imageHeight,
  paddingTop: '3%',
  paddingLeft: '3%',
});

export const Image = styled('div')({
  width: '100%',
  height: '100%',
  content: '" "',
  display: 'block',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
});

export const TextContainer = styled('div')({
  marginTop: 45,
  lineHeight: 'normal',
  fontSize: '16px',
  '& p': {
    padding: '10px 0',
  },
});

export const ContentWrapper = styled('div')({
  height: '100%',
  overflow: 'hidden',
});
