import { useEffect } from 'react';

import { TPresentation } from '../../../../types';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { useOrder } from '../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { usePresentationEditingSlides } from '../../../../hooks/usePresentationEditingSlides';
import { useSections } from '../../../../providers/providers/SectionsProvider';
import { usePresentationMode } from '../../../../providers/providers/PresentationModeProvider';
import { usePresentationType } from '../../../../providers/providers/PresentationTypeProvider';
import { OrderValidator } from '../../../../services/validators/OrderValidator';
import { PresentationConfigsFactory } from '../../../PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';

export function useSetSlidesSectionsAndOrderOnDashboard(presentation: TPresentation) {
  const { setSlides } = useSlides();
  const { setSections } = useSections();
  const { setSectionsOrder, setSectionsSlideOrder } = useOrder();

  const { presentationMode } = usePresentationMode();
  const { presentationType } = usePresentationType();

  const { hash } = useUser()!;
  const { isLoading, isFetching, data: slides } = usePresentationEditingSlides(hash, presentation);

  useEffect(() => {
    if (!presentationMode) return;

    const configs = PresentationConfigsFactory.create(presentationType, presentationMode);
    const validator = new OrderValidator(presentationType, presentationMode);

    const sectionsOrder = presentation?.[presentationMode]?.order?.sections
      ? validator.getPartialSectionsOrder(presentation?.[presentationMode]?.order?.sections ?? [])
      : configs.getDefaultOrder().sections;

    const sectionsSlide = presentation?.[presentationMode]?.order?.sectionsSlide
      ? validator.getPartialSectionsSlidesOrder(
          presentation?.[presentationMode]?.order?.sectionsSlide ?? {},
          slides ?? {},
        )
      : configs.getDefaultOrder().sectionsSlide;

    setSectionsOrder(sectionsOrder);
    setSectionsSlideOrder(sectionsSlide);
    setSections(presentation[presentationMode]?.sections ?? configs.getSections());
  }, [
    presentation,
    presentationMode,
    presentationType,
    setSections,
    setSectionsOrder,
    setSectionsSlideOrder,
    slides,
  ]);

  useEffect(
    function setSlidesOnLoad() {
      if (isLoading || isFetching || !presentationMode) return;

      setSlides(slides ?? {});
    },
    [isLoading, isFetching, presentationMode, setSlides, slides],
  );
}
