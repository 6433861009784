export const listItems = [
  {
    title: 'Strategic Pricing',
    text: 'An agent will help provide a Comparative  Market Analysis (CMA) to illustrate the multiple perspectives necessary to account for when pricing a home.',
  },
  {
    title: 'Marketing',
    text: 'Each property requires a different marketing package based on the supply and demand of the property. It can include staging, open houses, mailers, social media, and more.',
  },
  {
    title: 'Choosing an Offer',
    text: 'An agent will present the benefits and risks of each offer. After reviewing an offer, you can accept, decline, or offer a counter on price and/or terms.',
  },

  {
    title: 'Inspection',
    text: 'Buyers are expected to perform due diligence to satisfy inspection, appraisal, and loan contingencies.',
  },
];

export const subtitle =
  'At a high level, the home selling process can be structured as four key steps:';

export const graphItems = [
  {
    shortText: 'Meet with Agent',
    longText: 'Meet with a real estate professional',
  },
  {
    shortText: 'price',
  },
  {
    shortText: 'prepare home',
    longText: 'prepare your home',
  },
  {
    shortText: 'list for sale',
  },
  {
    shortText: 'offers',
  },
  {
    shortText: 'under contract',
  },
  {
    shortText: 'DUE DILIGENCE',
  },
  {
    shortText: 'closing',
  },
];
