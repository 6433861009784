export class ApiMapper {
  static apiToEntity(apiEntity) {
    console.warn('Implement API to entity mapping!');
    return apiEntity;
  }

  static entityToApi(entity) {
    console.warn('Implement entity to API mapping!');
    return entity;
  }
}
