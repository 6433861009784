import styled from 'styled-components';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../../providers/providers/UiConfigFontProvider';

interface Props {
  displayImage?: boolean;
}

export const LogosWrapper = styled('div')<Props>(({ displayImage }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  maxWidth: displayImage ? '40%' : '60%',
}));

export const Text = styled('h2')<Props>(({ displayImage }) => ({
  fontFamily: useUiConfigFontsHeader() ?? 'Arial',
  textAlign: displayImage ? 'left' : 'center',
  width: displayImage ? 'max-content' : '100%',
  fontSize: displayImage ? 17 : 16,
  textTransform: 'uppercase',
  color: '#000',
  fontWeight: 400,
  marginBottom: 16,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));
