import React from 'react';

import { IconName } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleButton, ButtonsWrapper } from './controls.styles';
import { TToolbarSize } from '../TextEditor.component';

export type TBlockType = 'unordered';

type TBlockStyleOptions = {
  style: TBlockType;
  icon: string;
};

interface BlockControlsProps {
  currentState: { listType: TBlockType | undefined };
  onChange: (value: TBlockType) => void;
  toolbarSize?: TToolbarSize;
}

export const BlockStyleControls: React.FC<BlockControlsProps> = ({
  currentState,
  onChange,
  toolbarSize,
}) => {
  const options: TBlockStyleOptions[] = [
    {
      style: 'unordered',
      icon: 'list-ul',
    },
  ];

  return (
    <ButtonsWrapper toolbarSize={toolbarSize}>
      {options.map(option => (
        <StyleButton
          key={option.style}
          isActive={option.style === currentState.listType}
          onClick={() => onChange(option.style)}
          toolbarSize={toolbarSize}
        >
          <FontAwesomeIcon icon={['fas', option.icon as IconName]} />
        </StyleButton>
      ))}
    </ButtonsWrapper>
  );
};
