import styled from 'styled-components';

export const NoImage = styled('img')({
  width: '100%',
  display: 'block',
});

export const Gallery = styled('div')({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, auto)',
  rowGap: 16,
  columnGap: 21,
});

export const GalleryImage = styled('div')({
  background: 'no-repeat center center',
  '-webkit-background-size': 'cover',
  backgroundSize: 'cover',
  width: '100%',
  ' &::before ': {
    content: '""',
    display: 'inline-block',
    width: 1,
    height: 0,
    paddingBottom: '79%',
  },
});
