import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getOrderedCards } from '../../data';
import { Card } from '../../../../../components/Card/Card.component';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '300px 300px',
  rowGap: 20,
  columnGap: 20,
  padding: '4%',
});

export const HomeBuyingProcessModern: React.FC = () => {
  const orderedCards = useMemo(() => getOrderedCards(), []);
  return (
    <Grid>
      {orderedCards.map((card, i) => (
        <Card key={i} {...card} />
      ))}
    </Grid>
  );
};
