import React from 'react';
import styled from 'styled-components';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { Typography } from '../../../../../../Common';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { Card } from '../../../../../components/Card/Card.component';
import { cards } from '../../data';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: 'repeat(5, 115px)',
  rowGap: 20,
  columnGap: 20,
  padding: '4%',
});

const CardItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 5,
  padding: '0 7%',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1'),
}));

export const WhatFactorsInfluencePricingModern: React.FC = () => {
  return (
    <Grid>
      {cards.map((card, i) =>
        i === 0 ? (
          <Card key={i} {...card} />
        ) : (
          <CardItem key={i}>
            <Typography
              color='400'
              style={{ fontSize: 23, fontWeight: 700, textTransform: 'uppercase' }}
            >
              {card.title}
            </Typography>
            <img src={card.icon} style={{ flexShrink: 0 }} />
          </CardItem>
        ),
      )}
    </Grid>
  );
};
