import styled from 'styled-components';

import { Checkbox } from '../../../../../../../../../../components/Common/Checkbox/Checkbox.component';

type Props = {
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
  text: string;
  additionalText: string;
};

export function SuperAccountCheckboxAndInfo({
  isChecked,
  setIsChecked,
  text,
  additionalText,
}: Props) {
  return (
    <MainContainer>
      <Container>
        <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} />
        <Text>{text}</Text>
      </Container>
      <Text>{additionalText}</Text>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.v2.gray[400]};
  gap: 5px;
`;

const Container = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  align-self: stretch;
  width: 100%;
`;

const Text = styled.p`
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.contrastText};
`;
