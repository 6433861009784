import styled from 'styled-components';

export const TableContainer = styled('div')({
  width: '100%',
  height: '100%',
  border: '1px solid #ececec',
});

export const TableItem = styled('div')({
  padding: 7.5,
  display: 'flex',
  borderBottom: '1px solid #ececec',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:nth-of-type(12n)': {
    borderBottom: 'none',
  },
});

export const PropertyDetailsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ImageWrapper = styled('div')({
  position: 'relative',
  width: 115,
  paddingLeft: 15,
  marginRight: 22,
});

export const Image = styled('img')({
  width: '100%',
});

export const PropertyDetailsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const PropertyDetails = styled('div')({
  display: 'flex',
  marginBottom: 7,
});

export const PropertyDetail = styled('div')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  color: theme.colors.gray[400],
  textTransform: 'uppercase',
  '&:not(:last-of-type)': {
    paddingRight: 5,
    marginRight: 5,
    borderRight: `1px solid ${theme.colors.gray[400]}`,
  },
}));

export const Address = styled('div')(({ theme }) => ({
  fontSize: 18,
  color: theme.colors.gray[400],
}));

export const RatingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 328,
});

export const Text = styled('div')(({ theme }) => ({
  fontSize: 16,
  color: theme.colors.gray[400],
  marginBottom: 10,
  fontWeight: 700,
}));

export const RatingWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const RatingText = styled('div')(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.gray[400],
  textTransform: 'uppercase',
  paddingLeft: 10,
}));

export const CirclesContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Circle = styled('div')({
  width: 15,
  height: 15,
  border: '1px solid #c4c4c4',
  borderRadius: '50%',
  '&:not(:last-of-type)': {
    marginRight: 5,
  },
});
