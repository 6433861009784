import { RawReport, TPresentation } from '../../../types';
import { usePresentationFromParams } from './usePresentationFromParams';

export const useGetProperties = () => {
  const { data: presentation } = usePresentationFromParams();

  const report = (presentation as TPresentation)?.reportData?.report?.reportDetailed as RawReport;

  return (presentation as TPresentation)?.version === 2
    ? (presentation as TPresentation)?.comparableProperties
    : report?.properties;
};
