import React, { useState } from 'react';
import { AddClientForm } from '../../../../../../components/Common/AddClientForm/AddClientForm.component';
import { AutosuggestClients } from '../../../../../../components/Common/AutosuggestClients/AutosuggestClients.component';
import { useClient } from '../../../../../../providers/providers/ClientProvider';
import { AddClientContainer, AddClientManuallyLink, AddClientTitle } from './AddClient.styles';
import { PresentationTypeProvider } from '../../../../../../providers/providers/PresentationTypeProvider';
import { PRESENTATION_TYPES } from '../../../../../../constants/PresentationTypes';
import { useBrandName } from '../../../../../../hooks/useBrandName';
import { FEATURES, useHasFeatureFlag } from '../../../../../../hooks/useFeatureFlags';

export const AddClient = () => {
  const { core } = useBrandName();
  const { client, setClient } = useClient();
  const [openForm, setOpenForm] = useState(false);
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const linkText = shouldUseBtTheme
    ? 'Don’t have a client created? Click here'
    : `Don’t have a ${core} client created? Click here`;

  return (
    <AddClientContainer>
      <AddClientTitle>Prepared For</AddClientTitle>
      <AutosuggestClients
        setClient={setClient}
        initialValue={client?.name ?? ''}
        placeholder='Enter client name'
      />
      <AddClientManuallyLink onClick={() => setOpenForm(true)}>{linkText}</AddClientManuallyLink>
      <PresentationTypeProvider initValue={PRESENTATION_TYPES.BUYER_TOUR}>
        <AddClientForm isOpen={openForm} onClose={() => setOpenForm(false)} setClient={setClient} />
      </PresentationTypeProvider>
    </AddClientContainer>
  );
};
