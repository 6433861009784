import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
  padding: 25,
  overflowY: 'auto',
  border: `1px solid ${theme.colors.v2.gray[200]}`,
  borderRadius: 5,
  backgroundColor: theme.colors.v2.background,
  overflow: 'auto',
}));

export const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 24,
  paddingBottom: 30,
});

export const NoSlidesText = styled('p')(({ theme }) => ({
  color: theme.colors.gray[400],
  fontSize: 16,
  textAlign: 'center',
}));

export const SlideContainer = styled('div')<any | any>(({ theme, disabled }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.colors.gray[200]}`,
  padding: '48px 19px 10px 16px',
  position: 'relative',
  minWidth: 274,
  maxWidth: 317,
  height: 'auto',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
  opacity: !disabled ? 0.4 : 1,
  cursor: 'pointer',
  transition: 'all .3s',
  overflow: 'hidden',
  '&:hover': {
    boxShadow: '3px 6px 14px rgba(0, 0, 0, 0.2)',
  },
}));
