import { useMemo } from 'react';
import styled from 'styled-components';

import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { data } from '../../data';
import slideGraphics from './slideGraphics';
import bg from './bg.png';

const headingStyles = {
  fontSize: 18,
  fontWeight: 800,
  lineHeight: '24px',
  color: '#444',
};

const textStyles = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '22px',
  color: '#101010',
};

export const WhyIAmTheRightBuyersAgentTraditional = () => {
  const primary = useUiConfigColorsPrimary();

  const img = useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  return (
    <ImageWrapper>
      <ShapeImg src={img} />
      <Title color={primary}>why i am the right</Title>
      <Title color={primary} isSubText>
        buyer’s agent
      </Title>
      <TraditionalSlideCircularCard
        heading={data[0].title}
        text={data[0].text}
        position={{ left: 125, bottom: 26 }}
        textStyles={{ ...textStyles, lineHeight: '18px', width: 203 }}
        headingStyles={{ ...headingStyles, lineHeight: '20px', width: 205 }}
        containerStyles={{ padding: 0 }}
        size={215}
      />
      <TraditionalSlideCircularCard
        heading={data[1].title}
        text={data[1].text}
        position={{ left: 175, bottom: 324 }}
        textStyles={{ ...textStyles, lineHeight: '18px', width: 200 }}
        headingStyles={{ ...headingStyles, width: 220 }}
        containerStyles={{ padding: 0 }}
        size={230}
      />
      <TraditionalSlideCircularCard
        heading={data[2].title}
        text={data[2].text}
        position={{ left: 742, top: 25 }}
        textStyles={{ ...textStyles, lineHeight: '21px' }}
        headingStyles={{ ...headingStyles, fontSize: 17 }}
        containerStyles={{ padding: 0 }}
        size={210}
      />
      <TraditionalSlideCircularCard
        topText='Strong'
        heading='negotiation skills'
        text={data[3].text}
        position={{ right: 372, bottom: 339 }}
        topTextStyles={{ color: '#444', fontSize: 17 }}
        textStyles={{ ...textStyles, width: 215 }}
        headingStyles={{ ...headingStyles, fontSize: 17 }}
        containerStyles={{ padding: 0 }}
        size={235}
      />
      <TraditionalSlideCircularCard
        topText='Coordinating'
        heading='with Professionals'
        text={data[4].text}
        position={{ right: 150, top: 19 }}
        topTextStyles={{ color: '#444', fontSize: 16.5 }}
        textStyles={{ ...textStyles, lineHeight: '21px', width: 200, fontSize: 14 }}
        headingStyles={{ ...headingStyles, fontSize: 16.5 }}
        containerStyles={{ padding: 0 }}
        size={235}
      />
      <TraditionalSlideCircularCard
        heading={data[5].title}
        text={data[5].text}
        position={{ right: 118, top: 275 }}
        textStyles={{ ...textStyles, fontSize: 13.5, width: 215 }}
        headingStyles={headingStyles}
        containerStyles={{ padding: 0 }}
        size={235}
      />
      <TraditionalSlideCircularCard
        topText='Protecting'
        heading='Your Interests'
        text={data[6].text}
        position={{ right: 297, bottom: 55 }}
        topTextStyles={{ color: '#444', fontSize: 18 }}
        textStyles={textStyles}
        headingStyles={headingStyles}
        containerStyles={{ padding: 0 }}
        size={205}
      />
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url('${bg}');
  background-position: bottom;
  background-size: cover;
`;

const ShapeImg = styled.img`
  margin: 0 auto;
  display: block;
  bottom: -1px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1340px;
`;

const Title = styled.h2<{ color: string; isSubText?: boolean }>`
  color: ${props => props.color};
  position: absolute;
  top: ${props => (props.isSubText ? '85px' : '40px')};
  left: 50px;
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 800;
  line-height: 43px;
  letter-spacing: 2px;
`;
