import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styles from './CompResults.module.scss';
import { useIsReportInStore, usePresentation } from '../../../../../../../hooks/usePresentation';
import { useSetPresentationHash } from '../../../../../../../state/presentationHash/useSetPresentationHash';
import { PropertyModal } from '../../../../../../../features/report/components/PropertyModal/PropertyModal';
import { isPropertyModalOpenAtom } from '../../../../../../../features/report/state/isPropertyModalOpenAtom';
import { useSyncStateWithRedux } from '../../../../../../../features/report/hooks/useSyncStateWithRedux';
import { ResultsHeader } from '../../../../../../../features/report/results-header/ResultsHeader';
import { ScaleOverviewReport } from '../../../../../../../features/report/scale-overview/ScaleOverviewReport';
import { MarketAssessmentReport } from '../../../../../../../features/report/market-assessment/MarketAssessmentReport';
import { useSetPresentationToState } from '../../../../../../../features/report/hooks/useSetPresentationToState';

export const CompResultsPresentation = () => {
  const { presentationId } = useParams();
  const { isLoading, data } = usePresentation(presentationId);
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useAtom(isPropertyModalOpenAtom);

  const dispatch = useDispatch();

  useSetPresentationHash(presentationId);
  const isReportInStore = useIsReportInStore();

  useSyncStateWithRedux();
  useSetPresentationToState(data, false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!data) return;

    dispatch({ type: 'GLOBAL_STORE_SET', payload: data?.reportData });
  }, [data]);

  if (isLoading || !isReportInStore || !data) return null;

  return (
    <>
      <ResultsHeader />
      <div className={styles.compResults}>
        <ScaleOverviewReport />
        <MarketAssessmentReport />

        <PropertyModal
          isModalOpen={isPropertyModalOpen}
          onCloseModal={() => setIsPropertyModalOpen(false)}
        />
      </div>
    </>
  );
};
