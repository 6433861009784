import styled from 'styled-components';
import { useUiConfigFontsBody } from '../../../../../../../../providers/providers/UiConfigFontProvider';

interface WithLuxury {
  isLuxury?: boolean;
}

export const Container = styled('div')<WithLuxury>(({ isLuxury }) => ({
  width: '100%',
  border: isLuxury ? 'none' : '0.5px solid #CCCCCC',
  borderRadius: isLuxury ? 'none' : 5,
}));

export const BrokerInfoContainer = styled('div')<WithLuxury>(({ isLuxury }) => ({
  padding: '40px 25px',
  display: 'flex',
  justifyContent: isLuxury ? 'center' : 'flex-start',
}));

export const BrokerInfoWrapper = styled('div')({
  width: '100%',
});

export const BrokerContactContainer = styled('div')<WithLuxury>(({ isLuxury }) => ({
  backgroundColor: 'rgba(65, 65, 65, 0.05)',
  display: 'flex',
  height: 172,
  padding: '40px 25px 20px 25px',
  flexDirection: 'column',
  flexWrap: 'wrap',
  border: isLuxury ? '1px solid #DDDDDD' : 'none',
  fontFamily: useUiConfigFontsBody(),
}));
