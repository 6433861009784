import React, { useMemo } from 'react';
import {
  TrackingEvent,
  TRACKING_EVENTS,
  NEXT_STEPS_TYPE,
} from '../../../../../../../../services/TrackingEvent';
import { TPresentation } from '../../../../../../../../types';
import { NextStepModal } from '../components/NextStepModal/NextStepModal.component';

import { Text, Button } from './GetStartedModal.styles';
import { PRESENTATION_TYPES } from '../../../../../../../../constants/PresentationTypes';

interface GetStartedModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  data?: TPresentation;
}

export const GetStartedModal: React.FC<GetStartedModalProps> = ({ isModalOpen, onClose, data }) => {
  const onViewContract = () => {
    TrackingEvent.click(
      'Clicked "View Contract" button on "Next Steps" slide',
      TRACKING_EVENTS.NEXT_STEPS_ELEMENT_CLICKED,
      NEXT_STEPS_TYPE.LETS_GET_STARTED,
    );

    if (data?.agreementLink) window.open(data?.agreementLink, '_blank');
  };

  const agreementLinkText = useMemo(() => {
    const isWinTheOffer = data?.type === PRESENTATION_TYPES.WIN_THE_OFFER;
    const isPresentInfo = data?.type === PRESENTATION_TYPES.PRESENT_INFO;
    const isWTR = data?.type === PRESENTATION_TYPES.WIN_THE_REPRESENTATION;

    if (isWinTheOffer) {
      return 'Please find the buyer’s agreement here';
    }

    if (isPresentInfo) {
      return 'Please find the agreement here';
    }

    if (isWTR) {
      return 'Please find the buyer representation contract here';
    }

    return 'Please find the listing agreement here';
  }, [data?.type]);

  const buttonText = useMemo(() => {
    const isWTR = data?.type === PRESENTATION_TYPES.WIN_THE_REPRESENTATION;

    if (isWTR) {
      return 'Contract link';
    }

    return 'Agreement link';
  }, [data?.type]);

  return (
    <NextStepModal
      isModalOpen={isModalOpen}
      onClose={onClose}
      onConfirmButtonClick={onClose}
      confirmButtonText='OK'
      headerTitle="Let's get started"
      hideDownloadPdfButton
    >
      {data?.agreementLink ? (
        <>
          <Text>{agreementLinkText}</Text>
          <Button onClick={onViewContract}>{buttonText}</Button>
        </>
      ) : (
        <Text>I will be in touch with next steps.</Text>
      )}
    </NextStepModal>
  );
};
