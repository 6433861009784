import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
} from '../../../../../../providers/providers/UiConfigFontProvider';

interface SlideInfoProps {
  isPdf?: boolean;
}
const SlideInfo = styled.div<SlideInfoProps>`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  font-size: ${({ isPdf }) => (isPdf ? '14px' : '18px')};
  line-height: ${({ isPdf }) => (isPdf ? 1.3 : '34px')};
  text-align: left;
  letter-spacing: ${() => useUiConfigBodyLetterSpacing()};

  & > h3 {
    font-weight: 900;
    text-transform: uppercase;
    color: ${() => useUiConfigColorsPrimary()};
    font-size: ${({ isPdf }) => (isPdf ? '15px' : '20px')};
    line-height: ${({ isPdf }) => (isPdf ? '25px' : '34px')};
    font-family: ${() => useUiConfigFontsHeader()};
    letter-spacing: 'normal';
  }
`;

interface SlideDescriptionProps {
  isPdf?: boolean;
}

export const SlideDescription: React.FC<SlideDescriptionProps> = ({ isPdf = false }) => (
  <SlideInfo isPdf={isPdf}>
    <h3>comparative market analysis</h3>
    Simply put, a Comparative Market Analysis (CMA) is used to help estimate the value of a home. A
    CMA helps identify & analyze a property’s value based on sales of “comparable” homes and market
    trends.
  </SlideInfo>
);

export const SlideDescriptionBT: React.FC<SlideDescriptionProps> = ({ isPdf = false }) => (
  <SlideInfo isPdf={isPdf}>
    <h3> Buyer Tour</h3>
    The goal of a Buyer Tour is to identify features of interest and find a property to make an
    offer on.
  </SlideInfo>
);
