export const title = 'Why i am the right buyer’s agent';
export const subtitle =
  "With a proven track record and a commitment to excellence, I bring unmatched service. Here's why I stand out as a reliable and trustworthy partner:";

export const data = [
  {
    title: 'Experience & expertise',
    text: 'With extensive real estate experience, I bring valuable knowledge and expertise, successfully assisting numerous clients in finding their dream homes.',
  },
  {
    title: 'Local market knowledge',
    text: 'I have a deep understanding of the local real estate market, including neighborhood trends, property values, and off-market opportunities. ',
  },
  {
    title: 'Client-Centered Approach',
    text: 'My commitment to client-first service ensures prioritizing your needs and preferences in the homebuying process.',
  },
  {
    title: 'Strong negotiation skills',
    text: 'My strong bargaining skills will secure the best deal, protecting your interests.',
  },
  {
    title: 'Coordinating with Professionals',
    text: "I'll collaborate with professionals like inspectors, appraisers, and mortgage brokers for a smooth closing process.",
  },
  {
    title: 'Facilitating Communication',
    text: "I'll ensure transparent communication among all parties involved, keeping you informed about the transaction's progress.",
  },
  {
    title: 'Protecting Your Interests',
    text: "I'll always act in your best interests, disclose any conflicts, explain your rights and obligations, and prioritize your goals.",
  },
];
