import styled, { CSSObject } from 'styled-components';
import { TStages } from './HomeStagingGraph.component';

type WithStageValue = {
  stage: TStages;
  colors: string[];
};

const getLabelItemStyles = (importance: TStages, colors: string[]): CSSObject => {
  switch (importance) {
    case 'Very Important':
      return {
        backgroundColor: colors[0],
        color: '#fff',
      };
    case 'Somewhat Important':
      return {
        backgroundColor: colors[1],
        color: '#fff',
      };
    case 'Neutral':
      return {
        backgroundColor: colors[2],
        color: '#000',
      };
    case 'Not Very Important':
      return {
        backgroundColor: colors[3],
        color: '#000',
      };
    case 'Not At All Important':
      return {
        backgroundColor: colors[4],
        color: '#000',
      };
    default:
      return {};
  }
};

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const GraphContainer = styled('div')({
  padding: '45px 73px 30px 55px',
  height: '70%',
});

export const LegendContainer = styled('div')({
  height: 100,
  borderTop: '2px solid #F3F3F3',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingRight: 90,
});

export const LegendTitle = styled('div')({
  fontSize: 14,
  textTransform: 'uppercase',
  color: '#000',
  fontWeight: 500,
  marginRight: 12,
});

export const LegendItemsContainer = styled('div')({
  width: '76.3%',
  display: 'flex',
  height: 40,
  borderLeft: '1px solid #CBCBCB',
  borderRight: '1px solid #CBCBCB',
  padding: '5px 0',
});

export const LegendItem = styled('div')<WithStageValue>(({ stage, colors }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: 12,
  ...getLabelItemStyles(stage, colors),
}));
