import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { AddSlides } from '../../../AddSlides/AddSlides';
import { OrderableSlide } from './OrderableSlide/OrderableSlide.component';
import { usePreviewMode } from '../../../../providers/PreviewModeProvider';
import { TSectionConfig, TSlide, TSlideId } from '../../../../../../../types';
import { Body, BodyContent, TheContent } from './DynamicOrderableSlides.styles';
import { useSlides } from '../../../../../../../providers/providers/SlidesProvider';
import { usePresentationType } from '../../../../../../../providers/providers/PresentationTypeProvider';
import { SlidePropsProvider } from '../../../../../../../components/Slide/providers/SlideProvider';

interface DynamicOrderableSlidesProps {
  notRemovable?: TSlideId[];
  notDraggable?: TSlideId[];
  preventDropOnPosition?: number[];
  slides: Record<string, TSlide>;
  order: TSlideId[];
  onOrderChange: (updatedSlides: TSlide[]) => void;
  onRemoveSlide: (slide: TSlide) => void;
  section: TSectionConfig;
  open: boolean;
  showSectionContents?: boolean;
}

export const DynamicOrderableSlides: React.FC<DynamicOrderableSlidesProps> = React.memo(
  function DynamicOrderableSlides({
    notRemovable,
    notDraggable,
    preventDropOnPosition,
    slides,
    order,
    onOrderChange,
    section,
    onRemoveSlide,
    open,
    showSectionContents,
  }) {
    const { previewMode } = usePreviewMode();
    const { slides: allSlides } = useSlides();
    const { presentationType } = usePresentationType();

    // const isCoverSlidesSection = section.id === 'startingSlides';

    const totalSlides = Object.values(allSlides as Partial<Record<TSlideId, TSlide>>);

    const sectionSlides = order.map(slideId => slides[slideId]);

    const onDragEnd = (result: DropResult): void => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const updatedSlides = [...sectionSlides];

      const sourceIndex = Object.keys(updatedSlides).find(
        (key: any) => updatedSlides[key].id === result.draggableId,
      );
      if (!sourceIndex) {
        return;
      }
      const destinationSlide = sectionSlides[result.destination.index] ?? undefined;
      if (!destinationSlide) {
        return;
      }

      const destinationIndex = Object.keys(updatedSlides).find(
        (key: any) => updatedSlides[key].id === destinationSlide.id,
      );

      if (preventDropOnPosition?.includes(result.destination.index)) return;

      const movingItem = updatedSlides.splice(Number(sourceIndex), 1);
      updatedSlides.splice(Number(destinationIndex), 0, movingItem[0]);
      onOrderChange(updatedSlides);
    };

    let totalSlidesNumber = order.length;
    if (showSectionContents) totalSlidesNumber += 1;

    const hideButton = presentationType === 'presentInfo' ? false : section.id === 'startingSlides';

    return (
      <Body contentheight='calc(100% + 69px)' open={open ? 1 : 0}>
        <BodyContent>
          <TheContent>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={section.id} type={section.id} key={section.id}>
                {(provided, snapshot) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {order.map((slideId, i) => {
                      const slide = slides[slideId];

                      if (!slide) return;

                      return (
                        <SlidePropsProvider slide={slide} key={slideId}>
                          <OrderableSlide
                            showSectionContents={showSectionContents}
                            slide={slide}
                            index={i}
                            sectionId={section.id}
                            onRemoveSlide={onRemoveSlide}
                            notDraggable={notDraggable}
                            notRemovable={notRemovable}
                          />
                        </SlidePropsProvider>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {!hideButton && <AddSlides section={section.id} />}
          </TheContent>
        </BodyContent>
      </Body>
    );
  },
);
