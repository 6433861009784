import text from './text';
import colors from './colors';
import typography from './typography';
import shadows from './shadows';
import { spacing } from '../utils';
import { Theme } from '../types';
import { breakpoints } from '../breakpoints';

export const CORE_PRESENT_THEME_NAME = 'core-present';

const theme: Theme = {
  name: CORE_PRESENT_THEME_NAME,
  colors,
  text,
  typography,
  spacing,
  shadows,
  breakpoints,
};

export default theme;
