import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useNotesUpdate } from './useNotesUpdate';
import { TProperty } from '../../../../../../types';
import { Button } from '../../../../../../components/Common';
import { Textarea } from '../../../../../../components/Common/V2/Textarea';
import { useUrlSearchParam } from '../../../../../../hooks/useQueryParams';
import { SectionDropdown } from '../../../../../../components/Property/DetailDescription/components/SectionDropdown';
import { DropdownKeys } from '../DetailDescription';

const btnStyle = {
  height: 35,
  padding: '0 10px',
  fontSize: 14,
  boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
};

interface Props {
  property: TProperty;
  note: string;
  openedDropdown: string;
  setOpenedDropdown: (value: string) => void;
}

export const NotesCreation = ({ property, note, openedDropdown, setOpenedDropdown }: Props) => {
  const source = useUrlSearchParam('source');
  const { mutate, isLoading } = useNotesUpdate();
  const [newNote, setNewNote] = useState(note || '');

  useEffect(() => setNewNote(note), [note]);

  const onSave = async () => {
    if (!newNote.length) return;

    mutate({ comparablePropertyId: property.id, note: newNote });
  };

  const onDelete = async () => {
    setNewNote('');
    mutate({ comparablePropertyId: property.id, note: '' });
  };

  const notEdited = note === newNote;
  const emptyNote = !newNote.length;
  const hasNote = !!note.length || !!newNote.length;

  if (source) return null;

  return (
    <SectionDropdown
      title={'Notes'.toUpperCase()}
      isOpen={openedDropdown === DropdownKeys.NOTES}
      setOpen={() => {
        if (openedDropdown === DropdownKeys.NOTES) return setOpenedDropdown('');
        setOpenedDropdown(DropdownKeys.NOTES);
      }}
      containerStyle={{ gap: 15 }}
    >
      <Wrapper>
        <Textarea
          value={newNote}
          onChange={e => setNewNote(e.target.value)}
          placeholder='Add notes'
          disabled={isLoading}
        />
        <ButtonsWrapper>
          <Button
            btnstyle='option'
            style={btnStyle}
            onClick={onDelete}
            disabled={!hasNote || isLoading}
          >
            Delete
          </Button>
          <Button
            disabled={notEdited || emptyNote || isLoading}
            btnstyle='primary'
            style={btnStyle}
            onClick={onSave}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </SectionDropdown>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 7px;
`;
