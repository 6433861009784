import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

export const NetProceedsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const NetProceedsWrapper = styled('div')(() => ({
  border: '1px solid #ddd',
  borderRadius: 5,
  padding: '24px',
  marginBottom: 16,
}));

export const NetProceedsTitle = styled('h4')(({ theme }) => ({
  fontSize: 24,
  textTransform: 'uppercase',
  marginBottom: 10,
  textAlign: 'start',
  fontFamily: useUiConfigFontsHeader(),
  color: useUiConfigColorsPrimary(),
  letterSpacing: useUiConfigHeaderLetterSpacing('0.11em'),
}));

export const NetProceedsText = styled('p')(({ theme }) => ({
  fontSize: 14,
  color: '#000',
  lineHeight: 1.6,
  textAlign: 'justify',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const NetProceedsCards = styled('div')({
  display: 'flex',
});
