import { CSSProperties } from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  value: string;
  hide?: boolean;
  valueStyle?: CSSProperties;
}

export const IntroItem = ({ label, value, hide, valueStyle }: Props) => {
  if (hide) return null;
  return (
    <FlexWrapper>
      <Text>{label}</Text>
      <Text style={valueStyle}>{value}</Text>
    </FlexWrapper>
  );
};

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Text = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;
