import styled from 'styled-components';
import { Button, Typography } from '../../../../../../../../../../../components/Common';

export const Container = styled('div')(({}) => ({
  width: 500,
  minHeight: 450,
  maxHeight: '50%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 20,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.325294)',
  borderRadius: 8,
  backgroundColor: '#fff',
}));

export const Content = styled.div({
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
});

export const Icon = styled.img`
  margin-right: 10px;
  height: 40px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

export const Headline = styled(Typography)`
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center;
`;

export const Description = styled(Typography)`
  margin: 10px 0;
  line-height: 1.28;
  display: flex;
  align-items: center;
`;

export const Main = styled.div`
  width: 100%;
  padding: 0 20px 20px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 10px 0;
  margin: 10px 20px 0;
  box-sizing: border-box;
  border-top: 1px solid #e6e6e6;
`;

export const ActionButton = styled(Button)`
  justify-content: center;
  width: 190px;
`;
