import { FC, CSSProperties } from 'react';
import styled from 'styled-components';
import btTheme from '../../../../config/themes/bold-trail/colors';
import cpTheme from '../../../../config/themes/core-present/colors';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';

interface LegendProps {
  options: {
    style?: CSSProperties;
    text?: string;
    circular?: boolean;
    className?: string;
    secondary?: boolean;
    green?: boolean;
    orange?: boolean;
    red?: boolean;
    blue?: boolean;
  }[];
}

export const Legend: FC<LegendProps> = ({ options }) => {
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  return (
    <Wrapper>
      {options.map((option, index) => (
        <LegendItem className={option.className} key={`${option.text}_${index}`}>
          <SquareOrCircle
            style={option.style}
            circular={option.circular}
            secondary={option.secondary}
            green={option.green}
            orange={option.orange}
            red={option.red}
            blue={option.blue}
            shouldUseBtTheme={shouldUseBtTheme}
          />
          {option.text}
        </LegendItem>
      ))}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 26px;
  margin-bottom: 10px;
`;

const LegendItem = styled.div`
  display: flex;
  color: #010101;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  gap: 16px;
`;

const SquareOrCircle = styled.div<{
  circular?: boolean;
  secondary?: boolean;
  green?: boolean;
  orange?: boolean;
  red?: boolean;
  blue?: boolean;
  shouldUseBtTheme?: boolean;
}>`
  width: 15px;
  height: 15px;
  background: ${({ theme }) => theme.colors.v2.primary};

  ${props => props.circular && 'border-radius: 50%;'}
  ${props => props.secondary && 'background: #027b76;'}
  ${props =>
    props.green && 'background-image: linear-gradient(137deg, #7ebd39, #7ebd39); !important;'}
  ${props =>
    props.orange && 'background-image: linear-gradient(137deg, #FF8D00, #FF8D00) !important;'}
  ${props =>
    props.red && 'background-image: linear-gradient(137deg, #F13B3B, #F13B3B); !important;'}
  ${props =>
    props.blue &&
    `background-image: linear-gradient(137deg, ${
      props.shouldUseBtTheme ? btTheme.v2.primary : cpTheme.v2.primary
    }, ${props.shouldUseBtTheme ? btTheme.v2.primary : cpTheme.v2.primary}) !important;`}
`;
