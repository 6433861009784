import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';

export const BoxContainer = styled('div')(({ theme }) => ({
  width: 257,
  height: 176,
  border: `1.11173px solid ${useUiConfigColorsPrimary()}`,
  borderRadius: 10.5,
  display: 'flex',
  flexDirection: 'column',
  padding: '5% 15px 0 15px',
  position: 'relative',
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  position: 'absolute',
  left: '50%',
  top: 0,
  transform: 'translate(-50%, -50%)',
  backgroundColor: useUiConfigColorsPrimary(),
  height: 60,
  width: 60,
  border: `5px solid rgb(250, 250, 250)`,
}));

export const Img = styled('img')({ width: '45%', height: 'auto' });

export const Title = styled('p')({
  fontSize: 16,
  fontWeight: 700,
  color: '#000',
  textAlign: 'center',
  marginBottom: 20,
  lineHeight: 1.3,
});

export const Text = styled('p')({
  fontSize: 13.5,
  fontWeight: 400,
  color: '#000',
  textAlign: 'center',
  lineHeight: 1.3,
});
