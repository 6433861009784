import React from 'react';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { LuxuryPropertyImage } from './LuxuryPropertyImage/LuxuryPropertyImage.component';
import { BrokerContactInfo } from '../components/BrokerContactInfo/BrokerContactInfo';
import {
  PropertyPreview,
  PropertyPreviewContainer,
  PropertyPreviewWrapper,
} from './LuxuryCoverPdf.styles';
import { useClient } from '../../../../../../../providers/providers/ClientProvider';

export const LuxuryCoverBTPdf: React.FC = () => {
  const { category } = useCategory();
  const { client } = useClient();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL} isCover>
      <PropertyPreviewContainer>
        <PropertyPreviewWrapper>
          <PropertyPreview>
            <LuxuryPropertyImage isPdf address={client?.name} />
          </PropertyPreview>
        </PropertyPreviewWrapper>
      </PropertyPreviewContainer>
      <BrokerContactInfo isLuxury />
    </PdfFrame>
  );
};
