import { useMemo } from 'react';

import { EntityMode, TDynamicSectionId, TSection, TSlide, TTag } from '../../types';
import { TDynamicSlideDto } from '../../api/DynamicSlidesApi';
import { useDynamicSlides } from './useDynamicSlides';
import { useUser } from '../../components/Slide/providers/UserProvider';
import { useLevelDynamicSlides } from '../useLevelDynamicSlides';
import { useLibraryDynamicSlides } from '../useLibraryDynamicSlides';
import { useEntity } from '../../pages/PresentationSettings/providers/EntityProvider';

export interface TCountableTag extends TTag {
  count: number;
}

function getDynamicSlideTagsMap(dynamicSlide: TDynamicSlideDto): Record<string, TCountableTag> {
  if (!dynamicSlide.slide?.tags?.length) return {};

  return dynamicSlide.slide.tags?.reduce(
    (map, tag) => {
      if (!tag?.id) return map;

      if (!map[tag.id]) {
        return {
          ...map,
          [tag.id]: { ...tag, count: 1 },
        };
      }

      if (!map?.[tag?.id]?.count) return map;

      return {
        ...map,
        [tag.id]: { ...map[tag.id], count: (map[tag.id]?.count ?? 1) + 1 },
      };
    },
    {} as Record<string, TCountableTag>,
  );
}

function extractTagsFromDynamicSlides(
  dynamicSlides: TDynamicSlideDto[] | undefined,
): Record<string, TCountableTag> {
  if (!dynamicSlides?.length) return {};

  let uniqueTagsMap = {} as Record<string, TCountableTag>;

  dynamicSlides.forEach(dynamicSlide => {
    const slideTagsMap = getDynamicSlideTagsMap(dynamicSlide);

    Object.keys(slideTagsMap).forEach((tagId: string) => {
      if (!uniqueTagsMap?.[tagId]) {
        uniqueTagsMap = {
          ...uniqueTagsMap,
          [tagId]: { ...slideTagsMap[tagId] },
        };

        return;
      }

      uniqueTagsMap = {
        ...uniqueTagsMap,
        [tagId]: {
          ...slideTagsMap[tagId],
          count: (slideTagsMap?.[tagId]?.count ?? 1) + (uniqueTagsMap?.[tagId]?.count ?? 1),
        },
      };
    });
  });

  return uniqueTagsMap;
}

function extractDynamicSlides(slidesMap: Record<string, TSlide>) {
  if (!slidesMap) return [];

  return Object.values(slidesMap)
    .map(ds => (!ds?.sectionId ? null : { section: ds.sectionId, slide: ds }))
    .filter(Boolean) as TDynamicSlideDto[];
}

export function useTags(): Partial<Record<string, TCountableTag>> {
  const { hash } = useUser();
  const { data } = useLibraryDynamicSlides(hash);

  const dynamic = useMemo(() => extractDynamicSlides(data), [data]);

  return useMemo(
    function () {
      return extractTagsFromDynamicSlides(dynamic);
    },
    [dynamic],
  );
}

function useExtractTagsFromSlides(
  section: TDynamicSectionId | 'allSlides',
  dynamicSlides: TDynamicSlideDto[] | undefined,
) {
  return useMemo(() => {
    if (section === 'allSlides') {
      return extractTagsFromDynamicSlides(dynamicSlides);
    }

    const sectionDynamicSlides = dynamicSlides?.filter(slide => slide.section === section);

    return extractTagsFromDynamicSlides(sectionDynamicSlides);
  }, [dynamicSlides, section]);
}

export function useTagsInSection(
  section: TDynamicSectionId | 'allSlides',
): Partial<Record<string, TCountableTag>> {
  const { hash } = useUser();
  const dynamicSlides = useDynamicSlides(hash);

  return useExtractTagsFromSlides(section, dynamicSlides.data);
}

export function useLevelTagsInSection(
  section: TDynamicSectionId | 'allSlides',
): Partial<Record<string, TCountableTag>> {
  const { hash, useSpecialBranding } = useUser();
  const { data } = useLevelDynamicSlides(hash, useSpecialBranding, null, EntityMode.READ);

  const dynamic = useMemo(() => extractDynamicSlides(data), [data]);

  return useExtractTagsFromSlides(section, dynamic);
}

export function useLevelTags(): Partial<Record<string, TCountableTag>> {
  const { hash, useSpecialBranding } = useUser();
  const { entity, entityMode } = useEntity();
  const { data } = useLevelDynamicSlides(hash, useSpecialBranding, entity, entityMode);

  const dynamic = useMemo(() => extractDynamicSlides(data), [data]);

  return useMemo(
    function () {
      return extractTagsFromDynamicSlides(dynamic);
    },
    [dynamic],
  );
}
