import { CSSProperties } from 'react';

import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { TooltipPayloadProps } from '../../../../types';
import { Adjusted, TooltipWrapper } from '../components/UI';

interface PricingFavorabilityTooltipProps {
  active: boolean;
  payload: TooltipPayloadProps[];
  label: string;
}

export const PricingFavorabilityTooltip = ({
  active,
  payload,
  label,
}: PricingFavorabilityTooltipProps) => {
  if (!active) {
    return null;
  }

  const listed = payload[0]?.value;
  const closed = payload[1]?.value;

  const adjustedListPrice = payload[0]?.payload?.adjusted_price;
  const hasAdjustedListPrice = Number(payload[0]?.payload?.adjusted_count) > 1;

  const adjustedSalePrice = payload[0]?.payload?.adjusted_sale_price;
  const hasAdjustedSalePrice = Number(payload[0]?.payload?.adjusted_sale_count) > 1;

  const style: CSSProperties = {};

  if (payload[0]?.color) {
    style.color = payload[0]?.color;
  }

  return (
    <TooltipWrapper>
      <p>{label}</p>
      <p>Sale Date: {payload[0]?.payload?.sale_date}</p>

      <p style={style}>{`Average List Price: ${MoneyFormatter.format(listed)}`}</p>
      {hasAdjustedListPrice && (
        <Adjusted>{`Average ADJ List Price: ${MoneyFormatter.format(adjustedListPrice)}`}</Adjusted>
      )}
      <p
        style={{
          color: payload[1].color,
        }}
      >{`Average Sale Price: ${MoneyFormatter.format(closed)}`}</p>
      {hasAdjustedSalePrice && (
        <Adjusted>{`Average ADJ Sale Price: ${MoneyFormatter.format(adjustedSalePrice)}`}</Adjusted>
      )}
    </TooltipWrapper>
  );
};
