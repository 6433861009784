import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import bg from './bg.png';
import cat from './cat.png';
import overlay from './layer.png';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import slideGraphics from './slideGraphics';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url('${bg}');
  background-position: bottom;
  background-size: cover;
`;

const ShapeImg = styled.img`
  margin: 0 auto;
  display: block;
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1300px;
`;

const OverlayImage = styled.img`
  z-index: 2;
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 500px;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 45px;
  padding-top: 45px;
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  font-family: 'Figtree';
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const Cat = styled('img')({
  position: 'absolute',
  bottom: 0,
  height: 398,
  left: '49%',
  transform: 'translateX(-50%)',
});

export const WhereBuyersGetTheirInfoTraditional: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const { isCanadaUser } = useUser();

  const img = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  const headingStyles = {
    fontSize: 18,
    color: '#444',
    fontWeight: 700,
  };

  const textStyles = {
    fontSize: 16,
    fontWeight: 400,
    color: '#444',
  };

  return (
    <ImageWrapper>
      <Heading>Where do buyers get their information?</Heading>
      <ShapeImg src={img} />
      {/* <BgImage src={bg} /> */}
      <Cat src={cat} />
      <OverlayImage src={overlay} />
      <TraditionalSlideCircularCard
        heading={'SPHERE'}
        text={
          'It’s natural to lean on the advice and experience from friends, family, and neighbors.'
        }
        position={{ left: 148, bottom: 153 }}
        textStyles={textStyles}
        headingStyles={headingStyles}
        containerStyles={{ padding: 0 }}
        size={195}
      />
      <TraditionalSlideCircularCard
        heading={'WEB'}
        text={
          isCanadaUser
            ? 'The first step the average buyer takes in their home buying process is to look online at properties for sale. '
            : 'The first step 44% of recent buyers took in the home buying process was to look online at properties for sale. '
        }
        position={{ left: 299, bottom: 359 }}
        textStyles={textStyles}
        headingStyles={headingStyles}
        containerStyles={{ padding: 0 }}
        size={255}
      />
      <TraditionalSlideCircularCard
        heading={'EXPERTS'}
        text={
          'Real Estate Agents, Lenders, Financial Planners, and other professionals can balance and weigh the market information in conjunction with each \n' +
          'buyer’s situation.'
        }
        position={{ left: 969, bottom: 351 }}
        textStyles={textStyles}
        headingStyles={headingStyles}
        containerStyles={{ padding: 10 }}
        size={288}
      />
      <TraditionalSlideCircularCard
        heading={'EXPERIENCE'}
        text={
          'It is easy to form an opinion on what something might be worth based on firsthand knowledge or perceived value.\n'
        }
        position={{ left: 1178, bottom: 106 }}
        textStyles={textStyles}
        headingStyles={headingStyles}
        containerStyles={{ padding: 10 }}
        size={250}
      />
    </ImageWrapper>
  );
};
