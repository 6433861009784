import { FC } from 'react';

import image from './image.png';
import { data, subtitle, title } from '../../data';
import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';

export const BuyersAgentCompensationLuxuryPDF: FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading={title}
      paragraphs={[subtitle]}
      listItems={data}
      image={image}
      paragraphStyles={{ marginBottom: 8 }}
      textContainerStyles={{ paddingTop: 0 }}
      listItemStyles={{ lineHeight: 1.75 }}
    />
  );
};
