import React, { useMemo } from 'react';
import { Table } from '@tanstack/react-table';
import { useAtom, useSetAtom } from 'jotai';
import styled from 'styled-components';

import { Select } from '../../../../../../components/Common';
import { Input } from '../../../../../../components/Common/V2/Input';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { PastPresentationItem } from '../../types';
import typeFilterAtom from '../../jotai/typeFilterAtom';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';

const options = [
  { value: [], label: 'All Presentation Types' },
  { value: ['seller'], label: 'Presentations for Seller' },
  { value: ['buyer', 'buyerTour', 'winTheRepresentation'], label: 'Presentations for Buyer' },
  { value: ['info'], label: 'Presentations for Anyone' },
  { value: ['seller'], label: 'Win the Listing Only' }, // same as Presentations for Seller
  { value: ['winTheRepresentation'], label: 'Win the Representation Only' },
  { value: ['buyerTour'], label: 'Buyer Tour Only' },
  { value: ['buyer'], label: 'Win the Offer Only' },
  { value: ['info'], label: 'General Presentation Only' }, // same as Presentations for Anyone
];

const noFeedsOptions = [
  { value: [], label: 'All Presentation Types' },
  { value: ['winTheRepresentation'], label: 'Win the Representation Only' },
  { value: ['info'], label: 'General Presentation Only' },
];

interface FiltersProps {
  table: Table<PastPresentationItem>;
}

const Filters: React.FC<FiltersProps> = ({ table }) => {
  const [typeFilter, setTypeFilter] = useAtom(typeFilterAtom);
  const { feedIds } = useUser();
  const userHasFeeds = feedIds?.length > 0;

  const selectOptions = userHasFeeds ? options : noFeedsOptions;

  const selectedOption = useMemo(() => {
    return selectOptions?.find(option => option.value === typeFilter);
  }, [selectOptions, typeFilter]);

  const isMobile = useMediaQuery('(max-width: 980px)');

  return (
    <FiltersWrapper>
      <Input
        onChange={e => table.getColumn('address')?.setFilterValue(e.target.value)}
        placeholder='Search'
        leftIconName={['fas', 'magnifying-glass']}
        wrapperStyle={isMobile ? { width: '100%' } : {}}
        inputWrapperStyle={{
          width: isMobile ? '100%' : '251px',
        }}
      />
      <div style={{ width: isMobile ? '100%' : 'auto' }}>
        <Select
          onChange={(e: any) => setTypeFilter(e.value)}
          styles={{
            minWidth: isMobile ? '100%' : '250px',
          }}
          defaultValue={[{ value: [], label: 'All Presentation Types' }]}
          options={selectOptions}
          menuPlacement='auto'
          menuPortalTarget={document.body}
          selectedOption={selectedOption}
        />
      </div>
    </FiltersWrapper>
  );
};

export default Filters;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;

  @media (max-width: 980px) {
    flex-direction: column;
    align-items: start;
  }
`;
