import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  gap: 30px;
  height: 100vh;
  inset: 0;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
`;

export const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentSection = styled.div`
  // padding: 40px 30px;
  // display: flex;
  // flex: 1;
  // justify-content: space-between;
  // > * {
  //   width: 48%;
  // }
  // overflow-y: auto;

  display: flex;
  padding: 0px 20px;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
`;
