import styled from 'styled-components';

type WithImage = {
  hasImage?: boolean;
};

export const ContentWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
});

export const ImagePreview = styled('div')({
  width: '100%',
  paddingBottom: '43%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});
export const InfoContainer = styled('div')<WithImage>(({ hasImage }) => ({
  display: 'flex',
  flexDirection: hasImage ? 'row' : 'column',
  justifyContent: 'space-between',
  padding: '25px 40px',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));
