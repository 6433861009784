import React from 'react';
import image from './image.png';
import * as data from '../../data';
import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';

const WhatFactorsInfluencePricingLuxuryPDF: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading={data.heading}
      listItems={data.listItemsLuxury}
      paragraphs={data.paragraphs}
      image={image}
      paragraphStyles={{ marginBottom: 20 }}
      containerStyles={{ paddingTop: 0 }}
      imageStyles={{ marginBottom: -10 }}
      listType='ul'
    />
  );
};

export default WhatFactorsInfluencePricingLuxuryPDF;
