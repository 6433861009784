import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { Typography, FilePicker } from '../../../../../Common';
import PdfViewer from './PdfViewer';
import { Button } from '../../../../../Common/V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled('div')(({ theme }) => ({
  display: 'block',
  backgroundColor: theme.colors.gray[100],
  margin: '19px auto',
  position: 'relative',
  border: `2px dashed ${theme.colors.v2.gray[200]}`,
}));

const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
});

const PdfWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
});

const ButtonWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const InfoText = styled('p')(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.v2.gray[400],
  width: '80%',
  textAlign: 'center',
  marginTop: 20,
  fontWeight: 500,
}));

export type TFileType = 'image' | 'pdf';

interface Props {
  orientation: 'landscape' | 'portrait';
  fileSrc: string;
  setFile: Dispatch<SetStateAction<File | null>>;
  infoText?: string;
  fileType: TFileType;
  onRemove: () => void;
}

const UploadFileComponent: React.FC<Props> = ({
  orientation,
  fileSrc,
  setFile,
  infoText,
  fileType,
  onRemove,
}) => {
  const getAccept = (fileType: TFileType) => {
    switch (fileType) {
      case 'image':
        return 'image/*';
      case 'pdf':
        return 'application/pdf';
      default:
        return '';
    }
  };

  const renderPreview = (fileType: TFileType) => {
    switch (fileType) {
      case 'image':
        return <Image src={fileSrc} />;
      case 'pdf':
        return (
          <PdfWrapper>
            <PdfViewer pdfUrl={fileSrc} containerHeight={orientation === 'landscape' ? 228 : 333} />
          </PdfWrapper>
        );
      default:
        return <></>;
    }
  };

  const getButtonText = (fileType: TFileType) => {
    switch (fileType) {
      case 'image':
        return 'Add Image';
      case 'pdf':
        return 'Add PDF';
      default:
        return '';
    }
  };

  return (
    <Container
      style={{
        width: orientation === 'landscape' ? 397 : 292,
        height: orientation === 'landscape' ? 228 : 333,
      }}
    >
      {fileSrc && renderPreview(fileType)}
      <FilePicker setFile={setFile} accept={getAccept(fileType)}>
        {!fileSrc && (
          <ButtonWrapper>
            <Button variant='primary' prefixIcon={<FontAwesomeIcon icon={['fas', 'add']} />}>
              {getButtonText(fileType)}
            </Button>
            {infoText && <InfoText>{infoText}</InfoText>}
          </ButtonWrapper>
        )}
      </FilePicker>
      {!!fileSrc && (
        <Typography
          variant='body1'
          style={{
            fontWeight: 500,
            position: 'absolute',
            bottom: -30,
            width: '100%',
            cursor: 'pointer',
          }}
          color='#D0021B'
          align='center'
          onClick={onRemove}
        >
          Remove
        </Typography>
      )}
    </Container>
  );
};

export default UploadFileComponent;
