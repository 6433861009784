import { ReactNode } from 'react';
import styled from 'styled-components';
import { Accordion } from '../../../../../../../../../../../components/Common/V2/Accordion';

const Container = styled.div`
  padding: 10px 15px 15px 15px;
`;

interface Props {
  children: ReactNode;
  title: string;
  info: string;
  isOpen: boolean;
}

export const CriteriaAccordion = ({ children, title, info, isOpen }: Props) => {
  return (
    <Accordion title={title} info={info} open={isOpen}>
      <Container>{children}</Container>
    </Accordion>
  );
};
