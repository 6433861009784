import { RuntimeEnvironment } from '../../types';
import { Environment } from './Environment';

type DomainMap = {
  [key in RuntimeEnvironment]: {
    kvc: string;
    bt: string;
  };
};

const envDomainsMap: DomainMap = {
  [RuntimeEnvironment.DEV]: {
    kvc: 'http://localhost:3000',
    bt: 'http://localhost:3000',
  },
  [RuntimeEnvironment.STAGING]: {
    kvc: 'https://corepresent-staging.kvcore.com',
    bt: 'https://present-staging.boldtrail.com',
  },
  [RuntimeEnvironment.PROD]: {
    kvc: 'https://corepresent.kvcore.com',
    bt: 'https://present.boldtrail.com',
  },
};

export class StandaloneAppDomains {
  static get() {
    const env = Environment.getEnv();

    return envDomainsMap[env];
  }

  static isStandaloneInstance() {
    const domains = StandaloneAppDomains.get();

    return window.location.origin === domains.kvc || window.location.origin === domains.bt;
  }
}
