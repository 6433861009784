import { useDomainWhitelabelSettings } from '../../../features/whitelabel/useDomainWhitelabelSettings';
import { TClient, TPresentation } from '../../../types';
import { PresentationApi } from '../../Presentation/api/PresentationApi';

export const useCreatePresentation = () => {
  const { data } = useDomainWhitelabelSettings();

  const createPresentation = async (presentation: TPresentation, forClient?: TClient | null) => {
    const presentationData = {
      ...presentation,
      whiteLabelSettings: data?.whiteLabelSettings || null,
    };
    const response = await PresentationApi.create(
      presentationData,
      forClient,
      presentation.agreementLink,
    );

    await PresentationApi.triggerPdfGeneration(response.id);

    return response;
  };

  return createPresentation;
};
