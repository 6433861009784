import React, { useContext, useEffect, useState } from 'react';

const ScaleFactorContext = React.createContext<number>(1);

export const useScaleFactor = () => {
  const context = useContext(ScaleFactorContext);

  if (!context) {
    console.warn('Using useScaleFactor without ScaleFactorContext');
  }

  return context ?? 1;
};

export const useInvertedScaleFactor = () => {
  const scaleFactor = useScaleFactor();

  if (scaleFactor === 0) {
    return 1;
  }

  if (scaleFactor < 0.16) {
    return 0.5 / scaleFactor;
  }

  return 1 / scaleFactor;
};

interface ScaleFactorProviderProps {
  factor: number;
}
export const ScaleFactorProvider: React.FC<ScaleFactorProviderProps> = ({ children, factor }) => {
  const [localFactor, setLocalFactor] = useState(factor || 1);

  useEffect(
    function onFactorChange() {
      if (localFactor !== factor) {
        setLocalFactor(factor || 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [factor],
  );

  return <ScaleFactorContext.Provider value={localFactor}>{children}</ScaleFactorContext.Provider>;
};
