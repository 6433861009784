import { corePresentApi } from '../api/CorePresentApi';
import { TBuyerDemandInterestData, TPresentationSlides } from '../types';
import circleToPolygon from 'circle-to-polygon';
import { ReportDetailedCommunicator } from '../pages/PresentationCreate/dash/communicators/ReportDetailed/ReportDetailedCommunicator';
import { toast } from 'react-toastify';

type TAddress = {
  deliveryLine: string;
  city: string;
  state: string;
  zip: string;
};

type TSubject = {
  propertyType: string;
  address: TAddress;
  coordinates: { longitude: number; latitude: number };
};

export class AdaptSlidesWithBuyerInterestData {
  static async getBuyerDemandData(subject: TSubject) {
    const polygon = circleToPolygon(
      [subject.coordinates.longitude, subject.coordinates.latitude],
      12000,
      20,
    );
    try {
      const zipData = await corePresentApi.post(`/buyer-interest/data`, {
        polygon: polygon,
      });
      return zipData;
    } catch (e: any) {
      console.error(e);
      return null;
    }
  }

  static async getBuyerInterestData(subject: TSubject, price: number, hash: string) {
    try {
      const zipData = await this.getBuyerDemandData(subject);
      const zips = zipData.map((zip: any) => {
        return `${zip.zip}`;
      });
      const data = await corePresentApi.post('/buyer-interest/funnel-data', {
        address: subject?.address?.deliveryLine,
        primary_zip: subject?.address?.zip,
        zips,
        user_hash: hash,
        price: price,
      });
      return {
        data,
        zipData,
      };
    } catch (e: any) {
      console.error(e);
      toast(
        'We had trouble populating the Buyer Demand and the Buyer Interest slides when building your presentation. Please review these slides.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
      return null;
    }
  }

  static async getBuyerDemandProperties(api: any, allExclusions: any) {
    try {
      const reportDetailed = await ReportDetailedCommunicator.buildPartial({
        ...api,
        excluded_ids: allExclusions,
        partial: true,
      });
      const properties: { active: any; pending: any; sold: any } = reportDetailed.properties;
      const mergedProperties: any[] = [...properties.active];
      return mergedProperties;
    } catch (e: any) {
      console.error('failed loading');
      toast(
        'We had trouble populating the Buyer Demand slide when building your presentation. Please review this slide.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
      return [];
    }
  }

  static async loadBuyerDemandAndInterestData(
    subject: TSubject,
    price: number,
    hash: string,
    report: any,
  ): Promise<TBuyerDemandInterestData> {
    try {
      const { api, allExclusions } = report;

      const [interest, demand] = await Promise.all([
        this.getBuyerInterestData(subject, price, hash),
        this.getBuyerDemandProperties(api, allExclusions),
      ]);

      return { interest, demand };
    } catch (e: any) {
      console.error('Faild to load buyer data', e);
      return { interest: null, demand: null };
    }
  }

  static async getPresentationSlides(
    presentationSlides: TPresentationSlides,
    subject: TSubject,
    price: number,
    hash: string,
    report: { api: any; allExclusions: any },
  ) {
    try {
      const { api, allExclusions } = report;

      const buyerInterestData = await this.getBuyerInterestData(subject, price, hash);
      const buyerDemandProperties = await this.getBuyerDemandProperties(api, allExclusions);

      return {
        orderById: presentationSlides.orderById,
        sections: {
          ...presentationSlides.sections,
          marketingAndBuyerActivity: {
            ...presentationSlides.sections.marketingAndBuyerActivity,
            slides: presentationSlides.sections.marketingAndBuyerActivity.slides.map(s => {
              if (s.id === 'buyerInterest') {
                const updatedSlide = {
                  ...s,
                  buyerInterestData: buyerInterestData?.data,
                };
                return updatedSlide;
              }
              if (s.id === 'buyerDemand') {
                const updatedSlide = {
                  ...s,
                  buyerDemandData: {
                    zipData: buyerInterestData?.zipData,
                    properties: buyerDemandProperties,
                  },
                };
                return updatedSlide;
              }
              return s;
            }),
          },
          whyIAmTheRightFit: {
            ...presentationSlides.sections.whyIAmTheRightFit,
            slides: presentationSlides.sections.whyIAmTheRightFit.slides.map(s => {
              if (s.id === 'oneSheeter') {
                const updatedSlide = {
                  ...s,
                  buyerInterestData: buyerInterestData?.data,
                };
                return updatedSlide;
              }
              return s;
            }),
          },
        },
      };
    } catch (e: any) {
      console.error(e);
      return presentationSlides;
    }
  }
}
