import { useSelector } from 'react-redux';

export const useAllProperties = () => {
  return useSelector(({ result, report }) => {
    if (!report?.reportDetailed?.properties) return null;
    return JSON.parse(JSON.stringify(report.reportDetailed.properties));
  });
};

export const useActiveProperties = () => {
  return useSelector(({ result, report }) =>
    JSON.parse(JSON.stringify(report.reportDetailed.properties.active)),
  );
};
