import { calculateRangeHighlightsHeight, calculatePdfHeight } from './calculateHeight';

export const buildContainerStyles = (
  highlights: any,
  numberOfSelected: number,
  markerBaseHeight: number,
  isPdf: boolean,
) => {
  if (numberOfSelected < 2) {
    return { width: `${highlights.length * 30}px` };
  }
  return {
    width: '100%',
    height: !isPdf
      ? `${calculateRangeHighlightsHeight(highlights, markerBaseHeight)}px`
      : calculatePdfHeight(numberOfSelected, markerBaseHeight, highlights.length) - 80,
  };
};
