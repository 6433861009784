import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Dashboard } from './Dashboard';
import { TPresentationType } from '../../types';
import Loader from '../../components/Loader/Loader';
import { usePresentation } from '../../hooks/usePresentation';
import { PRESENTATION_TYPES } from '../../constants/PresentationTypes';
import { BuyerTourDashboard } from '../../features/BuyerTour/components/BuyerTourDashboard/BuyerTourDashboard';
import { DashboardLayout } from '../../layouts/Dashboard/DashboardLayotu';
import { useSetPresentationHash } from '../../state/presentationHash/useSetPresentationHash';

const dashboardComponentByTypeMap = {
  [PRESENTATION_TYPES.PRESENT_INFO]: Dashboard,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: Dashboard,
  [PRESENTATION_TYPES.WIN_THE_LISTING]: Dashboard,
  [PRESENTATION_TYPES.WIN_THE_OFFER]: Dashboard,
  [PRESENTATION_TYPES.BUYER_TOUR]: BuyerTourDashboard,
};

export const DashboardPage: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();
  const { data, isLoading } = usePresentation(hash);
  useSetPresentationHash(hash);

  if (isLoading || !data)
    return (
      <Container style={{ height: 600 }}>
        <Loader size='3x' centered />
      </Container>
    );

  const Component = dashboardComponentByTypeMap[data.type as TPresentationType];
  if (!Component) return null;

  return (
    <DashboardLayout presentationHash={hash}>
      <Component presentation={data as any} />
    </DashboardLayout>
  );
};

export const Container = styled('div')({
  padding: 30,
  display: 'flex',
  justifyContent: 'space-between',
});
