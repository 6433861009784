import React from 'react';

import { TCategory, TSection } from '../../../../types';
import { useSlide } from '../../providers/SlideProvider';
import LuxuryProgressTracker from './luxury/LuxuryProgressTracker';
import ModernProgressTracker from './modern/ModernProgressTracker';
import TraditionalProgressTracker from './traditional/TraditionalProgressTracker';
import { CATEGORIES } from '../../../../constants/categories';
import { usePresentationType } from '../../../../providers/providers/PresentationTypeProvider';
import { useOrder } from '../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../providers/providers/SectionsProvider';

export interface ProgressTrackerComponentProps {
  label: string;
  sectionIds: string[];
  activeSectionPosition: number;
  fontColor?: string;
  containerStyles?: React.CSSProperties;
  titleStyles?: React.CSSProperties;
}

const trackersMap: Record<TCategory, React.FC<ProgressTrackerComponentProps>> = {
  [CATEGORIES.TRADITIONAL]: TraditionalProgressTracker,
  [CATEGORIES.MODERN]: ModernProgressTracker,
  [CATEGORIES.LUXURY]: LuxuryProgressTracker,
};

interface ProgressTrackerProps {
  category: TCategory;
  fontColor?: string;
  containerStyles?: React.CSSProperties;
  titleStyles?: React.CSSProperties;
}
const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  category,
  fontColor,
  containerStyles,
  titleStyles,
}) => {
  const excludeSections: TSection[] = ['startingSlides'];
  const { presentationType } = usePresentationType();
  const { sections: sectionsOrder, sectionsSlide, getSlideSectionId } = useOrder();
  const { slides } = useSlides();
  const { sections } = useSections();

  sectionsOrder.forEach(sectionId => {
    const _slides = sectionsSlide[sectionId];

    if (
      sectionId !== 'startingSlides' &&
      !_slides.filter(slideId => slides && slides[slideId]).length
    ) {
      excludeSections.push(sectionId as TSection);
    }
  });

  const slide = useSlide();
  const sectionId = getSlideSectionId(slide.id)!;
  const section = sections[sectionId];
  const sectionIds = sectionsOrder.filter(
    sectionId => !excludeSections.includes(sectionId as TSection),
  );

  const activeSectionPosition =
    sectionIds.filter(sId => sId !== 'startingSlides').findIndex(sId => sectionId === sId) ?? 0;

  const ProgressTrackerComponent = trackersMap[category] ?? trackersMap[CATEGORIES.TRADITIONAL];

  if (presentationType === 'presentInfo') return null;

  if (sectionIds.length === 1) return null;

  return (
    <ProgressTrackerComponent
      sectionIds={sectionIds}
      label={section?.label ?? ''}
      activeSectionPosition={activeSectionPosition}
      fontColor={fontColor}
      containerStyles={containerStyles}
      titleStyles={titleStyles}
    />
  );
};

export default ProgressTracker;
