import { cloneDeep } from 'lodash';

import type { IPresentationConfigs, TSlide, TSlideId } from '../../../types';
import { CurrentUserService } from '../../../services/CurrentUserService';
import { CanadaSlidesAdapter } from '../../../services/CanadaSlidesAdapter';
import { config as cover } from '../../../components/Slide/slides/startingSlides/Cover';
import type { TDynamicOrder, TDynamicSection, TDynamicSectionId } from '../../../types';
import { config as valueOfAgentConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/valueOfAnAgent';
import { config as coreSocialConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/CoreSocial';
import { config as homeSellingConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/homeSellingProcess';
import { config as realtorVSIBuyerConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/realtorVSIBuyer';
import { config as propertyBoostConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/propertyBoost';
import { config as pricingMisconceptionsConfig } from '../../../components/Slide/slides/whatsMyHomeWorth/PricingMisconceptions';
import { config as aboutMeConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/AboutMe';
import { config as myMarketingPlanConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/MyMarketingPlan';
import { config as whereBuyersGetTheirInfoConfig } from '../../../components/Slide/slides/whatsMyHomeWorth/whereBuyersGetTheirInfo';
import { config as importanceOfOpeningWeekendConfig } from '../../../components/Slide/slides/marketAnalysis/importanceOfOpeningWeekend';
import { config as whatFactorsInfluencePricingConfig } from '../../../components/Slide/slides/marketAnalysis/whatFactorsInfluencePricing';
import { config as importanceOfStagingConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/ImportanceOfStaging';
import { config as whyPricingCorrectlyIsImportantConfig } from '../../../components/Slide/slides/marketAnalysis/whyPricingCorrectlyIsImportant';
import { config as myTestimonialsConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/MyTestimonials';
import { config as homeBuyingConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/homeBuyingProcess';
import { config as whatBuyersWantConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/whatBuyersWantFromTheirAgents';
import { config as purposeOfBuyersAgentConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/purposeOfBuyersAgent';
import { config as buyersAgentCompensationConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/buyersAgentCompensation';
import { config as whyIAmTheRightBuyersAgentConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/whyIAmTheRightBuyersAgent';
import standaloneSlidesConfig from '../../../components/Slide/services/StandaloneSlidesGroup';
import { config as closingConfig } from '../../../components/Slide/slides/closingAndNextSteps/closingAndNextSteps';

const startingSlides: TDynamicSection = {
  id: 'startingSlides',
  label: 'Introduction',
};
const whyIAmTheRightFit: TDynamicSection = {
  id: 'whyIAmTheRightFit',
  label: 'Why I Am The Right Fit',
};
const whatsMyHomeWorth: TDynamicSection = {
  id: 'whatsMyHomeWorth',
  label: 'What’s My Home Worth',
};
const marketAnalysis: TDynamicSection = {
  id: 'marketAnalysis',
  label: 'Market Analysis',
};
const marketingAndBuyerActivity: TDynamicSection = {
  id: 'marketingAndBuyerActivity',
  label: 'Marketing & Buyer Activity',
};

const closingAndNextSteps: TDynamicSection = {
  id: 'closingAndNextSteps',
  label: 'Next Steps',
};

const sections: Record<TDynamicSectionId, TDynamicSection> = {
  startingSlides,
  whyIAmTheRightFit,
  whatsMyHomeWorth,
  marketAnalysis,
  marketingAndBuyerActivity,
  closingAndNextSteps,
} as const;

class LibraryConfigsImp implements IPresentationConfigs {
  getSlides(): Partial<Record<TSlideId, TSlide>> {
    const sharedSlidesConfigs = CurrentUserService.isCanadaUser()
      ? CanadaSlidesAdapter.getSlidesFromSlidesMap(standaloneSlidesConfig)
      : standaloneSlidesConfig;

    return cloneDeep({
      ...sharedSlidesConfigs,
      [cover.id]: cover,
      [homeSellingConfig.id]: homeSellingConfig,
      [whereBuyersGetTheirInfoConfig.id]: whereBuyersGetTheirInfoConfig,
      [homeBuyingConfig.id]: homeBuyingConfig,
      [whatBuyersWantConfig.id]: whatBuyersWantConfig,
      [purposeOfBuyersAgentConfig.id]: purposeOfBuyersAgentConfig,
      [buyersAgentCompensationConfig.id]: buyersAgentCompensationConfig,
      [whyIAmTheRightBuyersAgentConfig.id]: whyIAmTheRightBuyersAgentConfig,
      [closingConfig.id]: closingConfig,
    });
  }

  getSections(): Record<TDynamicSectionId, TDynamicSection> {
    return cloneDeep(sections);
  }

  getFullOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [
        sections.startingSlides.id,
        sections.whyIAmTheRightFit.id,
        sections.whatsMyHomeWorth.id,
        sections.marketAnalysis.id,
        sections.marketingAndBuyerActivity.id,
        sections.closingAndNextSteps.id,
      ],
      sectionsSlide: {
        [sections.startingSlides.id]: [cover.id] as TSlideId[],
        [sections.marketAnalysis.id]: [
          whatFactorsInfluencePricingConfig.id,
          whyPricingCorrectlyIsImportantConfig.id,
          importanceOfOpeningWeekendConfig.id,
        ] as TSlideId[],
        [sections.whyIAmTheRightFit.id]: [
          aboutMeConfig.id,
          myTestimonialsConfig.id,
          valueOfAgentConfig.id,
          homeSellingConfig.id,
          realtorVSIBuyerConfig.id,
          homeBuyingConfig.id,
          whatBuyersWantConfig.id,
          whyIAmTheRightBuyersAgentConfig.id,
        ] as TSlideId[],
        [sections.marketingAndBuyerActivity.id]: [
          importanceOfStagingConfig.id,
          myMarketingPlanConfig.id,
          propertyBoostConfig.id,
          coreSocialConfig.id,
          purposeOfBuyersAgentConfig.id,
          buyersAgentCompensationConfig.id,
        ] as TSlideId[],
        [sections.whatsMyHomeWorth.id]: [
          pricingMisconceptionsConfig.id,
          whereBuyersGetTheirInfoConfig.id,
        ] as TSlideId[],
        [sections.closingAndNextSteps.id]: [closingConfig.id] as TSlideId[],
      },
    });
  }

  getDefaultOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [
        sections.startingSlides.id,
        sections.whyIAmTheRightFit.id,
        sections.whatsMyHomeWorth.id,
        sections.marketAnalysis.id,
        sections.marketingAndBuyerActivity.id,
        sections.closingAndNextSteps.id,
      ],
      sectionsSlide: {
        [sections.startingSlides.id]: [cover.id] as TSlideId[],
        [sections.marketAnalysis.id]: [
          whatFactorsInfluencePricingConfig.id,
          whyPricingCorrectlyIsImportantConfig.id,
          importanceOfOpeningWeekendConfig.id,
        ] as TSlideId[],
        [sections.whyIAmTheRightFit.id]: [
          aboutMeConfig.id,
          myTestimonialsConfig.id,
          valueOfAgentConfig.id,
          homeSellingConfig.id,
          realtorVSIBuyerConfig.id,
          homeBuyingConfig.id,
          whatBuyersWantConfig.id,
          whyIAmTheRightBuyersAgentConfig.id,
        ] as TSlideId[],
        [sections.marketingAndBuyerActivity.id]: [
          importanceOfStagingConfig.id,
          myMarketingPlanConfig.id,
          propertyBoostConfig.id,
          coreSocialConfig.id,
          purposeOfBuyersAgentConfig.id,
          buyersAgentCompensationConfig.id,
        ] as TSlideId[],
        [sections.whatsMyHomeWorth.id]: [
          pricingMisconceptionsConfig.id,
          whereBuyersGetTheirInfoConfig.id,
        ] as TSlideId[],
        [sections.closingAndNextSteps.id]: [closingConfig.id] as TSlideId[],
      },
    });
  }

  getPredefinedSectionSlides(): Record<TDynamicSectionId, TSlideId[]> {
    return cloneDeep({
      [sections.startingSlides.id]: [cover.id] as TSlideId[],
      [sections.marketAnalysis.id]: [
        whatFactorsInfluencePricingConfig.id,
        whyPricingCorrectlyIsImportantConfig.id,
        importanceOfOpeningWeekendConfig.id,
      ] as TSlideId[],
      [sections.whyIAmTheRightFit.id]: [
        aboutMeConfig.id,
        myTestimonialsConfig.id,
        valueOfAgentConfig.id,
        homeSellingConfig.id,
        realtorVSIBuyerConfig.id,
        homeBuyingConfig.id,
        whatBuyersWantConfig.id,
        whyIAmTheRightBuyersAgentConfig.id,
      ] as TSlideId[],
      [sections.marketingAndBuyerActivity.id]: [
        importanceOfStagingConfig.id,
        myMarketingPlanConfig.id,
        propertyBoostConfig.id,
        coreSocialConfig.id,
        purposeOfBuyersAgentConfig.id,
        buyersAgentCompensationConfig.id,
      ] as TSlideId[],
      [sections.whatsMyHomeWorth.id]: [
        pricingMisconceptionsConfig.id,
        whereBuyersGetTheirInfoConfig.id,
      ] as TSlideId[],
      [sections.closingAndNextSteps.id]: [closingConfig.id] as TSlideId[],
    });
  }
}

export const LibraryConfigs = new LibraryConfigsImp();
