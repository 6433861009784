export const heading = "Purpose & Importance of a Buyer's Agent";
export const subtitle =
  "Understanding the purpose and importance of a buyer's agent is crucial for a successful home-buying journey. Here's why they're indispensable:";

export const data = [
  {
    title: 'Understand your needs',
    text: 'Delve into your preferences, needs, and budget, including factors like location, size, and amenities for your ideal property. This ensures comprehensive and personalized assistance.',
  },
  {
    title: 'Educate you',
    text: 'Receive valuable information and guidance about the home-buying process, market conditions, and legal and financial considerations.',
  },
  {
    title: 'Research Properties',
    text: 'Conduct thorough research on properties that match your criteria, providing insights into history and market value to empower well-informed decisions.',
  },
  {
    title: 'Keep you informed',
    text: 'Regularly update you on current market conditions, comparable sales, trends, and property values in your chosen area to keep you well-informed and confident.',
  },
  {
    title: 'Coordinate Viewings',
    text: 'Coordinate property viewings based on your availability, accompany you, and highlight both positive and negative aspects; allowing you to make well-informed decisions.',
  },
  {
    title: 'Negotiate for you',
    text: 'Utilize negotiation skills to advocate for your interests and secure the best deal, covering price, terms, and contingencies with the seller or their agent.',
  },
  {
    title: 'Due diligence',
    text: 'Guide you through due diligence, including inspections and necessary investigations, to understand potential risks and benefits associated with each property.',
  },
];
