import { cloneDeep } from 'lodash';

import {
  config as oneSheeterConfig,
  TOneSheeter,
} from '../../components/Slide/slides/whyIAmTheRightFitSection/oneSheeter';
import type { IPresentationConfigs, TSlide, TSlideId } from '../../types';
import type { TDynamicOrder, TDynamicSection, TDynamicSectionId } from '../../types';
import { config as greetingVideo } from '../../components/Slide/slides/startingSlides/GreetingVideo';
import { config as netProceeds } from '../../components/Slide/slides/closingAndNextSteps/netProceeds';
import { PresentInfoConfigs } from '../../features/PresentInfo/services/PresentInfoConfigs';

const oneSheeterSection = {
  id: 'oneSheeterSection',
  label: 'One Sheeter',
};

class OneSheeterConfigsImp implements IPresentationConfigs {
  getSlides(): Partial<Record<TSlideId, TSlide>> {
    return cloneDeep({
      ...PresentInfoConfigs.getSlides(),
      [oneSheeterConfig.id as TOneSheeter]: oneSheeterConfig,
      [greetingVideo.id]: greetingVideo,
      [netProceeds.id]: netProceeds,
    });
  }

  getSections(): Record<TDynamicSectionId, TDynamicSection> {
    return { oneSheeterSection };
  }

  getFullOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [oneSheeterSection.id],
      sectionsSlide: {
        [oneSheeterSection.id]: [oneSheeterConfig.id] as TSlideId[],
      },
    });
  }

  getDefaultOrder(): TDynamicOrder {
    return this.getFullOrder();
  }

  getPredefinedSectionSlides(): Record<string, TSlideId[]> {
    return {
      [oneSheeterSection.id]: [oneSheeterConfig.id] as TSlideId[],
    };
  }

  getSlidesArr(config?: Partial<Record<TSlideId, TSlide>>): TSlide[] {
    const slides: TSlide[] = [];

    const _config = config || OneSheeterConfigs.getSlides();

    Object.values(_config).forEach(v => v && slides.push(v));

    return slides;
  }
}

export const OneSheeterConfigs = new OneSheeterConfigsImp();
