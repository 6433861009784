class ChartFormatterImplementation {
  getTicks(data, field) {
    const domain = this.getDomain(data, field, true);
    const lowest = domain[0];
    const highest = domain[1];
    const step = domain[2];
    let ret = [];
    const tickCount = Math.floor((highest - lowest) / step);
    for (let i = 0; i <= tickCount; i++) {
      ret.push(lowest + i * step);
    }
    return ret;
  }

  getDomain(data, field, withStep = false) {
    let lowest = Number.POSITIVE_INFINITY;
    let highest = Number.NEGATIVE_INFINITY;
    let tmp;
    data.forEach(element => {
      tmp = element[field];
      if (tmp < lowest) lowest = tmp;
      if (tmp > highest) highest = tmp;
    });

    let step;
    if (lowest > 100000) {
      step = 50000;
    } else if (lowest > 1000) {
      step = 100;
    } else {
      step = 10;
    }

    lowest = Math.floor(lowest / step) * step;
    highest = Math.ceil(highest / step) * step;
    if (withStep) {
      return [lowest, highest, step];
    }
    return [lowest, highest];
  }
}

export const ChartFormatter = new ChartFormatterImplementation();
