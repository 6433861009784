import React from 'react';

import { TUserConfigsUi } from '../../types';
import { DefaultConfigProvider } from '../../services/DefaultConfigProvider';
import { UiConfigFontsProvider, useUiConfigFonts } from './UiConfigFontProvider';
import { UiConfigThemeProvider, useUiConfigTheme } from './UiConfigThemeProvider';
import { UiConfigLogosProvider, useUiConfigLogos } from './UiConfigLogosProvider';
import { UiConfigColorsProvider, useUiConfigColors } from './UiConfigColorProvider';
import {
  UiConfigInfoPlacementProvider,
  useUiConfigInfoPlacement,
} from './UiConfigInfoPlacementProvider';
import { UiUseKvCoreLogoProvider, useUiUseKvCoreLogo } from './UiUseKvCoreLogoProvider';

interface Props {
  initValue?: TUserConfigsUi;
  fallback?: TUserConfigsUi;
}

export const useSetUiConfigs = () => {
  const { setFonts } = useUiConfigFonts();
  const { setTheme } = useUiConfigTheme();
  const { setLogos } = useUiConfigLogos();
  const { setColors } = useUiConfigColors();
  const { setInfoPlacement } = useUiConfigInfoPlacement();
  const { setUseKvCoreLogo } = useUiUseKvCoreLogo();

  return React.useCallback(
    (config: TUserConfigsUi) => {
      const defaultConfig = DefaultConfigProvider.getUiConfig();

      setFonts(config?.fonts && config?.fonts?.header?.value ? config.fonts : defaultConfig.fonts);
      setTheme(config?.theme ?? defaultConfig.theme);
      setLogos(config?.logos ?? defaultConfig.logos);
      setColors(
        config?.colors && config?.colors?.primary?.value ? config.colors : defaultConfig.colors,
      );
      setUseKvCoreLogo(config?.useKvCoreLogo || defaultConfig.useKvCoreLogo);
      setInfoPlacement(config?.infoPlacement ?? defaultConfig.infoPlacement);
    },
    [setFonts, setTheme, setLogos, setColors, setUseKvCoreLogo, setInfoPlacement],
  );
};

export const useUiConfigs = () => {
  const { fonts } = useUiConfigFonts();
  const { theme } = useUiConfigTheme();
  const { logos } = useUiConfigLogos();
  const { colors } = useUiConfigColors();
  const { useKvCoreLogo } = useUiUseKvCoreLogo();
  const { infoPlacementData: infoPlacement } = useUiConfigInfoPlacement();

  return React.useMemo(
    () => ({ fonts, colors, infoPlacement, theme, logos, useKvCoreLogo }),
    [fonts, colors, theme, logos, useKvCoreLogo, infoPlacement],
  );
};

export const UiConfigProvider: React.FC<Props> = ({ initValue, fallback, children }) => (
  <UiConfigThemeProvider initValue={initValue?.theme ?? fallback?.theme}>
    <UiUseKvCoreLogoProvider initValue={initValue?.useKvCoreLogo ?? fallback?.useKvCoreLogo}>
      <UiConfigFontsProvider initValue={initValue?.fonts ?? fallback?.fonts}>
        <UiConfigInfoPlacementProvider
          initValue={initValue?.infoPlacement ?? fallback?.infoPlacement}
        >
          <UiConfigColorsProvider initValue={initValue?.colors ?? fallback?.colors}>
            <UiConfigLogosProvider initValue={initValue?.logos ?? fallback?.logos}>
              {children}
            </UiConfigLogosProvider>
          </UiConfigColorsProvider>
        </UiConfigInfoPlacementProvider>
      </UiConfigFontsProvider>
    </UiUseKvCoreLogoProvider>
  </UiConfigThemeProvider>
);
