import React from 'react';
import styled from 'styled-components';

import { PresentationSettings } from './PresentationSettings/PresentationSettings';
import { PresentationPreview } from './PresentationPreview';

interface Props {
  useEmphasis?: boolean;
  useTheme?: boolean;
  useMode?: boolean;
}

export const PresentationSettingsHeader: React.FC<Props> = ({
  useEmphasis = true,
  useTheme = true,
  useMode = false,
}) => (
  <Wrapper>
    <PresentationSettings useEmphasis={useEmphasis} useTheme={useTheme} useMode={useMode} />
    <PresentationPreview />
  </Wrapper>
);

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.v2.gray[400],
  padding: '15px 20px',
  display: 'flex',
  justifyContent: 'space-between',
}));
