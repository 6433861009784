import styled from 'styled-components';
import { useAnalyticsModal } from '../../../providers/AnalyticsModalProvider';
import { Button } from './Button';

interface Props {
  hash: string;
  buildInProgress?: boolean;
  sessionsCount: number;
}

export const AnalyticsModalButtonCell = ({ hash, buildInProgress, sessionsCount }: Props) => {
  const { setAnalyticsOpen } = useAnalyticsModal();

  return (
    <Container>
      <Button
        disabled={buildInProgress || !sessionsCount}
        onClick={() => setAnalyticsOpen(hash)}
        iconName='analytics'
        style={{ width: '39px' }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
