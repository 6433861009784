import React from 'react';
import styled from 'styled-components';
import { InputsSection } from './InputsSection.component';
import { TNetProceedsData } from '../../../../../../types';
import { TInputTarget } from '../AddNetSheet.component';

export const Section = styled('div')({
  width: '100%',
  marginTop: 20,
});

export const SectionTitleContainer = styled('div')(({ theme }) => ({
  padding: '8px 20px',
  backgroundColor: theme.colors.gray[200],
  marginBottom: 10,
}));

export const SectionTitle = styled('h4')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: theme.colors.gray[400],
  textTransform: 'capitalize',
}));

type InputProps = {
  title: string;
  inputNamePrefix: string;
  sectionName?: keyof TNetProceedsData;
  value1: number;
  value2: number;
  value3: number;
  onInputChange: (target: TInputTarget) => void;
};

interface NetSheetSectionProps {
  sectionTitle: string;
  inputSections: InputProps[];
}

export const NetSheetSection: React.FC<NetSheetSectionProps> = ({
  sectionTitle,
  inputSections,
}) => {
  return (
    <Section>
      <SectionTitleContainer>
        <SectionTitle>{sectionTitle}</SectionTitle>
      </SectionTitleContainer>
      {inputSections.map(inputSection => {
        return (
          <InputsSection
            key={inputSection.inputNamePrefix}
            title={inputSection.title}
            inputNamePrefix={inputSection.inputNamePrefix}
            sectionName={inputSection.sectionName}
            value1={inputSection.value1}
            value2={inputSection.value2}
            value3={inputSection.value3}
            onInputChange={inputSection.onInputChange}
          />
        );
      })}
    </Section>
  );
};
