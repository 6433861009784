import React from 'react';

import { ContentContainer, ContentWrapper } from './TraditionalCoverBeta.styles';
import { CoverContent } from '../../components/CoverContent/CoverContent';

export const TraditionalCoverBeta: React.FC = () => {
  return (
    <ContentContainer>
      <ContentWrapper>
        <CoverContent />
      </ContentWrapper>
    </ContentContainer>
  );
};
