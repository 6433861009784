import React from 'react';
import styled from 'styled-components';
import slideGraphics from './assets/slideGraphics';
import img_1 from './assets/1.png';
import img_2 from './assets/2.png';
import bg from './assets/bg.png';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';
import { paragraphs } from '../../data';
import calendar from './assets/calendar';

type WithCanadaUser = {
  isCanadaUser?: boolean;
};

const Container = styled('div')<WithCanadaUser>(({ theme, isCanadaUser }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  background: isCanadaUser ? '#fff' : `url(${bg})`,
  backgroundSize: '100%',
  backgroundPosition: 'bottom',
}));

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 30px;
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  position: relative;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
  z-index: 5;
`;

const Text = styled('p')<WithCanadaUser>(({ theme, isCanadaUser }) => ({
  color: theme.colors.gray[400],
  fontSize: 16,
  lineHeight: 1.3,
  paddingLeft: 30,
  width: isCanadaUser ? '100%' : '70%',
  marginTop: -10,
  letterSpacing: useUiConfigBodyLetterSpacing(),
  marginBottom: isCanadaUser ? 30 : 0,
}));

const Img1 = styled('img')({
  position: 'absolute',
  right: 16,
  top: -35,
  height: '101%',
  width: 'auto',
  zIndex: 4,
});

const Img2 = styled('img')({
  position: 'absolute',
  right: 16.5,
  top: -34,
  height: '101%',
  zIndex: 3,
});

const Graphics = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  position: 'absolute',
  width: isCanadaUser ? '96%' : '88%',
  height: 'auto',
  left: isCanadaUser ? '52%' : '50%',
  transform: 'translateX(-50%)',
  bottom: isCanadaUser ? 40 : 130,
  zIndex: 2,
}));

const ImportanceOfOpeningWeekendTraditionalPDF: React.FC = () => {
  const { isCanadaUser } = useUser();
  const primary = useUiConfigColorsPrimary();

  const graphic = isCanadaUser ? calendar : slideGraphics;
  const image = React.useMemo(() => svgStringToDataUrl(graphic, primary), [graphic, primary]);

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container isCanadaUser={isCanadaUser}>
        <Heading>Odds of selling at or above list price</Heading>
        {isCanadaUser ? (
          paragraphs.map((paragraph, i) => (
            <Text key={i} isCanadaUser>
              {paragraph}
            </Text>
          ))
        ) : (
          <Text>
            On average, the longer a home sits on the market,
            <br />
            the lower the odds of selling at or above list price.
          </Text>
        )}

        <Graphics src={image} isCanadaUser={isCanadaUser} />
        {!isCanadaUser && (
          <>
            <Img1 src={img_1} />
            <Img2 src={img_2} />
            <TraditionalSlideCircularCard
              topText='week 1'
              heading='57%'
              position={{ top: 689, left: 65 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
            <TraditionalSlideCircularCard
              topText='week 2'
              heading='50%'
              position={{ top: 545, left: 51 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
            <TraditionalSlideCircularCard
              topText='week 3'
              heading='39%'
              position={{ top: 410, left: 95 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
            <TraditionalSlideCircularCard
              topText='week 4'
              heading='34%'
              position={{ top: 293, left: 212 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
            <TraditionalSlideCircularCard
              topText='week 5'
              heading='29%'
              position={{ top: 244, right: 386 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
            <TraditionalSlideCircularCard
              topText='week 6'
              heading='23%'
              position={{ top: 292, right: 211 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
            <TraditionalSlideCircularCard
              topText='week 7'
              heading='23%'
              position={{ top: 409, right: 95 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
            <TraditionalSlideCircularCard
              topText='week 8'
              heading='18%'
              position={{ top: 543, right: 50 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
            <TraditionalSlideCircularCard
              topText='week 9'
              heading='16%'
              position={{ top: 689, right: 65 }}
              size={130}
              topTextStyles={{ fontSize: 16 }}
              headingStyles={{ fontSize: 26 }}
              textStyles={{ fontSize: 10, fontWeight: 300 }}
            />
          </>
        )}
      </Container>
    </PdfFrame>
  );
};

export default ImportanceOfOpeningWeekendTraditionalPDF;
