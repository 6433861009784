import { cloneDeep } from 'lodash';
import { TSlideId, TPresentationMode, TDynamicSectionId } from '../../../../types';
import { ConfigItem } from '../../../../providers/providers/OrderProvider';
import { hasGreetingVideoSlide } from './hasGreetingVideoSlide';
import { hasNetProceedsSlide } from './hasNetProceedsSlide';

function insertBefore(slides: TSlideId[], toInsert: TSlideId, before: TSlideId): TSlideId[] {
  const validSlides: TSlideId[] = [];

  slides
    .filter(sId => sId !== toInsert)
    .forEach(sId => {
      if (sId === before) {
        validSlides.push(toInsert);
      }

      validSlides.push(sId);
    });

  return validSlides.length > slides.length ? validSlides : [...validSlides, toInsert];
}

function addGVSlideToOrder(mode: TPresentationMode, order: ConfigItem) {
  let adaptedSectionSlide = cloneDeep(order.sectionsSlide);

  if (mode === 'regular' && adaptedSectionSlide.startingSlides) {
    adaptedSectionSlide.startingSlides = [
      'greetingVideo',
      ...adaptedSectionSlide.startingSlides.filter(s => s !== 'greetingVideo'),
    ];

    return adaptedSectionSlide;
  }

  const [firstSectionId] = order.sections;
  adaptedSectionSlide = {
    ...adaptedSectionSlide,
    [firstSectionId]: [
      'greetingVideo',
      ...adaptedSectionSlide[firstSectionId].filter(s => s !== 'greetingVideo'),
    ],
  };

  return adaptedSectionSlide;
}

function addNSSlideToOrder(mode: TPresentationMode, order: ConfigItem) {
  let adaptedSectionSlide = cloneDeep(order.sectionsSlide);

  if (mode === 'regular' && adaptedSectionSlide.closingAndNextSteps) {
    adaptedSectionSlide.closingAndNextSteps = insertBefore(
      adaptedSectionSlide.closingAndNextSteps,
      'netProceeds',
      'closingAndNextSteps',
    );

    return adaptedSectionSlide;
  }

  const [firstSectionId] = order.sections;
  adaptedSectionSlide = {
    ...adaptedSectionSlide,
    [firstSectionId]: insertBefore(
      adaptedSectionSlide[firstSectionId],
      'netProceeds',
      'closingAndNextSteps',
    ),
  };

  return adaptedSectionSlide;
}

export function addToModeOrderSlidesAddedOnDashboard(
  mode: TPresentationMode,
  modeOrder: ConfigItem,
  previousMode: TPresentationMode,
  previousModeOrder: ConfigItem,
): Record<TDynamicSectionId, TSlideId[]> {
  const order = cloneDeep(modeOrder);

  if (hasGreetingVideoSlide(previousMode, previousModeOrder)) {
    order.sectionsSlide = addGVSlideToOrder(mode, order);
  }

  if (
    hasNetProceedsSlide(previousMode, previousModeOrder) &&
    !hasNetProceedsSlide(mode, modeOrder)
  ) {
    order.sectionsSlide = addNSSlideToOrder(mode, order);
  }

  return order.sectionsSlide;
}
