import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigBodyLetterSpacing } from '../../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';

type TTableItem = {
  isActive: boolean;
  selectedIndex?: number;
};

type WithActive = {
  isActive: boolean;
};

export const TableContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const TableRow = styled('div')({
  display: 'flex',
});

export const TableHeading = styled('div')({
  flex: 1,
  textAlign: 'start',
});

export const TableItem = styled('div')({
  width: '21.33333%',
});

export const TableHead = styled(TableRow)({
  backgroundColor: '#D9D9D9',
  borderRadius: 5,
  overflow: 'hidden',
  height: 65,
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16,
  flexShrink: 0,
});

export const ListPriceTitle = styled(TableHeading)({
  fontSize: 16,
  textTransform: 'uppercase',
  color: '#444',
  paddingLeft: 20,
  fontWeight: 800,
  letterSpacing: '0.11em',
});

export const ListPriceItem = styled(TableItem)<TTableItem>(({ isActive, selectedIndex }) => ({
  fontSize: 16,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: isActive ? '#fff' : '#444',
  cursor: 'pointer',
  fontWeight: 800,
  letterSpacing: '0.11em',
  backgroundColor: isActive ? useUiConfigColorsPrimary() : 'transparent',
  borderLeft: isActive ? '1px solid transparent' : '1px solid #DEDEDE',
  transition: 'all .2s ease-in-out',
  '&:nth-child(3)': {
    borderLeft:
      selectedIndex === 0 || selectedIndex === 1 ? '1px solid transparent' : '1px solid #DEDEDE',
    borderRight: selectedIndex === 0 ? '1px solid #DEDEDE' : '1px solid transparent',
  },
  '&:last-child': {
    borderLeft: 'none',
    borderRight: 'none',
  },
}));

export const TableBody = styled('div')({
  border: '1px solid #DEDEDE',
  borderTop: 'none',
  borderRadius: '5px 5px 0 0',
  overflow: 'hidden',
});

export const CostBreakdownRow = styled(TableRow)({
  backgroundColor: '#666',
  display: 'flex',
  alignItems: 'center',
  height: 35,
});

export const CostBreakdownTitle = styled(TableHeading)({
  fontSize: 14,
  color: '#fff',
  textTransform: 'uppercase',
  fontWeight: 800,
  letterSpacing: '0.11em',
  paddingLeft: 20,
  flex: 1,
});

export const CostBreakdownEmpty = styled(TableItem)<WithActive>(({ isActive }) => ({
  height: '100%',
  borderLeft: 'none',
  borderRight: 'none',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transition: 'all .2s ease-in-out',
    opacity: isActive ? 0.125 : 0,
    background: useUiConfigColorsPrimary(),
  },
}));
