import Frame from './LuxuryFrameGama';
import thumbnail from './luxuryGama.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'luxuryGama';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TLuxuryFrameGama = typeof id;
export default config;
