import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { Container, ImageWrapper, ImagePreview } from './ThreeImagesAlpha.styles';
import { ImageProxy } from '../../../../services/ImageProxy';

interface Props {
  reverse?: boolean;
}

export const ThreeImagesAlphaPdf: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container isPdf>
        <ImageWrapper style={{ gridColumn: reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image, { width: 750 })} />
        </ImageWrapper>
        <SlideTextBlock
          isEditable={false}
          text={slide.data?.text}
          headline={slide.data?.headline}
          containerStyles={{ gridColumn: !reverse ? '2 / 2' : '1 / 1', gridRow: '1 / 1' }}
        />
        <ImageWrapper style={{ gridRow: '2 / 2' }}>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image2, { width: 750 })} />
        </ImageWrapper>
        <ImageWrapper style={{ gridRow: '2 / 2' }}>
          <ImagePreview src={ImageProxy.optimize(slide.data?.image3, { width: 750 })} />
        </ImageWrapper>
      </Container>
    </PdfFrame>
  );
};
