import { cloneDeep } from 'lodash';

import {
  config as cmaReport,
  TCmaReport,
} from '../../components/Slide/slides/whatsMyHomeWorth/cmaReport';
import {
  config as marketOverview,
  TMarketOverview,
} from '../../components/Slide/slides/marketAnalysis/marketOverview';
import type { IPresentationConfigs, TSlide, TSlideId } from '../../types';
import type { TDynamicOrder, TDynamicSection, TDynamicSectionId } from '../../types';
import { config as cover, TCoverSlide } from '../../components/Slide/slides/startingSlides/Cover';
import { config as greetingVideo } from '../../components/Slide/slides/startingSlides/GreetingVideo';
import { config as netProceeds } from '../../components/Slide/slides/closingAndNextSteps/netProceeds';
import { config as cma } from '../../components/Slide/slides/whatsMyHomeWorth/comparativeMarketAnalysis';
import { PresentInfoConfigs } from '../../features/PresentInfo/services/PresentInfoConfigs';

const cmaSection = {
  id: 'cmaSection',
  label: 'CMA',
};

class CmaConfigsImp implements IPresentationConfigs {
  getSlides(): Partial<Record<TSlideId, TSlide>> {
    return cloneDeep({
      ...PresentInfoConfigs.getSlides(),
      [cover.id as TCoverSlide]: cover,
      [cmaReport.id as TCmaReport]: cmaReport,
      [cma.id]: cma,
      [marketOverview.id as TMarketOverview]: marketOverview,
      [greetingVideo.id]: greetingVideo,
      [netProceeds.id]: netProceeds,
    });
  }

  getSections(): Record<TDynamicSectionId, TDynamicSection> {
    return { cmaSection };
  }

  getFullOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [cmaSection.id],
      sectionsSlide: {
        [cmaSection.id]: [cover.id, cma.id, cmaReport.id, marketOverview.id] as TSlideId[],
      },
    });
  }

  getDefaultOrder(): TDynamicOrder {
    return this.getFullOrder();
  }

  getPredefinedSectionSlides(): Record<string, TSlideId[]> {
    return {
      [cmaSection.id]: [cover.id, cma.id, cmaReport.id, marketOverview.id] as TSlideId[],
    };
  }
}

export const CmaConfigs = new CmaConfigsImp();
