import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { TProperty } from '../../../../../../types';
import { propertiesNotesAtom } from '../../../../state/propertiesNotesAtom';
import { isPresentationViewAtom } from '../../../../state/isPresentationViewAtom';
import { NotesCreation } from './NotesCreation';
import { NotesPreview } from './NotesPreview';
import { getPropertyId } from '../../../../services/getPropertyId';

interface Props {
  property: TProperty;
  openedDropdown: string;
  setOpenedDropdown: (value: string) => void;
}

export const Notes = ({ property, openedDropdown, setOpenedDropdown }: Props) => {
  const notes = useAtomValue(propertiesNotesAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const propertyId = getPropertyId(property);

  const propertyNotes = useMemo(
    () => notes?.find(n => n.property_id === propertyId)?.note || '',
    [notes, propertyId],
  );

  if (isPresentationView) {
    return (
      <NotesPreview
        note={propertyNotes}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
    );
  }

  return (
    <NotesCreation
      key={propertyId}
      property={property}
      note={propertyNotes}
      openedDropdown={openedDropdown}
      setOpenedDropdown={setOpenedDropdown}
    />
  );
};
