import { TCompConfigItem } from '../../../../../types';
import { FeatureGroup } from '../components/FeatureGroup';
import { Section } from '../components/Section';
import { FEATURES } from '../featuresConfig';
import { FEATURES_KEYS } from '../types';

export const OtherFeatures = () => {
  return (
    <Section title='OTHER:'>
      <FeatureGroup
        title='ASSOCIATION FEES'
        fields={[FEATURES[FEATURES_KEYS.HOA_1], FEATURES[FEATURES_KEYS.HOA_0]] as TCompConfigItem[]}
      />
      <FeatureGroup
        title='FIXER UPPER'
        fields={
          [
            FEATURES[FEATURES_KEYS.FIXER_UPPER_YES],
            FEATURES[FEATURES_KEYS.FIXER_UPPER_NO],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='NEW CONSTRUCTION'
        fields={
          [
            FEATURES[FEATURES_KEYS.NEW_CONSTRUCTION_YES],
            FEATURES[FEATURES_KEYS.NEW_CONSTRUCTION_NO],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='ADULT 55+'
        fields={
          [
            FEATURES[FEATURES_KEYS.ADULT55_YES],
            FEATURES[FEATURES_KEYS.ADULT55_NO],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='GREEN/ENERGY STAR'
        fields={
          [
            FEATURES[FEATURES_KEYS.GREEN_ENERGY_YES],
            FEATURES[FEATURES_KEYS.GREEN_ENERGY_NO],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='HORSE PROPERTY'
        fields={
          [FEATURES[FEATURES_KEYS.HORSE_YES], FEATURES[FEATURES_KEYS.HORSE_NO]] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='GOLF COURSE'
        fields={
          [FEATURES[FEATURES_KEYS.GOLF_YES], FEATURES[FEATURES_KEYS.GOLF_NO]] as TCompConfigItem[]
        }
      />
    </Section>
  );
};
