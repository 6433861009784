import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper, LeftCol, RightCol, ImagePreview } from './OneImageEpsilon.styles';

interface Props {
  reverse?: boolean;
}

export const OneImageEpsilon: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();

  return (
    <Container style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
      <LeftCol style={{ alignItems: reverse ? 'flex-end' : 'flex-start' }}>
        <ImageWrapper>
          <ImagePreview src={slide.data?.image} />
        </ImageWrapper>
      </LeftCol>
      <RightCol style={{ alignItems: !reverse ? 'flex-end' : 'flex-start' }}>
        <SlideTextBlock
          isEditable={false}
          text={slide.data?.text}
          headline={slide.data?.headline}
          containerStyles={{ minWidth: '100%', height: '70%' }}
        />
      </RightCol>
    </Container>
  );
};
