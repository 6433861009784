import React from 'react';
import { CheckboxContainer, CheckboxInput, CheckboxControl, CheckboxText } from './Checkbox.styles';
import { TFeedbackItem } from '../MoreTimeInfoModal.component';

interface CheckboxProps {
  text: string;
  isChecked: boolean;
  setIsChecked: (feedbackItem: TFeedbackItem, isChecked: boolean) => void;
  name: TFeedbackItem;
}

export const Checkbox: React.FC<CheckboxProps> = ({ text, isChecked, setIsChecked, name }) => {
  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const isChecked = e.currentTarget.checked;
    setIsChecked(name as TFeedbackItem, isChecked);
  };

  return (
    <CheckboxContainer>
      <CheckboxInput type='checkbox' onChange={onCheck} checked={isChecked} name={name} />
      <CheckboxControl isChecked={isChecked} />

      <CheckboxText>{text}</CheckboxText>
    </CheckboxContainer>
  );
};
