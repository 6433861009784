import React, { useRef, useEffect } from 'react';
import { useSlide } from '../../../../providers/SlideProvider';
import { Container, ImagePreview } from './SingleImage.styles';

interface Props {
  reverse?: boolean;
}

export const SingleImage: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();

  const containerRef = useRef<HTMLDivElement>(null);
  const imageElRef = useRef<HTMLImageElement>(null);
  const containerEl = containerRef.current;
  const imageEl = imageElRef.current;

  const image = slide.data?.image;
  const imagePdf = slide.data?.image2;

  const containerHeight = containerEl?.style.height;

  useEffect(() => {
    if (!containerEl || !imageEl) return;
    if (!image && imagePdf && containerHeight) {
      imageEl.style.width = `calc(${containerHeight} * 0.876)`;
    }
  });

  return (
    <Container>
      <ImagePreview
        ref={imageElRef}
        style={{
          width: !image ? `calc(${containerHeight} * 0.876)` : '100%',
          height: '100%',
          objectFit: 'contain',
          margin: image ? 0 : '0 auto',
        }}
        src={image || imagePdf}
      />
    </Container>
  );
};
