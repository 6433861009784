import styled from 'styled-components';

interface Props {
  label: string;
  value: string;
}

export const SectionDetailItem = ({ label, value }: Props) => {
  return (
    <Container>
      <Label>{label}</Label>
      <ValueContainer>
        <Value>{value}</Value>
      </ValueContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-transform: uppercase;
  width: 58%;
`;

const ValueContainer = styled.div`
  width: 42%;
`;

const Value = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: #010101;
`;
