import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import slideGraphics from '../../assets/slideGraphics';
import slideGraphics2 from '../../assets/slideGraphics2';
import { graphItems, listItems } from '../../data';

const Container = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: 16,
  justifyContent: 'space-between',
});

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '49.3%',
});

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  margin-bottom: 32px;
  margin-top: 0;
  font-size: 21px;
  font-weight: 900;
  text-transform: uppercase;
`;

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid #ddd`,
  width: '49.2%',
  padding: 32,
});

const CardTitle = styled('h2')(({ theme }) => ({
  color: '#444',
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  marginBottom: 8,
  fontSize: 18,
  fontWeight: 800,
}));

const Text = styled('p')(({ theme }) => ({
  fontSize: 16,
  color: '#000',
  lineHeight: 1.6,
  width: 235,
}));

interface El {
  src: string;
  color: string;
}

const Graphics = styled('img')<El>(({ src, color }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  width: '78%',
  zIndex: 2,
  background: `conic-gradient(from 0deg at 50% 50%, ${ColorOpacityApplier.hex(
    color,
    '0.21',
  )} 0deg, ${color} 360deg), #FFFFFF`,
  borderRadius: '50%',
}));

const Icons = styled('img')({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  width: '78%',
  zIndex: 2,
});

const TextItem = styled('h3')(({ theme }) => ({
  color: '#fff',
  fontWeight: 800,
  position: 'absolute',
  textTransform: 'uppercase',
  zIndex: 5,
  fontSize: 16,
  textAlign: 'center',
  fontFamily: useUiConfigFontsHeader(),
  lineHeight: 1.6,
  letterSpacing: useUiConfigHeaderLetterSpacing('0.05em'),
}));

export const HomeSellingProcessModernPdf: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const image = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);
  const image2 = React.useMemo(() => svgStringToDataUrl(slideGraphics2, primary), [primary]);

  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      <Container>
        <Row>
          <CardContainer>
            <Heading>The Home Selling Process</Heading>
            <CardTitle>{listItems[3].title}</CardTitle>
            <Text style={{ width: 183 }}>{listItems[3].text}</Text>
          </CardContainer>
          <CardContainer style={{ alignItems: 'flex-end' }}>
            <CardTitle>{listItems[0].title}</CardTitle>
            <Text style={{ textAlign: 'end' }}>{listItems[0].text}</Text>
          </CardContainer>
        </Row>
        <Row>
          <CardContainer style={{ justifyContent: 'flex-end' }}>
            <CardTitle style={{ width: 143 }}>{listItems[2].title}</CardTitle>
            <Text>{listItems[2].text}</Text>
          </CardContainer>
          <CardContainer style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <CardTitle>{listItems[1].title}</CardTitle>
            <Text style={{ textAlign: 'end' }}>{listItems[1].text}</Text>
          </CardContainer>
        </Row>
        <Graphics src={image} color={primary} />
        <Icons src={image2} style={{ position: 'absolute' }} />
        <TextItem
          style={{ top: 170, left: '62.8%', fontSize: 10, fontWeight: 600, letterSpacing: '0' }}
        >
          Start
        </TextItem>
        <TextItem
          style={{
            top: '50%',
            left: '40.5%',
            fontSize: 18,
            color: primary,
            fontWeight: 800,
            letterSpacing: '0.1em',
            lineHeight: 1.6,
          }}
        >
          Home
          <br />
          successfully
          <br />
          sold
        </TextItem>
        <TextItem style={{ top: 257, left: '36%' }}>{graphItems[7].shortText}</TextItem>
        <TextItem style={{ top: 233, left: '52.8%', width: 162 }}>
          {graphItems[0].longText}
        </TextItem>
        <TextItem style={{ top: 389, left: '68.5%', width: 130 }}>
          {graphItems[1].shortText}
        </TextItem>
        <TextItem style={{ top: 573, left: '67%', width: 171 }}>{graphItems[2].longText}</TextItem>

        <TextItem style={{ top: 718, left: '52.7%' }}>{graphItems[3].shortText}</TextItem>
        <TextItem style={{ top: 695, left: '28.7%', width: 158 }}>
          {graphItems[4].shortText}
        </TextItem>
        <TextItem style={{ top: 562, left: '16.2%', width: 127 }}>
          {graphItems[5].shortText}
        </TextItem>
        <TextItem style={{ top: 371, left: '16.5%', width: 130 }}>
          {graphItems[6].shortText}
        </TextItem>
      </Container>
    </PdfFrame>
  );
};
