import { useAtomValue } from 'jotai';
import { compAveragesAtom } from '../../state/compAveragesAtom';
import { estimatesAtom } from '../../state/estimatesAtom';
import { flagsAtom } from '../../state/flagsAtom';
import { useMemo } from 'react';

export const useCategories = () => {
  const compAverages = useAtomValue(compAveragesAtom);
  const estimates = useAtomValue(estimatesAtom);
  const flags = useAtomValue(flagsAtom);

  return useMemo(
    () => ({
      ...compAverages,
      ...estimates,
      ...flags,
    }),
    [compAverages, estimates, flags],
  );
};
