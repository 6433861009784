import { CSSProperties } from 'react';
import { ScatterProps } from 'recharts';
import styled from 'styled-components';

import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { ChartContainer } from '../components/ChartContainer';
import { Legend } from '../components/Legend';
import { ScatterChart } from '../components/ScatterChart';
import { PricePerSqftTooltip } from './PricePerSqftTooltip';
import { usePropertiesForPricePerSqftChart } from './usePropertiesForPricePerSqftChart';
import { useShowPropertyDetails } from '../../hooks/useShowPropertyDetails';
import { useAtomValue } from 'jotai';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';

const legendOptions = [
  { blue: true, circular: true, text: 'COMING SOON' },
  { green: true, circular: true, text: 'ACTIVES' },
  { orange: true, circular: true, text: 'PENDING' },
  { red: true, circular: true, text: 'CLOSED' },
];

interface PricePerSqftChartProps {
  headline?: string;
  description?: string;
  style?: CSSProperties;
}

export const PricePerSqftChart = ({ headline, description, style }: PricePerSqftChartProps) => {
  const { groupedByStatus, properties, scatterData } = usePropertiesForPricePerSqftChart();

  const { showPropertyDetails } = useShowPropertyDetails();
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const onMoreInfo = (id: string) => {
    if (isPresentationView) return;
    const property = properties?.find(p => p.id === id);
    showPropertyDetails(property || null);
  };

  return (
    <ChartContainer
      headline={headline}
      description={description && <Description description={description} />}
      legend={<Legend options={legendOptions} />}
      childrenWrapperStyle={style}
    >
      <ScatterChart
        data={groupedByStatus}
        xyAxisData={properties}
        moreInfo={onMoreInfo}
        customTooltip={props => <PricePerSqftTooltip {...props} />}
        xDataKey='size'
        xLabel={{ value: 'SQFT', offset: -2, position: 'bottom', dy: 10, fontWeight: 'normal' }}
        xTickFormatter={number => NumberFormatter.format(number)}
        yDataKey='price'
        yLabel={{ value: 'PRICE', offset: 0, angle: -90, position: 'left' }}
        margin={{ top: 10, right: 10, bottom: 20, left: 10 }}
        scatterDataSets={scatterData as ScatterProps[]}
      />
    </ChartContainer>
  );
};

const Description = ({ description }: { description: string }) => (
  <DescriptionContainer>
    <div>{description}</div>
  </DescriptionContainer>
);

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
