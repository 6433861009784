import {
  Select,
  SelectOption,
} from '../../../../../../../../../../../components/Common/Select/Select.component';
import { Editable } from './Editable/Editable';

interface Props {
  label: string;
  value: string;
  defaultValue: string;
  onConfirm: () => void;
  onUndo: () => void;
  enterPressed: boolean;
  editValue: SelectOption;
  onChange: () => void;
  options: SelectOption[];
}

export const EditableSelect = ({
  label,
  value,
  defaultValue,
  onConfirm,
  onUndo,
  enterPressed,
  editValue,
  onChange,
  options,
}: Props) => {
  return (
    <Editable
      label={label}
      value={value}
      defaultValue={defaultValue}
      onConfirm={onConfirm}
      onUndo={onUndo}
      enterPressed={enterPressed}
      editComponent={
        <Select
          placeholder='Select'
          defaultValue={[editValue]}
          onChange={onChange}
          options={options}
          menuPortalTarget={document.body}
          styles={{ borderRadius: '5px 0 0 5px' }}
        />
      }
      highlight
    />
  );
};
