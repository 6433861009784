import React from 'react';

import Logos from '../../../../components/Logos/Logos';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';
import { HeaderContainer } from './ModernFrameBeta.styles';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { CommonFrameProps } from '../../../../../../../../types';

const ModernFrameBeta: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => (
  <PlacementAwareContainer infoPlacement={infoPlacement} style={{ backgroundColor: '#F8F8F8' }}>
    <HeaderContainer>
      <BrokerInfo
        width='33.333%'
        imageStyles={{ borderRadius: 0 }}
        nameFontStyles={{ fontSize: 18, color: '#333333' }}
        contactFontStyles={{ fontSize: 14, color: '#333333' }}
      />
      {!hideProgressTracker && <ProgressTracker category={CATEGORIES.MODERN} fontColor='#333333' />}
      <Logos />
    </HeaderContainer>
    <TocPortalContainer offset={-28} zIndex={20} />
    {children}
  </PlacementAwareContainer>
);

export default ModernFrameBeta;
