import React from 'react';

import { ProgressTrackerComponentProps } from '../ProgressTracker';
import {
  Container,
  Typography,
  StepsWrapper,
  Step,
  CircularStep,
  StepsBackground,
} from './TraditionalProgressTracker.styles';

const TraditionalProgressTracker: React.FC<ProgressTrackerComponentProps> = ({
  label,
  sectionIds,
  activeSectionPosition,
  fontColor,
  containerStyles,
}) => (
  <Container style={containerStyles} id='progress-tracker'>
    <Typography variant='h4' align='center' style={{ color: fontColor }}>
      {label}
    </Typography>
    <StepsWrapper>
      <StepsBackground
        style={{ width: `${(100 / sectionIds.length) * (activeSectionPosition + 1)}%` }}
      />
      {sectionIds.map((s, position) => (
        <Step
          key={s}
          completed={position < activeSectionPosition}
          active={activeSectionPosition === position}
        >
          {position + 1}
          {activeSectionPosition === position && (
            <CircularStep style={{ position: 'absolute' }}>{position + 1}</CircularStep>
          )}
        </Step>
      ))}
    </StepsWrapper>
  </Container>
);

export default TraditionalProgressTracker;
