import React from 'react';

import { activeCompetitionData } from '../data';
import { TCategory } from '../../../../../../../../types';
import { SlideWithGraphWrapper } from '../../../../../components/common/SlideWithGraphWrapper';
import ActiveCompetitionChart from '../../../../../../../../features/report/charts/active-competition/ActiveCompetitionChart';

interface ActiveCompetitionProps {
  category: TCategory;
}

const ActiveCompetition: React.FC<ActiveCompetitionProps> = ({ category }) => (
  <SlideWithGraphWrapper
    description={activeCompetitionData.description}
    heading={activeCompetitionData.heading}
    graphPadding={'0px'}
    category={category}
    noDisclaimer
  >
    <ActiveCompetitionChart height={550} />
  </SlideWithGraphWrapper>
);

export default ActiveCompetition;
