class DB {
  save(key: string, value: any) {
    // eslint-disable-line
    return localStorage.setItem(key, JSON.stringify(value));
  }

  read(key: string) {
    const serializedValue = localStorage.getItem(key);

    return serializedValue === null ? null : JSON.parse(serializedValue);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  has(key: string) {
    return localStorage.getItem(key) !== null;
  }
}

export const StorageService = new DB();
