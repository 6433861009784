import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

interface WithReverse {
  isReversed?: boolean;
}

export const FrameContainer = styled('div')({
  width: '100%',
  backgroundColor: '#f8f8f8',
});

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#fff',
  height: 135,
  zIndex: 40,
  // flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '125px',
    top: '0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
  },
}));

export const GradientComponentWrapper = styled('div')({
  width: '33%',
  height: '100%',
  filter: 'drop-shadow(2px 2px 4px rgba(0,0,0,0.2))',
  zIndex: 1,
});

export const GradientComponent = styled('div')<WithReverse>(({ isReversed }) => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#fff',
    clipPath: isReversed
      ? 'polygon(0 0, 100% 0, 100% 100%, 10% 92%)'
      : 'polygon(0 0, 100% 0, 90% 92%, 0% 100%)',
  };
});

export const SlideContent = styled.div(({ theme }) => ({
  padding: '0px 20px 20px 20px',
  height: 775,
  backgroundColor: useUiConfigColorsPrimary(),
  position: 'relative',
  marginTop: -10,
}));

export const SlideContentRoundBorders = styled('div')({
  height: '100%',
  borderRadius: '0 0 20px 20px',
  overflow: 'hidden',
});

export const BrokerInfoWrapper = styled('div')({
  position: 'relative',
  zIndex: 1,
  width: '80%',
});
