import styled from 'styled-components';
import { Typography } from '..';

type WithActive = {
  isActive: boolean;
};

export const NavItemWrapper = styled('div')<WithActive>(({ isActive, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 1.5%',
  cursor: 'pointer',
  flexShrink: 0,
  '@media all and (max-width: 1170px)': {
    padding: '0 1%%',
    '&:last-child': {
      paddingRight: '2%',
    },
  },
  '&:last-child': {
    paddingRight: '3%',
  },
  '&:first-child': {
    paddingLeft: '3%',
  },
}));

export const NavItemText = styled('div')<WithActive>(({ theme, isActive }) => ({
  fontWeight: isActive ? 700 : 500,
  color: theme.colors.gray[400],
  paddingBottom: 15,
  fontSize: '14px',
  borderBottom: '5px solid',
  borderColor: isActive ? theme.colors.v2.primary : 'transparent',
  lineHeight: 1,
  flexShrink: 0,
  transition: 'all .4s,',
  '@media all and (max-width: 1170px)': {
    fontSize: 14,
    paddingBottom: 10,
    borderBottom: '4px solid',
    borderColor: isActive ? theme.colors.v2.primary : 'transparent',
  },
}));

export const Nav = styled('div')(({ theme }) => ({
  height: 67,
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'flex-end',
  flexShrink: 0,
  overflowX: 'scroll',
  scrollbarWidth: 'none',
  borderBottom: `1px solid ${theme.colors.gray[200]}`,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));
