import React, { useCallback, useEffect, useRef, useState } from 'react';
import Slide from '../../../../../components/Slide/Slide';
import { useUiConfigInfoPlacement } from '../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { TSlide } from '../../../../../types';

import { SlideWrapper, SlideLabel, SlideScale, SlideInfo, SlideOverlay } from './SlideItem.styles';
import { SlidePresent } from './SlidePresent/SlidePresent.component';
import { SlideSettings } from './SlideSettings/SlideSettings.component';
import SlideActions from './SlideActions/SlideActions';
import { useUiConfigTheme } from '../../../../../providers/providers/UiConfigThemeProvider';
import { useCarouselControls } from '../../providers/CarouselControlsProvider';

interface SlidesCarouselProps {
  slide: TSlide;
  currentSlideIndex: number;
  isSingleSlide?: boolean;
}

export const SlideItem: React.FC<SlidesCarouselProps> = ({
  slide,
  currentSlideIndex,
  isSingleSlide = false,
}) => {
  const { infoPlacement, infoPlacementData } = useUiConfigInfoPlacement();
  const { theme, setTheme } = useUiConfigTheme();
  const [scaleFactor, setScaleFactor] = useState(0);
  const [forceRerender, setForceRerender] = useState(false);

  const { selectedSlideIndex, animate, onAnimationEnd } = useCarouselControls();

  const slideRef = useRef<HTMLDivElement>(null);
  const { current } = slideRef;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onResize = () => {
    if (!current) return;
    setScaleFactor(current.clientWidth / 1599.853);
  };

  useEffect(() => {
    if (scaleFactor === 0 && !current && !forceRerender) setForceRerender(true);
    onResize();
  }, [forceRerender, onResize, current, scaleFactor]);

  useEffect(() => {
    if (!current) return;
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [current, onResize]);

  const isSelected = selectedSlideIndex === currentSlideIndex;

  return (
    <SlideWrapper
      ref={slideRef}
      animate={animate}
      onAnimationEnd={onAnimationEnd}
      isSingleSlide={isSingleSlide}
    >
      <SlideInfo isSelected={isSelected}>
        <SlideLabel isSelected={isSelected}>{slide.label}</SlideLabel>
        {isSelected && <SlideActions slide={slide} />}
        {isSelected && (
          <SlideSettings
            selectedTheme={theme}
            onChooseTheme={theme => setTheme(theme)}
            slideId={slide.id}
            slide={slide}
          />
        )}
      </SlideInfo>
      <SlideOverlay isSelected={isSelected}>
        <SlideScale
          style={{
            width: '100%',
            height: 900 * scaleFactor,
            overflow: 'visible',
            transform: `scale(${scaleFactor})`,
          }}
        >
          <Slide slide={slide} theme={theme} infoPlacement={infoPlacement} hideProgressTracker />
        </SlideScale>
      </SlideOverlay>
      {isSelected && <SlidePresent slide={slide} theme={theme} infoPlacement={infoPlacementData} />}
    </SlideWrapper>
  );
};
