import React, { useMemo } from 'react';

import { useCategory } from '../providers/providers/CategoryProvider';
import { CustomizationCombiner } from '../data/customizations/CustomizationCombiner';
import { useRawCustomizations } from '../data/customizations/useRawCustomizations';
import { EntityMode } from '../types';

export function useUserCustomizations(
  hash: string,
  entity: number | null = null,
  useSpecialBranding: boolean = false,
  entityMode = EntityMode.READ,
) {
  const { data: customizations, isLoading, isFetching } = useRawCustomizations(hash);

  const userCustomizations = React.useMemo(() => {
    if (isLoading || isFetching) return null;

    const config = CustomizationCombiner.combine(
      customizations,
      null,
      entity,
      useSpecialBranding,
      entityMode,
    );

    return config ?? null;
  }, [isLoading, isFetching, customizations, entity, useSpecialBranding, entityMode]);

  return React.useMemo(
    () => ({
      isLoading,
      isFetching,
      level: userCustomizations?.level,
      data: userCustomizations?.data,
    }),
    [userCustomizations, isLoading, isFetching],
  );
}

export function useUserCategoryCustomizations(
  hash: string,
  entity: number | null = null,
  useSpecialBranding: boolean = false,
  entityMode = EntityMode.READ,
) {
  const { category } = useCategory();
  const { data: customizations, isLoading, isFetching } = useRawCustomizations(hash);

  const customizationData = React.useMemo(() => {
    if (isLoading || isFetching || !category) return null;

    const config = CustomizationCombiner.combine(
      customizations,
      null,
      entity,
      useSpecialBranding,
      entityMode,
    );

    return config ?? null;
  }, [isLoading, isFetching, category, customizations, entity, useSpecialBranding, entityMode]);

  const categoryCustomizations = useMemo(() => {
    if (!category) return null;

    return customizationData?.data?.[category] ?? null;
  }, [category, customizationData?.data]);

  return React.useMemo(
    () => ({
      isLoading,
      isFetching,
      level: customizationData?.level,
      data: categoryCustomizations,
    }),
    [customizationData, categoryCustomizations, isLoading, isFetching],
  );
}
