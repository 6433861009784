import styled from 'styled-components';

import image from './image.jpg';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';

export const Container = styled('div')({
  display: 'flex',
  height: '100%',
});

export const LeftCol = styled('div')({
  height: '100%',
  backgroundColor: '#fff',
  width: '43%',
  clipPath: 'polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: ' 45px 20px 20px 45px',
});

export const Content = styled('div')({
  textAlign: 'start',
  width: 380,
});

export const Title = styled('h2')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontSize: 30,
  fontWeight: 900,
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const Subtitle = styled('h5')(({ theme }) => ({
  color: '#000',
  fontSize: 18,
  fontWeight: 400,
  marginTop: 20,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const DescriptionContainer = styled('div')({
  marginTop: 20,
});

export const Description = styled('p')(({ theme }) => ({
  fontSize: 18,
  lineHeight: '29.1px',
  marginBottom: 20,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const RightCol = styled('div')(({ theme }) => ({
  backgroundImage: `url(${image})`,
  width: '100%',
  marginLeft: '-43%',
  marginTop: '-1%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const ImageTitle = styled('h5')(({ theme }) => ({
  fontSize: 29,
  textTransform: 'uppercase',
  fontWeight: 800,
  color: '#000',
  padding: '40px 60px 0 0',
  textAlign: 'end',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const SourceText = styled('p')({
  fontSize: 13,
  fontWeight: 300,
  color: '#101010',
  textAlign: 'end',
  padding: 20,
});

export const SourceLink = styled('a')({
  fontSize: 13,
  fontWeight: 300,
  color: '#101010',
  textDecoration: 'none',
});
