import styled from 'styled-components';

export const Container = styled('div')({
  flex: 1,
});

export const LibraryPageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});
