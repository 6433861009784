import thunk from 'redux-thunk';
import { useMemo } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';

import reducers from '../rootReducers';

let store;

function initStore(preloadedState) {
  return createStore(reducers, preloadedState, compose(applyMiddleware(thunk)));
}

export const initializeStore = preloadedState => {
  let _store = store ?? initStore(preloadedState);

  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });

    store = undefined;
  }

  if (typeof window === 'undefined') return _store;

  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
