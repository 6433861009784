import { TSlide } from '../../../types';
import { useEffect, useState } from 'react';
import { GoToSlide } from '../../../services/GoToSlide';

export const useRevealInstance = () => {
  return useState(null);
};

export const useRevealSlideNavigation = (slides: TSlide[], Reveal: any) => {
  function navigateToSlide(e: CustomEvent) {
    if (!Reveal) return;

    const slideIndex = slides.findIndex(slide => slide.id === e.detail);

    if (slideIndex < 0) return;

    Reveal.slide(slideIndex);
  }

  useEffect(() => {
    GoToSlide.subscribe(navigateToSlide as EventListener);
    return () => GoToSlide.unsubscribe(navigateToSlide as EventListener);
  });
};
