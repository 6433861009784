export const URLS = Object.freeze({
  HOMEPAGE: '/',
  SEARCH: Object.freeze({
    ADDRESS: '/create/search/address',
    DETAILS: '/create/search/details',
  }),
  RESULT: '/create/result',
  ADAPT_RESULT: '/create/result/:presentationId',
});

export const ALLOWED_INTENDED_URLS = [URLS.SEARCH.ADDRESS, URLS.SEARCH.DETAILS, URLS.RESULT];
