import { useMemo } from 'react';
import { useOrder } from '../providers/providers/OrderProvider';
import { useSelectedSlide } from '../providers/providers/SelectedSlideProvider';

export const useHasSelectedSlide = () => {
  const { sectionsSlide } = useOrder();
  const { selectedSlide } = useSelectedSlide();

  const allIncludedSlides = useMemo(() => {
    return Object.values(sectionsSlide)?.flatMap(slide => slide);
  }, [sectionsSlide]);

  return useMemo(() => {
    return allIncludedSlides?.find(slideId => slideId === selectedSlide?.id);
  }, [allIncludedSlides, selectedSlide?.id]);
};
