import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { TProperty } from '../../../types';
import styled from 'styled-components';

interface GalleryWithThumbnailsProps {
  property: TProperty;
}

export const GalleryWithThumbnails = React.memo<GalleryWithThumbnailsProps>(function Gallery({
  property,
}) {
  const images =
    property?.images?.map(img => ({
      original: img,
      thumbnail: img,
    })) ?? [];

  return (
    <Container>
      <ImageGallery
        items={images}
        showPlayButton={false}
        autoPlay={true}
        slideInterval={55000}
        lazyLoad={true}
        showThumbnails
      />
    </Container>
  );
});

const Container = styled.div`
  margin-top: 10px;

  & .image-gallery-content.fullscreen {
    height: 100vh;
  }

  & .fullscreen .image-gallery-slide img {
    height: 85vh;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    object-position: center center;
  }

  & .fullscreen .image-gallery-thumbnails-wrapper img {
    height: 12vh;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    object-position: center center;
  }
`;
