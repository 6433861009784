import { useParams } from 'react-router-dom';
import { FEATURES, useHasFeatureFlag } from '../../../../../../hooks/useFeatureFlags';
import { usePreviewMode } from '../../../providers/PreviewModeProvider';
import { usePresentation } from '../../../../../../hooks/usePresentation';

export const useIsToggleHidden = () => {
  const showToggle = useHasFeatureFlag(FEATURES.CpPdfAllListingsToggle);
  const { previewMode } = usePreviewMode();

  const { hash } = useParams<{ hash: string }>();
  const { data: presentation } = usePresentation(hash);

  const isPdf = previewMode === 'pdf';

  const isCMA = presentation?.type === 'winTheListing' || presentation?.type === 'winTheOffer';

  return !showToggle || !isPdf || !isCMA;
};
