import React from 'react';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import { svgStringToDataUrl } from '../../services/svgStringToDataUrl';
import background from './background';

import { BackgroundContainer, Image } from './TraditionalTwoBackground.styles';

export const TraditionalTwoBackground: React.FC = ({ children }) => {
  const frameImage = svgStringToDataUrl(background, useUiConfigColorsPrimary());

  return (
    <BackgroundContainer>
      <Image src={frameImage} />
      {children}
    </BackgroundContainer>
  );
};
