type TLockedStatus = Record<string, boolean | undefined>;
type TEntityId = number | null;

export function getConfigLockedStatus(
  entity: TEntityId,
  primaryOfficeEntity: number,
  primaryTeamEntity: number,
  isOfficeEntity: boolean,
  lockedStatus?: TLockedStatus,
): boolean | undefined {
  if (!lockedStatus) return undefined;

  if (entity === -2) return lockedStatus['e--2'] ?? undefined;

  if (entity === -1) return lockedStatus['e--1'] ?? undefined;

  if (entity === 0) return lockedStatus['e--1'] || lockedStatus['e-0'] || undefined;

  if (isOfficeEntity) {
    return (
      lockedStatus['e--1'] ||
      lockedStatus['e--2'] ||
      lockedStatus['e-0'] ||
      lockedStatus[`e-${entity}`] ||
      undefined
    );
  }

  const entityId = entity ?? primaryTeamEntity;

  return (
    lockedStatus['e--1'] ||
    lockedStatus['e--2'] ||
    lockedStatus['e-0'] ||
    lockedStatus[`e-${primaryOfficeEntity}`] ||
    lockedStatus[`e-${entityId}`] ||
    undefined
  );
}
