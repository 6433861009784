import React from 'react';

import { Footer } from './Footer/Footer';

import styles from './StepsCard.module.scss';

export const StepsCard = ({ children, className = '' }) => (
  <div className={`${styles.stepsCard} ${className}`}>
    <div className={styles.content}>{children}</div>
    {/* <Footer /> */}
  </div>
);
