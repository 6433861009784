import React, { useState } from 'react';

import { MoreTimeInfoModal } from './MoreTimeInfoModal/MoreTimeInfoModal.component';
import {
  NEXT_STEPS_TYPE,
  TRACKING_EVENTS,
  TrackingEvent,
} from '../../../../../../../services/TrackingEvent';
import { GetStartedModal } from './GetStartedModal/GetStartedModal.component';
import { usePresentation } from '../../../../../../../hooks/usePresentation';
import { ContentContainer, Description } from './ClosingAndNextStepsContent.styles';
import { useSlideProps } from '../../../../../providers/SlideProvider';
import { TPresentation } from '../../../../../../../types';
import { ClosingAndNextStepsTitle } from '../../../../components/common/ClosingAndNextStepsTitle.component';
import { ClosingAndNextStepsButtons } from './ClosingAndNextStepsButtons';

export type TModalType = 'moreTime' | 'getStarted';

export const ClosingAndNextStepsContent: React.FC = () => {
  const [modalType, setModalType] = useState<TModalType | null>(null);

  const { presentationHash } = useSlideProps();

  const query = usePresentation(presentationHash);
  const data = query.data as TPresentation;

  const onOpenModal = (type: TModalType | null) => {
    switch (type) {
      case 'moreTime':
        TrackingEvent.click(
          'Clicked "More time/info" button on "Next Steps" slide',
          TRACKING_EVENTS.NEXT_STEPS_ELEMENT_CLICKED,
          NEXT_STEPS_TYPE.MORE_INFO,
        );
        setModalType('moreTime');
        break;
      case 'getStarted':
        if (data?.agreementLink) {
          TrackingEvent.click(
            `Clicked "Let's get started" button on "Next Steps" slide with agreement link`,
            TRACKING_EVENTS.NEXT_STEPS_ELEMENT_CLICKED,
            NEXT_STEPS_TYPE.LETS_GET_STARTED,
          );
        } else {
          TrackingEvent.click(
            `Clicked "Let's get started" button on "Next Steps" slide without agreement link`,
            TRACKING_EVENTS.NEXT_STEPS_ELEMENT_CLICKED,
            NEXT_STEPS_TYPE.LETS_GET_STARTED,
          );
        }
        setModalType('getStarted');
        break;
      default:
        setModalType(null);
    }
  };

  const onCloseModal = () => {
    setModalType(null);
  };

  const getModalType = (type: TModalType | null) => {
    switch (type) {
      case 'moreTime':
        return (
          <MoreTimeInfoModal
            isModalOpen={modalType === 'moreTime'}
            onClose={onCloseModal}
            data={data as TPresentation}
          />
        );
      case 'getStarted':
        return (
          <GetStartedModal
            isModalOpen={modalType === 'getStarted'}
            onClose={onCloseModal}
            data={data as TPresentation}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ContentContainer>
        <ClosingAndNextStepsTitle />
        <Description>
          This is a big decision, thank you for allowing me the opportunity to assist. Let&apos;s
          discuss next steps.
        </Description>
        <ClosingAndNextStepsButtons onOpenModal={onOpenModal} />
      </ContentContainer>
      {getModalType(modalType)}
    </>
  );
};
