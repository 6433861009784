import styled from 'styled-components';

import { MarketAssessmentGrid } from './MarketAssessmentGrid';

export const MarketAssessmentReport = () => {
  return (
    <Wrapper>
      <Title>MARKET ASSESSMENT</Title>
      <MarketAssessmentGrid />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.v2.gray[200]};
`;

const Title = styled.h3`
  color: ${props => props.theme.colors.v2.gray[400]};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
