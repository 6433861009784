import React, { useState } from 'react';

import { EditableInput } from '../components/EditableInput';
import { NumberFormatter } from '../../../../../../../../services/numberFormatter';

const INFO_REQUIRED = 'INFO_REQUIRED';

export const LivingAreaSizeEdit = ({ subject, onSubjectChange, defaultSubjectValue }) => {
  const [editValue, setEditValue] = useState(subject);
  const [enterPressed, setEnterPressed] = useState(false);

  if (!subject) {
    return null;
  }

  const onEditConfirm = () => {
    if (Number.isNaN(parseInt(editValue, 10)) || parseInt(editValue, 10) < 1) {
      setEnterPressed(false);
      setEditValue(defaultSubjectValue);
      return;
    }

    onSubjectChange(parseInt(editValue, 10));
    setEnterPressed(false);
  };

  const onUndoClick = () => {
    setEnterPressed(false);
    onSubjectChange(defaultSubjectValue);
    setEditValue(defaultSubjectValue);
  };
  const onEnterSubmit = () => {
    onEditConfirm();
    setEnterPressed(true);
  };

  const formatLabel = subject => {
    if (subject === INFO_REQUIRED || isNaN(subject)) {
      return 'Please enter';
    }
    return NumberFormatter.format(subject);
  };

  return (
    <EditableInput
      label={formatLabel(subject)}
      value={subject}
      defaultValue={defaultSubjectValue}
      onConfirm={onEditConfirm}
      onUndo={onUndoClick}
      editValue={editValue}
      onChange={val => setEditValue(val)}
      onEnterSubmit={onEnterSubmit}
      enterPressed={enterPressed}
    />
  );
};
