export default `<svg width="1423" height="849" viewBox="0 0 1423 849" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="190.699" cy="317.706" r="130.683" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="4.00045"/>
<circle cx="1047.59" cy="132.975" r="130.683" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="4.00045"/>
<path d="M295.608 393.462L344.388 426.633" stroke="#414141" stroke-width="4.00045"/>
<path d="M263.683 623.219L311 620.78" stroke="#414141" stroke-width="4.00045"/>
<path d="M1081.24 429.56L1169.54 383.706" stroke="#414141" stroke-width="4.00045"/>
<path d="M1110.02 631.511L1149.54 634.438" stroke="#414141" stroke-width="4.00045"/>
<path d="M952.951 265.658L970.999 238.829" stroke="#414141" stroke-width="4.00045"/>
<circle cx="1287.59" cy="326.145" r="130.683" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="4.00045"/>
<circle cx="1285.15" cy="637.853" r="135.073" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="4.00045"/>
<circle cx="710.878" cy="589.686" r="401.298" stroke="#027B76" stroke-width="13.0014"/>
<circle cx="132.693" cy="624.741" r="130.683" fill="white" fill-opacity="0.4" stroke="#027B76" stroke-width="4.00045"/>
<circle cx="28.8149" cy="525.863" r="27.8049" fill="#444444"/>
<path d="M32.9577 538.887H26.5172V518.384L20.191 520.232V515.354L32.367 511.143H32.9577V538.887Z" fill="white"/>
<circle cx="86.8212" cy="218.829" r="27.8049" fill="#444444"/>
<path d="M96.0896 231.853H76.7298V227.661L85.6475 218.286C87.8452 215.783 88.944 213.795 88.944 212.322C88.944 211.128 88.6836 210.219 88.1628 209.597C87.6419 208.974 86.8861 208.663 85.8952 208.663C84.9171 208.663 84.1231 209.082 83.5134 209.921C82.9036 210.747 82.5988 211.782 82.5988 213.027H76.1582C76.1582 211.325 76.5838 209.756 77.4349 208.32C78.286 206.872 79.4674 205.741 80.9791 204.928C82.4908 204.115 84.1803 203.709 86.0477 203.709C89.0457 203.709 91.3513 204.401 92.9646 205.786C94.5906 207.171 95.4036 209.159 95.4036 211.75C95.4036 212.843 95.2004 213.91 94.7939 214.951C94.3874 215.98 93.7522 217.066 92.8884 218.21C92.0373 219.34 90.659 220.858 88.7535 222.764L85.1712 226.899H96.0896V231.853Z" fill="white"/>
<circle cx="1146.94" cy="29.8083" r="27.8049" fill="#444444"/>
<path d="M1142.72 26.2729H1145.75C1148.15 26.2729 1149.35 25.0978 1149.35 22.7477C1149.35 21.8331 1149.06 21.0899 1148.49 20.5183C1147.92 19.9339 1147.11 19.6418 1146.07 19.6418C1145.22 19.6418 1144.48 19.8895 1143.84 20.3849C1143.22 20.8803 1142.91 21.4964 1142.91 22.2332H1136.49C1136.49 20.7723 1136.89 19.4703 1137.71 18.327C1138.52 17.1837 1139.64 16.2944 1141.08 15.6593C1142.53 15.0114 1144.11 14.6875 1145.84 14.6875C1148.93 14.6875 1151.36 15.3925 1153.12 16.8026C1154.89 18.2126 1155.77 20.1499 1155.77 22.6143C1155.77 23.8084 1155.4 24.939 1154.66 26.0061C1153.94 27.0605 1152.88 27.9179 1151.48 28.5785C1152.96 29.112 1154.11 29.925 1154.95 31.0175C1155.79 32.0973 1156.21 33.4375 1156.21 35.0381C1156.21 37.5152 1155.26 39.4969 1153.35 40.9832C1151.44 42.4695 1148.94 43.2126 1145.84 43.2126C1144.03 43.2126 1142.34 42.8697 1140.77 42.1837C1139.22 41.485 1138.05 40.5259 1137.25 39.3064C1136.45 38.0742 1136.05 36.6768 1136.05 35.1143H1142.51C1142.51 35.9654 1142.85 36.7022 1143.54 37.3247C1144.22 37.9471 1145.07 38.2584 1146.07 38.2584C1147.2 38.2584 1148.1 37.9471 1148.78 37.3247C1149.45 36.6895 1149.79 35.8829 1149.79 34.9047C1149.79 33.5074 1149.44 32.5165 1148.74 31.9322C1148.04 31.3478 1147.07 31.0556 1145.84 31.0556H1142.72V26.2729Z" fill="white"/>
<circle cx="1386.97" cy="222.83" r="27.8049" fill="#444444"/>
<path d="M1393.83 225.011H1396.65V229.965H1393.83V235.853H1387.41V229.965H1376.68L1376.3 226.04L1387.41 208.166V208.109H1393.83V225.011ZM1382.42 225.011H1387.41V216.474L1387.01 217.122L1382.42 225.011Z" fill="white"/>
<circle cx="1383.89" cy="534.767" r="27.8049" fill="#444444"/>
<path d="M1374.18 534.224L1375.88 520.048H1392.09V525.04H1381.1L1380.47 530.547C1380.93 530.28 1381.52 530.045 1382.26 529.842C1383.01 529.639 1383.74 529.537 1384.45 529.537C1387.21 529.537 1389.32 530.356 1390.8 531.995C1392.28 533.621 1393.03 535.908 1393.03 538.855C1393.03 540.633 1392.63 542.247 1391.83 543.695C1391.04 545.13 1389.93 546.235 1388.49 547.01C1387.06 547.785 1385.36 548.173 1383.4 548.173C1381.66 548.173 1380.03 547.817 1378.51 547.106C1376.98 546.381 1375.79 545.397 1374.93 544.152C1374.06 542.894 1373.64 541.478 1373.65 539.903H1380.09C1380.15 540.919 1380.48 541.726 1381.06 542.323C1381.65 542.92 1382.41 543.218 1383.37 543.218C1385.53 543.218 1386.61 541.618 1386.61 538.417C1386.61 535.457 1385.28 533.977 1382.64 533.977C1381.14 533.977 1380.03 534.459 1379.29 535.425L1374.18 534.224Z" fill="white"/>
</svg>
`;
