import React from 'react';

import { pricePerSquareFootData } from '../data';
import { SlideWithGraphWrapper } from '../../../../../components/common/SlideWithGraphWrapper';
import { TCategory } from '../../../../../../../../types';
import { PricePerSqftChart } from '../../../../../../../../features/report/charts/price-per-sqft/PricePerSqftChart';

interface PricePerSquareFootSlideProps {
  category: TCategory;
}

const PricePerSquareFootSlide: React.FC<PricePerSquareFootSlideProps> = ({ category }) => (
  <SlideWithGraphWrapper
    description={pricePerSquareFootData.description}
    heading={pricePerSquareFootData.heading}
    category={category}
  >
    <PricePerSqftChart style={{ height: 500 }} />
  </SlideWithGraphWrapper>
);

export default PricePerSquareFootSlide;
