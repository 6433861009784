import styled, { useTheme } from 'styled-components';

import { Icon } from '../../../../../components/Common';
import { IconName } from '../../../../../components/Common/Icon/Icon.types';
import PresentationCard from './PresentationCard';

interface CardsWrapperProps {
  data: {
    title: string;
    iconName: IconName;
    cards: {
      title: string;
      url: string;
      list: { name: string; enabled: boolean }[];
    }[];
  };
  style?: React.CSSProperties;
}

const CardsWrapper: React.FC<CardsWrapperProps> = ({ data, style }) => {
  const { title, iconName } = data;
  const { colors } = useTheme();

  return (
    <Container style={style}>
      <HeadingWrapper>
        <Icon iconName={iconName} fill={colors.v2.gray[400]} />
        <TextWrapper>
          <Label>PRESENTATIONS FOR</Label>
          <Title>{title}</Title>
        </TextWrapper>
      </HeadingWrapper>
      <ChildrenWrapper>
        {data.cards.map((card, i) => (
          <PresentationCard key={`${card.title}_${i}`} data={card} />
        ))}
      </ChildrenWrapper>
    </Container>
  );
};

export default CardsWrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  border: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);

  @media (max-width: 980px) {
    width: 100%;
    transition: opacity 0.3s ease-in-out;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Figtree;
`;

const ChildrenWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Figtree;
  color: #444;
`;

const Label = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

const Title = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
