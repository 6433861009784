import { TFullOrder, TOrderByType, TPresentationType, TUserConfigsCategory } from '../types';

export class OrderByTypeAdapter {
  private static getValidOrderByType(order: TFullOrder | null, type: TPresentationType) {
    if (!order) return undefined;

    if (type === 'winTheListing') {
      const slidesToExclude = ['homeBuyingProcess', 'whatBuyersWantFromTheirAgents'];
      return {
        ...order,
        sectionsSlide: {
          ...(order && order.sectionsSlide),
          whyIAmTheRightFit:
            order &&
            order.sectionsSlide.whyIAmTheRightFit.filter(slide => {
              if (!slide?.id) return;
              return !slidesToExclude.includes(slide.id);
            }),
        },
      };
    }
    if (type === 'winTheOffer') {
      return {
        ...order,
        sectionsSlide: {
          ...(order && order.sectionsSlide),
          whyIAmTheRightFit:
            order &&
            order.sectionsSlide.whyIAmTheRightFit.filter(
              slide => slide?.id !== 'homeSellingProcess',
            ),
          whatsMyHomeWorth:
            order &&
            order.sectionsSlide.whatsMyHomeWorth.filter(
              slide => slide?.id !== 'whereDoBuyersGetTheirInfo',
            ),
        },
      };
    }

    return order;
  }

  static getOrderByType(categoryCustomization?: TUserConfigsCategory): TOrderByType | undefined {
    if (!categoryCustomization) return undefined;

    if (categoryCustomization.orderByType) return categoryCustomization.orderByType;

    const { order } = categoryCustomization;
    if (!order) return undefined;

    return {
      winTheListing: OrderByTypeAdapter.getValidOrderByType(order || null, 'winTheListing'),
      winTheOffer: OrderByTypeAdapter.getValidOrderByType(order || null, 'winTheOffer'),
      presentInfo: order,
      winTheRepresentation: order,
    } as TOrderByType;
  }
}
