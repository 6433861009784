import React from 'react';
import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

import { Container, ImageWrapper, ImagePreview } from './FourImagesAlphaLayout.styles';
import { useSlide } from '../../../../providers/SlideProvider';

interface Props {
  hideHeading?: boolean;
}

export const FourImagesAlpha: React.FC<Props> = ({ hideHeading }) => {
  const slide = useSlide();
  return (
    <Container style={{ gridTemplateRows: hideHeading ? '1fr 1fr' : 'auto 1fr 1fr' }}>
      {!hideHeading && (
        <SlideTextBlock
          headline={slide.data?.headline}
          isHeadlineCenter
          isEditable={false}
          containerStyles={{ gridColumn: '1 / -1', gridRow: ' 1 / 1' }}
        />
      )}
      <ImageWrapper>
        <ImagePreview src={slide.data?.image} />
      </ImageWrapper>
      <ImageWrapper>
        <ImagePreview src={slide.data?.image2} />
      </ImageWrapper>
      <ImageWrapper>
        <ImagePreview src={slide.data?.image3} />
      </ImageWrapper>
      <ImageWrapper>
        <ImagePreview src={slide.data?.image4} />
      </ImageWrapper>
    </Container>
  );
};

export default FourImagesAlpha;
