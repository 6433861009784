import React from 'react';

import {
  Container,
  SlideTitle,
  PropertyInfoWrapper,
  Wrapper,
  Text,
  Image,
} from './LuxuryCover.styles';

import { CoverLogos } from '../components/CoverLogos/CoverLogos.component';

import bg from './bg.png';
import { useClient } from '../../../../../../../providers/providers/ClientProvider';

export const LuxuryCoverBT: React.FC = () => {
  const { client } = useClient();

  return (
    <Container>
      <Image src={bg} />
      <Wrapper>
        <PropertyInfoWrapper>
          <Text>Tour Prepared for</Text>
          <SlideTitle>{client?.name}</SlideTitle>
        </PropertyInfoWrapper>
        <CoverLogos presentedByStyles={{ fontFamily: 'Cinzel' }} />
      </Wrapper>
    </Container>
  );
};
