import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { corePresentApi } from '../../../../../api/CorePresentApi';
import { useInvalidateComparableExtensions } from './useComparableExtensions';
import { usePresentationCreationQuery } from '../../../../../hooks/usePresentationCreationQuery';
import { useInvalidatePresentation } from '../../../../../hooks/usePresentation';
import { isUpdatePresentationWithBuildAtom } from '../../../../../features/report/state/isUpdatePresentationWithBuildAtom';
import { useSetAtom } from 'jotai';

type CreatePayload = {
  name: string;
  value: number | null;
  propertyId: string;
};

async function createExtension(payload: CreatePayload) {
  return corePresentApi.post(`/comparable-property/${payload.propertyId}/extensions`, {
    name: payload.name,
    value: payload.value,
  });
}

export function useComparableExtensionCreate(): UseMutationResult<void, Error, CreatePayload> {
  const { presentation: presentationHash } = usePresentationCreationQuery();
  const invalidatePresentation = useInvalidatePresentation(presentationHash);
  const invalidate = useInvalidateComparableExtensions();
  const setIsUpdatePresentationWithBuild = useSetAtom(isUpdatePresentationWithBuildAtom);

  return useMutation(createExtension, {
    onSuccess: (_, variables) => {
      invalidate(variables.propertyId);
      invalidatePresentation();
      setIsUpdatePresentationWithBuild(true);
    },
  });
}
