import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-solid-svg-icons';
import { StyleButton } from './controls.styles';
import { TToolbarSize } from '../TextEditor.component';

type WithOpen = {
  isOpen?: boolean;
};

type WithToolbarSize = {
  toolbarSize?: TToolbarSize;
};

const Container = styled(StyleButton)<WithToolbarSize>(({ toolbarSize }) => ({
  position: 'relative',
  width: toolbarSize === 'large' ? 80 : 40,
  border: '1px solid #ddd',
  fontSize: toolbarSize === 'large' ? 18 : 10,
}));

const Dropdown = styled('div')<WithOpen>(({ isOpen }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  backgroundColor: '#fff',
  zIndex: 2,
  width: '100%',
  border: '1px solid #ddd',
  display: isOpen ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const Item = styled('div')<WithToolbarSize>(({ toolbarSize }) => ({
  minHeight: toolbarSize === 'large' ? 40 : 20,
  padding: toolbarSize === 'large' ? '0 10px' : '0 5px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

type TLineHeight = 'lineheight-1' | 'lineheight-1.5' | 'lineheight-2';

export const LineHeightControl: React.FC = (props: any) => {
  const { editorState, onChange, modalHandler } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (style: any) => {
    const selection = editorState.getSelection();

    const nextContentState = Object.keys(props.styleMap).reduce((contentState, lineHeight) => {
      return Modifier.removeInlineStyle(contentState, selection, lineHeight);
    }, editorState.getCurrentContent());

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

    const currentStyle = editorState.getCurrentInlineStyle();

    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state: EditorState, lineHeight: TLineHeight) => {
        return RichUtils.toggleInlineStyle(state, lineHeight);
      }, nextEditorState);
    }
    if (!currentStyle.has(style)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style);

      onChange(nextEditorState);
    }
  };
  let signalOpen: boolean;

  const onExpandEvent = () => {
    signalOpen = !isOpen;
  };

  const expandCollapse = () => {
    setIsOpen(signalOpen);
    signalOpen = false;
  };

  useEffect(() => {
    modalHandler.registerCallBack(expandCollapse);

    return () => {
      modalHandler.deregisterCallBack(expandCollapse);
    };
  });

  return (
    <Container onClick={onExpandEvent} toolbarSize={props.toolbarSize}>
      <FontAwesomeIcon icon={['fas', `line-height` as IconName]} />
      <Dropdown isOpen={isOpen}>
        <Item onClick={() => onClick('lineheight-1')} toolbarSize={props.toolbarSize}>
          single
        </Item>
        <Item onClick={() => onClick('lineheight-1.5')} toolbarSize={props.toolbarSize}>
          1.5
        </Item>
        <Item onClick={() => onClick('lineheight-2')} toolbarSize={props.toolbarSize}>
          double
        </Item>
      </Dropdown>
    </Container>
  );
};
