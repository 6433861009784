import { corePresentApi } from '../../../api/CorePresentApi';
import { TSlide, TSlideId, TUserConfigs } from '../../../types';
import { syncCustomizationSettingsWithPersonilzedSlides } from './syncCustomizationSettingsWithPersonilzedSlides';

export class PersonalizedSlidesApi {
  static async fetch(hash: string): Promise<any | null> {
    const res = await corePresentApi.get(`/presentation-personalizations`, {}, { hash });

    return res.data ?? [];
  }

  static async update({
    config = {} as Record<TSlideId, Partial<TSlide>>,
    customizationData,
  }: {
    config: Record<TSlideId, Partial<TSlide>>;
    customizationData: TUserConfigs;
  }): Promise<any> {
    const res = await corePresentApi.post(`/presentation-personalizations`, {
      data: JSON.stringify(config),
    });

    if (customizationData) {
      syncCustomizationSettingsWithPersonilzedSlides(customizationData, config);
    }

    return res.data;
  }
}
