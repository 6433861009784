import { useQuery } from '@tanstack/react-query';
import { AnalyticsSummaryApi } from './api/AnalyticsSummaryApi';
import { useMemo } from 'react';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { getAnalyticsFeedbackText } from '../../../../services/getAnalyticsFeedbackText';
import {
  FEEDBACK_RESPONSE,
  MORE_TIME_DESCRIPTIONS,
  TAnalyticsSummaryData,
} from '../../../../types';

export const useAnalyticsSummaryData = () => {
  const { data, isLoading } = useQuery(['analytics-summary'], () =>
    AnalyticsSummaryApi.fetchSummary(),
  );

  const { feedIds } = useUser()!;

  const avgBuyerValue = useMemo(() => {
    return getAnalyticsFeedbackText(
      data?.avgBuyerResponse as FEEDBACK_RESPONSE,
      data?.avgBuyerFeedbackOption as keyof typeof MORE_TIME_DESCRIPTIONS,
    );
  }, [data]);

  const avgSellerValue = useMemo(() => {
    return getAnalyticsFeedbackText(
      data?.avgSellerResponse as FEEDBACK_RESPONSE,
      data?.avgSellerFeedbackOption as keyof typeof MORE_TIME_DESCRIPTIONS,
    );
  }, [data]);

  const analyticsSummaryData: TAnalyticsSummaryData[] | null = useMemo(() => {
    if (!data) return null;

    let analyticsData = [
      {
        label: 'Presentations',
        value: data?.presentationCount,
      },
      {
        label: 'Avg Time Spent',
        value: data?.avgDuration,
      },
      {
        label: 'Avg Open Rate',
        value: `${(data?.avgOpenRate as number).toFixed(2)}%`,
      },
      {
        label: 'Avg Seller Response',
        value: avgSellerValue,
        isSmallFont: !!data?.avgSellerResponse,
      },
      {
        label: 'Avg Buyer Response',
        value: avgBuyerValue,
        isSmallFont: !!data?.avgBuyerResponse,
      },
    ];

    if (feedIds.length === 0) {
      analyticsData = analyticsData.slice(0, -2);
    }

    return analyticsData;
  }, [avgBuyerValue, avgSellerValue, data, feedIds.length]);

  return {
    analyticsSummaryData,
    isLoading,
  };
};
