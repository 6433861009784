export default `<svg width="1512" height="761" viewBox="0 0 1512 761" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="737.829" cy="722.536" r="429.756" stroke="#027B76" stroke-width="12.6829"/>
<circle cx="134.091" cy="471.968" r="131.384" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="341.732" cy="193.756" r="150.732" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="1140.76" cy="173.268" r="171.22" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
<circle cx="1361.24" cy="489.365" r="148.78" fill="white" fill-opacity="0.85" stroke="#027B76" stroke-width="3.90244"/>
<path d="M426.122 317.171L472.951 381.073" stroke="#444444" stroke-width="3.90244"/>
<path d="M1053.44 319.122L1006.61 387.415" stroke="#444444" stroke-width="3.90244"/>
<path d="M252.463 529.854L335.39 571.317" stroke="#444444" stroke-width="3.90244"/>
<path d="M1231.98 564.488L1150.51 593.757" stroke="#444444" stroke-width="3.90244"/>
</svg>
`;
