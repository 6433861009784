import styled from 'styled-components';
import React, { PropsWithChildren, useRef, useEffect, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

export const WebAspectRatioBox: React.FC<PropsWithChildren<{}>> = ({
  children,
}: PropsWithChildren<{}>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (!containerRef.current) return;

      const { width, height } = containerRef.current.getBoundingClientRect();
      const aspectRatio = width / height > 16 / 9 ? height * (16 / 9) : width;
      setDimensions({ width: aspectRatio, height: aspectRatio * (9 / 16) });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('change', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('change', updateDimensions);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <Content dimensions={dimensions}>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Content = styled.div<{ dimensions: Dimensions }>`
  position: absolute;
  width: ${({ dimensions }) => dimensions.width}px;
  height: ${({ dimensions }) => dimensions.height}px;
  left: 50%;
  transform: translate(-50%, 0);
`;
