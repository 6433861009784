import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { Card } from '../../../../../components/Card/Card.component';
import { data, title } from '../../data';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr 1fr 1fr',
  rowGap: 20,
  columnGap: 20,
  width: '100%',
  height: '100%',
});

export const WhyIAmTheRightBuyersAgentModernPDF = () => {
  const cards = [{ title, primaryColorTitle: true }, ...data];

  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      <Grid>
        {cards.map((card, i) => (
          <Card
            key={i}
            {...card}
            type='pdf'
            titleStyles={{
              width: '98%',
              fontSize: '24px',
              height: '55px',
              letterSpacing: 0,
              marginTop: '10px',
            }}
            textStyles={{ width: '100%' }}
          />
        ))}
      </Grid>
    </PdfFrame>
  );
};
