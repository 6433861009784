import styled from 'styled-components';
import { useUiConfigFontsBody } from '../../../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
});

export const GraphContainer = styled('div')({
  height: '60%',
});

export const DescriptionContainer = styled('div')({
  height: 'auto',
});

export const SourceText = styled('div')(({ theme }) => ({
  fontSize: 11,
  fontWeight: 300,
  color: '#101010',
  textAlign: 'right',
  fontFamily: useUiConfigFontsBody(),
  transform: 'translateY(5px)',
}));
