import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../components/Common/V2/Button/Button';
import { useOrder } from '../../../../providers/providers/OrderProvider';
import { useClient } from '../../../../providers/providers/ClientProvider';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../providers/providers/SectionsProvider';
import { useVideoLink } from '../../providers/VideoLinkProvider';
import { useProperties } from '../../../../providers/providers/PropertiesProvider';
import { useUrlSearchParam } from '../../../../hooks/useQueryParams';
import { useCategory } from '../../../../providers/providers/CategoryProvider';
import { PRESENTATION_TYPES } from '../../../../constants/PresentationTypes';
import { useUiConfigs } from '../../../../providers/providers/UiConfigProvider';
import { PresentationApi } from '../../../../pages/Presentation/api/PresentationApi';
import { TClient, TPresentation } from '../../../../types';
import { toast } from 'react-toastify';
import { useCreatePresentation } from '../../../../pages/PresentationCreate/hooks/sharedHooks';
import { useDomainWhitelabelSettings } from '../../../whitelabel/useDomainWhitelabelSettings';

export const CreateBuyerTourActions: React.FC = () => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const ui = useUiConfigs();
  const { client } = useClient();
  const { slides } = useSlides();
  const { category } = useCategory();
  const { sections } = useSections();
  const { videoLink } = useVideoLink();
  const { properties } = useProperties();
  const { sections: sectionsOrder, sectionsSlide } = useOrder();

  const createPresentation = useCreatePresentation();

  const action = useUrlSearchParam('action');
  const presentationHash = useUrlSearchParam('presentation');

  const queryClient = useQueryClient();

  const { data } = useDomainWhitelabelSettings();

  const activeProperties = React.useMemo(
    () => properties.filter(p => p.status === 'active'),
    [properties],
  );

  const onCreatePresentation = async () => {
    setIsLoading(true);

    try {
      const presentation = {
        ui,
        properties: activeProperties,
        slides,
        sections,
        order: { sections: sectionsOrder, sectionsSlide },
        category,
        type: PRESENTATION_TYPES.BUYER_TOUR,
        videoLink,
      };

      let theId = '';
      let theHash = '';

      if (action === 'edit' && presentationHash) {
        const { id } = (await PresentationApi.fetch(presentationHash ?? '')) as any;
        const presentationData = {
          ...presentation,
          whiteLabelSettings: data?.whiteLabelSettings || null,
        };
        const response = await PresentationApi.update(id, presentationData);
        await PresentationApi.updateClient(id, client as TClient);
        queryClient.invalidateQueries(['presentation']);

        theId = response.id ?? '';
        theHash = response.hash ?? '';
      } else {
        const { id, hash: presHash } = await createPresentation(
          presentation as TPresentation,
          client,
        );

        theId = id;
        theHash = presHash;
      }

      await PresentationApi.triggerPdfGeneration(theId);

      push(`/presentation/${theHash}/edit`);
    } catch (err) {
      console.error(err);
      toast(
        'We had trouble creating your presentation. Please try again. If the issue persists, please contact our support team.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  const enableCreatePresentation = !!client && !!activeProperties.length;

  return (
    <Button
      variant='primary'
      onClick={onCreatePresentation}
      style={{ marginRight: 10, width: 142 }}
      isLoading={isLoading}
      disabled={isLoading || !enableCreatePresentation}
    >
      Save & Continue
    </Button>
  );
};
