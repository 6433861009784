import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import styled, { useTheme } from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TProperty } from '../../../../../../../../types';
import { useActionButtons } from '../../../../../../hooks/useActionButtons';
import { isPresentationViewAtom } from '../../../../../../state/isPresentationViewAtom';

interface Props {
  property: TProperty;
}

export const ActionIndicator = ({ property }: Props) => {
  const { colors } = useTheme();
  const { isLiked, isFeatured, isDeleted } = useActionButtons(property);

  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const icon = useMemo(() => {
    if (isFeatured) return ['fas', 'star'];
    if (isLiked) return ['fas', 'thumbs-up'];
    if (isDeleted && !isPresentationView) return ['fas', 'undo'];
  }, [isDeleted, isFeatured, isLiked, isPresentationView]);

  return <Container>{icon && <Icon icon={icon as IconProp} color={colors.gray[400]} />}</Container>;
};

const Icon = styled(FontAwesomeIcon)`
  font-size: 13px;
`;

const Container = styled.div`
  display: flex;
`;
