import { FC } from 'react';
import { useTheme } from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { CustomizedAxisTick } from './CustomizedAxisTick';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';

interface DoubleBarsChartProps {
  data?: any[];
  firstBarDataKey?: string;
  secondBarDataKey?: string;
  customTooltip?: (props: any) => JSX.Element;
  xLabelKey?: string;
  moreInfo?: (f: any) => any;
  primaryColor?: string;
  secondaryColor?: string;
}

export const DoubleBarsChart: FC<DoubleBarsChartProps> = ({
  data = [],
  firstBarDataKey = 'uv',
  secondBarDataKey = 'pv',
  customTooltip = null,
  xLabelKey,
  moreInfo = f => f,
  primaryColor,
  secondaryColor = '#bdc3c7',
}) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer>
      <BarChart data={data} barGap={0} margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey='name'
          padding={{ left: 20, right: 20 }}
          stroke='black'
          tick={<CustomizedAxisTick graphData={data} moreInfo={moreInfo} xKey={xLabelKey} />}
          tickLine={false}
          tickMargin={10}
          interval={0}
          color='#fff'
        />
        <YAxis
          tickMargin={10}
          tickLine={false}
          axisLine={false}
          tickFormatter={number => MoneyFormatter.format(number)}
          width={100}
          allowDecimals={false}
        />

        {customTooltip && typeof customTooltip === 'function' ? (
          <Tooltip content={customTooltip} />
        ) : (
          <Tooltip />
        )}

        <Bar dataKey={firstBarDataKey} fill={primaryColor || theme.colors.v2.primary} />
        <Bar dataKey={secondBarDataKey} fill={secondaryColor} />
      </BarChart>
    </ResponsiveContainer>
  );
};
