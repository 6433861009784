import React from 'react';

import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { useListingDetailsPdf } from './useListingDetailsPDF';

const ListingDetailsPDF = () => {
  const { properties } = useProperties();

  const propertyDetailPages = useListingDetailsPdf(properties);

  return propertyDetailPages;
};

export default ListingDetailsPDF;
