import styled from 'styled-components';
import { ExteriorFeaturesOffMarket } from './ExteriorFeaturesOffMarket';
import { InteriorFeaturesOffMarket } from './InteriorFeaturesOffMarket';
import { OtherFeaturesOffMarket } from './OtherFeaturesOffMarket';

export const OffMarketFeatures = () => {
  return (
    <Wrapper>
      <ExteriorFeaturesOffMarket />
      <InteriorFeaturesOffMarket />
      <OtherFeaturesOffMarket />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
