import { ReportGraphs, TPricingFavorability } from '../../../types';

export function extractPricingFavorability(graphs: ReportGraphs): TPricingFavorability {
  const rawData = graphs?.pricing_favorability || {};

  if (!rawData) return {} as TPricingFavorability;

  const graph =
    rawData?.graph?.map((value, key) => ({
      value: key,
      fav: value.favorability,
      label: value.month,
      count: value.count,
      price: value.price,
      sale_count: value?.sale_count,
      sale_price: value?.sale_price,
      adjusted_count: value?.adjusted_count,
      adjusted_price: value?.adjusted_price,
      adjusted_sale_count: value?.adjusted_sale_count,
      adjusted_sale_price: value?.adjusted_sale_price,
    })) || [];

  return {
    text: rawData.text,
    description: rawData.description,
    graph,
  };
}
