import { useQueryClient, useMutation } from '@tanstack/react-query';
import { DynamicSlidesApi } from '../../api/DynamicSlidesApi';
import { TSection, TSlide } from '../../types';
import { useUserCustomizations } from '../useUserCategoryCustomizations';
import { useInvalidateCustomizations } from '../../data/customizations/useRawCustomizations';

export const useUpdateDynamicSlide = (hash: string, entityId: number | null) => {
  const { data: customizationData } = useUserCustomizations(hash, entityId);
  const invalidateCustomizations = useInvalidateCustomizations();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (args: { section: TSection; slide: TSlide; entityId: number | null }) =>
      DynamicSlidesApi.update(args.section, args.slide, hash, args.entityId, customizationData),
    {
      onSuccess: data => {
        queryClient.setQueryData(['dynamicSlides', hash], data.slides);
        invalidateCustomizations(hash);
      },
      onError: err => {
        console.error('Error while updating dynamic slide', err);
      },
    },
  );
  return mutation;
};
