import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { Container, ImagePreview, ImageWrapper, LeftCol, RightCol } from './OneImageAlpha.styles';
import { ImageProxy } from '../../../../services/ImageProxy';

interface Props {
  reverse?: boolean;
}

export const OneImageAlphaPdf: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
        <LeftCol isPdf>
          <ImageWrapper isPdf>
            <ImagePreview src={ImageProxy.optimize(slide.data?.image, { width: 1350 })} />
          </ImageWrapper>
        </LeftCol>
        <RightCol isPdf>
          <SlideTextBlock
            isEditable={false}
            text={slide.data?.text}
            headline={slide.data?.headline}
          />
        </RightCol>
      </Container>
    </PdfFrame>
  );
};
