import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../../components/Common/V2/Button/Button';
import styled from 'styled-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { initMapCoordinatesAtom } from '../state/initMapCoordinatesAtom';
import { mapCoordinatesAtom } from '../state/mapCoordinatesAtom';
import { initRadiusAtom } from '../state/initRadiusAtom';
import { radiusAtom } from '../state/radiusAtom';
import { initMapModeAtom } from '../state/initMapModeAtom';
import { mapModeAtom } from '../state/mapModeAtom';
import { searchAreaValueAtom } from '../state/searchAreaValueAtom';
import { areaDetailsAtom } from '../state/areaDetailsAtom';
import { initAreaDetailsAtom } from '../state/initAreaDetailsAtom';
import { initAreaTypeAtom } from '../state/initAreaTypeAtom';
import { areaTypeAtom } from '../state/areaTypeAtom';

const Wrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 63px;
`;

const deepCopy = (value: any) => JSON.parse(JSON.stringify(value));
export const ResetButton = () => {
  const initMapCoordinates = useAtomValue(initMapCoordinatesAtom);
  const setMapCoordinates = useSetAtom(mapCoordinatesAtom);
  const initRadius = useAtomValue(initRadiusAtom);
  const setRadius = useSetAtom(radiusAtom);
  const initMapMode = useAtomValue(initMapModeAtom);
  const setMapMode = useSetAtom(mapModeAtom);
  const setSearchAreaValue = useSetAtom(searchAreaValueAtom);
  const setAreaDetails = useSetAtom(areaDetailsAtom);
  const initAreaDetails = useAtomValue(initAreaDetailsAtom);
  const initAreaType = useAtomValue(initAreaTypeAtom);
  const setAreaType = useSetAtom(areaTypeAtom);
  const onReset = () => {
    setMapCoordinates(deepCopy(initMapCoordinates));
    setRadius(deepCopy(initRadius));
    setMapMode(initMapMode);
    setSearchAreaValue('');
    setAreaDetails(initAreaDetails);
    setAreaType(initAreaType);
  };

  return (
    <Wrapper>
      <Button
        variant={'secondary'}
        onClick={onReset}
        prefixIcon={<FontAwesomeIcon icon={['fas', 'rotate-right']} />}
      >
        Reset
      </Button>
    </Wrapper>
  );
};
