import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { FeatureFlag } from '../../types';
import { useFeatureFlagIds } from './useFeatureFlagIds';
import { corePresentApi } from '../../api/CorePresentApi';

async function fetchAgentFeatureFlags(userKvCoreId: number): Promise<FeatureFlag[]> {
  const response = await corePresentApi.get(`/auth/featureFlags/${userKvCoreId}`);

  return Object.values(response);
}

export function useAgentFeatureFlags(): UseQueryResult<FeatureFlag[]> {
  const ffIds = useFeatureFlagIds();

  return useQuery(
    ['feature-flags', 'user', ffIds.kvCoreUserId],
    () => fetchAgentFeatureFlags(ffIds.kvCoreUserId),
    {
      enabled: Boolean(ffIds.kvCoreUserId),
      refetchInterval: 10 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    },
  );
}
