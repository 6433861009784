import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TSlide } from '../../../../../../types';
import { usePreviewMode } from '../../../providers/PreviewModeProvider';
import { useSelectedSlideNavigators } from './useSelectedSlideNavigators';
import { useOrder } from '../../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { useActiveSection } from '../../../../../../components/Slide/providers/ActiveSectionProvider';
import { ButtonsGroup } from '../../../../../../components/Common/V2/ButtonsGroup';

export const PrevNextButtons: React.FC = () => {
  const { colors } = useTheme();

  const {
    getOrderedSlides,
    sections: sectionsOrder,
    sectionsSlide: sectionsSlideOrder,
  } = useOrder();
  const { slides } = useSlides();
  const { previewMode } = usePreviewMode();
  const { activeSection } = useActiveSection();
  const { selectedSlide } = useSelectedSlide();

  const activeSectionPosition = sectionsOrder.findIndex(s => s === activeSection);

  const filteredSlides = useMemo((): TSlide[] => {
    if (!slides) return [];

    return getOrderedSlides()
      .map(slideId => slides[slideId] ?? null)
      .filter(s => !!s) as TSlide[];
  }, [slides, getOrderedSlides]);

  const isNotFirstSectionInPdfMode =
    previewMode === 'pdf' && activeSection && activeSection !== 'startingSlides';

  const { onBack, onNext } = useSelectedSlideNavigators(filteredSlides);

  const buttonsConfigs = [
    {
      label: 'Prev',
      onClick: onBack,
      disabled:
        !selectedSlide?.id ||
        (selectedSlide.id === filteredSlides?.[0]?.id && !isNotFirstSectionInPdfMode) ||
        (activeSectionPosition === 0 && selectedSlide.id === 'sectionContents'),
      prefixIcon: <FontAwesomeIcon icon={['fas', 'chevron-left']} size='sm' />,
    },
    {
      label: 'Next',
      onClick: onNext,
      disabled:
        !selectedSlide?.id || selectedSlide.id === filteredSlides[filteredSlides.length - 1]?.id,
      suffixIcon: <FontAwesomeIcon icon={['fas', 'chevron-right']} size='sm' rotate={180} />,
    },
  ];

  return (
    <Container>
      <ButtonsGroup buttonsConfigs={buttonsConfigs} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;
