import React, { CSSProperties } from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex-grow: 1;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  className?: string;
  style?: CSSProperties;
  color?: string;
  centered?: boolean;
  size?:
    | 'xs'
    | 'lg'
    | 'sm'
    | '1x'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x'
    | undefined;
}
const Loader: React.FC<Props> = ({
  className,
  style = {},
  color,
  centered = false,
  size = undefined,
}) => {
  const theme = useTheme();

  if (centered) {
    return (
      <Container style={style} className={className}>
        <Content>
          <FontAwesomeIcon
            size={size}
            icon={['far', 'circle-notch']}
            color={color ?? theme.colors.v2.primary}
            spin
          />
        </Content>
      </Container>
    );
  }

  return (
    <FontAwesomeIcon
      className={className}
      size={size}
      icon={['far', 'circle-notch']}
      color={color ?? theme.colors.v2.primary}
      spin
    />
  );
};

export default Loader;
