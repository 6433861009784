import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../../constants/categories';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useSlide } from '../../../../providers/SlideProvider';
import { NetProceedsInfo } from '../components/NetProceedsInfo/NetProceedsInfo.component';
import { NetProceedsTable } from '../components/NetProceedsTable/NetProceedsTable.component';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const NetProceedsPDF: React.FC = () => {
  const { category } = useCategory();
  const slide = useSlide();

  const getCardsData = () => {
    if (!slide?.netProceedsData) return [];
    const [netProceeds1, netProceeds2, netProceeds3] = slide.netProceedsData;

    if (!netProceeds1) return;

    const listPrice1 = netProceeds1?.listPrice?.price;
    const costs1 =
      netProceeds1.mortgagePayoffs.first +
      netProceeds1.realEstateFees.listingOffice +
      netProceeds1.realEstateFees.sellingOffice +
      netProceeds1.settlementCosts.escrowFee +
      netProceeds1.settlementCosts.titleInsurance +
      netProceeds1.settlementCosts.proratedPropertyTax +
      netProceeds1.transferExciseTax.stateTax +
      netProceeds1.otherCosts.assessments +
      netProceeds1.otherCosts.HOAFees +
      netProceeds1.otherCosts.administrativeFee +
      netProceeds1.otherCosts.homeWarrantyPolicy +
      netProceeds1.otherCosts.inspections +
      netProceeds1.otherCosts.closingCosts;

    const listPrice2 = netProceeds2.listPrice?.price;
    const costs2 =
      netProceeds2.mortgagePayoffs.first +
      netProceeds2.realEstateFees.listingOffice +
      netProceeds2.realEstateFees.sellingOffice +
      netProceeds2.settlementCosts.escrowFee +
      netProceeds2.settlementCosts.titleInsurance +
      netProceeds2.settlementCosts.proratedPropertyTax +
      netProceeds2.transferExciseTax.stateTax +
      netProceeds2.otherCosts.assessments +
      netProceeds2.otherCosts.HOAFees +
      netProceeds2.otherCosts.administrativeFee +
      netProceeds2.otherCosts.homeWarrantyPolicy +
      netProceeds2.otherCosts.inspections +
      netProceeds2.otherCosts.closingCosts;

    const listPrice3 = netProceeds3.listPrice?.price;
    const costs3 =
      netProceeds3.mortgagePayoffs.first +
      netProceeds3.realEstateFees.listingOffice +
      netProceeds3.realEstateFees.sellingOffice +
      netProceeds3.settlementCosts.escrowFee +
      netProceeds3.settlementCosts.titleInsurance +
      netProceeds3.settlementCosts.proratedPropertyTax +
      netProceeds3.transferExciseTax.stateTax +
      netProceeds3.otherCosts.assessments +
      netProceeds3.otherCosts.HOAFees +
      netProceeds3.otherCosts.administrativeFee +
      netProceeds3.otherCosts.homeWarrantyPolicy +
      netProceeds3.otherCosts.inspections +
      netProceeds3.otherCosts.closingCosts;

    const card1 = {
      listPrice: listPrice1,
      estimatedPrice: listPrice1,
      costs: costs1,
      netProceeds: listPrice1 - costs1,
    };

    const card2 = {
      listPrice: listPrice2,
      estimatedPrice: listPrice2,
      costs: costs2,
      netProceeds: listPrice2 - costs2,
    };

    const card3 = {
      listPrice: listPrice3,
      estimatedPrice: listPrice3,
      costs: costs3,
      netProceeds: listPrice3 - costs3,
    };

    return [card1, card2, card3];
  };

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <NetProceedsInfo isWeb={false} cards={getCardsData()} />
        <NetProceedsTable data={slide?.netProceedsData} />
      </Container>
    </PdfFrame>
  );
};
