import { CSSProperties } from 'react';
import styled from 'styled-components';

import { TProperty } from '../../../../types';
import { SectionTitle } from '../components/SectionTitle';
import { Price } from './Price';
import { PriceAdjustments } from './PriceAdjustments/PriceAdjustments';

interface Props {
  property: TProperty;
  containerStyle?: CSSProperties;
  hideCompAdjustments?: boolean;
  hideActions?: boolean;
}

export const Intro = ({ property, containerStyle, hideCompAdjustments, hideActions }: Props) => {
  return (
    <Container style={containerStyle}>
      <SectionTitle>Price</SectionTitle>
      <Price property={property} />
      {!hideCompAdjustments && <PriceAdjustments property={property} hideActions={hideActions} />}
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid #ddd;
`;
