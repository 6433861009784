import React from 'react';

import { useUiConfigs } from '../../../providers/providers/UiConfigProvider';
import { PreviewContainer, ButtonWrapper } from './PresentationPreview.styles';
import { Button } from '../../../components/Common';
import PreviewSlide from '../../../components/PreviewSlide/PreviewSlide.component';
import { useUiConfigInfoPlacement } from '../../../providers/providers/UiConfigInfoPlacementProvider';
import { TSlide } from '../../../types';

interface PresentationPreviewProps {
  presentationHash?: string;
  previewSlide?: TSlide;
}

export const PresentationPreview: React.FC<PresentationPreviewProps> = ({
  presentationHash,
  previewSlide,
}) => {
  const { theme } = useUiConfigs();
  const { infoPlacement } = useUiConfigInfoPlacement();

  return (
    <PreviewContainer>
      <PreviewSlide
        slide={previewSlide}
        theme={theme}
        infoPlacement={infoPlacement}
        hideProgressTracker
      />
      <ButtonWrapper>
        <Button
          size='large'
          btnstyle='option'
          onClick={() => window.open(`/present/presentation/${presentationHash}`)}
        >
          Open Preview
        </Button>
      </ButtonWrapper>
    </PreviewContainer>
  );
};
