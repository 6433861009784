import React from 'react';
import image from './image.png';
import { paragraphs } from './data';
import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';

export const HomeBuyingProcessLuxuryPDF: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading='Home Buying Process'
      image={image}
      paragraphs={paragraphs}
    />
  );
};
