import styled from 'styled-components';
import { useAtomValue } from 'jotai';

import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { ButtonsGroup } from '../../../../components/Common/V2/ButtonsGroup';

interface BigPictureModeProps {
  bigPictureMode: boolean;
  setBigPictureMode: (value: boolean) => void;
}

export const BigPictureMode = ({ bigPictureMode, setBigPictureMode }: BigPictureModeProps) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primary = useUiConfigColorsPrimary();

  const bgColorStyle = isPresentationView ? { backgroundColor: primary } : {};

  const bigPictureButtons = [
    {
      label: 'Scale',
      onClick: () => setBigPictureMode(false),
      isActive: !bigPictureMode,
      style: !bigPictureMode ? bgColorStyle : {},
    },
    {
      label: 'Graph',
      onClick: () => setBigPictureMode(true),
      isActive: bigPictureMode,
      style: bigPictureMode ? bgColorStyle : {},
    },
  ];
  return (
    <Wrapper>
      <ButtonsGroup buttonsConfigs={bigPictureButtons} groupWidth={'242px'} disabled={false} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-self: end;
`;
