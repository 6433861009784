import React from 'react';
import styled from 'styled-components';

import overlay from './overlay.png';
import slideGraphics from './slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import { cards } from '../../data';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  position: relative;
`;

const Image = styled.img`
  width: 79%;
  margin: 0 auto;
  display: block;
  top: 4px;
  position: relative;
`;

const OverlayImage = styled.img`
  z-index: 1;
  width: 87.5%;
  top: 8px;
  position: absolute;
  right: 98px;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 45px;
  margin-bottom: 100px;
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
  position: absolute;
  top: 25px;
  left: 0;
  margin-top: 0;
  width: 525px;
  line-height: 1.3;
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

export const HomeBuyingProcessTraditional: React.FC = () => {
  const primary = useUiConfigColorsPrimary();

  const img = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  const headingStyles = {
    fontSize: 18,
    color: '#444',
    fontWeight: 700,
  };

  const textStyles = {
    fontSize: 16,
    fontWeight: 400,
    color: '#444',
  };

  return (
    <ImageWrapper>
      <Heading>
        The Home
        <br />
        Buying Process
      </Heading>
      <Image src={img} />
      <OverlayImage src={overlay} />
      <TraditionalSlideCircularCard
        heading={cards[3].title}
        text={cards[3].text}
        position={{ top: 440, left: 146 }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        size={270}
      />
      <TraditionalSlideCircularCard
        heading={cards[1].title}
        text={cards[1].text}
        position={{ top: 170, left: 197 }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        size={263}
      />
      <TraditionalSlideCircularCard
        heading={cards[4].title}
        text={cards[4].text}
        position={{ top: -13, right: 340.5 }}
        headingStyles={headingStyles}
        textStyles={textStyles}
        size={298}
      />
      <TraditionalSlideCircularCard
        heading={cards[2].title}
        text={cards[2].text}
        position={{ top: 167.5, right: 145.5 }}
        headingStyles={{ ...headingStyles, width: '70%' }}
        textStyles={textStyles}
        size={270}
      />
      <TraditionalSlideCircularCard
        heading={cards[5].title}
        text={cards[5].text}
        position={{ top: 426, right: 140 }}
        headingStyles={{ ...headingStyles, width: '70%' }}
        textStyles={textStyles}
        size={291}
      />
    </ImageWrapper>
  );
};
