import { useAtomValue } from 'jotai';
import React from 'react';
import { useStore } from 'react-redux';
import { subjectAtom } from '../../../../../features/report/state/subjectAtom';

export const useSubjectInfo = () => {
  const subject = useAtomValue(subjectAtom);
  const { address, beds, baths, totalBaths, size, coordinates, propertyType } = subject?.property;

  return React.useMemo(
    () => ({
      addressShort: address?.deliveryLine,
      address: address || {},
      beds,
      baths,
      totalBaths,
      size,
      coordinates,
      propertyType,
    }),
    [address, beds, baths, totalBaths, size, coordinates, propertyType],
  );
};

export const useSubjectInfoGetter = () => {
  const store = useStore();

  return () => store.getState()?.report?.reportDetailed?.subject || {};
};
