import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Button } from '../../components/Common/V2/Button/Button';

type Props = {
  hash: string;
};

export function DashboardHeader({ hash }: Props) {
  const { push } = useHistory();

  return (
    <Container>
      <Title>Presentation Dashboard</Title>
      <Button onClick={() => push(`/presentation/${hash}/edit`)} variant='primary'>
        Edit Presentation
      </Button>
    </Container>
  );
}

const Container = styled('div')(({ theme }) => ({
  padding: '15px 20px',
  borderBottom: `1px solid ${theme.colors.gray[200]}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Title = styled('h5')(({ theme }) => ({
  fontSize: 18,
  color: theme.colors.gray[400],
  fontWeight: 700,
}));
