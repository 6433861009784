import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { EditorWrapper } from './TextEditor.styles';

import { InlineStyleControls } from './controls/InlineStyleControls.component';
import { BlockStyleControls } from './controls/BlockStyleControls.component';
import { TextAlignmentControls } from './controls/TextAlignmentControls.component';
import { CSSObject } from 'styled-components';
import { LineHeightControl } from './controls/LineHeightControl.component';
import { convertFromHTML, convertToHTML } from 'draft-convert';

export type TToolbarSize = 'regular' | 'large';

export type TToolbarOption = 'inline' | 'fontSize' | 'list' | 'textAlign';

interface TextEditorProps {
  onChangeText: (value: string) => void;
  rawHtml: string;
  isInitialCenterAlignment?: boolean;
  textAreaStyles?: CSSObject;
  editorWrapperStyles?: CSSObject;
  toolbarOptions: TToolbarOption[];
  toolbarSize?: TToolbarSize;
  isRefresh?: boolean;
  setIsRefresh?: Dispatch<SetStateAction<boolean>>;
}

export const TextEditor: React.FC<TextEditorProps> = ({
  onChangeText,
  rawHtml,
  isInitialCenterAlignment,
  textAreaStyles,
  editorWrapperStyles,
  toolbarOptions,
  toolbarSize = 'regular',
  isRefresh,
  setIsRefresh,
}) => {
  const contentState = convertFromHTML({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'span' && node.style.fontSize === '12px') {
        return currentStyle.add('fontsize-12');
      }
      if (nodeName === 'span' && node.style.fontSize === '14px') {
        return currentStyle.add('fontsize-14');
      }
      if (nodeName === 'span' && node.style.fontSize === '16px') {
        return currentStyle.add('fontsize-16');
      }
      if (nodeName === 'span' && node.style.fontSize === '18px') {
        return currentStyle.add('fontsize-18');
      }
      if (nodeName === 'span' && node.style.fontSize === '20px') {
        return currentStyle.add('fontsize-20');
      }
      if (nodeName === 'span' && node.style.fontSize === '22px') {
        return currentStyle.add('fontsize-22');
      }
      if (nodeName === 'span' && node.style.lineHeight === '1') {
        return currentStyle.add('lineheight-1');
      }
      if (nodeName === 'span' && node.style.lineHeight === '1.5') {
        return currentStyle.add('lineheight-1.5');
      }
      if (nodeName === 'span' && node.style.lineHeight === '2') {
        return currentStyle.add('lineheight-2');
      }

      return currentStyle;
    },
    htmlToBlock: (nodeName, node) => {
      if (nodeName === 'p' && node.style.textAlign === 'left') {
        return {
          type: 'unstyled',
          data: { 'text-align': 'left' },
        };
      }
      if (nodeName === 'p' && node.style.textAlign === 'right') {
        return {
          type: 'unstyled',
          data: { 'text-align': 'right' },
        };
      }
      if (nodeName === 'p' && node.style.textAlign === 'center') {
        return {
          type: 'unstyled',
          data: { 'text-align': 'center' },
        };
      }
    },
  })(rawHtml ?? '');

  const initialEditorState = contentState
    ? EditorState.createWithContent(contentState)
    : EditorState.createEmpty();

  const [editorState, setEditorState] = useState(initialEditorState);
  const [editor, setEditor] = useState<boolean>(false);

  useEffect(() => {
    if (isRefresh) {
      setEditorState(initialEditorState);
      setIsRefresh?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  useEffect(() => {
    setEditor(true);
  }, []);

  const onChange = (editorState: EditorState) => {
    setEditorState(editorState);

    const html = convertToHTML({
      styleToHTML: (style: any) => {
        if (style === 'fontsize-12') {
          return <span style={{ fontSize: 12 }} />;
        }
        if (style === 'fontsize-14') {
          return <span style={{ fontSize: 14 }} />;
        }
        if (style === 'fontsize-16') {
          return <span style={{ fontSize: 16 }} />;
        }
        if (style === 'fontsize-18') {
          return <span style={{ fontSize: 18 }} />;
        }
        if (style === 'fontsize-20') {
          return <span style={{ fontSize: 20 }} />;
        }
        if (style === 'fontsize-22') {
          return <span style={{ fontSize: 22 }} />;
        }
        if (style === 'lineheight-2') {
          return <span style={{ lineHeight: 2 }} />;
        }
        if (style === 'lineheight-1') {
          return <span style={{ lineHeight: 1 }} />;
        }
        if (style === 'lineheight-1.5') {
          return <span style={{ lineHeight: 1.5 }} />;
        }
      },
      blockToHTML: (block: any) => {
        if (block.data['text-align'] === 'left') {
          return <p style={{ textAlign: 'left' }} />;
        }
        if (block.data['text-align'] === 'right') {
          return <p style={{ textAlign: 'right' }} />;
        }
        if (block.data['text-align'] === 'center') {
          return <p style={{ textAlign: 'center' }} />;
        }
      },
    })(editorState.getCurrentContent());
    onChangeText(html);
  };

  const TextAlignComponent = (props: any) => {
    return <TextAlignmentControls editorState={editorState} {...props} toolbarSize={toolbarSize} />;
  };

  const InlineStyleControlsComponent = (props: any) => {
    return <InlineStyleControls {...props} toolbarSize={toolbarSize} />;
  };

  const BlockStyleControlsComponent = (props: any) => {
    return <BlockStyleControls {...props} toolbarSize={toolbarSize} />;
  };

  const toolbarControlsOptions = {
    options: toolbarOptions,
    inline: {
      inDropdown: false,
      component: InlineStyleControlsComponent,
      options: ['bold', 'italic'],
    },
    fontSize: {
      options: [12, 14, 16, 18, 20, 22],
      className: `font-size ${toolbarSize === 'large' && 'font-size-large-button'}`,
      dropdownClassName: `font-size-dropdown ${
        toolbarSize === 'large' && 'font-size-large-dropdown'
      }`,
    },
    list: {
      component: BlockStyleControlsComponent,
      options: ['unordered'],
    },
    textAlign: {
      component: TextAlignComponent,
      options: ['left', 'center', 'right'],
    },
  };

  const lineHeightStyleMap = {
    'lineheight-1': {
      lineHeight: 1,
    },
    'lineheight-2': {
      lineHeight: 2,
    },
    'lineheight-1.5': {
      lineHeight: 1.5,
    },
  };

  const styleMap = {
    ...lineHeightStyleMap,
    'fontsize-12': {
      fontSize: 12,
    },
    'fontsize-14': {
      fontSize: 14,
    },
    'fontsize-16': {
      fontSize: 16,
    },
    'fontsize-18': {
      fontSize: 18,
    },
    'fontsize-20': {
      fontSize: 20,
    },
    'fontsize-22': {
      fontSize: 22,
    },
    left: {
      textAlign: 'left',
    },
    right: {
      textAlign: 'right',
    },
    center: {
      textAlign: 'center',
    },
  };

  const CustomComponent = (props: any) => {
    return <LineHeightControl {...props} styleMap={lineHeightStyleMap} toolbarSize={toolbarSize} />;
  };

  return (
    <EditorWrapper isInitialCenterAlignment={isInitialCenterAlignment} style={editorWrapperStyles}>
      {editor && (
        <Editor
          customStyleMap={styleMap}
          editorState={editorState}
          onEditorStateChange={onChange}
          toolbar={toolbarControlsOptions}
          toolbarOnFocus
          toolbarStyle={{
            padding: '4px 0',
            position: 'absolute',
            bottom: '100%',
            left: 0,
            border: '1px solid #ddd',
            boxShadow: '2px 4px 12px rgb(0 0 0 / 30%)',
          }}
          wrapperStyle={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
          editorStyle={{
            padding: '0 10px',
            ...textAreaStyles,
          }}
          editorClassName='editor'
          toolbarCustomButtons={[<CustomComponent key={0} />]}
          stripPastedStyles
        />
      )}
    </EditorWrapper>
  );
};
