import React from 'react';

import { activeShelfLifeData } from '../data';
import { SlideWithGraphWrapper } from '../../../../../components/common/SlideWithGraphWrapper';
import { TCategory } from '../../../../../../../../types';
import { ActiveShelfLifeChart } from '../../../../../../../../features/report/charts/active-shelf-life/ActiveShelfLifeChart';

interface ActiveShelfLifeProps {
  category: TCategory;
}

const ActiveShelfLife: React.FC<ActiveShelfLifeProps> = ({ category }) => {
  return (
    <SlideWithGraphWrapper
      description={activeShelfLifeData.description}
      heading={activeShelfLifeData.heading}
      category={category}
    >
      <ActiveShelfLifeChart style={{ height: 550 }} />
    </SlideWithGraphWrapper>
  );
};

export default ActiveShelfLife;
