import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TwoImagesDelta } from './TwoImagesDelta';
import { TwoImagesDeltaForm } from './TwoImagesDeltaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { TwoImagesDeltaPdf } from './TwoImagesDeltaPdf';

const template = 'twoImagesLayoutDelta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - Two Images',
  frame: true,
  data: {
    image: '',
    image2: '',
    text: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TwoImagesDeltaForm,
  web: TwoImagesDelta,
  pdf: TwoImagesDeltaPdf,
};

export type TTwoImagesLayoutDelta = typeof template;
export { config, mappings, thumbnail };
