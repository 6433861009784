import { useCallback, useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { TPastPresentationRaw } from '../../../../../types';
import { PresentationApi } from '../../../../Presentation/api/PresentationApi';

const QUERY_KEY = 'presentations';

type UseQueryOptionsExtended<T> = UseQueryOptions<T> & { beforeRequest: () => void };
const usePresentationsQuery = (options: UseQueryOptionsExtended<TPastPresentationRaw[]>) => {
  return useQuery<TPastPresentationRaw[] | any, Error | any>(
    [QUERY_KEY],
    () => {
      options.beforeRequest();
      return PresentationApi.fetchAll();
    },
    options,
  );
};

export const useLoadPresentationsWithPdfPooling = () => {
  const [refetchInterval, setRefetchInterval] = useState(5000);

  return usePresentationsQuery({
    refetchOnWindowFocus: false,
    refetchInterval,
    beforeRequest: () => {
      setRefetchInterval(5000);
    },
    onSuccess: data => {
      const hasPdfBuildInProgress = data?.some(
        (presentation: any) => !!presentation.build_in_progress,
      );

      if (!hasPdfBuildInProgress) {
        setRefetchInterval(-1);
      }
    },
  });
};

export function useInvalidatePresentations() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEY]);
  }, [queryClient]);
}
