import { KeyboardEvent, useState } from 'react';
import customEstimateIcon from './assets/custom-estimates-icon.png';
import styled from 'styled-components';
import { Input } from '../../../../pages/PresentationCreate/dash/view/components/Input/Input';
import { MoneyInput } from '../../../../pages/PresentationCreate/dash/view/components/Input/MoneyInput';
import { useAtom, useAtomValue } from 'jotai';
import { customEstimatesAtom } from '../../state/customEstimatesAtom';
import { TProperty, TReportSelectedCategory } from '../../../../types';
import { subjectAtom } from '../../state/subjectAtom';

const adaptCustomEstimate = (name: string, value: number, subject: TProperty) => ({
  name,
  value,
  valuePerSqft: Math.round(value / subject.size),
  icon: customEstimateIcon,
  id: `custom_estimate_${Math.random().toString(36).substring(2, 15)}`,
});

export const CustomEstimates = () => {
  const [inputPair, setInputPair] = useState<{ name: string; value: string } | null>({
    name: '',
    value: '',
  });

  const [estimates, setCustomEstimates] = useAtom(customEstimatesAtom);
  const subject = useAtomValue(subjectAtom);

  const onSave = (e: KeyboardEvent<HTMLDivElement>) => {
    if (!inputPair?.name || !inputPair?.value || e.key !== 'Enter') return;

    setCustomEstimates([
      ...estimates,
      adaptCustomEstimate(
        inputPair?.name,
        Number(inputPair?.value),
        subject.property,
      ) as TReportSelectedCategory,
    ]),
      setInputPair(null);
  };

  const onAdd = () => {
    setInputPair({ name: '', value: '' });
  };

  const remove = (index: number) => {
    const filtered = [...estimates];
    filtered.splice(index, 1);
    setCustomEstimates(filtered);
  };

  return (
    <Container onKeyDown={onSave}>
      <Title>
        Manually add multiple “public” estimates to your report. <br />
        <Example>
          Whether from another site (Realtor.com, Redfin, etc) or from a neighbor, friend, family
          member, etc to address the “My Aunt Linda thinks we should list for…” conversation and
          show prominently on the scale.
        </Example>
      </Title>

      {estimates.map(({ name, value }, i) => (
        <EstimatePair key={i}>
          <InputWrapper>
            <Input value={name} disabled noError />
          </InputWrapper>
          <InputWrapper>
            <MoneyInput value={value} disabled noError />
          </InputWrapper>
          <Close onClick={() => remove(i)}>X</Close>
        </EstimatePair>
      ))}

      {inputPair && (
        <EstimatePair>
          <InputWrapper>
            <Input
              placeholder='Estimate Name'
              value={inputPair?.name}
              onChange={(value: string) => {
                setInputPair(prev => {
                  if (!prev) return null;
                  return {
                    ...prev,
                    name: value,
                  };
                });
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <MoneyInput
              placeholder='Estimate Price'
              value={inputPair?.value}
              onChange={(value: string) =>
                setInputPair(prev => {
                  if (!prev) return null;
                  return {
                    ...prev,
                    value: value,
                  };
                })
              }
            />
          </InputWrapper>
        </EstimatePair>
      )}

      <Add isDisabled={Boolean(inputPair)}>
        <span onClick={onAdd}>Add +</span>
      </Add>
    </Container>
  );
};

const Container = styled.div`
  font-size: 14px;
  margin-top: 30px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary.textLight};
  letter-spacing: 0.01em;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  padding-right: 5px;
  margin-top: 10px;
  min-height: 30px;
`;

const Example = styled.span`
  opacity: 0.7;
`;

const EstimatePair = styled.div`
  display: flex;
  margin-top: 5px;
`;

const Add = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: flex-end;

  span {
    padding-right: 5px;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.primary.main};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.7 : 1)};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`;

const InputWrapper = styled.div`
  padding: 3px 5px;
  font-size: 14px;
  width: 100%;
`;

const Close = styled.span`
  padding-left: 4px;
  color: ${({ theme }) => theme.colors.primary.main};
  cursor: pointer;
`;
