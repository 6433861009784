import { TSlide } from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { LuxuryCover, ModernCover, TraditionalCover } from './web';
import { CoverPdf, LuxuryCoverPdf } from './pdf';

const id = 'coverSlide';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Cover',
  frame: false,
  data: {
    displayImage: false,
    image: '',
  },
};

const mappings: TSlideTypeMapping = {
  pdf: {
    [CATEGORIES.TRADITIONAL]: CoverPdf,
    [CATEGORIES.MODERN]: CoverPdf,
    [CATEGORIES.LUXURY]: LuxuryCoverPdf,
  },
  web: {
    [CATEGORIES.TRADITIONAL]: TraditionalCover,
    [CATEGORIES.MODERN]: ModernCover,
    [CATEGORIES.LUXURY]: LuxuryCover,
  },
};

export type TCoverSlide = typeof id;
export { config, mappings };
