import styled from 'styled-components';
import { Icon, Typography, Button } from '../../Common';

export { Icon, Typography, Button };

interface WithOpen {
  open: 0 | 1;
}

interface BodyEl extends WithOpen {
  contentheight?: number;
}

interface DescriptionEl {
  contentheight?: number;
}

interface BodyContentEl {
  bodyContentTopPadding?: number;
}

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: 5,
  overflow: 'hidden',
  width: '100%',
  flexShrink: 0,
  height: 'fit-content',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

export const Head = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 48.67,
  padding: '0 20px',
  cursor: 'pointer',
  flexShrink: 0,
}));

export const Body = styled('div')<BodyEl>(({ theme, contentheight, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  transition: 'height .4s, border .2s linear',
  borderTop: `1px solid ${open ? theme.colors.gray[200] : 'transparent'}`,
  height: open ? 'auto' : 0,
  position: 'relative',
}));

export const BodyContent = styled('div')<BodyContentEl>(({ bodyContentTopPadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: bodyContentTopPadding,
}));

export const TheContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 20,
  '&::-webkit-scrollbar': {
    width: '.3em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colors.gray[200],
    outline: 'none',
  },
  'scroll-behavior': 'smooth',
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.colors.gray[400],
  fontWeight: 500,
  marginLeft: theme.spacing(1.5),
  marginRight: 'auto',
}));

export const Description = styled('div')<DescriptionEl>(({ theme, contentheight }) => ({
  padding: '8px 14px',
  backgroundColor: theme.colors.gray[300],
  alignItems: 'center',
  width: '100%',
  height: contentheight || 'auto',
}));

export const DescriptionWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const TextWrapper = styled('div')({
  marginLeft: '8px',
});

export const AdditionalDescriptionWrapper = styled('div')({
  marginTop: 10,
});
