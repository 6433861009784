import styled from 'styled-components';

export const Container = styled('div')({
  display: 'flex',
  height: '100%',
  flex: 1,
});

export const Content = styled('div')({
  backgroundColor: '#fff',
  width: '67%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ContentWrapper = styled('div')({
  width: '95%',
});

export const Text = styled('p')({
  fontSize: 18,
  lineHeight: '27.6px',
  marginBottom: 27,
  textAlign: 'center',
});

export const DownloadPdfWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 40,
});
