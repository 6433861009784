import styled from 'styled-components';
import { TRangePrice } from './AgentPrice';
import { MoneyInput } from './components/MoneyInput';

interface Props {
  price: TRangePrice;
  onChange: (price: TRangePrice) => void;
  isActivated: boolean;
}

export const RangePrice = ({ price, onChange = f => f, isActivated = false }: Props) => {
  const onFromChange = (from?: number | null) => onChange({ ...price, from });
  const onToChange = (to?: number | null) => onChange({ ...price, to });

  return (
    <Container>
      <InputWrapper>
        <MoneyInput
          placeholder='Min Range'
          value={price.from || ''}
          onChange={onFromChange}
          disabled={isActivated}
        />
      </InputWrapper>
      <InputWrapper>
        <MoneyInput
          placeholder='Max Range'
          value={price.to || ''}
          onChange={onToChange}
          disabled={isActivated}
        />
      </InputWrapper>
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  height: 35px;
  margin-right: -1px;
  width: 40%;
  display: flex;
  gap: 7px;

  @media all and (max-width: 814px) {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  width: 50%;
`;
