import { useTheme } from 'styled-components';
import { useDomainWhitelabelSettings } from './useDomainWhitelabelSettings';
import { FEATURES, useHasFeatureFlag } from '../../hooks/useFeatureFlags';
import { getWhiteLabelColors } from './getWhiteLabelColors';

export const useWhiteLabelSettings = () => {
  const { data } = useDomainWhitelabelSettings();
  const theme = useTheme();
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const hasWL = data && Boolean(data?.whiteLabelSettings);
  const isPremium = data?.whiteLabelSettings?.packageLevel === 'premium';
  const isStandard = data?.whiteLabelSettings?.packageLevel === 'standard';

  const { primary, secondary, primaryLogoColor, secondaryLogoColor } = getWhiteLabelColors({
    theme,
    hasWL,
    wlPrimaryColor: data?.whiteLabelSettings?.primaryColor,
    wlSecondaryColor: data?.whiteLabelSettings?.secondaryColor,
    packageLevel: data?.whiteLabelSettings?.packageLevel,
  });

  const fallbackFavicon = hasBtTheme ? '/boldTrail/favicon.ico' : '/favicon.ico';

  const favicon = data?.whiteLabelSettings?.favicon || fallbackFavicon;

  return {
    hasWL,
    isPremium,
    isStandard,
    primary,
    secondary,
    primaryLogoColor,
    secondaryLogoColor,
    favicon,
  };
};
