import React, { useEffect, useMemo, useState } from 'react';
import { useSlides } from '../../../../../../../../providers/providers/SlidesProvider';
import { useSelectedSlide } from '../../../../../../../../providers/providers/SelectedSlideProvider';
import { useSlide } from '../../../../../../providers/SlideProvider';

import { useUser } from '../../../../../../providers/UserProvider';
import { useIsEditAllowed } from '../../hooks/useIsEditAllowed';
import { EditIcon } from '../EditIcon/EditIcon.component';
import { EditInput } from '../EditInput/EditInput.component';
import { CoverHeading } from '../Heading';
import {
  PropertyText,
  PropertyInfoContainer,
  PropertyFeaturesContainer,
  PropertyFeature,
  Wrapper,
} from './PropertyInfo.styles';
import { useParams } from 'react-router-dom';
import { usePresentation } from '../../../../../../../../hooks/usePresentation';
import { NumberFormatter } from '../../../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { FEATURES, useHasFeatureFlag } from '../../../../../../../../hooks/useFeatureFlags';

interface PropertyInfoProps {
  withImage?: boolean;
  addressShort: string;
  beds: number;
  baths: number;
  size: number;
}

export const PropertyInfo: React.FC<PropertyInfoProps> = ({
  withImage,
  addressShort,
  beds,
  size,
  baths,
}) => {
  const user = useUser();
  const isCanadaUser = user?.isCanadaUser;
  const isEditAllowed = useIsEditAllowed();
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide()!;
  const { setSlide } = useSlides();
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  const { hash } = useParams<{ hash: string }>();

  const { data: presentation } = usePresentation(hash);

  const predefinedTitle = useMemo(() => {
    if (addressShort) return addressShort;

    return presentation?.clientName || '';
  }, [addressShort, presentation?.clientName]);

  const slide = useSlide();

  const [isEditTitle, setIsEditTitle] = useState(false);
  const [titleValue, setTitleValue] = useState(slide?.data?.text || predefinedTitle);

  useEffect(() => {
    setTitleValue(slide?.data?.text || predefinedTitle);
  }, [predefinedTitle, slide?.data?.text]);

  const [isEditFeatures, setIsEditFeatures] = useState(false);

  let featuresText = '';

  if (typeof beds !== 'undefined') featuresText += `${beds} Bedrooms`;
  if (typeof baths !== 'undefined' && hasBathCountFF) featuresText += ` | ${baths} Total Bathrooms`;

  if (typeof size !== 'undefined') {
    featuresText += ` ${
      !isCanadaUser ? ` | ${NumberFormatter.format(size)} SQFT Living Area` : ''
    }`;
  }

  const [featuresValue, setFeaturesValue] = useState(slide?.data?.text2 || featuresText);

  useEffect(() => {
    setFeaturesValue(featuresText);
  }, [featuresText]);

  const onTitleChange = (val: string) => {
    setTitleValue(val);
  };

  const onFeaturesChange = (val: string) => {
    setFeaturesValue(val);
  };

  const onApplyTitleClick = () => {
    if (!selectedSlide) return;

    if (!titleValue) {
      setTitleValue(predefinedTitle);
    }

    const updatedData = {
      ...selectedSlide.data,
      text: titleValue,
    };

    setSlide(selectedSlide.id, {
      ...selectedSlide,
      data: updatedData,
    });

    setSlideDataProperty('text', titleValue);
    setIsEditTitle(false);
  };

  const onApplyFeaturesClick = () => {
    if (!selectedSlide) return;

    if (!featuresValue) {
      setFeaturesValue(featuresText);
    }

    const updatedData = {
      ...selectedSlide.data,
      text2: featuresValue,
    };

    setSlide(selectedSlide.id, {
      ...selectedSlide,
      data: updatedData,
    });

    setSlideDataProperty('text2', featuresValue);
    setIsEditFeatures(false);
  };

  return (
    <PropertyInfoContainer hasImage={withImage}>
      <PropertyText hasImage={withImage}>Presentation prepared for</PropertyText>
      {isEditTitle ? (
        <EditInput
          inputValue={titleValue}
          inputStyles={{ fontSize: withImage ? 34 : 75, fontWeight: 700, width: '100%' }}
          onChange={onTitleChange}
          onApplyClick={onApplyTitleClick}
        />
      ) : (
        <Wrapper>
          <CoverHeading hasImage={withImage}>{titleValue}</CoverHeading>
          {isEditAllowed && <EditIcon onClick={() => setIsEditTitle(true)} />}
        </Wrapper>
      )}
      {isEditFeatures ? (
        <EditInput
          inputValue={featuresValue}
          inputStyles={{ fontSize: 20, fontWeight: withImage ? 300 : 400 }}
          onChange={onFeaturesChange}
          onApplyClick={onApplyFeaturesClick}
          width={withImage ? '50%' : '30%'}
        />
      ) : (
        <PropertyFeaturesContainer>
          <PropertyFeature>{featuresValue}</PropertyFeature>
          {isEditAllowed && <EditIcon onClick={() => setIsEditFeatures(true)} />}
        </PropertyFeaturesContainer>
      )}
    </PropertyInfoContainer>
  );
};
