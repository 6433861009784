import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';

import { TCategory } from '../../../../types';
import { BasicModal } from '../../../../components/Common/BasicModal';
import { SellingPaceCardContent } from '../../../../components/Common/SellingPaceCardContent/SellingPaceCardContent';
import { useSellingPaceTookTimeMessage } from '../../../../components/Slide/slides/marketAnalysis/marketOverview/components/sellingPace/hooks/useSellingPaceTookTimeMessage';
import SellingPaceSlide from '../../../../components/Slide/slides/marketAnalysis/marketOverview/components/sellingPace/web/SellingPaceSlide';
import { sellingPaceAtom } from '../../state/sellingPaceAtom';
import { MarketAssessmentCard } from './MarketAssessmentCard';
import bgImg from './assets/selling-pace-dark.svg?url';
import bgImgLight from './assets/selling-pace.svg?url';

interface SellingPaceGaugeProps {
  trackClick?: () => void;
  category?: TCategory;
}

export const SellingPaceGauge = ({ trackClick, category }: SellingPaceGaugeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const data = useAtomValue(sellingPaceAtom);

  const description = useSellingPaceTookTimeMessage(data);

  const openPopup = () => {
    setIsOpen(true);
    trackClick?.();
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const isDisabled = !data?.graph.length;

  return (
    <Fragment>
      <MarketAssessmentCard
        headline='Selling Pace'
        content={<SellingPaceCardContent data={data} />}
        description={description}
        bgImg={bgImg}
        bgImgLight={bgImgLight}
        clicked={openPopup}
        disabled={isDisabled}
      />

      <BasicModal id='marketOverview' isOpen={isOpen} onClose={closePopup}>
        <SellingPaceSlide category={category || 'traditional'} />
      </BasicModal>
    </Fragment>
  );
};
