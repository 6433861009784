import { useAtomValue } from 'jotai';

import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';

export function usePropertiesForBigPicture() {
  const reportProperties = useAtomValue(reportPropertiesAtom);

  const properties = reportProperties
    .map(property => property.property)
    .filter(property => !property.excluded && !!property.size);

  return properties;
}
