import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import reducers from './rootReducers';
import { loadState, persister } from './statePersister';

const composeEnhancers =
  (!import.meta.env.SSR && // eslint-disable-line
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

let persistedStorage = window.location.href.includes('/create/result') ? loadState() : {};

const middleware = [thunk, persister];

const store = createStore(
  reducers,
  persistedStorage,
  composeEnhancers(applyMiddleware(...middleware)),
);

export default store;
