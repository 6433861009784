import { useUrlSearchParam } from '../../../../../../hooks/useQueryParams';
import { TProperty } from '../../../../../../types';
import { useActionButtons } from '../../../../hooks/useActionButtons';
import styled from 'styled-components';
import { Loading } from '../../../../../../pages/PresentationCreate/dash/view/components/Loading/Loading';
import { ActionButton } from './ActionButton';
import { getPropertyId } from '../../../../services/getPropertyId';

interface Props {
  property: TProperty;
}

export const ActionButtons = ({ property }: Props) => {
  const source = useUrlSearchParam('source');

  const {
    isLiked,
    isFeatured,
    isDeleted,
    onToggleDelete,
    onToggleHighlight,
    onHighlightClick,
    excludeInProgress,
  } = useActionButtons(property);

  const propertyId = getPropertyId(property);

  if (source) return null;

  if (excludeInProgress === propertyId) {
    return (
      <IconButtons>
        <Loading small />
      </IconButtons>
    );
  }

  return (
    <IconButtons>
      {!isDeleted && (
        <ActionButton
          icon='star'
          text='Feature'
          isActive={isFeatured}
          onClick={() => onHighlightClick(property)}
          tooltipText='Click this button to highlight this property on the scale below.'
        />
      )}
      {!isDeleted && (
        <ActionButton
          text='Like'
          icon='thumbs_up'
          isActive={isLiked}
          onClick={() => onToggleHighlight(property)}
          tooltipText='Click this button to include in the overall calculations but not featured on the scale.'
        />
      )}
      <ActionButton
        text={isDeleted ? 'Undo' : 'Delete'}
        icon={isDeleted ? 'undo' : 'trash'}
        isActive={false}
        onClick={() => {
          onToggleDelete(propertyId);
        }}
        tooltipText={
          isDeleted
            ? 'Add the property back to the calculations and the scale.'
            : 'Remove the property from calculations and the scale.'
        }
      />
    </IconButtons>
  );
};

const IconButtons = styled.div`
  display: flex;
  gap: 5px;
  height: 35px;
`;
