import React from 'react';
import { Header } from './Header/Header.component';
import { Container, ContentBox, Copyright, Wrapper, ContentContainer } from './GuestLayout.styles';
import { useLocation } from 'react-router-dom';

type GuestLayoutProps = {
  children: React.ReactNode;
  hideHeader?: boolean;
};

export function GuestLayout({ children, hideHeader }: GuestLayoutProps) {
  const { pathname } = useLocation();

  const isSSO = pathname.includes('/sso');

  const Component = isSSO ? React.Fragment : ContentBox;

  return (
    <Container>
      <Wrapper noTopPadding={Boolean(hideHeader)}>
        {hideHeader ? null : <Header />}
        <ContentContainer>
          <Component>
            {children}
            {!isSSO && (
              <Copyright>
                Copyright © {new Date().getFullYear()} InsideRE, LLC. All rights reserved.
              </Copyright>
            )}
          </Component>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
}
