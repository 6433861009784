import { WritableAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export function useUpdateAtomOnChange<T>(atom: WritableAtom<T, any[], any>, value: T) {
  const setAtom = useSetAtom(atom);

  useEffect(() => {
    setAtom(value);
  }, [setAtom, value]);
}
