import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';

import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import image1 from '../assets/image1.jpg';
import image2 from '../assets/image2.jpg';

const common: React.CSSProperties = {
  fontSize: 20,
  lineHeight: 1.6,
  textAlign: 'justify',
  color: '#fff',
};
const Container = styled('div')({
  height: '100%',
  position: 'relative',
  display: 'flex',
});
const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background:
    'linear-gradient(163.23deg, rgba(0, 0, 0, 0.304) -3.3%, rgba(236, 236, 236, 0.204) 49.23%, rgba(0, 0, 0, 0.276) 103.99%)',
  backdropFilter: 'blur(10px)',
});
const TextContainer = styled('div')(() => ({
  height: '100%',
  padding: '3% 4%',
  width: '50%',
  zIndex: 1,
}));
const TextContentWrapper = styled('div')({
  padding: '8% 8% 0 8%',
  color: '#fff',
  position: 'relative',
  zIndex: 10,
});
const Heading = styled('h3')(({ theme }) => ({
  paddingBottom: 6,
  fontSize: 22,
  fontWeight: 700,
  borderBottom: '1px solid #fff',
  textTransform: 'uppercase',
  textAlign: 'left',
  marginBottom: 30,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const Paragraph = styled('p')(({ theme }) => ({
  ...common,
  marginBottom: 15,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));
export const LI = styled('li')(({ theme }) => ({
  ...common,
  marginTop: 10,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));
const ImageContainer = styled('div')(() => ({
  height: '100%',
  width: '50%',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px',
}));
const ImageBg = styled('div')({
  height: '100%',
  width: '360px',
  position: 'absolute',
  zIndex: 1,
});
const Image = styled('img')({
  width: '80%',
  zIndex: 2,
  border: '4px solid white',
  marginBottom: '30px',
});
const Images = styled('div')({
  width: '80%',
  marginTop: '40px',
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});
const Source = styled('div')({
  color: '#fff',
  textAlign: 'right',
  width: '80%',
  marginTop: '-20px',
  fontSize: '12px',
});
export const ImportanceOfStagingLuxury: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const fontHeader = useUiConfigFontsHeader();
  const fontBody = useUiConfigFontsBody();
  const letterSpacing = useUiConfigHeaderLetterSpacing();
  const boxColor = ColorOpacityApplier.hex(primary, '1.2');
  return (
    <Container style={{ background: primary }}>
      <Overlay />
      <TextContainer>
        <TextContentWrapper>
          <Heading style={{ fontFamily: fontHeader, letterSpacing }}>What is staging?</Heading>
          <Paragraph style={{ fontFamily: fontBody }}>
            Home staging is the preparation of a private residence for sale in the real estate
            marketplace.
          </Paragraph>
          <Heading style={{ fontFamily: fontHeader }}>Why stage a home?</Heading>
          <Paragraph style={{ fontFamily: fontBody }}>
            The goal of staging is to make a home appealing to the highest number of potential
            buyers, thereby selling a property more swiftly and for more money.
          </Paragraph>
          <Heading style={{ fontFamily: fontHeader }}>NOTES:</Heading>
          <ul style={{ paddingLeft: 22.5 }}>
            <LI>83% said staging made it easier for buyers to visualize their future home.</LI>
            <LI>
              25% said staging increased the value offered 1%-5%, compared to similar unstaged homes
              on the market.
            </LI>
            <LI>40% cited that staging had an effect on most buyers’ view of the home.</LI>
          </ul>
        </TextContentWrapper>
      </TextContainer>
      <ImageContainer>
        <ImageBg style={{ background: boxColor }} />
        <Images>
          <Image src={image1} alt='Importance of staging image' />
          <Image src={image2} alt='Importance of staging image' />
          <Source>https://www.imgnyc.com/before-after/</Source>
        </Images>
      </ImageContainer>
    </Container>
  );
};
