const TRADITIONAL = {
  DEFAULT: 'traditionalBeta',
  ALPHA: 'traditionalAlpha',
  BETA: 'traditionalBeta',
  GAMA: 'traditionalGama',
} as const;

const MODERN = {
  DEFAULT: 'modernBeta',
  ALPHA: 'modernAlpha',
  BETA: 'modernBeta',
  GAMA: 'modernGama',
} as const;

const LUXURY = {
  DEFAULT: 'luxuryBeta',
  ALPHA: 'luxuryAlpha',
  BETA: 'luxuryBeta',
  GAMA: 'luxuryGama',
} as const;

export const THEMES = {
  TRADITIONAL,
  MODERN,
  LUXURY,
} as const;
