import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { SingleImageWithFrame } from './SingleImageWithFrame';
import { SingleImageWithFrameForm } from './SingleImageWithFrameForm';
import { SingleImageWithFramePdf } from './SingleImageWithFramePdf';

import thumbnail from './thumbnail.png';

const template = 'singleImageWithFrameTemplate';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - Single image only',
  frame: true,
  data: {
    image: '',
    image2: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: SingleImageWithFrameForm,
  web: SingleImageWithFrame,
  pdf: SingleImageWithFramePdf,
};

export type TSingleImageWithFrameTemplate = typeof template;

export { config, mappings, thumbnail };
