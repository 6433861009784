import React, { useContext, useState } from 'react';

type CreatedAtContextTypes = {
  createdAt: number;
  setCreatedAt: (value: number) => void;
};

const CreatedAtContext = React.createContext<CreatedAtContextTypes | undefined>(undefined);

export const useCreatedAt = () => {
  const context = useContext(CreatedAtContext);

  if (typeof context === 'undefined') {
    throw new Error('usePresentationType cannot be used without PresentationTypeContext');
  }

  return context;
};

export const CreatedAtProvider: React.FC = ({ children }) => {
  const [createdAt, setCreatedAt] = useState<number>(0);

  const value = React.useMemo(
    () => ({
      createdAt,
      setCreatedAt,
    }),
    [createdAt],
  );

  return <CreatedAtContext.Provider value={value}>{children}</CreatedAtContext.Provider>;
};
