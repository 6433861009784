import React, { useCallback } from 'react';

import { SlideFactory } from './services/SlideFactory';
import { CATEGORIES } from '../../constants/categories';
import { SlidePropsProvider } from './providers/SlideProvider';
import WebFrame from './components/SlideFrame/frames/web/WebFrame';
import type { TSlideId, SlideContentFactoryProps } from '../../types';
import { useCategory } from '../../providers/providers/CategoryProvider';
import { Container, SlideContentContainer, Frameless } from './Slide.styles';

const Slide: React.FC<SlideContentFactoryProps> = ({
  user,
  slide,
  theme,
  infoPlacement,
  isEditable,
  isDefaultConfig,
  platform = 'web',
  activeSection,
  hideProgressTracker = false,
  presentationHash,
  onCursorTracking = e => {},
  showTemplatePreview = false,
  whiteLabelSettings,
}) => {
  const { category } = useCategory();

  const SlideComponent = SlideFactory.create({
    slide: slide.id as TSlideId,
    theme,
    platform,
    isEditable,
    template: slide.template,
    category: category ?? CATEGORIES.TRADITIONAL,
  });

  const onMouseClick = useCallback(
    function onMouseClick(event) {
      onCursorTracking({ clicks: event });
    },
    [onCursorTracking],
  );

  const onMouseMove = useCallback(
    function onMouseMove(event) {
      onCursorTracking({ movements: event });
    },
    [onCursorTracking],
  );

  if (!SlideComponent) return null;

  if (platform === 'pdf') {
    return (
      <SlidePropsProvider
        slide={slide}
        hideProgressTracker={hideProgressTracker}
        whiteLabelSettings={whiteLabelSettings}
      >
        <SlideComponent activeSection={activeSection} />
      </SlidePropsProvider>
    );
  }

  return (
    <Container platform={platform} id={slide.id} onClick={onMouseClick} onMouseMove={onMouseMove}>
      <SlidePropsProvider
        slide={slide}
        isEditable={isEditable}
        isDefaultConfig={isDefaultConfig}
        presentationHash={presentationHash}
        showTemplatePreview={showTemplatePreview}
      >
        {!slide.frame ? (
          <Frameless>
            <SlideComponent theme={theme} />
          </Frameless>
        ) : (
          <WebFrame
            infoPlacement={infoPlacement}
            theme={theme}
            hideProgressTracker={hideProgressTracker}
          >
            <SlideContentContainer>
              <SlideComponent />
            </SlideContentContainer>
          </WebFrame>
        )}
      </SlidePropsProvider>
    </Container>
  );
};

export default Slide;
