import React from 'react';

import Logos from '../../../../components/Logos/Logos';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';
import { HeaderContainer, LogoWrapper } from './ModernFrameGama.styles';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { CommonFrameProps } from '../../../../../../../../types';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

const ModernFrameGama: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => (
  <PlacementAwareContainer infoPlacement={infoPlacement} style={{ backgroundColor: '#F8F8F8' }}>
    <HeaderContainer>
      <BrokerInfo
        width='33.333%'
        imageStyles={{ borderRadius: 0 }}
        nameFontStyles={{ fontSize: 18, color: '#333333' }}
        contactFontStyles={{ fontSize: 14, color: '#333333' }}
        containerStyles={{
          backgroundColor: '#EDEDED',
          height: '100%',
          paddingLeft: useReverseHeader() ? 0 : 20,
          paddingRight: !useReverseHeader() ? 0 : 20,
        }}
      />
      {!hideProgressTracker && (
        <ProgressTracker
          category={CATEGORIES.MODERN}
          fontColor='#333333'
          containerStyles={{
            backgroundColor: '#F5F5F5',
            paddingBottom: 21,
            paddingTop: 5,
            height: '100%',
          }}
        />
      )}
      <LogoWrapper>
        <Logos containerStyles={{ width: '90%' }} />
      </LogoWrapper>
    </HeaderContainer>

    <TocPortalContainer offset={-36} zIndex={20} />

    {children}
  </PlacementAwareContainer>
);

export default ModernFrameGama;
