import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../constants/categories';
import { useCategory } from '../../../../../providers/providers/CategoryProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
} from '../../../../../providers/providers/UiConfigFontProvider';
import { PdfFrame } from '../../../components/SlideFrame/frames/pdf/PdfFrame';
import shape from './assets/shape_pdf.png';
import * as content from './content';
import laptop from './assets/laptop.png';
import screen from './assets/screen.png';
import { useBrandName } from '../../../../../hooks/useBrandName';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import { useSlideLogo } from './useSlideLogo';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 0,
  position: 'relative',
  overflow: 'hidden',
  padding: '20px 40px',
});

const TextContainer = styled('div')({
  width: 300,
  marginBottom: 300,
});

const Shape = styled('img')({
  position: 'absolute',
  zIndex: 1,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '50%',
});

const Text = styled('p')(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.3,
  letterSpacing: useUiConfigBodyLetterSpacing(),
  fontFamily: useUiConfigFontsBody(),
}));

const UL = styled('ul')(({ theme }) => ({
  zIndex: 50,
  paddingLeft: 30,
  '& li': { margin: 0 },
}));

const Logo = styled('img')<{ hasBtTheme: boolean }>(({ hasBtTheme }) => ({
  alignSelf: 'flex-start',
  marginBottom: 25,
  width: hasBtTheme ? 180 : 150,
}));

const Laptop = styled('img')({ position: 'absolute', right: 20, top: 240, zIndex: 3, width: 800 });
const Screen = styled('img')({ position: 'absolute', zIndex: 4, top: 330, right: 75, width: 410 });

const CoreSocialPdf: React.FC = () => {
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const { social } = useBrandName();
  const { category } = useCategory();

  const logo = useSlideLogo();

  const text = content.text.replaceAll('$brand', social);

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <Logo hasBtTheme={hasBtTheme} src={logo || ''} />
        <TextContainer>
          <Text>{text}</Text>
        </TextContainer>
        <Shape src={shape} />
        <Text style={{ fontWeight: 700, zIndex: 10 }}>{content.heading}</Text>
        <UL>
          {content.listItems.map(item => (
            <li key={item}>
              <Text>{item}</Text>
            </li>
          ))}
        </UL>
      </Container>
      <Laptop src={laptop} />
      <Screen src={screen} />
      <Text
        style={{
          fontWeight: 700,
          color: '#000',
          position: 'absolute',
          zIndex: 10,
          right: 110,
          bottom: 570,
          fontSize: 12,
        }}
      >
        EXAMPLE
      </Text>
    </PdfFrame>
  );
};

export default CoreSocialPdf;
