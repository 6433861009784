import React from 'react';

import { TCategory } from '../../../../../../types';
import { PdfUniversalFrame } from './PdfUniversalFrame';
import { CATEGORIES } from '../../../../../../constants/categories';
import { useSlideProps } from '../../../../providers/SlideProvider';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';

export interface CommonPdfFrameProps {
  isCover?: boolean;
  hideProgressTracker?: boolean;
}

interface PdfFrameProps extends CommonPdfFrameProps {
  category: TCategory;
}
export const PdfFrame: React.FC<PdfFrameProps> = ({
  children,
  category,
  isCover,
  hideProgressTracker,
}) => {
  const frames: Record<TCategory, React.FC> = {
    [CATEGORIES.TRADITIONAL]: PdfUniversalFrame,
    [CATEGORIES.MODERN]: PdfUniversalFrame,
    [CATEGORIES.LUXURY]: PdfUniversalFrame,
  };

  const { hideProgressTracker: hideProgressTrackerSlideProps } = useSlideProps();

  const { presentationType } = usePresentationType();

  const FrameComponent: React.FC<CommonPdfFrameProps> = frames[category];
  if (!FrameComponent) {
    return null;
  }

  return (
    <FrameComponent
      isCover={isCover}
      hideProgressTracker={
        hideProgressTracker || hideProgressTrackerSlideProps || presentationType === 'buyerTour'
      }
    >
      {children}
    </FrameComponent>
  );
};
