import styled from 'styled-components';
import { Icon } from '../Icon/Icon.component';

export { Icon };

export const Container = styled('header')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 99,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 70,
  backgroundColor: '#fff',
  padding: '0 20px',
  '& > svg:first-child': {
    marginRight: '10%',
  },
  fontFamily: 'Figtree',
}));

export const ChildrenWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
