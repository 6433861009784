import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { SlideItem } from './SlideItem.component';
import { TSection, TSectionConfig, TSlide, TSlideId, TTag } from '../../../../../../../../../types';
import { useCategory } from '../../../../../../../../../providers/providers/CategoryProvider';
import { ExpandableCardComponent } from '../../../../../../../../../components/Common/ExpandableCard/ExpandableCard.component';
import { useSlides } from '../../../../../../../../../providers/providers/SlidesProvider';
import { useCustomizationOrder } from '../../../../../../../../../providers/providers/CustomizationOrderProvider';
import { usePresentationType } from '../../../../../../../../../providers/providers/PresentationTypeProvider';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';
import { useLoadPersonalized } from '../../../../../../../../../data/personalized/useLoadPersonalized';
import { isPopulatedPersonalizedSlide } from '../../../../../../../../../services/isPopulatedPersonalizedSlide';
import { Accordion } from '../../../../../../../../../components/Common/V2/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface SlideSectionConfigProps {
  sections: Record<TSection, TSectionConfig>;
  section: TSectionConfig;
  open: boolean;
  selectedSlide?: TSlide;
  index: number;
  activeTags: TTag[];
  onSlideSelect: (slideId: TSlide) => void;
  onToggle: () => void;
}

export const SlideSectionConfigItem: React.FC<SlideSectionConfigProps> = ({
  sections,
  section,
  selectedSlide,
  index,
  onSlideSelect,
  activeTags,
  ...expandableProps
}) => {
  const { customizationSectionsSlideByType, setCustomizationSectionSlidesOrderByType } =
    useCustomizationOrder();

  const { category } = useCategory();
  const { slides: slidesMap } = useSlides();
  const { presentationType } = usePresentationType();
  const { hash } = useUser()!;
  const { data } = useLoadPersonalized(hash);

  const onSwitch = (slideId: TSlide['id'], val: boolean) => {
    const updatedSlides = customizationSectionsSlideByType?.[presentationType]?.[section.id].map(
      s => {
        if (s.id !== slideId) return s;
        s.includeSlide = val;
        return s;
      },
    );
    setCustomizationSectionSlidesOrderByType(section.id, updatedSlides ?? [], presentationType);
  };

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const updatedSlides = [
      ...(customizationSectionsSlideByType?.[presentationType]?.[section.id] ?? []),
    ];

    if (updatedSlides[destinationIndex].id === 'closingAndNextSteps') return;
    const movingItem = updatedSlides.splice(sourceIndex, 1);

    updatedSlides.splice(destinationIndex, 0, movingItem[0]);

    setCustomizationSectionSlidesOrderByType(section.id, updatedSlides, presentationType);
  };

  const checkIsMissingActiveTag = useCallback(
    (slide: TSlide | undefined) => {
      const hasActiveTags = activeTags?.length;
      if (!slide || !hasActiveTags) return false;

      return (
        !slide.template ||
        !slide.tags?.length ||
        !activeTags?.every(at => slide.tags?.find(st => st.id === at.id))
      );
    },
    [activeTags],
  );

  if (!slidesMap) return null;

  return (
    <Draggable
      key={section.id}
      draggableId={section.id}
      index={index}
      isDragDisabled={section.id === 'closingAndNextSteps'}
    >
      {(provided, snapshot) => (
        <Accordion
          hideDropdownIcon={section.id === 'closingAndNextSteps'}
          caret={false}
          prefixIcon={
            section.id === 'closingAndNextSteps' ? null : (
              <FontAwesomeIcon icon={'grip-dots-vertical'} />
            )
          }
          {...expandableProps}
          provided={provided}
          title={section.label}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={section.id} type={section.id} key={section.id}>
              {(provided, snapshot) => (
                <SlidesList ref={provided.innerRef} {...provided.droppableProps}>
                  {customizationSectionsSlideByType?.[presentationType]?.[section.id]?.map(
                    (slideItem, i) => {
                      if (!slideItem?.id || slideItem.excludeFromConfig) return null;
                      if (slideItem.id === 'netProceeds') return null;
                      if (!isPopulatedPersonalizedSlide(slideItem.id!, data)) return null;

                      const slide = slidesMap?.[slideItem.id as TSlideId];
                      if (!slide || checkIsMissingActiveTag(slide)) return null;

                      return (
                        <SlideItem
                          key={i}
                          disabled={!slideItem.includeSlide}
                          selected={selectedSlide?.id === slideItem.id}
                          checked={slideItem.includeSlide}
                          slide={slide}
                          index={i}
                          onClick={() => onSlideSelect(slide)}
                          onSwitch={() =>
                            onSwitch(slideItem.id as TSlideId, !slideItem.includeSlide)
                          }
                        />
                      );
                    },
                  )}
                  {provided.placeholder}
                </SlidesList>
              )}
            </Droppable>
          </DragDropContext>
        </Accordion>
      )}
    </Draggable>
  );
};

const SlidesList = styled.div`
  padding: 15px;
`;
