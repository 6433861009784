import React from 'react';
import styled from 'styled-components';
import { SlideSectionHeaderComponent } from '../../../components/common/SlideSectionHeader.component';

interface WithPdf {
  isPdf?: boolean;
}

const Wrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  flexGrow: 1,
  borderRadius: 5,
  boxShadow: isPdf ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.15)',
  border: isPdf ? ' 1px solid #DDDDDD' : 'none',
}));

const SlideContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%' },
});

interface ChartContainerProps {
  heading: string;
  description?: string;
  children: React.ReactNode;
  isPdf?: boolean;
}

export const ChartContainer: React.FC<ChartContainerProps> = ({
  heading,
  description = '',
  children,
  isPdf,
}) => {
  return (
    <Wrapper isPdf={isPdf}>
      <SlideSectionHeaderComponent description={description} heading={heading} isPdf={isPdf} />
      <SlideContent>{children}</SlideContent>
    </Wrapper>
  );
};
