import React from 'react';
import styled from 'styled-components';

import { TSection } from '../../../types';
import { SlidesCarousel } from './SlidesCarousel/SlidesCarousel.component';
import { CarouselControlsProvider } from './providers/CarouselControlsProvider';
import { LibraryNavigation } from './LibraryNavigation/LibraryNavigation.component';
import { DynamicSlidesTags } from './DynamicSlidesTags/DynamicSlidesTags.component';

export type TActiveSection = TSection | 'allSlides';

const LibraryContentWrapper = styled.div`
  position: relative;
`;

export const LibraryContent: React.FC = () => {
  return (
    <LibraryContentWrapper>
      <CarouselControlsProvider>
        <LibraryNavigation />
        <DynamicSlidesTags />
        <SlidesCarousel />
      </CarouselControlsProvider>
    </LibraryContentWrapper>
  );
};
