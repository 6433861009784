import { TCompConfigItem } from '../../../../../types';
import { FeatureGroup } from '../components/FeatureGroup';
import { Section } from '../components/Section';
import { FEATURES } from '../featuresConfig';
import { FEATURES_KEYS } from '../types';

export const ExteriorFeaturesOffMarket = () => {
  return (
    <Section isClosed title='EXTERIOR:'>
      <FeatureGroup
        title='PROPERTY POOL'
        fields={
          [
            FEATURES[FEATURES_KEYS.POOL_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.POOL_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='PROPERTY VIEW'
        fields={
          [
            FEATURES[FEATURES_KEYS.VIEW_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.VIEW_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='WATER FRONT'
        fields={
          [
            FEATURES[FEATURES_KEYS.WATER_FRONT_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.WATER_FRONT_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='WATER VIEW'
        fields={
          [
            FEATURES[FEATURES_KEYS.WATER_VIEW_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.WATER_VIEW_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='DECK'
        fields={
          [
            FEATURES[FEATURES_KEYS.DECK_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.DECK_NO_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='BASEMENT'
        fields={
          [
            FEATURES[FEATURES_KEYS.BASEMENT_NONE_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BASEMENT_YES_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BASEMENT_FINISHED_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BASEMENT_PARTIAL_OFF_MARKET],
            FEATURES[FEATURES_KEYS.BASEMENT_UNFINISHED_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='GARAGE'
        fields={
          [
            FEATURES[FEATURES_KEYS.GARAGE_0_OFF_MARKET],
            FEATURES[FEATURES_KEYS.GARAGE_1_OFF_MARKET],
            FEATURES[FEATURES_KEYS.GARAGE_2_OFF_MARKET],
            FEATURES[FEATURES_KEYS.GARAGE_3_OFF_MARKET],
          ] as TCompConfigItem[]
        }
      />
    </Section>
  );
};
