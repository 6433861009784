import React from 'react';

import { CoverLogos } from '../CoverLogos/CoverLogos.component';

import { ContentWrapper, InfoContainer } from './CoverContent.styles';
import { useClient } from '../../../../../../../../providers/providers/ClientProvider';
import { CoverHeading, BTPreHeading } from '../Heading';

export const CoverContentBT: React.FC = () => {
  const { client } = useClient();

  const withImage = false;

  return (
    <ContentWrapper>
      <InfoContainer hasImage={withImage}>
        <BTPreHeading>Tour prepared for</BTPreHeading>
        <CoverHeading style={{ transform: 'translateY(215px)' }}>{client?.name}</CoverHeading>
        <CoverLogos
          displayImage={withImage}
          logoContainerStyles={{ height: withImage ? 80 : 100 }}
          logoStyles={{ height: withImage ? 90 : 100 }}
        />
      </InfoContainer>
    </ContentWrapper>
  );
};
