import { useEffect, useRef } from 'react';

export function useEventListener(
  eventName: string,
  handler: EventListener,
  eventPropagation = false,
  element = window,
) {
  const savedHandler = useRef<EventListener>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => savedHandler.current?.(event);
    element.addEventListener(eventName, eventListener, eventPropagation);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, eventPropagation]);
}
