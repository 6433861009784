import React from 'react';

import { ModalContent, ModalContentLeft, ModalContentRight } from '../../../sections.styles';
import { TestimonialExpandible } from './TestimonialExpandible/TestimonialExpandible.component';
import { useSlides } from '../../../../../../../../providers/providers/SlidesProvider';
import PreviewSlide from '../../../../../../../../components/PreviewSlide/PreviewSlide.component';
import { useUiConfigTheme } from '../../../../../../../../providers/providers/UiConfigThemeProvider';

export const ModalContentTestimonials: React.FC = () => {
  const { getSlide, setSlide } = useSlides();
  const { theme } = useUiConfigTheme();

  const slide = getSlide('testimonials');

  const onSwitch = (prop: 'includeSlide') => {
    if (!slide) return alert('Something went wrong');

    const updatedSlide = { ...slide };
    if (prop === 'includeSlide') {
      updatedSlide.includeSlide = !updatedSlide.includeSlide;
    }

    setSlide(slide?.id, updatedSlide);
  };

  if (!slide || !slide.data) return null;

  return (
    <ModalContent>
      <ModalContentLeft>
        <PreviewSlide slide={slide} theme={theme} isEditable isDefaultConfig />
      </ModalContentLeft>
      <ModalContentRight>
        <TestimonialExpandible />
      </ModalContentRight>
    </ModalContent>
  );
};
