import { CurrentUserService } from '../../services/CurrentUserService';

export class EntityType {
  static isOfficeEntity(entity: number | null): boolean {
    const userOffices = CurrentUserService.getUser()?.offices;

    return Boolean(userOffices?.some(office => office.id === entity));
  }

  static isTeamEntity(entity: number | null): boolean {
    const userTeams = CurrentUserService.getUser()?.teams;

    return Boolean(userTeams?.some(team => team.id === entity));
  }
}
