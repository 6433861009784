import {
  TCategory,
  TDynamicSlideTemplate,
  TSlideId,
  TSlidePlatform,
  TSlideTheme,
} from '../../../types';
import { CATEGORIES } from '../../../constants/categories';
import { PredefinedSlideFactory } from './PredefinedSlideFactory';
import { DynamicSlideFactory } from '../dynamic/DynamicSlideFactory';

type TSlideMeta = {
  theme: TSlideTheme;
  category: TCategory;
  platform: TSlidePlatform;
  isEditable?: boolean;
  template?: TDynamicSlideTemplate;
  slide?: TSlideId;
};

export class SlideFactory {
  static create(slideMeta: TSlideMeta) {
    const { theme, template, category, platform, isEditable, slide } = slideMeta;

    return template
      ? DynamicSlideFactory.create(platform, template, isEditable)
      : PredefinedSlideFactory.create(theme, category ?? CATEGORIES.TRADITIONAL, platform, slide);
  }
}
