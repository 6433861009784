import React, { useEffect, useState } from 'react';

import { SlidesProvider, useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { Container, Wrapper } from '../sections.styles';
import { TPersonalizeSlide } from '../../../../../../types';
import Loader from '../../../../../../components/Loader/Loader';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';
import { PersonalizationModalComponent } from './PersonalizationModal.component';
import { ProgressProvider, useProgressState } from '../../../../ProgressProvider';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { withProviders } from '../../../../../../hoc/withProviders';
import { useLoadPersonalized } from '../../../../../../data/personalized/useLoadPersonalized';
import { CustomizationSettingsItem } from '../../components/CustomizationSettingsItem/CustomizationSettingsItem.component';
import { DefaultConfigProvider } from '../../../../../../services/DefaultConfigProvider';

function getFullPersonalizedConfig(data: any) {
  const defaultPersonalizedSlides = DefaultConfigProvider.getPersonalizedSlides();

  return {
    aboutMe: data.aboutMe ?? defaultPersonalizedSlides.aboutMe,
    testimonials: data.testimonials ?? defaultPersonalizedSlides.testimonials,
  };
}

export const PersonalizeContentSection: React.FC = withProviders(
  SlidesProvider,
  ProgressProvider,
)(() => {
  const { hash } = useUser();
  const { setSlides } = useSlides();
  const { progress, setProgress } = useProgressState();
  const { isLoading, data } = useLoadPersonalized(hash);

  const [personalizeSlide, setPersonalizeSlide] = useState<null | TPersonalizeSlide>(null);

  useEffect(() => {
    if (!data) return;

    const updatedPersonalizedSlides = Object.keys(data).reduce(
      (map, sId) => ({ ...map, [sId]: true }),
      {},
    );

    setProgress(updatedPersonalizedSlides);

    const fullPersonalizedSlides = getFullPersonalizedConfig(data);
    setSlides(fullPersonalizedSlides);
  }, [data, setProgress, setSlides]);

  const onClose = React.useCallback(
    function onClose() {
      setPersonalizeSlide(null);

      const fullPersonalizedSlides = getFullPersonalizedConfig(data);
      setSlides(fullPersonalizedSlides);
    },
    [data, setSlides],
  );

  return (
    <Container>
      <Wrapper hasMargin>
        <SectionHeader
          title='Personalize Content'
          subtitle='Update the slide content to fit you and your business.'
          icon='user-circle'
        />
      </Wrapper>
      {!isLoading ? (
        <Wrapper>
          <CustomizationSettingsItem
            section='personalize'
            title='About Me'
            edited={!!progress.aboutMe}
            completed={!!progress.aboutMe}
            onButtonClick={() => setPersonalizeSlide('aboutMe')}
          />
          <CustomizationSettingsItem
            section='personalize'
            title='Testimonials'
            edited={!!progress.testimonials}
            completed={!!progress.testimonials}
            onButtonClick={() => setPersonalizeSlide('testimonials')}
          />
        </Wrapper>
      ) : (
        <Loader centered size='2x' />
      )}

      <PersonalizationModalComponent personalizeSlide={personalizeSlide} onClose={onClose} />
    </Container>
  );
});
