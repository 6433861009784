import { TFrameConfig } from '../../../../../../../types';
import { THEMES } from '../../../../../../../constants/themes';
import LuxuryFrameGama, { TLuxuryFrameGama } from './LuxuryFrameTwo';
import LuxuryFrameBeta, { TLuxuryFrameBeta } from './LuxuryFrameBeta';
import LuxuryFrameAlpha, { TLuxuryFrameAlpha } from './LuxuryFrameAlpha';

export type TSlideThemeLuxury = TLuxuryFrameAlpha | TLuxuryFrameBeta | TLuxuryFrameGama;

const luxuryConfig: Record<TSlideThemeLuxury, TFrameConfig> = {
  [THEMES.LUXURY.ALPHA]: LuxuryFrameAlpha,
  [THEMES.LUXURY.BETA]: LuxuryFrameBeta,
  [THEMES.LUXURY.GAMA]: LuxuryFrameGama,
};

export default luxuryConfig;
