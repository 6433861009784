import { AvgProps } from './types';

import { Card } from './Card';
import { LargeText, Row, SmallText } from './Cards.styles';

type TimeUnitProps = {
  unit: string;
  value: number;
};

function TimeUnit({ value, unit }: TimeUnitProps) {
  return (
    <LargeText>
      {value}
      <SmallText style={{ fontWeight: 500, marginLeft: 3, textTransform: 'lowercase' }}>
        {unit}
      </SmallText>
    </LargeText>
  );
}

export function AvgTimeSpentCard({ avgTimeSpent }: AvgProps) {
  const { hours, minutes, seconds } = avgTimeSpent;

  const allZero = [hours, minutes, seconds].every((value: number) => value === 0);

  const content = allZero ? (
    <LargeText>N/A</LargeText>
  ) : (
    <>
      {hours > 0 && <TimeUnit value={hours} unit='h' />}
      {((hours > 0 && seconds > 0) || minutes > 0) && <TimeUnit value={minutes} unit='m' />}
      {seconds > 0 && <TimeUnit value={seconds} unit='s' />}
    </>
  );

  return (
    <Card title='Avg Time Spent'>
      <SmallText>Duration</SmallText>
      <Row>{content}</Row>
    </Card>
  );
}
