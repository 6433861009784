import React from 'react';

import { THEMES } from '../../../../../../../constants/themes';
import { TraditionalCoverBeta } from './beta/TraditionalCoverBeta';
import { TraditionalCoverAlpha } from './alpha/TraditionalCoverAlpha';
import { TraditionalCoverGamma } from './gamma/TraditionalCoverGamma';
import { TSlideThemeTraditional } from '../../../../../components/SlideFrame/frames/web/Traditional';

interface TraditionalCoverProps {
  theme: TSlideThemeTraditional;
}

export const TraditionalCover: React.FC<TraditionalCoverProps> = ({ theme, children }) => {
  const coverMap: Record<TSlideThemeTraditional, React.FC> = {
    [THEMES.TRADITIONAL.ALPHA]: TraditionalCoverAlpha,
    [THEMES.TRADITIONAL.BETA]: TraditionalCoverBeta,
    [THEMES.TRADITIONAL.GAMA]: TraditionalCoverGamma,
  };

  const Component: React.FC<TraditionalCoverProps> = coverMap[theme];
  if (!Component) return null;

  return <Component theme={theme}>{children}</Component>;
};
