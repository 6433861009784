import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useInvalidatePresentation } from './usePresentation';
import { PresentationApi } from '../pages/Presentation/api/PresentationApi';
import { TSuperPresentation, WhiteLabelSettings } from '../types';
import { useDomainWhitelabelSettings } from '../features/whitelabel/useDomainWhitelabelSettings';

async function updatePresentation(
  presentation: TSuperPresentation,
  whiteLabelSettings?: WhiteLabelSettings | null,
): Promise<TSuperPresentation> {
  const presentationData = {
    ...presentation,
    whiteLabelSettings: whiteLabelSettings || null,
  };
  const res = await PresentationApi.update(presentation.id || '', presentationData, false);

  return res;
}

export const useUpdatePresentationWithoutSaving = (
  hash?: string,
): UseMutationResult<TSuperPresentation, Error, TSuperPresentation> => {
  const queryClient = useQueryClient();
  const { data } = useDomainWhitelabelSettings();

  const invalidatePresentation = useInvalidatePresentation(hash);

  return useMutation(
    (presentation: TSuperPresentation) =>
      updatePresentation(presentation, data?.whiteLabelSettings),
    {
      onMutate: async presentation => {
        await queryClient.cancelQueries(['presentation', presentation.hash]);
        const prevPresentation = queryClient.getQueryData([
          'presentation',
          presentation.hash,
        ]) as TSuperPresentation;
        queryClient.setQueryData(['presentation', presentation.hash], () => {
          return presentation;
        });
        return { prevPresentation };
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ['presentation', context?.prevPresentation.hash],
          context?.prevPresentation,
        );
      },
      onSuccess: () => {
        invalidatePresentation();
      },
    },
  );
};
