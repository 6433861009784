import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { FourImagesZeta } from './FourImagesZeta';
import { FourImagesZetaForm } from './FourImagesZetaForm';
import { FourImagesZetaPdf } from './FourImagesZetaPdf';
import thumbnail from './thumbnail.png';

const template = 'fourImagesLayoutZeta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Four Image Layout',
  frame: true,
  data: {
    image: '',
    image2: '',
    image3: '',
    image4: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: FourImagesZetaForm,
  web: FourImagesZeta,
  pdf: FourImagesZetaPdf,
};

export type TFourImagesLayoutZeta = typeof template;
export { config, mappings, thumbnail };
