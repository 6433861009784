import { cloneDeep } from 'lodash';

import type { IPresentationConfigs, TSlide, TSlideId } from '../../../types';
import type { TDynamicOrder, TDynamicSection, TDynamicSectionId } from '../../../types';
import { config as coverConfig } from '../../../components/Slide/slides/startingSlides/Cover';
import { config as closingConfig } from '../../../components/Slide/slides/closingAndNextSteps/closingAndNextSteps';
import { config as homeBuyingConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/homeBuyingProcess';
import { config as aboutMeConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/AboutMe';
import { config as whatBuyersWantConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/whatBuyersWantFromTheirAgents';
import { config as myTestimonialsConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/MyTestimonials';
import { config as purposeOfBuyersAgentConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/purposeOfBuyersAgent';
import { config as buyersAgentCompensationConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/buyersAgentCompensation';
import { config as whyIAmTheRightBuyersAgentConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/whyIAmTheRightBuyersAgent';

const startingSlides: TDynamicSection = {
  id: 'startingSlides',
  label: 'Introduction',
};
const marketingAndBuyerActivity: TDynamicSection = {
  id: 'marketingAndBuyerActivity',
  label: 'Marketing & Buyer Activity',
};

const whyIAmTheRightFit: TDynamicSection = {
  id: 'whyIAmTheRightFit',
  label: 'Why I Am The Right Fit',
};

const closingAndNextSteps: TDynamicSection = {
  id: 'closingAndNextSteps',
  label: 'Next Steps',
};

const sections: Record<TDynamicSectionId, TDynamicSection> = {
  startingSlides,
  marketingAndBuyerActivity,
  whyIAmTheRightFit,
  closingAndNextSteps,
} as const;

class WTRConfigsImp implements IPresentationConfigs {
  getSlides(): Partial<Record<TSlideId, TSlide>> {
    return cloneDeep({
      [coverConfig.id]: coverConfig,
      [purposeOfBuyersAgentConfig.id]: purposeOfBuyersAgentConfig,
      [buyersAgentCompensationConfig.id]: buyersAgentCompensationConfig,
      [homeBuyingConfig.id]: homeBuyingConfig,
      [whyIAmTheRightBuyersAgentConfig.id]: whyIAmTheRightBuyersAgentConfig,
      [aboutMeConfig.id]: aboutMeConfig,
      [myTestimonialsConfig.id]: myTestimonialsConfig,
      [whatBuyersWantConfig.id]: whatBuyersWantConfig,
      [closingConfig.id]: closingConfig,
    });
  }

  getSections(): Record<TDynamicSectionId, TDynamicSection> {
    return cloneDeep(sections);
  }

  getFullOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [
        sections.startingSlides.id,
        sections.marketingAndBuyerActivity.id,
        sections.whyIAmTheRightFit.id,
        sections.closingAndNextSteps.id,
      ],
      sectionsSlide: {
        [sections.startingSlides.id]: [coverConfig.id] as TSlideId[],
        [sections.marketingAndBuyerActivity.id]: [
          purposeOfBuyersAgentConfig.id,
          buyersAgentCompensationConfig.id,
          homeBuyingConfig.id,
        ] as TSlideId[],
        [sections.whyIAmTheRightFit.id]: [
          whyIAmTheRightBuyersAgentConfig.id,
          aboutMeConfig.id,
          myTestimonialsConfig.id,
          whatBuyersWantConfig.id,
        ] as TSlideId[],
        [sections.closingAndNextSteps.id]: [closingConfig.id] as TSlideId[],
      },
    });
  }

  getDefaultOrder(): TDynamicOrder {
    return cloneDeep({
      sections: [
        sections.startingSlides.id,
        sections.marketingAndBuyerActivity.id,
        sections.whyIAmTheRightFit.id,
        sections.closingAndNextSteps.id,
      ],
      sectionsSlide: {
        [sections.startingSlides.id]: [coverConfig.id] as TSlideId[],
        [sections.marketingAndBuyerActivity.id]: [purposeOfBuyersAgentConfig.id] as TSlideId[],
        [sections.whyIAmTheRightFit.id]: [whyIAmTheRightBuyersAgentConfig.id] as TSlideId[],
        [sections.closingAndNextSteps.id]: [closingConfig.id] as TSlideId[],
      },
    });
  }

  getPredefinedSectionSlides(): Record<TDynamicSectionId, TSlideId[]> {
    return cloneDeep({
      [sections.startingSlides.id]: [coverConfig.id] as TSlideId[],
      [sections.marketingAndBuyerActivity.id]: [
        purposeOfBuyersAgentConfig.id,
        buyersAgentCompensationConfig.id,
        homeBuyingConfig.id,
      ] as TSlideId[],
      [sections.whyIAmTheRightFit.id]: [
        whyIAmTheRightBuyersAgentConfig.id,
        aboutMeConfig.id,
        myTestimonialsConfig.id,
        whatBuyersWantConfig.id,
      ] as TSlideId[],
      [sections.closingAndNextSteps.id]: [closingConfig.id] as TSlideId[],
    });
  }
}

export const WTRConfigs = new WTRConfigsImp();
