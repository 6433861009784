import styled from 'styled-components';
import { Icon as I } from '../';

interface WithSearch {
  search: 0 | 1;
}

interface WithIcon extends WithSearch {
  icon: 0 | 1;
}

export const InputEl = styled('input')<WithIcon>(({ theme, icon, search, width }) => ({
  height: 35,
  fontSize: search ? 13 : 12,
  fontWeight: 300,
  outline: 'none',
  color: theme.colors.gray[400],
  padding: theme.spacing(0, icon ? 3 : 2, 0, 2),
  width: width ? width : '100%',
  border: 'none',
  flexShrink: 0,
  backgroundColor: 'transparent',
}));

export const InputWrapper = styled('div')<WithSearch>(({ theme, search }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderRadius: search ? 50 : 5,
  backgroundColor: search ? theme.colors.gray[100] : '#fff',
  border: search ? 'none' : `1px solid ${theme.colors.gray[200]}`,
  overflow: 'hidden',
}));

export const Icon = styled(I)(({ theme }) => ({
  position: 'absolute',
  right: 12,
}));
