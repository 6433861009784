import * as creators from './actions';

export const setRebuildData = data => creators.setRebuildData(data);
export const setReportDetailed = reportDetailed => {
  const useDtcInsteadDomForSold = !!reportDetailed?.properties?.sold?.find(
    p => p.daysOnMarket === false || p.daysOnMarket === null,
  );

  return creators.setReportDetailed({
    ...reportDetailed,
    useDtcInsteadDomForSold,
  });
};

export const resetReport = () => dispatch => {
  dispatch(setRebuildData({}));
  dispatch(setReportDetailed({}));
};
