import { ThemeColors } from '../types';

const colors: ThemeColors = {
  v2: {
    primary: '#6AB4E4',
    background: '#FFFFFF',
    accent: '#8dc038',
    text: {
      regular: '#444444',
      contrastText: '#ffffff',
      link: '#0066cc',
    },
    gradient: {
      accent: '#8dc038',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 41.16%), linear-gradient(270deg, rgba(255, 255, 255, 0.30) 0%, rgba(141, 192, 56, 0.30) 22%, rgba(207, 217, 255, 0.30) 82.5%, rgba(255, 255, 255, 0.30) 100%), #FFF',
      border: 'linear-gradient(white, white),linear-gradient(#B1B1B1, #B1B1B1)',
    },
    button: {
      danger: '#ED0D21',
    },
    gray: {
      400: '#444444',
      300: '#B1B1B1',
      200: '#DDDDDD',
      100: '#F1F1F1',
    },
    status: {
      success: '#a3c769',
      warning: '#EBAF17',
      error: '#D0021B',
    },
  },
  primary: {
    light: '#7db9e0',
    main: '#6AB4E4',
    dark: '#5A99C2',
    contrastText: '#ffffff',
    textLight: '#373e43',
    borderDark: '#323232',
    textLink: '#0066CC',
  },
  secondary: {
    light: '#a3c769',
    main: '#8dc038',
    dark: '#63872a',
    contrastText: '#fff',
    textLight: '#626262',
    borderDark: '#323232',
    textLink: '#0066CC',
  },
  gray: {
    400: '#444',
    300: '#666',
    200: '#ddd',
    100: '#f1f1f1',
  },
  success: '#a3c769',
  warning: '#EBAF17',
  error: '#D0021B',
};

export default colors;
