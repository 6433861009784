import styled from 'styled-components';
import { competitionPinConfigs } from './CompetitionPins';

export const CompetitionLegend = () => (
  <Container>
    {competitionPinConfigs.map(pin => (
      <Item key={pin.label}>
        <Image src={pin.image} alt='' /> {pin.label}
      </Item>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const Image = styled.img`
  margin-right: 5px;
`;
