import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';

type WithWeb = {
  isWeb?: boolean;
};

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Rectangle = styled('div')<WithWeb>(({ isWeb }) => ({
  width: isWeb ? '42%' : '44%',
  backgroundColor: useUiConfigColorsPrimary(),
  minHeight: isWeb ? 140 : 120,
  marginTop: 15,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const RectangleText = styled('h5')<WithWeb>(({ isWeb }) => ({
  color: '#fff',
  fontSize: isWeb ? 17 : 15,
  textTransform: 'uppercase',
}));
