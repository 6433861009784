import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')({
  border: '1px solid #ddd',
  height: '100%',
  position: 'relative',
  padding: '60px 10px 12px 10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const ImagesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const ImageWrapper = styled('div')({
  width: '49%',
});

export const Image = styled('img')({
  width: '100%',
});

export const DescriptionContainer = styled('div')(({ theme }) => ({
  padding: '0 42px',
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const TextSection = styled('div')({
  marginBottom: 30,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const Title = styled('h3')(({ theme }) => ({
  color: '#000',
  fontSize: 26,
  fontWeight: 700,
  position: 'relative',
  lineHeight: '37.62px',
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  '&:after': {
    content: '""',
    width: 339,
    height: 1,
    bottom: 0,
    left: 0,
    position: 'absolute',
    backgroundColor: useUiConfigColorsPrimary(),
  },
}));

export const DescriptionText = styled('p')(({ theme }) => ({
  color: theme.colors.gray[300],
  fontSize: 16,
  lineHeight: '29px',
  marginTop: 20,
  width: 704,
}));

export const ListTitle = styled(DescriptionText)({
  marginTop: 0,
});

export const List = styled('ul')({
  marginTop: 12,
  listStyleType: 'none',
  paddingLeft: 0,
});

export const SourceText = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 300,
  color: '#333',
  textAlign: 'right',
  fontFamily: useUiConfigFontsBody(),
}));

export const ListItem = styled(DescriptionText).attrs({
  as: 'li',
})({
  marginTop: 0,
  '&:before': {
    content: '"•"',
    marginRight: 5,
  },
});
