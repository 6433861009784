import { useState } from 'react';
import styled from 'styled-components';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { ModeSelection } from './mode-selection/ModeSelection';
import { ResultsTuning } from '../results-tuning/ResultsTuning';
import { BigPicture } from './big-picture/BigPicture';
import { Scale } from './scale/web/Scale';

const Container = styled.div``;

const ModeWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
`;

export const ScaleOverviewPresentation = () => {
  const [bigPictureMode, setBigPictureMode] = useState(false);

  return (
    <Container>
      <ModeWrapper>
        <ModeSelection bigPictureMode={bigPictureMode} setBigPictureMode={setBigPictureMode} />
      </ModeWrapper>
      <ResultsTuning />
      {bigPictureMode ? <BigPicture /> : <Scale />}
    </Container>
  );
};
