import styled from 'styled-components';
import marketingPlanImage from './assets/web-marketing-plan.png';

import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';

export const MarketingPlanContainer = styled('div')({
  width: '100%',
  backgroundImage: `url(${marketingPlanImage})`,
  backgroundSize: '100%',
  height: '100%',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.12)',
  },
});

export const CustomColors = styled('img')({
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});

export const WhiteOverlay = styled('img')({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '50%',
  zIndex: 1,
});

export const MarketingPlanTextContainer = styled('div')({
  width: '47.8%',
  padding: '45px 0 0 45px',
  height: '100%',
  position: 'relative',
  zIndex: 2,
  clipPath: 'polygon(0 0, 100% 0, 53% 100%, 0% 100%)',
});

export const MarketingPlanTitle = styled('h2')(({ theme }) => ({
  fontFamily: useUiConfigFontsHeader(),
  color: useUiConfigColorsPrimary(),
  fontSize: 30,
  fontWeight: 900,
  textTransform: 'uppercase',
  textAlign: 'left',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const MarketingPlanTextWrapper = styled('div')(({ theme }) => ({
  marginTop: 18,
  width: 380,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const MarketingPlanText = styled('p')({
  fontSize: 18,
  fontWeight: 400,
  color: '#000',
  marginBottom: 30,
  textAlign: 'left',
  lineHeight: '28.41px',
  '&:last-of-type': {
    marginBottom: 0,
  },
});
