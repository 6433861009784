import React from 'react';
import { CSSObject } from 'styled-components';
import {
  Container,
  ImageContainer,
  TextCointainer,
  Overlay,
  TextContentWrapper,
  Heading,
  LI,
  Paragraph,
  OL,
  UL,
  ListItemBold,
} from './TwoSectionsSlideLuxuryTemplate.styles';

interface Props {
  heading: string;
  subtitle?: string;
  paragraphs?: string[];
  listItems?: { title?: string; text: string }[];
  listType?: 'ol' | 'ul';
  image: string;
  imagePosition: 'left' | 'right';
  contentStyles?: CSSObject;
  imageDominant?: boolean;
  imageStyles?: CSSObject;
  isEqualWidth?: boolean;
  textContainerStyles?: CSSObject;
}
export const TwoSectionsSlideLuxuryTemplate: React.FC<Props> = ({
  heading,
  subtitle,
  paragraphs,
  listItems,
  listType = 'ol',
  image,
  contentStyles,
  imageDominant,
  imagePosition,
  imageStyles,
  isEqualWidth,
  textContainerStyles,
}) => {
  const listElMap = {
    ol: OL,
    ul: UL,
  };

  const List = listElMap[listType];

  return (
    <Container>
      {imagePosition === 'left' && (
        <ImageContainer
          position={imagePosition}
          src={image}
          imageStyles={imageStyles}
          isEqualWidth={isEqualWidth}
          isImageDominant={imageDominant}
        />
      )}
      <TextCointainer
        position={imagePosition === 'left' ? 'right' : 'left'}
        isEqualWidth={isEqualWidth}
        isImageDominant={imageDominant}
        style={{ position: 'relative', ...textContainerStyles }}
      >
        <Overlay />
        <TextContentWrapper style={{ padding: imageDominant ? '6% 2%' : '8% 8% 0 8%' }}>
          <Heading>{heading}</Heading>
          {subtitle && <Paragraph style={contentStyles}>{subtitle}</Paragraph>}
          {paragraphs &&
            paragraphs.map((p, i) => (
              <Paragraph key={i} style={contentStyles}>
                {p}
              </Paragraph>
            ))}
          {listItems && (
            <List style={{ paddingLeft: 22.5 }}>
              {listItems.map((item, i) => (
                <LI key={i} style={contentStyles}>
                  {item.title && <ListItemBold>{item.title}: </ListItemBold>}
                  {item.text}
                </LI>
              ))}
            </List>
          )}
        </TextContentWrapper>
      </TextCointainer>
      {imagePosition === 'right' && (
        <ImageContainer
          position={imagePosition}
          src={image}
          imageStyles={imageStyles}
          isEqualWidth={isEqualWidth}
          isImageDominant={imageDominant}
        />
      )}
    </Container>
  );
};
