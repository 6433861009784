import React, { useMemo } from 'react';

import frame from './frame.png';
import {
  Image,
  FrameImageWrapper,
  ImageContainer,
  PropertyImageWrapper,
} from './LuxuryPropertyImage.styles';
import { useParams } from 'react-router-dom';
import { usePresentation } from '../../../../../../../../hooks/usePresentation';

interface LuxuryPropertyImageProps {
  isPdf?: boolean;
  image?: string;
  address?: string;
}

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 15%',
};

export const LuxuryPropertyImage: React.FC<LuxuryPropertyImageProps> = ({
  isPdf,
  image,
  address,
}) => {
  const { hash } = useParams<{ hash: string }>();

  const { data: presentation } = usePresentation(hash);

  const slideTitle = useMemo(() => {
    if (presentation?.type === 'winTheListing' || presentation?.type === 'winTheOffer') {
      return 'Presenting';
    }

    if (presentation?.type === 'buyerTour') {
      return 'Tour Prepared for';
    }

    return 'Presentation prepared for';
  }, [presentation?.type]);

  return (
    <ImageContainer>
      <FrameImageWrapper isPdf={isPdf}>
        <Image src={frame} />
      </FrameImageWrapper>
      <PropertyImageWrapper style={!image && address ? { ...styles, flexDirection: 'column' } : {}}>
        {!image && <p style={{ fontSize: '12px', fontWeight: 'normal' }}>{slideTitle}</p>}
        {image ? <Image src={image} /> : address}
      </PropertyImageWrapper>
    </ImageContainer>
  );
};
