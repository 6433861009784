import React from 'react';
import styled from 'styled-components';
import slideGraphics from './slideGraphics';
import house from './house.png';
import bg from './bg.png';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: url(${bg});
  background-size: 100%;
  background-position: bottom;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 30px;
  font-size: 26px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const Text = styled('p')(({ theme }) => ({
  color: theme.colors.gray[400],
  fontSize: 16,
  lineHeight: 1.3,
  paddingLeft: 30,
  width: '85%',
  marginTop: -10,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const House = styled('img')({
  position: 'absolute',
  right: 2,
  top: -42.5,
  height: '104.3%',
});

const Graphics = styled('img')({
  position: 'absolute',
  width: '95%',
  height: 'auto',
  left: 20,
  bottom: 0,
});

const WhatFactorsInfluencePricingTraditionalPDF: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const image = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container>
        <Heading>What factors influence pricing</Heading>
        <Text>
          Ultimately, a home’s value is based on what potential buyers will pay, to know what
          they’re looking for in a home can help you get top dollar. Here are ten factors potential
          buyers consider when comparing homes:{' '}
        </Text>
        <Graphics src={image} />
        <House src={house} />
        <TraditionalSlideCircularCard
          heading='layout'
          position={{ top: 670, left: -7 }}
          size={170}
          headingStyles={{ fontSize: 20 }}
        />
        <TraditionalSlideCircularCard
          heading='Remodel'
          text='Kitchen remodel, bathroom update, etc.'
          position={{ top: 180, right: 225 }}
          size={195}
          headingStyles={{ fontSize: 20 }}
          textStyles={{ fontSize: 16 }}
        />
        <TraditionalSlideCircularCard
          heading='Walk Score'
          position={{ top: 425, left: 242 }}
          size={170}
          headingStyles={{ fontSize: 20 }}
        />
        <TraditionalSlideCircularCard
          heading='noise'
          position={{ top: 680, right: 5 }}
          size={170}
          headingStyles={{ fontSize: 20 }}
        />
        <TraditionalSlideCircularCard
          heading='schools'
          position={{ top: 520, left: 55 }}
          size={170}
          headingStyles={{ fontSize: 20 }}
        />
        <TraditionalSlideCircularCard
          heading='Level of upkeep'
          position={{ top: 300, right: 45 }}
          size={175}
          headingStyles={{ fontSize: 20, width: 170 }}
        />
        <TraditionalSlideCircularCard
          heading='safety'
          position={{ top: 430, right: 215 }}
          size={170}
          headingStyles={{ fontSize: 20 }}
        />
        <TraditionalSlideCircularCard
          heading='Area'
          text='Views, community/ neighbors, etc.'
          position={{ top: 185, left: 239 }}
          size={200}
          headingStyles={{ fontSize: 20 }}
          textStyles={{ width: 180, fontSize: 16 }}
        />
        <TraditionalSlideCircularCard
          heading='updates'
          text='HVAC, roof, plumbing, etc.'
          position={{ top: 315, left: 40 }}
          size={180}
          headingStyles={{ fontSize: 20 }}
          textStyles={{ width: 140, fontSize: 16 }}
        />
        <TraditionalSlideCircularCard
          heading='Return Value'
          position={{ top: 540, right: 50 }}
          size={170}
          headingStyles={{ fontSize: 20 }}
        />
      </Container>
    </PdfFrame>
  );
};

export default WhatFactorsInfluencePricingTraditionalPDF;
