import { FC } from 'react';

import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';
import { data, title, subtitle } from '../../data';
import image from './image.png';

export const BuyersAgentCompensationLuxury: FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading={title}
      subtitle={subtitle}
      listItems={data}
      image={image}
      imagePosition='left'
      imageStyles={{ width: '43%' }}
      textContainerStyles={{ padding: 25, width: '65%' }}
    />
  );
};
