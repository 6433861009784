import { flatten } from 'lodash';
import { ConfigItem } from '../../../../../../../providers/providers/OrderProvider';

export function stringifySlidesOrdering(orderConfig?: ConfigItem) {
  if (!orderConfig) return '';

  const { sectionsSlide, sections } = orderConfig;
  const slides = flatten(sections?.map(sectionId => sectionsSlide[sectionId]) ?? []);

  return String(slides);
}
