import { useActionButtons } from '../../../../hooks/useActionButtons';
import { TProperty } from '../../../../../../types';
import { ActionButton } from './ActionButton';
import { useUrlSearchParam } from '../../../../../../hooks/useQueryParams';
import { Loading } from '../../../../../../pages/PresentationCreate/dash/view/components/Loading/Loading';
import styled from 'styled-components';
import { getPropertyId } from '../../../../services/getPropertyId';

interface Props {
  property: TProperty;
}

export const ActionIndicators = ({ property }: Props) => {
  const source = useUrlSearchParam('source');

  const { isLiked, isFeatured, excludeInProgress } = useActionButtons(property);

  if (source) return null;

  if (excludeInProgress === getPropertyId(property)) {
    return (
      <IconButtons>
        <Loading />
      </IconButtons>
    );
  }
  return (
    <IconButtons>
      {isFeatured && (
        <ActionButton
          icon='star'
          text='Feature'
          tooltipText='This property was marked by your agent as a featured property.'
          isActive
        />
      )}
      {isLiked && (
        <ActionButton
          icon='thumbs_up'
          text='Like'
          tooltipText='This property was marked by your agent as a liked property.'
          isActive
        />
      )}
    </IconButtons>
  );
};

const IconButtons = styled.div`
  display: flex;
  gap: 5px;
`;
