import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSlides } from '../../../../../../../../../../providers/providers/SlidesProvider';
import { ITestimonialLink } from '../../../../../../../../../../types';
import {
  AddLinksTitle,
  ButtonsWrapper,
  Container,
  LinkInputsContainer,
  RemoveLinkInputs,
  Wrapper,
} from './AddLinks.styles';
import { ResyncTestimonials } from './ResyncTestimonials/ResyncTestimonials';
import { Input } from '../../../../../../../../../../components/Common/V2/Input';
import { Button } from '../../../../../../../../../../components/Common/V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

interface Props {
  setIsResynced: Dispatch<SetStateAction<boolean>>;
}

export const AddLinks: React.FC<Props> = ({ setIsResynced }) => {
  const { getSlide, setSlideData } = useSlides();
  const slide = getSlide('testimonials');
  const { colors } = useTheme();

  const [links, setLinks] = useState(slide?.data?.links ?? []);

  const updateSlides = (links: ITestimonialLink[]) => {
    if (!slide || !slide.data || slide.id !== 'testimonials' || !slide.data?.testimonials) return;

    setSlideData(slide.id, { ...slide.data, links });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const inputValue = e.target.value;
    const inputName = e.target.name;
    const changedValues = links.map((item, index) => {
      if (index === i) {
        return {
          ...item,
          [inputName]: inputValue,
        };
      } else {
        return item;
      }
    });

    setLinks(changedValues);
    updateSlides(changedValues);
  };

  const addLinkInputs = () => {
    const addedLinkValues = [
      ...links,
      {
        title: '',
        url: '',
      },
    ];

    setLinks(addedLinkValues);
  };

  const removeLinkInputs = (index: number) => {
    const changedLinks = links.filter((link, i) => i !== index);

    setLinks(changedLinks);
    updateSlides(changedLinks);
  };

  return (
    <Container>
      <ButtonsWrapper>
        <AddLinksTitle>Link to additional testimonials:</AddLinksTitle>
        <Button
          prefixIcon={<FontAwesomeIcon icon='plus' color={colors.v2.gray[400]} />}
          variant='link'
          onClick={addLinkInputs}
        >
          Add another link
        </Button>
      </ButtonsWrapper>
      {links.map(({ title, url }, i) => (
        <LinkInputsContainer key={i}>
          <Input
            value={title}
            name='title'
            placeholder='Link title'
            onChange={e => onChange(e, i)}
          />
          <Input name='url' value={url} placeholder='Link URL' onChange={e => onChange(e, i)} />
          <RemoveLinkInputs onClick={() => removeLinkInputs(i)}>
            <FontAwesomeIcon icon='circle-xmark' />
          </RemoveLinkInputs>
        </LinkInputsContainer>
      ))}
      <Wrapper>
        <ResyncTestimonials setIsResynced={setIsResynced} />
      </Wrapper>
    </Container>
  );
};
