import { ButtonComponentProps } from './Button.types';
import React from 'react';
import { ButtonEl, Loader } from './Button.styles';
import { Icon } from '../Icon/Icon.component';

export const Button: React.FC<ButtonComponentProps> = React.forwardRef<
  HTMLButtonElement,
  ButtonComponentProps
>(
  (
    {
      children,
      btnstyle,
      customLoadText = null,
      size = 'medium',
      type = 'button',
      icon,
      loading,
      sufixUI,
      ...props
    },
    ref,
  ) => {
    return (
      <ButtonEl
        ref={ref}
        type={type}
        btnstyle={btnstyle}
        size={size}
        disabled={props?.disabled}
        {...props}
      >
        {loading ? (
          customLoadText ? (
            customLoadText
          ) : (
            <Loader />
          )
        ) : (
          <>
            {icon && icon.position !== 'right' && (
              <Icon
                iconName={icon.name}
                style={{ marginRight: icon.position === 'left' ? 8 : 0, ...icon.style }}
                fill={icon.fill}
              />
            )}
            {children}
            {icon && icon.position === 'right' && (
              <Icon
                iconName={icon.name}
                style={{ marginLeft: 8, ...icon.style }}
                fill={icon.fill}
              />
            )}
            {sufixUI}
          </>
        )}
      </ButtonEl>
    );
  },
);

Button.displayName = 'Button';
