import { useAtom, useAtomValue } from 'jotai';
import { TCompConfigItem } from '../../../../../types';
import { useCategories } from '../../hooks/useCategories';
import { pricePerSqftModeAtom } from '../../../state/pricePerSqftModeAtom';
import { showDiffValuesAtom } from '../state/showDiffValuesAtom';
import { useCallback } from 'react';

export const useShouldDisplay = () => {
  const categories = useCategories();
  const pricePerSqftMode = useAtomValue(pricePerSqftModeAtom);

  const [showDiffValues] = useAtom(showDiffValuesAtom);

  const shouldDisplay = useCallback(
    (fields: TCompConfigItem[]) => {
      const getFieldValue = (field: TCompConfigItem) =>
        pricePerSqftMode ? categories[field.idPerSqft] : categories[field.id];

      if (showDiffValues) {
        return true;
      }
      const values: number[] = [];
      fields.forEach(field => {
        const fv = getFieldValue(field);
        if (fv) {
          values.push(fv);
        }
      });
      const unique = [...new Set(values)];
      return unique.length > 1;
    },
    [categories, pricePerSqftMode, showDiffValues],
  );

  return { shouldDisplay };
};
