import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigFontsHeader } from '../../../../../../../../../providers/providers/UiConfigFontProvider';

import { Icon } from '../../../../../../../../Common';
export { Icon };

type WithPdf = {
  isPdf?: boolean;
};

export const CardHeader = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: useUiConfigFontsHeader(),
  height: isPdf ? 75 : 90,
  backgroundColor: '#e7e7e7',
  padding: '0 20px',
}));

export const CardHeaderTitleModern = styled('h5')({
  fontSize: 16,
  fontWeight: 700,
  color: '#333',
});

export const IconWrapper = styled('div')(({ theme }) => ({
  backgroundColor: useUiConfigColorsPrimary(),
  height: 55,
  width: 55,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
