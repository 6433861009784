import styled from 'styled-components';

import { ExteriorFeatures } from './ExteriorFeatures';
import { InteriorFeatures } from './InteriorFeatures';
import { OtherFeatures } from './OtherFeatures';

export const OnMarketFeatures = () => {
  return (
    <Wrapper>
      <ExteriorFeatures />
      <InteriorFeatures />
      <OtherFeatures />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
