import React, { Dispatch, SetStateAction } from 'react';
import { AddClient } from '../../../../components/Common/AddClient/AddClient.component';
import { SendEmailPopup } from '../../../../components/Common/SendEmailPopup/SendEmailPopup.component';
import { TClient, TPresentation } from '../../../../types';
import { TDashboardItem } from '../DashboardItems.component';
import { AddAgreementLink } from '../DashboardModalContents/AddAgreementLink.component';
import { AddCoreVideo } from '../DashboardModalContents/AddCoreVideo/AddCoreVideo.component';
import { AddNetSheet } from '../DashboardModalContents/AddNetSheet/AddNetSheet.component';

export const getDashboardModalContent = (
  item: TDashboardItem | null,
  presentationData: TPresentation,
  setDashboardItem: Dispatch<SetStateAction<null | TDashboardItem>>,
  presentationClient: TClient,
) => {
  const netSheetSlide = presentationData?.slidesMap?.netProceeds;

  switch (item) {
    case 'addLink':
      return (
        <AddAgreementLink
          isOpen={true}
          onClose={() => setDashboardItem(null)}
          initialAgreementLink={presentationData?.agreementLink}
          id={presentationData.id ? presentationData.id : ''}
          type={presentationData?.type}
          presentationHash={presentationData?.hash || ''}
        />
      );
    case 'addClient':
      return (
        <AddClient
          isOpen={true}
          onClose={() => setDashboardItem(null)}
          id={presentationData.id ? presentationData.id : ''}
          presentationClient={presentationClient as TClient}
          presentationType={presentationData?.type}
          triggerPdf
          hash={presentationData?.hash}
        />
      );
    case 'addNetSheet':
      return (
        <AddNetSheet
          isOpen={true}
          onClose={() => setDashboardItem(null)}
          netSheetData={netSheetSlide?.netProceedsData}
          presentation={presentationData}
        />
      );
    case 'emailPresentation':
      return (
        <SendEmailPopup
          address={
            presentationData.reportData?.report?.reportDetailed?.subject?.address?.deliveryLine
          }
          presentationType={presentationData?.type}
          presentationHash={presentationData?.hash ?? ''}
          client={{
            id: presentationData.clientId,
            name: presentationData.clientName,
            email: presentationData.clientEmail,
          }}
          isOpen={true}
          onClose={() => setDashboardItem(null)}
        />
      );
    case 'addCoreVideo':
      return (
        <AddCoreVideo
          isOpen={true}
          onClose={() => setDashboardItem(null)}
          initialVideoLink={presentationData.videoLink}
          presentationData={presentationData}
        />
      );
    default:
      return <></>;
  }
};
