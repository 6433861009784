import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { TPresentationTypeApi } from '../../../types';
import { useMemo } from 'react';
import { PRESENTATION_TYPES } from '../../../../../../../constants/PresentationTypes';

const Container = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
`;

const ButtonPlaceholder = styled.div`
  width: 16px;
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddressText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.v2.text.link};
  font-weight: 600;
`;

const PresentationTypeText = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[400]};
`;

const ExpandButton = styled.button`
  border: none;
  background: white;
  outline: none;
  cursor: pointer;
`;

interface Props {
  address: string;
  canExpand?: boolean;
  onToggle: () => void;
  isExpanded?: boolean;
  presentationType: TPresentationTypeApi;
}

const PRESENTATION_TYPE_TEXT = {
  seller: 'Win the listing',
  buyer: 'Win the offer',
  info: 'General presentation',
  buyerTour: '',
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: '',
};

export const AddressCell = ({
  address,
  canExpand,
  onToggle,
  isExpanded,
  presentationType,
}: Props) => {
  const presentationTypeText = useMemo(() => {
    return PRESENTATION_TYPE_TEXT[presentationType];
  }, [presentationType]);

  return (
    <Container>
      {canExpand ? (
        <ExpandButton onClick={onToggle}>
          {isExpanded ? (
            <FontAwesomeIcon icon={['fas', 'minus-square']} />
          ) : (
            <FontAwesomeIcon icon={['fas', 'plus-square']} />
          )}
        </ExpandButton>
      ) : (
        <ButtonPlaceholder />
      )}
      <AddressWrapper>
        <AddressText>{address}</AddressText>
        <PresentationTypeText>{presentationTypeText}</PresentationTypeText>
      </AddressWrapper>
    </Container>
  );
};
