import React, { useState } from 'react';

import {
  AllSlidesWrapper,
  NavigationContainer,
  NavItemWrapper,
  NavItemText,
} from './LibraryNavigation.styles';
import Nav from '../../../../components/Common/Navigation';
import { AllSlidesList } from './AllSlidesList/AllSlidesList.component';
import { useCarouselControls } from '../providers/CarouselControlsProvider';
import { LibrarySlidesExtractor } from '../../services/LibrarySlidesExtractor';
import { useOrder } from '../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../providers/providers/SectionsProvider';
import { TSection } from '../../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

export const LibraryNavigation: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { colors } = useTheme();
  const { sectionsSlide, sections: sectionsOrder } = useOrder();
  const { slides } = useSlides();
  const { sections } = useSections();
  const navItems = LibrarySlidesExtractor.getLibrarySections(
    sectionsOrder as TSection[],
    sectionsSlide,
    slides ?? {},
  );

  const { activeSection, onSelectSection } = useCarouselControls();

  return (
    <NavigationContainer>
      <Nav.Container>
        <AllSlidesWrapper
          onClick={() => {}}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <NavItemWrapper isActive={false} style={{ gap: '5px' }}>
            <NavItemText>All Slides</NavItemText>
            <FontAwesomeIcon
              icon={['fas', 'caret-down']}
              fontSize={12}
              color={colors.v2.gray[400]}
            />
            <AllSlidesList isOpen={open} />
          </NavItemWrapper>
        </AllSlidesWrapper>
        {navItems.map(section => (
          <Nav.Item
            key={section}
            isActive={section === activeSection}
            text={sections[section].label}
            onClick={() => onSelectSection(section)}
            style={{ padding: '0 1.5%' }}
          />
        ))}
      </Nav.Container>
    </NavigationContainer>
  );
};
