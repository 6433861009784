import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

type Props = {
  text?: string;
};

export function InfoBanner({
  text = 'Only admins can lock/unlock customization settings!',
}: Props) {
  return (
    <Container>
      <Icon icon='circle-info' />
      <Text>{text}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 7px;
  padding: 10px 15px;
  align-items: center;
  align-self: stretch;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.v2.gray[400]};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.v2.background};
`;

const Text = styled.p`
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.contrastText};
`;
