import { useState } from 'react';
import styled from 'styled-components';

import { TPresentationSession } from '../../../../../../../../api/TrackingApi';
import { TPresentationType } from '../../../../../../../../types';

import { Actions, TViewModes } from './Actions';
import { AnalyticsViewContent } from './AnalyticsViewContent';

type Props = {
  hash: string;
  type: TPresentationType;
  session?: TPresentationSession;
};

export function AnalyticsView({ session, hash, type }: Props) {
  const [viewMode, setViewMode] = useState<TViewModes>('timeOnPage');
  const [showPagesWithNoViews, setShowPagesWithNoViews] = useState(true);

  return (
    <Container>
      <Actions
        viewMode={viewMode}
        showPagesWithNoViews={showPagesWithNoViews}
        onViewModeChange={mode => setViewMode(mode)}
        onVisibilityModeChange={() => setShowPagesWithNoViews(prev => !prev)}
        type={type}
      />
      <AnalyticsViewContent
        viewMode={viewMode}
        showPagesWithNoViews={showPagesWithNoViews}
        session={session}
        hash={hash}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;
