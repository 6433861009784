import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { AddLinks } from './AddLinks/AddLinks.component';
import { ITestimonial } from '../../../../../../../../../types';
import { TestimonialEdit } from './TestimonialEdit/TestimonialEdit.component';
import { getTestimonials } from '../../../../../../../../../services/getTestimonials';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';
import { useSlides } from '../../../../../../../../../providers/providers/SlidesProvider';
import { Typography } from '../../../../CustomizePresentation/CustomizationModal/CustomizationModal.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from '../../../../../../../../../components/Common/V2/Accordion';
import { Description, DescriptionWrapper, TextWrapper } from '../../UI';

const testimonialsToState = (testimonials: ITestimonial[]): { [key: string]: ITestimonial } => {
  const obj: { [key: string]: ITestimonial } = {};
  testimonials.forEach(({ id, text, client, isHidden }) => {
    obj[id] = {
      id,
      text,
      client,
      isHidden,
    };
  });
  return obj;
};

export const TestimonialExpandible: React.FC = () => {
  const { getSlide, setSlideData } = useSlides();
  const slide = getSlide('testimonials');

  const [errorMessage, setErrorMessage] = useState('');

  const { testimonials: userApiTestimonials } = useUser();

  const [isResynced, setIsResynced] = useState(false);

  const testimonials = useMemo(() => {
    const slideTestimonials = slide?.data?.testimonials || [];
    return getTestimonials(slideTestimonials, userApiTestimonials);
  }, [slide?.data?.testimonials, userApiTestimonials]);

  const [values, setValues] = useState(testimonialsToState(testimonials ?? []));

  useEffect(() => {
    setSlideData('testimonials', testimonials as unknown as ITestimonial);
  }, [setSlideData, testimonials]);

  useEffect(() => {
    setValues(testimonialsToState(testimonials ?? []));
  }, [testimonials]);

  useEffect(() => {
    const elHeight = document.getElementById('testimonialsContent')?.clientHeight ?? 0;
    if (elHeight > 810) {
      setErrorMessage('* Content is too long and it cannot fit on the slide!');
      return;
    }
    setErrorMessage('');
  }, [testimonials]);

  const updateSlides = (
    id: string,
    key: 'text' | 'client' | 'isHidden',
    value: string | boolean,
  ) => {
    if (!slide || !slide.data || slide?.id !== 'testimonials') return;

    const slideTestimonials: ITestimonial[] =
      slide.data?.testimonials?.map(testimonial => {
        if (testimonial.id === id) {
          return {
            ...testimonial,
            [key]: value,
          };
        }
        return testimonial;
      }) ?? [];

    const slideData = { ...slide.data, testimonials: slideTestimonials };

    setSlideData(slide.id, slideData);
  };

  const onChange = (id: string, key: 'text' | 'client', val: string) => {
    setValues(prev => {
      if (!prev[id]) {
        const item: ITestimonial = {
          id,
          text: '',
          client: '',
          isHidden: false,
        };
        item[key] = val;
        return { ...prev, [id]: item };
      }
      return { ...prev, [id]: { ...prev[id], [key]: val } };
    });

    updateSlides(id, key, val);
  };

  const onToggle = (id: string, val: boolean) => {
    setValues(prev => {
      if (!prev[id]) {
        const item: ITestimonial = {
          id,
          text: '',
          client: '',
          isHidden: false,
        };
        item.isHidden = val;
        return { ...prev, [id]: item };
      }
      return { ...prev, [id]: { ...prev[id], isHidden: val } };
    });

    updateSlides(id, 'isHidden', val);
  };

  if (!slide) return null;

  return (
    <Accordion
      title='Testimonials'
      open
      prefixIcon={<FontAwesomeIcon icon='quote-left' fontSize={15} />}
    >
      <Description>
        <DescriptionWrapper>
          <FontAwesomeIcon icon='circle-info' color='#fff' />
          <TextWrapper>
            <Typography
              variant='small'
              color='white'
              style={{ fontWeight: 700, fontSize: 10, lineHeight: 'normal' }}
            >
              Social proof & positive testimonials play a large role in building client confidence.
            </Typography>
          </TextWrapper>
        </DescriptionWrapper>
      </Description>
      <Wrapper>
        {errorMessage && (
          <Typography variant={'body1'} color='error'>
            {errorMessage}
          </Typography>
        )}
        {testimonials.map(({ id }, i) => (
          <TestimonialEdit
            key={id}
            id={id}
            title={`Testimonial ${i + 1}`}
            note='75 words max'
            values={values[id]}
            onChange={onChange}
            onToggle={onToggle}
            isResynced={isResynced}
            setIsResynced={setIsResynced}
          />
        ))}
      </Wrapper>
      <AddLinks setIsResynced={setIsResynced} />
    </Accordion>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;
