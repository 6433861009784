import styled from 'styled-components';

interface WithPdf {
  isPdf?: boolean;
}

export const ContactItemContainer = styled('div')<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  marginBottom: isPdf ? 8 : 12,
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const IconWrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  width: 20,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  fontSize: isPdf ? 16 : 20,
  paddingRight: 10,
}));

export const Text = styled('div')<WithPdf>(({ isPdf }) => ({
  fontSize: isPdf ? 16 : 20,
  marginLeft: isPdf ? 10 : 20,
  fontWeight: isPdf ? 400 : 300,
  textAlign: 'start',
  opacity: isPdf ? 1 : 0.6,
  color: isPdf ? '#444' : '#fff',
}));

export const LinkEl = Text.withComponent('a');

export const Link = styled(LinkEl)<WithPdf>(({ isPdf }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  fontSize: isPdf ? 16 : 20,
  marginLeft: isPdf ? 10 : 20,
  fontWeight: isPdf ? 400 : 300,
  color: isPdf ? '#444' : '#fff',
}));

export const Address = styled(Text)<WithPdf>(({ isPdf }) => ({
  marginTop: isPdf ? 7 : 12,
  textAlign: 'start',
  opacity: isPdf ? 1 : 0.6,
  fontSize: isPdf ? 16 : 20,
  '&:first-of-type': {
    marginTop: 0,
  },
}));

export const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
