import { useUser } from '../providers/UserProvider';
import { ACCOUNT_IDS } from '../../../constants/accountIds';
import { CurrentUserService } from '../../../services/CurrentUserService';

export const useReverseHeader = () => {
  const user = CurrentUserService.getUser();
  if (!user) return false;

  return ([ACCOUNT_IDS.THE_KEYES_COMPANY, ACCOUNT_IDS.THE_KEYES_COMPANY_2] as Number[]).includes(
    user.accountId,
  );
};
