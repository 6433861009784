import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { OneImageDelta } from './OneImageDelta';
import { OneImageDeltaForm } from './OneImageDeltaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { OneImageDeltaPdf } from './OneImageDeltaPdf';

const template = 'oneImageLayoutDelta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - One Image',
  frame: true,
  data: {
    image: '',
    text: '',
    headline: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: OneImageDeltaForm,
  web: OneImageDelta,
  pdf: OneImageDeltaPdf,
};

export type TOneImageLayoutDelta = typeof template;
export { config, mappings, thumbnail };
