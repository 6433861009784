import {
  ACTIVE_COLOR,
  CLOSED_COLOR,
} from '../../../../pages/PresentationCreate/dash/config/constants';
import { ChartContainer } from '../components/ChartContainer';
import { DoubleBarsChart } from '../components/DoubleBarsChart';
import { Legend } from '../components/Legend';
import { usePricingFavorabilityData } from './usePricingFavorabilityData';
import { PricingFavorabilityTooltip } from './PricingFavorabilityTooltip';

const legendOptions = [
  { green: true, circular: true, text: 'Average List Price' },
  { red: true, circular: true, text: 'Average Sale Price' },
];

interface PricingFavorabilityChartProps {
  headline?: string;
}

export const PricingFavorabilityChart = ({ headline }: PricingFavorabilityChartProps) => {
  const { options } = usePricingFavorabilityData();

  return (
    <ChartContainer headline={headline} legend={<Legend options={legendOptions} />}>
      <DoubleBarsChart
        data={options}
        firstBarDataKey='price'
        secondBarDataKey='sale_price'
        xLabelKey='label'
        primaryColor={ACTIVE_COLOR}
        secondaryColor={CLOSED_COLOR}
        customTooltip={props => <PricingFavorabilityTooltip {...props} />}
      />
    </ChartContainer>
  );
};
