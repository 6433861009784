import { CATEGORIES } from '../../../../../constants/categories';
import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { PurposeOfBuyersAgentLuxuryPDF } from './pdf/luxury/PurposeOfBuyersAgentLuxuryPDF';
import { PurposeOfBuyersAgentLuxury } from './web/luxury/PurposeOfBuyersAgentLuxury';
import { PurposeOfBuyersAgentModernPDF } from './pdf/modern/PurposeOfBuyersAgentModernPDF';
import { PurposeOfBuyersAgentModern } from './web/modern/PurposeOfBuyersAgentModern';
import { PurposeOfBuyersAgentTraditionalPDF } from './pdf/traditional/PurposeOfBuyersAgentTraditionalPDF';
import { PurposeOfBuyersAgentTraditional } from './web/traditional/PurposeOfBuyersAgentTraditional';

const id = 'purposeOfBuyersAgent';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Purpose & Importance of a Buyer’s Agent',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: PurposeOfBuyersAgentTraditional,
    [CATEGORIES.MODERN]: PurposeOfBuyersAgentModern,
    [CATEGORIES.LUXURY]: PurposeOfBuyersAgentLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: PurposeOfBuyersAgentTraditionalPDF,
    [CATEGORIES.MODERN]: PurposeOfBuyersAgentModernPDF,
    [CATEGORIES.LUXURY]: PurposeOfBuyersAgentLuxuryPDF,
  },
};

export type TPurposeOfBuyersAgent = typeof id;
export { config, mappings };
