import React from 'react';
import Reveal from 'reveal.js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'reveal.js/dist/reveal.css';

const PresentationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Container = ({ children = [], onInit }) => {
  const revealRef = React.useRef(null);

  React.useEffect(() => {
    const deck = new Reveal({
      embedded: false,
      width: 1600,
      height: 900,
      margin: 0,
      touch: false,
    });

    deck.initialize();

    onInit(deck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PresentationContainer>
      <div className='reveal' ref={revealRef}>
        <div className='slides'>{children}</div>
      </div>
    </PresentationContainer>
  );
};

Container.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onInit: PropTypes.func.isRequired,
};

export const Item = ({ children = [] }) => (
  <section data-transition={`none`} style={{ pointerEvents: 'all' }}>
    {children}
  </section>
);
Item.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default {
  Container,
  Item,
};
