import React, { useContext, useState } from 'react';
import { TSlidePlatform } from '../../../../types';

type PreviewModeContextProps = {
  previewMode: TSlidePlatform;
  setPreviewMode: (value: TSlidePlatform) => void;
};
const PreviewModeContext = React.createContext<PreviewModeContextProps | undefined>(undefined);

export const usePreviewMode = () => {
  const context = useContext(PreviewModeContext);

  if (!context) {
    throw new Error('usePreviewMode cannot be used without PreviewModeContext');
  }

  return context;
};

export const PreviewModeProvider: React.FC = ({ children }) => {
  const [previewMode, setPreviewMode] = useState<TSlidePlatform>('web');

  const value = React.useMemo(
    () => ({
      previewMode,
      setPreviewMode,
    }),
    [previewMode],
  );

  return <PreviewModeContext.Provider value={value}>{children}</PreviewModeContext.Provider>;
};
