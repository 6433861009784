import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { CoreSocial } from './CoreSocial';
import CoreSocialPdf from './CoreSocialPdf';

const id = 'coreSocial';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'CORE Social',
  boldTrailLabel: 'Social',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: CoreSocial,
  },
  pdf: {
    shared: CoreSocialPdf,
  },
};

export type TCoreSocial = typeof id;
export { config, mappings };
