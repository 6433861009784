import styled from 'styled-components';
import { TAddress } from '../../../types';
import { useAtomValue } from 'jotai';
import { subjectAtom } from '../state/subjectAtom';
import { HeaderCard } from './components/HeaderCard';
import { Separator } from './components/Separator';

const formatAddress = (address: TAddress) => {
  const { deliveryLine, city, state, zip } = address ?? {};
  return `${deliveryLine}, ${city}, ${state} ${zip}`;
};

const getDate = () => {
  const d = new Date();

  return d.toLocaleDateString('en-EN', { year: 'numeric', month: 'long', day: 'numeric' });
};

export const ReportInfo = () => {
  const subject = useAtomValue(subjectAtom);

  return (
    <HeaderCard title='Pricing Analysis Prepared for'>
      <Container>
        <Address>{formatAddress(subject?.property?.address)}</Address>
        <Separator />
        <ReportValidTitle>This report is valid for</ReportValidTitle>
        <DateText>{getDate()}</DateText>
      </Container>
    </HeaderCard>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Address = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: center;
`;

const ReportValidTitle = styled.p`
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.gray[400]};
`;

const DateText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
`;
