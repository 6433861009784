import React from 'react';
import { CoverContent } from '../../components/CoverContent/CoverContent';

import { BackgroundContainer, ClippedBackground, ContentContainer } from './ModernOneCover.styles';

export const ModernOneCover: React.FC = () => {
  return (
    <BackgroundContainer>
      <ClippedBackground />
      <ContentContainer>
        <CoverContent />
      </ContentContainer>
    </BackgroundContainer>
  );
};
