import React from 'react';

import { useDynamicSlides } from './dynamicSlides/useDynamicSlides';
import { useLoadPersonalized } from '../data/personalized/useLoadPersonalized';

export function usePersonalizedWithDynamicSlides(hash: string) {
  const dynamic = useDynamicSlides(hash);
  const personalized = useLoadPersonalized(hash);

  const isLoading = dynamic.isLoading || personalized.isLoading;
  const isFetching = dynamic.isFetching || personalized.isFetching;

  const map = React.useMemo(() => {
    if (isLoading || isFetching) return null;

    const dynamicSlidesMap = (dynamic.data ?? []).reduce(
      (map, ds) => ({ ...map, [ds.slide.id]: { ...ds.slide, sectionId: ds.section } }),
      {},
    );

    return { ...(personalized.data ?? {}), ...dynamicSlidesMap };
  }, [isLoading, isFetching, dynamic.data, personalized.data]);

  return React.useMemo(
    () => ({
      isLoading,
      isFetching,
      data: map,
      dynamic,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isFetching, map],
  );
}
