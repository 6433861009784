import { useAtom, useAtomValue } from 'jotai';
import { useDispatch, useStore } from 'react-redux';

import { ReportDetailedCommunicator } from '../../../pages/PresentationCreate/dash/communicators/ReportDetailed/ReportDetailedCommunicator';
import { operations as reportOperations } from '../../../pages/PresentationCreate/dash/store/report';
import {
  RawReport,
  ReportCompAverages,
  ReportEstimates,
  ReportFlagValues,
  TReportSelectedCategory,
} from '../../../types';
import { selectedCategoriesAtom } from '../state/selectedCategoriesAtom';
import { excludedPropertyIdsAtom } from '../state/excludedPropertyIdsAtom';

const rebuildSelectedCategories = (
  report: RawReport,
  selectedCategories: TReportSelectedCategory[],
) => {
  const categoriesValues = {
    ...report.compAverages,
    ...report.estimates,
    ...report.flags,
  };

  return selectedCategories
    .filter(category => categoriesValues[category.id])
    .map(category => ({
      ...category,
      value: categoriesValues[category.id],
      valuePerSqft:
        categoriesValues[
          (category.id + '_per_sqft') as
            | keyof ReportCompAverages
            | keyof ReportEstimates
            | keyof ReportFlagValues
        ],
    }));
};

export const useUpdateReport = () => {
  const [selectedCategories, setSelectedCategories] = useAtom(selectedCategoriesAtom);
  const excludedIds = useAtomValue(excludedPropertyIdsAtom);
  const dispatch = useDispatch();
  const getState = useStore().getState;

  const updateReport = async (additionalPayload = {}) => {
    const { search } = getState();

    const updatedReport = await ReportDetailedCommunicator.build({
      ...search.api,
      excluded_ids: excludedIds,
      ...additionalPayload,
    });

    dispatch(reportOperations.setReportDetailed(updatedReport));

    const selCat = rebuildSelectedCategories(updatedReport, selectedCategories);
    setSelectedCategories(selCat);

    return updatedReport;
  };

  return updateReport;
};
