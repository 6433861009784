export const pricingFavorabilityData = {
  heading: 'Average Monthly List/Sale Price',
  summary: [
    'Pricing favorability is leaning towards “BUYER”. Supply for comparable homes is high (helping the buyer) and demand for comparable homes is low (helping the buyer).',
  ],
  value: 'Buyer',
  subtitle: 'Average list price of all monthly active and pending comparables',
  description: [
    {
      title: 'WHAT IS AVERAGE MONTHLY PRICE?',
      text: 'The chart illustrates a monthly comparison of the average list and sold price of comparable properties.',
    },
    {
      title: 'HOW IS AVERAGE MONTHLY PRICE USED IN A PRICE ANALYSIS?',
      text: 'The monthly trend can help identify how supply and demand is influencing the list price of comparable properties. While the trend can be very insightful for gauging how aggressive/conservative to price, it’s critical to balance these insights with an analysis of recent closed sales too.',
    },
  ],
};
