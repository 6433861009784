import styled from 'styled-components';
import { TSlide } from '../../types';
import { media } from '../../config/themes';
import { PresentationPreview } from '../../pages/Dashboard/PresentationPreview/PresentationPreview.component';

type Props = {
  presentationHash: string;
  previewSlide: TSlide;
  children: React.ReactNode;
};

export function DashboardContent({ presentationHash, previewSlide, children }: Props) {
  return (
    <Container>
      <LeftColumn>
        <PresentationPreview presentationHash={presentationHash} previewSlide={previewSlide} />
      </LeftColumn>
      <RightColumn>{children}</RightColumn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
    gap: 40px;
  `}
`;

const LeftColumn = styled.div`
  flex: 1;
  overflow: hidden;
`;

const RightColumn = styled.div`
  width: 100%;
  margin-top: 20px;

  ${media.desktop`
    margin-top: 0;
    width: 350px;
  `}

  ${media.largeDesktop`
    width: 450px;
  `}
`;
