import { useEffect } from 'react';

import { extractSubject } from '../services/extractSubject';
import { extractReportProperties } from '../services/extractReportProperties';
import { PropertyGroups, RawReport, TPresentation, TSuperPresentation } from '../../../types';
import { extractPricingFavorability } from '../services/extractPricingFavorability';
import { extractPricingRateOfChange } from '../services/extractPricingRateOfChange';
import { extractSellingPace } from '../services/extractSellingPace';
import { extractActiveShelfLife } from '../services/extractActiveShelfLife';
import { getPropertiesNotes } from '../services/getPropertiesNotes';
import { useSetAtomState } from './useSetAtomState';

export function useSetPresentationToState(
  presentation?: TSuperPresentation,
  isPresentationView: boolean = true,
) {
  const {
    setIsPresentationView,
    setProperties,
    setSubject,
    setSearchCriteria,
    setHighlightedProperties,
    setExcluded,
    setNotes,
    setCustomMarkers,
    setPricingFavorability,
    setRateOfChange,
    setSellingPace,
    setActiveShelfLife,
    setCompAverages,
    setEstimates,
    setCustomEstimates,
    setCompetitionGraphData,
    setFlags,
    setIsDtc,
    setSelectedCategories,
  } = useSetAtomState();

  const report = (presentation as TPresentation)?.reportData?.report?.reportDetailed as RawReport;
  const searchCriteria = (presentation as TPresentation)?.reportData?.search?.searchCriteria;
  const highlightedProperties = (presentation as TPresentation)?.reportData?.result?.highlighted;
  const thumbsUp = (presentation as TPresentation)?.reportData?.result?.thumbsUp;
  const excluded = (presentation as TPresentation)?.reportData?.result?.excluded;
  const compAverages = (presentation as TPresentation)?.reportData?.report?.reportDetailed
    .compAverages;
  const estimates = (presentation as TPresentation)?.reportData?.report?.reportDetailed.estimates;

  const customMarkers = (presentation as TPresentation)?.reportData?.result?.customMarkers;
  const customEstimates = (presentation as TPresentation)?.reportData?.result?.customEstimates;
  const flags = (presentation as TPresentation)?.reportData?.report?.reportDetailed.flags;
  const selectedCategories = (presentation as TPresentation)?.reportData?.result
    ?.selectedCategories;

  const properties =
    (presentation as TPresentation)?.version === 2
      ? (presentation as TPresentation)?.comparableProperties
      : report?.properties;

  const graphs = presentation?.graphs ? presentation.graphs : report?.graphs;

  const notes =
    (presentation as TPresentation)?.version === 2
      ? getPropertiesNotes(properties as PropertyGroups)
      : (presentation as TPresentation)?.reportData?.result?.notes;

  useEffect(() => {
    setIsPresentationView(isPresentationView);

    if (!report) return;

    setProperties(extractReportProperties(properties, { filterExcluded: true }));
    setSubject(extractSubject(report));
    setSearchCriteria(searchCriteria);
    setHighlightedProperties(highlightedProperties);
    setExcluded(excluded);
    setNotes(notes);
    setCustomMarkers(customMarkers);
    setPricingFavorability(extractPricingFavorability(graphs));
    setRateOfChange(extractPricingRateOfChange(graphs));
    setSellingPace(extractSellingPace(graphs));
    setActiveShelfLife(extractActiveShelfLife(graphs));
    setCompAverages(compAverages);
    setEstimates(estimates);
    setCustomEstimates(customEstimates);
    setCompetitionGraphData(graphs.competition);

    setFlags(flags);
    setIsDtc(report.useDtcInsteadDomForSold || false);
    setSelectedCategories(selectedCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, searchCriteria]);
}
