import styled from 'styled-components';

type WithActiveAndCount = {
  isActive?: boolean;
  count?: number;
};

type ContainerProps = { noPadding?: boolean };
export const TagsContainer = styled('div')<ContainerProps>(({ noPadding, theme }) => ({
  display: 'flex',
  padding: noPadding ? 0 : '10px 20px',
  borderBottom: noPadding ? 'none' : `1px solid ${theme.colors.v2.gray[200]}`,
  flexWrap: 'wrap',
  background: theme.colors.v2.background,
}));

export const Tag = styled('div')<WithActiveAndCount>(({ theme, isActive }) => ({
  color: isActive ? theme.colors.v2.text.regular : theme.colors.v2.text.link,
  fontSize: 14,
  cursor: 'pointer',
  background: isActive ? theme.colors.v2.gray[200] : 'transparent',
  padding: '4px 10px',
  borderRadius: '5px',
  '&:not(:last-of-type)': {
    marginRight: 5,
  },
}));

export const TagCount = styled('span')<WithActiveAndCount>(({ theme, isActive, count }) => ({
  fontSize: count && count >= 10 ? '0.6rem' : '0.8rem',
  width: 15,
  height: 15,
  borderRadius: '50%',
  border: isActive
    ? `1px solid ${theme.colors.v2.text.regular}`
    : `1px solid ${theme.colors.v2.text.link}`,
  color: isActive ? theme.colors.v2.text.regular : theme.colors.v2.text.link,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 3,
}));
