import { TSlide } from '../../../types';
import { useEffect, useState } from 'react';

export const useCurrentSlide = (slides: TSlide[], Reveal: any) => {
  const [currentSlide, setCurrentSlide] = useState(slides[0]);

  const getCurrentSlide = (): TSlide => {
    const index = (Reveal as any)?.getState()?.indexh;
    if (isNaN(index)) return slides[0];
    return slides[index];
  };

  useEffect(() => {
    if (!Reveal) return;

    function onSlideChanged(e: any) {
      const index = e?.indexh;
      if (index < 0) return;
      const slide = slides[index];
      if (slide) setCurrentSlide(slide);
    }

    function onSetInitialSlide() {
      setCurrentSlide(slides[0]);
    }

    Reveal.on('ready', onSetInitialSlide);
    Reveal.on('slidechanged', onSlideChanged);
    return () => {
      Reveal.off('slidechanged', onSlideChanged);
      Reveal.off('slidechanged', onSlideChanged);
    };
  });

  return getCurrentSlide() ?? currentSlide;
};
