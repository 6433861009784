import styled from 'styled-components';

export const Container = styled('div')({
  display: 'flex',
  height: '100%',
  flex: 1,
});

export const Content = styled('div')({
  backgroundColor: '#fff',
  width: '67%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});
