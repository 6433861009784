import styled from 'styled-components';

export const Container = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 60,
  backgroundColor: '#fff',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  flexShrink: 0,
  padding: theme.spacing(0, 4),
}));
