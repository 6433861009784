import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import h337 from 'heatmap.js';

import { THeatmapTracking, TSlide } from '../../../../../../../../../../../types';

import { useOutsideClick } from '../../../../../../../../../../../services/useOutsideClick';
import { millisToMinutesAndSeconds } from '../../../../../services/millisToMinutesAndSeconds';
import { usePresentation } from '../../../../../../../../../../../hooks/usePresentation';

import { useUiConfigTheme } from '../../../../../../../../../../../providers/providers/UiConfigThemeProvider';
import { useUiConfigInfoPlacement } from '../../../../../../../../../../../providers/providers/UiConfigInfoPlacementProvider';

import PreviewSlide from '../../../../../../../../../../../components/PreviewSlide/PreviewSlide.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  hash: string;
  slide: TSlide;
  pageVisits: number;
  timeOnPage: number;
  isHeaderDown?: boolean;
  pageInteractions: number;
  cursorTrackingData: THeatmapTracking;
  onClose: () => void;
};

export function SlidePopup({
  hash,
  slide,
  timeOnPage,
  pageVisits,
  isHeaderDown,
  pageInteractions,
  cursorTrackingData,
  onClose,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => onClose());

  const { data: presentation } = usePresentation(hash);
  const { theme } = useUiConfigTheme();
  const { infoPlacement } = useUiConfigInfoPlacement();

  useEffect(() => {
    const movementHeatmap = h337.create({
      container: document.getElementById(slide.id) as HTMLElement,
      radius: 30,
      maxOpacity: 0.6,
      minOpacity: 0.2,
      gradient: {
        '0.25': 'yellow',
        '0.55': 'green',
        '0.85': 'red',
        '1.0': 'red',
      },
    });

    const movementHeatmapData = {
      max: (cursorTrackingData?.movements?.length ?? 0) / 50,
      min: 0,
      data: cursorTrackingData?.movements ?? [],
    };

    movementHeatmap.setData(movementHeatmapData);

    const heatmap = h337.create({
      container: document.getElementById(slide.id) as HTMLElement,
      radius: 60,
      maxOpacity: 0.7,
      minOpacity: 0.3,
      blur: 0.1,
      gradient: {
        '1.0': '#6AB4E4',
      },
    });

    const heatMapData = {
      max: cursorTrackingData?.clicks?.length ?? 0,
      min: 0,
      data: cursorTrackingData?.clicks ?? [],
    };

    heatmap.setData(heatMapData);
  }, [cursorTrackingData, slide.id]);

  const onClosePopup = (e: any) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Container ref={ref} isHeaderDown={isHeaderDown}>
      <Triangle isHeaderDown={isHeaderDown} />
      <Header isHeaderDown={isHeaderDown}>
        <Title>{slide.label}</Title>
        <Close icon={'close'} onClick={onClosePopup} />
      </Header>
      <Body>
        <SlideCardWrapper>
          <PreviewSlide
            slide={slide}
            theme={theme}
            infoPlacement={infoPlacement}
            user={presentation?.user}
            presentationHash={presentation?.hash}
          />
        </SlideCardWrapper>
        <CardWrapper>
          <Row>
            <Text>Page Visits</Text>
            <Value>{pageVisits ?? 'N/A'}</Value>
          </Row>
          <Row>
            <Text>Time On Page</Text>
            <Value>{millisToMinutesAndSeconds(timeOnPage)}</Value>
          </Row>
          <Row style={{ borderBottom: 'none' }}>
            <Text>Page Interaction</Text>
            <Value>{pageInteractions ?? 'N/A'}</Value>
          </Row>
        </CardWrapper>
      </Body>
    </Container>
  );
}

const Container = styled.div<{ isHeaderDown?: boolean }>`
  display: flex;
  flex-direction: ${({ isHeaderDown }) => (isHeaderDown ? 'column-reverse' : 'column')};
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  position: absolute;
  height: 248px;
  width: 634px;
  border-radius: 7px;
  z-index: 3;
  top: ${({ isHeaderDown }) => (isHeaderDown ? 'auto' : '40px')};
  bottom: ${({ isHeaderDown }) => (isHeaderDown ? '40px' : 'auto')};
  left: 50%;
  transform: translateX(-50%);
`;

const Triangle = styled.div<{ isHeaderDown?: boolean }>`
  position: absolute;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: ${({ isHeaderDown }) =>
    `translateX(-50%) rotate(${isHeaderDown ? '-180deg ' : '0deg'})`};
  clip-path: polygon(50% 40%, 0% 100%, 100% 100%);
  top: ${({ isHeaderDown }) => (isHeaderDown ? 'auto' : '-60px')};
  bottom: ${({ isHeaderDown }) => (isHeaderDown ? '-60px' : 'auto')};
  background: ${({ theme }) => theme.colors.v2.background};
`;

const Header = styled.div<{ isHeaderDown?: boolean }>`
  display: flex;
  padding: 12px 15px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  background: #fff;
  border-bottom: ${({ isHeaderDown }) => (isHeaderDown ? 'none' : '1px solid #ddd')};
  border-top: ${({ isHeaderDown }) => (isHeaderDown ? '1px solid #ddd' : 'none')};
  border-radius: ${({ isHeaderDown }) => (isHeaderDown ? '0 0 7px 7px' : '7px 7px 0 0')};
`;

const Title = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Close = styled(FontAwesomeIcon)`
  height: 14px;
  width: 14px;
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Body = styled.div`
  position: relative;
  display: flex;
  padding: 16px 17px;
  align-items: flex-start;
  gap: 17px;
  border-radius: 0px 0px 7px 7px;
  background: ${({ theme }) => theme.colors.v2.gray[100]};
  flex: 1;
`;

const SlideCardWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 231px;
  height: 175px;
  align-items: center;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.v2.background};
  transition: all 0.2s ease-in-out;
  border: 2px solid ${({ theme }) => theme.colors.v2.background};
  :hover {
    transform: scale(2);
  }
`;

const CardWrapper = styled.div`
  display: flex;
  width: 268px;
  padding: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  background: #fff;
  flex: 1;
  height: 100%;
  transition: all 0.2s ease-in-out;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-bottom: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Value = styled.p`
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
