class ValidatorImp {
  #validators = [];

  clear() {
    this.#validators = [];
  }

  subscribe(key, validator) {
    if (typeof validator !== 'function' || typeof key !== 'string') {
      return;
    }

    this.#validators[key] = validator;
  }

  unsubscribe(key) {
    delete this.#validators[key];
  }

  validate() {
    return Object.values(this.#validators).every(validator => validator());
  }
}

export const SearchValidator = new ValidatorImp();
