import React, { useEffect, useState } from 'react';

import { TProperty } from '../../../../types';
import { MapWrapper, CMAWrapper } from './Map.styles';
import { Markers } from './Markers/Markers.component';
import { GoogleMapComponent } from '../../../../components/Common/GoogleMap/GoogleMap.component';
import { useAdaptMapBoundariesToIncludeProperties } from './useAdaptMapBoundariesToIncludeProperties';

interface MapProps {
  properties: any[];
  cma?: boolean;
  onMoreDetailsClick?: (property: TProperty) => void;
  style?: React.CSSProperties;
  useNumberedMarkers?: boolean;
  isViewModeProperty?: boolean;
}

export const Map: React.FC<MapProps> = ({
  properties,
  cma,
  onMoreDetailsClick,
  style,
  useNumberedMarkers,
  isViewModeProperty,
}) => {
  const [mapInstance, setMapInstance] = useState<any>(null);

  useAdaptMapBoundariesToIncludeProperties(mapInstance, properties);

  const onCloseInfoWindow = () => {
    document.dispatchEvent(new CustomEvent('close-info-window'));
  };

  useEffect(() => {
    const onMoreInfo = ({ id }: { id: string }) => {
      onCloseInfoWindow();

      const selectedProperty = properties?.find(property => property?.id === id);

      onMoreDetailsClick?.(selectedProperty);
    };

    document.addEventListener('more-info', (e: Event) => {
      onMoreInfo((e as CustomEvent).detail);
    });

    return () => {
      document.removeEventListener('more-info', (e: Event) => {
        onMoreInfo((e as CustomEvent).detail);
      });
    };
  }, [onMoreDetailsClick, properties]);

  const Wrapper = cma ? CMAWrapper : MapWrapper;

  return (
    <Wrapper style={style ?? {}}>
      <GoogleMapComponent
        mapContainerStyle={{ height: '100%', width: '100%' }}
        zoom={10}
        setMapInstance={setMapInstance}
        options={{ maxZoom: 18 }}
      >
        <Markers
          properties={properties}
          useNumberedMarkers={useNumberedMarkers}
          onMoreInfo={onMoreDetailsClick}
          isViewModeProperty={isViewModeProperty}
        />
      </GoogleMapComponent>
    </Wrapper>
  );
};
