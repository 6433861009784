import React from 'react';
import { CSSObject } from 'styled-components';

import { Text, LogosWrapper } from './CoverLogos.styles';
import { useUser } from '../../../../../../providers/UserProvider';
import Logos from '../../../../../../components/SlideFrame/components/Logos/Logos';

interface CoverLogosProps {
  displayImage?: boolean;
  logoContainerStyles?: CSSObject;
  logoStyles?: CSSObject;
  presentedByStyles?: CSSObject;
}

export const CoverLogos: React.FC<CoverLogosProps> = ({
  displayImage,
  logoContainerStyles,
  logoStyles,
  presentedByStyles,
}) => {
  const user = useUser();

  return (
    <LogosWrapper>
      <Logos
        containerStyles={{
          height: 100,
          width: '100%',
          marginTop: displayImage ? 0 : 'auto',
          justifyContent: displayImage ? 'flex-start' : 'center',
          ...logoContainerStyles,
        }}
        logoStyles={{ height: 100, ...logoStyles }}
      />
      <Text
        style={{
          fontSize: 16,
          color: '#444',
          marginTop: 12,
          paddingTop: 15,
          marginBottom: 0,
          borderTop: '0.5px solid rgba(0, 0, 0, 0.3)',
          ...presentedByStyles,
        }}
      >
        Prepared by {user?.name}
      </Text>
    </LogosWrapper>
  );
};
