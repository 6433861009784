import { useEffect } from 'react';
import { SpacingArgs, Theme } from './types';

const spacingFactor = 8;

export const spacing = (val1: SpacingArgs, ...args: SpacingArgs[]): string => {
  const values = [val1, ...args].map(v => (v === 'auto' ? v : `${v * spacingFactor}px`));
  return values.join(' ');
};

export function useSyncCSSVariables(theme: Theme) {
  useEffect(() => {
    document.documentElement.style.setProperty('--color-primary', theme.colors.v2.primary);
    document.documentElement.style.setProperty('--color-gray', theme.colors.v2.gray[400]);
    document.documentElement.style.setProperty('--color-gray-light', theme.colors.v2.gray[300]);
    document.documentElement.style.setProperty('--color-gray-lighter', theme.colors.v2.gray[200]);
    document.documentElement.style.setProperty(
      '--color-gray-super-light',
      theme.colors.v2.gray[100],
    );
    document.documentElement.style.setProperty('--color-error', theme.colors.v2.status.error);
  }, [theme]);
}
