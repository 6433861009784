import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TSlideTheme } from '../../../../../../../../../../types';

import { useCategory } from '../../../../../../../../../../providers/providers/CategoryProvider';
import { useUiConfigTheme } from '../../../../../../../../../../providers/providers/UiConfigThemeProvider';

import { CustomizationSettingsExpandableCommon } from '../../../CustomizationModalStepOne.component';
import { FrameThumbnailConfigsProvider } from '../../../../../../../../../../components/Slide/components/SlideFrame/frames/web/FrameThumbnailConfigsProvider';
import { Accordion } from '../../../../../../../../../../components/Common/V2/Accordion';

type ExpandableThemeComponentProps = CustomizationSettingsExpandableCommon;

export function ExpandableThemeComponent({ open, onToggle }: ExpandableThemeComponentProps) {
  const { category } = useCategory();
  const { theme, setTheme } = useUiConfigTheme();
  const [activeTheme, setActiveTheme] = useState<TSlideTheme>(theme);

  useEffect(() => setTheme(activeTheme), [activeTheme, setTheme]);

  const handleClick = (id: TSlideTheme) => {
    setActiveTheme(id);
  };

  if (!category) return null;

  const themes = FrameThumbnailConfigsProvider.getByCategory(category);

  return (
    <Accordion
      open={open}
      title={'Themes'.toUpperCase()}
      prefixIcon={<FontAwesomeIcon icon={'table-layout'} />}
      onClick={() => onToggle('theme')}
    >
      <Container>
        {themes.map(themeItem => (
          <ImageWrapper key={themeItem.id}>
            <Gradient isActive={activeTheme === themeItem.id}>
              <Image src={themeItem.thumbnail} onClick={() => handleClick(themeItem.id)} />
            </Gradient>
            <DefaultText>{activeTheme === themeItem.id ? 'Default' : ''}</DefaultText>
          </ImageWrapper>
        ))}
      </Container>
    </Accordion>
  );
}

const Container = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

const Gradient = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 100%;
  padding: 2px;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.v2.gradient.accent : 'none')};
`;

const Image = styled.img`
  display: flex;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
`;

const DefaultText = styled.p`
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
