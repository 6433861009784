import React from 'react';
import { useMemo } from 'react';
import { TPresentationSession } from '../../../../../../../../api/TrackingApi';
import { Select } from '../../../../../../../../components/Common/Select/Select.component';

type Props = {
  activeSession?: TPresentationSession;
  setActiveSession: (session?: TPresentationSession) => void;
  sessions?: TPresentationSession[];
};

export const SessionSelector: React.FC<Props> = ({ activeSession, setActiveSession, sessions }) => {
  const sortedSessions = useMemo(
    () =>
      [...(sessions ?? [])].sort(
        (a, b) => new Date(b?.startedAt ?? 0).getTime() - new Date(a?.startedAt ?? 0).getTime(),
      ),
    [sessions],
  );

  const options = useMemo(
    () =>
      sortedSessions?.map(session => {
        const label = new Date(session.startedAtTimestamp * 1000).toLocaleDateString('en-US', {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        });

        return {
          label,
          value: session.hash ?? '',
        };
      }) ?? [],
    [sortedSessions],
  );

  const selected = options.find(o => o.value === activeSession?.hash);

  return (
    <Select
      options={options}
      selectedOption={selected}
      onChange={(option: any) => {
        setActiveSession(sessions?.find(s => s?.hash === option?.value));
      }}
    />
  );
};
