import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import styled from 'styled-components';

import { ComparableExtension } from '../../../../../../types';
import { Button } from '../../../../../Common/V2/Button/Button';
import { useComparableExtensionCreate } from '../useComparableExtensionCreate';
import { ComparableExtensionInput } from './ComparableExtensionInput';
import { ComparableExtensionPriceInput } from './ComparableExtensionPriceInput';
import { ComparableExtensionList } from '../ComparableExtensionList';
import { comparableExtensionPropertyErrorsAtom } from './comparableExtensionPropertyErrorsAtom';

interface Props {
  propertyId: string;
  comparableExtensions?: ComparableExtension[];
}

export const AddComparableExtension = ({ propertyId, comparableExtensions }: Props) => {
  const { mutate, error, isError, reset } = useComparableExtensionCreate();

  const [compExtensionPropertyErrors, setCompExtensionPropertyErrors] = useAtom(
    comparableExtensionPropertyErrorsAtom,
  );
  const [extensionName, setExtensionName] = useState('');
  const [extensionValue, setExtensionValue] = useState<number | string | undefined>('');

  useEffect(() => {
    reset();
    setExtensionName('');
    setExtensionValue('');
  }, [propertyId, reset]);

  const handleExtensionValueChange = (val?: number | string) => {
    if (isError) {
      reset();
      setCompExtensionPropertyErrors(prev => prev.filter(error => error.propertyId !== propertyId));
    }

    setExtensionValue(val);
  };

  const createExtension = () => {
    mutate(
      {
        propertyId,
        name: extensionName,
        value: !extensionValue ? null : Number(extensionValue),
      },
      {
        onSuccess: () => {
          setExtensionName('');
          setExtensionValue('');
          setCompExtensionPropertyErrors(prev =>
            prev.filter(error => error.propertyId !== propertyId),
          );
        },
        onError: error => {
          setCompExtensionPropertyErrors(prev => [...prev, { propertyId, message: error.message }]);
        },
      },
    );
  };

  const propertyError = compExtensionPropertyErrors.find(error => error.propertyId === propertyId);
  const hasPropertyError = Boolean(propertyError);
  const propertyErrorMessage = propertyError?.message;

  const errorMsg = isError ? error.message : hasPropertyError ? propertyErrorMessage : '';

  return (
    <Wrapper>
      <Grid>
        <ComparableExtensionInput
          label='Adjustment name'
          value={extensionName}
          placeholder='Enter Name'
          name='name'
          onChange={e => {
            if (isError || hasPropertyError) {
              reset();
              setCompExtensionPropertyErrors(prev =>
                prev.filter(error => error.propertyId !== propertyId),
              );
            }

            setExtensionName(e.target.value);
          }}
        />
        <ComparableExtensionPriceInput
          value={extensionValue}
          onChange={handleExtensionValueChange}
          placeholder='$0'
          name='price'
          prefix='$'
          isAllowed={({ floatValue }) =>
            (floatValue ?? 0) <= 1000000000 && (floatValue ?? 0) >= -1000000000 && floatValue !== 0
          }
        />
        <Button variant='primary' onClick={createExtension}>
          Add
        </Button>
        {!errorMsg ? null : <Error>{errorMsg}</Error>}
      </Grid>
      <ComparableExtensionList
        comparableExtensions={comparableExtensions}
        propertyId={propertyId}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.v2.gray[100]};
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  margin-top: 15px;
  margin-left: -15px;
  margin-right: -15px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
  align-items: end;
  gap: 5px;

  @media (max-width: 1240px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  grid-column: span 3;

  @media (max-width: 1240px) {
    grid-column: span 1;
  }
`;
