import styled from 'styled-components';
import { Typography } from '../../../../../../../components/Common';

export { Typography };

export const StepTwoLeftContent = styled('div')(({ theme }) => ({
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  marginRight: 20,
  padding: '40px 0 40px 40px',
}));

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100vh - 360px)',
  paddingRight: 20,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '.3em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colors.gray[200],
    outline: 'none',
  },
  'scroll-behavior': 'smooth',
}));

export const StepTwoRightContent = styled('div')({
  width: 'calc(60% - 20px)',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 40px 40px 0',
});
