import styled from 'styled-components';

import { TViewModes } from '../Actions';

type Props = { timestamps: string[]; viewMode: TViewModes };

export function Timestamps({ timestamps, viewMode }: Props) {
  return (
    <ColumnsRow>
      {timestamps.map((timestamp, i, self) => {
        const first = i === 0;
        const last = i === self.length - 1;

        const zeroTimestamp = viewMode === 'pageViews' ? '0' : '0:00';

        const timestampStyles = { transform: last ? '' : 'translateX(50%)' };
        const columnStyles = { borderRight: last ? '1px solid #f1f1f1' : '' };

        const TimestampBlock = first ? (
          <ZeroTimestampColumnWrapper>
            <Timestamp>{zeroTimestamp}</Timestamp>
            <Timestamp style={timestampStyles}>{timestamp}</Timestamp>
          </ZeroTimestampColumnWrapper>
        ) : (
          <Timestamp style={timestampStyles}>{timestamp}</Timestamp>
        );

        return (
          <TimestampColumnWrapper key={timestamp}>
            {TimestampBlock}
            <Column style={columnStyles} />
          </TimestampColumnWrapper>
        );
      })}
    </ColumnsRow>
  );
}

const ColumnsRow = styled.div`
  display: flex;
  flex: 1 1 0;
  width: 100%;
  grid-row-start: 1;
  grid-column-start: 1;
  margin-bottom: 20px;
`;

const TimestampColumnWrapper = styled.div`
  display: flex;
  width: 0;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  gap: 3px;
`;

const ZeroTimestampColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Timestamp = styled.div`
  display: flex;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Column = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-self: stretch;
  background: ${({ theme }) => theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[100]};
  border-right: none;
`;
