import { TCompConfigItem } from '../../../../../types';
import { FeatureGroup } from '../components/FeatureGroup';
import { Section } from '../components/Section';
import { FEATURES } from '../featuresConfig';
import { FEATURES_KEYS } from '../types';

export const ExteriorFeatures = () => {
  return (
    <Section title='EXTERIOR:'>
      <FeatureGroup
        title='PROPERTY POOL'
        fields={
          [FEATURES[FEATURES_KEYS.POOL_YES], FEATURES[FEATURES_KEYS.POOL_NO]] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='PROPERTY VIEW'
        fields={
          [FEATURES[FEATURES_KEYS.VIEW_YES], FEATURES[FEATURES_KEYS.VIEW_NO]] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='WATER FRONT'
        fields={
          [
            FEATURES[FEATURES_KEYS.WATER_FRONT_YES],
            FEATURES[FEATURES_KEYS.WATER_FRONT_NO],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='WATER VIEW'
        fields={
          [
            FEATURES[FEATURES_KEYS.WATER_VIEW_YES],
            FEATURES[FEATURES_KEYS.WATER_VIEW_NO],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='DECK'
        fields={
          [FEATURES[FEATURES_KEYS.DECK_YES], FEATURES[FEATURES_KEYS.DECK_NO]] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='BASEMENT'
        fields={
          [
            FEATURES[FEATURES_KEYS.BASEMENT_NONE],
            FEATURES[FEATURES_KEYS.BASEMENT_YES],
            FEATURES[FEATURES_KEYS.BASEMENT_FINISHED],
            FEATURES[FEATURES_KEYS.BASEMENT_PARTIAL],
            FEATURES[FEATURES_KEYS.BASEMENT_UNFINISHED],
          ] as TCompConfigItem[]
        }
      />
      <FeatureGroup
        title='GARAGE'
        fields={
          [
            FEATURES[FEATURES_KEYS.GARAGE_0],
            FEATURES[FEATURES_KEYS.GARAGE_1],
            FEATURES[FEATURES_KEYS.GARAGE_2],
            FEATURES[FEATURES_KEYS.GARAGE_3],
          ] as TCompConfigItem[]
        }
      />
    </Section>
  );
};
