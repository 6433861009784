import styled from 'styled-components';

import { TViewModes } from '../Actions';
import { TPresentationSession } from '../../../../../../../../../api/TrackingApi';

import { millisToMinutesAndSeconds } from '../../../services/millisToMinutesAndSeconds';

import { Timestamps } from './Timestamps';
import { BarGraph } from './Bar/BarGraph';

type Props = {
  hash: string;
  viewMode: TViewModes;
  showPagesWithNoViews: boolean;
  session?: TPresentationSession;
};

export function DataVisualization({ session, showPagesWithNoViews, viewMode, hash }: Props) {
  const analytics = session?.slides ?? null;

  if (!analytics) return null;

  const values = Object.values(analytics).map((s: any) =>
    viewMode === 'pageViews' ? s.views : s.duration,
  );

  function toValue(val: number) {
    return viewMode === 'pageViews' ? val.toString() : millisToMinutesAndSeconds(val);
  }

  function getTimestampValue(maxVal: number) {
    const timestampRange = Math.ceil(maxVal / 9);
    const timestampS = Math.round(timestampRange); // only for time

    const powerOfValueViews = maxVal <= 9 ? 1 : 2;
    const powerOfValueTime = timestampS <= 20 ? 2 : 5;

    const timeFormatted = timestampS + (timestampS % powerOfValueTime); // only for time
    const viewsFormatted = timestampRange + (timestampRange % powerOfValueViews);

    return viewMode === 'pageViews' ? viewsFormatted : timeFormatted;
  }

  function getMaxValue() {
    const maxValue = Math.ceil(Math.max(...values));
    const minMaxValue = viewMode === 'pageViews' ? 9 : 9000;
    const timestamp = getTimestampValue(maxValue > minMaxValue ? maxValue : minMaxValue);
    return timestamp * 9;
  }

  function getTimestamps() {
    const timestamps = [];
    const timestampsSpan = getTimestampValue(maxValue);

    let curr = timestampsSpan;

    for (let i = 0; i < 9; i++) {
      timestamps[i] = toValue(curr);
      curr += timestampsSpan;
    }

    return timestamps;
  }

  const maxValue = getMaxValue();
  const timestamps = getTimestamps();

  return (
    <Container>
      <Timestamps timestamps={timestamps} viewMode={viewMode} />
      <BarGraph
        hash={hash}
        maxValue={maxValue}
        viewMode={viewMode}
        analytics={analytics}
        showPagesWithNoViews={showPagesWithNoViews}
      />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
`;
