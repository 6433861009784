import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper, LeftCol, RightCol, ImagePreview } from './OneImageAlpha.styles';

interface Props {
  reverse?: boolean;
}

export const OneImageAlpha: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();

  return (
    <Container style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
      <LeftCol>
        <ImageWrapper>
          <ImagePreview src={slide.data?.image} />
        </ImageWrapper>
      </LeftCol>
      <RightCol>
        <SlideTextBlock
          isEditable={false}
          text={slide.data?.text}
          headline={slide.data?.headline}
        />
      </RightCol>
    </Container>
  );
};
