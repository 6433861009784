import { CSSProperties } from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
  style?: CSSProperties;
}

export const Disclaimer = ({ text, style }: Props) => {
  return <Text style={style}>{text}</Text>;
};

export const Text = styled.div`
  color: #626262;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
`;
