import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  onClick?: () => void;
  isLocked?: boolean;
  canLock?: boolean;
};

export function Lock({ canLock, onClick, isLocked }: Props) {
  if (!isLocked && !canLock) return null;

  return <Icon icon={isLocked ? 'lock' : 'unlock'} onClick={canLock ? onClick : undefined} />;
}

const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
