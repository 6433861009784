import { merge, cloneDeep } from 'lodash';

import { CATEGORIES } from '../../constants/categories';
import {
  EntityMode,
  InheritanceLevel,
  TCategory,
  TInheritanceUserConfigs,
  TKvCoreEntity,
  TSlide,
  TUserConfigs,
} from '../../types';
import { CurrentUserService } from '../../services/CurrentUserService';
import { UserConfigStructureMigrator } from './UserConfigStructureMigrator';
import { getConfigLockedStatus } from '../../pages/PresentationSettings/views/CustomizationSettings/sections/CustomizePresentation/CustomizationModal/services/getConfigLockedStatus';
import { OrderByTypeAdapter } from '../../services/OrderByTypeAdapter';
import { getSupperaccountId } from '../../services/getSuperaccountId';
import { OrderByTypeCombiner } from './OrderByTypeCombiner';
import { EntityType } from './EntityType';

function updateLockableValue(
  superaccountId: number,
  entity: number | null,
  primaryTeamEntity: number,
  primaryOfficeEntity: number,
  isTeamEntity: boolean,
  isOfficeEntity: boolean,
  merged: any,
  superaccountConfigs?: any,
  accountConfigs?: any,
  officeConfigs?: any,
  teamConfigs?: any,
  usersConfigs?: any,
): any {
  if (!merged) return;

  const isTeamLocked = getConfigLockedStatus(
    isTeamEntity ? entity : null,
    primaryOfficeEntity,
    primaryTeamEntity,
    isOfficeEntity,
    teamConfigs?.lockedStatus,
  );

  const isOfficeLocked = getConfigLockedStatus(
    isOfficeEntity ? entity : null,
    primaryOfficeEntity,
    primaryTeamEntity,
    isOfficeEntity,
    officeConfigs?.lockedStatus,
  );
  const isAccountLocked = getConfigLockedStatus(0, 0, 0, false, accountConfigs?.lockedStatus);
  const isSuperaccountLocked = getConfigLockedStatus(
    superaccountId,
    -1,
    -1,
    false,
    superaccountConfigs?.lockedStatus,
  );

  if (isSuperaccountLocked) {
    merged.value = superaccountConfigs?.value;
    return;
  }

  if (isAccountLocked) {
    merged.value = accountConfigs?.value || superaccountConfigs?.value;
    return;
  }

  if (isOfficeLocked) {
    merged.value = officeConfigs?.value || accountConfigs?.value || superaccountConfigs?.value;
    return;
  }

  if (isTeamLocked) {
    merged.value =
      teamConfigs?.value ||
      officeConfigs?.value ||
      accountConfigs?.value ||
      superaccountConfigs?.value;
    return;
  }

  merged.value =
    usersConfigs?.value ||
    teamConfigs?.value ||
    officeConfigs?.value ||
    accountConfigs?.value ||
    superaccountConfigs?.value;
}

function applyTheRightValues(
  superaccountId: number,
  entity: number | null,
  primaryTeamEntity: number,
  primaryOfficeEntity: number,
  isTeamEntity: boolean,
  isOfficeEntity: boolean,
  merged: any,
  superaccountConfigs?: TUserConfigs,
  accountConfigs?: TUserConfigs,
  officeConfigs?: TUserConfigs,
  teamConfigs?: TUserConfigs,
  usersConfigs?: TUserConfigs,
) {
  if (!superaccountConfigs && !accountConfigs && !officeConfigs && !teamConfigs && !usersConfigs)
    return undefined;

  if (entity === -1 || entity === -2) return;

  Object.values(CATEGORIES).forEach(category => {
    updateLockableValue(
      superaccountId,
      entity,
      primaryTeamEntity,
      primaryOfficeEntity,
      isTeamEntity,
      isOfficeEntity,
      merged[category]?.ui?.colors?.primary,
      superaccountConfigs?.[category]?.ui?.colors?.primary,
      accountConfigs?.[category]?.ui?.colors?.primary,
      officeConfigs?.[category]?.ui?.colors?.primary,
      teamConfigs?.[category]?.ui?.colors?.primary,
      usersConfigs?.[category]?.ui?.colors?.primary,
    );

    updateLockableValue(
      superaccountId,
      entity,
      primaryTeamEntity,
      primaryOfficeEntity,
      isTeamEntity,
      isOfficeEntity,
      merged[category]?.ui?.colors?.secondary,
      superaccountConfigs?.[category]?.ui?.colors?.secondary,
      accountConfigs?.[category]?.ui?.colors?.secondary,
      officeConfigs?.[category]?.ui?.colors?.secondary,
      teamConfigs?.[category]?.ui?.colors?.secondary,
      usersConfigs?.[category]?.ui?.colors?.secondary,
    );

    updateLockableValue(
      superaccountId,
      entity,
      primaryTeamEntity,
      primaryOfficeEntity,
      isTeamEntity,
      isOfficeEntity,
      merged[category]?.ui?.fonts?.header,
      superaccountConfigs?.[category]?.ui?.fonts?.header,
      accountConfigs?.[category]?.ui?.fonts?.header,
      officeConfigs?.[category]?.ui?.fonts?.header,
      teamConfigs?.[category]?.ui?.fonts?.header,
      usersConfigs?.[category]?.ui?.fonts?.header,
    );

    updateLockableValue(
      superaccountId,
      entity,
      primaryTeamEntity,
      primaryOfficeEntity,
      isTeamEntity,
      isOfficeEntity,
      merged[category]?.ui?.fonts?.body,
      superaccountConfigs?.[category]?.ui?.fonts?.body,
      accountConfigs?.[category]?.ui?.fonts?.body,
      officeConfigs?.[category]?.ui?.fonts?.body,
      teamConfigs?.[category]?.ui?.fonts?.body,
      usersConfigs?.[category]?.ui?.fonts?.body,
    );

    updateLockableValue(
      superaccountId,
      entity,
      primaryTeamEntity,
      primaryOfficeEntity,
      isTeamEntity,
      isOfficeEntity,
      merged[category]?.ui?.logos?.first,
      superaccountConfigs?.[category]?.ui?.logos?.first,
      accountConfigs?.[category]?.ui?.logos?.first,
      officeConfigs?.[category]?.ui?.logos?.first,
      teamConfigs?.[category]?.ui?.logos?.first,
      usersConfigs?.[category]?.ui?.logos?.first,
    );

    updateLockableValue(
      superaccountId,
      entity,
      primaryTeamEntity,
      primaryOfficeEntity,
      isTeamEntity,
      isOfficeEntity,
      merged[category]?.ui?.infoPlacement,
      superaccountConfigs?.[category]?.ui?.infoPlacement,
      accountConfigs?.[category]?.ui?.infoPlacement,
      officeConfigs?.[category]?.ui?.infoPlacement,
      teamConfigs?.[category]?.ui?.infoPlacement,
      usersConfigs?.[category]?.ui?.infoPlacement,
    );

    updateLockableValue(
      superaccountId,
      entity,
      primaryTeamEntity,
      primaryOfficeEntity,
      isTeamEntity,
      isOfficeEntity,
      merged[category]?.order,
      superaccountConfigs?.[category]?.order,
      accountConfigs?.[category]?.order,
      officeConfigs?.[category]?.order,
      teamConfigs?.[category]?.order,
      usersConfigs?.[category]?.order,
    );

    OrderByTypeCombiner.mutate({
      entity,
      combinedOBT:
        merged[category]?.orderByType ?? OrderByTypeAdapter.getOrderByType(merged[category]),
      superaccountOBT:
        superaccountConfigs?.[category]?.orderByType ??
        OrderByTypeAdapter.getOrderByType(superaccountConfigs?.[category]),
      accountOBT:
        accountConfigs?.[category]?.orderByType ??
        OrderByTypeAdapter.getOrderByType(accountConfigs?.[category]),
      officeOBT:
        officeConfigs?.[category]?.orderByType ??
        OrderByTypeAdapter.getOrderByType(officeConfigs?.[category]),
      teamOBT:
        teamConfigs?.[category]?.orderByType ??
        OrderByTypeAdapter.getOrderByType(teamConfigs?.[category]),
      usersOBT:
        usersConfigs?.[category]?.orderByType ??
        OrderByTypeAdapter.getOrderByType(usersConfigs?.[category]),
    });
  });

  return merged;
}

export class CustomizationCombiner {
  static combine(
    customizations: any,
    personalized: any,
    entity: number | null = null,
    useSpecialBranding: boolean = false,
    entityMode = EntityMode.READ,
  ): TInheritanceUserConfigs | null {
    const primaryEntity = CurrentUserService.getUser()?.primaryEntity as number;

    const mergedConfigs = CustomizationCombiner.toSingleConfig(
      customizations,
      primaryEntity,
      entity,
      useSpecialBranding,
      entityMode,
    );

    if (CustomizationCombiner.isConfigEmpty(mergedConfigs)) return null;

    // return CustomizationCombiner.addPersonalizedSlides(mergedConfigs, personalized);
    return mergedConfigs;
  }

  private static isConfigEmpty(config: any) {
    return !config || !Object.keys(config)?.length;
  }

  private static getEntityLevelConfigs(
    entityConfigs: any,
    entityId: number | null = null,
    entities?: TKvCoreEntity[],
  ) {
    const hasEntityId = entities?.some(entity => entity.id === entityId);

    if (!hasEntityId) return null;

    return !entityConfigs[`e-${entityId}`]?.data
      ? null
      : cloneDeep(entityConfigs[`e-${entityId}`]?.data);
  }

  private static toSingleConfig(
    entityConfigs: any, // customizations
    primaryEntity: number,
    entity: number | null = null,
    useSpecialBranding: boolean = false,
    entityMode = EntityMode.READ,
  ): TInheritanceUserConfigs | null {
    if (!entityConfigs) return null;

    const currentUser = CurrentUserService.getUser();

    const userOffices = currentUser?.offices;
    const userTeams = currentUser?.teams;
    const isOfficeEntity = EntityType.isOfficeEntity(entity);
    const isTeamEntity = EntityType.isTeamEntity(entity);

    const user = !entityConfigs['e-null']?.data ? null : cloneDeep(entityConfigs['e-null']?.data);
    const account = !entityConfigs['e-0']?.data ? null : cloneDeep(entityConfigs['e-0']?.data);

    const office = CustomizationCombiner.getEntityLevelConfigs(
      entityConfigs,
      isOfficeEntity ? entity : currentUser?.officePrimaryEntity,
      userOffices,
    );

    const team = CustomizationCombiner.getEntityLevelConfigs(
      entityConfigs,
      isTeamEntity ? entity : currentUser?.teamsPrimaryEntity,
      userTeams,
    );

    const superaccountId = getSupperaccountId({ entity, entityMode, useSpecialBranding });
    const superaccountIdKey = 'e-' + superaccountId;

    const superaccount = !entityConfigs[superaccountIdKey]?.data
      ? null
      : cloneDeep(entityConfigs[superaccountIdKey]?.data);

    if (!user && !account && !office && !superaccount) return null;

    UserConfigStructureMigrator.migrate(user);
    UserConfigStructureMigrator.migrate(team);
    UserConfigStructureMigrator.migrate(office);
    UserConfigStructureMigrator.migrate(account);

    const teamPrimaryEntity = currentUser?.teamsPrimaryEntity || 0;
    const officePrimaryEntity = currentUser?.officePrimaryEntity || 0;

    if (entity === -1 || entity === -2) {
      return {
        level: CustomizationCombiner.getInheritanceLevel(superaccount, null, null, null, null),
        data: superaccount,
      };
    }

    if (entity === 0) {
      const data = applyTheRightValues(
        superaccountId,
        entity,
        teamPrimaryEntity,
        officePrimaryEntity,
        isTeamEntity,
        isOfficeEntity,
        merge(cloneDeep(superaccount) ?? {}, cloneDeep(account) ?? {}, null, null),
        superaccount ?? {},
        account ?? {},
      );

      return {
        level: CustomizationCombiner.getInheritanceLevel(superaccount, account, null, null, null),
        data,
      };
    }

    if (entity !== null && entity > 0 && isOfficeEntity) {
      if (!office && !account && !superaccount) return null;

      const data = applyTheRightValues(
        superaccountId,
        entity,
        teamPrimaryEntity,
        officePrimaryEntity,
        isTeamEntity,
        isOfficeEntity,
        merge(cloneDeep(superaccount) ?? {}, cloneDeep(account) ?? {}, cloneDeep(office) ?? {}),
        superaccount ?? {},
        account ?? {},
        office ?? {},
      );

      return {
        level: CustomizationCombiner.getInheritanceLevel(superaccount, account, office, null, null),
        data,
      };
    }

    if (entity !== null && entity > 0 && isTeamEntity) {
      if (!team && !office && !account && !superaccount) return null;

      const data = applyTheRightValues(
        superaccountId,
        entity,
        teamPrimaryEntity,
        officePrimaryEntity,
        isTeamEntity,
        isOfficeEntity,
        merge(
          cloneDeep(superaccount) ?? {},
          cloneDeep(account) ?? {},
          cloneDeep(office) ?? {},
          cloneDeep(team) ?? {},
        ),
        superaccount ?? {},
        account ?? {},
        office ?? {},
        team ?? {},
      );

      return {
        level: CustomizationCombiner.getInheritanceLevel(superaccount, account, office, team, null),
        data,
      };
    }

    const data = applyTheRightValues(
      superaccountId,
      entity,
      teamPrimaryEntity,
      officePrimaryEntity,
      isTeamEntity,
      isOfficeEntity,
      merge(
        cloneDeep(superaccount) ?? {},
        cloneDeep(account ?? {}),
        cloneDeep(office ?? {}),
        cloneDeep(team ?? {}),
        cloneDeep(user ?? {}),
      ),
      superaccount ?? {},
      account ?? {},
      office ?? {},
      team ?? {},
      user ?? {},
    );

    return {
      level: CustomizationCombiner.getInheritanceLevel(superaccount, account, office, team, user),
      data,
    };
  }

  private static getInheritanceLevel(
    superaccountConfigs: TUserConfigs | null,
    accountConfigs: TUserConfigs | null,
    officeConfigs: TUserConfigs | null,
    teamConfigs: TUserConfigs | null,
    usersConfigs: TUserConfigs | null,
  ) {
    if (usersConfigs) return InheritanceLevel.AGENT;

    if (teamConfigs) return InheritanceLevel.TEAM;

    if (officeConfigs) return InheritanceLevel.OFFICE;

    if (accountConfigs) return InheritanceLevel.ACCOUNT;

    if (superaccountConfigs) return InheritanceLevel.SUPERACCOUNT;

    return InheritanceLevel.AGENT;
  }

  private static addPersonalizedSlides(
    customizationSettings: TUserConfigs | null,
    personalizedSlides: any,
  ) {
    if (!customizationSettings || !personalizedSlides) return null;

    const customizationSettingsCopy = JSON.parse(JSON.stringify(customizationSettings));
    const personalizedSlidesKeys = Object.keys(personalizedSlides);

    Object.keys(customizationSettingsCopy).forEach(category => {
      const slides = customizationSettingsCopy[
        category as TCategory
      ].slides.sections.whyIAmTheRightFit.slides.map((s: TSlide) => {
        if (!personalizedSlidesKeys.includes(s.id)) return s;

        s.data = personalizedSlides[s.id].data;
        s.includeSlide = personalizedSlides[s.id].includeSlide;

        return s;
      });

      customizationSettingsCopy[category as TCategory].slides.sections.whyIAmTheRightFit.slides =
        slides;
    });

    return customizationSettingsCopy;
  }
}
