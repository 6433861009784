import styled from 'styled-components';

export const ListingDetailsContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

export const TitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 0 20px 0',
});

export const Title = styled('h3')(({ theme }) => ({
  fontSize: 18,
  color: theme.colors.gray[400],
  textTransform: 'uppercase',
}));

export const GalleryAndNotes = styled('div')({
  display: 'flex',
  marginTop: 25,
  borderTop: '1px solid #efefef',
  paddingTop: 16,
  paddingBottom: 16,
});

export const Wrapper = styled('div')({
  width: '50%',
});

export const Description = styled('p')({
  fontSize: 16,
  color: '#444',
  lineHeight: '26px',
  paddingTop: '20px',
});
