import React from 'react';
import styled from 'styled-components';

import bg from './bg.png';
import slideGraphics from './slideGraphics';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background: url(${bg}) bottom center;
  background-size: cover;
  position: relative !important;
`;

const Image = styled.img`
  top: 20px;
  position: absolute;
  right: 70px;
  height: 500px;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 45px;
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  text-align: left;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const Description = styled.p`
  position: relative;
  z-index: 5;
  line-height: 29px;
  font-size: 18px;
  padding-left: 45px;
  text-align: left;
  width: 525px;
  letter-spacing: ${() => useUiConfigBodyLetterSpacing()};
`;

export const ImportanceOfStagingTraditional: React.FC = () => {
  const primary = useUiConfigColorsPrimary();

  const img = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  const headingStyles = {
    fontSize: 30,
    fontWeight: 900,
    color: '#444',
  };

  const textStyles = {
    fontSize: 17,
    fontWeight: 400,
    color: '#444',
  };

  return (
    <ImageWrapper>
      <div style={{ position: 'absolute' }}>
        <Heading>Staging</Heading>
        <Description>
          Home staging is the preparation of a private residence for sale in the real estate
          marketplace.
          <br />
          <br />
          The goal of staging is to make a home appealing to the highest number of potential buyers,
          thereby selling a property more swiftly and for more money.
        </Description>
      </div>

      <div>
        <Image src={img} />
        <TraditionalSlideCircularCard
          heading={'83%'}
          text={
            'of buyers’ agents said staging a home made it easier for a buyer to visualize the property as a future home.'
          }
          position={{ bottom: '35%', left: 543 }}
          textStyles={textStyles}
          headingStyles={headingStyles}
          size={279}
        />
        <TraditionalSlideCircularCard
          heading={'25%'}
          text={
            'of buyer’s agents said that staging a home increased the dollar value offered between one and five percent, compared to other similar homes on the market that are not staged.'
          }
          position={{ bottom: 403, left: 947 }}
          textStyles={textStyles}
          headingStyles={headingStyles}
          size={330}
        />
        <TraditionalSlideCircularCard
          heading={'40%'}
          text={
            'of buyers’ agents cited that home staging had an effect on most buyers’ view of the home.'
          }
          position={{ bottom: 200, left: 1234 }}
          textStyles={textStyles}
          headingStyles={headingStyles}
          size={275}
        />
      </div>
    </ImageWrapper>
  );
};
