import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';

import { Container } from '../TextLayout.styles';

export const TextGammaPdf: React.FC = () => {
  const slide = useSlide();
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container isPdf>
        <SlideTextBlock
          text={slide.data?.text}
          headline={slide.data?.headline}
          containerStyles={{ marginBottom: 90, height: 'auto' }}
        />
        <SlideTextBlock
          text={slide.data?.text2}
          headline={slide.data?.headline2}
          containerStyles={{ height: 'auto' }}
        />
      </Container>
    </PdfFrame>
  );
};
