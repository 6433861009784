import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { GreetingVideo } from './GreetingVideo.component';

const id = 'greetingVideo';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Greeting Video',
  frame: false,
  excludeFromConfig: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: GreetingVideo,
  },
};

export type TGreetingVideo = typeof id;
export { config, mappings };
