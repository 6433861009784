import React from 'react';
import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

import { Container } from './FourImagesAlphaLayout.styles';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';

interface Props {
  hideHeading?: boolean;
}

export const FourImagesAlphaForm: React.FC<Props> = ({ hideHeading }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  if (!selectedSlide) return null;

  return (
    <Container style={{ gridTemplateRows: hideHeading ? '1fr 1fr' : 'auto 1fr 1fr' }}>
      {!hideHeading && (
        <SlideTextBlock
          headline={selectedSlide.data?.headline}
          isHeadlineCenter
          onChangeHeadline={onChangeHeadline}
          isEditable
          containerStyles={{ gridColumn: '1 / -1' }}
        />
      )}
      <UploadImage
        slideDataConfigPropertyName={'image'}
        image={selectedSlide.data?.image}
        imageSizeSuggestion={hideHeading ? '736x325' : '736x287'}
      />
      <UploadImage
        slideDataConfigPropertyName={'image2'}
        image={selectedSlide.data?.image2}
        imageSizeSuggestion={hideHeading ? '736x325' : '736x287'}
      />
      <UploadImage
        slideDataConfigPropertyName={'image3'}
        image={selectedSlide.data?.image3}
        imageSizeSuggestion={hideHeading ? '736x325' : '736x287'}
      />
      <UploadImage
        slideDataConfigPropertyName={'image4'}
        image={selectedSlide.data?.image4}
        imageSizeSuggestion={hideHeading ? '736x325' : '736x287'}
      />
    </Container>
  );
};

export default FourImagesAlphaForm;
