import React from 'react';
import { IconComponentProps } from './Icon.types';
import * as I from './Icon.styles';

export const Icon = React.forwardRef(
  ({ iconName, ...props }: IconComponentProps, ref: React.Ref<SVGSVGElement>) => {
    const Component = I[iconName];

    return <Component ref={ref} {...props} />;
  },
);

Icon.displayName = 'IconEl';
