export const heading = 'Where do buyers get their information';

export const paragraphs = [
  `A home's value is directly related to what a buyer is willing to pay. Understanding where buyers get their information helps form a perspective on what a buyer is expecting to pay. The following four areas are the most common sources:
`,
];

export const getListItems = (isCanadaUser: boolean) => {
  return [
    {
      title: 'Professionals',
      text: `Real Estate Agents, Financial Planners, and other professionals can balance and weigh the market information in conjunction with each buyer's situation.`,
    },
    {
      title: 'Online Sources',
      text: isCanadaUser
        ? 'The first step the average buyer takes in their home buying process is to look online at properties for sale. '
        : `For 44% of recent buyers, this was their first step in the home buying process.`,
    },
    {
      title: 'Sphere of Influence',
      text: `It's natural to lean on the advice and experience of friends, family, and neighbors.`,
    },
    {
      title: 'Personal Experience',
      text: `It is easy to form an opinion on what something might be worth based on firsthand knowledge or perceived value.`,
    },
  ];
};
