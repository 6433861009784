import React from 'react';

import styles from './PropertyInfoSection.module.scss';

export const PropertyInfoSection = ({ title, description, hint = '', className = '' }) => (
  <div className={`${styles.propertyInfoSection} ${className}`}>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.description}>{description}</p>
    <p className={styles.description}>{hint}</p>
  </div>
);
