import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { YearBuiltEdit } from './YearBuiltEdit';
import { YearBuiltRangeForm } from './YearBuiltRangeForm';
import { SearchCombiner } from '../../../../SearchCombiner';
import { SearchValidator } from '../../../../SearchValidator';
import { CriteriaOptionsSubtitle } from '../components/CriteriaOptionsSubtitle/CriteriaOptionsSubtitle';

import { CRITERIAS } from '../../../Criterias';
import { useCriteriaIsOpen } from '../../../CriteriaIsOpenProvider';
import { CriteriaAccordion } from '../components/CriteriaAccordion';
import { ActionsAndStatus } from '../components/ActionsAndStatus';

const info =
  'Represents the construction year as documented on the Title record. The default range is set to include all years, ensuring no restrictions on comparable matches.';

const searchCriteriaToState = searchCriteria => {
  const { yearBuilt, yearBuiltFrom, yearBuiltTo, yearBuiltRangeSet } = searchCriteria;
  return {
    yearBuilt,
    yearBuiltFrom: isNaN(yearBuiltFrom) ? yearBuilt ?? 1920 : yearBuiltFrom,
    yearBuiltTo: isNaN(yearBuiltTo) ? new Date().getFullYear() : yearBuiltTo,
    yearBuiltRangeSet: Boolean(yearBuiltRangeSet),
    defaults: {
      yearBuiltFrom: isNaN(yearBuiltFrom) ? yearBuilt ?? 1920 : yearBuiltFrom,
      yearBuiltTo: isNaN(yearBuiltTo) ? new Date().getFullYear() : yearBuiltTo,
      subjectValue: yearBuilt,
    },
  };
};

const selector = state => {
  const { search } = state;
  return {
    searchCriteria: search.searchCriteria,
  };
};

export const YearBuilt = ({ className, onChange }) => {
  const { searchCriteria } = useSelector(selector);
  const [state, setState] = useState(searchCriteriaToState(searchCriteria));
  const [showForm, setShowForm] = useState(false);
  const [saved, setSaved] = useState(false);
  const [isOpen] = useCriteriaIsOpen(CRITERIAS.YEAR_BUILT);
  const { yearBuiltFrom, yearBuiltTo, yearBuilt, yearBuiltRangeSet, defaults } = state;

  const [subjectValue, setSubjectValue] = useState(yearBuilt ?? yearBuiltFrom);

  const validate = useCallback(
    () =>
      !isNaN(yearBuiltFrom) &&
      yearBuiltFrom &&
      !isNaN(yearBuiltTo) &&
      yearBuiltTo &&
      !isNaN(yearBuilt) &&
      parseInt(yearBuiltFrom) <= parseInt(yearBuiltTo) &&
      yearBuiltTo <= new Date().getFullYear(),
    [yearBuiltFrom, yearBuiltTo, yearBuilt],
  );

  const onCombine = useCallback(
    () => ({
      yearBuiltFrom,
      yearBuiltTo,
      yearBuilt,
      yearBuiltRangeSet,
    }),
    [yearBuiltFrom, yearBuiltTo, yearBuilt, yearBuiltRangeSet],
  );

  useEffect(() => {
    SearchValidator.subscribe('yearBuilt', validate);
    SearchCombiner.add('yearBuilt', onCombine);

    if (saved) {
      onChange(SearchCombiner.combine());
      setSaved(false);
    }

    return () => {
      SearchValidator.unsubscribe('yearBuilt');
      SearchCombiner.remove('yearBuilt');
    };
  }, [validate, onCombine, onChange, saved]);

  const onFieldChange = field => value => {
    setState(prev => ({ ...prev, [field]: value }));
  };

  const onFormCancel = () => {
    reset();
    setShowForm(false);
  };

  const onFormConfirm = () => {
    if (!yearBuiltFrom || !yearBuiltTo) {
      return;
    }
    setState(prev => ({ ...prev, yearBuiltRangeSet: true }));
    setShowForm(false);
    setSaved(true);
  };

  const onYearBuiltChange = val => {
    setSubjectValue(val);
    setState(prev => ({ ...prev, yearBuiltFrom: val }));
    setSaved(true);
  };

  const onChangeYearRangeClick = () => {
    setShowForm(true);
  };

  const onResetYearRangeClick = () => {
    setState(prev => ({
      ...searchCriteriaToState(searchCriteria),
      yearBuiltRangeSet: false,
    }));
    setSaved(true);
  };

  const reset = () => {
    setState(searchCriteriaToState(searchCriteria));
    setSaved(true);
  };

  return (
    <CriteriaAccordion info={info} title='YEAR BUILT' isOpen={isOpen}>
      <YearBuiltEdit
        subject={subjectValue}
        onSubjectChange={onYearBuiltChange}
        defaultSubjectValue={defaults.subjectValue}
      />

      {showForm || yearBuiltRangeSet ? <CriteriaOptionsSubtitle /> : null}

      {showForm ? (
        <YearBuiltRangeForm
          values={state}
          showForm={showForm}
          onFieldChange={onFieldChange}
          onCancel={onFormCancel}
          onConfirm={onFormConfirm}
          onChangeYearRangeClick={onChangeYearRangeClick}
        />
      ) : (
        <ActionsAndStatus
          isRangeSet={yearBuiltRangeSet}
          valueFrom={yearBuiltFrom}
          valueTo={yearBuiltTo}
          onChangeRange={onChangeYearRangeClick}
          onResetRange={onResetYearRangeClick}
          changeBtnText=' Change Year Range (optional)'
          resetBtnText='Clear Year Built Filter'
          rangeName='Year built'
        />
      )}
    </CriteriaAccordion>
  );
};
