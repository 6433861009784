import React from 'react';
import { CATEGORIES } from '../../../../../../constants/categories';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import {
  Container,
  LeftCol,
  RightCol,
  ImageWrapper,
  ImagePreview,
} from './TwoImagesAlphaLayout.styles';
import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { ImageProxy } from '../../../../services/ImageProxy';

interface Props {
  reverse?: boolean;
  vertical?: boolean;
}

const TwoImagesLayoutAlphaPdf: React.FC<Props> = ({ reverse, vertical }) => {
  const { category } = useCategory();
  const slide = useSlide();

  const styles: React.CSSProperties = {};

  if (vertical) {
    styles.width = '100%';
    styles.flex = 1;
    styles.flexDirection = 'row';
  }
  let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'row';
  if (vertical) flexDirection = 'column';
  if (vertical && reverse) flexDirection = 'column-reverse';
  if (!vertical && reverse) flexDirection = 'row-reverse';

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container
        isPdf={vertical}
        style={{
          flexDirection,
        }}
      >
        <LeftCol
          isPdf
          style={{ ...styles, marginBottom: reverse ? 0 : 32, height: !vertical ? '100%' : '48%' }}
        >
          <ImageWrapper
            isPdf
            style={{
              height: !vertical ? '41%' : '100%',
              marginRight: vertical ? 32 : 0,
            }}
          >
            <ImagePreview src={ImageProxy.optimize(slide.data?.image, { width: 750 })} />
          </ImageWrapper>
          <ImageWrapper
            isPdf
            style={{
              height: !vertical ? '53%' : '100%',
            }}
          >
            <ImagePreview src={ImageProxy.optimize(slide.data?.image2, { width: 750 })} />
          </ImageWrapper>
        </LeftCol>
        <RightCol
          isPdf
          style={{ ...styles, marginBottom: !reverse ? 0 : 32, height: !vertical ? '100%' : '48%' }}
        >
          <SlideTextBlock
            headline={slide.data?.headline}
            text={slide.data?.text}
            isEditable={false}
          />
        </RightCol>
      </Container>
    </PdfFrame>
  );
};

export default TwoImagesLayoutAlphaPdf;
