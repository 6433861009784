import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import slideGraphics from '../../assets/slideGraphics';
import slideGraphics2 from '../../assets/slideGraphics2';
import { graphItems, listItems } from '../../data';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const Container = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: '4% 5%',
});

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
  border: `1px solid #ddd`,
  borderRadius: 5,
  padding: '3%',
  position: 'relative',
  '&:not(:last-child)': {
    marginBottom: 20,
  },
});

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 40px;
  margin-bottom: 100px;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  left: 0;
  width: 50%;
  line-height: 1.3;
  z-index: 5;
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-end',
});

const CardTitle = styled('h2')(({ theme }) => ({
  color: '#444',
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  marginBottom: 5,
  fontSize: 20,
  fontWeight: 800,
}));

const Text = styled('p')(({ theme }) => ({
  fontSize: 19,
  color: '#000',
  lineHeight: 1.6,
  width: 280,
}));

interface El {
  src: string;
  color: string;
}

const Graphics = styled('img')<El>(({ src, color }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  height: '81%',
  width: 'auto',
  zIndex: 2,
  background: `conic-gradient(from 0deg at 50% 50%, ${ColorOpacityApplier.hex(
    color,
    '0.21',
  )} 0deg, ${color} 360deg), #FFFFFF`,
  borderRadius: '50%',
}));

const Icons = styled('img')({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  height: '81%',
  width: 'auto',
  zIndex: 2,
});

const TextItem = styled('h3')(({ theme }) => ({
  color: '#fff',
  fontWeight: 800,
  position: 'absolute',
  textTransform: 'uppercase',
  zIndex: 5,
  fontSize: 18,
  textAlign: 'center',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing('0.05em'),
}));

const HomeSellingProcessModern: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const secondary = useUiConfigColorsPrimary();
  const image = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);
  const image2 = React.useMemo(() => svgStringToDataUrl(slideGraphics2, primary), [primary]);

  return (
    <Container>
      <Row>
        <Heading>
          The Home
          <br />
          Selling Process
        </Heading>
        <CardContainer>
          <CardTitle>{listItems[3].title}</CardTitle>
          <Text>{listItems[3].text}</Text>
        </CardContainer>
        <CardContainer style={{ marginBottom: 10 }}>
          <CardTitle>{listItems[0].title}</CardTitle>
          <Text>{listItems[0].text}</Text>
        </CardContainer>
      </Row>
      <Row>
        <CardContainer style={{ marginBottom: 20 }}>
          <CardTitle>{listItems[2].title}</CardTitle>
          <Text>{listItems[2].text}</Text>
        </CardContainer>
        <CardContainer>
          <CardTitle>{listItems[1].title}</CardTitle>
          <Text>{listItems[1].text}</Text>
        </CardContainer>
      </Row>
      <Graphics src={image} color={primary} />
      <Icons src={image2} style={{ position: 'absolute' }} />
      <TextItem
        style={{ top: 80, left: '56.4%', fontSize: 11, fontWeight: 600, letterSpacing: '0' }}
      >
        Start
      </TextItem>
      <TextItem
        style={{
          top: '49%',
          left: '44.8%',
          fontSize: 20,
          color: primary,
          fontWeight: 800,
          letterSpacing: '0.1em',
          lineHeight: 1.2,
        }}
      >
        Home
        <br />
        successfully
        <br />
        sold
      </TextItem>
      <TextItem style={{ top: 155, left: '42%' }}>{graphItems[7].shortText}</TextItem>
      <TextItem style={{ top: 140, left: '50.8%', width: 162 }}>{graphItems[0].longText}</TextItem>
      <TextItem style={{ top: 280, left: '59.5%', width: 130 }}>{graphItems[1].shortText}</TextItem>
      <TextItem style={{ top: 450, left: '58.3%', width: 171 }}>{graphItems[2].longText}</TextItem>

      <TextItem style={{ top: 575, left: '50.7%' }}>{graphItems[3].shortText}</TextItem>
      <TextItem style={{ top: 560, left: '38.7%', width: 158 }}>{graphItems[4].shortText}</TextItem>
      <TextItem style={{ top: 425, left: '33.2%', width: 127 }}>{graphItems[5].shortText}</TextItem>
      <TextItem style={{ top: 265, left: '33.5%', width: 130 }}>{graphItems[6].shortText}</TextItem>
    </Container>
  );
};

export default HomeSellingProcessModern;
