import { IconName } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, Modifier } from 'draft-js';
import React from 'react';
import styled from 'styled-components';
import { StyleButton } from './controls.styles';

const TextAlignmentWrapper = styled('div')({
  display: 'flex',
  margin: '0 20px',
});

type TTextAlignment = 'left' | 'right' | 'center';

export const TextAlignmentControls: React.FC = (props: any) => {
  const onClick = (style: any) => {
    const contentState = Modifier.applyInlineStyle(
      props.editorState.getCurrentContent(),
      props.editorState.getSelection(),
      style,
    );

    EditorState.push(props.editorState, contentState, 'change-inline-style');
    props.onChange(style);
  };

  return (
    <TextAlignmentWrapper style={{ margin: props.toolbarSize === 'large' ? '0 20px' : '0 10px' }}>
      {props.config.options.map((option: TTextAlignment) => {
        return (
          <StyleButton
            isActive={props.currentState.textAlignment === option}
            onClick={() => onClick(option)}
            key={option}
            toolbarSize={props.toolbarSize}
          >
            <FontAwesomeIcon icon={['fas', `align-${option}` as IconName]} />
          </StyleButton>
        );
      })}
    </TextAlignmentWrapper>
  );
};
