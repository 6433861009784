import color1 from './assets/color1.png';
import color2 from './assets/color2.png';
import color3 from './assets/color3.png';
import color4 from './assets/color4.png';
import color5 from './assets/color5.png';
import color6 from './assets/color6.png';
import color7 from './assets/color7.png';
import color8 from './assets/color8.png';
import color9 from './assets/color9.png';
import color10 from './assets/color10.png';
import color11 from './assets/color11.png';
import color12 from './assets/color12.png';
import color13 from './assets/color13.png';

export const competitionPinConfigs = [
  { label: '0-2 Wks', image: color1 },
  { label: '2-4 Wks', image: color2 },
  { label: '4-6 Wks', image: color3 },
  { label: '6-8 Wks', image: color4 },
  { label: '8-10 Wks', image: color5 },
  { label: '10-12 Wks', image: color6 },
  { label: '12-14 Wks', image: color7 },
  { label: '14-16 Wks', image: color8 },
  { label: '16-18 Wks', image: color9 },
  { label: '18-20 Wks', image: color10 },
  { label: '20-22 Wks', image: color11 },
  { label: '22-24 Wks', image: color12 },
  { label: '24+ Wks', image: color13 },
];

export const getCompetitionPin = (daysOnMarket: number) => {
  for (let pin of competitionPinConfigs) {
    const from = parseInt(pin.label.split('-')[0]) * 7;
    const to = parseInt(pin.label.split('-')[1]) * 7;

    if (from < daysOnMarket && daysOnMarket <= to) {
      return pin.image;
    }
  }

  return color13;
};
