import { TSlide } from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { WhyPricingCorrectlyIsImportantLuxury } from './web/luxury/WhyPricingCorrectlyIsImportantLuxury';
import { WhyPricingCorrectlyIsImportantModern } from './web/modern/WhyPricingCorrectlyIsImportantModern';
import { WhyPricingCorrectlyIsImportantTraditional } from './web/traditional/WhyPricingCorrectlyIsImportantTraditional';
import WhyPricingCorrectlyIsImportantModernPDF from './pdf/modern/WhyPricingCorrectlyIsImportantModernPDF';
import WhyPricingCorrectlyIsImportantLuxuryPDF from './pdf/luxury/WhyPricingCorrectlyIsImportantLuxuryPDF';
import WhyPricingCorrectlyIsImportantTraditionalPDF from './pdf/traditional/WhyPricingCorrectlyIsImportantTraditionalPDF';

const id = 'whyPricingCorrectlyIsImportant';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Why Pricing Correctly Is Important',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: WhyPricingCorrectlyIsImportantTraditional,
    [CATEGORIES.MODERN]: WhyPricingCorrectlyIsImportantModern,
    [CATEGORIES.LUXURY]: WhyPricingCorrectlyIsImportantLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: WhyPricingCorrectlyIsImportantTraditionalPDF,
    [CATEGORIES.MODERN]: WhyPricingCorrectlyIsImportantModernPDF,
    [CATEGORIES.LUXURY]: WhyPricingCorrectlyIsImportantLuxuryPDF,
  },
};

export type TWhyPricingCorrectlyIsImportant = typeof id;
export { config, mappings };
