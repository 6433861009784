import { useAtom, useSetAtom } from 'jotai';
import { Select } from '../../../../../../components/Common';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { radiusAtom } from '../../state/radiusAtom';
import { MapRadiusOption } from '../../../../../../types';
import { mapModeAtom } from '../../state/mapModeAtom';
import styled from 'styled-components';
import { searchAreaValueAtom } from '../../state/searchAreaValueAtom';
import { areaTypeAtom } from '../../state/areaTypeAtom';
import { areaDetailsAtom } from '../../state/areaDetailsAtom';

const radiusOptions = [
  { label: '0.25', value: 0.25 },
  { label: '0.5', value: 0.5 },
  { label: '0.75', value: 0.75 },
  { label: '1', value: 1 },
  { label: '1.5', value: 1.5 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
];

const Wrapper = styled.div<{ isActive?: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  width: 27%;
  min-width: 162px;
`;

export const RadiusSelect = () => {
  const { isCanadaUser } = useUser();
  const unit = isCanadaUser ? ' km' : 'Mile';

  const [radius, setRadius] = useAtom(radiusAtom);
  const [mapMode, setMapMode] = useAtom(mapModeAtom);
  const setAreaType = useSetAtom(areaTypeAtom);
  const setSearchValue = useSetAtom(searchAreaValueAtom);
  const setAreaDetails = useSetAtom(areaDetailsAtom);

  const options = radiusOptions.map(option => {
    return {
      ...option,
      label: `Within ${option.label} ${unit} Radius`,
    };
  });

  return (
    <Wrapper
      onClick={() => {
        setMapMode(null);
        setSearchValue('');
        setAreaType(null);
        setAreaDetails(null);
      }}
      isActive={mapMode === null || mapMode === 'marker'}
    >
      <Select
        onChange={value => {
          setRadius(value as MapRadiusOption);
        }}
        styles={{
          minWidth: '100px',
          menuListPadding: '10px',
        }}
        options={options}
        menuPlacement='auto'
        selectedOption={radius ? radius : { label: '1', value: 1 }}
      />
    </Wrapper>
  );
};
