import { PropertyGroups, ReportProperty } from '../../../types';

export function extractFilteredProperties(propertyGroups: PropertyGroups): ReportProperty[] {
  if (!propertyGroups) return [];

  const properties = (Object.values(propertyGroups) || [])
    ?.flatMap(p => p)
    ?.filter(p => Boolean(p))
    ?.map(
      p =>
        ({
          lat: p?.coordinates.latitude,
          lng: p?.coordinates.longitude,
          address: p?.address.deliveryLine,
          excluded: p?.excluded,
          status: p?.status,
          property: p,
        }) as ReportProperty,
    );

  return properties;
}
