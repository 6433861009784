import React from 'react';

import classes from './YearBuilt.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../../../../../../../../components/Common/V2/Button/Button';
import styled from 'styled-components';

interface Props {
  isRangeSet?: boolean;
  valueFrom?: string;
  valueTo?: string;
  onResetRange: () => void;
  onChangeRange: () => void;
  changeBtnText: string;
  resetBtnText: string;
  rangeName: string;
}

export const ActionsAndStatus = ({
  isRangeSet = false,
  valueFrom,
  valueTo,
  onResetRange,
  onChangeRange,
  changeBtnText,
  resetBtnText,
  rangeName,
}: Props) => {
  if (isRangeSet)
    return (
      <StatusContainer>
        <StatusText>
          {rangeName} is from <b>{valueFrom}</b> to <b>{valueTo}</b>
        </StatusText>
        <Button variant='secondary' onClick={onResetRange}>
          {resetBtnText}
        </Button>
      </StatusContainer>
    );

  return (
    <Button
      variant='secondary'
      onClick={onChangeRange}
      prefixIcon={<FontAwesomeIcon icon={['fal', 'ruler']} />}
      style={{ width: '100%', marginTop: '10px' }}
    >
      {changeBtnText}
    </Button>
  );
};

const StatusContainer = styled.div`
  margin-top: 10px;
`;

const StatusText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  margin-bottom: 20px;
  font-weight: 500;
`;
