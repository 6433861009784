import { FC, useEffect } from 'react';

import { EntityMode, TUser } from '../../../../../../types';

import { CATEGORIES } from '../../../../../../constants/categories';

import { Timestamp } from '../../../../../../services/Timestamp';

import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { useUserCustomizations } from '../../../../../../hooks/useUserCategoryCustomizations';
import { useRawCustomizations } from '../../../../../../data/customizations/useRawCustomizations';

import { withProviders } from '../../../../../../hoc/withProviders';
import { PresentationTypeProvider } from '../../../../../../providers/providers/PresentationTypeProvider';
import { CustomizationOrderProvider } from '../../../../../../providers/providers/CustomizationOrderProvider';
import { CoreSlidesConfigProvider } from '../../../../../../providers/providers/CoreSlidesConfigProvider';
import {
  CreatedAtProvider,
  useCreatedAt,
} from '../../../../../../providers/providers/CreatedAtProvider';
import {
  CategoryProvider,
  useCategory,
} from '../../../../../../providers/providers/CategoryProvider';

import { Categories } from './Categories';
import { Container, Wrapper } from '../sections.styles';
import Loader from '../../../../../../components/Loader/Loader';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';
import { EntityProvider, useEntity } from '../../../../providers/EntityProvider';
import { ProgressProvider, useProgressState } from '../../../../ProgressProvider';
import { CustomizationModalComponent } from './CustomizationModal/CustomizationModal.component';

export const CustomizePresentationSection: FC = withProviders(
  [CategoryProvider, { initiallyUndefined: true }],
  ProgressProvider,
  PresentationTypeProvider,
  [EntityProvider, { initMode: EntityMode.WRITE }],
  CoreSlidesConfigProvider,
  CustomizationOrderProvider,
  CreatedAtProvider,
)(() => {
  const user = useUser() as TUser;
  const { category } = useCategory();
  const { setProgress } = useProgressState();
  const { entity, entityMode } = useEntity();
  const { setCreatedAt } = useCreatedAt();

  const { isLoading, data: userConfigs } = useUserCustomizations(
    user.hash as string,
    entity,
    user.useSpecialBranding,
    entityMode,
  );

  const { data: customizationData } = useRawCustomizations(user?.hash);

  useEffect(() => {
    if (!userConfigs || !user) return;

    setCreatedAt(Timestamp.now());

    const data = customizationData?.['e-null']?.data;
    const progressData: Record<string, string[]> = {};

    Object.values(CATEGORIES).forEach(category => {
      progressData[category] = [];
      if (!category || !data?.[category]?.ui) return;
      progressData[category] = Object.keys(data[category].ui);
    });

    setProgress(progressData);
  }, [userConfigs, entity, user, setCreatedAt, setProgress, customizationData]);

  return (
    <Container>
      <Wrapper hasMargin>
        <SectionHeader
          title='Customize Presentation'
          subtitle='Create your default layout and order for each presentation type you’d like to use.'
          icon='sidebar'
        />
      </Wrapper>
      {isLoading ? (
        <Loader centered size='2x' />
      ) : (
        <Wrapper>
          <Categories />
        </Wrapper>
      )}

      {!isLoading && category && <CustomizationModalComponent />}
    </Container>
  );
});
