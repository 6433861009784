import { useAtomValue } from 'jotai';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { IconName } from '../../../../../../components/Common/Icon/Icon.types';
import { Button } from '../../../../../../components/Common';
import { isPresentationViewAtom } from '../../../../state/isPresentationViewAtom';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';

interface Props {
  icon: IconName;
  text: string;
  tooltipText: string;
  isActive: boolean;
  onClick?: () => void;
}

export const ActionButton = ({ icon, text, tooltipText, isActive, onClick }: Props) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primaryColor = useUiConfigColorsPrimary();
  const theme = useTheme();

  const color = isPresentationView ? primaryColor : theme.colors.v2.primary;

  return (
    <div data-tip={tooltipText}>
      <Button
        btnstyle='option'
        onClick={onClick}
        icon={{
          name: icon,
          fill: isActive ? '#fff' : '#444',
          style: { marginRight: 5 },
        }}
        style={{
          height: 35,
          padding: 10,
          fontSize: 14,
          fontWeight: 500,
          letterSpacing: -0.385,
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
          ...(isActive ? { backgroundColor: color, color: '#fff', fontWeight: 700 } : {}),
        }}
      >
        {text}
      </Button>
      <ReactTooltip place='bottom' type='dark' effect='solid' />
    </div>
  );
};
