import styled from 'styled-components';

import { Typography as Text } from '../../../../Common';
import { useUiConfigFontsHeader } from '../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')(({ theme }) => ({
  width: '33.333%',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: 120,
  paddingBottom: theme.spacing(2),
  zIndex: 10,
  cursor: 'pointer',
}));

export const Title = styled(Text)(() => ({
  color: '#141414',
  fontWeight: 400,
  fontSize: 20,
  fontFamily: useUiConfigFontsHeader() ?? 'Arial',
  lineHeight: 1.35,
}));

export const Section = styled(Text)(() => ({
  color: '#000',
  fontWeight: 700,
  fontSize: 14,
  fontFamily: useUiConfigFontsHeader() ?? 'Arial',
}));
