import React from 'react';
import styled from 'styled-components';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

export const TextBeta: React.FC = () => {
  const slide = useSlide();

  return (
    <SlideTextBlock
      text={slide.data?.text}
      headline={slide.data?.headline}
      isHeadlineCenter
      containerStyles={{ padding: '2%', textAlign: 'center' }}
      textAreaStyles={{ textAlign: 'center', width: '53%', margin: '0 auto' }}
    />
  );
};
