import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

import { Container } from '../TextLayout.styles';

export const TextDelta: React.FC = () => {
  const slide = useSlide();

  return (
    <Container>
      <SlideTextBlock
        text={slide.data?.text}
        headline={slide.data?.headline}
        containerStyles={{ marginBottom: 90, height: 'auto' }}
      />
      <SlideTextBlock
        text={slide.data?.text2}
        headline={slide.data?.headline2}
        containerStyles={{ marginBottom: 90, height: 'auto' }}
      />
      <SlideTextBlock
        text={slide.data?.text3}
        headline={slide.data?.headline3}
        containerStyles={{ height: 'auto' }}
      />
    </Container>
  );
};
