import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { Container, ImagePreview, ImageWrapper } from './OneImageGamma.styles';
import { ImageProxy } from '../../../../services/ImageProxy';

interface Props {
  reverse?: boolean;
}

export const OneImageGammaPdf: React.FC<Props> = ({ reverse }) => {
  const slide = useSlide();
  const { category } = useCategory();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container
        style={{
          flexDirection: reverse ? 'column-reverse' : 'column',
          justifyContent: 'space-between',
        }}
      >
        <SlideTextBlock
          isEditable={false}
          text={slide.data?.text}
          headline={slide.data?.headline}
          containerStyles={{ height: '44%' }}
        />
        <ImageWrapper
          isPdf
          style={{ marginBottom: !reverse ? 0 : 32, height: '44%', flex: '0 1 auto' }}
        >
          <ImagePreview src={ImageProxy.optimize(slide.data?.image, { width: 750 })} />
        </ImageWrapper>
      </Container>
    </PdfFrame>
  );
};
