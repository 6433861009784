import styled from 'styled-components';

export const ExperienceLevelContainer = styled('div')({
  marginBottom: '15px',
});

export const ExperienceLevelDescription = styled('div')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  color: `${theme.colors.v2.gray[400]}`,
  marginBottom: '9px',
}));
