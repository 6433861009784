import React from 'react';
import { useAtomValue } from 'jotai';
import styled, { CSSObject } from 'styled-components';

import { GraphWrapper } from './GraphWrapper';
import { Typography } from '../../../../Common';
import { TCategory, TSlideId } from '../../../../../types';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../services/ColorOpacityApplier';
import { isPresentationViewAtom } from '../../../../../features/report/state/isPresentationViewAtom';
import { useHasComparableAdjustments } from '../../../../../hooks/useHasComparableAdjustments';

const Container = styled.div<{ isPresentationView: boolean }>`
  display: flex;
  padding: 30px 20px;
  width: 100%;
  overflow-y: auto;

  ${props =>
    !props.isPresentationView &&
    `
    @media (max-width: 1460px) {
      flex-direction: column;
      padding: 20px 0px;
    }
  `}
`;

const ColumnLeft = styled.div<{ isPresentationView: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 425px;
  padding-right: 40px;
  height: '100%';

  ${props =>
    !props.isPresentationView &&
    `
    @media (max-width: 1460px) {
      width: 100%;
      padding-right: 0;
    }
  `}
`;

const Description = styled.div`
  padding: 15px 20px 15px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 0.97561px solid #f2f2f2;
  border-radius: 4.87805px;
  flex: 1;
  gap: 30px;
`;

const Disclaimer = styled.span`
  color: #626262;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  white-space: pre-line;
`;

const RightCol = styled.div<{ isPresentationView: boolean }>`
  width: 72%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props =>
    !props.isPresentationView &&
    `
    @media (max-width: 1460px) {
      width: 100%;
      height: max-content;
    }
  `}
`;

const modernDescriptionTitleStyles = {
  lineHeight: 1.95,
  fontSize: 20,
  marginBottom: 20,
};
const getDescriptionMainTitleStyles = (category?: TCategory): CSSObject => {
  switch (category) {
    case 'traditional':
      return {
        fontSize: 30,
      };
    case 'modern':
      return modernDescriptionTitleStyles;
    case 'luxury':
      return {
        lineHeight: 1.95,
        fontSize: 20,
        letterSpacing: '0.08em',
      };
    default:
      return {};
  }
};

const getDescriptionTitleStyles = (category?: TCategory, primaryColor?: string): CSSObject => {
  switch (category) {
    case 'traditional':
      return {
        fontWeight: 700,
        marginBottom: 10,
        textTransform: 'uppercase',
      };
    case 'modern':
      return {
        ...modernDescriptionTitleStyles,
        color: primaryColor,
      };
    case 'luxury':
      return {
        fontWeight: 700,
        marginBottom: 20,
        textTransform: 'none',
      };
    default:
      return {};
  }
};

const getDescriptionTextStyles = (category?: TCategory): CSSObject => {
  switch (category) {
    case 'traditional':
      return {
        '&:not(:last-of-type)': {
          marginBottom: 30,
        },
      };
    default:
      return {};
  }
};

type WithCategory = {
  category?: TCategory;
};

const MainDescriptionTitle = styled(Typography)<WithCategory>(({ category }) => ({
  fontWeight: 900,
  lineHeight: '40px',
  marginBottom: 20,
  textTransform: 'uppercase',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  color: useUiConfigColorsPrimary(),
  ...getDescriptionMainTitleStyles(category),
}));

const DescriptionTitle = styled(Typography)<WithCategory & { primaryColor: string }>(
  ({ category, primaryColor }) => ({
    textTransform: 'uppercase',
    fontWeight: 900,
    fontSize: 18,
    color: '#000',
    lineHeight: '1.58',
    fontFamily: useUiConfigFontsHeader(),
    ...getDescriptionTitleStyles(category, primaryColor),
  }),
);

const DescriptionText = styled(Typography)<WithCategory>(({ category }) => ({
  fontSize: 18,
  color: '#000',
  lineHeight: 1.58,
  '&:not(:last-of-type)': {
    marginBottom: 20,
  },
  ...getDescriptionTextStyles(category),
}));

export type TSlideListItem = {
  title: string;
  id: TSlideId;
};

type TDescription = {
  title: string;
  text: string;
};

interface SlideWithGraphWrapperProps {
  description: TDescription[];
  heading: string;
  summary?: string[];
  headerDescription?: string;
  graphPadding?: string | number;
  category?: TCategory;
  noDisclaimer?: boolean;
}

export const SlideWithGraphWrapper: React.FC<SlideWithGraphWrapperProps> = ({
  description,
  children,
  heading,
  headerDescription,
  summary,
  graphPadding,
  category,
  noDisclaimer,
}) => {
  const primaryColor = useUiConfigColorsPrimary();
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const hasAdjustments = useHasComparableAdjustments();

  const fontStyle = isPresentationView ? {} : { fontFamily: 'Figtree' };

  return (
    <Container isPresentationView={isPresentationView} style={fontStyle}>
      <ColumnLeft isPresentationView={isPresentationView}>
        <Description
          style={{
            backgroundColor:
              category === 'modern' ? ColorOpacityApplier.hex(primaryColor, '0.05') : '#fff',
          }}
        >
          <div>
            {description.map((descriptionItem, i) => (
              <>
                {i === 0 ? (
                  <MainDescriptionTitle
                    key={descriptionItem.title}
                    variant='h3'
                    category={category}
                    style={fontStyle}
                  >
                    {descriptionItem.title}
                  </MainDescriptionTitle>
                ) : (
                  <DescriptionTitle
                    key={descriptionItem.title}
                    variant='h3'
                    category={category}
                    primaryColor={primaryColor}
                    style={fontStyle}
                  >
                    {descriptionItem.title}
                  </DescriptionTitle>
                )}

                <DescriptionText category={category} variant={'body1'} style={fontStyle}>
                  {descriptionItem.text}
                </DescriptionText>
              </>
            ))}
          </div>
          {!noDisclaimer && hasAdjustments && (
            <Disclaimer>
              {
                'The plotted price is the actual price, not the adjusted.\nHover to see the adjusted price and details.'
              }
            </Disclaimer>
          )}
        </Description>
      </ColumnLeft>
      <RightCol isPresentationView={isPresentationView}>
        <GraphWrapper
          heading={heading}
          summary={summary}
          headerDescription={headerDescription}
          graphPadding={graphPadding}
        >
          {children}
        </GraphWrapper>
      </RightCol>
    </Container>
  );
};
