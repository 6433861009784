import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigBodyLetterSpacing } from '../../../../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../../../../services/ColorOpacityApplier';

export const Text = styled('p')(({ theme }) => ({
  color: '#000',
  fontSize: 22,
  marginBottom: 30,
  textAlign: 'start',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const Button = styled('button')(({ theme }) => {
  const primaryColor = useUiConfigColorsPrimary();
  return {
    border: 'none',
    padding: '0 20px',
    height: 40,
    borderRadius: 5,
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 500,
    backgroundColor: primaryColor,
    color: '#fff',
    '&:hover': {
      backgroundColor: ColorOpacityApplier.hex(primaryColor, '.8'),
    },
    '&:focus': {
      outline: 'none',
    },
  };
});
