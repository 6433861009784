import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './Icon.module.scss';

export const ICONS = Object.freeze({
  EDIT: 'pen',
  CHECK: 'check',
  UNDO: 'undo',
});

export const Icon = ({ icon, onClick, className }) => (
  <span onClick={onClick} className={className}>
    <FontAwesomeIcon icon={['fas', icon]} className={classes.icon} size='1x' color='#ffffff' />
  </span>
);
