import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigBodyLetterSpacing } from '../../../../../../../../providers/providers/UiConfigFontProvider';

type WithActive = {
  isActive?: boolean;
  isWeb?: boolean;
};

export const CardContainer = styled('div')<WithActive>(({ isActive, isWeb }) => ({
  backgroundColor: isActive ? useUiConfigColorsPrimary() : 'rgba(68, 68, 68, 0.05)',
  transition: 'all .2s ease-in-out',
  padding: isWeb ? '20px 35px' : 20,
  borderRadius: 5,
  color: isActive ? '#fff' : '#444',
  cursor: 'pointer',
  width: isWeb ? '100%' : '31.5%',
  marginBottom: isWeb ? 15 : 0,
  letterSpacing: useUiConfigBodyLetterSpacing(),
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const ListPrice = styled('h6')({
  fontSize: 14,
  marginBottom: 12,
  letterSpacing: '0.11em',
  textAlign: 'start',
  fontWeight: 700,
  textTransform: 'uppercase',
});

export const CardItemsContainer = styled('div')<WithActive>(({ isActive }) => ({
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 30,
    height: 1.5,
    backgroundColor: isActive ? '#fff' : '#444',
    bottom: 0,
    right: 0,
  },
}));

export const CardItemWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 4,
  paddingBottom: 8,
});

export const CardItemTitle = styled('div')(({ theme }) => ({
  fontSize: 13,
}));

export const CardItemValue = styled('div')({
  fontSize: 14,
});

export const NetProceedsItemTitle = styled('div')({
  fontSize: 14,
  fontWeight: 800,
  letterSpacing: '0.11em',
  textTransform: 'uppercase',
});

export const NetProceedsValue = styled(CardItemValue)({
  fontWeight: 800,
});
