import React from 'react';
import { CATEGORIES } from '../../../../../../constants/categories';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { ListingFeedbackTablePDF } from './ListingFeebackTablePDF/ListingFeedbackTablePDF.component';

export const useListingFeedbackPages = () => {
  const { category } = useCategory();
  const { properties } = useProperties();
  const chunkSize = 12;

  const propertiesChunks = properties
    .map((property, i) => {
      return i % chunkSize === 0 ? properties.slice(i, i + chunkSize) : null;
    })
    .filter(property => {
      return property;
    });

  return propertiesChunks.map((properties, i) => {
    if (!properties) return;
    return (
      <PdfFrame key={i} category={category ?? CATEGORIES.TRADITIONAL}>
        <ListingFeedbackTablePDF properties={properties} />
      </PdfFrame>
    );
  });
};
