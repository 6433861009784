import { useEffect, useMemo } from 'react';
import { useSlides } from '../../../providers/providers/SlidesProvider';
import { useSelectedSlide } from '../../../providers/providers/SelectedSlideProvider';
import { useHasSelectedSlide } from '../../../hooks/useHasSelectedSlide';

export function useSetFirstSlideAsActive() {
  const { slides } = useSlides();

  const { selectedSlide, setSelectedSlide } = useSelectedSlide();

  const firstSlide = slides?.coverSlide;

  const hasSelectedSlide = useHasSelectedSlide();
  useEffect(() => {
    if ((selectedSlide || !firstSlide) && hasSelectedSlide) return;

    setSelectedSlide(firstSlide);
  }, [selectedSlide, setSelectedSlide, firstSlide, hasSelectedSlide]);

  return selectedSlide;
}
