import styled from 'styled-components';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1'),
  borderRadius: 5,
  padding: '25px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'auto',
}));

export const DescriptionWrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  marginBottom: isPdf ? 25 : 30,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const Title = styled('h5')<WithPdf>(({ theme, isPdf }) => ({
  fontSize: isPdf ? 18 : 20,
  fontWeight: 900,
  fontFamily: useUiConfigFontsHeader(),
  color: useUiConfigColorsPrimary(),
  textAlign: 'start',
  marginBottom: isPdf ? 5 : 13,
  textTransform: isPdf ? 'none' : 'uppercase',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const Description = styled('p')<WithPdf>(({ theme, isPdf }) => ({
  fontSize: isPdf ? 16 : 16,
  color: theme.colors.gray[400],
  lineHeight: 1.5,
  textAlign: 'start',
}));

export const ListTitle = styled(Title)({
  textTransform: 'none',
});

export const List = styled('ul')<WithPdf>(({ isPdf }) => ({
  fontSize: isPdf ? 16 : 16,
  listStyleType: '"•"',
  lineHeight: 1.5,
}));

export const ListItem = styled('li')<WithPdf>(({ theme, isPdf }) => ({
  marginBottom: isPdf ? 10 : 15,
  color: theme.colors.gray[400],
  paddingLeft: 7,
  '&:last-of-type': {
    marginBottom: 0,
  },
}));
