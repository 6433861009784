import React, { useState, useEffect, useMemo } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';

import { TSection, TSectionConfig, TSlide, TTag } from '../../../../../../../types';
import PreviewSlide from '../../../../../../../components/PreviewSlide/PreviewSlide.component';
import { useUiConfigTheme } from '../../../../../../../providers/providers/UiConfigThemeProvider';
import { SlideSectionConfigItem } from './components/SlideSectionConfig/SlideSectionConfigItem.component';
import { useUiConfigInfoPlacement } from '../../../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { SelectedSlideProvider } from '../../../../../../../providers/providers/SelectedSlideProvider';
import { useSections } from '../../../../../../../providers/providers/SectionsProvider';
import { useSlides } from '../../../../../../../providers/providers/SlidesProvider';
import { useCustomizationOrder } from '../../../../../../../providers/providers/CustomizationOrderProvider';
import { PresentationTypePresets } from './components/PresentationTypePresets/PresentationTypePresets.component';
import { usePresentationType } from '../../../../../../../providers/providers/PresentationTypeProvider';
import { useEntity } from '../../../../../providers/EntityProvider';
import { useLevelTags } from '../../../../../../../hooks/dynamicSlides/useTags';
import { SlidesTags } from '../../../../../../../components/Common/SlidesTags/SlidesTags.component';
import styled from 'styled-components';

const sectionsToOpenState = (sections: TSection[]): { [key in TSection]?: boolean } => {
  const obj: { [key in TSection]?: boolean } = {};
  sections.forEach((s, i) => (obj[s] = i === 0));

  return obj;
};

export const CustomizationModalComponentStepTwo: React.FC = () => {
  const { theme } = useUiConfigTheme();
  const { infoPlacement } = useUiConfigInfoPlacement();

  const { presentationType } = usePresentationType();
  const { entityLabel } = useEntity();

  const { sections: sectionsMap } = useSections();
  const {
    customizationSectionsSlideByType,
    customizationSectionsByType: sectionsOrder,
    setCustomizationSectionsByType,
  } = useCustomizationOrder();
  const { getSlide, slides } = useSlides();

  const sections = React.useMemo(
    () =>
      Object.fromEntries(
        (sectionsOrder?.[presentationType] || [])?.map(sectionId => {
          return [
            sectionId,
            {
              ...sectionsMap[sectionId],
            },
          ];
        }),
      ) as Record<TSection, TSectionConfig>,
    [presentationType, sectionsMap, sectionsOrder],
  );

  const totalSlides = React.useMemo(() => {
    let count = 0;

    Object.values(sections).forEach(s => {
      if (!['startingSlides'].includes(s.id)) {
        count +=
          customizationSectionsSlideByType?.[presentationType]?.[s.id]?.filter(
            sl => sl?.includeSlide,
          )?.length ?? 0;
      }
    });

    return count;
  }, [sections, customizationSectionsSlideByType, presentationType]);
  const coverSlide = getSlide('coverSlide');

  const [selectedSlide, setSelectedSlide] = useState<TSlide | undefined>(coverSlide as TSlide);
  const [open, setOpen] = useState<{ [key in TSection]?: boolean }>({});
  const onOpenToggle = (field: TSection): void => {
    setOpen(prev => ({ ...prev, [field]: !prev[field] }));
  };

  useEffect(
    function onSlidesConfigChange() {
      if (!coverSlide) return;
      setSelectedSlide(coverSlide);
      setOpen(sectionsToOpenState(sectionsOrder?.[presentationType] as TSection[]));
    },
    [coverSlide, presentationType, sectionsOrder],
  );

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const updatedSections = [...(sectionsOrder?.[presentationType] ?? [])];

    if (result.destination.index === updatedSections.length - 1) return; // try to move section under next steps

    const movingItem = updatedSections.splice(sourceIndex, 1);
    updatedSections.splice(destinationIndex, 0, movingItem[0]);

    setCustomizationSectionsByType({ ...sectionsOrder, [presentationType]: updatedSections });
  };

  const tagsMap = useLevelTags();
  const tagsList = useMemo(() => Object.values(tagsMap ?? {}), [tagsMap]);
  const [selectedTags, setSelectedTags] = useState<TTag[]>([]);

  if (!slides) return null;

  return (
    <>
      <SelectedSlideProvider>
        <StepTwoLeftContent>
          <DefaultPresentationSubtitle>
            <PrimaryText>Customizing default for: {entityLabel}</PrimaryText>
            <SecondaryText>
              {`Your default presentation has ${sectionsOrder?.[presentationType]?.filter(
                section => !['startingSlides'].includes(section),
              ).length} sections, ${totalSlides} slides`}
            </SecondaryText>
          </DefaultPresentationSubtitle>

          <HashtagsAndDropdowns>
            <SlidesTags
              noPadding
              tagsList={tagsList}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />

            <PresentationTypePresets />

            <DragDropContext onDragEnd={onDragEnd}>
              <Wrapper>
                <Droppable droppableId={'sections-content'} type={'sections-content'}>
                  {(provided, snapshot) => (
                    <SlideSectionConfigItemsWrapper
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {sectionsOrder?.[presentationType]?.map(
                        (section, i) =>
                          !['startingSlides'].includes(section) && (
                            <SlideSectionConfigItem
                              key={section}
                              activeTags={selectedTags}
                              sections={sections}
                              section={sections?.[section as TSection]}
                              open={!!open?.[section as TSection]}
                              index={i}
                              selectedSlide={selectedSlide}
                              onSlideSelect={setSelectedSlide}
                              onToggle={() => onOpenToggle(section as TSection)}
                            />
                          ),
                      )}
                      {provided.placeholder}
                    </SlideSectionConfigItemsWrapper>
                  )}
                </Droppable>
              </Wrapper>
            </DragDropContext>
          </HashtagsAndDropdowns>
        </StepTwoLeftContent>

        <StepTwoRightContent>
          <PreviewSlide
            slide={selectedSlide}
            theme={theme}
            infoPlacement={infoPlacement}
            showTemplatePreview={selectedSlide?.id === 'buyerInterest'}
          />
        </StepTwoRightContent>
      </SelectedSlideProvider>
    </>
  );
};

const StepTwoLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1;
  & > * {
    width: 100%;
  }
`;

const DefaultPresentationSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const PrimaryText = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const SecondaryText = styled.p`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const HashtagsAndDropdowns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 360px);
  overflow-y: auto;

  &::-webkit-scrollbar: {
    width: 0.3em;
  }

  &::-webkit-scrollbar-thumb: {
    background-color: ${({ theme }) => theme.colors.v2.gray[200]};
    outline: none;
  }
  scroll-behavior: smooth;
`;

const SlideSectionConfigItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StepTwoRightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 719px;
  height: 404px;
  border-radius: 5px;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;
