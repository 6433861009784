import React from 'react';
import frameGraphics from './frameGraphics';
import Logos from '../../../../components/Logos/Logos';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../ProgressTracker/ProgressTracker';
import BrokerInfo from '../../../../components/BrokerInfo/BrokerInfo.component';
import {
  HeaderContainer,
  BrokerInfoWrapper,
  Graphics,
  Line,
  BackgroundWrapper,
} from './LuxuryFrameGama.styles';
import { PlacementAwareContainer } from '../../../../components/PlacementAwareContainer';
import { svgStringToDataUrl } from '../../../../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import TocPortalContainer from '../../../../components/TocPortalContainer';
import { CommonFrameProps } from '../../../../../../../../types';
import { useUser } from '../../../../../../providers/UserProvider';
import { ACCOUNT_IDS } from '../../../../../../../../constants/accountIds';

const frameImg = svgStringToDataUrl(frameGraphics);

const LuxuryFrameGama: React.FC<CommonFrameProps> = ({
  children,
  infoPlacement,
  hideProgressTracker,
}) => {
  const user = useUser();

  const primaryColor = useUiConfigColorsPrimary();

  const position = infoPlacement === 'bottom' ? 'bottom' : 'top';
  const hasImageBackground = ![user?.kvcoreParentAccountId, user?.accountId].includes(
    ACCOUNT_IDS.NEXT_HOME,
  );

  return (
    <PlacementAwareContainer infoPlacement={infoPlacement}>
      <BackgroundWrapper
        style={{ [position]: 0, transform: `scale(${position === 'bottom' ? '-' : ''}1)` }}
      >
        {hasImageBackground && <Graphics src={frameImg} />}
      </BackgroundWrapper>

      <TocPortalContainer zIndex={5} offset={-20} />
      <Line style={{ [position]: 140, zIndex: 8 }} />
      <HeaderContainer>
        <BrokerInfoWrapper>
          <BrokerInfo fontColor='#000' nameFontStyles={{ fontSize: 18, fontWeight: 400 }} />
        </BrokerInfoWrapper>
        {!hideProgressTracker && (
          <ProgressTracker
            category={CATEGORIES.LUXURY}
            containerStyles={{ paddingBottom: infoPlacement === 'top' ? 16 : 42 }}
            titleStyles={{ color: primaryColor }}
          />
        )}
        <Logos />
      </HeaderContainer>
      {children}
    </PlacementAwareContainer>
  );
};

export default LuxuryFrameGama;
