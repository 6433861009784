import { TPresentationType } from '../types';
import { PRESENTATION_TYPES } from '../constants/PresentationTypes';

enum Deal {
  SELLER = 'seller',
  BUYER = 'buyer',
}

const presentationTypeDealsMap: Record<TPresentationType, Deal> = {
  [PRESENTATION_TYPES.WIN_THE_LISTING]: Deal.SELLER,
  [PRESENTATION_TYPES.WIN_THE_OFFER]: Deal.BUYER,
  [PRESENTATION_TYPES.PRESENT_INFO]: Deal.SELLER,
  [PRESENTATION_TYPES.BUYER_TOUR]: Deal.BUYER,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: Deal.BUYER,
};

export function getDealType(presentationType: TPresentationType): Deal {
  return presentationTypeDealsMap[presentationType];
}
