import { http } from '../services/http';
import { StorageService } from '../services/storageService';
import { API_BASE_URL } from '../config/constants';

class CmaApiService {
  addToken(data) {
    const token = StorageService.read('token');

    return !token ? data : { ...data, token };
  }

  addAcceptJsonHeader(options) {
    const token = StorageService.read('token');

    const headers = {
      ...options.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (!token) {
      return {
        ...options,
        headers,
      };
    }
    return {
      ...options,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  buildRejectedPromise(error) {
    const response = error.response ? error.response.data.data : {};

    const message = (response && response.message) || 'Communication Error';
    const errors = response && response.errors ? { errors: response.errors } : {};

    return Promise.reject({
      message,
      ...errors,
    });
  }

  get(path, options = {}, params = {}) {
    const url = `${API_BASE_URL}${path}`;

    const combinedOptions = this.addAcceptJsonHeader(options);
    const combinedParams = this.addToken(params);

    return http
      .get(url, combinedOptions, combinedParams)
      .then(res => res.data)
      .catch(error => this.buildRejectedPromise(error));
  }

  post(path, data, options = {}, params = {}) {
    const url = `${API_BASE_URL}${path}`;

    const dataWithToken = this.addToken(data);
    const combinedOptions = this.addAcceptJsonHeader({
      ...options,
      data: dataWithToken,
    });

    return http
      .post(url, combinedOptions, params)
      .then(res => res.data)
      .catch(error => this.buildRejectedPromise(error));
  }
}

export const cmaApi = new CmaApiService();
