import Frame from './TraditionalFrameAlpha';
import thumbnail from './traditionalAlpha.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'traditionalAlpha';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TTraditionalFrameAlpha = typeof id;
export default config;
