import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import classes from '../BedCount/BedCount.module.scss';
import { SearchCombiner } from '../../../../SearchCombiner';
import { SearchValidator } from '../../../../SearchValidator';
import { CriteriaOptionsSubtitle } from '../components/CriteriaOptionsSubtitle/CriteriaOptionsSubtitle';
import { CRITERIAS } from '../../../Criterias';
import { useCriteriaIsOpen } from '../../../CriteriaIsOpenProvider';
import { CriteriaAccordion } from '../components/CriteriaAccordion';
import { SliderOption } from '../BedCount/SliderOption/SliderOption';
import { CountEdit } from '../components/CountEdit';

const info =
  'Represents the total number of bathrooms as documented on the Title record. The default range is set to include all bath counts, ensuring no restrictions on comparable matches';

interface Props {
  onChange: (data: any) => void;
}

const bathCountOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10+', value: 10 },
];

export const TotalBathCount = ({ onChange }: Props) => {
  const [baths, setBaths] = useState('');
  const [bathsDefault, setBathsDefault] = useState('');
  const [bathsRange, setBathsRange] = useState([1, 10]);

  const searchCriteria = useSelector(({ search }: any) => search.searchCriteria);

  const [isOpen] = useCriteriaIsOpen(CRITERIAS.BATHS);

  const validate = () => {
    if (!baths) return;

    const min = Number.parseInt(baths) + Math.min(...bathsRange);
    const max = Number.parseInt(baths) + Math.max(...bathsRange);
    return !Number.isNaN(min) && !Number.isNaN(max);
  };

  const onCombine = () => ({
    totalBaths: Number(baths),
    bathsInit: Number(bathsDefault),
    bathsRange,
  });
  useEffect(() => {
    setBaths(isNaN(parseInt(searchCriteria?.totalBaths)) ? baths : searchCriteria.totalBaths);
    setBathsDefault(searchCriteria.bathsInit || searchCriteria.totalBaths || baths);
    setBathsRange(searchCriteria.bathsRange || bathsRange);
  }, []);

  useEffect(() => {
    SearchValidator.subscribe('baths', validate);
    SearchCombiner.add('baths', onCombine);

    onChange(SearchCombiner.combine());

    return () => {
      SearchValidator.unsubscribe('baths');
      SearchCombiner.remove('baths');
    };
  }, [baths, bathsRange]);

  return (
    <CriteriaAccordion title='TOTAL BATH COUNT' info={info} isOpen={isOpen}>
      <CountEdit defaultValue={bathsDefault} subject={baths} onSubjectChange={setBaths} />
      <CriteriaOptionsSubtitle />
      <SliderOption
        initValue={bathsRange}
        options={bathCountOptions}
        description='Bath Count Range'
        specialMargin=''
        changed={setBathsRange}
        className={classes.slider}
      />
    </CriteriaAccordion>
  );
};
