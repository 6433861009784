import React from 'react';
import {
  RadioButtonsWrapper,
  RadioButtons,
  Wrapper,
  Container,
  Icon,
} from './SettingsOptions.styles';
import { TOption } from '../SettingsOptionsButton.component';

interface SettingsOptionsProps {
  isOpen: boolean;
  onClose: () => void;
  options: TOption[];
  onSelectOption: (val: string) => void;
  defaultSelected?: string;
  selectedOption?: TOption;
  AdditionalInfo?: React.FC | null;
}

export const SettingsOptions = React.forwardRef(
  (
    {
      onClose,
      isOpen,
      options,
      onSelectOption,
      defaultSelected,
      AdditionalInfo,
    }: SettingsOptionsProps,
    ref?: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <Container isOpen={isOpen} ref={ref}>
        <Wrapper>
          <RadioButtonsWrapper>
            <RadioButtons
              options={options}
              defaultSelected={defaultSelected ?? ''}
              onChange={(val: string) => onSelectOption(val)}
            />
          </RadioButtonsWrapper>
          <Icon
            iconName='close'
            fill='#666'
            style={{ width: 14, height: 14, cursor: 'pointer' }}
            onClick={onClose}
          />
        </Wrapper>
        {AdditionalInfo && <AdditionalInfo />}
      </Container>
    );
  },
);

SettingsOptions.displayName = 'Settings';
