import styled from 'styled-components';

import { Icon, Typography } from '../../../../components/Common';

export { Icon, Typography };

type WithInfoPlacement = {
  isInfoPlacementBottom?: boolean;
};

export const IconContainer = styled('div')<WithInfoPlacement>(({ isInfoPlacementBottom }) => ({
  position: 'absolute',
  bottom: isInfoPlacementBottom ? 'auto' : 60,
  top: isInfoPlacementBottom ? 30 : 'auto',
  right: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}));

export const MessageContainer = styled('div')(({ theme }) => ({
  padding: 10,
  backgroundColor: theme.colors.gray[400],
  borderRadius: 5,
  marginRight: 10,
}));

export const AgreeText = styled(Typography)(({ theme }) => ({
  color: theme.colors.primary.main,
  fontWeight: 500,
  cursor: 'pointer',
}));
