import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  position: 'relative',
  backgroundColor: '#DDD',
}));

export const PdfViewerWrapper = styled('div')({
  overflowY: 'auto',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
});
