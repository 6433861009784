import React from 'react';
import styled from 'styled-components';

import { useUiConfigFontsHeader } from '../../../../providers/providers/UiConfigFontProvider';

const TextWrapper = styled('div')({
  position: 'fixed',
  bottom: 33,
  right: 60,
  zIndex: 5,
});

const Text = styled('p')({
  textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white',
  fontSize: 30,
  fontWeight: 900,
  color: '#000',
});

export const ProceedToPresentation = () => {
  const fontFamily = useUiConfigFontsHeader();

  return (
    <TextWrapper>
      <Text style={{ fontFamily }}>Proceed to presentation</Text>
    </TextWrapper>
  );
};
