import { Switch, Route, Redirect } from 'react-router-dom';
import { createContext } from 'react';

import { URLS } from '../../constants/URLS';
import { CompResults } from './CompResults/CompResults';
import { PropertySearch } from './PropertySearch/PropertySearch';
import { UserDataIssuesWarnings } from './UserDataIssuesWarnings';
import { CompResultsPresentation } from './CompResults/CompResultsPresentation';
import { InternetExplorerWarning } from '../../components/InternetExplorerWarning/InternetExplorerWarning';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';

export const UserContext = createContext();

export const Authenticated = () => {
  const user = {
    user: useUser(),
  };

  return (
    <div>
      <UserContext.Provider value={user}>
        <InternetExplorerWarning />
        <UserDataIssuesWarnings />

        <Switch>
          <Route path='/create/search/:step' component={PropertySearch} />
          <Route path={URLS.ADAPT_RESULT} component={CompResultsPresentation} />
          <Route path={URLS.RESULT} component={CompResults} />
          <Redirect to={URLS.SEARCH.ADDRESS} />
        </Switch>
      </UserContext.Provider>
    </div>
  );
};
