import styled from 'styled-components';
import { TToolbarSize } from './TextEditor.component';

type withInitialAlignmentCenter = {
  isInitialCenterAlignment?: boolean;
};

export const EditorWrapper = styled('div')<withInitialAlignmentCenter>(
  ({ isInitialCenterAlignment, theme }) => ({
    border: `1px solid ${theme.colors.v2.gray[300]}`,
    borderRadius: 5,
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset',
    '&:focus-within': {
      border: 'double 1px transparent',
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',
      backgroundImage: theme.colors.v2.gradient.border,
    },
    height: 'calc(100% - 64px)',
    fontSize: 16,
    '& .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr': {
      textAlign: isInitialCenterAlignment ? 'center' : 'left',
    },
    '& .public-DraftStyleDefault-block': {
      margin: 0,
      padding: '10px 0',
      lineHeight: 'normal',
    },
    '& .rdw-fontsize-wrapper': {
      marginBottom: 0,
    },
    '& .font-size': {
      margin: '0 10px',
      minWidth: 50,
      fontSize: 14,
      borderRadius: 3,
      border: '1px solid #ddd',
      padding: 3,
      position: 'relative',
      height: 20,
      '&:hover': {
        boxShadow: 'none',
      },
    },
    '& .font-size-large-button': {
      margin: '0 20px',
      minWidth: 75,
      fontSize: 22,
      padding: 5,
      height: 37,
    },
    '& .font-size-dropdown': {
      width: '100%',
      position: 'absolute',
      top: '100%',
      left: 0,
      overflowY: 'auto',
      '& li': {
        minHeight: 20,
        justifyContent: 'flex-start',
        padding: '0 5px',
      },
    },
    '& .font-size-large-dropdown': {
      '& li': {
        padding: '0 10px',
        minHeight: 40,
      },
    },
    '& .rdw-center-aligned-block .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr': {
      textAlign: 'center',
    },
    '& .rdw-right-aligned-block .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr': {
      textAlign: 'right',
    },
    '& .rdw-left-aligned-block .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr': {
      textAlign: 'left',
    },
  }),
);
