import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CSSObject } from 'styled-components';
import { corePresentApi } from '../../../api/CorePresentApi';
import Loader from '../../Loader/Loader';
import { FitContentModal } from '../FitContentModal/FitContentModal';

import { Container, Wrapper } from './NotifyMlsEmailPopup.styles';
import { StorageService } from '../../../services/storageService';
import { useUser } from '../../Slide/providers/UserProvider';
import { useBrandName } from '../../../hooks/useBrandName';

interface EmailPopupProps {
  modalContainerStyles?: CSSObject;
  headerTitle?: string;
}

export const NotifyMlsEmailPopup: React.FC<EmailPopupProps> = ({
  modalContainerStyles,
  headerTitle = 'Information about your account',
}) => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { present } = useBrandName();
  const { feedIds } = useUser();
  const userHasFeeds = feedIds?.length > 0;

  const onClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (!StorageService.has('userWasShownNotifyMlsModal') && !userHasFeeds) {
      setModalOpen(true);
      StorageService.save('userWasShownNotifyMlsModal', true);
    }
  }, [userHasFeeds]);

  const send = async () => {
    setLoading(true);
    try {
      await corePresentApi.post('/users/notify-mls', {});
      toast('Thank you for submitting your interest.', {
        type: 'success',
        position: 'top-center',
      });
      setTimeout(() => {
        setLoading(false);
        onClose();
      }, 1500);
      return;
    } catch (e) {
      toast('There was an error. ', {
        type: 'error',
        position: 'top-center',
        autoClose: 4000,
      });
      setTimeout(() => {
        setLoading(false);
      }, 1500);
      return;
    }
  };

  const renderModalContent = () => {
    switch (true) {
      case loading:
        return <Loader size={'4x'} />;

      default:
        return (
          <>
            <Wrapper style={{ flexDirection: 'column' }}>
              <p>
                Comparable Market Analysis (CMA) and One-Sheeters that highlight sales trends are
                not enabled for your {present}.
              </p>
              <br />
              <p>
                Access to sold data from the MLS is a requirement for these features to be enabled.{' '}
                <br />
                {present} currently does not receive sold data from the MLS(s) you are subscribed
                to.{' '}
              </p>
              <br />
              <p>Indicate and submit your interest in enabling these features.</p>
            </Wrapper>
          </>
        );
    }
  };

  if (userHasFeeds) return null;

  return (
    <FitContentModal
      isOpen={modalOpen}
      headerTitle={headerTitle}
      onClose={onClose}
      onConfirmClick={() => send()}
      confirmBtnText='Submit My Interest'
      hasCancelBtn
      modalContainerStyles={modalContainerStyles}
    >
      <Container>{renderModalContent()}</Container>
    </FitContentModal>
  );
};
