import { BrowserRouter } from 'react-router-dom';

import { QueryProvider } from './QueryProvider';
import { ThemeProvider } from '../config/themes';
import { StoreProvider } from '../store/StoreProvider';
import { GoogleMapProvider } from '../components/Common/GoogleMap/GoogleMapProvider';

type Props = {
  children: React.ReactNode;
};

export default function GlobalProviders({ children }: Props) {
  return (
    <StoreProvider>
      <BrowserRouter basename='/present'>
        <QueryProvider>
          <GoogleMapProvider>
            <ThemeProvider>{children}</ThemeProvider>
          </GoogleMapProvider>
        </QueryProvider>
      </BrowserRouter>
    </StoreProvider>
  );
}
