import React from 'react';
import { DashboardTextareaEl, DashboardLabel } from './DashboardTextarea.styles';
import { Textarea } from '../V2/Textarea';

interface DashboardInputProps {
  label: string;
  textareaId?: string;
  value?: string;
  onChange: (message: string) => void;
}

export const DashboardTextarea: React.FC<DashboardInputProps> = ({
  value = '',
  label,
  textareaId,
  onChange = f => f,
}) => {
  return (
    <DashboardTextareaEl>
      <DashboardLabel htmlFor={textareaId}>{label}</DashboardLabel>
      <Textarea
        value={value}
        onChange={event => onChange(event.currentTarget.value)}
        rows={10}
        id={textareaId}
      />
    </DashboardTextareaEl>
  );
};
