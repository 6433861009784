import { TSlide } from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { WhereBuyersGetTheirInfoModern } from './web/modern/WhereBuyersGetTheirInfoModern';
import { WhereBuyersGetTheirInfoLuxury } from './web/luxury/WhereBuyersGetTheirInfoLuxury';
import WhereBuyersGetTheirInfoModernPDF from './pdf/modern/WhereBuyersGetTheirInfoModernPDF';
import WhereBuyersGetTheirInfoLuxuryPDF from './pdf/luxury/WhereBuyersGetTheirInfoLuxuryPDF';
import { WhereBuyersGetTheirInfoTraditional } from './web/traditional/WhereBuyersGetTheirInfoTraditional';
import WhereBuyersGetTheirInfoTraditionalPDF from './pdf/traditional/WhereBuyersGetTheirInfoTraditionalPDF';

const id = 'whereDoBuyersGetTheirInfo';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Where Do Buyers Get Their Info',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.MODERN]: WhereBuyersGetTheirInfoModern,
    [CATEGORIES.LUXURY]: WhereBuyersGetTheirInfoLuxury,
    [CATEGORIES.TRADITIONAL]: WhereBuyersGetTheirInfoTraditional,
  },
  pdf: {
    [CATEGORIES.MODERN]: WhereBuyersGetTheirInfoModernPDF,
    [CATEGORIES.LUXURY]: WhereBuyersGetTheirInfoLuxuryPDF,
    [CATEGORIES.TRADITIONAL]: WhereBuyersGetTheirInfoTraditionalPDF,
  },
};

export type TWhereBuyersGetTheirInfo = typeof id;
export { config, mappings };
