import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Routes } from '../../../pages/Router/Routes';
import { PresentationBuildingLayout } from '../../../layouts/PresentationBuildingLayout/PresentationBuildingLayout';

interface CreateBuyerTourLayoutProps {
  HeaderActions: React.FC;
}

export const CreateBuyerTourLayout: React.FC<CreateBuyerTourLayoutProps> = ({
  HeaderActions,
  children,
}) => {
  const location = useLocation();
  const { hash } = useParams<{ hash: string }>();

  const isAddProperties = location.pathname.startsWith(Routes.presentationNew);
  const isCreatePresentation = location.pathname.startsWith(`/presentation/${hash}/edit`);

  const navItems = [
    { label: '1. Add Listings', isActive: isAddProperties },
    {
      label: '2. Create Presentation',
      isActive: isCreatePresentation,
    },
    {
      label: '3. Save & Present',
    },
  ];

  return (
    <PresentationBuildingLayout
      HeaderActions={<HeaderActions />}
      navigationItems={navItems}
      isScrollable
    >
      {children}
    </PresentationBuildingLayout>
  );
};
