import styled from 'styled-components';

export const Container = styled('div')<{ isPdf?: boolean }>(({ isPdf }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: isPdf ? '90%' : '100%',
  padding: isPdf ? 0 : '2%',
}));

export const ImagesWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexShrink: 0,
  gap: 24,
});

export const ImageWrapper = styled('div')({
  flex: 0.95,
  overflow: 'hidden',
});

export const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'center',
});
