import { withRouter } from 'react-router-dom';

import '../../config/axios';
import { AuthApi } from '../../api/AuthApi';
import { AuthenticatedRouter } from './AuthenticatedRouter';
import { UnauthenticatedRouter } from './UnauthenticatedRouter';
import { provideFaIcons } from '../../config/faIcons/faIconsProvider';

provideFaIcons();

export const Router = withRouter(() =>
  AuthApi.isLoggedIn() ? <AuthenticatedRouter /> : <UnauthenticatedRouter />,
);
