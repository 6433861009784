import React from 'react';
import {
  Container,
  Content,
  Description,
  DescriptionContainer,
  ImageTitle,
  LeftCol,
  RightCol,
  SourceLink,
  SourceText,
  Subtitle,
  Title,
} from './PricingMisconceptions.styles';

export const PricingMisconceptions: React.FC = () => {
  return (
    <Container>
      <LeftCol>
        <Content>
          <Title>Pricing misconceptions</Title>
          <Subtitle>AVM – Automated Valuation Model?</Subtitle>
          <DescriptionContainer>
            <Description>
              An AVM is simply an estimate. It’s based on a modeled formula that accounts for
              multiple variables that range from market conditions and home facts to buyer interest.
              Zillow, Realtor.com, Trulia and Redfin are some of the more common AVMs that buyers
              reference. AVMs should not be relied upon on their own, but are a great starting
              point.
            </Description>
            <Description>
              It is almost a guarantee that buyers will check an AVM. There are over 1.7 billion
              real estate websites globally, and over 200 million in the U.S. alone. Zillow ranks in
              the top 25 most visited U.S. sites with each visitor spending an average of 7 minutes.
            </Description>
          </DescriptionContainer>
        </Content>
      </LeftCol>
      <RightCol>
        <ImageTitle>Most VIsited US WEBSITES</ImageTitle>
        <SourceText>
          Source:
          <SourceLink href='https://www.alexa.com/topsites/countries/US' target='_blank'>
            www.alexa.com/topsites/countries/US (Nov 2020)
          </SourceLink>
        </SourceText>
      </RightCol>
    </Container>
  );
};
