import { useMemo } from 'react';
import styled from 'styled-components';

import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { data } from '../../data';
import slideGraphics from './slideGraphics';
import bg from './bg.png';

const headingStyles = {
  fontSize: 13,
  fontWeight: 800,
  lineHeight: '133%',
  color: '#444',
};

const textStyles = {
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '140%',
  color: '#101010',
};

export const WhyIAmTheRightBuyersAgentTraditionalPDF = () => {
  const primary = useUiConfigColorsPrimary();

  const img = useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <ImageWrapper>
        <ShapeImg src={img} />
        <Title color={primary}>why i am the right</Title>
        <Title color={primary} isSubText>
          buyer’s agent
        </Title>
        <TraditionalSlideCircularCard
          heading={data[0].title}
          text={data[0].text}
          position={{ left: 172, bottom: 50 }}
          textStyles={{ ...textStyles, fontSize: 12.5 }}
          headingStyles={{ ...headingStyles, width: 145 }}
          containerStyles={{ padding: 0 }}
          size={195}
        />
        <TraditionalSlideCircularCard
          heading={data[1].title}
          text={data[1].text}
          position={{ left: 13, bottom: 270 }}
          textStyles={{ ...textStyles, fontSize: 12.5, width: 175 }}
          headingStyles={{ ...headingStyles, width: 160 }}
          containerStyles={{ padding: 0 }}
          size={200}
        />
        <TraditionalSlideCircularCard
          heading={data[2].title}
          text={data[2].text}
          position={{ left: 20, top: 245 }}
          textStyles={{ ...textStyles, width: 175 }}
          headingStyles={{ ...headingStyles, width: 170 }}
          containerStyles={{ padding: 0 }}
          size={205}
        />
        <TraditionalSlideCircularCard
          topText='Strong'
          heading='negotiation skills'
          text={data[3].text}
          position={{ right: 237, top: 55 }}
          topTextStyles={{ color: '#444', fontSize: 14 }}
          textStyles={{ ...textStyles, width: 190 }}
          headingStyles={headingStyles}
          containerStyles={{ padding: 0 }}
          size={200}
        />
        <TraditionalSlideCircularCard
          topText='Coordinating'
          heading='with Professionals'
          text={data[4].text}
          position={{ right: 25, top: 231 }}
          topTextStyles={{ color: '#444', fontSize: 13.3 }}
          textStyles={{ ...textStyles, fontSize: 12, width: 190 }}
          headingStyles={{ ...headingStyles, fontSize: 13.3 }}
          containerStyles={{ padding: 0 }}
          size={215}
        />
        <TraditionalSlideCircularCard
          heading={data[5].title}
          text={data[5].text}
          position={{ right: 10, bottom: 275 }}
          textStyles={{ ...textStyles, fontSize: 12.5, width: 190 }}
          headingStyles={{ ...headingStyles, width: 175 }}
          containerStyles={{ padding: 0 }}
          size={205}
        />
        <TraditionalSlideCircularCard
          topText='Protecting'
          heading='Your Interests'
          text={data[6].text}
          position={{ right: 167, bottom: 50 }}
          topTextStyles={{ color: '#444', fontSize: 14 }}
          textStyles={{ ...textStyles, width: 190 }}
          headingStyles={headingStyles}
          containerStyles={{ padding: 0 }}
          size={200}
        />
      </ImageWrapper>
    </PdfFrame>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url('${bg}');
  background-position: bottom;
  background-size: cover;
`;

const ShapeImg = styled.img`
  margin: 0 auto;
  display: block;
  bottom: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
`;

const Title = styled.h2<{ color: string; isSubText?: boolean }>`
  color: ${props => props.color};
  position: absolute;
  top: ${props => (props.isSubText ? '70px' : '35px')};
  left: 30px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
`;
