import React from 'react';
import styled from 'styled-components';

import { ImageButtons } from './ImageButtons.component';
import { PrevNextButtons } from './PrevNextButtons/PrevNextButtons.component';
import { IncludeAllPropertiesSwitch } from './IncludeAllPropertiesSwitch/IncludeAllPropertiesSwitch';

export const SlideAndPresentationActions: React.FC = () => {
  return (
    <Container>
      <IncludeAllPropertiesSwitch />

      <RightActions>
        <ImageButtons />
        <PrevNextButtons />
      </RightActions>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const RightActions = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 20,
  justifySelf: 'flex-end',
});
