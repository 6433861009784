import { CSSProperties } from 'react';
import styled from 'styled-components';
import { PropertyModal } from '../../../components/PropertyModal/PropertyModal';
import { RangeHighlights } from '../components/RangeHighlights';
import { ScaleMiddleLine } from '../components/ScaleMiddleLine';
import { BottomRangeSelected } from '../components/BottomRangeSelected';
import { useAtom } from 'jotai';
import { isPropertyModalOpenAtom } from '../../../state/isPropertyModalOpenAtom';

interface ScaleProps {
  modalId?: string;
  containerStyle?: CSSProperties;
  withPropertyModal?: boolean;
  defaultMarkersOnly?: boolean;
}

export const Scale = ({
  modalId = 'scale-frame',
  containerStyle = {},
  withPropertyModal = false,
  defaultMarkersOnly = false,
}: ScaleProps) => {
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useAtom(isPropertyModalOpenAtom);

  return (
    <ScaleWrapper style={containerStyle}>
      <CategoriesRangeGroup>
        <CategoriesSectionRange>
          <RangeHighlights defaultMarkersOnly={defaultMarkersOnly} />
        </CategoriesSectionRange>
        <ScaleMiddleLine defaultMarkersOnly={defaultMarkersOnly} />
        <CategoriesSectionMarkers>
          <BottomRangeSelected defaultMarkersOnly={defaultMarkersOnly} />
        </CategoriesSectionMarkers>
      </CategoriesRangeGroup>
      {withPropertyModal && (
        <PropertyModal
          id={modalId}
          isModalOpen={isPropertyModalOpen}
          onCloseModal={() => setIsPropertyModalOpen(false)}
        />
      )}
    </ScaleWrapper>
  );
};

const ScaleWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

const CategoriesRangeGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 750px;
`;

const CategoriesSectionRange = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: #ececec;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 50px;
  margin: 0 auto;
  position: relative;
`;

const CategoriesSectionMarkers = styled.div`
  width: 100%;
  padding: 0 50px;
  display: flex;
  justify-content: space-around;
`;
