import { useEntity } from '../../../../../../../providers/EntityProvider';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';

export function useCanUpdateLockStatus(
  officePrimaryEntity: number,
  lockedStatus?: Record<string, boolean | undefined>,
) {
  const user = useUser();
  const { entity } = useEntity();

  if (entity === -1 || entity === -2) {
    return Boolean(user?.superaccounts?.find(sa => sa.id === entity && sa.isAdmin));
  }

  if (entity === 0) {
    return Boolean(user?.account?.isAdmin) && !lockedStatus?.['e--1'];
  }

  const isOfficeAdmin = (user?.offices ?? []).find(e => e.id === entity && e.isAdmin);
  if (entity && isOfficeAdmin) {
    return !lockedStatus?.[`e-0`] && !lockedStatus?.['e--1'];
  }

  const isTeamAdmin = (user?.teams ?? []).find(e => e.id === entity && e.isAdmin);
  if (entity && isTeamAdmin) {
    return (
      !lockedStatus?.[`e-${officePrimaryEntity}`] &&
      !lockedStatus?.[`e-0`] &&
      !lockedStatus?.['e--1']
    );
  }

  return false;
}
