import styled from 'styled-components';

import { Comparables } from './comparables/Comparables';
import { Trends } from './trends/Trends';
import { Features } from './features/Features';
import { Estimates } from './estimates/Estimates';
import { Outliers } from './outliers/Outliers';
import { ResultsTuningLegends } from './ResultsTuningLegends';

interface Props {
  isResponsive?: boolean;
}

export const ResultsTuning = ({ isResponsive }: Props) => {
  return (
    <Container isResponsive={isResponsive}>
      <Wrapper>
        <Comparables />
        <Trends />
        <Features />
        <Estimates />
        <Outliers />
      </Wrapper>
      <ResultsTuningLegends isResponsive={isResponsive} />
    </Container>
  );
};

const Container = styled.div<{ isResponsive?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background: #f1f1f1;
  padding: 20px;
  border-radius: 7px 7px 0px 0px;
  margin-bottom: 1px;

  ${props =>
    props.isResponsive &&
    `
    @media (max-width: 1080px) {
      flex-direction: column;
      align-items: start;
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
`;
