import { useMutation } from '@tanstack/react-query';
import { usePresentationFromParams } from './usePresentationFromParams';
import { useUpdatePresentationWithoutSaving } from '../../../hooks/useUpdatePresentationWithoutSaving';
import { useReportData } from '../../../pages/PresentationCreate/useReportData';
import { useRefreshBuild } from './useRefreshBuild';

export function useUpdatePresentationWithBuild() {
  const { data: presentation } = usePresentationFromParams();
  const { mutateAsync: updatePresentationWithoutSaving } = useUpdatePresentationWithoutSaving(
    presentation?.hash,
  );

  const getReportData = useReportData();

  const refreshBuild = useRefreshBuild();

  return useMutation(async () => {
    if (!presentation?.type || !presentation?.id) return;
    await refreshBuild(presentation.type, presentation.id);

    if (!presentation?.id) return;

    const reportData = getReportData();

    await updatePresentationWithoutSaving({
      ...presentation,
      reportData,
    });
  });
}
