import React from 'react';

import { UploadImage } from '../../components/UploadImage.component';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { Container, ImagesWrapper } from './ThreeImagesGamma.styles';

interface Props {
  reverse?: boolean;
  horizontal?: boolean;
}
export const ThreeImagesGammaForm: React.FC<Props> = ({ reverse, horizontal }) => {
  const { selectedSlide, setSlideDataProperty } = useSelectedSlide();

  const onChangeHeadline = (value: string) => {
    setSlideDataProperty('headline', value);
  };

  const onChangeText = (value: string) => {
    setSlideDataProperty('text', value);
  };

  if (!selectedSlide) return null;

  let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'column';
  if (horizontal) flexDirection = 'row';
  if (horizontal && reverse) flexDirection = 'row-reverse';
  if (!horizontal && reverse) flexDirection = 'column-reverse';

  return (
    <Container style={{ flexDirection }}>
      <SlideTextBlock
        isEditable
        text={selectedSlide.data?.text}
        headline={selectedSlide.data?.headline}
        onChangeText={onChangeText}
        onChangeHeadline={onChangeHeadline}
        containerStyles={{
          height: horizontal ? '100%' : '49%',
          width: horizontal ? 'calc(65% - 60px)' : '100%',
        }}
      />
      <ImagesWrapper
        style={{
          flexDirection: horizontal ? 'column' : 'row',
          width: horizontal ? '35%' : '100%',
          marginRight: horizontal && reverse ? 60 : 0,
          marginLeft: horizontal && !reverse ? 60 : 0,
          marginBottom: horizontal || !reverse ? 0 : 40,
          marginTop: horizontal || reverse ? 0 : 40,
          height: horizontal ? '100%' : '45%',
        }}
      >
        <UploadImage
          image={selectedSlide.data?.image}
          slideDataConfigPropertyName={'image'}
          imageSizeSuggestion={horizontal ? '526x212' : '485x307'}
        />
        <UploadImage
          image={selectedSlide.data?.image2}
          slideDataConfigPropertyName={'image2'}
          imageSizeSuggestion={horizontal ? '526x212' : '485x307'}
        />
        <UploadImage
          image={selectedSlide.data?.image3}
          slideDataConfigPropertyName={'image3'}
          imageSizeSuggestion={horizontal ? '526x212' : '485x307'}
        />
      </ImagesWrapper>
    </Container>
  );
};
