import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { CMADynamicOrderableSlides } from './CMADynamicOrderableSlides.component';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { TPresentation, TSection, TSectionConfig, TSlide, TSlideId } from '../../../../../types';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../../providers/providers/SectionsProvider';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { useActiveSection } from '../../../../../components/Slide/providers/ActiveSectionProvider';
import { DynamicOrderableSections } from '../DynamicOrderableSections/DynamicOrderableSections.component';
import { usePresentationMode } from '../../../../../providers/providers/PresentationModeProvider';

interface CMADynamicSectionsProps {
  presentation: TPresentation;
}

export const CMADynamicSections: React.FC<CMADynamicSectionsProps> = ({ presentation }) => {
  const { slides } = useSlides();
  const { setSelectedSlide } = useSelectedSlide();
  const { sections: cmaSections } = useSections();
  const { sections, findSlideSection, getOrderedSlides } = useOrder();

  const { presentationMode } = usePresentationMode();
  const { activeSection, setActiveSection } = useActiveSection();

  const dummyCb = React.useCallback(() => {}, []);

  useEffect(() => {
    if (!cmaSections?.cmaSection || activeSection === cmaSections.cmaSection.id || !slides) return;

    setActiveSection(cmaSections.cmaSection.id);

    const orderedSlides = getOrderedSlides();
    const toSelectSlideId = orderedSlides.find(
      slideId => slides[slideId] && slideId !== 'greetingVideo',
    );
    setSelectedSlide(slides[toSelectSlideId || 'cmaReport'] as TSlide);
  }, [
    cmaSections,
    slides,
    presentationMode,
    activeSection,
    setActiveSection,
    getOrderedSlides,
    setSelectedSlide,
  ]);

  const extractSlideSection = (slide: TSlide) => {
    const sectionId = findSlideSection(slide.id);

    if (!sectionId) return null;

    return cmaSections[sectionId] as TSectionConfig;
  };

  if (!cmaSections?.cmaSection) return null;

  return (
    <DynamicOrderableSections
      sectionsOrder={sections as TSection[]}
      onSectionsOrderChange={dummyCb}
      sections={cmaSections as Record<TSection, TSectionConfig>}
      OrderableSlidesComponent={CMADynamicOrderableSlides}
      extractSlideSection={extractSlideSection}
      openInitialSection={!isEmpty(cmaSections)}
    />
  );
};
