import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getOrderedCards } from '../../data';
import { Card } from '../../../../../components/Card/Card.component';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '300px 300px 300px',
  gridAutoFlow: 'column',
  rowGap: 20,
  columnGap: 20,
  padding: '4%',
});

export const HomeBuyingProcessModernPDF: React.FC = () => {
  const orderedCards = useMemo(() => getOrderedCards(), []);

  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      <Grid>
        {orderedCards.map((card, i) => (
          <Card
            key={i}
            {...card}
            titleStyles={{ width: '75%' }}
            textStyles={{ width: '100%' }}
            type='pdf'
          />
        ))}
      </Grid>
    </PdfFrame>
  );
};
