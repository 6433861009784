import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { TSuperPresentation } from '../../types';
import { useUser } from '../../components/Slide/providers/UserProvider';

export function useRedirectIfUserDontMatch(presentation?: TSuperPresentation) {
  const replace = useHistory().replace;
  const { userId } = useUser();

  // @ts-ignore
  const presentationUserId = presentation?.user?.id;

  useEffect(() => {
    if (!presentationUserId || !userId) return;

    if (parseInt(presentationUserId) !== parseInt(userId)) {
      toast(`You don't have access to this presentation!`, {
        position: 'bottom-center',
        type: 'error',
      });

      return replace('/home');
    }
  }, [presentationUserId, replace, userId]);
}
