import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';

type WithWhiteBgColor = {
  isWhiteBgColor: boolean;
};

export const ContainerBackground = styled('div')<WithWhiteBgColor>(({ theme, isWhiteBgColor }) => ({
  backgroundColor: isWhiteBgColor ? '#fff' : useUiConfigColorsPrimary(),
  width: '100%',
  position: 'relative',
}));

export const ContainerWaveLighter = styled('div')({
  position: 'absolute',
  transformOrigin: 0,
  transform: 'cmaReport(.975) translateY(-5px)',
  left: 0,
});
