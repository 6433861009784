import React, { useEffect, useMemo, useState } from 'react';

import styles from './ManualSelector.module.scss';
import { SearchCombiner } from '../../SearchCombiner';
import { useBrandName } from '../../../../../../../../../hooks/useBrandName';
import { ButtonsGroup } from '../../../../../../../../../components/Common/V2/ButtonsGroup';

export const ManualSelector = ({ isManual, disabled = false, onChange, className }) => {
  const [innerIsManual, setInnerIsManual] = useState(isManual);
  const { present } = useBrandName();

  const onCombine = () => (!innerIsManual ? { reportType: 'auto' } : { reportType: 'manual' });

  useEffect(() => {
    SearchCombiner.add('reportType', onCombine);
    onChange(innerIsManual);

    return () => SearchCombiner.remove('reportType');
  }, [innerIsManual]);

  const onReportTypeSelect = isManualReport => {
    if (disabled) return;

    setInnerIsManual(isManualReport);
  };

  const buttonsConfig = useMemo(() => {
    return [
      {
        label: 'AUTO',
        value: 'auto',
        isActive: !isManual,
        onClick: () => onReportTypeSelect(false),
      },
      {
        label: 'MANUAL',
        value: 'manual',
        isActive: isManual,
        onClick: () => onReportTypeSelect(true),
      },
    ];
  }, [isManual, onReportTypeSelect]);

  return (
    <div className={`${styles.root} ${className}`}>
      <p className={styles.info}>Select “Auto” to allow {present} to find comparables.</p>
      <p className={styles.info}>Select “Manual” to add your own MLS IDs.</p>
      <div className={styles.buttonsWrapper}>
        <ButtonsGroup activeVariant='dark' buttonsConfigs={buttonsConfig} disabled={disabled} />
      </div>
    </div>
  );
};
