import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';

import { Container } from '../TextLayout.styles';

export const TextZeta: React.FC = () => {
  const slide = useSlide();

  return (
    <Container isRow isSpaceBetween>
      <SlideTextBlock
        text={slide.data?.text}
        headline={slide.data?.headline}
        containerStyles={{ width: '30%' }}
      />
      <SlideTextBlock
        text={slide.data?.text2}
        headline={slide.data?.headline2}
        containerStyles={{ width: '30%' }}
      />
      <SlideTextBlock
        text={slide.data?.text3}
        headline={slide.data?.headline3}
        containerStyles={{ width: '30%' }}
      />
    </Container>
  );
};
