import React, { useState } from 'react';
import styled from 'styled-components';
import { QuickActionsModal } from '../../../../../Common';

import WarningPopup from '../../../../../Common/WarningConfirmPopup/WarningConfirmPopup';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { isValidUrl } from '../../../../../../services/isValidUrl';
import { Input } from '../../../../../Common/V2/Input';

const ModalContentWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));

const Label = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-weight: 700;
  margin-bottom: 10px;
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const InsertVideoUrlModal: React.FC<Props> = ({ open, onClose }) => {
  const { setSlideDataProperty } = useSelectedSlide();
  const [url, setUrl] = useState('');

  const [warningOpen, setWarningOpen] = useState(false);

  const onSave = () => {
    const isUrl = isValidUrl(url);
    if (isUrl) {
      setSlideDataProperty('videoUrl', url);
      onClose();
    } else {
      setWarningOpen(true);
    }
  };

  return (
    <QuickActionsModal
      open={open}
      actionButtonText='Save & Add to Library'
      footerActionsPosition='right'
      heading='Upload Video'
      zIndex={101}
      onClose={onClose}
      onAction={onSave}
      onCancel={onClose}
    >
      <ModalContentWrapper>
        <Label>Insert video URL to embed your video</Label>
        <Input value={url} onChange={({ target }) => setUrl(target.value)} />
        {warningOpen && (
          <WarningPopup
            open={warningOpen}
            heading={`Invalid URL`}
            description='The video link must be a valid url'
            confirmButtonText='Enter again'
            onConfirm={() => setWarningOpen(false)}
            onClose={() => setWarningOpen(false)}
          />
        )}
      </ModalContentWrapper>
    </QuickActionsModal>
  );
};
