import { CardComponentProps } from '../../../../../components/Card/Card.component';
import icon1 from '../../assets/icons/1.png';
import icon2 from '../../assets/icons/2.png';
import icon3 from '../../assets/icons/3.png';
import icon4 from '../../assets/icons/4.png';

export const getCards = (isCanadaUser: boolean) => {
  return [
    {
      title: 'Where do buyers get their information?',
      text: `A home's value is directly related to what a buyer is willing to pay. Understanding where buyers get their information helps form a perspective on what a buyer is expecting to pay. The following four areas are the most common sources:`,
      primaryColorTitle: true,
      cardStyles: { gridRow: '1 / -1', border: `1px solid #DDDDDD` },
      titleStyles: { marginBottom: 80 },
    },
    {
      title: 'Online sources',
      text: isCanadaUser
        ? 'The first step the average buyer takes in their home buying process is to look online at properties for sale.'
        : 'For 44% of recent buyers, the first step that they took in the home buying process was to look online at properties for sale (source: NAR).',
      icon: icon1,
    },
    {
      title: 'Professionals',
      text: "Real Estate Agents, Lenders, Financial Planners and other Professionals are able to balance and weigh the market information in conjunction with other buyer's situation.",
      icon: icon2,
    },
    {
      title: 'Sphere of influence',
      text: "It's natural to lean on the advice and experience from friends, family and neighbors.",
      icon: icon3,
    },
    {
      title: 'Personal experience',
      text: 'It is easy to form an opinion on what something might be worth based on firsthand knowledge or perceived value.',
      icon: icon4,
    },
  ];
};
