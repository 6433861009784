import { TSlide } from '../../../../../types';
import { MarketOverview } from './web/MarketOverview';
import { MarketOverviewPdf } from './pdf/MarketOverviewPdf';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';

const id = 'marketOverview';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Market Overview',
  frame: true,
  isInteractive: true,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    shared: MarketOverviewPdf,
  },
  web: {
    shared: MarketOverview,
  },
};

export type TMarketOverview = typeof id;
export { config, mappings };
