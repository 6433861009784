import { ApiMapper } from '../ApiMapper';
import { Address } from '../../entities/Address';
import { updateEntityProperties } from '../../entities/services/EntityPropertiesUpdater';

export class AddressApiMapper extends ApiMapper {
  static apiToEntity(apiAddress) {
    return updateEntityProperties(new Address(), {
      state: apiAddress.state,
      zip: apiAddress.zip,
      city: apiAddress.city,
      deliveryLine: apiAddress.delivery_line,
      fullAddress: apiAddress?.label,
      external: apiAddress?.external,
    });
  }
}
