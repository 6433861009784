import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { TableHeadButton } from '../../../../components/Common/TableHeadButton/TableHeadButton.component';
import { AgentPerformanceUtils } from '../AgentPerformanceUtils';
import { TAgentPerformance, TAgentPerformanceSortOption } from './AgentPerformanceTable.component';

import { TableField, TableHeadContainer } from './AgentPerformanceTable.styles';

interface AgentPerformanceTableHeadProps {
  setAgentPerformanceData: Dispatch<SetStateAction<TAgentPerformance[]>>;
}

export const AgentPerformanceTableHead: React.FC<AgentPerformanceTableHeadProps> = ({
  setAgentPerformanceData,
}) => {
  const [sort, setSort] = useState<TAgentPerformanceSortOption>('name_asc');

  useEffect(() => {
    setAgentPerformanceData(prev => AgentPerformanceUtils.sort([...prev], sort));
  }, [setAgentPerformanceData, sort]);

  const onNameClick = () => {
    setSort(prev => (prev === 'name_dsc' ? 'name_asc' : 'name_dsc'));
  };

  const onEmailClick = () => {
    setSort(prev => (prev === 'email_asc' ? 'email_asc' : 'email_dsc'));
  };

  const onLastDateClick = () => {
    setSort(prev => (prev === 'lastDate_dsc' ? 'lastDate_asc' : 'lastDate_dsc'));
  };

  const onOfficeClick = () => {
    setSort(prev => (prev === 'office_dsc' ? 'office_asc' : 'office_dsc'));
  };

  const onReportsSentClick = () => {
    setSort(prev => (prev === 'reportsSent_dsc' ? 'reportsSent_asc' : 'reportsSent_dsc'));
  };

  const onUniqueClientsClick = () => {
    setSort(prev => (prev === 'uniqueClients_dsc' ? 'uniqueClients_asc' : 'uniqueClients_dsc'));
  };

  const onUniqueAddressesClick = () => {
    setSort(prev =>
      prev === 'uniqueAddresses_dsc' ? 'uniqueAddresses_asc' : 'uniqueAddresses_dsc',
    );
  };

  const onUniqueReportsClick = () => {
    setSort(prev => (prev === 'uniqueReports_dsc' ? 'uniqueReports_asc' : 'uniqueReports_dsc'));
  };

  const onOpenRateClick = () => {
    setSort(prev => (prev === 'openRate_dsc' ? 'openRate_asc' : 'openRate_dsc'));
  };

  const onTopSellerFeedbackClick = () => {
    setSort(prev =>
      prev === 'topSellerFeedback_dsc' ? 'topSellerFeedback_asc' : 'topSellerFeedback_dsc',
    );
  };
  const onTopBuyerFeedbackClick = () => {
    setSort(prev =>
      prev === 'topBuyerFeedback_dsc' ? 'topBuyerFeedback_asc' : 'topBuyerFeedback_dsc',
    );
  };

  return (
    <TableHeadContainer>
      <TableField style={{ justifyContent: 'flex-start' }}>
        <TableHeadButton
          text='Name'
          onClick={onNameClick}
          color='#000'
          fontStyles={{ fontWeight: 700 }}
          active={sort === 'name_dsc' || sort === 'name_asc'}
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'email_dsc' || sort === 'email_asc'}
          text='Email'
          onClick={onEmailClick}
          color='#000'
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'office_dsc' || sort === 'office_asc'}
          text='Office'
          onClick={onOfficeClick}
          color='#000'
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'reportsSent_dsc' || sort === 'reportsSent_asc'}
          text='Reports Sent'
          onClick={onReportsSentClick}
          color='#000'
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'lastDate_dsc' || sort === 'lastDate_asc'}
          text='Last Report Date'
          onClick={onLastDateClick}
          color='#000'
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'uniqueClients_asc' || sort === 'uniqueClients_dsc'}
          text='Unique Clients'
          onClick={onUniqueClientsClick}
          color='#000'
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'uniqueAddresses_dsc' || sort === 'uniqueAddresses_asc'}
          text='Unique Addresses'
          onClick={onUniqueAddressesClick}
          color='#000'
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'uniqueReports_dsc' || sort === 'uniqueReports_asc'}
          text='Unique Reports'
          onClick={onUniqueReportsClick}
          color='#000'
        />
      </TableField>

      <TableField>
        <TableHeadButton
          active={sort === 'openRate_dsc' || sort === 'openRate_asc'}
          text='Open Rate'
          onClick={onOpenRateClick}
          color='#000'
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'topSellerFeedback_dsc' || sort === 'topSellerFeedback_asc'}
          text='Top Sellers Feedback '
          onClick={onTopSellerFeedbackClick}
          color='#000'
        />
      </TableField>
      <TableField>
        <TableHeadButton
          active={sort === 'topBuyerFeedback_dsc' || sort === 'topBuyerFeedback_asc'}
          text='Top Buyers Feedback'
          onClick={onTopBuyerFeedbackClick}
          color='#000'
        />
      </TableField>
    </TableHeadContainer>
  );
};
