export const realtorData = {
  propertyValue: '$370,000',
  commission: '$22,000',
  serviceCharge: '$0',
  closingCosts: '$3,707',
  total: '$55,059',
};

export const iBuyerData = {
  propertyValue: '$351,500',
  commission: '$0',
  serviceCharge: '$26,363',
  closingCosts: '$3,632',
  total: '$33,294',
};

export const staticText = {
  title: 'REALTOR VS iBUYER',
  subtitle: 'Is the convenience worth the cost?',
  descriptionTitle: {
    part1: 'What is an',
    part2: 'iBuyer?',
  },
  descriptionText: {
    p1: 'An iBuyer is a company that offers to pay cash for your home, sight unseen. If you choose to accept the price, you can close in as little as two or three days.',
    p2: 'iBuyers tout the process as quick and easy, but what is the cost of this convenience.',
    p3: "iBuyers typically offer below market value for the home, costing the seller's equity. Plus, they charge full commission and other fees that can add up to as much as 13%.",
  },
  comparisonTitle: 'A comparison of what you could net',
};
