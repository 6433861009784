import React from 'react';

import { usePresentationType } from '../../../../../../../providers/providers/PresentationTypeProvider';
import { CoverBTPdf } from './CoverBTPdf';
import { CoverRegularPdf } from './CoverRegularPdf';

export const CoverPdf: React.FC = () => {
  const { presentationType } = usePresentationType();
  if (presentationType === 'buyerTour') return <CoverBTPdf />;
  return <CoverRegularPdf />;
};
