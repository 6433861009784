import { useCallback, useMemo } from 'react';
import { getSupperaccountId } from '../services/getSuperaccountId';
import { TSlide, EntityMode } from '../types';
import { usePersonalizedWithDynamicSlides } from './usePersonalizedWithDynamicSlides';

const isPersonalSlide = (slide: TSlide) => ['testimonials', 'aboutMe'].includes(slide.id);

export const useLevelDynamicSlides = (
  hash: string,
  useSpecialBranding: boolean = false,
  entity: number | null = null,
  entityMode: EntityMode = EntityMode.READ,
) => {
  const sAccountId = useMemo(
    () => getSupperaccountId({ entity, entityMode, useSpecialBranding }),
    [entity, entityMode, useSpecialBranding],
  );

  const filterLevelSlide = useCallback(
    (slide: any) => {
      if (isPersonalSlide(slide)) return true;

      if (entity === -1) return slide?.entityId === -1;

      if (entity === -2) return slide?.entityId === -1 || slide?.entityId === -2;

      if (entity === 0) return slide?.entityId === 0 || slide?.entityId === sAccountId;

      if (entity)
        return (
          slide?.entityId === entity || slide?.entityId === 0 || slide?.entityId === sAccountId
        );

      if (slide?.entityId === -2 && !useSpecialBranding) return false;

      return true;
    },
    [entity, sAccountId, useSpecialBranding],
  );

  const pdQuery = usePersonalizedWithDynamicSlides(hash);

  const slidesMap = useMemo((): Record<string, TSlide> => {
    return (Object.values(pdQuery.data ?? {})
      .filter(filterLevelSlide)
      .reduce((map: any, slide: any) => ({ ...map, [slide?.id]: { ...slide } }), {}) ??
      {}) as Record<string, TSlide>;
  }, [filterLevelSlide, pdQuery.data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ ...pdQuery, data: slidesMap }), [slidesMap]);
};
