import React, { createContext, useContext, useState } from 'react';
import { TProperty } from '../../../../../../../types';

type TSelectedProperty = {
  selectedProperty: TProperty | null;
  setSelectedProperty: (property: TProperty | null) => void;
};

const SelectedPropertyContext = createContext<TSelectedProperty | undefined>(undefined);

export function useSelectedProperty() {
  const context = useContext(SelectedPropertyContext);

  if (!context) {
    throw new Error('useSelectedProperty must be used within a SelectedPropertyContext');
  }

  return context;
}

export const SelectedPropertyProvider: React.FC = ({ children }) => {
  const [selectedProperty, setSelectedProperty] = useState<TProperty | null>(null);

  const value = React.useMemo(
    () => ({ selectedProperty, setSelectedProperty }),
    [selectedProperty],
  );

  return (
    <SelectedPropertyContext.Provider value={value}>{children}</SelectedPropertyContext.Provider>
  );
};
