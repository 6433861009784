export const title = 'What buyers want from their agents';

export const listItems = [
  {
    title: 'Finding the Right Home',
    text: '50% of buyers look to an agent to help them find the right home to purchase.',
  },
  {
    title: 'Negotiations',
    text: '12% of buyers depend on their agent to negotiate the price and the terms of the sale.',
  },
  {
    title: 'Paperwork',
    text: `7% of buyers need their agent's help with paperwork.`,
  },
  {
    title: 'Financing',
    text: '3% of buyers rely on their agent to determine how much house they can afford and to help them find and arrange financing.',
  },
  {
    title: 'Comparative Market Analysis',
    text: '7% of buyers want to know the worth of nearby homes to gain better insight into how much they should spend in a specific area.',
  },
];
