import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { TCompConfigItem } from '../../../../types';
import { CurrentUserService } from '../../../../services/CurrentUserService';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { useCategories } from '../hooks/useCategories';
import { SidebarTitle } from '../components/SidebarTitle';
import { CategoryCheckbox } from '../components/CategoryCheckbox';
import { CustomEstimates } from './CustomEstimates';
import { CustomEstimatesList } from './CustomEstimatesList';
import { ESTIMATES } from './estimatesConfig';

export const EstimatesSidebarContent = () => {
  const categoriesValues = useCategories();
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const hasValue = (field: TCompConfigItem) => {
    return !!categoriesValues[field.id];
  };
  const getStyles = (field: TCompConfigItem) => {
    if (hasValue(field)) {
      return {};
    }
    return {
      opacity: '0.5',
    };
  };

  return (
    <Container style={isPresentationView ? {} : { fontFamily: 'Figtree' }}>
      <SidebarTitle title='ESTIMATES' iconName='clipboard_list' />
      {!CurrentUserService.isCanadaUser() && (
        <EstimateLabel>
          <CategoryCheckbox field={ESTIMATES.ATTOM} />
          <Span style={getStyles(ESTIMATES.ATTOM)}>Attom</Span>
        </EstimateLabel>
      )}
      {isPresentationView ? <CustomEstimatesList /> : <CustomEstimates />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const EstimateLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const Span = styled.span`
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
