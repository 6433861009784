import { THighlightedProperty, TReportSelectedCategory } from '../../../../../types';
import { extractMinMaxPrices } from './extractMinMaxPrices';

export const calculateOffset = (
  index: number,
  categories: TReportSelectedCategory[],
  highlights: THighlightedProperty[],
  price: number,
  selectedLength: number,
  useSqft: boolean,
  useAdjPrice?: boolean,
) => {
  if (selectedLength < 2) {
    return `${index * 30}px`;
  }

  const { min, max } = extractMinMaxPrices(categories, highlights, useSqft, useAdjPrice);

  const range = max - min;
  const baseSize = 100 / range;

  return `${baseSize * (range - (max - price))}%`;
};
