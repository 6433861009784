import styles from './PropertyMatchLegends.module.scss';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';
import { ACTIVE_COLOR, CLOSED_COLOR, PENDING_COLOR } from '../../../../../../config/constants';
import { FEATURES, useHasFeatureFlag } from '../../../../../../../../../hooks/useFeatureFlags';
import btTheme from '../../../../../../../../../config/themes/bold-trail/colors';
import cpTheme from '../../../../../../../../../config/themes/core-present/colors';
import pinA from '../../../../../../../../../assets/icons/map/pin-a.svg?url';
import pinCS from '../../../../../../../../../assets/icons/map/pin-coming_soon.svg?url';
import pinCSBT from '../../../../../../../../../assets/icons/map/pin-coming_soon_bt.svg?url';
import pinP from '../../../../../../../../../assets/icons/map/pin-p.svg?url';
import pinC from '../../../../../../../../../assets/icons/map/pin-c.svg?url';

const getCountString = (propType, partialData, additional = '') => {
  const count = getCount(propType, partialData);

  if (count) {
    return `${count}${additional ? ' ' + additional : ''}`;
  }
  return `0${additional ? ' ' + additional : ''}`;
};

const getCount = (propType, partialData) => {
  if (!partialData || !partialData.properties) {
    return null;
  }
  const properties = partialData.properties;
  const { active, pending, sold, comingSoon } = properties;
  const validArrays = [active, pending, sold, comingSoon].filter(Array.isArray);

  if (propType === 'total' || propType === undefined) {
    return validArrays.flat().filter(p => !p.partialMatch && !p.excluded).length;
  }
  if (propType === 'nomatch') {
    return validArrays.flat().filter(p => p.partialMatch || p.excluded).length;
  }
  if (!properties[propType]) {
    return null;
  }
  return properties[propType].filter(p => p.partialMatch === false && !p.excluded).length;
};

export const PropertyTotalMatches = ({ partialData }) => (
  <p className={styles.total}>{getCountString(undefined, partialData, ' Total Matches')}</p>
);

export const PropertyMatchLegends = ({ partialData }) => {
  const { isCanadaUser } = useUser();

  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const comingSoonColor = hasBtTheme ? btTheme.v2.primary : cpTheme.v2.primary;

  return (
    <div className={`${styles.legend}`}>
      <PropertyTotalMatches partialData={partialData} />
      <div className={`${styles.icons}`}>
        {hasBtTheme ? <img src={pinCSBT} alt='' /> : <img src={pinCS} alt='' />}{' '}
        <span style={{ color: comingSoonColor }}>
          Coming Soon Match: {getCountString('comingSoon', partialData)}
        </span>
        <img src={pinA} alt='' />{' '}
        <span style={{ color: ACTIVE_COLOR }}>
          Active Match: {getCountString('active', partialData)}
        </span>
        {!isCanadaUser && (
          <>
            <img src={pinP} alt='' />{' '}
            <span style={{ color: PENDING_COLOR }}>
              Pending Match: {getCountString('pending', partialData)}
            </span>
          </>
        )}
        <img src={pinC} alt='' />{' '}
        <span style={{ color: CLOSED_COLOR }}>
          Sold Match: {getCountString('sold', partialData)}
        </span>
      </div>
      <span className={styles.info}>
        You can manually add a comparable by clicking the gray icon.
      </span>
    </div>
  );
};
