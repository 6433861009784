import styled from 'styled-components';
import { ButtonsGroup } from '../../../../../components/Common/V2/ButtonsGroup';
import { useState } from 'react';

const ControlsContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
`;

interface Props {
  mapInstance: google.maps.Map | null;
}

export const MapTypeControls = ({ mapInstance }: Props) => {
  const [mapType, setMapType] = useState(mapInstance?.getMapTypeId() || 'roadmap');
  const roadmapButtonStyle = {
    width: '40%',
    flex: '0 1 auto',
  };
  const satelliteButtonStyle = {
    width: '60%',
    flex: '0 1 auto',
  };
  const buttonsConfigs = [
    {
      label: 'Map',
      value: 'roadmap',
      onClick: () => {
        mapInstance?.setMapTypeId('roadmap');
        setMapType('roadmap');
      },
      style:
        mapType === 'roadmap' ? { fontWeight: 'bold', ...roadmapButtonStyle } : roadmapButtonStyle,
    },
    {
      label: 'Satellite',
      value: 'satellite',
      onClick: () => {
        mapInstance?.setMapTypeId('satellite');
        setMapType('satellite');
      },
      style:
        mapType === 'satellite'
          ? { fontWeight: 'bold', ...satelliteButtonStyle }
          : satelliteButtonStyle,
    },
  ];
  return (
    <ControlsContainer>
      <ButtonsGroup buttonsConfigs={buttonsConfigs} groupWidth={'141px'} disabled={false} />
    </ControlsContainer>
  );
};
