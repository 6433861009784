import React, { useRef, useEffect } from 'react';
import { useSlide } from '../../../../providers/SlideProvider';
import { ImageProxy } from '../../../../services/ImageProxy';
import { Container, ImagePreview } from './SingleImage.styles';

interface Props {
  isWithFrame?: boolean;
}

export const SingleImagePdf: React.FC<Props> = ({ isWithFrame }) => {
  const slide = useSlide();

  const image = ImageProxy.optimize(slide.data?.image ?? '', { height: 1500 });
  const imagePdf = ImageProxy.optimize(slide.data?.image2 ?? '', { height: 2250 });

  return (
    <Container style={!isWithFrame ? { width: 1000, height: 1294 } : {}}>
      {(!!image || !!imagePdf) && (
        <ImagePreview
          style={{
            width: '100%',
            height: imagePdf ? `100%` : 534,
            objectFit: 'contain',
          }}
          src={imagePdf || image}
        />
      )}
    </Container>
  );
};
