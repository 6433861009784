import React from 'react';
import { TPersonalizeSlide } from '../../../../../../types';
import { Wrapper, Container, ContentWrapper } from '../common.styles';
import { PersonalizationAside } from '../components/PersonalizationAside/PersonalizationAside.component';

import { ScaleContent } from './ScaleContent';

interface Props {
  slideId: TPersonalizeSlide;
  isEditable?: boolean;
}

export const PersonalSlidesLayout: React.FC<Props> = ({ slideId, isEditable, children }) => {
  return (
    <Wrapper>
      <PersonalizationAside activeSlide={slideId} />
      <Container>
        <ContentWrapper>
          <ScaleContent skipScaling={isEditable} predefinedHeight={805} predefinedWidth={912}>
            {children}
          </ScaleContent>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
