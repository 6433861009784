import React, { useEffect, useRef, useState } from 'react';

import Slider from './components/Slider/Slider';
import { HeatmapAtomValueUpdater, useTracking } from './hooks/useTracking';
import { TPresentation, TSlide, TSuperPresentation } from '../../types';
import { useCurrentSlide } from './hooks/useCurrentSlide';
import { PresentationWrapper } from './Presentation.styles';
import TableOfContent from './components/TableOfContent/TableOfContent';
import { FramelessLayout } from '../../layouts/FramelessLayout/FramelessLayout';
import PresentationSlide from './components/PresentationSlide/PresentationSlide';
import { ScaleFactorProvider } from '../../components/PreviewSlide/ScaleFactorProvider';
import { useRevealInstance, useRevealSlideNavigation } from './hooks/useRevealSlideNavigation';
import { useInteractiveIconTooltipVisibility } from './hooks/useInteractiveIconTooltipVisibility';
import { InteractiveSlideIcon } from './components/IntercativeSlideIcon/InteractiveSlideIcon.component';
import { ProceedToPresentation } from './components/ProceedToPresentation/ProceedToPresentation.component';
import { useCalculateScaleFactor } from '../../hooks/useCalculateScaleFactor';

interface PresentationContentProps {
  presentation: TSuperPresentation;
  slides: TSlide[];
  hideProgressTracker?: boolean;
}

export const PresentationContent: React.FC<PresentationContentProps> = ({
  presentation,
  slides,
  hideProgressTracker,
}) => {
  const [showToc, setShowToc] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const [Reveal, setRevealInstance] = useRevealInstance();
  useTracking(slides, Reveal, presentation?.hash);
  useRevealSlideNavigation(slides, Reveal);

  const { scaleFactor } = useCalculateScaleFactor({
    current: containerRef?.current,
  });

  const currentSlide = useCurrentSlide(slides, Reveal);

  const getCurrentSlide = (): TSlide => {
    const index = (Reveal as any)?.getState()?.indexh;
    if (isNaN(index)) return slides[0];
    return slides[index];
  };

  useInteractiveIconTooltipVisibility();

  const renderToc = (slide: TSlide) => {
    if (!slide?.frame || !showToc) return null;
    const index = (Reveal as any)?.getState()?.indexh;
    if (isNaN(index)) return null;
    const isActive = slides[index]?.id === slide.id;
    return isActive ? (
      <TableOfContent activeSlide={slides[index].id} onClose={() => setShowToc(false)} />
    ) : null;
  };

  const onMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (!target) return;
    const show = !!target?.closest(`#progress-tracker, #presentation-toc`);
    if (show) setShowToc(true);
  };

  return (
    <ScaleFactorProvider factor={scaleFactor}>
      <HeatmapAtomValueUpdater />
      <FramelessLayout>
        <PresentationWrapper
          onMouseOver={onMouseOver}
          onMouseEnter={() => setShowToc(false)}
          ref={containerRef}
        >
          <Slider.Container onInit={setRevealInstance}>
            {slides.map(slide => {
              if (!slide) return;
              return (
                <Slider.Item key={slide.id}>
                  {renderToc(slide)}
                  <PresentationSlide
                    theme={presentation?.ui?.theme}
                    infoPlacement={presentation?.ui?.infoPlacement}
                    slide={slide}
                    user={presentation?.user}
                    presentationHash={presentation.hash}
                    hideProgressTracker={hideProgressTracker}
                  />
                  {slide.isInteractive && (
                    <InteractiveSlideIcon
                      infoPlacement={presentation?.ui?.infoPlacement}
                      isFirstInteractiveSlide={
                        slides.filter(slide => slide.isInteractive)[0].id === slide.id
                      }
                    />
                  )}
                </Slider.Item>
              );
            })}
          </Slider.Container>
          {currentSlide?.id === 'greetingVideo' && <ProceedToPresentation />}
        </PresentationWrapper>
      </FramelessLayout>
    </ScaleFactorProvider>
  );
};
