import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
  display: flex;
  flex: 1;
  overflow: hidden;
  gap: 20px;
`;

export const LeftCol = styled('div')({
  width: 278,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: 10,
});

export const RightCol = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: 10,
});

export const SlideWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  justifyContent: 'center',
});

export const PdfWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: 'calc(100vw - 338px)',
  maxHeight: 'calc(100vh - 300px)',
  minHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'flex',
    background: theme.colors.v2.gray[200],
    width: 6,
    paddingRight: 2,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.colors.v2.gray[400],
    borderRadius: 100,
    width: 4,
  },
}));

export const PdfInsideWrapper = styled('div')({
  width: '50%',
});
