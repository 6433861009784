import { RawReport, ReportCompAverages, ReportEstimates, ReportFlagValues } from '../../../types';
import { getCompsConfigAsCollection } from './getCompsConfigAsCollection';

export const getCategoriesFromIds = (
  ids: (keyof ReportCompAverages | keyof ReportEstimates | keyof ReportFlagValues)[],
  reportDetailed: RawReport,
) => {
  const categories = {
    ...reportDetailed.flags,
    ...reportDetailed.compAverages,
    ...reportDetailed.estimates,
  };

  const comps = getCompsConfigAsCollection();

  return ids
    ?.map(categoryId => {
      const value = categories[categoryId];
      const valuePerSqft =
        categories[
          (categoryId + '_per_sqft') as
            | keyof ReportCompAverages
            | keyof ReportEstimates
            | keyof ReportFlagValues
        ];

      return comps
        .filter(configOption => configOption.id === categoryId)
        .map(config => ({
          ...config,
          icon: config.icon,
          name: config.labelHtml,
          value: value,
          valuePerSqft: valuePerSqft,
        }));
    })
    ?.flat()
    ?.sort((a, b) => a.value - b.value);
};
