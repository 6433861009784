import styled from 'styled-components';

interface WithActive {
  isActive?: boolean;
  numberOfItems: number;
}

export const ButtonsContainer = styled('div')({
  display: 'flex',
  borderRadius: '5px',
  boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.07)',
  flexShrink: 0,
});

export const Button = styled('button')<WithActive>(
  ({ theme, isActive = false, numberOfItems = 1 }) => ({
    display: 'inline',
    width: `calc(100%/${numberOfItems})`,
    color: isActive ? theme.colors.primary.contrastText : theme.colors.gray[400],
    fontSize: '13px',
    backgroundColor: isActive ? theme.colors.v2.primary : '#fff',
    border: isActive
      ? `1px solid ${theme.colors.v2.primary}`
      : `1px solid ${theme.colors.gray[200]}`,
    borderLeft: 'none',
    padding: '9px 0',
    cursor: 'pointer',
    overflow: 'hidden',
    textTransform: 'capitalize',
    '&:focus': {
      outline: 'none',
    },
    '&:first-of-type': {
      borderLeft: isActive
        ? `1px solid ${theme.colors.v2.primary}`
        : `1px solid ${theme.colors.gray[200]}`,
      borderRadius: '5px 0px 0px 5px',
    },
    '&:last-of-type': {
      borderRadius: '0px 5px 5px 0px',
    },
  }),
);
