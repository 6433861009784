import { useAuthMe } from '../../hooks/useAuthMe';
import { useScript } from '../../hooks/useScript';
import { TApiUser } from '../../types';

const scriptSrc = 'https://remax.my.site.com/ESWIREChat1694022628525/assets/js/bootstrap.min.js';
const scrt2URL = 'https://remax.my.salesforce-scrt.com';
const chatCode = '00D0b000000Cieh';
const uri = 'https://remax.my.site.com/ESWIREChat1694022628525';

// const scriptSrc =
//   'https://remax--uat.sandbox.my.site.com/ESWIREChat1694022628525/assets/js/bootstrap.min.js';
// const scrt2URL = 'https://remax--uat.sandbox.my.salesforce-scrt.com';
// const chatCode = '00D7j0000004g63';
// const uri = 'https://remax--uat.sandbox.my.site.com/ESWIREChat1694022628525';

function initEmbeddedMessaging(user: TApiUser) {
  try {
    window.embeddedservice_bootstrap.settings.language = 'en_US';

    window.addEventListener('onEmbeddedMessagingReady', () => {
      console.log('Inside Prechat API!!');
      window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
        MCID: user.external_vendor_id,
      });
    });

    window.embeddedservice_bootstrap.init(chatCode, 'IRE_Chat', uri, {
      scrt2URL,
    });
  } catch (err) {
    console.error('Error loading Embedded Messaging: ', err);
  }
}

export function RemaxChat() {
  const { data: user } = useAuthMe();
  useScript(scriptSrc, {
    enabled: Boolean(user),
    onLoad: () => initEmbeddedMessaging(user as TApiUser),
  });

  return <></>;
}
