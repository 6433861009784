import React from 'react';

import { CardsSection } from './components/CardsSection/CardsSection.component';
import { ReportsSent, TReportsSent } from './engagementMetricsGraphs/ReportsSent.component';
import {
  ReportsSentPerAgent,
  TReportsSentPerAgent,
} from './engagementMetricsGraphs/ReportsSentPerAgent.component';
import {
  ReportOpenRate,
  TReportOpenRate,
} from './engagementMetricsGraphs/ReportOpenRate.component';

import {
  SectionContainer,
  SectionContent,
  SectionHeader,
  Typography,
  HeaderButtons,
  Button,
  ButtonText,
} from './AnalyticsSection.styles';
import {
  NextStepsFeedback,
  TNextStepsFeedback,
} from './TrackingFeedback/NextStepsFeedback.component';
import { SellerBarrierBreakdown } from './TrackingFeedback/SellerBarrierBreakdown.component';
import { TPercentagesBarData } from './components/PercentageGraph/PercentageGraph.component';
import { BuyerBarrierBreakdown } from './TrackingFeedback/BuyerBarrierBreakdown.component';
import { useBrandName } from '../../hooks/useBrandName';

type TEngagementMetricsData = {
  reportsSent: TReportsSent;
  reportsOpenRate: TReportOpenRate;
  reportsSentPerAgent: TReportsSentPerAgent;
};

type TTrackingFeedbackData = {
  nextStepsFeedback: TNextStepsFeedback;
  sellerBarrierBreakdown: TPercentagesBarData[];
  buyerBarrierBreakdown: TPercentagesBarData[];
};

interface AnalyticsSectionProps {
  name: string;
  engagementMetricsData: TEngagementMetricsData;
  trackingFeedbackData: TTrackingFeedbackData;
  withHeaderButtons?: boolean;
  isWithAgentData?: boolean;
  type: 'all' | 'seller' | 'buyer';
}

export const AnalyticsSection: React.FC<AnalyticsSectionProps> = ({
  name,
  engagementMetricsData,
  trackingFeedbackData,
  withHeaderButtons,
  isWithAgentData,
  type = 'all',
}) => {
  const { present } = useBrandName();
  return (
    <SectionContainer>
      <SectionHeader>
        <Typography variant='h2' color='#333' style={{ fontWeight: 500 }}>
          {present}: {name}
        </Typography>
        {withHeaderButtons && (
          <HeaderButtons>
            <Button
              btnstyle='option'
              icon={{ name: 'expand', position: 'right' }}
              onClick={console.log}
            >
              <ButtonText>Last 30 days</ButtonText>
            </Button>
            <Button btnstyle='primary' onClick={console.log} style={{ marginLeft: 9 }}>
              Update
            </Button>
          </HeaderButtons>
        )}
      </SectionHeader>
      <SectionContent>
        <CardsSection title='Engagement metrics'>
          <ReportsSent
            reportsSent={engagementMetricsData.reportsSent}
            isWithAgentData={isWithAgentData}
          />
          {engagementMetricsData.reportsSentPerAgent && (
            <ReportsSentPerAgent
              reportsSentPerAgent={engagementMetricsData.reportsSentPerAgent}
              isWithAgentData={isWithAgentData}
            />
          )}
          <ReportOpenRate
            reportsOpenRate={engagementMetricsData.reportsOpenRate}
            isWithAgentData={isWithAgentData}
          />
        </CardsSection>
        <CardsSection title='Tracking Feedback'>
          <NextStepsFeedback
            type={type}
            nextStepsFeedback={trackingFeedbackData.nextStepsFeedback}
            isWithAgentData={isWithAgentData}
          />
          {type !== 'buyer' && (
            <SellerBarrierBreakdown
              sellerBarrierBreakdown={trackingFeedbackData.sellerBarrierBreakdown}
              isWithAgentData={isWithAgentData}
            />
          )}
          {type !== 'seller' && (
            <BuyerBarrierBreakdown
              buyerBarrierBreakdown={trackingFeedbackData.buyerBarrierBreakdown}
              isWithAgentData={isWithAgentData}
            />
          )}
        </CardsSection>
      </SectionContent>
    </SectionContainer>
  );
};
