import React, { useEffect } from 'react';

export const useOutsideClick = (ref: React.RefObject<HTMLElement> | null, callback: () => void) => {
  useEffect(() => {
    if (!ref) return;

    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
