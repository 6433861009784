export const listItems = [
  {
    title: 'Establish Budget',
    text: 'I highly recommend getting pre-qualified for a loan amount before you start your home search.',
  },
  {
    title: 'Wishlist',
    text: 'What are you looking for in a home? What are “must-haves,” and what are just “nice-to-haves”?',
  },
  {
    title: 'Buyer Tour',
    text: 'To find the perfect property to write an offer on, let’s look at properties, revise, and refine the wishlist.',
  },
  {
    title: 'Make an offer',
    text: 'I’ll run a comparative market analysis (CMA), and we’ll discuss a fair market value.',
  },
  {
    title: 'Offer Accepted',
    text: 'From the start of escrow opening, I’ll guide you through inspections and other critical decisions until keys are exchanged – and beyond.',
  },
];
