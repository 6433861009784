import styled from 'styled-components';

export const ContentContainer = styled('div')({
  width: '100%',
  height: '100%',
  padding: 40,
});

export const ContentWrapper = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 20,
  overflow: 'hidden',
});
