import styled from 'styled-components';
import { RadioButtons } from '../../../components/Common';

export { RadioButtons };

type WithSelected = {
  isSelected: boolean;
};

type ThemeSelectionContainerProps = {
  isOpen: boolean;
  isPositionLeft?: boolean;
};

export const ThemeSelectionContainer = styled('div')<ThemeSelectionContainerProps>(
  ({ isOpen, isPositionLeft }) => ({
    height: isOpen ? 190 : 0,
    position: 'absolute',
    left: isPositionLeft ? 'auto' : 0,
    right: isPositionLeft ? 0 : 'auto',
    top: '100%',
    width: 444,
    zIndex: 10,
    overflow: 'hidden',
  }),
);

export const ThemeSelectionWrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.colors.v2.background};
  border-radius: 10px;
`;

export const CloseIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

export const ContentWrapper = styled.div`
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const RadioButtonsWrapper = styled('div')({
  width: 360,
});

export const ThemeImagesWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const ThemeImageWrapper = styled('div')({
  width: 104,
  cursor: 'pointer',
  '&:not(:last-of-type)': {
    marginRight: 17,
  },
});

export const ThemeImage = styled('img')<WithSelected>(({ isSelected }) => ({
  width: '100%',
  border: isSelected ? '3px solid #444' : 'none',
  filter: isSelected ? 'drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.3))' : 'none',
}));

export const PreviewSlideWrapper = styled('div')<WithSelected>(({ isSelected }) => ({
  border: isSelected ? '3px solid #444' : 'none',
  filter: isSelected ? 'drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.3))' : 'none',
  height: 61.5,
  width: '100%',
  pointerEvents: 'none',
}));
