import styled from 'styled-components';

import { TProperty } from '../../../../types';
import { SectionDetailItem } from '../components/SectionDetailItem';
import { useDaysOnMarket } from '../../../../hooks/daysOnMarket/useDaysOnMarket';
import { getPropertyId } from '../../../../features/report/services/getPropertyId';
import { getPropertyTypeString } from '../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';

interface Props {
  property: TProperty;
}

export const Info = ({ property }: Props) => {
  const DOM = useDaysOnMarket(property);
  const id = getPropertyId(property);

  return (
    <Wrapper>
      {property.status !== 'pending' && (
        <SectionDetailItem label={DOM.label} value={String(DOM.value)} />
      )}
      <SectionDetailItem
        hide={property.status === 'sold'}
        label='Listing Date'
        value={property.listDateHuman}
      />
      <SectionDetailItem
        hide={property.status !== 'sold'}
        label='Sold'
        value={property.saleDateHuman}
      />
      <SectionDetailItem label='MLS ID' value={id.toUpperCase()} />
      <SectionDetailItem
        label='Property Type'
        value={getPropertyTypeString(property.propertyType)}
      />
      <SectionDetailItem label='Listing Type' value='Residential' />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
