import { TUser } from '../types';

class _CurrentUserService {
  private static instance: _CurrentUserService;
  private user: TUser | null = null;

  private constructor() {}

  public static instantiate() {
    if (!_CurrentUserService.instance) {
      _CurrentUserService.instance = new _CurrentUserService();
    }

    return this.instance;
  }

  public remove() {
    this.user = null;
  }

  public getUser() {
    return this.user;
  }

  public setUser(user: TUser | null) {
    this.user = user;
  }

  public isCanadaUser(): boolean {
    return !!this.user?.is_canada_user;
  }
}

export const CurrentUserService = _CurrentUserService.instantiate();
