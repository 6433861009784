import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import {
  Container,
  LeftCol,
  RightCol,
  SlideWrapper,
  PdfWrapper,
  PdfInsideWrapper,
} from './PresentationEditBody.styles';
import { SectionsColumn } from '../SectionsColumn';
import PreviewSlide from '../../PreviewSlide/PreviewSlide.component';
import { useUiConfigTheme } from '../../../providers/providers/UiConfigThemeProvider';
import { useSelectedSlide } from '../../../providers/providers/SelectedSlideProvider';
import { useActiveSection } from '../../Slide/providers/ActiveSectionProvider';
import { useUiConfigInfoPlacement } from '../../../providers/providers/UiConfigInfoPlacementProvider';
import { usePreviewMode } from '../../../pages/PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { SlideAndPresentationActions } from '../../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/TopBodyContent/SlideAndPresentationActions';
import { SectionsInfo } from '../../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/TopBodyContent/SectionsInfo.component';
import { WebAspectRatioBox } from '../../Slide/WebAspectRatioBox';

type Props = {
  multiPageSlides?: string[];
};

export const PresentationEditBody: React.FC<Props> = ({ multiPageSlides }) => {
  const { previewMode } = usePreviewMode();
  const { theme } = useUiConfigTheme();
  const { infoPlacement } = useUiConfigInfoPlacement();
  const { selectedSlide } = useSelectedSlide();
  const { activeSection } = useActiveSection();

  const { hash } = useParams<{ hash: string }>();

  if (!selectedSlide) return null;

  const SlideOrPdfWrapper = previewMode === 'web' ? SlideWrapper : PdfWrapper;
  const InsideWrapper = previewMode === 'web' ? WebAspectRatioBox : PdfInsideWrapper;

  const showMultiPages = (multiPageSlides ?? []).includes(selectedSlide.id);

  return (
    <Container>
      <LeftCol>
        {previewMode === 'web' && <SectionsInfo />}
        <SectionsColumn />
      </LeftCol>

      <RightCol>
        <SlideAndPresentationActions />
        <SlideOrPdfWrapper>
          <InsideWrapper>
            <PreviewSlide
              key={`${selectedSlide.id}-${previewMode}`}
              platform={previewMode}
              slide={selectedSlide}
              theme={theme}
              infoPlacement={infoPlacement}
              activeSection={activeSection || ''}
              presentationHash={hash}
              showMultiPages={showMultiPages}
              hideProgressTracker
            />
          </InsideWrapper>
        </SlideOrPdfWrapper>
      </RightCol>
    </Container>
  );
};
