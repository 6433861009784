import styled from 'styled-components';
import { InputHTMLAttributes } from 'react';

import { ColorOpacityApplier } from '../../Slide/services/ColorOpacityApplier';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (newState: boolean) => void;
};

export function Switch({ disabled = false, checked = false, onChange, ...props }: Props) {
  function onSwitch() {
    onChange?.(!checked);
  }

  return (
    <Slider disabled={disabled} checked={checked}>
      <Input {...props} type='checkbox' onClick={onSwitch} checked={checked} disabled={disabled} />
      <Circle checked={checked} />
    </Slider>
  );
}

const Slider = styled.label<{ disabled: boolean; checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  height: 20px;
  padding: 4px 5px;
  border-radius: 100px;
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  background: ${({ theme }) => `${theme.colors.v2.background}`};
  border: ${({ theme }) => `1px solid ${theme.colors.v2.gray[300]}`};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  :hover {
    & > div {
      box-shadow: 0 0 0px 4px
        ${({ theme, checked }) =>
          ColorOpacityApplier.hex(
            checked ? theme.colors.v2.primary : theme.colors.v2.gray[300],
            '0.25',
          )};
    }
  }
`;
const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
`;

const Circle = styled.div<{ checked: boolean }>`
  display: flex;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  transition: 0.4s;
  border-radius: 100px;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.v2.primary : theme.colors.gray[300]};
  transform: ${({ checked }) => (checked ? 'translateX(11px)' : 'translateX(0px)')};
`;
