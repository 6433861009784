import React, { useState } from 'react';
import { Error } from './Error/Error';

const CriteriaIsOpenContext = React.createContext({});

export const useCriteriaIsOpen = criteria => {
  const context = React.useContext(CriteriaIsOpenContext);

  if (!context) throw new Error('useCriteriaIsOpen cannot be used without CriteriaIsOpenContext');

  const [criterias, setCriteriaState] = context;

  const criteriaValue = criterias?.[criteria] ?? false;
  const setCriteriaValue = React.useCallback(
    newState => {
      setCriteriaState(criteria, newState);
    },
    [criteria, setCriteriaState],
  );

  return React.useMemo(() => [criteriaValue, setCriteriaValue], [criteriaValue]);
};

export const useCriteriaIsOpenSetter = () => {
  const context = React.useContext(CriteriaIsOpenContext);

  if (!context) throw new Error('useCriteriaIsOpen cannot be used without CriteriaIsOpenContext');

  const [criterias, setCriteriaState] = context;
  return React.useCallback(
    (criteria, value) => {
      const toggle = !criterias[criteria];
      setCriteriaState(criteria, toggle);
    },
    [criterias],
  );
};

export function CriteriaIsOpenProvider({ ...props }) {
  const [criteriaIsOpen, setCriteriaIsOpen] = useState({});

  const setCriteria = React.useCallback(
    function setCriteria(criteria, newState) {
      setCriteriaIsOpen(prevState => ({
        ...prevState,
        [criteria]: newState,
      }));
    },
    [criteriaIsOpen],
  );

  const value = React.useMemo(() => [criteriaIsOpen, setCriteria], [criteriaIsOpen]);

  return <CriteriaIsOpenContext.Provider value={value} {...props} />;
}
