import { TUserConfigs } from '../../../types';
import { CATEGORIES } from '../../../constants/categories';
import { corePresentApi } from '../../../api/CorePresentApi';
import { KvCoreEntityAdapter } from '../../../api/adapters/KvCoreEntityAdapter';
import { SlidesConfigOrderAdapter } from '../../../services/SlidesConfigOrderAdapter';
import { OrderByTypeAdapter } from '../../../services/OrderByTypeAdapter';

type TUpdatePayload = {
  config: any;
  entityId: number | null;
};

export class CustomizationSettingsApi {
  private static entityGroupsToMap(response: any) {
    if (!response?.length) return null;

    const entityConfigsMap = response.reduce((map: any, config: any) => {
      if (config.data) {
        Object.keys(config.data).forEach(category => {
          if (config.data[category]?.order && config.data[category]?.orderByType) return;

          if (!config.data[category].order && !config.data[category].orderByType) return;

          if (!config.data[category]?.order && config.data[category]?.slides) {
            const fullOrder = SlidesConfigOrderAdapter.toNewConfig(config.data[category].slides);
            config.data[category].order = fullOrder;
          }

          config.data[category].orderByType = OrderByTypeAdapter.getOrderByType(
            config.data[category],
          );

          delete config.data[category].slides;
        });
      }

      const entityMap = CustomizationSettingsApi.entityToMap(config);

      return { ...map, ...entityMap };
    }, {});

    return entityConfigsMap;
  }

  private static entityToMap(config: any) {
    if (!config) return {};

    const entityId = config.owner_type === 'user_id' ? 'e-null' : `e-${config.kvcore_entity_id}`;

    return { [entityId]: config };
  }

  static async fetch(hash: string): Promise<any | null> {
    const res = await corePresentApi.get(`/presentation-customizations`, {}, { hash });

    return CustomizationSettingsApi.entityGroupsToMap(res);
  }

  private static stripLockStatusEntities(configs: TUserConfigs, entityId: number | null = null) {
    function strip(config: any, entity: number | null) {
      if (typeof config !== 'object') return;

      Object.keys(config).forEach(entityKey => {
        if (entityKey !== String(`e-${entity}`)) {
          delete config[entityKey];
        }
      });
    }

    Object.values(CATEGORIES).forEach(category => {
      strip(configs[category]?.ui.colors?.primary?.lockedStatus, entityId);
      strip(configs[category]?.ui.colors?.secondary?.lockedStatus, entityId);
      strip(configs[category]?.ui.fonts?.header?.lockedStatus, entityId);
      strip(configs[category]?.ui.fonts?.body?.lockedStatus, entityId);
      strip(configs[category]?.ui.logos?.first?.lockedStatus, entityId);
      strip(configs[category]?.ui.infoPlacement?.lockedStatus, entityId);
    });
  }

  private static stripKvCoreLogoFromNonSuperaccoutn(
    configs: TUserConfigs,
    entityId: number | null = null,
  ) {
    Object.values(CATEGORIES).forEach(category => {
      if (entityId !== -1 && entityId !== -2) {
        delete configs[category]?.ui?.useKvCoreLogo;
      }
    });
  }

  static async update({ config = {}, entityId = null }: TUpdatePayload): Promise<any> {
    CustomizationSettingsApi.stripLockStatusEntities(config, entityId);
    CustomizationSettingsApi.stripKvCoreLogoFromNonSuperaccoutn(config, entityId);

    const res = await corePresentApi.post(`/presentation-customizations`, {
      data: JSON.stringify(config),
      owner_type: KvCoreEntityAdapter.toOwner(entityId),
      kvcore_entity_id: entityId,
    });

    return CustomizationSettingsApi.entityToMap(res);
  }

  static async deleteSlide(uuid: string) {
    const res = await corePresentApi.delete(`/presentation-customizations/for-slide`, { id: uuid });

    return res;
  }
}
