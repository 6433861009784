import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Input } from '../../../../../Common/V2/Input';
import styled from 'styled-components';

interface Props {
  value?: number | string;
  name: string;
  placeholder: string;
  onChange: (val?: number | string) => void;
  prefix?: '' | '$';
  isAllowed?: (values: NumberFormatValues) => boolean;
}

export const ComparableExtensionPriceInput = ({
  value,
  name,
  placeholder,
  onChange,
  prefix,
  isAllowed,
}: Props) => {
  return (
    <Wrapper>
      <Label htmlFor={name}>Value (+/-)</Label>
      <NumberFormat
        customInput={Input}
        name={name}
        value={value}
        onValueChange={({ floatValue }) => onChange(floatValue)}
        thousandSeparator={true}
        prefix={prefix}
        placeholder={placeholder}
        min='0'
        isAllowed={isAllowed}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.label`
  color: ${props => props.theme.colors.v2.gray[400]};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;
