import styled from 'styled-components';

import { Button } from '../../../../../../components/Common';

export { Button };

export const ModalFooter = styled('div')({
  borderTop: '1px solid #DDDDDD',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: 60,
  padding: '0 24px',
  backgroundColor: '#fff',
  borderRadius: '0px 0px 8px 8px',
  gap: '10px',
});
