import React, { useContext, useState } from 'react';

import { TDynamicSectionId } from '../../../../../../types';

type ModalActiveSectionContextType = {
  modalActiveSection: TDynamicSectionId | undefined;
  setModalActiveSection: (value: TDynamicSectionId | undefined) => void;
  resetModalActiveSection: () => void;
};
const ModalActiveSectionContext = React.createContext<ModalActiveSectionContextType | undefined>(
  undefined,
);

export const useModalActiveSection = () => {
  const context = useContext(ModalActiveSectionContext);

  if (context === undefined) {
    throw new Error('useActiveSection cannot be used without ModalActiveSectionContext');
  }

  return context;
};

export const ModalActiveSectionProvider: React.FC = ({ children }) => {
  const [modalActiveSection, setModalActiveSection] = useState<TDynamicSectionId | undefined>(
    undefined,
  );

  const resetModalActiveSection = React.useCallback(() => setModalActiveSection(undefined), []);

  const value = React.useMemo(
    () => ({
      modalActiveSection,
      setModalActiveSection,
      resetModalActiveSection,
    }),
    [modalActiveSection, resetModalActiveSection],
  );

  return (
    <ModalActiveSectionContext.Provider value={value}>
      {children}
    </ModalActiveSectionContext.Provider>
  );
};
