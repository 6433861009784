import axios from 'axios';

class Http {
  get(url, options = {}, params = {}) {
    return axios({
      method: 'GET',
      url,
      params,
      ...options,
    }).then(res => {
      return res.data;
    });
  }

  post(url, options = {}, params = {}) {
    return axios({
      method: 'POST',
      url,
      params,
      ...options,
    }).then(res => {
      return res.data;
    });
  }
}

export const http = new Http();
