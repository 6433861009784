import React from 'react';
import { useParams } from 'react-router-dom';

import { SlideContainer } from './styles';
import { TSlide } from '../../../../../../../types';
import PreviewSlide from '../../../../../../../components/PreviewSlide/PreviewSlide.component';
import { useUiConfigTheme } from '../../../../../../../providers/providers/UiConfigThemeProvider';
import { useUiConfigInfoPlacement } from '../../../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { Typography } from '../../../../../../../components/Common';

interface Props {
  slide: TSlide;
  isSelected: boolean;
  onClick: () => void;
}
export const SelectableSlide: React.FC<Props> = ({ slide, isSelected, onClick }) => {
  const { theme } = useUiConfigTheme();
  const { infoPlacement } = useUiConfigInfoPlacement();

  const { hash } = useParams<{ hash: string }>();

  return (
    <SlideContainer
      disabled={!isSelected}
      onClick={onClick}
      style={{ pointerEvents: slide.id === 'closingAndNextSteps' ? 'none' : 'auto' }}
    >
      <Typography
        variant='body1'
        style={{ fontWeight: 500, position: 'absolute', top: 13, left: 10 }}
        color='400'
      >
        {slide.label}
      </Typography>
      <PreviewSlide
        slide={slide}
        theme={theme}
        infoPlacement={infoPlacement}
        style={{ pointerEvents: 'none' }}
        presentationHash={hash}
      />
      <Typography variant='small' style={{ fontWeight: 400, paddingTop: 2 }} color='400'>
        {isSelected ? 'Included' : 'Not Included'}
      </Typography>
    </SlideContainer>
  );
};
