import { useEffect } from 'react';

import { useEmphasis } from '../../EmphasisProvider';
import { useStaticConfigs } from '../useEmphasisConfigs';
import { InheritanceLevel } from '../../../../../../../../types';
import { stringifySlidesOrdering } from '../../services/stringifySlidesOrdering';
import { useOrder } from '../../../../../../../../providers/providers/OrderProvider';
import { usePresentationMeta } from '../../../../../../../../providers/providers/PresentationMetaProvider';
import { useUpdateOrderingOnCategoryChange } from './useUpdateOrderingOnCategoryChange';
import { useInheritanceCategoryCustomizations } from './useInheritanceCategoryCustomizations';

export function useApplyOrdering() {
  const { sectionsSlide, sections, setSectionsOrder } = useOrder();

  const { emphasis, emphasisConfigs, setEmphasis } = useEmphasis();
  const staticConfigs = useStaticConfigs();
  const currentSlidesOrderingStr = stringifySlidesOrdering({ sectionsSlide, sections });

  const { inheritanceLevel } = usePresentationMeta();

  const isDefaultOrder = emphasis?.value === 'defaultOrder';

  const hasSameInheritanceLevels = inheritanceLevel === (emphasis?.level ?? InheritanceLevel.AGENT);

  const defaultOrderStr = emphasisConfigs?.configs.defaultOrder.slidesOrder ?? '';

  const { isLoading, isFetching } = useInheritanceCategoryCustomizations();

  /**
   * Set the order of sectionsSlide and sections from the user customization if available or
   * from default congis when default emphasis is selected.
   */
  useUpdateOrderingOnCategoryChange({ isDefaultOrder });

  /**
   * Set the emphasis to custom (null) if current emphasis is default and any change is made on slidesOrdering or sectionsOrdering.
   */
  useEffect(() => {
    if (!isDefaultOrder || !defaultOrderStr || isLoading || isFetching) return;
    if (currentSlidesOrderingStr !== defaultOrderStr && hasSameInheritanceLevels) setEmphasis(null);
  }, [
    currentSlidesOrderingStr,
    isLoading,
    isFetching,
    isDefaultOrder,
    defaultOrderStr,
    hasSameInheritanceLevels,
    setEmphasis,
  ]);

  /**
   * If emphasis is custom and you set an order of sections and/or slides that matches
   * any emphasis (default by customization or static emphasis ordering), this hook
   * will switch the emphasis to the one it matches.
   */
  useEffect(() => {
    if (
      !defaultOrderStr ||
      !currentSlidesOrderingStr ||
      isLoading ||
      isFetching ||
      (isDefaultOrder && !hasSameInheritanceLevels)
    )
      return;

    if (currentSlidesOrderingStr === defaultOrderStr) {
      return setEmphasis({ ...emphasisConfigs?.configs.defaultOrder! });
    }

    const serializedSectionsOrder = String(sections);
    const matchingStaticEmphasisConfig = staticConfigs.find(
      conf => String(conf.order) === serializedSectionsOrder,
    );

    const emphasisToSet = !matchingStaticEmphasisConfig
      ? null
      : { ...matchingStaticEmphasisConfig };

    if (emphasisToSet) {
      setSectionsOrder(emphasisToSet?.order);
    }

    setEmphasis(emphasisToSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlidesOrderingStr, defaultOrderStr, isLoading, isFetching]);

  /**
   * Update section ordering only if an static emphasis is selected
   */
  useEffect(() => {
    if (!emphasis || emphasis.value === 'defaultOrder' || !defaultOrderStr) return;
    setSectionsOrder(emphasis.order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emphasis?.value]);
}
