import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TPresentationType } from '../../../../../../../../types';
import { ButtonsGroup } from '../../../../../../../../components/Common/V2/ButtonsGroup';
import { Button } from '../../../../../../../../components/Common/V2/Button/Button';

export type TViewModes = 'timeOnPage' | 'pageViews' | 'propertyRatings';

type Props = {
  onVisibilityModeChange: () => void;
  onViewModeChange: (viewMode: TViewModes) => void;
  viewMode: TViewModes;
  showPagesWithNoViews: boolean;
  type: TPresentationType;
};

export function Actions({
  type,
  viewMode,
  onViewModeChange,
  showPagesWithNoViews,
  onVisibilityModeChange,
}: Props) {
  const getOptions = (viewMode: TViewModes, type: TPresentationType) => {
    const options = [
      {
        label: 'Time on Page',
        isActive: viewMode === 'timeOnPage',
        prefixIcon: (
          <FontAwesomeIcon
            color={`${viewMode === 'timeOnPage' ? '#fff' : '#444'}`}
            icon={'stopwatch'}
          />
        ),
        onClick: () => onViewModeChange('timeOnPage'),
      },
      {
        label: 'Page Views',
        isActive: viewMode === 'pageViews',
        prefixIcon: (
          <FontAwesomeIcon color={`${viewMode === 'pageViews' ? '#fff' : '#444'}`} icon={'eye'} />
        ),
        onClick: () => onViewModeChange('pageViews'),
      },
    ];

    if (type === 'buyerTour') {
      options.push({
        label: 'Property Ratings',
        isActive: viewMode === 'propertyRatings',
        prefixIcon: (
          <FontAwesomeIcon
            color={`${viewMode === 'propertyRatings' ? '#fff' : '#444'}`}
            icon={'chart-simple-horizontal'}
          />
        ),
        onClick: () => onViewModeChange('propertyRatings'),
      });
    }

    return options;
  };

  return (
    <Container>
      <ButtonsGroup buttonsConfigs={getOptions(viewMode, type)} />
      <Button variant='link' onClick={onVisibilityModeChange}>
        {!showPagesWithNoViews ? 'Hide' : 'Show'} Pages with No Views
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 20px;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.v2.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;
