import styled from 'styled-components';

export const LeftCol = styled('div')({
  width: '30%',
});

export const RightCol = styled('div')({
  width: '68%',
});

export const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2%',
});
