import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';

const Box = styled('div')({
  border: '1px solid #ccc',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  width: '48.5%',
  alignItems: 'center',
  position: 'relative',
});

const Line = styled('div')({
  width: '100%',
  content: '" "',
  display: 'block',
  height: 1,
  backgroundColor: '#ccc',
  '&:not(:last-child)': {
    marginBottom: 32,
  },
});

const CheckBox = styled('div')({
  display: 'block',
  content: '" "',
  height: 24,
  width: 24,
  border: '1px solid #444',
  borderRadius: 2,
  position: 'absolute',
  top: 24,
  left: 32,
});

const Text = styled('p')(({ theme }) => ({
  color: '#444',
  lineHeight: 1.6,
  marginTop: 10,
}));

interface Props {
  title: string;
  topTitle: string;
}
const BoxItem: React.FC<Props> = ({ title, topTitle }) => {
  const primary = useUiConfigColorsPrimary();
  return (
    <Box>
      <CheckBox />
      <Text style={{ fontSize: 16, textTransform: 'uppercase', lineHeight: 1 }}>{topTitle}</Text>
      <Text
        style={{
          fontSize: 23,
          textTransform: 'uppercase',
          color: primary,
          fontWeight: 700,
          letterSpacing: '0.05em',
          marginTop: -2,
          marginBottom: 36,
        }}
      >
        {title}
      </Text>
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
    </Box>
  );
};

export default BoxItem;
