import { useMemo } from 'react';
import { PastPresentationItem } from '../../../types';
import { TextCell } from './TextCell';

interface Props {
  presentationItem?: PastPresentationItem;
}

export const VersionsCell = ({ presentationItem }: Props) => {
  const numOfPresentationItems = useMemo(() => {
    return (presentationItem?.subRows?.length ?? 0) + 1;
  }, [presentationItem?.subRows?.length]);

  if (!numOfPresentationItems) return null;

  return <TextCell text={String(numOfPresentationItems)} />;
};
