import { useMemo } from 'react';
import styled from 'styled-components';

import { TProperty } from '../../../../types';
import { getPropertyFeatures } from '../../../../pages/PresentationCreate/dash/services/getPropertyFeatures';
import { SectionTitle } from '../components/SectionTitle';
import { Wrapper } from '../components/UI';

interface Props {
  property: TProperty;
}

export const Features = ({ property }: Props) => {
  const features = useMemo(() => getPropertyFeatures(property), [property]);

  if (!features?.length) return null;

  return (
    <Wrapper>
      <SectionTitle>Features</SectionTitle>
      <FeaturesGrid>
        {features.map(feature => (
          <Item key={feature.label}>{feature.label}</Item>
        ))}
      </FeaturesGrid>
    </Wrapper>
  );
};

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 13px;
`;

const Item = styled.span`
  color: #444;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
