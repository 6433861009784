import React, { useEffect, useState } from 'react';

import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';
import { StorageService } from '../../../services/storageService';

import classes from './InfoModal.module.scss';

export const InfoModal = ({ storageKey, subject, text }) => {
  const [isOpen, setIsOpen] = useState(true);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (!StorageService.read(storageKey)) {
      setTimeout(openModal, 2000);
      StorageService.save(storageKey, true);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} className={classes.infoModal}>
      <header data-test-header={true} className={classes.chartHeadline}>
        {subject}
        <span onClick={closeModal} />
      </header>
      <div className={classes.childrenContainer}>
        {text}
        <br />
        <br />
        <Button fluid primary onClick={closeModal} text='Okay!' />
      </div>
    </Modal>
  );
};
