import React, { useState } from 'react';
import styled from 'styled-components';

import { TProperty } from '../../../../../../../../../types';
import { ComparableDetails } from '../../../../../../../../Property/DetailDescription/ComparableDetails';
import { ListingInfoAndDescription } from '../../../../../../../../Property/DetailDescription/ListingInfoAndDescription/ListingInfoAndDescription';
import { Intro } from '../../../../../../../../Property/DetailDescription/Intro/Intro';
import { DropdownKeys } from '../../../../../../../../../features/report/components/Property/DetailDescription/DetailDescription';

interface Props {
  property: TProperty;
}

export const DetailDescription: React.FC<Props> = ({ property }) => {
  const [openedDropdown, setOpenedDropdown] = useState(DropdownKeys.COMPARABLE_DETAILS);

  return (
    <Wrapper>
      <Intro property={property} hideCompAdjustments />
      <ComparableDetails
        property={property}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
      <ListingInfoAndDescription
        property={property}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;
