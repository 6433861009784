import { useState } from 'react';

import { Sidebar } from '../components/Sidebar';
import { TrendsSidebarContent } from './TrendsSidebarContent';
import { Button } from '../components/Button';

export const Trends = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        label='Trends'
        iconName='chart_line_up'
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
      />

      <Sidebar
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <TrendsSidebarContent />
      </Sidebar>
    </>
  );
};
