import { useSetAtom } from 'jotai';
import { selectedCategoriesAtom } from '../state/selectedCategoriesAtom';
import { RawReport } from '../../../types';
import { getDefaultSelectedCategories } from '../services/getDefaultSelectedCategories';
import { flagsAtom } from '../state/flagsAtom';
import { compAveragesAtom } from '../state/compAveragesAtom';

export const useSetDefaultCategories = () => {
  const setSelectedCategories = useSetAtom(selectedCategoriesAtom);
  const setFlags = useSetAtom(flagsAtom);
  const setCompAverages = useSetAtom(compAveragesAtom);

  const setDefaultCategories = (reportDetailed: RawReport) => {
    const defaultCategories = getDefaultSelectedCategories({
      flags: reportDetailed.flags,
      compAverages: reportDetailed.compAverages,
      properties: reportDetailed.properties,
    });

    setSelectedCategories(defaultCategories);
    setFlags(reportDetailed?.flags);
    setCompAverages(reportDetailed?.compAverages);
  };

  return setDefaultCategories;
};
