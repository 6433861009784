import styled from 'styled-components';

import { Typography } from '../../../../../../components/Common';

export const HeroContainer = styled.div<{ isBT?: boolean }>`
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: ${({ theme, isBT }) =>
    isBT
      ? `none`
      : `linear-gradient(to right,${theme.colors.gray[300]} 60%, ${theme.colors.gray[400]})`};
  flex-shrink: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

export const TextContainer = styled.div`
  display: flex;
  width: 708px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const Title = styled(Typography)`
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
`;

export const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
`;

export const ImageCP = styled.img`
  position: absolute;
  height: 100%;
  width: auto;
  right: 4%;
  top: 0;
`;

export const ImageBT = styled.img`
  width: 75px;
  height: 75px;
`;
