import { TProperty } from '../../../../../types';
import { appendAdjustedPerSqft } from './appendAdjustedPerSqft';
import { getValidPropertyPrice } from './getValidPropertyPrice';

export const getMedianProperty = (
  properties: TProperty[] | null,
  sqftModeOn: boolean,
  useAdjustedPrice: boolean,
) => {
  if (!properties) return;
  const propertiesList = appendAdjustedPerSqft(properties?.filter(p => !p.excluded));
  const sortedProperties =
    propertiesList?.sort((a, b) => {
      const aPrice = getValidPropertyPrice(a, sqftModeOn, useAdjustedPrice) || 0;
      const bPrice = getValidPropertyPrice(b, sqftModeOn, useAdjustedPrice) || 0;
      return aPrice - bPrice;
    }) || [];

  const middleIndex = Math.floor(sortedProperties.length / 2);

  if (middleIndex === 0) {
    return sortedProperties[0];
  }

  if (sortedProperties?.length % 2 === 0) {
    return sortedProperties[middleIndex - 1];
  }

  return sortedProperties[middleIndex];
};
