import { URLS } from '../constants/urls';
import { corePresentApi } from './CorePresentApi';
import { TTrackingEvent } from '../services/TrackingEvent';
import { THeatmapTracking, TPresentationSessionSlide, TSlideId } from '../types';

type TSlideOpenEvent = {
  name: string;
  id: string;
};

type TSlideClosedEvent = {
  name: string;
  id: string;
  data: THeatmapTracking;
};

type TPresentationSessionAction = {
  description: string;
  type: string;
  name: string;
  triggeredAt: string;
};

export type TPresentationSessionRatings = {
  property_id: string;
  property_rating: string;
  timestamp: string;
};

export type TPresentationSession = {
  hash: string;
  duration: number;
  ip: string;
  endedAt: string;
  startedAt: string;
  startedAtTimestamp: number;
  totalSlides: number;
  actions: TPresentationSessionAction[];
  slides: Partial<Record<TSlideId, TPresentationSessionSlide>>;
  avgSlidesDuration: number;
  followUp: string[];
  ratings?: TPresentationSessionRatings[];
};

const apiToEntityAction = (action: any): TPresentationSessionAction => ({
  description: action.description,
  type: action.type,
  name: action.name,
  triggeredAt: action.timestamp,
});

const capDurationTime = (time?: number) => {
  if (!time) return 1000;

  const timeInMs = time * 1000;

  return timeInMs > 90000 ? 90000 : timeInMs;
};

const apiToEntitySlides = (slide: any): TPresentationSessionSlide | null => {
  if (!slide?.slide_id) return null;

  const data = JSON.parse(slide.data);
  const duration = capDurationTime(slide.duration);

  return {
    id: slide.slide_id,
    name: slide.slide_name,
    data,
    duration,
    views: 1,
    interactions: data?.clicks?.length ?? 0,
  };
};

const aggregateSlides = (
  slides: TPresentationSessionSlide[],
): Partial<Record<TSlideId, TPresentationSessionSlide>> => {
  return slides.reduce(
    (
      map: Partial<Record<TSlideId, TPresentationSessionSlide>>,
      slide: TPresentationSessionSlide,
    ) => {
      if (!map[slide.id]) return { ...map, [slide.id]: { ...slide } };

      const mapSlide = map[slide.id] as TPresentationSessionSlide;

      let duration = (mapSlide?.duration || 0) + slide.duration;
      duration = duration > 90000 ? 90000 : duration;
      const durationSeconds = duration / 1000;
      duration =
        durationSeconds > 0 && durationSeconds < 1 ? 1000 : Math.round(durationSeconds) * 1000;

      const clicks = [...(mapSlide?.data?.clicks ?? []), ...(slide.data?.clicks ?? [])];
      const movements = [...(mapSlide?.data?.movements ?? []), ...(slide.data?.movements ?? [])];

      return {
        ...map,
        [slide.id]: {
          ...slide,
          data: { clicks, movements },
          duration: duration > 90000 ? 90000 : duration,
          views: mapSlide?.views + slide.views,
          interactions: slide.interactions + mapSlide.interactions,
        },
      };
    },
    {} as Partial<Record<TSlideId, TPresentationSessionSlide>>,
  );
};

const calculateAvgDuration = (slidesMap: Partial<Record<TSlideId, TPresentationSessionSlide>>) => {
  const slides = Object.values(slidesMap);

  const numberOfSlides = slides?.length ?? 1;
  const durationSum = slides.reduce((sum, slide) => sum + slide?.duration!, 0);

  return Math.floor(durationSum / numberOfSlides);
};

export class TrackingApi {
  static async fetchSessions(hash: string): Promise<TPresentationSession[]> {
    const res = await corePresentApi.get(`/sessions/${hash}/sessions`);

    return res.map((session: any) => {
      const slideEntities =
        session.slides
          ?.map(apiToEntitySlides)
          .filter((s: TPresentationSessionSlide | null) => !!s) ?? [];

      const slidesMap = aggregateSlides(slideEntities);

      const averageDuration = calculateAvgDuration(slidesMap);

      return {
        actions: session.actions?.map(apiToEntityAction) ?? [],
        hash: session.hash,
        duration: session.duration,
        ip: session.ip_address,
        endedAt: session.session_ended_at,
        startedAt: session.session_started_at,
        startedAtTimestamp: session.session_start_timestamp,
        totalSlides: session.total_slides_count,
        slides: slidesMap,
        avgSlidesDuration: averageDuration,
        followUp: session.followup,
        ratings: session.ratings,
      };
    });
  }

  static async startSession(hash: string, totalSlides: number): Promise<string> {
    try {
      const session = await corePresentApi.post(`/sessions/${hash}/start`, {
        total_slides: totalSlides,
      });

      return session?.hash;
    } catch (e: any) {
      console.warn('ERROR: ', e.message);
      return Promise.reject('An error occurred');
    }
  }

  static async closeSession(sessionId: string, heatmapData: THeatmapTracking, slideId?: TSlideId) {
    const payload = new FormData();
    payload.append(
      'data',
      JSON.stringify({
        clicks: heatmapData?.clicks ?? [],
        movements: heatmapData?.movements ?? [],
      }),
    );

    payload.append('slide_id', String(slideId));

    const isSentSuccessfully = navigator.sendBeacon(
      `${URLS.CORE_PRESENT_API}/sessions/${sessionId}/end`,
      payload,
    );

    return isSentSuccessfully;
  }

  static async click(session: string, event: TTrackingEvent): Promise<string> {
    try {
      const res = await corePresentApi.post(`/sessions/${session}/track-click`, event);

      return res?.message;
    } catch (e: any) {
      console.warn('ERROR: ', e.message);
      return Promise.reject('An error occurred');
    }
  }

  static async slideOpen(sessionId: string, event: TSlideOpenEvent): Promise<string> {
    const payload = {
      slide_name: event.name,
      slide_id: event.id,
    };

    try {
      const res = await corePresentApi.post(`/sessions/${sessionId}/slide-opened`, payload);

      return res?.message;
    } catch (e: any) {
      console.warn('ERROR: ', e.message);
      return Promise.reject('An error occurred');
    }
  }
  static async slideClosed(sessionId: string, event: TSlideClosedEvent): Promise<string> {
    const payload = {
      slide_name: event.name,
      slide_id: event.id,
      data: JSON.stringify(event.data),
    };

    try {
      const res = await corePresentApi.post(`/sessions/${sessionId}/slide-closed`, payload);

      return res?.message;
    } catch (e: any) {
      console.warn('ERROR: ', e.message);
      return Promise.reject('An error occurred');
    }
  }
}
