import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { Button } from '../../../components/Common/V2/Button/Button';
import UserDropdown from './UserDropdown';
import { PresentLogo } from '../../../components/PresentLogo/PresentLogo';

export const Header: React.FC = () => {
  const history = useHistory();

  const isMobile = useMediaQuery('(max-width: 800px)');

  const marginRight = { marginRight: isMobile ? '0px' : '5px' };
  const buttonStyle = { padding: '10px', height: '100%' };

  return (
    <Container>
      <PresentLogo isNewLogo style={{ width: 157, height: 43 }} />

      <ActionsWrapper>
        <Button
          variant='secondary'
          onClick={() => {
            history.push('/settings/customization');
          }}
          prefixIcon={<FontAwesomeIcon icon={'gear'} style={{ ...marginRight }} />}
          style={buttonStyle}
        >
          <MobileHidden>Custom Settings</MobileHidden>
        </Button>
        <Button
          variant='secondary'
          onClick={() => {
            history.push('/library');
          }}
          prefixIcon={<FontAwesomeIcon icon={'books'} style={{ ...marginRight }} />}
          style={buttonStyle}
        >
          <MobileHidden>Presentation Library</MobileHidden>
        </Button>
        <UserDropdown />
      </ActionsWrapper>
    </Container>
  );
};

const Container = styled.header`
  font-family: Figtree;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  padding: 9px 30px 6px;
  max-width: 1610px;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 9px 20px 6px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const MobileHidden = styled.div`
  width: 100%;
  text-align: left;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 800px) {
    display: none;
  }
`;
