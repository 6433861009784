interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  payload?: any;
  graphData?: any[];
  moreInfo?: (id: string) => void;
  xKey?: string;
}

export const CustomizedAxisTick: React.FC<CustomizedAxisTickProps> = ({
  x,
  y,
  payload = {},
  graphData = [],
  moreInfo = f => f,
  xKey,
}) => {
  return (
    <g
      style={{ cursor: 'pointer' }}
      onClick={() => moreInfo(graphData?.[payload.index].id)}
      transform={`translate(${x},${y})`}
    >
      <text x={-20} y={0} dy={5}>
        {graphData && xKey && graphData[payload.index][xKey]}
      </text>
    </g>
  );
};
