import React, { createContext, useContext, useState } from 'react';

import { TSlideTheme } from '../../types';
import { THEMES } from '../../constants/themes';

type ThemeContextType = {
  theme: TSlideTheme;
  setTheme: (value: TSlideTheme) => void;
};

const UiThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useUiConfigTheme = () => {
  const context = useContext(UiThemeContext);

  if (!context) throw new Error('useUiConfigTheme cannot be used without UiThemeContext');

  return context;
};

interface Props {
  initValue?: TSlideTheme;
}
export const UiConfigThemeProvider: React.FC<Props> = ({ initValue, children }) => {
  const [theme, setTheme] = useState<TSlideTheme>(initValue ?? THEMES.TRADITIONAL.DEFAULT);

  const value = React.useMemo(() => ({ theme, setTheme }), [theme]);

  return <UiThemeContext.Provider value={value}>{children}</UiThemeContext.Provider>;
};
