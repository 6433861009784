import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TwoImagesBeta } from './TwoImagesBeta';
import { TwoImagesBetaForm } from './TwoImagesBetaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { TwoImagesBetaPdf } from './TwoImagesBetaPdf';

const template = 'twoImagesLayoutBeta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - Two Images',
  frame: true,
  data: {
    image: '',
    image2: '',
    text: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TwoImagesBetaForm,
  web: TwoImagesBeta,
  pdf: TwoImagesBetaPdf,
};

export type TTwoImagesLayoutBeta = typeof template;
export { config, mappings, thumbnail };
