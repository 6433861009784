import { useEffect } from 'react';
import { TPresentation } from '../../../types';
import { WTREditingLayout } from './WTREditingLayout';
import { withProviders } from '../../../hoc/withProviders';
import { useSetFirstSlideAsActive } from './useSetFirstSlideAsActive';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { OrderProvider } from '../../../providers/providers/OrderProvider';
import { SlidesProvider } from '../../../providers/providers/SlidesProvider';
import { FullScreenLoader } from '../../../components/Loader/FullScreenLoader';
import { SectionsProvider } from '../../../providers/providers/SectionsProvider';
import { CategoryProvider } from '../../../providers/providers/CategoryProvider';
import { UiConfigProvider } from '../../../providers/providers/UiConfigProvider';
import { CreatedAtProvider } from '../../../providers/providers/CreatedAtProvider';
import { SelectedSlideProvider } from '../../../providers/providers/SelectedSlideProvider';
import { PresentationModeProvider } from '../../../providers/providers/PresentationModeProvider';
import { PresentationTypeProvider } from '../../../providers/providers/PresentationTypeProvider';
import { ActiveSectionProvider } from '../../../components/Slide/providers/ActiveSectionProvider';
import { useSetWTRPresentationFullConfigToProviders } from './useSetWTRPresentationFullConfigToProviders';
import { PreviewModeProvider } from '../../../pages/PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { PresentationCreationBody } from '../../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/PresentationCreationBody.component';
import { PresentationSettingsHeader } from '../../../pages/PresentationEdit/PresentationCreationContent/PresentationSettingsHeader/PresentationSettingsHeader';
import { useOrderingChange } from '../../../pages/PresentationEdit/PresentationCreationContent/hooks/useOrderingChange';
import { PresentationMetaProvider } from '../../../providers/providers/PresentationMetaProvider';

interface Props {
  presentation: TPresentation;
}
export const WTRPresentationEdit: React.FC<Props> = withProviders<Props>(
  UiConfigProvider,
  PresentationMetaProvider,
  PresentationTypeProvider,
  CategoryProvider,
  SlidesProvider,
  SectionsProvider,
  OrderProvider,
  ActiveSectionProvider,
  PresentationModeProvider,
  SelectedSlideProvider,
  PreviewModeProvider,
  CreatedAtProvider,
)(function WTRPresentationEdit({ presentation }: Props) {
  const user = useUser();
  useSetWTRPresentationFullConfigToProviders(presentation);

  const slide = useSetFirstSlideAsActive();

  useOrderingChange();

  if (!slide || !user) return <FullScreenLoader />;

  return (
    <WTREditingLayout presentation={presentation}>
      <PresentationSettingsHeader useEmphasis={false} />
      <PresentationCreationBody presentation={presentation} />
    </WTREditingLayout>
  );
});
