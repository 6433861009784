import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { TCompConfigItem } from '../../../../types';
import { CheckboxGroup } from './components/CheckboxGroup';
import { DescriptionBlock } from './components/DescriptionBlock';
import { CheckboxGroups, FlagsContainer } from './components/UI';
import {
  BEDROOM_KEYS,
  FOOTAGE_KEYS,
  LARGER_FLAGS_BEDROOMS,
  LARGER_FLAGS_FOOTAGE,
  LARGER_FOOTAGE_KEYS,
} from './flagsConfig';

export const LargerFeatures = () => {
  const { isCanadaUser } = useUser();
  return (
    <FlagsContainer>
      <DescriptionBlock
        title='LARGER FEATURES'
        description={
          <>
            “Outliers” highlight properties in the set area that are <span>TOO LARGE</span> to be
            considered comparable matches.
          </>
        }
      />
      <CheckboxGroups>
        {!isCanadaUser && (
          <CheckboxGroup
            title='SQUARE FOOTAGE'
            description='Same bed count, but significantly MORE square footage.'
            fields={
              [
                LARGER_FLAGS_FOOTAGE[FOOTAGE_KEYS.FROM_25_TO_50],
                LARGER_FLAGS_FOOTAGE[FOOTAGE_KEYS.FROM_50_TO_75],
                LARGER_FLAGS_FOOTAGE[FOOTAGE_KEYS.FROM_75_TO_100],
                LARGER_FLAGS_FOOTAGE[LARGER_FOOTAGE_KEYS.OVER_100],
              ] as TCompConfigItem[]
            }
          />
        )}
        <CheckboxGroup
          title='BEDROOMS'
          description='Within the square footage range, but HIGHER bed count.'
          fields={
            [
              LARGER_FLAGS_BEDROOMS[BEDROOM_KEYS.ONE],
              LARGER_FLAGS_BEDROOMS[BEDROOM_KEYS.TWO],
              LARGER_FLAGS_BEDROOMS[BEDROOM_KEYS.THREE],
            ] as TCompConfigItem[]
          }
        />
      </CheckboxGroups>
    </FlagsContainer>
  );
};
