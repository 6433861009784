import styled from 'styled-components';
import React, { useState } from 'react';

import { Groups } from './Groups';
import { Thumbnails } from './Thumbnails';
import { DynamicSlidesDefaultConfigProvider } from '../../../../../../../../components/Slide/dynamic/DynamicSlidesDefaultConfigProvider';
import { useCreateSlideProps } from '../../../../../../providers/CreateSlidePropsProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

function getInitGroup() {
  const configs = DynamicSlidesDefaultConfigProvider.getConfigs();
  const [firstGroupId] = configs.orderById;

  return DynamicSlidesDefaultConfigProvider.getConfigs().groups[firstGroupId].id;
}

export interface SingleSlideThumbnailsComponentProps {
  singleOpen: boolean;
  onSingleToggle: () => void;
}

export const TemplateSelector = () => {
  const { groups } = DynamicSlidesDefaultConfigProvider.getConfigs();
  const { activeGroup } = useCreateSlideProps();

  const templates = React.useMemo(() => {
    return groups[activeGroup].templates;
  }, [activeGroup, groups]);

  const groupRowHeight = 60;
  let templatesHeight = 3 * (93 + 12);

  return (
    <Container style={{ height: groupRowHeight + templatesHeight }}>
      <Groups />
      <Thumbnails templates={templates} />
    </Container>
  );
};
