import styled from 'styled-components';

export const Container = styled('div')({
  display: 'flex',
  borderRadius: 10,
  flexDirection: 'column',
  width: '100%',
  border: '1px solid #dddddd',
  overflow: 'hidden',
});

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 30px',
  height: 60,
  flexShrink: 0,
  '&:nth-child(odd)': {
    backgroundColor: '#f6f6f6',
  },
  '&:first-child': {
    backgroundColor: '#e6e6e6',
  },
});

interface WithProps {
  isHead?: boolean;
}

export const RowItem = styled('div')<WithProps>(({ isHead }) => ({
  fontSize: isHead ? 16 : 13,
  fontWeight: isHead ? 700 : 400,
  letterSpacing: isHead ? '0.11em' : 'auto',
  color: isHead ? '#333' : '#444',
  flexShrink: 0,
  flex: 1,
}));
