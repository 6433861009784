import { useCallback, useEffect, useRef, useState } from 'react';
import { Link as RRouterLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

interface ITooltipEllipsisText {
  text: string | number;
  link?: string;
  justify?: string;
}

const TooltipEllipsisText = ({ text, link, justify }: ITooltipEllipsisText) => {
  const [isTruncated, setTruncated] = useState(false);
  const ref = useRef(null);

  const checkTruncation = useCallback(() => {
    if (!ref.current) return;

    const { offsetWidth, scrollWidth } = ref.current;

    const isOverflowing = offsetWidth < scrollWidth;

    setTruncated(isOverflowing);
  }, []);

  useEffect(() => {
    checkTruncation();
    window.addEventListener('resize', checkTruncation);

    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [checkTruncation]);

  const attributes = { 'data-tip': !isTruncated ? '' : text };

  return (
    <Wrapper {...attributes} justify={justify}>
      {isTruncated && <ReactTooltip place='top' type='dark' effect='float' />}

      {!link ? (
        <Text ref={ref}>{text}</Text>
      ) : (
        <Link ref={ref} to={link}>
          {text}
        </Link>
      )}
    </Wrapper>
  );
};

export default TooltipEllipsisText;

const Wrapper = styled.div<{ justify?: string }>`
  display: flex;
  justify-content: ${({ justify }) => (!justify ? 'center' : justify)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Text = styled.p`
  font-family: Figtree;
  font-size: 13px;
  color: #444;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Link = styled(RRouterLink)`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary.main};
  text-decoration: none;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
