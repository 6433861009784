import { BUYER_TOUR } from '../features/BuyerTour/constants';
import { WIN_THE_REPRESENTATION } from '../features/WinTheRepresentation/constants';

const WIN_THE_LISTING = 'winTheListing';
const WIN_THE_OFFER = 'winTheOffer';
const PRESENT_INFO = 'presentInfo';

export const PRESENTATION_TYPES = {
  WIN_THE_LISTING,
  WIN_THE_OFFER,
  PRESENT_INFO,
  BUYER_TOUR,
  WIN_THE_REPRESENTATION,
} as const;

export function isWinTheListing(type: string) {
  return type === WIN_THE_LISTING;
}
