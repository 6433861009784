import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';
import * as data from '../../data';
import image from './image.png';

export const PurposeOfBuyersAgentLuxuryPDF = () => {
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading={data.heading}
      listItems={data.data}
      paragraphs={[data.subtitle]}
      image={image}
      paragraphStyles={{ marginBottom: 5, lineHeight: 1.5, fontSize: '15px' }}
      listItemStyles={{ lineHeight: 1.5, marginBottom: 5, fontSize: '15px' }}
      imageStyles={{ marginBottom: 0 }}
      headingStyles={{ lineHeight: 1.2 }}
      textContainerStyles={{ paddingTop: 0 }}
    />
  );
};
