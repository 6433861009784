import React, { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { TSuperPresentation } from '../../types';
import { Typography } from '../Common';
import { Button } from '../Common/V2/Button/Button';
import { useSetPresentation } from '../../hooks/usePresentation';
import { PresentationApi } from '../../pages/Presentation/api/PresentationApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  isFetching: boolean;
  presentation: TSuperPresentation;
  onBack?: () => void;
}

export const PresentationEditActions: React.FC<Props> = ({ presentation, isFetching, onBack }) => {
  const { hash } = useParams<{ hash: string }>();
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const setPresentation = useSetPresentation(hash);

  const onPresentAndShare = async () => {
    setIsLoading(true);

    if (!presentation) return;
    const id = presentation?.id || '';

    await PresentationApi.update(id, presentation);
    await PresentationApi.triggerPdfGeneration(id);

    setPresentation(presentation as TSuperPresentation);
    setIsLoading(false);

    push(`/presentation/${hash}/dashboard`);
  };

  return (
    <Fragment>
      {!onBack ? null : (
        <Button
          variant='secondary'
          prefixIcon={<FontAwesomeIcon icon={'chevron-left'} style={{ marginTop: '1px' }} />}
          onClick={onBack}
          style={{ marginRight: 10 }}
          disabled={isLoading || isFetching}
        >
          Previous
        </Button>
      )}

      <Button variant='primary' disabled={isLoading || isFetching} onClick={onPresentAndShare}>
        Present & Share
      </Button>
    </Fragment>
  );
};
