import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 18,
});

const common = {
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    opacity: 0.9,
  },
};

export const Title = styled('p')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  fontSize: 16,
  textAlign: 'left',
  ...common,
}));

interface I {
  active?: boolean;
}

export const Text = styled('p')<I>(({ theme, active }) => ({
  fontSize: active ? 16 : 14,
  fontWeight: active ? 700 : 400,
  color: theme.colors.gray[400],
  position: 'relative',
  textAlign: 'left',
  textTransform: 'capitalize',
  ...common,
}));

export const Arrow = styled('img')({
  position: 'absolute',
  left: -20,
});
