import { useState } from 'react';

import { EditableInput } from '../components/EditableInput';

export const YearBuiltEdit = ({ subject, onSubjectChange, defaultSubjectValue }) => {
  const [editValue, setEditValue] = useState(subject);
  const [enterPressed, setEnterPressed] = useState(false);

  if (!subject) {
    return null;
  }

  const onEditConfirm = () => {
    if (Number.isNaN(parseInt(editValue, 10)) || parseInt(editValue, 10) < 1) {
      setEditValue(defaultSubjectValue);
      setEnterPressed(false);
      return;
    }
    setEnterPressed(false);
    onSubjectChange(parseInt(editValue, 10));
  };

  const onUndoClick = () => {
    onSubjectChange(defaultSubjectValue);
    setEditValue(defaultSubjectValue);
    setEnterPressed(false);
  };
  const onEnterSubmit = () => {
    onEditConfirm();
    setEnterPressed(true);
  };

  return (
    <EditableInput
      label={subject}
      value={subject}
      defaultValue={defaultSubjectValue}
      onConfirm={onEditConfirm}
      onUndo={onUndoClick}
      editValue={editValue}
      onChange={val => {
        setEditValue(val);
      }}
      onEnterSubmit={onEnterSubmit}
      enterPressed={enterPressed}
      thousandSeparator={false}
    />
  );
};
