import styled from 'styled-components';

type WithReversed = {
  isReversed?: boolean;
};

export const ModernCoverContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const TopContainer = styled('div')({
  width: '100%',
  flex: 1,
  overflow: 'hidden',
  backgroundColor: '#fff',
});

export const ImagePreview = styled('div')({
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

export const InfoContainer = styled('div')<WithReversed>(({ isReversed }) => ({
  display: 'flex',
  flexDirection: isReversed ? 'row-reverse' : 'row',
  justifyContent: 'space-between',
  backgroundColor: '#fff',
  height: 180,
  padding: '25px 40px',
}));
