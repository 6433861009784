export default `<svg width="180" height="58" viewBox="0 0 180 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11231_19788)">
<path d="M82.2692 34.5524C82.4005 35.2921 82.6748 35.9124 83.0924 36.4134C83.5099 36.9145 84.0467 37.2724 84.679 37.511C85.3113 37.7376 86.0389 37.8569 86.8263 37.8569C88.1743 37.8569 89.2122 37.5587 89.928 36.9741C90.6437 36.3896 91.0016 35.6619 91.0016 34.791C91.0016 34.0753 90.7869 33.4907 90.3455 33.0493C89.9041 32.6079 89.3076 32.2262 88.5441 31.916C87.7807 31.6059 86.8979 31.3076 85.8958 30.9975C84.846 30.7111 83.8081 30.401 82.7941 30.0431C81.7682 29.6852 80.8377 29.22 79.9788 28.6593C79.1199 28.0986 78.4399 27.3709 77.9269 26.4762C77.4139 25.5815 77.1753 24.4601 77.1992 23.1001C77.1992 21.4658 77.6167 20.0581 78.4637 18.889C79.3107 17.7199 80.444 16.8372 81.8756 16.2407C83.3071 15.6561 84.9653 15.3579 86.8621 15.3579C88.5203 15.3579 89.9995 15.5965 91.3118 16.0617C92.6121 16.5389 93.6977 17.2308 94.5685 18.1733C95.4394 19.1038 96.0597 20.2371 96.4414 21.5612L91.0016 22.79C90.8704 22.2293 90.6079 21.7521 90.2143 21.3584C89.8206 20.9648 89.3434 20.6665 88.7708 20.4757C88.1982 20.2848 87.6375 20.1774 87.041 20.1536C86.3253 20.1536 85.6811 20.2729 85.1084 20.4995C84.5358 20.7262 84.0706 21.0602 83.7246 21.5016C83.3787 21.9311 83.2117 22.444 83.2117 23.0286C83.2117 23.6131 83.4145 24.1499 83.8081 24.5436C84.2018 24.9373 84.7386 25.2832 85.4186 25.5815C86.0986 25.8797 86.8621 26.1541 87.6971 26.4046C88.7469 26.7386 89.8087 27.0965 90.8942 27.4783C91.9798 27.86 92.9819 28.3491 93.9005 28.9575C94.819 29.554 95.5587 30.3294 96.1194 31.2599C96.68 32.1904 96.9663 33.3476 96.9663 34.7314C96.9663 36.2345 96.6085 37.5945 95.8927 38.7993C95.1769 40.0042 94.0913 40.9586 92.6479 41.6743C91.2044 42.3901 89.3792 42.748 87.1842 42.748C85.3709 42.748 83.7246 42.4855 82.2573 41.9606C80.79 41.4358 79.5851 40.6603 78.6307 39.6225C77.6883 38.5846 77.056 37.3439 76.7578 35.8885L82.2334 34.5882L82.2692 34.5524Z" fill="#444444"/>
<path d="M109.444 42.7124C107.583 42.7124 105.901 42.3187 104.422 41.5194C102.943 40.7202 101.773 39.5869 100.938 38.1076C100.091 36.6283 99.6738 34.8747 99.6738 32.8586C99.6738 30.8426 100.103 29.1008 100.974 27.6216C101.845 26.1543 103.014 25.021 104.505 24.2098C105.985 23.3986 107.655 23.0049 109.528 23.0049C111.4 23.0049 113.106 23.4105 114.586 24.2098C116.065 25.009 117.246 26.1543 118.117 27.6216C118.988 29.0889 119.417 30.8306 119.417 32.8586C119.417 34.8866 118.976 36.6283 118.093 38.1076C117.21 39.5869 116.017 40.7321 114.514 41.5194C113.011 42.3068 111.317 42.7124 109.456 42.7124H109.444ZM109.444 37.6543C110.112 37.6543 110.744 37.4753 111.341 37.1175C111.937 36.7596 112.438 36.2347 112.832 35.5308C113.226 34.827 113.429 33.9442 113.429 32.8706C113.429 31.7969 113.25 30.938 112.868 30.2222C112.498 29.5064 112.021 28.9696 111.436 28.6117C110.852 28.2539 110.208 28.0749 109.516 28.0749C108.824 28.0749 108.191 28.2539 107.595 28.6117C107.01 28.9696 106.545 29.4945 106.211 30.1984C105.877 30.9022 105.71 31.785 105.71 32.8586C105.71 33.9323 105.865 34.7912 106.187 35.507C106.509 36.2227 106.963 36.7596 107.547 37.1175C108.132 37.4753 108.764 37.6543 109.42 37.6543H109.444Z" fill="#444444"/>
<path d="M131.442 42.7124C129.581 42.7362 127.923 42.3426 126.503 41.5194C125.072 40.6963 123.95 39.5511 123.151 38.0718C122.34 36.5926 121.946 34.8628 121.946 32.8944C121.946 30.9261 122.352 29.2321 123.175 27.7409C123.998 26.2497 125.108 25.0806 126.527 24.2575C127.947 23.4224 129.581 23.0049 131.454 23.0049C133.578 23.0049 135.439 23.5298 137.025 24.5796C138.612 25.6294 139.745 27.0848 140.377 28.9458L135.093 30.2461C134.842 29.5542 134.413 29.0173 133.804 28.6356C133.208 28.2539 132.528 28.063 131.788 28.063C131.048 28.063 130.345 28.2658 129.76 28.6714C129.176 29.077 128.722 29.6496 128.4 30.4012C128.078 31.1408 127.923 31.9759 127.923 32.8944C127.923 34.326 128.281 35.4712 128.997 36.342C129.712 37.2129 130.643 37.6424 131.8 37.6424C132.54 37.6424 133.208 37.4634 133.816 37.0817C134.413 36.7119 134.866 36.187 135.176 35.495L140.425 36.7954C139.757 38.5848 138.624 40.0044 137.013 41.0661C135.403 42.1278 133.554 42.6647 131.454 42.7005L131.442 42.7124Z" fill="#444444"/>
<path d="M146.14 21.9549C145.09 21.9549 144.243 21.6328 143.587 21.0005C142.93 20.3564 142.608 19.5929 142.608 18.6982C142.608 17.8034 142.93 17.0042 143.587 16.3838C144.243 15.7635 145.09 15.4414 146.14 15.4414C147.189 15.4414 148.001 15.7516 148.669 16.3838C149.337 17.0042 149.659 17.7796 149.659 18.6982C149.659 19.6167 149.325 20.3564 148.669 21.0005C148.001 21.6328 147.165 21.9549 146.14 21.9549ZM143.3 23.4699H148.931V42.6287H143.3V23.4699Z" fill="#444444"/>
<path d="M163.998 42.6404V40.2665C163.33 41.0896 162.614 41.7219 161.838 42.1871C161.063 42.6524 160.025 42.879 158.749 42.879C157.544 42.879 156.47 42.6643 155.528 42.2468C154.585 41.8292 153.834 41.2089 153.285 40.3858C152.736 39.5626 152.462 38.5844 152.462 37.4392C152.462 35.8526 153.035 34.5403 154.192 33.4905C155.337 32.4407 157.102 31.7965 159.476 31.5341L163.998 30.9973V29.8043C163.998 29.3391 163.783 28.9096 163.342 28.504C162.9 28.0984 162.256 27.8956 161.385 27.8956C160.598 27.8956 159.906 28.0984 159.333 28.4921C158.761 28.8857 158.355 29.4822 158.128 30.2696L153.38 28.504C153.917 26.7981 154.896 25.4858 156.315 24.5792C157.735 23.6726 159.5 23.2192 161.624 23.2192C164.224 23.2192 166.217 23.8754 167.588 25.1757C168.96 26.476 169.64 28.2296 169.64 30.4246V42.6524H164.01L163.998 42.6404ZM163.998 34.4807L160.431 34.8982C159.715 35.0056 159.166 35.2203 158.808 35.5663C158.439 35.9122 158.248 36.3417 158.248 36.8546C158.248 37.3437 158.451 37.7493 158.856 38.0834C159.262 38.4174 159.822 38.5844 160.538 38.5844C161.254 38.5844 161.874 38.4413 162.399 38.143C162.924 37.8448 163.318 37.4272 163.592 36.8785C163.866 36.3297 163.998 35.6498 163.998 34.8266V34.4807Z" fill="#444444"/>
<path d="M173.625 15.4185H179.22V42.6296H173.625V15.4185Z" fill="#444444"/>
<path d="M42.914 0.731445H15.3449C7.24769 0.731445 0.683594 7.29555 0.683594 15.3928V42.9618C0.683594 51.059 7.24769 57.6231 15.3449 57.6231H42.914C51.0112 57.6231 57.5753 51.059 57.5753 42.9618V15.3928C57.5753 7.29555 51.0112 0.731445 42.914 0.731445Z" fill="#DADEE7"/>
<path d="M30.6505 28.5623L34.5873 32.5109L43.5344 32.499L39.0727 28.0374L35.1241 24.0887L39.0727 20.1401L43.5344 15.6784L34.5873 15.6665L30.6505 19.6152L26.165 24.0887L30.6505 28.5623Z" fill="#027B76"/>
<path d="M27.6084 29.778L23.6598 25.8413L14.7246 25.8532L19.1862 30.3149L23.1349 34.2635L19.1862 38.2002L14.7246 42.6738L23.6598 42.6857L27.6084 38.7371L32.082 34.2635L27.6084 29.778Z" fill="#101010"/>
</g>
<defs>
<clipPath id="clip0_11231_19788">
<rect width="178.537" height="56.8917" fill="white" transform="translate(0.682617 0.731934)"/>
</clipPath>
</defs>
</svg>
`;
