import { TRACKING_EVENTS, TrackingEvent } from '../../../../services/TrackingEvent';

export const trackElementClick = (data = '', type = TRACKING_EVENTS.ELEMENT_CLICKED) => {
  const stripHtml = html => {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  TrackingEvent.click(stripHtml(data), type);
};
