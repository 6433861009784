export default `
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="323px" height="200px" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns:xlink="http://www.w3.org/1999/xlink">
<g><path style="opacity:1" fill="transparent" d="M -0.5,-0.5 C 110.5,-0.5 221.5,-0.5 332.5,-0.5C 332.5,66.5 332.5,133.5 332.5,200.5C 221.5,200.5 110.5,200.5 -0.5,200.5C -0.5,133.5 -0.5,66.5 -0.5,-0.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 146.5,31.5 C 152.5,31.5 158.5,31.5 164.5,31.5C 164.5,39.5 164.5,47.5 164.5,55.5C 151.745,54.9178 139.412,56.9178 127.5,61.5C 123.765,54.5289 120.431,47.3623 117.5,40C 119.221,38.4728 121.221,37.4728 123.5,37C 131.181,34.728 138.848,32.8946 146.5,31.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 168.5,31.5 C 183.681,32.3028 198.348,35.4695 212.5,41C 213.022,41.5613 213.355,42.228 213.5,43C 211.085,50.2729 207.919,57.1062 204,63.5C 192.617,58.8566 180.784,56.1899 168.5,55.5C 168.5,47.5 168.5,39.5 168.5,31.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 110.5,41.5 C 112.144,41.2865 113.644,41.6198 115,42.5C 117.057,48.2808 119.39,53.9474 122,59.5C 122.667,60.8333 122.667,62.1667 122,63.5C 110.603,68.7803 100.103,75.447 90.5,83.5C 84.6813,78.1816 79.0146,72.6816 73.5,67C 84.5078,56.5026 96.8411,48.0026 110.5,41.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 217.5,43.5 C 232.03,49.6035 244.696,58.4368 255.5,70C 250.867,75.6349 245.867,80.9682 240.5,86C 239.5,86.6667 238.5,86.6667 237.5,86C 228.952,77.4515 219.285,70.4515 208.5,65C 211.644,57.8801 214.644,50.7134 217.5,43.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 70.5,69.5 C 76.479,74.8123 82.1457,80.479 87.5,86.5C 79.0013,96.4931 71.5013,107.16 65,118.5C 57.7159,115.52 50.5492,112.353 43.5,109C 50.3764,94.3063 59.3764,81.1396 70.5,69.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 257.5,73.5 C 259.716,74.2026 261.549,75.5359 263,77.5C 271.256,87.9347 278.09,99.2681 283.5,111.5C 276.281,114.405 269.115,117.405 262,120.5C 256.617,109.554 250.117,99.3871 242.5,90C 247.324,84.3421 252.324,78.8421 257.5,73.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 229.5,135.5 C 228.081,134.549 226.415,134.216 224.5,134.5C 228.3,133.221 232.3,132.721 236.5,133C 234.257,134.136 231.924,134.97 229.5,135.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 41.5,113.5 C 49.3774,115.905 57.044,118.905 64.5,122.5C 59.2014,134.579 56.868,147.246 57.5,160.5C 49.1667,160.5 40.8333,160.5 32.5,160.5C 32.5516,144.243 35.5516,128.576 41.5,113.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 283.5,115.5 C 285.284,116.574 286.451,118.24 287,120.5C 288.515,126.893 290.181,133.226 292,139.5C 292.5,146.506 293,153.506 293.5,160.5C 285.5,160.5 277.5,160.5 269.5,160.5C 269.821,148.436 267.821,136.769 263.5,125.5C 270.074,121.976 276.741,118.642 283.5,115.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 211.5,136.5 C 210.848,137.725 209.681,138.392 208,138.5C 201.846,139.427 195.679,140.26 189.5,141C 194.851,141.263 200.184,141.263 205.5,141C 200.58,143.196 195.58,145.196 190.5,147C 189.244,147.417 188.577,148.25 188.5,149.5C 183.728,148.689 179.228,149.689 175,152.5C 174.667,151.167 174.333,149.833 174,148.5C 173.921,150.736 173.421,152.07 172.5,152.5C 172.606,149.385 171.606,146.719 169.5,144.5C 173.22,144.436 176.886,143.936 180.5,143C 180.167,142.667 179.833,142.333 179.5,142C 180.416,141.722 181.082,141.222 181.5,140.5C 191.5,139.167 201.5,137.833 211.5,136.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 169.5,144.5 C 171.606,146.719 172.606,149.385 172.5,152.5C 168.786,158.245 164.286,158.912 159,154.5C 157.173,145.493 160.673,142.16 169.5,144.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 224.5,134.5 C 226.415,134.216 228.081,134.549 229.5,135.5C 215.833,140.167 202.167,144.833 188.5,149.5C 188.577,148.25 189.244,147.417 190.5,147C 195.58,145.196 200.58,143.196 205.5,141C 200.184,141.263 194.851,141.263 189.5,141C 195.679,140.26 201.846,139.427 208,138.5C 209.681,138.392 210.848,137.725 211.5,136.5C 215.894,136.156 220.228,135.49 224.5,134.5 Z"/></g>
<g><path style="opacity:1" fill="#" d="M 181.5,140.5 C 181.082,141.222 180.416,141.722 179.5,142C 179.833,142.333 180.167,142.667 180.5,143C 176.886,143.936 173.22,144.436 169.5,144.5C 160.673,142.16 157.173,145.493 159,154.5C 164.286,158.912 168.786,158.245 172.5,152.5C 173.421,152.07 173.921,150.736 174,148.5C 174.333,149.833 174.667,151.167 175,152.5C 179.228,149.689 183.728,148.689 188.5,149.5C 183.972,151.404 179.306,153.237 174.5,155C 169.207,161.613 163.374,162.113 157,156.5C 153.354,148.129 155.854,142.796 164.5,140.5C 170.18,141.831 175.847,141.831 181.5,140.5 Z"/></g>
</svg>
`;
