import {
  generateMultiPageSlide,
  isMultiPageSlide,
} from '../../../../../../../../services/MultislidesBuilder';
import { TCategory, TSlide, TSlidePlatform, TSlideTheme } from '../../../../../../../../types';

export const shouldHideMultiSlidesPdf = (
  previewMode: TSlidePlatform,
  slide: TSlide,
  theme: TSlideTheme,
  category?: TCategory,
) => {
  let hideMultiSlides = false;
  if (isMultiPageSlide(previewMode, slide)) {
    const multislides = generateMultiPageSlide(
      slide,
      theme,
      previewMode,
      category || 'traditional',
    );

    hideMultiSlides = !multislides?.length;
  }

  return hideMultiSlides;
};
