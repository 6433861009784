import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { PROPERTY_STATUS, TCompConfigItem } from '../../../../types';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { COMING_SOON, TRENDS_KEYS } from './trendsConfig';
import { TrendsSection } from './TrendsSection';

const comingSoonGroups = [
  [
    COMING_SOON[TRENDS_KEYS.HIGHEST],
    COMING_SOON[TRENDS_KEYS.AVERAGE],
    COMING_SOON[TRENDS_KEYS.MEDIAN],
  ],
  [
    COMING_SOON[TRENDS_KEYS.LOWEST],
    COMING_SOON[TRENDS_KEYS.CLOSEST],
    COMING_SOON[TRENDS_KEYS.RECENT],
  ],
] as TCompConfigItem[][];

export const ComingSoonTrends = () => {
  const properties = useAtomValue(reportPropertiesAtom);

  const count = useMemo(() => {
    return properties?.filter(
      p => p.status === PROPERTY_STATUS.COMING_SOON || (p.status === 'coming_soon' && !p.excluded),
    )?.length;
  }, [properties]);

  return (
    <TrendsSection
      title='COOMING SOON PROPERTIES'
      count={count}
      groups={comingSoonGroups}
      status={PROPERTY_STATUS.COMING_SOON}
    />
  );
};
