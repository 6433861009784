import React from 'react';

import style from './Header.module.scss';
import Logos from '../../../../../components/Logos/Logos';
import { CATEGORIES } from '../../../../../../../../../constants/categories';
import ProgressTracker from '../../../../../../ProgressTracker/ProgressTracker';
import { useCategory } from '../../../../../../../../../providers/providers/CategoryProvider';
import { CommonPdfFrameProps } from '../../../PdfFrame';

interface HeaderProps extends CommonPdfFrameProps {
  page: number;
}

export const Header: React.FC<HeaderProps> = ({ page, isCover, hideProgressTracker }) => {
  const { category } = useCategory();
  const showProgressTracker = isCover || hideProgressTracker ? false : true;
  return (
    <div className={style.header}>
      <Logos containerStyles={{ justifyContent: 'flex-end' }} />
      {showProgressTracker && (
        <ProgressTracker
          category={category ?? CATEGORIES.TRADITIONAL}
          fontColor='#444'
          containerStyles={{
            left: 'auto',
            right: 0,
            top: -20,
            marginLeft: 'auto',
            width: 'max-content',
            transform: 'translate(0, 0)',
          }}
        />
      )}
    </div>
  );
};
