import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withLoadedUser } from '../../../../hoc/withLoadedUser';

import { HeaderComponent } from '../../../../components/Common';
import { HeroSection } from './components/HeroSection/HeroSection';
import { Button } from '../../../../components/Common/V2/Button/Button';
import { Container, ContentSection, TopSection } from './CustomizationSettings.styles';
import { PersonalizeContentSection } from './sections/PersonalizeContent/PersonalizeContentSection';
import { CustomizePresentationSection } from './sections/CustomizePresentation/CustomizePresentationSection';

const navItems = [{ label: 'Customization Settings', isActive: true }];

export const CustomizationSettingsPage: FC = withLoadedUser(() => {
  const { goBack } = useHistory();

  return (
    <Container>
      <TopSection>
        <HeaderComponent navItems={navItems}>
          <Button
            variant='secondary'
            prefixIcon={
              <FontAwesomeIcon fontSize={12} style={{ marginTop: '1px' }} icon={'chevron-left'} />
            }
            onClick={goBack}
          >
            Back
          </Button>
        </HeaderComponent>
        <HeroSection />
      </TopSection>
      <ContentSection>
        <CustomizePresentationSection />
        <PersonalizeContentSection />
      </ContentSection>
    </Container>
  );
});
