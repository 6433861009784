import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { DashboardInput, FitContentModal } from '../../../../../components/Common';
import { corePresentApi } from '../../../../../api/CorePresentApi';
import {
  TPresentation,
  TPresentationMode,
  TPresentationModeConfig,
  TSlideId,
} from '../../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { useSections } from '../../../../../providers/providers/SectionsProvider';
import { config as greetingVideoConfig } from '../../../../../components/Slide/slides/startingSlides/GreetingVideo';
import { hasGreetingVideoSlide } from '../../../../PresentationEdit/PresentationCreationContent/services/hasGreetingVideoSlide';
import { usePresentationMode } from '../../../../../providers/providers/PresentationModeProvider';
import { useParams } from 'react-router-dom';

export const Container = styled('div')({
  padding: '20px',
});

interface AddCoreVideoProps {
  isOpen: boolean;
  onClose: () => void;
  initialVideoLink?: string;
  presentationData?: TPresentation;
}

export const AddCoreVideo: React.FC<AddCoreVideoProps> = ({
  isOpen,
  onClose,
  initialVideoLink,
  presentationData,
}) => {
  const [errMessage, setErrMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { sections, sectionsSlide, setSectionsSlideOrder, getOrderForMode } = useOrder();
  const { getSectionsForMode } = useSections();
  const { hash } = useParams<{ hash: string }>();

  const theme = useTheme();
  const { presentationMode } = usePresentationMode();
  const hasGV = hasGreetingVideoSlide(presentationMode, { sections, sectionsSlide });

  const [videoLink, setVideoLink] = useState(hasGV && initialVideoLink ? initialVideoLink : '');

  const queryClient = useQueryClient();

  const getModes = () => {
    const modesConfig: Partial<Record<TPresentationMode, TPresentationModeConfig>> = {};
    const modes: TPresentationMode[] = ['cma', 'oneSheeter', 'regular'];

    modes.forEach(mode => {
      const modeOrderConfig = getOrderForMode(mode);
      const modeSectionsConfig = getSectionsForMode(mode);

      if (!modeOrderConfig || !modeSectionsConfig) return;

      const firstSectionSlideOrder = (
        modeOrderConfig.sectionsSlide[modeOrderConfig.sections[0]] || []
      ).filter(sId => sId !== 'greetingVideo');

      const newSectionOrder = {
        ...modeOrderConfig.sectionsSlide,
        [modeOrderConfig.sections[0]]: ['greetingVideo', ...firstSectionSlideOrder] as TSlideId[],
      };
      setSectionsSlideOrder(newSectionOrder);

      modesConfig[mode] = {
        order: {
          sections: modeOrderConfig.sections,
          sectionsSlide: newSectionOrder,
        },
        sections: modeSectionsConfig,
      };
    });

    return modesConfig;
  };

  const onConfirmVideoLink = async () => {
    try {
      setLoading(true);
      const isVideoValid =
        /(youtube|youtu|loom)\.(com|be)\/((watch\?v=([-\w]+))|(share\/([-\w]+))|([-\w]+))/.test(
          videoLink,
        );
      if (!presentationData) return;

      if (!isVideoValid) {
        setErrMessage('Url should be valid YouTube or Loom video url');
        return;
      }

      const data = {
        ...presentationData,
        slidesMap: {
          ...presentationData.slidesMap,
          greetingVideo: greetingVideoConfig,
        },
        ...getModes(),
        videoLink,
      };

      await corePresentApi.put(`/presentations/${presentationData.id}`, {
        data: JSON.stringify(data),
      });
      queryClient.invalidateQueries(['presentation', presentationData?.hash]);
      onClose();
    } catch (e: any) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FitContentModal
      isOpen={isOpen}
      headerTitle='Add Video Link'
      onClose={onClose}
      confirmBtnText='Add'
      hasCancelBtn
      onConfirmClick={onConfirmVideoLink}
      modalDescription={{ text: 'You can add a YouTube or Loom video link.' }}
      modalDescriptionContainerStyles={{ display: 'block', color: theme.colors.v2.gray[400] }}
      loading={loading}
    >
      <Container>
        <DashboardInput
          type='text'
          label='Video URL'
          placeholder='Video URL'
          inputId='video-url'
          value={videoLink}
          onChange={e => {
            setVideoLink(e.currentTarget.value);
          }}
          errorMessage={errMessage}
        />
      </Container>
    </FitContentModal>
  );
};
