import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Popup from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthApi } from '../../../api/AuthApi';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { Button } from '../../../components/Common';

const UserDropdown = () => {
  const user = useUser();
  const { colors } = useTheme();

  const history = useHistory();

  const isMobile = useMediaQuery('(max-width: 800px)');

  const logout = async () => {
    await AuthApi.logout();
    return history.push('/');
  };

  return (
    <Popup
      position={isMobile ? 'bottom right' : 'bottom left'}
      arrow={false}
      offsetY={1}
      closeOnDocumentClick
      trigger={open => (
        <Button
          btnstyle='option'
          icon={{
            name: 'avatar',
            position: 'left',
            style: { marginRight: isMobile ? '0px' : '5px' },
          }}
          sufixUI={
            <MobileHidden isRightAlign>
              <FontAwesomeIcon
                icon={['fas', 'chevron-down']}
                fontSize='12px'
                style={{
                  color: colors.v2.gray[300],
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'all .2s ease',
                }}
              />
            </MobileHidden>
          }
          style={{
            padding: '10px',
            height: '100%',
            lineHeight: 1,
            minWidth: isMobile ? 'fit-content' : '230px',
            border: open ? 'double 1px transparent' : '1px solid #b1b1b1',
            backgroundOrigin: open ? 'border-box' : '',
            backgroundClip: open ? 'padding-box, border-box' : '',
            backgroundImage: open
              ? colors.v2.gradient.border
              : 'linear-gradient(white, white),linear-gradient(#B1B1B1, #B1B1B1)',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset',
          }}
        >
          <MobileHidden style={{ width: '100%' }}>{user?.name}</MobileHidden>
        </Button>
      )}
      contentStyle={{
        width: isMobile ? 'fit-content' : '230px',
        padding: '4px 8px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        fontSize: '14px',
        fontFamily: 'Figtree',
        color: colors.v2.gray[400],
      }}
    >
      <span onClick={logout}>Click to logout</span>
    </Popup>
  );
};

export default UserDropdown;

const MobileHidden = styled.div<{ isRightAlign?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isRightAlign ? 'flex-end' : 'space-between')};
  line-height: 1;
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 800px) {
    display: none;
  }
`;
