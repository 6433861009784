import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

type WithCanadaUser = {
  isCanadaUser?: boolean;
};

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: `linear-gradient(to right, #C4C4C4, ${theme.colors.gray[100]}, rgba(250, 250, 250, .4))`,
  display: 'flex',
  position: 'relative',
}));

export const AsideBackground = styled('img')({
  position: 'absolute',
  width: 'auto',
  height: '110%',
  transform: 'translate(-40px, -40px)',
  zIndex: 2,
});

export const Graphic = styled('img')<WithCanadaUser>(({ isCanadaUser }) => ({
  marginLeft: 'auto',
  height: isCanadaUser ? '120%' : '97%',
  width: 'auto',
  position: isCanadaUser ? 'absolute' : 'static',
  top: -50,
  right: 0,
}));

export const ContentContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '45px',
  position: 'relative',
});

export const TextualContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: 3,
  width: 382,
});

export const Heading = styled('h2')(({ theme }) => ({
  fontFamily: useUiConfigFontsHeader(),
  color: useUiConfigColorsPrimary(),
  fontWeight: 900,
  lineHeight: '40px',
  fontSize: 30,
  marginBottom: 20,
  textTransform: 'uppercase',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const commonStyles = {
  fontSize: 18,
  color: '#000',
  lineHeight: 1.58,
  marginBottom: 30,
};
export const Text = styled('p')(({ theme }) => ({
  ...commonStyles,
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const UL = styled('ul')({ paddingLeft: 10, listStyle: 'none' });

export const LI = styled('li')(({ theme }) => ({
  ...commonStyles,
  position: 'relative',
  marginBottom: 10,
  letterSpacing: useUiConfigBodyLetterSpacing(),
  '&:before': {
    content: `" "`,
    display: 'block',
    position: 'absolute',
    width: 4,
    height: 4,
    left: -10,
    top: 10,
    borderRadius: 50,
    backgroundColor: '#000',
  },
}));

export const SourceText = styled('p')(({ theme }) => ({
  color: '#101010',
  fontSize: 13.5,
  fontWeight: 300,
  position: 'absolute',
  width: 'max-content',
  bottom: 20,
  right: 70,
}));
