import React from 'react';
import styled, { useTheme } from 'styled-components';

import { Typography } from '../Common';
import { getCategoryConfigs } from '../../constants/categories';
import type { TCategory, TSlide, TSlideTheme } from '../../types';
import { useCategory } from '../../providers/providers/CategoryProvider';
import { ThemeSelectionDropdown } from './ThemeSelectionDropdown/ThemeSelectionDropdown.component';
import { Button } from '../Common/V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonTextBold = styled('span')({
  fontWeight: 700,
  marginLeft: '3px',
});

const ButtonText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

export const ButtonWrapper = styled('div')({
  position: 'relative',
});

interface ThemeSelectionProps {
  onOpenDropdown: () => void;
  isOpen: boolean;
  onCloseDropdown: () => void;
  selectedTheme: TSlideTheme;
  onChooseTheme: (theme: TSlideTheme) => void;
  slide?: TSlide;
  isPositionLeft?: boolean;
}

export const ThemeSelection: React.FC<ThemeSelectionProps> = ({
  onOpenDropdown,
  isOpen,
  onCloseDropdown,
  selectedTheme,
  onChooseTheme,
  slide,
  isPositionLeft,
}) => {
  const theme = useTheme();
  const { category } = useCategory();

  return (
    <ButtonWrapper>
      <Button
        variant='secondary'
        prefixIcon={
          <FontAwesomeIcon icon={['fas', 'table-layout']} color={theme.colors.v2.primary} />
        }
        onClick={() => onOpenDropdown()}
        style={{ boxShadow: 'none' }}
      >
        Theme: {getCategoryConfigs(category as TCategory)?.label}
      </Button>
      <ThemeSelectionDropdown
        isOpen={isOpen}
        onClose={onCloseDropdown}
        selectedTheme={selectedTheme}
        onChooseTheme={onChooseTheme}
        slide={slide}
        isPositionLeft={isPositionLeft}
      />
    </ButtonWrapper>
  );
};
