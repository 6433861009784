import React from 'react';
import styled from 'styled-components';

import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { MarketAssessmentGrid } from '../../../../../../features/report/market-assessment/MarketAssessmentGrid';

const Container = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Headline = styled('h2')(({ theme }) => ({
  fontSize: 20,
  color: '#000',
  fontFamily: useUiConfigFontsHeader(),
  textTransform: 'uppercase',
  textAlign: 'center',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const Description = styled('p')(({ theme }) => ({
  fontSize: 16,
  color: '#000',
  marginBottom: 10,
  lineHeight: 1.5,
  fontFamily: useUiConfigFontsBody(),
  textAlign: 'center',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const MarketOverview: React.FC = () => {
  const { category } = useCategory();

  return (
    <Container>
      <Headline>Market Assessment</Headline>
      <Description>Click on any box to view further details.</Description>
      <MarketAssessmentGrid category={category} />
    </Container>
  );
};
