import { TSlide } from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { HomeSellingProcessLuxury } from './web/luxury/HomeSellingProcessLuxury';
import { HomeSellingProcessTraditional } from './web/traditional/HomeSellingProcessTraditional';
import HomeSellingProcessModern from './web/modern/HomeSellingProcessModern';
import { HomeSellingProcessTraditionalPdf } from './pdf/traditional/HomeSellingProcessTraditionalPdf';
import { HomeSellingProcessModernPdf } from './pdf/modern/HomeSellingProcessModernPdf';
import { HomeSellingProcessLuxuryPdf } from './pdf/luxury/HomeSellingProcessLuxuryPdf';

const id = 'homeSellingProcess';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'Home Selling Process',
  frame: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    [CATEGORIES.TRADITIONAL]: HomeSellingProcessTraditional,
    [CATEGORIES.MODERN]: HomeSellingProcessModern,
    [CATEGORIES.LUXURY]: HomeSellingProcessLuxury,
  },
  pdf: {
    [CATEGORIES.TRADITIONAL]: HomeSellingProcessTraditionalPdf,
    [CATEGORIES.MODERN]: HomeSellingProcessModernPdf,
    [CATEGORIES.LUXURY]: HomeSellingProcessLuxuryPdf,
  },
};

export type THomeSellingProcess = typeof id;
export { config, mappings };
