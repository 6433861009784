import styled from 'styled-components';

export * from '../../../common.styles';

export const FloatWrapper = styled('div')({
  '&::before': {
    content: '""',
    display: 'block',
    float: 'left',
    height: '125px',
  },
});

interface Props {
  isEditable?: boolean;
}

const imageWidth = 400;
const imageHeight = imageWidth * 1.25;

export const ImageWrapper = styled('div')({
  float: 'right',
  clear: 'both',
  width: imageWidth,
  height: imageHeight,
  paddingTop: 22,
  paddingLeft: 30,
});

export const ImagePreview = styled('div')<Props>(({ theme, isEditable }) => ({
  border: `2px solid ${isEditable ? theme.colors.v2.primary : 'transparent'}`,
  position: 'relative',
  width: `100%`,
  height: `100%`,
  minWidth: `100%`,
  minHeight: `100%`,
  content: '" "',
}));

export const Image = styled('div')({
  minWidth: `100%`,
  minHeight: `100%`,
  width: `100%`,
  height: `100%`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'block',
  content: '""',
});

export const UploadIconWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const Text = styled('div')({
  '& p': {
    padding: '10px 0',
    lineHeight: 'normal',
    fontSize: 16,
  },
});
