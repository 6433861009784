import React, { Dispatch, SetStateAction } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider, { Handle } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Container, HandleEl, RatingText } from './RatingSlider.styles';
import { useUiConfigColorsPrimary } from '../../../../../../../../../providers/providers/UiConfigColorProvider';
import { TrackingEvent } from '../../../../../../../../../services/TrackingEvent';
import { RATINGS } from '../../../../../../../../../pages/Home/components/PastPresentation/components/AnalyticsModal/services/formatBTAnalyticsTableData';

type TRatingKey = 'rating1' | 'rating2' | 'rating3';

const ratingText: Record<TRatingKey, string> = {
  rating1: 'Not Interested',
  rating2: 'Considering',
  rating3: 'Make An Offer',
};

interface RatingSliderProps {
  propertyId: string;
  propertyAddress: string;
  setPropertyRatings: Dispatch<SetStateAction<Record<string, number>>>;
  propertyRatings: Record<string, number>;
}

export const RatingSlider: React.FC<RatingSliderProps> = ({
  propertyId,
  propertyAddress,
  setPropertyRatings,
  propertyRatings,
}) => {
  const getRatingText = (value: number) => {
    return ratingText[`rating${value}` as TRatingKey];
  };

  const onChange = (value: number, propertyId: string) => {
    const ratingKey =
      Object.values(RATINGS).find(item => item.value === value)?.key || RATINGS.NOT_INTERESTED.key;

    TrackingEvent.clickRating(
      `Clicked property rating for property ${propertyAddress} (MLS ID: ${propertyId}): ${getRatingText(
        value,
      )}`,
      propertyId,
      ratingKey,
    );
    setPropertyRatings(prev => ({
      ...prev,
      [propertyId]: value,
    }));
  };

  const style = {
    trackStyle: [
      {
        backgroundColor: useUiConfigColorsPrimary(),
        height: 15,
        borderRadius: 10,
        cursor: 'pointer',
      },
    ],
    handleStyle: [
      {
        borderColor: useUiConfigColorsPrimary(),
        backgroundColor: '#fff',
        borderWidth: '1px',
        boxShadow: 'none',
        width: 15,
        height: 15,
        marginTop: 0,
        position: 'relative',
      },
    ],
    railStyle: {
      backgroundColor: '#ddd',
      height: 15,
      borderRadius: 10,
      cursor: 'pointer',
    },
  };

  const MyHandle = ({ ...restProps }) => {
    return (
      <Handle {...restProps}>
        <HandleEl>{propertyRatings[propertyId]}</HandleEl>
      </Handle>
    );
  };

  return (
    <Container>
      <Slider
        min={1}
        max={3}
        value={propertyRatings[propertyId]}
        onChange={(value: number) => onChange(value, propertyId)}
        {...style}
        handle={MyHandle}
        style={{ height: 20 }}
      />
      <RatingText>{getRatingText(propertyRatings[propertyId])}</RatingText>
    </Container>
  );
};
