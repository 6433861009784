import React from 'react';
import styled from 'styled-components';
import slideGraphics from './slideGraphics';
import loop from './loop.png';
import bg from './bg.png';
import * as data from '../../data';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import { TraditionalSlideCircularCard } from '../../../../../components/TraditionalSlideCircularCard/TraditionalSlideCircularCard';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const Heading = styled.h1`
  color: ${() => useUiConfigColorsPrimary()};
  padding-left: 40px;
  margin-bottom: 100px;
  font-size: 26px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  width: 50%;
  z-index: 5;
  font-family: ${() => useUiConfigFontsHeader()};
  letter-spacing: ${() => useUiConfigHeaderLetterSpacing()};
`;

const Background = styled('img')({
  width: '100%',
  height: 'auto',
  position: 'absolute',
});

const Loop = styled('img')({
  position: 'absolute',
  right: 30,
  top: 0,
  height: '100%',
});

const Graphics = styled('img')({
  position: 'absolute',
  width: '95%',
  height: 'auto',
  left: 20,
  top: 280,
});

const WhatBuyersWantFromTheirAgentstTraditionalPDF: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const image = React.useMemo(() => svgStringToDataUrl(slideGraphics, primary), [primary]);

  return (
    <PdfFrame category={CATEGORIES.TRADITIONAL}>
      <Container>
        <Heading>
          What BUYERS want
          <br />
          from their agents
        </Heading>
        <Background src={bg} />
        <Graphics src={image} />
        <Loop src={loop} />
        <TraditionalSlideCircularCard
          heading={data.listItems[0].title}
          text={data.listItems[0].text}
          position={{ top: 374, left: 80 }}
          size={195}
          textStyles={{ fontSize: 13, lineHeight: 1.2 }}
          headingStyles={{ fontSize: 14 }}
        />
        <TraditionalSlideCircularCard
          heading={data.listItems[1].title}
          text={data.listItems[1].text}
          position={{ top: 275, left: 300 }}
          size={195}
          textStyles={{ fontSize: 13, lineHeight: 1.2 }}
          headingStyles={{ fontSize: 14 }}
        />
        <TraditionalSlideCircularCard
          heading={data.listItems[2].title}
          text={data.listItems[2].text}
          position={{ top: 380, right: 60 }}
          size={195}
          textStyles={{ fontSize: 13, lineHeight: 1.2 }}
          headingStyles={{ fontSize: 14 }}
        />
        <TraditionalSlideCircularCard
          heading={data.listItems[3].title}
          text={data.listItems[3].text}
          position={{ top: 604, right: 20 }}
          size={192}
          textStyles={{ fontSize: 13, lineHeight: 1.2 }}
          headingStyles={{ fontSize: 14 }}
        />
        <TraditionalSlideCircularCard
          heading={data.listItems[4].title}
          text={'7% of buyers want to know the worth of nearby homes.'}
          position={{ top: 600, left: 7.5 }}
          size={210}
          textStyles={{ fontSize: 13, lineHeight: 1.2 }}
          headingStyles={{ fontSize: 14 }}
        />
      </Container>
    </PdfFrame>
  );
};

export default WhatBuyersWantFromTheirAgentstTraditionalPDF;
