import styled from 'styled-components';
import { CSSObject } from 'styled-components';

import { Icon } from '../../';
import { IconName } from '../../Icon/Icon.types';
import { Button } from '../../V2/Button/Button';

type ModalDescriptionProps = {
  buttonText?: string;
  iconName?: IconName;
  descriptionText?: string;
  onButtonClick?: () => void;
  modalDescriptionContainerStyles?: CSSObject;
};

export function ModalDescription({
  iconName,
  descriptionText,
  buttonText,
  onButtonClick,
  modalDescriptionContainerStyles,
}: ModalDescriptionProps) {
  return (
    <DescriptionContainer style={modalDescriptionContainerStyles}>
      <Wrapper>
        {iconName && <Icon iconName={iconName} style={{ marginRight: 18 }} />}
        <Text>{descriptionText}</Text>
      </Wrapper>
      {buttonText && (
        <Button variant='primary' onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </DescriptionContainer>
  );
}

export const DescriptionContainer = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.v2.gray[300]};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  width: 90%;
`;
