import React, { createContext, useContext, useMemo } from 'react';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAP_KEY } from '../../../pages/PresentationCreate/dash/config/constants';

type GoogleMapContextType = {
  isLoaded: boolean;
};

const GoogleMapContext = createContext<GoogleMapContextType>({ isLoaded: true });

export const useGoogleMapLoaded = () => {
  const context = useContext(GoogleMapContext);

  if (!context) throw new Error('useGoogleMap cannot be used without GoogleMapContext');

  return context?.isLoaded;
};

const libraries = ['drawing'] as Libraries;

export const GoogleMapProvider: React.FC = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
  });

  const value = useMemo(() => ({ isLoaded }), [isLoaded]);

  if (!isLoaded) return null;

  return <GoogleMapContext.Provider value={value}>{children}</GoogleMapContext.Provider>;
};
