import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';

export const PropertyPreviewContainer = styled('div')(({ theme }) => ({
  fontFamily: useUiConfigFontsBody(),
}));

export const ReportInfo = styled('p')({
  fontSize: 16,
  fontWeight: 400,
  color: '#858585',
  textAlign: 'right',
});

export const PropertyPreviewWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: -30,
});

export const PropertyPreview = styled('div')({
  width: 1000,
  height: 655,
  position: 'relative',
});

export const PropertyAddressFrame = styled('div')({
  position: 'absolute',
  width: '83%',
  height: 80,
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, -25%)',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 0px 7.24919px rgba(0, 0, 0, 0.15)',
  padding: 5,
});

export const PropertyAddressWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  width: '100%',
  height: '100%',
  border: '0.5px solid rgba(221, 221, 221, 0.6)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: useUiConfigFontsHeader() + ' !important',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const Address = styled('h4')(({ theme }) => ({
  color: useUiConfigColorsPrimary(),
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: 22,
  fontWeight: 700,
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const PropertyInfoItems = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 5,
});

interface T {
  displayImage?: boolean;
}

export const PropertyInfoItem = styled('div')<T>(({ displayImage }) => ({
  fontSize: displayImage ? 16 : 18,
  fontWeight: 400,
  color: '#333',
  textTransform: 'uppercase',
  marginRight: displayImage ? 25 : 40,
  '&:last-of-type': {
    marginRight: 0,
  },
}));
