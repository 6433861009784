import styled from 'styled-components';

interface ThumbnailImageProps {
  active: boolean;
}

export const ThumbnailWrapper = styled.div<ThumbnailImageProps>`
  width: 49%;
  height: 93px;
  border: double 1px transparent;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-image: ${({ active, theme }) => (active ? theme.colors.v2.gradient.border : 'none')};
`;

export const ThumbnailImage = styled.div<ThumbnailImageProps>`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  box-shadow: ${({ active }) => (active ? '2px 4px 12px rgba(0, 0, 0, 0.3)' : 'none')};
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1%;
  padding: 0 20px;
`;

export const Col = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 5,
});

export const SectionTitle = styled('h5')(({ theme }) => ({
  color: theme.colors.v2.text.regular,
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '10px',
}));
