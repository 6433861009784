import React from 'react';
import PropTypes from 'prop-types';

import styles from './Checkbox.module.scss';

const Checkbox = ({ label, name, value, onChange, className, disabled }) => {
  return (
    <label
      className={`${styles.container} ${disabled && styles.disabled} ${
        !label && styles.defaultHeight
      } ${className}`}
    >
      {label}
      <input
        type='checkbox'
        name={name}
        checked={value}
        className={value ? 'custom-checked' : ''}
        onChange={() => onChange(!value)}
        disabled={disabled}
      />
      <span className={styles.checkmark}>
        <div />
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  print: PropTypes.bool,
};

Checkbox.defaultProps = {
  defaultChecked: false,
  onChange: f => f,
  label: '',
  name: '',
  className: '',
  disabled: false,
  print: false,
};

export { Checkbox };
