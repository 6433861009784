import styled from 'styled-components';

export const Title = styled('h6')(({ theme }) => ({
  color: theme.colors.gray[300],
  fontSize: 14,
  marginTop: 30,
  marginBottom: 15,
  '&:last-of-type': {
    marginBottom: 10,
    marginTop: 20,
  },
}));

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});
