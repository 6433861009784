import { TPresentationMode } from '../../../../types';
import { ConfigItem } from '../../../../providers/providers/OrderProvider';

export function hasNetProceedsSlide(mode: TPresentationMode | undefined, order: ConfigItem | null) {
  if (!order) return false;

  if (mode === 'regular') {
    return order?.sectionsSlide?.closingAndNextSteps?.includes('netProceeds');
  }

  const [firstSectionId] = order.sections;
  return order?.sectionsSlide?.[firstSectionId]?.includes('netProceeds');
}
