import { PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

type FallbackRendererProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

function fallbackRender({ error, resetErrorBoundary }: FallbackRendererProps) {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export function GlobalErrorBoundary({ children }: PropsWithChildren<{}>) {
  useEffect(() => {
    window.onerror = function (message) {
      if (import.meta.env.NODE_ENV === 'development') {
        closeOmsLibError(message);
      }
    };

    window.onunhandledrejection = function (event) {
      console.error('Unhandled rejection:', event.reason);
    };
  }, []);
  return (
    <ErrorBoundary FallbackComponent={fallbackRender} onReset={() => window.location.reload()}>
      {children}
    </ErrorBoundary>
  );
}

function closeOmsLibError(message: string | Event) {
  if (typeof message !== 'string' || !message.includes('fromLatLngToDivPixel')) return;
  let frame = document.querySelector('#webpack-dev-server-client-overlay') as HTMLIFrameElement;
  if (!frame) return false;

  (
    frame.contentWindow?.document?.querySelector('[aria-label="Dismiss"]') as HTMLButtonElement
  )?.click?.();
}
