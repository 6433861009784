import styled from 'styled-components';

type WithActive = {
  isActive?: boolean;
};

type WithToolbarSize = {
  toolbarSize?: 'regular' | 'large';
};

export const ButtonsWrapper = styled('div')<WithToolbarSize>(({ toolbarSize }) => ({
  display: 'flex',
  margin: toolbarSize === 'large' ? '0 20px' : '0 10px',
  alignItems: 'center',
}));

export const StyleButton = styled('button')<WithActive & WithToolbarSize>(
  ({ isActive, theme, toolbarSize }) => ({
    fontSize: toolbarSize === 'large' ? 20 : 12,
    border: 'none',
    borderRadius: 3,
    background: 'transparent',
    marginRight: 10,
    cursor: 'pointer',
    color: isActive ? theme.colors.primary.main : '#000',
  }),
);
