import React from 'react';
import image from './image.png';
// import * as data from '../../data';
import { listItems, subtitle } from '../../data';
import TwoSectionsSlideLuxuryTemplatePDF from '../../../../../components/TwoSectionsSlideLuxuryTemplate/pdf/TwoSectionsSlideLuxuryTemplatePDF';

export const HomeSellingProcessLuxuryPdf: React.FC = () => {
  const paragraphs = listItems.map(item => {
    return `${item.title}: ${item.text}`;
  });
  return (
    <TwoSectionsSlideLuxuryTemplatePDF
      heading='Home Selling Process'
      paragraphs={[subtitle, ...paragraphs]}
      image={image}
    />
  );
};
