import { corePresentApi } from './CorePresentApi';
import { StorageService } from '../services/storageService';
import { CurrentUserService } from '../services/CurrentUserService';

export class AuthApi {
  static isLoggedIn = (): boolean => StorageService.has('token');

  static logout = async () => {
    await corePresentApi.post('/auth/logout');
    StorageService.remove('token');
    CurrentUserService.remove();
  };

  static getSsoUrl = async (provider: string): Promise<string> => {
    const data = await corePresentApi.get(`/auth/sso/${provider}/redirect`);
    return data?.url;
  };

  static processSsoCallback = async (queryParams: string): Promise<any> => {
    return await corePresentApi.get(`/auth/sso/kvcore/callback${queryParams}`);
  };
}
