import styled from 'styled-components';

export const Container = styled('div')({
  padding: '44px 30px 30px 30px',
});

export const Text = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.colors.gray[400],
}));
