import React from 'react';
import { TPresentation } from '../../../types';
import { WTREditActions } from './WTREditActions';
import { PresentationBuildingLayout } from '../../../layouts/PresentationBuildingLayout/PresentationBuildingLayout';

const navItems = [
  {
    label: '1. Create Presentation',
    isActive: true,
  },
  {
    label: '2. Save & Present',
    isActive: false,
  },
];

type Props = {
  presentation: TPresentation;
};

export const WTREditingLayout: React.FC<Props> = ({ presentation, children }) => {
  return (
    <PresentationBuildingLayout
      HeaderActions={<WTREditActions presentation={presentation} />}
      navigationItems={navItems}
      isScrollable
    >
      {children}
    </PresentationBuildingLayout>
  );
};
