import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

export const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
`;

export const AddLinksTitle = styled('p')(({ theme }) => ({
  fontSize: '14px',
  color: theme.colors.v2.gray[400],
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
}));

export const AddLinkButton = styled('button')(({ theme }) => ({
  color: theme.colors.primary.main,
  background: 'transparent',
  border: 'none',
  fontSize: 11,
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
}));

export const LinkInputsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 9,
  alignItems: 'center',
  gap: 10,
});

export const RemoveLinkInputs = styled('div')({
  cursor: 'pointer',
});
