import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { corePresentApi } from '../../../../../api/CorePresentApi';
import { ComparableExtension } from '../../../../../types';

function fetchExtensions(propertyId: string) {
  return corePresentApi.get(`/comparable-property/${propertyId}/extensions`);
}

export function useComparableExtensions(propertyId: string): UseQueryResult<ComparableExtension[]> {
  return useQuery(['extensions', propertyId], () => fetchExtensions(propertyId), {
    retry: false,
  });
}

export function useInvalidateComparableExtensions() {
  const qc = useQueryClient();

  return (propertyId: string) => qc.invalidateQueries(['extensions', propertyId]);
}
