import styled from 'styled-components';

export const HeaderSectionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.colors.v2.primary,
  minHeight: '141px',
}));

export const LeftSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray[100],
  clipPath: ' polygon(0 0, 100% 0, 83% 100%, 0% 100%)',
  width: '52%',
  display: 'flex',
  flexDirection: 'column',
}));

export const LeftSectionWrapper = styled.div`
  width: 84%;
  height: 100%;
`;

export const RightSection = styled.div({
  width: '48%',
});
