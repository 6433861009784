import Frame from './ModernFrameBeta';
import thumbnail from './modernBeta.png';
import { TFrameConfig } from '../../../../../../../../types';

const id = 'modernBeta';

const config: TFrameConfig = {
  id,
  thumbnail,
  Frame,
};

export type TModernFrameBeta = typeof id;
export default config;
