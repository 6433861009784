import React, { useState } from 'react';
import { TSlide, TSlideTheme } from '../../../../../../types';
import { useOutsideClick } from '../../../../../../services/useOutsideClick';
import { SlideSettingsContainer, Title, ButtonWrapper } from './SlideSettings.styles';
import { ThemeSelection } from '../../../../../../components/ThemeSelection/ThemeSelection.component';

interface SlideSettingsProps {
  selectedTheme: TSlideTheme;
  onChooseTheme: (theme: TSlideTheme) => void;
  slideId: string;
  slide: TSlide;
}

export const SlideSettings: React.FC<SlideSettingsProps> = ({
  selectedTheme,
  onChooseTheme,
  slideId,
  slide,
}) => {
  const [open, setOpen] = useState<React.RefObject<HTMLDivElement> | null>(null);

  const themeElRef = React.useRef<HTMLDivElement>(null);

  const onCloseDropdown = () => {
    setOpen(null);
  };

  useOutsideClick(open, onCloseDropdown);

  return (
    <SlideSettingsContainer>
      <Title>Slide settings</Title>
      <ButtonWrapper ref={themeElRef}>
        <ThemeSelection
          onOpenDropdown={() => setOpen(themeElRef)}
          onCloseDropdown={onCloseDropdown}
          isOpen={!!open && open.current === themeElRef?.current}
          selectedTheme={selectedTheme}
          onChooseTheme={onChooseTheme}
          slide={slide}
          isPositionLeft
        />
      </ButtonWrapper>
      {/*{!isPersonalSlide && (*/}
      {/*  <ButtonWrapper>*/}
      {/*    <Button*/}
      {/*      btnstyle='option'*/}
      {/*      size='large'*/}
      {/*      icon={{ name: 'document', position: 'left', fill: ' #6AB4E4' }}*/}
      {/*      onClick={console.log}*/}
      {/*      style={{ boxShadow: 'none', width: 180 }}*/}
      {/*    >*/}
      {/*      <Typography color='400' style={{ display: 'flex' }}>*/}
      {/*        Content: <ButtonTextBold>Traditional</ButtonTextBold>*/}
      {/*      </Typography>*/}
      {/*    </Button>*/}
      {/*  </ButtonWrapper>*/}
      {/*)}*/}
    </SlideSettingsContainer>
  );
};
