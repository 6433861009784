import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { CATEGORIES } from '../../../../../constants/categories';
import { OneSheeterPdf } from './pdf/OneSheeterPdf';
import { OneSheeter } from './web/OneSheeter';

const id = 'oneSheeter';

const config: TSlide = {
  id,
  includeSlide: false,
  label: 'One Sheeter',
  frame: true,
  buyerInterestData: null,
};

const mappings: TSlideTypeMapping = {
  pdf: {
    shared: OneSheeterPdf,
  },
  web: {
    shared: OneSheeter,
  },
};

export type TOneSheeter = typeof id;
export { config, mappings };
