import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TDynamicSlideTypeMapping } from '../../../types';
import { TextBeta } from './TextBeta';
import { TextBetaForm } from './TextBetaForm';
import { TextBetaPdf } from './TextBetaPdf';

import thumbnail from './thumbnail.png';

const template = 'textLayoutBeta';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Text Only',
  frame: true,
  data: {
    headline: '',
    text: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TextBetaForm,
  web: TextBeta,
  pdf: TextBetaPdf,
};

export type TTextLayoutBeta = typeof template;
export { config, mappings, thumbnail };
