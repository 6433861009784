import { useMemo } from 'react';
import { useSlides } from '../../../../../../../../providers/providers/SlidesProvider';
import { usePresentationType } from '../../../../../../../../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../../../../../../../../providers/providers/PresentationModeProvider';
import { useUserCategoryCustomizations } from '../../../../../../../../hooks/useUserCategoryCustomizations';
import { OrderValidator } from '../../../../../../../../services/validators/OrderValidator';
import { PresentationConfigsFactory } from '../../../../../PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';

export function useUserCategoryCustomizationOrder(
  hash: string,
  entity: number | null = null,
  useSpecialBranding = false,
) {
  const { presentationMode } = usePresentationMode();
  const { presentationType } = usePresentationType();

  const { slides } = useSlides();
  const customizationQuery = useUserCategoryCustomizations(hash, entity, useSpecialBranding);

  return useMemo(() => {
    if (
      customizationQuery.isLoading ||
      !Object.keys(slides ?? {}).length ||
      !presentationType ||
      !presentationMode
    ) {
      return {
        isLoading: true,
        isFetching: false,
        data: undefined,
      };
    }

    const defaultOrder = PresentationConfigsFactory.create(
      presentationType,
      presentationMode,
    ).getDefaultOrder();
    const validator = new OrderValidator(presentationType, presentationMode);

    const rawSections =
      customizationQuery.data?.orderByType?.[presentationType]?.sections ??
      customizationQuery.data?.order?.sections ??
      defaultOrder.sections;

    const sections = rawSections ? validator.getPartialSectionsOrder(rawSections) : null;

    let sectionSlidesConfig =
      customizationQuery.data?.orderByType?.[presentationType]?.sectionsSlide;

    if (
      !sectionSlidesConfig &&
      (presentationType === 'winTheListing' || presentationType === 'winTheOffer')
    ) {
      sectionSlidesConfig = customizationQuery.data?.order?.sectionsSlide;
    }

    const sectionSlidesMap = !sectionSlidesConfig
      ? defaultOrder.sectionsSlide
      : Object.keys(sectionSlidesConfig)?.reduce((map, section) => {
          return {
            ...map,
            [section]: sectionSlidesConfig?.[section]?.filter(s => s.includeSlide).map(s => s.id),
          };
        }, {});

    const sectionsSlide = validator.getPartialSectionsSlidesOrder(sectionSlidesMap, slides ?? {});

    return {
      isLoading: false,
      isFetching: customizationQuery.isFetching,
      data: { sections, sectionsSlide },
    };
  }, [
    customizationQuery.isLoading,
    customizationQuery.data?.orderByType,
    customizationQuery.data?.order?.sections,
    customizationQuery.data?.order?.sectionsSlide,
    customizationQuery.isFetching,
    slides,
    presentationType,
    presentationMode,
  ]);
}
