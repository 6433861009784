import styled from 'styled-components';
import { Icon, FilePicker } from '../../../../../../../../../../components/Common';

export { Icon, FilePicker };

type WithDisabled = {
  isDisabled?: boolean;
};

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'fit-content',
  marginTop: 30,
});

export const AddButton = styled('button')<WithDisabled>(({ theme, isDisabled }) => ({
  height: 45,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  backgroundColor: theme.colors.gray[100],
  border: `1px solid ${theme.colors.gray[200]}`,
  color: theme.colors.gray[400],
  fontSize: 14,
  transition: 'all .2s',
  cursor: 'pointer',
  width: '90%',
  '& svg': {
    marginRight: theme.spacing(1),
  },
  '&:hover': {
    backgroundColor: isDisabled ? theme.colors.gray[100] : theme.colors.gray[300],
    color: isDisabled ? theme.colors.gray[400] : '#fff',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 104,
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: 5,
  width: '100%',
}));

export const Image = styled('img')({
  height: '85%',
  width: '90%',
  objectFit: 'contain',
});

export const CloseIcon = styled(Icon)({
  position: 'absolute',
  top: -8,
  right: -8,
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    transform: 'cmaReport(1.05)',
  },
});

export const LogoPickerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

export const Lock = styled(Icon)({
  height: 19,
  width: 'auto',
  marginLeft: 12.25,
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    transform: 'cmaReport(1.05)',
  },
});

export const KvCoreLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const KvCoreLogoImg = styled.img`
  height: 100px;
  object-fit: contain;
`;
