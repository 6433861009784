import { TSlide } from '../../../../../types';
import { TSlideTypeMapping } from '../../../services/PredefinedSlideFactory';
import { ListingFeedbackPDF } from './pdf/ListingFeedbackPDF.component';
import { ListingFeedback } from './web/ListingFeedback.component';

const id = 'listingFeedback';

const config: TSlide = {
  id,
  includeSlide: true,
  label: 'Listing Feedback',
  frame: false,
  isInteractive: true,
};

const mappings: TSlideTypeMapping = {
  web: {
    shared: ListingFeedback,
  },
  pdf: {
    shared: ListingFeedbackPDF,
  },
};

export type TListingFeedback = typeof id;
export { config, mappings };
