import { ChangeEvent, KeyboardEvent } from 'react';
import { Input } from '../../../../../../../../../../../components/Common/V2/Input';
import { Editable } from './Editable/Editable';
import NumberFormat from 'react-number-format';

interface Props {
  label: string;
  value: string;
  defaultValue: string;
  onConfirm: () => void;
  onUndo: () => void;
  enterPressed: boolean;
  editValue: string;
  onChange: (value?: number) => void;
  onEnterSubmit: () => void;
  thousandSeparator?: boolean;
}

export const EditableInput = ({
  label,
  value,
  defaultValue,
  onConfirm,
  onUndo,
  enterPressed,
  editValue,
  onChange,
  onEnterSubmit,
  thousandSeparator = true,
}: Props) => {
  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onEnterSubmit();
    }
  };

  return (
    <Editable
      label={label}
      value={value}
      defaultValue={defaultValue}
      onConfirm={onConfirm}
      onUndo={onUndo}
      enterPressed={enterPressed}
      editComponent={
        <NumberFormat
          customInput={Input}
          value={editValue}
          onValueChange={({ floatValue }) => onChange(floatValue)}
          thousandSeparator={thousandSeparator}
          min='0'
          inputWrapperStyle={{ borderRadius: '5px 0 0 5px' }}
          wrapperStyle={{ flex: 1 }}
        />
      }
      highlight
    />
  );
};
