import React from 'react';
import styled from 'styled-components';

import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { SlideContainer, Typography } from '../../../../../Slide.styles';

import { Chart } from './Chart/Chart';
import { ChartContainer } from '../../components/ChartContainer';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';
import { svgStringToDataUrl } from '../../../../../services/svgStringToDataUrl';
import calendar from '../../assets/calendarModern';

export const LeftCol = styled('div')({
  width: '71%',
});

export const RightCol = styled('div')({
  width: '26%',
});

export const SourceText = styled('p')({
  position: 'absolute',
  bottom: '3%',
  right: '3%',
  fontSize: 14,
  color: '#000',
  fontWeight: 300,
});
const ChartPositioner = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
const Description = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 0.97561px solid #f2f2f2;
  border-radius: 4.87805px;
  height: 100%;
  letter-spacing: ${() => useUiConfigBodyLetterSpacing()};
`;

const ImageWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Image = styled('img')({
  width: '91%',
});

const Text = styled('p')({
  fontSize: 16,
  marginTop: 32,
  marginBottom: 51,
  fontWeight: 900,
});

export const ImportanceOfOpeningWeekendModern: React.FC = () => {
  const { isCanadaUser } = useUser();
  const secondary = useUiConfigColorsSecondary();
  const primary = useUiConfigColorsPrimary();

  const img = React.useMemo(() => svgStringToDataUrl(calendar, primary), [primary]);

  return (
    <SlideContainer>
      <LeftCol>
        <ChartContainer heading={'Odds of selling at or above list price'}>
          <ChartPositioner>
            {isCanadaUser ? (
              <ImageWrapper>
                <Text>
                  The longer homes last on the market, the lower the odds of selling for list price.
                </Text>
                <Image src={img} />
              </ImageWrapper>
            ) : (
              <Chart />
            )}
          </ChartPositioner>
        </ChartContainer>
      </LeftCol>

      <RightCol>
        <Description style={{ background: ColorOpacityApplier.hex(secondary, '.1') }}>
          <Typography
            variant={'h2'}
            style={{
              fontSize: 20,
              fontWeight: 900,
              color: useUiConfigColorsPrimary(),
              textTransform: 'uppercase',
              marginBottom: 13,
              lineHeight: 1.4,
              letterSpacing: useUiConfigHeaderLetterSpacing(),
              fontFamily: useUiConfigFontsHeader(),
            }}
          >
            Why is opening weekend important?
          </Typography>
          <Typography
            variant={'body1'}
            style={{ paddingBottom: 20, lineHeight: 1.5, fontSize: 16, color: '#444' }}
          >
            It’s worth remembering that the average time on the market varies from area to area.
            <br />
            <br />
            Opening weekend is your home’s first impression. Just like opening weekend is crucial
            for blockbuster movies, opening weekend sets the tone for your home sale. Reviews will
            be in by the end of the weekend, and it’s critical to pay attention to the feedback –
            and analyze the traffic.
            <br />
            <br />
            If you only receive drive-bys and online view, it’s indicative that your home is
            overpriced by 10% or higher (Low or infrequent showings can indicate that the home is
            overpriced by 5-10%).
            <br />
            <br />
            If you have showings but no offers, it can indicate a minor repair or update is needed.
            It could also indicate that the home is overpriced by 5%.
          </Typography>
        </Description>
      </RightCol>
      {!isCanadaUser && <SourceText>Source: Zillow Listing Data</SourceText>}
    </SlideContainer>
  );
};
