import styled from 'styled-components';
import { useSlides } from '../../../../../../../../../../providers/providers/SlidesProvider';
import { TPresentationSessionSlide, TSlideId } from '../../../../../../../../../../types';
import { TViewModes } from '../../Actions';
import { Bar } from './Bar';

type Props = {
  hash: string;
  maxValue: number;
  viewMode: TViewModes;
  showPagesWithNoViews: boolean;
  analytics: Partial<Record<TSlideId, TPresentationSessionSlide>>;
};

export function BarGraph({ hash, showPagesWithNoViews, viewMode, analytics, maxValue }: Props) {
  const { slidesList } = useSlides();
  // const slidesBigger = [...slidesList, ...slidesList, ...slidesList];

  return (
    <BarsContainer>
      {slidesList?.map((slide, i) => {
        const metric = viewMode === 'pageViews' ? 'views' : 'duration';
        const slideAnalytics = analytics?.[slide.id as TSlideId];

        if (showPagesWithNoViews && !slideAnalytics?.[metric]) return null;

        return (
          <Bar
            key={slide.id}
            value={slideAnalytics?.[metric] ?? 0}
            maxValue={maxValue}
            page={i + 1}
            slide={slide}
            analytics={slideAnalytics as TPresentationSessionSlide}
            hash={hash}
          />
        );
      })}
    </BarsContainer>
  );
}

const BarsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  grid-row-start: 1;
  grid-column-start: 1;
  padding-top: 35px;
  padding-bottom: 37px;
`;
