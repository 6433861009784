import React from 'react';
import styled from 'styled-components';

import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../providers/providers/UiConfigColorProvider';
import { BuyerInterest } from '../web/BuyerInterest.component';
import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { PotentialBuyersTable } from './PotentialBuyersTable/PotentialBuyersTable';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const SlideWrapper = styled('div')({
  width: '100%',
  height: 700,
  overflow: 'hidden',
});

export const TitleSection = styled('div')(({ theme }) => ({
  background: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
  borderRadius: 5,
  padding: 17,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: 90,
}));

export const Title = styled('h1')(({ theme }) => ({
  lineHeight: '16px',
  fontWeight: 900,
  fontSize: 26,
  textTransform: 'uppercase',
  color: useUiConfigColorsPrimary(),
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const SubTitle = styled('p')(({ theme }) => ({
  fontWeight: 300,
  fontSize: 16,
  color: '#000000',
}));

export const BuyerInterestPdf: React.FC = () => {
  const { category } = useCategory();
  const slide = useSlide();
  const leads = slide?.buyerInterestData?.data?.leads?.contacts?.details;

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <SlideWrapper>
          <TitleSection>
            <Title>I have buyers</Title>
            <SubTitle>
              Our office works with both buyers and sellers and keeps an up to date funnel of
              interest.
            </SubTitle>
          </TitleSection>
          <BuyerInterest isWeb={false} />
        </SlideWrapper>
        {leads?.length > 0 && <PotentialBuyersTable leads={leads} />}
      </Container>
    </PdfFrame>
  );
};
