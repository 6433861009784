import { MouseEvent, MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '../../../../../../components/Common/Autocomplete/Autocomplete';
import { AreaType, SearchedArea } from '../../../../../../types';
import { useAtom, useSetAtom } from 'jotai';
import { mapCoordinatesAtom } from '../../state/mapCoordinatesAtom';
import { areaDetailsAtom } from '../../state/areaDetailsAtom';
import { mapModeAtom } from '../../state/mapModeAtom';
import { useAreaSuggestions } from '../../hooks/useAreaSearch';
import { usePolygonDetails } from '../../hooks/usePolygonDetails';
import styled from 'styled-components';
import { searchAreaValueAtom } from '../../state/searchAreaValueAtom';
import { areaTypeAtom } from '../../state/areaTypeAtom';

const Wrapper = styled.div<{ isActive?: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  width: 49%;
`;

export const AreaSelect = () => {
  const [value, setValue] = useAtom(searchAreaValueAtom);
  const setMapCoordinates = useSetAtom(mapCoordinatesAtom);
  const [areaDetails, setAreaDetails] = useAtom(areaDetailsAtom);
  const [searchValue, setSearchValue] = useState('');
  const [mapMode, setMapMode] = useAtom(mapModeAtom);
  const { data: areas } = useAreaSuggestions(searchValue);
  const { data: polygonDetails } = usePolygonDetails(areaDetails?.polygonId, areaDetails?.type);
  const setAreaType = useSetAtom(areaTypeAtom);

  const getAreaInfo = (value: any) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (!value) {
      setValue(areaDetails?.label || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaDetails?.label]);

  const autocompleteAreas = useMemo(
    () =>
      areas?.map((area: SearchedArea) => ({
        ...area,
        label: `${area.type}: ${area.name}`,
        value: area.name,
      })) || [],
    [areas],
  );

  useEffect(() => {
    if (!polygonDetails) return;

    setMapCoordinates(JSON.stringify(polygonDetails));
  }, [areaDetails, polygonDetails, setMapCoordinates]);

  const onSuggestionSelected = async (area: SearchedArea) => {
    setAreaDetails(area);
    setMapMode('area');
    setSearchValue('');
  };

  const onSelectClick = () => {
    setValue('');
    setSearchValue('');

    if (mapMode === 'area') return;

    setMapMode('area');
    setAreaDetails(null);
    setMapCoordinates(null);
    setAreaType(AreaType.geographical);
  };

  return (
    <Wrapper isActive={mapMode === 'area'} onClick={onSelectClick}>
      <Autocomplete
        placeholder={'Search for an area'}
        value={value}
        onChange={val => setValue(val)}
        asyncOptionsProvider={getAreaInfo}
        onSuggestionSelected={onSuggestionSelected}
        suggestions={autocompleteAreas}
        containerStyles={{ minWidth: '200px' }}
      />
    </Wrapper>
  );
};
