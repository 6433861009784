import styled from 'styled-components';

export const ContentContainer = styled('div')({
  width: '100%',
  height: '100%',
  padding: 40,
  position: 'relative',
});

export const ContentWrapper = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 20,
  overflow: 'hidden',
});

export const Shape = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
});
