import mailSvg from '../../../assets/icons/mail.svg';
import expandSvg from '../../../assets/icons/expand.svg';
import expand_largeSvg from '../../../assets/icons/expand_large.svg';
import collapse_largeSvg from '../../../assets/icons/collapse_large.svg';
import check_filledSvg from '../../../assets/icons/check_filled.svg';
import restoreSvg from '../../../assets/icons/restore.svg';
import dashSvg from '../../../assets/icons/dash.svg';
import playbooksSvg from '../../../assets/icons/playbooks.svg';
import arrow_downSvg from '../../../assets/icons/arrow_down.svg';
import logoSvg from '../../../assets/icons/logo.svg';
import logo_newSvg from '../../../assets/icons/logo_new.svg';
import helpSvg from '../../../assets/icons/help.svg';
import presentation_librarySvg from '../../../assets/icons/presentation_library.svg';
import settingsSvg from '../../../assets/icons/settings.svg';
import settings_newSvg from '../../../assets/icons/settings_new.svg';
import tracking_resultsSvg from '../../../assets/icons/tracking_results.svg';
import offerSvg from '../../../assets/icons/offer.svg';
import presentSvg from '../../../assets/icons/present.svg';
import trophySvg from '../../../assets/icons/trophy.svg';
import propertySvg from '../../../assets/icons/property.svg';
import rebuildSvg from '../../../assets/icons/rebuild.svg';
import linkSvg from '../../../assets/icons/link.svg';
import pdfSvg from '../../../assets/icons/pdf.svg';
import removeSvg from '../../../assets/icons/remove.svg';
import event_bookedSvg from '../../../assets/icons/event_booked.svg';
import rowsSvg from '../../../assets/icons/rows.svg';
import columnsSvg from '../../../assets/icons/columns.svg';
import searchSvg from '../../../assets/icons/search.svg';
import backSvg from '../../../assets/icons/back.svg';
import closeSvg from '../../../assets/icons/close.svg';
import presentationSvg from '../../../assets/icons/presentation.svg';
import profileSvg from '../../../assets/icons/profile.svg';
import check_greenSvg from '../../../assets/icons/check_green.svg';
import lockedSvg from '../../../assets/icons/locked.svg';
import unlockedSvg from '../../../assets/icons/unlocked.svg';
import info_blueSvg from '../../../assets/icons/info_blue.svg';
import themeSvg from '../../../assets/icons/theme.svg';
import colorsSvg from '../../../assets/icons/colors.svg';
import colorboxSvg from '../../../assets/icons/colorbox.svg';
import fontSvg from '../../../assets/icons/font.svg';
import logosSvg from '../../../assets/icons/logos.svg';
import add_greenSvg from '../../../assets/icons/add_green.svg';
import addSvg from '../../../assets/icons/add.svg';
import close_filledSvg from '../../../assets/icons/close_filled.svg';
import info_placementSvg from '../../../assets/icons/info_placement.svg';
import edit_textSvg from '../../../assets/icons/edit_text.svg';
import info_whiteSvg from '../../../assets/icons/info_white.svg';
import dragSvg from '../../../assets/icons/drag.svg';
import left_arrow_blueSvg from '../../../assets/icons/left_arrow_blue.svg';
import documentSvg from '../../../assets/icons/document.svg';
import textSvg from '../../../assets/icons/text.svg';
import printerSvg from '../../../assets/icons/printer.svg';
import copy_linkSvg from '../../../assets/icons/copy_link.svg';
import video_cameraSvg from '../../../assets/icons/video_camera.svg';
import calendarSvg from '../../../assets/icons/calendar.svg';
import arrow_up_blackSvg from '../../../assets/icons/arrow_up_black.svg';
import arrow_down_blackSvg from '../../../assets/icons/arrow_down_black.svg';
import quoteSvg from '../../../assets/icons/quote.svg';
import targetSvg from '../../../assets/icons/target.svg';
import gridSvg from '../../../assets/icons/grid.svg';
import play_blueSvg from '../../../assets/icons/play_blue.svg';
import plus_greenSvg from '../../../assets/icons/plus_green.svg';
import slideSvg from '../../../assets/icons/slide.svg';
import avatarSvg from '../../../assets/icons/avatar.svg';
import carousel_arrowSvg from '../../../assets/icons/carousel_arrow.svg';
import back_primarySvg from '../../../assets/icons/back_primary.svg';
import forward_primarySvg from '../../../assets/icons/forward_primary.svg';
import defaultOrderSvg from '../../../assets/icons/defaultOrder.svg';
import rightTimeToSellSvg from '../../../assets/icons/rightTimeToSell.svg';
import topDollarValueSvg from '../../../assets/icons/topDollarValue.svg';
import agentIsTheRightFitSvg from '../../../assets/icons/agentIsTheRightFit.svg';
import realtorSvg from '../../../assets/icons/realtor.svg';
import ibuyerSvg from '../../../assets/icons/ibuyer.svg';
import interactive_slideSvg from '../../../assets/icons/interactive_slide.svg';
import graph_barsSvg from '../../../assets/icons/graph_bars.svg';
import smallArrowsSvg from '../../../assets/icons/smallArrows.svg';
import slidersSquareSvg from '../../../assets/icons/sliders_square.svg';
import mapWithPinSvg from '../../../assets/icons/map_with_pin.svg';
import mapWithPinGraySvg from '../../../assets/icons/map_with_pin_gray.svg';
import analyticsSvg from '../../../assets/icons/analytics.svg';
import clockSvg from '../../../assets/icons/clock.svg';
import eye_openSvg from '../../../assets/icons/eye_open.svg';
import eye_openWhiteSvg from '../../../assets/icons/eye_open_white.svg';
import uploadSvg from '../../../assets/icons/upload.svg';
import circle_checkmarkSvg from '../../../assets/icons/circle_checkmark.svg';
import circle_crossmarkSvg from '../../../assets/icons/circle_crossmark.svg';
import sellersSvg from '../../../assets/icons/sellers.svg';
import buyersSvg from '../../../assets/icons/buyers.svg';
import playSvg from '../../../assets/icons/play.svg';
import librarySvg from '../../../assets/icons/library.svg';
import arrow_rightSvg from '../../../assets/icons/arrow_right.svg';
import arrow_leftSvg from '../../../assets/icons/arrow_left.svg';
import chart_horizontalSvg from '../../../assets/icons/chart_horizontal.svg';
import sortSvg from '../../../assets/icons/sort.svg';
import square_pollSvg from '../../../assets/icons/square_poll.svg';
import chart_line_upSvg from '../../../assets/icons/chart_line_up.svg';
import house_flagSvg from '../../../assets/icons/house_flag.svg';
import clipboard_listSvg from '../../../assets/icons/clipboard_list.svg';
import flag_pennantSvg from '../../../assets/icons/flag_pennant.svg';
import starSvg from '../../../assets/icons/star.svg';
import thumbs_upSvg from '../../../assets/icons/thumbs_up.svg';
import trashSvg from '../../../assets/icons/trash.svg';
import undoSvg from '../../../assets/icons/undo.svg';
import caret_upSvg from '../../../assets/icons/caret_up.svg';
import close_sidebarSvg from '../../../assets/icons/close_sidebar.svg';
import circle_checkmark_btSvg from '../../../assets/icons/circle_checkmark_bt.svg';
import magnifying_glassSvg from '../../../assets/icons/magnifying_glass.svg';
import chevron_downSvg from '../../../assets/icons/chevron_down.svg';
import requiredSvg from '../../../assets/icons/required.svg';
import styled, { AnyStyledComponent, CSSObject, CSSProperties } from 'styled-components';

interface IconEl {
  style?: CSSProperties;
  fill?: string;
}

const withStyled = (icon: any) => {
  const getFillStyles = (props: IconEl): CSSObject => {
    let styles: CSSObject = {
      flexShrink: 0,
    };

    if (props.fill) {
      styles = {
        '& path': { fill: props.fill + ' !important' },
      };
    }

    return styles;
  };

  return styled(icon as AnyStyledComponent)<IconEl>(getFillStyles);
};

const mail = withStyled(mailSvg);
const expand = withStyled(expandSvg);
const expand_large = withStyled(expand_largeSvg);
const collapse_large = withStyled(collapse_largeSvg);
const check_filled = withStyled(check_filledSvg);
const restore = withStyled(restoreSvg);
const dash = withStyled(dashSvg);
const playbooks = withStyled(playbooksSvg);
const arrow_down = withStyled(arrow_downSvg);
const logo = withStyled(logoSvg);
const logo_new = withStyled(logo_newSvg);
const offer = withStyled(offerSvg);
const present = withStyled(presentSvg);
const trophy = withStyled(trophySvg);
const property = withStyled(propertySvg);
const help = withStyled(helpSvg);
const presentation_library = withStyled(presentation_librarySvg);
const settings = withStyled(settingsSvg);
const settings_new = withStyled(settings_newSvg);
const tracking_results = withStyled(tracking_resultsSvg);
const rebuild = withStyled(rebuildSvg);
const pdf = withStyled(pdfSvg);
const link = withStyled(linkSvg);
const remove = withStyled(removeSvg);
const event_booked = withStyled(event_bookedSvg);
const rows = withStyled(rowsSvg);
const columns = withStyled(columnsSvg);
const search = withStyled(searchSvg);
const back = withStyled(backSvg);
const close = withStyled(closeSvg);
const presentation = withStyled(presentationSvg);
const profile = withStyled(profileSvg);
const check_green = withStyled(check_greenSvg);
const locked = withStyled(lockedSvg);
const unlocked = withStyled(unlockedSvg);
const info_blue = withStyled(info_blueSvg);
const theme = withStyled(themeSvg);
const colors = withStyled(colorsSvg);
const colorbox = withStyled(colorboxSvg);
const font = withStyled(fontSvg);
const logos = withStyled(logosSvg);
const add_green = withStyled(add_greenSvg);
const add = withStyled(addSvg);
const close_filled = withStyled(close_filledSvg);
const info_placement = withStyled(info_placementSvg);
const edit_text = withStyled(edit_textSvg);
const info_white = withStyled(info_whiteSvg);
const drag = withStyled(dragSvg);
const left_arrow_blue = withStyled(left_arrow_blueSvg);
const document = withStyled(documentSvg);
const text = withStyled(textSvg);
const printer = withStyled(printerSvg);
const copy_link = withStyled(copy_linkSvg);
const video_camera = withStyled(video_cameraSvg);
const calendar = withStyled(calendarSvg);
const arrow_up_black = withStyled(arrow_up_blackSvg);
const arrow_down_black = withStyled(arrow_down_blackSvg);
const quote = withStyled(quoteSvg);
const target = withStyled(targetSvg);
const grid = withStyled(gridSvg);
const play_blue = withStyled(play_blueSvg);
const plus_green = withStyled(plus_greenSvg);
const slide = withStyled(slideSvg);
const avatar = withStyled(avatarSvg);
const carousel_arrow = withStyled(carousel_arrowSvg);
const back_primary = withStyled(back_primarySvg);
const forward_primary = withStyled(forward_primarySvg);
const defaultOrder = withStyled(defaultOrderSvg);
const agentIsTheRightFit = withStyled(agentIsTheRightFitSvg);
const topDollarValue = withStyled(topDollarValueSvg);
const rightTimeToSell = withStyled(rightTimeToSellSvg);
const realtor = withStyled(realtorSvg);
const ibuyer = withStyled(ibuyerSvg);
const interactive_slide = withStyled(interactive_slideSvg);
const graph_bars = withStyled(graph_barsSvg);
const smallArrows = withStyled(smallArrowsSvg);
const sliders_square = withStyled(slidersSquareSvg);
const mapWithPin = withStyled(mapWithPinSvg);
const mapWithPinGray = withStyled(mapWithPinGraySvg);
const analytics = withStyled(analyticsSvg);
const clock = withStyled(clockSvg);
const eye_open = withStyled(eye_openSvg);
const eye_open_white = withStyled(eye_openWhiteSvg);
const upload = withStyled(uploadSvg);
const circle_checkmark = withStyled(circle_checkmarkSvg);
const circle_crossmark = withStyled(circle_crossmarkSvg);
const sellers = withStyled(sellersSvg);
const buyers = withStyled(buyersSvg);
const play = withStyled(playSvg);
const library = withStyled(librarySvg);
const arrow_right = withStyled(arrow_rightSvg);
const arrow_left = withStyled(arrow_leftSvg);
const chart_horizontal = withStyled(chart_horizontalSvg);
const sort = withStyled(sortSvg);
const square_poll = withStyled(square_pollSvg);
const chart_line_up = withStyled(chart_line_upSvg);
const house_flag = withStyled(house_flagSvg);
const clipboard_list = withStyled(clipboard_listSvg);
const flag_pennant = withStyled(flag_pennantSvg);
const star = withStyled(starSvg);
const thumbs_up = withStyled(thumbs_upSvg);
const trash = withStyled(trashSvg);
const undo = withStyled(undoSvg);
const caret_up = withStyled(caret_upSvg);
const close_sidebar = withStyled(close_sidebarSvg);
const circle_checkmark_bt = withStyled(circle_checkmark_btSvg);
const bt_magnifying_glass = withStyled(magnifying_glassSvg);
const bt_chevron_down = withStyled(chevron_downSvg);
const bt_required = withStyled(requiredSvg);

export {
  mail,
  expand,
  check_filled,
  restore,
  dash,
  playbooks,
  arrow_down,
  logo,
  logo_new,
  help,
  presentation_library,
  settings,
  settings_new,
  tracking_results,
  offer,
  present,
  trophy,
  property,
  rebuild,
  link,
  pdf,
  remove,
  expand_large,
  collapse_large,
  event_booked,
  rows,
  columns,
  search,
  back,
  back_primary,
  forward_primary,
  close,
  presentation,
  profile,
  check_green,
  locked,
  info_blue,
  theme,
  unlocked,
  colors,
  colorbox,
  font,
  logos,
  add,
  add_green,
  close_filled,
  info_placement,
  edit_text,
  info_white,
  drag,
  left_arrow_blue,
  document,
  text,
  printer,
  copy_link,
  video_camera,
  calendar,
  arrow_up_black,
  arrow_down_black,
  quote,
  target,
  grid,
  play_blue,
  plus_green,
  slide,
  avatar,
  carousel_arrow,
  defaultOrder,
  rightTimeToSell,
  agentIsTheRightFit,
  topDollarValue,
  realtor,
  ibuyer,
  interactive_slide,
  graph_bars,
  smallArrows,
  sliders_square,
  mapWithPin,
  mapWithPinGray,
  analytics,
  clock,
  eye_open,
  eye_open_white,
  upload,
  circle_checkmark,
  circle_crossmark,
  sellers,
  buyers,
  play,
  library,
  arrow_right,
  arrow_left,
  chart_horizontal,
  sort,
  square_poll,
  chart_line_up,
  house_flag,
  clipboard_list,
  flag_pennant,
  star,
  thumbs_up,
  trash,
  undo,
  caret_up,
  close_sidebar,
  circle_checkmark_bt,
  bt_magnifying_glass,
  bt_chevron_down,
  bt_required,
};
