import { v4 as uuid } from 'uuid';

import { TSlide } from '../../../../../../types';
import { TwoImagesGamma } from './TwoImagesGamma';
import { TwoImagesGammaForm } from './TwoImagesGammaForm';
import { TDynamicSlideTypeMapping } from '../../../types';
import thumbnail from './thumbnail.png';
import { TwoImagesGammaPdf } from './TwoImagesGammaPdf';

const template = 'twoImagesLayoutGamma';

const config: TSlide = {
  id: uuid(),
  template,
  includeSlide: true,
  label: 'Custom Slide - Two Images',
  frame: true,
  data: {
    image: '',
    image2: '',
    text: '',
  },
};

const mappings: TDynamicSlideTypeMapping = {
  form: TwoImagesGammaForm,
  web: TwoImagesGamma,
  pdf: TwoImagesGammaPdf,
};

export type TTwoImagesLayoutGamma = typeof template;
export { config, mappings, thumbnail };
