import styled from 'styled-components';
import { RadioButtons, Typography, Icon } from '../../../../../../../../components/Common';

export { RadioButtons, Typography, Icon };

type WithOpen = {
  isOpen: boolean;
};

export const Container = styled('div')<WithOpen>(({ isOpen }) => ({
  display: isOpen ? 'block' : 'none',
  position: 'absolute',
  left: 0,
  top: '100%',
  backgroundColor: '#fff',
  borderRadius: 5,
  padding: 20,
  width: 676,
  border: '1px solid #DDDDDD',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.3)',
  zIndex: 10,
}));

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const RadioButtonsWrapper = styled('div')({
  width: 600,
});

export const Body = styled('div')({
  display: 'flex',
  marginTop: 17,
});

export const IconWrapper = styled('div')({
  width: 30,
  flexShrink: 0,
});
