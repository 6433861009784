import React from 'react';

import Nav from '../../../../../../components/Common/Navigation';
import { useModalActiveSection } from '../providers/ModalActiveSectionProvider';
import { TDynamicSection, TDynamicSectionId } from '../../../../../../types';

interface Props {
  order: TDynamicSectionId[];
  sections: Record<TDynamicSectionId, TDynamicSection>;
}
export const SectionsNavigation: React.FC<Props> = ({ order, sections }) => {
  const { modalActiveSection, setModalActiveSection } = useModalActiveSection();

  return (
    <Nav.Container>
      <Nav.Item
        text='All Slides'
        isActive={modalActiveSection === 'allSlides'}
        onClick={() => setModalActiveSection('allSlides')}
      />
      {order.map(sectionId => (
        <Nav.Item
          key={sectionId}
          text={sections[sectionId].label}
          isActive={modalActiveSection === sectionId}
          onClick={() => setModalActiveSection(sectionId)}
        />
      ))}
    </Nav.Container>
  );
};
