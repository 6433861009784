import React from 'react';

import { useSlide } from '../../../../providers/SlideProvider';
import { SlideTextBlock } from '../../components/SlideTextBlock.component';
import { Container, ImageWrapper, ImagePreview, ImagesWrapper } from './ThreeImagesGamma.styles';

interface Props {
  reverse?: boolean;
  horizontal?: boolean;
}

export const ThreeImagesGamma: React.FC<Props> = ({ reverse, horizontal }) => {
  const slide = useSlide();

  let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'column';
  if (horizontal) flexDirection = 'row';
  if (horizontal && reverse) flexDirection = 'row-reverse';
  if (!horizontal && reverse) flexDirection = 'column-reverse';

  return (
    <Container style={{ flexDirection }}>
      <SlideTextBlock
        isEditable={false}
        text={slide.data?.text}
        headline={slide.data?.headline}
        containerStyles={{
          height: horizontal ? '100%' : '49%',
          width: horizontal ? 'calc(65% - 60px)' : '100%',
        }}
      />
      <ImagesWrapper
        style={{
          flexDirection: horizontal ? 'column' : 'row',
          width: horizontal ? '35%' : '100%',
          marginRight: horizontal && reverse ? 60 : 0,
          marginLeft: horizontal && !reverse ? 60 : 0,
          marginBottom: horizontal || !reverse ? 0 : 40,
          marginTop: horizontal || reverse ? 0 : 40,
          height: horizontal ? '100%' : '45%',
        }}
      >
        <ImageWrapper>
          <ImagePreview src={slide.data?.image} />
        </ImageWrapper>
        <ImageWrapper>
          <ImagePreview src={slide.data?.image2} />
        </ImageWrapper>
        <ImageWrapper>
          <ImagePreview src={slide.data?.image3} />
        </ImageWrapper>
      </ImagesWrapper>
    </Container>
  );
};
