export enum FEATURES_KEYS {
  POOL_YES = 'POOL_YES',
  POOL_NO = 'POOL_NO',
  VIEW_YES = 'VIEW_YES',
  VIEW_NO = 'VIEW_NO',
  BATHROOM_MINUS_TWO = 'BATHROOM_MINUS_TWO',
  BATHROOM_MINUS_ONE = 'BATHROOM_MINUS_ONE',
  BATHROOM_SAME = 'BATHROOM_SAME',
  BATHROOM_ONE = 'BATHROOM_ONE',
  BATHROOM_TWO = 'BATHROOM_TWO',
  BATHROOM_THREE = 'BATHROOM_THREE',
  LEVELS_ONE = 'LEVELS_ONE',
  LEVELS_TWO = 'LEVELS_TWO',
  LEVELS_THREE = 'LEVELS_THREE',
  HOA_0 = 'HOA_0',
  HOA_1 = 'HOA_1',
  FIXER_UPPER_YES = 'FIXER_UPPER_YES',
  FIXER_UPPER_NO = 'FIXER_UPPER_NO',
  NEW_CONSTRUCTION_YES = 'NEW_CONSTRUCTION_YES',
  NEW_CONSTRUCTION_NO = 'NEW_CONSTRUCTION_NO',
  ADULT55_YES = 'ADULT55_YES',
  ADULT55_NO = 'ADULT55_NO',
  GREEN_ENERGY_YES = 'GREEN_ENERGY_YES',
  GREEN_ENERGY_NO = 'GREEN_ENERGY_NO',
  HORSE_YES = 'HORSE_YES',
  HORSE_NO = 'HORSE_NO',
  GOLF_YES = 'GOLF_YES',
  GOLF_NO = 'GOLF_NO',
  MASTER_MAIN_YES = 'MASTER_MAIN_YES',
  MASTER_MAIN_NO = 'MASTER_MAIN_NO',
  AIR_CONDITIONING_YES = 'AIR_CONDITIONING_YES',
  AIR_CONDITIONING_NO = 'AIR_CONDITIONING_NO',
  WATER_FRONT_YES = 'WATER_FRONT_YES',
  WATER_FRONT_NO = 'WATER_FRONT_NO',
  WATER_VIEW_YES = 'WATER_VIEW_YES',
  WATER_VIEW_NO = 'WATER_VIEW_NO',
  DECK_YES = 'DECK_YES',
  DECK_NO = 'DECK_NO',
  BASEMENT_NONE = 'BASEMENT_NONE',
  BASEMENT_YES = 'BASEMENT_YES',
  BASEMENT_FINISHED = 'BASEMENT_FINISHED',
  BASEMENT_PARTIAL = 'BASEMENT_PARTIAL',
  BASEMENT_UNFINISHED = 'BASEMENT_UNFINISHED',
  GARAGE_0 = 'GARAGE_0',
  GARAGE_1 = 'GARAGE_1',
  GARAGE_2 = 'GARAGE_2',
  GARAGE_3 = 'GARAGE_3',
  POOL_YES_OFF_MARKET = 'POOL_YES_OFF_MARKET',
  POOL_NO_OFF_MARKET = 'POOL_NO_OFF_MARKET',
  VIEW_YES_OFF_MARKET = 'VIEW_YES_OFF_MARKET',
  VIEW_NO_OFF_MARKET = 'VIEW_NO_OFF_MARKET',
  BATHROOM_MINUS_TWO_OFF_MARKET = 'BATHROOM_MINUS_TWO_OFF_MARKET',
  BATHROOM_MINUS_ONE_OFF_MARKET = 'BATHROOM_MINUS_ONE_OFF_MARKET',
  BATHROOM_SAME_OFF_MARKET = 'BATHROOM_SAME_OFF_MARKET',
  BATHROOM_ONE_OFF_MARKET = 'BATHROOM_ONE_OFF_MARKET',
  BATHROOM_TWO_OFF_MARKET = 'BATHROOM_TWO_OFF_MARKET',
  BATHROOM_THREE_OFF_MARKET = 'BATHROOM_THREE_OFF_MARKET',
  LEVELS_ONE_OFF_MARKET = 'LEVELS_ONE_OFF_MARKET',
  LEVELS_TWO_OFF_MARKET = 'LEVELS_TWO_OFF_MARKET',
  LEVELS_THREE_OFF_MARKET = 'LEVELS_THREE_OFF_MARKET',
  HOA_0_OFF_MARKET = 'HOA_0_OFF_MARKET',
  HOA_1_OFF_MARKET = 'HOA_1_OFF_MARKET',
  FIXER_UPPER_YES_OFF_MARKET = 'FIXER_UPPER_YES_OFF_MARKET',
  FIXER_UPPER_NO_OFF_MARKET = 'FIXER_UPPER_NO_OFF_MARKET',
  NEW_CONSTRUCTION_YES_OFF_MARKET = 'NEW_CONSTRUCTION_YES_OFF_MARKET',
  NEW_CONSTRUCTION_NO_OFF_MARKET = 'NEW_CONSTRUCTION_NO_OFF_MARKET',
  ADULT55_YES_OFF_MARKET = 'ADULT55_YES_OFF_MARKET',
  ADULT55_NO_OFF_MARKET = 'ADULT55_NO_OFF_MARKET',
  GREEN_ENERGY_YES_OFF_MARKET = 'GREEN_ENERGY_YES_OFF_MARKET',
  GREEN_ENERGY_NO_OFF_MARKET = 'GREEN_ENERGY_NO_OFF_MARKET',
  HORSE_YES_OFF_MARKET = 'HORSE_YES_OFF_MARKET',
  HORSE_NO_OFF_MARKET = 'HORSE_NO_OFF_MARKET',
  GOLF_YES_OFF_MARKET = 'GOLF_YES_OFF_MARKET',
  GOLF_NO_OFF_MARKET = 'GOLF_NO_OFF_MARKET',
  MASTER_MAIN_YES_OFF_MARKET = 'MASTER_MAIN_YES_OFF_MARKET',
  MASTER_MAIN_NO_OFF_MARKET = 'MASTER_MAIN_NO_OFF_MARKET',
  AIR_CONDITIONING_YES_OFF_MARKET = 'AIR_CONDITIONING_YES_OFF_MARKET',
  AIR_CONDITIONING_NO_OFF_MARKET = 'AIR_CONDITIONING_NO_OFF_MARKET',
  WATER_FRONT_YES_OFF_MARKET = 'WATER_FRONT_YES_OFF_MARKET',
  WATER_FRONT_NO_OFF_MARKET = 'WATER_FRONT_NO_OFF_MARKET',
  WATER_VIEW_YES_OFF_MARKET = 'WATER_VIEW_YES_OFF_MARKET',
  WATER_VIEW_NO_OFF_MARKET = 'WATER_VIEW_NO_OFF_MARKET',
  DECK_YES_OFF_MARKET = 'DECK_YES_OFF_MARKET',
  DECK_NO_OFF_MARKET = 'DECK_NO_OFF_MARKET',
  BASEMENT_NONE_OFF_MARKET = 'BASEMENT_NONE_OFF_MARKET',
  BASEMENT_YES_OFF_MARKET = 'BASEMENT_YES_OFF_MARKET',
  BASEMENT_FINISHED_OFF_MARKET = 'BASEMENT_FINISHED_OFF_MARKET',
  BASEMENT_PARTIAL_OFF_MARKET = 'BASEMENT_PARTIAL_OFF_MARKET',
  BASEMENT_UNFINISHED_OFF_MARKET = 'BASEMENT_UNFINISHED_OFF_MARKET',
  GARAGE_0_OFF_MARKET = 'GARAGE_0_OFF_MARKET',
  GARAGE_1_OFF_MARKET = 'GARAGE_1_OFF_MARKET',
  GARAGE_2_OFF_MARKET = 'GARAGE_2_OFF_MARKET',
  GARAGE_3_OFF_MARKET = 'GARAGE_3_OFF_MARKET',
}

export enum OFF_MARKET_FEATURES_KEYS {}
