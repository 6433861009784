export const getYouTubeID = (url: string) => {
  const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
};

export const isYoutubeVideo = (video_link: string) =>
  /^(https?:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/.test(video_link);

export const isLoomVideo = (video_link: string) =>
  /^(https?:\/\/)?((www\.)?loom\.com)\/.+$/.test(video_link);

export const getEmbedUrl = (video_link?: string) => {
  if (!video_link) return null;
  const isYt = isYoutubeVideo(video_link);
  const isLoom = isLoomVideo(video_link);
  const id = isYt ? getYouTubeID(video_link) : isLoom ? video_link.split('/').reverse()[0] : null;
  if (!id) return '';
  const host = isYt ? 'youtube' : 'loom';
  return `https://www.${host}.com/embed/${id}`;
};
