import styled from 'styled-components';

import { Button as ButtonEl } from '../V2/Button/Button';
import { ButtonVariants } from './Button/types';

type ButtonConfig = {
  label: string;
  value?: string;
  isActive?: boolean;
  onClick?: () => void;
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  disabled?: boolean;
  style?: React.CSSProperties;
};

type Props = {
  buttonsConfigs: ButtonConfig[];
  label?: string;
  disabled?: boolean;
  groupWidth?: string;
  activeVariant?: ButtonVariants;
};

export function ButtonsGroup({
  buttonsConfigs,
  label,
  disabled = false,
  groupWidth = '100%',
  activeVariant = 'primary',
}: Props) {
  return (
    <MainContainer>
      {label ? <GroupLabel>{label}</GroupLabel> : null}
      <GroupContainer width={groupWidth}>
        {buttonsConfigs?.map(config => (
          <Button
            key={config.label}
            variant={config.isActive ? activeVariant : 'secondary'}
            disabled={disabled || config.disabled}
            onClick={config.onClick}
            prefixIcon={config?.prefixIcon ?? null}
            suffixIcon={config?.suffixIcon ?? null}
            value={config?.value ?? config.label}
            style={config.style}
          >
            {config.label}
          </Button>
        ))}
      </GroupContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupLabel = styled.div`
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 13px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const GroupContainer = styled.div<{ width: string }>`
  display: flex;
  width: ${props => props.width};
  flex: 1 1 0;
`;

const Button = styled(ButtonEl)`
  flex: 1;
  min-width: max-content;
  &:not(:first-of-type) {
    border-radius: 0;
    border-left: none;
  }

  &:first-of-type {
    border-radius: 5px 0 0 5px;
  }

  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }
`;
