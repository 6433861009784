import React from 'react';
import parse from 'html-react-parser';

import {
  TitlePdf,
  FloatWrapper,
  ImageContainer,
  Image,
  TextContainer,
  ContentWrapper,
} from './AboutMePdf.styles';
import { ScaleContent } from '../../ScaleContent';
import { CommonFrameProps } from '../../../../../../../../types';
import { useUser } from '../../../../../../providers/UserProvider';
import { useSlide } from '../../../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';

export const AboutMePdf: React.FC<CommonFrameProps> = () => {
  const { bio } = useUser();
  const slide = useSlide();
  const { category } = useCategory();

  if (!slide) return null;

  const { text, displayImage, image } = slide.data!;
  const slideText = text ? text : bio;

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <ScaleContent predefinedHeight={980} predefinedWidth={900}>
        <ContentWrapper>
          <TitlePdf>About Me</TitlePdf>
          {displayImage && (
            <FloatWrapper>
              <ImageContainer>
                <Image style={{ backgroundImage: `url('${image}')` }} />
              </ImageContainer>
            </FloatWrapper>
          )}
          <TextContainer>{parse(slideText)}</TextContainer>
        </ContentWrapper>
      </ScaleContent>
    </PdfFrame>
  );
};
