import styled from 'styled-components';

type WithGreenBorder = {
  isGreenBorder?: boolean;
};

type WithChecked = {
  isChecked: boolean;
};

export const LegendContainer = styled('div')({
  display: 'flex',
});

export const LegendItem = styled('div')<WithGreenBorder>(({ theme, isGreenBorder }) => ({
  paddingBottom: 2,
  borderBottom: `3px solid ${
    isGreenBorder ? theme.colors.v2.status.success : theme.colors.primary.main
  }`,
  display: 'flex',
  alignItems: 'center',
  '&:not(:last-of-type)': {
    marginRight: 18,
  },
}));

export const LegendText = styled('p')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: theme.colors.gray[400],
}));

export const CheckboxContainer = styled('label')({
  display: 'flex',
  alignItems: 'center',
  marginRight: 5,
});

export const CheckboxInput = styled('input')({
  opacity: 0,
  height: 0,
  width: 0,
});

export const CheckboxControl = styled('div')<WithChecked>(({ isChecked, theme }) => ({
  height: 12,
  width: 12,
  backgroundColor: isChecked ? theme.colors.primary.main : '#fff',
  border: `1px solid ${theme.colors.primary.main}`,
  position: 'relative',
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',
  '&:after': {
    position: 'absolute',
    content: '""',
    width: 4,
    height: 8,
    top: '42%',
    left: '50%',
    transform: 'translate(-50%, -50%)  rotate(45deg)',
    borderStyle: 'solid',
    borderColor: isChecked ? '#fff' : '#F0F0F0',
    borderWidth: '0 2px 2px 0',
  },
}));
