import styled from 'styled-components';

export const PercentagesContainer = styled('div')({
  width: '100%',
  marginBottom: 25,
});

export const PercentagesTitle = styled('h6')(({ theme }) => ({
  color: theme.colors.gray[400],
  fontWeight: 700,
  fontSize: 14,
  marginBottom: 8,
}));
