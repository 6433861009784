import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomizationSettingsApi } from '../../pages/PresentationSettings/api/CustomizationSettingsApi';
import { useCallback } from 'react';

export function useRawCustomizations(hash?: string) {
  return useQuery(['customization', hash], () => CustomizationSettingsApi.fetch(hash || ''), {
    enabled: Boolean(hash),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

export function useInvalidateCustomizations() {
  const queryClient = useQueryClient();

  return useCallback(
    (hash?: string) => {
      queryClient.invalidateQueries(['customization', hash]);
    },
    [queryClient],
  );
}
