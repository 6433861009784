import styled from 'styled-components';

import { Typography } from '../Common';
import {
  useUiConfigFontsBody,
  useUiConfigHeaderLetterSpacing,
} from '../../providers/providers/UiConfigFontProvider';
import { useUiConfigColorsPrimary } from '../../providers/providers/UiConfigColorProvider';

export { Typography };

interface WithProps {
  spacing?: number;
  isactive?: 0 | 1;
  flex?: number;
}

export const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const SlideContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '3% 3%',
  backgroundColor: '#fff',
  justifyContent: 'space-between',
  flexShrink: 0,
  position: 'relative',
  zIndex: 5,
  height: '100%',
  width: '100%',
}));

export const SlideSectionBox = styled('div')<WithProps>(({ theme, isactive, flex, spacing }) => ({
  backgroundColor: isactive ? useUiConfigColorsPrimary() : theme.colors.gray[100],
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  flex: flex ? flex : 1,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  '&:not(:last-child)': {
    marginBottom: spacing ? spacing * 5 : '3.5%',
  },
  '& > *': {
    color: `${isactive ? '#fff' : theme.colors.gray[400]}`,
  },
}));

export const SlideContentContainer = styled.div`
  background: white;
  position: relative;
  flex: 1 0 86%;
  overflow: hidden;
  height: 100%;
`;

interface T {
  platform: 'pdf' | 'web';
}
export const Container = styled('div')<T>(({ platform }) => ({
  textAlign: 'start',
  fontFamily: useUiConfigFontsBody(),
  width: platform === 'web' ? 1600 : 1000,
  height: platform === 'web' ? 900 : 1294,
}));

export const Frameless = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;
