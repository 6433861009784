interface IPath {
  lat: number;
  lng: number;
}
export type TNumberOfBuyersLookingForHomes = 'very_low' | 'low' | 'medium' | 'high' | 'very_high';

interface IPolygon {
  numberOfBuyersLookingForHomes: TNumberOfBuyersLookingForHomes;
  paths: IPath[];
}

const polygons: IPolygon[] = [
  {
    numberOfBuyersLookingForHomes: 'very_low',
    paths: [
      {
        lat: 44.818882,
        lng: 20.394971,
      },
      { lat: 44.799619, lng: 20.427214 },
      { lat: 44.821316, lng: 20.440223 },
      {
        lat: 44.818882,
        lng: 20.394971,
      },
    ],
  },
  {
    numberOfBuyersLookingForHomes: 'low',
    paths: [
      {
        lat: 44.798882,
        lng: 20.374971,
      },
      { lat: 44.779619, lng: 20.407214 },
      { lat: 44.801316, lng: 20.420223 },
      {
        lat: 44.798882,
        lng: 20.374971,
      },
    ],
  },
  {
    numberOfBuyersLookingForHomes: 'medium',
    paths: [
      {
        lat: 44.778882,
        lng: 20.354971,
      },
      { lat: 44.759619, lng: 20.387214 },
      { lat: 44.781316, lng: 20.400223 },
      {
        lat: 44.778882,
        lng: 20.354971,
      },
    ],
  },
  {
    numberOfBuyersLookingForHomes: 'high',
    paths: [
      {
        lat: 44.758882,
        lng: 20.334971,
      },
      { lat: 44.739619, lng: 20.367214 },
      { lat: 44.761316, lng: 20.380223 },
      {
        lat: 44.758882,
        lng: 20.334971,
      },
    ],
  },
  {
    numberOfBuyersLookingForHomes: 'very_high',
    paths: [
      {
        lat: 44.738882,
        lng: 20.314971,
      },
      { lat: 44.719619, lng: 20.347214 },
      { lat: 44.741316, lng: 20.360223 },
      {
        lat: 44.738882,
        lng: 20.314971,
      },
    ],
  },
];

const center = {
  lat: 44.812153,
  lng: 20.414443,
};

const activeComparables = [
  { lat: 44.802153, lng: 20.414443 },
  { lat: 44.79, lng: 20.42 },
  { lat: 44.82, lng: 20.43 },
];
const activeNonComparables = [
  { lat: 44.832153, lng: 20.404443 },
  { lat: 44.79, lng: 20.41 },
  { lat: 44.82, lng: 20.42 },
];

export const dummyBuyerDemandsResponse = {
  polygons,
  center,
  activeComparables,
  activeNonComparables,
};
