import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../../../providers/providers/UiConfigFontProvider';

interface WithPDF {
  isPdf?: boolean;
}

export const ImageContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const SquaresBackground = styled('div')({
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});

export const FrameImageWrapper = styled('div')<WithPDF>(({ isPdf }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: isPdf ? '100%' : '99%',
  transform: 'translate(-50%, -50%)',
}));

export const Image = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover',
});

export const PropertyImageWrapper = styled('div')<WithPDF>(({ isPdf }) => ({
  width: '92%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
  height: '80%',
  padding: 12.5,
  fontSize: 32,
  color: useUiConfigColorsPrimary(),
  textAlign: 'center',
  verticalAlign: 'center',
  fontWeight: 700,
  lineHeight: 1.4,
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));
