import React from 'react';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { useSlide } from '../../../providers/SlideProvider';
import PdfViewer from '../components/UploadFileModal/PdfViewer';
import { PdfViewerWrapper, Container } from './PdfPresentation.styles';

export const PdfPresentation: React.FC = () => {
  const slide = useSlide();

  if (!slide) return null;

  const webPdf = slide.data?.webPdf;
  const pdf = slide.data?.pdf;

  return (
    <Container>
      <PdfViewerWrapper>
        <PdfViewer pdfUrl={webPdf ? webPdf : pdf} containerHeight={900} />
      </PdfViewerWrapper>
    </Container>
  );
};
