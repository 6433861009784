import { FEEDBACK_RESPONSE, MORE_TIME_DESCRIPTIONS } from '../types';

export const getAnalyticsFeedbackText = (
  feedbackResponse: FEEDBACK_RESPONSE,
  moreInfoResponse: keyof typeof MORE_TIME_DESCRIPTIONS,
) => {
  if (feedbackResponse === FEEDBACK_RESPONSE.LETS_GET_STARTED) return feedbackResponse;

  if (moreInfoResponse) return MORE_TIME_DESCRIPTIONS?.[moreInfoResponse];

  if (feedbackResponse === FEEDBACK_RESPONSE.NEED_MORE_TIME && !moreInfoResponse)
    return 'Need more time (no reason selected)';

  return '';
};
