import IntercomLib from 'react-intercom';
import { useUser } from '../../components/Slide/providers/UserProvider';
import boldTrail from '../../config/themes/bold-trail/colors';
import corePresent from '../../config/themes/core-present/colors';
import { FEATURES, useHasFeatureFlag } from '../../hooks/useFeatureFlags';

export function Intercom() {
  const user = useUser();
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const colors = hasBtTheme ? boldTrail : corePresent;

  const userForIntercom = {
    user_id: user?.kvcoreUserId ?? user?.userId ?? '',
    email: user?.email,
    name: user?.name,
    Product: 'dashCMA',
    dashCMA: 'true',
    alignment: 'right',
    action_color: colors.v2.primary,
    background_color: colors.v2.primary,
  };

  if (user.shouldHideIntercomButton) {
    return null;
  }

  return <IntercomLib appID='f1lcarew' {...userForIntercom} />;
}
