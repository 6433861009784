import React from 'react';
import styled from 'styled-components';

import { getPropertyId } from '../../../../../../../../../../../features/report/services/getPropertyId';
import { Checkbox } from '../../../../../../../../../../../features/report/components/Checkbox';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  margin: 10px 0;
  border-top: 1px solid #e6e6e6;
`;

const Title = styled.div`
  font-size: 18px;
  color: #111;
  font-size: bold;
  margin-bottom: 5px;
`;

interface Props {
  id: string;
  properties: any;
  selected: Record<string, any>;
  onChange: (id: string, value: any) => void;
}

export const DuplicatesList: React.FC<Props> = ({ id, properties, onChange, selected }) => {
  const getCompositeKey = (property: any) => {
    return `${property?.mlsId}:composite:${getPropertyId(property)}`;
  };

  return (
    <Container>
      <Title>{id}</Title>

      {properties.map((property: any) => (
        <Checkbox
          key={getCompositeKey(property)}
          label={`${property?.address?.deliveryLine}, ${property?.address?.city}, ${property?.address?.state} ${property?.address?.zip}  (${property.status})`}
          name='property-selector'
          value={selected[getPropertyId(property)]?.uuid === getCompositeKey(property)}
          onChange={value =>
            onChange(
              getPropertyId(property),
              value ? { uuid: getCompositeKey(property), property } : '',
            )
          }
        />
      ))}
    </Container>
  );
};
