import * as alpha from './alpha';
import * as beta from './beta';
import * as gamma from './gamma';
import * as delta from './delta';
import * as epsilon from './epsilon';
import * as zeta from './zeta';

import { TDynamicSlideConfigs } from '../../types';
import { TDynamicSlideOneImageTemplate } from '../../../../../types';

export const oneImageTemplateConfigs: Record<TDynamicSlideOneImageTemplate, TDynamicSlideConfigs> =
  {
    oneImageLayoutAlpha: alpha,
    oneImageLayoutBeta: beta,
    oneImageLayoutGamma: gamma,
    oneImageLayoutDelta: delta,
    oneImageLayoutEpsilon: epsilon,
    oneImageLayoutZeta: zeta,
  };
