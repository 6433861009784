import React, { createContext, useContext, useState } from 'react';

import type { TSlide, TSlideId, TPresentationMode, TSlideData } from '../../types';

type TSetSlidesFunc = (
  slidesMap?: Partial<Record<TSlideId, TSlide>>,
) => Partial<Record<TSlideId, TSlide>> | undefined;

type TSlidesContext = {
  slides: Partial<Record<TSlideId, TSlide>> | undefined;
  getSlides: (slideIds: TSlideId[]) => TSlide[];
  getSlide: (slideId: TSlideId) => TSlide | null;
  slidesList: TSlide[];
  setSlides: (slidesMap?: Partial<Record<TSlideId, TSlide>> | TSetSlidesFunc) => void;
  setSlide: (slideId: TSlideId | string, slide: TSlide) => void | undefined;
  setSlideData: (slideId: TSlideId, data: TSlideData) => void | undefined;
};
const SlidesContext = createContext<TSlidesContext | undefined>(undefined);

export function useSlides() {
  const context = useContext(SlidesContext);

  if (!context) {
    throw new Error('useSlides must be used within a SlidesContext');
  }

  return context;
}
interface Props {
  presentationMode?: TPresentationMode;
}

export const SlidesProvider: React.FC<Props> = function SlidesProvider({ children }) {
  const [slides, setSlides] = useState<Partial<Record<TSlideId, TSlide>> | undefined>(undefined);

  const slidesList = React.useMemo(
    () => (!slides ? [] : Object.values(slides)),
    [slides],
  ) as TSlide[];

  const getSlides = React.useCallback(
    function getSlides(slideIds: TSlideId[]): TSlide[] {
      if (!Array.isArray(slideIds)) return [];

      return slideIds.reduce((collection: TSlide[], sId: TSlideId) => {
        if (!slides?.[sId]) return collection;

        return [...collection, slides[sId] as TSlide];
      }, []);
    },
    [slides],
  );

  const getSlide = React.useCallback(
    function getSlide(slideId: TSlideId) {
      return slides?.[slideId] ?? null;
    },
    [slides],
  );

  const setSlide = React.useCallback((id: TSlideId | string, slide: TSlide) => {
    if (!id) return;

    setSlides(prev => ({
      ...prev,
      [id]: { ...slide },
    }));
  }, []);

  const setSlideData = React.useCallback((id: TSlideId, data?: TSlideData) => {
    if (!id) return;

    setSlides(prev => {
      if (!prev?.[id]) return;

      return {
        ...prev,
        [id]: { ...prev[id], data: { ...(prev[id]?.data ?? {}), ...(data ?? {}) } },
      };
    });
  }, []);

  const value = React.useMemo(
    function memoizedOrder() {
      return {
        slides,
        slidesList,
        setSlides,
        getSlides,
        getSlide,
        setSlide,
        setSlideData,
      };
    },
    [slides, slidesList, getSlides, getSlide, setSlide, setSlideData],
  );

  return <SlidesContext.Provider value={value}>{children}</SlidesContext.Provider>;
};
