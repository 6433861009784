export const fetchProperties = async (ids, subjectId, fetchFn) => {
  const errors = {};
  const properties = [];
  const multipleResults = {};

  for (const id of ids) {
    const mlsProperty = await fetchFn(id, subjectId).catch(err => {
      errors[id] = err.message;
    });

    if (!mlsProperty) {
      errors[id] = 404;
      continue;
    }

    if (mlsProperty.length > 1) {
      multipleResults[id] = mlsProperty;
      continue;
    }

    properties.push(mlsProperty[0]);
  }

  return {
    properties,
    multipleResults,
    hasMultipleResults: !!Object.keys(multipleResults).length,
    errors,
    hasErrors: !!Object.keys(errors).length,
  };
};
