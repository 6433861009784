export const activeShelfLifeData = {
  heading: 'Active Shelf Life',
  value: 'DAYS',
  subtitle: 'Current Active Shelf Life',
  description: [
    {
      title: 'WHAT IS ACTIVE SHELF LIFE?',
      text: ' Active shelf life highlights the average number of days active and pending comparable properties are on the market each month.',
    },
    {
      title: 'How is active shelf life used in a price analysis?',
      text: 'Understanding how long similar homes are sitting on the market can help set expectations on how long it might take to find a buyer – and when to consider a price reduction.',
    },
  ],
};
