import { useEffect } from 'react';
import { TProperty } from '../../../../types';

export function useAdaptMapBoundariesToIncludeProperties(
  mapInstance: any,
  properties: TProperty[],
) {
  const w = window as any;

  useEffect(() => {
    if (!mapInstance) return;
    const bounds = new w.google.maps.LatLngBounds();

    properties.forEach(property => {
      bounds.extend({ lat: property.coordinates.latitude, lng: property.coordinates.longitude });
    });

    mapInstance?.fitBounds(bounds);

    if (!properties.length) {
      const defaultCenter = {
        lat: 34.0522,
        lng: -118.2437,
      };
      mapInstance?.setCenter(defaultCenter);
    }
  }, [properties, mapInstance, w.google.maps.LatLngBounds]);
}
