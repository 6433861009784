import { useAtomValue } from 'jotai';
import { customEstimatesAtom } from '../../state/customEstimatesAtom';
import styled from 'styled-components';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';

export const CustomEstimatesList = () => {
  const estimates = useAtomValue(customEstimatesAtom);

  return (
    <EstimatesContainer>
      {estimates.map((estimate, i) => (
        <Estimate hasBottomBorder={i < estimates.length - 1} key={estimate.id}>
          <span>{estimate.name}</span>
          <span>{MoneyFormatter.format(estimate.value)}</span>
        </Estimate>
      ))}
    </EstimatesContainer>
  );
};

const EstimatesContainer = styled.div`
  margin-top: 30px;
`;

const Estimate = styled.div<{ hasBottomBorder?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.primary.textLight};
  font-size: 16px;
  border-bottom: ${({ hasBottomBorder }) => (hasBottomBorder ? '1px solid #eee' : 'none')};
`;
