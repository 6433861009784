import styled from 'styled-components';

import { Typography as Text } from '../../../../Common';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigFontsHeader } from '../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')(({ theme }) => ({
  width: '33.333%',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: 120,
  paddingBottom: theme.spacing(2),
  cursor: 'pointer',
}));

export const Typography = styled(Text)(() => ({
  color: '#000',
  fontWeight: 400,
  fontFamily: useUiConfigFontsHeader() ?? 'Arial',
}));

export const StepsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 4,
});

interface WithActive {
  active: boolean;
  completed: boolean;
}

export const Step = styled('div')<WithActive>(({ theme, active, completed }) => ({
  height: 4,
  width: 56,
  backgroundColor:
    !active && !completed
      ? theme.colors.gray[200]
      : completed
      ? theme.colors.gray[300]
      : useUiConfigColorsPrimary(),
  '&:not(:last-child)': {
    marginRight: theme.spacing(0.5),
  },
}));
