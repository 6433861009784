import React from 'react';
import styled from 'styled-components';

import { ReportInfo } from './ReportInfo';
import { useUser } from '../../../../providers/UserProvider';
import { useSlide } from '../../../../providers/SlideProvider';
import { TrackingEvent } from '../../../../../../services/TrackingEvent';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { Scale } from '../../../../../../features/report/scale-overview/scale/web/Scale';
import { CriteriaReviewBox } from '../../../whatsMyHomeWorth/comparativeMarketAnalysis/components/CriteriaReviewBox/CriteriaReviewBox';
import { PotentialBuyers } from '../../../marketingAndBuyerActivitySection/BuyerInterest/web/PotentialBuyers/PotentialBuyers.component';
import { PricePerSqftGauge } from '../../../../../../features/report/market-assessment/components/PricePerSqftGauge';
import { PricingRateOfChangeGauge } from '../../../../../../features/report/market-assessment/components/PricingRateOfChangeGauge';
import { ActiveShelfLifeGauge } from '../../../../../../features/report/market-assessment/components/ActiveShelfLifeGauge';

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  padding: 40,
  gap: 30,
});

const Row = styled('div')({ display: 'flex' });

const Column = styled('div')({ display: 'flex', flexDirection: 'column' });

const BoxWrapper = styled(Column)({
  flex: 1,
});

const boxIdToLabelMap: any = {
  favor: 'Average List Price',
  competition: 'Fresh/Stale',
  sellingPace: 'Selling Pace',
  pricePerSqft: 'Price per SQFT',
  rate: 'Pricing Rate of Change',
  shelfLife: 'Current Active Shelf Life',
};

export const OneSheeter: React.FC = () => {
  const { category } = useCategory();
  const { isCanadaUser } = useUser()!;

  const slide = useSlide();

  const trackClick = (overview: string) => {
    TrackingEvent.click(
      `Clicked "${boxIdToLabelMap[overview] ?? 'some'}" box on one sheeter slide!`,
    );
  };

  const leads = slide?.buyerInterestData?.data?.leads?.contacts?.details ?? [];

  return (
    <Container>
      <Column style={{ width: '25%' }}>
        <BoxWrapper>
          <ReportInfo />
          <CriteriaReviewBox
            gridStyles={{
              backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '0.05'),
            }}
            headingStyles={{ color: '#444' }}
          />
        </BoxWrapper>
      </Column>
      <Column
        style={{
          width: leads.length ? '55%' : '75%',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            marginBottom: 20,
            transform: 'scale(0.7)',
            transformOrigin: '0 0',
            maxHeight: 420,
          }}
        >
          <Scale
            containerStyle={{
              width: leads.length ? 1150 : 1585,
              height: 600,
              border: '1px solid #ddd',
              borderRadius: 5,
            }}
            withPropertyModal
            defaultMarkersOnly
            modalId='oneSheeter'
          />
        </div>
        <Row style={{ gap: 15 }}>
          {!isCanadaUser && (
            <PricePerSqftGauge
              trackClick={() => trackClick('pricePerSqft')}
              category={category}
              oneSheeterSlide
            />
          )}
          <PricingRateOfChangeGauge
            trackClick={() => trackClick('rate')}
            category={category}
            oneSheeterSlide
          />
          <ActiveShelfLifeGauge
            trackClick={() => trackClick('shelfLife')}
            category={category}
            oneSheeterSlide
          />
        </Row>
      </Column>
      {!!leads.length && (
        <Column style={{ width: '20%' }}>
          <PotentialBuyers data={leads.slice(0, 3)} />
        </Column>
      )}
    </Container>
  );
};
