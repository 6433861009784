import Scale from './ReportFacade/components/Scale/Scale';
import { useSubjectInfo } from './ReportFacade/data/useSubjectInfo';
import { useAllProperties } from './ReportFacade/data/useAllProperties';
import { useReportUpdatedAt } from './ReportFacade/data/useReportUpdatedAt';
import { ComparativeMarketAnalysis } from './ReportFacade/components/ComparativeMarketAnalysis/ComparativeMarketAnalysis';
import { useAveragePricePerSquareFeet } from './ReportFacade/data/useAveragePricePerSquareFeet';
import { usePricingRateOfChange } from './ReportFacade/data/usePricingRateOfChange';
import { useSellingPace } from './ReportFacade/data/useSellingPace';

const dataSelectors = {
  report: {
    useReportUpdatedAt,
    useAveragePricePerSquareFeet,
    usePricingRateOfChange,
    useSellingPace,
  },
  subject: {
    useSubjectInfo,
  },
  properties: {
    useAllProperties,
  },
};

export const Report = {
  data: dataSelectors,
  Components: {
    Scale,
    ComparativeMarketAnalysis,
  },
};
