import { cloneDeep } from 'lodash';

import { TPresentationType, TSlide, TSlideId } from '../../types';
import { PresentationConfigsFactory } from '../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';

export class SlidesValidator {
  private presentationType: TPresentationType;

  constructor(type: TPresentationType) {
    this.presentationType = type;
  }

  getPartialConfig(partialSlidesConfig?: Record<TSlideId, TSlide>) {
    const slidesConfig = {} as Partial<Record<TSlideId, TSlide>>;

    if (!partialSlidesConfig) return slidesConfig;

    const referenceSlidesConfig = this.getSlides();

    (Object.keys(partialSlidesConfig) as TSlideId[]).forEach(slideId => {
      if (!referenceSlidesConfig[slideId] && !partialSlidesConfig[slideId]?.template) return;

      slidesConfig[slideId] = cloneDeep(partialSlidesConfig[slideId]);
    });

    return slidesConfig;
  }

  getFullConfig(slidesConfig: Partial<Record<TSlideId, TSlide>>) {
    const referenceSlidesMap = this.getSlides();

    (Object.keys(slidesConfig) as TSlideId[]).forEach(slideId => {
      if (!slidesConfig?.[slideId] && !slidesConfig[slideId]?.template) return;

      referenceSlidesMap[slideId] = cloneDeep(slidesConfig[slideId]);
    });

    return referenceSlidesMap;
  }

  private getSlides() {
    return PresentationConfigsFactory.create(this.presentationType).getSlides();
  }
}
