import React, { useEffect, useMemo, useState } from 'react';

import { usePresentationType } from '../../../../../../../../../providers/providers/PresentationTypeProvider';
import { TPresentationType } from '../../../../../../../../../types';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';
import { ButtonsGroup } from '../../../../../../../../../components/Common/V2/ButtonsGroup';

type TTypePreset = {
  label: string;
  value: TPresentationType;
};

const typePresets: TTypePreset[] = [
  {
    label: 'Win Listing',
    value: 'winTheListing',
  },
  {
    label: 'Win Representation',
    value: 'winTheRepresentation',
  },
  {
    label: 'Win Offer',
    value: 'winTheOffer',
  },
  {
    label: 'General Presentation',
    value: 'presentInfo',
  },
];

export const PresentationTypePresets: React.FC = () => {
  const { presentationType, setPresentationType } = usePresentationType();
  const { feedIds } = useUser()!;

  const presentationTypePresets = useMemo(() => {
    return typePresets;
  }, []);

  const noFeedsPresents = presentationTypePresets?.filter(
    preset => preset.value === 'winTheRepresentation' || preset.value === 'presentInfo',
  );

  const [presets, setPresets] = useState(presentationTypePresets);

  useEffect(() => {
    if (feedIds.length === 0) {
      setPresentationType('presentInfo');
      setPresets(noFeedsPresents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentationTypePresets]);

  function generateConfigs() {
    return presets.map(preset => ({
      isActive: presentationType === preset.value,
      label: preset.label,
      onClick: () => setPresentationType(preset.value),
    }));
  }

  const configs = generateConfigs();

  return <ButtonsGroup buttonsConfigs={configs} />;
};
