import React, { useRef, useEffect, useState } from 'react';

import { Container, ImagePreview } from './SingleImage.styles';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { UploadImageButton } from '../../../../../Common/UploadImageButton/UploadImageButton.component';
import { UploadSingleImageModal } from '../../components/UploadSingleImageModal.component';

interface SingleImageFormProps {
  isWithFrame?: boolean;
}

export const SingleImageForm: React.FC<SingleImageFormProps> = ({ isWithFrame }) => {
  const { selectedSlide } = useSelectedSlide();

  const image = selectedSlide?.data?.image;
  const imagePdf = selectedSlide?.data?.image2;

  const [open, setOpen] = useState(!image && !imagePdf);

  const containerRef = useRef<HTMLDivElement>(null);
  const imageElRef = useRef<HTMLImageElement>(null);
  const containerEl = containerRef.current;
  const imageEl = imageElRef.current;

  const containerHeight = containerEl?.style.height;

  useEffect(() => {
    if (!containerEl || !imageEl) return;
    if (!image && imagePdf && containerHeight) {
      imageEl.style.width = `calc(${containerHeight} * 0.876)`;
    }
  });

  const webUploadInfoText = isWithFrame
    ? 'Images that have an aspect ratio of 2.1:1 work best. Example size 1566x745 pixels.'
    : 'Images that have an aspect ratio of 16:9 work best. Example size 1600x900 pixels.';

  const pdfUploadInfoText = isWithFrame
    ? 'Images that have an aspect ratio of 1:1.14 work best. Example size 905x1004 pixels.'
    : 'Images that have an aspect ratio of 1:1.29 work best. Example size 1000x1294 pixels.';

  if (!selectedSlide) return null;

  return (
    <Container>
      {(!!image || !!imagePdf) && (
        <>
          <ImagePreview
            ref={imageElRef}
            style={{
              width: !image ? `calc(${containerHeight} * 0.876)` : '100%',
              height: '100%',
              objectFit: 'contain',
              margin: image ? 0 : '0 auto',
            }}
            src={image || imagePdf}
          />
          <UploadImageButton
            onClick={() => setOpen(true)}
            containerStyles={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        </>
      )}
      <UploadSingleImageModal
        open={open}
        onClose={() => setOpen(false)}
        webUploadInfoText={webUploadInfoText}
        pdfUploadInfoText={pdfUploadInfoText}
        initialSrcWeb={image}
        initialSrcPdf={imagePdf}
      />
    </Container>
  );
};
