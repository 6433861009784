import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../../../../providers/providers/UiConfigColorProvider';

export const BorderLine = styled('div')(({ theme }) => ({
  width: '100%',
  height: 32,
  backgroundColor: '#f3f3f3',
  position: 'relative',
  '&:after': {
    content: '""',
    clipPath: ' polygon(0 0, 100% 0, 98% 100%, 0 100%)',
    backgroundColor: useUiConfigColorsPrimary(),
    width: '40%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));
