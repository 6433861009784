import { ReportGraphs, TPresentation } from '../../../types';
import { usePresentationFromParams } from './usePresentationFromParams';

export const useGetGraphs = () => {
  const { data: presentation } = usePresentationFromParams();

  const graphs = presentation?.graphs
    ? presentation.graphs
    : (presentation as TPresentation)?.reportData?.report?.reportDetailed?.graphs;

  return graphs as ReportGraphs;
};
