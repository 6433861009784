import * as types from './types';

export const onStep = (step = 1, action) => {
  switch (action.type) {
    case types.ON_STEP_SET:
      return action.payload;
    default:
      return step;
  }
};

export const address = (data = {}, action) => {
  switch (action.type) {
    case types.ADDRESS_SET:
      return action.payload;
    default:
      return data;
  }
};

export const searchCriteria = (data = {}, action) => {
  switch (action.type) {
    case types.SEARCH_CRITERIA_SET:
      return action.payload;
    default:
      return data;
  }
};

export const clients = (data = {}, action) => {
  switch (action.type) {
    case types.REPORT_CLIENTS_SET:
      return action.payload;
    default:
      return data;
  }
};

export const api = (data = {}, action) => {
  switch (action.type) {
    case types.API_SET:
      return action.payload;
    default:
      return data;
  }
};
