import React from 'react';
import { useCarouselControls } from '../../providers/CarouselControlsProvider';
import { AllSlidesContainer, SlideItemContainer, SlideTitle } from './AllSlidesList.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  isOpen: boolean;
}

export const AllSlidesList: React.FC<Props> = ({ isOpen }) => {
  const { allSlides, onSelectSlide } = useCarouselControls();

  return (
    <AllSlidesContainer isOpen={isOpen}>
      {allSlides.map(slide => {
        const isDynamicSlide = !!slide.template;
        return (
          <SlideItemContainer key={slide.id} onClick={() => onSelectSlide(slide)}>
            {isDynamicSlide ? (
              <FontAwesomeIcon icon={['fas', 'pen-ruler']} />
            ) : (
              <FontAwesomeIcon icon={['fas', 'memo']} />
            )}
            <SlideTitle>{slide.label}</SlideTitle>
          </SlideItemContainer>
        );
      })}
    </AllSlidesContainer>
  );
};
