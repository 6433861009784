import styled from 'styled-components';

import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { Card } from '../../../../../components/Card/Card.component';
import { data, title } from '../../data';

const cardStyles = {
  padding: '35px 37px',
};

const titleStyles = {
  fontSize: '24px',
  height: 'unset',
  marginBottom: '30px',
  letterSpacing: '1px',
  lineHeight: '168%',
  width: '100%',
};

const textStyles = {
  width: '100%',
  lineHeight: '168%',
  fontWeight: 500,
};

export const BuyersAgentCompensationModernPDF = () => {
  const primaryColor = useUiConfigColorsPrimary();
  const secondaryColor = useUiConfigColorsSecondary();

  return (
    <PdfFrame category={CATEGORIES.MODERN}>
      <Title color={primaryColor}>{title}</Title>
      <Grid color={secondaryColor}>
        {data.map((item, i) => (
          <Card
            key={`${item.title}_${i}`}
            type='pdf'
            cardStyles={cardStyles}
            titleStyles={titleStyles}
            textStyles={textStyles}
            {...item}
          />
        ))}
      </Grid>
    </PdfFrame>
  );
};

const Grid = styled.div<{ color: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  align-items: stretch;
  height: calc(100% - 110px);

  & > :first-of-type {
    border: 1.5px solid #f2f8f8;
    background-color: ${props => ColorOpacityApplier.hex(props.color, '.1')};
  }
`;

const Title = styled.h1<{ color: string }>`
  font-size: 24px;
  font-weight: 800;
  line-height: 41px;
  text-transform: uppercase;
  color: ${props => props.color};
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  padding: 24px 0;
  margin-top: 0;
`;
