import React, { useEffect, useState } from 'react';
import { AnalyticsGraphCard } from '../components/AnalyticsGraphsCard/AnalyticsGraphsCard.component';
import { GraphBars, TGraphData } from '../components/GraphBars/GraphBars.component';

export type TReportsSent = {
  totalReportsSent?: number;
  reportsSentData: TGraphData[];
};

interface ReportsSentProps {
  reportsSent: TReportsSent;
  isWithAgentData?: boolean;
}

export const ReportsSent: React.FC<ReportsSentProps> = ({ reportsSent, isWithAgentData }) => {
  const [reportsData, setReportsData] = useState<TGraphData[]>([]);

  useEffect(() => {
    setReportsData(reportsSent.reportsSentData);
  }, [reportsSent.reportsSentData]);

  const onCheckboxClick = (showOfficeData: boolean) => {
    if (showOfficeData) {
      setReportsData(reportsSent.reportsSentData);
    } else {
      const filteredData = reportsSent.reportsSentData.map(reportDataItem => {
        return {
          month: reportDataItem.month,
          agent: reportDataItem.agent,
        };
      });
      setReportsData(filteredData);
    }
  };

  const values = reportsData.map(value => {
    if (value.agent !== undefined && value.office !== undefined) {
      return value.agent > value.office ? value.agent : value.office;
    }
    return value.office || 0;
  });
  const max = Math.max(...values);

  return (
    <AnalyticsGraphCard
      height={175}
      title={isWithAgentData ? 'Total Reports Sent' : 'Reports Sent'}
      isWithAgentData={isWithAgentData}
      onCheckboxClick={onCheckboxClick}
      subtitle={reportsSent.totalReportsSent ? `Total sent: ${reportsSent.totalReportsSent}` : ''}
      cardContainerStyles={{ justifyContent: 'space-between' }}
    >
      <GraphBars graphData={reportsData} graphMaxValue={max ? max : 100} />
    </AnalyticsGraphCard>
  );
};
