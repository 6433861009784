import styled from 'styled-components';

export const SwitchWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '20px',
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px',
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const Switch = styled('label')({
  position: 'relative',
  display: 'inline-block',
  width: '30px',
  height: '14px',
});

export const InputCheckbox = styled('input')({
  opacity: 0,
  width: 0,
  height: 0,
  '&:checked + span': {
    backgroundColor: '#69D6FD',
  },
  '&:checked + span:before': {
    transform: 'translateX(16px)',
    backgroundColor: '#03AAFB',
  },
});

export const SwitchSlider = styled('span')({
  position: 'absolute',
  cursor: 'pointer',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#7D7D7D',
  borderRadius: '7px',
  transition: '.4s',
  '&:before': {
    position: 'absolute',
    content: '""',
    height: '18px',
    width: '18px',
    left: '-3px',
    bottom: '-2px',
    backgroundColor: '#E4E4E4',
    transition: '.4s',
    borderRadius: '50%',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
  },
});

export const Switch_V2Container = styled('div')({
  width: 50,
  height: 25,
  transition: 'all .4s',
  position: 'relative',
  backgroundColor: '#CBCBCB',
  margin: '0 8px',
  flexShrink: 0,
  borderRadius: 100,
  cursor: 'pointer',
});

export const Circle = styled('div')({
  height: 21,
  width: 20,
  top: 2.5,
  transition: 'all .4s',
  position: 'absolute',
  borderRadius: '50%',
  backgroundColor: '#fff',
  cursor: 'pointer',
});
