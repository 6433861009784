import React, { useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ModalHeader, Col, Content } from './CreateSlideModalHeader.styles';
import { useActiveSection } from '../../../../../../components/Slide/providers/ActiveSectionProvider';
import { TSection, TTag } from '../../../../../../types';
import { useSelectedSlide } from '../../../../../../providers/providers/SelectedSlideProvider';
import { useCreateSlideProps } from '../../../../providers/CreateSlidePropsProvider';
import { TModel } from '../../CreateSlideModal.component';
import { SlideName } from './components/SlideName';
import { SlideSectionSelector } from './components/SlideSectionSelector';
import { AccountOfficeSelector } from './components/AccountOfficeSelector';
import TagsSelector from './components/TagsSelector';
import { usePreviewMode } from '../../../../../PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { ButtonsGroup } from '../../../../../../components/Common/V2/ButtonsGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  model: TModel;
  setModel: Dispatch<SetStateAction<TModel>>;
}

export const CreateSlideModalHeader: React.FC<Props> = ({ model, setModel }) => {
  const { selectedSlide } = useSelectedSlide();
  const { setActiveSection } = useActiveSection();
  const { editing } = useCreateSlideProps();
  const isPdfPresentationSlide = selectedSlide?.template === 'pdfPresentationTemplate';

  const { setPreviewMode, previewMode } = usePreviewMode();

  const onSectionChange = useCallback(
    function onSectionChange(section: TSection) {
      setModel(prev => ({ ...prev, section }));
      setActiveSection(section);
    },
    [setActiveSection, setModel],
  );

  const setName = useCallback(
    (name: string) => {
      setModel(prev => ({ ...prev, name }));
    },
    [setModel],
  );

  const onEntityChange = useCallback(
    (entity: number | null) => {
      setModel(prev => ({ ...prev, entity }));
    },
    [setModel],
  );

  const onTagsChange = useCallback(
    (tags: any[]) => {
      const finalTags: TTag[] = !tags
        ? []
        : tags.map(t => ({ id: t.__isNew__ ? uuidv4() : t.value, label: t.label }));
      setModel(prev => ({ ...prev, tags: finalTags }));
    },
    [setModel],
  );

  useEffect(() => {
    const initialSection = model.section !== 'startingSlides' ? model.section : 'whyIAmTheRightFit';
    setActiveSection(isPdfPresentationSlide ? 'startingSlides' : initialSection);
    setModel(prev => ({
      ...prev,
      section: isPdfPresentationSlide ? 'startingSlides' : initialSection,
    }));
  }, [editing, isPdfPresentationSlide, model.section, selectedSlide, setActiveSection, setModel]);

  const previewModeButtonsConfig = [
    {
      label: 'Web View',
      value: 'web',
      isActive: previewMode === 'web',
      onClick: () => setPreviewMode('web'),
      prefixIcon: <FontAwesomeIcon icon={['fas', 'presentation-screen']} />,
    },
    {
      label: 'PDF View',
      value: 'pdf',
      isActive: previewMode === 'pdf',
      onClick: () => setPreviewMode('pdf'),
      prefixIcon: <FontAwesomeIcon icon={['fas', 'file-pdf']} />,
    },
  ];

  return (
    <ModalHeader>
      <Content>
        <Col>
          <SlideName onChange={setName} value={model.name} />
          <SlideSectionSelector onChange={onSectionChange} value={model.section} />
        </Col>
        <Col>
          <TagsSelector value={model.tags} onChange={onTagsChange} />
          <AccountOfficeSelector onChange={onEntityChange} value={model.entity} />
        </Col>
        <Col style={{ justifyContent: 'flex-end' }}>
          <ButtonsGroup buttonsConfigs={previewModeButtonsConfig} />
        </Col>
      </Content>
    </ModalHeader>
  );
};
