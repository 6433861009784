import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { TooltipRange } from './TooltipRange/TooltipRange';
import { SearchCombiner } from '../../../../SearchCombiner';
import { SearchValidator } from '../../../../SearchValidator';
import { CriteriaOptionsSubtitle } from '../components/CriteriaOptionsSubtitle/CriteriaOptionsSubtitle';

import classes from './ClosedDate.module.scss';
import { CRITERIAS } from '../../../Criterias';
import { Accordion } from '../../../../../../../../../../../components/Common/V2/Accordion';
import { useCriteriaIsOpen } from '../../../CriteriaIsOpenProvider';
import { CriteriaAccordion } from '../components/CriteriaAccordion';

const dateLimit = [
  { label: '', value: 0 },
  { label: '', value: 24 },
];

const info =
  'Reflects the date in which a property closed according to the Title record for the property. The default range is set to identify comparables that have closed in the last 6 months.';

export const ClosedDateComponent = ({ searchCriteria, className, onChange }) => {
  const [saleDateLimitRange, setSaleDateLimitRange] = useState([0, 0]);
  const [isOpen] = useCriteriaIsOpen(CRITERIAS.SALE_DATE_LIMIT_RANGE);

  const validate = () => {
    const min = Math.min(...saleDateLimitRange);
    const max = Math.max(...saleDateLimitRange);

    return !Number.isNaN(min) && !Number.isNaN(max);
  };

  const onCombine = () => ({ saleDateLimitRange });

  useEffect(() => {
    setSaleDateLimitRange(searchCriteria.saleDateLimitRange || [0, 6]);
  }, []);

  useEffect(() => {
    SearchValidator.subscribe('saleDateLimitRange', validate);
    SearchCombiner.add('saleDateLimitRange', onCombine);
    onChange(SearchCombiner.combine());

    return () => {
      SearchValidator.unsubscribe('saleDateLimitRange');
      SearchCombiner.remove('saleDateLimitRange');
    };
  }, [saleDateLimitRange]);

  return (
    <CriteriaAccordion info={info} title='CLOSED DATE' isOpen={isOpen}>
      <>
        <CriteriaOptionsSubtitle text='Only pull comps that have sold within:' />

        <TooltipRange
          options={dateLimit}
          value={saleDateLimitRange}
          changed={setSaleDateLimitRange}
          className={classes.range}
        />
      </>
    </CriteriaAccordion>
  );
};

const stateToProps = ({ search }) => ({
  searchCriteria: search.searchCriteria,
});
export const ClosedDate = connect(stateToProps)(ClosedDateComponent);
