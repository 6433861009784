import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { PresentationApi } from '../../../../Presentation/api/PresentationApi';
import { useInvalidatePresentations } from './useLoadPresentations';

export const useDeletePresentation = () => {
  const invalidatePresentations = useInvalidatePresentations();

  const mutation = useMutation((id: string) => PresentationApi.delete(id), {
    onSuccess: () => {
      invalidatePresentations();
    },
    onError: () => {
      toast.error(`Error while deleting presentation`);
    },
  });
  return mutation;
};
