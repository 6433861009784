export default `<svg xmlns="http://www.w3.org/2000/svg" width="75" height="76" viewBox="0 0 75 76" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 0.5H52.5H75V23V53L52.5 75.5L52.5 23H0L22.5 0.5Z" fill="url(#paint0_linear_9244_15974)"/>
<defs>
  <linearGradient id="paint0_linear_9244_15974" x1="0" y1="0.5" x2="69.6429" y2="75.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#027B76"/>
    <stop offset="1" stop-color="#101010"/>
  </linearGradient>
</defs>
</svg>
`;
