import React from 'react';
import image from './image.png';
import { listItems, subtitle } from '../../data';
import { TwoSectionsSlideLuxuryTemplate } from '../../../../../components/TwoSectionsSlideLuxuryTemplate/web/TwoSectionsSlideLuxuryTemplate.component';

export const HomeSellingProcessLuxury: React.FC = () => {
  return (
    <TwoSectionsSlideLuxuryTemplate
      heading='Home Selling Process'
      subtitle={subtitle}
      imagePosition='left'
      image={image}
      imageStyles={{ width: '71%' }}
      listItems={listItems}
      textContainerStyles={{ padding: 25, width: '50%' }}
    />
  );
};
