import { TSlideId } from '../../../types';
import { dummyBuyerDemandsResponse } from './dummyData';

export type TSlideDummyData = typeof dummyBuyerDemandsResponse;

export class SlideApi {
  static async fetchSlideData(slideId: TSlideId): Promise<TSlideDummyData> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(dummyBuyerDemandsResponse);
      }, 1000);
    });
  }
}
