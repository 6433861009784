import { TDynamicSlideTypeMapping } from './types';
import { TDynamicSlideTemplate, TSlidePlatform } from '../../../types';

import { mappings as oneImageLayoutAlphaMappings } from './templates/oneImageLayout/alpha';
import { mappings as oneImageLayoutBetaMappings } from './templates/oneImageLayout/beta';
import { mappings as oneImageLayoutGammaMappings } from './templates/oneImageLayout/gamma';
import { mappings as oneImageLayoutDeltaMappings } from './templates/oneImageLayout/delta';
import { mappings as oneImageLayoutEpsilonMappings } from './templates/oneImageLayout/epsilon';
import { mappings as oneImageLayoutZetaMappings } from './templates/oneImageLayout/zeta';

import { mappings as twoImagesLayoutAlphaMappings } from './templates/twoImagesLayout/alpha';
import { mappings as twoImagesLayoutBetaMappings } from './templates/twoImagesLayout/beta';
import { mappings as twoImagesLayoutGammaMappings } from './templates/twoImagesLayout/gamma';
import { mappings as twoImagesLayoutDeltaMappings } from './templates/twoImagesLayout/delta';
import { mappings as twoImagesLayoutEpsilonMappings } from './templates/twoImagesLayout/epsilon';
import { mappings as twoImagesLayoutZetaMappings } from './templates/twoImagesLayout/zeta';

import { mappings as threeImagesLayoutAlphaMappings } from './templates/threeImagesLayout/alpha';
import { mappings as threeImagesLayoutBetaMappings } from './templates/threeImagesLayout/beta';
import { mappings as threeImagesLayoutGammaMappings } from './templates/threeImagesLayout/gamma';
import { mappings as threeImagesLayoutDeltaMappings } from './templates/threeImagesLayout/delta';
import { mappings as threeImagesLayoutEpsilonMappings } from './templates/threeImagesLayout/epsilon';
import { mappings as threeImagesLayoutZetaMappings } from './templates/threeImagesLayout/zeta';

import { mappings as fourImagesLayoutAlphaMappings } from './templates/fourImagesLayout/alpha';
import { mappings as fourImagesLayoutBetaMappings } from './templates/fourImagesLayout/beta';
import { mappings as fourImagesLayoutGammaMappings } from './templates/fourImagesLayout/gamma';
import { mappings as fourImagesLayoutDeltaMappings } from './templates/fourImagesLayout/delta';
import { mappings as fourImagesLayoutEpsilonMappings } from './templates/fourImagesLayout/epsilon';
import { mappings as fourImagesLayoutZetaMappings } from './templates/fourImagesLayout/zeta';

import { mappings as textLayoutAlphaMappings } from './templates/textLayout/alpha';
import { mappings as textLayoutBetaMappings } from './templates/textLayout/beta';
import { mappings as textLayoutGammaMappings } from './templates/textLayout/gamma';
import { mappings as textLayoutDeltaMappings } from './templates/textLayout/delta';
import { mappings as textLayoutEpsilonMappings } from './templates/textLayout/epsilon';
import { mappings as textLayoutZetaMappings } from './templates/textLayout/zeta';

import { mappings as singleImageTemplateMappings } from './templates/singleImageLayout/singleImage';
import { mappings as singleImageWithFrameTemplateMappings } from './templates/singleImageLayout/singleImageWithFrame';
import { mappings as singleFramelessVideoMappings } from './templates/singleVideoLayout/singleFramelessVideo';
import { mappings as singleVideoMappings } from './templates/singleVideoLayout/singleVideo';
import { mappings as pdfPresentationMappings } from './templates/pdfPresentationLayout';

class DynamicSlideFactoryImp {
  private slidesMappings: Record<TDynamicSlideTemplate, TDynamicSlideTypeMapping> = {
    oneImageLayoutAlpha: oneImageLayoutAlphaMappings,
    oneImageLayoutBeta: oneImageLayoutBetaMappings,
    oneImageLayoutGamma: oneImageLayoutGammaMappings,
    oneImageLayoutDelta: oneImageLayoutDeltaMappings,
    oneImageLayoutEpsilon: oneImageLayoutEpsilonMappings,
    oneImageLayoutZeta: oneImageLayoutZetaMappings,
    twoImagesLayoutAlpha: twoImagesLayoutAlphaMappings,
    twoImagesLayoutBeta: twoImagesLayoutBetaMappings,
    twoImagesLayoutGamma: twoImagesLayoutGammaMappings,
    twoImagesLayoutDelta: twoImagesLayoutDeltaMappings,
    twoImagesLayoutEpsilon: twoImagesLayoutEpsilonMappings,
    twoImagesLayoutZeta: twoImagesLayoutZetaMappings,
    threeImagesLayoutAlpha: threeImagesLayoutAlphaMappings,
    threeImagesLayoutBeta: threeImagesLayoutBetaMappings,
    threeImagesLayoutGamma: threeImagesLayoutGammaMappings,
    threeImagesLayoutDelta: threeImagesLayoutDeltaMappings,
    threeImagesLayoutEpsilon: threeImagesLayoutEpsilonMappings,
    threeImagesLayoutZeta: threeImagesLayoutZetaMappings,
    fourImagesLayoutAlpha: fourImagesLayoutAlphaMappings,
    fourImagesLayoutBeta: fourImagesLayoutBetaMappings,
    fourImagesLayoutGamma: fourImagesLayoutGammaMappings,
    fourImagesLayoutDelta: fourImagesLayoutDeltaMappings,
    fourImagesLayoutEpsilon: fourImagesLayoutEpsilonMappings,
    fourImagesLayoutZeta: fourImagesLayoutZetaMappings,
    textLayoutAlpha: textLayoutAlphaMappings,
    textLayoutBeta: textLayoutBetaMappings,
    textLayoutGamma: textLayoutGammaMappings,
    textLayoutDelta: textLayoutDeltaMappings,
    textLayoutEpsilon: textLayoutEpsilonMappings,
    textLayoutZeta: textLayoutZetaMappings,
    singleImageTemplate: singleImageTemplateMappings,
    singleImageWithFrameTemplate: singleImageWithFrameTemplateMappings,
    singleFramelessVideo: singleFramelessVideoMappings,
    singleVideo: singleVideoMappings,
    pdfPresentationTemplate: pdfPresentationMappings,
  };

  create(platform: TSlidePlatform, slide?: TDynamicSlideTemplate, formVariant = false) {
    if (!slide) return null;

    if (formVariant && platform === 'web' && this.slidesMappings?.[slide].form) {
      return this.slidesMappings[slide].form;
    }

    if (this.slidesMappings?.[slide]?.[platform]) {
      return this.slidesMappings[slide][platform];
    }

    return null;
  }
}

export const DynamicSlideFactory = new DynamicSlideFactoryImp();
