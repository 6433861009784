import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';
import { ColorOpacityApplier } from '../../../services/ColorOpacityApplier';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 32px 0 32px',
  flex: 1,
  height: 120,
  maxHeight: 191,
  '&:not(:last-child)': {
    marginBottom: 10,
  },
});

const Title = styled('h1')(({ theme }) => ({
  fontSize: 25,
  fontWeight: 800,
  lineHeight: 1.2,
  marginBottom: 8,
  textTransform: 'uppercase',
  marginTop: 0,
  color: '#444',
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing('0.05em'),
}));

const Text = styled('div')(({ theme }) => ({
  color: '#101010',
  fontSize: 18,
  lineHeight: 1.7,
  fontWeight: 300,
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

interface Props {
  order: number;
  title: string;
  description: string;
  isActive?: boolean;
  isLast?: boolean;
}

const SectionItem: React.FC<Props> = ({ order, title, description, isActive, isLast }) => {
  const primary = useUiConfigColorsPrimary();
  return (
    <Container
      style={{
        backgroundColor: isActive ? ColorOpacityApplier.hex(primary, '0.05') : 'transparent',
        filter: `grayscale(${isActive ? '0' : '0.4'})`,
        opacity: isActive ? 1 : 0.4,
      }}
    >
      <Text style={{ fontWeight: 500, marginBottom: 0 }}>SECTION {order + 1}</Text>
      <Title>{title}</Title>
      <Text>{description}</Text>
    </Container>
  );
};
export default SectionItem;
