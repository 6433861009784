import { useEffect } from 'react';

import { useSlides } from '../../../providers/providers/SlidesProvider';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { useLevelDynamicSlides } from '../../../hooks/useLevelDynamicSlides';

export function useSetDynamicSlidesToSlidesProvider() {
  const { hash, useSpecialBranding } = useUser();
  const { setSlides } = useSlides();

  const { data, isFetching, isLoading } = useLevelDynamicSlides(hash, useSpecialBranding, null);

  useEffect(
    function addSlidesToConfig() {
      if (!data) return;

      setSlides(prev => ({
        ...prev,
        ...(data ?? {}),
      }));
    },
    [data, setSlides],
  );

  return { data, isFetching, isLoading };
}
