import { TProperty } from '../../../../types';
import { TextDescription, Wrapper } from '../components/UI';
import { SectionTitle } from '../components/SectionTitle';

interface Props {
  property: TProperty;
}

export const MlsDisclaimer = ({ property }: Props) => {
  return (
    <Wrapper>
      <SectionTitle>MLS Disclaimer</SectionTitle>
      <TextDescription>
        {property.disclaimer.replace('#update', new Date().toDateString())}
      </TextDescription>
    </Wrapper>
  );
};
