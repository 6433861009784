import React, { useContext, useState } from 'react';
import { EntityMode } from '../../../types';

type EntityContextType = {
  entity: number | null;
  setEntity: (value: number | null) => void;
  entityMode: EntityMode;
  setEntityMode: (mode: EntityMode) => void;
  resetEntity: () => void;
  entityLabel: string;
  setEntityLabel: (value: string) => void;
};

const EntityContext = React.createContext<EntityContextType | undefined>(undefined);

export const useEntity = () => {
  const context = useContext(EntityContext);

  if (!context) {
    throw new Error('useEntity cannot be used without EntityContext');
  }

  return context;
};

interface EntityProviderProps {
  initValue?: number | null;
  initMode?: EntityMode;
}

export const EntityProvider: React.FC<EntityProviderProps> = ({
  initValue,
  initMode,
  children,
}) => {
  const [entity, setEntity] = useState<number | null>(initValue ?? null);
  const [entityMode, setEntityMode] = useState<EntityMode>(initMode ?? EntityMode.READ);
  const [entityLabel, setEntityLabel] = useState('');

  const resetEntity = React.useCallback(function resetEntity() {
    setEntity(null);
  }, []);

  const value = React.useMemo(
    () => ({
      entity,
      setEntity,
      entityMode,
      setEntityMode,
      resetEntity,
      entityLabel,
      setEntityLabel,
    }),
    [entity, entityLabel, entityMode, resetEntity],
  );

  return <EntityContext.Provider value={value}>{children}</EntityContext.Provider>;
};
