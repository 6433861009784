import React from 'react';
import styled from 'styled-components';
import { CATEGORIES } from '../../../../../constants/categories';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';
import { PdfFrame } from '../../SlideFrame/frames/pdf/PdfFrame';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  border: '1px solid #E8E8E8',
  backdropFilter: 'blur(10px)',
  paddingTop: 50,
});

const Img = styled('img')({
  width: '100%',
  height: 'auto',
  marginBottom: 20,
});

const TextContainer = styled('div')(({ theme }) => ({
  padding: 50,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const Heading = styled('h1')(({ theme }) => ({
  fontSize: 26,
  color: '#000',
  fontWeight: 900,
  fontFamily: useUiConfigFontsHeader(),
  textTransform: 'uppercase',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
  '&::after': {
    display: 'block',
    content: '" "',
    height: 1.5,
    backgroundColor: useUiConfigColorsPrimary(),
    width: '45%',
    marginTop: 7,
    marginBottom: 20,
  },
}));

const UL = styled('ul')({ paddingLeft: 20 });
const OL = styled('ol')({ paddingLeft: 20 });

const LI = styled('li')({ fontSize: 16, lineHeight: 1.8 });

const Text = styled('p')({
  fontSize: 16,
  textAlign: 'justify',
  lineHeight: 1.8,
  marginBottom: 30,
});

const ListItemBold = styled.span`
  font-weight: 700;
`;

interface Props {
  heading: string;
  paragraphs?: string[];
  listItems?: { title?: string; text: string }[];
  image: string;
  listItemStyles?: React.CSSProperties;
  paragraphStyles?: React.CSSProperties;
  headingStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
  imageStyles?: React.CSSProperties;
  listType?: 'ul' | 'ol';
  noColons?: boolean;
  textContainerStyles?: React.CSSProperties;
}

const TwoSectionsSlideLuxuryTemplatePDF: React.FC<Props> = ({
  heading,
  paragraphs,
  image,
  paragraphStyles = {},
  headingStyles,
  listItems,
  listItemStyles = {},
  containerStyles,
  imageStyles,
  listType = 'ol',
  noColons = false,
  textContainerStyles,
}) => {
  const listMap = {
    ul: UL,
    ol: OL,
  };
  const ListComponent = listMap[listType];
  return (
    <PdfFrame category={CATEGORIES.LUXURY}>
      <Container style={containerStyles}>
        <Img src={image} style={imageStyles} />
        <TextContainer style={textContainerStyles}>
          <Heading style={headingStyles}>{heading}</Heading>
          {paragraphs &&
            paragraphs.map((p, i) => (
              <Text key={i} style={paragraphStyles}>
                {p}
              </Text>
            ))}
          {listItems && (
            <ListComponent>
              {listItems.map((li, i) => (
                <LI key={i} style={listItemStyles}>
                  {li.title && <ListItemBold>{li.title}: </ListItemBold>}
                  {li.text}
                </LI>
              ))}
            </ListComponent>
          )}
        </TextContainer>
      </Container>
    </PdfFrame>
  );
};

export default TwoSectionsSlideLuxuryTemplatePDF;
