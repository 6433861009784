import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { PROPERTY_STATUS, TCompConfigItem } from '../../../../types';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { CLOSED, TRENDS_KEYS } from './trendsConfig';
import { TrendsSection } from './TrendsSection';

const closedGroups = [
  [CLOSED[TRENDS_KEYS.HIGHEST], CLOSED[TRENDS_KEYS.AVERAGE], CLOSED[TRENDS_KEYS.MEDIAN]],
  [CLOSED[TRENDS_KEYS.LOWEST], CLOSED[TRENDS_KEYS.CLOSEST], CLOSED[TRENDS_KEYS.RECENT]],
] as TCompConfigItem[][];

export const ClosedTrends = () => {
  const properties = useAtomValue(reportPropertiesAtom);

  const count = useMemo(() => {
    return properties?.filter(p => p.status === PROPERTY_STATUS.SOLD && !p.excluded)?.length;
  }, [properties]);

  return (
    <TrendsSection
      title='CLOSED PROPERTIES'
      count={count}
      groups={closedGroups}
      status={PROPERTY_STATUS.SOLD}
    />
  );
};
