import styled from 'styled-components';

type WithPdf = {
  isPdf?: boolean;
};

export const Container = styled('div')<WithPdf>(({ theme, isPdf }) => ({
  display: 'grid',
  height: '100%',
  gridTemplateRows: isPdf ? '50% 50%' : '48% 48%',
  gridTemplateColumns: isPdf ? '50% 50%' : '48% 48%',
  gap: isPdf ? 10 : 32,
  padding: isPdf ? 0 : '2%',
}));

export const ImageWrapper = styled('div')<WithPdf>(({ isPdf }) => ({
  width: '100%',
  overflow: 'hidden',
}));

export const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'center',
});
