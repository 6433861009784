import { useMemo } from 'react';
import { PropertyGroups, TNote, TPresentation } from '../../../types';
import { getPropertiesNotes } from '../services/getPropertiesNotes';
import { usePresentationFromParams } from './usePresentationFromParams';
import { useGetProperties } from './useGetProperties';
import { useUpdateAtomOnChange } from '../../../hooks/useUpdateAtomOnChange';
import { propertiesNotesAtom } from '../state/propertiesNotesAtom';

export const useSetNotes = () => {
  const { data: presentation } = usePresentationFromParams();

  const listings = useGetProperties();

  const notes: TNote[] = useMemo(() => {
    return (presentation as TPresentation)?.version === 2
      ? getPropertiesNotes(listings as PropertyGroups)
      : (presentation as TPresentation)?.reportData?.result?.notes;
  }, [listings, presentation]);

  useUpdateAtomOnChange(propertiesNotesAtom, notes);
};
