import React, { Fragment, useRef, Dispatch, SetStateAction } from 'react';

export interface FilePickerComponentProps {
  onTrigger?: React.MouseEventHandler;
  setFile: Dispatch<SetStateAction<File | null>>;
  accept: string;
  children: React.ReactNode;
  isDisabled?: boolean;
}

export const FilePicker: React.FC<FilePickerComponentProps> = ({
  onTrigger,
  setFile,
  accept,
  children,
  isDisabled,
}) => {
  const inputElRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return;
    setFile(e.target.files[0]);
  };

  const handleClick = (e: React.MouseEvent): void => {
    if (!inputElRef.current) return;
    inputElRef.current.click();
    if (onTrigger) onTrigger(e);
  };

  const childrenWithProps = React.Children.map(children, child => {
    const props = { onClick: handleClick };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return (
    <Fragment>
      {childrenWithProps}
      <input
        accept={accept}
        id='contained-button-file'
        name='document'
        type='file'
        style={{ display: 'none' }}
        ref={inputElRef}
        onChange={handleSelectFile}
        disabled={isDisabled}
      />
    </Fragment>
  );
};
