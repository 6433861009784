import React from 'react';

export const svgStringToDataUrl = (
  svgString: string,
  primary = '#027B76',
  secondary = '#101010',
): string => {
  const imageWithReplacedPrimary = svgString.replace(/#027B76/gi, primary);
  const image = imageWithReplacedPrimary.replace(/#101010/gi, secondary);

  return `data:image/svg+xml;utf8,${encodeURIComponent(image)}`;
};
