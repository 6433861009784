import { useState, useEffect, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Portal from './Portal';
import styled, { CSSProperties } from 'styled-components';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  id: string;
  style?: CSSProperties;
}

export const BasicModal = ({
  isOpen = false,
  children,
  onClose,
  id = 'scale-frame',
  style,
}: Props) => {
  const [afterOpen, setAfterOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => setAfterOpen(isOpen));
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Portal id={id}>
      <Overlay onClick={onClose}>
        <ModalEl onClick={e => e.stopPropagation()} afterOpen={afterOpen} style={style}>
          <CloseWrapper onClick={onClose}>
            <Close icon={['far', 'times']} />
          </CloseWrapper>
          {children}
        </ModalEl>
      </Overlay>
    </Portal>
  );
};

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1111;
  overflow: hidden;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    padding: 10px;
  }
`;

const ModalEl = styled.div<{ afterOpen: boolean }>`
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  background: var(--cma-full-light);
  border: 1px solid var(--cma-background-light);
  border-radius: 3px;
  padding: 20px;
  outline: none;
  box-shadow: none;
  -webkit-transform: ${({ afterOpen }) => (afterOpen ? 'translateY(0)' : 'translateY(100%)')};
  transform: ${({ afterOpen }) => (afterOpen ? 'translateY(0)' : 'translateY(100%)')};
  transition: all 200ms linear;
  display: flex;
`;

const CloseWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
`;

const Close = styled(FontAwesomeIcon)`
  position: absolute;
  right: 14px;
  top: 7px;
  color: #808080;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;
