import { FC } from 'react';
import { useAtomValue } from 'jotai';
import styled, { useTheme } from 'styled-components';

import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';

interface MarketAssessmentCardCircleProps {
  hovered: boolean;
  oneSheeter?: boolean;
  sup?: JSX.Element;
  sub?: string;
}

export const MarketAssessmentCardCircle: FC<MarketAssessmentCardCircleProps> = ({
  hovered,
  oneSheeter,
  sup,
  sub,
  children,
}) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primaryColor = useUiConfigColorsPrimary();
  const { colors } = useTheme();

  const circleColor = isPresentationView ? primaryColor : colors.v2.primary;

  return (
    <Wrapper oneSheeter={oneSheeter}>
      <Circle style={{ backgroundColor: circleColor }}>
        <InnerCircle hovered={hovered}>
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </InnerCircle>
      </Circle>
      {sup && <Sup style={{ color: circleColor }}>{sup}</Sup>}
      {sub && <Sub style={{ color: circleColor }}>{sub}</Sub>}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ oneSheeter?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => (props.oneSheeter ? 'scale(0.8) translate(20px, -20px)' : '')};
  transform-origin: ${props => (props.oneSheeter ? '0 0' : '')};
`;

const Circle = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: stretch;
  position: relative;
  width: 90px;
  height: 90px;
`;

const InnerCircle = styled.div<{ hovered: boolean }>`
  margin: 0;
  border-radius: 50%;
  background: ${props => (props.hovered ? props.theme.colors.v2.gray[100] : '#FFF')};
  display: flex;
  align-items: center;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
`;

const ChildrenWrapper = styled.div`
  font-size: 26px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const Sup = styled.div`
  font-weight: bold;
  align-self: flex-start;
  font-size: 1.5em;
  padding-top: 5px;
`;

const Sub = styled.div`
  font-weight: bold;
  align-self: flex-end;
  font-size: 1.125em;
`;
