import { useHistory } from 'react-router-dom';

import { BasicModal } from '../../../../components/Common/BasicModal';
import { useUrlSearchParam } from '../../../../hooks/useQueryParams';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { selectedPropertyAtom } from '../../state/selectedPropertyAtom';
import { PropertiesOverview } from './PropertiesOverview/PropertiesOverview';
import { CSSProperties } from 'styled-components';
import { isUpdatePresentationWithBuildAtom } from '../../state/isUpdatePresentationWithBuildAtom';
import { useUpdatePresentationWithBuild } from '../../hooks/useUpdatePresentationWithBuild';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';

interface Props {
  id?: string;
  modalStyle?: CSSProperties;
  isModalOpen?: boolean;
  onCloseModal?: () => void;
}

export const PropertyModal = ({
  id = 'scale-frame',
  modalStyle,
  isModalOpen = false,
  onCloseModal,
}: Props) => {
  const { push, location } = useHistory();
  const source = useUrlSearchParam('source');
  const setSelectedProperty = useSetAtom(selectedPropertyAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const [isUpdatePresentationWithBuild, setUpdatePresentationWithBuild] = useAtom(
    isUpdatePresentationWithBuildAtom,
  );
  const { mutate: syncPresentationWithBuild } = useUpdatePresentationWithBuild();

  const onClose = () => {
    setSelectedProperty(null);
    onCloseModal?.();

    if (!isPresentationView && isUpdatePresentationWithBuild) {
      setUpdatePresentationWithBuild(false);
      syncPresentationWithBuild();
    }

    if (source) {
      const params = new URLSearchParams(location.search);
      params.delete('source');
      push({ pathname: location.pathname, search: '?' + params.toString() });
    }
  };

  return (
    <BasicModal id={id} isOpen={isModalOpen} onClose={onClose} style={modalStyle}>
      <PropertiesOverview />
    </BasicModal>
  );
};
