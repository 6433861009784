import React, { FC } from 'react';
import { Body, IconWrapper, Icon, Typography } from './EmphasisInfo.styles';
import { useEmphasis } from '../EmphasisProvider';
import { IconName } from '../../../../../../../components/Common/Icon/Icon.types';
import { useAvailableCustomizationLevels } from '../hooks/useAvailableCustomizationLevels';
import { usePresentationMeta } from '../../../../../../../providers/providers/PresentationMetaProvider';
import { InheritanceLevel } from '../../../../../../../types';
import { RadioButtons } from '../../../../../../../components/Common';

const DefaultEmphasisInfo: FC = () => {
  const levels = useAvailableCustomizationLevels();
  const { inheritanceLevel, setInheritanceLevel } = usePresentationMeta();

  return (
    <div>
      <Typography variant='body2' color='400'>
        Slides are ordered according to the default directions established in the customization
        settings and the default version selected here:
      </Typography>

      {levels?.length <= 1 ? null : (
        <RadioButtons
          defaultSelected={inheritanceLevel}
          options={levels}
          onChange={val => setInheritanceLevel(val as InheritanceLevel)}
        />
      )}
    </div>
  );
};

export const EmphasisInfo: React.FC = () => {
  const { emphasis } = useEmphasis();

  const getEmphasisText = () => {
    switch (emphasis?.value) {
      case 'defaultOrder':
        return <DefaultEmphasisInfo />;
      case 'rightTimeToSell':
        return (
          <Typography variant='body2' color='400'>
            The order of this report is designed for the “<strong>CONTINGENCY</strong>” Seller.
            <br />
            This is a “no nonsense” seller - their current home is holding them back from their next
            move. Their main hurdle is feeling comfortable that the home is priced right & your
            marketing plan will get the job done.
          </Typography>
        );
      case 'agentIsTheRightFit':
        return (
          <Typography variant='body2' color='400'>
            The order of this report is designed for the “<strong>I’M SHOPPING YOU</strong>” Seller.
            <br />
            For this seller, it’s not a question of timing or value - This seller is committed to
            sell. Their only hurdle is choosing who the right agent is to list with.
          </Typography>
        );
      case 'topDollarValue':
        return (
          <Typography variant='body2' color='400'>
            The order of this report is designed for the “<strong>MAKE ME LIST</strong>” Seller.
            <br />
            This seller is interested in selling but only motivated to list if/when they can get top
            dollar. Their main hurdle is understanding why NOW is the right time to get top dollar
            and why you are the one to get it.
          </Typography>
        );
      default:
        return null;
    }
  };

  if (!emphasis?.value) return null;

  return (
    <Body>
      <IconWrapper>
        <Icon iconName={emphasis?.value as IconName} />
      </IconWrapper>
      {getEmphasisText()}
    </Body>
  );
};
