import React from 'react';
import {
  CustomColors,
  MarketingPlanContainer,
  MarketingPlanText,
  MarketingPlanTextContainer,
  MarketingPlanTextWrapper,
  MarketingPlanTitle,
  WhiteOverlay,
} from './MyMarketingPlan.styles';
import customColorsOverlay from './assets/web-custom-colors';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import whiteOverlay from './assets/web-left-col.png';

export const MyMarketingPlan: React.FC = () => {
  const primary = useUiConfigColorsPrimary();
  const img = React.useMemo(() => svgStringToDataUrl(customColorsOverlay, primary), [primary]);

  return (
    <MarketingPlanContainer>
      <CustomColors src={img} />
      <WhiteOverlay src={whiteOverlay} />
      <MarketingPlanTextContainer>
        <MarketingPlanTitle>My Marketing Plan</MarketingPlanTitle>
        <MarketingPlanTextWrapper>
          <MarketingPlanText>
            I’ll provide a straightforward course of action for marketing your home that will
            identify and attract buyers and generate buzz about your listing. Think of your
            marketing plan as a map of the road to your final destination – selling your home.
          </MarketingPlanText>
          <MarketingPlanText>
            Your customized marketing plan will include various marketing tools like open houses,
            virtual tours, websites, mailers, emails, social media ads, and others. To prove
            progress, I’ll also track metrics like where buyers are finding your listing, how much
            online traffic your listing is generating, and returns on any advertising.
          </MarketingPlanText>
        </MarketingPlanTextWrapper>
      </MarketingPlanTextContainer>
    </MarketingPlanContainer>
  );
};
